import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { utcToZonedTime } from "date-fns-tz/esm";
import { zonedTimeToUtc } from "date-fns-tz";
import { gql } from "@apollo/client";

import { useNotificationsContext, useStreamContext } from "Services/stream";
import { CurrentUser, useSession } from "Services/session";

import { Notification } from "Components/Notifications/Notification";

import { useNotificationUserQuery } from "@tract/common/dist/graphql";
import { NotificationSkeleton } from "./NotificationSkeleton";
import { NotificationBody } from "./NotificationBody";
import { getDisplayName } from "Types/User";

type Props = {
  group: {
    activities: TODO[];
    is_seen: boolean;
    is_read: boolean;
    id: string;
    verb: string;
  };
  currentUser?: CurrentUser;
  onClose?: () => void;
};

const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const NOTIFICATION_USER_FRAGMENT = gql`
  fragment NotificationUser on user {
    firestoreId
    username
    avatar
    firstName
    lastName
    isEducator
    teacher {
      id: userId
      displayName
    }
  }
`;

gql`
  ${NOTIFICATION_USER_FRAGMENT}
  query NotificationUser($userId: String!) {
    users: user(limit: 1, where: { firestoreId: { _eq: $userId } }) {
      ...NotificationUser
    }
  }
`;

export const NotificationGroup: React.FC<Props> = ({ group, onClose }) => {
  const { activities, is_read } = group;
  const { client } = useStreamContext();
  const { checkUnseen } = useNotificationsContext();
  const { currentUser } = useSession();
  const [isRead, setRead] = useState(is_read);
  const history = useHistory();

  const feed = client.feed("notification", currentUser.uid);

  const aggregateCount = useMemo(() => {
    // Returns a count of unique users
    return activities.reduce((accumulator, activity) => {
      const { actor } = activity;
      accumulator.add(typeof actor === "object" ? actor.id : actor);
      return accumulator;
    }, new Set()).size;
  }, [activities]);

  const latestActivity = activities[0];
  const { actor } = latestActivity;

  const { data, loading } = useNotificationUserQuery({
    variables: {
      userId: actor,
    },
  });
  const user = !!data?.users?.length ? data.users[0] : undefined;

  const zonedTime = utcToZonedTime(
    zonedTimeToUtc(latestActivity.time, "UTC"),
    CURRENT_TIMEZONE
  );

  async function handleClickNotification() {
    if (!isRead) {
      feed.get({ mark_read: [group.id] });
      setRead(true);
      // This is to update the dot in the main nav
      checkUnseen();
    }

    if (!!onClose) {
      onClose();
    }
    if (latestActivity.target) {
      history.push(latestActivity.target);
    }
  }

  useEffect(() => {
    if (!!is_read) {
      setRead(is_read);
    }
  }, [is_read]);

  const hideUser = group.verb === "reject:project";
  const displayName = hideUser ? "A moderator" : getDisplayName(user);

  if (loading) {
    return <NotificationSkeleton isRead={isRead} />;
  }

  if (actor !== "admin" && !user) {
    return null;
  }

  return (
    <Notification
      isMod={hideUser}
      user={user}
      isRead={isRead}
      timestamp={zonedTime}
      onClick={handleClickNotification}
    >
      <NotificationBody
        description={latestActivity.description}
        username={hideUser ? "" : user?.username || "someone"}
        isEducator={user?.isEducator || false}
        displayName={displayName || ""}
        numActors={aggregateCount}
      />
    </Notification>
  );
};
