import { gql } from "urql";

export const SHOP_ITEM_FRAGMENT = gql`
  fragment ShopItem on shop_item {
    id
    name
    description
    cost
    media
    disabled
    quantity
    tags
    coverFile {
      id
      filename
      transforms {
        fileId
        filename
      }
    }
    externalURL
    metadata
    owner {
      email
    }
  }
`;

export const SHOP_ITEMS_QUERY = gql`
  ${SHOP_ITEM_FRAGMENT}

  query ShopItems($limit: Int!, $offset: Int!, $tags: jsonb_comparison_exp!) {
    items: shop_item(
      where: { disabled: { _eq: false }, tags: $tags }
      limit: $limit
      offset: $offset
      order_by: { cost: asc }
    ) {
      ...ShopItem
    }
  }
`;

export const SHOP_ITEM_QUERY = gql`
  ${SHOP_ITEM_FRAGMENT}

  query ShopItem($id: uuid!) {
    item: shop_item_by_pk(id: $id) {
      ...ShopItem
    }
  }
`;
