import React, { useEffect, useState } from "react";
import { useQuery } from "urql";
import { Redirect, useParams } from "react-router-dom";
import axios from "axios";

import {
  AspectRatio,
  Box,
  ButtonLink,
  ButtonSolid,
  ExternalLink,
  Flex,
  Grid,
  HStack,
  IconExternalLink,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  Center,
  IconCheck,
  Markdown,
} from "Shared";

import { SHOP_ITEM_QUERY } from "../graphql";

import { UserFile } from "Utils/UserFile";

import { CoinValue } from "Components/CoinValue";
import { LayoutCentered } from "Components/LayoutCentered";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { useCoins } from "Services/hooks/useCoins";

import { isKidUser } from "Types/User";

import {
  ShopItemFragment,
  ShopItemQuery,
  ShopItemQueryVariables,
} from "@tract/common/dist/graphql";
import { post } from "Services/api";
import { getShopCategoryName } from "Services/shop";

export function PrizeDetails() {
  const { currentUser, firebaseUser } = useSession();
  const { updateBalance } = useCoins();
  const { track } = useAnalytics();
  const { itemId }: { itemId: string } = useParams();
  const toast = useToast();
  const [isRedeeming, setRedeeming] = useState(false);
  const [redemptionURL, setRedemptionURL] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ data, stale, fetching }, refetch] = useQuery<
    ShopItemQuery,
    ShopItemQueryVariables
  >({
    requestPolicy: "network-only",
    query: SHOP_ITEM_QUERY,
    variables: { id: itemId },
  });

  const item = data?.item;

  const hasQuantity = typeof item?.quantity === "number";
  const isSoldOut = !!(
    typeof item?.quantity === "number" && item.quantity <= 0
  );

  useEffect(() => {
    track(ANALYTICS_EVENTS.PRIZE_VIEWED, {
      prizeTitle: item?.name,
      prizeCategory: item?.tags?.join(","),
    });
  }, [track, item?.name, item?.tags]);

  const coverFile = new UserFile(item?.coverFile);

  const getImgSrc = (item: ShopItemFragment) => {
    if (item.coverFile) {
      if (item.coverFile.transforms) {
        return coverFile.getTransformedUrl("image_full");
      } else {
        return coverFile.getOriginalUrl();
      }
    }

    if (item.media) {
      return item.media.cover.files[0];
    }
  };

  async function handlePurchase() {
    if (!item) {
      throw new Error("Order not loaded");
    }

    try {
      setRedeeming(true);

      const res = await post("/v2/shop/purchase-item", firebaseUser, {
        id: item.id,
      });

      updateBalance(-res.data.cost);

      if (item.metadata?.successActionURL) {
        const url = new URL(item.metadata.successActionURL);

        // Append purchase ID params to success action URL. This allows us to
        // link Airtable form submissions to purchases.
        url.searchParams.append("prefill_Purchase ID", res.data.purchaseId);
        url.searchParams.append("hide_Purchase ID", "true");

        setRedemptionURL(url.toString());
      }

      track(ANALYTICS_EVENTS.PRIZE_REDEEMED, {
        username: currentUser.username,
        purchaseId: res.data.purchaseId,
        itemId: item.id,
        prizeTitle: item.name,
        prizeCost: res.data.cost,
        prizeCategory: item?.tags?.join(","),
        ...(item.owner ? { ownerEmail: item.owner.email } : undefined),
        fulfillmentStatus: item.metadata?.successActionRequired
          ? "Redeemed"
          : "Ready",
      });

      if (hasQuantity) {
        refetch();
      }

      onOpen();
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (
          (err.message as String)?.includes(
            '"coin_trx" violates check constraint "non-negative balance"'
          )
        ) {
          toast({
            title: "Insufficient Balance",
            description: "You do not have enough coins to complete order",
            status: "error",
          });
        }

        if (err.response?.status === 412) {
          toast({
            title: "Item Sold Out",
            status: "error",
          });
        } else {
          toast({
            title: "Error",
            description: "Error completing order, please try again",
            status: "error",
          });

          captureException(err);
        }
      } else {
        toast({
          title: "Error",
          description: "Error completing order, please try again",
          status: "error",
        });

        captureException(err);
      }
    } finally {
      setRedeeming(false);
    }
  }

  if (!item && (stale || fetching)) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!item || item.disabled) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Grid
        maxWidth="60rem"
        mx="auto"
        columnGap={{ base: 0, lg: 10, xl: 20 }}
        rowGap={{ base: 6, md: 8 }}
        mt={10}
        templateColumns={{
          base: "1fr",
          lg: "auto 1fr",
        }}
      >
        <AspectRatio
          ratio={1}
          boxSize={{ base: "100%", md: "25rem" }}
          position="relative"
          bg="gray.50"
          borderRadius={16}
          overflow="hidden"
        >
          <Image src={getImgSrc(item)} objectFit="cover" />
        </AspectRatio>
        <Flex direction="column" align="start">
          <Text as="h1" fontSize="4xl" fontWeight="bold" color="gray.900">
            {item.name}
          </Text>
          <Box my={2}>
            {isSoldOut ? (
              <Text fontSize="xl" color="red.500">
                Currently Unavailable
              </Text>
            ) : (
              <CoinValue size="xl" value={item.cost || 0} color="gray.600" />
            )}
          </Box>
          {!!item.tags?.length && (
            <Text fontSize="lg" mt={4} color="gray.600">
              {getShopCategoryName(item.tags[0])}
            </Text>
          )}
          {item.description && (
            <Text fontSize="md" color="gray.900" mt={4}>
              {item.description}{" "}
              {item.externalURL && (
                <ExternalLink
                  href={item.externalURL}
                  display="inline-flex"
                  alignItems="center"
                  target="_blank"
                  fontWeight="bold"
                  color="brand"
                >
                  Additional details{" "}
                  <IconExternalLink
                    ml={1}
                    size="1.25rem"
                    display="inline-block"
                    aria-hidden="true"
                  />
                </ExternalLink>
              )}
            </Text>
          )}
          {!isSoldOut && (
            <HStack spacing={4} mt={10}>
              <ButtonSolid
                size="lg"
                onClick={handlePurchase}
                isLoading={isRedeeming}
                disabled={!isKidUser(currentUser) || isRedeeming}
                colorScheme="brandFull"
              >
                Get Now for
                <CoinValue ml={2} size="md" value={item.cost || 0} />
              </ButtonSolid>
              {!!item.quantity && item.quantity <= 10 && (
                <Text color="green.600" fontWeight="bold">
                  Only {item.quantity} left in stock!
                </Text>
              )}
            </HStack>
          )}
          {!isKidUser(currentUser) && (
            <Text mt={4} color="gray.600">
              Only Learners Can Redeem
            </Text>
          )}
        </Flex>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} closeOnEsc size="xl">
        <ModalOverlay />
        <ModalContent pb={10}>
          <Center mt={10}>
            <Box
              width={16}
              bg="green.500"
              height={16}
              borderRadius={99}
              overflow="hidden"
            >
              <Center height="full" width="full">
                <IconCheck width={10} height={10} color="white" />
              </Center>
            </Box>
          </Center>
          <ModalHeader pb={0}>Order Confirmed!</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={10} pb={0}>
            {!!item.metadata?.successText ? (
              <Markdown
                source={item.metadata?.successText || ""}
                preset="mini"
              />
            ) : (
              <Center width="full">
                <Text>
                  The Tract team will reach out when your item is ready.
                </Text>
              </Center>
            )}
          </ModalBody>
          {!!item.metadata?.successActionText &&
            !!item.metadata?.successActionURL && (
              <ModalFooter pt={4} pb={0}>
                <Center width="full">
                  <ButtonLink
                    as={ExternalLink}
                    href={redemptionURL || item.metadata.successActionURL}
                    target="_blank"
                    variant="solid"
                    colorScheme="brandFull"
                    size="lg"
                  >
                    {item.metadata?.successActionText}
                  </ButtonLink>
                </Center>
              </ModalFooter>
            )}
        </ModalContent>
      </Modal>
    </>
  );
}
