import React from "react";

import {
  Text,
  Flex,
  Avatar,
  Td,
  Tr,
  HStack,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  IconButton,
  IconMoreVertical,
  Portal,
  FontAwesomeIcon,
} from "Shared";

import { ClassroomLearnerGroupFragment } from "@tract/common/dist/graphql";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export type LearnerGroupPendingMember =
  ClassroomLearnerGroupFragment["pendingMembers"][0];

type LearnerPendingRowProps = {
  learner: LearnerGroupPendingMember;
  onDeleteModalOpen: (learner: LearnerGroupPendingMember) => void;
};

export const LearnerPendingRow: React.FC<LearnerPendingRowProps> = ({
  learner,
  onDeleteModalOpen,
  ...props
}) => {
  return (
    <Tr>
      <Td py={3} pr={4}>
        <Flex {...props}>
          <Avatar
            size="lg"
            mr={3}
            name={`${learner.firstName} ${learner.lastName}`}
          />
          <Flex flex="1" direction="column" justify="center" align="start">
            <Text fontSize="lg" color="gray.900" fontWeight="bold" mb={1}>
              {learner.firstName} {learner.lastName || ""}
            </Text>
            <Text fontSize="md" color="gray.600" fontWeight="bold">
              Pending
            </Text>
          </Flex>
        </Flex>
      </Td>
      <Td textAlign="center">0</Td>
      <Td textAlign="end">0</Td>
      <Td>
        <HStack width="100%" justify="flex-end">
          <Menu>
            <MenuButton
              as={IconButton}
              size="lg"
              aria-label="More"
              variant="ghost"
              icon={<IconMoreVertical />}
            />

            <Portal>
              <MenuList minWidth="16rem">
                <MenuItem
                  onClick={() => onDeleteModalOpen(learner)}
                  icon={<FontAwesomeIcon icon={regular("circle-minus")} />}
                >
                  Remove from Class
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </HStack>
      </Td>
    </Tr>
  );
};
