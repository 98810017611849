import { BuilderComponent, builder } from "@builder.io/react";
import { useEffect, useState } from "react";

export const BuilderPage = () => {
  const [builderContentJson, setBuilderContentJson] = useState<any | null>(
    null
  );

  useEffect(() => {
    builder
      .get("page", { url: window.location.pathname })
      .promise()
      .then(setBuilderContentJson);
  }, []);

  return <BuilderComponent model="page" content={builderContentJson} />;
};
