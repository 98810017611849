import React from "react";

import {
  Box,
  Button,
  DarkMode,
  Flex,
  HStack,
  Icon,
  IconXCircle,
  Text,
} from "Shared";

type Props = {
  onRetryCamera: () => void;
  onClose: () => void;
};

export const Permissions: React.FC<Props> = ({ onRetryCamera, onClose }) => {
  return (
    <DarkMode>
      <Flex flexDir="column" alignItems="center" maxW="500px" color="white">
        <Box mb={6}>
          <Icon as={IconXCircle} h="4rem" w="4rem" color="white" />
        </Box>
        <Box mb={1}>
          <Text fontSize="2xl" fontWeight="bold" textAlign="center">
            Camera is unavailable
          </Text>
        </Box>
        <Box mb={6}>
          <Text fontSize="md" textAlign="center">
            To create content, you need to allow camera and mic permissions in
            the popup. If you previously blocked them, select the camera icon in
            the address bar and unblock them.
          </Text>
        </Box>
        <HStack spacing={2} justifyContent="center">
          <Button size="lg" colorScheme="brandFull" onClick={onRetryCamera}>
            Retry Camera
          </Button>
          <Button size="lg" variant="solid" onClick={onClose}>
            Close
          </Button>
        </HStack>
      </Flex>
    </DarkMode>
  );
};
