import { forwardRef } from "@chakra-ui/react";

import { Flex, Button, FontAwesomeIcon } from "Shared";

import { useIntercom } from "Services/intercom";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const HelpButton = forwardRef((props, ref) => {
  const { isReady, show } = useIntercom();

  return (
    <Flex boxShadow="lg" borderRadius="full" {...props} hidden={!isReady}>
      <Button
        variant="outline"
        bg="white"
        size="lg"
        leftIcon={<FontAwesomeIcon icon={regular("comment")} />}
        ref={ref}
        onClick={show}
      >
        Feedback
      </Button>
    </Flex>
  );
});
