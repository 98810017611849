import { gql } from "@apollo/client";
import { PATHS_BY_STATUS_FRAGMENT } from "Pages/Author/graphql";
import { PROFILE_BADGE_USER_TYPE_FRAGMENT } from "Types/User";

export const PATH_ACTIVITY_FRAGMENT = gql`
  ${PROFILE_BADGE_USER_TYPE_FRAGMENT}

  fragment PathActivity on path_activity {
    id
    pathId
    userId
    pathReviewId
    path {
      id
      title
      coverFile {
        id
        filename
        transforms {
          filename
        }
      }
      user {
        id: firestoreId
        firestoreId
        avatar
        username
        creatorLevel
        country
      }
    }
    type
    user {
      id: firestoreId
      firstName
      lastName
      avatar
      username
      ...ProfileBadgeUserType
    }
    pathReview {
      id
      pathId
      type
      userId
      needsReview
      createdAt
      updatedAt
      comment {
        text
      }
    }
  }
`;

gql`
  ${PATH_ACTIVITY_FRAGMENT}

  mutation CreatePathActivityWithReview(
    $object: path_activity_insert_input!
    $pathId: bigint!
    $status: String!
    $isPublished: Boolean!
    $publishedAt: timestamptz
    $slug: String
    $authorId: String!
    $authors: [path_author_insert_input!]!
  ) {
    insert_path_activity_one(object: $object) {
      ...PathActivity
    }

    insert_path_author(
      objects: $authors
      on_conflict: {
        update_columns: []
        constraint: path_author_path_id_user_id_key
      }
    ) {
      returning {
        pathId
        userId
      }
    }

    update_path_by_pk(
      pk_columns: { id: $pathId }
      _set: {
        status: $status
        slug: $slug
        isPublished: $isPublished
        publishedAt: $publishedAt
        authorId: $authorId
      }
    ) {
      id
      status
      slug
      isPublished
      isTractOriginal
      authorId
      authors {
        pathId
        userId
      }
    }
  }
`;

export const PATH_REVIEW_FRAGMENT = gql`
  fragment PathReview on path_review {
    id
    pathId
    type
    userId
    needsReview
    createdAt
    updatedAt
    comment {
      text
    }
    user {
      id: firestoreId
      avatar
      userType
      creatorLevel
      isEducator
      username
      isMod
      firstName
      lastName
    }
  }
`;

gql`
  ${PATH_REVIEW_FRAGMENT}

  mutation CreatePathReview($object: path_review_insert_input!) {
    insert_path_review_one(object: $object) {
      ...PathReview
    }
  }
`;

gql`
  ${PATH_ACTIVITY_FRAGMENT}

  query GetPathActivityForUser(
    $offset: Int!
    $limit: Int!
    $userId: String!
    $creatorLevel: String_comparison_exp!
    $type: String_comparison_exp!
  ) {
    pathActivity: path_activity(
      offset: $offset
      limit: $limit
      order_by: { createdAt: desc_nulls_last }
      where: {
        userId: { _eq: $userId }
        path: {
          authorId: { _neq: $userId }
          user: { creatorLevel: $creatorLevel }
        }
        type: $type
      }
    ) {
      ...PathActivity
    }
  }
`;

export const REQUEST_REVIEW_FROM_REVIEWER = gql`
  mutation RequestReviewFromReviewer($pathReviewId: uuid!) {
    update_path_review_by_pk(
      pk_columns: { id: $pathReviewId }
      _set: { needsReview: true }
    ) {
      id
      pathId
      needsReview
      type
    }
  }
`;

gql`
  ${PATH_REVIEW_FRAGMENT}
  ${PATHS_BY_STATUS_FRAGMENT}

  query PathsWithReviews(
    $offset: Int!
    $limit: Int!
    $creatorLevel: String_comparison_exp!
    $status: String_comparison_exp!
    $userId: String!
    $type: String_comparison_exp!
    $orderBy: [path_review_order_by!]
    $reviewUserId: String_comparison_exp!
  ) {
    pathReviews: list_latest_path_reviews(
      offset: $offset
      limit: $limit
      where: {
        type: $type
        userId: $reviewUserId
        path: {
          status: $status
          _schemaVersion: { _eq: 3 }
          user: { creatorLevel: $creatorLevel, firestoreId: { _neq: $userId } }
        }
      }
      order_by: $orderBy
    ) {
      ...PathReview
      path {
        ...pathsByStatus
      }
    }
  }
`;
