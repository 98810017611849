import React, { forwardRef } from "react";
import copy from "copy-to-clipboard";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory, useLocation } from "react-router-dom";
import {
  ClassroomLearnerGroupFragment,
  Enums_Integration_Provider_Enum,
} from "@tract/common/dist/graphql";
import {
  AspectRatio,
  Box,
  Link,
  Text,
  Flex,
  FontAwesomeIcon,
  IconMoreVertical,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  useDisclosure,
  useToast,
  HStack,
} from "Shared";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import { PendingApprovaModal } from "Components/PendingApprovalModal";
import { IconGoogleClassroom } from "Shared/Icon/custom/IconGoogleClassroom";
import { AddStudentsModal } from "Pages/Classroom/AddStudentsModal";
import { getDisplayName } from "Types/User";
import { ArchiveClassroomModal } from "./ArchiveClassroomModal";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const ClassroomCard = forwardRef<HTMLDivElement, Props>(
  ({ classroom }, ref) => {
    const { isApprovedOrg, currentUser } = useSession();
    const isClassTeacher =
      classroom.members.findIndex(
        (member) =>
          member.role === "teacher" && member.userId === currentUser.id
      ) > -1;
    const owner = classroom.members.filter((user) => user.isOwner)?.[0]?.user;
    const ownerDisplayName = getDisplayName(owner);
    const { pathname } = useLocation();
    const { track } = useAnalytics();
    const toast = useToast();
    const history = useHistory();

    const {
      isOpen: isInviteStudentsModalOpen,
      onOpen: openInviteStudentsModal,
      onClose: closeInviteStudentsModal,
    } = useDisclosure();

    const {
      isOpen: isArchiveClassModalOpen,
      onOpen: openArchiveClassModal,
      onClose: closeArchiveClassModal,
    } = useDisclosure();

    const {
      isOpen: isPendingApprovalModalOpen,
      onOpen: openPendingApprovalModal,
      onClose: closePendingApprovalModal,
    } = useDisclosure();

    const classCode = classroom?.educatorCodes[0]?.code;
    const studentInviteLink = `${window.location.origin}/get-started/kid?code=${classCode}`;

    const onCopyStudentInviteLink = () => {
      if (classroom) {
        copy(studentInviteLink);
        toast({
          status: "success",
          title: "Link Copied!",
        });
        track(ANALYTICS_EVENTS.CLASS_CODE_COPIED, {
          classId: classroom.id,
          classTitle: classroom.name,
        });
      }
    };

    const onClickInviteStudents = () => {
      if (isApprovedOrg) {
        openInviteStudentsModal();
      } else {
        openPendingApprovalModal();
      }
    };

    const unfinishedAssignmentCount =
      classroom?.assignments?.filter((assignment) => !assignment.isFinished)
        .length || 0;

    return (
      <LinkBox
        border="1px solid"
        borderColor="gray.300"
        borderRadius="2xl"
        overflow="hidden"
        position="relative"
        _focusWithin={{ "& .showFocused": { opacity: 1 } }}
        transition="150ms ease box-shadow"
        _hover={{ boxShadow: "md", "& .showFocused": { opacity: 1 } }}
        ref={ref}
      >
        {isClassTeacher && (
          <Box
            className="showFocused"
            position="absolute"
            opacity={0}
            top={3}
            right={3}
            zIndex={1}
          >
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Classroom actions"
                icon={<IconMoreVertical />}
                variant="ghost"
                colorScheme="whiteAlpha"
                size="md"
                color="white"
              />
              <Portal>
                <MenuList width={64}>
                  <MenuItem
                    onClick={onClickInviteStudents}
                    icon={<FontAwesomeIcon icon={regular("user-plus")} />}
                  >
                    Add Students
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      history.push(`/class/${classroom.id}/settings`)
                    }
                    icon={<FontAwesomeIcon icon={regular("gear")} />}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    onClick={openArchiveClassModal}
                    icon={<FontAwesomeIcon icon={regular("box-archive")} />}
                  >
                    Archive Class
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Box>
        )}
        <AspectRatio ratio={5 / 2}>
          <Box bg={classroom?.color || "gray.100"} />
        </AspectRatio>
        <Box px={6} py={5}>
          <HStack mb={2} alignItems="flex-start">
            {classroom.source === Enums_Integration_Provider_Enum.Google && (
              <IconGoogleClassroom />
            )}
            <LinkOverlay
              as={Link}
              _hover={{ textDecoration: "none" }}
              to={{
                pathname: `/class/${classroom.id}`,
                state: { back: pathname },
              }}
              fontSize="lg"
              fontWeight="bold"
              noOfLines={1}
            >
              {classroom.name}
            </LinkOverlay>
          </HStack>
          <Flex direction="row" w="full" align="center" overflow="hidden">
            <UserAvatar
              userId={owner.id}
              size="sm"
              avatarURL={owner?.avatar || ""}
              isTeacher={true}
              name={ownerDisplayName}
              mr={2}
            />
            <Box overflow="hidden" flex={1}>
              <UsernameLink
                userId={owner.id}
                flex={1}
                fontSize="md"
                color="gray.600"
                isTeacher={true}
              >
                {ownerDisplayName}
              </UsernameLink>
            </Box>
            <Flex direction="row" color="gray.600" align="center" ml={2}>
              <FontAwesomeIcon icon={solid("users")} size={5} />
              <Text ml={2} mr={4}>
                {
                  classroom.members.filter((user) => user.role === "learner")
                    .length
                }
              </Text>
              <FontAwesomeIcon icon={solid("inbox")} size={5} />
              <Text ml={2}>{unfinishedAssignmentCount}</Text>
            </Flex>
          </Flex>
        </Box>
        <AddStudentsModal
          learnerGroup={classroom}
          codeLink={studentInviteLink}
          onCopyLink={onCopyStudentInviteLink}
          isOpen={isInviteStudentsModalOpen}
          onClose={closeInviteStudentsModal}
        />
        {!isApprovedOrg && (
          <PendingApprovaModal
            isOpen={isPendingApprovalModalOpen}
            onClose={closePendingApprovalModal}
          />
        )}
        <ArchiveClassroomModal
          isOpen={isArchiveClassModalOpen}
          onClose={closeArchiveClassModal}
          learnerGroupId={classroom.id}
          learnerGroupName={classroom.name || undefined}
        />
      </LinkBox>
    );
  }
);
