import { ModalProps } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSession } from "Services/session";

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "Shared";
import { PrivacyFormValues } from ".";

type Props = {
  privacySettings?: PrivacyFormValues;
  onConfirm: () => void;
  loading: boolean;
} & Omit<ModalProps, "children">;

export const ConfirmModal: React.FC<Props> = ({
  privacySettings,
  isOpen,
  loading,
  onClose,
  onConfirm,
}) => {
  const { currentUser } = useSession();
  const [canConfirm, setCanConfirm] = useState(false);
  const isPublic = privacySettings?.profilePrivacy === "public";

  useEffect(() => {
    if (isOpen) setCanConfirm(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="600px">
        <ModalCloseButton />
        <ModalHeader>
          {isPublic
            ? "Switch to public account?"
            : "Switch to private account?"}
        </ModalHeader>
        <ModalBody>
          <Box mb={4}>
            <Text>
              {isPublic
                ? "Anyone will be able to find and follow your profile."
                : `Your profile will no longer be visible to all members, and nobody will be able to follow you.`}
            </Text>
          </Box>
          <FormControl isRequired>
            <FormLabel>Enter your username to confirm</FormLabel>
            <Input
              onChange={(e) => {
                setCanConfirm(
                  e.target.value.toLowerCase() ===
                    currentUser.username?.toLowerCase()
                );
              }}
              placeholder="Enter username"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button variant="outline" onClick={onClose} size="lg">
              No, Cancel
            </Button>
            <Button
              colorScheme="brandFull"
              onClick={onConfirm}
              disabled={!canConfirm || loading}
              isLoading={loading}
              size="lg"
            >
              Switch to {isPublic ? "Public" : "Private"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
