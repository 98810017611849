import { Redirect } from "react-router";
import { FC } from "react";
import copy from "copy-to-clipboard";
import { useQuery } from "urql";
import {
  GetUserInvitesQuery,
  GetUserInvitesQueryVariables,
} from "@tract/common/dist/graphql";
import {
  Box,
  ButtonSolid,
  Flex,
  HStack,
  Text,
  VStack,
  Image,
  Center,
  Spinner,
  ButtonLink,
  useToast,
} from "Shared";
import { GET_KID_INVITES } from "./graphql";
import { useSession } from "Services/session";
import { pluralize } from "Utils";
import { useIntercom } from "Services/intercom";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useFeature } from "Services/features";
import { BoxProps } from "@chakra-ui/layout";

interface KidInvitesContentProps {
  title: string;
  description: string;
  titleSize?: string;
  descriptionSize?: string;
}

export const KidInvitesContent: FC<KidInvitesContentProps & BoxProps> = ({
  title,
  description,
  titleSize = "4xl",
  descriptionSize = "xl",
  ...props
}) => {
  const areKidInvitesEnabled = useFeature("kid-invites");
  const toast = useToast();
  const { currentUser } = useSession();
  const { track } = useAnalytics();
  const { show: showIntercom } = useIntercom();
  const [{ data, fetching }] = useQuery<
    GetUserInvitesQuery,
    GetUserInvitesQueryVariables
  >({
    query: GET_KID_INVITES,
    variables: { userId: currentUser.id },
    pause: !areKidInvitesEnabled,
  });

  const codes = data?.educator_code;
  const onClickCopy = (kidCode: GetUserInvitesQuery["educator_code"][0]) => {
    copy(`${window.location.origin}/get-started/kid?code=${kidCode.code}`);
    toast({
      title: "Link Copied!",
      status: "success",
    });

    track(ANALYTICS_EVENTS.INVITE_CODE_COPIED, {
      inviteSeatsRemaining: kidCode.available,
    });
  };

  const availableCodes = codes?.filter((kidCode) => !!kidCode.available);

  if (!areKidInvitesEnabled) return <Redirect to="/" />;

  return (
    <Box {...props}>
      <VStack>
        <Image
          width="96px"
          src="https://firebasestorage.googleapis.com/v0/b/tract-staging-media/o/kid-invite.png?alt=media"
        />
        <Text fontWeight="bold" fontSize={titleSize}>
          {title}
        </Text>
        <Text fontSize={descriptionSize}>{description}</Text>
      </VStack>
      {fetching && (
        <Center mt={10}>
          <Spinner
            size="lg"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brandFull.500"
          />
        </Center>
      )}
      {!fetching && codes?.length === 0 ? (
        <Text mt={10} color="gray.600">
          You don't have any invites
        </Text>
      ) : null}
      {codes && codes.length > 0 && (
        <Box mt={10} spacing={3}>
          {availableCodes?.map((kidCode) => (
            <Flex
              px={6}
              py={4}
              mt={3}
              key={kidCode.code}
              mx="auto"
              maxW="555px"
              boxShadow="md"
              borderRadius="2xl"
              border="1px solid"
              alignItems="center"
              justifyContent="center"
              borderColor="gray.300"
              flexDir={{ base: "column", md: "row" }}
            >
              <HStack width="100%" flex={1} spacing={2} alignItems="stretch">
                {kidCode.code.split("").map((letter, i) => (
                  <Flex
                    key={i}
                    flex={1}
                    height={10}
                    fontSize="lg"
                    fontFamily="mono"
                    fontWeight="bold"
                    borderRadius="lg"
                    bgColor="gray.100"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {letter}
                  </Flex>
                ))}
              </HStack>
              <Flex
                width={{ base: "full", md: "unset" }}
                alignItems="center"
                justifyContent="space-between"
                mt={{ base: 4, md: 0 }}
              >
                <Text
                  pl={{ base: 0, md: 6 }}
                  fontWeight="bold"
                  color="green.600"
                >
                  {pluralize(kidCode.available, "invite")} remaining
                </Text>
                <Box pl={{ base: 0, md: 6 }}>
                  <ButtonSolid size="lg" onClick={() => onClickCopy(kidCode)}>
                    Copy Link
                  </ButtonSolid>
                </Box>
              </Flex>
            </Flex>
          ))}
        </Box>
      )}
      <Text mt={10}>
        Need {availableCodes?.length ? "more" : ""} invites?{" "}
        <ButtonLink colorScheme="brandFull" onClick={showIntercom}>
          Contact Us
        </ButtonLink>
      </Text>
    </Box>
  );
};
