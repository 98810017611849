import React, { FC } from "react";
import { FlexProps } from "@chakra-ui/react";

import { Box, ButtonOutlined, ButtonSolid, Flex } from "Shared";

import { useSession } from "Services/session";
import { useFollows } from "Services/social";

import { isParentUser } from "Types/User";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";

import { UserSocialFragment } from "@tract/common/dist/graphql";

type Props = {
  user: UserSocialFragment;
};

export const UserListRow: FC<Props & FlexProps> = ({ user, ...props }) => {
  const { currentUser } = useSession();
  const { isFollowing, follow, unfollow, loading } = useFollows(user, true);

  const isMyProfile = user.id === currentUser.uid;
  const isFollowed = !!user.followers.length || isFollowing;

  return (
    <Flex direction="row" align="center" {...props}>
      <UserAvatar
        userId={user.id}
        username={user.username || ""}
        avatarURL={user.avatar || ""}
        display={["none", null, "block"]}
        size="md"
        mr={3}
      />
      <Box flex="1">
        <UsernameLink
          username={user.username || ""}
          fontSize="md"
          color="gray.900"
          fontWeight="bold"
        >
          {user.username}
        </UsernameLink>
      </Box>

      {isMyProfile || isParentUser(user) ? null : isFollowed ? (
        <ButtonOutlined onClick={() => unfollow()} isLoading={loading}>
          Unfollow
        </ButtonOutlined>
      ) : (
        <ButtonSolid onClick={() => follow()} isLoading={loading}>
          Follow
        </ButtonSolid>
      )}
    </Flex>
  );
};
