import { useState } from "react";
import { v4 as uuid } from "uuid";

import { captureException } from "./errors";
import { storage } from "./firebase";

const ugcStorage = storage("ugc");

type FileConfig = {
  path: string;
};

export function useFileDelete(config: FileConfig) {
  const storageRef = ugcStorage.ref(config.path);

  const deleteFile = async (filename: string) => {
    try {
      await storageRef.child(filename).delete();
    } catch (error: any) {
      captureException(error);
    }
  };

  return { deleteFile };
}

export type UserFileResult = {
  fileId: string;
  filename: string;
  fileUrl: string;
  metadata: Record<string, any>;
};

export function useUserFileUpload(config?: { path?: string }) {
  const [result, setResult] = useState<UserFileResult | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<Error | null>(null);

  const fileId = uuid();
  const refPath = config?.path || `uf/${fileId}`;
  const storageRef = ugcStorage.ref(refPath);

  const handleUploadStart = () => setIsUploading(true);

  const handleUploadError = (error: Error) => {
    setError(error);
    setIsUploading(false);
    captureException(error);
  };

  const handleProgress = (progress: number) => setProgress(progress);

  const handleUploadSuccess = async (filename: string) => {
    const fileId = refPath.split("/").pop() as string;

    const fileUrl = await storageRef.child(filename).getDownloadURL();
    const metadata = await storageRef.child(filename).getMetadata();

    setProgress(100);
    setIsUploading(false);

    const result = {
      filename,
      fileId,
      fileUrl,
      metadata,
    };

    setResult(result);
    setError(null);
    return result;
  };

  const reset = () => {
    setResult(null);
    setIsUploading(false);
    setProgress(0);
  };

  return {
    result,
    isUploading,
    progress,
    storageRef,
    error,
    handleUploadStart,
    handleUploadError,
    handleProgress,
    handleUploadSuccess,
    reset,
  };
}
