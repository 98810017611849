import React, { FC } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Button,
  ButtonSolid,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "Shared";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const ForgotPasswordSchema = Yup.object().shape({
  usernameOrEmail: Yup.string().required("Enter email or username"),
});

export type FormValues = {
  usernameOrEmail: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  isLoading: boolean;
};

export const ForgotPasswordForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const history = useHistory();
  return (
    <>
      <Text as="h1" fontSize="2xl" fontWeight="bold" color="gray.900" mb={4}>
        Forgot Password?
      </Text>
      <Text fontSize="lg" color="gray.600" mb={6}>
        No problem! Enter your email or username and we'll send password reset
        instructions.
      </Text>
      <Formik
        initialValues={{
          usernameOrEmail: "",
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={onSubmit}
      >
        {(form) => (
          <Form onSubmit={form.handleSubmit}>
            <Field name="usernameOrEmail">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={8}
                >
                  <FormLabel>Email or Username</FormLabel>
                  <Input
                    {...field}
                    variant="filled"
                    size="lg"
                    type="text"
                    placeholder="Email or Username"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <ButtonSolid
              size="lg"
              width="100%"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Reset Password
            </ButtonSolid>
            <Button
              size="lg"
              variant="ghost"
              colorScheme="brandFull"
              width="100%"
              disabled={isLoading}
              mt={3}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
