import { FC } from "react";

import { Center, EmptyState, Icon, IconLock } from "Shared";

import { PageApp } from "Components/PageApp";

export const PrivateAccount: FC = () => {
  return (
    <PageApp py={24}>
      <Center>
        <Icon as={IconLock} w={10} h={10} color="gray.400" />
      </Center>
      <EmptyState
        headline="Account Private"
        body="Sorry, this account has set their profile to private."
      ></EmptyState>
    </PageApp>
  );
};
