import React, { FC } from "react";

import { Accordion, AccordionItem, ExternalLink, Link } from "Shared";

export const FaqContent: FC = () => {
  return (
    <Accordion allowToggle={true} defaultIndex={0}>
      <AccordionItem heading="How can I be sure the content on Tract is age appropriate?">
        Along with our promise to remain 100% ad-free, we’re committed to
        empowering our creators with the resources and support they need to
        learn and grow. This means that while courses are inspired by their
        ideas, everything they produce is vetted by Tract’s Chief Learning
        Officer,{" "}
        <Link to="/company/about" color="brand" fontWeight="bold">
          Esther Wojcicki
        </Link>
        , so information is not only engaging and kid-centric, it’s accurate,
        too.
      </AccordionItem>
      <AccordionItem heading="Who are Tract Creators?">
        Tract content is co-created by kids and leading influencers and
        changemakers who are not only experts in their field, but also in how to
        share their knowledge in an engaging and exciting way so kids will sit
        up and listen.
      </AccordionItem>
      <AccordionItem heading="What do kids create on Tract?">
        On Tract, kids create real-world projects in the form of video or
        multimedia to share with their classmates and community.{" "}
        <Link color="brand" fontWeight="bold" to="/explore">
          Explore
        </Link>{" "}
        our growing library of curriculum-aligned, on-demand projects.
      </AccordionItem>
      <AccordionItem heading="Does Tract content align with state learning standards?">
        All of Tract Originals are tied to U.S. academic standards. In
        particular, we follow{" "}
        <ExternalLink
          color="brand"
          href="http://www.corestandards.org/read-the-standards"
          fontWeight="bold"
          target="_blank"
        >
          Common Core State Standards
        </ExternalLink>{" "}
        (CCSS) for math and English language arts,{" "}
        <ExternalLink
          color="brand"
          href="https://www.nextgenscience.org"
          fontWeight="bold"
          target="_blank"
        >
          Next Generation Science Standards
        </ExternalLink>{" "}
        (NGSS) for science, and the{" "}
        <ExternalLink
          color="brand"
          href="https://www.socialstudies.org/standards/c3"
          fontWeight="bold"
          target="_blank"
        >
          National Council for Social Studies
        </ExternalLink>{" "}
        (NCSS) “C3” framework (College, Career, and Civic Life) for social
        studies.
      </AccordionItem>
    </Accordion>
  );
};
