import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminDashboardClassroomsByTeacherIdQueryResult,
  AdminDashboardClassroomsByTeacherIdQueryVariables,
  AdminDashboardCreateClassroomMutationResult,
  AdminDashboardCreateClassroomMutationVariables,
  AdminDashboardLearnerGroupFragment,
  ClassroomLearnerGroupFragment,
  ClassroomsByTeacherIdQuery,
  ClassroomsByTeacherIdQueryVariables,
  CreateClassroomMutationVariables,
  CurrentUserFragment,
  LearnerGroupSavesForPathQuery,
  LearnerGroupSavesForPathQueryVariables,
} from "@tract/common/dist/graphql";

import { CURRENT_USER_FRAGMENT } from "graphql/users";

import { LEARNER_GROUP_SAVES_FOR_PATH } from "Components/ClassroomSavedPaths/SaveModal";

import { ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/graphql";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";

export const api_create_classroom: UpdateResolver<
  { createClassroom: { learnerGroup: ClassroomLearnerGroupFragment } } & {
    adminCreateClassroom: AdminDashboardLearnerGroupFragment;
  }
> = (result, args, cache, info) => {
  const classroom = result.createClassroom?.learnerGroup;
  if (classroom) {
    const variables = info.variables as CreateClassroomMutationVariables;

    const user = cache.readFragment<CurrentUserFragment>(
      CURRENT_USER_FRAGMENT,
      { __typename: "user", id: variables.userId }
    );

    if (user && classroom) {
      const member: CurrentUserFragment["learnerGroups"] = [
        {
          __typename: "learner_group_member",
          isOwner: true,
          userId: user.id,
          learnerGroupId: classroom.id,
          role: "Teacher",
          learnerGroup: classroom,
        },
      ];

      // Add new classroom to current user data
      cache.writeFragment<CurrentUserFragment>(CURRENT_USER_FRAGMENT, {
        ...user,
        learnerGroups: user.learnerGroups
          ? [...member, ...user.learnerGroups]
          : [...member],
      });

      cache.updateQuery<
        ClassroomsByTeacherIdQuery,
        ClassroomsByTeacherIdQueryVariables
      >(
        {
          query: CLASSROOMS_BY_TEACHER_ID_QUERY,
          variables: {
            teacherUserId: variables.userId,
          },
        },
        (data) => {
          data?.learnerGroups.unshift({
            ...classroom,
          });
          return data;
        }
      );

      // Update the query that populates the checkboxes in the save modal
      if (variables.pathId) {
        const learnerGroup = result.createClassroom.learnerGroup;
        const pathCollection =
          learnerGroup.learner_group_path_collections[0].pathCollection;
        const item = pathCollection.pathCollectionItems[0];

        cache.updateQuery<
          LearnerGroupSavesForPathQuery,
          LearnerGroupSavesForPathQueryVariables
        >(
          {
            query: LEARNER_GROUP_SAVES_FOR_PATH,
            variables: { pathId: variables.pathId },
          },
          (data) => {
            if (result && data) {
              if (item)
                data.path_collection_item.push({
                  ...item,
                  pathCollection: {
                    id: item.collectionId,
                    __typename: "path_collection",
                    learnerGroupPathCollection: [
                      {
                        learnerGroupId: learnerGroup.id,
                        pathCollectionId: item.collectionId,
                        __typename: "learner_group_path_collection",
                      },
                    ],
                  },
                });
            }

            return data;
          }
        );
      }
    }
  }

  if (result.adminCreateClassroom) {
    const variables =
      info.variables as AdminDashboardCreateClassroomMutationVariables;

    cache.updateQuery<
      AdminDashboardClassroomsByTeacherIdQueryResult["data"],
      AdminDashboardClassroomsByTeacherIdQueryVariables
    >(
      {
        query: ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY,
        variables: {
          teacherUserId: variables.userId,
        },
      },
      (data) => {
        const item = (
          result as AdminDashboardCreateClassroomMutationResult["data"]
        )?.adminCreateClassroom;

        if (item) {
          data?.learnerGroups.unshift({
            ...item,
          });
        }

        return data;
      }
    );
  }
};
