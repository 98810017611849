import React, { FC, useState } from "react";

import { Text, ButtonOutlined, useToast } from "Shared";

import { PageHeader } from "Components/PageHeader";
import { PageContent } from "Components/PageContent";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { captureException } from "Services/errors";
import { useMutation, useQuery } from "urql";
import {
  CreateLivestreamMutation,
  CreateLivestreamMutationVariables,
  LivestreamByPkQuery,
  LivestreamByPkQueryVariables,
  UpdateLivestreamMutation,
  UpdateLivestreamMutationVariables,
} from "@tract/common/dist/graphql";
import {
  CREATE_LIVESTREAM,
  LIVESTREAM_BY_PK_QUERY,
  UPDATE_LIVESTREAM,
} from "../graphql";

import { db } from "Services/firebase";
import { LivestreamForm, LivestreamFormValues } from "./LivestreamForm";
import { LayoutCentered } from "Components/LayoutCentered";

type Props = {};

export const LivestreamBuilder: FC<Props> = () => {
  const history = useHistory();
  const toast = useToast();
  const isBuilder = !!useRouteMatch("/admin/livestreams/create");
  const [loading, setLoading] = useState(false);
  const { livestreamId }: { livestreamId: string } = useParams();

  const [{ data, fetching }] = useQuery<
    LivestreamByPkQuery,
    LivestreamByPkQueryVariables
  >({
    pause: !livestreamId || isBuilder,
    requestPolicy: "cache-and-network",
    query: LIVESTREAM_BY_PK_QUERY,
    variables: {
      id: livestreamId,
    },
  });

  const [, updateMutation] = useMutation<
    UpdateLivestreamMutation,
    UpdateLivestreamMutationVariables
  >(UPDATE_LIVESTREAM);

  const livestreamLoaded = data?.livestream || undefined;

  async function handleSubmitEdit(
    livestream: LivestreamFormValues,
    coverFileId?: string
  ) {
    if (!livestreamLoaded?.id) return;

    try {
      setLoading(true);
      const mutationResult = await updateMutation({
        id: livestreamLoaded?.id,
        object: {
          title: livestream?.title || "",
          hostUserId: livestream?.hostUserId,
          description: livestream?.description || "",
          disabled: !livestream?.visible,
          startDate:
            !!livestream?.startDate &&
            new Date(livestream?.startDate).toISOString(),
          endDate:
            !!livestream?.startDate &&
            !!livestream?.duration &&
            new Date(
              new Date(livestream?.startDate).getTime() +
                livestream.duration * 60 * 1000
            ).toISOString(),
          coverFileId: coverFileId || null,
        },
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.message);
      } else {
        toast({
          status: "success",
          title: `Livestream updated successfully!`,
        });
        history.push("/admin/livestreams");
      }
    } catch (e: any) {
      captureException(e);
      toast({
        status: "error",
        title: "Oops!",
        description:
          "There was an error updating the livestream, please try again.",
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  }

  const [, createMutation] = useMutation<
    CreateLivestreamMutation,
    CreateLivestreamMutationVariables
  >(CREATE_LIVESTREAM);

  async function handleSubmitCreate(
    livestream: LivestreamFormValues,
    coverFileId?: string
  ) {
    try {
      setLoading(true);
      const mutationResult = await createMutation({
        object: {
          title: livestream.title,
          description: livestream.description || "",
          hostUserId: livestream.hostUserId,
          disabled: !livestream.visible,
          startDate:
            !!livestream?.startDate &&
            new Date(livestream?.startDate).toISOString(),
          endDate:
            !!livestream?.startDate &&
            !!livestream?.duration &&
            new Date(
              new Date(livestream?.startDate).getTime() +
                livestream.duration * 60 * 1000
            ).toISOString(),
          coverFileId: coverFileId || null,
        },
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.message);
      } else {
        const livestreamRef = db.doc(
          `livestreams/${mutationResult.data?.livestream?.id}`
        );
        await db.runTransaction(async (trx) => {
          trx.set(livestreamRef, {
            isLive: false,
          });
        });
        toast({
          status: "success",
          title: `Livestream created successfully!`,
        });
        history.push("/admin/livestreams");
      }
    } catch (e: any) {
      captureException(e);
      toast({
        status: "error",
        title: "Oops!",
        description:
          "There was an error creating the livestream, please try again.",
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  }

  if (fetching && !isBuilder) {
    return <LayoutCentered isLoading height="auto" />;
  }
  if (!livestreamLoaded && !isBuilder) {
    return <Redirect to="/" />;
  }
  return (
    <PageContent maxW={{ lg: "50rem" }}>
      <PageHeader
        position="sticky"
        top={0}
        bg="white"
        zIndex={1}
        renderTitle={
          <Text
            fontSize={{ base: "xl", lg: "2xl" }}
            fontWeight="bold"
            mr={10}
            noOfLines={1}
          >
            {isBuilder ? "New Livestream" : "Edit Livestream"}
          </Text>
        }
        renderActions={
          <ButtonOutlined onClick={history.goBack} disabled={loading} size="lg">
            Cancel
          </ButtonOutlined>
        }
      />
      <LivestreamForm
        onSubmit={isBuilder ? handleSubmitCreate : handleSubmitEdit}
        submitLoading={loading}
        livestream={isBuilder ? undefined : livestreamLoaded}
      />
    </PageContent>
  );
};
