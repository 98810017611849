import React, { useEffect, useState } from "react";
import { addMinutes, intervalToDuration } from "date-fns";

import { Center, Text } from "Shared";

import { useInterval } from "@tract/common/dist/hooks";

type Props = {
  isActive: boolean;
  minutes: number;
  startTime: Date;
  onExpired: () => void;
};

export const Timer: React.FC<Props> = ({
  isActive,
  minutes,
  startTime,
  onExpired,
}) => {
  const [duration, setDuration] = useState<Duration>();
  const [expired, setExpired] = useState(false);

  useInterval(
    () => {
      const start = addMinutes(startTime, minutes);
      const end = new Date();

      if (start.getTime() - end.getTime() <= 0) {
        setExpired(true);
        onExpired();
        return;
      }

      setDuration(intervalToDuration({ start, end }));
    },
    isActive && !expired ? 250 : null
  );

  useEffect(() => {
    if (!isActive) {
      setDuration(undefined);
    }
  }, [isActive]);

  if (!isActive || !duration) return null;

  return (
    <Center bg="rgba(0, 0, 0, 0.8)" borderRadius="full" px={4}>
      <Text fontSize="md" fontWeight="bold" color="white">
        {`${duration.minutes}:${String(duration.seconds).padStart(2, "0")}`}
      </Text>
    </Center>
  );
};
