import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  LearnerGroupSavesForPathQuery,
  LearnerGroupSavesForPathQueryVariables,
  CreateLearnerGroupPathCollectionMutationResult,
} from "@tract/common/dist/graphql";

import { LEARNER_GROUP_SAVES_FOR_PATH } from "Components/ClassroomSavedPaths/SaveModal";

export const insert_learner_group_path_collection_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const typedResult =
    result as CreateLearnerGroupPathCollectionMutationResult["data"];

  if (!typedResult?.insert_learner_group_path_collection_one) return;

  const { pathCollection } =
    typedResult.insert_learner_group_path_collection_one;
  const pathId = pathCollection?.pathCollectionItems[0].pathId;

  // Update the query that populates the checkboxes in the save modal
  cache.updateQuery<
    LearnerGroupSavesForPathQuery,
    LearnerGroupSavesForPathQueryVariables
  >(
    {
      query: LEARNER_GROUP_SAVES_FOR_PATH,
      variables: { pathId },
    },
    (data) => {
      if (result && data) {
        const item =
          typedResult.insert_learner_group_path_collection_one?.pathCollection
            .pathCollectionItems[0];
        if (item) data.path_collection_item.push(item);
      }

      return data;
    }
  );
};
