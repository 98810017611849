import React, { PropsWithChildren } from "react";

import { Box, Flex, useDisclosure } from "Shared";

import { TopNavApp } from "Components/TopNav";
import { MobileMenuProvider, MobileNav } from "Components/MobileNav";

import { LayoutAppSideNav, LayoutAppSideNavProvider } from "./LayoutAppSidenav";

type Props = {};

export const LayoutApp: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const mobileMenu = useDisclosure();
  const layoutAppSidenav = useDisclosure({ defaultIsOpen: true });

  return (
    <MobileMenuProvider value={mobileMenu}>
      <TopNavApp />
      <MobileNav display={mobileMenu.isOpen ? "block" : "none"} />
      <Box
        display={mobileMenu.isOpen ? "none" : "block"}
        overflow={{ base: "hidden", lg: "initial" }}
      >
        <LayoutAppSideNavProvider value={layoutAppSidenav}>
          {children}
        </LayoutAppSideNavProvider>
      </Box>
    </MobileMenuProvider>
  );
};

export const LayoutHome: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
      overflow={{ base: "hidden", lg: "initial" }}
    >
      <LayoutAppSideNav />
      <Box
        id="main"
        as="main"
        overflow={{ base: "hidden", lg: "initial" }}
        w="full"
      >
        {children}
      </Box>
    </Flex>
  );
};
