import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { useRef, useEffect, useState, useCallback } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import * as yup from "yup";
import { gql, useMutation, useQuery } from "urql";

import {
  ButtonLink,
  ButtonOutlined,
  ButtonSolid,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Box,
  FormHelperText,
  Text,
  Spinner,
  FormErrorMessage,
  useToast,
  Alert,
  IconAlertTriangle,
  Flex,
  FileUploader,
  Switch,
} from "Shared";

import { PageHeader } from "Components/PageHeader";
import { LayoutCentered } from "Components/LayoutCentered";
import { OrgAvatar } from "Components/OrgAvatar";
import { SchoolSearchInput } from "Pages/Onboarding/Educators/SchoolSearchInput";

import {
  GradeLevel,
  OrganizationTypes,
} from "@tract/common/dist/types/models/Organization";
import {
  AdminOrgByIdQuery,
  AdminOrgByIdQueryVariables,
} from "@tract/common/dist/graphql";
import { ADMIN_ORG_QUERY } from "./graphql";

import { PlaceResult } from "@tract/common/dist/utils/parse-google-address";

import { captureException } from "Services/errors";
import {
  useAddress,
  useGooglePlaceDetails,
} from "Services/hooks/useGooglePlaces";
import { gradeLevelList } from "Services/organization";
import { useQuery as useQueryParams } from "Utils";

import { useUserFileUpload } from "Services/useUserFileUpload";
import { db } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type FormProps = {
  avatar: string;
  type: OrganizationTypes;
  name: string;
  gradeLevel: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  zip: string;
  country: string;
  emailDomains: string;
  shopEnabled: boolean;
};

const saveOrgSchema = yup.object().shape({
  type: yup.string().required("Please select organization type"),
  name: yup.string().required("Please provide a valid name"),
  emailDomains: yup.string().required("Please provide at least one domain"),
  addressLine1: yup.string().optional(),
  addressLine2: yup.string().optional(),
  city: yup.string().optional(),
  region: yup.string().optional(),
  zip: yup.string().optional(),
  country: yup.string().optional(),
  avatar: yup.string().optional(),
  shopEnabled: yup.boolean().optional(),
});

export const CreateOrganization = () => {
  const query = useQueryParams();
  const referrer = query.get("referrer") as "teachers";
  const toast = useToast();
  const history = useHistory();
  const { trackForUser } = useAnalytics();
  const { orgId } = useParams<{ orgId?: string }>();
  const isEditing = !!orgId;
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedOrganization, setSelectedOrganization] =
    useState<PlaceResult | null>(null);
  const [type, setType] = useState<OrganizationTypes>(OrganizationTypes.School);
  const [showAddress, setShowAddress] = useState(false);
  const formRef = useRef<FormikProps<FormProps>>(null);
  const formRefAttached = !!formRef.current;
  const [, saveOrg] = useMutation(gql`
    mutation SaveOrg($org: api_upsert_organization_data_input!) {
      api_upsert_organization(data: $org) {
        orgId
      }
    }
  `);
  const [{ data, fetching: loadingOrg }] = useQuery<
    AdminOrgByIdQuery,
    AdminOrgByIdQueryVariables
  >({
    query: ADMIN_ORG_QUERY,
    variables: { orgId: orgId || "" },
    requestPolicy: "network-only",
  });
  const orgData = data?.organization_by_pk;
  const org = {
    ...orgData,
    emailDomains: orgData?.emailDomains.map((d) => d.domain),
  };

  const [selectedOrgDetails, detailsLoading] = useGooglePlaceDetails(
    selectedOrganization?.place_id || null
  );

  const isSchoolInputDisabled = isEditing && !!org?.placeId;

  useEffect(() => {
    setSelectedOrganization((selectedOrg) =>
      selectedOrg && selectedOrgDetails
        ? {
            ...selectedOrg,
            ...selectedOrgDetails,
          }
        : null
    );
  }, [selectedOrgDetails]);

  const addressDetails = useAddress(
    !isEditing || (org && !org.placeId) ? selectedOrganization : null
  );

  useDeepCompareEffect(() => {
    // We don't allow editing name/address if org is already
    // tied with google places (and has placeId)
    if (!selectedOrganization) return;

    formRef.current?.setFieldValue(
      "addressLine1",
      addressDetails.addressLine1 || ""
    );
    formRef.current?.setFieldValue(
      "addressLine2",
      addressDetails.addressLine2 || ""
    );
    formRef.current?.setFieldValue("city", addressDetails.city || "");
    formRef.current?.setFieldValue("region", addressDetails.region || "");
    formRef.current?.setFieldValue("zip", addressDetails.zip || "");
    formRef.current?.setFieldValue("country", addressDetails.country || "");
  }, [addressDetails, formRefAttached]);

  const onSubmit = async (values: FormProps) => {
    if (isEditing && !org) return;

    if (!isEditing || !org?.placeId) {
      if (!selectedOrganization) {
        formRef.current?.setFieldError(
          "name",
          "Please select a valid organization"
        );
        return;
      } else if (!selectedOrganization.address_components) {
        formRef.current?.setFieldError(
          "name",
          "Address wasn't fetched properly. Please try again."
        );
        return;
      }
    }
    const newOrgId = db.collection("organization-signups").doc().id;

    const organizationData = {
      avatar: values.avatar,
      type: values.type,
      gradeLevel: values.gradeLevel,
      orgId: org?.id || newOrgId,
      placeId: selectedOrganization?.place_id || org?.placeId || "",
      emailDomains: values.emailDomains
        ? values.emailDomains.split(",").map((domain) => domain.trim())
        : [],
      shopEnabled: values.shopEnabled,
    };

    setSubmitting(true);
    try {
      if (!org.verified && org.users) {
        await Promise.all(
          org.users.map((user) =>
            trackForUser(user.id, ANALYTICS_EVENTS.EDUCATOR_SIGN_UP_APPROVED, {
              isVerifiedOrg: true,
            })
          )
        );
      }

      await saveOrg({
        org: organizationData,
      });

      toast({
        status: "success",
        title: `Organization saved successfully!`,
      });

      if (referrer === "teachers") {
        history.push(`/admin/organizations/${orgId}/teachers`);
      } else {
        history.push("/admin/organizations?status=Approved");
      }
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error saving organization :(",
        description:
          typeof error.response?.data === "string"
            ? error.response?.data
            : null,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onSchoolAvailability = useCallback(
    (isAvailable: boolean, loadingAvailability: boolean) => {
      if (!loadingAvailability && isAvailable === false) {
        formRef.current?.setFieldError(
          "name",
          "Selected organization already exists"
        );
      }
    },
    [formRef]
  );

  const onSchoolSelect = (selectedSchool: PlaceResult) => {
    setSelectedOrganization(selectedSchool);
    formRef.current?.setFieldValue("name", selectedSchool.name);
    setShowAddress(false);
  };

  const onSchoolUnselect = () => {
    formRef.current?.setFieldValue("name", "");
  };

  const [invalidFile, setInvalidFile] = useState(false);
  const avatarFileUploader = useRef(null);
  const {
    result,
    handleProgress,
    handleUploadError,
    handleUploadStart,
    handleUploadSuccess,
    isUploading,
    storageRef,
  } = useUserFileUpload({ path: `ugc/org-avatars/${orgId}` });

  useEffect(() => {
    if (result) {
      formRef.current?.setFieldValue("avatar", result.fileUrl);
    }
  }, [result]);

  const onAvatarFileSelect = (event: Event) => {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length === 1) {
      const file = fileInput.files[0];
      if (file.size <= 1024 * 50 && file.type.startsWith("image/")) {
        // @ts-ignore
        avatarFileUploader.current.startUpload(file);
        setInvalidFile(false);
      } else {
        setInvalidFile(true);
      }
    }
  };

  if (isEditing && loadingOrg) {
    return <LayoutCentered isLoading />;
  }

  if (isEditing && !loadingOrg && !org) {
    return <Redirect to="/admin/organizations" />;
  }

  const schoolInputLabel = `${
    type === OrganizationTypes.School ? "School " : ""
  } Name and Address`;

  return (
    <>
      <PageHeader
        title={`${
          !org.verified ? "Review" : isEditing ? "Edit" : "Create"
        } Organization`}
      />
      <Box maxWidth="480px" mx="auto" pt={4}>
        {isEditing && !loadingOrg && !org?.placeId && (
          <Alert status="warning" mb={4} alignItems="flex-start">
            <IconAlertTriangle />{" "}
            <Text ml={2}>
              This organization is not yet linked to Google Places — search by
              name and address to link it now.
            </Text>
          </Alert>
        )}
        <Formik
          initialValues={{
            avatar: org.avatar || "",
            type: (org.type as OrganizationTypes) || OrganizationTypes.School,
            name: org.name || "",
            addressLine1: org.addressLine1 || "",
            addressLine2: org.addressLine2 || "",
            city: org.city || "",
            region: org.region || "",
            zip: org.zip || "",
            country: org.country || "",
            emailDomains: org.emailDomains?.join(", ") || "",
            gradeLevel: org.gradeLevel || GradeLevel.Primary,
            shopEnabled: org.shopEnabled || false,
          }}
          validationSchema={saveOrgSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
          innerRef={formRef}
          enableReinitialize
        >
          {({ handleSubmit, values, dirty, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="avatar">
                {() => (
                  <Flex align="center" mb={6}>
                    <OrgAvatar size="xl" src={values.avatar} mr={6} />
                    <FormControl isInvalid={invalidFile}>
                      <FormLabel htmlFor="avatar">Avatar</FormLabel>
                      <ButtonOutlined
                        as="label"
                        cursor="pointer"
                        htmlFor="avatar"
                        disabled={isUploading}
                        isLoading={isUploading}
                      >
                        Select
                        <FileUploader
                          accept="image/jpeg,image/jpg,image/png"
                          ref={avatarFileUploader}
                          onChange={onAvatarFileSelect}
                          hidden
                          id="avatar"
                          name="avatar"
                          storageRef={storageRef}
                          onUploadStart={handleUploadStart}
                          onUploadError={handleUploadError}
                          onUploadSuccess={(filename: string) => {
                            handleUploadSuccess(filename);
                          }}
                          onProgress={handleProgress}
                        />
                      </ButtonOutlined>
                      <FormErrorMessage>
                        Please select a JPG file under 50KB
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                )}
              </Field>
              <Field name="type">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                    isRequired
                  >
                    <FormLabel htmlFor="type">Organization Type</FormLabel>
                    <Select
                      {...field}
                      id="type"
                      size="lg"
                      disabled={isSchoolInputDisabled}
                      onChange={(e) => {
                        field.onChange(e);
                        setType(e.target.value as OrganizationTypes);
                      }}
                    >
                      {Object.values(OrganizationTypes).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="name">
                {({ form: { submitCount }, meta, field }: FieldProps) => (
                  <FormControl
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                    isRequired
                  >
                    {isSchoolInputDisabled ? (
                      <>
                        <FormLabel>{schoolInputLabel}</FormLabel>
                        <Input {...field} size="lg" disabled />
                      </>
                    ) : (
                      <SchoolSearchInput
                        schoolType={type}
                        inputProps={{
                          id: "name",
                          name: "name",
                          disabled: isSchoolInputDisabled,
                          placeholder: `Search by ${
                            values.type === OrganizationTypes.School
                              ? "school"
                              : "organization"
                          } name or address`,
                        }}
                        labelProps={{
                          htmlFor: "name",
                        }}
                        inputLabel={schoolInputLabel}
                        onAvailability={onSchoolAvailability}
                        onSchoolSelect={onSchoolSelect}
                        onSchoolUnselect={onSchoolUnselect}
                      />
                    )}
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                    {detailsLoading ? (
                      <FormHelperText as={HStack}>
                        <Spinner size="sm" />
                        <Text>Loading details</Text>
                      </FormHelperText>
                    ) : (
                      (selectedOrganization || org) && (
                        <FormHelperText>
                          <ButtonLink
                            colorScheme="brandFull"
                            onClick={() =>
                              setShowAddress((showAddress) => !showAddress)
                            }
                          >
                            {showAddress ? "Hide" : "Show"} Address
                          </ButtonLink>
                        </FormHelperText>
                      )
                    )}
                  </FormControl>
                )}
              </Field>
              {((showAddress && org) ||
                (showAddress && selectedOrganization && !detailsLoading)) && (
                <>
                  <Field name="addressLine1">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                        isRequired
                      >
                        <FormLabel htmlFor="addressLine1">
                          Street Address 1
                        </FormLabel>
                        <Input
                          {...field}
                          id="addressLine1"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="addressLine2">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                      >
                        <FormLabel htmlFor="addressLine2">
                          Street Address 2
                        </FormLabel>
                        <Input
                          {...field}
                          id="addressLine2"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="city">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                        isRequired
                      >
                        <FormLabel htmlFor="city">City/Town</FormLabel>
                        <Input
                          {...field}
                          id="city"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="region">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                        isRequired
                      >
                        <FormLabel htmlFor="region">
                          State/Province/Region
                        </FormLabel>
                        <Input
                          {...field}
                          id="region"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="zip">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                        isRequired
                      >
                        <FormLabel htmlFor="zip">Zip/Postal Code</FormLabel>
                        <Input
                          {...field}
                          id="zip"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="country">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                        isRequired
                      >
                        <FormLabel htmlFor="country">Country</FormLabel>
                        <Input
                          {...field}
                          id="country"
                          size="lg"
                          readOnly
                          disabled
                        />
                      </FormControl>
                    )}
                  </Field>
                </>
              )}
              <Field name="emailDomains">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                    isRequired
                  >
                    <FormLabel htmlFor="emailDomains">
                      Trusted Email Domains
                    </FormLabel>
                    <Input
                      {...field}
                      id="emailDomains"
                      size="lg"
                      autoComplete="off"
                      placeholder="domain1.com, domain2.com, domain3.com"
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                    <FormHelperText>
                      When an educator signs up for this organization and
                      verifies ownership of a trusted email domain, they will be
                      granted immediate access
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>

              <Field name="gradeLevel">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                    isRequired
                  >
                    <FormLabel htmlFor="gradeLevel">Grade Level</FormLabel>
                    <Select {...field} id="gradeLevel" size="lg">
                      {gradeLevelList.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="shopEnabled">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                  >
                    <FormLabel htmlFor="shopEnabled">Shop Enabled</FormLabel>
                    <Switch
                      {...field}
                      size="lg"
                      colorScheme="brandFull"
                      name={field.name}
                      isChecked={field.value}
                      onChange={() => {
                        setFieldValue(field.name, !field.value);
                      }}
                    />
                  </FormControl>
                )}
              </Field>

              <Flex direction="row" justifyContent="center">
                <ButtonOutlined
                  as={Link}
                  to={
                    referrer === "teachers"
                      ? `/admin/organizations/${orgId}/teachers`
                      : !org.verified
                      ? "/admin/organizations?status=Pending"
                      : "/admin/organizations?status=Approved"
                  }
                  disabled={isSubmitting}
                  size="lg"
                >
                  Cancel
                </ButtonOutlined>
                <ButtonSolid
                  ml={4}
                  size="lg"
                  disabled={org.verified && (isSubmitting || !dirty)}
                  isLoading={isSubmitting}
                  type="submit"
                >
                  {!org.verified ? "Approve" : isEditing ? "Save" : "Create"}
                </ButtonSolid>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
