import React, { FC } from "react";
import { useMutation } from "urql";
import {
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import useInfiniteScroll from "react-infinite-scroll-hook";
import * as yup from "yup";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
} from "Shared";

import { useOffsetPaginationQuery } from "Utils";

import { captureException } from "Services/errors";

import { PageHeader } from "Components/PageHeader";

import {
  AdminDashboardUserAccountFragment,
  AdminUserAdjustCoinsMutation,
  AdminUserAdjustCoinsMutationVariables,
  AdminUserCoinsLedgerQuery,
  AdminUserCoinsLedgerQueryVariables,
} from "@tract/common/dist/graphql";

import {
  ADMIN_USER_ADJUST_COINS_MUTATION,
  ADMIN_USER_COINS_LEDGER_QUERY,
} from "../graphql";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

type FormValues = {
  amount: number;
};

const schema = yup.object({
  amount: yup
    .number()
    .integer()
    .test("non-zero", "must be less than or greater than 0", (v) => v !== 0)
    .required(),
});

export const UserCoins: FC<Props> = ({ user }) => {
  const cancelRef = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingAmount, setPendingAmount] = React.useState<
    number | undefined
  >();
  const { response, pagination } = useOffsetPaginationQuery<
    AdminUserCoinsLedgerQuery,
    AdminUserCoinsLedgerQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: ADMIN_USER_COINS_LEDGER_QUERY,
    field: "coins",
    variables: {
      userId: user.id,
      limit: 50,
      offset: 0,
    },
  });

  const [, adjustCoinsMutation] = useMutation<
    AdminUserAdjustCoinsMutation,
    AdminUserAdjustCoinsMutationVariables
  >(ADMIN_USER_ADJUST_COINS_MUTATION);

  const [{ data, fetching }, refetch] = response;
  const transactions = data?.coins || [];

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: pagination.hasNextPage,
    onLoadMore: pagination.loadMore,
    loading: pagination.loadingMore || fetching,
  });

  const toast = useToast();

  const handleFormSubmit = async (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    onOpen();
    setPendingAmount(values.amount);
    resetForm();
  };

  const handleAdjustment = async () => {
    if (!pendingAmount) {
      return;
    }

    try {
      await adjustCoinsMutation({
        userId: user.id,
        amount: pendingAmount,
      });

      toast({ status: "success", title: "adjusted user coins" });

      refetch({ requestPolicy: "cache-and-network" });
      onClose();
    } catch (error: any) {
      captureException(error);

      toast({
        status: "error",
        title: "error adjusting coins",
      });
    }
  };

  return (
    <>
      <PageHeader title="Coins" />

      <Box borderWidth={1} p={4} borderRadius={16} mb={4}>
        <Formik
          initialValues={{ amount: 0 }}
          onSubmit={handleFormSubmit}
          validationSchema={schema}
          validateOnChange
        >
          <Form>
            <Field name="amount">
              {({ field, meta: { error } }: FieldProps) => (
                <FormControl isInvalid={!!error}>
                  <FormLabel>Add/Subtract Coins</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children="Amount" />
                    <Input
                      name={field.name}
                      placeholder="Enter amount"
                      type="number"
                      value={field.value}
                      onWheel={(e: any) => e.target.blur()}
                      onChange={field.onChange}
                    />
                  </InputGroup>
                  <FormHelperText>
                    Use positive/negative numbers to add/subtract from their
                    balance
                  </FormHelperText>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Form>
        </Formik>
      </Box>

      <Table width="100%" size="sm">
        <Thead>
          <Tr>
            <Th>Amount</Th>
            <Th>Balance</Th>
            <Th>Category</Th>
            <Th>Date</Th>
          </Tr>
        </Thead>
        <Tbody fontSize="md">
          {transactions.map((t) => (
            <Tr bgColor={Math.sign(t.amount) === 1 ? "green.50" : "red.50"}>
              <Td>{t.amount.toLocaleString()}</Td>
              <Td>{t.balance.toLocaleString()}</Td>
              <Td>{t.category}</Td>
              <Td>{new Date(t.createdAt).toLocaleString()}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {pagination.hasNextPage && (
        <Center mt={4}>
          <Button
            ref={sentryRef}
            variant="outline"
            onClick={() => pagination.loadMore()}
            isLoading={pagination.loadingMore}
          >
            Load More
          </Button>
        </Center>
      )}

      {pendingAmount && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {Math.sign(pendingAmount) === 1 ? "Add Coins" : "Remove Coins"}
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={Math.sign(pendingAmount) === 1 ? "green" : "red"}
                  onClick={handleAdjustment}
                  ml={3}
                >
                  {Math.sign(pendingAmount) === 1
                    ? `Add ${pendingAmount.toLocaleString()} Coins`
                    : `Remove ${-pendingAmount.toLocaleString()} Coins`}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};
