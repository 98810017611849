import React, { useRef, useState, PropsWithChildren } from "react";
import { useBreakpointValue } from "Shared";
import { ProjectCardFragment } from "@tract/common/dist/graphql";

import { createContext } from "Utils";
import { pauseAllVideos } from "Utils/videos";

import { ProjectDetailsGalleryModal } from "Pages/ProjectGallery/ProjectDetailsModal";

import { useRouteUpdateDisclosure } from "./hooks/useRouteUpdateDisclosure";
import { ViewSource } from "Types/Project";

const [Provider, useContext] = createContext<{
  isProjectModalOpen: boolean;
  handleOpenProjectModal: (
    e: React.MouseEvent,
    project?: ProjectCardFragment,
    projectId?: string
  ) => void;
  closeProjectModal: (goBack?: boolean) => void;
  modalRef: React.MutableRefObject<HTMLDivElement | null>;
  setViewSource: (source: ViewSource) => void;
  viewSource: ViewSource;
}>({
  strict: true,
});

export const useProjectModal = useContext;

type Props = {};

export const ProjectModalProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const [openProjectId, setOpenProjectId] = useState<string>();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [viewSource, setViewSource] = useState<ViewSource>(ViewSource.Unknown);

  const {
    isOpen: isProjectModalOpen,
    onOpen: openProjectModal,
    onClose,
  } = useRouteUpdateDisclosure();

  const shouldShowModal = useBreakpointValue({
    base: false,
    lg: true,
  });

  const closeProjectModal = (goBack?: boolean) => {
    setViewSource(ViewSource.Unknown);
    onClose(goBack);
  };

  const handleOpenProjectModal = (
    e: React.MouseEvent,
    project?: ProjectCardFragment,
    projectId?: string
  ) => {
    if (!project && !projectId) {
      return;
    }
    if (shouldShowModal) {
      e.preventDefault();
      setOpenProjectId(projectId || project?.id);

      // Pause videos that might be playing when opening project modal
      pauseAllVideos();

      const title = "Project - Tract";
      const url = `/project/${projectId || project?.id}`;

      if (!isProjectModalOpen) {
        window.history.pushState({}, title, url);
        openProjectModal(e);
      } else {
        window.history.replaceState({}, title, url);
      }

      if (!!modalRef.current) {
        modalRef.current.scrollTop = 0;
      }
    }
  };

  return (
    <Provider
      value={{
        isProjectModalOpen,
        handleOpenProjectModal,
        closeProjectModal,
        modalRef,
        setViewSource,
        viewSource,
      }}
    >
      {children}
      <ProjectDetailsGalleryModal
        isOpen={isProjectModalOpen}
        onClose={() => closeProjectModal(true)}
        projectId={openProjectId}
        onOpenNewProject={(event, openProject: ProjectCardFragment) => {
          handleOpenProjectModal(event, openProject);
        }}
      />
    </Provider>
  );
};
