import React from "react";
import { Field, FieldProps } from "formik";
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "Shared";

import { useSession } from "Services/session";

import { CreateClassCheckboxField } from "Components/CreateClassCheckboxField";

export const StepAssignClass = () => {
  const { currentUser } = useSession();

  return (
    <>
      <Stack spacing={10}>
        <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
          <Field name="startDate">
            {({ field, meta }: FieldProps) => (
              <FormControl isRequired isInvalid={meta.touched && !!meta.error}>
                <FormLabel fontWeight="bold">Start Date</FormLabel>
                <Input type="date" variant="filled" {...field} />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="dueDate">
            {({ field, meta, form }: FieldProps) => (
              <FormControl isInvalid={meta.touched && !!meta.error}>
                <FormLabel fontWeight="bold">Due Date</FormLabel>
                <Input
                  type="date"
                  variant="filled"
                  min={form.values.startDate}
                  {...field}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Field name="classIds">
          {({ meta, form }: FieldProps) => (
            <FormControl
              isRequired
              isInvalid={
                !!meta.error && !form.getFieldMeta("newClassroom.name").error
              }
            >
              <FormLabel fontWeight="bold">Classes</FormLabel>
              <Stack spacing={2} alignItems="start">
                {currentUser.learnerGroups.map((lg) => (
                  <Field
                    key={lg.learnerGroupId}
                    name="classIds"
                    type="checkbox"
                    value={lg.learnerGroupId}
                  >
                    {({ field }: FieldProps) => (
                      <Checkbox
                        key={lg.learnerGroupId}
                        size="lg"
                        {...field}
                        isChecked={field.checked}
                      >
                        {lg.learnerGroup.name}
                      </Checkbox>
                    )}
                  </Field>
                ))}
                <CreateClassCheckboxField key="newClassroom" />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </Stack>
            </FormControl>
          )}
        </Field>
      </Stack>
    </>
  );
};
