import { FC } from "react";
import { EmptyState } from "Shared";
import { PageApp } from "Components/PageApp";

export const AccessDenied: FC = () => {
  return (
    <PageApp py={24}>
      <EmptyState
        headline="Access Denied"
        body="You don't have access to the requested page."
      ></EmptyState>
    </PageApp>
  );
};
