import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ControlProps, ScrollMode } from "nuka-carousel";
import { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import builder from "@builder.io/react";

import { prevButtonDisabled, nextButtonDisabled } from "Shared/Carousel";

import {
  Box,
  Carousel,
  Center,
  Flex,
  FontAwesomeIcon,
  Grid,
  GridItem,
  Link,
  LinkBox,
  LinkOverlay,
  IconButton,
  Text,
  VisuallyHidden,
  useBreakpointValue,
  ExternalLink,
} from "Shared";

import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";

import { LayoutCentered } from "Components/LayoutCentered";

type CuratedExploreRow = {
  priority: number;
  itemsPerRow: number;
  itemAspectRatio: string;
  title: string;
  subTitle?: string;
  items: CuratedExploreItem[];
};

type CuratedExploreItem = {
  title: string;
  subTitle?: string;
  pathname?: string;
  url?: string;
  thumbnail: string;
};

export const ExploreCurated = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setError] = useState<boolean>(false);
  const [rows, setRows] = useState<CuratedExploreRow[]>([]);
  const { currentUserRoles } = useSession();
  const { track } = useAnalytics();

  useEffect(() => {
    if (loading || !!rows.length || hasError) return;

    setLoading(true);

    builder
      .getAll("curated-explore-row", {
        cachebust: process.env.REACT_APP_ENVIRONMENT !== "production",
        limit: 20,
        query: {
          "data.allowedRoles.role": { $in: currentUserRoles },
          "data.environment": { $eq: process.env.REACT_APP_ENVIRONMENT },
        },
        options: {
          sort: {
            "data.priority": 1,
          },
        },
      })
      .then((data) => {
        if (!data.length) {
          setError(true);
          captureException(new Error("no data returned from builder.io"));
          return;
        }

        const mappedRows = data.map((row: any) => ({
          ...row.data,
          items: row.data.items.map((rawItem: any) => rawItem.item),
        }));

        setRows(mappedRows);
      })
      .catch((err) => {
        setError(true);
        captureException(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentUserRoles, loading, rows, hasError]);

  const handleItemClick = ({
    row,
    item,
  }: {
    row: CuratedExploreRow;
    item: CuratedExploreItem;
  }) => {
    track(ANALYTICS_EVENTS.EXPLORE_ROW_ITEM_CLICKED, {
      rowPriority: row.priority,
      rowPosition: rows.indexOf(row) + 1,
      rowTitle: row.title,
      rowItemsPerRow: row.itemsPerRow,
      rowItemAspectRatio: row.itemAspectRatio,
      rowItemsCount: row.items.length,
      rowItemPosition: row.items.indexOf(item) + 1,
      rowItemTitle: item.title,
      rowItemPathname: item.pathname,
      rowItemUrl: item.url,
    });
  };

  if (loading) {
    return <LayoutCentered isLoading h="auto" py={10} />;
  }

  if (hasError) {
    return <Redirect to="/explore/originals" />;
  }

  return (
    <Box mx="auto" px={{ base: 0, md: 10 }} maxW="96rem" w="full">
      <Box py={10}>
        {rows.map((row, i) => (
          <Box mb={{ base: 6, md: 10 }} key={i}>
            <CuratedRow row={row} onItemClick={handleItemClick} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CuratedRowLeftControls = (controlProps: ControlProps) => {
  return (
    <Center w="2.5rem" position="absolute" left="-2rem" top={1} bottom={1}>
      {!prevButtonDisabled(controlProps) && (
        <IconButton
          variant="ghost"
          borderRadius="md"
          h="full"
          w="full"
          color="gray.600"
          _hover={{ bg: "transparent" }}
          _focus={{ color: "gray.900" }}
          _groupHover={{ color: "gray.900" }}
          _active={{ bg: "transparent" }}
          icon={<FontAwesomeIcon icon={regular("chevron-left")} size={10} />}
          aria-label="Back"
          onClick={controlProps.previousSlide}
        />
      )}
    </Center>
  );
};

const CuratedRowRightControls = (controlProps: ControlProps) => {
  return (
    <Center w="2.5rem" position="absolute" right="-2rem" top={1} bottom={1}>
      {!nextButtonDisabled(controlProps) && (
        <IconButton
          variant="ghost"
          borderRadius="md"
          h="full"
          w="full"
          color="gray.600"
          _hover={{ bg: "transparent" }}
          _focus={{ color: "gray.900" }}
          _groupHover={{ color: "gray.900" }}
          _active={{ bg: "transparent" }}
          icon={<FontAwesomeIcon icon={regular("chevron-right")} size={10} />}
          aria-label="next"
          onClick={controlProps.nextSlide}
        />
      )}
    </Center>
  );
};

type CuratedRowProps = {
  row: CuratedExploreRow;
  onItemClick: ({
    row,
    item,
  }: {
    row: CuratedExploreRow;
    item: CuratedExploreItem;
  }) => void;
};

const CuratedRow: FC<CuratedRowProps> = ({ row, onItemClick }) => {
  const itemsPerRow = useBreakpointValue({
    base: 0,
    md: row.itemsPerRow - 2,
    lg: row.itemsPerRow - 1,
    xl: row.itemsPerRow,
  });
  const [x, y]: number[] = row.itemAspectRatio
    .split("/")
    .map((x: string) => parseInt(x, 10));
  const itemRatio: number = x / y;
  const mobileItemWidth: number = itemRatio * 150 - ((itemRatio * 150) % 8);

  return (
    <Box role="group">
      <Box mb={3} px={{ base: 4, md: 2 }}>
        <Text fontSize="lg" fontWeight="bold">
          {row.title}
        </Text>
        {row.subTitle && (
          <Text fontSize="md" color="gray.600">
            {row.subTitle}
          </Text>
        )}
      </Box>
      {itemsPerRow && itemsPerRow > 0 ? (
        <Carousel
          enableKeyboardControls
          cellSpacing={8}
          slidesToShow={itemsPerRow}
          slidesToScroll={itemsPerRow}
          dragging={true}
          scrollMode={ScrollMode.remainder}
          renderCenterLeftControls={CuratedRowLeftControls}
          renderCenterRightControls={CuratedRowRightControls}
          renderBottomCenterControls={() => null}
        >
          {row.items.map((item, i) => (
            <RowItem key={i} row={row} item={item} onItemClick={onItemClick} />
          ))}
        </Carousel>
      ) : (
        <Box
          overflowX="scroll"
          css={{
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Grid
            gap={1}
            templateColumns={`repeat(${row.items.length}, ${
              mobileItemWidth / 16
            }rem)`}
            display="inline-grid"
            px={3}
          >
            {row.items.map((item, i) => (
              <GridItem key={i}>
                <RowItem row={row} item={item} onItemClick={onItemClick} />
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

type RowItemProps = {
  row: CuratedExploreRow;
  item: CuratedExploreItem;
  onItemClick: ({
    row,
    item,
  }: {
    row: CuratedExploreRow;
    item: CuratedExploreItem;
  }) => void;
};

const RowItem: FC<RowItemProps> = ({ row, item, onItemClick }) => {
  return (
    <LinkBox
      display="block"
      as={item.pathname ? Link : ExternalLink}
      to={item.pathname ? item.pathname : undefined}
      href={item.url ? item.url : undefined}
      onClick={() => onItemClick({ row, item })}
      borderRadius="2xl"
      overflow="hidden"
      position="relative"
      style={{ aspectRatio: row.itemAspectRatio }}
      bgImage={`url("${item.thumbnail}")`}
      backgroundPosition="center"
      backgroundSize="cover"
      m={1}
      _hover={{ boxShadow: "outline" }}
      _focusVisible={{ boxShadow: "outline" }}
    >
      <LinkOverlay position="absolute">
        <VisuallyHidden>{item.title}</VisuallyHidden>
      </LinkOverlay>
      {item.subTitle && (
        <Flex
          position="absolute"
          justify="center"
          align="flex-end"
          bottom={0}
          left={0}
          right={0}
          h="3rem"
          zIndex={1}
          textAlign="center"
          pb={2}
          bg="linear-gradient(rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 25%) 50%, rgba(0, 0, 0, 50%) 100%)"
        >
          <Text color="white" fontSize="sm" fontWeight="bold">
            {item.subTitle}
          </Text>
        </Flex>
      )}
    </LinkBox>
  );
};
