import React from "react";
import {
  ButtonOutlined,
  EmptyState,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "Shared";

import { AdminOrgFragment } from "@tract/common/dist/graphql";

interface OrganizationsTableProps {
  orgs?: AdminOrgFragment[];
  status?: string | null;
}

export const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
  orgs = [],
}) => {
  if (orgs.length === 0) {
    return <EmptyState headline="No results" />;
  }

  return (
    <Table width="100%">
      <Thead>
        <Tr>
          <Th py={2}>Name</Th>
          <Th py={2}>Address</Th>
          <Th py={2}>Created at</Th>
          <Th py={2}>
            <VisuallyHidden>Actions</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>
      <Tbody fontSize="md">
        {orgs?.map((org) => (
          <Tr key={org.id}>
            <Td py={2} w="400px" overflowWrap="break-word">
              <Link fontWeight="bold" to={`/admin/organizations/${org.id}`}>
                {org.name}
              </Link>
            </Td>
            <Td py={2} title={org.address} overflowWrap="break-word">
              {org.address}
            </Td>
            <Td py={2} w="250px">
              {org.createdAt && new Date(org.createdAt).toLocaleString()}
            </Td>
            <Td py={2} w="100px">
              <HStack justifyContent="flex-end">
                <ButtonOutlined
                  as={Link}
                  to={`/admin/organizations/${org.id}/edit`}
                >
                  {!org.verified ? "Review" : "Edit"}
                </ButtonOutlined>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
