import React, { useEffect, useMemo, useState } from "react";

import { Text, useBreakpointValue } from "Shared";

import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { ProgressSection } from "./ProgressSection";
import { PathSection } from "./PathSection";
import { LivestreamSection } from "./LivestreamSection";
import { ProjectSection } from "./ProjectSection";
import { LearnerChecklist } from "./LearnerChecklist";
import { TopPaths } from "./TopPaths";
import { useDocument } from "Utils/hooks/firestore";
import { captureException } from "Services/errors";
import { LivestreamPreviewBanner } from "Pages/Livestream/LivestreamPreviewBanner";

export type DashOrder = {
  topPicks: number;
  recentlyAdded: number;
  upcomingLivestreams: number;
  viewingHistory: number;
  awesomeProjects: number;
};

export const LearnerDash: React.FC = () => {
  const { track } = useAnalytics();
  const { currentUser } = useSession();

  const [viewed, setViewed] = useState(false);
  useEffect(() => {
    if (viewed) {
      return;
    }

    track(ANALYTICS_EVENTS.HOME_DASHBOARD_VIEWED);
    setViewed(true);
  }, [track, viewed]);

  const { data: dashOrderDoc } = useDocument<DashOrder>(
    `/enums/home-dash-order`,
    {
      onError: (err) => captureException(err),
    }
  );

  const numCards = useBreakpointValue({ base: 6, lg: 3, xl: 4 });

  const dashboardRows = useMemo(() => {
    const rows = new Map();
    const topPicks = () => <TopPaths key="topPaths" initialLimit={numCards} />;
    const recentlyAdded = () => (
      <PathSection key="pathSection" numCards={numCards} />
    );
    const upcomingLivestreams = () => <LivestreamSection key="livestream" />;
    const viewingHistory = () => (
      <ProgressSection key="progress" user={currentUser} />
    );
    const awesomeProjects = () => (
      <ProjectSection key="project" numCards={numCards} />
    );
    rows.set(topPicks(), dashOrderDoc?.topPicks || 1);
    rows.set(recentlyAdded(), dashOrderDoc?.recentlyAdded || 2);
    rows.set(upcomingLivestreams(), dashOrderDoc?.upcomingLivestreams || 3);
    rows.set(viewingHistory(), dashOrderDoc?.viewingHistory || 4);
    rows.set(awesomeProjects(), dashOrderDoc?.awesomeProjects || 5);
    return Array.from(
      new Map(
        [...Array.from(rows.entries())].sort((a, b) => a[1] - b[1])
      ).keys()
    );
  }, [
    currentUser,
    dashOrderDoc?.topPicks,
    dashOrderDoc?.recentlyAdded,
    dashOrderDoc?.upcomingLivestreams,
    dashOrderDoc?.viewingHistory,
    dashOrderDoc?.awesomeProjects,
    numCards,
  ]);

  return (
    <>
      <LivestreamPreviewBanner mt={10} mx="auto" />
      <Text
        fontSize={{ base: "3xl", md: "4xl", xl: "5xl" }}
        fontWeight="bold"
        color="gray.900"
        display={{ base: "none", lg: "block" }}
        mt={10}
      >
        Home
      </Text>
      <LearnerChecklist />
      {dashboardRows.map((row) => row)}
    </>
  );
};
