import React from "react";
import { Switch, Redirect, Route, RouteProps } from "react-router-dom";

import { RouteGroup, unauthedRoutes } from "Routes/routes";

export function UnauthedRoutes() {
  const renderChildRoutes = (routeGroup: RouteGroup, key: number) => {
    const Layout = routeGroup.layout;

    // If route group contains nested layouts, wrap them in another Switch and recurse
    if (routeGroup.isNestedLayout) {
      return (
        <Layout key={key}>
          <Switch>
            {routeGroup.children.map((child: any, i: number) =>
              renderChildRoutes(child as RouteGroup, i)
            )}
          </Switch>
        </Layout>
      );
    }

    // Otherwise we know that the children are RouteConfigs
    const children = routeGroup.children as RouteProps[];

    // Get route paths as an array of strings
    const routes: string[] = children.map((child) => child.path as string);

    return (
      <Route path={routes} key={key}>
        <Layout>
          <Switch>
            {children.map((route, i: number) => (
              <Route {...route} key={i} />
            ))}
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Route>
    );
  };

  return (
    <Switch>
      {unauthedRoutes.map(renderChildRoutes)}
      <Redirect to="/" />
    </Switch>
  );
}
