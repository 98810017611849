import { createIcon } from "@chakra-ui/react";

export const IconSidebarLeft = createIcon({
  displayName: "IconSidebarLeft",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
  },
  path: [
    <path key={1} d="M3 5V19" fill="none" />,
    <path key={2} d="M14 17L9 12L14 7" fill="none" />,
    <path key={3} d="M9 12H21" fill="none" />,
  ],
});
