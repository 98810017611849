import React, { ReactNode } from "react";
import { forwardRef } from "@chakra-ui/react";

import { Box, Flex, Text } from "Shared";

type SideNavLinkProps = {
  isActive?: boolean;
  iconOnly?: boolean;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isNested?: boolean;
};

export const SideNavLink = forwardRef<SideNavLinkProps, "div">(
  (
    { isActive, children, iconOnly, iconLeft, iconRight, isNested, ...props },
    ref
  ) => {
    return (
      <Flex
        align="center"
        px={3}
        pl={isNested ? 8 : undefined}
        py={2}
        borderRadius="lg"
        minH="3rem"
        color={isActive ? "brandFull.500" : "gray.900"}
        _hover={{
          color: "brandFull.500",
          cursor: "pointer",
          textDecoration: "none",
        }}
        ref={ref}
        fontSize="md"
        fontWeight="bold"
        textAlign="start"
        whiteSpace="nowrap"
        transition="150ms ease color"
        {...props}
      >
        <Box as="span">{iconLeft}</Box>
        <Text
          ml={!!iconLeft ? 3 : undefined}
          flex={1}
          display={iconOnly ? "none" : "inline-block"}
        >
          {children}
        </Text>
        <Box as="span" display={iconOnly ? "none" : "inline-block"}>
          {iconRight}
        </Box>
      </Flex>
    );
  }
);
