import React from "react";
import { Box, SkeletonCircle, Stack, AspectRatio, SkeletonText } from "Shared";

type Props = {};

export const PathCardSkeleton: React.FC<Props> = () => {
  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <SkeletonCircle borderRadius="xl" width="full" />
      </AspectRatio>
      <Stack
        mt={4}
        spacing={{ base: 2, md: 3 }}
        direction="row"
        align="top"
        width="full"
      >
        <Box width="2rem" height="2rem">
          <SkeletonCircle size="2rem" />
        </Box>
        <Box height="3.25rem" width="100%" pt={1}>
          <SkeletonText noOfLines={2} height="full" flex={1} maxW="80%" />
        </Box>
      </Stack>
    </Box>
  );
};
