import { forwardRef } from "@chakra-ui/system";
import React, { FC, useEffect, useRef, useState } from "react";

import {
  AspectRatio,
  Box,
  ButtonOutlined,
  Flex,
  FormControl,
  IconButton,
  Text,
  FileUploader,
  IconTrash,
  IconUploadCloud,
  FormErrorMessage,
} from "Shared";
import { UserFileResult, useUserFileUpload } from "Services/useUserFileUpload";
import { FormControlProps } from "@chakra-ui/form-control";
import { ThumnbnailRadioCard } from "./SettingsModal/StepDetails";

type PathCoverUploaderProps = {
  accept?: string;
  coverUrl?: string;
  thumbnailRadioProps?: any;
  maxFileSize: number;
  inputId: string;
  inputLabel: string;
  inputHelperText: string;
  onCoverUpdate: (result: UserFileResult | null) => void;
  onValidateFile: (isValid: boolean) => void;
  disabled: boolean;
};

enum InvalidFileReason {
  Size = "size",
  Type = "type",
}

export const PathCoverUploaderV2: FC<
  PathCoverUploaderProps | FormControlProps
> = forwardRef(
  (
    {
      accept,
      fileId,
      filename,
      coverUrl,
      maxFileSize,
      inputId,
      inputLabel,
      inputHelperText,
      onCoverUpdate,
      onValidateFile,
      disabled,
      thumbnailRadioProps,
      ...props
    },
    ref
  ) => {
    const [invalidFile, setInvalidFile] = useState(false);
    const [invalidFileReason, setInvalidFileReason] =
      useState<InvalidFileReason | null>();
    const coverFileUploader = useRef(null);
    const [lastUpdatedCoverId, setLastUpdatedCoverId] = useState<string | null>(
      null
    );

    const {
      result,
      handleProgress,
      handleUploadError,
      handleUploadStart,
      handleUploadSuccess,
      isUploading,
      progress,
      reset,
      storageRef,
    } = useUserFileUpload();

    useEffect(() => {
      if (result && result.fileId !== lastUpdatedCoverId) {
        onCoverUpdate(result);
        setLastUpdatedCoverId(result.fileId);
      }
    }, [result, lastUpdatedCoverId, onCoverUpdate]);

    const onCoverFileSelect = (event: Event) => {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length === 1) {
        const file = fileInput.files[0];
        if (file.size <= maxFileSize && file.type.startsWith("image/")) {
          // @ts-ignore
          coverFileUploader.current.startUpload(file);
          setInvalidFile(false);
          onValidateFile(true);
        } else {
          if (!file.type.startsWith("image/")) {
            setInvalidFileReason(InvalidFileReason.Type);
          } else {
            setInvalidFileReason(InvalidFileReason.Size);
          }
          setInvalidFile(true);
          onValidateFile(false);
        }
      }
    };

    const onDeleteCover = () => {
      onCoverUpdate(null);
      reset();
    };

    return (
      <FormControl {...props} ref={ref} isInvalid={invalidFile}>
        <Box position="relative">
          {!!coverUrl.length && (
            <ThumnbnailRadioCard
              thumbnailSrc={coverUrl}
              {...thumbnailRadioProps}
            >
              <>
                <Box pr="0.5rem" pt="0.5rem">
                  <IconButton
                    aria-label="Delete cover"
                    boxShadow="md"
                    borderRadius="full"
                    icon={<IconTrash />}
                    disabled={disabled}
                    onClick={() => {
                      onDeleteCover();
                    }}
                  />
                </Box>
              </>
            </ThumnbnailRadioCard>
          )}
          <AspectRatio ratio={16 / 9}>
            <>
              {isUploading && (
                <Flex
                  borderRadius="lg"
                  border="1px solid"
                  borderColor={invalidFile ? "red.700" : "gray.300"}
                  align="center"
                  justify="center"
                  flex={1}
                >
                  <Text color="gray.600" fontWeight="700" fontSize="16px">
                    {progress}%
                  </Text>
                </Flex>
              )}
              {!isUploading && !coverUrl && !result?.filename && (
                <ButtonOutlined
                  as="label"
                  tabIndex={0}
                  size="lg"
                  cursor="pointer"
                  borderRadius="lg"
                  htmlFor={inputId}
                  alignSelf="center"
                  disabled={disabled}
                >
                  <IconUploadCloud />
                  <FileUploader
                    hidden
                    accept={accept}
                    ref={coverFileUploader}
                    onChange={onCoverFileSelect}
                    id={inputId}
                    name={inputId}
                    disabled={disabled}
                    storageRef={storageRef}
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={(filename: string) => {
                      handleUploadSuccess(filename);
                    }}
                    onProgress={handleProgress}
                  />
                </ButtonOutlined>
              )}
            </>
          </AspectRatio>
        </Box>
        <FormErrorMessage>Invalid file {invalidFileReason}</FormErrorMessage>
      </FormControl>
    );
  }
);
