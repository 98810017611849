import { BoxProps } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { FC, ReactNode } from "react";

import { Box, Flex, FontAwesomeIcon, Image, Text } from "Shared";

import archived from "./archived.svg";
import award from "./award.svg";
import draft from "./draft.svg";
import inReview from "./inReview.svg";
import like from "./like.svg";
import path from "./path.svg";
import project from "./project.svg";

interface Props extends BoxProps {
  headline: string;
  body?: string;
  icon?: ReactNode;
  cta?: ReactNode;
  image?:
    | "archived"
    | "award"
    | "draft"
    | "inReview"
    | "like"
    | "path"
    | "project";
}

const images = {
  archived,
  award,
  draft,
  inReview,
  like,
  path,
  project,
};

export const EmptyState: React.FC<React.PropsWithChildren<Props>> = ({
  headline,
  body,
  icon,
  image,
  cta,
  children,
  ...props
}) => {
  return (
    <Flex
      direction="column"
      align="center"
      p={10}
      borderRadius={16}
      textAlign="center"
      {...props}
    >
      {icon ? <Box mb={2}>{icon}</Box> : undefined}
      {image && (
        <Box h="8rem">
          <Image src={images[image]} mx="auto" h="8rem" minH="8rem" />
        </Box>
      )}
      <Text as="h3" color="gray.600" fontWeight="bold" fontSize="xl" mb={2}>
        {headline}
      </Text>
      {children ? (
        children
      ) : (
        <Text color="gray.600" fontSize="md">
          {body}
        </Text>
      )}
      {cta && <Box mt={6}>{cta}</Box>}
    </Flex>
  );
};

export const EmptyStateFontAwesomeIcon: FC<{ icon: IconProp }> = ({ icon }) => {
  return (
    <Box color="gray.400" borderRadius="full" bg="gray.50" p={6}>
      <FontAwesomeIcon size={16} icon={icon} />
    </Box>
  );
};
