import React, { FC } from "react";

import {
  Tr,
  Td,
  Link,
  Button,
  IconBell,
  Text,
  AspectRatio,
  Image,
  VStack,
  useToast,
  HStack,
  Badge,
  Box,
  Flex,
  useBreakpointValue,
  IconCheck,
  IconImage,
} from "Shared";

import { LivestreamFragment } from "@tract/common/dist/graphql";
import { Livestream, ViewSource } from "Types/Livestream";
import { useDocument } from "Utils/hooks/firestore";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { db } from "Services/firebase";
import { UserFile } from "Utils/UserFile";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { format, isAfter } from "date-fns";

type Props = {
  livestream: LivestreamFragment;
};

export const LivestreamGalleryRow: FC<Props> = ({ livestream }) => {
  const { currentUser } = useSession();
  const toast = useToast();
  const { track } = useAnalytics();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const coverFile = new UserFile(livestream.coverFile);
  const { data: livestreamDoc } = useDocument<Livestream>(
    livestream.id ? `/livestreams/${livestream.id}` : null,
    {
      onError: (err) => captureException(err),
    }
  );
  const { data: reminder } = useDocument<{
    remind: boolean;
  }>(
    !!livestream
      ? `livestreams/${livestream.id}/reminders/${currentUser.uid}`
      : null,
    {
      listen: true,
      onError: (err) => captureException(err),
    }
  );
  const toggleReminder = () => {
    if (!reminder) return;
    const newVal = !reminder.remind;

    db.doc(`livestreams/${livestream.id}/reminders/${currentUser.uid}`).set({
      remind: newVal,
    });

    if (newVal === true) {
      toast({
        status: "success",
        title: "We'll send you a reminder when the livestream is starting",
      });

      track(ANALYTICS_EVENTS.LIVESTREAM_REMINDER_SET, {
        livestreamId: livestream?.id,
        livestreamTitle: livestream?.title,
        livestreamHostId: livestream?.hostUserId,
        livestreamHostUsername: livestream?.hostUser?.username,
      });
    }
  };

  const isLive = livestreamDoc?.isLive;
  const happeningNow =
    isAfter(new Date(), new Date(livestream?.startDate)) &&
    isAfter(new Date(livestream?.endDate), new Date());

  const date = new Date(livestream.startDate);
  const toLivestream = {
    pathname: `/livestream/${livestream.id}`,
    state: {
      viewSource: ViewSource.LivestreamGallery,
    },
  };
  const alreadyStreamed =
    !isLive && new Date().getTime() > new Date(livestream.endDate).getTime();

  return (
    <Tr width="full">
      <Td
        py={3}
        width="5.75rem"
        borderBottom="none"
        verticalAlign={{ base: "top", md: "middle" }}
      >
        <VStack align="center" spacing={0}>
          <Text
            fontWeight="bold"
            fontSize="sm"
            color={alreadyStreamed ? "gray.300" : "gray.600"}
          >
            {format(date, "LLL").toUpperCase()}
          </Text>
          <Text
            fontWeight="bold"
            fontSize="3xl"
            color={alreadyStreamed ? "gray.400" : undefined}
          >
            {format(date, "d")}
          </Text>
          <Text
            fontWeight="bold"
            fontSize="sm"
            color={alreadyStreamed ? "gray.300" : undefined}
          >
            {format(date, "h:mmaaaaa")}
          </Text>
        </VStack>
      </Td>
      <Td py={3} borderBottom="none" px={{ base: 0, md: 3 }}>
        <Flex direction={{ base: "column", md: "row" }}>
          <Box
            as={alreadyStreamed ? undefined : Link}
            display="block"
            position="relative"
            mb={{ base: 2, md: 0 }}
            to={toLivestream}
            tabIndex={-1}
            _focus={{ boxShadow: "none" }}
            width={{ base: "full", md: "12rem" }}
            minWidth="12rem"
            mr={{ base: 0, md: 4 }}
          >
            {isLive && (
              <Badge
                colorScheme="red"
                variant="solid"
                fontSize="xs"
                fontWeight="bold"
                position="absolute"
                zIndex={1}
                left={4}
                top={4}
              >
                LIVE
              </Badge>
            )}
            {alreadyStreamed && (
              <Badge
                colorScheme="gray"
                variant="solid"
                fontSize="xs"
                fontWeight="bold"
                position="absolute"
                zIndex={1}
                left={4}
                top={4}
              >
                ENDED
              </Badge>
            )}
            <AspectRatio
              ratio={16 / 9}
              borderRadius={16}
              overflow="hidden"
              bg="gray.100"
            >
              {coverFile.exists ? (
                <Image
                  objectFit="cover"
                  opacity={alreadyStreamed ? 0.3 : undefined}
                  src={coverFile.getTransformedUrl("cover_thumb")}
                />
              ) : (
                <Box w={6}>
                  <IconImage color="gray.400" />
                </Box>
              )}
            </AspectRatio>
          </Box>
          <Box>
            <Text
              as={alreadyStreamed ? undefined : Link}
              to={toLivestream}
              color={alreadyStreamed ? "gray.500" : undefined}
              fontWeight="bold"
              fontSize={{ base: "md", md: "lg" }}
            >
              {livestream.title}
            </Text>
            <HStack mt={3} spacing={3} verticalAlign="center">
              <UserAvatar
                username={livestream.hostUser?.username || ""}
                avatarURL={livestream.hostUser?.avatar || ""}
                size={isMobile ? "xs" : "sm"}
              />
              <UsernameLink
                username={livestream.hostUser?.username || ""}
                fontSize={{ base: "sm", md: "md" }}
              >
                {livestream.hostUser?.username || ""}
              </UsernameLink>
            </HStack>
          </Box>
        </Flex>
      </Td>

      <Td
        display={{ base: "none", md: "block" }}
        py={3}
        textAlign="right"
        verticalAlign="center"
        borderBottom="none"
      >
        {happeningNow ? (
          <Button
            colorScheme="brandFull"
            size="lg"
            as={Link}
            to={toLivestream}
            variant="solid"
          >
            Join Live
          </Button>
        ) : (
          !alreadyStreamed &&
          (livestreamDoc?.isLive ? null : reminder && reminder.remind ? (
            <Button
              variant="outline"
              size="lg"
              leftIcon={<IconCheck />}
              onClick={toggleReminder}
            >
              Reminder Set
            </Button>
          ) : (
            <Button
              variant="outline"
              size="lg"
              leftIcon={<IconBell />}
              onClick={toggleReminder}
            >
              Remind Me
            </Button>
          ))
        )}
      </Td>
    </Tr>
  );
};
