import { ImageProps } from "@chakra-ui/react";
import { UserFileFragment } from "@tract/common/dist/graphql";
import { Image } from "Shared";
import { UserFile } from "Utils/UserFile";

interface Props {
  cover?: UserFileFragment | null;
}

export const PathCover: React.FC<Props & ImageProps> = ({
  cover,
  ...props
}) => {
  const coverFile = new UserFile(cover);
  const coverUrl =
    coverFile.getTransformedUrl("cover_thumb") ||
    coverFile.getTransformedUrl("video_cover_sm");

  return <Image src={coverUrl} {...props} />;
};
