import { FC } from "react";
import * as yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";

import {
  ButtonLink,
  ButtonSolid,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  useToast,
} from "Shared";

import { useSignOut } from "Services/auth";
import { PATH_GRADE_LEVEL_V2 } from "Constants/paths";
import { PathSubjectsOptionGroup } from "Components/PathSubjectsOptionGroup";
import { ONBOARD_EDUCATOR_STEP_CLASS } from "./graphql";
import { useMutation } from "urql";
import {
  OnboardEducatorStepClassMutation,
  OnboardEducatorStepClassMutationVariables,
} from "@tract/common/dist/graphql";
import { useSession } from "Services/session";

const DISPLAY_NAME_CHAR_LIMIT = 30;

export type StepClassForm = {
  displayName?: string;
  lowerGradeLevel?: string;
  upperGradeLevel?: string;
  subjects?: string[];
};
const classInfoFormSchema = yup.object().shape({
  displayName: yup.string(),
  lowerGradeLevel: yup.string(),
  upperGradeLevel: yup.string(),
  subjects: yup.array().of(yup.string()),
});

export const StepClass: FC = () => {
  const signOut = useSignOut();
  const { refreshSession, currentUser } = useSession();
  const toast = useToast();

  function handleSubjectsChange(
    subject: string,
    subjects: string[],
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    if (subjects?.includes(subject)) {
      setFieldValue(
        "subjects",
        subjects?.filter((value) => value !== subject)
      );
    } else {
      setFieldValue(
        "subjects",
        !!subjects?.length ? subjects?.concat([subject]) : [subject]
      );
    }
  }

  const [, onboardEducatorClassStep] = useMutation<
    OnboardEducatorStepClassMutation,
    OnboardEducatorStepClassMutationVariables
  >(ONBOARD_EDUCATOR_STEP_CLASS);

  const onSubmitStep = async (values: StepClassForm) => {
    const { error, data } = await onboardEducatorClassStep({
      teacher: {
        userId: currentUser.id,
        displayName: values.displayName,
        lowerGradeLevel: values.lowerGradeLevel,
        upperGradeLevel: values.upperGradeLevel,
        subjects: values.subjects,
      },
    });

    const id = data?.teacher?.id;

    if (error || !id) {
      toast({
        status: "error",
        title: "error saving class details",
      });
      return;
    }

    //TODO: figure out what analytics are desired here
    try {
      refreshSession();
    } catch (err) {
      window.location.reload();
    }
  };

  return (
    <>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        Tell us about yourself
      </Text>
      <Text fontSize="md" color="gray.600">
        We’ll use this information to match you with the best content for you.
      </Text>
      <Formik
        initialValues={{
          displayName: "",
          lowerGradeLevel: "",
          upperGradeLevel: "",
          subjects: [],
        }}
        onSubmit={onSubmitStep}
        validationSchema={classInfoFormSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Field name="displayName">
              {({ field, form }: FieldProps) => (
                <FormControl
                  id="displayName"
                  mb={6}
                  mt={10}
                  isInvalid={
                    !!(form.errors.displayName && form.touched.displayName)
                  }
                >
                  <FormLabel>What do your students call you?</FormLabel>
                  <Input
                    {...field}
                    display="block"
                    maxLength={DISPLAY_NAME_CHAR_LIMIT}
                    disabled={isSubmitting}
                    variant="filled"
                    placeholder="Preferred name"
                  />
                  <FormHelperText>
                    Enter a name that will be shown to your students
                  </FormHelperText>
                  <FormErrorMessage>{form.errors.displayName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Text align="left" mb={2} fontWeight="bold">
              What grade levels do you teach?
            </Text>
            <HStack mb={6}>
              <Field name="lowerGradeLevel">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id={field.name} isInvalid={!!error}>
                    <Select
                      onChange={field.onChange}
                      name={field.name}
                      value={field.value}
                      placeholder="Lowest..."
                      variant="filled"
                    >
                      {PATH_GRADE_LEVEL_V2.map((gradeLevel, i) => (
                        <option
                          key={gradeLevel.value}
                          value={gradeLevel.value}
                          disabled={
                            !!values.upperGradeLevel &&
                            i >
                              PATH_GRADE_LEVEL_V2.findIndex(
                                (grade) =>
                                  grade.value === values.upperGradeLevel
                              )
                          }
                        >
                          {gradeLevel.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="upperGradeLevel">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id={field.name} isInvalid={!!error}>
                    <Select
                      onChange={field.onChange}
                      name={field.name}
                      value={field.value}
                      placeholder="Highest..."
                      variant="filled"
                    >
                      {PATH_GRADE_LEVEL_V2.map((gradeLevel, i) => (
                        <option
                          key={gradeLevel.value}
                          value={gradeLevel.value}
                          disabled={
                            !!values.lowerGradeLevel &&
                            i <
                              PATH_GRADE_LEVEL_V2.findIndex(
                                (grade) =>
                                  grade.value === values.lowerGradeLevel
                              )
                          }
                        >
                          {gradeLevel.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </HStack>
            <Text fontWeight="bold" mb={2} align="left">
              Which subjects do you teach?
            </Text>
            <PathSubjectsOptionGroup
              onChange={handleSubjectsChange}
              subjects={values.subjects}
              setFieldValue={setFieldValue}
            />
            <ButtonSolid
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              width="full"
              size="lg"
              mt={4}
              mb={10}
            >
              Continue
            </ButtonSolid>
            <ButtonLink onClick={signOut} colorScheme="brandFull">
              Sign Out
            </ButtonLink>
          </Form>
        )}
      </Formik>
    </>
  );
};
