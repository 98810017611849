import { gql } from "urql";

export const ADMIN_SHOP_ITEM_FRAGMENT = gql`
  fragment AdminShopItem on shop_item {
    id
    name
    description
    cost
    media
    quantity
    coverFile {
      id
      filename
      transforms {
        fileId
        filename
      }
    }
    externalURL
    disabled
    tags
    createdAt
    metadata
    ownerId
    owner {
      email
    }
  }
`;

export const ADMIN_SHOP_ITEM_OWNER_FRAGMENT = gql`
  fragment AdminShopItemOwner on user {
    id
    firestoreId
    firstName
    lastName
    email
    isAdmin
    username
  }
`;

export const ADMIN_SHOP_ITEMS = gql`
  ${ADMIN_SHOP_ITEM_FRAGMENT}

  query AdminShopItems($limit: Int!, $offset: Int!) {
    items: shop_item(
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc }
    ) {
      ...AdminShopItem
    }
  }
`;

export const ADMIN_SHOP_ITEM = gql`
  ${ADMIN_SHOP_ITEM_FRAGMENT}
  ${ADMIN_SHOP_ITEM_OWNER_FRAGMENT}

  query AdminShopItem($id: uuid!) {
    item: shop_item_by_pk(id: $id) {
      ...AdminShopItem
    }
    owners: user(where: { isAdmin: { _eq: true } }) {
      ...AdminShopItemOwner
    }
  }
`;

export const ADMIN_SHOP_ITEM_OWNERS = gql`
  ${ADMIN_SHOP_ITEM_OWNER_FRAGMENT}

  query AdminShopItemOwners {
    owners: user(where: { isAdmin: { _eq: true } }) {
      ...AdminShopItemOwner
    }
  }
`;

export const UPDATE_ADMIN_SHOP_ITEM = gql`
  ${ADMIN_SHOP_ITEM_FRAGMENT}

  mutation UpdateAdminShopItem($id: uuid!, $object: shop_item_set_input!) {
    item: update_shop_item_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...AdminShopItem
    }
  }
`;

export const CREATE_ADMIN_SHOP_ITEM = gql`
  ${ADMIN_SHOP_ITEM_FRAGMENT}

  mutation CreateAdminShopItem($object: shop_item_insert_input!) {
    item: insert_shop_item_one(object: $object) {
      ...AdminShopItem
    }
  }
`;

export const ADMIN_SHOP_ITEM_PURCHASE_FRAGMENT = gql`
  fragment AdminShopItemPurchase on shop_item_purchase {
    id
    status
    createdAt
    coinTrx {
      id
      amount
    }
    shopItem {
      id
      name
      media
    }
    user {
      id: firestoreId
      avatar
      username
    }
  }
`;

export const ADMIN_SHOP_PURCHASES = gql`
  ${ADMIN_SHOP_ITEM_PURCHASE_FRAGMENT}

  query AdminShopPurchases($limit: Int!, $offset: Int!) {
    purchases: shop_item_purchase(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      ...AdminShopItemPurchase
    }
  }
`;
