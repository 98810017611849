import { ElementType } from "react";
import { Redirect, RouteProps } from "react-router";

import { LayoutAdmin } from "Components/LayoutAdmin";
import { LayoutApp, LayoutHome } from "Components/LayoutApp";
import { LayoutCentered } from "Components/LayoutCentered";
import { LayoutClass } from "Components/LayoutClass";
import { LayoutCreate } from "Components/LayoutCreate";
import { LayoutFull } from "Components/LayoutFull";
import { LayoutMarketing } from "Components/LayoutMarketing";
// import { LayoutMarketingVideo } from "Components/LayoutMarketingVideo";
import { LayoutSettings } from "Components/LayoutSettings";
import { LayoutStudentOnboarding } from "Components/LayoutStudentOnboarding";
import { LayoutTeach } from "Components/LayoutTeach";
import { LayoutBuilderIO } from "Components/LayoutBuilderIO";

import { UserRole } from "Services/session";

import {
  About,
  AdminDashboard,
  Author,
  Explore,
  ExploreUnauthed,
  Faq,
  Following,
  // ForgotPassword,
  HomeAuthed,
  Homepage,
  LearnerSubmissions,
  Learners,
  // ManageUser,
  MyPaths,
  Notifications,
  // ParentSignUp,
  PrivacyPolicy,
  PrizeBoard,
  Profile,
  ProjectDetails,
  Search,
  Settings,
  SignIn,
  SignOut,
  // SignUp,
  TermsOfService,
} from "Pages";

import { Achievements } from "Pages/Achievements";
import { AdminCommentsV2 } from "Pages/AdminDashboard/AdminComments/AdminCommentsV2";
import { BuilderPage } from "Pages/BuilderIO";
import { Collection } from "Pages/Collection";
import { Community } from "Pages/Community";
import { CommunityGuidelines } from "Pages/CommunityGuidelines";
import { EditPathNode } from "Pages/CreateV4/EditPathNode";
// import { EducatorSignUp } from "Pages/SignUp/Educators";
import { Insights } from "Pages/Insights";
import { KidInvites } from "Pages/KidInvites";
import { LayoutCreateV4 } from "Pages/CreateV4/LayoutCreateV4";
import { LearnerHomeClasses } from "Pages/LearnerHomeClasses";
import { Livestream } from "Pages/Livestream";
import { LivestreamGallery } from "Pages/LivestreamGallery";
// import { LivestreamUnauthed } from "Pages/LivestreamUnauthed";
import { Onboarding } from "Pages/Onboarding";
import { PathReviews } from "Pages/AuthorPathReviews";
import { PathViewer } from "Pages/PathViewer";
import { PathViewerUnauthed } from "Pages/PathViewer/PathViewerUnauthed";
import { RedirectToFirstNode } from "Pages/CreateV4/RedirectToFirstNode";
import { StudentOnboard } from "Pages/SignUp/Students";
import { TeachingResources } from "Pages/TeachingResources";

import { PathNodeRedirect } from "Redirects/pathNode";

export type RoutePropsExtended = {
  isShared?: boolean;
} & RouteProps;

export type RouteGroup = {
  layout: ElementType;
  isNestedLayout?: boolean;
  children: RouteConfig[] | RouteProps[] | RouteGroup[];
};

export type RouteConfig = {
  isShared?: boolean;
  allowedRoles: AllowedRoles;
} & RouteProps;

type AllowedRoles = AllRoles | UserRole[];
type AllRoles = "*";

export const ALL_ROLES: AllRoles = "*";

export const unauthedRoutes = [
  {
    layout: LayoutBuilderIO,
    children: [
      {
        path: "/m/:slug",
        component: BuilderPage,
      },
      {
        path: "/districts",
        component: BuilderPage,
      },
    ],
  },
  // {
  //   layout: LayoutMarketingVideo,
  //   children: [
  //     {
  //       path: "/get-started/educator",
  //       component: EducatorSignUp,
  //     },
  //   ],
  // },
  // {
  //   layout: LayoutStudentOnboarding,
  //   children: [
  //     {
  //       path: "/get-started/kid",
  //       exact: true,
  //       component: StudentOnboard,
  //     },
  //   ],
  // },
  {
    layout: LayoutMarketing,
    children: [
      {
        path: "/",
        exact: true,
        component: Homepage,
      },
      {
        path: "/explore",
        component: ExploreUnauthed,
      },
      {
        path: "/path/:slug/v/:nodeId",
        component: PathViewerUnauthed,
      },
      {
        path: "/path/:slug",
        component: PathViewerUnauthed,
      },
      {
        path: "/company/about",
        component: About,
      },
      {
        path: "/company/faq",
        component: Faq,
      },
      // {
      //   path: "/get-started",
      //   exact: true,
      //   component: SignUp,
      // },
      // {
      //   path: "/get-started/parent",
      //   component: ParentSignUp,
      // },
      // {
      //   path: "/sign-in",
      //   component: SignIn,
      // },
      {
        path: "/sign-in",
        component: SignIn,
      },
      // {
      //   path: "/forgot-password",
      //   component: ForgotPassword,
      // },
      // {
      //   path: "/manage-user",
      //   component: ManageUser,
      // },
      {
        path: "/legal/privacy",
        component: PrivacyPolicy,
        isShared: true,
      },
      {
        path: "/legal/tos",
        component: TermsOfService,
        isShared: true,
      },
      {
        path: "/legal/community-guidelines",
        component: CommunityGuidelines,
        isShared: true,
      },
      // {
      //   path: "/livestream/:livestreamId",
      //   component: LivestreamUnauthed,
      // },
    ],
  },
];

export const authedRoutes = [
  {
    layout: LayoutCentered,
    children: [
      {
        path: "/sign-out",
        component: SignOut,
        allowedRoles: ALL_ROLES,
      },
    ],
  },
  {
    layout: LayoutStudentOnboarding,
    children: [
      {
        path: "/get-started/kid",
        exact: true,
        component: StudentOnboard,
        allowedRoles: [UserRole.Learner],
      },
    ],
  },
  {
    layout: LayoutFull,
    children: [
      {
        path: "/onboarding",
        component: Onboarding,
        allowedRoles: ALL_ROLES,
      },
    ],
  },
  {
    layout: LayoutCreateV4,
    children: [
      {
        path: "/create/path/:pathId/v/:nodeId",
        component: EditPathNode,
        allowedRoles: ALL_ROLES,
      },
      {
        path: "/create/path/:pathId",
        component: RedirectToFirstNode,
        allowedRoles: ALL_ROLES,
      },
    ],
  },
  {
    layout: LayoutApp,
    isNestedLayout: true,
    children: [
      {
        layout: LayoutAdmin,
        children: [
          {
            path: "/admin",
            component: AdminDashboard,
            allowedRoles: [UserRole.Admin],
          },
        ],
      },
      {
        layout: LayoutCreate,
        children: [
          {
            path: "/author/submissions",
            component: LearnerSubmissions,
            allowedRoles: [UserRole.Creator],
          },
          {
            path: "/author/peer-reviews",
            component: PathReviews,
            allowedRoles: [UserRole.Admin],
          },
          {
            path: "/author",
            component: Author,
            allowedRoles: [UserRole.Creator],
          },
        ],
      },
      {
        layout: LayoutTeach,
        children: [
          {
            path: "/learners",
            component: Learners,
            allowedRoles: [UserRole.Parent],
          },
          {
            path: "/submissions",
            component: LearnerSubmissions,
            allowedRoles: [UserRole.Teacher, UserRole.Parent],
          },
          {
            path: "/comments",
            component: AdminCommentsV2,
            allowedRoles: [UserRole.Teacher, UserRole.Parent],
          },
          {
            path: "/insights",
            component: Insights,
            allowedRoles: [UserRole.Teacher],
          },
        ],
      },

      {
        layout: LayoutSettings,
        children: [
          {
            path: "/settings",
            component: Settings,
            allowedRoles: [
              UserRole.Learner,
              UserRole.Teacher,
              UserRole.Creator,
              UserRole.Parent,
            ],
          },
        ],
      },
      {
        layout: LayoutHome,
        children: [
          {
            path: "/community",
            component: Community,
            allowedRoles: [UserRole.Teacher, UserRole.Admin],
          },
          {
            path: "/classes",
            component: LearnerHomeClasses,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/events",
            component: LivestreamGallery,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/following",
            component: Following,
            allowedRoles: ALL_ROLES,
          },
        ],
      },
      {
        layout: LayoutFull,
        children: [
          {
            path: "/achievements",
            exact: true,
            component: Achievements,
            allowedRoles: [UserRole.Learner, UserRole.Creator],
          },
          {
            path: "/class/:classId",
            component: LayoutClass,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/explore",
            component: Explore,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/invites",
            component: KidInvites,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/legal/privacy",
            component: PrivacyPolicy,
            isShared: true,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/legal/tos",
            component: TermsOfService,
            isShared: true,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/livestream/:livestreamId",
            component: Livestream,
            allowedRoles: ALL_ROLES,
          },
          // {
          //   path: "/manage-user",
          //   component: ManageUser,
          //   allowedRoles: ALL_ROLES,
          // },
          {
            path: "/me",
            component: Profile,
            allowedRoles: [
              UserRole.Learner,
              UserRole.Admin,
              UserRole.Creator,
              UserRole.Teacher,
            ],
          },
          {
            path: "/my-paths",
            exact: true,
            component: MyPaths,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/node/:nodeId",
            component: PathNodeRedirect,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/notifications",
            component: Notifications,
            allowedRoles: [UserRole.Learner, UserRole.Creator],
          },
          {
            path: "/path/review/:pathId",
            component: PathViewer,
            allowedRoles: [UserRole.Admin],
          },
          {
            path: "/path/preview/:pathId",
            component: PathViewer,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/path/:slug",
            component: PathViewer,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/collection/:id",
            component: Collection,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/prize-board",
            component: () => <Redirect to="/shop" />,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/project/:projectId",
            component: ProjectDetails,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/resources",
            component: TeachingResources,
            allowedRoles: [UserRole.Teacher],
          },
          {
            path: "/search",
            component: Search,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/shop",
            component: PrizeBoard,
            allowedRoles: ALL_ROLES,
            exact: true,
          },
          {
            path: "/shop/category/:category",
            component: PrizeBoard,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/shop/item/:itemId",
            component: PrizeBoard,
            allowedRoles: ALL_ROLES,
          },
          {
            path: "/:usernameOrId",
            component: Profile,
            allowedRoles: ALL_ROLES,
          },
        ],
      },
      {
        layout: LayoutHome,
        children: [
          {
            path: "/",
            exact: true,
            component: HomeAuthed,
            allowedRoles: ALL_ROLES,
          },
        ],
      },
    ],
  },
];
