import { createIcon } from "@chakra-ui/react";

export const IconFilledAffiliate = createIcon({
  displayName: "IconFilledAffiliate",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path d="M4.333 6.333l3.5 2.334 3.489-4.884a.834.834 0 011.356 0l3.489 4.884 3.5-2.334a.833.833 0 011.289.792l-1.37 11.64a.833.833 0 01-.827.735H5.241a.833.833 0 01-.828-.736L3.044 7.124a.833.833 0 011.29-.79zM12 14.5a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z" />
  ),
});
