import React from "react";
import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { builder } from "@builder.io/react";

import "./index.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

const BUILDERIO_PUBLIC_KEY = "30650952141548d98890fa79ac9e76fe";

builder.init(BUILDERIO_PUBLIC_KEY);

declare global {
  interface Window {
    Intercom: any;
    _getVersion: () => string;
    videoask: any;
  }
}

window._getVersion = () => {
  return process.env.REACT_APP_GIT_SHA || "";
};

if (process.env.REACT_APP_ENABLE_REACT_18) {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const container = document.getElementById("root");
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    container
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
