import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  AddPathV4CoAuthorMutationResult,
  GetPathWithNodesQueryResult,
  GetPathWithNodesQueryVariables,
} from "@tract/common/dist/graphql";
import { GET_PATH_WITH_NODES_QUERY } from "Pages/CreateV4/graphql";

export const insert_path_author_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const variables = args.object as GetPathWithNodesQueryVariables;
  cache.updateQuery<
    GetPathWithNodesQueryResult["data"],
    GetPathWithNodesQueryVariables
  >(
    {
      query: GET_PATH_WITH_NODES_QUERY,
      variables: {
        pathId: `${variables.pathId}`,
      },
    },
    (data) => {
      const coAuthor = (result as AddPathV4CoAuthorMutationResult["data"])
        ?.insert_path_author_one;
      if (coAuthor) {
        const authors = [...(data?.path?.authors || []), coAuthor];
        if (data?.path?.authors) data.path.authors = authors;
      }
      return data;
    }
  );
};
