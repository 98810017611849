import { FC, PropsWithChildren } from "react";
import { useIntercom } from "Services/intercom";
import {
  Flex,
  Box,
  Center,
  HStack,
  Icon,
  Text,
  IconPhone,
  IconEmail,
  IconChat,
  Image,
  Menu,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  IconHelp,
} from "Shared";
import bg from "assets/backgrounds/tract_sign-up_bg.svg";
// import { ApiVideoPlayer } from "Components/ApiVideoPlayer";

export const LayoutMarketingVideo: FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { show, isReady } = useIntercom();

  const openEmailHelp = () => {
    window.open("mailto:help@tract.app");
  };

  return (
    <Flex width="100%" direction="row" {...props}>
      <Box
        width={{ base: "100%", lg: "40%" }}
        minW={{ base: undefined, lg: "30rem" }}
        pt={{ base: "2.5rem", lg: "5rem" }}
        px={{ base: 6, lg: undefined }}
      >
        <Box
          display={{ base: "block", lg: "none" }}
          position="absolute"
          top={4}
          right={4}
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="open comment menu"
              icon={<IconHelp width={6} height={6} />}
              width={12}
              height={12}
              variant="outline"
            />
            <MenuList width="16rem">
              <Box as="a" href="tel:+18338722826" width="full" height="full">
                <MenuItem>
                  <HStack>
                    <Icon as={IconPhone} w={5} h={5} />
                    <Text>1 (833) TRACT-CO</Text>
                  </HStack>
                </MenuItem>
              </Box>
              <MenuItem onClick={openEmailHelp}>
                <HStack>
                  <Icon as={IconEmail} w={5} h={5} />
                  <Text>help@tract.app</Text>
                </HStack>
              </MenuItem>
              {isReady && (
                <MenuItem onClick={show}>
                  <HStack>
                    <Icon as={IconChat} w={5} h={5} />
                    <Text>Live Support</Text>
                  </HStack>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
        {children}
      </Box>
      <Box
        left="40%"
        display={{ base: "none", lg: "block" }}
        width={{ base: "0%", lg: "60%" }}
        bgColor="brand"
        bgImage={`url(${bg})`}
        bgRepeat="no-repeat"
        bgPosition="right"
        bgSize="auto 100%"
        height="100vh"
        position="sticky"
        top={0}
        px="5rem"
      >
        <Box
          position="absolute"
          top={6}
          right={6}
          bgColor="white"
          width="30rem"
          borderRadius="3xl"
          py={3}
          px={4}
          zIndex={10}
        >
          <Flex direction="row" justifyContent="space-between">
            <Button
              color="gray.600"
              variant="link"
              as="a"
              href="tel:+18338722826"
            >
              <HStack spacing={1}>
                <Icon as={IconPhone} w={5} h={5} />
                <Text>1 (833) TRACT-CO</Text>
              </HStack>
            </Button>
            <Button color="gray.600" variant="link" onClick={openEmailHelp}>
              <HStack spacing={1}>
                <Icon as={IconEmail} w={5} h={5} />
                <Text>help@tract.app</Text>
              </HStack>
            </Button>
            {isReady && (
              <Button color="gray.600" variant="link" onClick={show}>
                <HStack spacing={1}>
                  <Icon as={IconChat} w={5} h={5} />
                  <Text>Live Support</Text>
                </HStack>
              </Button>
            )}
          </Flex>
        </Box>
        <Center width="100%" height="100%">
          <Image
            borderRadius="3xl"
            w="50rem"
            maxW="100%"
            src="https://firebasestorage.googleapis.com/v0/b/tract-media/o/media%2Fonboarding%2Flander.png?alt=media"
          />
          {/* <Box width="100%" borderRadius="2xl" overflow="hidden">
            <ApiVideoPlayer src="https://embed.api.video/vod/vi31EKcWR5r3jCKUGNDvDsy9" />
          </Box> */}
        </Center>
      </Box>
    </Flex>
  );
};
