import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useQuery } from "urql";
import { CollectionsForAdminQuery } from "@tract/common/dist/graphql";
import { getDisplayName } from "Types/User";

import {
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  EmptyState,
  useToast,
  HStack,
  FontAwesomeIcon,
  IconButton,
} from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";
import { AdminContent } from "../AdminContent";
import { PageHeader } from "Components/PageHeader";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { isParentUser, isTeacherUser } from "Types/User";
import {
  CollectionAddOrEditModal,
  CollectionMetadata,
} from "Components/Collections/AddOrEditModal";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { COLLECTIONS_QUERY } from "./graphql";

export const AdminCollections: FC = () => {
  const { pathname } = useLocation();
  const toast = useToast();
  const [collectionToEdit, setCollectionToEdit] =
    useState<CollectionMetadata>();
  const [{ fetching, error, data }] = useQuery<CollectionsForAdminQuery>({
    query: COLLECTIONS_QUERY,
  });

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load paths.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  if (fetching && !data?.path_collection) {
    return <LayoutCentered isLoading />;
  }

  if (!data?.path_collection?.length) {
    return <EmptyState headline="No collections" mt={10} />;
  }

  return (
    <AdminContent>
      <PageHeader title="Collections" />
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Owner</Th>
            <Th>Paths</Th>
            <Th></Th> {/* No heading for the edit icon column */}
          </Tr>
        </Thead>
        <Tbody>
          {data?.path_collection?.map((collection) => {
            return (
              <Tr key={collection.id}>
                <Td py={4} pr={2}>
                  <Link
                    fontWeight="bold"
                    to={{
                      pathname: `/collection/${collection.id}`,
                      state: {
                        sourcePath: pathname,
                        sourceLabel: "Back to Collections",
                      },
                    }}
                  >
                    {collection.title || "[untitled]"}
                  </Link>
                </Td>
                <Td py={4} pr={2}>
                  <HStack>
                    <UserAvatar
                      userId={collection.user.id}
                      username={collection.user.username || ""}
                      name={collection.user && getDisplayName(collection.user)}
                      isTeacher={collection.user.isEducator}
                      avatarURL={collection.user.avatar || ""}
                      tabIndex={-1}
                      size="sm"
                    />
                    <UsernameLink
                      username={collection.user.username || ""}
                      userId={collection.user.id}
                      isTeacher={isTeacherUser(collection.user)}
                      isParent={isParentUser(collection.user)}
                    >
                      {getDisplayName(collection.user)}
                    </UsernameLink>
                  </HStack>
                </Td>
                <Td py={4} pr={2}>
                  {collection.pathCollectionItems?.length}
                </Td>
                <Td py={4} pr={2}>
                  <IconButton
                    aria-label="edit this collection"
                    variant="ghost"
                    icon={<FontAwesomeIcon icon={regular("pencil")} />}
                    onClick={() =>
                      setCollectionToEdit({
                        edit: {
                          id: collection.id,
                          title: collection.title || "",
                          description: collection.description || "",
                          userId: collection.user.id,
                        },
                      })
                    }
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {collectionToEdit && (
        <CollectionAddOrEditModal
          isOpen={!!collectionToEdit}
          collectionMetadata={collectionToEdit}
          onClose={() => setCollectionToEdit(undefined)}
        />
      )}
    </AdminContent>
  );
};
