import { useEffect, useRef, useState } from "react";

import {
  collection,
  doc,
  FirestoreError,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { dbV9 as db } from "Services/firebase";

type Options = {
  listen?: boolean;
  onError?: (error: Error) => void;
};

type Document<T = {}> = T & {
  id: string;
};

export const useDocument = <
  Data extends object = {},
  Doc extends Document = Document<Data>
>(
  documentPath: string | null,
  { listen, onError }: Options = {}
) => {
  const [data, setData] = useState<Doc>();
  const [error, setError] = useState<Error>();
  const unsubRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!documentPath || data || error) return;

    const docRef = doc(db, documentPath);

    if (listen) {
      if (unsubRef.current) return;

      unsubRef.current = onSnapshot(
        docRef,
        (doc) => {
          setData({ id: doc.id, ...doc.data() } as Doc);
        },
        (err) => {
          onError?.(err);
        }
      );
    } else {
      getDoc(docRef)
        .then((doc) => {
          setData(doc.data() as Doc);
        })
        .catch((err) => {
          setError(err);
          onError?.(err);
        });
    }
  }, [documentPath, listen, onError, data, error]);

  return {
    data,
    loading: !data && !error,
    error,
  };
};

type WhereArray = Parameters<typeof where>;

type CollectionOptions = {
  limit?: number;
  where?: WhereArray[] | WhereArray;
  orderBy?: Parameters<typeof orderBy>;
  listen?: boolean;
  onError?: (error: FirestoreError) => void;
};

export const useCollection = <
  Data extends object = {},
  Doc extends Document = Document<Data>
>(
  collectionPath: string | null,
  {
    onError,
    limit: _limit,
    where: _where,
    orderBy: _orderBy,
    listen,
  }: CollectionOptions = {}
) => {
  const [data, setData] = useState<Doc[]>();
  const [error, setError] = useState<FirestoreError>();
  const unsubRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!collectionPath || data || error) return;

    let colRef = collection(db, collectionPath);
    let q = query(colRef);

    if (_where) {
      if (Array.isArray(_where[0])) {
        (_where as WhereArray[]).forEach((whereClause) => {
          q = query(q, where(...whereClause));
        });
      } else {
        q = query(q, where(...(_where as WhereArray)));
      }
    }

    q = query(q, limit(_limit || 20));

    if (_orderBy) {
      q = query(q, orderBy(..._orderBy));
    }

    if (listen) {
      if (unsubRef.current) return;

      unsubRef.current = onSnapshot(
        q,
        (snap) => {
          setData(
            snap.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Doc))
          );
        },
        (err) => {
          setError(err);
          onError?.(err);
        }
      );
    } else {
      getDocs(q)
        .then((d) => {
          setData(d.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Doc)));
        })
        .catch((err) => {
          setError(err);
          onError?.(err);
        });
    }
  }, [collectionPath, _limit, _where, onError, _orderBy, listen, data, error]);

  return {
    data,
    loading: !data && !error,
    error,
  };
};
