import { useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

// Same as Chakra's useBreakpointValue except it doesn't change
// when the page is resized
export function useInitialBreakpointValue<T = any>(
  values: Partial<Record<string, T>>
): T | undefined {
  const [outputValue, setOutputValue] = useState<T>();
  const base = values.base;

  // Need to set base to undefined to be able to detect when
  // the breakpoint has been calculated. If there is a base value,
  // useBreakpointValue will return that before the real breakpoint
  // has been determined.
  const breakpointValue = useBreakpointValue({
    ...values,
    base: undefined,
  });

  useEffect(() => {
    if (breakpointValue && !outputValue) setOutputValue(breakpointValue);
  }, [breakpointValue, outputValue]);

  return outputValue === undefined ? base : outputValue;
}
