import React, { useEffect, useState } from "react";
import { ButtonProps, forwardRef, StackProps } from "@chakra-ui/react";
import { fabric } from "fabric";

import {
  Box,
  Button,
  Flex,
  Grid,
  IconBackdrop,
  IconBack,
  IconButton,
  IconSidebarRight,
  IconType,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "Shared";

import { useVideoEditorContext } from "..";

import { DrawerPanel, findCanvasObjectByName } from "../shared";
import { TextFx } from "./TextFx";
import { BackdropFx } from "./BackdropFx";
import { Settings } from "./Settings";

type Props = {
  onMinimize: () => void;
};

const titleByPanel = {
  [DrawerPanel.Effects]: "Effects",
  [DrawerPanel.TextOptions]: "Text",
  [DrawerPanel.BackdropOptions]: "Backdrop",
  [DrawerPanel.Settings]: "Options",
};

fabric.IText.prototype.set({
  _getNonTransformedDimensions() {
    // @ts-ignore
    return new fabric.Point(this.width, this.height).scalarAdd(16);
  },
  // @ts-ignore
  _calculateCurrentDimensions() {
    // Controls dimensions
    return fabric.util.transformPoint(
      this._getTransformedDimensions(),
      this.getViewportTransform(),
      true
    );
  },
});

export const Drawer: React.FC<Props> = ({ onMinimize }) => {
  const {
    fabricCanvas,
    activeDrawerPanel: activePanel,
    setActiveEffectsPanel,
  } = useVideoEditorContext();
  const [stream] = useState<MediaStream>();

  useEffect(() => {
    if (!stream || !fabricCanvas) return;

    stream.addEventListener("inactive", () => {
      const obj = findCanvasObjectByName(fabricCanvas, "desktop");
      if (obj) fabricCanvas.remove(obj);
    });

    return () => {
      stream.removeEventListener("inactive", () => {});
    };
  }, [stream, fabricCanvas]);

  const handleAddText = () => {
    const TextObj = new fabric.IText("Edit Me", {
      padding: 32,
      originY: "center",
      originX: "center",
      backgroundColor: "white",
      fill: "black",
      fontFamily: "Basier Circle",
      fontWeight: "bold",
    });

    fabricCanvas?.add(TextObj).centerObject(TextObj);
    fabricCanvas?.setActiveObject(TextObj);
    TextObj.enterEditing();
    TextObj.selectAll();
    setActiveEffectsPanel(DrawerPanel.TextOptions);
  };

  const handleRemove = () => {
    fabricCanvas?.remove(fabricCanvas?.getActiveObject());
    setActiveEffectsPanel(DrawerPanel.Effects);
  };

  const handleBack = () => {
    setActiveEffectsPanel(DrawerPanel.Effects);
  };

  return (
    <Flex flexDir="column" bg="white" borderRadius="xl" height="100%">
      <Flex h="4rem" justify="center" align="center" p={4} position="relative">
        {activePanel === DrawerPanel.Effects ||
        activePanel === DrawerPanel.Settings ? null : (
          <IconButton
            position="absolute"
            top={3}
            left={3}
            onClick={handleBack}
            variant="ghost"
            icon={<IconBack />}
            aria-label="Back"
          />
        )}
        <Text fontSize="lg" fontWeight="bold">
          {titleByPanel[activePanel]}
        </Text>
        <IconButton
          position="absolute"
          top={3}
          right={3}
          onClick={onMinimize}
          variant="ghost"
          icon={<IconSidebarRight />}
          aria-label="Hide Sidebar"
        />
      </Flex>

      <Box p={6} flex={1}>
        <Tabs defaultIndex={0} index={activePanel} height="100%" isLazy>
          <TabPanels height="100%">
            <TabPanel>
              <Grid gridTemplateColumns="repeat(2, minmax(0, 1fr))" gap={3}>
                <ButtonOption
                  label="Backdrop"
                  icon={<IconBackdrop />}
                  onClick={() =>
                    setActiveEffectsPanel(DrawerPanel.BackdropOptions)
                  }
                />
                <ButtonOption
                  label="Text"
                  icon={<IconType />}
                  onClick={handleAddText}
                />
              </Grid>
            </TabPanel>
            <TabPanel height="100%">
              <TextFx onDelete={handleRemove} />
            </TabPanel>
            <TabPanel height="100%">
              <BackdropFx />
            </TabPanel>
            <TabPanel height="100%">
              <Settings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

type ButtonOptionProps = {
  selected?: boolean;
  label?: string;
  icon: JSX.Element;
  imgSrc?: string;
};

export const ButtonOption = forwardRef<
  ButtonOptionProps & ButtonProps & StackProps,
  "button"
>(({ label, icon, imgSrc, selected = false, ...props }, ref) => {
  return (
    <Button
      p={0}
      variant="outline"
      backgroundColor={selected ? "gray.100" : "initial"}
      overflow="hidden"
      borderRadius="lg"
      display="block"
      height="auto"
      fontWeight="normal"
      {...props}
    >
      <VStack
        h="full"
        w="full"
        align="center"
        justify="center"
        borderRadius="lg"
        boxShadow={selected ? "outlineSelected" : undefined}
        p={6}
        bgPosition="center"
        bgSize="cover"
        bgImage={imgSrc ? `url(${imgSrc})` : undefined}
      >
        <>{icon}</>
        {label && <Text>{label}</Text>}
      </VStack>
    </Button>
  );
});
