import React, { PropsWithChildren } from "react";

import { Text } from "Shared";

type Props = {
  level: number;
};

const headings: Record<any, "h1" | "h2" | "h3" | "h4" | "h5" | "h6"> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
};

export const Heading: React.FC<PropsWithChildren<Props>> = (props) => {
  let fontSize;
  let my;

  switch (props.level) {
    case 1:
      fontSize = "xl";
      my = 2;
      break;
    case 2:
      fontSize = "lg";
      my = 2;
      break;
    case 3:
      fontSize = "md";
      my = 2;
      break;
    case 4:
      fontSize = "md";
      my = 2;
      break;
    default:
      fontSize = "md";
      my = 2;
      break;
  }

  return (
    <Text
      as={headings[props.level]}
      fontSize={fontSize}
      fontWeight="bold"
      {...props}
      my={my}
    />
  );
};
