import React from "react";
import { forwardRef, LinkOverlay } from "@chakra-ui/react";

import { AspectRatio, Box, Image, Link, LinkBox, Text } from "Shared";

import { CoinValue } from "Components/CoinValue";

import { ShopItemFragment } from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";

type Props = {
  item: ShopItemFragment;
};

export const PrizeCard = forwardRef<Props, "div">(({ item, ...props }, ref) => {
  const isSoldOut = !!(typeof item.quantity === "number" && item.quantity <= 0);
  const coverFile = new UserFile(item.coverFile);

  const getImgSrc = (item: ShopItemFragment) => {
    if (item.coverFile) {
      if (item.coverFile.transforms) {
        return coverFile.getTransformedUrl("thumb");
      } else {
        return coverFile.getOriginalUrl();
      }
    }

    if (item.media) {
      return item.media.cover.files[0];
    }
  };

  return (
    <Box ref={ref} {...props}>
      <LinkBox>
        <Box position="relative">
          <AspectRatio
            ratio={1}
            mb={3}
            position="relative"
            bg="gray.50"
            borderRadius={16}
            overflow="hidden"
          >
            <Image src={getImgSrc(item)} objectFit="cover" />
          </AspectRatio>
        </Box>
        <Text fontSize="lg" fontWeight="bold" color="gray.900">
          <LinkOverlay
            as={Link}
            to={`/shop/item/${item.id}`}
            href="#"
            aria-label="Shop Item Link"
          >
            {item.name}
          </LinkOverlay>
        </Text>
      </LinkBox>
      <Box spacing={1}>
        {isSoldOut ? (
          <Text color="red">Currently Unavailable</Text>
        ) : (
          <CoinValue size="md" value={item.cost} color="gray.600" />
        )}
      </Box>
    </Box>
  );
});
