import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  useRef,
  useState,
  PropsWithChildren,
} from "react";
import styled from "@emotion/styled";
import { Grid as GiphyGrid } from "@giphy/react-components";

import {
  Box,
  Flex,
  IconButtonOutlined,
  IconSearch,
  IconX,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Theme,
  useBreakpointValue,
  useDisclosure,
  useTheme,
} from "Shared";

import { gf } from "Services/giphy";
import { useBadWords } from "Utils/badwords";

import rainbowCat from "./rainbowCat.webp";

type Props = {
  onSelectGif?: ({ giphyId }: { giphyId: string }) => void;
  containerRef: MutableRefObject<HTMLElement | null>;
};

type GiphyGridWrapperProps = {
  theme?: Theme;
};

const GiphyGridWrapper = styled.div<GiphyGridWrapperProps>`
  a.giphy-gif:focus {
    outline-width: ${({ theme }) => theme.focusOutline.normal.outlineWidth};
    outline-style: ${({ theme }) => theme.focusOutline.normal.outlineStyle};
    outline-color: ${({ theme }) => theme.focusOutline.normal.outlineColor};
    outline-offset: ${({ theme }) => theme.focusOutline.normal.outlineOffset};
  }
`;

export const GiphyPopover: FC<PropsWithChildren<Props>> = ({
  children,
  onSelectGif,
  containerRef,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState("");
  const [giphyGrid, setGifResult] = useState<any>();
  const { focusOutline } = useTheme();
  const widths = useBreakpointValue({
    base: { popoverWidth: 320, gridWidth: 304 },
    md: { popoverWidth: 400, gridWidth: 384 },
  });
  const badwords = useBadWords();

  function handleClose() {
    onClose();
    setQuery("");
  }

  function handleClearSearch() {
    const node = searchInputRef.current;

    // Clear the search query
    setQuery("");

    // Set focus back to search input
    node && node.focus();
  }

  function handleGifSearch(e: ChangeEvent<HTMLInputElement>) {
    let query = e.target.value;

    setQuery(query);

    if (
      badwords.includes(query.replace(/\s/g, "")) ||
      query.split(" ").some((word) => badwords.includes(word))
    ) {
      query = "disneyland";
    }

    const fetchGifs = () => {
      return (
        <GiphyGridWrapper>
          <GiphyGrid
            key={query}
            hideAttribution
            columns={2}
            gutter={8}
            width={widths ? widths.gridWidth : 0}
            fetchGifs={(offset: number) =>
              gf.search(query, { offset, rating: "g" })
            }
            borderRadius={6}
            onGifClick={handleGifClick}
          />
        </GiphyGridWrapper>
      );
    };

    setGifResult(fetchGifs);
  }

  function handleGifClick(gif: any, e: any) {
    e.preventDefault();
    onSelectGif && onSelectGif({ giphyId: gif.id });
    handleClose();
  }

  return (
    <Popover
      id="giphy-popover"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      initialFocusRef={searchInputRef}
      returnFocusOnClose={true}
      isLazy
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal containerRef={containerRef}>
        <PopoverContent
          boxShadow="xl"
          height="20rem"
          overflowY="scroll"
          width={widths ? widths.popoverWidth : 0}
          maxWidth={widths ? widths.popoverWidth : 0}
          borderRadius={12}
          borderColor="gray.300"
          _focus={{ ...focusOutline.normal }}
        >
          <Box as="form" p={2} position="sticky" top={0} bg="white" zIndex={1}>
            <InputGroup>
              <Input
                ref={searchInputRef}
                placeholder="Search GIPHY..."
                onChange={handleGifSearch}
                value={query}
                borderRadius={6}
              />
              <InputRightElement>
                {query.length > 0 ? (
                  <IconButtonOutlined
                    aria-label="Clear search"
                    size="sm"
                    border={0}
                    icon={<IconX />}
                    onClick={handleClearSearch}
                  />
                ) : (
                  <IconSearch color="gray.600" />
                )}
              </InputRightElement>
            </InputGroup>
          </Box>
          <Flex
            direction="column"
            p={2}
            align="center"
            justify="center"
            flex="1"
          >
            {query.length > 0 ? (
              giphyGrid
            ) : (
              <Image
                src={rainbowCat}
                height="10rem"
                width="60%"
                objectFit="cover"
                filter="grayscale(100%) invert(1) contrast(2)"
                borderRadius={6}
                opacity={0.1}
              />
            )}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
