import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminAddCoTeacherMutationResult,
  AdminAddCoTeacherMutationVariables,
  AdminDashboardLearnerGroupFragment,
} from "@tract/common/dist/graphql";

import { ADMIN_LEARNER_GROUP_FRAGMENT } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/graphql";

export const insert_learner_group_member_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  if (result.adminAddCoTeacher) {
    const variables = info.variables as AdminAddCoTeacherMutationVariables;

    const newMember = (result as AdminAddCoTeacherMutationResult["data"])
      ?.adminAddCoTeacher;

    const learnerGroup = cache.readFragment(
      ADMIN_LEARNER_GROUP_FRAGMENT,
      cache.keyOfEntity({
        __typename: "learner_group",
        id: variables.learnerGroupId,
      })
    );

    cache.writeFragment<AdminDashboardLearnerGroupFragment>(
      ADMIN_LEARNER_GROUP_FRAGMENT,
      {
        ...learnerGroup,
        members: [...learnerGroup.members, newMember],
      }
    );
  }
};
