import { PropsWithChildren } from "react";

import {
  BoxProps,
  useRadio,
  UseRadioProps,
  forwardRef,
  RadioProps,
} from "@chakra-ui/react";

import { Box } from "Shared";

type Props = UseRadioProps & {
  labelProps?: BoxProps;
} & RadioProps;

export const RadioCard = forwardRef<PropsWithChildren<Props>, "div">(
  ({ labelProps, ...props }, ref) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    // React doesn't like the isChecked property applied to a dom element
    const boxProps = { ...props };
    delete boxProps.isChecked;

    return (
      <Box as="label" {...labelProps}>
        <input {...input} disabled={props.isDisabled} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderRadius="full"
          fontWeight="bold"
          textTransform="capitalize"
          _checked={{
            bg: "brandFull.50",
            color: "brandFull.500",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          _hover={{
            color: "brandFull.500",
          }}
          _active={{
            bg: "brandFull.50",
            color: "brandFull.500",
          }}
          px={5}
          py={3}
          ref={ref}
          {...boxProps}
        />
      </Box>
    );
  }
);
