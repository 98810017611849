import React, { FC } from "react";

import { Accordion, AccordionItem, Box, ExternalLink } from "Shared";

export const FaqGettingStarted: FC = () => {
  return (
    <Accordion allowToggle={true} defaultIndex={0}>
      <AccordionItem heading="Is Tract really free?">
        Tract is free for teachers who sign up this school year. Learn more and
        get started today at{" "}
        <ExternalLink
          color="brand"
          fontWeight="bold"
          href="https://teach.tract.app"
          target="_blank"
        >
          teach.tract.app
        </ExternalLink>
      </AccordionItem>
      <AccordionItem heading="How can I be sure my data is secure on Tract?">
        <Box as="p" mb={4}>
          To respect your privacy, Tract collects the minimum amount of data
          necessary to operate: basic information about your account (if
          signed-in), your device and your child's profile and activity (if
          signed-in).
        </Box>
        <Box as="p">
          And no shady business here — the data we collect in-app is never
          shared with third parties or used for advertising purposes, so you can
          rest assured that your, and your child’s, data is secure on Tract.
        </Box>
      </AccordionItem>
    </Accordion>
  );
};
