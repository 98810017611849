import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  CollectionsForAdminQuery,
  InsertPathCollectionMutationResult,
} from "@tract/common/dist/graphql";

import { COLLECTIONS_QUERY } from "Pages/AdminDashboard/AdminCollections/graphql";

export const insert_path_collection_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.updateQuery<CollectionsForAdminQuery>(
    { query: COLLECTIONS_QUERY },
    (data) => {
      const typedResult = result as InsertPathCollectionMutationResult["data"];

      if (typedResult?.insert_path_collection_one) {
        data?.path_collection.push(typedResult.insert_path_collection_one);
        // sort by title
        data?.path_collection.sort((a, b) => {
          if (!a.title || !b.title) return 0;
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
      }

      return data;
    }
  );
};
