import React, { useEffect, useRef, useState } from "react";
import { Redirect, useLocation, useHistory, useRouteMatch } from "react-router";
import { useApolloClient } from "@apollo/client";

import {
  GetNodeCommentReplyCountDocument,
  GetNodeCommentsDocument,
  PathViewerNodeFragment,
  PathViewerPathFragment,
  ProjectHistoryDocument,
  useGetNodeCommentReplyCountQuery,
  useProjectHistoryQuery,
} from "@tract/common/dist/graphql";

import {
  Box,
  Button,
  Flex,
  HStack,
  IconAlertTriangle,
  IconCheck,
  Markdown,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "Shared";
import { ChallengeCard } from "./Challenge";
import { ChallengeProjects } from "./ChallengeProjects";
import { ChallengeCompleteModal } from "./ChallengeCompleteModal";
import { ApiVideoPlayer } from "Components/ApiVideoPlayer";
import { ProjectHistory } from "./ProjectHistory";
import { NodeComments } from "./NodeComments";

import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useFeature } from "Services/features";
import { captureException } from "Services/errors";

import { usePathRoute } from "./usePathRoute";
import { UserFile } from "Utils/UserFile";
import { UNTITLTED_CHALLENGE_NAME } from "Constants/paths";
import { VideoResources } from "./VideoResources";

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
};

const HISTORY_LIMIT = 10;
const MAX_DESCRIPTION_HEIGHT = 300;
const TRUNCATED_DESCRIPTION_HEIGHT = 200;

export const ContentNode: React.FC<Props> = ({ path, node }) => {
  const { params, baseUrl, isViewOnlyMode, isTeacherPath } = usePathRoute(path);
  const isSubmissionFeedbackEnabled = useFeature("submission-feedback");
  const tabsRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const showComments = !!useRouteMatch("/path/:slug/v/:videoId/comments");
  const history = useHistory();
  const client = useApolloClient();
  const { state } = useLocation<{
    refetchProjectHistory: boolean;
    refetchComments: boolean;
  }>();
  const [refetchingProjectHistory, setRefetchingProjectHistory] =
    useState(false);
  const [refetchingComments, setRefetchingComments] = useState(false);
  const { currentUser, isAdmin } = useSession();

  const project = node?.projects?.[0];
  const isCompleted = !!project;

  const [wasCompleted, setWasCompleted] = useState(false);

  const { data: projectHistoryData, refetch: refetchProjectHistory } =
    useProjectHistoryQuery({
      variables: {
        userId: currentUser.uid,
        nodeId: node.id,
        limit: HISTORY_LIMIT,
      },
    });
  const projectHistoryProjects = projectHistoryData?.projects;

  const { data } = useGetNodeCommentReplyCountQuery({
    variables: {
      nodeId: node.id,
      matches: "*{1}",
    },
  });
  const commentCount = data?.path_node_comment_aggregate.aggregate?.count;

  useEffect(() => {
    if (state?.refetchComments && !refetchingComments) {
      setRefetchingComments(true);

      client
        .refetchQueries({
          include: [GetNodeCommentsDocument, GetNodeCommentReplyCountDocument],
          onQueryUpdated(observableQuery) {
            return observableQuery.variables?.nodeId === node.id;
          },
        })
        .catch((err) => {
          captureException(err);
        })
        .finally(() => {
          setRefetchingComments(false);
        });

      history.replace({
        state: { refetchComments: undefined },
      });
    }
  }, [state, client, history, refetchingComments, node.id]);

  const completeChallenge = () => {
    onOpen();
    setWasCompleted(true);
    refetchProjectHistory();
  };

  useEffect(() => {
    setWasCompleted(false);
    refetchProjectHistory();
  }, [params.nodeId, node, setWasCompleted, refetchProjectHistory]);

  const { track } = useAnalytics();
  const [viewedChallengeId, setViewedChallengeId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (
      node.responsesEnabled &&
      state?.refetchProjectHistory &&
      !refetchingProjectHistory
    ) {
      setRefetchingProjectHistory(true);

      client
        .refetchQueries({
          include: [ProjectHistoryDocument],
          onQueryUpdated(observableQuery) {
            return observableQuery.variables?.nodeId === node.id;
          },
        })
        .catch((err) => {
          captureException(err);
        })
        .finally(() => {
          setRefetchingProjectHistory(false);
        });

      history.replace({
        state: { refetchProjectHistory: undefined },
      });
    }
  }, [state, client, history, refetchingProjectHistory, node]);

  useEffect(() => {
    if (viewedChallengeId !== node.id) {
      track(
        node.responsesEnabled
          ? ANALYTICS_EVENTS.CHALLENGE_VIEWED
          : ANALYTICS_EVENTS.MISSION_VIEWED,
        {
          pathId: path?.id,
          pathTitle: path?.title,
          pathAuthorId: path?.authorId,
          pathNodeTitle: node.title,
          pathNodeId: node.id,
        }
      );
      setViewedChallengeId(node.id);
    }
  }, [track, path, viewedChallengeId, node]);

  useEffect(() => {
    if (showComments) {
      setCurrentTabIndex(1);
      tabsRef.current?.scrollIntoView();
    }
  }, [showComments, tabsRef]);

  useEffect(() => {
    const descriptionContainer = descriptionRef.current;
    if (!descriptionContainer || !node?.caption) return;

    setShowMore(descriptionContainer.clientHeight > MAX_DESCRIPTION_HEIGHT);
  }, [node?.caption]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const videoFile = new UserFile(node.videoFile);
  const videoId =
    node?.videoFile?.integrations.find((i) => i.source === "api.video")
      ?.foreignId || "";

  if (!node) {
    return <Redirect to={baseUrl} />;
  }

  return (
    <Box pb={32} mx="auto">
      {videoId && (
        <Box w="full" bg="black">
          <Box maxW="96rem" mx="auto">
            <ApiVideoPlayer
              title={node.title || ""}
              videoId={videoId}
              fallbackSrc={videoFile.getOriginalUrl()}
              autoplay
            />
          </Box>
        </Box>
      )}
      <Box px={{ base: 4, lg: 6 }} maxW="60rem" mx="auto">
        <Box mt={10} mb={6}>
          <Text mb={2} fontSize="3xl" fontWeight="bold">
            {node?.title || UNTITLTED_CHALLENGE_NAME}
          </Text>
          <Box
            ref={descriptionRef}
            style={{
              height: showMore ? `${TRUNCATED_DESCRIPTION_HEIGHT}px` : "auto",
              overflow: showMore ? "hidden" : "auto",
            }}
          >
            {node?.caption && <Markdown source={node?.caption} />}
          </Box>
          {showMore && (
            <div
              style={{
                backgroundImage:
                  "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))",
                top: "-150px",
                position: "relative",
                paddingTop: "100px",
                marginBottom: "-150px",
                height: "150px",
              }}
            >
              <Button
                onClick={() => {
                  setShowMore(false);
                  setShowLess(true);
                }}
                margin="auto"
                marginTop="2"
                display="block"
                variant="outline"
                backgroundColor="white"
              >
                Show More
              </Button>
            </div>
          )}
          {showLess && (
            <Button
              onClick={() => {
                setShowMore(true);
                setShowLess(false);
              }}
              margin="auto"
              marginTop="2"
              display="block"
              variant="outline"
              backgroundColor="white"
            >
              Show Less
            </Button>
          )}
        </Box>
        {node.responsesEnabled && (
          <>
            <Flex
              py={4}
              mt={6}
              mb={10}
              align="center"
              justify="space-between"
              color="gray.600"
              fontSize="sm"
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              {!isViewOnlyMode ? (
                isCompleted || wasCompleted ? (
                  <HStack spacing={4}>
                    <IconCheck color="green.500" />
                    <Text fontSize="md">Challenge complete, nice work!</Text>
                  </HStack>
                ) : (
                  <Text fontSize="md">Submit your response below</Text>
                )
              ) : (
                <HStack spacing={4}>
                  <IconAlertTriangle color="orange.500" />
                  <Text fontSize="md">You are in view-only mode</Text>
                </HStack>
              )}
            </Flex>
            <Box mb={10}>
              <ChallengeCard
                path={path}
                node={node}
                onChallengeCompleted={completeChallenge}
              />
            </Box>
          </>
        )}
        {isSubmissionFeedbackEnabled &&
          node.responsesEnabled &&
          !!projectHistoryProjects && (
            <ProjectHistory projects={projectHistoryProjects} />
          )}
        <>
          <Tabs
            isManual
            ref={tabsRef}
            index={currentTabIndex}
            onChange={setCurrentTabIndex}
          >
            <TabList>
              {node.responsesEnabled && !isTeacherPath && <Tab>Gallery</Tab>}
              <Tab>Comments</Tab>
              {isAdmin && <Tab>Resources</Tab>}
            </TabList>
            <TabPanels mt={10}>
              {node.responsesEnabled && !isTeacherPath && (
                <TabPanel>
                  <ChallengeProjects path={path} node={node} />
                </TabPanel>
              )}
              <TabPanel>
                <NodeComments
                  pathId={path.id}
                  pathAuthorId={path.authorId}
                  nodeId={node.id}
                  pathTitle={path.title || ""}
                  nodeTitle={node.title || ""}
                  commentCount={commentCount || 0}
                />
              </TabPanel>
              {isAdmin && (
                <TabPanel>
                  <VideoResources node={node} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </>
        {node.responsesEnabled && (
          <ChallengeCompleteModal
            path={path}
            node={node}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </Box>
    </Box>
  );
};
