import React from "react";
import { forwardRef } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { PATH_SUBJECT_V2 } from "Constants/paths";
import { Box, FontAwesomeIcon, Grid, Link, Stack, Text } from "Shared";

type CardProps = {
  label: string;
  color?: string;
  bannerColor?: string;
  iconLabel?: IconProp;
  slug: string;
};

const SubjectCard = forwardRef(
  ({ label, iconLabel, slug, ...props }: CardProps, ref) => {
    return (
      <Stack
        ref={ref}
        as={Link}
        to={`/explore/subject/${slug}`}
        aria-label={label}
        direction={{ base: "row", lg: "column" }}
        align={{ base: "center", lg: "flex-start" }}
        justify={{ base: "stretch", lg: "center" }}
        spacing={{ base: 3, lg: 1 }}
        minH="4rem"
        px={{ base: 3, lg: 6 }}
        py={{ base: 2, lg: 5 }}
        bg="gray.100"
        borderRadius={{ base: "lg", lg: "xl" }}
        transition="150ms ease background-color, 150ms ease color"
        _hover={{ textDecoration: "none", bg: "gray.200" }}
        {...props}
      >
        {iconLabel && (
          <Box color="brand">
            <FontAwesomeIcon size={8} icon={iconLabel} />
          </Box>
        )}
        <Text fontWeight="bold" aria-hidden="true" lineHeight={1.25}>
          {label}
        </Text>
      </Stack>
    );
  }
);

type Props = {};

export const SubjectGrid: React.FC<Props> = (props) => {
  return (
    <Box
      position="relative"
      overflowX={{ base: "scroll", lg: "inherit" }}
      mx={{ base: -4, lg: 0 }}
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Box
        display={{ base: "inline-block", lg: "block" }}
        px={{ base: 4, lg: 0 }}
      >
        <Grid
          gap={{ base: 2, lg: 3 }}
          templateColumns={{
            base: "repeat(4, minmax(13rem, 1fr))",
            lg: "repeat(4, minmax(0, 1fr))",
          }}
          {...props}
        >
          {PATH_SUBJECT_V2.map(
            ({ standardizedShortLabel, slug, iconDuotone }) =>
              !!slug && (
                <SubjectCard
                  key={slug}
                  slug={slug}
                  label={standardizedShortLabel || ""}
                  iconLabel={iconDuotone}
                />
              )
          )}
        </Grid>
      </Box>
    </Box>
  );
};
