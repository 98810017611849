import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetPathFeedsByPathIdQueryResult,
  GetPathFeedsByPathIdQueryVariables,
  RemovePathFromPathFeedMutationResult,
  RemovePathFromPathFeedMutationVariables,
} from "@tract/common/dist/graphql";
import { GET_PATH_FEEDS_BY_PATH_ID } from "graphql/path-feeds";

export const delete_path_feed_by_pk: UpdateResolver<
  RemovePathFromPathFeedMutationResult["data"]
> = (result, args, cache, info) => {
  const variables = info.variables as RemovePathFromPathFeedMutationVariables;
  cache.updateQuery<
    GetPathFeedsByPathIdQueryResult["data"],
    GetPathFeedsByPathIdQueryVariables
  >(
    {
      query: GET_PATH_FEEDS_BY_PATH_ID,
      variables: {
        pathId: variables.pathId,
      },
    },
    (data) => {
      const index = data?.path_feed.findIndex(
        (pf) => pf.slug === result?.delete_path_feed_by_pk?.slug
      );
      if (index !== undefined) data?.path_feed.splice(index, 1);
      return data;
    }
  );
};
