import { SearchIndex } from "algoliasearch";
import { useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import { captureException } from "Services/errors";

type SearchConfig = {
  index: SearchIndex;
  params: any;
  query?: string;
  skip?: boolean;
  mergeResult?: boolean;
};

export const useAlgoliaSearch = <T>({
  index,
  query = "",
  params,
  skip = false,
  mergeResult = false,
}: SearchConfig) => {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [meta, setMeta] = useState<{
    nbHits: number;
    nbPages: number;
    page: number | string;
  } | null>();

  const reset = useCallback(() => {
    setData([]);
  }, []);

  useDeepCompareEffect(() => {
    function fetchData() {
      setIsLoading(true);

      try {
        index
          .search(query, params)
          .then(({ hits, nbHits, nbPages, page }) => {
            hits = hits.map(hit => ({ id: hit.objectID, ...hit }));
            mergeResult
              ? setData(prevData => [...prevData, ...hits] as any)
              : setData(hits as any);

            setMeta({ nbHits, nbPages, page });
          })
          .catch(err => {
            setError(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (err: any) {
        captureException(err);
        setError(err);
        setIsLoading(false);
      }
    }

    if (!skip) {
      fetchData();
    }
  }, [index, query, params, skip]);

  return {
    data,
    error,
    isLoading,
    meta,
    reset,
  };
};
