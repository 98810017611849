import React, {
  useEffect,
  forwardRef,
  useState,
  ReactElement,
  JSXElementConstructor,
} from "react";
import firebase from "firebase/compat";
import FileUploader from "react-firebase-file-uploader";

import {
  AspectRatio,
  Box,
  ButtonOutlined,
  Flex,
  IconUpload,
  Progress,
  Text,
} from "Shared";

import { useFileUpload } from "Services/useFileUpload";

type Props = {
  name: string;
  mediaType: "video" | "image";
  accept?: string;
  filePath: string;
  inputId: string;
  storage: firebase.storage.Storage;
  maxFileSize?: number;
  preview?: string;
  hasError?: boolean;
  filename?: string;
  disabled?: boolean;
  uploaderRef: React.RefObject<{
    startUpload: (file: File) => void;
  }>;
  onFileUploadSuccess?: (fullPath: string, filename: string) => void;
  onFileUpdate?: (file?: string, fileUrl?: string, error?: string) => void;
  renderUploading?: ({
    progress,
  }: {
    progress: number;
  }) => ReactElement<any, string | JSXElementConstructor<any>> | null;
  renderAction?: ({
    as,
    htmlFor,
    disabled,
  }: {
    as: string;
    htmlFor: string;
    disabled?: boolean;
  }) => ReactElement<any, string | JSXElementConstructor<any>> | null;
};

export const MediaUploader = forwardRef(
  (
    {
      name,
      accept,
      mediaType,
      filePath,
      inputId,
      preview,
      maxFileSize = 10,
      hasError,
      storage,
      filename,
      disabled = false,
      uploaderRef,
      onFileUpdate = () => {},
      onFileUploadSuccess = () => {},
      renderAction,
      renderUploading,
    }: Props,
    ref
  ) => {
    let {
      file,
      fileUrl,
      isUploading,
      progress,
      handleUploadStart,
      handleUploadError,
      handleProgress,
      handleUploadSuccess,
      storageRef,
      reset,
    } = useFileUpload({
      path: filePath,
      storage: storage,
    });

    const onUploadSuccess = (filename: string) => {
      handleUploadSuccess(filename);
      onFileUploadSuccess(`${filePath}/${filename}`, filename);
    };

    const onUploadStart = () => {
      handleUploadStart();
    };

    useEffect(() => {
      if (fileUrl) {
        onFileUpdate(file, fileUrl);
      }
    }, [file, fileUrl, onFileUpdate]);

    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const onFileChange = (event: Event) => {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length === 1) {
        const file = fileInput.files[0];

        setErrorMsg(null);
        reset();

        if (file.size > maxFileSize * 1024 * 1024) {
          onFileUpdate(undefined, undefined);
          setErrorMsg(`File cannot exceed ${maxFileSize} MB`);
          return;
        }

        if (
          !file.type.startsWith("image/") &&
          !file.type.startsWith("video/")
        ) {
          onFileUpdate(undefined, undefined);
          setErrorMsg(`Must be a valid ${mediaType}`);
          return;
        }

        uploaderRef.current?.startUpload(file);
      }
    };

    if (!accept) {
      if (mediaType === "image") {
        accept = "image/*";
      }

      if (mediaType === "video") {
        accept = ".mp4,.mov,.ogv,.webm";
      }
    }

    if (isUploading && renderUploading) {
      return renderUploading({ progress });
    }

    if (!isUploading && renderAction) {
      return (
        <>
          {renderAction({ as: "label", htmlFor: inputId, disabled })}
          <FileUploader
            name={name}
            accept={accept}
            ref={uploaderRef}
            onChange={onFileChange}
            hidden
            id={inputId}
            storageRef={storageRef}
            filename={filename}
            randomizeFilename={!filename}
            onUploadStart={onUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={onUploadSuccess}
            onProgress={handleProgress}
            disabled={disabled}
          />
        </>
      );
    }

    return (
      <Box position="relative">
        <AspectRatio
          ratio={16 / 9}
          border={preview ? "none" : "2px dashed"}
          borderColor={hasError || errorMsg ? "red.500" : "gray.300"}
          borderRadius="12px"
          bg="gray.50"
          overflow="hidden"
        >
          <>
            {isUploading && (
              <Box borderRadius="12px" bg="gray.50" p={4}>
                <Flex align="center" flex={1}>
                  <Progress
                    bg="gray.200"
                    height="16px"
                    value={progress}
                    colorScheme="green"
                    flex="1"
                  />
                  <Text
                    ml={4}
                    color="gray.600"
                    fontWeight="700"
                    fontSize="16px"
                  >
                    {progress}%
                  </Text>
                </Flex>
              </Box>
            )}
            {!isUploading && !preview && !file && (
              <>
                <Box>
                  <ButtonOutlined
                    as="label"
                    size="lg"
                    cursor="pointer"
                    htmlFor={inputId}
                    alignSelf="center"
                    leftIcon={<IconUpload />}
                    textTransform="capitalize"
                    disabled={disabled}
                  >
                    Upload {mediaType}
                    <FileUploader
                      name={name}
                      accept={accept}
                      ref={uploaderRef}
                      onChange={onFileChange}
                      hidden
                      id={inputId}
                      storageRef={storageRef}
                      filename={filename}
                      randomizeFilename={!filename}
                      onUploadStart={onUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={onUploadSuccess}
                      onProgress={handleProgress}
                      disabled={disabled}
                    />
                  </ButtonOutlined>
                </Box>
                <Text
                  color="red.500"
                  mt={10}
                  display="block"
                  pointerEvents="none"
                >
                  {errorMsg}
                </Text>
              </>
            )}
          </>
        </AspectRatio>
      </Box>
    );
  }
);
