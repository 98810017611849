import { FC, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { LayoutCentered } from "Components/LayoutCentered";
import { firebaseClient } from "Services/firebase";
import { captureException } from "Services/errors";
import { PageContent } from "Components/PageContent";
import { Helmet } from "react-helmet";
import { ButtonSolid, FormLabel, Input, Text } from "Shared";
import { useRef } from "react";
import { FormControl } from "@chakra-ui/react";
import { post } from "Services/api";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  token: string;
};

export const EMAIL_FOR_SIGN_IN_KEY = "emailForSignIn";

export const SignInWithEmailLink: FC<Props> = ({ token }) => {
  const { track, identify } = useAnalytics();
  const [emailAvailable, setEmailAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [signedIn, setSignedIn] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (signedIn) return;

    async function verify() {
      const emailForSignIn =
        window.localStorage.getItem(EMAIL_FOR_SIGN_IN_KEY) || email;
      setEmailAvailable(!!emailForSignIn);
      if (!emailForSignIn) {
        return;
      }
      setLoading(true);
      try {
        const {
          data: { signInLink, isNewUser },
        } = await post("/v1/onboard/educator/complete", null, {
          token,
          generateSignInLink: true,
        });

        const isEmailLinkValid = firebaseClient
          .auth()
          .isSignInWithEmailLink(signInLink);

        if (isEmailLinkValid) {
          const userRecord = await firebaseClient
            .auth()
            .signInWithEmailLink(emailForSignIn, signInLink);

          if (isNewUser && userRecord.user) {
            identify(userRecord.user.uid, {
              email: emailForSignIn,
            });

            track(ANALYTICS_EVENTS.EDUCATOR_EMAIL_VERIFIED);
          }

          setSignedIn(true);

          window.localStorage.removeItem(EMAIL_FOR_SIGN_IN_KEY);
        }
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    }

    verify();
  }, [email, token, identify, track, signedIn]);

  if (!emailAvailable) {
    return (
      <PageContent pt={16} pb={32} px={0} w="480px" textAlign="center">
        <Helmet>
          <title>Complete Sign Up</title>
        </Helmet>
        <Text fontSize="3xl" fontWeight="bold">
          Almost there...
        </Text>
        <Text fontSize="lg" color="gray.600">
          Enter your email to complete the sign up
        </Text>
        <FormControl mt={4} isRequired>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            type="email"
            size="lg"
            id="email"
            name="email"
            placeholder="name@school-email.edu"
            ref={inputRef}
            formNoValidate
          />
        </FormControl>
        <ButtonSolid
          mt={4}
          size="lg"
          width="full"
          onClick={() => setEmail(inputRef.current?.value || "")}
        >
          Continue
        </ButtonSolid>
      </PageContent>
    );
  }

  if (loading) {
    return <LayoutCentered isLoading={loading} />;
  }

  if (signedIn) {
    return (
      <PageContent pt={16} pb={32} px={0} w="480px" textAlign="center">
        <Text fontSize="3xl" fontWeight="bold">
          Email Verified!
        </Text>
        <Text fontSize="lg" color="gray.600">
          Hang tight, we are redirecting you now...
        </Text>
      </PageContent>
    );
  }

  return <Redirect to="/" />;
};
