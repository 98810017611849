import React, { FC } from "react";
import { GridProps } from "@chakra-ui/react";

import { LayoutCentered } from "Components/LayoutCentered";
import { PathCard } from "Components/PathCard";

import { EmptyState, Text } from "Shared";

import { PathCardFragment } from "@tract/common/dist/graphql";
import { GridPaths } from "Components/GridContainer";

import { ViewSource } from "Types/Path";

type Props = {
  isLoading: boolean;
  paths?: PathCardFragment[];
  source: ViewSource;
} & GridProps;

export const PathList: FC<Props> = ({ isLoading, paths, source, ...props }) => {
  if (isLoading) {
    return <LayoutCentered isLoading />;
  }

  if (!paths || !paths.length) {
    return (
      <EmptyState headline="No Paths" mx="auto">
        <Text fontSize="md" color="gray.600">
          There are no paths that match the selected filter value.
        </Text>
      </EmptyState>
    );
  }

  return (
    <GridPaths {...props}>
      {paths.map((path, i) => (
        <PathCard key={i} path={path} source={source} />
      ))}
    </GridPaths>
  );
};
