import { useState } from "react";
import { gql, useMutation, useQuery } from "urql";

import { useToast } from "Shared";

import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";

import { PROJECT_STATS_FRAGMENT } from "Components/ProjectCard/graphql/index";
import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";

import {
  LikeProjectMutation,
  LikeProjectMutationVariables,
  ProjectCardFragment,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
  UserProjectLikesQuery,
  UserProjectLikesQueryVariables,
} from "@tract/common/dist/graphql";

import { createNotification } from "@tract/common/dist/utils/create-notification";

export const MAX_USER_LIKES = 5;

export type LikeCounts = {
  projectCount: number;
  userCount: number;
};

export type HandleLike = () => void;

const PROJECT_REACTION_FRAGMENT = gql`
  fragment projectReaction on project_reaction {
    projectId
    userId
    count
  }
`;

const USER_PROJECT_LIKES_QUERY = gql`
  ${PROJECT_REACTION_FRAGMENT}

  query UserProjectLikes($projectId: uuid!, $userId: String!) {
    projectReaction: project_reaction_by_pk(
      projectId: $projectId
      userId: $userId
    ) {
      ...projectReaction
    }
  }
`;

const LIKE_PROJECT_MUTATION = gql`
  ${PROJECT_REACTION_FRAGMENT}
  ${PROJECT_STATS_FRAGMENT}

  mutation LikeProject($projectId: uuid!, $userId: String!, $count: Int = 1) {
    insert_project_reaction_one(
      object: { projectId: $projectId, userId: $userId, count: $count }
      on_conflict: { constraint: project_reaction_pkey, update_columns: count }
    ) {
      ...projectReaction
    }

    update_project_stats_by_pk(
      pk_columns: { projectId: $projectId }
      _inc: { likeCount: 1 }
    ) {
      ...projectStats
    }
  }
`;

export const useLikeProject = (project: ProjectCardFragment) => {
  const { currentUser } = useSession();
  const toast = useToast();
  const { track } = useAnalytics();
  const [saving, setSaving] = useState(false);

  const [{ fetching: loading, data }] = useQuery<
    UserProjectLikesQuery,
    UserProjectLikesQueryVariables
  >({
    query: USER_PROJECT_LIKES_QUERY,
    variables: {
      projectId: project.id,
      userId: currentUser.uid,
    },
  });

  const projectReaction = data?.projectReaction;
  let currentCount =
    !!projectReaction?.count && !loading ? projectReaction.count : 0;

  const [, likeProject] = useMutation<
    LikeProjectMutation,
    LikeProjectMutationVariables
  >(LIKE_PROJECT_MUTATION);

  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);

  async function handleLike() {
    if (loading || saving) {
      return;
    }

    setSaving(true);

    // Don't allow likes past MAX_USER_LIKES
    if (currentCount >= MAX_USER_LIKES) {
      return;
    }

    const hasPreviouslyLiked = !!projectReaction;

    const projectLikeCount = (project.stats?.likeCount || 0) + 1;
    const newUserCount = currentCount + 1;
    try {
      const mutationResult = await likeProject({
        projectId: project.id,
        userId: currentUser.uid,
        count: newUserCount,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      track(ANALYTICS_EVENTS.PROJECT_LIKED, {
        projectId: project.id,
        projectLikeCount: projectLikeCount,
        projectUserLikeCount: newUserCount,
        pathId: project.node?.id,
      });

      // Only notify on first like and don't notify for your own projects
      if (!hasPreviouslyLiked) {
        createFeedActivity({
          apiKey: process.env.REACT_APP_STREAM_API_KEY,
          feedSlug: "notification",
          feedUserId: project.user.id,
          activity: createNotification({
            type: "actor",
            actor: currentUser.id,
            entity: "project",
            entityId: project.id,
            verb: "like",
            description: "liked your project",
            targetPath: `/project/${project.id}`,
          }),
        });
      }
    } catch (err: any) {
      captureException(err);
      toast({
        title: "Error liking project",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSaving(false);
    }
  }

  return { handleLike, likes: currentCount, isReady: !loading };
};
