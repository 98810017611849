import { forwardRef } from "@chakra-ui/react";
import { FORUM_EDUCATOR_INVITE_URL } from "Services/forum";

import { Box, Button, ExternalLink, Flex, IconForum, Text } from "Shared";

export const ForumBanner = forwardRef(({ post, ...props }, ref) => {
  return (
    <Box
      px={10}
      py={8}
      borderRadius="2xl"
      bg="brandFull.50"
      color="brandFull.500"
      {...props}
      ref={ref}
    >
      <Flex align="flex-start">
        <Box mr={10} flex={1}>
          <Text fontWeight="bold" fontSize="3xl" as="h1" mb={1}>
            Teacher's Lounge
          </Text>
          <Text fontSize="lg" mb={4}>
            Connect with like-minded educators, share knowledge, and collaborate
            with the Tract team.
          </Text>
          <Button
            as={ExternalLink}
            href={FORUM_EDUCATOR_INVITE_URL}
            target="_blank"
            size="lg"
            variant="solid"
            colorScheme="brandFull"
          >
            Accept Invite
          </Button>
        </Box>
        <IconForum boxSize={24} color="brandFull.400" />
      </Flex>
    </Box>
  );
});
