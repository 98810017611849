import axios, { AxiosRequestConfig } from "axios";
import firebase from "firebase/compat";

type TAuth = firebase.User | null;

async function request<R = any>(
  requestType: "get" | "post" | "patch" | "delete",
  path: string,
  auth?: TAuth,
  data?: any,
  config?: AxiosRequestConfig
) {
  const origin = process.env.REACT_APP_WEB_API_ORIGIN;

  let token = "";
  if (auth) {
    token = await auth?.getIdToken();
  }

  const axiosConfig = {
    ...config,
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };

  if (requestType === "post") {
    return axios.post<R>(`${origin}${path}`, data, axiosConfig);
  }

  if (requestType === "patch") {
    return axios.patch<R>(`${origin}${path}`, data, axiosConfig);
  }

  if (requestType === "delete") {
    return axios.delete<R>(`${origin}${path}`, axiosConfig);
  }

  return axios.get<R>(`${origin}${path}`, axiosConfig);
}

export function post<R = any>(
  path: string,
  auth?: TAuth,
  data?: any,
  config?: AxiosRequestConfig
) {
  return request<R>("post", path, auth, data, config);
}

export function patch<R = any>(
  path: string,
  auth?: TAuth,
  data?: any,
  config?: AxiosRequestConfig
) {
  return request<R>("patch", path, auth, data, config);
}

export function del<R = any>(
  path: string,
  auth?: TAuth,
  data?: any,
  config?: AxiosRequestConfig
) {
  return request<R>("delete", path, auth, data, config);
}

export function get<R = any>(
  path: string,
  auth?: TAuth,
  data?: any,
  config?: AxiosRequestConfig
) {
  return request<R>("get", path, auth, data, config);
}
