import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  GetProjectDetailsQueryResult,
  GetProjectDetailsQueryVariables,
  GiveAwardMutationResult,
} from "@tract/common/dist/graphql";
import { GET_PROJECT_DETAILS } from "Pages/ProjectDetails/graphql";

export const insert_project_award_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const item = result as GiveAwardMutationResult["data"];
  cache.updateQuery<
    GetProjectDetailsQueryResult["data"],
    GetProjectDetailsQueryVariables
  >(
    {
      query: GET_PROJECT_DETAILS,
      variables: {
        id: item?.projectComment?.projectId,
      },
    },
    (data) => {
      const newAward = item?.update_project_award_by_pk;
      if (!!newAward) {
        if (
          !!data?.project?.awards.filter(
            (award) => award.awardId === newAward.awardId
          ).length
        ) {
          data.project.awards.filter(
            (award) => award.awardId === newAward.awardId
          )[0].count++;
        } else {
          data?.project?.awards?.unshift({
            ...newAward,
          });
        }
      }
      return data;
    }
  );
};
