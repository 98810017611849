import React, { FC, PropsWithChildren } from "react";
import {
  useRouteMatch,
  useParams,
  useHistory,
  Redirect,
} from "react-router-dom";
import { useQuery } from "urql";

import {
  Box,
  Flex,
  IconButton,
  IconPlus,
  Link,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "Shared";

import { createContext } from "Utils";

import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";

import { useSession } from "Services/session";
import { useCreateClassroomMutation } from "Services/classroom";
import { captureException, ERROR_TAGS } from "Services/errors";

import { SideNavGroupLabel } from "Components/SideNav/SideNavGroupLabel";
import { SideNavTitle } from "Components/SideNav/SideNavTitle";
import { CreateClassModal } from "Components/CreateClassroomModal";

import { isParentUser, isTeacherUser } from "Types/User";
import {
  ClassroomLearnerGroupFragment,
  ClassroomsByTeacherIdQueryResult,
  ClassroomsByTeacherIdQueryVariables,
} from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";

type Props = {};

export const [Provider, useTeacherClassroomsContext] = createContext<{
  classrooms: ClassroomLearnerGroupFragment[];
  activeClassroom: ClassroomLearnerGroupFragment;
}>({ strict: true });

export const LayoutTeach: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const { currentUser } = useSession();
  const toast = useToast();
  const history = useHistory();

  const isParent = isParentUser(currentUser);
  const isTeacher = isTeacherUser(currentUser);

  const isClassroom = !!useRouteMatch("/classroom");
  const isSubmissions = !!useRouteMatch("/submissions");
  const isComments = !!useRouteMatch("/comments");
  const isLearners = !!useRouteMatch("/learners");
  const isInsights = !!useRouteMatch("/insights");

  const { classId }: { classId?: string } = useParams();

  const {
    isOpen: isCreateClassModalOpen,
    onOpen: openCreateClassModal,
    onClose: closeCreateClassModal,
  } = useDisclosure();

  const [{ data, fetching: loading }] = useQuery<
    ClassroomsByTeacherIdQueryResult["data"],
    ClassroomsByTeacherIdQueryVariables
  >({
    query: CLASSROOMS_BY_TEACHER_ID_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      teacherUserId: currentUser.id,
    },
  });

  const createClassroom = useCreateClassroomMutation();

  const classrooms = data?.learnerGroups || [];

  const onCreateClassSubmit = async (values: {
    classroomName: string;
    numStudents: string;
  }) => {
    if (!classrooms || !currentUser.orgId) return;

    try {
      const result = await createClassroom({
        name: values.classroomName,
        orgId: currentUser.orgId,
        userId: currentUser.id,
      });

      if (!result.data?.createClassroom) {
        throw new Error("classroom not found");
      }

      toast({
        title: "Class Created!",
        status: "success",
      });

      closeCreateClassModal();
      history.push(`/classroom/${classId}/students`);
    } catch (error: any) {
      captureException(error, { tags: { feature: ERROR_TAGS.CLASSROOMS } });
      toast({
        title: "Error creating the class",
        status: "error",
      });
    }
  };

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  const activeClassroom =
    classrooms.find(({ id }) => id === classId) || classrooms[0];

  if (isClassroom) {
    if (!classId && classrooms.length) {
      return <Redirect to={`/classroom/${classrooms[0].id}`} />;
    }
  }

  if (!activeClassroom) {
    return <Redirect to="/" />;
  }

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
    >
      {(isParent || isTeacher) && (
        <SideNav>
          <SideNavTitle
            iconRight={
              isTeacher ? (
                <Tooltip
                  label="New Class"
                  hasArrow
                  placement="right"
                  openDelay={300}
                >
                  <IconButton
                    variant="outline"
                    size="md"
                    icon={<IconPlus />}
                    aria-label="New Class"
                    onClick={openCreateClassModal}
                  />
                </Tooltip>
              ) : null
            }
          >
            {isTeacher ? "Teach" : "Parents"}
          </SideNavTitle>
          <VStack w="100%" align="stretch" spacing={2} p={2}>
            {isTeacher && (
              <>
                <SideNavGroupLabel>Your Classes</SideNavGroupLabel>
                {classrooms?.map((classroom) => (
                  <SideNavLink
                    key={classroom.id}
                    as={Link}
                    to={`/classroom/${classroom.id}/students`}
                    isActive={classroom.id === classId}
                    isNested
                  >
                    {classroom.name}
                  </SideNavLink>
                ))}
              </>
            )}
            {isParent && !isTeacher && (
              <SideNavLink as={Link} to="/learners" isActive={isLearners}>
                Learners
              </SideNavLink>
            )}
            <SideNavLink
              as={Link}
              to={`/submissions?learnerGroupId=${activeClassroom.id}`}
              isActive={isSubmissions}
            >
              Submissions
            </SideNavLink>
            <SideNavLink as={Link} to="/comments" isActive={isComments}>
              Comments
            </SideNavLink>
            {isParent && isTeacher && (
              <SideNavLink as={Link} to="/insights" isActive={isInsights}>
                Insights
              </SideNavLink>
            )}
          </VStack>
        </SideNav>
      )}
      <Box w="100%" overflowX={{ base: "scroll", lg: "auto" }}>
        <Provider value={{ classrooms, activeClassroom }}>{children}</Provider>
      </Box>
      <CreateClassModal
        learnerGroups={classrooms}
        isOpen={isCreateClassModalOpen}
        onClose={closeCreateClassModal}
        onSubmit={onCreateClassSubmit}
        includeNumStudents={true}
      />
    </Flex>
  );
};
