import { useEffect, useState, FC, PropsWithChildren } from "react";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";

import { useSession } from "./session";

import { isParentUser, isTeacherUser } from "Types/User";

import { captureException } from "Services/errors";

import { createContext } from "Utils/createContext";

type ChatContextProps = {
  chatClient: StreamChat;
  isLoading: boolean;
};

const STREAM_CHAT_TIMEOUT_MS = 25000;

const [ChatContextProvider, useChatContext] = createContext<ChatContextProps>();

type ChatProviderProps = {
  apiKey: string;
  token: string;
};

export const ChatProvider: FC<PropsWithChildren<ChatProviderProps>> = ({
  apiKey,
  token,
  ...props
}) => {
  const { currentUser } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const chatClient = StreamChat.getInstance(apiKey, {
    timeout: STREAM_CHAT_TIMEOUT_MS,
  });

  useEffect(() => {
    const isParentOrTeacher =
      isTeacherUser(currentUser) || isParentUser(currentUser);

    async function setChatUser() {
      if (chatClient?.userID && chatClient?.userID === currentUser.uid) {
        setIsLoading(false);

        return;
      }

      if (chatClient?.userID && chatClient?.userID !== currentUser.uid) {
        try {
          await chatClient.disconnectUser();
        } catch (err: any) {
          captureException(err);
        }
      }

      try {
        await chatClient.connectUser(
          {
            id: currentUser.uid,
            name:
              (isParentOrTeacher
                ? `${currentUser.firstName} ${currentUser.lastName}`
                : currentUser.username) || "Unknown",
            image: currentUser.avatar,
          },
          token
        );
      } catch (err: any) {
        captureException(err);
      } finally {
        setIsLoading(false);
      }
    }

    if (!!token) {
      setChatUser();
    }
  }, [currentUser, chatClient, token]);

  return <ChatContextProvider value={{ chatClient, isLoading }} {...props} />;
};

export function useChat() {
  const { chatClient, isLoading } = useChatContext();

  async function reset() {
    return chatClient.disconnectUser();
  }

  return { chatClient, reset, isLoading };
}
