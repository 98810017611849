import { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  Link as RouterLink,
  useParams,
  useHistory,
} from "react-router-dom";
import { useMutation, useQuery } from "urql";

import {
  Alert,
  BadgeOutlined,
  ButtonOutlined,
  ButtonSolid,
  EmptyState,
  FormControl,
  FormLabel,
  HStack,
  IconTrash,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "Shared";
import { PageHeader } from "Components/PageHeader";
import { LayoutCentered } from "Components/LayoutCentered";
import { TeachersTable } from "./TeachersTable";
import { captureException } from "Services/errors";
import {
  AdminDeletePendingOrgMutation,
  AdminDeletePendingOrgMutationVariables,
  AdminOrgByIdQuery,
  AdminOrgByIdQueryVariables,
} from "@tract/common/dist/graphql";
import { ADMIN_DELETE_PENDING_ORG_MUTATION, ADMIN_ORG_QUERY } from "../graphql";

export const OrganizationDetails = () => {
  const {
    isOpen: isDeleteConfirmModalOpen,
    onClose: closeDeleteConfirmModal,
    onOpen: openDeleteConfirmModal,
  } = useDisclosure();
  const { orgId } = useParams<{ orgId?: string }>();
  const [{ data, fetching: loading }] = useQuery<
    AdminOrgByIdQuery,
    AdminOrgByIdQueryVariables
  >({
    query: ADMIN_ORG_QUERY,
    variables: { orgId: orgId || "" },
    requestPolicy: "network-only",
  });
  const org = data?.organization_by_pk;

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  if (!org) {
    return <Redirect to="/admin/organizations" />;
  }

  return (
    <>
      <PageHeader
        renderTitle={
          <HStack>
            <Text fontWeight="bold" fontSize="4xl">
              {org.name}
            </Text>
            <BadgeOutlined colorScheme={!org.verified ? "orange" : "green"}>
              {!org.verified ? "pending" : "active"}
            </BadgeOutlined>
          </HStack>
        }
        renderActions={
          <ButtonSolid
            as={RouterLink}
            to={`/admin/organizations/${org.id}/edit?referrer=teachers`}
            size="lg"
            colorScheme="brandFull"
          >
            {!org.verified ? "Review" : "Edit"}
          </ButtonSolid>
        }
        renderTabs={
          <HStack spacing={6} mt={6}>
            <FormControl width="xs">
              <FormLabel htmlFor="role" fontWeight="bold">
                Role
              </FormLabel>
              <Select variant="filled" name="role" id="role">
                <option value="teacher">Teacher</option>
                <option value="student" disabled>
                  Student
                </option>
              </Select>
            </FormControl>
            {!org.verified && (
              <ButtonOutlined
                aria-label="Delete organization"
                alignSelf="flex-end"
                leftIcon={<IconTrash />}
                onClick={openDeleteConfirmModal}
              >
                Delete
              </ButtonOutlined>
            )}
          </HStack>
        }
      />
      <Switch>
        <Route path="/admin/organizations/:orgId/teachers">
          <TeachersTable />
        </Route>
        <Route path="/admin/organizations/:orgId/students">
          <EmptyState headline="Coming soon!" />
        </Route>
        <Redirect to="/admin/organizations/:orgId/teachers" />
      </Switch>
      <ConfirmDeleteOrgModal
        organization={org}
        isOpen={isDeleteConfirmModalOpen}
        onClose={closeDeleteConfirmModal}
      />
    </>
  );
};

const ConfirmDeleteOrgModal = ({
  organization,
  isOpen,
  onClose,
}: {
  organization: AdminOrgByIdQuery["organization_by_pk"];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const history = useHistory();
  const [deleting, setDeleting] = useState(false);
  const [, deleteOrg] = useMutation<
    AdminDeletePendingOrgMutation,
    AdminDeletePendingOrgMutationVariables
  >(ADMIN_DELETE_PENDING_ORG_MUTATION);
  const onDelete = async () => {
    setDeleting(true);

    try {
      const { data } = await deleteOrg({ orgId: organization?.id || "" });

      if (data?.delete_organization?.affected_rows === 1) {
        toast({
          status: "success",
          title: "Organization Deleted",
        });
        history.replace(`/admin/organizations`);
      } else {
        toast({
          status: "error",
          title: "Organization could not be deleted",
        });
      }
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Something went wrong",
      });
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete Organization?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this organization? This action is{" "}
          <Text as="span" fontWeight="bold">
            permanent
          </Text>{" "}
          and{" "}
          <Text as="span" fontWeight="bold">
            irreversible
          </Text>
          <Alert status="error" mt={2}>
            Make sure to delete all the linked users before performing this
            action.
          </Alert>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined disabled={deleting} size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={deleting}
            isLoading={deleting}
            size="lg"
            colorScheme="red"
            onClick={onDelete}
          >
            Yes, Delete
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
