import { gql } from "graphql-request";

export const PATH_CARD_FRAGMENT = gql`
  fragment PathCard on path {
    _schemaVersion
    authorId
    cover
    coverAnimated
    id
    interestArea
    isPublished
    isTractOriginal
    subjects
    gradeLevels
    orgId
    publishedAt
    slug
    status
    title
    totalXP
    isVisibleUnauthed
    coverFile {
      id
      filename
      transforms {
        fileId
        filename
      }
    }
    coverAnimatedFile {
      id
      filename
      transforms {
        fileId
        filename
      }
    }
    tags
    user {
      id: firestoreId
      avatar
      firstName
      lastName
      username
      creatorLevel
    }
  }
`;
