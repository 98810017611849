import { gql } from "@apollo/client";

export const GET_USERS_BY_USER_IDS_QUERY = gql`
  query GetUsersByUserIds($userIds: [String!]) {
    user(where: { firestoreId: { _in: $userIds } }) {
      id: firestoreId
      firstName
      username
      userType
    }
  }
`;
