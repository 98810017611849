import {
  Switch,
  Route,
  useParams,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

import {
  Box,
  Button,
  HStack,
  TabLink,
  TabList,
  Tabs,
  useRouteTabs,
} from "Shared";

import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { CoinValue } from "Components/CoinValue";

import { PrizeStat } from "./PrizeStat";
import { PrizeList } from "./PrizeList";

import { useSession } from "Services/session";
import { useCoins } from "Services/hooks/useCoins";

import { isKidUser } from "Types/User";
import { SHOP_ITEMS_QUERY } from "./graphql";
import {
  ShopItemsQuery,
  ShopItemsQueryVariables,
} from "@tract/common/dist/graphql";
import { useQuery } from "urql";
import { PrizeDetails } from "./PrizeDetails";

import { ShopCategory } from "Types/Prize";
import { useIntercom } from "Services/intercom";

function PrizeBoardV2() {
  const { currentUser } = useSession();
  const { balance } = useCoins();
  const { category } = useParams<{ category: string }>();
  const isShopItemDetails = !!useRouteMatch("/shop/item");
  const { show } = useIntercom();

  const tabRoutes = [
    { path: `/shop/category/all`, name: "All" },
    {
      path: `/shop/category/${ShopCategory.CreatorGoodies}`,
      name: "Creator Goodies",
    },
    { path: `/shop/category/${ShopCategory.Experiences}`, name: "Experiences" },
    { path: `/shop/category/${ShopCategory.Donations}`, name: "Donations" },
    { path: `/shop/category/${ShopCategory.Merch}`, name: "Merch" },
  ];

  const { tabsProps } = useRouteTabs({
    routes: tabRoutes,
  });

  const [{ data, fetching }] = useQuery<
    ShopItemsQuery,
    ShopItemsQueryVariables
  >({
    query: SHOP_ITEMS_QUERY,
    pause: !category,
    variables: {
      limit: 50,
      offset: 0,
      tags: category === "all" ? {} : { _has_key: category },
    },
  });

  const items = data?.items || [];

  if (!!currentUser.organization && !currentUser.organization.shopEnabled) {
    return <Redirect to="/" />;
  }

  if (!category && !isShopItemDetails) {
    return <Redirect to={tabRoutes[0].path} />;
  }

  return (
    <>
      <PageHeader
        title="Shop"
        renderActions={
          <HStack spacing={10}>
            {isKidUser(currentUser) && (
              <PrizeStat label="Coin Balance">
                <CoinValue size="xl" value={balance} />
              </PrizeStat>
            )}
            <Button
              variant="solid"
              colorScheme="brandFull"
              size="lg"
              onClick={show}
            >
              Request Item
            </Button>
          </HStack>
        }
        renderTabs={
          isShopItemDetails ? null : (
            <Box overflowX={{ base: "scroll", md: "initial" }}>
              <Tabs size="lg" isManual {...tabsProps}>
                <TabList>
                  {tabRoutes.map((route) => (
                    <TabLink to={route.path} key={route.path}>
                      {route.name}
                    </TabLink>
                  ))}
                </TabList>
              </Tabs>
            </Box>
          )
        }
      />
      <Switch>
        <Route path="/shop/category/:category">
          <PrizeList items={items} loading={fetching} />
        </Route>
        <Route path="/shop/item/:itemId">
          <PrizeDetails />
        </Route>
      </Switch>
    </>
  );
}

export function PrizeBoard() {
  return (
    <PageApp>
      <PrizeBoardV2 />
    </PageApp>
  );
}
