import React, { FC } from "react";

import {
  Accordion,
  AccordionItem,
  Box,
  ExternalLink,
  Link,
  Text,
  VStack,
} from "Shared";

export const FaqEducators: FC = () => {
  return (
    <Accordion allowToggle={true} defaultIndex={0}>
      <AccordionItem heading="How do I set up my students on Tract?">
        <Box as="p" mb={4}>
          Setting up your classroom on Tract is easy!
        </Box>
        <Box as="p"></Box>Once you{" "}
        <Link to="/get-started/educator" fontWeight="bold" color="brand">
          sign up
        </Link>{" "}
        you'll be sent an educator code to invite your students to set up their
        accounts, where they'll also be able to sign in with their Google or
        Clever account.
      </AccordionItem>
      <AccordionItem heading="How should I use Tract in the classroom?">
        <Box as="p" mb={4}>
          Tract doesn't replace your lessons and projects—it supercharges them.
        </Box>
        <Box as="p">
          Through our collection of turnkey{" "}
          <ExternalLink
            color="brand"
            fontWeight="bold"
            href="https://help.tract.app/en/articles/6439333-tract-project-guides"
            target="_blank"
          >
            project guides
          </ExternalLink>
          , teachers can easily integrate short videos from our library into
          classwork, turning kids' media consumption into a launchpad for an
          enriching, educational experience that supports project-based
          learning, Genius Hour, social and emotional learning, GATE, and more.
        </Box>
      </AccordionItem>
      <AccordionItem heading="Are there any requirements our IT department should be aware of before implementing Tract in our school?">
        Tract hosts its videos on our private server, which ensures all videos
        are safe, ad-free, and reviewed before publishing. This also means
        there's a 99% chance everything will work for you without the need for
        any IT assistance 🥳. In the event you either (1) cannot access our site
        or (2) cannot see or play videos, then your school network firewall is
        likely the cause of the issue, and you can have your IT department{" "}
        <ExternalLink
          color="brand"
          fontWeight="bold"
          href="https://help.tract.app/en/articles/4793480-it-check"
          target="_blank"
        >
          follow these instructions
        </ExternalLink>
        .
      </AccordionItem>

      <AccordionItem heading="How do you manage student data privacy?">
        <VStack align="stretch" spacing={4}>
          <Text>
            We take student privacy very seriously and require account holders
            to be verified parents, guardians, teachers, or school
            administrators.
          </Text>
          <Text>
            Tract has worked closely with our legal counsel to design our{" "}
            <Link to="/legal/tos" color="brand" fontWeight="bold">
              Terms of Service
            </Link>
            ,{" "}
            <Link to="/legal/privacy" color="brand" fontWeight="bold">
              Privacy Policy
            </Link>
            , and Business Practices to achieve strong compliance with relevant
            laws like COPPA, SOPPA, FERPA, and CCPA. We do not and will not sell
            or share student data for commercial, marketing, or advertising
            purposes.
          </Text>
          <Text>
            If your school requires a privacy review before using Tract, please
            let us know and we will be happy to submit the needed information on
            your behalf. Alternatively, feel free to share the language and
            links to our{" "}
            <Link to="/legal/tos" color="brand" fontWeight="bold">
              Terms of Service{" "}
            </Link>
            and{" "}
            <Link to="/legal/privacy" color="brand" fontWeight="bold">
              Privacy Policy
            </Link>
            .
          </Text>
        </VStack>
      </AccordionItem>
    </Accordion>
  );
};
