// export { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { forwardRef } from "react";

import { Box } from "Shared";

import {
  FontAwesomeIcon as Icon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";

interface FontAwsomeIconProps
  extends Omit<FontAwesomeIconProps, "size" | "fixedWidth"> {
  size?: 4 | 5 | 6 | 8 | 10 | 12 | 16;
}

export const FontAwesomeIcon = forwardRef<SVGSVGElement, FontAwsomeIconProps>(
  ({ size = 6, ...props }, ref) => {
    let fontSize;

    switch (size) {
      case 4:
        fontSize = "16px";
        break;
      case 5:
        fontSize = "18px";
        break;
      case 6:
        fontSize = "22px";
        break;
      case 8:
        fontSize = "24px";
        break;
      case 10:
        fontSize = "30px";
        break;
      case 12:
        fontSize = "32px";
        break;
      case 16:
        fontSize = "48px";
        break;
      default:
        fontSize = "22px";
    }

    return (
      <Box
        h={`${size * 4}px`}
        w={`${size * 4}px`}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Icon ref={ref} {...props} fixedWidth fontSize={fontSize} />
      </Box>
    );
  }
);
