import React, { FC } from "react";
import { Redirect, useParams } from "react-router-dom";

import { ProjectDetails as ProjectDetailsContent } from "Pages/ProjectGallery/ProjectDetails";

import {
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables,
  ProjectCardFragment,
} from "@tract/common/dist/graphql";

import { useSession } from "Services/session";
import { usePrivacyScopesCheck } from "Services/privacy";
import { isTeacherUser } from "Types/User";
import { useQuery } from "urql";
import { GET_PROJECT_DETAILS } from "./graphql";

interface ProjectDetailsWrapperProps {
  project: ProjectCardFragment;
}

const ProjectDetailsWrapper: FC<ProjectDetailsWrapperProps> = ({ project }) => {
  return <ProjectDetailsContent project={project} isFullPage />;
};

type Props = {
  projectId?: string;
};

export const ProjectDetails: React.FC<Props> = ({ projectId }) => {
  const { projectId: idParams }: { projectId: string } = useParams();
  const { currentUser, isAdmin } = useSession();

  if (idParams) {
    projectId = idParams;
  }

  const [{ data }] = useQuery<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >({
    query: GET_PROJECT_DETAILS,
    variables: {
      id: projectId,
    },
  });

  const project = data?.project;
  const { isAllowed } = usePrivacyScopesCheck("projects", project?.user);
  const isPathCreator = currentUser.id === project?.node?.path.authorId;
  const isTeacher = isTeacherUser(currentUser);

  if (!project) {
    return null;
  }

  if (!isAdmin && !isTeacher) {
    if (project.user.id !== currentUser.id && !project.published) {
      return <Redirect to="/" />;
    }
  }

  if (!isPathCreator && !isAllowed) {
    return <Redirect to="/" />;
  }

  return <ProjectDetailsWrapper project={project} />;
};
