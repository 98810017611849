import { PropsWithChildren, ReactNode } from "react";
import { BoxProps, forwardRef } from "@chakra-ui/react";

import { Box } from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";

type Props = {
  renderTopNav?: ReactNode;
  renderBottomNav?: ReactNode;
  isLoading?: boolean;
} & BoxProps;

export const PageApp = forwardRef<PropsWithChildren<Props>, "div">(
  ({ renderTopNav, renderBottomNav, isLoading, children, ...props }, ref) => {
    return (
      <>
        {isLoading ? (
          <LayoutCentered isLoading />
        ) : (
          <Box
            width="100%"
            maxWidth="100rem"
            mx="auto"
            px={{ base: 4, lg: 10 }}
            pb={{ base: 16, lg: 20 }}
            {...props}
            ref={ref}
          >
            {children}
          </Box>
        )}
      </>
    );
  }
);
