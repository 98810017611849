export const Input = {
  variants: {
    outline: {
      field: {
        borderColor: "gray.300",
      },
    },
  },
  sizes: {
    sm: {
      field: {
        borderRadius: "md",
      },
    },
    lg: {
      field: { fontSize: "md", borderRadius: "lg" },
    },
    xl: {
      field: { fontSize: "lg", borderRadius: "lg", px: 4, py: 3 },
    },
  },
  defaultProps: {
    size: "lg",
    focusBorderColor: "brand",
  },
};
