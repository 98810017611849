import { PropsWithChildren } from "react";
import { BoxProps, forwardRef, GridProps } from "@chakra-ui/react";

import { Box, Grid, HStack } from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

export const GridContainer = forwardRef<
  PropsWithChildren<{ isLoading?: boolean } & BoxProps>,
  "div"
>(({ isLoading = false, children, ...props }, ref) => {
  return (
    <>
      {isLoading ? (
        <LayoutCentered isLoading />
      ) : (
        <Box
          width="100%"
          maxWidth={{
            base: "100%",
            "3xl": "132rem",
          }}
          mx="auto"
          px={{ base: 4, lg: 10 }}
          pb={{ base: 16, lg: 20 }}
          {...props}
          ref={ref}
        >
          {children}
        </Box>
      )}
    </>
  );
});

export const GridPaths = forwardRef<PropsWithChildren<GridProps>, "div">(
  (props, ref) => (
    <Grid
      gap={4}
      templateColumns={{
        base: "100%",
        sm: "repeat(2, minmax(0, 1fr))",
        md: "repeat(3, minmax(0, 1fr))",
        xl: "repeat(4, minmax(0, 1fr))",
        "2xl": "repeat(5, minmax(0, 1fr))",
        "3xl": "repeat(6, minmax(0, 1fr))",
      }}
      ref={ref}
      {...props}
    />
  )
);

export const GridClasses = forwardRef<PropsWithChildren<GridProps>, "div">(
  (props, ref) => (
    <Grid
      gap={4}
      templateColumns={{
        base: "100%",
        md: "repeat(2, minmax(0, 1fr))",
        lg: "repeat(3, minmax(0, 1fr))",
        "2xl": "repeat(4, minmax(0, 1fr))",
      }}
      ref={ref}
      {...props}
    />
  )
);

export const GridProfileItems = forwardRef<PropsWithChildren<GridProps>, "div">(
  (props, ref) => (
    <Grid
      gap={6}
      templateColumns={{
        base: "100%",
        md: "repeat(2, minmax(0, 1fr))",
        xl: "repeat(3, minmax(0, 1fr))",
        "2xl": "repeat(4, minmax(0, 1fr))",
      }}
      ref={ref}
      {...props}
    />
  )
);

type GridProjectsProps = {
  size: "md" | "lg";
};

export const GridProjects = forwardRef<
  PropsWithChildren<GridProps & GridProjectsProps>,
  "div"
>((props, ref) => {
  let templateColumns, gap;
  if (props.size === "lg") {
    templateColumns = [
      "100%",
      null,
      "repeat(2, minmax(0, 1fr))",
      null,
      "repeat(3, minmax(0, 1fr))",
    ];
    gap = 6;
  } else {
    templateColumns = {
      base: "100%",
      sm: "repeat(2, minmax(0, 1fr))",
      lg: "repeat(3, minmax(0, 1fr))",
      "2xl": "repeat(4, minmax(0, 1fr))",
      "3xl": "repeat(5, minmax(0, 1fr))",
    };
    gap = { base: 4, lg: 6 };
  }
  return (
    <Grid gap={gap} templateColumns={templateColumns} ref={ref} {...props} />
  );
});

export const ScrollMenu = forwardRef<PropsWithChildren<BoxProps>, "div">(
  ({ children, ...props }, ref) => {
    return (
      <Box
        position="relative"
        overflowX="scroll"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        ref={ref}
        {...props}
      >
        <GridContainer px={0} pb={0}>
          <HStack
            align="center"
            justify="stretch"
            display="inline-flex"
            spacing={2}
            px={{ base: 4, lg: 6 }}
          >
            {children}
          </HStack>
        </GridContainer>
      </Box>
    );
  }
);
