import React, { FC } from "react";
import { Helmet } from "react-helmet";

import { ContainerMarketing } from "Components/LayoutMarketing";
import {
  Avatar,
  Box,
  ExternalLink,
  Flex,
  Grid,
  Text,
  Link,
  IconPhone,
  IconEmail,
  IconMail,
} from "Shared";
import team from "./team.json";
import { Author } from "Types/Author";
import { Badge, BoxProps, HStack, VStack } from "@chakra-ui/react";

interface TeamMemberProfile {
  name: string;
  title: string;
  avatar: string;
}

type EmployeeProps = {
  profile: TeamMemberProfile;
};

export const Employee: FC<EmployeeProps> = ({ profile, ...props }) => {
  return (
    <Flex direction="column" align="center" textAlign="center" {...props}>
      <Avatar size="xl" name={profile?.name} src={profile?.avatar} mb={4} />
      <Text fontSize="lg" fontWeight="bold" color="gray.900">
        {profile?.name}
      </Text>
      <Text fontSize="md" color="gray.600">
        {profile?.title}
      </Text>
    </Flex>
  );
};

interface CreatorProfileProps {
  author: Author;
}

export const Creator: FC<CreatorProfileProps> = ({ author, ...props }) => {
  return (
    <Flex direction="column" align="center" textAlign="center" {...props}>
      <Avatar
        as={Link}
        to={`/@${author.username}`}
        aria-hidden="true"
        size="xl"
        name={author?.name}
        src={author?.photo}
        mb={4}
      />
      <Link
        to={`/@${author.username}`}
        fontSize="lg"
        fontWeight="bold"
        color="gray.900"
      >
        {author?.name}{" "}
      </Link>
      {!!author?.isLead && (
        <Badge variant="outline" colorScheme="brandFull" my={1}>
          Lead Creator
        </Badge>
      )}
      <Text fontSize="md" color="gray.600">
        {author?.school}
      </Text>
    </Flex>
  );
};

type Props = {
  authors?: Author[];
};

const Main: FC<Props & BoxProps> = ({ authors = [], ...props }) => {
  return (
    <Box {...props}>
      <Box maxWidth="50rem" mx="auto">
        <Text
          as="h1"
          fontSize="5xl"
          lineHeight="1.25"
          fontWeight="bold"
          color="gray.900"
          mb={10}
        >
          Where the next generation of ChangeMakers and leaders come to solve
          the world's biggest challenges.
        </Text>
        <VStack spacing={6} align="stretch">
          <Text fontSize="xl" color="gray.900">
            “Bueller, Bueller … ” If you’re unfamiliar with that famous line
            from the 80s classic “Ferris Bueller’s Day Off,” picture a group of
            students half asleep as their teacher calls attendance. No one is
            listening. No one is engaged. They would ALL rather be somewhere —
            anywhere! — else. Way before the age of “Zoom School,” we could all
            relate to what it’s like to be in school … but mentally checked out.
          </Text>
          <Text fontSize="xl" color="gray.900">
            We won’t go so far as to say this scene inspired the founding of
            Tract, but we certainly think about it a lot around here.
          </Text>
          <Text fontSize="md" color="gray.900">
            Too often, traditional education dims that bright light of
            curiosity. When success is measured by checking the right boxes,
            children — often the smartest and most creative among them — shut
            down. They can do the work, but the joy is gone. How can we end the
            passive screen time? How can we get kids engaged in their own
            education, excited to learn and not just putting in face time?
          </Text>
          <Text fontSize="md" color="gray.900">
            These are questions{" "}
            <ExternalLink
              color="brand"
              fontWeight="bold"
              href="https://www.calentertainment.com/portfoliotype/esther-wojcicki/"
              target="_blank"
            >
              Esther Wojcicki
            </ExternalLink>{" "}
            has dedicated her life to solving. Legendary educator, The Godmother
            of Silicon Valley, and author of the #1 Amazon bestseller How to
            Raise Successful People, Esther has positively impacted the lives of
            thousands of children (including her own three successful daughters:
            the CEO of YouTube, the CEO and founder of 23andMe, and a top UCSF
            medical researcher).
          </Text>
          <Text fontSize="md" color="gray.900">
            Esther knew she was in a position to take action. She also knew
            exactly who she wanted to tap as her co-founder: her former student,
            Ari Memar, who she saw as a champion for student empowerment. And,
            as an early-stage key member of the Uber team, he knew a thing or
            two about start-ups.
          </Text>
          <Text fontSize="md" color="gray.900">
            Unified by their passion and belief in student-centered learning,
            they joined forces to scale Esther’s successful teaching method to
            families globally with a belief that empowering kids to think
            critically and independently is the key to solving the world’s
            biggest challenges. After all, this will fall upon our children, so
            don’t we owe it to them to give them the skills — and passion — they
            need to succeed?
          </Text>
          <Text fontSize="md" color="gray.900">
            That’s what Tract is all about.
          </Text>
        </VStack>

        <Box
          mt={{ base: 10, lg: 24 }}
          mb={{ base: 10, lg: 16 }}
          textAlign="center"
        >
          <Text as="h2" fontSize="4xl" fontWeight="bold" mb={4}>
            Meet the Team
          </Text>
          <Text fontSize="lg" color="gray.900" mb={10}>
            We’re a small team united by Esther’s student-centered teaching
            philosophy. Using technology, people and the best content, we want
            to give every child the chance to be successful.
          </Text>
        </Box>
      </Box>

      <Grid
        gap={10}
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
          "repeat(5, 1fr)",
        ]}
        mb={{ base: 10, lg: 16 }}
      >
        {team
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((profile, i) => (
            <Employee key={i} profile={profile} />
          ))}
      </Grid>
    </Box>
  );
};

const Sidebar: FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <Box maxWidth="50rem" mx="auto">
        <Grid
          gap={10}
          templateColumns={{
            base: undefined,
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          color="gray.600"
        >
          <HStack spacing={6}>
            <IconMail color="gray.600" />
            <Text
              as="address"
              fontSize="md"
              color="gray.600"
              fontStyle="normal"
              lineHeight="1.5"
            >
              <strong>Tract Learning, Inc.</strong>
              <br />
              1062 Sanchez St
              <br />
              San Francisco, CA 94114
              <br />
              USA
            </Text>
          </HStack>
          <HStack spacing={6}>
            <IconPhone color="gray.600" />
            <ExternalLink href="tel:+1-833-872-2826">
              1 (833) TRACT-CO
            </ExternalLink>
          </HStack>
          <HStack spacing={6}>
            <IconEmail color="gray.600" />
            <ExternalLink href="mailto:hello@tract.app">
              hello@tract.app
            </ExternalLink>
          </HStack>
        </Grid>
      </Box>
    </Box>
  );
};

export const About: FC = () => {
  return (
    <ContainerMarketing pt={24} pb={32}>
      <Helmet>
        <title>Our Story — Tract</title>
      </Helmet>
      <Main />
      <Sidebar />
    </ContainerMarketing>
  );
};
