import React from "react";
import { Box, createIcon } from "@chakra-ui/react";
import {
  Activity,
  AlertTriangle,
  Archive,
  ArrowDownRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  AtSign,
  Award,
  BarChart,
  Bell,
  Book,
  Bookmark,
  BookOpen,
  Briefcase,
  Calendar,
  Camera,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Circle,
  Clock,
  Compass,
  CornerUpRight,
  Edit2,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  File,
  Filter,
  Flag,
  Gift,
  Globe,
  Hash,
  Heart,
  HelpCircle,
  Home,
  Image,
  Info,
  Instagram,
  Key,
  Link,
  Linkedin,
  List,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Menu,
  MessageCircle,
  Minus,
  MinusCircle,
  Monitor,
  MoreHorizontal,
  MoreVertical,
  Move,
  Phone,
  Play,
  PlayCircle,
  Plus,
  PlusCircle,
  Radio,
  RefreshCw,
  Repeat,
  Search,
  Settings,
  Share2,
  Shield,
  Slash,
  Sliders,
  Square,
  Star,
  Target,
  Trash2,
  Twitter,
  Type,
  Upload,
  UploadCloud,
  User,
  UserPlus,
  Users,
  Video,
  X,
  XCircle,
  Youtube,
  Zap,
} from "react-feather";

export { IconFilledAdmin } from "./custom/IconFilledAdmin";
export { IconFilledAffiliate } from "./custom/IconFilledAffiliate";
export { IconFilledBook } from "./custom/IconFilledBook";
export { IconFilledCamera } from "./custom/IconFilledCamera";
export { IconFilledCreator } from "./custom/IconFilledCreator";
export { IconFilledHeart } from "./custom/IconFilledHeart";
export { IconFilledPartner } from "./custom/IconFilledPartner";
export { IconBackdrop } from "./custom/IconBackdrop";
export { IconForum } from "./custom/IconForum";
export { IconMarkRead } from "./custom/IconMarkRead";
export { IconPartner } from "./custom/IconPartner";
export { IconPath } from "./custom/IconPath";
export { IconSchool } from "./custom/IconSchool";
export { IconSidebarLeft } from "./custom/IconSidebarLeft";
export { IconSidebarRight } from "./custom/IconSidebarRight";
export { IconTextDefault } from "./custom/IconTextDefault";
export { IconTextInverted } from "./custom/IconTextInverted";

function makeIcon(icon, name) {
  const Icon = (props) => <Box as={icon} {...props} />;

  Icon.displayName = name;

  return Icon;
}

export const IconReaction = createIcon({
  displayName: "IconReaction",
  path: (
    <path
      fill="currentColor"
      d="M19.813.188a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2a1 1 0 011-1zM3 12a9 9 0 019-9 1 1 0 100-2C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11a1 1 0 10-2 0 9 9 0 11-18 0zm4.4 1.2a1 1 0 011.397.196l.005.006a3.667 3.667 0 00.194.219c.147.153.368.362.655.57.577.42 1.374.809 2.349.809.975 0 1.772-.39 2.35-.809a4.953 4.953 0 00.815-.748l.034-.041.004-.006A1 1 0 0116.8 14.6L16 14l.8.6-.001.001-.001.002-.003.004-.007.009-.021.027-.07.086a6.936 6.936 0 01-1.17 1.08c-.8.58-2.002 1.191-3.527 1.191-1.525 0-2.728-.61-3.526-1.191a6.942 6.942 0 01-1.17-1.08 4.14 4.14 0 01-.07-.086l-.022-.027-.007-.01-.003-.003-.001-.002S7.2 14.6 8 14l-.8.6a1 1 0 01.2-1.4zM8 9a1 1 0 011-1h.01a1 1 0 110 2H9a1 1 0 01-1-1zm7-1a1 1 0 100 2h.01a1 1 0 100-2H15z"
    />
  ),
});

export const IconTikTok = createIcon({
  displayName: "IconTikTok",
  viewBox: "0 0 24 24",
  d: "M21.8232 9.9008C21.6435 9.91827 21.463 9.92743 21.2824 9.92824C20.3071 9.92838 19.3473 9.68566 18.4893 9.22201C17.6314 8.75836 16.9024 8.08836 16.3682 7.2725V16.316C16.3682 17.6379 15.9762 18.9302 15.2418 20.0294C14.5073 21.1286 13.4634 21.9853 12.242 22.4912C11.0207 22.9971 9.6767 23.1295 8.38011 22.8716C7.08352 22.6137 5.89252 21.9771 4.95773 21.0423C4.02294 20.1075 3.38634 18.9165 3.12844 17.62C2.87053 16.3234 3.0029 14.9794 3.5088 13.7581C4.01471 12.5368 4.87142 11.4929 5.97062 10.7584C7.06982 10.0239 8.36212 9.63194 9.68412 9.63194C9.82365 9.63194 9.96004 9.64448 10.0972 9.6531V12.9469C9.96004 12.9304 9.82521 12.9054 9.68412 12.9054C8.77935 12.9054 7.91164 13.2648 7.27187 13.9045C6.6321 14.5443 6.27269 15.412 6.27269 16.3168C6.27269 17.2215 6.6321 18.0892 7.27187 18.729C7.91164 19.3687 8.77935 19.7281 9.68412 19.7281C11.5686 19.7281 13.2327 18.2435 13.2327 16.3591L13.2656 1H16.4168C16.5608 2.36917 17.1815 3.64417 18.1703 4.60206C19.1592 5.55995 20.4533 6.13977 21.8264 6.24015V9.9008",
  defaultProps: {
    h: "1.5rem",
    w: "1.5rem",
    display: "block",
  },
});

export const IconActivity = makeIcon(Activity, "Activity");
export const IconAdmin = makeIcon(Shield, "Admin");
export const IconAlertTriangle = makeIcon(AlertTriangle, "AlertTriangle");
export const IconArchive = makeIcon(Archive, "Archive");
export const IconArrowDownRight = makeIcon(ArrowDownRight, "ArrowDownRight");
export const IconArrowUp = makeIcon(ArrowUp, "ArrowDownUp");
export const IconAuthor = makeIcon(Play, "Author");
export const IconAward = makeIcon(Award, "Award");
export const IconBack = makeIcon(ArrowLeft, "Back");
export const IconBell = makeIcon(Bell, "Bell");
export const IconBook = makeIcon(Book, "Book");
export const IconBookmark = makeIcon(Bookmark, "Bookmark");
export const IconBookOpen = makeIcon(BookOpen, "BookOpen");
export const IconBriefcase = makeIcon(Briefcase, "Briefcase");
export const IconCalender = makeIcon(Calendar, "Calender");
export const IconCamera = makeIcon(Camera, "Camera");
export const IconCancel = makeIcon(XCircle, "Cancel");
export const IconChannel = makeIcon(Hash, "Channel");
export const IconChat = makeIcon(MessageCircle, "MessageCircle");
export const IconCheck = makeIcon(Check, "Check");
export const IconCheckCircle = makeIcon(CheckCircle, "CheckCircle");
export const IconChevronDown = makeIcon(ChevronDown, "ChevronDown");
export const IconChevronLeft = makeIcon(ChevronLeft, "ChevronLeft");
export const IconChevronRight = makeIcon(ChevronRight, "ChevronRight");
export const IconChevronUp = makeIcon(ChevronUp, "ChevronUp");
export const IconCircle = makeIcon(Circle, "Circle");
export const IconClock = makeIcon(Clock, "Clock");
export const IconClubhouse = makeIcon(Home, "Clubhouse");
export const IconCornerUpRight = makeIcon(CornerUpRight, "CornerUpRight");
export const IconEdit = makeIcon(Edit2, "Edit");
export const IconEmail = makeIcon(AtSign, "Email");
export const IconExplore = makeIcon(Compass, "Explore");
export const IconExternalLink = makeIcon(ExternalLink, "ExternalLink");
export const IconFacebook = makeIcon(Facebook, "Facebook");
export const IconFile = makeIcon(File, "File");
export const IconFilter = makeIcon(Filter, "Filter");
export const IconFlag = makeIcon(Flag, "IconFlag");
export const IconForward = makeIcon(ArrowRight, "Forward");
export const IconGift = makeIcon(Gift, "Gift");
export const IconGlobe = makeIcon(Globe, "Globe");
export const IconHeart = makeIcon(Heart, "Heart");
export const IconHelp = makeIcon(HelpCircle, "Help");
export const IconHide = makeIcon(EyeOff, "Hide");
export const IconImage = makeIcon(Image, "Image");
export const IconInfo = makeIcon(Info, "Info");
export const IconInstagram = makeIcon(Instagram, "Instagram");
export const IconKey = makeIcon(Key, "Key");
export const IconLeaderboard = makeIcon(BarChart, "Leaderboard");
export const IconLink = makeIcon(Link, "Link");
export const IconLinkedin = makeIcon(Linkedin, "Linkedin");
export const IconList = makeIcon(List, "List");
export const IconLivestream = makeIcon(Radio, "Livestream");
export const IconLock = makeIcon(Lock, "Lock");
export const IconLogIn = makeIcon(LogIn, "LogIn");
export const IconLogOut = makeIcon(LogOut, "LogOut");
export const IconMail = makeIcon(Mail, "Mail");
export const IconMenu = makeIcon(Menu, "Menu");
export const IconMinus = makeIcon(Minus, "Minus");
export const IconMinusCircle = makeIcon(MinusCircle, "MinusCircle");
export const IconMonitor = makeIcon(Monitor, "Monitor");
export const IconMoreHorizontal = makeIcon(MoreHorizontal, "MoreHorizontal");
export const IconMoreVertical = makeIcon(MoreVertical, "MoreVertical");
export const IconMove = makeIcon(Move, "Move");
export const IconPhone = makeIcon(Phone, "Phone");
export const IconPlay = makeIcon(PlayCircle, "Play");
export const IconPlus = makeIcon(Plus, "Plus");
export const IconPlusCircle = makeIcon(PlusCircle, "PlusCircle");
export const IconRefresh = makeIcon(RefreshCw, "Refresh");
export const IconRepeat = makeIcon(Repeat, "Repeat");
export const IconSearch = makeIcon(Search, "Search");
export const IconSettings = makeIcon(Settings, "Settings");
export const IconShare = makeIcon(Share2, "Share");
export const IconSlash = makeIcon(Slash, "Slash");
export const IconSliders = makeIcon(Sliders, "Sliders");
export const IconSquare = makeIcon(Square, "Square");
export const IconStar = makeIcon(Star, "Star");
export const IconTarget = makeIcon(Target, "Target");
export const IconTrash = makeIcon(Trash2, "Trash");
export const IconTwitter = makeIcon(Twitter, "Twitter");
export const IconType = makeIcon(Type, "Type");
export const IconUpload = makeIcon(Upload, "Upload");
export const IconUploadCloud = makeIcon(UploadCloud, "UploadCloud");
export const IconUser = makeIcon(User, "User");
export const IconUserPlus = makeIcon(UserPlus, "UserPlus");
export const IconUsers = makeIcon(Users, "Users");
export const IconVideo = makeIcon(Video, "Video");
export const IconView = makeIcon(Eye, "View");
export const IconX = makeIcon(X, "X");
export const IconXCircle = makeIcon(XCircle, "XCircle");
export const IconYouTube = makeIcon(Youtube, "YouTube");
export const IconZap = makeIcon(Zap, "Zap");
