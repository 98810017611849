import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {
  ProjectGalleryQuery,
  ProjectGalleryQueryVariables,
  UserFollowingByIdQueryResult,
  UserFollowingByIdQueryVariables,
} from "@tract/common/dist/graphql";

import {
  Button,
  Center,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  Flex,
  Text,
} from "Shared";

import { ProjectCard } from "Components/ProjectCard";
import { GridProjects } from "Components/GridContainer";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";
import { useProjectModal } from "Services/projectModal";
import { useSession } from "Services/session";

import { ViewSource } from "Types/Project";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PROJECT_GALLERY_QUERY } from "Pages/ProjectGallery/graphql";
import { useQuery } from "urql";
import { USER_FOLLOWING_BY_ID_QUERY } from "Pages/Profile/graphql";
import { useOffsetPaginationQuery } from "Utils";

const GALLERY_LIMIT = 24;

export const FollowingProjects: React.FC = () => {
  const { currentUser } = useSession();
  const { handleOpenProjectModal } = useProjectModal();

  const [{ data: followingData, fetching: loadingFollowingData }] = useQuery<
    UserFollowingByIdQueryResult["data"],
    UserFollowingByIdQueryVariables
  >({
    query: USER_FOLLOWING_BY_ID_QUERY,
    variables: {
      id: currentUser.id,
      currentUserId: currentUser.id,
      limit: 50,
    },
  });
  const following = followingData?.user[0]?.following?.map(
    (record) => record.targetUserId
  );

  const {
    response: [{ data, fetching: loading, error }],
    pagination,
  } = useOffsetPaginationQuery<
    ProjectGalleryQuery,
    ProjectGalleryQueryVariables
  >({
    query: PROJECT_GALLERY_QUERY,
    pause: loadingFollowingData || !following?.length,
    variables: {
      limit: GALLERY_LIMIT,
      offset: 0,
      where: {
        user: {
          projectPrivacy: { _is_null: true },
        },
        userId: { _in: following },
        file: { transforms: {} },
        published: { _eq: true },
        rejectedAt: { _eq: "-infinity" },
        isPrivate: { _eq: false },
      },
    },
    field: "projects",
  });

  const initialLoading = loadingFollowingData || loading;
  const isAnyLoading =
    loadingFollowingData || loading || pagination.loadingMore;

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: pagination.hasNextPage,
    onLoadMore: pagination.loadMore,
    loading: isAnyLoading,
  });

  const projects = data?.projects;
  const skeletonCount = GALLERY_LIMIT;

  return (
    <Flex direction="column">
      {initialLoading && !!skeletonCount && (
        <GridProjects size="md" w="full">
          {Array.from({ length: skeletonCount }).map((_, i) => (
            <ProjectCardSkeleton key={i} />
          ))}
        </GridProjects>
      )}

      {(!initialLoading && !projects?.length && !error && (
        <EmptyState
          headline="No projects yet"
          icon={<EmptyStateFontAwesomeIcon icon={duotone("images")} />}
        >
          {currentUser.orgId ? (
            <Text>Follow classmates to view their projects here!</Text>
          ) : (
            <Text>Follow tract members to view their projects here!</Text>
          )}
        </EmptyState>
      )) || (
        <GridProjects size="md">
          {projects?.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                viewSource={ViewSource.FollowingProjects}
                project={project}
                handleClickFromParent={(event) => {
                  handleOpenProjectModal(event, project);
                }}
              />
            );
          })}
        </GridProjects>
      )}

      {!initialLoading &&
        !!projects?.length &&
        projects.length >= GALLERY_LIMIT &&
        pagination.hasNextPage && (
          <Center ref={sentryRef} mt={10} width="full">
            {pagination.loadingMore ? (
              !!skeletonCount && (
                <GridProjects size="md" w="full">
                  {Array.from({ length: skeletonCount }).map((_, i) => (
                    <ProjectCardSkeleton key={i} />
                  ))}
                </GridProjects>
              )
            ) : (
              <Button variant="ghost" size="lg" colorScheme="brandFull">
                Load More
              </Button>
            )}
          </Center>
        )}
    </Flex>
  );
};
