import { MutableRefObject, ReactNode, useRef } from "react";
import { gql } from "@apollo/client";
import { Link, LinkProps } from "react-router-dom";

import {
  Avatar,
  // AvatarBadge,
  Box,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "Shared";

import { ProfileBadges } from "Components/ProfileBadges";
import { FollowButton } from "Pages/Profile/FollowButton";

import { useCheckSession } from "Services/session";
// import { isUserOnline } from "Services/users";

import { isAuthorUser, isParentUser, isTeacherUser } from "Types/User";

import {
  UserProfilePopoverFragment,
  UserSocialCountsByIdQueryResult,
  UserSocialCountsByIdQueryVariables,
  UserSocialCountsFragment,
  useUserProfilePopoverQuery,
} from "@tract/common/dist/graphql";
import { useQuery } from "urql";
import { USER_SOCIAL_COUNTS_BY_ID_QUERY } from "Pages/Profile/graphql";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

type ProfilePopoverProps = {
  userId: string;
  triggerElement: ReactNode;
  isAuthor?: boolean;
  containerRef?: MutableRefObject<HTMLElement | null>;
  onLinkClick?: () => void;
};

const PROFILE_POPOVER_FRAGMENT = gql`
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment UserProfilePopover on user {
    id: firestoreId
    username
    avatar
    isMod
    country
    firstName
    lastName
    isEducator
    creatorLevel
    userType

    ...UserPrivacyScopes
  }
`;

gql`
  ${PROFILE_POPOVER_FRAGMENT}

  query UserProfilePopover($userId: String!) {
    user(where: { firestoreId: { _eq: $userId } }) {
      ...UserProfilePopover
    }
  }
`;

export const ProfilePopover = ({
  userId,
  triggerElement,
  containerRef,
  isAuthor = false,
  onLinkClick = () => {},
}: ProfilePopoverProps) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const initialFocusRef = useRef(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { hasSession } = useCheckSession();

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      placement="bottom"
      openDelay={600}
      closeOnBlur={false}
      trigger="hover"
      isLazy
      lazyBehavior="unmount"
      eventListeners={{ resize: false, scroll: false }}
      computePositionOnMount={false}
    >
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      {hasSession && !isMobile && (
        <Portal containerRef={containerRef}>
          <PopoverContent
            color="gray.900"
            bg="white"
            display={isOpen ? "block" : "none"}
            borderColor="gray.300"
            boxShadow="lg"
            borderRadius="xl"
            py={2}
            px={1}
            onClick={(e) => e.stopPropagation()}
          >
            <ProfilePopoverContent userId={userId} onLinkClick={onLinkClick} />
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

const ProfilePopoverContent = ({
  userId,
  onLinkClick = () => {},
}: {
  userId: string;
  onLinkClick?: () => void;
}) => {
  const { data } = useUserProfilePopoverQuery({
    variables: {
      userId,
    },
  });

  const user = data?.user[0];

  const [{ data: socialCountsData }] = useQuery<
    UserSocialCountsByIdQueryResult["data"],
    UserSocialCountsByIdQueryVariables
  >({
    pause: !userId,
    query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
    variables: {
      id: userId,
    },
  });
  const socialCounts = socialCountsData?.user[0];

  if (!user) {
    return null;
  }

  const avatarSrc = user?.avatar || "";
  const isParentOrTeacher =
    (user && isTeacherUser(user)) || (user && isParentUser(user));

  return (
    <>
      <PopoverHeader
        as={Flex}
        justifyContent="space-between"
        alignItems="flex-start"
        border={0}
      >
        <Linkable
          shouldLink={!!user?.username || isTeacherUser(user)}
          to={`/${isTeacherUser(user) ? user?.id : `@${user?.username}`}`}
          onClick={onLinkClick}
        >
          <Avatar
            size="lg"
            src={avatarSrc}
            name={
              isParentOrTeacher
                ? `${user?.firstName} ${user?.lastName}`
                : undefined
            }
          >
            {/* {isUserOnline(user) && (
              <AvatarBadge boxSize="1.5rem" bg="green.500" />
            )} */}
          </Avatar>
        </Linkable>
        <FollowButton profile={user} />
      </PopoverHeader>
      <PopoverBody>
        {user && (
          <ProfilePopoverBody
            user={user}
            onLinkClick={onLinkClick}
            socialCounts={socialCounts}
          />
        )}
      </PopoverBody>
    </>
  );
};

export const popoverBodyUserFragment = gql`
  fragment PopoverBodyUser on user {
    firstName
    lastName
    username
  }
`;

const ProfilePopoverBody = ({
  user,
  onLinkClick = () => {},
  socialCounts,
}: {
  user: UserProfilePopoverFragment;
  onLinkClick?: () => void;
  socialCounts?: UserSocialCountsFragment;
}) => {
  const isAuthor = isAuthorUser(user);

  if (!user) return null;

  const isParentOrTeacher = isTeacherUser(user) || isParentUser(user);

  return (
    <VStack alignItems="left">
      <HStack>
        <Linkable
          shouldLink={!!user?.username || isTeacherUser(user)}
          to={`/${isTeacherUser(user) ? user?.id : `@${user?.username}`}`}
          onClick={onLinkClick}
        >
          <Text
            fontWeight="bold"
            _hover={{ textDecoration: !!user?.username ? "underline" : "none" }}
          >
            {isParentOrTeacher && `${user?.firstName} ${user?.lastName}`}
            {isAuthor && user.username}
          </Text>
        </Linkable>
      </HStack>
      {/* TODO: URA */}
      {/* {isAuthor && <Text>{(profile as Document<AuthorUser>).school}</Text>} */}
      {!isParentOrTeacher && !!socialCounts && (
        <HStack spacing={3} color="gray.600">
          <Box>
            <Text as="span" fontWeight="bold">
              {socialCounts?.followers_aggregate?.aggregate?.count || 0}
            </Text>{" "}
            <Text as="span" color="gray.600">
              Followers
            </Text>
          </Box>
          <Box>
            <Text as="span" fontWeight="bold">
              {socialCounts?.following_aggregate?.aggregate?.count || 0}
            </Text>{" "}
            <Text as="span" color="gray.600">
              Following
            </Text>
          </Box>
        </HStack>
      )}

      <VStack alignItems="left">
        <>
          <Divider my={2} />
          <Text color="gray.600" fontWeight="bold" fontSize="sm" mt={0} mb={2}>
            BADGES
          </Text>
        </>
        <ProfileBadges iconSize={6} user={user} spacing={2} flagFont="xl" />
      </VStack>
    </VStack>
  );
};

const Linkable: React.FC<
  LinkProps & {
    shouldLink: boolean;
  }
> = ({ shouldLink, children, ...props }) => {
  if (shouldLink) {
    return (
      <>
        <Link {...props}>{children}</Link>
      </>
    );
  }
  return <>{children}</>;
};
