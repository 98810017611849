import React, { FC, PropsWithChildren } from "react";
import { Flex } from "Shared";

export const LayoutFull: FC<PropsWithChildren> = ({ children, ...props }) => {
  return (
    <Flex width="100%" {...props}>
      {children}
    </Flex>
  );
};
