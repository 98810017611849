import { useHistory, useLocation } from "react-router";
import { Formik, FormikValues } from "formik";

import {
  Box,
  VisuallyHidden,
  FormControl,
  FormLabel,
  SearchInput,
} from "Shared";
import { forwardRef } from "@chakra-ui/system";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { PATH_SEARCH_QUERY_PARAM } from "Pages/Explore/PathFeedAlgolia";

export const TopNavSearch = forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const { track } = useAnalytics();

  const handleSearch = (values: FormikValues) => {
    const { query } = values;
    const sanitizedQuery = query.trim();
    const queryString = location.pathname.toString().includes("search")
      ? window.location.search
      : "";
    const urlParams = new URLSearchParams(queryString);
    if (!!sanitizedQuery.length) {
      if (urlParams.has(PATH_SEARCH_QUERY_PARAM)) {
        urlParams.delete(PATH_SEARCH_QUERY_PARAM);
      }
      urlParams.append(PATH_SEARCH_QUERY_PARAM, sanitizedQuery);
    }

    track(ANALYTICS_EVENTS.SEARCH_SUBMITTED, {
      searchQuery: sanitizedQuery,
    });

    if (!urlParams.toString().length) {
      history.push("/explore/originals");
    } else {
      history.push(`/search?${urlParams.toString()}`);
    }
  };

  return (
    <Formik initialValues={{ query: "" }} onSubmit={handleSearch}>
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Box
          as="form"
          // @ts-ignore
          onSubmit={handleSubmit}
          width="100%"
          ref={ref}
          {...props}
        >
          <FormControl>
            <VisuallyHidden>
              <FormLabel htmlFor="path-search-input">Search paths</FormLabel>
            </VisuallyHidden>
            <SearchInput
              query={values.query}
              onChange={handleChange}
              onClear={() => setFieldValue("query", "")}
            />
          </FormControl>
        </Box>
      )}
    </Formik>
  );
});
