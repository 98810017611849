import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  CreateProjectCommentMutationResult,
  ProjectCardFragment,
  ProjectCommentsQueryResult,
  ProjectCommentsQueryVariables,
} from "@tract/common/dist/graphql";
import { PROJECT_COMMENTS_QUERY } from "Pages/ProjectGallery/graphql";
import { PROJECT_COMMENT_LIMIT } from "Pages/ProjectGallery/Comments";
import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";

export const insert_project_comment_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const item = result as CreateProjectCommentMutationResult["data"];
  cache.updateQuery<
    ProjectCommentsQueryResult["data"],
    ProjectCommentsQueryVariables
  >(
    {
      query: PROJECT_COMMENTS_QUERY,
      variables: {
        projectId: item?.projectComment?.projectId,
        limit: PROJECT_COMMENT_LIMIT,
        offset: 0,
      },
    },
    (data) => {
      if (!!item?.projectComment) {
        data?.projectComments?.unshift({
          ...item.projectComment,
        });
      }
      return data;
    }
  );

  //Update comment count in project stats
  const project = cache.readFragment(
    PROJECT_CARD_FRAGMENT,
    cache.keyOfEntity({
      __typename: "project",
      id: item?.projectComment?.projectId,
    })
  );
  const newProject = {
    ...project,
    stats: {
      ...project.stats,
      commentCount: project.stats.commentCount + 1,
    },
  };
  cache.writeFragment<ProjectCardFragment>(PROJECT_CARD_FRAGMENT, newProject);
};
