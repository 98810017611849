import React from "react";
import firebase from "firebase/compat";

import { NotificationsProvider, StreamProvider } from "Services/stream";
import { SessionProvider } from "Services/session";
import { ProjectModalProvider } from "Services/projectModal";
import { ChatProvider } from "Services/chat";

import { AppVersion } from "Components/AppVersion";

import { AuthRouter } from "./AuthRouter";

import { Auth } from "Types/Auth";

type Props = {
  auth: Auth;
  firebaseUser: firebase.User;
};

export const AuthedRoutes: React.FC<Props> = ({ auth, firebaseUser }) => {
  return (
    <SessionProvider auth={auth} firebaseUser={firebaseUser}>
      <StreamProvider token={auth.streamToken}>
        <NotificationsProvider userId={firebaseUser.uid}>
          <ChatProvider
            token={auth.chatToken}
            apiKey={process.env.REACT_APP_STREAM_API_KEY}
          >
            <AppVersion />
            <ProjectModalProvider>
              <AuthRouter />
            </ProjectModalProvider>
          </ChatProvider>
        </NotificationsProvider>
      </StreamProvider>
    </SessionProvider>
  );
};
