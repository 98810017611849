import { FlexProps } from "@chakra-ui/layout";
import { forwardRef } from "@chakra-ui/system";
import { Flex } from "Shared";

export const UserAwardCardLayout: React.FC<FlexProps> = forwardRef(
  ({ children, ...props }, ref) => (
    <Flex
      as="button"
      direction="column"
      p={6}
      bg="gray.50"
      textAlign="left"
      transition="150ms ease all"
      _hover={{ bg: "gray.100" }}
      _focus={{ boxShadow: "outline", outline: 0 }}
      borderRadius="xl"
      ref={ref}
      {...props}
    >
      {children}
    </Flex>
  )
);
