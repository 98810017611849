import { useMemo } from "react";
import { gql } from "urql";

import { useSession } from "./session";

import {
  CurrentUserFragment,
  UserPrivacyScopesFragment,
} from "@tract/common/dist/graphql";

import { isTeacherUser } from "Types/User";

export const USER_PRIVACY_SCOPES_FRAGMENT = gql`
  fragment UserPrivacyScopes on user {
    id: firestoreId
    profilePrivacy
    projectPrivacy
    pathPrivacy
    organization {
      id: firestoreId
    }
  }
`;

export const usePrivacyScopesCheck = (
  entity: "profile" | "projects" | "paths",
  user?: UserPrivacyScopesFragment
) => {
  const { currentUser, isAdmin } = useSession();

  const isAllowed = useMemo(() => {
    if (!user) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    if (currentUser.id === user.id) {
      return true;
    }

    if (isTeacherUser(currentUser) && isSameSchool(currentUser, user)) {
      return true;
    }

    if (entity === "profile") {
      if (!user.profilePrivacy) {
        return true;
      }

      if (user.profilePrivacy === "school") {
        return isSameSchool(currentUser, user);
      }

      if (user.profilePrivacy === "private") {
        return false;
      }
    }

    if (entity === "projects") {
      if (!user.projectPrivacy) {
        return true;
      }

      if (user.projectPrivacy === "school") {
        return isSameSchool(currentUser, user);
      }

      if (user.projectPrivacy === "private") {
        return false;
      }
    }

    if (entity === "paths") {
      if (!user.pathPrivacy) {
        return true;
      }

      if (user.pathPrivacy === "school") {
        return isSameSchool(currentUser, user);
      }

      if (user.pathPrivacy === "private") {
        return false;
      }
    }

    return false;
  }, [currentUser, user, isAdmin, entity]);

  return { isAllowed };
};

const isSameSchool = (
  currentUser: CurrentUserFragment,
  targetUser: UserPrivacyScopesFragment
) => {
  if (!currentUser.organization || !targetUser.organization) {
    return false;
  }

  return currentUser.organization?.id === targetUser.organization?.id;
};
