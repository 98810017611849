import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import { useQuery } from "urql";

import { LayoutCentered } from "Components/LayoutCentered";

import { Users } from "./Users";
import { UserProfile } from "./UserProfile";
import { UserAccount } from "./UserAccount";
import { UserPaths } from "./UserPaths";
// import { UserClubs } from "./UserClubs";
import { LayoutUser } from "./LayoutUser";
// import { TeacherClassrooms } from "./TeacherClassrooms";
import { TeacherActivation } from "./TeacherActivation";

import { GET_ADMIN_DASHBOARD_USER } from "./graphql";

import {
  GetAdminDashboardUserQueryResult,
  GetAdminDashboardUserQueryVariables,
} from "@tract/common/dist/graphql";
import { TeacherClassrooms } from "./TeacherClassrooms";
import { UserCoins } from "./UserCoins";
import { UserInvites } from "./UserInvites";

const UserDetails = () => {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();

  const [{ data, fetching }, refetch] = useQuery<
    GetAdminDashboardUserQueryResult["data"],
    GetAdminDashboardUserQueryVariables
  >({
    pause: !userId,
    requestPolicy: "network-only",
    query: GET_ADMIN_DASHBOARD_USER,
    variables: {
      userId,
    },
  });

  const user = data?.user_by_pk;

  if (fetching) {
    return <LayoutCentered isLoading />;
  }

  if (!user) {
    return <Redirect to="/admin/users" />;
  }

  return (
    <LayoutUser user={user}>
      <Switch>
        <Route exact path="/admin/users/:userId/edit">
          <UserProfile
            user={user}
            onSaveSuccess={() => {
              refetch({ requestPolicy: "network-only" });
              history.push("/admin/users");
            }}
          />
        </Route>
        <Route exact path="/admin/users/:userId/account">
          <UserAccount user={user} />
        </Route>
        <Route exact path="/admin/users/:userId/invites">
          <UserInvites user={user} />
        </Route>
        <Route exact path="/admin/users/:userId/paths">
          <UserPaths user={user} />
        </Route>
        <Route exact path="/admin/users/:userId/coins">
          <UserCoins user={user} />
        </Route>
        {/* Clubs are disabled <Route exact path="/admin/users/:userId/clubs">
          <UserClubs user={user} />
        </Route> */}
        <Route exact path="/admin/users/:userId/classrooms">
          <TeacherClassrooms user={user} />
        </Route>
        <Route exact path="/admin/users/:userId/activation">
          <TeacherActivation user={user} />
        </Route>
        <Redirect to={`/admin/users/${userId}/edit`} />
      </Switch>
    </LayoutUser>
  );
};

export const AdminUsers: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/admin/users" component={Users} />
      <Route path="/admin/users/:userId" component={UserDetails} />
    </Switch>
  );
};
