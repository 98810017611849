import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  Api_Update_Privacy_Output,
  SessionUserByIdQuery,
} from "@tract/common/dist/graphql";

import { SESSION_USER_BY_ID_QUERY } from "graphql/users";

export const api_update_privacy: UpdateResolver = (data, args, cache, info) => {
  const result = data.api_update_privacy as Api_Update_Privacy_Output;

  cache.updateQuery<SessionUserByIdQuery>(
    {
      query: SESSION_USER_BY_ID_QUERY,
      variables: {
        userId: result.userId,
      },
    },
    (data) => {
      data?.users.forEach((user) => {
        if (user.id === result.userId) {
          user.profilePrivacy = result.profilePrivacy;
          user.projectPrivacy = result.projectPrivacy;
          user.pathPrivacy = result.pathPrivacy;
        }
      });

      return data;
    }
  );
  return data;
};
