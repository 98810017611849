import React from "react";
import { Route } from "react-router-dom";

import { RouteConfig, ALL_ROLES } from "./routes";
import { AccessDenied } from "./AccessDenied";
import { Disabled } from "./Disabled";

import { useSession } from "Services/session";

export const AuthRoute: React.FC<RouteConfig> = ({ allowedRoles, ...rest }) => {
  const { currentUser, currentUserRoles } = useSession();

  let isAllowedRole = false;

  if (currentUser.disabled) {
    return <Disabled />;
  }

  if (allowedRoles === ALL_ROLES) {
    isAllowedRole = true;
  } else if (Array.isArray(allowedRoles)) {
    isAllowedRole =
      allowedRoles?.filter((role) => currentUserRoles.includes(role)).length >
      0;
  }

  if (!isAllowedRole) {
    return <AccessDenied />;
  }

  return <Route {...rest} />;
};
