import React, { forwardRef, useState } from "react";
import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import * as Yup from "yup";

import {
  ButtonSolid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Textarea,
  Switch,
  Select,
  Stack,
  useToast,
} from "Shared";

import {
  LivestreamFragment,
  useCreateFileMutation,
} from "@tract/common/dist/graphql";
import { useSession } from "Services/session";
import { FormFileUploader } from "Components/FormFileUploader";
import { UserFileResult } from "Services/useUserFileUpload";
import { captureException } from "Services/errors";
import to from "@tract/common/dist/utils/async";
import { post } from "Services/api";
import { UserFile } from "Utils/UserFile";

type Props = {
  livestream?: LivestreamFragment;
  onSubmit: (livestream: LivestreamFormValues, coverFileId?: string) => void;
  submitLoading?: boolean;
};

const LivestreamSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title"),
  hostUserId: Yup.string().required("Please enter a host user id"),
  description: Yup.string().max(
    500,
    "Description must be less than 500 characters"
  ),
  startDate: Yup.string().required("Must have a start date"),
  duration: Yup.string().required(),
  coverFile: Yup.object().nullable(),
  visible: Yup.boolean(),
});

export type LivestreamFormValues = {
  title: string;
  hostUserId?: string;
  description?: string;
  startDate?: string;
  duration: number;
  coverFile?: UserFileResult;
  visible: boolean; //Inverse of disabled
};

export const LivestreamForm = forwardRef<
  FormikProps<LivestreamFormValues>,
  Props
>(({ livestream, onSubmit, submitLoading }, ref) => {
  const { firebaseUser, currentUser } = useSession();
  const [coverResult, setCoverResult] = useState<UserFileResult | null>();
  const [coverFileId, setCoverFileId] = useState(livestream?.coverFile?.id);
  const [created, setCreated] = useState(false);
  const toast = useToast();
  const [createFile] = useCreateFileMutation();
  const [coverFile, setCoverFile] = useState(
    new UserFile(livestream?.coverFile).getTransformedUrl("cover_thumb")
  );

  async function handleSubmit(livestream: LivestreamFormValues) {
    if (coverResult && coverResult.fileId !== livestream?.coverFile?.fileId) {
      const [createCoverErr, res] = await to(
        createFile({
          variables: {
            file: {
              id: coverResult.fileId,
              filename: coverResult.filename,
              userId: livestream.hostUserId,
            },
          },
        })
      );

      if (createCoverErr) {
        captureException(createCoverErr);
        toast({
          status: "error",
          title: "Error saving thumbnail. Please try again",
        });
        return;
      }

      if (!res?.data?.file?.id) {
        captureException(
          new Error("No response on adding livestream thumbnail")
        );
        toast({
          status: "error",
          title: "Error saving thumbnail. Please try again",
        });
        return;
      }

      const [coverTransloaditErr] = await to(
        post("/v2/transloadit", firebaseUser, {
          fileId: res.data.file.id,
          templateName: "path-covers",
        })
      );

      if (coverTransloaditErr) {
        captureException(coverTransloaditErr);
        toast({
          status: "error",
          title: "Error saving thumbnail. Please try again",
        });
        return;
      }
    }

    onSubmit(livestream, coverFileId);
  }

  const formatDateForDatePicker = (d: Date) => {
    return `${d.getFullYear()}-${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}T${d
      .getHours()
      .toString()
      .padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`;
  };
  const durationMinutes =
    (new Date(livestream?.endDate).getTime() -
      new Date(livestream?.startDate).getTime()) /
    60 /
    1000;

  return (
    <Formik
      validationSchema={LivestreamSchema}
      initialValues={{
        title: livestream?.title || "",
        hostUserId: livestream?.hostUserId || currentUser.uid,
        description: livestream?.description || "",
        startDate: formatDateForDatePicker(new Date(livestream?.startDate)),
        duration: durationMinutes || 30,
        coverFile: undefined,
        visible: !livestream?.disabled,
      }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        try {
          await handleSubmit(values);
          setCreated(true);
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ setFieldValue, values, dirty, isSubmitting }) => (
        <Form noValidate autoComplete="off">
          <Field name="visible">
            {({ field, meta: { error } }: FieldProps) => (
              <FormControl id={field.name} mb={5}>
                <FormLabel>Visible</FormLabel>
                <Switch
                  name={field.name}
                  isChecked={field.value}
                  onChange={() => {
                    setFieldValue(field.name, !field.value);
                  }}
                  size="lg"
                />
              </FormControl>
            )}
          </Field>
          <Field name="title">
            {({
              field,
              form: { submitCount },
              meta: { error },
            }: FieldProps) => (
              <FormControl
                id={field.name}
                isInvalid={submitCount > 0 && !!error}
                isRequired
                mb={5}
              >
                <FormLabel>Title</FormLabel>
                <Input {...field} size="lg" />
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <FormFileUploader
            mb={5}
            width="50%"
            accept="image/jpeg,image/png"
            inputId="coverFile"
            inputLabel="Thumbnail"
            inputHelperText="JPG or PNG, Max 5MB, 16:9 Aspect Ratio"
            url={coverResult?.fileUrl || coverFile}
            maxFileSize={1024 * 1024 * 5}
            onUpdate={(fileResult) => {
              setCoverResult(fileResult);
              setCoverFileId(fileResult?.fileId);
              if (!fileResult) {
                setCoverFile("");
              }
            }}
            onValidateFile={() => null}
            isRequired
          />
          <Field name="hostUserId">
            {({
              field,
              form: { submitCount },
              meta: { error },
            }: FieldProps) => (
              <FormControl
                id={field.name}
                isInvalid={submitCount > 0 && !!error}
                isRequired
                mb={5}
              >
                <FormLabel>Host UID</FormLabel>
                <Input {...field} size="lg" />
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="description">
            {({
              field,
              form: { submitCount },
              meta: { error },
            }: FieldProps) => (
              <FormControl
                id="description"
                isInvalid={submitCount > 0 && !!error}
                mb={5}
              >
                <FormLabel>Description</FormLabel>
                <Textarea
                  {...field}
                  name="description"
                  size="lg"
                  height="200px"
                />
                <FormErrorMessage>{error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack direction={{ base: "column", lg: "row" }} mb={5}>
            <Field id="startDate" name="startDate">
              {({
                field,
                form: { submitCount },
                meta: { error },
              }: FieldProps) => (
                <FormControl
                  id="startDate"
                  isRequired
                  isInvalid={submitCount > 0 && !!error}
                >
                  <FormLabel>Start Date</FormLabel>
                  <Input
                    {...field}
                    size="lg"
                    type="datetime-local"
                    min={formatDateForDatePicker(new Date())}
                  />
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="duration">
              {({
                field,
                form: { submitCount },
                meta: { error },
              }: FieldProps) => (
                <FormControl
                  id="duration"
                  isRequired
                  isInvalid={submitCount > 0 && !!error}
                >
                  <FormLabel>Duration (Minutes)</FormLabel>
                  <Select
                    {...field}
                    id="duration"
                    size="lg"
                    borderRadius="lg"
                    defaultValue={30}
                  >
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={45}>45</option>
                    <option value={60}>60</option>
                    <option value={90}>90</option>
                  </Select>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          <ButtonSolid
            isLoading={isSubmitting}
            disabled={!dirty || isSubmitting || created}
            type="submit"
            size="lg"
            mb={10}
          >
            Save
          </ButtonSolid>
        </Form>
      )}
    </Formik>
  );
});
