import { useContext } from "react";
import styled from "@emotion/styled";
import { forwardRef } from "@chakra-ui/system";
import {
  ScrollMenu as ReactScrollMenu,
  VisibilityContext,
  Props as ReactScrollMenuProps,
} from "react-horizontal-scrolling-menu";

import {
  Box,
  Center,
  Fade,
  IconButton,
  IconChevronLeft,
  IconChevronRight,
  useBreakpointValue,
} from "Shared";

type Props = {
  children: ReactScrollMenuProps["children"];
};

export const ScrollMenu = forwardRef<Props, "div">(
  ({ children, ...props }, ref) => {
    const isMobile = useBreakpointValue({
      base: true,
      lg: false,
    });

    return (
      <StyledScrollMenuContainer
        position="relative"
        mx={isMobile ? -4 : undefined}
        ref={ref}
        {...props}
      >
        <ReactScrollMenu
          LeftArrow={isMobile ? null : LeftArrow}
          RightArrow={isMobile ? null : RightArrow}
        >
          {children}
        </ReactScrollMenu>
      </StyledScrollMenuContainer>
    );
  }
);

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <Fade in={!isFirstItemVisible}>
      <Center
        bg="linear-gradient(to right, white 0%, white 60%, transparent 100%)"
        pr={10}
        h="100%"
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
      >
        <IconButton
          aria-label="Backward"
          variant="ghost"
          icon={<IconChevronLeft />}
          onClick={() => scrollPrev()}
        />
      </Center>
    </Fade>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  return (
    <Fade in={!isLastItemVisible}>
      <Center
        bg="linear-gradient(to left, white 0%, white 60%, transparent 100%)"
        pl={10}
        h="100%"
        position="absolute"
        top={0}
        right={0}
        zIndex={1}
      >
        <IconButton
          aria-label="Forward"
          variant="ghost"
          icon={<IconChevronRight />}
          onClick={() => scrollNext()}
        />
      </Center>
    </Fade>
  );
};

const StyledScrollMenuContainer = styled(Box)`
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;
