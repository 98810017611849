import React from "react";
import { useQuery } from "urql";

import { Text, Link } from "Shared";

import { MIN_AWESOME_GALLERY_AWARD_COST } from "Pages";

import { HScrollContainer } from "./HScrollContainer";
import { ProjectCard } from "Components/ProjectCard";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";

import { useProjectModal } from "Services/projectModal";

import {
  HomeAwesomeProjectsQuery,
  HomeAwesomeProjectsQueryVariables,
  ProjectCardFragment,
} from "@tract/common/dist/graphql";

import { ViewSource } from "Types/Project";
import { ExploreRoutes } from "Pages/Explore/ExplorePaths";

import { HOME_AWESOME_PROJECTS_QUERY } from "./graphql";

type Props = {
  templateColumns?: {
    base?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  numCards?: number;
};

export const ProjectSection: React.FC<Props> = ({
  templateColumns,
  numCards,
}) => {
  const { handleOpenProjectModal } = useProjectModal();

  const [{ data, fetching: loading }] = useQuery<
    HomeAwesomeProjectsQuery,
    HomeAwesomeProjectsQueryVariables
  >({
    pause: !numCards,
    query: HOME_AWESOME_PROJECTS_QUERY,
    variables: {
      where: {
        file: { transforms: {} },
        awards: { award: { cost: { _gte: MIN_AWESOME_GALLERY_AWARD_COST } } },
        rejectedAt: { _eq: "-infinity" },
        isPrivate: { _eq: false },
        published: { _eq: true },
        user: {
          projectPrivacy: { _is_null: true },
        },
      },
      limit: numCards || 0,
      offset: 0,
    },
  });

  const projects = data?.projects;

  const renderTitle = () => {
    return (
      <>
        <Text as={Link} to={ExploreRoutes.awesomeProjects} color="gray.900">
          Awesome Projects
        </Text>
        <Link
          fontSize="lg"
          to={ExploreRoutes.awesomeProjects}
          color="brand"
          tabIndex={-1}
        >
          See more
        </Link>
      </>
    );
  };

  if (loading) {
    return (
      <HScrollContainer
        renderTitle={renderTitle}
        templateColumns={templateColumns}
      >
        {Array.from({ length: numCards || 4 }).map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </HScrollContainer>
    );
  }

  if (!projects || (projects && projects.length < 1)) {
    return null;
  }

  const renderCard = (project: ProjectCardFragment) => {
    return (
      <ProjectCard
        key={project.id}
        viewSource={ViewSource.HomeAwesome}
        project={project}
        handleClickFromParent={(event) => {
          handleOpenProjectModal(event, project);
        }}
      />
    );
  };

  return (
    <>
      <HScrollContainer
        renderTitle={renderTitle}
        templateColumns={templateColumns}
      >
        {projects.filter((_, i) => i < (numCards || 4)).map(renderCard)}
      </HScrollContainer>
    </>
  );
};
