import React, { MutableRefObject } from "react";
import mime from "mime-types";

import {
  AspectRatio,
  Box,
  Flex,
  Link,
  IconVideo,
  Image,
  Spinner,
  VStack,
  Text,
  IconShare,
  ButtonSolid,
} from "Shared";

import { useLikeProject } from "Services/hooks/useLikeProject";
import { useProjectModal } from "Services/projectModal";

import { LikeButton } from "Components/LikeButton";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { ProfilePopover } from "Components/ProfilePopover";

import { ProjectCardFragment } from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";
import { useSession } from "Services/session";

import { ViewSource } from "Types/Project";
import { KidInvitesShareCTA } from "Pages/KidInvites/KidInvitesShareCTA";
import { getDisplayName } from "Types/User";

interface Props {
  project: ProjectCardFragment;
  viewSource: ViewSource;
  showStatsOnMobile?: boolean;
  calledFromModal?: boolean;

  handleClickFromParent: (
    event: React.MouseEvent,
    project: ProjectCardFragment
  ) => void;
  containerRef?: MutableRefObject<HTMLElement | null>;
}

export const ProjectCard: React.FC<Props> = ({
  project,
  viewSource,
  showStatsOnMobile = true,
  handleClickFromParent,
  containerRef,
}) => {
  const { currentUser } = useSession();
  const { commentCount, likeCount, viewCount } = project.stats || {
    likeCount: 0,
    viewCount: 0,
    commentCount: 0,
  };
  const isMyProject = currentUser.uid === project.user.id;

  const { handleLike, likes, isReady } = useLikeProject(project);

  const { isProjectModalOpen, closeProjectModal, setViewSource } =
    useProjectModal();
  const onLinkClick = () => {
    if (isProjectModalOpen) {
      closeProjectModal(false);
    }
  };
  const processed = !!project.file?.transforms?.length;
  const projectFile = new UserFile(project.file);
  const thumbUrl = projectFile.getTransformedUrl("thumb");
  const mimeType = mime.lookup(project.file?.filename || "") || "";
  const isVideo = mimeType.startsWith("video");
  const isPrivate = project.isPrivate;

  const projectLink = {
    pathname: `/project/${project.id}`,
    state: {
      viewSource: viewSource,
    },
  };

  const displayName = getDisplayName(project.user);

  return (
    <>
      <Box _focusWithin={{ "& .showFocused": { opacity: 1 } }}>
        <Link
          to={projectLink}
          onClick={(event: React.MouseEvent) => {
            setViewSource(viewSource);
            handleClickFromParent(event, project as any);
          }}
          display="block"
          p={0}
          borderRadius="2xl"
          position="relative"
          role="group"
          aria-label={`View project by @${project.user?.username}`}
        >
          <AspectRatio
            borderRadius="2xl"
            ratio={16 / 9}
            overflow="hidden"
            bg="gray.100"
          >
            {processed ? (
              <Image objectFit="cover" src={thumbUrl} />
            ) : (
              <VStack spacing={2}>
                <Spinner
                  thickness="3px"
                  speed="2s"
                  emptyColor="gray.300"
                  color="gray.500"
                  size="lg"
                />
                <Text fontSize="sm" fontWeight="bold" color="gray.500">
                  Processing...
                </Text>
              </VStack>
            )}
          </AspectRatio>
          {isVideo && (
            <Box
              position="absolute"
              top={0}
              left={0}
              bg="rgba(0, 0, 0, 0.5)"
              px="10px"
              py="6px"
              margin={4}
              borderRadius="6px"
            >
              <IconVideo color="white" size="20px" />
            </Box>
          )}
          {isPrivate && (
            <Box
              position="absolute"
              top={0}
              right={0}
              bg="rgba(0, 0, 0, 0.5)"
              px="10px"
              py="6px"
              margin={4}
              borderRadius="6px"
            >
              <Text fontSize="sm" color="white" lineHeight={5}>
                PRIVATE
              </Text>
            </Box>
          )}
          {!isMyProject && (
            <Box
              className="showFocused"
              opacity="0"
              position="absolute"
              top={0}
              left={0}
              right={0}
              borderTopLeftRadius="2xl"
              borderTopRightRadius="2xl"
              overflow="hidden"
              p={4}
              _groupHover={{ opacity: "1" }}
              transition=".1s ease opacity"
              bg="linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.25) 70%, rgba(0,0,0,.5) 100%)"
            >
              <Flex direction="row" justify="flex-end">
                <LikeButton
                  disabled={!isReady}
                  likeCount={likes}
                  variant="solid"
                  onLike={handleLike}
                  size="sm"
                  pointerEvents="visible"
                  borderRadius="lg"
                  _hover={{ bg: "gray.100" }}
                />
              </Flex>
            </Box>
          )}
          <KidInvitesShareCTA entryId="project-card">
            {({ onClick }) => (
              <ButtonSolid
                m={4}
                top={0}
                left={0}
                size="sm"
                bg="white"
                opacity={0}
                color="black"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick();
                }}
                pointerEvents="visible"
                borderRadius="lg"
                border="none"
                variant="outline"
                position="absolute"
                _hover={{ bg: "gray.100" }}
                _groupHover={{ opacity: "1" }}
                aria-label="Share the project"
                leftIcon={<IconShare color="black" width="16px" />}
              >
                Share
              </ButtonSolid>
            )}
          </KidInvitesShareCTA>
        </Link>
        <Flex align="center" justify="space-between" pt={2}>
          {project.user && (
            <ProfilePopover
              userId={project.user.id || ""}
              containerRef={containerRef}
              triggerElement={
                <Flex align="center" flexDirection="row" overflow="hidden">
                  <UserAvatar
                    userId={project.user?.id || ""}
                    username={project.user?.username || "unknown"}
                    avatarURL={project.user?.avatar || ""}
                    size="xs"
                    onClick={onLinkClick}
                    isTeacher={project.user.isEducator}
                    name={displayName}
                  />
                  <UsernameLink
                    userId={project.user?.id || ""}
                    username={project.user?.username || "unknown"}
                    mx={2}
                    fontWeight="bold"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    onClick={onLinkClick}
                    isTeacher={project.user.isEducator}
                  >
                    {displayName}
                  </UsernameLink>
                </Flex>
              }
            />
          )}

          <Box
            align="center"
            justify="space-around"
            fontSize="sm"
            display={{
              base: showStatsOnMobile ? "flex" : "none",
              lg: "flex",
            }}
          >
            <Box
              whiteSpace="nowrap"
              as="div"
              aria-label={`${viewCount ? viewCount : 0} Views`}
            >
              <Box as="span" role="img">
                👀
              </Box>
              <Box as="span" ml={1}>
                {viewCount ? viewCount : 0}
              </Box>
            </Box>
            <Box
              as="div"
              aria-label={`${likeCount ? likeCount : 0} Likes`}
              mx={3}
              whiteSpace="nowrap"
            >
              <Box as="span" role="img">
                ❤️
              </Box>
              <Box as="span" ml={1}>
                {likeCount ? likeCount : 0}
              </Box>
            </Box>

            <Box
              as="div"
              aria-label={`${commentCount ? commentCount : 0} Comments`}
              whiteSpace="nowrap"
            >
              <Box as="span" role="img">
                💬
              </Box>
              <Box as="span" ml={1}>
                {commentCount ? commentCount : 0}
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
