import { BoxProps, forwardRef } from "@chakra-ui/react";

import { Box, IconChevronDown, IconChevronUp, Flex } from "Shared";

export {
  Table,
  Tbody,
  Thead,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

type ThProps = {
  onOrderToggle?: () => void;
  isSortable?: boolean;
  isAscending?: boolean;
};

export const ThSortable = forwardRef<ThProps & BoxProps, "th">(
  ({ children, isSortable, isAscending, onOrderToggle, ...props }, ref) => (
    <Box
      as="th"
      borderBottom="0.0625rem solid"
      borderColor="gray.300"
      _first={{ pl: 0 }}
      _last={{ pr: 0 }}
      px={3}
      py={4}
      fontSize="md"
      fontWeight="normal"
      color="gray.600"
      textAlign="left"
      ref={ref}
      {...props}
    >
      <Flex>
        {children}
        {isSortable ? (
          isAscending ? (
            <IconChevronUp onClick={onOrderToggle} ml={3} cursor="pointer" />
          ) : (
            <IconChevronDown onClick={onOrderToggle} ml={3} cursor="pointer" />
          )
        ) : (
          false
        )}
      </Flex>
    </Box>
  )
);
