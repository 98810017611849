import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useApolloClient } from "@apollo/client";
import { v4 } from "uuid";
import { Redirect, useLocation } from "react-router-dom";
import { formatDistanceStrict } from "date-fns";
import { lookup } from "mime-types";
import { gql, useMutation, useQuery } from "urql";

import {
  Avatar,
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  Link,
  VideoPlayer,
  useToast,
  ButtonOutlined,
  IconAlertTriangle,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  ButtonSolid,
  FormHelperText,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
  Spinner,
  Textarea,
  VStack,
  IconFlag,
  IconShare,
} from "Shared";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { db } from "Services/firebase";

import { AwardListModal, AwardWithText } from "Components/AwardListModal";
import { LayoutCentered } from "Components/LayoutCentered";
import { LikeButton } from "Components/LikeButton";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";

import { getDisplayName, isParentUser } from "Types/User";
import {
  ReportStatus,
  ReportReasonTypes,
  ReportReasons,
} from "Types/AbuseReport";

import { ProjectAwardItem } from "./ProjectAwardItem";
import { Comments } from "./Comments";
import { ProjectCard, ProjectCardPlaceholder } from "Components/ProjectCard";
import { ProfilePopover } from "Components/ProfilePopover";
import { useLikeProject } from "Services/hooks/useLikeProject";
import { useProjectModal } from "Services/projectModal";
// import { ProfileBadges } from "Components/ProfileBadges";

import {
  GetAwardsQuery,
  GetAwardsQueryVariables,
  GetProjectPathQuery,
  GetProjectPathQueryVariables,
  IncProjectViewCountMutation,
  IncProjectViewCountMutationVariables,
  ProjectCardFragment,
  ProjectGalleryQueryResult,
  ProjectPathFragment,
  RejectProjectMutation,
  RejectProjectMutationVariables,
  ReportModalQuery,
  ReportModalQueryVariables,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
  UserByIdQuery,
  UserByIdQueryVariables,
} from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";
import { useFeature } from "Services/features";
import { ViewSource } from "Types/Project";
import { KidInvitesShareCTA } from "Pages/KidInvites/KidInvitesShareCTA";

import { createNotification } from "@tract/common/dist/utils/create-notification";
import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";
import { PathCover } from "Components/PathCover";
import { ProfileBadges } from "Components/ProfileBadges";
import { MOD_AVATAR_URL } from "Constants/userTypes";
import {
  GET_PROJECT_PATH,
  INC_PROJECT_VIEW_COUNT,
  PROJECT_GALLERY_QUERY,
} from "./graphql";
import { GET_AWARDS_QUERY } from "graphql/awards";
import { REJECT_PROJECT_MUTATION } from "graphql/projects";
import { USER_BY_ID_QUERY } from "Services/paths";

interface ProjectStatsProps {
  likeCount: number;
  viewCount: number;
  commentCount: number;
}

const ProjectStats: React.FC<ProjectStatsProps> = ({
  likeCount,
  viewCount,
  commentCount,
}) => {
  return (
    <HStack spacing={3} fontSize="sm" align="center">
      <Flex as="span" aria-label={` ${viewCount ? viewCount : 0} Views)`}>
        <Box as="span" role="img">
          👀
        </Box>
        <Box as="span" mx={1}>
          {viewCount ? viewCount : 0}
        </Box>
        <Box as="span">{viewCount === 1 ? "view" : "views"}</Box>
      </Flex>
      <Flex as="span" aria-label={`${likeCount} Likes`}>
        <Box as="span" role="img">
          ❤️
        </Box>
        <Box as="span" mx={1}>
          {likeCount}
        </Box>
        <Box as="span">{likeCount === 1 ? "like" : "likes"}</Box>
      </Flex>
      <Flex as="span" aria-label={`${commentCount} Comments)`}>
        <Box as="span" role="img">
          💬
        </Box>
        <Box as="span" mx={1}>
          {commentCount}
        </Box>
        <Box as="span">{commentCount === 1 ? "comment" : "comments"}</Box>
      </Flex>
    </HStack>
  );
};

interface Props {
  project: ProjectCardFragment;
  isFullPage?: boolean;
}

export const ProjectDetails: React.FC<Props> = ({ project, isFullPage }) => {
  const { currentUser } = useSession();
  const location = useLocation<{ viewSource?: string }>();

  const [, incViewCount] = useMutation<
    IncProjectViewCountMutation,
    IncProjectViewCountMutationVariables
  >(INC_PROJECT_VIEW_COUNT);

  const {
    isProjectModalOpen,
    handleOpenProjectModal,
    closeProjectModal,
    modalRef,
    viewSource,
  } = useProjectModal();
  const isMyProject = currentUser?.uid === project.user.id;

  const { handleLike: onClickLike, likes, isReady } = useLikeProject(project);

  const processed = !!project.file?.transforms?.length;

  const [newAward, setNewAward] = useState<AwardWithText | null>(null);

  const [{ fetching: loading, data }] = useQuery<
    GetProjectPathQuery,
    GetProjectPathQueryVariables
  >({
    query: GET_PROJECT_PATH,
    variables: { id: project.node?.path.id },
  });

  const path = data?.path;

  const { commentCount, viewCount, likeCount } = project?.stats || {
    commentCount: 0,
    viewCount: 0,
    likeCount: 0,
  };

  const mobileOnlyView = useBreakpointValue({ base: true, lg: false });

  const numberOfAwesomeProjects =
    useBreakpointValue({
      base: 4,
      lg: 2,
      xl: 3,
    }) || 0;

  const onLinkClick = () => {
    if (isProjectModalOpen) {
      closeProjectModal(false);
    }
  };

  const [{ data: awesomeProjectsData }, refetch] = useQuery<
    ProjectGalleryQueryResult["data"]
  >({
    query: PROJECT_GALLERY_QUERY,
    variables: {
      limit: numberOfAwesomeProjects,
      offset: 0,
      where: {
        file: { transforms: {} },
        rejectedAt: { _eq: "-infinity" },
        published: { _eq: true },
        isPrivate: { _eq: false },
        awards: {},
        id: { _neq: project.id },
        pathNodeId: { _eq: project.node?.id },
      },
    },
  });

  useEffect(() => {
    if (!!numberOfAwesomeProjects) {
      refetch();
    }
  }, [numberOfAwesomeProjects, refetch]);

  const awesomeProjects = awesomeProjectsData?.projects;

  const {
    isOpen: isReportAbuseModalOpen,
    onOpen: openReportAbuseModal,
    onClose: closeReportAbuseModal,
  } = useDisclosure();

  const {
    isOpen: isAwardModalOpen,
    onOpen: openAwardModal,
    onClose: closeAwardModal,
  } = useDisclosure();

  const [{ data: awardsData }] = useQuery<
    GetAwardsQuery,
    GetAwardsQueryVariables
  >({
    query: GET_AWARDS_QUERY,
  });

  const projectMime = lookup(project.file?.filename || "") || "";
  const isVideo = projectMime.startsWith("video");
  const projectFile = new UserFile(project.file);
  const imageUrl = projectFile.getTransformedUrl("image_full");
  const videoUrl = projectFile.getTransformedUrl("video_full");

  const { track } = useAnalytics();
  const [isViewed, setViewed] = useState(false);

  useEffect(() => {
    if (isViewed || !path) {
      return;
    }

    track(ANALYTICS_EVENTS.PROJECT_VIEWED, {
      projectId: project.id,
      pathId: project.node?.path.id,
      pathSlug: path?.slug,
      pathTitle: path?.title,
      pathAuthorId: path?.authorId,
      projectViewSource:
        viewSource || location.state?.viewSource || ViewSource.Unknown,
    });

    const incViews = async () => {
      const mutationResult = await incViewCount({ projectId: project.id });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
    };

    if (currentUser.uid !== project.user.id) {
      incViews().catch((err: any) => {
        captureException(err);
      });
    }

    setViewed(true);
  }, [
    track,
    path,
    project,
    isViewed,
    incViewCount,
    currentUser.uid,
    location,
    viewSource,
  ]);

  const wasRejected =
    !!project.rejectedAt && project.rejectedAt !== "-infinity";

  const rejectReason = !!project.rejectedReason
    ? ReportReasons[project.rejectedReason as ReportReasonTypes]
    : undefined;

  const [{ data: rejectedByUserData }] = useQuery<
    UserByIdQuery,
    UserByIdQueryVariables
  >({
    query: USER_BY_ID_QUERY,
    variables: {
      userId: project.rejectedByUserId!,
    },
    pause: !wasRejected || !project?.rejectedByUserId,
  });
  const rejectedByUser = rejectedByUserData?.users?.length
    ? rejectedByUserData.users[0]
    : undefined;
  const isRejectedByMe = rejectedByUser?.firestoreId === currentUser.uid;
  const rejectedByUserName = isRejectedByMe ? "You" : "A moderator";
  const rejectedByAvatar = isRejectedByMe
    ? rejectedByUser?.avatar
    : MOD_AVATAR_URL;

  const displayName = getDisplayName(project.user);

  if (loading) {
    return <LayoutCentered isLoading={loading} />;
  }
  if (!path) {
    return <Redirect to="/" />;
  }

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      align={{ base: "stretch", lg: "flex-start" }}
      overflowY={isFullPage ? undefined : "scroll"}
      overflowX={isFullPage ? undefined : "hidden"}
      ref={modalRef}
      width="100%"
      maxWidth="100%"
    >
      <Flex
        direction="column"
        align="center"
        overflow={{ lg: "hidden" }}
        flex={1}
      >
        <Box
          pt={{ base: 4, md: 6, lg: 16 }}
          pb={{ base: 4, md: 6, lg: 10 }}
          px={{ base: 3, lg: 10 }}
          mx="auto"
          width="100%"
          maxWidth={{ lg: "50rem" }}
        >
          {wasRejected && (
            <Box
              width="full"
              border="1px solid"
              borderColor="gray.300"
              p={6}
              mb={12}
              borderRadius="xl"
              boxShadow="base"
            >
              <HStack spacing={4} width="full" alignItems="top">
                <Avatar
                  src={rejectedByAvatar || ""}
                  name={rejectedByUserName}
                  aria-hidden="true"
                  width={12}
                />
                <Box>
                  <Text>
                    <Text as="span" fontWeight="bold">
                      {rejectedByUserName}
                    </Text>{" "}
                    requested revisions
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    {formatDistanceStrict(
                      new Date(project.rejectedAt),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                  </Text>
                  <Text mt={2} overflowWrap="anywhere" color="gray.600">
                    {project?.rejectedFeedback?.length
                      ? project.rejectedFeedback
                      : rejectReason}
                  </Text>
                </Box>
              </HStack>
            </Box>
          )}
          <Flex align="start" justify="space-between">
            <Flex direction="row" align="center">
              <UserAvatar
                userId={project.user?.id || ""}
                username={project.user?.username || "unknown"}
                avatarURL={project.user?.avatar || ""}
                size="md"
                ml={{ base: "md", lg: 0 }}
                onClick={onLinkClick}
                name={displayName}
                isTeacher={project.user.isEducator}
                mr={4}
              />
              <UsernameLink
                userId={project.user?.id || ""}
                username={project.user?.username || ""}
                fontSize={mobileOnlyView ? "md" : "lg"}
                fontWeight="bold"
                color="gray.900"
                onClick={onLinkClick}
                isTeacher={project.user.isEducator}
                mr={2}
              >
                {displayName}
              </UsernameLink>
              <ProfileBadges
                iconSize={5}
                user={project.user}
                spacing={1}
                flagFont="lg"
              />
            </Flex>

            {!mobileOnlyView && (
              <HStack ml={4}>
                {!isMyProject && (
                  <Box as="span" boxShadow="sm" borderRadius="full">
                    <LikeButton
                      disabled={!isReady}
                      variant="outline"
                      likeCount={likes}
                      onLike={onClickLike}
                    />
                  </Box>
                )}
                <KidInvitesShareCTA entryId="project-details">
                  {({ onClick }) => (
                    <ButtonOutlined
                      size="lg"
                      onClick={onClick}
                      leftIcon={<IconShare width="24px" />}
                    >
                      Share
                    </ButtonOutlined>
                  )}
                </KidInvitesShareCTA>
                {!isMyProject && (
                  <Box as="span" boxShadow="sm" borderRadius="full">
                    <Button
                      variant="outline"
                      size="lg"
                      onClick={openAwardModal}
                    >
                      Give Award
                    </Button>
                  </Box>
                )}
              </HStack>
            )}
          </Flex>
          {!mobileOnlyView && (
            <Box pl={16} mt={2}>
              <ProjectStats
                viewCount={viewCount}
                commentCount={commentCount}
                likeCount={likeCount}
              />
            </Box>
          )}
        </Box>

        <Box px={{ base: 0, lg: 10 }} maxW="90rem" mx="auto" width="100%">
          {processed ? (
            isVideo ? (
              <VideoPlayer
                url={videoUrl}
                borderRadius={{ base: 0, lg: "2xl" }}
              />
            ) : (
              <Image
                src={imageUrl}
                width="100vw"
                borderRadius={{ base: 0, lg: "2xl" }}
              />
            )
          ) : (
            <AspectRatio
              ratio={16 / 9}
              bg="gray.100"
              borderRadius={{ base: 0, lg: "2xl" }}
            >
              <VStack spacing={3}>
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.300"
                  color="gray.500"
                  size="xl"
                />
                <Text size="md" fontWeight="bold" color="gray.500">
                  Processing...
                </Text>
              </VStack>
            </AspectRatio>
          )}
        </Box>

        {project.text && (
          <Box
            px={{ base: 4, lg: 10 }}
            mx="auto"
            width="100%"
            maxWidth={{ lg: "50rem" }}
          >
            <Flex mt={{ base: 4, md: 10 }} justify="center">
              <Text color="gray.900" fontWeight="medium">
                {project.text}
              </Text>
            </Flex>
          </Box>
        )}

        {mobileOnlyView && (
          <Flex
            direction="column"
            align="center"
            justifyContent="space-around"
            mt={10}
          >
            <HStack align="center" mb={4}>
              <Box as="span" boxShadow="sm" borderRadius="full">
                <LikeButton
                  variant="outline"
                  likeCount={likes}
                  onLike={onClickLike}
                />
              </Box>
              <KidInvitesShareCTA entryId="project-details">
                {({ onClick }) => (
                  <ButtonOutlined
                    size="lg"
                    leftIcon={<IconShare width="20px" />}
                    onClick={onClick}
                  >
                    Share
                  </ButtonOutlined>
                )}
              </KidInvitesShareCTA>
              {!isMyProject && !isParentUser(currentUser) && (
                <Box as="span" boxShadow="sm" borderRadius="full">
                  <Button variant="outline" size="lg" onClick={openAwardModal}>
                    Give Award
                  </Button>
                </Box>
              )}
            </HStack>
            <ProjectStats
              viewCount={viewCount}
              commentCount={commentCount}
              likeCount={likeCount}
            />
          </Flex>
        )}

        {project?.awards?.length > 0 && (
          <Box
            px={{ base: 4, lg: 10 }}
            mx="auto"
            width="100%"
            maxWidth={{ lg: "50rem" }}
          >
            <Box
              mt={{ base: 14, lg: 10 }}
              bg={{ base: "white", lg: "gray.50" }}
              pt={{ base: 0, lg: 8 }}
              pb={{ base: 0, lg: 10 }}
              px={{ base: 0, lg: 10 }}
              borderRadius="2xl"
            >
              <Flex
                align={"center"}
                justify={{ base: "flex-start", lg: "space-between" }}
                mb={3}
              >
                <Text as="h2" fontSize="2xl" fontWeight="bold" mb={2}>
                  Awards
                </Text>
                {!isMyProject && !isParentUser(currentUser) && !mobileOnlyView && (
                  <Button
                    onClick={openAwardModal}
                    fontWeight="bold"
                    color="brand"
                    fontSize="md"
                    variant="link"
                  >
                    Give Award
                  </Button>
                )}
              </Flex>

              <Grid
                gap={6}
                templateColumns={{
                  base: `repeat(2, minmax(0, 1fr))`,
                  md: `repeat(3, minmax(0, 1fr))`,
                  xl: `repeat(4, minmax(0, 1fr))`,
                }}
                px={0}
              >
                {project.awards.map(({ awardId, count }) => {
                  const award = awardsData?.awards.find(
                    (award) => award.id === awardId
                  );
                  if (!award) return null;

                  return (
                    <ProjectAwardItem
                      award={award}
                      count={count}
                      mb={{ base: 4, md: 0 }}
                      key={awardId}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Box>
        )}

        <Box
          px={{ base: 4, lg: 10 }}
          mx="auto"
          width="100%"
          maxWidth={{ lg: "50rem" }}
        >
          <Box
            bg={{ base: "white", lg: "gray.50" }}
            mt={10}
            pt={{ base: 0, lg: 8 }}
            pb={{ base: 0, lg: 10 }}
            px={{ base: 0, lg: 10 }}
            borderRadius="2xl"
          >
            <Text as="h2" fontSize="2xl" fontWeight="bold">
              The Challenge
            </Text>
            <Text fontSize="lg" color="gray.900" mt={2} mb={6}>
              {project?.node?.title}
            </Text>
            <Flex
              align={["flex-start", null, "center"]}
              direction={["column", null, "row"]}
            >
              <Link
                to={`/path/${path.slug}`}
                aria-hidden="true"
                tabIndex={-1}
                mb={[4, 0]}
                width={["100%", "inherit"]}
                onClick={onLinkClick}
              >
                <AspectRatio
                  ratio={16 / 9}
                  width={{ base: "100%", lg: "8rem" }}
                  minWidth="6rem"
                >
                  <PathCover
                    borderRadius={8}
                    cover={path.coverFile}
                    objectFit="cover"
                  />
                </AspectRatio>
              </Link>
              <Flex direction="column" flex="1" ml={{ base: 0, md: 6 }}>
                <Link
                  to={`/path/${path.slug}`}
                  fontSize="md"
                  fontWeight="bold"
                  my={{ base: 0, md: 1 }}
                  onClick={onLinkClick}
                >
                  {path.title}
                </Link>
                {path.user && (
                  <ProfilePopover
                    userId={path.user.id || ""}
                    triggerElement={
                      <Flex
                        align="center"
                        flexDirection="row"
                        overflow="hidden"
                        mt={{ base: 1, md: 0 }}
                      >
                        <UserAvatar
                          username={path.user.username || ""}
                          avatarURL={path.user.avatar || ""}
                          size="xs"
                        />
                        <UsernameLink
                          username={path.user.username || ""}
                          mx={2}
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          fontSize="sm"
                          onClick={onLinkClick}
                        >
                          {path.user.username}
                        </UsernameLink>
                      </Flex>
                    }
                  />
                )}
              </Flex>
            </Flex>
          </Box>
        </Box>

        <Box pb={{ base: 10, lg: 24 }} overflow="hidden" width="100%">
          {awesomeProjects?.length ? (
            <Box
              mt={10}
              maxWidth={{ lg: "90rem" }}
              width="100%"
              mx="auto"
              px={{ base: 0, lg: 10 }}
            >
              <Flex
                px={{ base: 4, lg: 0 }}
                align="center"
                justify="space-between"
              >
                <Text fontSize={{ base: "2xl", lg: "lg" }} fontWeight="bold">
                  More projects from this challenge
                </Text>
                {!mobileOnlyView &&
                  awesomeProjects?.length >= (numberOfAwesomeProjects || 4) && (
                    <Link
                      to={`/explore/projects?path=${path.id}&v=${project?.node?.id}`}
                      target="_blank"
                      fontWeight="bold"
                      color="brand"
                      fontSize="md"
                    >
                      View More
                    </Link>
                  )}
              </Flex>
              <Box pt={4} mx={{ lg: -1 }} overflowX="auto">
                <Grid
                  templateColumns={{
                    base: `repeat(4, 240px)`,
                    lg: "repeat(2, minmax(0, 1fr))",
                    xl: "repeat(3, minmax(0, 1fr))",
                  }}
                  gap={{ base: 4, lg: 6 }}
                  px={{ base: 4, lg: 1 }}
                  width={{ base: "fit-content", lg: "100%" }}
                >
                  {awesomeProjects.map((proj) => (
                    <ProjectCard
                      key={proj.id}
                      viewSource={ViewSource.ProjectDetails}
                      project={proj}
                      showStatsOnMobile={false}
                      containerRef={modalRef}
                      handleClickFromParent={(
                        event,
                        openProject: ProjectCardFragment
                      ) => {
                        handleOpenProjectModal(event, openProject);
                      }}
                    />
                  ))}
                  {/* fill remaining space with gray placeholders */}
                  {Array.from({
                    length: numberOfAwesomeProjects - awesomeProjects.length,
                  }).map((_, i) => (
                    <ProjectCardPlaceholder key={i} />
                  ))}
                </Grid>
              </Box>
            </Box>
          ) : null}
          {!mobileOnlyView && project.user.id !== currentUser?.uid && (
            <Flex mt={20} justify="center">
              <Button
                colorScheme="red"
                size="lg"
                variant="outline"
                leftIcon={<IconFlag />}
                onClick={openReportAbuseModal}
              >
                Report
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>
      <Box
        minWidth={{ lg: "24rem" }}
        width={{ lg: "24rem" }}
        height={{ lg: "calc(100vh - 2.5rem)" }}
        position={{ lg: "sticky" }}
        top={{ lg: !isProjectModalOpen ? "4rem" : 0 }}
        overflowY={{ lg: "scroll" }}
        pl={{ base: 0, lg: 6 }}
        mt={{ base: -6, lg: 0 }}
        borderLeft={{ base: 0, lg: "1px solid" }}
        borderColor={{ base: "white", lg: "gray.200" }}
        mx={{ base: 4, lg: 0 }}
      >
        <Comments
          project={project}
          newAward={newAward}
          onNewAwardAdded={() => setNewAward(null)}
          containerRef={modalRef}
        />
      </Box>
      <ReportAbuseModal
        isOpen={isReportAbuseModalOpen}
        onClose={closeReportAbuseModal}
        project={project}
        path={path}
      />

      <AwardListModal
        isOpen={isAwardModalOpen}
        onClose={closeAwardModal}
        project={project}
        onAwardSuccess={() => {
          closeAwardModal();
        }}
      />

      {mobileOnlyView && project.user.id !== currentUser?.uid && (
        <Flex mb={20} justify="center">
          <Button
            colorScheme="red"
            size="lg"
            variant="outline"
            leftIcon={<IconAlertTriangle />}
            onClick={openReportAbuseModal}
          >
            Report
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const REPORT_MODAL_QUERY = gql`
  query ReportModal($nodeId: uuid!, $coinTrxId: uuid!) {
    path_node_by_pk(id: $nodeId) {
      id
      title
    }

    coin_trx_by_pk(id: $coinTrxId) {
      id
      amount
    }
  }
`;

type ReportAbuseModalProps = {
  project: ProjectCardFragment;
  path: ProjectPathFragment;
  isOpen: boolean;
  onClose: () => void;
};

const ReportAbuseModal: React.FC<ReportAbuseModalProps> = ({
  project,
  path,
  isOpen,
  onClose,
}) => {
  const apollo = useApolloClient();
  const { currentUser, isAdmin } = useSession();
  const toast = useToast();
  const { track, trackForUser } = useAnalytics();
  const isSubmissionFeedbackEnabled = useFeature("submission-feedback");
  const [, rejectProject] = useMutation<
    RejectProjectMutation,
    RejectProjectMutationVariables
  >(REJECT_PROJECT_MUTATION);
  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);

  const formik = useFormik({
    initialValues: {
      reason: ReportReasonTypes.Invalid,
      feedback: "",
      reject: false,
    },

    onSubmit: async (values) => {
      try {
        const reportRef = db.collection(`abuse-reports`).doc();

        let wasSubmissionRejected = false;
        // Also rejected by mod
        if (values.reject) {
          const result = await apollo.query<
            ReportModalQuery,
            ReportModalQueryVariables
          >({
            query: REPORT_MODAL_QUERY,
            variables: {
              nodeId: project.node?.id,
              coinTrxId:
                project.rewardCoinTrxId ||
                "00000000-0000-0000-0000-000000000000",
            },
          });

          const rewardCoinTrx = result.data.coin_trx_by_pk;
          const rejectAmount = rewardCoinTrx?.amount || 0;

          const mutationResult = await rejectProject({
            coinTrxId: v4(),
            coinAmount: rejectAmount,
            projectId: project.id,
            rejectedAt: new Date().toISOString(),
            rejectedByUserId: currentUser.uid,
            rejectReason: values.reason,
            rejectFeedback: values.feedback.trim() || null,
            userId: project.user.id,
          });
          if (!!mutationResult.error) {
            throw new Error(mutationResult.error.toString());
          }

          wasSubmissionRejected = true;

          const eventProps = {
            pathId: path.id,
            pathTitle: path.title,
            pathAuthorId: path?.authorId || "unknown",
            pathNodeId: project.node?.id,
            pathNodeTitle: project.node?.title,
            isFeedbackProvided: !!values?.feedback?.length,
            reason: values?.reason,
          };

          track(ANALYTICS_EVENTS.SUBMISSION_REJECTED, eventProps);
          trackForUser(
            project.user.id || "",
            ANALYTICS_EVENTS.SUBMISSION_REJECTION_RECEIVED,
            eventProps
          );
        }

        if (!values.reject) {
          await reportRef.set({
            type: "project",
            reporterId: currentUser.uid,
            reporteeId: project.user.id,
            reason: values.reason,
            status: ReportStatus.Pending,
            meta: {
              projectId: project.id,
            },
          });
        }

        if (values.reject) {
          createFeedActivity({
            apiKey: process.env.REACT_APP_STREAM_API_KEY,
            feedSlug: "notification",
            feedUserId: project.user.id,
            activity: createNotification({
              type: "actor",
              actor: currentUser.id,
              verb: "reject",
              entity: "project",
              entityId: project.id,
              targetPath: `/project/${project.id}`,
              description: "has requested revisions on your project submission",
            }),
          });
        }

        toast({
          status: "success",
          title: values.reject ? "Project Rejected" : "Project Reported",
        });

        track(ANALYTICS_EVENTS.PROJECT_REPORTED, {
          projectId: project.id,
          projectReportReason: values.reason,
          wasSubmissionRejected,
        });

        onClose();
      } catch (error: any) {
        captureException(error);
        toast({
          status: "error",
          title: "There was a problem reporting this project",
        });
      }
    },
  });

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Report Project</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Reason</FormLabel>
            <Select
              size="lg"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            >
              <option value={ReportReasonTypes.Invalid}>
                {ReportReasons.Invalid}
              </option>
              <option value={ReportReasonTypes.Offensive}>
                {ReportReasons.Offensive}
              </option>
              <option value={ReportReasonTypes.Plagiarised}>
                {ReportReasons.Plagiarised}
              </option>
              <option value={ReportReasonTypes.Other}>
                {ReportReasons.Other}
              </option>
            </Select>
            <FormHelperText>
              Let us know why you’re reporting this project
            </FormHelperText>
          </FormControl>
          {(isAdmin || currentUser.uid === path.authorId) && (
            // TODO: URA
            // || isParentOrTeacherOf(project.user.id || "", currentUser)
            <>
              <FormControl mt={6}>
                <Checkbox
                  size="lg"
                  name="reject"
                  isChecked={formik.values.reject}
                  onChange={formik.handleChange}
                >
                  <Text fontSize="md">Request changes on this submission</Text>
                </Checkbox>
              </FormControl>
              {isSubmissionFeedbackEnabled && (
                <FormControl mt={6}>
                  <Textarea
                    disabled={!formik.values.reject}
                    onChange={formik.handleChange}
                    name="feedback"
                    value={formik.values.feedback}
                    placeholder="Provide feedback..."
                    width="100%"
                    height="6rem"
                    py={3}
                    size="md"
                    autoComplete="off"
                    borderRadius="lg"
                  />
                </FormControl>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={
              formik.values.reject &&
              formik.values.reason === ReportReasonTypes.Other &&
              !formik.values.feedback.length
            }
            isLoading={formik.isSubmitting}
            size="lg"
            onClick={() => formik.handleSubmit()}
          >
            {formik.values.reject ? "Report & Request Changes" : "Report"}
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
