import React from "react";
import { Helmet } from "react-helmet";

import { Text, VStack } from "Shared";
import { ContainerMarketing } from "Components/LayoutMarketing";

import { FaqContent } from "./FaqContent";
import { FaqEducators } from "./FaqEducators";
import { FaqPlatform } from "./FaqPlatform";
import { FaqGettingStarted } from "./FaqGettingStarted";

function Header() {
  return (
    <Text
      as="h1"
      fontSize={{ base: "4xl", lg: "5xl" }}
      fontWeight="bold"
      color="gray.900"
      mb={6}
      textAlign="center"
    >
      Frequently Asked Questions
    </Text>
  );
}

export function Faq() {
  return (
    <ContainerMarketing pt={12} pb={32} maxWidth="50rem">
      <Helmet>
        <title>FAQ — Tract</title>
      </Helmet>
      <VStack align="stretch" spacing={10}>
        <Header />
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          textAlign="center"
        >
          The Platform
        </Text>
        <FaqPlatform />
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          textAlign="center"
        >
          The Content
        </Text>
        <FaqContent />
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          textAlign="center"
        >
          Getting Started
        </Text>
        <FaqGettingStarted />
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          textAlign="center"
        >
          Educators
        </Text>
        <FaqEducators />
      </VStack>
    </ContainerMarketing>
  );
}
