import React, { FC, useEffect, useState } from "react";

import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  EmptyState,
  Flex,
  Button,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

import { PathStatus } from "@tract/common/dist/types/models/Path";
import { Order_By, usePathsWithReviewsQuery } from "@tract/common/dist/graphql";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { PathRow } from "Pages/AdminDashboard/AdminPaths/PathsTable";
import { PathActivityType } from "Pages/CreateV4/PathActions";

type Props = {
  creatorLevels: string | string[];
  thumbnailSize?: string;
  showHeaderAndBorders?: boolean;
};

const PATHS_PER_PAGE = 20;

export const NeedsReviewTable: FC<Props> = ({
  creatorLevels,
  thumbnailSize = "10rem",
  showHeaderAndBorders = true,
}) => {
  const { currentUser } = useSession();
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const { data, loading, fetchMore } = usePathsWithReviewsQuery({
    variables: {
      status: { _eq: PathStatus.InReview },
      offset: 0,
      reviewUserId: {},
      limit: PATHS_PER_PAGE,
      creatorLevel: { _in: creatorLevels as string[] },
      userId: currentUser.id,
      type: { _eq: PathActivityType.ReviewRequest },
      orderBy: { updatedAt: Order_By.Asc },
    },
  });

  const paths = data?.pathReviews.map((review) => review.path);

  useEffect(() => {
    if (!!paths?.length) {
      const moreToLoad = paths.length >= PATHS_PER_PAGE * currentPage;

      setHasNextPage(moreToLoad);
    }
  }, [data, currentPage, setHasNextPage, paths]);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          offset: paths?.length,
        },
      });
      setCurrentPage(currentPage + 1);
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoadingMore(false);
    }
  };

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  if (!loading && !paths?.length) {
    return <EmptyState headline="No paths to review" mt={10} />;
  }

  return (
    <>
      <Table width="100%">
        {showHeaderAndBorders && (
          <Thead>
            <Tr>
              <Th>Thumbnail</Th>
              <Th>Title</Th>
              <Th>Created By</Th>
              <Th>
                <Box visibility="hidden">Actions</Box>
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {paths?.map((path) => {
            return (
              <PathRow
                showPeerReviewLink
                key={path.id}
                path={path}
                pathStatus={PathStatus.InReview}
                thumbnailSize={thumbnailSize}
                showHeaderAndBorders={showHeaderAndBorders}
              />
            );
          })}
        </Tbody>
      </Table>
      {hasNextPage && (
        <Flex direction="column" alignItems="center" mt={8}>
          <Button
            size="lg"
            variant="outline"
            isLoading={loadingMore}
            onClick={loadMore}
          >
            Load More
          </Button>
        </Flex>
      )}
    </>
  );
};
