import React, {
  useState,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
} from "react";
import { useBreakpointValue, UseDisclosureReturn } from "@chakra-ui/react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useRouteMatch } from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Flex,
  FontAwesomeIcon,
  Link,
  VStack,
  Badge,
} from "Shared";

import { SideNav } from "Components/SideNav";
import { SideNavGroupLabel } from "Components/SideNav/SideNavGroupLabel";
import { SideNavLink } from "Components/SideNav/SideNavLink";

import { useSession } from "Services/session";
import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { captureException } from "Services/errors";

import { isKidUser, isTeacherUser } from "Types/User";
import { useCollection } from "Utils/hooks/firestore";

export const useLayoutAppSideNav = () => {
  return useContext(LayoutAppSideNavContext);
};

export type PinnedTag = {
  tag: string;
  role: PinnedTagRole;
  priority: number;
};

export enum PinnedTagRole {
  All = "ALL",
  Kid = "KID",
  Teacher = "TEACHER",
}

export const LayoutAppSideNavContext = createContext<
  UseDisclosureReturn & { widthCollapsed?: string; widthExpanded?: string }
>({
  isOpen: false,
  onOpen: () => null,
  onClose: () => null,
  onToggle: () => null,
  isControlled: false,
  getButtonProps: () => null,
  getDisclosureProps: () => null,
  widthCollapsed: "4.5rem",
  widthExpanded: "16rem",
});

export const LayoutAppSideNavProvider: FC<
  PropsWithChildren<{ value: UseDisclosureReturn }>
> = ({ value, ...props }) => {
  return (
    <LayoutAppSideNavContext.Provider
      value={{ ...value, widthCollapsed: "4.5rem", widthExpanded: "16rem" }}
      {...props}
    />
  );
};

export const LayoutAppSideNav: React.FC = () => {
  const { currentUser } = useSession();
  const { track } = useAnalytics();
  const isKid = isKidUser(currentUser);
  const isTeacher = isTeacherUser(currentUser);

  const isOpen = useBreakpointValue({
    base: false,
    lg: true,
  });
  const {
    //onToggle,
    widthExpanded,
    widthCollapsed,
  } = useLayoutAppSideNav();
  const [showPeek, setShowPeek] = useState(false);
  const isCollapsed = !isOpen && !showPeek;

  const isFollowing = !!useRouteMatch("/following");
  const isEvents = !!useRouteMatch("/events");
  const isClasses = !!useRouteMatch("/classes");

  const getWidth = () => {
    return isOpen ? widthExpanded : widthCollapsed;
  };

  const handleEnter = () => {
    if (!isOpen) {
      setShowPeek(true);
    }
  };

  const handleLeave = () => {
    if (!isOpen) {
      setShowPeek(false);
    }
  };

  const roles = [PinnedTagRole.All];
  if (isTeacher) {
    roles.push(PinnedTagRole.Teacher);
  }
  if (isKid) {
    roles.push(PinnedTagRole.Kid);
  }

  const { data: pinnedTagsData } = useCollection<PinnedTag>("pinned-tags", {
    where: ["role", "in", roles],
    orderBy: ["priority", "asc"],
    onError: (err) => captureException(err),
  });

  const clickPinnedTag = (tag: string) => {
    track(ANALYTICS_EVENTS.EXPLORE_TAG_CLICKED, {
      tagValue: tag,
    });
  };

  const unfinishedAssignmentCount =
    currentUser?.learnerGroups?.flatMap((lg) =>
      lg.learnerGroup?.assignments.filter((a) => !a.isFinished)
    ).length || 0;

  if (isTeacher) return null;

  return (
    <SideNav
      width={getWidth()}
      minW={getWidth()}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      overflow="scroll"
      py={0}
      hasBorder={false}
    >
      <Flex
        direction="column"
        pt={3}
        pb={6}
        flex={1}
        top={0}
        left={0}
        bottom={0}
        bg="white"
        position={isOpen || !showPeek ? "relative" : "absolute"}
        w={isOpen ? "auto" : widthExpanded}
        zIndex={11}
        boxShadow={showPeek && !isOpen ? "md" : "none"}
        transition="width 150ms ease"
      >
        <VStack as="nav" align="stretch" w="full" spacing={0} px={2}>
          {isKid && (
            <SideNavLink
              as={Link}
              to="/classes"
              iconOnly={isCollapsed}
              fontSize="lg"
              minH="3.5rem"
              iconLeft={
                <FontAwesomeIcon
                  icon={isClasses ? solid("backpack") : regular("backpack")}
                  size={8}
                />
              }
              iconRight={
                !!unfinishedAssignmentCount ? (
                  <Badge variant="solid" colorScheme="brandFull">
                    {unfinishedAssignmentCount}
                  </Badge>
                ) : undefined
              }
              isActive={isClasses}
            >
              Classes
            </SideNavLink>
          )}
          <SideNavLink
            as={Link}
            to="/following"
            iconOnly={isCollapsed}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isFollowing ? solid("eyes") : regular("eyes")}
                size={8}
              />
            }
            isActive={isFollowing}
          >
            Following
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/events"
            iconOnly={isCollapsed}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isEvents ? solid("calendar") : regular("calendar")}
                size={8}
              />
            }
            isActive={isEvents}
          >
            Events
          </SideNavLink>
        </VStack>
        <Divider my={4} />
        <VStack as="nav" align="stretch" spacing={0} px={2}>
          <SideNavGroupLabel hidden={isCollapsed}>
            Popular Topics
          </SideNavGroupLabel>
          <SideNavLink
            as={Link}
            to="/explore/interest/arts"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("paintbrush-pencil")} />}
          >
            Arts
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/explore/interest/gaming"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("alien-8bit")} />}
          >
            Gaming
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/explore/interest/sports"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("football")} />}
          >
            Sports
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/explore/interest/music"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("music")} />}
          >
            Music
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/explore/interest/food"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("avocado")} />}
          >
            Food
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/explore/interest/nature"
            iconOnly={isCollapsed}
            iconLeft={<FontAwesomeIcon icon={regular("narwhal")} />}
          >
            Nature
          </SideNavLink>
        </VStack>
        <Divider my={4} hidden={isCollapsed} />
        <VStack
          as="nav"
          align="stretch"
          spacing={0}
          px={2}
          hidden={isCollapsed}
        >
          <SideNavGroupLabel hidden={isCollapsed}>Discover</SideNavGroupLabel>
          <Box pl={3}>
            {pinnedTagsData?.map((doc, i) => (
              <Button
                variant="outline"
                whiteSpace="nowrap"
                size="sm"
                display="inline-flex"
                key={doc.tag}
                as={Link}
                to={`/explore/tag/${doc.tag}`}
                onClick={() => clickPinnedTag(doc.tag)}
                mr={2}
                mb={2}
              >
                #{doc.tag}
              </Button>
            ))}
          </Box>
        </VStack>
        {/* <Box flex={1} />
        <VStack as="nav" align="stretch" spacing={1} px={2}>
          <SideNavLink
            as={Link}
            to="#"
            _focus={{ outline: "none", boxShadow: "outline" }}
            iconOnly={isCollapsed}
            iconLeft={isOpen ? <IconSidebarLeft /> : <IconSidebarRight />}
            onClick={onToggle}
          >
            {isOpen ? "Collapse" : "Expand"}
          </SideNavLink>
        </VStack> */}
      </Flex>
    </SideNav>
  );
};
