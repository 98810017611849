import { FC, PropsWithChildren, useState } from "react";
import { Helmet } from "react-helmet";

import { Box, Waypoint } from "Shared";

import { HelpButton } from "Components/HelpButton";

import { NavbarMarketing } from "./NavbarMarketing";
// import { FooterMarketing } from "./FooterMarketing";

export { ContainerMarketing } from "./ContainerMarketing";

type Props = {
  title?: string;
};

export const LayoutMarketing: FC<PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  const [isFixed, setIsFixed] = useState<boolean>(false);

  function handlePositionChange({
    currentPosition,
  }: {
    currentPosition: string;
  }) {
    if (currentPosition === "above") {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }

  return (
    <Box position="relative">
      <Helmet>
        <title>{title ? `${title} — Tract` : "Tract"}</title>

        {/** Inject Autopilot tracking snippet in production only */}
        {process.env.NODE_ENV === "production" && (
          <script type="text/javascript">
            {`(function(o){var b="https://nimblebird.co/anywhere/",t="e175022679c6436d9f115aa5a1b3f8d7314c091d31264dbfb56ce8d2b306239e",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;l.parentNode.insertBefore(e,l);};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({});`}
          </script>
        )}
      </Helmet>
      <Box maxWidth="100rem" bg="white" mx="auto">
        <NavbarMarketing isFixed={isFixed} />
        <Waypoint onPositionChange={handlePositionChange} topOffset="96px" />
        {children}
        {/* <FooterMarketing /> */}
      </Box>
      <HelpButton
        display={["none", null, "flex"]}
        position="fixed"
        right={6}
        bottom={6}
        zIndex="9"
      />
    </Box>
  );
};
