import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  GetPathWithNodesQueryResult,
  GetPathWithNodesQueryVariables,
  RemovePathV4CoAuthorMutationResult,
  RemovePathV4CoAuthorMutationVariables,
} from "@tract/common/dist/graphql";
import { GET_PATH_WITH_NODES_QUERY } from "Pages/CreateV4/graphql";

export const delete_path_author: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const variables = info.variables as RemovePathV4CoAuthorMutationVariables;
  cache.updateQuery<
    GetPathWithNodesQueryResult["data"],
    GetPathWithNodesQueryVariables
  >(
    {
      query: GET_PATH_WITH_NODES_QUERY,
      variables: {
        pathId: `${variables.pathId}`,
      },
    },
    (data) => {
      const coAuthor = (result as RemovePathV4CoAuthorMutationResult["data"])
        ?.delete_path_author;
      if (coAuthor) {
        const authorIndex = data?.path?.authors.findIndex(
          (a) => a.userId === coAuthor.returning[0].userId
        );
        if (authorIndex !== undefined) {
          data?.path?.authors.splice(authorIndex, 1);
        }
      }
      return data;
    }
  );
};
