import { useState, useCallback, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  Button,
  ButtonSolid,
  IconPlus,
  Tabs,
  TabList,
  TabLink,
  HStack,
  useToast,
  useDisclosure,
  useRouteTabs,
  Center,
} from "Shared";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";

import { PageContent } from "Components/PageContent";
import { PageHeader } from "Components/PageHeader";
import { PublishedPathsTable } from "./PublishedPathsTable";
import { DraftPathsTable } from "./DraftPathsTable";
import { UNTITLTED_PATH_NAME } from "Constants/paths";
import { ArchivedPathsTable } from "./ArchivedPathsTable";
import { InReviewPathsTable } from "./InReviewPathsTable";

import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";
import { CreatorLevel, isTeacherUser } from "Types/User";
import { LayoutCentered } from "Components/LayoutCentered";
import {
  CreatorPathsQuery,
  CreatorPathsQueryVariables,
  Order_By,
  CreatePathMutation,
  CreatePathMutationVariables,
} from "@tract/common/dist/graphql";
import { AddPathTitleModal } from "Pages/CreateV4/AddPathTitleModal";
import { useOffsetPaginationQuery } from "Utils";
import { CREATE_PATH_MUTATION, CREATOR_PATHS_QUERY } from "./graphql";
import { useMutation } from "urql";

const PATHS_PER_PAGE = 20;

export function Author() {
  const { currentUser } = useSession();
  const history = useHistory();
  const toast = useToast();
  const { track } = useAnalytics();
  const [creatingPath, setCreatingPath] = useState(false);
  const { pathname } = useLocation();

  const {
    isOpen: pathTitleModalV4IsOpen,
    onOpen: pathTitleModalV4OnOpen,
    onClose: pathTitleModalV4OnClose,
  } = useDisclosure();

  const { tabsProps } = useRouteTabs({
    routes: [
      { path: `/author/drafts` },
      { path: `/author/in-review` },
      { path: `/author/published` },
      { path: `/author/archived` },
    ],
  });

  const getPathStatusByIndex = useCallback(() => {
    switch (tabsProps.index) {
      case 0:
        return PathStatus.Draft;
      case 1:
        return PathStatus.InReview;
      case 2:
        return PathStatus.Published;
      case 3:
        return PathStatus.Archived;
      default:
        return PathStatus.Unknown;
    }
  }, [tabsProps.index]);

  const [pathStatus, setPathStatus] = useState<PathStatus>(
    getPathStatusByIndex()
  );

  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<CreatorPathsQuery, CreatorPathsQueryVariables>({
    query: CREATOR_PATHS_QUERY,
    variables: {
      offset: 0,
      authorId: currentUser.uid,
      status: pathStatus,
      limit: PATHS_PER_PAGE,
      orderBy:
        pathStatus === PathStatus.Published
          ? { publishedAt: Order_By.Desc }
          : { createdAt: Order_By.Desc },
    },
    field: "paths",
  });
  const paths = data?.paths;

  useEffect(() => {
    setPathStatus(getPathStatusByIndex());
  }, [getPathStatusByIndex, setPathStatus]);

  const [, createPathMutation] = useMutation<
    CreatePathMutation,
    CreatePathMutationVariables
  >(CREATE_PATH_MUTATION);

  async function handleCreatePathV4(title?: string) {
    setCreatingPath(true);
    const { uid, orgId, creatorLevel } = currentUser;
    const isPrivatePath =
      creatorLevel === CreatorLevel.Base ||
      creatorLevel === CreatorLevel.Creator;

    try {
      const pathTitle = !!title?.length ? title : UNTITLTED_PATH_NAME;
      const pathMutationResult = await createPathMutation({
        path: {
          _schemaVersion: 3,
          authorId: uid,
          title: pathTitle,
          description: "",
          status: PathStatus.Draft,
          orgId,
          visibility: isPrivatePath
            ? PathVisibility.OrgOnly
            : PathVisibility.Public,
          isVisibleUnauthed:
            creatorLevel === CreatorLevel.Partner || isTeacherUser(currentUser),
        },
      });

      const pathId = pathMutationResult.data?.insert_path_one?.id;
      if (!pathId) {
        throw new Error("Invalid response from create path mutation");
      }

      track(ANALYTICS_EVENTS.PATH_CREATED, {
        pathId: pathId,
        pathAuthorId: currentUser.id,
        pathAuthorUsername: currentUser.username,
        pathAuthorCreatorLevel: currentUser.creatorLevel,
        pathTitle,
      });

      history.push({
        pathname: `/create/path/${pathId}`,
        state: {
          sourcePath: pathname,
          sourceLabel: "Back to Paths",
        },
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong :(",
        status: "error",
      });
    } finally {
      setCreatingPath(false);
    }
  }

  if (typeof tabsProps.index !== "number") {
    return <Redirect to="/" />;
  }

  return (
    <PageContent pb={32}>
      <Tabs {...tabsProps} isManual>
        <PageHeader
          title="My Paths"
          renderActions={
            <HStack>
              <ButtonSolid
                size="lg"
                onClick={pathTitleModalV4OnOpen}
                leftIcon={<IconPlus />}
                isLoading={creatingPath}
              >
                Create Path
              </ButtonSolid>
            </HStack>
          }
          renderTabs={
            <TabList>
              <TabLink to="/author/drafts">Drafts</TabLink>
              <TabLink to="/author/in-review">In Review</TabLink>
              <TabLink to="/author/published">Published</TabLink>
              <TabLink to="/author/archived">Archived</TabLink>
            </TabList>
          }
        />
        {!pagination.loadingMore && fetching ? (
          <LayoutCentered isLoading height="auto" py={10} />
        ) : (
          <Switch>
            <Redirect path="/author" exact to="/author/published" />
            <Route path="/author/published">
              <PublishedPathsTable paths={paths} key="published" />
            </Route>
            <Route path="/author/in-review">
              <InReviewPathsTable paths={paths} key="in-review" />
            </Route>
            <Route path="/author/drafts">
              <DraftPathsTable paths={paths} key="drafts" />
            </Route>
            <Route path="/author/archived">
              <ArchivedPathsTable paths={paths} key="archived" />
            </Route>
          </Switch>
        )}
      </Tabs>
      {pagination.hasNextPage && !!paths?.length && (
        <Center w="full">
          <Button
            variant="outline"
            size="lg"
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </Button>
        </Center>
      )}
      <AddPathTitleModal
        isOpen={pathTitleModalV4IsOpen}
        onClose={pathTitleModalV4OnClose}
        onCreatePath={handleCreatePathV4}
      />
    </PageContent>
  );
}
