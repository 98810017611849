import { Dot } from "Components/Notifications";
import { Box, VStack, Flex } from "Shared";

type Props = {
  isRead: boolean;
};

export const NotificationSkeleton: React.FC<Props> = ({ isRead }) => {
  return (
    <Box p={2} height="5rem" width="full" bg={isRead ? undefined : "gray.50"}>
      <Flex direction="row" alignItems="center">
        <Box bg="gray.100" width={16} height={16} borderRadius="full" mr={4} />
        <VStack spacing={1} width="full" flex={1}>
          <Box bg="gray.100" height={3} width="full" borderRadius="md" />
          <Box bg="gray.100" height={3} width="full" borderRadius="md" />
        </VStack>
        {!isRead && (
          <Flex p={2} align="center" justify="center">
            <Flex height="3rem" width="3rem" align="center" justify="center">
              <Dot />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
