import React, { FC } from "react";

import {
  AspectRatio,
  Badge,
  HStack,
  Image,
  Link,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  ButtonSolid,
  Center,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { ADMIN_SHOP_ITEMS } from "./graphql";

import {
  AdminShopItemFragment,
  AdminShopItemsQuery,
  AdminShopItemsQueryVariables,
} from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";
import { useOffsetPaginationQuery } from "Utils";

type Props = {};

export const PrizeTable: FC<Props> = () => {
  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<
    AdminShopItemsQuery,
    AdminShopItemsQueryVariables
  >({
    query: ADMIN_SHOP_ITEMS,
    variables: {
      limit: 25,
      offset: 0,
    },
    field: "items",
  });

  const items = data?.items || [];

  const getImgSrc = (item: AdminShopItemFragment) => {
    const coverFile = new UserFile(item.coverFile);

    if (item.coverFile) {
      if (item.coverFile.transforms) {
        return coverFile.getTransformedUrl("thumb");
      } else {
        return coverFile.getOriginalUrl();
      }
    }

    if (item.media) {
      return item.media.cover.files[0];
    }
  };

  if (fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }

  return (
    <>
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Qty</Th>
            <Th>Categories</Th>
            <Th>Status</Th>
            <Th>Updated</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => {
            return (
              <Tr key={i}>
                <Td py={4} pr={10}>
                  <HStack spacing={4}>
                    <Link to={`/admin/shop/item/${item.id}/edit`}>
                      <AspectRatio ratio={1} width="75px">
                        <Image
                          src={getImgSrc(item)}
                          objectFit="cover"
                          width="75px"
                          borderRadius={8}
                        />
                      </AspectRatio>
                    </Link>
                    <Text
                      as={Link}
                      to={`/admin/shop/item/${item.id}/edit`}
                      fontWeight="bold"
                    >
                      {item.name}
                    </Text>
                  </HStack>
                </Td>
                <Td py={4}>
                  {typeof item.quantity === "number" ? item.quantity : "-"}
                </Td>
                <Td py={4}>
                  {item.tags.map((tag: string) => (
                    <Tag size="md">{tag}</Tag>
                  ))}
                </Td>
                <Td py={4}>
                  {item.disabled ? (
                    <Badge colorScheme="red">DISABLED</Badge>
                  ) : (
                    <Badge colorScheme="green">ACTIVE</Badge>
                  )}
                </Td>
                <Td py={4}>{new Date(item.createdAt).toLocaleString()}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {pagination.hasNextPage && !!items.length && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </>
  );
};
