export const Modal = {
  baseStyle: {
    dialog: {
      borderRadius: "2xl",
    },
    header: {
      fontSize: { base: "xl", md: "2xl" },
      textAlign: { base: "left", md: "center" },
    },
  },
};
