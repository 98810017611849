import { UpdateResolver } from "@urql/exchange-graphcache";

import { Mutation_RootUpdate_Path_Collection_By_PkArgs } from "@tract/common/dist/graphql";

export const update_path_collection_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const typedArgs = args as Mutation_RootUpdate_Path_Collection_By_PkArgs;
  const cacheKey = cache.keyOfEntity({
    __typename: "path_collection",
    id: typedArgs.pk_columns.id,
  });
  cache.invalidate(cacheKey);
};
