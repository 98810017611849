/**
 * A tooltip wrapper for icons because Chakra tooltips don't seem to
 * play nice with Chakra icons.
 */
import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { TooltipProps } from "@chakra-ui/react";

import { Tooltip } from "Shared";

interface Props {
  children: ReactElement;
}

const Wrap = styled.div`
  cursor: pointer;
  position: relative;
  top: 6px;
`;

export const TooltipIcon: React.FC<Props & TooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip {...props}>
      <Wrap>{children}</Wrap>
    </Tooltip>
  );
};
