import { colors } from "./colors";

export const focusOutline = {
  normal: {
    outlineWidth: "0.125rem",
    outlineStyle: "solid",
    outlineColor: colors.brand,
    outlineOffset: "0.125rem",
  },
};
