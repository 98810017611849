import { AspectRatio, Box, Flex, Image, Text, VStack } from "Shared";

import empty from "./icon_empty_project.svg";

export const ProjectCardPlaceholder = () => (
  <Box>
    <AspectRatio ratio={16 / 9} bg="gray.100" borderRadius="xl">
      <Flex align="center" justify="center">
        <VStack spacing={1}>
          <Image src={empty} />
          <Text fontSize="xs" fontWeight="bold" color="gray.400">
            Your Project Here
          </Text>
        </VStack>
      </Flex>
    </AspectRatio>
  </Box>
);
