import React, { PropsWithChildren } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "urql";
import { useDocument } from "Utils/hooks/firestore";
import firebase from "firebase/compat";

import { createContext } from "Utils";

import { IdentifyUser } from "Services/analytics";
import { captureMessage } from "Services/errors";

import { LayoutCentered } from "Components/LayoutCentered";
import { Disabled } from "Routes/Disabled";

import { EmptyState, Logo, Stack, useTheme } from "Shared";

import { Auth } from "Types/Auth";
import {
  AnyUser,
  isParentUser,
  isKidUser,
  isAuthorUser,
  isTeacherUser,
} from "Types/User";
import {
  CurrentUserFragment,
  CurrentUserLearnerGroupFragment,
  SessionUserByIdQueryResult,
  SessionUserByIdQueryVariables,
  useCoinBalanceByUserQuery,
} from "@tract/common/dist/graphql";
import {
  SESSION_UPDATE_USER_TEACHER_MUTATION,
  SESSION_USER_BY_ID_QUERY,
  SESSION_USER_TEACHER_INSERT_MUTATION,
  SESSION_USER_UPDATE_MUTATION,
} from "graphql/users";

type TProfileContext = {
  auth: Auth;
  firebaseUser: firebase.User;
  currentUser: CurrentUserFragment;
  currentUserRoles: UserRole[];
  currentKidIds: string[];
  defaultLearnerGroup?: CurrentUserLearnerGroupFragment;
  isAdmin: boolean;
  parentUserId?: string;
  update: (data: Partial<AnyUser>) => Promise<void> | null;
  updating: boolean;
  isApprovedOrg: boolean;
  profileSlug?: string;
  onboarded: boolean;
  refreshSession: () => void;
};

export const [_, useStrictSessionContext, SessionContext] =
  createContext<TProfileContext>({
    strict: true,
    errorMessage: `useSession/useSessionContext must be inside ProfileProvider`,
  });

type SessionProviderProps = {
  auth: Auth;
  firebaseUser: firebase.User;
};

export enum UserRole {
  Learner = "Learner",
  Parent = "Parent",
  Teacher = "Teacher",
  Admin = "Admin",
  Creator = "Creator",
}

export interface CurrentUser extends CurrentUserFragment {}

export const SessionProvider: React.FC<
  PropsWithChildren<SessionProviderProps>
> = ({ auth, firebaseUser, children }) => {
  const { colors } = useTheme();

  const [UpdateSessionUserResult, UpdateSessionUser] = useMutation(
    SESSION_USER_UPDATE_MUTATION
  );

  const [UpdateSessionUserTeacherResult, UpdateSessionUserTeacher] =
    useMutation(SESSION_UPDATE_USER_TEACHER_MUTATION);

  const [InsertSessionUserTeacherResult, InsertSessionUserTeacher] =
    useMutation(SESSION_USER_TEACHER_INSERT_MUTATION);

  const update = async (data: Partial<AnyUser>) => {
    const variables = {
      id: userData?.id,
      avatar: data.avatar || userData?.avatar,
      bio: data.bio || userData?.bio,
      interestAreas: data.interestAreas || userData?.interestAreas,
      country: data.country || userData?.country,
      accomplishments: data.accomplishments || userData?.accomplishments,
    };

    if (userData?.isEducator) {
      const userTeacherVariables = {
        displayName: data.displayName,
        lowerGradeLevel: data.lowerGradeLevel,
        upperGradeLevel: data.upperGradeLevel,
        subjects: data.subjects,
      };
      if (!!userData?.teacher?.id) {
        await UpdateSessionUserTeacher({
          userId: userData.teacher.id,
          ...userTeacherVariables,
        }).then((result) => {
          if (result.error) {
            throw result.error;
          }
        });
      } else {
        await InsertSessionUserTeacher({
          object: {
            userId: userData.uid,
            ...userTeacherVariables,
          },
        }).then((result) => {
          if (result.error) {
            throw result.error;
          }
        });
      }
    }

    await UpdateSessionUser(variables).then((result) => {
      if (result.error) {
        throw result.error;
      }
    });
  };

  const updating =
    UpdateSessionUserResult?.fetching ||
    UpdateSessionUserTeacherResult?.fetching ||
    InsertSessionUserTeacherResult?.fetching;

  useCoinBalanceByUserQuery({
    variables: {
      userId: firebaseUser.uid,
    },
  });

  const [
    { data: usersQueryResult, fetching: loading, error },
    refetchSessionUser,
  ] = useQuery<
    SessionUserByIdQueryResult["data"],
    SessionUserByIdQueryVariables
  >({
    query: SESSION_USER_BY_ID_QUERY,
    variables: { userId: firebaseUser.uid },
  });

  const {
    data: sessionData,
    loading: sessionLoading,
    error: sessionError,
  } = useDocument<{
    disabled: boolean;
  }>(`sessions/${firebaseUser.uid}`, {
    listen: true,
  });

  const userData = usersQueryResult?.users?.[0];
  const defaultLearnerGroup = userData?.learnerGroups?.[0];
  const currentUserRoles = [];
  const groupOwnerUserId = defaultLearnerGroup?.learnerGroup.members.find(
    ({ isOwner }) => isOwner
  )?.userId;
  const profileSlug = !!userData?.username
    ? `@${userData.username}`
    : userData?.id;

  let hasErrors = !!error || !!sessionError;

  if (!hasErrors && (loading || sessionLoading)) {
    return <LayoutCentered isLoading />;
  }

  if (sessionData?.disabled === true) {
    captureMessage("User is disabled");
    return <Disabled />;
  }

  const currentKidIds =
    defaultLearnerGroup?.learnerGroup.members
      .filter(({ role }) => role === "learner")
      .map(({ userId }) => userId) || [];

  if (hasErrors || !userData) {
    return (
      // TODO: Needs designed Tract layout
      <LayoutCentered>
        <Stack direction="column" align="center">
          <Logo color={colors.brand} />
          <EmptyState
            headline="Sorry, something went wrong."
            body="Try refreshing this page."
          />
        </Stack>
      </LayoutCentered>
    );
  }

  let currentUser: CurrentUserFragment = {
    ...userData,
    avatar: userData.avatar || "",
    email: userData.email || firebaseUser.email || "",
  };

  if (auth.isAdmin) {
    currentUserRoles.push(UserRole.Admin);
  }

  let onboarded = true;

  if (isParentUser(userData)) {
    if (userData.isEducator) {
      currentUserRoles.push(UserRole.Teacher);
    } else {
      currentUserRoles.push(UserRole.Parent);
    }
  }

  if (isKidUser(userData)) {
    currentUserRoles.push(UserRole.Learner);

    const signUpDate = new Date(firebaseUser.metadata.creationTime || 0);
    const signUpUTC = Date.UTC(
      signUpDate.getFullYear(),
      signUpDate.getMonth(),
      signUpDate.getDate()
    );

    // Any users before August 1, 2022 can skip the onboarding step
    if (signUpUTC < Date.UTC(2022, 7, 1)) {
      onboarded = true;
    } else {
      onboarded = !!(
        userData.username &&
        userData.firstName &&
        userData.lastName
      );
    }
  }

  if (isAuthorUser(userData)) {
    currentUserRoles.push(UserRole.Creator);
  }

  if (isTeacherUser(userData)) {
    currentUserRoles.push(UserRole.Teacher);
    onboarded = !!userData.orgId;
  }

  return (
    <SessionContext.Provider
      value={{
        auth,
        firebaseUser,
        currentUser,
        currentUserRoles,
        currentKidIds,
        isAdmin: auth.isAdmin,
        defaultLearnerGroup: defaultLearnerGroup?.learnerGroup,
        parentUserId: groupOwnerUserId,
        isApprovedOrg: !!currentUser.organization?.verified,
        updating,
        profileSlug,
        onboarded,
        update,
        refreshSession: () => {
          refetchSessionUser({ requestPolicy: "network-only" });
        },
      }}
    >
      <IdentifyUser />
      {children}
    </SessionContext.Provider>
  );
};

export function useSession(opts?: { bypassStrict?: boolean }) {
  return useStrictSessionContext({ bypassStrict: opts?.bypassStrict });
}

export function useCheckSession() {
  const context = useStrictSessionContext({ bypassStrict: true });
  return { hasSession: !!context };
}

export function useIsMyProfile() {
  const { currentUser } = useSession();
  const userHasUsername = currentUser?.username?.length;
  const meRoute = "/me";
  const usernameRoute = isTeacherUser(currentUser)
    ? `/${currentUser.id}`
    : `/@${currentUser.username}`;
  const isMeRoute = useRouteMatch(meRoute);
  const isUsernameRoute = useRouteMatch(usernameRoute);
  const isMyProfile = !!(isMeRoute || isUsernameRoute);
  const profileRoute = userHasUsername ? usernameRoute : meRoute;

  return { isMyProfile, profileRoute };
}
