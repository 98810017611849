import { ChangeEvent, useEffect, useRef } from "react";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from "formik";
import { useHistory, useLocation } from "react-router";
import * as yup from "yup";
import Autosize from "react-textarea-autosize";

import { PathStatus } from "@tract/common/dist/types/models/Path";

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
  Alert,
  AlertIcon,
  Select,
  Switch,
} from "Shared";
import {
  linkState,
  PathResponseRewards,
  usePathV4Context,
} from "./LayoutCreateV4";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { CoinIcon } from "Components/CoinIcon";
import { useDebouncedCallback } from "use-debounce";
import { CreatorLevel } from "Types/User";
import { RouterPrompt } from "Components/RouterPrompt";

interface NodeDetailsFormProps {
  title: string;
  caption?: string;
  responsesEnabled?: boolean;
  rewardAmount?: number;
}

const pathNodeDetailsFormSchema = yup.object().shape({
  title: yup.string().required("Please provide a title"),
  caption: yup.string().optional(),
  responsesEnabled: yup.boolean().optional(),
  rewardAmount: yup.number().optional(),
});

export const PathNodeDetailsForm: React.FC = () => {
  const history = useHistory();
  const { track } = useAnalytics();
  const { currentUser, isAdmin } = useSession();
  const toast = useToast();
  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: openDeleteConfirmModal,
    onClose: closeDeleteConfirmModal,
  } = useDisclosure();
  const {
    isOpen: isRoutePromptOpen,
    onOpen: onOpenRoutePrompt,
    onClose: onCloseRoutePrompt,
  } = useDisclosure();
  const formRef = useRef<FormikProps<NodeDetailsFormProps> | null>(null);
  const {
    path,
    activeNode,
    updatePathNodeMutation,
    setDetailsSaved,
    deletePathNodeMutation,
    autosaveFailed,
    setAutosaveFailed,
    unsavedChanges,
  } = usePathV4Context();
  const { state } = useLocation<linkState>();

  const handleChangeResponsesEnabled = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;

    formRef.current?.setFieldValue("responsesEnabled", value);
    if (!!formRef.current?.values) {
      await saveDetails({
        ...formRef.current?.values,
        responsesEnabled: value,
      });
      formRef.current?.resetForm();
    }
  };

  const canEdit = isAdmin || path.status !== PathStatus.Published;

  const onDeleteNode = async () => {
    const pathNodeMutationResult = await deletePathNodeMutation({
      nodeId: activeNode?.id,
    });
    if (!!pathNodeMutationResult.error) {
      captureException(pathNodeMutationResult.error);
      setAutosaveFailed(true);
      toast({
        title: "Error deleting video",
        status: "error",
      });
    }
    track(ANALYTICS_EVENTS.NODE_DELETED, {
      pathId: path.id,
      pathAuthorId: path?.authorId,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
      pathTitle: path?.title,
      pathSlug: path?.slug,
      pathNodesCount: path?.nodes?.length,
      nodeId: activeNode?.id,
      nodeTitle: activeNode?.title,
      nodeResponsesEnabled: activeNode?.responsesEnabled,
      nodeRewardAmount: activeNode?.rewardAmount,
    });
    let nodeIndex = path.nodes.findIndex((node) => node.id === activeNode?.id);
    if (nodeIndex === path.nodes.length - 1) {
      nodeIndex--;
    } else {
      nodeIndex++;
    }
    history.replace({
      pathname: `/create/path/${path.id}/v/${path.nodes[nodeIndex].id}/details`,
      state: state,
    });
    setDetailsSaved(true);
    setAutosaveFailed(false);
    closeDeleteConfirmModal();
  };

  const onBlurNodeDetails = async (values: NodeDetailsFormProps) => {
    await debouncedSaveDetails(values);
  };

  const debouncedSaveDetails = useDebouncedCallback(
    async (values: NodeDetailsFormProps) => {
      if (!formRef.current?.dirty || isRoutePromptOpen) {
        return;
      }
      await saveDetails(values);
    },
    100
  );

  const saveDetails = async (values: NodeDetailsFormProps) => {
    const pathNodeMutationResult = await updatePathNodeMutation({
      nodeId: activeNode?.id,
      node: {
        title: values.title,
        caption: values.caption,
        responsesEnabled: values.responsesEnabled,
        rewardAmount: values.rewardAmount,
      },
    });
    if (!!pathNodeMutationResult.error) {
      setAutosaveFailed(true);
      captureException(pathNodeMutationResult.error);
      toast({
        title: "Error updating video details",
        status: "error",
      });
      return;
    }
    setDetailsSaved(true);
    setAutosaveFailed(false);
    track(ANALYTICS_EVENTS.NODE_EDITED, {
      pathId: path?.id,
      pathAuthorId: path?.authorId,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
      pathTitle: path?.title,
      pathSlug: path?.slug,
      pathNodesCount: path?.nodes?.length,
      nodeId: activeNode?.id,
      nodeTitle: activeNode?.title,
      nodeResponsesEnabled: activeNode?.responsesEnabled,
      nodeRewardAmount: activeNode?.rewardAmount,
    });
  };

  const onBlurFormFields = async (values: NodeDetailsFormProps) => {
    await onBlurNodeDetails(values);
  };

  const onChangeRewardAmount = async (
    value: number,
    values: NodeDetailsFormProps
  ) => {
    formRef.current?.setFieldValue("rewardAmount", value);
    await onBlurNodeDetails({
      ...values,
      rewardAmount: value,
    });
  };

  const onSaveBeforeRouteChange = async (values?: NodeDetailsFormProps) => {
    if (!!values) {
      await Promise.resolve(saveDetails(values));
      formRef.current?.resetForm();
    }
    return Promise.resolve(true);
  };

  const onDiscardChanges = () => {
    formRef.current?.resetForm();
    return Promise.resolve(true);
  };

  const canUseDoubleReward =
    [CreatorLevel.Affiliate, CreatorLevel.Partner].includes(
      currentUser.creatorLevel as CreatorLevel
    ) || isAdmin;
  const canUseCapstone =
    currentUser?.creatorLevel === CreatorLevel.Partner || isAdmin;

  if (!activeNode) return null;

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          title: activeNode.title || "",
          caption: activeNode.caption || "",
          responsesEnabled: activeNode.responsesEnabled || false,
          rewardAmount: activeNode.rewardAmount || 0,
        }}
        validationSchema={pathNodeDetailsFormSchema}
        onSubmit={onBlurNodeDetails}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <Form
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              minHeight: "100%",
            }}
          >
            <Box px={6} py={4}>
              {!canEdit && (
                <Alert status="warning" mb={10}>
                  <AlertIcon />
                  Videos can't be edited once your path is published
                </Alert>
              )}
              <VStack spacing={6} w="full">
                <Field name="title">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={!!meta.error && submitCount > 0}
                      isRequired
                    >
                      <FormLabel>Title</FormLabel>
                      <Input
                        {...field}
                        size="lg"
                        maxLength={1000}
                        autoComplete="off"
                        isDisabled={!canEdit}
                        onBlur={() => onBlurFormFields(values)}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="caption">
                  {({ field }: FieldProps) => (
                    <FormControl isInvalid={false}>
                      <FormLabel>Caption</FormLabel>
                      <Textarea
                        {...field}
                        as={Autosize}
                        size="lg"
                        maxRows={6}
                        minRows={2}
                        rows={2}
                        resize="none"
                        maxLength={5000}
                        autoComplete="off"
                        isDisabled={!canEdit}
                        onBlur={() => onBlurFormFields(values)}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="responsesEnabled">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={!!meta.error && submitCount > 0}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      w="full"
                    >
                      <FormLabel m={0}>Challenge Viewers to Respond</FormLabel>
                      <Switch
                        {...field}
                        size="lg"
                        colorScheme="brandFull"
                        isChecked={activeNode?.responsesEnabled}
                        onChange={handleChangeResponsesEnabled}
                        isDisabled={!canEdit}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                {activeNode?.responsesEnabled && (
                  <Field name="rewardAmount">
                    {({ field }: FieldProps) => (
                      <FormControl isInvalid={false}>
                        <FormLabel>
                          <Flex align="center">
                            <CoinIcon size={4} />
                            <Text ml={1}>Coins</Text>
                          </Flex>
                        </FormLabel>
                        <Select
                          {...field}
                          size="lg"
                          name="rewardAmount"
                          onChange={({ target: { value } }) => {
                            onChangeRewardAmount(Number(value), values);
                          }}
                          disabled={!canEdit}
                        >
                          <option value={PathResponseRewards.Standard}>
                            Standard (15 Coins)
                          </option>
                          <option
                            value={PathResponseRewards.Double}
                            disabled={!canUseDoubleReward}
                          >
                            Double (30 Coins, Affiliates+ only)
                          </option>
                          <option
                            value={PathResponseRewards.Capstone}
                            disabled={!canUseCapstone}
                          >
                            Capstone (60 Coins, Partners only)
                          </option>
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                )}
              </VStack>
            </Box>
            <Flex flex={1} />
            <Flex
              align="center"
              bg="white"
              direction="column"
              justify="space-around"
              position="sticky"
              px={6}
              py={4}
              bottom={0}
              left={0}
              right={0}
              zIndex={1}
            >
              <Center w="full">
                {autosaveFailed && (
                  <Button
                    type="submit"
                    size="lg"
                    variant="solid"
                    colorScheme="brandFull"
                    flex={2}
                    mr={2}
                  >
                    Save Changes
                  </Button>
                )}
                {(isAdmin || !path.publishedAt) && (
                  <Button
                    variant="ghost"
                    flex={2}
                    size="lg"
                    colorScheme="red"
                    onClick={openDeleteConfirmModal}
                    isDisabled={!canEdit || path?.nodes?.length <= 1}
                  >
                    Delete
                  </Button>
                )}
              </Center>
            </Flex>
            <FormObserver />
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={isDeleteConfirmModalOpen}
        onClose={closeDeleteConfirmModal}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader textAlign="left">Delete video?</ModalHeader>
          <ModalBody>
            <Text>This action cannot be undone.</Text>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={closeDeleteConfirmModal} size="lg">
                Cancel
              </Button>
              <Button onClick={onDeleteNode} colorScheme="red" size="lg">
                Yes, Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <RouterPrompt
        when={unsavedChanges}
        isOpen={isRoutePromptOpen}
        onOpen={onOpenRoutePrompt}
        onClose={onCloseRoutePrompt}
        title="Save changes?"
        bodyText="Do you want to save your changes before leaving?"
        okText="Yes, Save Changes"
        cancelText="No, Discard"
        onOK={() => onSaveBeforeRouteChange(formRef.current?.values)}
        onCancel={onDiscardChanges}
      />
    </>
  );
};

const FormObserver: React.FC = () => {
  const { dirty } = useFormikContext();
  const { setUnsavedChanges } = usePathV4Context();
  useEffect(() => {
    setUnsavedChanges(dirty);
  }, [setUnsavedChanges, dirty]);
  return null;
};
