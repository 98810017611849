import React from "react";

import {
  Box,
  ButtonOutlined,
  ButtonSolid,
  HStack,
  Textarea,
  useToast,
} from "Shared";

import {
  ProjectCommentFragment,
  ProjectCardFragment,
  UpdateCommentMutation,
  UpdateCommentMutationVariables,
} from "@tract/common/dist/graphql";

import { CommentType } from "Types/Comment";
import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { Form, Formik } from "formik";
import { useMutation } from "urql";
import { PROJECT_COMMENT_UPDATE } from "./graphql";

type CommentEditorProps = {
  comment: ProjectCommentFragment;
  project: ProjectCardFragment;
  type: CommentType;
  isAuthorComment: boolean;
  onSave: () => void;
  onCancel: () => void;
};

export const CommentEditor: React.FC<CommentEditorProps> = ({
  comment,
  isAuthorComment,
  project,
  onSave,
  onCancel,
  type,
}) => {
  const toast = useToast();
  const { track } = useAnalytics();
  const { currentUser } = useSession();

  const [{ fetching: loading }, updateComment] = useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(PROJECT_COMMENT_UPDATE);

  const onSubmit = async (values: any) => {
    const newCommentText = values.commentText.trim();

    if (!newCommentText.length) {
      return;
    }

    try {
      if (newCommentText !== comment.comment.text) {
        await updateComment({
          commentId: comment.commentId,
          text: newCommentText,
        });

        onSave();

        track(ANALYTICS_EVENTS.PROJECT_COMMENT_EDITED, {
          projectId: project.id,
          projectCommentCount: project.stats?.commentCount,
          projectCommentType: type || "",
          isProjectOwner: comment.comment.userId === project.user.id,
          isCommentOwner: currentUser.uid === comment.comment.userId,
          isAuthorComment,
        });
      } else {
        onCancel();
      }
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong.",
        description: "Unable to save comment, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={{ commentText: comment.comment.text || "" }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange }) => (
        <Box as={Form} width="full" pr={4} pt={2}>
          <Textarea
            name="commentText"
            value={values.commentText}
            onChange={handleChange}
            py={3}
            borderRadius="lg"
            placeholder="Edit comment"
            height="3rem"
          />
          <HStack spacing={2} mt={3}>
            <ButtonSolid type="submit" size="md" isLoading={loading}>
              Save
            </ButtonSolid>
            <ButtonOutlined size="md" onClick={onCancel}>
              Cancel
            </ButtonOutlined>
          </HStack>
        </Box>
      )}
    </Formik>
  );
};
