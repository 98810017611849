import { gql } from "@apollo/client";
import { EducatorCode as IEducatorCode } from "@tract/common/dist/types/models/EducatorCode";
import { IsEducatorCodeActiveFragment } from "@tract/common/dist/graphql";

export interface EducatorCode
  extends Omit<IEducatorCode, "expires" | "createdAt"> {
  expires: Date;
  createdAt?: Date;
}

gql`
  fragment IsEducatorCodeActive on educator_code {
    code
    available
    expiresAt
  }
`;

export function isEducatorCodeActive(code?: IsEducatorCodeActiveFragment) {
  return (
    code &&
    code.available > 0 &&
    new Date(code.expiresAt).getTime() > Date.now()
  );
}
