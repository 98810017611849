import React from "react";
import {
  FormControlProps,
  FormControl as ChakraFormControl,
  FormErrorMessage as ChakraFormErrorMessage,
  FormHelperText as ChakraFormHelperText,
  FormLabel as ChakraFormLabel,
  forwardRef,
} from "@chakra-ui/react";

export const FormControl = forwardRef<FormControlProps, "div">((props, ref) => (
  <ChakraFormControl ref={ref} fontFamily="body" fontWeight="bold" {...props} />
));

export const FormErrorMessage: React.FC<TODO> = (props) => (
  <ChakraFormErrorMessage
    fontFamily="body"
    fontWeight="bold"
    fontSize="md"
    textAlign="start"
    {...props}
  />
);

export const FormHelperText: React.FC<TODO> = (props) => (
  <ChakraFormHelperText
    fontFamily="body"
    fontWeight="medium"
    lineHeight="1.5"
    fontSize="md"
    color="gray.600"
    textAlign="start"
    {...props}
  />
);

export const FormLabel: React.FC<TODO> = (props) => (
  <ChakraFormLabel
    fontFamily="body"
    fontWeight="bold"
    color="gray.900"
    textAlign="start"
    {...props}
  />
);
