import { mode, cssVar, SystemStyleFunction } from "@chakra-ui/theme-tools";

const $bg = cssVar("tooltip-bg");
const $arrowBg = cssVar("popper-arrow-bg");

const baseStyle: SystemStyleFunction = (props) => {
  const bg = mode("gray.900", "gray.300")(props);

  return {
    py: 1,
    px: 2,
    borderRadius: "md",
    textAlign: "center",
    fontWeight: "bold",
    [$bg.variable]: `colors.${bg}`,
    bg,
    [$arrowBg.variable]: [$bg.reference],
  };
};

export const Tooltip = {
  baseStyle,
  variants: {
    form: {
      px: 5,
      py: 4,
      textAlign: "left",
      borderRadius: "xl",
      fontWeight: "normal",
      maxWidth: "400px",
    },
  },
};
