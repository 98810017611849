import React, { useEffect, useState } from "react";

import { EmptyState, Grid } from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { PrizeCard } from "Components/PrizeCard";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";

import { ShopItemFragment } from "@tract/common/dist/graphql";

type Props = {
  items: ShopItemFragment[];
  loading: boolean;
};

export const PrizeList: React.FC<Props> = ({ items, loading = false }) => {
  const { track } = useAnalytics();

  const [viewed, setViewed] = useState(false);
  useEffect(() => {
    if (viewed) {
      return;
    }

    track(ANALYTICS_EVENTS.PRIZE_BOARD_VIEWED);
    setViewed(true);
  }, [track, viewed]);

  if (loading) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!items.length) {
    return (
      <EmptyState
        headline="No prizes yet"
        body="No prizes have been published, check back later."
      />
    );
  }

  return (
    <Grid
      columnGap={{ base: 4, md: 6, xl: 8 }}
      rowGap={{ base: 6, xl: 10 }}
      mt={10}
      templateColumns={{
        base: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
      }}
    >
      {items?.map((item) => (
        <PrizeCard item={item} key={item.id} />
      ))}
    </Grid>
  );
};
