import { useState } from "react";

import {
  Box,
  Button,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
  Td,
  Flex,
  Avatar,
  Badge,
  Link,
  useDisclosure,
  Center,
  VisuallyHidden,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { useSession } from "Services/session";

import {
  ClassroomLearnerGroupFragment,
  ClassroomMemberTeachersQueryResult,
  ClassroomMemberTeachersQueryVariables,
  useGetUserCoinBalancesQuery,
} from "@tract/common/dist/graphql";

import { useQuery } from "urql";
import { CLASSROOM_MEMBER_TEACHERS_QUERY } from "./graphql";
import { CoTeachersModal } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/CoTeachersModal";
import { PendingApprovaModal } from "Components/PendingApprovalModal";
import { useClassContext } from "Components/LayoutClass";
import { getDisplayName } from "Types/User";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const ClassroomTeachers: React.FC<Props> = ({ classroom }) => {
  const { currentUser, isApprovedOrg, isAdmin } = useSession();
  const { isClassPrimaryOwner, isClassOwner } = useClassContext();

  const [balancesByUserId, setBalancesByUserId] = useState<Map<string, number>>(
    new Map()
  );

  const {
    isOpen: isCoTeachersModalOpen,
    onClose: onCloseCoTeachersModal,
    onOpen: onOpenCoTeachersModal,
  } = useDisclosure();

  const {
    isOpen: isPendingApprovalModalOpen,
    onOpen: openPendingApprovalModal,
    onClose: closePendingApprovalModal,
  } = useDisclosure();

  const teacherIds =
    classroom.members
      .filter(({ role }) => role === "teacher")
      .map(({ userId }) => userId) || [];

  const [{ data, fetching: loading }] = useQuery<
    ClassroomMemberTeachersQueryResult["data"],
    ClassroomMemberTeachersQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: CLASSROOM_MEMBER_TEACHERS_QUERY,
    variables: {
      classId: classroom.id,
      teacherIds,
    },
  });

  const teachers = data?.learner_group_member || [];

  useGetUserCoinBalancesQuery({
    variables: {
      userIds: teacherIds,
    },
    onCompleted: (data) => {
      const balanceMap = new Map();
      data.balances.forEach((balance) => {
        balanceMap.set(balance.userId, balance.balance);
      });
      setBalancesByUserId(balanceMap);
    },
  });

  if (loading && !teachers.length) {
    return <LayoutCentered isLoading height="auto" />;
  }

  return (
    <>
      <Box overflowX={{ base: "auto", lg: "visible" }}>
        <Table width="100%">
          <Thead hidden={!isClassOwner && !isAdmin}>
            <Tr>
              <Th>
                <VisuallyHidden>Name</VisuallyHidden>
              </Th>
              <Th textAlign="end">Coins</Th>
            </Tr>
          </Thead>
          <Tbody>
            {teachers?.map((teacher) => {
              const user = teacher.user;
              const displayName = getDisplayName(user);
              return (
                <Tr key={user.id}>
                  <Td py={3}>
                    <Flex align="center">
                      {user.firstName && (
                        <>
                          <Avatar
                            tabIndex={-1}
                            src={user.avatar || ""}
                            name={displayName || ""}
                            size="lg"
                            mr={3}
                          />
                          <Box>
                            <Box as="p">
                              <Text
                                as={Link}
                                to={`/${user.id}`}
                                fontSize="lg"
                                color="gray.900"
                                fontWeight="bold"
                              >
                                {displayName}
                              </Text>
                            </Box>
                            {teacher.isOwner && (
                              <Badge variant="outline" mt={1}>
                                Primary
                              </Badge>
                            )}
                            <Text as="p" mb={0} color="gray.600" mt={1}>
                              {user.email}
                            </Text>
                          </Box>
                        </>
                      )}
                    </Flex>
                  </Td>
                  {(isClassOwner || isAdmin) && (
                    <Td textAlign="end">
                      {(balancesByUserId.get(user.id) || 0).toLocaleString()}
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {isClassPrimaryOwner && (
          <Center>
            <Button
              variant="outline"
              size="lg"
              mt={6}
              onClick={
                isApprovedOrg ? onOpenCoTeachersModal : openPendingApprovalModal
              }
            >
              Manage Teachers
            </Button>
          </Center>
        )}
      </Box>
      <CoTeachersModal
        user={currentUser}
        learnerGroup={classroom}
        isOpen={isCoTeachersModalOpen}
        onClose={onCloseCoTeachersModal}
      />
      {!isApprovedOrg && (
        <PendingApprovaModal
          isOpen={isPendingApprovalModalOpen}
          onClose={closePendingApprovalModal}
        />
      )}
    </>
  );
};
