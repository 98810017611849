import { gql } from "urql";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

export const PROJECT_STATS_FRAGMENT = gql`
  fragment projectStats on project_stats {
    projectId
    commentCount
    likeCount
    viewCount
  }
`;

export const PROJECT_CARD_FRAGMENT = gql`
  ${PROJECT_STATS_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment projectCard on project {
    id
    text
    rewardCoinTrxId
    isPrivate
    published
    userId
    rejectedAt
    rejectedReason
    rejectedFeedback
    rejectedByUserId
    awards(order_by: { count: desc }) {
      projectId
      awardId
      count
    }
    node {
      id
      title
      path {
        id
        authorId
      }
    }
    stats {
      ...projectStats
    }
    user {
      id: firestoreId
      avatar
      username
      isEducator
      firstName
      lastName
      isMod
      creatorLevel
      country
      ...UserPrivacyScopes
    }
    file {
      id
      filename
      transforms {
        filename
      }
    }
  }
`;
