import React, { FC } from "react";

import { ExternalLink, Accordion, AccordionItem } from "Shared";

export const FaqPlatform: FC = () => {
  return (
    <Accordion allowToggle={true} defaultIndex={0}>
      <AccordionItem heading="What is Tract?">
        Tract isn't your everyday EdTech platform—and we don't want to be. As
        the first online community where kids teach kids through multimedia,
        we're the place where teachers go to help students become epic content
        creators and develop 21st century skills.
      </AccordionItem>
      <AccordionItem heading="Who uses Tract?">
        Tract is designed for busy teachers and their 3 - 8 grade students to
        use both{" "}
        <ExternalLink
          href="https://teach.tract.app/"
          target="_blank"
          fontWeight="bold"
          color="brand"
        >
          in school
        </ExternalLink>{" "}
        and at home.
      </AccordionItem>
      <AccordionItem heading="Where is Tract available?">
        Tract is available to kids around the globe. At this time, we support
        only English, but are hard at work internationalizing our product for
        multi-language support, so stay tuned!
      </AccordionItem>
      <AccordionItem heading="How can I contact Tract?">
        We love hearing from kids and adults around the world! If you have
        additional questions or feedback, you can reach us any time at{" "}
        <ExternalLink
          color="brand"
          fontWeight="bold"
          href="mailto:hello@tract.app"
          target="_blank"
        >
          hello@tract.app
        </ExternalLink>
      </AccordionItem>
      <AccordionItem heading="What are Coins?">
        Coins are a virtual currency awarded to learners on Tract. They can be
        used to give awards to other learners, or donated to real-world causes
        from our in-app shop — like planting a tree, protecting the coastline,
        and donating a meal.
      </AccordionItem>
    </Accordion>
  );
};
