import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminDashboardClassroomsByTeacherIdQueryResult,
  AdminDashboardClassroomsByTeacherIdQueryVariables,
  AdminDashboardCreateClassroomMutationResult,
  AdminDashboardCreateClassroomMutationVariables,
  AdminDashboardLearnerGroupFragment,
  ClassroomLearnerGroupFragment,
  ClassroomsByTeacherIdQuery,
  ClassroomsByTeacherIdQueryVariables,
  CreateClassroomMutationVariables,
  CurrentUserFragment,
} from "@tract/common/dist/graphql";

import { CURRENT_USER_FRAGMENT } from "graphql/users";

import { ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/graphql";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";

export const insert_learner_group_one: UpdateResolver<
  { createClassroom: ClassroomLearnerGroupFragment } & {
    adminCreateClassroom: AdminDashboardLearnerGroupFragment;
  }
> = (result, args, cache, info) => {
  if (result.createClassroom) {
    const classId = result.createClassroom.id;
    const variables = info.variables as CreateClassroomMutationVariables;

    const user = cache.readFragment<CurrentUserFragment>(
      CURRENT_USER_FRAGMENT,
      { __typename: "user", id: variables.userId }
    );

    if (user) {
      const member: CurrentUserFragment["learnerGroups"] = [
        {
          __typename: "learner_group_member",
          isOwner: true,
          userId: user.id,
          learnerGroupId: classId,
          role: "Teacher",
          learnerGroup: result.createClassroom,
        },
      ];

      // Add new classroom to current user data
      cache.writeFragment<CurrentUserFragment>(CURRENT_USER_FRAGMENT, {
        ...user,
        learnerGroups: user.learnerGroups
          ? [...member, ...user.learnerGroups]
          : [...member],
      });
    }

    cache.updateQuery<
      ClassroomsByTeacherIdQuery,
      ClassroomsByTeacherIdQueryVariables
    >(
      {
        query: CLASSROOMS_BY_TEACHER_ID_QUERY,
        variables: {
          teacherUserId: variables.userId,
        },
      },
      (data) => {
        const item = result?.createClassroom;

        if (item) {
          data?.learnerGroups.unshift({
            ...item,
          });
        }

        return data;
      }
    );
  }

  if (result.adminCreateClassroom) {
    const variables =
      info.variables as AdminDashboardCreateClassroomMutationVariables;

    cache.updateQuery<
      AdminDashboardClassroomsByTeacherIdQueryResult["data"],
      AdminDashboardClassroomsByTeacherIdQueryVariables
    >(
      {
        query: ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY,
        variables: {
          teacherUserId: variables.userId,
        },
      },
      (data) => {
        const item = (
          result as AdminDashboardCreateClassroomMutationResult["data"]
        )?.adminCreateClassroom;

        if (item) {
          data?.learnerGroups.unshift({
            ...item,
          });
        }

        return data;
      }
    );
  }
};
