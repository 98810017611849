import { BoxProps, FlexProps, TextProps } from "@chakra-ui/layout";
import format from "date-fns/format";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import startOfDay from "date-fns/startOfDay";
import { groupBy } from "lodash";
import { Fragment, useMemo, PropsWithChildren } from "react";
import { useParams } from "react-router";
import { LayoutCentered } from "Components/LayoutCentered";
import { ProfileBadges } from "Components/ProfileBadges";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { useSession } from "Services/session";
import {
  Box,
  EmptyState,
  Text,
  Flex,
  HStack,
  Badge,
  Divider,
  VStack,
  IconTrash,
} from "Shared";
import { PathActivityType } from "./PathActions";
import { isParentUser, isTeacherUser } from "Types/User";
import { useQuery } from "urql";
import { GET_PATH_ACTIVITY_FOR_PATH } from "./graphql";
import {
  GetPathActivityForPathQuery,
  GetPathActivityForPathQueryVariables,
} from "@tract/common/dist/graphql";

type PathActivityItem = GetPathActivityForPathQuery["pathActivity"][0];

interface PathActivityProps {
  showTitle?: boolean;
  pathIdFromParent?: string;
}

const formatToDay = (dt: string) => {
  const date = new Date(dt);
  if (isToday(date)) return "today";
  if (isYesterday(date)) return "yesterday";
  return format(date, "MMM dd");
};

export const PathActivity: React.FC<BoxProps & PathActivityProps> = ({
  showTitle = true,
  pathIdFromParent,
  ...props
}) => {
  const { pathId } = useParams<{ pathId: string }>();

  const [{ data, fetching: loading }] = useQuery<
    GetPathActivityForPathQuery,
    GetPathActivityForPathQueryVariables
  >({
    query: GET_PATH_ACTIVITY_FOR_PATH,
    variables: {
      pathId: pathIdFromParent || pathId,
    },
    requestPolicy: "network-only",
  });

  const activityFeed = data?.pathActivity;
  const activityFeedGrouped = useMemo(() => {
    // 1. Group activities by day
    // 2. Convert result into entries
    // 3. Fix the ordering
    // 4. Format group key (ISO date in this case) to "Today" "Yesterday" etc.
    return Object.entries(
      groupBy(activityFeed, (activity) =>
        startOfDay(new Date(activity.createdAt)).toISOString()
      )
    )
      .sort((entry1, entry2) =>
        entry1[0] > entry2[0] ? -1 : entry1[0] < entry2[0] ? 1 : 0
      )
      .map((entry) => ({
        day: formatToDay(entry[0]),
        feed: entry[1],
      }));
  }, [activityFeed]);

  return (
    <Box width="520px" mt={4} mx={6} {...props}>
      {showTitle && (
        <Text fontSize="4xl" fontWeight="bold">
          Activity
        </Text>
      )}
      {loading ? (
        <LayoutCentered height="auto" isLoading />
      ) : data?.pathActivity.length ? (
        <VStack mt={6} mb={10}>
          {activityFeedGrouped.map((group) => (
            <Fragment key={group.day}>
              <ActivityGroupHeader>{group.day}</ActivityGroupHeader>
              <VStack spacing={6} pt={4} pb={10} w="full">
                {group.feed.map((activity) => (
                  <ActivityItem key={activity.id} item={activity} />
                ))}
              </VStack>
            </Fragment>
          ))}
        </VStack>
      ) : (
        <EmptyState headline="No Activity Yet" />
      )}
    </Box>
  );
};

const ActivityGroupHeader: React.FC<PropsWithChildren> = ({ children }) => (
  <HStack spacing={4} w="full">
    <Divider flex={1} />
    <Badge>{children}</Badge>
    <Divider flex={1} />
  </HStack>
);

const ActivityItem: React.FC<{ item: PathActivityItem }> = ({ item }) => {
  const { currentUser } = useSession();
  const isMyPath = currentUser.uid === item.path.user?.firestoreId;

  switch (item.type) {
    case PathActivityType.ReviewDeny:
      return (
        <Box width="full">
          <HStack spacing={3}>
            <UserAvatar
              username={item.user.username || ""}
              userId={item.user.id || ""}
              isTeacher={isTeacherUser(item.user)}
              isParent={isParentUser(item.user)}
              avatarURL={item.user.avatar || ""}
              boxSize={10}
              name={item.user.username || ""}
            />
            <ActivityItemDescription
              item={item}
              description={`requested changes on ${
                isMyPath ? "your" : "this"
              } path`}
            />
          </HStack>
          {item.pathReview?.comment?.text && (
            <ActivityItemComment item={item} />
          )}
        </Box>
      );
    case PathActivityType.ReviewApprove:
    case PathActivityType.SetPublished:
      return (
        <Box width="full">
          <HStack spacing={3}>
            <UserAvatar
              username={item.user.username || ""}
              userId={item.user.id || ""}
              isTeacher={isTeacherUser(item.user)}
              isParent={isParentUser(item.user)}
              avatarURL={item.user.avatar || ""}
              boxSize={10}
              name={item.user.username || ""}
            />
            <ActivityItemDescription
              item={item}
              description={`approved${item.user.isMod ? " and published " : ""}
              ${isMyPath ? "your" : "this"} path`}
            />
          </HStack>
          {item.pathReview?.comment?.text && (
            <ActivityItemComment item={item} />
          )}
        </Box>
      );

    case PathActivityType.SetInReview:
      return (
        <Box width="full">
          <HStack spacing={3}>
            <UserAvatar
              username={currentUser.username || ""}
              userId={currentUser.uid || ""}
              isTeacher={isTeacherUser(currentUser)}
              isParent={isParentUser(currentUser)}
              avatarURL={currentUser.avatar || ""}
              boxSize={10}
              name={currentUser.username || ""}
            />
            <ActivityItemDescription
              item={item}
              description={`requested a review`}
            />
          </HStack>
        </Box>
      );

    case PathActivityType.SetDraft:
      return (
        <Box width="full">
          <HStack spacing={3}>
            <UserAvatar
              username={currentUser.username || ""}
              userId={currentUser.uid || ""}
              isTeacher={isTeacherUser(currentUser)}
              isParent={isParentUser(currentUser)}
              avatarURL={currentUser.avatar || ""}
              boxSize={10}
              name={currentUser.username || ""}
            />
            <ActivityItemDescription
              item={item}
              description={`cancelled the review`}
            />
          </HStack>
        </Box>
      );

    case PathActivityType.SetArchived:
      return (
        <Box width="full">
          <HStack spacing={3}>
            <ActivityItemIcon bgColor="red.100">
              <IconTrash color="red.600" />
            </ActivityItemIcon>
            <ActivityItemDescription
              item={item}
              description={`archived ${isMyPath ? "your" : "this"} path`}
            />
          </HStack>
        </Box>
      );

    default:
      return null;
  }
};

const ActivityItemComment: React.FC<{ item: PathActivityItem }> = ({
  item,
}) => {
  return (
    <Box
      mt={3}
      w="inherit"
      bg="gray.50"
      borderRadius="xl"
      overflow="hidden"
      p={3}
    >
      <Text>{item.pathReview?.comment?.text}</Text>
    </Box>
  );
};

const ActivityItemIcon: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex
    minW="40px"
    height="40px"
    borderRadius="full"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {children}
  </Flex>
);

const ActivityItemDescription: React.FC<
  TextProps & { item: PathActivityItem; description: string }
> = ({ item, description, ...props }) => {
  const { currentUser } = useSession();

  return (
    <Text {...props}>
      <Text as="span" fontWeight="bold">
        {item.user.id === currentUser.id ? (
          "You"
        ) : (
          <>
            <UsernameLink
              username={item.user.username || ""}
              userId={item.user.id || ""}
              isTeacher={isTeacherUser(item.user)}
              isParent={isParentUser(item.user)}
            >
              {isTeacherUser(item.user)
                ? `${item.user.firstName} ${item.user.lastName}`
                : item.user.username}
            </UsernameLink>{" "}
            <ProfileBadges iconSize={5} user={item.user} flagFont="md" />
          </>
        )}
      </Text>{" "}
      {description}
    </Text>
  );
};

// const ActivityCommentMenu: React.FC = () => {
//   return (
//     <Menu>
//       <MenuButton
//         as={IconButton}
//         aria-label="open comment menu"
//         icon={<IconMoreVertical width={4} height={4} />}
//         variant="ghost"
//         size="sm"
//       />
//       <MenuList width={64}>
//         <MenuItem cursor="pointer" as={Flex} align="center" px={6}>
//           Action
//         </MenuItem>
//       </MenuList>
//     </Menu>
//   );
// };
