import React, { PropsWithChildren, ReactNode } from "react";
import { Box, HStack, Grid } from "Shared";

type ExploreRowProps = {
  renderTitle: () => ReactNode;
  templateColumns?: {
    base?: string;
    sm?: string;
    md?: string;
    lg?: string;
  };
};

export const HScrollContainer: React.FC<PropsWithChildren<ExploreRowProps>> = ({
  children,
  renderTitle,
  templateColumns = {
    base: "repeat(6, 256px)",
    lg: "repeat(3, minmax(0,1fr))",
    xl: "repeat(4, minmax(0,1fr))",
  },
}) => {
  return (
    <Box mt={{ base: 4, lg: 12 }}>
      <HStack
        textDecoration="none"
        spacing={{ base: 4, lg: 6 }}
        mb={{ base: 0, lg: 4 }}
        fontSize="xl"
        fontWeight="bold"
      >
        {renderTitle()}
      </HStack>
      <Box
        pb={{ base: 6, lg: 0 }}
        mt={{ base: 3, lg: 0 }}
        mx={{ base: -4, lg: 0 }}
        overflowX={{ base: "auto", lg: "hidden" }}
      >
        <Grid
          width={{ base: "max-content", lg: "100%" }}
          px={{ base: 4, lg: 0 }}
          templateColumns={templateColumns}
          gap={{ base: 2, md: 3 }}
        >
          {children}
        </Grid>
      </Box>
    </Box>
  );
};
