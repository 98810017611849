import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminLivestreamsQueryResult,
  AdminLivestreamsQueryVariables,
} from "@tract/common/dist/graphql";

import { ADMIN_LIVESTREAMS_QUERY } from "Pages/AdminDashboard/AdminLivestreams/graphql";
import {
  ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING,
  ADMIN_LIVESTREAMS_PER_PAGE,
  ADMIN_LIVESTREAMS_START_DATE_UPCOMING,
} from "Pages/AdminDashboard/AdminLivestreams/LivestreamsTable";

export const update_livestream_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.updateQuery<
    AdminLivestreamsQueryResult["data"],
    AdminLivestreamsQueryVariables
  >(
    {
      query: ADMIN_LIVESTREAMS_QUERY,
      variables: {
        startDate: ADMIN_LIVESTREAMS_START_DATE_UPCOMING,
        orderBy: ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING,
        limit: ADMIN_LIVESTREAMS_PER_PAGE,
        offset: 0,
      },
    },
    (data) => {
      //Sort cache data by start date in case start date was updated
      data?.livestreams.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      return data;
    }
  );
};
