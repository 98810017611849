type Dict = Record<string, any>;

function baseStyleContent(props: Dict) {
  return {
    borderColor: "gray.300",
    borderRadius: "xl",
  };
}

const baseStyle = (props: Dict) => ({
  content: baseStyleContent(props),
});

export const Popover = {
  baseStyle,
  variants: {
    dark: {
      content: {
        bg: "gray.900",
        borderColor: "gray.900",
        color: "white",
      },
    },
  },
};
