import { CurrentUserFragment } from "@tract/common/dist/graphql";
import { isTeacherUser } from "Types/User";

export function getTaughtClassesforUser(user: CurrentUserFragment) {
  return user.learnerGroups
    .filter((learnerGroupMember) => learnerGroupMember.role === "teacher")
    .map((learnerGroupMember) => ({
      name: learnerGroupMember.learnerGroup.name,
      id: learnerGroupMember.learnerGroup.id,
    }));
}

export function userCanSavePaths(user: CurrentUserFragment) {
  return isTeacherUser(user);
}
