export const SIGNUP_ERRORS = {
  INTERNAL: "Internal",
  EDUCATOR_CODE_REQUIRED: "EducatorCodeRequired",
  MISSING_FOREIGN_ID: "MissingForeignId",
  INVALID_AUTH_CODE: "InvalidAuthCode",
  MISSING_IDENTITY_PAYLOAD: "MissingIdentityPayload",
  MISSING_EMAIL: "MissingEmail",
  NEED_TO_SIGNUP: "NeedToSignUp",
  INVALID_EMAIL_DOMAIN: "InvalidEmailDomain",
  LINKED_WITH_DIFFERENT_ACCOUNT: "LinkedWithDifferentAccount",
  MISSING_CLASSROOM_INVITE: "MissingClassroomInvite",
};
