import React from "react";

import { UnauthPathViewerPathFragment } from "@tract/common/dist/graphql";

import { ApiVideoPlayer } from "Components/ApiVideoPlayer";

import { UserFile } from "Utils/UserFile";

import {
  Avatar,
  Box,
  Button,
  Markdown,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Link,
  Text,
  HStack,
  AspectRatio,
} from "Shared";

import { UserType } from "Types/User";
import { PathCover } from "Components/PathCover";
import { useParams } from "react-router-dom";

type Props = {
  path: UnauthPathViewerPathFragment;
};

export const ContentUnauthed: React.FC<Props> = ({ path }) => {
  const { nodeId } = useParams<{ nodeId: string }>();
  let pathNode = path.nodes[0];

  if (nodeId) {
    const currentNode = path.nodes.find((n) => n.id === nodeId);

    if (currentNode) {
      pathNode = currentNode;
    }
  }

  const videoFile = new UserFile(pathNode.videoFile);
  const videoId =
    pathNode.videoFile?.integrations.find((i) => i.source === "api.video")
      ?.foreignId || "";

  return (
    <Box>
      {!!videoFile ? (
        <ApiVideoPlayer
          videoId={videoId}
          fallbackSrc={videoFile.getOriginalUrl()}
        />
      ) : (
        <AspectRatio ratio={16 / 9}>
          <PathCover objectFit="cover" cover={path.coverFile} />
        </AspectRatio>
      )}
      <Box my={6} maxW="50rem" px={{ base: 6, lg: 0 }}>
        <Text mb={2} fontSize="3xl" fontWeight="bold">
          {path.title}
        </Text>
        <HStack fontSize="md" spacing={2} mb={4}>
          {path.user?.userType === UserType.Kid ? (
            <>
              <Avatar
                tabIndex={-1}
                size="md"
                src={path.user?.avatar || ""}
                mr={1}
              />
              <Text fontWeight="bold">{path.user?.username}</Text>
            </>
          ) : (
            <>
              <Avatar
                as={Link}
                to={`/@${path.user?.username}`}
                tabIndex={-1}
                size="md"
                src={path.user?.avatar || ""}
                mr={1}
              />
              <Link as={Link} to={`/@${path.user?.username}`} fontWeight="bold">
                {path.user?.username}
              </Link>
            </>
          )}
        </HStack>
        <Markdown source={path.description || ""} mb={4} />
        <StatGroup mb={6}>
          <Stat>
            <StatLabel>📚 Subjects</StatLabel>
            {path?.subjects?.length > 0 ? (
              <StatNumber fontSize="xl">{path.subjects.join(", ")}</StatNumber>
            ) : (
              <StatNumber>-</StatNumber>
            )}
          </Stat>
          <Stat>
            <StatLabel>🛠 Skills Developed</StatLabel>
            {path.skills?.length > 0 ? (
              <StatNumber fontSize="xl">{path.skills.join(", ")}</StatNumber>
            ) : (
              <StatNumber>-</StatNumber>
            )}
          </Stat>
        </StatGroup>
        <StatGroup>
          <Stat>
            <StatLabel>✨ Category</StatLabel>
            {path.interestArea ? (
              <StatNumber
                fontSize="xl"
                as={Link}
                to={`/explore/interest/${path.interestArea.toLowerCase()}`}
              >
                {path.interestArea}
              </StatNumber>
            ) : (
              <StatNumber>-</StatNumber>
            )}
          </Stat>
        </StatGroup>
        {!!path.supplies && (
          <StatGroup mt={6}>
            <Stat>
              <StatLabel>📦 Required Supplies</StatLabel>
              <StatNumber fontSize="xl">{path.supplies}</StatNumber>
            </Stat>
          </StatGroup>
        )}
        {path?.tags?.length > 0 && (
          <StatGroup mt={6}>
            <Stat>
              <StatLabel mb={2}>🏷 Tags</StatLabel>
              <StatNumber>
                {path.tags.map((tag: string) => (
                  <Button
                    as={Link}
                    to={`/explore/tag/${tag}`}
                    variant="outline"
                    mr={2}
                    mb={2}
                  >
                    #{tag}
                  </Button>
                ))}
              </StatNumber>
            </Stat>
          </StatGroup>
        )}
      </Box>
    </Box>
  );
};
