import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { Flex } from "Shared";

import { ProjectGallery } from "Pages/ProjectGallery";
import { ExplorePaths } from "./ExplorePaths";
import { ExploreTag } from "./ExploreTag";
import { ExploreCreators } from "./ExploreCreators";

import { ExploreInterestArea } from "./ExploreInterestArea";
import { ExploreOrganization } from "./ExploreOrganization";
import { Helmet } from "react-helmet";
import { ExploreSubject } from "./ExploreSubject";
import { ExploreCurated } from "./ExploreCurated";
import { useFeature } from "Services/features";

interface Props {}

export const Explore: React.FC<Props> = () => {
  const isCuratedExploreEnabled = useFeature("curated-explore");

  return (
    <Flex w="full" direction="column" overflow="hidden">
      <Helmet>
        <title>Explore — Tract</title>
      </Helmet>
      <Switch>
        <Route path="/explore/creators">
          <ExploreCreators />
        </Route>
        <Route path="/explore/interest/:interestArea">
          <ExploreInterestArea />
        </Route>
        <Route path="/explore/tag/:tag">
          <ExploreTag authed={true} />
        </Route>
        <Route path="/explore/subject/:subject">
          <ExploreSubject />
        </Route>
        <Route path="/explore/organization/:orgId">
          <ExploreOrganization />
        </Route>
        <Route path="/explore/projects">
          <ProjectGallery />
        </Route>
        {isCuratedExploreEnabled && (
          <Route path="/explore" exact>
            <ExploreCurated />
          </Route>
        )}
        <Route path="/explore">
          <ExplorePaths />
        </Route>
        {!isCuratedExploreEnabled && (
          <>
            <Redirect path="/explore" to="/explore/originals" />
            <Redirect path="/" to="/explore/originals" />
          </>
        )}
      </Switch>
    </Flex>
  );
};
