import React, { useState } from "react";
import { useRefinementList } from "react-instantsearch-hooks-web";
import {
  Badge,
  Box,
  Center,
  Flex,
  FontAwesomeIcon,
  Stack,
  Link,
  Text,
  useBreakpointValue,
  useInitialBreakpointValue,
  EmptyState,
  EmptyStateFontAwesomeIcon,
} from "Shared";
import { GridContainer, GridPaths } from "Components/GridContainer";

import { ViewSource } from "Types/Path";
import {
  regular,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { HStack } from "@chakra-ui/react";
import {
  PathFeed,
  PathFeedInfinite,
  PathFeedProviderAlgolia,
  ExploreFilters,
  CurrentRefinements,
  NumberOfHits,
} from "./PathFeedAlgolia";

type ExplorePageType = {
  subjects?: string[];
  tags?: string[];
  title?: string;
  description?: string;
  iconDuotone?: any;
  iconColor?: string;
  bannerColor?: string;
  showTopTags?: boolean;
  FollowButton?: JSX.Element;
  interestAreas?: string[];
};

export const ExplorePage: React.FC<ExplorePageType> = ({
  subjects,
  interestAreas,
  tags,
  description,
  iconDuotone,
  iconColor,
  bannerColor,
  title,
  showTopTags,
  FollowButton,
}: ExplorePageType) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const hasMetadata = title || description || showTopTags || iconDuotone;
  const [noPaths, setNoPaths] = useState(false);

  const limit = useInitialBreakpointValue({
    base: 4,
    sm: 8,
    md: 12,
    xl: 16,
    "2xl": 15,
    "3xl": 18,
  });

  return (
    <PathFeedProviderAlgolia
      limit={limit}
      subjects={subjects}
      tags={tags}
      interestAreas={interestAreas}
    >
      <GridContainer pb={0}>
        {hasMetadata && (
          <Stack
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems="flex-start"
            pt={{ base: 6, lg: 10 }}
            width="100%"
          >
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: 4, md: 6 }}
              align="flex-start"
              width="full"
            >
              {iconDuotone && (
                <Center
                  w={{ base: "full", md: "6rem" }}
                  h="6rem"
                  p={4}
                  borderRadius="xl"
                  bg={bannerColor || "brandFull.50"}
                  color={iconColor || "brandFull.400"}
                >
                  <FontAwesomeIcon size={16} icon={iconDuotone} />
                </Center>
              )}
              <Flex direction="column" align="left">
                <Flex direction="row" align="left">
                  {title && (
                    <Text
                      fontSize={{ base: "2xl", lg: "4xl" }}
                      fontWeight="bold"
                      color="inherit"
                    >
                      {title}
                    </Text>
                  )}
                  {FollowButton}
                </Flex>
                {description && (
                  <Text
                    fontSize={{ base: "xl", lg: "2xl" }}
                    fontWeight="bold"
                    color="gray.600"
                  >
                    {description}
                  </Text>
                )}
                {showTopTags && <TopTagsList />}
              </Flex>
            </Stack>
          </Stack>
        )}

        <Flex
          direction="row"
          justifyContent="space-between"
          width="full"
          my={{ base: 2, md: 10 }}
          align="center"
        >
          <HStack align="center">
            <ExploreFilters />
            {!isMobile && <CurrentRefinements />}
          </HStack>
          <NumberOfHits />
        </Flex>
        {isMobile && <CurrentRefinements mb={4} />}

        <GridPaths mt={10}>
          <PathFeed
            source={ViewSource.ExploreSubject}
            isEmptyCallback={setNoPaths}
          />
        </GridPaths>

        {noPaths && (
          <EmptyState
            width="full"
            icon={
              <EmptyStateFontAwesomeIcon
                icon={duotone("wand-magic-sparkles")}
              />
            }
            headline="No paths yet"
          />
        )}
        <PathFeedInfinite />
      </GridContainer>
    </PathFeedProviderAlgolia>
  );
};

const TAG_LIMIT = 8;

const TopTagsList: React.FC = () => {
  const { items } = useRefinementList({ attribute: "tags" });
  const slicedItems = items?.slice(0, TAG_LIMIT);

  if (slicedItems.length < 1) return null;

  return (
    <Flex mt={2} align="flex-start" wrap="wrap">
      <Badge
        variant="subtle"
        color="green.600"
        bg="green.50"
        display="flex"
        alignItems="center"
        mr={3}
        mb={1}
      >
        <FontAwesomeIcon size={4} icon={regular("arrow-trend-up")} />
        <Box as="span" ml={2}>
          Trending
        </Box>
      </Badge>
      {slicedItems.map((item) => (
        <Link
          key={item.label}
          fontWeight="bold"
          display="inline-block"
          to={`/explore/tag/${item.value}`}
          mr={3}
          mb={1}
          _last={{ mr: 0 }}
        >
          #{item.label}
        </Link>
      ))}
    </Flex>
  );
};
