import React, { ReactNode } from "react";
import { forwardRef } from "@chakra-ui/react";

import { Flex, Text } from "Shared";

type Props = {
  iconRight?: ReactNode;
};

export const SideNavTitle = forwardRef<Props, "div">(
  ({ children, iconRight, ...props }, ref) => {
    return (
      <Flex
        align="center"
        justify="space-between"
        pl={5}
        pr={3}
        py={2}
        borderRadius="lg"
        color="gray.900"
        fontSize="2xl"
        fontWeight="bold"
        ref={ref}
        {...props}
      >
        <Text>{children}</Text>
        {iconRight}
      </Flex>
    );
  }
);
