import firebase from "firebase/compat";

export enum CodeType {
  ClassCode = "class_invite",
  KidCode = "kid_invite",
}

export enum EducatorCodeStatus {
  Deleted = "deleted",
}

export interface EducatorCode {
  expires: firebase.firestore.Timestamp;
  available: number;
  orgId: string;
  userId: string;
  learnerGroupId: string;
  status?: EducatorCodeStatus;
  total?: number;
  createdAt?: firebase.firestore.Timestamp;
}
