import React, { FC } from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  EmptyState,
  Flex,
  ButtonSolid,
  useBreakpointValue,
  EmptyStateFontAwesomeIcon,
} from "Shared";

import { useOffsetPaginationQuery } from "Utils";

import { useSession } from "Services/session";
import { useProjectModal } from "Services/projectModal";
import { usePrivacyScopesCheck } from "Services/privacy";

import { ProjectCard } from "Components/ProjectCard";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";
import { GridProfileItems } from "Components/GridContainer";

import {
  UserProfileProjectsQuery,
  UserProfileProjectsQueryVariables,
  UserProfileUserFragment,
} from "@tract/common/dist/graphql";
import { ViewSource } from "Types/Project";

import { USER_PROFILE_PROJECTS_QUERY } from "../graphql";

type Props = {
  user: UserProfileUserFragment;
};

const GALLERY_LIMIT = 54;

export const ProfileProjects: FC<Props> = ({ user }) => {
  const { currentUser } = useSession();
  const { isAllowed } = usePrivacyScopesCheck("projects", user);
  const isOwnProfile = user.id === currentUser.uid;
  const { handleOpenProjectModal } = useProjectModal();

  const { response, pagination } = useOffsetPaginationQuery<
    UserProfileProjectsQuery,
    UserProfileProjectsQueryVariables
  >({
    pause: !isAllowed,
    query: USER_PROFILE_PROJECTS_QUERY,
    field: "projects",
    variables: {
      userId: user.id,
      published: isOwnProfile ? { _in: [true, false] } : { _eq: true },
      isPrivate: isOwnProfile ? { _in: [true, false] } : { _eq: false },
      limit: GALLERY_LIMIT,
      offset: 0,
    },
  });

  const [{ data, fetching: loading }] = response;
  const projects = data?.projects || [];

  const skeletonCount = useBreakpointValue({
    base: 2,
    md: 4,
    xl: 6,
  });

  if (!projects?.length && loading && !!skeletonCount) {
    return (
      <GridProfileItems>
        {Array.from({ length: skeletonCount }).map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </GridProfileItems>
    );
  }

  if (!isAllowed) {
    return (
      <EmptyState image="project" headline="This users projects are private" />
    );
  }

  if (!projects || projects.length <= 0) {
    return (
      <EmptyState
        icon={<EmptyStateFontAwesomeIcon icon={duotone("images")} />}
        headline="No projects yet"
        body={isOwnProfile ? "Complete your first challenge" : undefined}
      />
    );
  }

  return (
    <>
      <GridProfileItems>
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            viewSource={ViewSource.UserProfile}
            project={project}
            handleClickFromParent={(event) => {
              handleOpenProjectModal(event, project);
            }}
          />
        ))}
      </GridProfileItems>

      {pagination.hasNextPage && (
        <Flex alignItems="center" justifyContent="center" mt={4}>
          <ButtonSolid onClick={pagination.loadMore}>Load More</ButtonSolid>
        </Flex>
      )}
    </>
  );
};
