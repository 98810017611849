import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "urql";

import {
  Badge,
  Box,
  Button,
  Tab,
  Tabs,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
  VStack,
  Image,
  TabList,
  TabPanels,
  TabPanel,
  useBreakpointValue,
  IconButton,
  IconBack,
} from "Shared";

import { CoinValue } from "Components/CoinValue";
import { LayoutCentered } from "Components/LayoutCentered";

import { captureException } from "Services/errors";
import { GiveAwardErrors } from "Services/awards";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import { useFeature } from "Services/features";
import { useCoins } from "Services/hooks/useCoins";

import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";

import { AwardButtonItem } from "./AwardButtonItem";

import { AwardType } from "Types/Awards";

import {
  GetAwardsQuery,
  GiveAwardMutation,
  GiveAwardMutationVariables,
  ProjectCardFragment,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
  useGetAwardsQuery,
} from "@tract/common/dist/graphql";

import { createNotification } from "@tract/common/dist/utils/create-notification";
import { GIVE_AWARD_MUTATION } from "graphql/awards";

export type AwardWithText = GetAwardsQuery["awards"][0] & { text?: string };

type Props = {
  project: ProjectCardFragment;
  onAwardSuccess: (award: AwardWithText) => void;
  isOpen: boolean;
  onClose: () => void;
};

enum TAB_INDEXES {
  ALL = 0,
  MEDALS = 1,
  REACTIONS = 2,
  CREATOR = 3,
}

export const AwardListModal: React.FC<Props> = ({
  project,
  onAwardSuccess,
  isOpen,
  onClose,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(TAB_INDEXES.ALL);
  const toast = useToast();
  const { track, trackForUser } = useAnalytics();
  const { currentUser, isApprovedOrg } = useSession();
  const { balance, updateBalance } = useCoins();
  const [givingAward, setGivingAward] = useState(false);
  const { data, loading } = useGetAwardsQuery();

  const medalAwards = useMemo(
    () =>
      data?.awards
        .filter((award) => award.type === AwardType.Medal)
        .sort((a, b) => a.cost - b.cost) || [],
    [data]
  );

  const reactionAwards = useMemo(
    () =>
      data?.awards
        .filter((award) => award.type === AwardType.Reaction)
        .sort((a, b) => a.cost - b.cost),
    [data]
  );

  const creatorAwards = useMemo(
    () =>
      data?.awards
        .filter(
          (award) =>
            award.type === AwardType.Creator &&
            (award.ownerId === null || award.ownerId === currentUser.uid)
        )
        .sort((a, b) => a.cost - b.cost) || [],
    [data, currentUser.uid]
  );

  const allAwards = useMemo(
    () => medalAwards.concat(reactionAwards || []).concat(creatorAwards),
    [medalAwards, reactionAwards, creatorAwards]
  );

  const [optionalMessage, setOptionalMessage] = useState("");
  const [selection, setSelection] = useState<
    GetAwardsQuery["awards"][0] | undefined
  >();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isAwardTextCommentEnabled = useFeature("award-text-comment");

  useEffect(() => {
    if (isMobile) {
      setSelection(undefined);
      return;
    }

    switch (currentTabIndex) {
      case TAB_INDEXES.REACTIONS:
        setSelection(reactionAwards?.[0]);
        break;
      case TAB_INDEXES.MEDALS:
        setSelection(medalAwards?.[0]);
        break;
      case TAB_INDEXES.ALL:
        setSelection(allAwards?.[0]);
        break;
      case TAB_INDEXES.CREATOR:
        setSelection(creatorAwards?.[0]);
        break;
      default:
        setSelection(undefined);
        break;
    }
  }, [
    isOpen,
    isMobile,
    allAwards,
    medalAwards,
    creatorAwards,
    reactionAwards,
    currentTabIndex,
  ]);

  const [, giveAward] = useMutation<
    GiveAwardMutation,
    GiveAwardMutationVariables
  >(GIVE_AWARD_MUTATION);

  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);

  const handleGiveAward = async (award: GetAwardsQuery["awards"][0]) => {
    setGivingAward(true);
    try {
      const mutationResult = await giveAward({
        awardId: award.id,
        projectId: project.id,
        userId: project.user.id,
        fromUserId: currentUser.uid,
        bonus: award.bonus,
        cost: -award.cost,
        comment: optionalMessage.trim(),
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
      updateBalance(-award.cost);

      toast({
        title: "Successfully awarded project",
        status: "success",
      });

      await createFeedActivity({
        apiKey: process.env.REACT_APP_STREAM_API_KEY,
        feedSlug: "notification",
        feedUserId: project.user.id,
        activity: createNotification({
          type: "actor",
          actor: currentUser.id,
          verb: "award",
          entity: "project",
          entityId: project.id,
          targetPath: `/project/${project.id}`,
          description: "gave you an award",
          meta: {
            schemaVersion: 1,
            awardId: award.id,
            bonusCoins: award.bonus || 0,
          },
        }),
      });

      track(ANALYTICS_EVENTS.PROJECT_AWARD_GIVEN, {
        awardId: award.id,
        awardName: award.name,
        awardCost: award.cost,
        awardBonus: award.bonus,
        awardType: award.type,
        userCoinBalance: balance,
      });
      trackForUser(
        project.user.id || "",
        ANALYTICS_EVENTS.PROJECT_AWARD_RECEIVED,
        {
          awardId: award.id,
          awardName: award.name,
          awardCost: award.cost,
          awardBonus: award.bonus,
          awardType: award.type,
        }
      );
      onAwardSuccess({ ...award, text: optionalMessage.trim() });
      setOptionalMessage("");
    } catch (err: any) {
      if (err.message === GiveAwardErrors.InsufficientCoinBalance) {
        toast({
          title: "Not enough coins for this award",
          status: "error",
        });
        return;
      }
      if (err.message === GiveAwardErrors.AwardSelfProhibited) {
        toast({
          title: "Not allowed to award your own project",
          status: "error",
        });
      }
      toast({
        title: "Error awarding project",
        status: "error",
      });
      captureException(err);
    } finally {
      setGivingAward(false);
    }
  };

  const handleClose = () => {
    setSelection(undefined);
    setCurrentTabIndex(TAB_INDEXES.ALL);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="5xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent h="640px">
        <ModalHeader
          borderBottom="1px solid"
          borderBottomColor="gray.100"
          textAlign="start"
        >
          <Text as="span">Give Award</Text>
          <Badge
            ml={{ base: 8, md: 6 }}
            bgColor={{ base: "unset", md: "gray.50" }}
            fontWeight="bold"
            fontSize="lg"
            px={{ base: 0, md: 4 }}
            py={{ base: 0, md: 3 }}
          >
            <CoinValue size="lg" coinProps={{ mr: 1 }} value={balance} />
          </Badge>
        </ModalHeader>
        <ModalCloseButton size="lg" mt={{ base: 0, md: 1 }} />
        <ModalBody py={0}>
          {loading ? (
            <LayoutCentered isLoading />
          ) : (
            <HStack height="100%" alignItems="unset">
              {(!isMobile || !selection) && (
                <VStack flex={1} mx={-1}>
                  <Tabs
                    tabIndex={currentTabIndex}
                    onChange={(index) => setCurrentTabIndex(index)}
                    width="100%"
                    mt={1}
                  >
                    <TabList px={1}>
                      <Tab>All</Tab>
                      <Tab>Medals</Tab>
                      <Tab>Reactions</Tab>
                      {creatorAwards.length ? <Tab>Your Awards</Tab> : null}
                    </TabList>
                    <TabPanels
                      pl={1}
                      pr={{ base: 0, md: 4 }}
                      py={6}
                      width="100%"
                      overflowY="auto"
                    >
                      <TabPanel>
                        <Grid
                          gap={3}
                          templateColumns="repeat(auto-fill, minmax(96px, auto))"
                        >
                          {allAwards?.map((award) => (
                            <AwardButtonItem
                              key={award.id}
                              isSelected={selection?.id === award.id}
                              award={award}
                              onClick={() => setSelection(award)}
                            />
                          ))}
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <Grid
                          gap={3}
                          templateColumns="repeat(auto-fill, minmax(96px, auto))"
                        >
                          {medalAwards?.map((award) => (
                            <AwardButtonItem
                              key={award.id}
                              isSelected={selection?.id === award.id}
                              award={award}
                              onClick={() => setSelection(award)}
                            />
                          ))}
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <Grid
                          gap={3}
                          templateColumns="repeat(auto-fill, minmax(96px, auto))"
                        >
                          {reactionAwards?.map((award) => (
                            <AwardButtonItem
                              key={award.id}
                              isSelected={selection?.id === award.id}
                              award={award}
                              onClick={() => setSelection(award)}
                            />
                          ))}
                        </Grid>
                      </TabPanel>
                      <TabPanel>
                        <Grid
                          gap={3}
                          templateColumns="repeat(auto-fill, minmax(96px, auto))"
                        >
                          {creatorAwards?.map((award) => (
                            <AwardButtonItem
                              key={award.id}
                              isSelected={selection?.id === award.id}
                              award={award}
                              onClick={() => setSelection(award)}
                            />
                          ))}
                        </Grid>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </VStack>
              )}
              {selection && (
                <>
                  <VStack
                    width={{ base: "100%", md: "400px" }}
                    borderLeft={{ base: "none", md: "1px solid" }}
                    borderLeftColor={{ md: "gray.200" }}
                    position="relative"
                    py={6}
                    pl={{ base: 0, md: 6 }}
                  >
                    {isMobile && (
                      <IconButton
                        aria-label="show all awards"
                        position="absolute"
                        left="-10px"
                        top="24px"
                        variant="ghost"
                        icon={<IconBack />}
                        onClick={() => setSelection(undefined)}
                      />
                    )}
                    <Box width="100%" flex={1}>
                      <VStack textAlign="center" spacing={2} mb={6}>
                        <Box minWidth="64px" height="64px">
                          <Image
                            alt={selection.name}
                            src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${selection.filePath}`}
                            height="64px"
                          />
                        </Box>
                        <Text
                          fontSize={{ base: "xl", md: "2xl" }}
                          fontWeight="bold"
                        >
                          {selection.name} Award
                        </Text>
                        <Text>{selection.description}</Text>
                        <Text color="gray.600">
                          {selection.bonusDescription ||
                            `Gives ${selection.bonus} coins`}
                        </Text>
                        <CoinValue value={selection.cost} size="lg" />
                      </VStack>
                      {currentUser.isEducator && !isApprovedOrg
                        ? null
                        : isAwardTextCommentEnabled && (
                            <Textarea
                              placeholder="Add an optional comment..."
                              value={optionalMessage}
                              onChange={(e) =>
                                setOptionalMessage(e.target.value)
                              }
                            />
                          )}
                    </Box>
                    <Button
                      colorScheme="brandFull"
                      size="lg"
                      onClick={() => handleGiveAward(selection)}
                      mt={6}
                      width="100%"
                      isLoading={givingAward}
                    >
                      Give Award for <CoinValue ml={2} value={selection.cost} />
                    </Button>
                  </VStack>
                </>
              )}
            </HStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
