import { forwardRef } from "@chakra-ui/system";

import { Avatar, Link } from "Shared";

type UserAvatarProps = {
  username?: string;
  userId?: string;
  avatarURL?: string;
  isTeacher?: boolean;
  isParent?: boolean;
  displayName?: string;
};

export const UserAvatar = forwardRef(
  (
    {
      username = "",
      userId,
      avatarURL,
      isTeacher,
      isParent,
      displayName,
      ...props
    }: UserAvatarProps,
    ref
  ) => {
    return isParent && !isTeacher ? (
      <Avatar
        src={avatarURL}
        name={displayName}
        aria-hidden="true"
        tabIndex={-1}
        ref={ref}
        {...props}
      />
    ) : (
      <Avatar
        src={avatarURL}
        as={Link}
        to={isTeacher ? `/${userId}` : `/@${username}`}
        _hover={{ textDecoration: "none" }}
        aria-hidden="true"
        tabIndex={-1}
        ref={ref}
        {...props}
      />
    );
  }
);
