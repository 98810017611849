import { gql } from "@apollo/client";

export const ADMIN_PROJECT_FRAGMENT = gql`
  fragment AdminProject on project {
    id
    text
    isPrivate
    createdAt
    updatedAt
    userId
    published
    rejectedAt
    rejectedByUserId
    rejectedReason
    rejectedFeedback
    pathNodeId
    rewardCoin {
      amount
    }
    file {
      id
      filename
    }
    user {
      id: firestoreId
      username
      firstName
      lastName
      invitedBy {
        username
      }
      isEducator
    }
  }
`;

export const ADMIN_PROJECTS_QUEUE_QUERY = gql`
  ${ADMIN_PROJECT_FRAGMENT}

  query AdminProjectsQueue(
    $limit: Int!
    $offset: Int!
    $where: project_bool_exp
  ) {
    projects: project(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { createdAt: desc }
    ) {
      ...AdminProject
    }
  }
`;

export const ADMIN_PROJECTS_QUEUE_COUNT_QUERY = gql`
  query AdminProjectsQueueCount {
    projects: project_aggregate(
      where: { published: { _eq: false }, rejectedAt: { _eq: "-infinity" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

gql`
  ${ADMIN_PROJECT_FRAGMENT}

  query AdminProjects(
    $limit: Int = 20
    $offset: Int = 0
    $responseTypes: [String!] = ["text", "video"]
    $userIdInput: String_comparison_exp
    $pathInput: path_bool_exp = {}
    $pathIdInput: bigint_comparison_exp
    $rejectedAtInput: timestamptz_comparison_exp
    $published: Boolean_comparison_exp
  ) {
    projects: project(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
      where: {
        rejectedAt: $rejectedAtInput
        node: { pathId: $pathIdInput, path: $pathInput }
        published: $published
        userId: $userIdInput
      }
    ) {
      ...AdminProject
    }
  }
`;

const ADMIN_PROJECT_PATH_FRAGMENT = gql`
  fragment adminProjectPath on project {
    node {
      id
      title
      caption
      path {
        id
        title
        slug
        authorId
      }
    }
  }
`;

gql`
  ${ADMIN_PROJECT_PATH_FRAGMENT}

  query GetPathByProjectId($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      ...adminProjectPath
    }
  }
`;

gql`
  mutation AdminPublishProject($projectId: uuid!) {
    update_project_by_pk(
      pk_columns: { id: $projectId }
      _set: { published: true }
    ) {
      id
    }
  }
`;

export const USERNAME_SEARCH_QUERY = gql`
  query UsernameSearch($username: String!) {
    user(where: { username: { _eq: $username } }) {
      id: firestoreId
    }
  }
`;
