import React from "react";
import { gql, useQuery } from "urql";

import { Text, Link } from "Shared";

import { PathCard } from "Components/PathCard";

import { HScrollContainer } from "./HScrollContainer";

import { captureException } from "Services/errors";

import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";
import { PathVisibility } from "@tract/common/dist/types/models/Path";
import { PathCardFragment } from "@tract/common/dist/graphql";
import { ViewSource } from "Types/Path";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { ExploreRoutes } from "Pages/Explore/ExplorePaths";

type Props = {
  templateColumns?: {
    base?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
  numCards?: number;
};

export const PathSection: React.FC<Props> = ({ templateColumns, numCards }) => {
  const query = gql`
    ${PATH_CARD_FRAGMENT}

    query HomePathSection(
      $limit: Int!
      $isPublished: Boolean!
      $visibility: String!
    ) @cached(ttl: 120) {
      paths: path(
        limit: $limit
        order_by: { publishedAt: desc_nulls_last }
        where: {
          orgId: { _is_null: true }
          isPublished: { _eq: $isPublished }
          visibility: { _eq: $visibility }
        }
      ) {
        ...PathCard
      }
    }
  `;

  const [{ data, fetching: loading, error }] = useQuery<{
    paths: PathCardFragment[];
  }>({
    query,
    variables: {
      limit: 6,
      visibility: PathVisibility.Public,
      isPublished: true,
    },
  });

  if (error) {
    captureException(error);
  }

  const paths = data?.paths;

  const renderTitle = () => {
    return (
      <>
        <Text as={Link} to={ExploreRoutes.communityNewest} color="gray.900">
          Recently Added
        </Text>
        <Link
          fontSize="lg"
          to={ExploreRoutes.communityNewest}
          color="brand"
          tabIndex={-1}
        >
          See more
        </Link>
      </>
    );
  };

  if (loading) {
    return (
      <HScrollContainer
        renderTitle={renderTitle}
        templateColumns={templateColumns}
      >
        {Array.from({ length: numCards || 4 }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </HScrollContainer>
    );
  }

  if (!paths || (paths && paths.length < 1)) {
    return null;
  }

  return (
    <HScrollContainer
      renderTitle={renderTitle}
      templateColumns={templateColumns}
    >
      {paths
        .filter((_, i) => i < (numCards || 4))
        .map((path) => (
          <PathCard key={path.id} path={path} source={ViewSource.HomeNew} />
        ))}
    </HScrollContainer>
  );
};
