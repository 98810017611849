import { LexoRank } from "lexorank";

export function calculateNewLexorank(
  items: { lexorank: string }[],
  sourceIndex: number,
  destinationIndex: number
): string {
  let newLexoRank = "";

  if (destinationIndex === 0) {
    newLexoRank = LexoRank.parse(items[0].lexorank).genPrev().format();
  } else if (destinationIndex === items.length - 1) {
    newLexoRank = LexoRank.parse(items[items.length - 1].lexorank)
      .genNext()
      .format();
  } else {
    const movingUp = destinationIndex < sourceIndex;
    const betweenStartIndex = movingUp
      ? destinationIndex - 1
      : destinationIndex;
    const betweenEndIndex = movingUp ? destinationIndex : destinationIndex + 1;
    newLexoRank = LexoRank.parse(items[betweenStartIndex].lexorank)
      .between(LexoRank.parse(items[betweenEndIndex].lexorank))
      .format();
  }

  return newLexoRank;
}

export function sortByLexorank(
  a: { lexorank: string },
  b: { lexorank: string }
) {
  return LexoRank.parse(a.lexorank).compareTo(LexoRank.parse(b.lexorank));
}
