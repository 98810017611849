import { useLocation, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";

import { useToast } from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { ResetPassword } from "./ResetPassword";
import { SignInWithEmailLink } from "./SignInWithEmailLink";

import { useSession } from "Services/session";
import { firebaseClient } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";

import { USER_TYPES } from "Constants/userTypes";
import { EMAIL_ACTION_MODES } from "Constants/emailActionModes";

export function ManageUser() {
  const toast = useToast();
  const { track } = useAnalytics();
  const session = useSession({ bypassStrict: true });

  const location = useLocation();
  const [verifying, setVerifying] = useState(false);
  const [hasError, setError] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const token = searchParams.get("token");
  const userType = searchParams.get("userType") || USER_TYPES.parent;
  const isVerifyMode = mode === EMAIL_ACTION_MODES.verifyEmail;
  const isResetPasswordMode = mode === EMAIL_ACTION_MODES.resetPassword;

  useEffect(() => {
    if (
      !oobCode ||
      !session ||
      !isVerifyMode ||
      verifying ||
      hasError ||
      session?.firebaseUser.emailVerified
    )
      return;

    setVerifying(true);

    firebaseClient
      .auth()
      .applyActionCode(oobCode)
      .then(() => {
        track(ANALYTICS_EVENTS.EDUCATOR_EMAIL_VERIFIED);

        window.location.href = "/explore";
      })
      .catch((err) => {
        captureException(err);
        toast({
          title: "There was an error verifying your email",
          status: "error",
        });
        setError(true);
      })
      .finally(() => {
        setVerifying(false);
      });
  }, [toast, track, session, oobCode, isVerifyMode, verifying, hasError]);

  if (session?.firebaseUser.emailVerified) {
    return <Redirect to="/explore" />;
  }

  if (isVerifyMode) {
    if (!session) {
      const redirectPath = `/manage-user?${searchParams.toString()}`;
      return (
        <Redirect
          to={`/sign-in?redirect=${encodeURIComponent(redirectPath)}`}
        />
      );
    }

    return <LayoutCentered height="100vh" isLoading />;
  }

  if (isResetPasswordMode) {
    return <ResetPassword oobCode={oobCode} mode={mode} userType={userType} />;
  }

  if (token) {
    return <SignInWithEmailLink token={token} />;
  }

  return <Redirect to="/" />;
}
