import { FC, useState } from "react";
import { useMutation, gql } from "urql";

import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "Shared";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";

const REMOVE_PATH_COLLECTION_ITEM = gql`
  mutation RemovePathCollectionItem($collectionId: uuid!, $pathId: bigint!) {
    delete_path_collection_item_by_pk(
      collectionId: $collectionId
      pathId: $pathId
    ) {
      pathCollection {
        id
      }
    }
  }
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  pathId: number;
  collectionId: string;
  title: string;
  description: string;
};

export const RemoveModal: FC<Props> = ({
  isOpen,
  onClose,
  pathId,
  collectionId,
  title,
  description,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [, removeMutation] = useMutation(REMOVE_PATH_COLLECTION_ITEM);
  const { track } = useAnalytics();

  async function remove() {
    setIsRemoving(true);
    await removeMutation({
      pathId: pathId,
      collectionId: collectionId,
    });
    setIsRemoving(false);
    onClose();

    track(ANALYTICS_EVENTS.PATH_UNSAVED);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{description}</ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={2} flex={1}>
            <Button size="lg" variant="outline" flex={1} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="solid"
              colorScheme="red"
              flex={1}
              onClick={remove}
              isLoading={isRemoving}
            >
              Yes, Remove
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
