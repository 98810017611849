import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  ButtonOutlined,
  Emoji,
  Menu,
  Flex,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Stack,
} from "Shared";

import { useAlgoliaSearch } from "Services/hooks/useAlgolia";
import { index } from "Services/algolia";

import { PathList } from "Components/PathList";
import {
  PathFilterItem,
  PATH_INTEREST_AREAS,
  PATH_SKILLS,
  PATH_SUBJECTS,
  PATH_SUBJECT_V2,
} from "Constants/paths";
import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";
import { PathCardFragment } from "@tract/common/dist/graphql";
import { ViewSource } from "Types/Path";

const MAX_HITS_PER_PAGE = 21;

type FilterMenuProps = {
  items: PathFilterItem[];
  onSelect: (item: PathFilterItem) => void;
  selection?: PathFilterItem;
  title: string;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  items,
  onSelect,
  selection,
  title,
}) => {
  let displayName = selection?.label;
  if (title === "Subject") {
    displayName =
      PATH_SUBJECT_V2.filter((subject) => subject.label === selection?.label)[0]
        ?.standardizedShortLabel || selection?.label;
  }
  return (
    <Menu>
      {selection ? (
        <MenuButton
          as={ButtonOutlined}
          bg="gray.50"
          borderColor="gray.600"
          borderWidth={2}
          transition="none"
          _hover={{
            transition: ".15s ease border-color",
            transform: "none",
            borderColor: "gray.600",
          }}
          direction="row"
          align="center"
          width="100%"
        >
          {selection.emoji && <Emoji emoji={selection.emoji} mr={2} />}
          {displayName}
        </MenuButton>
      ) : (
        <MenuButton
          as={ButtonOutlined}
          _hover={{
            transition: ".15s ease border-color",
            transform: "none",
            borderColor: "gray.600",
          }}
          width="100%"
        >
          {title}
        </MenuButton>
      )}

      <MenuList left={0}>
        <MenuOptionGroup value={selection?.value} type="radio">
          {items.map((item, i) => {
            let displayName = item.label;
            if (title === "Subject") {
              displayName =
                PATH_SUBJECT_V2.filter(
                  (subject) => subject.label === item.label
                )[0]?.standardizedShortLabel || item.label;
            }
            return (
              <MenuItem key={`${item}-${i}`} onClick={() => onSelect(item)}>
                {item.emoji && <Emoji emoji={item.emoji} mr={2} />}
                {displayName}
              </MenuItem>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

type ExploreProps = {};

export const ExplorePaths: React.FC<ExploreProps> = () => {
  const [activeFilters, setActiveFilters] = useState<{
    skills?: PathFilterItem;
    subjects?: PathFilterItem;
    interestArea?: PathFilterItem;
  } | null>(null);
  const [page, setPage] = useState<number>(0);

  const searchFilters = useMemo(() => {
    let filters = [
      `status:${PathStatus.Published}`,
      `visibility:${PathVisibility.Public}`,
      `isVisibleUnauthed:true`,
      `_schemaVersion = 3`,
    ];

    if (activeFilters) {
      for (const [key, filter] of Object.entries(activeFilters)) {
        filters.push(`${key}:"${filter!.value}"`);
      }
    }

    return filters;
  }, [activeFilters]);

  const searchParams = {
    filters: searchFilters.join(" AND "),
    hitsPerPage: MAX_HITS_PER_PAGE,
    page,
  };

  const {
    data: filteredData,
    isLoading,
    meta,
  } = useAlgoliaSearch<PathCardFragment>({
    index: index.paths,
    query: "",
    params: searchParams,
    mergeResult: page > 0,
  });

  const handleItemChange = (
    fieldKey: string,
    item: PathFilterItem,
    selection?: PathFilterItem
  ) => {
    if (item !== selection) {
      setActiveFilters({ ...activeFilters, [fieldKey]: item });
      setPage(0);
    }
  };

  const handleReset = () => {
    setActiveFilters(null);
    setPage(0);
  };

  return (
    <>
      <Stack
        direction={["column", null, "row"]}
        spacing={2}
        mb={10}
        pt={10}
        shouldWrapChildren
      >
        <FilterMenu
          title={"Subject"}
          items={PATH_SUBJECTS}
          onSelect={(item) =>
            handleItemChange("subjects", item, activeFilters?.subjects)
          }
          selection={activeFilters?.subjects}
        />
        <FilterMenu
          title={"Skill Developed"}
          items={PATH_SKILLS}
          onSelect={(item) =>
            handleItemChange("skills", item, activeFilters?.skills)
          }
          selection={activeFilters?.skills}
        />
        <FilterMenu
          title={"Interest Area"}
          items={PATH_INTEREST_AREAS}
          onSelect={(item) =>
            handleItemChange("interestArea", item, activeFilters?.interestArea)
          }
          selection={activeFilters?.interestArea}
        />
        {activeFilters && (
          <Box
            borderStyle="solid"
            borderTopWidth={["1px", null, 0]}
            borderLeftWidth={[0, null, "1px"]}
            borderLeftColor="gray.200"
            borderTopColor="gray.200"
            pt={[4, null, 0]}
            mt={[2, null, 0]}
            pl={[0, null, 4]}
            ml={[0, null, 2]}
          >
            <ButtonOutlined onClick={handleReset} width="100%">
              Clear Filters
            </ButtonOutlined>
          </Box>
        )}
      </Stack>

      <PathList
        isLoading={!page && isLoading}
        paths={filteredData}
        templateColumns={["1fr", null, "repeat(2, 1fr)", "repeat(3, 1fr)"]}
        source={ViewSource.ExploreAllUnauth}
      />
      {meta && meta.nbPages > page + 1 && (
        <Flex direction="column" alignItems="center" mt={8}>
          <Button
            size="lg"
            variant="outline"
            isLoading={isLoading}
            onClick={() => setPage((page) => page + 1)}
          >
            Load More
          </Button>
        </Flex>
      )}
    </>
  );
};
