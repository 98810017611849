import { useMemo } from "react";
import groupBy from "lodash.groupby";
import { useCollection } from "Utils/hooks/firestore";
import { AchievementCategory } from "Types/Achievement";
import { UserAchievement } from "Types/UserAchievement";
import { UserProfileUserFragment } from "@tract/common/dist/graphql";

type GroupedUserAchievements = {
  [achivementId: string]: UserAchievement[];
};

export const useAchievements = (user: UserProfileUserFragment) => {
  const { data, loading } = useCollection<UserAchievement>(
    "user-achievements",
    {
      where: [
        ["userId", "==", user.id],
        ["achievementCategory", "==", AchievementCategory.Trophies],
      ],
      orderBy: ["createdAt", "desc"],
      limit: 500,
    }
  );

  const userAchievementsTrophies: GroupedUserAchievements | null =
    useMemo(() => {
      if (!data?.length) return null;
      return groupBy(data, (u) => u.achievementId);
    }, [data]);

  return [userAchievementsTrophies, loading];
};
