import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function ScrollToTopOnMount() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return null;
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
}
