import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { FC, useState } from "react";
import * as Yup from "yup";
import { AxiosResponse } from "axios";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  ExternalLink,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "Shared";

import { signInWithUsername } from "Services/auth";
import { captureException, ERROR_TAGS } from "Services/errors";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { post } from "Services/api";

import { FormFieldUsername } from ".";
import to from "@tract/common/dist/utils/async";
import { StudentSchema, USERNAME_MAX_LENGTH } from "./schema";

import { Educator_Code } from "@tract/common/dist/graphql";

const SignUpWithKidCodeSchema = StudentSchema.shape({
  // month: Yup.string().required("Please provide a valid birthday"),
  // day: Yup.string().required("Please provide a valid birthday"),
  // year: Yup.string().required("Please provide a valid birthday"),
  acceptTerms: Yup.boolean()
    .required()
    .test(
      "terms-accepted",
      "You must agree to the Terms of Service and Privacy Policy",
      (val) => val === true
    ),
});

export type SignUpWithKidCodeForm = {
  month: string;
  day: string;
  year: string;
  username: string;
  password: string;
  acceptTerms: boolean;
};

interface SignUpFormProps {
  code: string;
  loggedIn: boolean;
  codeData: Educator_Code;
  onSuccess: () => void;
}

export const SignUpWithKidCode: FC<SignUpFormProps> = ({
  code,
  loggedIn,
  codeData,
  onSuccess,
}) => {
  const { track, identify } = useAnalytics();
  const [onboarding, setOnboarding] = useState(false);
  const [onboardingError, setOnboardingError] = useState("");

  async function onSubmit(
    { day, month, year, username, password }: SignUpWithKidCodeForm,
    actions: FormikHelpers<SignUpWithKidCodeForm>
  ) {
    setOnboarding(true);
    setOnboardingError("");
    try {
      const [err, response] = await to<
        any,
        Error & { response: AxiosResponse }
      >(
        post("/v2/onboard/student", null, {
          birthday: `${year}-${prefixZero(month)}-${prefixZero(day)}`,
          username,
          password,
          code,
        })
      );

      if (err) {
        captureException(err, { tags: { feature: ERROR_TAGS.KID_ONBOARDING } });
        setOnboardingError(err?.response?.data || err.message);
        return;
      }

      if (!response) {
        captureException(new Error("No response from /v2/onboard/student"), {
          tags: { feature: ERROR_TAGS.KID_ONBOARDING },
        });
        setOnboardingError("Something went wrong :(");
        return;
      }

      const { user } = await signInWithUsername({ username, password });

      if (user) {
        identify(user.uid, {
          email: user.email,
          invitedByUserId: codeData.user?.id,
        });

        track(ANALYTICS_EVENTS.LEARNER_SIGN_UP_COMPLETED, {
          learnerGroupId: codeData.learnerGroup?.id,
          orgId: codeData.learnerGroup?.orgId,
          learnerGroupOwnerId: codeData.learnerGroup?.members.find(
            (member) => member.isOwner
          )?.userId,
          inviteCode: code,
          invitedByUserId: codeData.user?.id,
          invitedByUsername: codeData.user?.username,
          username: username,
        });
      }

      onSuccess();
    } catch (err: any) {
      captureException(err, { tags: { feature: ERROR_TAGS.KID_ONBOARDING } });
      setOnboardingError(err.message);
    } finally {
      setOnboarding(false);
    }
  }

  return (
    <>
      <Box mb={6}>
        <Text fontSize="2xl" fontWeight="bold">
          Create your account:
        </Text>
      </Box>
      <Formik
        initialValues={{
          month: "",
          day: "",
          year: "",
          username: "",
          password: "",
          acceptTerms: false,
        }}
        validationSchema={SignUpWithKidCodeSchema}
        validateOnChange={false}
        onSubmit={(values, actions) => onSubmit(values, actions)}
      >
        <Form noValidate>
          {/* <DatePicker /> */}
          <FormFieldUsername
            id="username"
            variant="filled"
            placeholder="Username"
            helpText="Don't use your real name"
            autoComplete="off"
            size="lg"
            isDisabled={onboarding}
            maxLength={USERNAME_MAX_LENGTH}
          />
          <Field name="password">
            {({ field, form: { submitCount }, meta }: FieldProps) => (
              <FormControl
                isRequired
                isInvalid={submitCount > 0 && !!meta.error}
                mb={6}
              >
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  {...field}
                  variant="filled"
                  id="password"
                  type="password"
                  size="lg"
                  placeholder="Password"
                  autoComplete="new-password"
                  isDisabled={onboarding}
                />
                <FormHelperText textAlign="left">
                  Keep this in a safe place
                </FormHelperText>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="acceptTerms">
            {({ field, form: { submitCount }, meta }: FieldProps) => (
              <FormControl
                isRequired
                mb={6}
                textAlign="left"
                isInvalid={submitCount > 0 && !!meta.error}
              >
                <Checkbox
                  {...field}
                  isInvalid={submitCount > 0 && !!meta.error}
                  name="acceptTerms"
                  size="lg"
                >
                  <Text fontWeight="normal" color="gray.600" ml={2}>
                    I agree to the{" "}
                    <ExternalLink
                      href="/legal/tos"
                      target="_blank"
                      color="brand"
                      fontWeight="bold"
                    >
                      Terms of Service
                    </ExternalLink>{" "}
                    and{" "}
                    <ExternalLink
                      href="/legal/privacy"
                      target="_blank"
                      color="brand"
                      fontWeight="bold"
                    >
                      Privacy Policy
                    </ExternalLink>
                  </Text>
                </Checkbox>
                <FormErrorMessage textAlign="left">
                  {meta.error}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          {onboardingError && (
            <Alert status="error" textAlign="left" mb={6}>
              {onboardingError}
            </Alert>
          )}
          <Button
            size="lg"
            width="100%"
            type="submit"
            disabled={onboarding || loggedIn}
            isLoading={onboarding}
            loadingText={"We're giving your account super powers..."}
            colorScheme="brandFull"
          >
            {loggedIn ? "Just a second..." : "Create Account"}
          </Button>
        </Form>
      </Formik>
    </>
  );
};

function prefixZero(num: string) {
  return Number(num) < 10 ? `0${num}` : num;
}
