import { gql } from "@apollo/client";

export const ACTIVE_ORG_TEACHERS_QUERY = gql`
  query ActiveOrgTeachers($orgId: String!, $userType: String!) {
    user(where: { orgId: { _eq: $orgId }, userType: { _eq: $userType } }) {
      id: firestoreId
      firstName
      lastName
      email
      createdAt
      avatar
      userType
      teacher {
        id: userId
        displayName
      }
    }
  }
`;

export const ADMIN_ORG_QUERY = gql`
  query AdminOrgById($orgId: String!) {
    organization_by_pk(firestoreId: $orgId) {
      id: firestoreId
      name
      type
      verified
      address
      avatar
      gradeLevel
      placeId
      addressLine1
      addressLine2
      city
      zip
      region
      country
      shopEnabled
      users(where: { isEducator: { _eq: true } }) {
        id: firestoreId
      }
      emailDomains: domains {
        domain
      }
    }
  }
`;

export const ADMIN_ORGS_FRAGMENT = gql`
  fragment AdminOrg on organization {
    id: firestoreId
    name
    verified
    address
    createdAt
  }
`;

export const ADMIN_ORGS_BY_STATUS_QUERY = gql`
  ${ADMIN_ORGS_FRAGMENT}

  query AdminOrgsByStatus($verified: Boolean!, $limit: Int!, $offset: Int!) {
    adminOrgs: organization(
      limit: $limit
      offset: $offset
      where: { verified: { _eq: $verified } }
      order_by: { createdAt: desc }
    ) {
      ...AdminOrg
    }

    pendingCount: organization_aggregate(
      where: { verified: { _eq: false } }
      order_by: { createdAt: desc }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ADMIN_ORGS_SEARCH_ORGS = gql`
  ${ADMIN_ORGS_FRAGMENT}

  query AdminOrgsSearch(
    $search: String!
    $verified: Boolean!
    $limit: Int!
    $offset: Int!
  ) {
    adminOrgs: search_organizations(
      args: { search: $search }
      where: { verified: { _eq: $verified } }
      limit: $limit
      offset: $offset
    ) {
      ...AdminOrg
    }

    pendingCount: search_organizations_aggregate(
      args: { search: $search }
      where: { verified: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ADMIN_DELETE_PENDING_ORG_MUTATION = gql`
  mutation AdminDeletePendingOrg($orgId: String!) {
    delete_organization(
      where: { firestoreId: { _eq: $orgId }, verified: { _eq: false } }
    ) {
      affected_rows
    }
  }
`;

export const ADMIN_MOVE_USER = gql`
  mutation AdminMoveUser($userId: String!, $orgId: String!) {
    update_user_by_pk(
      pk_columns: { firestoreId: $userId }
      _set: { orgId: $orgId }
    ) {
      id
    }

    update_learner_group(
      where: { members: { userId: { _eq: $userId }, isOwner: { _eq: true } } }
      _set: { orgId: $orgId }
    ) {
      affected_rows
    }

    update_user(
      where: {
        learnerGroups: {
          learnerGroup: {
            members: { userId: { _eq: $userId }, isOwner: { _eq: true } }
          }
        }
      }
      _set: { orgId: $orgId }
    ) {
      affected_rows
    }
  }
`;
