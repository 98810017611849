import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { forwardRef } from "@chakra-ui/system";
import { gql } from "@apollo/client";
import {
  InstantSearch,
  Configure,
  useInfiniteHits,
  useSearchBox,
  useCurrentRefinements,
  useRefinementList,
} from "react-instantsearch-hooks-web";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import {
  Box,
  Button,
  Center,
  EmptyState,
  Flex,
  FontAwesomeIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "Shared";
import { PathCard } from "Components/PathCard";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";
import { createContext, pluralize, useQuery } from "Utils";
import { algoliaClient } from "Services/algolia";
import { PathCardFragment } from "@tract/common/dist/graphql";
import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";
import { ViewSource } from "Types/Path";
import { GridPaths } from "Components/GridContainer";
import { FilterKey, FilterModal } from "./FilterModal";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { PathFilter, PATH_SUBJECT_V2 } from "Constants/paths";
import { useHistory, useLocation } from "react-router-dom";

export type AlgoliaSearchPageUiState = {
  paths: {
    query: string;
    refinementList: {
      subjects: [];
      gradeLevels: [];
    };
  };
};

export type AlgoliaSearchPageRouteState = {
  query: string;
  subjects: [];
  gradeLevels: [];
};

gql`
  ${PATH_CARD_FRAGMENT}

  query PathsById(
    $pathIds: [bigint!]!
    $limit: Int = 20
    $offset: Int = 0
    $status: String!
  ) {
    paths: path(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
      where: {
        id: { _in: $pathIds }
        status: { _eq: $status }
        isPublished: { _eq: true }
      }
    ) {
      ...PathCard
    }
  }
`;
export const PATH_SEARCH_QUERY_PARAM = "query";
export const PATH_SUBJECT_FILTER_PARAM = FilterKey.Subjects;

const [Provider, useContext] = createContext<{
  query: {
    orgId?: string;
    limit: number;
    status?: PathStatus;
    visibility?: PathVisibility;
    interestAreas?: string[];
    subjects?: string[];
    skills?: string[];
    gradeLevels?: string[];
    creatorLevels?: string[];
    tags?: string[];
  };
}>({
  strict: true,
});

export const usePathFeedAlgolia = useContext;

const [ProviderControls, useContextControls] = createContext<{
  paths?: PathCardFragment[];
  loading: boolean;
  numberOfHits: number;
  loadMore: () => void;
  page: number;
  hasMore: boolean;
}>({
  strict: true,
});
export const usePathFeedControls = useContextControls;

type PathFeedProviderProps = {
  orgId?: string;
  status?: PathStatus;
  visibility?: PathVisibility;
  interestAreas?: string[];
  subjects?: string[];
  skills?: string[];
  tags?: string[];
  gradeLevels?: string[];
  creatorLevels?: string[];
  _schemaVersion?: number;
  limit?: number;
  isTractOriginal?: boolean;
};

export const PathFeedProviderAlgolia: React.FC<
  PropsWithChildren<PathFeedProviderProps>
> = ({
  orgId,
  limit,
  status,
  visibility,
  interestAreas,
  subjects,
  skills,
  tags,
  gradeLevels,
  creatorLevels,
  _schemaVersion,
  isTractOriginal,
  children,
}) => {
  const query = useMemo(() => {
    return {
      orgId,
      limit: limit || 48,
      status: status || PathStatus.Published,
      visibility: visibility || PathVisibility.Public,
      interestAreas,
      subjects,
      skills,
      tags,
      gradeLevels,
      creatorLevels,
      _schemaVersion,
      isTractOriginal,
    };
  }, [
    orgId,
    limit,
    status,
    visibility,
    interestAreas,
    subjects,
    skills,
    tags,
    gradeLevels,
    creatorLevels,
    _schemaVersion,
    isTractOriginal,
  ]);

  const [filter, setFilter] = useState("");
  useEffect(() => {
    const interestAreaQuery = query.interestAreas?.length
      ? ` AND (${query.interestAreas
          .map((interestArea) => `interestArea:"${interestArea}"`)
          .join(" OR ")})`
      : "";
    const tagQuery = query.tags?.length
      ? ` AND (${query.tags.map((tag) => `tags:"${tag}"`).join(" OR ")})`
      : "";
    const subjectQuery = query.subjects?.length
      ? ` AND (${query.subjects
          .map((subject) => `subjects:"${subject}"`)
          .join(" OR ")})`
      : "";
    const skillQuery = query.skills?.length
      ? ` AND (${query.skills
          .map((skill) => `skills:"${skill}"`)
          .join(" OR ")})`
      : "";
    const gradeLevelQuery = query.gradeLevels?.length
      ? ` AND (${query.gradeLevels
          .map((gradeLevel) => `gradeLevels:"${gradeLevel}"`)
          .join(" OR ")})`
      : "";
    const creatorLevelQuery = query.creatorLevels?.length
      ? ` AND (${query.creatorLevels
          .map((creatorLevels) => `user.creatorLevel:"${creatorLevels}"`)
          .join(" OR ")})`
      : "";
    const tractOriginalsQuery =
      query.isTractOriginal !== undefined
        ? ` AND ${
            query.isTractOriginal
              ? "isTractOriginal:true"
              : "NOT isTractOriginal:true"
          }`
        : "";
    const filterString = `visibility:${
      query.visibility ? query.visibility : PathVisibility.Public
    } AND status:${
      query.status ? query.status : PathStatus.Published
    }${interestAreaQuery}${tagQuery}${subjectQuery}${skillQuery}${gradeLevelQuery}${creatorLevelQuery}${tractOriginalsQuery}`;
    setFilter(filterString);
  }, [query]);

  if (!filter) {
    return null;
  }

  return (
    <InstantSearch searchClient={algoliaClient} indexName="paths">
      <Configure filters={filter} hitsPerPage={query.limit} page={0} />
      <UrlParamListener />
      <Provider value={{ query }}>
        <PathFeedProviderAlgoliaControls>
          {children}
        </PathFeedProviderAlgoliaControls>
      </Provider>
    </InstantSearch>
  );
};

const PathFeedProviderAlgoliaControls: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { results, hits, showMore, isLastPage } =
    useInfiniteHits<PathCardFragment>();
  const { isSearchStalled } = useSearchBox();

  return (
    <ProviderControls
      value={{
        paths: hits,
        numberOfHits: results?.nbHits || 0,
        loadMore: showMore,
        loading: isSearchStalled || !!results?.__isArtificial,
        page: results?.page || 0,
        hasMore: !isLastPage,
      }}
    >
      {children}
    </ProviderControls>
  );
};

type Props = {
  source: ViewSource;
  isEmptyCallback?: (pathsAreEmpty: boolean) => void;
};

export const PathFeed: React.FC<Props> = ({ source, isEmptyCallback }) => {
  const { paths, loading, page } = usePathFeedControls();
  const loadingFirstPage = page === 0 && loading;

  isEmptyCallback && isEmptyCallback(!loadingFirstPage && paths?.length === 0);

  return (
    <>
      {!!paths?.length &&
        !loadingFirstPage &&
        paths.map((path) => (
          <PathCard key={path.id} path={path} source={source} />
        ))}
    </>
  );
};

export const ExploreFilters: React.FC<{
  showSubjects?: boolean;
  showGrades?: boolean;
}> = ({ showSubjects = true, showGrades = true }) => {
  const { track } = useAnalytics();
  const {
    isOpen: isOpenSubjects,
    onOpen: onOpenSubjects,
    onClose: onCloseSubjects,
  } = useDisclosure();
  const {
    isOpen: isOpenGradeLevels,
    onOpen: onOpenGradeLevels,
    onClose: onCloseGradeLevels,
  } = useDisclosure();

  const clickFilter = (filterKey: FilterKey) => {
    track(ANALYTICS_EVENTS.EXPLORE_FILTER_CLICKED, {
      exploreFilterName: PathFilter[filterKey],
    });
    if (filterKey === FilterKey.Subjects) {
      onOpenSubjects();
    } else {
      onOpenGradeLevels();
    }
  };
  return (
    <>
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              variant="ghost"
              leftIcon={<FontAwesomeIcon icon={regular("sliders")} />}
              onClick={() => {
                if (!showSubjects || !showGrades)
                  clickFilter(FilterKey.GradelLevels);
              }}
            >
              Filter
            </MenuButton>
            {showSubjects && showGrades && (
              <MenuList w="16rem">
                <MenuItem onClick={() => clickFilter(FilterKey.Subjects)}>
                  <Flex justifyContent="space-between" w="full">
                    <Text>Subjects</Text>
                    <FontAwesomeIcon icon={regular("angle-right")} />
                  </Flex>
                </MenuItem>
                <MenuItem onClick={() => clickFilter(FilterKey.GradelLevels)}>
                  <Flex justifyContent="space-between" w="full">
                    <Text>Grade Levels</Text>
                    <FontAwesomeIcon icon={regular("angle-right")} />
                  </Flex>
                </MenuItem>
              </MenuList>
            )}
          </>
        )}
      </Menu>
      <FilterModal
        filterKey={FilterKey.Subjects}
        isOpen={isOpenSubjects}
        onClose={onCloseSubjects}
      />
      <FilterModal
        filterKey={FilterKey.GradelLevels}
        isOpen={isOpenGradeLevels}
        onClose={onCloseGradeLevels}
      />
    </>
  );
};

type RefinementListItem = {
  attribute: string;
  value: string | number;
  label: string;
  count?: number;
};
export const CurrentRefinements = forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const currentRefinements = useCurrentRefinements();
  // const { query: searchQuery } = useSearchBox();
  const searchQuery = query.get("query");
  const refinements = currentRefinements?.items
    ?.map((item) => item.refinements)
    .flat();

  const clearSearch = () => {
    query.delete("query");
    if (!!query.toString()) {
      history.push(`${location.pathname}?${query.toString()}`);
    } else {
      history.push(location.pathname);
    }
  };

  const clearSingleRefinement = (refinement: RefinementListItem) => {
    const currentFilter = query.get(refinement.attribute);
    if (!currentFilter?.length) return;
    const currentFilters = currentFilter.split(",");
    const index = currentFilters.indexOf(refinement.value as string);
    if (index > -1) {
      currentFilters.splice(index, 1);
    }
    if (query.has(refinement.attribute)) {
      query.delete(refinement.attribute);
    }
    if (!!currentFilters.length) {
      query.append(refinement.attribute, currentFilters.join(","));
    }
    if (!!query.toString().length) {
      history.push(`${location.pathname}?${query.toString()}`);
    } else {
      history.push(location.pathname);
    }
  };

  const clearAll = () => {
    history.push(location.pathname);
  };

  if (!refinements.length && !searchQuery?.length) return null;

  return (
    <Box {...props}>
      {!!searchQuery?.length && (
        <Button
          mr={2}
          mb={{ base: 2, md: 0 }}
          key="search"
          variant="outline"
          onClick={clearSearch}
          rightIcon={<FontAwesomeIcon icon={regular("close")} size={5} />}
        >
          "{searchQuery}"
        </Button>
      )}
      {refinements.map((refinement) => {
        let displayName = refinement.label;
        if (refinement.attribute === FilterKey.GradelLevels) {
          displayName = `Grade ${refinement.label}`;
        }
        if (refinement.attribute === FilterKey.Subjects) {
          displayName =
            PATH_SUBJECT_V2.filter(
              (subject) => subject.label === refinement.label
            )[0]?.standardizedShortLabel || refinement.label;
        }
        return (
          <Button
            mr={2}
            mb={{ base: 2, md: 0 }}
            key={refinement.label}
            variant="outline"
            onClick={() => clearSingleRefinement(refinement)}
            rightIcon={<FontAwesomeIcon icon={regular("close")} size={5} />}
          >
            {displayName}
          </Button>
        );
      })}
      <Button variant="outline" onClick={clearAll} mb={{ base: 2, md: 0 }}>
        Clear
      </Button>
    </Box>
  );
});

export const PathFeedInfinite = forwardRef<{ pageLimit?: number }, "div">(
  ({ pageLimit, ...props }, ref) => {
    const { loading, page, loadMore, hasMore } = usePathFeedControls();
    const [isPaused, setIsPaused] = useState(false);
    const limit = pageLimit || 4;
    const skeletonCount = useBreakpointValue({
      base: 2,
      sm: 4,
      md: 6,
      xl: 8,
      "2xl": 10,
      "3xl": 12,
    });
    useEffect(() => {
      if (page === 0) {
        setIsPaused(false);
      } else if (page % limit === 0) {
        setIsPaused(true);
      }
    }, [page, setIsPaused, limit]);

    const handleLoadMore = () => {
      if (!isPaused) {
        return loadMore();
      }
    };

    const [sentryRef] = useInfiniteScroll({
      loading: loading || false,
      hasNextPage: hasMore,
      onLoadMore: handleLoadMore,
      // `rootMargin` is passed to `IntersectionObserver`.
      // We can use it to trigger 'onLoadMore' when the sentry comes near to become
      // visible, instead of becoming fully visible on the screen.
      rootMargin: "0px 0px 80% 0px",
    });

    return (
      <Center {...props} ref={ref} width="full" mt={page > 0 ? 4 : undefined}>
        {(loading || hasMore) && !isPaused && !!skeletonCount && (
          <GridPaths ref={sentryRef} width="full" height="full">
            {Array.from({ length: skeletonCount }).map((_, i) => (
              <PathCardSkeleton key={i} />
            ))}
          </GridPaths>
        )}
        {isPaused && hasMore && (
          <Button
            variant="ghost"
            size="lg"
            colorScheme="brandFull"
            mt={10}
            onClick={() => setIsPaused(false)}
          >
            Load More
          </Button>
        )}
      </Center>
    );
  }
);

export const PathFeedLoadMore = forwardRef((props, ref) => {
  const { hasMore, loadMore, loading } = usePathFeedControls();
  return (
    <Center {...props} ref={ref}>
      {hasMore && (
        <Button
          variant="ghost"
          size="lg"
          colorScheme="brandFull"
          onClick={loadMore}
          isLoading={loading || false}
        >
          Load More
        </Button>
      )}
    </Center>
  );
});

type EmptyProps = {};

export const PathFeedEmptyState: React.FC<EmptyProps> = () => {
  const { numberOfHits, loading } = usePathFeedControls();
  if (!!numberOfHits || loading) return null;

  return (
    <Center>
      <EmptyState width="full" headline="No results found" />
    </Center>
  );
};

export const NumberOfHits = forwardRef((props, ref) => {
  const { results } = useInfiniteHits();
  if (!results?.nbHits) return null;
  return (
    <Text {...props} ref={ref}>
      {pluralize(results.nbHits, "result", undefined, undefined, true)}
    </Text>
  );
});

export const UrlParamListener: React.FC<EmptyProps> = () => {
  const query = useQuery();
  const currentRefinements = useCurrentRefinements();

  //Search
  const { refine: refineSearch, clear } = useSearchBox();
  const urlSearchFilter = query.get("query");
  useEffect(() => {
    if (!!urlSearchFilter?.length) {
      refineSearch(urlSearchFilter);
    } else {
      clear();
    }
  }, [urlSearchFilter, refineSearch, clear]);

  //Subjects
  const currentSubjects = currentRefinements.items.filter(
    (item) => item.attribute === FilterKey.Subjects
  );

  const { refine: refineSubject } = useRefinementList({
    attribute: FilterKey.Subjects,
  });
  const urlSubjectFilter = query.get(FilterKey.Subjects);
  useEffect(() => {
    const currrentSubjectRefinements = currentSubjects.length
      ? currentSubjects[0].refinements
      : [];
    const urlSubjectFilters = urlSubjectFilter?.split(",");

    //Toggle on url filters missing from current
    urlSubjectFilters?.forEach((newSubject) => {
      if (
        !currrentSubjectRefinements.length ||
        !currrentSubjectRefinements.filter(
          (currentSubject) => currentSubject.value === newSubject
        ).length
      ) {
        refineSubject(newSubject);
      }
    });

    //Toggle off current filters missing from url
    currrentSubjectRefinements.forEach((currentSubject) => {
      const value = currentSubject.value as string;
      if (!urlSubjectFilters?.includes(value)) {
        refineSubject(value);
      }
    });
  }, [urlSubjectFilter, refineSubject, currentSubjects]);

  //Grade Levels
  const currentGradeLevels = currentRefinements.items.filter(
    (item) => item.attribute === FilterKey.GradelLevels
  );
  const { refine: refineGradeLevel } = useRefinementList({
    attribute: FilterKey.GradelLevels,
  });
  const urlGradeLevelFilter = query.get(FilterKey.GradelLevels);
  useEffect(() => {
    const urlGradeLevelFilters = urlGradeLevelFilter?.split(",");
    const currrentGradeLevelRefinements = currentGradeLevels.length
      ? currentGradeLevels[0].refinements
      : [];

    //Toggle on url filters missing from current
    urlGradeLevelFilters?.forEach((newGradeLevel) => {
      if (
        !currrentGradeLevelRefinements.length ||
        !currrentGradeLevelRefinements.filter(
          (currentGradeLevel) => currentGradeLevel.value === newGradeLevel
        ).length
      ) {
        refineGradeLevel(newGradeLevel);
      }
    });

    //Toggle off current filters missing from url
    currrentGradeLevelRefinements.forEach((currentGradeLevel) => {
      const value = currentGradeLevel.value as string;
      if (!urlGradeLevelFilters?.includes(value)) {
        refineGradeLevel(value);
      }
    });
  }, [urlGradeLevelFilter, refineGradeLevel, currentGradeLevels]);

  return null;
};
