import { SetStateAction, useState } from "react";
import { Form, Field, FieldProps, useFormikContext } from "formik";
import { GetPathWithNodesQuery } from "@tract/common/dist/graphql";

import {
  VStack,
  Text,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  ButtonSolid,
  Textarea,
  Grid,
  GridItem,
  AspectRatio,
  useRadioGroup,
  Box,
} from "Shared";

import { UserFileResult } from "Services/useUserFileUpload";
import { UserFile } from "Utils/UserFile";
import { NodePublishFormProps, TriggerType } from ".";
import { PathCoverUploaderV2 } from "../PathCoverUploaderV2";
import { StepBox } from "./StepBox";
import { RadioCard } from "Components/RadioCard";
import { UseRadioGroupReturn } from "@chakra-ui/react";

export const StepDetails: React.FC<{
  path: GetPathWithNodesQuery["path"];
  triggerType: TriggerType;
  onChangeStep: (step: number) => void;
  onSaveStart: () => void;
  onSaveComplete: () => void;
  coverResult?: UserFileResult | null;
  setCoverResult: React.Dispatch<
    SetStateAction<UserFileResult | null | undefined>
  >;
  disabled: boolean;
}> = ({ path, coverResult, setCoverResult, onChangeStep, disabled }) => {
  const [coverUrl, setCoverUrl] = useState(
    new UserFile(path?.coverFile).getTransformedUrl("cover_thumb")
  );
  const isCoverFromVideo = !!path?.nodes
    .map((n) => n.videoFile?.id)
    .filter((fileId) => !!fileId)
    .includes(path.coverFile?.id);

  const { handleSubmit, values, setFieldValue } =
    useFormikContext<NodePublishFormProps>();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "coverFileId",
    value: values.coverFileId,
    onChange: (videoFileId: string) =>
      setFieldValue("coverFileId", videoFileId),
  });

  const selectedFileIdForUploader =
    coverResult?.fileId || (!isCoverFromVideo ? path?.coverFile?.id : "");

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
      onSubmit={handleSubmit}
      autoComplete="off"
      noValidate
    >
      <ModalBody>
        <StepBox>
          <Text fontSize="2xl" fontWeight="bold" mb={6}>
            Add details about your path
          </Text>
          <VStack w="full" spacing={6}>
            <Field name="title">
              {({ field }: FieldProps) => (
                <FormControl isRequired>
                  <FormLabel>Title</FormLabel>
                  <Input
                    placeholder="Add a catchy title"
                    size="lg"
                    borderRadius="lg"
                    isDisabled={disabled}
                    {...field}
                  />
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field }: FieldProps) => (
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    placeholder="Add description"
                    borderRadius="lg"
                    isDisabled={disabled}
                    {...field}
                  />
                </FormControl>
              )}
            </Field>
            <FormControl mb={0} isRequired>
              <FormLabel>Thumbnail</FormLabel>
              <Grid
                gap={3}
                w="full"
                templateColumns="repeat(3, 1fr)"
                {...getRootProps()}
              >
                {path?.nodes
                  .filter(
                    (n) =>
                      !!n.videoFile &&
                      !!new UserFile(n.videoFile).getTransformedUrl(
                        "video_cover_sm"
                      )
                  )
                  .map((node) => (
                    <GridItem key={node.id}>
                      <ThumnbnailRadioCard
                        thumbnailSrc={new UserFile(
                          node.videoFile
                        ).getTransformedUrl("video_cover_sm")}
                        {...getRadioProps({
                          value: node.videoFile?.id,
                          checked: node.videoFile?.id === values.coverFileId,
                        })}
                      >
                        <Box />
                      </ThumnbnailRadioCard>
                    </GridItem>
                  ))}
                <GridItem>
                  <Field name="coverFileId">
                    {({ field, form }: FieldProps) => (
                      <PathCoverUploaderV2
                        accept="image/jpeg,image/png"
                        inputId={field.name}
                        thumbnailRadioProps={getRadioProps({
                          value: selectedFileIdForUploader,
                          checked:
                            selectedFileIdForUploader === values.coverFileId,
                        })}
                        coverUrl={
                          coverResult?.fileUrl ||
                          (!isCoverFromVideo ? coverUrl : "")
                        }
                        maxFileSize={1024 * 1024 * 5}
                        onCoverUpdate={async (file) => {
                          if (file) {
                            form.setFieldValue("coverFileId", file.fileId);
                          } else if (!isCoverFromVideo) {
                            setCoverUrl("");
                            form.setFieldValue("coverFileId", "");
                          }
                          setCoverResult(file);
                        }}
                        onValidateFile={() => null}
                        isRequired
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </GridItem>
              </Grid>
            </FormControl>
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter>
        <ButtonSolid minW="10rem" size="lg" onClick={() => onChangeStep(2)}>
          Next
        </ButtonSolid>
      </ModalFooter>
    </Form>
  );
};

export const ThumnbnailRadioCard: React.FC<
  { thumbnailSrc: string } & ReturnType<UseRadioGroupReturn["getRadioProps"]>
> = ({ thumbnailSrc, children, ...props }) => (
  <RadioCard
    as={AspectRatio}
    ratio={16 / 9}
    overflow="hidden"
    borderRadius="lg"
    fontWeight="unset"
    textTransform="unset"
    bgImage={thumbnailSrc}
    bgSize="cover"
    bgPosition="center"
    _checked={undefined}
    _active={undefined}
    {...props}
  >
    <Box
      borderRadius="lg"
      boxShadow={
        props.checked
          ? "0 0 0 3px #171923 inset, 0 0 0 4px #ffffff inset"
          : undefined
      }
      style={{
        alignItems: "start",
        justifyContent: "flex-end",
      }}
    >
      {children}
    </Box>
  </RadioCard>
);
