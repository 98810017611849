import React from "react";
import { FormikProps, withFormik } from "formik";
import Autosize from "react-textarea-autosize";
import * as yup from "yup";

import { Box, Button, Textarea, HStack } from "Shared";

export type Values = { text: string };
const COMMENT_CHAR_LIMIT = 500;

const Form: React.FC<FormikProps<Values>> = ({
  values,
  status,
  dirty,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Textarea
          as={Autosize}
          name="text"
          value={values.text}
          maxLength={COMMENT_CHAR_LIMIT}
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          maxRows={8}
          resize="none"
          onKeyPress={(kp) => {
            // Support CTRL + Enter
            if (kp.ctrlKey && (kp.key === "Enter" || kp.key === "\n")) {
              handleSubmit();
            }
          }}
        />
      </Box>
      <HStack spacing={2} justifyContent="flex-end">
        <Button
          type="reset"
          variant="outline"
          onClick={status.handleCancel}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="solid"
          colorScheme="brandFull"
          isLoading={isSubmitting}
          disabled={!dirty}
        >
          Save
        </Button>
      </HStack>
    </form>
  );
};

const schema: yup.SchemaOf<Values> = yup.object({
  text: yup.string().max(1000).trim().required(),
});

type Props = {
  text: string;
  submitHandler: (values: Values) => Promise<void>;
  errorHandler: (error: any) => void;
  parentPath?: string;
  onCancel: () => void;
};

export const CommentEditForm = withFormik<Props, Values>({
  displayName: "NodeCommentEditForm",
  mapPropsToValues: (props) => ({
    text: props.text,
  }),
  mapPropsToStatus: (props) => ({
    handleCancel: props.onCancel,
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const castedValues = schema.cast(values) || values;

    if (typeof castedValues.text !== "string") return;

    setSubmitting(true);
    try {
      await props.submitHandler({
        text: castedValues.text,
      });
      resetForm();
    } catch (err) {
      props.errorHandler(err);
    } finally {
      setSubmitting(false);
    }
  },
})(Form);
