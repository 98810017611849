import { FC } from "react";
import { useQuery } from "urql";
import { Box, Text } from "Shared";
import { ClassroomGrid } from "Components/ClassroomGrid";
import { pluralize } from "Utils";
import { useSession } from "Services/session";
import { CLASSROOMS_BY_LEARNER_ID_QUERY } from "./graphql";
import {
  ClassroomsByLearnerIdQueryResult,
  ClassroomsByLearnerIdQueryVariables,
} from "@tract/common/dist/graphql";
import { PageApp } from "Components/PageApp";

export const LearnerHomeClasses: FC = () => {
  const { currentUser } = useSession();
  const [{ data, fetching: loading }] = useQuery<
    ClassroomsByLearnerIdQueryResult["data"],
    ClassroomsByLearnerIdQueryVariables
  >({
    query: CLASSROOMS_BY_LEARNER_ID_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      userId: currentUser.id,
    },
  });

  const classrooms = data?.learnerGroups || [];

  return (
    <PageApp>
      {loading ? (
        <Box h="4.875rem" />
      ) : (
        <Text fontSize="xl" fontWeight="bold" my={6}>
          {pluralize(classrooms.length, "Class", "Classes")}
        </Text>
      )}
      <ClassroomGrid loading={loading} classrooms={classrooms} />
    </PageApp>
  );
};
