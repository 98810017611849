import React from "react";
import { StackProps, forwardRef } from "@chakra-ui/react";

import { Box, ButtonLink, Stack } from "Shared";

import { UserListModal } from "Components/UserListModal";

import { UserSocialCountsFragment } from "@tract/common/dist/graphql";

type Props = {
  socialCounts?: UserSocialCountsFragment;
};

export const SocialCounts: React.FC<Props | StackProps> = forwardRef(
  ({ socialCounts, ...props }, ref) => {
    const followersCount = socialCounts?.followers_aggregate?.aggregate?.count;
    const followingCount = socialCounts?.following_aggregate?.aggregate?.count;

    return (
      <Stack direction="row" spacing={4} {...props} ref={ref}>
        <UserListModal
          header="Followers"
          listType="followers"
          profileId={socialCounts.id}
          loadingStateCount={followersCount}
        >
          {/** @ts-ignore */}
          {({ open }) => (
            <ButtonLink
              pointerEvents={!!followersCount ? "" : "none"}
              onClick={open}
              color="gray.900"
              _hover={{ textDecoration: "none", color: "brand" }}
            >
              {`${followersCount?.toLocaleString() || 0}`}{" "}
              <Box as="span" fontWeight="normal" ml={2}>
                Followers
              </Box>
            </ButtonLink>
          )}
        </UserListModal>
        <UserListModal
          header="Following"
          listType="following"
          profileId={socialCounts.id}
          loadingStateCount={followingCount}
        >
          {/** @ts-ignore */}
          {({ open }) => (
            <ButtonLink
              pointerEvents={!!followingCount ? "" : "none"}
              onClick={open}
              color="gray.900"
              _hover={{ textDecoration: "none", color: "brand" }}
            >
              {`${followingCount?.toLocaleString() || 0}`}{" "}
              <Box as="span" fontWeight="normal" ml={2}>
                Following
              </Box>
            </ButtonLink>
          )}
        </UserListModal>
      </Stack>
    );
  }
);
