import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  AddPathToPathFeedMutationResult,
  AddPathToPathFeedMutationVariables,
  GetPathFeedsByPathIdQueryResult,
  GetPathFeedsByPathIdQueryVariables,
} from "@tract/common/dist/graphql";

import { GET_PATH_FEEDS_BY_PATH_ID } from "graphql/path-feeds";

export const insert_path_feed_one: UpdateResolver<
  AddPathToPathFeedMutationResult["data"]
> = (result, args, cache, info) => {
  const variables = args.object as AddPathToPathFeedMutationVariables;
  cache.updateQuery<
    GetPathFeedsByPathIdQueryResult["data"],
    GetPathFeedsByPathIdQueryVariables
  >(
    {
      query: GET_PATH_FEEDS_BY_PATH_ID,
      variables: {
        pathId: variables.pathId,
      },
    },
    (data) => {
      if (result?.insert_path_feed_one)
        data?.path_feed.push(result.insert_path_feed_one);
      return data;
    }
  );
};
