import { ButtonProps } from "@chakra-ui/react";
import { Flex, IconCheck, Text, Button } from "Shared";

export const StepperItem: React.FC<
  ButtonProps & {
    step: number;
    label: string;
    isActive?: boolean;
    isCompleted?: boolean;
  }
> = ({ step, label, isActive = false, isCompleted = false, ...props }) => {
  return (
    <Button
      borderRadius="none"
      variant="ghost"
      size="lg"
      textAlign="start"
      display="flex"
      width="full"
      justifyContent="start"
      _focusVisible={{ boxShadow: "outlineInset" }}
      leftIcon={
        isCompleted ? (
          <Flex
            borderRadius="full"
            width="24px"
            height="24px"
            fontSize="sm"
            alignItems="center"
            justifyContent="center"
            bgColor="green.500"
            marginRight={2}
          >
            <IconCheck width="16px" color="white" strokeWidth="3px" />
          </Flex>
        ) : (
          <Flex
            borderRadius="full"
            width="24px"
            height="24px"
            fontSize="sm"
            alignItems="center"
            justifyContent="center"
            bgColor={isActive ? "brand" : "gray.200"}
            color={isActive ? "white" : "gray.400"}
            marginRight={2}
          >
            {step}
          </Flex>
        )
      }
      {...props}
    >
      <Text
        as="span"
        color={isActive ? "gray.900" : "gray.600"}
        fontWeight={isActive ? "bold" : "normal"}
        display={{ base: "none", md: "block" }}
      >
        {label}
      </Text>
    </Button>
  );
};
