import React, { PropsWithChildren, ReactNode } from "react";
import { BoxProps, forwardRef, useBreakpointValue } from "@chakra-ui/react";

import { Box, Flex, Text } from "Shared";

interface Props {
  title?: string;
  renderBreadcrumb?: ReactNode;
  renderTabs?: ReactNode;
  renderTitle?: ReactNode;
  renderActions?: ReactNode;
  renderMobileActions?: ReactNode;
  showBreadcrumb?: boolean;
}

export const PageHeader = forwardRef<
  PropsWithChildren<Props & BoxProps>,
  "header"
>(
  (
    {
      title,
      renderBreadcrumb,
      renderTabs,
      renderTitle,
      renderActions,
      renderMobileActions,
      showBreadcrumb,
      ...props
    },
    ref
  ) => {
    const showMobileActions = useBreakpointValue({
      base: true,
      lg: false,
    });
    const tabSize = useBreakpointValue({
      base: "md",
      md: "lg",
    });

    return (
      <Box as="header" my={{ base: 4, md: 6, xl: 10 }} ref={ref} {...props}>
        {showBreadcrumb && renderBreadcrumb ? (
          <Flex mb={2} fontSize={{ base: "sm", md: "md" }}>
            {renderBreadcrumb}
          </Flex>
        ) : null}
        <Flex align="center" justify="space-between">
          {renderTitle ? (
            renderTitle
          ) : (
            <Text
              fontSize={{ base: "3xl", md: "4xl", xl: "5xl" }}
              fontWeight="bold"
              color="gray.900"
            >
              {title}
            </Text>
          )}
          {!showMobileActions && renderActions ? renderActions : null}
          {showMobileActions && renderMobileActions
            ? renderMobileActions
            : null}
        </Flex>
        {renderTabs && (
          <Box mt={2}>
            {React.Children.map(renderTabs, (child) => {
              // checking isValidElement is the safe way and avoids a typescript error too
              if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                  size: tabSize,
                });
              }
            })}
          </Box>
        )}
      </Box>
    );
  }
);
