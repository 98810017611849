import { fabric } from "fabric";

export enum DrawerPanel {
  Effects,
  TextOptions,
  BackdropOptions,
  Settings,
}

export const findCanvasObjectByName = (
  canvasCtx: fabric.Canvas,
  name: string
) => {
  const canvasObjects = canvasCtx.getObjects();
  return canvasObjects.find((obj) => {
    return name === obj.name;
  });
};
