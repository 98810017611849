import React, { FC, PropsWithChildren } from "react";

import { Flex, HStack, Text } from "Shared";

export const PrizeStat: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children,
}) => {
  return (
    <Flex direction="column" align="start">
      <Text fontSize="md" color="gray.600" fontWeight="bold">
        {label}
      </Text>
      <HStack>{children}</HStack>
    </Flex>
  );
};
