import React from "react";
import { forwardRef, StackProps, TextProps } from "@chakra-ui/react";

import { HStack, Text, Badge } from "Shared";
import { CoinIcon, CoinIconProps } from "Components/CoinIcon";

type Props = {
  value: number;
  loading?: boolean;
  multiplier?: number;
  baseValueOnly?: boolean;
  showPlus?: boolean;
  coinProps?: CoinIconProps;
  hasFreeCoins?: boolean;
  textProps?: TextProps;
} & StackProps &
  TextProps;

export const CoinValue = forwardRef(
  (
    {
      value,
      loading = false,
      multiplier = 1,
      showPlus = false,
      textProps,
      coinProps,
      hasFreeCoins,
      ...props
    }: Props,
    ref
  ) => {
    const hasMultiplier = multiplier > 1;
    const calculatedXP = hasMultiplier ? value * multiplier : value;

    let spacing = 1;
    let coinSize = 4;

    switch (props.size) {
      case "md":
        spacing = 1;
        break;
      case "lg":
        spacing = 1.5;
        coinSize = 5;
        break;
      case "xl":
        spacing = 2;
        coinSize = 6;
        break;
      case "2xl":
        spacing = 3;
        coinSize = 8;
        break;
      case "3xl":
        spacing = 3;
        coinSize = 10;
        break;
    }

    return (
      <HStack
        as="span"
        spacing={spacing}
        ref={ref}
        fontSize={props.size}
        {...props}
      >
        <CoinIcon size={coinSize} {...coinProps} />
        {hasFreeCoins ? (
          <Badge
            colorScheme="red"
            variant="solid"
            fontSize="xs"
            fontWeight="bold"
            px="6px"
          >
            FREE
          </Badge>
        ) : (
          <>
            <Text
              as={hasMultiplier ? "s" : "span"}
              fontWeight="bold"
              {...textProps}
            >
              {showPlus && "+"}
              {loading ? "-" : value?.toLocaleString()}
            </Text>

            {hasMultiplier && (
              <Text as="span" fontWeight="bold" {...textProps}>
                {showPlus && "+"}
                {loading ? "-" : calculatedXP.toLocaleString()}
              </Text>
            )}
          </>
        )}
      </HStack>
    );
  }
);
