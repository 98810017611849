type TractNotification = {
  [key: string]: any;
  type: "actor" | "general";
  actor: string;
  entity: string;
  entityId: string;
  verb: string;
  description: string;
  targetPath: string;
  to?: string[];
  meta?: {
    schemaVersion: number;
    [key: string]: any;
  };
};

export const createNotification = (activity: TractNotification) => {
  const verb = `${activity.verb}:${activity.entity}`;
  const foreignId = `${verb}:${activity.entityId}`;

  return {
    actor: activity.actor,
    object: activity.entity,
    verb,
    type: activity.type,
    description: activity.description,
    foreign_id: foreignId,
    target: activity.targetPath,
    time: new Date().toISOString(),
  };
};
