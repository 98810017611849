import { Field, FieldProps, Form, Formik } from "formik";
import { FC, useState } from "react";
import { useMutation } from "urql";

import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "Shared";

import { FormFieldUsername } from ".";
import { StudentSchema, USERNAME_MAX_LENGTH } from "./schema";
import { SIGN_UP_STUDENT_MUTATION } from "../graphql";

import { captureException, ERROR_TAGS } from "Services/errors";
import { GroupKey, useAnalytics } from "Services/analytics";
import { firebaseClient } from "Services/firebase";

import {
  Educator_Code,
  Enums_Integration_Provider_Enum,
  SignUpStudentMutation,
  SignUpStudentMutationVariables,
} from "@tract/common/dist/graphql";
import { SSOButton } from "Components/SSO/SSOButton";
import { SSOErrors } from "Components/SSO/SSOErrors";
import { getDisplayName } from "Types/User";

type StudentFormInput = {
  username: string;
  password: string;
};

interface SignUpFormProps {
  code: string;
  loggedIn: boolean;
  codeData: Educator_Code;
  onSuccess: () => void;
}

export const SignUpWithClassCode: FC<SignUpFormProps> = ({
  code,
  loggedIn,
  codeData,
  onSuccess,
}) => {
  const { identify, group } = useAnalytics();
  const [onboarding, setOnboarding] = useState(false);
  const [onboardingError, setOnboardingError] = useState("");

  const [, signUp] = useMutation<
    SignUpStudentMutation,
    SignUpStudentMutationVariables
  >(SIGN_UP_STUDENT_MUTATION);

  async function onSubmit({ username, password }: StudentFormInput) {
    setOnboarding(true);
    setOnboardingError("");

    try {
      const { data } = await signUp({ username, password, code });

      if (!data?.api_sign_up?.authToken) {
        return;
      }

      const { user } = await firebaseClient
        .auth()
        .signInWithCustomToken(data.api_sign_up.authToken);

      if (user) {
        identify(user.uid, {
          email: user.email,
          orgId: codeData.learnerGroup?.orgId,
        });

        if (codeData.learnerGroup?.id) {
          group(GroupKey.LearnerGroup, codeData.learnerGroup.id, {
            orgId: codeData.learnerGroup.orgId,
          });
        }
      }

      onSuccess();
    } catch (err: any) {
      captureException(err, { tags: { feature: ERROR_TAGS.KID_ONBOARDING } });
      setOnboardingError(err.message);
    } finally {
      setOnboarding(false);
    }
  }

  const teacherUser = codeData.learnerGroup?.members?.[0]?.user;
  const teacherDisplayName = getDisplayName(teacherUser);

  return (
    <>
      <Box mb={8}>
        <Text fontSize="3xl" fontWeight="bold">
          Join {codeData?.learnerGroup?.name}
        </Text>
        {teacherUser && (
          <HStack justifyContent="center" mt={2} spacing={3}>
            <Avatar
              src={teacherUser.avatar || ""}
              name={teacherDisplayName || ""}
            />
            <Text fontSize="lg" fontWeight="bold" color="gray.600">
              {teacherDisplayName}
            </Text>
          </HStack>
        )}
      </Box>
      <VStack spacing={3} mb={6}>
        {(codeData.learnerGroup?.source
          ? [codeData.learnerGroup.source]
          : [
              Enums_Integration_Provider_Enum.Google,
              Enums_Integration_Provider_Enum.Clever,
            ]
        ).map((provider) => (
          <SSOButton
            key={provider}
            signUpRole="Student"
            educatorCode={code}
            provider={provider}
          />
        ))}
        <SSOErrors />
      </VStack>
      {!codeData.learnerGroup?.source && (
        <>
          <Box mt={10} mb={6}>
            <Text fontSize="2xl" fontWeight="bold">
              Or create your account:
            </Text>
          </Box>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={StudentSchema}
            validateOnChange={false}
            onSubmit={(values) => onSubmit(values)}
          >
            <Form noValidate>
              <FormFieldUsername
                id="username"
                variant="filled"
                placeholder="Username"
                helpText="Visible to all Tract members. Don't use your real name!"
                autoComplete="off"
                size="lg"
                isDisabled={onboarding}
                maxLength={USERNAME_MAX_LENGTH}
              />
              <Field name="password">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isRequired
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={8}
                  >
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      {...field}
                      variant="filled"
                      id="password"
                      type="password"
                      size="lg"
                      placeholder="Password"
                      autoComplete="new-password"
                      isDisabled={onboarding}
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                    <FormHelperText>Keep this in a safe place</FormHelperText>
                  </FormControl>
                )}
              </Field>
              {onboardingError && (
                <Alert status="error" textAlign="left" mb={6}>
                  {onboardingError}
                </Alert>
              )}
              <Button
                size="lg"
                width="100%"
                type="submit"
                disabled={onboarding || loggedIn}
                isLoading={onboarding}
                loadingText={"We're giving your account super powers..."}
                colorScheme="brandFull"
              >
                {loggedIn ? "Just a second..." : "Create Account"}
              </Button>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
