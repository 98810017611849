import { FC, useRef } from "react";

import {
  Box,
  Flex,
  Tabs,
  Tab,
  TabList,
  useToast,
  Center,
  useDisclosure,
  ModalCloseButton,
  HStack,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Text,
  useRouteTabs,
  Portal,
} from "Shared";

import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { linkState, usePathV4Context } from "./LayoutCreateV4";
import { VideoUploader } from "Components/VideoUploader";
import { VideoType } from "@tract/common/dist/types/api/videos";
import { PathActivity } from "./PathActivity";
import { PathNodeDetailsForm } from "./PathNodeDetailsForm";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import to from "@tract/common/dist/utils/async";
import { del, post } from "Services/api";
import { UserFile } from "Utils/UserFile";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import { Result, VideoEditor } from "Components/VideoEditor";

export const EditPathNode: FC = () => {
  const {
    path,
    activeNode,
    setUnsavedChanges,
    setDetailsSaved,
    addNodeVideoFileMutation,
    deleteNodeVideoFileMutation,
    videoFileUploadingOrDeleting,
    setVideoFileUploadingOrDeleting,
  } = usePathV4Context();
  const uploaderRef = useRef<{ startUpload: (file: File) => void }>(null);
  const { firebaseUser, isAdmin } = useSession();
  const { track } = useAnalytics();
  const toast = useToast();
  const history = useHistory();
  const { state } = useLocation<linkState>();
  const nodeVideoId =
    activeNode?.videoFile?.integrations.find((i) => i.source === "api.video")
      ?.foreignId || "";
  const videoFile = new UserFile(activeNode?.videoFile);
  const originalVideoFileUrl = videoFile.getOriginalUrl();

  const { tabsProps } = useRouteTabs({
    routes: [
      { path: `/create/path/${path.id}/v/${activeNode?.id}/details` },
      { path: `/create/path/${path.id}/v/${activeNode?.id}/activity` },
    ],
  });

  const {
    isOpen: isRemoveVideoConfirmModalOpen,
    onOpen: openRemoveVideoConfirmModal,
    onClose: closeRemoveVideoConfirmModal,
  } = useDisclosure();

  const {
    onClose: onCloseVideoEditor,
    isOpen: isVideoEditorOpen,
    onOpen: onOpenVideoEditor,
  } = useDisclosure();

  const canEdit = isAdmin || path.status !== PathStatus.Published;

  const handleUploadSuccess = async (
    fileId: string,
    filename: string,
    foreignId: string
  ) => {
    setVideoFileUploadingOrDeleting(true);

    try {
      const addVideoMutationResult = await addNodeVideoFileMutation({
        fileId: fileId,
        nodeId: activeNode?.id,
        file: {
          id: fileId,
          userId: firebaseUser.uid,
          filename,
          integrations: {
            data: [
              {
                source: "api.video",
                foreignId: foreignId,
              },
            ],
          },
        },
      });

      if (!!addVideoMutationResult.error) {
        throw new Error(addVideoMutationResult.error.toString());
      }

      const [transloaditErr] = await to(
        post("/v2/transloadit", firebaseUser, {
          fileId: fileId,
          templateName: "video-covers",
        })
      );

      if (!!transloaditErr) {
        throw new Error(transloaditErr.toString());
      }
      track(ANALYTICS_EVENTS.NODE_VIDEO_UPLOADED, {
        pathId: path?.id,
        pathAuthorId: path.authorId,
        pathAuthorUsername: path.user?.username,
        pathAuthorCreatorLevel: path.user?.creatorLevel,
        pathTitle: path?.title,
        pathSlug: path?.slug,
        pathNodesCount: path?.nodes.length,
        nodeId: activeNode?.id,
        nodeTitle: activeNode?.title,
        nodeResponsesEnabled: activeNode?.responsesEnabled,
        nodeRewardAmount: activeNode?.rewardAmount,
      });
      setDetailsSaved(true);
    } catch (error: any) {
      captureException(error);
    } finally {
      setVideoFileUploadingOrDeleting(false);
    }
  };

  const onRemoveNodeVideo = async () => {
    if (!nodeVideoId) return;

    try {
      closeRemoveVideoConfirmModal();
      setVideoFileUploadingOrDeleting(true);

      const [err] = await to(del(`/v1/videos/${nodeVideoId}`, firebaseUser));

      if (!!err) {
        throw new Error(err.toString());
      }

      const mutationResult = await deleteNodeVideoFileMutation({
        nodeId: activeNode?.id,
        nodeVideoId: nodeVideoId,
      });

      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
      track(ANALYTICS_EVENTS.NODE_VIDEO_DELETED, {
        pathId: path?.id,
        pathAuthorId: path.authorId,
        pathAuthorUsername: path.user?.username,
        pathAuthorCreatorLevel: path.user?.creatorLevel,
        pathTitle: path?.title,
        pathSlug: path?.slug,
        pathNodesCount: path?.nodes.length,
        nodeId: activeNode?.id,
        nodeTitle: activeNode?.title,
        nodeResponsesEnabled: activeNode?.responsesEnabled,
        nodeRewardAmount: activeNode?.rewardAmount,
      });
      setDetailsSaved(true);
    } catch (error: any) {
      captureException(error);
      toast({
        title: "There was an error while deleting the video",
        status: "error",
      });
    } finally {
      setVideoFileUploadingOrDeleting(false);
    }
  };

  const handleVideoEditorSave = (result: Result) => {
    let file: File | null = null;

    if (result.type === "video") {
      file = new File([result.blob], "video.webm", { type: "video/webm" });
    }

    if (!file) {
      onCloseVideoEditor();
      return;
    }
    try {
      uploaderRef.current?.startUpload(file);
    } catch (error: any) {
      captureException(error);
    }

    onCloseVideoEditor();
  };

  const handleCreate = () => {
    document.querySelectorAll("video").forEach((v) => {
      v.pause();
      v.autoplay = false;
    });

    onOpenVideoEditor();
  };

  return (
    <>
      <Flex direction="row" w="full">
        <Box w="full" px="3.5rem" flex={2}>
          <Center h="full" w="full">
            <Box w="full" position="relative">
              <VideoUploader
                uploaderRef={uploaderRef}
                title={`${activeNode?.title}`}
                videoType={VideoType.NodeVideo}
                foreignVideoId={nodeVideoId}
                isLoading={videoFileUploadingOrDeleting}
                originalFileUrl={originalVideoFileUrl}
                disabled={!canEdit}
                videoMeta={[
                  {
                    key: "pathId",
                    value: path.id,
                  },
                ]}
                onCreate={handleCreate}
                onUploadSuccess={handleUploadSuccess}
              />
              {!!nodeVideoId && (
                <Center
                  w="full"
                  position="absolute"
                  bottom="-5rem"
                  left={0}
                  h="3rem"
                  p={4}
                >
                  <Button
                    variant="outline"
                    size="lg"
                    disabled={!canEdit || videoFileUploadingOrDeleting}
                    onClick={openRemoveVideoConfirmModal}
                  >
                    Change Video
                  </Button>
                </Center>
              )}
            </Box>
          </Center>
        </Box>
        <Flex
          position="sticky"
          top="4rem"
          h="calc(100vh - 4rem)"
          direction="column"
          justify="stretch"
          w="25rem"
          p={4}
        >
          <Flex
            direction="column"
            flex={1}
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            position="relative"
            overflow="hidden"
          >
            <Tabs {...tabsProps} isManual isFitted variant="enclosed" size="lg">
              <TabList w="full">
                <Tab
                  onClick={() => {
                    history.push({
                      pathname: `/create/path/${path.id}/v/${activeNode?.id}/details`,
                      state: state,
                    });
                  }}
                  fontSize="md"
                >
                  Details
                </Tab>
                <Tab
                  onClick={() => {
                    setUnsavedChanges(false);
                    setDetailsSaved(false);
                    history.push({
                      pathname: `/create/path/${path.id}/v/${activeNode?.id}/activity`,
                      state: state,
                    });
                  }}
                  fontSize="md"
                >
                  Activity
                </Tab>
              </TabList>
            </Tabs>
            <Box overflowY="auto" flex={1}>
              <Switch>
                <Route path={`/create/path/:pathId/v/:nodeId/details`}>
                  <PathNodeDetailsForm />
                </Route>
                <Route path={`/create/path/:pathId/v/:nodeId/activity`}>
                  <PathActivity
                    w="auto"
                    showTitle={false}
                    pathIdFromParent={path.id}
                  />
                </Route>
              </Switch>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Modal
        isOpen={isRemoveVideoConfirmModalOpen}
        onClose={closeRemoveVideoConfirmModal}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader textAlign="left">Change video?</ModalHeader>
          <ModalBody>
            <Text>
              This will delete your current video, and cannot be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={closeRemoveVideoConfirmModal} size="lg">
                Cancel
              </Button>
              <Button onClick={onRemoveNodeVideo} colorScheme="red" size="lg">
                Yes, Change
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Portal>
        {isVideoEditorOpen && (
          <VideoEditor
            isOpen={isVideoEditorOpen}
            onClose={onCloseVideoEditor}
            onSave={handleVideoEditorSave}
            enablePhotoUpload={false}
          />
        )}
      </Portal>
    </>
  );
};
