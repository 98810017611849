import { gql } from "urql";

export const ONBOARD_EDUCATOR_STEP_SCHOOOL = gql`
  mutation OnboardEducatorStepSchool(
    $orgId: String
    $placeId: String
    $gradeLevel: String
  ) {
    api_onboard_educator(
      orgId: $orgId
      placeId: $placeId
      gradeLevel: $gradeLevel
    ) {
      orgId
    }
  }
`;

export const ONBOARD_EDUCATOR_STEP_CLASS = gql`
  mutation OnboardEducatorStepClass($teacher: user_teacher_insert_input!) {
    teacher: insert_user_teacher_one(object: $teacher) {
      id: userId
      displayName
      lowerGradeLevel
      upperGradeLevel
      subjects
    }
  }
`;
