import React, { PropsWithChildren, useEffect, useState } from "react";

import { EmptyState, Link, Text, useToast, useBreakpointValue } from "Shared";

import { PathCard } from "Components/PathCard";
import { PageHeader } from "Components/PageHeader";

import { useSession } from "Services/session";
import { usePathHistory } from "Services/pathHistory";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { GridContainer, GridPaths } from "Components/GridContainer";

import { ViewSource } from "Types/Path";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";

type Props = {};

const HISTORY_LIMIT = 50;

export const MyPaths: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const { track } = useAnalytics();
  const { currentUser } = useSession();
  const toast = useToast();

  const { paths, isLoading, error } = usePathHistory({
    userId: currentUser.uid,
    limit: HISTORY_LIMIT,
  });

  useEffect(() => {
    if (!!error) {
      toast({
        status: "error",
        title: "Error getting path history",
      });
    }
  }, [error, toast]);

  const [viewed, setViewed] = useState(false);
  useEffect(() => {
    if (viewed) {
      return;
    }

    track(ANALYTICS_EVENTS.MY_PATHS_VIEWED);

    setViewed(true);
  }, [track, viewed]);

  const skeletonCount = useBreakpointValue({
    base: 2,
    sm: 4,
    md: 6,
    xl: 8,
    "2xl": 10,
    "3xl": 12,
  });

  return (
    <GridContainer>
      <PageHeader title="Viewing History" />
      {isLoading && !!skeletonCount ? (
        <GridPaths width="full" height="full">
          {Array.from({ length: skeletonCount }).map((_, i) => (
            <PathCardSkeleton key={i} />
          ))}
        </GridPaths>
      ) : paths?.length ? (
        <GridPaths>
          {!!paths?.length &&
            paths.map((path) => (
              <PathCard
                key={path.id}
                path={path}
                source={ViewSource.ViewingHistory}
              />
            ))}
        </GridPaths>
      ) : (
        <EmptyState
          image="path"
          headline="No paths viewed yet"
          maxWidth={[null, null, "50%"]}
          mx="auto"
          mt={10}
        >
          <Text>
            <Link fontWeight="bold" color="brand" to="/explore/paths">
              Explore paths
            </Link>{" "}
            to get started!
          </Text>
        </EmptyState>
      )}
    </GridContainer>
  );
};
