import React from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSession } from "Services/session";

import {
  Text,
  Flex,
  Center,
  LinkBox,
  LinkOverlay,
  Stack,
  ButtonSolid,
  FontAwesomeIcon,
} from "Shared";
import { useHistory } from "react-router-dom";

type Props = {
  openCreateClassModal: () => void;
};

export const TeacherHomeEmptyState: React.FC<Props> = ({
  openCreateClassModal,
}) => {
  const { currentUser } = useSession();
  const history = useHistory();

  const exploreLink = "/explore";

  return (
    <Flex direction="column" align="center" w="full" py={{ base: 10, lg: 16 }}>
      <Text fontSize="3xl" fontWeight="bold" mb={10} textAlign="center">
        Welcome, {currentUser.firstName}! Ready to get started?
      </Text>
      <Stack
        direction={{ base: "column", lg: "row" }}
        justifyContent="center"
        spacing={{ base: 6, lg: 10 }}
        width="full"
      >
        <LinkBox
          border="1px solid"
          borderColor="gray.300"
          borderRadius="2xl"
          width="full"
          transition="150ms ease box-shadow"
          _hover={{ lg: { boxShadow: "lg" } }}
          p={{ base: 6, lg: 10 }}
        >
          <Flex align="center" direction="column">
            <Center
              w="7rem"
              h="7rem"
              bg="gray.50"
              color="brandFull.400"
              p={6}
              borderRadius={99}
            >
              <FontAwesomeIcon icon={duotone("compass")} size={16} />
            </Center>
            <Text fontSize="2xl" fontWeight="bold" mt={2} mb={2}>
              Explore our library
            </Text>
            <Text
              color="gray.600"
              mb={6}
              textAlign="center"
              maxW="30rem"
              mx="auto"
            >
              Explore our library of Tract Originals and community content. You
              can assign content to any of your classes.
            </Text>
            <LinkOverlay
              as={ButtonSolid}
              colorScheme="brandFull"
              onClick={() => history.push(exploreLink)}
              size="xl"
            >
              Start Exploring
            </LinkOverlay>
          </Flex>
        </LinkBox>
        <LinkBox
          border="1px solid"
          borderColor="gray.300"
          borderRadius="2xl"
          width="full"
          transition="150ms ease box-shadow"
          _hover={{ lg: { boxShadow: "lg" } }}
          p={{ base: 6, lg: 10 }}
        >
          <Flex align="center" direction="column">
            <Center
              w="7rem"
              h="7rem"
              bg="gray.50"
              color="brandFull.400"
              p={6}
              borderRadius={99}
            >
              <FontAwesomeIcon icon={duotone("chalkboard-user")} size={16} />
            </Center>
            <Text fontSize="2xl" fontWeight="bold" mt={2} mb={2}>
              Create a class
            </Text>
            <Text
              color="gray.600"
              mb={6}
              textAlign="center"
              maxW="30rem"
              mx="auto"
            >
              You can create as many classrooms as you need. Let’s get you
              started with your first for now.
            </Text>
            <LinkOverlay
              as={ButtonSolid}
              size="xl"
              onClick={openCreateClassModal}
            >
              Create Class
            </LinkOverlay>
          </Flex>
        </LinkBox>
      </Stack>
    </Flex>
  );
};
