import React, { useEffect, useState } from "react";
import { MAX_USER_LIKES } from "Services/hooks/useLikeProject";

import { Box, Button } from "Shared";
import useSound from "use-sound";
// @ts-ignore
import coin from "./coin.wav";
import { ButtonProps, forwardRef } from "@chakra-ui/react";

type LikeButtonProps = {
  likeCount: number;
  onLike: () => void;
} & ButtonProps;

export const LikeButton = forwardRef<LikeButtonProps, "button">(
  ({ likeCount, onLike, ...props }, ref) => {
    const [play] = useSound(coin);
    const [displayedLikeCount, setDisplayedLikeCount] = useState(likeCount);

    useEffect(() => {
      if (!!likeCount) {
        setDisplayedLikeCount(likeCount);
      }
    }, [likeCount, setDisplayedLikeCount]);

    function handleIncrement(e: any) {
      e.preventDefault();
      e.stopPropagation();

      if (likeCount >= MAX_USER_LIKES) return;

      setDisplayedLikeCount(displayedLikeCount + 1);
      play();
      onLike();
    }

    function renderLikes(likeCount: number) {
      if (!likeCount) return;
      let likeViews = [];
      for (let i = 0; i < MAX_USER_LIKES; i++) {
        likeViews.push(
          i < likeCount ? (
            <span key={i} role="img" aria-hidden="true">
              ❤️
            </span>
          ) : (
            <Box key={i} as="span" opacity={0.3} role="img" aria-hidden="true">
              ❤️
            </Box>
          )
        );
      }
      return likeViews;
    }

    return (
      <Box display="inline-block" position="relative" zIndex={1}>
        <Button
          variant="outline"
          aria-label={`Like (${
            displayedLikeCount ? displayedLikeCount : 0
          } likes)`}
          bg="white"
          size="lg"
          onClick={handleIncrement}
          ref={ref}
          {...props}
        >
          {displayedLikeCount ? renderLikes(displayedLikeCount) : <>❤️ Like</>}
        </Button>
      </Box>
    );
  }
);
