import React, { FC } from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  EmptyState,
  EmptyStateFontAwesomeIcon,
  useBreakpointValue,
  ButtonSolid,
  Center,
} from "Shared";
import { PathCard } from "Components/PathCard";
import { useSession } from "Services/session";

import {
  ProfilePathsQuery,
  ProfilePathsQueryVariables,
  UserProfileUserFragment,
} from "@tract/common/dist/graphql";

import { ViewSource } from "Types/Path";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { GridProfileItems } from "Components/GridContainer";
import { useOffsetPaginationQuery } from "Utils";
import { PROFILE_PATHS_QUERY } from "../graphql";

type Props = {
  author: UserProfileUserFragment;
};
const PROFILE_PATH_PAGE_SIZE = 54;

export const ProfilePaths: FC<Props> = ({ author }) => {
  const { currentUser } = useSession();
  const isOwnProfile = author.id === currentUser.uid;

  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<ProfilePathsQuery, ProfilePathsQueryVariables>({
    query: PROFILE_PATHS_QUERY,
    variables: {
      limit: PROFILE_PATH_PAGE_SIZE,
      offset: 0,
      userId: author.id,
    },
    field: "paths",
  });

  const paths = data?.paths || [];

  const skeletonCount = useBreakpointValue({
    base: 2,
    md: 4,
    xl: 6,
  });

  if (fetching) {
    return (
      <GridProfileItems>
        {Array.from({ length: skeletonCount || 8 }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </GridProfileItems>
    );
  }

  if (!paths || paths.length <= 0) {
    return (
      <EmptyState
        icon={
          <EmptyStateFontAwesomeIcon icon={duotone("wand-magic-sparkles")} />
        }
        headline="No paths yet"
        body={isOwnProfile ? "Publish your first learning path" : undefined}
      />
    );
  }

  return (
    <>
      <GridProfileItems>
        {paths.map((path) => (
          <PathCard
            key={path.id}
            path={path}
            showAuthor={false}
            showAuthorName={false}
            source={ViewSource.UserProfile}
          />
        ))}
      </GridProfileItems>
      {pagination.hasNextPage && !!paths.length && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </>
  );
};
