import { FC } from "react";
import { gql } from "@apollo/client";
import { forwardRef, useBreakpointValue } from "@chakra-ui/react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation } from "react-router-dom";

import {
  ExploreCreatorsUsersQuery,
  ExploreCreatorsUsersQueryVariables,
  ExploreCreatorUserFragment,
} from "@tract/common/dist/graphql";

import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Center,
  EmptyState,
  Flex,
  Grid,
  Link,
  LinkBox,
  LinkOverlay,
  TabLink,
  TabList,
  Tabs,
  Text,
  useRouteTabs,
} from "Shared";

import { useOffsetPaginationQuery } from "Utils";

import { LayoutCentered } from "Components/LayoutCentered";
import { GridContainer } from "Components/GridContainer";
import { ProfileBadges } from "Components/ProfileBadges";

import { CreatorLevel } from "@tract/common/dist/types/models/User";

const EXPLORE_CREATORS_USERS_QUERY = gql`
  fragment ExploreCreatorUser on user {
    id: firestoreId
    firstName
    lastName
    username
    avatar
    creatorLevel
    userType
    canAuthor
    onboarded
    subscribed
    isEducator
    isMod
    country
  }

  query ExploreCreatorsUsers(
    $offset: Int!
    $limit: Int!
    $where: view_top_creators_by_followers_count_bool_exp
  ) @cached(ttl: 300) {
    topCreators: view_top_creators_by_followers_count(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { followersCount: desc }
    ) {
      user {
        ...ExploreCreatorUser
      }
    }
  }
`;

export const ExploreCreators: FC<{}> = () => {
  const location = useLocation();
  const creatorLevel = location.pathname.split("/")[3];

  const tabRoutes = [
    { path: `/explore/creators`, name: "All" },
    {
      path: `/explore/creators/${CreatorLevel.Partner}`,
      name: "Partners",
    },
    {
      path: `/explore/creators/${CreatorLevel.Affiliate}`,
      name: "Affiliates",
    },
    {
      path: `/explore/creators/${CreatorLevel.Creator}`,
      name: "Creators",
    },
  ];

  const { tabsProps } = useRouteTabs({
    routes: tabRoutes,
  });

  return (
    <Flex direction="column">
      <GridContainer>
        <Center py={3}>
          <Tabs variant="soft-rounded" size="md" isManual {...tabsProps}>
            <TabList>
              {tabRoutes.map((route) => (
                <TabLink to={route.path} key={route.path}>
                  {route.name}
                </TabLink>
              ))}
            </TabList>
          </Tabs>
        </Center>
        <CreatorsGrid creatorLevel={creatorLevel} key={creatorLevel} />
      </GridContainer>
    </Flex>
  );
};

type CreatorGridProps = {
  creatorLevel?: string | null;
};

const CreatorsGrid: React.FC<CreatorGridProps> = ({ creatorLevel }) => {
  const perPage = useBreakpointValue({
    base: 24,
    md: 36,
    lg: 36,
    xl: 50,
    "2xl": 48,
    "3xl": 49,
  });

  const {
    response: [{ data, fetching, stale }],
    pagination,
  } = useOffsetPaginationQuery<
    ExploreCreatorsUsersQuery,
    ExploreCreatorsUsersQueryVariables
  >({
    field: "topCreators",
    pause: !perPage,
    query: EXPLORE_CREATORS_USERS_QUERY,
    variables: {
      limit: perPage || 24,
      offset: 0,
      where: {
        user: {
          ...(creatorLevel && { creatorLevel: { _eq: creatorLevel } }),
        },
      },
    },
  });

  const loading = fetching || stale;

  const users =
    data?.topCreators.reduce((prev, curr) => {
      if (curr.user) {
        prev.push(curr.user);
      }

      return prev;
    }, [] as ExploreCreatorUserFragment[]) || [];

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: pagination.hasNextPage,
    onLoadMore: pagination.loadMore,
    loading: pagination.loadingMore || loading,
  });

  return (
    <>
      {loading && <LayoutCentered isLoading height="auto" />}
      {!loading && !users?.length && (
        <EmptyState headline="No Creators Found" />
      )}

      {!!users?.length && (
        <Grid
          justifyContent="stretch"
          templateColumns={{
            base: "repeat(2, minmax(0, 1fr))",
            md: "repeat(3, minmax(0, 1fr))",
            lg: "repeat(4, minmax(0, 1fr))",
            xl: "repeat(5, minmax(0, 1fr))",
            "2xl": "repeat(6, minmax(0, 1fr))",
            "3xl": "repeat(7, minmax(0, 1fr))",
          }}
          gap={6}
          width="100%"
        >
          {users.map((profile) => (
            <CreatorCard key={profile.id} profile={profile} />
          ))}
        </Grid>
      )}

      {!loading && pagination.hasNextPage && (
        <Center ref={sentryRef} mt={6}>
          <Button
            variant="ghost"
            size="lg"
            colorScheme="brandFull"
            onClick={pagination.loadMore}
            isLoading={pagination.loadingMore}
          >
            Load More
          </Button>
        </Center>
      )}
    </>
  );
};

type CreatorCardProps = {
  profile: ExploreCreatorUserFragment;
};

const CreatorCard = forwardRef<CreatorCardProps, "div">(
  ({ profile, ...props }, ref) => {
    return (
      <LinkBox
        key={profile.id}
        borderRadius="2xl"
        bg="gray.50"
        p={6}
        overflow="hidden"
        _hover={{ bg: "gray.100" }}
        _focusWithin={{ boxShadow: "outline" }}
        transition="150ms ease all"
        {...props}
        ref={ref}
      >
        <AspectRatio ratio={1} mb={6}>
          <Avatar
            display="block"
            name={profile.username ? profile.username : undefined}
            src={profile.avatar ? profile.avatar : undefined}
            size="xl"
          />
        </AspectRatio>
        <Box overflow="hidden">
          <LinkOverlay
            color="gray.900"
            as={Link}
            to={`/@${profile.username}`}
            _hover={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            display="flex"
            alignItems="center"
          >
            <Text
              as="span"
              mr={1}
              noOfLines={1}
              fontSize="lg"
              fontWeight="bold"
            >
              {profile.username}
            </Text>
            <ProfileBadges user={profile} />
          </LinkOverlay>
        </Box>
      </LinkBox>
    );
  }
);
