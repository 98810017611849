import React, { PropsWithChildren } from "react";
import { forwardRef } from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";
import { TransformOptions } from "react-markdown/lib/react-markdown";

import { Box } from "Shared";

import { defaultRenderers } from "./renderers/default";
import { miniRenderers } from "./renderers/mini";

const presets = {
  default: defaultRenderers,
  mini: miniRenderers,
};

type Props = {
  source?: string;
  preset?: "default" | "mini";
  components?: TransformOptions["components"];
};

export const Markdown = forwardRef(
  (
    {
      source = "",
      preset = "default",
      components = {},
      ...props
    }: PropsWithChildren<Props>,
    ref
  ) => {
    return (
      <Box {...props} ref={ref}>
        <ReactMarkdown
          children={source}
          components={{ ...(preset && presets[preset]), ...components }}
          skipHtml
        />
      </Box>
    );
  }
);
