import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import { PageHeader } from "Components/PageHeader";

import { Submissions } from "./Submissions";
import { AdminContent } from "../AdminContent";

export const AdminSubmissions: React.FC = () => {
  return (
    <AdminContent>
      <PageHeader title="Submissions" />
      <Switch>
        <Route path="/admin/submissions" exact>
          <Submissions />
        </Route>
      </Switch>
    </AdminContent>
  );
};
