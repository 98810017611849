import React, { useEffect, useMemo, useState } from "react";
import { gql, NetworkStatus } from "@apollo/client";

import { Button, Center, Text, Link, useBreakpointValue } from "Shared";

import { HScrollContainer } from "./HScrollContainer";
import { useGetPathFeedQuery } from "@tract/common/dist/graphql";
import { PathCard } from "Components/PathCard";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

import { ViewSource } from "Types/Path";
import { GridPaths } from "Components/GridContainer";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { ExploreRoutes } from "Pages/Explore/ExplorePaths";

gql`
  ${PATH_CARD_FRAGMENT}

  query GetPathFeed($slug: String!, $limit: Int = 4, $offset: Int = 0) {
    paths: path_feed(
      where: { slug: { _eq: $slug }, path: { status: { _eq: "PUBLISHED" } } }
      order_by: { createdAt: desc }
      limit: $limit
      offset: $offset
    ) {
      slug
      pathId
      path {
        ...PathCard
      }
    }
  }
`;

type Props = {
  initialLimit?: number;
  loadMoreLimit?: number;
  homePage?: boolean;
  templateColumns?: {
    base?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
  };
};

export const TopPaths: React.FC<Props> = ({
  initialLimit = 6,
  loadMoreLimit = 16,
  homePage = true,
  templateColumns,
}) => {
  const [hideShowMore, setHideShowMore] = useState(false);
  const [page, setPage] = useState(1);

  const { data, loading, fetchMore, refetch, networkStatus } =
    useGetPathFeedQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        slug: "top-picks",
        limit: initialLimit,
      },
    });

  const paths = useMemo(() => {
    return data?.paths || [];
  }, [data]);

  useEffect(() => {
    // Resets the feed when revisiting the component
    if (!!initialLimit) {
      refetch();
    }
  }, [refetch, initialLimit]);

  useEffect(() => {
    if (!paths.length) {
      return;
    }

    if (!homePage) {
      setHideShowMore(paths.length < initialLimit + (page - 1) * loadMoreLimit);
    }
  }, [paths, homePage, initialLimit, loadMoreLimit, page]);

  const handleFetchMore = async () => {
    const nextPage = page + 1;
    setPage(nextPage);

    await fetchMore({
      variables: {
        slug: "top-picks",
        limit: loadMoreLimit,
        offset: paths.length,
      },
    });
  };

  const skeletonCount = useBreakpointValue({
    base: 2,
    sm: 4,
    md: 6,
    xl: 8,
    "2xl": 10,
    "3xl": 12,
  });

  if (loading && networkStatus !== NetworkStatus.fetchMore) {
    return !!homePage ? (
      <HScrollContainer renderTitle={Title} templateColumns={templateColumns}>
        {Array.from({ length: initialLimit || 4 }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </HScrollContainer>
    ) : (
      <GridPaths width="full" height="full">
        {Array.from({ length: skeletonCount || 8 }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </GridPaths>
    );
  }

  if (!paths.length) {
    return null;
  }

  const pathCards = () => {
    return paths.map(({ path }) => (
      <PathCard
        key={path.id}
        path={path}
        source={homePage ? ViewSource.HomeTopPicks : ViewSource.ExploreTopPicks}
      />
    ));
  };

  return (
    <>
      {!!homePage ? (
        <HScrollContainer renderTitle={Title} templateColumns={templateColumns}>
          {pathCards()}
        </HScrollContainer>
      ) : (
        <>
          <GridPaths width="full" height="full">
            {pathCards()}
          </GridPaths>
          {!hideShowMore && (
            <Center mt={6}>
              <Button
                variant="ghost"
                colorScheme="brandFull"
                size="lg"
                isLoading={networkStatus === NetworkStatus.fetchMore}
                onClick={handleFetchMore}
              >
                Load More
              </Button>
            </Center>
          )}
        </>
      )}
    </>
  );
};

const Title = () => {
  return (
    <>
      <Text as={Link} to={ExploreRoutes.community} color="gray.900">
        Top Picks
      </Text>
      <Link
        fontSize="lg"
        to={ExploreRoutes.community}
        color="brand"
        tabIndex={-1}
      >
        See More
      </Link>
    </>
  );
};
