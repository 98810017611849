import React, { FC } from "react";

import { EmptyState, Table, Tbody, Text } from "Shared";
import { PathsByStatusFragment } from "@tract/common/dist/graphql";
import { AuthorPathRow } from "./AuthorPathRow";
import { PathStatus } from "@tract/common/dist/types/models/Path";

type Props = {
  paths?: PathsByStatusFragment[];
};

export const ArchivedPathsTable: FC<Props> = ({ paths }) => {
  if (!paths?.length) {
    return (
      <EmptyState
        image="archived"
        headline="No archived paths yet"
        maxWidth={[null, null, "50%"]}
        mx="auto"
        mt={10}
      >
        <Text fontSize="md" color="gray.600">
          This is where your archived paths live
        </Text>
      </EmptyState>
    );
  }

  return (
    <Table width="100%" mt={10}>
      <Tbody>
        {paths?.map((path, i) => (
          <AuthorPathRow
            key={path.id}
            path={path}
            pathStatus={PathStatus.Archived}
          />
        ))}
      </Tbody>
    </Table>
  );
};
