import React from "react";

import {
  ModalCloseButton as ChakraModalCloseButton,
  forwardRef,
} from "@chakra-ui/react";

import { IconButton, IconX } from "Shared";

export {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

export const ModalCloseButton = forwardRef((props, ref) => {
  return (
    <IconButton
      as={ChakraModalCloseButton}
      variant="ghost"
      size="md"
      icon={<IconX />}
      position="absolute"
      top={{ base: 2, md: 3 }}
      right={{ base: 2, md: 3 }}
      ref={ref}
      {...props}
    />
  );
});
