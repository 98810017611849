import { FC } from "react";

import { GridClasses } from "Components/GridContainer";
import { ClassroomLearnerGroupFragment } from "@tract/common/dist/graphql";
import { ClassroomCard } from "Pages/HomeAuthed/ClassroomCard";
import { ClassroomCardSkeleton } from "Pages/HomeAuthed/ClassroomCardSkeleton";

interface Props {
  loading: boolean;
  classrooms: ClassroomLearnerGroupFragment[];
}

export const ClassroomGrid: FC<Props> = ({ loading, classrooms }) => {
  return (
    <GridClasses>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((_, i) => (
            <ClassroomCardSkeleton key={i} />
          ))}
        </>
      ) : (
        <>
          {classrooms.map((classroom) => (
            <ClassroomCard key={classroom.id} classroom={classroom} />
          ))}
        </>
      )}
    </GridClasses>
  );
};
