import { FC } from "react";
import { useMutation } from "urql";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "Shared";
import {
  ArchiveClassroomMutationResult,
  ArchiveClassroomMutationVariables,
} from "@tract/common/dist/graphql";
import { ARCHIVE_CLASSROOM_MUTATION } from "./graphql";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  learnerGroupId: string;
  learnerGroupName?: string;
};

export const ArchiveClassroomModal: FC<Props> = ({
  isOpen,
  onClose,
  learnerGroupId,
  learnerGroupName,
}) => {
  const { track } = useAnalytics();

  const [{ fetching: archivingClassroom }, archiveClassroom] = useMutation<
    ArchiveClassroomMutationResult["data"],
    ArchiveClassroomMutationVariables
  >(ARCHIVE_CLASSROOM_MUTATION);

  const onArchiveClick = async () => {
    await archiveClassroom({
      learnerGroupId: learnerGroupId,
      archivedAt: "now()",
    });
    track(ANALYTICS_EVENTS.CLASS_ARCHIVED, {
      classId: learnerGroupId,
      classTitle: learnerGroupName,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Archive this class?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to archive {`"${learnerGroupName}"`}?
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={2}>
            <Button size="lg" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="solid"
              colorScheme="red"
              onClick={onArchiveClick}
              isLoading={archivingClassroom}
            >
              Yes, Archive
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
