import React, { FC } from "react";

import { EmptyState, Table, Tbody, Text } from "Shared";

import { PathsByStatusFragment } from "@tract/common/dist/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import { AuthorPathRow } from "./AuthorPathRow";

type Props = {
  paths?: PathsByStatusFragment[];
};

export const PublishedPathsTable: FC<Props> = ({ paths }) => {
  if (!paths?.length) {
    return (
      <EmptyState
        image="path"
        headline="No published paths yet"
        maxWidth={[null, null, "50%"]}
        mx="auto"
        mt={10}
      >
        <Text fontSize="md" color="gray.600">
          Time to get to work!
        </Text>
      </EmptyState>
    );
  }

  return (
    <Table width="100%" mt={10}>
      <Tbody>
        {paths?.map((path, i) => (
          <AuthorPathRow
            key={path.id}
            path={path}
            pathStatus={PathStatus.Published}
          />
        ))}
      </Tbody>
    </Table>
  );
};
