import { gql } from "@apollo/client";
import {
  useCirclePostsQuery,
  useCircleSpaceGroupsQuery,
  useCircleSpacesQuery,
} from "@tract/common/dist/graphql";

export type {
  ForumSpaceGroupFragment,
  ForumSpaceFragment,
  ForumPostFragment,
} from "@tract/common/dist/graphql";

const COMMUNITY_ID = "28790";
export const FORUM_URL = "https://community.tract.app";
export const FORUM_EDUCATOR_INVITE_URL =
  "https://community.tract.app/join?invitation_token=1fd41f05e778c86f384d3403e84e073967970423-0eaff039-710a-4710-9076-deeefc4635be";

gql`
  fragment ForumPost on CirclePost {
    id
    name
    body
    url
    space_name
    user_name
    user_avatar_url
    comments_count
    likes_count
    created_at
    hide_meta_info
  }

  query CirclePosts($communityId: String!, $spaceId: String!, $page: String!) {
    circle_posts(communityId: $communityId, spaceId: $spaceId, page: $page) {
      ...ForumPost
    }
  }
`;

gql`
  fragment ForumSpaceGroup on CircleSpaceGroup {
    id
    name
    space_order_array
  }
  query CircleSpaceGroups($communityId: String!) {
    circle_space_groups(communityId: $communityId) {
      ...ForumSpaceGroup
    }
  }
`;

gql`
  fragment ForumSpace on CircleSpace {
    id
    name
    emoji
  }

  query CircleSpaces($communityId: String!) {
    circle_spaces(communityId: $communityId) {
      ...ForumSpace
    }
  }
`;

type UseForumPostsOptions = {
  spaceId?: string;
  page?: string;
};

export const useForumPosts = (options?: UseForumPostsOptions) => {
  return useCirclePostsQuery({
    variables: {
      communityId: COMMUNITY_ID,
      spaceId: options?.spaceId || "",
      page: options?.page || "1",
    },
  });
};

export const useForumSpaces = () => {
  return useCircleSpacesQuery({
    variables: {
      communityId: COMMUNITY_ID,
    },
  });
};

export const useForumSpaceGroups = () => {
  return useCircleSpaceGroupsQuery({
    variables: {
      communityId: COMMUNITY_ID,
    },
  });
};
