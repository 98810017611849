import { gql } from "urql";

import { USER_FILE_FRAGMENT } from "Utils/UserFile";

import { USER_DISPLAY_NAME_FRAGMENT } from "Types/User";

export const ASSIGN_BUILDER_PATH_FRAGMENT = gql`
  ${USER_FILE_FRAGMENT}
  ${USER_DISPLAY_NAME_FRAGMENT}

  fragment AssignBuilderPath on path {
    id
    title
    slug
    isTractOriginal
    description
    user {
      id: firestoreId
      avatar
      ...UserDisplayName
    }
    nodes {
      id
    }
    coverFile {
      ...UserFile
    }
    guides {
      guide {
        id
      }
    }
  }
`;

export const ASSIGNMENT_BUILDER_FRAGMENT = gql`
  ${ASSIGN_BUILDER_PATH_FRAGMENT}

  fragment AssignmentBuilder on assignment {
    id
    title
    description
    userId
    startDate
    dueDate
    learnerGroups {
      assignmentId
      learnerGroupId
    }
    paths {
      assignmentId
      pathId
      path {
        ...AssignBuilderPath
      }
    }
  }
`;

export const ASSIGN_BUILDER_QUERY = gql`
  ${ASSIGNMENT_BUILDER_FRAGMENT}

  query AssignBuilder($id: uuid!) {
    assignment_by_pk(id: $id) {
      ...AssignmentBuilder
    }
  }
`;

export const ASSIGN_BUILDER_PATH_BY_ID_QUERY = gql`
  ${ASSIGN_BUILDER_PATH_FRAGMENT}

  query AssignBuilderPathById($id: bigint!) {
    path_by_pk(id: $id) {
      ...AssignBuilderPath
    }
  }
`;

export const CREATE_ASSIGNMENT_MUTATION = gql`
  ${ASSIGNMENT_BUILDER_FRAGMENT}

  mutation CreateAssignment($object: assignment_insert_input!) {
    insert_assignment_one(object: $object) {
      id
      title
      description
      userId
      dueDate
      startDate
      learnerGroups {
        assignmentId
        learnerGroupId
      }
      paths {
        assignmentId
        pathId
        path {
          ...AssignBuilderPath
        }
      }
    }
  }
`;

export const UPDATE_ASSIGNMENT_MUTATION = gql`
  ${ASSIGNMENT_BUILDER_FRAGMENT}

  mutation UpdateAssignment(
    $id: uuid!
    $object: assignment_set_input!
    $learnerGroups: [assignment_learner_group_insert_input!]!
  ) {
    delete_assignment_learner_group(where: { assignmentId: { _eq: $id } }) {
      affected_rows
    }

    insert_assignment_learner_group(objects: $learnerGroups) {
      affected_rows
    }

    update_assignment_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      title
      description
      userId
      dueDate
      startDate
      learnerGroups {
        assignmentId
        learnerGroupId
      }
      paths {
        assignmentId
        pathId
        path {
          ...AssignBuilderPath
        }
      }
    }
  }
`;
