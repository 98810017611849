import { BoxProps } from "@chakra-ui/layout";
import { ReactComponent as IconCoin } from "assets/icons/coin.svg";
import { SVGProps } from "react";
import { Box } from "Shared";

export type CoinIconProps = {
  size?: number;
} & BoxProps &
  SVGProps<SVGSVGElement>;

export const CoinIcon = ({ size = 8, ...props }: CoinIconProps) => (
  <Box
    as={IconCoin}
    width={size}
    height={size}
    display="inline-block"
    {...props}
  />
);
