import React from "react";
import { forwardRef } from "@chakra-ui/system";
import { Box } from "Shared";

export const AdminContent = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box
      w="100%"
      pb={20}
      px={{ base: 4, lg: 6, "2xl": 10 }}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  );
});
