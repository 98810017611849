import React from "react";
import { useQuery } from "urql";

import {
  Text,
  Flex,
  ButtonSolid,
  IconPlus,
  useDisclosure,
  useToast,
} from "Shared";
import { CreateClassModal } from "Components/CreateClassroomModal";
import { ClassroomGrid } from "Components/ClassroomGrid";
import { TeacherHomeEmptyState } from "./TeacherHomeEmptyState";

import { ANALYTICS_EVENTS, useTrackEventOnce } from "Services/analytics";
import { captureException, ERROR_TAGS } from "Services/errors";
import { useCreateClassroomMutation } from "Services/classroom";
import { useSession } from "Services/session";
import { pluralize } from "Utils";

import {
  ClassroomsByTeacherIdQueryResult,
  ClassroomsByTeacherIdQueryVariables,
} from "@tract/common/dist/graphql";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";

export const TeacherHome: React.FC = () => {
  const { currentUser } = useSession();
  const toast = useToast();

  useTrackEventOnce(ANALYTICS_EVENTS.HOME_DASHBOARD_VIEWED);

  const [{ data, fetching: loading }] = useQuery<
    ClassroomsByTeacherIdQueryResult["data"],
    ClassroomsByTeacherIdQueryVariables
  >({
    query: CLASSROOMS_BY_TEACHER_ID_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      teacherUserId: currentUser.id,
    },
  });

  const classrooms = data?.learnerGroups || [];

  const {
    isOpen: isCreateClassModalOpen,
    onOpen: openCreateClassModal,
    onClose: closeCreateClassModal,
  } = useDisclosure();

  const createClassroom = useCreateClassroomMutation();

  const onCreateClassSubmit = async (values: { classroomName: string }) => {
    if (!currentUser.orgId) return;

    try {
      const result = await createClassroom({
        name: values.classroomName,
        orgId: currentUser.orgId,
        userId: currentUser.id,
      });

      if (!result.data?.createClassroom) {
        throw new Error("classroom not found");
      }

      toast({
        title: "Class Created!",
        status: "success",
      });

      closeCreateClassModal();
    } catch (error: any) {
      captureException(error, { tags: { feature: ERROR_TAGS.CLASSROOMS } });
      toast({
        title: "Error creating the class",
        status: "error",
      });
    }
  };

  return (
    <>
      {!loading && !classrooms.length ? (
        <TeacherHomeEmptyState openCreateClassModal={openCreateClassModal} />
      ) : (
        <>
          <Flex
            direction="row"
            w="full"
            align="center"
            justifyContent="space-between"
            my={6}
            h="3rem"
          >
            {loading ? null : (
              <>
                <Text fontSize="xl" fontWeight="bold">
                  {pluralize(classrooms.length, "Class", "Classes")}
                </Text>
                <ButtonSolid
                  size="lg"
                  leftIcon={<IconPlus />}
                  onClick={openCreateClassModal}
                >
                  Class
                </ButtonSolid>
              </>
            )}
          </Flex>
          <ClassroomGrid loading={loading} classrooms={classrooms} />
        </>
      )}
      <CreateClassModal
        learnerGroups={classrooms}
        isOpen={isCreateClassModalOpen}
        onClose={closeCreateClassModal}
        onSubmit={onCreateClassSubmit}
        includeNumStudents={false}
      />
    </>
  );
};
