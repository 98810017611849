import { extendTheme, Theme as ChakraTheme } from "@chakra-ui/react";
import { colors, focusOutline } from "./shared";
import * as components from "./components";

const fonts = {
  body: "Basier Circle, system-ui, sans-serif",
  heading: "Basier Circle, system-ui, sans-serif",
};

const shadowColor1 = "rgba(23, 25, 35, 0.05)";
const shadowColor2 = "rgba(23, 25, 35, 0.03)";
const shadowColor3 = "rgba(23, 25, 35, 0.05)";

const shadows = {
  outline: `0 0 0 3px rgba(20, 28, 222, .75)`,
  outlineInset: `0 0 0 3px rgba(20, 28, 222, .75) inset`,
  outlineSelected: `0 0 0 3px #171923 inset`, // gray.900
  outlineRed: `0 0 0 3px rgba(229, 62, 62, .75)`,
  "0dp": "none",
  "4dp": `0 2px 4px 0 ${shadowColor1},0 4px 5px 0 ${shadowColor2},0 1px 10px 0 ${shadowColor3}`,
  "8dp": `0 8px 10px 1px ${shadowColor1},0 3px 14px 3px ${shadowColor2},0 4px 15px 0 ${shadowColor3}`,
};

export const lightTheme = extendTheme({
  fonts,
  colors,
  shadows,
  focusOutline,
  breakpoints: {
    "3xl": "125rem",
  },
  components: {
    ...components,
  },
  styles: {
    global: {
      ".calendly-overlay": {
        backgroundColor: "blackAlpha.800",
      },
    },
  },
});

export type Theme = ChakraTheme & { focusOutline: any; colors: typeof colors };
