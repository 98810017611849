import { Notifications } from "Pages";
import { useRef, PropsWithChildren } from "react";

import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "Shared";

type Props = {};

export const NotificationsPopover: React.FC<PropsWithChildren<Props>> = ({
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Popover
      initialFocusRef={ref}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      computePositionOnMount
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        zIndex={4}
        boxShadow="lg"
        width="400px"
        borderRadius="2xl"
        maxH="calc(100vh - 4rem)"
        pt={3}
      >
        <PopoverHeader
          alignSelf="center"
          border="none"
          fontWeight="bold"
          fontSize="xl"
        >
          Notifications
        </PopoverHeader>
        <PopoverCloseButton aria-label="close popup" />
        <PopoverBody pb={0} tabIndex={-1} ref={ref} overflowY="scroll">
          <Notifications insidePopover onClose={onClose} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
