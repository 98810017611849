import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { FlexProps, forwardRef, UseDisclosureReturn } from "@chakra-ui/react";
import { useLocation } from "react-router";
import { LinkProps } from "react-router-dom";

import {
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

import {
  Avatar,
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  Link,
  Text,
  VStack,
} from "Shared";
import { TopNavSearch } from "Components/TopNav";

import { isKidUser, isParentUser, isTeacherUser } from "Types/User";

import { useFeature } from "Services/features";
import { useSession } from "Services/session";

export const MobileMenuContext = createContext<UseDisclosureReturn>({
  isOpen: false,
  onOpen: () => null,
  onClose: () => null,
  onToggle: () => null,
  isControlled: false,
  getButtonProps: () => null,
  getDisclosureProps: () => null,
});

export const MobileMenuProvider = MobileMenuContext.Provider;

export const MobileNav = forwardRef((props, ref) => {
  const { currentUser, isAdmin } = useSession();
  const location = useLocation();

  const isKid = isKidUser(currentUser);
  const isParent = isParentUser(currentUser);
  const isTeacher = isTeacherUser(currentUser);
  const { onClose: onCloseMobileNav } = useContext(MobileMenuContext);
  useEffect(() => {
    onCloseMobileNav();
  }, [location, onCloseMobileNav]);
  const displayName = isParent
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : currentUser.username;

  const isAchievementPageEnabled = useFeature("achievement-page");
  const areMissionCommentsEnabled = useFeature("mission-comments");
  const areKidInvitesEnabled = useFeature("kid-invites");

  return (
    <Box as="nav" px={2} pt={4} pb={10} w="100%" ref={ref} {...props}>
      <TopNavSearch display={{ base: "block", lg: "none" }} px={2} mb={2} />
      <MobileNavItem to="/">Home</MobileNavItem>
      {isKid && (
        <>
          <MobileNavItem to={`/classes`} fontWeight="normal">
            Classes
          </MobileNavItem>
          <MobileNavItem to={`/following`} fontWeight="normal">
            Following
          </MobileNavItem>
          <MobileNavItem to={`/events`} fontWeight="normal">
            Events
          </MobileNavItem>
        </>
      )}
      <MobileNavItem to="/explore">Explore</MobileNavItem>
      <Accordion allowMultiple>
        {!isTeacher && !isParent && (
          <AccordionItem border={0}>
            <MobileNavItem as={AccordionButton} iconRight={<AccordionIcon />}>
              Create
            </MobileNavItem>
            <AccordionPanel px={0} py={2}>
              <VStack align="stretch" spacing={1}>
                <MobileNavItem to="/author/drafts" nesting={1}>
                  Paths
                </MobileNavItem>
                <MobileNavItem to="/author/submissions" nesting={1}>
                  Submissions
                </MobileNavItem>
                {isAdmin && (
                  <MobileNavItem to="/author/peer-reviews" nesting={1}>
                    Peer Reviews
                  </MobileNavItem>
                )}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        )}
        {isParent && !isTeacher && (
          <AccordionItem border={0}>
            <MobileNavItem as={AccordionButton} iconRight={<AccordionIcon />}>
              Parents
            </MobileNavItem>
            <AccordionPanel px={0} py={2}>
              <VStack align="stretch" spacing={1}>
                <MobileNavItem to="/learners" nesting={1}>
                  Learners
                </MobileNavItem>
                <MobileNavItem to="/submissions" nesting={1}>
                  Submissions
                </MobileNavItem>
                <MobileNavItem to="/comments" nesting={1}>
                  Comments
                </MobileNavItem>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        )}
        {/* {clubScope !== ClubScope.Restricted && (
          <MobileNavItem to="/clubs">Clubs</MobileNavItem>
        )} */}
        {/*  We want shop enabled for users with no org also */}
        {(!currentUser.organization ||
          currentUser.organization.shopEnabled) && (
          <MobileNavItem to="/shop">Shop</MobileNavItem>
        )}
        {isAdmin && (
          <AccordionItem border={0}>
            <MobileNavItem as={AccordionButton} iconRight={<AccordionIcon />}>
              Admin
            </MobileNavItem>
            <AccordionPanel px={0} py={2}>
              <VStack align="stretch" spacing={1}>
                <MobileNavItem to="/admin/users" nesting={1}>
                  Users
                </MobileNavItem>
                <MobileNavItem to="/admin/paths" nesting={1}>
                  Paths
                </MobileNavItem>
                <MobileNavItem to="/admin/collections" nesting={1}>
                  Collections
                </MobileNavItem>
                <MobileNavItem to="/admin/organizations" nesting={1}>
                  Organizations
                </MobileNavItem>
                <MobileNavItem to="/admin/submissions-queue" nesting={1}>
                  Submissions Queue
                </MobileNavItem>
                <MobileNavItem to="/admin/submissions" nesting={1}>
                  Submissions
                </MobileNavItem>
                <MobileNavItem to="/admin/comments" nesting={1}>
                  Comments
                </MobileNavItem>
                {areMissionCommentsEnabled && (
                  <MobileNavItem to="/admin/comments_v2" nesting={1}>
                    Mission Comments
                  </MobileNavItem>
                )}
                <MobileNavItem to="/admin/reports" nesting={1}>
                  Reports
                </MobileNavItem>
                <MobileNavItem as={Link} to="/admin/livestreams" nesting={1}>
                  Livestreams
                </MobileNavItem>
                <MobileNavItem to="/admin/shop" nesting={1}>
                  Shop
                </MobileNavItem>
                <MobileNavItem to="/admin/livestreams" nesting={1}>
                  Livestreams
                </MobileNavItem>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
      <Box pt={4} px={4} pb={4}>
        <Divider mb={6} />
        <HStack spacing={4}>
          <Avatar
            size="md"
            src={currentUser.avatar || ""}
            name={displayName || ""}
          />
          <Text fontSize="lg" fontWeight="bold">
            {displayName}
          </Text>
        </HStack>
      </Box>
      <VStack align="stretch" spacing={0}>
        {!isTeacher && !isParent && (
          <MobileNavItem to={`/me`} fontWeight="normal">
            Profile
          </MobileNavItem>
        )}
        {isKid && isAchievementPageEnabled && (
          <MobileNavItem to={`/achievements`} fontWeight="normal">
            Achievements
          </MobileNavItem>
        )}
        {areKidInvitesEnabled && (
          <MobileNavItem as={Link} to="/invites" fontWeight="normal">
            Invites{" "}
            <Badge ml={2} color="brandFull.500" bgColor="brandFull.50">
              New
            </Badge>
          </MobileNavItem>
        )}
        {isKid && (
          <MobileNavItem to={`/my-paths`} fontWeight="normal">
            Viewing History
          </MobileNavItem>
        )}
        <MobileNavItem to={`/settings`} fontWeight="normal">
          Settings
        </MobileNavItem>
      </VStack>
      <Box pt={4} px={4} pb={4}>
        <Divider />
      </Box>
      <MobileNavItem to={`/sign-out`} fontWeight="normal">
        Sign Out
      </MobileNavItem>
    </Box>
  );
});

type MobileNavItemProps = {
  isActive?: boolean;
  iconRight?: ReactNode;
  nesting?: 0 | 1 | 2 | 3;
  to?: LinkProps["to"];
} & FlexProps;

const MobileNavItem = forwardRef<PropsWithChildren<MobileNavItemProps>, "div">(
  ({ isActive, children, iconRight, nesting = 0, ...props }, ref) => {
    return (
      <Flex
        as={MobileNavLink}
        align="center"
        justify="space-between"
        px={4}
        py={2}
        pl={nesting > 0 ? 4 + nesting * 4 : undefined}
        borderRadius="lg"
        minH={nesting > 0 ? "3rem" : "3.5rem"}
        bg="none"
        _hover={{
          bg: "none",
          cursor: "pointer",
          textDecoration: "none",
        }}
        ref={ref}
        fontSize="lg"
        fontWeight={nesting > 0 ? "normal" : "bold"}
        {...props}
      >
        <Text color="gray.900">{children}</Text>
        {iconRight}
      </Flex>
    );
  }
);

const MobileNavLink = forwardRef(({ hideMenu, ...props }, ref) => {
  const { onClose } = useContext(MobileMenuContext);

  return <Link onClick={onClose} ref={ref} {...props} />;
});
