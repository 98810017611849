import { useEffect, useMemo, useState } from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import { PathVisibility } from "@tract/common/dist/types/models/Path";

import {
  ButtonSolid,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  Flex,
  Grid,
  useBreakpointValue,
  useToast,
} from "Shared";

import { PathCard } from "Components/PathCard";

import { useSession } from "Services/session";

import { isKidUser } from "Types/User";
import { ViewSource } from "Types/Path";

import {
  ClassroomLearnerGroupFragment,
  ClassroomPathsQueryResult,
  ClassroomPathsQueryVariables,
} from "@tract/common/dist/graphql";
import { useQuery } from "urql";
import { CLASSROOM_PATHS_QUERY } from "./graphql";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { useClassContext } from "Components/LayoutClass";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};
const MAX_HITS_PER_PAGE = 21;

export const ClassroomPaths: React.FC<Props> = ({ classroom }) => {
  const { currentUser } = useSession();
  const { onClickInviteStudents } = useClassContext();
  const [offset, setOffset] = useState(0);

  const kids = useMemo(() => {
    return (
      classroom.members
        .filter(({ role }) => role === "learner")
        .map(({ userId }) => userId) || []
    );
  }, [classroom]);

  const toast = useToast();

  const [{ data, fetching: loading, error }] = useQuery<
    ClassroomPathsQueryResult["data"],
    ClassroomPathsQueryVariables
  >({
    pause: !kids.length,
    query: CLASSROOM_PATHS_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      offset: offset,
      limit: MAX_HITS_PER_PAGE,
      visibility: PathVisibility.OrgOnly,
      isPublished: true,
      authorIds: kids,
    },
  });

  const paths = data?.paths;

  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  useEffect(() => {
    setShowLoadMore(!((paths?.length || 0) % MAX_HITS_PER_PAGE));
  }, [paths]);

  useEffect(() => {
    if (!loading || error) {
      setLoadMoreLoading(false);
    }
  }, [loading, error, loadMoreLoading]);

  const handleLoadMore = async () => {
    if (paths) {
      setLoadMoreLoading(true);
      setOffset(paths.length);
    }
  };

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load paths.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const isClassStudent =
    isKidUser(currentUser) && kids.includes(currentUser.uid);

  const skeletonCount =
    useBreakpointValue({
      base: 3,
      md: 4,
      xl: 6,
      "2xl": 8,
    }) || 0;

  if (!loadMoreLoading && loading) {
    return (
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, minmax(0, 1fr))",
          xl: "repeat(3, minmax(0, 1fr))",
          "2xl": "repeat(4, minmax(0, 1fr))",
        }}
        gap={3}
      >
        {Array.from({ length: skeletonCount }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </Grid>
    );
  }

  if (!paths || paths?.length === 0) {
    return (
      <EmptyState
        icon={
          <EmptyStateFontAwesomeIcon icon={duotone("wand-magic-sparkles")} />
        }
        headline="No paths yet"
        body={
          isClassStudent
            ? "Create a path and you could be the first!"
            : "Paths published by students in this class will appear here"
        }
        cta={
          isClassStudent ? null : (
            <ButtonSolid size="lg" onClick={onClickInviteStudents}>
              Add Students
            </ButtonSolid>
          )
        }
      />
    );
  }

  return (
    <>
      {!!paths && paths?.length > 0 && (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, minmax(0, 1fr))",
            xl: "repeat(3, minmax(0, 1fr))",
            "2xl": "repeat(4, minmax(0, 1fr))",
          }}
          gap={3}
        >
          {paths.map((path) => (
            <PathCard
              key={path.id}
              path={path}
              source={ViewSource.TeacherClass}
            />
          ))}
        </Grid>
      )}
      {paths?.length && paths.length >= MAX_HITS_PER_PAGE && showLoadMore ? (
        <Flex alignItems="center" justifyContent="center" mt={4}>
          <ButtonSolid onClick={handleLoadMore} isLoading={loading}>
            Load More
          </ButtonSolid>
        </Flex>
      ) : null}
    </>
  );
};
