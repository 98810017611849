import React from "react";
import { GridProps } from "@chakra-ui/react";
import { format } from "date-fns";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { Box, Center, Flex, FontAwesomeIcon, Grid, Text } from "Shared";

type Props = {
  startDate: string;
  dueDate: string;
} & GridProps;

export const AssignDatesGrid: React.FC<Props> = ({
  startDate,
  dueDate,
  ...props
}) => {
  return (
    <Grid gridTemplateColumns="1fr 1fr" {...props}>
      <Flex>
        <Center flexBasis={8} mr={2}>
          <FontAwesomeIcon icon={regular("calendar-day")} size={8} />
        </Center>
        <Box flex={1}>
          <Text fontSize="sm">Start Date</Text>
          <Text fontWeight="bold">
            {startDate ? format(new Date(startDate), "LLL d, y") : "None"}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Center flexBasis={8} mr={2}>
          <FontAwesomeIcon icon={regular("calendar-download")} size={8} />
        </Center>
        <Box flex={1}>
          <Text fontSize="sm">Due Date</Text>
          <Text fontWeight="bold">
            {dueDate ? format(new Date(dueDate), "LLL d, y") : "None"}
          </Text>
        </Box>
      </Flex>
    </Grid>
  );
};
