import { FC, ReactNode } from "react";

import { Flex } from "Shared";

type Props = {
  renderStart: ReactNode;
  renderEnd: ReactNode;
};

export const TopNav: FC<Props> = ({ renderStart, renderEnd }) => {
  return (
    <Flex
      align="center"
      justify="space-between"
      position="sticky"
      top={0}
      width="100%"
      boxShadow="sm"
      height="4rem"
      zIndex={12}
      bg="white"
    >
      {renderStart}
      {renderEnd}
    </Flex>
  );
};
