import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  CreatePathNodeMutationResult,
  CreatorPathsQueryResult,
  CreatorPathsQueryVariables,
  GetPathWithNodesQueryResult,
  GetPathWithNodesQueryVariables,
  Order_By,
} from "@tract/common/dist/graphql";
import { GET_PATH_WITH_NODES_QUERY } from "Pages/CreateV4/graphql";
import { CREATOR_PATHS_QUERY } from "Pages/Author/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";

export const insert_path_node_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const node = (result as CreatePathNodeMutationResult["data"])
    ?.insert_path_node_one;
  const variables = args.object as GetPathWithNodesQueryVariables;
  cache.updateQuery<
    GetPathWithNodesQueryResult["data"],
    GetPathWithNodesQueryVariables
  >(
    {
      query: GET_PATH_WITH_NODES_QUERY,
      variables: {
        pathId: variables.pathId,
      },
    },
    (data) => {
      if (node) {
        data?.path?.nodes.push({
          ...node,
        });
      }
      return data;
    }
  );

  const cacheResult = cache.readQuery<
    GetPathWithNodesQueryResult["data"],
    GetPathWithNodesQueryVariables
  >({
    query: GET_PATH_WITH_NODES_QUERY,
    variables: { pathId: node?.pathId },
  });

  const path = cacheResult?.path;

  if (path) {
    cache.updateQuery<
      CreatorPathsQueryResult["data"],
      CreatorPathsQueryVariables
    >(
      {
        query: CREATOR_PATHS_QUERY,
        variables: {
          authorId: path.authorId,
          status: path.status,
          orderBy:
            path.status === PathStatus.Published
              ? { publishedAt: Order_By.Desc }
              : { createdAt: Order_By.Desc },
          limit: 20,
          offset: 0,
        },
      },
      (data) => {
        const pathIndex = data?.paths.findIndex((p) => p.id === node?.pathId);
        if (pathIndex !== undefined && pathIndex > -1) {
          data?.paths[pathIndex].nodes.push({ id: node?.id });
        }
        return data;
      }
    );
  }
};
