/**
 * Pauses all known video players on the page.
 */
export const pauseAllVideos = () => {
  // Pauses all HTML Video elements
  document.querySelectorAll("video").forEach((video) => video.pause());

  // Pauses API Videos
  document.querySelectorAll("iframe").forEach((iframe) => {
    if (!iframe.src?.includes("api.video")) return;

    const fragments = iframe.src.split(";");
    const idFragment = fragments.find((f) => f.includes("sdkPlayerId"));
    const sdkPlayerId = idFragment?.split(":")?.[1];

    iframe.contentWindow?.postMessage(
      { message: "pause", sdkPlayerId: sdkPlayerId || "1" },
      "*"
    );
  });
};
