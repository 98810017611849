import { gql } from "@apollo/client";

export const REJECT_PROJECT_MUTATION = gql`
  mutation RejectProject(
    $projectId: uuid!
    $userId: String!
    $coinAmount: Int!
    $coinTrxId: uuid!
    $rejectReason: String!
    $rejectFeedback: String
    $rejectedByUserId: String!
    $rejectedAt: timestamptz!
  ) {
    insert_coin_trx_one(
      object: {
        id: $coinTrxId
        amount: $coinAmount
        userId: $userId
        category: project_reject
      }
    ) {
      id
    }

    update_project_by_pk(
      pk_columns: { id: $projectId }
      _set: {
        rejectedAt: $rejectedAt
        rejectedByUserId: $rejectedByUserId
        rejectedReason: $rejectReason
        rejectedFeedback: $rejectFeedback
        rejectCoinTrxId: $coinTrxId
      }
    ) {
      id
      userId
      challengeId
      rejectedAt
      rejectedByUserId
      rejectedReason
      rejectedFeedback
      rejectCoinTrxId
    }
  }
`;

export const ADMIN_REJECTED_PROJECT_FRAGMENT = gql`
  fragment AdminRejectedProject on project {
    id
    rejectedAt
    userId
    rewardCoin {
      amount
    }
  }
`;

gql`
  ${ADMIN_REJECTED_PROJECT_FRAGMENT}
  query GetRejectedProjectById($projectId: uuid!) {
    project: project_by_pk(id: $projectId) {
      ...AdminRejectedProject
    }
  }
`;

gql`
  mutation UnrejectProject(
    $projectId: uuid!
    $refundAmount: Int!
    $refundUserId: String!
  ) {
    update_project_by_pk(
      pk_columns: { id: $projectId }
      _set: {
        rejectedAt: "-infinity"
        rejectedReason: null
        rejectedFeedback: null
        rejectedByUserId: null
        rejectCoinTrxId: null
      }
    ) {
      id
      rejectedAt
    }

    insert_coin_trx_one(
      object: {
        userId: $refundUserId
        amount: $refundAmount
        category: project_reject_refund
      }
    ) {
      id
    }
  }
`;
