import React, { FC, PropsWithChildren } from "react";
import { Avatar, forwardRef } from "@chakra-ui/react";
import { formatDistanceStrict } from "date-fns";
import { Box, Flex, Grid, Text } from "Shared";
import { UserAvatar } from "Components/UserAvatar";
import { ProfilePopover } from "Components/ProfilePopover";
import { Dot } from "./Dot";

import { NotificationUserFragment } from "@tract/common/dist/graphql";
import { getDisplayName } from "Types/User";
import { MOD_AVATAR_URL } from "Constants/userTypes";

export interface NotificationProps {
  user?: NotificationUserFragment | null | undefined;
  isRead: boolean;
  isMod?: boolean;
  timestamp: Date;
  onClick: (e?: React.MouseEvent) => void;
  aggregate?: {
    count: number;
  };
}

export const Notification: FC<PropsWithChildren<NotificationProps>> =
  forwardRef(({ user, isRead, isMod, timestamp, onClick, children }, ref) => {
    return (
      <Grid
        as="button"
        textAlign="left"
        alignItems="start"
        borderRadius="xl"
        cursor="pointer"
        onClick={onClick}
        bg={isRead ? undefined : "gray.50"}
        _hover={{ bg: isRead ? "gray.50" : "gray.100" }}
        _focus={{ boxShadow: "outline", outline: 0 }}
        transition="150ms ease background-color"
        position="relative"
        templateColumns="auto 1fr"
        ref={ref}
      >
        <Box p={2}>
          {isMod ? (
            <Avatar
              src={MOD_AVATAR_URL}
              size="lg"
              display="block"
              onClick={(e: any) => e.stopPropagation()}
            />
          ) : (
            user && (
              <ProfilePopover
                userId={user?.firestoreId}
                triggerElement={
                  <UserAvatar
                    userId={user?.firestoreId}
                    username={user?.username || undefined}
                    avatarURL={user?.avatar || undefined}
                    display="block"
                    onClick={(e: any) => e.stopPropagation()}
                    size="lg"
                    isTeacher={user?.isEducator}
                    name={getDisplayName(user)}
                  />
                }
              />
            )
          )}
        </Box>
        <Flex align="center" justify="space-between">
          <Box pl={2} pr={4} py={4}>
            {children}
            <Text mt={1} fontSize="sm" color="gray.600">
              {formatDistanceStrict(timestamp, new Date())} ago
            </Text>
          </Box>

          {!isRead && (
            <Flex p={2} align="center" justify="center">
              <Flex height="full" mr={2} align="center" justify="center">
                <Dot />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Grid>
    );
  });
