import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import { PageHeader } from "Components/PageHeader";
import { AbuseReports } from "./AbuseReports";
import { AdminContent } from "../AdminContent";

export const AdminAbuseReports: React.FC = () => {
  return (
    <AdminContent>
      <PageHeader title="Reports" />
      <Switch>
        <Route path="/admin/reports" exact>
          <AbuseReports />
        </Route>
      </Switch>
    </AdminContent>
  );
};
