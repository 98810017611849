import { gql } from "urql";

export const ADMIN_COMMENT_FRAGMENT = gql`
  fragment AdminComment on comment {
    id
    text
    giphyId
    createdAt
    updatedAt
    deletedAt
    published
    path
    user {
      id: firestoreId
      avatar
      username
      firstName
      lastName
      invitedBy {
        username
      }
    }
    projectComment {
      projectId
      commentId
      awardId
    }
    pathReviewComment {
      id
      pathId
      commentId
    }
    pathNodeComment {
      pathNodeId
      commentId
      node {
        id
        path {
          id
          authorId
        }
      }
    }
  }
`;

export const ADMIN_COMMENTS_QUERY = gql`
  ${ADMIN_COMMENT_FRAGMENT}

  query AdminComments($where: comment_bool_exp!, $limit: Int!, $offset: Int!) {
    comments: comment(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { updatedAt: desc }
    ) {
      ...AdminComment
    }
  }
`;

export const ADMIN_PARENT_COMMENT_QUERY = gql`
  query ParentComment($id: uuid!) {
    comment_by_pk(id: $id) {
      userId
      id
    }
  }
`;

export const ADMIN_UPDATE_COMMENT_STATUS_MUTATION = gql`
  mutation AdminUpdateCommentStatus(
    $commentId: uuid!
    $deletedAt: timestamptz!
    $published: Boolean!
  ) {
    update_comment_by_pk(
      pk_columns: { id: $commentId }
      _set: { deletedAt: $deletedAt, published: $published }
    ) {
      id
      deletedAt
      published
    }
  }
`;
