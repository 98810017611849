export const Accordion = {
  variants: {
    enclosed: {
      container: {
        borderWidth: "1px",
        borderRadius: "2xl",
        borderColor: "gray.300",
      },
      panel: {
        py: { base: 4, md: 6 },
        px: { base: 4, md: 6 },
      },
      button: {
        px: { base: 4, md: 6 },
        py: { base: 4, md: 6 },
        borderRadius: "xl",
        _hover: {
          bg: "transparent",
        },
      },
    },
    ghost: {
      container: {
        _first: { borderTopWidth: 0 },
        _last: { borderBottomWidth: 0 },
      },
      panel: {
        pt: 4,
        px: 4,
        pb: 6,
      },
      button: {
        px: 4,
        py: 4,
        h: "4rem",
        fontSize: "xl",
        fontWeight: "bold",
        borderRadius: "none",
        _hover: {
          bg: "transparent",
          color: "brandPrimary.500",
        },
        _focusVisible: { boxShadow: "outlineInset" },
      },
    },
  },
};
