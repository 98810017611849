import { useCallback } from "react";
import { Field, FieldProps, Form, useFormikContext } from "formik";

import { GetPathWithNodesQuery } from "@tract/common/dist/graphql";
import {
  Text,
  Box,
  Checkbox,
  Switch,
  VStack,
  CheckboxGroup,
  Grid,
  ModalFooter,
  ModalBody,
  ButtonSolid,
  ButtonOutlined,
  FormControl,
  FormLabel,
  Select,
  FormHelperText,
  Alert,
} from "Shared";

import { TeammatesSettings } from "../TeammateSettings";
import {
  PATH_GRADE_LEVEL,
  PATH_INTEREST_AREAS,
  PATH_SKILLS,
  PATH_SUBJECT_V2,
} from "Constants/paths";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";

import { TriggerType } from ".";
import { StepBox } from "./StepBox";
import PathTags from "Pages/PathBuilder/PathTags";
import { CreatorLevel } from "Types/User";

export const StepMetaData: React.FC<{
  path: GetPathWithNodesQuery["path"];
  triggerType: TriggerType;
  onChangeStep: (step: number) => void;
  onSaveStart: () => void;
  onSaveComplete: () => void;
  disabled: boolean;
}> = ({
  path,
  triggerType,
  onChangeStep,
  onSaveStart,
  onSaveComplete,
  disabled,
}) => {
  const { handleSubmit, setFieldValue } = useFormikContext();
  const { currentUser, isAdmin } = useSession();

  const onNewTagsChange = useCallback(
    (theTags: TODO[]) => setFieldValue("newTags", theTags),
    [setFieldValue]
  );
  const onTagsChange = useCallback(
    (theTags: TODO[]) => setFieldValue("tags", theTags),
    [setFieldValue]
  );

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
      onSubmit={handleSubmit}
    >
      <ModalBody>
        <StepBox>
          <Text fontSize="2xl" fontWeight="bold" mb={6}>
            Make your path discoverable to more viewers
          </Text>
          <VStack spacing={6} w="full" align="stretch">
            <Field name="interestArea">
              {({ field }: FieldProps) => (
                <FormControl>
                  <FormLabel>Category</FormLabel>
                  <Select
                    {...field}
                    placeholder="Select a category"
                    name="interestArea"
                    size="lg"
                    borderRadius="lg"
                    isDisabled={disabled}
                  >
                    {PATH_INTEREST_AREAS.map(({ emoji, label, value }, i) => (
                      <option key={i} value={value}>
                        {emoji} {label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field name="tags">
              {() => (
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  {path && (
                    <PathTags
                      path={path}
                      onTagsChange={onTagsChange}
                      onNewTagsChange={onNewTagsChange}
                      onError={(err: any) => captureException(err)}
                      disabled={disabled}
                    />
                  )}
                  <FormHelperText>
                    Tag your path to make it easier to find
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            {!currentUser.orgId &&
            currentUser.creatorLevel !== CreatorLevel.Partner ? null : (
              <TeammatesSettings
                onSaveStart={onSaveStart}
                onSaveComplete={onSaveComplete}
                disabled={disabled}
              />
            )}
            {isAdmin && <AdminMetaFields path={path} />}
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <ButtonOutlined
          size="lg"
          minWidth="10rem"
          onClick={() => onChangeStep(1)}
        >
          Back
        </ButtonOutlined>
        <ButtonSolid minW="10rem" size="lg" onClick={() => onChangeStep(3)}>
          Next
        </ButtonSolid>
      </ModalFooter>
    </Form>
  );
};

const AdminMetaFields: React.FC<{ path: GetPathWithNodesQuery["path"] }> = ({
  path,
}) => {
  const isPathPrivacyEnabled = !!path?.user?.pathPrivacy;

  return (
    <>
      <Field name="subjects">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl mb={6}>
            <FormLabel mb={3}>Subject Areas</FormLabel>
            <CheckboxGroup
              onChange={(subjects) => setFieldValue("subjects", subjects)}
              value={field.value}
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                columnGap={4}
                rowGap={3}
                fontSize="md"
              >
                {PATH_SUBJECT_V2.map(
                  ({ emoji, standardizedShortLabel, value }, i) => (
                    <Checkbox
                      name="subjects"
                      size="lg"
                      key={i}
                      value={value}
                      fontWeight="normal"
                    >
                      <Box as="span" aria-hidden="true" mr={2}>
                        {emoji}
                      </Box>
                      {standardizedShortLabel}
                    </Checkbox>
                  )
                )}
              </Grid>
            </CheckboxGroup>
          </FormControl>
        )}
      </Field>
      <Field name="gradeLevels">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl mb={6}>
            <FormLabel>Grade Level(s)</FormLabel>
            <CheckboxGroup
              onChange={(gradeLevels) =>
                setFieldValue("gradeLevels", gradeLevels)
              }
              value={field.value}
            >
              <Grid templateColumns="repeat(4, 1fr)" columnGap={4} rowGap={2}>
                {PATH_GRADE_LEVEL.map((level, i) => (
                  <Checkbox
                    name="gradeLevels"
                    size="lg"
                    key={i}
                    value={level.value}
                    fontWeight="normal"
                  >
                    {level.label}
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          </FormControl>
        )}
      </Field>
      <Field name="skills">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl mb={6}>
            <FormLabel mb={3}>Skills</FormLabel>
            <CheckboxGroup
              onChange={(v) => setFieldValue("skills", v)}
              value={field.value}
            >
              <Grid templateColumns="repeat(2, 1fr)" columnGap={4} rowGap={3}>
                {PATH_SKILLS.map(({ emoji, label, value }, i) => (
                  <Checkbox
                    name="skills"
                    size="lg"
                    key={i}
                    value={value}
                    fontWeight="normal"
                  >
                    <Box as="span" aria-hidden="true" mr={2}>
                      {emoji}
                    </Box>
                    {label}
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          </FormControl>
        )}
      </Field>
      <Field name="xpMultiplier">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl mb={6}>
            <FormLabel htmlFor="xpMultiplier">XP Boost</FormLabel>
            <Select
              size="lg"
              value={field.value}
              name="xpMultiplier"
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setFieldValue("xpMultiplier", parseInt(e.target.value, 10))
              }
            >
              <option value={1}>Default (1x)</option>
              <option value={2}>2x</option>
              <option value={3}>3x</option>
            </Select>
          </FormControl>
        )}
      </Field>
      {isPathPrivacyEnabled && (
        <Alert status="warning">
          Fields below are disabled due to user privacy settings
        </Alert>
      )}
      <Field name="isVisibleUnauthed">
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <FormControl mb={6}>
            <FormLabel htmlFor="isVisibleUnauthed">
              External Visibility
            </FormLabel>
            <Checkbox
              name="isVisibleUnauthed"
              isChecked={field.value}
              onChange={() => setFieldValue("isVisibleUnauthed", !field.value)}
              fontWeight="normal"
              isDisabled={isPathPrivacyEnabled}
            >
              Show path to un-authenticated website visitors. You must first
              obtain parent/teacher permission to do so.
            </Checkbox>
          </FormControl>
        )}
      </Field>
      <Field name="inTopPicks" type="checkbox">
        {({ field }: FieldProps) => (
          <FormControl>
            <FormLabel>Top Picks</FormLabel>
            <Switch
              {...field}
              isChecked={field.checked}
              isDisabled={isPathPrivacyEnabled}
            />
          </FormControl>
        )}
      </Field>
      <Field name="isTractOriginal" type="checkbox">
        {({ field }: FieldProps) => (
          <FormControl>
            <FormLabel>Tract Original</FormLabel>
            <Switch
              isChecked={field.checked}
              {...field}
              isDisabled={isPathPrivacyEnabled}
            />
          </FormControl>
        )}
      </Field>
    </>
  );
};
