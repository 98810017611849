import { FC } from "react";

import {
  AspectRatio,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Text,
} from "Shared";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  imageURL: string;
  subHead?: string;
};

export const UserAwardModal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  subTitle,
  imageURL,
  subHead,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={10}>
          <AspectRatio
            ratio={1}
            height={{ base: "8rem", md: "12rem" }}
            width={{ base: "8rem", md: "12rem" }}
            mx="auto"
            mb={6}
          >
            <Image src={imageURL} />
          </AspectRatio>
          {subHead && (
            <Text
              fontSize={{ base: "xs", md: "sm" }}
              color="gray.600"
              textAlign="center"
              mb={{ base: 1, md: 2 }}
            >
              {subHead}
            </Text>
          )}
          <Text
            fontWeight="bold"
            fontSize={{ base: "xl", md: "2xl" }}
            textAlign="center"
            mb={{ base: 1, md: 2 }}
          >
            {title}
          </Text>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color="gray.600"
            textAlign="center"
          >
            {subTitle}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
