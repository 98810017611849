import React from "react";
import { Button, Center } from "Shared";

import {
  GetNodeCommentsQuery,
  GetNodeCommentsQueryVariables,
  NodeCommentFragment,
} from "@tract/common/dist/graphql";
import { Values as CommentFormValues } from "./CommentForm";
import { Values as CommentEditFormValues } from "./CommentEditForm";
import { CommentItem } from "./CommentItem";
import { CommentSkeleton } from "./CommentSkeleton";
import { GET_NODE_COMMENTS_QUERY } from "./graphql";
import { useOffsetPaginationQuery } from "Utils";
import { useSession } from "Services/session";

type Props = {
  nodeId: string;
  handleCreateComment: (v: CommentFormValues) => Promise<void>;
  handleCommentError: any;
  parentPath?: string;
  handleEditComment: (
    v: CommentEditFormValues,
    nodeComment: NodeCommentFragment
  ) => Promise<void>;
  handleDeleteComment: (nodeComment: NodeCommentFragment) => Promise<void>;
  count?: number;
};

export const COMMENTS_PER_PAGE = 10;

export const CommentList: React.FC<Props> = ({
  nodeId,
  parentPath,
  handleCreateComment,
  handleCommentError,
  handleEditComment,
  handleDeleteComment,
  count = 3,
}) => {
  const { currentUser } = useSession();
  const isReply = !!parentPath;
  const matchPath = { _matches: isReply ? `${parentPath}.*{1}` : "*{1}" };
  const {
    response: [{ data, fetching: loadingComments }],
    pagination,
  } = useOffsetPaginationQuery<
    GetNodeCommentsQuery,
    GetNodeCommentsQueryVariables
  >({
    query: GET_NODE_COMMENTS_QUERY,
    requestPolicy: isReply ? "network-only" : "cache-and-network",
    variables: {
      nodeId: nodeId,
      limit: COMMENTS_PER_PAGE,
      offset: 0,
      path: matchPath,
    },
    field: "nodeComments",
  });
  const comments = data?.nodeComments || [];

  if (loadingComments) {
    return (
      <>
        {[...Array(count)].map((_, i) => (
          <CommentSkeleton key={i} />
        ))}
      </>
    );
  }

  return (
    <>
      {comments
        ?.filter((c) => c.comment?.deletedAt === "-infinity")
        ?.filter(
          (c) => c.comment?.published || c.comment?.user.id === currentUser.id
        )
        .map((comment) => (
          <CommentItem
            key={comment.commentId}
            nodeComment={comment}
            createCommentHandler={handleCreateComment}
            createCommentErrorHandler={handleCommentError}
            editCommentHandler={handleEditComment}
            deleteCommentHandler={handleDeleteComment}
          />
        ))}
      {pagination.loadingMore && (
        <>
          {[...Array(count)].map((i) => (
            <CommentSkeleton key={i} />
          ))}
        </>
      )}
      {pagination.hasNextPage && !!comments.length && (
        <Center mt={6}>
          <Button
            variant="outline"
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </Button>
        </Center>
      )}
    </>
  );
};
