import firebase from "firebase/compat";

export enum ReportType {
  Project = "project",
  Comment = "comment",
  Profile = "profile",
}

export enum ReportStatus {
  Pending = "Pending",
  Confirmed = "Confirmed",
  Dismissed = "Dismissed",
}

export enum ReportReasonTypes {
  Invalid = "Invalid",
  Offensive = "Offensive",
  Plagiarised = "Plagiarised",
  Other = "Other",
}

export const ReportReasons = {
  [ReportReasonTypes.Invalid]: "Does not satisfy challenge prompt",
  [ReportReasonTypes.Offensive]: "Offensive or inappropriate content",
  [ReportReasonTypes.Plagiarised]: "Appears to be plagiarized",
  [ReportReasonTypes.Other]: "Other",
};

export type Report = {
  type: ReportType;
  reason: ReportReasonTypes;
  status: ReportStatus;
  reporterId: string;
  reporteeId: string;
  meta?: {
    projectId?: string;
    commentId?: string;
  };
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
};
