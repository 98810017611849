import { PageHeader } from "Components/PageHeader";
import React, { FC, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { LivestreamsTable } from "./LivestreamsTable";

import { ButtonSolid, IconPlus, Link, TabList, TabLink, Tabs } from "Shared";

import { AdminContent } from "../AdminContent";

export const AdminLivestreams: FC = () => {
  const TAB_INDEXES: { [pathname: string]: number } = {
    "/admin/livestreams/upcoming": 0,
    "/admin/livestreams/past": 1,
  };
  const { pathname } = useLocation();
  function handleTabChange(i: number) {
    setTabIndex(i);
  }

  const [tabIndex, setTabIndex] = useState(TAB_INDEXES[pathname]);

  return (
    <AdminContent>
      <Tabs isManual index={tabIndex} onChange={handleTabChange}>
        <PageHeader
          title="Livestreams"
          renderActions={
            <ButtonSolid
              as={Link}
              to="/admin/livestreams/create"
              leftIcon={<IconPlus />}
              size="lg"
            >
              Create
            </ButtonSolid>
          }
          renderTabs={
            <TabList>
              <TabLink to="/admin/livestreams/upcoming">Upcoming</TabLink>
              <TabLink to="/admin/livestreams/past">Past</TabLink>
            </TabList>
          }
        />
        <Switch>
          <Route path="/admin/livestreams/upcoming">
            <LivestreamsTable upcoming={true} />
          </Route>
          <Route path="/admin/livestreams/past">
            <LivestreamsTable upcoming={false} />
          </Route>
        </Switch>
      </Tabs>
    </AdminContent>
  );
};
