import firebase from "firebase/compat";
import { useState } from "react";
import { captureException } from "./errors";

type FileConfig = {
  path: string;
  storage: firebase.storage.Storage;
};

export function useFileDelete(config: FileConfig) {
  const storageRef = config.storage.ref(config.path);

  const deleteFile = async (filename: string) => {
    try {
      await storageRef.child(filename).delete();
    } catch (error: any) {
      captureException(error);
    }
  };

  return { deleteFile };
}

export function useFileUpload(config: FileConfig) {
  const [file, setFile] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [fileMetadata, setFileMetadata] = useState({});
  const storageRef = config.storage.ref(config.path);

  const handleUploadStart = () => setIsUploading(true);
  const handleUploadError = (error: Error) => {
    setIsUploading(false);
    captureException(error);
  };
  const handleProgress = (progress: number) => setProgress(progress);
  const handleUploadSuccess = (filename: string) => {
    setFile(filename);
    setProgress(100);
    setIsUploading(false);

    storageRef
      .child(filename)
      .getDownloadURL()
      .then((url) => setFileUrl(url));

    storageRef
      .child(filename)
      .getMetadata()
      .then((metadata) => setFileMetadata(metadata));
  };
  const reset = () => {
    setFile("");
    setIsUploading(false);
    setProgress(0);
    setFileUrl("");
    setFileMetadata({});
  };

  return {
    file,
    isUploading,
    progress,
    fileUrl,
    fileMetadata,
    storageRef,
    handleUploadStart,
    handleUploadError,
    handleProgress,
    handleUploadSuccess,
    reset,
  };
}
