import React, { FC } from "react";

import { Table, Thead, Tbody, Th, Tr, ButtonSolid, Center } from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

import { PrizeRedemptionsRow } from "./PrizeRedemptionsRow";

import { ADMIN_SHOP_PURCHASES } from "./graphql";
import {
  AdminShopPurchasesQuery,
  AdminShopPurchasesQueryVariables,
} from "@tract/common/dist/graphql";
import { useOffsetPaginationQuery } from "Utils";

type Props = {};

const PER_PAGE = 50;

export const PrizeRedemptionsTable: FC<Props> = () => {
  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<
    AdminShopPurchasesQuery,
    AdminShopPurchasesQueryVariables
  >({
    query: ADMIN_SHOP_PURCHASES,
    variables: {
      limit: PER_PAGE,
      offset: 0,
    },
    field: "purchases",
  });

  const purchases = data?.purchases || [];

  if (!pagination.loadingMore && fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!purchases) {
    return null;
  }

  return (
    <>
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Prize</Th>
            <Th>Paid</Th>
            <Th>Submitted</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {purchases?.map((purchase) => (
            <PrizeRedemptionsRow key={purchase.id} redemption={purchase} />
          ))}
        </Tbody>
      </Table>
      {pagination.hasNextPage && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </>
  );
};
