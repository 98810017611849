import { GradeLevel } from "@tract/common/dist/types/models/Organization";

export const gradeLevelList = [
  {
    label: "Primary/Elementary School",
    value: GradeLevel.Primary,
  },
  {
    label: "Intermediate/Middle School",
    value: GradeLevel.Intermediate,
  },
  {
    label: "Secondary/High School",
    value: GradeLevel.Secondary,
  },
  {
    label: "Vocational",
    value: GradeLevel.Vocational,
  },
  {
    label: "College",
    value: GradeLevel.College,
  },
];
