import { Timestamp } from "Services/firebase";

export type Achievement = {
  title: string;
  descriptionNotAwarded: string;
  descriptionAwarded: string;
  category: AchievementCategory;
  imageURL: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export enum AchievementCategory {
  Trophies = "trophies",
}
