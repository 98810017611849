import React from "react";

import { Flex, FormControl, FormLabel, Select, Switch, VStack } from "Shared";
import { useVideoEditorContext } from "..";

import { VuMeter } from "../VuMeter";

type Props = {};

export const Settings: React.FC<Props> = () => {
  const {
    audioDevice,
    videoDevice,
    videoDevices,
    audioDevices,
    setAudioDevice,
    setVideoDevice,
    isCountdownEnabled,
    toggleCountdown,
  } = useVideoEditorContext();

  const handleVideoSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceId = e.target.value;
    const device = videoDevices.find((d) => d.deviceId === deviceId);
    if (device) {
      setVideoDevice(device);
    }
  };

  const handleAudioSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const deviceId = e.target.value;
    const device = audioDevices.find((d) => d.deviceId === deviceId);
    if (device) {
      setAudioDevice(device);
    }
  };

  return (
    <VStack spacing={6}>
      <FormControl>
        <FormLabel>Camera</FormLabel>
        <Select
          size="lg"
          disabled={!videoDevices.length}
          onChange={handleVideoSelect}
          value={videoDevice?.deviceId || videoDevices?.[0]?.deviceId}
        >
          {videoDevices.map(({ deviceId, label }) => (
            <option key={deviceId} value={deviceId}>
              {label}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Audio</FormLabel>
        <Select
          size="lg"
          disabled={!audioDevices.length}
          onChange={handleAudioSelect}
          value={audioDevice?.deviceId || audioDevices?.[0]?.deviceId}
        >
          {audioDevices.map(({ deviceId, label }) => (
            <option key={deviceId} value={deviceId}>
              {label}
            </option>
          ))}
        </Select>
      </FormControl>

      {audioDevice && <VuMeter audioDevice={audioDevice} />}

      <FormControl>
        <Flex justifyContent="space-between" alignContent="center">
          <FormLabel>Countdown Timer</FormLabel>
          <Switch
            size="lg"
            onChange={toggleCountdown}
            isChecked={isCountdownEnabled}
          />
        </Flex>
      </FormControl>
    </VStack>
  );
};
