import { createIcon } from "@chakra-ui/react";

export const IconFilledCamera = createIcon({
  displayName: "IconFilledCamera",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2C8.66565 2 8.35342 2.1671 8.16795 2.4453L6.46482 5H3C2.20435 5 1.44129 5.31607 0.87868 5.87868C0.316071 6.44129 0 7.20435 0 8V19C0 19.7957 0.316071 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22H21C21.7957 22 22.5587 21.6839 23.1213 21.1213C23.6839 20.5587 24 19.7957 24 19V8C24 7.20435 23.6839 6.44129 23.1213 5.87868C22.5587 5.31607 21.7957 5 21 5H17.5352L15.8321 2.4453C15.6466 2.1671 15.3344 2 15 2H9ZM9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13ZM12 8C9.23858 8 7 10.2386 7 13C7 15.7614 9.23858 18 12 18C14.7614 18 17 15.7614 17 13C17 10.2386 14.7614 8 12 8Z"
    />
  ),
});
