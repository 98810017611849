import React, { useState } from "react";

import { Box, Link, usePrefersReducedMotion } from "Shared";

import { GiphyGif } from "Components/GiphyGif";

interface Props {
  giphyId: string | undefined;
}

export const GiphyComment: React.FC<Props> = ({ giphyId }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const [showGif, setShowGif] = useState(false);

  function toggleShowGif() {
    setShowGif((showGif) => !showGif);
  }

  function shouldShowGif() {
    if (prefersReducedMotion && !showGif) {
      return false;
    }

    return true;
  }

  return (
    <>
      {prefersReducedMotion && !showGif && (
        <Box my={2}>
          <Link
            as="button"
            color="brand"
            fontWeight="bold"
            onClick={toggleShowGif}
          >
            Show Animated Comment
          </Link>
        </Box>
      )}
      {shouldShowGif() && giphyId && (
        <>
          <Box mt={2} pointerEvents="none">
            <GiphyGif giphyId={giphyId} width={296} />
          </Box>
          <Box>
            {prefersReducedMotion && (
              <Link
                as="button"
                color="brand"
                fontWeight="bold"
                onClick={toggleShowGif}
                mt={2}
              >
                Hide
              </Link>
            )}
          </Box>
        </>
      )}
    </>
  );
};
