import { useMemo } from "react";

import { InterestAreaItem, PATH_INTEREST_AREAS } from "Constants/paths";
import { Path as PathGQL } from "@tract/common/dist/graphql";
import { gql } from "@apollo/client";

export function useInterestArea(
  path: Partial<PathGQL> | Pick<PathGQL, "interestArea">
): InterestAreaItem {
  const interestArea = useMemo(() => {
    return PATH_INTEREST_AREAS.filter(
      (option) => option.value === path.interestArea
    )[0];
  }, [path]);

  return interestArea;
}

/**
 * Provide author details with user document as fallback
 */
export const USER_FRAGMENT = gql`
  fragment User on user {
    firestoreId
    username
    avatar
    firstName
    lastName
  }
`;

export const USER_BY_ID_QUERY = gql`
  ${USER_FRAGMENT}

  query UserById($userId: String!) {
    users: user(limit: 1, where: { firestoreId: { _eq: $userId } }) {
      ...User
    }
  }
`;
