import { mode, transparentize, getColor } from "@chakra-ui/theme-tools";

type Dict = Record<string, any>;

function getBg(props: Dict) {
  const { theme, colorScheme: c } = props;
  const lightBg = getColor(theme, `${c}.100`, c);
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return mode(lightBg, darkBg)(props);
}

function getTextColor(props: Dict) {
  const { colorScheme: c } = props;
  return mode(`${c}.700`, `${c}.700`);
}
function variantSubtle(props: Dict) {
  return {
    container: { bg: getBg(props), color: getTextColor(props) },
    title: {
      ml: 3,
    },
  };
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.300`, `white`)(props);
  return {
    container: {
      bg: getBg(props),
      color: getTextColor(props),
      border: "1px solid",
      borderColor: c === "white" ? borderColor : "currentColor",
      boxShadow: "sm",
    },
  };
}

export const Alert = {
  variants: {
    subtle: variantSubtle,
    outline: variantOutline,
  },
};
