import { FC, useEffect, useState } from "react";
import { format, formatDistanceStrict } from "date-fns";
import { lookup } from "mime-types";

import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Image,
  Link,
  Markdown,
  Table,
  Tbody,
  Text,
  Tr,
  Td,
  VideoPlayer,
  DrawerFooter,
  useDisclosure,
  Flex,
} from "Shared";

import { storage } from "Services/firebase";
import { captureException } from "Services/errors";

import { useSession } from "Services/session";
import { SubmissionStatusBadge } from "../SubmissionStatusBadge";

import { RejectSubmissionModal } from "./RejectSubmissionModal";

import { isAuthorUser } from "Types/User";
import {
  AdminProjectFragment,
  ProjectHistoryProjectFragment,
  useGetPathByProjectIdQuery,
  useProjectHistoryQuery,
} from "@tract/common/dist/graphql";
import { UnrejectModal } from "./UnrejectSubmissionModal";
import { ReportReasons, ReportReasonTypes } from "Types/AbuseReport";
import { LayoutCentered } from "Components/LayoutCentered";

type Props = {
  isOpen: boolean;
  onClose: (rejectedSubId?: string) => void;
  project: AdminProjectFragment;
  onApprove?: (project: AdminProjectFragment) => void;
  onReject?: (project: AdminProjectFragment) => void;
};

const HISTORY_LIMIT = 10;

export const SubmissionDrawer: FC<Props> = ({
  isOpen,
  onClose,
  project,
  onApprove,
  onReject = () => {},
}) => {
  const { data, loading } = useGetPathByProjectIdQuery({
    variables: {
      projectId: project.id,
    },
  });

  const isAcceptedProject = project.rejectedAt === "-infinity";
  const node = data?.project?.node;
  const path = node?.path;
  const projectMime = lookup(project.file?.filename || "") || "";

  const [previewURL, setPreviewURL] = useState("");
  const { currentUser, isAdmin } = useSession();
  const {
    isOpen: isRejectModalOpen,
    onOpen: openRejectModal,
    onClose: onCloseRejectModal,
  } = useDisclosure();
  const {
    isOpen: isUnrejectModalOpen,
    onOpen: openUnrejectModal,
    onClose: closeUnrejectModal,
  } = useDisclosure();

  useEffect(() => {
    async function getPreviewURL() {
      let previewURL = "";
      try {
        const ref = storage("ugc").ref(
          `uf/${project.file?.id}/${project.file?.filename}`
        );
        previewURL = await ref.getDownloadURL();
      } catch (err: any) {
        captureException(err);
      }
      setPreviewURL(previewURL);
    }
    getPreviewURL();
  }, [project]);

  const { data: projectHistoryData } = useProjectHistoryQuery({
    fetchPolicy: "no-cache",
    variables: {
      userId: project.userId,
      nodeId: project.pathNodeId,
      limit: HISTORY_LIMIT,
    },
  });

  const projectHistoryProjects = projectHistoryData?.projects;

  if (loading) {
    return <LayoutCentered isLoading height="auto" />;
  }

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>View Submission</DrawerHeader>
          <DrawerBody pb={10} visibility={loading ? "hidden" : "visible"}>
            {projectMime.startsWith("image") && (
              <Image w="100%" src={previewURL} />
            )}
            {projectMime.startsWith("video") && (
              <VideoPlayer borderRadius={0} url={previewURL} />
            )}
            {project.text && (
              <Box
                as="blockquote"
                borderLeft="0.25rem solid"
                borderColor="gray.300"
                bg="gray.50"
                py={6}
                px={10}
              >
                <Text fontSize="lg">"{project.text}"</Text>
              </Box>
            )}
            <Table w="100%" mt={6}>
              <Tbody>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Challenge Prompt
                  </Td>
                  <Td>
                    <Text>{node?.title}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Instructions
                  </Td>
                  <Td>
                    <Markdown source={node?.caption || ""} />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    {isAdmin ? "Username" : "Learner"}
                  </Td>
                  <Td>
                    <Link to={`/@${project.user.username}`} color="brand">
                      {isAdmin || isAuthorUser(currentUser)
                        ? project.user.username
                        : `${project.user.firstName} ${
                            project.user.lastName || ""
                          }`}
                    </Link>
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Submitted at
                  </Td>
                  <Td>
                    {format(new Date(project.createdAt), "h:mm a, MMM d, yyyy")}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Status
                  </Td>
                  <Td>
                    <SubmissionStatusBadge submission={project} />
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Path
                  </Td>
                  <Td>
                    <Link to={`/path/${path?.slug}`} color="brand">
                      {path?.title}
                    </Link>
                  </Td>
                </Tr>
                {isAdmin && (
                  <Tr>
                    <Td
                      fontWeight="bold"
                      pr={10}
                      verticalAlign="top"
                      whiteSpace="nowrap"
                    >
                      Path ID
                    </Td>
                    <Td>{path?.id}</Td>
                  </Tr>
                )}
                <Tr>
                  <Td
                    fontWeight="bold"
                    pr={10}
                    verticalAlign="top"
                    whiteSpace="nowrap"
                  >
                    Challenge
                  </Td>
                  <Td>
                    <Link
                      to={`/path/${path?.slug}/v/${node?.id}`}
                      color="brand"
                    >
                      {node?.title}
                    </Link>
                  </Td>
                </Tr>
                {!!projectHistoryProjects?.length && (
                  <Tr>
                    <Td
                      fontWeight="bold"
                      pr={10}
                      verticalAlign="top"
                      whiteSpace="nowrap"
                      borderBottom={0}
                    >
                      History
                    </Td>
                    <Td borderBottom={0}>
                      {projectHistoryProjects.map((project) => (
                        <HistoryItem key={project.id} project={project} />
                      ))}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </DrawerBody>
          <DrawerFooter justifyContent="center">
            <ButtonGroup mb={4}>
              {project.id && (
                <Button
                  as={Link}
                  to={`/project/${project.id}`}
                  variant="outline"
                  size="lg"
                >
                  View Project
                </Button>
              )}
              {isAcceptedProject && (
                <>
                  {isAdmin && onApprove && (
                    <Button
                      variant="solid"
                      colorScheme="green"
                      size="lg"
                      onClick={() => onApprove(project)}
                    >
                      Approve
                    </Button>
                  )}
                  <Button
                    variant="solid"
                    colorScheme="red"
                    size="lg"
                    onClick={openRejectModal}
                  >
                    Request Changes
                  </Button>
                </>
              )}
              {!isAcceptedProject && (
                <Button
                  variant="solid"
                  colorScheme="brandFull"
                  size="lg"
                  onClick={openUnrejectModal}
                >
                  Cancel Request
                </Button>
              )}
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {!isAcceptedProject && (
        <UnrejectModal
          projectId={project.id}
          isOpen={isUnrejectModalOpen}
          onClose={() => {
            closeUnrejectModal();
            onClose();
          }}
        />
      )}

      {node && (
        <RejectSubmissionModal
          node={node}
          submission={project}
          isOpen={isRejectModalOpen}
          onClose={(rejected) => {
            onCloseRejectModal();
            if (rejected === true) {
              // close drawer as well on rejection
              onReject(project);
              onClose(project.id);
            }
          }}
        />
      )}
    </>
  );
};

type HistoryItemProps = {
  project: ProjectHistoryProjectFragment;
};

const HistoryItem: React.FC<HistoryItemProps> = ({ project }) => {
  const isRejected = project.rejectedAt !== "-infinity";
  const rejectedByUser = project?.rejectedByUser;
  const rejectedByUserName =
    (rejectedByUser?.firstName
      ? `${rejectedByUser.firstName} ${rejectedByUser.lastName || ""}`
      : rejectedByUser?.username) || "A moderator";
  const rejectReason = !!project.rejectedReason
    ? ReportReasons[project.rejectedReason as ReportReasonTypes]
    : undefined;

  return (
    <Flex
      direction="column"
      width="full"
      justifyContent="flex-start"
      mb={4}
      pl={2}
      borderLeft="3px solid"
      borderLeftColor={isRejected ? "red.300" : "green.300"}
    >
      {isRejected ? (
        <Box>
          <Text>
            <Text as="span" fontWeight="bold">
              {rejectedByUserName}
            </Text>{" "}
            <Text as="span" fontSize="sm" color="gray.600">
              (Requested for changes{" "}
              {formatDistanceStrict(new Date(project.rejectedAt), new Date(), {
                addSuffix: true,
              })}
              )
            </Text>{" "}
          </Text>
          <Text overflowWrap="anywhere">
            {project?.rejectedFeedback?.length
              ? project.rejectedFeedback
              : rejectReason}
          </Text>
        </Box>
      ) : (
        <Box>
          <Text fontSize="sm" color="gray.600">
            Submitted{" "}
            {formatDistanceStrict(new Date(project.createdAt), new Date(), {
              addSuffix: true,
            })}
          </Text>
        </Box>
      )}
      <Box>
        <Link
          as={Link}
          target="_blank"
          color="brand"
          to={`/project/${project.id}`}
        >
          View Project
        </Link>
      </Box>
    </Flex>
  );
};
