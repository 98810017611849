import { useRef, useState } from "react";

import {
  Badge,
  Box,
  Table,
  Tbody,
  Text,
  Tr,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ButtonSolid,
  useToast,
  ModalCloseButton,
  Td,
  IconTrash,
  HStack,
  Divider,
  Input,
  InputGroup,
  InputRightElement,
  EmptyState,
  Avatar,
  IconX,
  IconSearch,
  TableContainer,
} from "Shared";

import { captureException } from "Services/errors";
import {
  ActiveOrgTeachersQuery,
  ActiveOrgTeachersQueryVariables,
  AdminAddCoTeacherMutationResult,
  AdminAddCoTeacherMutationVariables,
  AdminDashboardLearnerGroupFragment,
  AdminDashboardUserAccountFragment,
  AdminLearnerGroupMemberFragment,
  AdminRemoveCoTeacherMutationResult,
  AdminRemoveCoTeacherMutationVariables,
  CurrentUserFragment,
  ClassroomLearnerGroupFragment,
} from "@tract/common/dist/graphql";
import { useMutation, useQuery } from "urql";
import {
  ADMIN_ADD_CO_TEACHER_MUTATION,
  ADMIN_REMOVE_CO_TEACHER_MUTATION,
} from "./graphql";
import { ACTIVE_ORG_TEACHERS_QUERY } from "Pages/AdminDashboard/AdminOrganizations/graphql";
import { UserType } from "@tract/common/dist/types/models/User";
import { LayoutCentered } from "Components/LayoutCentered";
import { getDisplayName } from "Types/User";

type ClassCodesModalProps = {
  isOpen: boolean;
  learnerGroup:
    | AdminDashboardLearnerGroupFragment
    | ClassroomLearnerGroupFragment;
  user: AdminDashboardUserAccountFragment | CurrentUserFragment;
  onClose: () => void;
};

export const CoTeachersModal: React.FC<ClassCodesModalProps> = ({
  isOpen,
  learnerGroup,
  user,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [addingUserId, setAddingUserId] = useState<string | undefined>();
  const [removingUserId, setRemovingUserId] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const [{ fetching: addingCoTeacher }, addCoTeacher] = useMutation<
    AdminAddCoTeacherMutationResult,
    AdminAddCoTeacherMutationVariables
  >(ADMIN_ADD_CO_TEACHER_MUTATION);

  const [{ fetching: removingCoTeacher }, removeCoTeacher] = useMutation<
    AdminRemoveCoTeacherMutationResult,
    AdminRemoveCoTeacherMutationVariables
  >(ADMIN_REMOVE_CO_TEACHER_MUTATION);

  const [{ data, fetching: loadingUsers }] = useQuery<
    ActiveOrgTeachersQuery,
    ActiveOrgTeachersQueryVariables
  >({
    query: ACTIVE_ORG_TEACHERS_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      orgId: user.orgId || "",
      userType: UserType.Parent,
    },
  });

  const clearSearchInput = () => {
    setSearchTerm("");
    inputRef.current?.focus();
  };

  const orgTeachers = data?.user;

  const classTeachers = learnerGroup.members.filter(
    (member) => member.role === "teacher"
  );

  const otherOrgTeachers = orgTeachers?.filter(
    (orgTeacher) => !classTeachers.find((t) => t.userId === orgTeacher.id)
  );

  const otherOrgTeachersFiltered = otherOrgTeachers?.filter(
    (teacher) =>
      teacher.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.teacher?.displayName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      teacher.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      `${teacher.firstName} ${teacher.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const onAddCoTeacher = async (teacher: ActiveOrgTeachersQuery["user"][0]) => {
    try {
      setAddingUserId(teacher.id);
      await addCoTeacher({
        learnerGroupId: learnerGroup.id,
        userId: teacher.id,
      });
      toast({
        title: "Teacher Added",
        status: "success",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong",
        status: "error",
      });
    }
  };

  const onRemoveCoTeacher = async (
    teacher: AdminLearnerGroupMemberFragment
  ) => {
    try {
      setRemovingUserId(teacher.userId);
      await removeCoTeacher({
        learnerGroupId: learnerGroup.id,
        userId: teacher.userId,
      });

      toast({
        title: "Teacher Removed",
        status: "success",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong",
        status: "error",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} scrollBehavior="inside" onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Teachers</ModalHeader>
        <ModalBody>
          <TableContainer>
            <Table size="md" width="100%">
              <Tbody>
                {classTeachers
                  .filter((t) => t.isOwner)
                  .map((teacher: AdminLearnerGroupMemberFragment) => {
                    const displayName = getDisplayName(teacher.user);
                    return (
                      <Tr key={teacher.userId}>
                        <Td py={2}>
                          <HStack spacing={3} align="center">
                            <Avatar
                              src={teacher?.user?.avatar || ""}
                              size="lg"
                              tabIndex={-1}
                              name={displayName || ""}
                            />
                            <Box>
                              <Box>
                                <Text as="span" fontWeight="bold" fontSize="lg">
                                  {displayName}
                                </Text>
                              </Box>
                              <Box>
                                <Badge variant="outline" mt={1}>
                                  Primary
                                </Badge>
                              </Box>
                              {teacher?.user?.email && (
                                <Text noOfLines={1} color="gray.600" mt={1}>
                                  {teacher?.user?.email}
                                </Text>
                              )}
                            </Box>
                          </HStack>
                        </Td>
                        <Td></Td>
                      </Tr>
                    );
                  })}
                {classTeachers
                  .filter((t) => !t.isOwner)
                  .map((teacher: AdminLearnerGroupMemberFragment) => {
                    const displayName = getDisplayName(teacher.user);
                    return (
                      <Tr key={teacher.userId}>
                        <Td py={2}>
                          <HStack>
                            <Avatar
                              src={teacher.user.avatar || ""}
                              size="lg"
                              tabIndex={-1}
                              name={displayName || ""}
                              mr={1}
                            />
                            <Box>
                              <Text as="span" fontWeight="bold" fontSize="lg">
                                {displayName}
                              </Text>
                              {teacher?.user?.email && (
                                <Text noOfLines={1} color="gray.600" mt={1}>
                                  {teacher?.user?.email}
                                </Text>
                              )}
                            </Box>
                          </HStack>
                        </Td>
                        <Td py={2} textAlign="end">
                          <IconButton
                            size="lg"
                            variant="ghost"
                            aria-label="Remove Teacher"
                            icon={<IconTrash />}
                            isDisabled={
                              removingUserId === teacher.userId &&
                              removingCoTeacher
                            }
                            isLoading={
                              removingUserId === teacher.userId &&
                              removingCoTeacher
                            }
                            onClick={() => onRemoveCoTeacher(teacher)}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <Divider color="gray.200" my={6} />
          <InputGroup size="lg">
            <Input
              ref={inputRef}
              variant="filled"
              borderRadius="full"
              placeholder="Search by name or email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              mb={6}
              disabled={!otherOrgTeachers?.length}
            />
            <InputRightElement>
              {!!searchTerm.length ? (
                <IconButton
                  variant="ghost"
                  aria-label="clear teacher search"
                  icon={<IconX />}
                  onClick={clearSearchInput}
                />
              ) : (
                <IconSearch width={6} height={6} color="gray.600" />
              )}
            </InputRightElement>
          </InputGroup>
          {loadingUsers && <LayoutCentered height="auto" isLoading />}
          {!loadingUsers && !!otherOrgTeachers?.length && (
            <TableContainer>
              <Table
                width="100%"
                mb={!otherOrgTeachersFiltered?.length ? 0 : 6}
              >
                <Tbody>
                  {otherOrgTeachersFiltered?.map((teacher) => (
                    <Tr key={teacher.id}>
                      <Td py={3}>
                        <HStack spacing={3}>
                          <Avatar
                            src={teacher.avatar || ""}
                            size="md"
                            tabIndex={-1}
                            name={`${teacher.firstName} ${teacher.lastName}`}
                          />
                          <Box>
                            <Text fontWeight="bold" fontSize="lg">
                              {teacher.firstName} {teacher.lastName}
                            </Text>
                            {teacher.email && (
                              <Text noOfLines={1} color="gray.600" mt={1}>
                                {teacher.email}
                              </Text>
                            )}
                          </Box>
                        </HStack>
                      </Td>
                      <Td py={2} textAlign="end">
                        <ButtonSolid
                          size="lg"
                          isDisabled={
                            addingUserId === teacher.id && addingCoTeacher
                          }
                          isLoading={
                            addingUserId === teacher.id && addingCoTeacher
                          }
                          onClick={() => onAddCoTeacher(teacher)}
                        >
                          Add
                        </ButtonSolid>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!loadingUsers && !otherOrgTeachersFiltered?.length && (
            <EmptyState
              headline={
                !otherOrgTeachers?.length
                  ? "No teachers to add"
                  : "No results found"
              }
            >
              <Text textAlign="center" color="gray.600">
                {!otherOrgTeachers?.length
                  ? "You've added all the educators from this organization."
                  : "We couldn't find any educators in your organization that match your search."}
              </Text>
            </EmptyState>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
