import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, Link } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  split,
} from "@apollo/client";
import {
  createClient,
  errorExchange,
  dedupExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";
import { devtoolsExchange } from "@urql/devtools";
import {
  getMainDefinition,
  offsetLimitPagination,
} from "@apollo/client/utilities";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";

import { Box, Button, EmptyState, lightTheme, Text } from "Shared";

import { AuthedRoutes } from "Routes/AuthedRoutes";
import { UnauthedRoutes } from "Routes/UnauthedRoutes";

import { signOut, useAuth } from "Services/auth";
import { FeaturesProvider } from "Services/features";
import { functions } from "Services/firebase";
import { PageTracker } from "Services/analytics";
import { useFetchFeatures } from "Services/features";
import { captureException } from "Services/errors";

import { LayoutError } from "Components/LayoutError";
import { ScrollToTop } from "Components/ScrollToTop";
import { LayoutCentered } from "Components/LayoutCentered";
import { cache } from "Services/cacheExchange";
import { get } from "Services/api";
// import { useInterval } from "@tract/common/dist/hooks";

if (process.env.REACT_APP_FIREBASE_LOCAL_FUNCTION_ADDRESS) {
  functions.useFunctionsEmulator(
    process.env.REACT_APP_FIREBASE_LOCAL_FUNCTION_ADDRESS
  ); // e.g. http://localhost:5001
}

function App() {
  return (
    <ErrorBoundary
      onError={(err) =>
        captureException(err, {
          tags: { type: "ErrorBoundary" },
          extra: { component: "App" },
        })
      }
      FallbackComponent={() => {
        return (
          <ChakraProvider theme={lightTheme}>
            <LayoutError title="App Crashed">
              <Text fontSize="4xl" fontWeight="bold">
                Uh oh...
              </Text>
              <Text fontSize="xl" mt={4}>
                We've have encountered an issue, please{" "}
                <Link href="mailto:help@tract.app" isExternal color="blue">
                  contact support
                </Link>{" "}
                if the issue persists.
              </Text>
            </LayoutError>
          </ChakraProvider>
        );
      }}
    >
      <ChakraProvider theme={lightTheme}>
        <AuthBootstrap />
      </ChakraProvider>
    </ErrorBoundary>
  );
}

const AuthBootstrap = () => {
  const { auth, isLoading: authLoading, firebaseUser, error } = useAuth();
  const { features, isLoading: featuresLoading } = useFetchFeatures();
  const isLoading = authLoading || featuresLoading;

  /* Reimplements fetch to inject a JWT token if it's expired */
  const gqlFetch = async (uri: RequestInfo, options?: RequestInit) => {
    if (auth && firebaseUser && options) {
      const headers = new Headers();
      const result = await firebaseUser.getIdTokenResult();
      const fiveMinuteBufferMs = 30000;

      let token = result.token;

      // Check if token is expired, a buffer is set to check early
      // since a token can expire while a request is outbound
      if (
        Date.parse(result.expirationTime) <=
        new Date().getTime() - fiveMinuteBufferMs
      ) {
        token = await firebaseUser.getIdToken(true);
        // Refresh auth
        await get("/v1/auth", firebaseUser);
      }

      headers.set("Authorization", `Bearer ${token}`);
      options.headers = headers;
    }

    return fetch(uri, options);
  };

  const batchHttpLink = new BatchHttpLink({
    uri: process.env.REACT_APP_HASURA_URI,
    batchMax: 10, // No more than 5 operations per batch
    batchInterval: 30, // Wait no more than 30ms after first batched operation
    includeExtensions: true,
    fetch: gqlFetch,
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_URI,
    includeExtensions: true,
    fetch: gqlFetch,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) graphQLErrors.forEach((err) => captureException(err));
    if (networkError) captureException(networkError);
  });

  /**
   * Manually contorl which queries get batched. Return true for a batched operation.
   */
  const batchSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      const isOperation = definition.kind === "OperationDefinition";

      if (isOperation) {
        switch (definition.name?.value) {
          case "GetMissionCommentReplyCount":
          case "UserProjectLikes":
            return true;
          default:
            return false;
        }
      }

      return false;
    },
    batchHttpLink,
    httpLink
  );

  const apolloClient = new ApolloClient({
    name: "Tract Web",
    version: "1.0",
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            path: {
              ...offsetLimitPagination([
                "where",
                [
                  "id",
                  ["_eq"],
                  "slug",
                  "isPublished",
                  "authorId",
                  "_or",
                  "status",
                  "interestArea",
                  "tags",
                  "orgId",
                  "visibility",
                  "subjects",
                  "skills",
                  "gradeLevels",
                  "isVisibleUnauthed",
                  "user",
                ],
              ]),
              // @ts-ignore
              read(existing, { args: { offset } }) {
                /**
                 * Support paginated reads. This prevents the cache from
                 * returning the entire list of cached results at once. See:
                 * https://www.apollographql.com/docs/react/pagination/offset-based/#using-with-a-paginated-read-function
                 */
                return existing && existing.slice(offset, existing?.length);
              },

              merge(existing = [], incoming = [], { args, variables }) {
                const merged = existing ? existing.slice(0) : [];
                if (args) {
                  // this logic handles merging logic for pagination with `in` queries
                  // since `in` queries are considered filters they don't actually produce
                  // a valid cache store key
                  if (args["where"]?.["id"]?.["_in"]?.length) {
                    if (!incoming.length) {
                      return existing;
                    }

                    if (variables?.mergeBehavior === "replace") {
                      return incoming;
                    }

                    return existing.slice(0).concat(incoming);
                  }

                  // Assume an offset of 0 if args.offset omitted.
                  const { offset = 0 } = args;
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i];
                  }
                } else {
                  throw new Error("expected path args");
                }

                return merged;
              },
            },

            path_feed: {
              ...offsetLimitPagination(["where", ["slug", "pathId"]]),
            },

            comment: {
              ...offsetLimitPagination(["where", ["deletedAt", "user"]]),
            },

            project_comment: {
              ...offsetLimitPagination(["where", ["comment", "projectId"]]),
            },

            project_reaction: {
              ...offsetLimitPagination(["where", ["userId"]]),
            },

            project: {
              keyArgs: [
                "where",
                [
                  "id",
                  ["_eq"],
                  "challengeId",
                  "rejectedAt",
                  "published",
                  "userId",
                  "awards",
                  "challenge",
                  ["responseType", "mission", ["pathId", "path"]],
                ],
              ],

              merge(existing = [], incoming = [], { args }) {
                const merged = existing ? existing.slice(0) : [];

                if (args) {
                  // this logic handles merging logic for pagination with `in` queries
                  // since `in` queries are considered filters they don't actually produce
                  // a valid cache store key
                  if (args["where"]?.["id"]?.["_in"]?.length) {
                    if (!incoming.length) {
                      return existing;
                    }

                    return existing.slice(0).concat(incoming);
                  }

                  // Assume an offset of 0 if args.offset omitted.
                  const { offset = 0 } = args;
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i];
                  }
                } else {
                  throw new Error("expected project args");
                }

                return merged;
              },
            },

            mission_comment: {
              ...offsetLimitPagination(["where", ["comment", "missionId"]]),
            },

            path_activity: {
              ...offsetLimitPagination(["where", ["userId", "pathId", "type"]]),
            },

            path_review: {
              ...offsetLimitPagination(["where", ["type", "pathId", "path"]]),
            },
          },
        },

        educator_code: {
          keyFields: ["code"],
        },

        learner_group_member: {
          keyFields: ["learnerGroupId", "userId"],
        },

        path_activity: {
          keyFields: ["pathId", "pathReviewId"],
        },

        path_active_user: {
          keyFields: ["pathId", "userId"],
        },

        path_author: {
          keyFields: ["pathId", "userId"],
        },

        path_feed: {
          keyFields: ["slug", "pathId"],
        },

        mission_comment: {
          keyFields: ["missionId", "commentId"],
        },

        mission_attachment: {
          keyFields: ["missionId", "fileId"],
        },

        project_comment: {
          keyFields: ["projectId", "commentId"],
        },

        project_stats: {
          keyFields: ["projectId"],
        },

        project_reaction: {
          keyFields: ["userId", "projectId"],
        },

        project_award: {
          keyFields: ["projectId", "awardId"],
        },
      },
    }),
    link: from([errorLink, batchSplitLink]),
  });

  const exchanges = [
    dedupExchange,
    cache(),
    errorExchange({
      onError: (error) => {
        captureException(error);
      },
    }),
    fetchExchange,
  ];

  if (process.env.ENVIRONMENT !== "production") {
    exchanges.unshift(devtoolsExchange);
  }

  const urqlClient = createClient({
    url: process.env.REACT_APP_HASURA_URI || "",
    fetch: gqlFetch,
    exchanges,
  });

  if (error) {
    return (
      <Box p={20}>
        <EmptyState headline="Error authenticating, please refresh to try again">
          <Button
            variant="link"
            colorScheme="brandFull"
            onClick={async () => {
              await signOut();
              window.location.reload();
            }}
          >
            Sign Out
          </Button>
        </EmptyState>
      </Box>
    );
  }

  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    //This is to block default for custom confirm modal
    if (message === "useCustom") return;

    // this is the default behavior
    const allowTransition = window.confirm(message);
    callback(allowTransition);
  };

  if (isLoading) {
    return <LayoutCentered isLoading />;
  }

  return (
    <UrqlProvider value={urqlClient}>
      <ApolloProvider client={apolloClient}>
        <FeaturesProvider features={features}>
          <Router getUserConfirmation={getUserConfirmation}>
            <ScrollToTop />
            <PageTracker />
            {!auth || !firebaseUser ? (
              <UnauthedRoutes />
            ) : (
              <AuthedRoutes auth={auth} firebaseUser={firebaseUser} />
            )}
          </Router>
        </FeaturesProvider>
      </ApolloProvider>
    </UrqlProvider>
  );
};

export default App;
