import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "urql";

import { useSession } from "./session";
import { captureException } from "./errors";
import { useAnalytics, ANALYTICS_EVENTS } from "./analytics";

import { isAuthorUser, USER_TYPE_FRAGMENT } from "Types/User";

import {
  FindFollowRelationshipQueryResult,
  FindFollowRelationshipQueryVariables,
  FollowUserMutationResult,
  FollowUserMutationVariables,
  UnfollowUserMutationResult,
  UnfollowUserMutationVariables,
  UserSocialFollowHookFragment,
} from "@tract/common/dist/graphql";
import { usePrivacyScopesCheck, USER_PRIVACY_SCOPES_FRAGMENT } from "./privacy";

gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment UserSocialFollowHook on user {
    id: firestoreId
    ...UserType
    ...UserPrivacyScopes
  }
`;

export const FIND_FOLLOW_RELATIONSHIP = gql`
  query FindFollowRelationship($sourceUserId: String!, $targetUserId: String!) {
    user_follow_by_pk(
      sourceUserId: $sourceUserId
      targetUserId: $targetUserId
    ) {
      sourceUserId
      targetUserId
    }
  }
`;

const FOLLOW_USER_MUTATION = gql`
  mutation FollowUser($sourceUserId: String!, $targetUserId: String!) {
    insert_user_follow_one(
      object: { sourceUserId: $sourceUserId, targetUserId: $targetUserId }
    ) {
      sourceUserId
      targetUserId
    }
  }
`;

const UNFOLLOW_USER_MUTATION = gql`
  mutation UnfollowUser($sourceUserId: String!, $targetUserId: String!) {
    delete_user_follow_by_pk(
      sourceUserId: $sourceUserId
      targetUserId: $targetUserId
    ) {
      sourceUserId
      targetUserId
    }
  }
`;

export function useFollows(
  user: UserSocialFollowHookFragment,
  skipFollowCheck = false
) {
  const { track } = useAnalytics();
  const { currentUser } = useSession();
  const { isAllowed } = usePrivacyScopesCheck("profile", user);
  const [loading, setLoading] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [, followMutation] = useMutation<
    FollowUserMutationResult["data"],
    FollowUserMutationVariables
  >(FOLLOW_USER_MUTATION);

  const [, unfollowMutation] = useMutation<
    UnfollowUserMutationResult["data"],
    UnfollowUserMutationVariables
  >(UNFOLLOW_USER_MUTATION);

  const [{ data, fetching }] = useQuery<
    FindFollowRelationshipQueryResult["data"],
    FindFollowRelationshipQueryVariables
  >({
    query: FIND_FOLLOW_RELATIONSHIP,
    pause: skipFollowCheck,
    variables: {
      sourceUserId: currentUser.id,
      targetUserId: user.id,
    },
  });

  useEffect(() => {
    if (!isReady && !fetching) {
      setIsReady(true);
    }

    setLoading(fetching);
  }, [fetching, isReady]);

  async function follow() {
    if (!isAllowed) {
      return;
    }

    setLoading(true);

    try {
      await followMutation({
        sourceUserId: currentUser.id,
        targetUserId: user.id,
      });

      setIsFollowing(true);

      track(ANALYTICS_EVENTS.PROFILE_FOLLOWED, {
        followedUserId: user.id,
        isFollowedAuthor: isAuthorUser(user),
      });
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoading(false);
    }
  }

  async function unfollow() {
    if (!isAllowed) {
      return;
    }

    setLoading(true);

    try {
      await unfollowMutation({
        sourceUserId: currentUser.id,
        targetUserId: user.id,
      });

      setIsFollowing(false);

      track(ANALYTICS_EVENTS.PROFILE_UNFOLLOWED, {
        unfollowedUserId: user.id,
        isUnfollowedAuthor: isAuthorUser(user),
      });
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoading(false);
    }
  }

  return {
    isFollowable: isAllowed,
    follow,
    unfollow,
    isFollowing: !!data?.user_follow_by_pk || isFollowing,
    loading,
    isReady,
  };
}
