import firebase from "firebase/compat";

export enum EducatorSignUpStatus {
  PendingVerification = "PendingVerification",
  PendingApproval = "PendingApproval",
  Incomplete = "Incomplete",
  Active = "Active",
}

export enum EducatorRole {
  Teacher = "Teacher",
  Principal = "Principal",
  TechnologySpecialist = "Technology Specialist",
  Administrator = "Administrator",
}

export type EducatorSignUp = {
  firstName: string;
  lastName: string;
  email: string;
  status: EducatorSignUpStatus;
  orgId: string;
  startDate: string;
  useCase: string;
  educatorRole: string;
  otherUseCase?: string;
  accessCode?: string;
  phoneNumber?: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
};
