import React, { FC } from "react";
import {
  EmptyState,
  ButtonSolid,
  Center,
  EmptyStateFontAwesomeIcon,
} from "Shared";

import {
  ProfileLivestreamsQuery,
  ProfileLivestreamsQueryVariables,
  UserProfileUserFragment,
} from "@tract/common/dist/graphql";
import { GridProfileItems } from "Components/GridContainer";
import { useOffsetPaginationQuery } from "Utils";
import { PROFILE_LIVESTREAMS_QUERY } from "../graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { LivestreamCard } from "Components/LivestreamCard";
import { ViewSource } from "Types/Livestream";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  user: UserProfileUserFragment;
};

const GALLERY_LIMIT = 54;

export const ProfileLivestreams: FC<Props> = ({ user }) => {
  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<
    ProfileLivestreamsQuery,
    ProfileLivestreamsQueryVariables
  >({
    query: PROFILE_LIVESTREAMS_QUERY,
    variables: {
      limit: GALLERY_LIMIT,
      offset: 0,
      hostUserId: user.id,
      startDate: "now()",
    },
    field: "livestreams",
  });

  const livestreams = data?.livestreams || [];

  if (fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!livestreams?.length) {
    return (
      <EmptyState
        headline="No upcoming livestreams"
        icon={<EmptyStateFontAwesomeIcon icon={duotone("signal-stream")} />}
      />
    );
  }

  return (
    <>
      <GridProfileItems>
        {livestreams.map((livestream) => (
          <LivestreamCard
            key={livestream.id}
            viewSource={ViewSource.UserProfile}
            livestream={livestream}
          />
        ))}
      </GridProfileItems>
      {pagination.hasNextPage && !!livestreams.length && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </>
  );
};
