import React, { useState } from "react";
import { StackProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "urql";
import {
  AwesomeChallengeProjectsQuery,
  AwesomeChallengeProjectsQueryVariables,
  PathViewerNodeFragment,
  PathViewerPathFragment,
} from "@tract/common/dist/graphql";
import {
  Box,
  ButtonLink,
  Center,
  EmptyState,
  HStack,
  useRadioGroup,
  useBreakpointValue,
} from "Shared";
import { ProjectCard } from "Components/ProjectCard";
import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";
import { GridProjects } from "Components/GridContainer";
import { RadioCard } from "Components/RadioCard";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";

import { useProjectModal } from "Services/projectModal";
import { ViewSource } from "Types/Project";

const AWESOME_CHALLENGE_PROJECTS = gql`
  ${PROJECT_CARD_FRAGMENT}

  query AwesomeChallengeProjects($limit: Int = 20, $where: project_bool_exp) {
    project(
      limit: $limit
      offset: 0
      where: $where
      order_by: { createdAt: desc }
    ) {
      ...projectCard
    }
  }
`;

enum SortByType {
  New = "new",
  Awesome = "awesome",
}

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
};

const PROJECTS_LIMIT = 9;

export const ChallengeProjects: React.FC<Props> = ({ path, node }) => {
  const { handleOpenProjectModal } = useProjectModal();
  const [queryBy, setQueryBy] = useState(SortByType.New);

  const [{ data, fetching: loading }] = useQuery<
    AwesomeChallengeProjectsQuery,
    AwesomeChallengeProjectsQueryVariables
  >({
    query: AWESOME_CHALLENGE_PROJECTS,
    variables: {
      limit: PROJECTS_LIMIT,
      where: {
        isPrivate: { _eq: false },
        pathNodeId: { _eq: node.id },
        file: { transforms: {} },
        published: { _eq: true },
        rejectedAt: { _eq: "-infinity" },
        user: { projectPrivacy: { _is_null: true } },
        ...(queryBy === SortByType.Awesome && {
          awards: {
            award: {
              cost: { _gte: 450 },
            },
          },
        }),
      },
    },
  });

  const handleSortChange = (type: SortByType) => {
    setQueryBy(type);
  };

  const projects = data?.project || [];

  const skeletonCount = useBreakpointValue({
    base: 2,
    md: 4,
    xl: 6,
  });

  return (
    <>
      <Center my={6}>
        <SortBy onChangeValue={handleSortChange} />
      </Center>
      {loading && !!skeletonCount && (
        <GridProjects minHeight="200px" size="lg">
          {Array.from({ length: skeletonCount }).map((_, i) => (
            <ProjectCardSkeleton key={i} />
          ))}
        </GridProjects>
      )}
      {!loading && data && !projects.length && (
        <EmptyState
          image="project"
          headline="No Projects Yet"
          body="Submit a response and you could be first!"
        />
      )}
      {!loading && (
        <GridProjects minHeight="200px" size="lg">
          {projects?.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                project={project}
                viewSource={ViewSource.ChallengeAwesome}
                handleClickFromParent={(event) => {
                  handleOpenProjectModal(event, project);
                }}
              />
            );
          })}
        </GridProjects>
      )}
      {projects.length >= PROJECTS_LIMIT && (
        <Box textAlign="center" mt="10">
          <ButtonLink
            as={Link}
            target="_blank"
            to={`/explore/projects/${queryBy}?path=${path?.id}&v=${node.id}`}
            variant="outline"
          >
            View More
          </ButtonLink>
        </Box>
      )}
    </>
  );
};

type SortByProps = StackProps & {
  onChangeValue: (val: SortByType) => void;
};

const SortBy: React.FC<SortByProps> = ({
  onChangeValue,
  ...props
}: SortByProps) => {
  const {
    getRootProps,
    getRadioProps,
    value: selectedValue,
  } = useRadioGroup({
    name: "sortby",
    defaultValue: SortByType.New,
    onChange: (value: SortByType) => onChangeValue(value),
  });

  const radioGroupProps = getRootProps();
  return (
    <HStack {...radioGroupProps} {...props}>
      {Object.values(SortByType).map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard
            {...radio}
            isChecked={selectedValue === value}
            key={value}
            borderRadius="xl"
          >
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
