export const Textarea = {
  variants: {
    outline: {
      borderColor: "gray.300",
    },
  },
  sizes: {
    sm: {
      borderRadius: "md",
    },
    lg: {
      borderRadius: "lg",
      fontSize: "md",
      pt: 3,
      pb: 3,
    },
  },
  defaultProps: {
    focusBorderColor: "brand",
  },
};
