import { FC, useState } from "react";
import { useMutation, useQuery } from "urql";
import { sample } from "lodash";

import {
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Link,
  VisuallyHidden,
  useDisclosure,
  HStack,
  Button,
  useToast,
  IconExternalLink,
  Badge,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  IconMoreVertical,
} from "Shared";

import {
  CreateClassModal,
  CreateClassModalForm,
} from "Components/CreateClassroomModal";
import { PageHeader } from "Components/PageHeader";
import { ClassCodesModal } from "./ClassCodesModal";
import { CoTeachersModal } from "./CoTeachersModal";
import { LayoutCentered } from "Components/LayoutCentered";

import { captureException } from "Services/errors";

import {
  AdminDashboardClassroomsByTeacherIdQueryResult,
  AdminDashboardClassroomsByTeacherIdQueryVariables,
  AdminDashboardCreateClassroomMutationResult,
  AdminDashboardCreateClassroomMutationVariables,
  AdminDashboardUserAccountFragment,
  AdminArchiveClassroomMutationResult,
  AdminArchiveClassroomMutationVariables,
  AdminDashboardLearnerGroupFragment,
} from "@tract/common/dist/graphql";
import { generateRandomCode } from "@tract/common/dist/utils/random-code";
import {
  ADMIN_ARCHIVE_CLASSROOM_MUTATION,
  ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY,
  ADMIN_CREATE_CLASSROOM_MUTATION,
} from "./graphql";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { LearnerGroupColors } from "@tract/common/dist/types/models/LearnerGroup";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

export const TeacherClassrooms: FC<Props> = ({ user }) => {
  const toast = useToast();
  const { track } = useAnalytics();

  const {
    isOpen: isClassCodesModalOpen,
    onClose: onCloseClassCodesModal,
    onOpen: onOpenClassCodesModal,
  } = useDisclosure();

  const {
    isOpen: isCoTeachersModalOpen,
    onClose: onCloseCoTeachersModal,
    onOpen: onOpenCoTeachersModal,
  } = useDisclosure();

  const {
    isOpen: isCreateClassModalOpen,
    onOpen: openCreateClassModal,
    onClose: closeCreateClassModal,
  } = useDisclosure();

  const [{ data: learnerGroups, fetching: loading }] = useQuery<
    AdminDashboardClassroomsByTeacherIdQueryResult["data"],
    AdminDashboardClassroomsByTeacherIdQueryVariables
  >({
    query: ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      teacherUserId: user.id,
    },
  });

  const [, createClassroom] = useMutation<
    AdminDashboardCreateClassroomMutationResult["data"],
    AdminDashboardCreateClassroomMutationVariables
  >(ADMIN_CREATE_CLASSROOM_MUTATION);

  const [activeLearnerGroupIndex, setActiveLearnerGroupIndex] = useState<
    number | null
  >(null);

  const onClassCodesClick = (i: number) => {
    setActiveLearnerGroupIndex(i);
    onOpenClassCodesModal();
  };

  const onCoTeachersClick = (i: number) => {
    setActiveLearnerGroupIndex(i);
    onOpenCoTeachersModal();
  };

  const [, archiveClassroom] = useMutation<
    AdminArchiveClassroomMutationResult["data"],
    AdminArchiveClassroomMutationVariables
  >(ADMIN_ARCHIVE_CLASSROOM_MUTATION);

  const onArchiveClick = async (group: AdminDashboardLearnerGroupFragment) => {
    const isArchiving = group.archivedAt === "-infinity";
    await archiveClassroom({
      learnerGroupId: group.id,
      archivedAt: isArchiving ? "now()" : "-infinity",
    });
    track(
      isArchiving
        ? ANALYTICS_EVENTS.CLASS_ARCHIVED
        : ANALYTICS_EVENTS.CLASS_UNARCHIVED,
      {
        classId: group.id,
        classTitle: group.name,
      }
    );
  };

  const onCreateClassSubmit = async (values: CreateClassModalForm) => {
    if (!learnerGroups) return;
    try {
      await createClassroom({
        code: generateRandomCode(6),
        name: values.classroomName,
        numStudents: Number(values.numStudents),
        // Org id must exist for a teacher user
        orgId: user.orgId!,
        userId: user.id,
        color: sample(LearnerGroupColors) || LearnerGroupColors[0],
      });

      toast({
        title: "Class Created!",
        status: "success",
      });

      closeCreateClassModal();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error creating the class",
        status: "error",
      });
    }
  };

  const activeLearnerGroup =
    activeLearnerGroupIndex === null
      ? null
      : learnerGroups?.learnerGroups[activeLearnerGroupIndex];

  return (
    <>
      <PageHeader
        title="Classes"
        renderActions={
          <Button
            size="lg"
            variant="solid"
            colorScheme="brandFull"
            onClick={openCreateClassModal}
          >
            New Class
          </Button>
        }
      />
      <CreateClassModal
        isOpen={isCreateClassModalOpen}
        onClose={closeCreateClassModal}
        onSubmit={onCreateClassSubmit}
        includeNumStudents={true}
      />

      {loading ? (
        <LayoutCentered height="50vh" isLoading />
      ) : (
        <Table width="100%">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Students</Th>
              <Th>
                <VisuallyHidden>Actions</VisuallyHidden>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {learnerGroups?.learnerGroups.map((group, i) => (
              <Tr key={group.id}>
                <Td py={4} fontWeight="bold">
                  <HStack spacing={3}>
                    <Link to={`/class/${group.id}`}>{group.name}</Link>
                    <IconExternalLink size={20} />
                    {group.archivedAt !== "-infinity" && (
                      <Badge colorScheme="gray">Archived</Badge>
                    )}
                  </HStack>
                </Td>
                <Td py={4}>
                  {group.members.filter((g) => g.role === "learner").length}
                </Td>
                <Td py={4} textAlign="right">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="see more actions"
                      variant="outline"
                      borderRadius="full"
                      icon={<IconMoreVertical />}
                    />
                    <MenuList width="256px">
                      <MenuItem onClick={() => onCoTeachersClick(i)}>
                        Manage Co-Teachers
                      </MenuItem>
                      <MenuItem onClick={() => onClassCodesClick(i)}>
                        Manage Class Codes
                      </MenuItem>
                      <MenuItem onClick={() => onArchiveClick(group)}>
                        {group.archivedAt === "-infinity"
                          ? "Archive"
                          : "Un-Archive"}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      {activeLearnerGroup && (
        <>
          <ClassCodesModal
            user={user}
            learnerGroup={activeLearnerGroup}
            isOpen={isClassCodesModalOpen}
            onClose={onCloseClassCodesModal}
          />
          <CoTeachersModal
            user={user}
            learnerGroup={activeLearnerGroup}
            isOpen={isCoTeachersModalOpen}
            onClose={onCloseCoTeachersModal}
          />
        </>
      )}
    </>
  );
};
