import { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { useClient } from "urql";

import {
  Badge,
  Button,
  ButtonGroup,
  ButtonOutlined,
  EmptyState,
  FormControl,
  Grid,
  IconButton,
  IconCheck,
  IconMoreVertical,
  IconX,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  VisuallyHidden,
} from "Shared";

import {
  Report,
  ReportType,
  ReportStatus,
  ReportReasonTypes,
} from "Types/AbuseReport";

import { useAlgoliaSearch } from "Services/hooks/useAlgolia";
import { index } from "Services/algolia";
import { captureException } from "Services/errors";
import { LayoutCentered } from "Components/LayoutCentered";
import { Center } from "@chakra-ui/layout";
import { db } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useQuery } from "Utils";
import { FormLabelWithClearAction } from "Components/FormLabelWithClearAction";
import { GET_USERS_BY_USER_IDS_QUERY } from "./graphql";
import {
  GetUsersByUserIdsQuery,
  GetUsersByUserIdsQueryVariables,
} from "@tract/common/dist/graphql";

type ReportRow = {
  type: ReportType;
  reason: ReportReasonTypes;
  reportId: string;
  reporterId: string;
  reporteeId: string;
  reporterUsername: string;
  reporteeUsername: string;
  status: ReportStatus;
  projectId?: string;
  commentId?: string;
  updatedAt: Date;
  createdAt: Date;
};

const REPORTS_LIMIT = 25;

function createSearchFilters(filterMap: Record<string, string | null>) {
  return Object.entries(filterMap)
    .map(([key, value]) => {
      return value?.length ? `${key}:"${value}"` : "";
    })
    .filter((stringFilter) => stringFilter.length);
}

export const AbuseReports: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const toast = useToast();
  const { track } = useAnalytics();
  const [tableData, setTableData] = useState<ReportRow[]>();
  const [reporteeUsername, setReporteeUsername] = useState("");
  const [reporterUsername, setReporterUsername] = useState("");
  const [type, setType] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("");

  const [isAggregating, setAggregating] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [requestResultPages, setRequestResultPages] = useState(0);
  const [currentResultPages, setCurrentResultPages] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const client = useClient();
  const reportsRoute = "/admin/reports";

  const filters = {
    reporteeId: query.get("reporteeId"),
    reporterId: query.get("reporterId"),
    type: query.get("type"),
    reason: query.get("reason"),
    status: query.get("status"),
  };

  const [searchFilters, setSearchFilters] = useState<string[]>(
    createSearchFilters(filters)
  );

  // Run if any of the filters change
  // `filters` is an object so we need deep compare
  useDeepCompareEffect(() => {
    setSearchFilters(createSearchFilters(filters));

    // Reset pagination
    setRequestResultPages(0);
    setCurrentResultPages(0);

    // Reset load more state
    setIsLastPage(false);
  }, [filters]);

  const searchParams = {
    filters: searchFilters.join(" AND "),
    hitsPerPage: REPORTS_LIMIT,
    page: requestResultPages,
  };

  const { data: reports, isLoading } = useAlgoliaSearch<
    Report & {
      objectID: string;
      updatedAt: Date;
      createdAt: Date;
    }
  >({
    index: index.reports,
    query: "",
    params: searchParams,
  });

  useEffect(() => {
    setType(filters.type || "");
  }, [filters.type]);

  useEffect(() => {
    setReason(filters.reason || "");
  }, [filters.reason]);

  useEffect(() => {
    setStatus(filters.status || "");
  }, [filters.status]);

  // Keep reporteeUsername field in sync with query param
  useEffect(() => {
    async function setUsernameFilterByUid(uid: string) {
      try {
        const { username } = await index.users.getObject(uid);
        setReporteeUsername(username || "");
      } catch (err: any) {
        captureException(err);
      }
    }

    if (filters.reporteeId) {
      setUsernameFilterByUid(filters.reporteeId);
    } else {
      setReporteeUsername("");
    }
  }, [filters.reporteeId]);

  // Keep reporterUsername field in sync with query param
  useEffect(() => {
    async function setUsernameFilterByUid(uid: string) {
      try {
        const { username } = await index.users.getObject(uid);
        setReporterUsername(username || "");
      } catch (err: any) {
        captureException(err);
      }
    }

    if (filters.reporterId) {
      setUsernameFilterByUid(filters.reporterId);
    } else {
      setReporterUsername("");
    }
  }, [filters.reporterId]);

  const handleUsernameFilterSubmit = async (
    userType: "reportee" | "reporter",
    username: string
  ) => {
    const filterName = `${userType}Id`;

    if (username.length < 1) {
      query.delete(filterName);
    } else {
      const usernameSearchResults = await index.users.search<{
        objectID: string;
      }>("", {
        facetFilters: `username:${username}`,
        hitsPerPage: 1,
        attributesToRetrieve: ["objectID"],
      });

      const users = usernameSearchResults.hits;

      if (users && users.length > 0) {
        query.set(filterName, users[0].objectID);
      } else {
        query.delete(filterName);

        toast({
          status: "error",
          title: "User not found",
        });
      }
    }

    history.push(`${reportsRoute}?${query.toString()}`);
  };

  const handleDropdownFilterChange = (filterName: string) => {
    return (e: any) => {
      const { value } = e.target;

      if (value.length) {
        query.set(filterName, value);
      } else {
        query.delete(filterName);
      }

      history.push(`${reportsRoute}?${query.toString()}`);
    };
  };

  const handleClickClearFilter = (filterName: string) => {
    query.delete(filterName);
    history.push(`?${query.toString()}`);
  };

  const paginate = async () => {
    setRequestResultPages((requestResultPages) => requestResultPages + 1);
    setIsLoadingMore(true);
  };

  const changeReportStatus = async (
    reportRow: ReportRow,
    status: ReportStatus
  ) => {
    if (!tableData || reportRow.status === status) return;

    try {
      await db.doc(`abuse-reports/${reportRow.reportId}`).update({
        status,
      });

      const newTableData = [...tableData];
      const index = newTableData.findIndex(
        (row) => row.reportId === reportRow.reportId
      );
      newTableData[index].status = status;
      setTableData(newTableData);

      toast({
        status: "success",
        title: "Report status changed",
      });

      const eventProps = {
        reportType: reportRow.type,
        reportReason: reportRow.reason,
        reporteeId: reportRow.reporteeId,
      };

      if (status === ReportStatus.Confirmed) {
        track(ANALYTICS_EVENTS.REPORT_CONFIRMED, eventProps);
      } else if (status === ReportStatus.Dismissed) {
        track(ANALYTICS_EVENTS.REPORT_DISMISSED, eventProps);
      }
    } catch (error) {
      toast({
        status: "error",
        title: "Error changing report status",
      });
    }
  };

  useEffect(() => {
    if (!reports) {
      return;
    }

    async function mapReportsWithUsers() {
      const userIds = reports
        .map((report) => [report.reporteeId, report.reporterId])
        .reduce((a, b) => a.concat(b), []);

      if (userIds.length === 0) {
        return;
      }

      setAggregating(true);
      try {
        const result = await client
          .query<GetUsersByUserIdsQuery, GetUsersByUserIdsQueryVariables>(
            GET_USERS_BY_USER_IDS_QUERY,
            {
              userIds,
            }
          )
          .toPromise();

        const users = new Map<string, GetUsersByUserIdsQuery["user"][0]>();
        result.data?.user.forEach((user) => {
          users.set(user.id, user);
        });

        const mappedReports = reports
          .map((report) => {
            const reporter = users.get(report.reporterId);
            const reportee = users.get(report.reporteeId);

            const reportRow: ReportRow = {
              ...report,
              reportId: report.objectID,
              reporterUsername:
                reporter?.userType === "parent"
                  ? reporter?.firstName || "<No name>"
                  : reporter?.username || "<No username>",
              reporteeUsername:
                reportee?.userType === "parent"
                  ? reportee?.firstName || "<No name>"
                  : reportee?.username || "<No username>",
              projectId: report.meta?.projectId,
              commentId: report.meta?.commentId,
              updatedAt: new Date(report.updatedAt),
              createdAt: new Date(report.createdAt),
            };

            return reportRow;
          })
          .filter((row) => !!row);

        if (requestResultPages > currentResultPages) {
          setTableData(tableData?.concat(mappedReports) || []);
          setCurrentResultPages(requestResultPages);
        } else {
          setTableData(mappedReports);
        }

        setIsLastPage(mappedReports.length < REPORTS_LIMIT);
      } catch (error: any) {
        captureException(error);
      } finally {
        setAggregating(false);
        setIsLoadingMore(false);
      }
    }
    mapReportsWithUsers();

    // We don't want to run the effect if any of these changes:
    // 'currentResultPages', 'requestResultPages', and 'tableData'
    // eslint-disable-next-line
  }, [reports]);

  return (
    <>
      <Grid
        templateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(5, 1fr)"]}
        gap={6}
        mb={6}
      >
        <Formik
          initialValues={{ reportee: reporteeUsername }}
          enableReinitialize={true}
          onSubmit={({ reportee }) =>
            handleUsernameFilterSubmit("reportee", reportee)
          }
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabelWithClearAction
                  showClearAction={!!filters.reporteeId}
                  onClickClear={() => handleClickClearFilter("reporteeId")}
                >
                  Reportee
                </FormLabelWithClearAction>
                <Input
                  name="reportee"
                  variant="filled"
                  value={values.reportee}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
          )}
        </Formik>
        <Formik
          initialValues={{ reporter: reporterUsername }}
          enableReinitialize={true}
          onSubmit={({ reporter }) =>
            handleUsernameFilterSubmit("reporter", reporter)
          }
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabelWithClearAction
                  showClearAction={!!filters.reporterId}
                  onClickClear={() => handleClickClearFilter("reporterId")}
                >
                  Reporter
                </FormLabelWithClearAction>
                <Input
                  name="reporter"
                  variant="filled"
                  value={values.reporter}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
          )}
        </Formik>
        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!filters.type}
            onClickClear={() => handleClickClearFilter("type")}
          >
            Type
          </FormLabelWithClearAction>
          <Select
            name="type"
            variant="filled"
            value={type}
            onChange={handleDropdownFilterChange("type")}
          >
            <option value="">All</option>
            <option value="project">Project</option>
            <option value="comment">Comment</option>
            <option value="profile">Profile</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!filters.reason}
            onClickClear={() => handleClickClearFilter("reason")}
          >
            Reason
          </FormLabelWithClearAction>
          <Select
            name="reason"
            variant="filled"
            value={reason}
            onChange={handleDropdownFilterChange("reason")}
          >
            <option value="">All</option>
            <option value={ReportReasonTypes.Invalid}>
              {ReportReasonTypes.Invalid}
            </option>
            <option value={ReportReasonTypes.Offensive}>
              {ReportReasonTypes.Offensive}
            </option>
            <option value={ReportReasonTypes.Plagiarised}>
              {ReportReasonTypes.Plagiarised}
            </option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!filters.status}
            onClickClear={() => handleClickClearFilter("status")}
          >
            Status
          </FormLabelWithClearAction>
          <Select
            name="status"
            variant="filled"
            value={status}
            onChange={handleDropdownFilterChange("status")}
          >
            <option value="">All</option>
            <option value={ReportStatus.Pending}>{ReportStatus.Pending}</option>
            <option value={ReportStatus.Confirmed}>
              {ReportStatus.Confirmed}
            </option>
            <option value={ReportStatus.Dismissed}>
              {ReportStatus.Dismissed}
            </option>
          </Select>
        </FormControl>
      </Grid>
      {!isLoadingMore && (isLoading || isAggregating) ? (
        <LayoutCentered isLoading height="auto" />
      ) : tableData && tableData.length > 0 ? (
        <>
          <Table width="100%">
            <Thead>
              <Tr>
                <Th>Reportee</Th>
                <Th>Reported by</Th>
                <Th>Reported at</Th>
                <Th>Type</Th>
                <Th>Reason</Th>
                <Th>Status</Th>
                <Th>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="md">
              {tableData.map((row) => (
                <Tr key={row.reportId}>
                  <Td py={2}>
                    <Button
                      variant="link"
                      color="gray.900"
                      onClick={() => {
                        query.set("reporteeId", row.reporteeId);
                        history.push(`${reportsRoute}?${query.toString()}`);
                      }}
                    >
                      {row.reporteeUsername}
                    </Button>
                  </Td>
                  <Td py={2}>
                    <Button
                      variant="link"
                      color="gray.900"
                      onClick={() => {
                        query.set("reporterId", row.reporterId);
                        history.push(`${reportsRoute}?${query.toString()}`);
                      }}
                    >
                      {row.reporterUsername}
                    </Button>
                  </Td>
                  <Td py={2}>{new Date(row.createdAt).toLocaleString()}</Td>
                  <Td py={2} textTransform="capitalize">
                    {row.type}
                  </Td>
                  <Td py={2}>{row.reason}</Td>
                  <Td py={2}>
                    {row.status === ReportStatus.Pending && (
                      <Badge
                        colorScheme="orange"
                        color="orange.600"
                        fontSize="xs"
                      >
                        PENDING
                      </Badge>
                    )}

                    {row.status === ReportStatus.Confirmed && (
                      <Badge
                        variant="outline"
                        colorScheme="green"
                        fontSize="xs"
                      >
                        CONFIRMED
                      </Badge>
                    )}
                    {row.status === ReportStatus.Dismissed && (
                      <Badge variant="outline" colorScheme="red" fontSize="xs">
                        DISMISSED
                      </Badge>
                    )}
                  </Td>
                  <Td py={2} textAlign="end">
                    <ButtonGroup>
                      <ButtonOutlined
                        as={Link}
                        to={
                          row.type === ReportType.Profile
                            ? `/@${row.reporteeUsername}`
                            : `/project/${row.projectId}`
                        }
                      >
                        View
                      </ButtonOutlined>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="see more actions"
                          variant="outline"
                          borderRadius="full"
                          icon={<IconMoreVertical />}
                        />
                        <MenuList width="256px">
                          <MenuItem
                            icon={<IconCheck color="green.600" />}
                            onClick={() =>
                              changeReportStatus(row, ReportStatus.Confirmed)
                            }
                          >
                            Confirm
                          </MenuItem>
                          <MenuItem
                            icon={<IconX color="red.600" />}
                            onClick={() =>
                              changeReportStatus(row, ReportStatus.Dismissed)
                            }
                          >
                            Dismiss
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </ButtonGroup>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Center>
            {!isLastPage && (
              <Button
                size="lg"
                variant="outline"
                onClick={paginate}
                mt={10}
                isLoading={isLoadingMore}
              >
                Load More
              </Button>
            )}
          </Center>
        </>
      ) : (
        <EmptyState headline="No Reports Found" />
      )}
    </>
  );
};
