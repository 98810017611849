import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FC } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Link,
  Text,
  ButtonSolid,
  EmptyStateFontAwesomeIcon,
} from "Shared";

interface PendingApprovaModalProps {
  isOpen: boolean;
  hideCTA?: boolean;
  onClose: () => void;
}

export const PendingApprovaModal: FC<PendingApprovaModalProps> = (props) => {
  const redirectRoute = `/explore`;
  return (
    <Modal size="xl" isCentered {...props}>
      <ModalOverlay />
      <ModalContent maxWidth="600px">
        <ModalCloseButton />
        <ModalBody textAlign="center" px={10} py={12}>
          <Box mb={6} display="inline-block">
            <EmptyStateFontAwesomeIcon icon={duotone("arrows-rotate")} />
          </Box>
          <Text mb={1} fontSize="2xl" fontWeight="bold">
            Your account is pending verification
          </Text>
          <Text mb={6} color="gray.600">
            It looks like you’re the first educator at your school on Tract. We
            verify all accounts to protect the privacy of our members. We’ll
            reach out via email once you’re verified — in the meantime, you can
            familiarize yourself with the app and explore our content library.
          </Text>
          {!!props.hideCTA ? null : (
            <Box>
              <ButtonSolid size="lg" as={Link} to={redirectRoute}>
                Start Exploring
              </ButtonSolid>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
