import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "Shared";

const titleFormSchema = yup.object().shape({
  title: yup.string().trim().required("Please provide a path title"),
});

type TitleFormValues = {
  title: string;
};

export const AddPathTitleModal = ({
  isOpen,
  onClose,
  onCreatePath,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCreatePath: (title: string) => void;
}) => {
  const titleFormInitValues: TitleFormValues = {
    title: "",
  };
  const onSubmit = async (values: TitleFormValues) => {
    const { title } = titleFormSchema.cast(values) as TitleFormValues;
    onCreatePath(title);
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        <ModalHeader
          fontSize="2xl"
          color="gray.900"
          textAlign="center"
          fontWeight="bold"
        >
          Create Path
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={titleFormInitValues}
          validationSchema={titleFormSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, values, errors, handleChange }) => (
            <Form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
              <ModalBody>
                <FormControl isInvalid={!!errors.title}>
                  <FormLabel>Title</FormLabel>
                  <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    autoFocus
                  />
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup spacing={2}>
                  <Button variant="outline" size="lg" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    colorScheme="brandFull"
                    size="lg"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
