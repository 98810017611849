import { KeyingConfig } from "@urql/exchange-graphcache";

export const keys: KeyingConfig = {
  assignment_learner_group: (data) =>
    `${data.assignmentId}:${data.learnerGroupId}`,
  assignment_path: (data) => `${data.assignmentId}:${data.pathId}`,
  educator_code: (data) => {
    return `${data.code}`;
  },
  guide_path: (data) => `${data.guideId}:${data.pathId}`,
  learner_group_member: (data) => {
    // Prevent caching data without these as a single "undefined:undefined" item
    if (!data.learnerGroupId || !data.userId) return null;

    return `${data.learnerGroupId}:${data.userId}`;
  },

  learner_group_pending_member: (data) => {
    return `${data.learnerGroupId}:${data.foreignId}:${data.provider}`;
  },
  learner_group_path_collection: (data) =>
    `${data.learnerGroupId}:${data.pathCollectionId}`,
  learner_group_path_collection_aggregate_fields: () => null,
  path_collection_item: (data) => `${data.collectionId}:${data.pathId}`,
  project_award: (data) => `${data.projectId}:${data.awardId}`,
  path_collection_item_aggregate_fields: () => null,
  path_collection_item_aggregate: () => null,
  path_feed: (data) => `${data.pathId}:${data.slug}`,
  user_file_transform: () => null,

  user_follow: (data) => {
    return `${data.sourceUserId}:${data.targetUserId}`;
  },
  user_follow_aggregate: () => null,
  user_follow_aggregate_fields: () => null,
  user_integration: (data) => `${data.userId}:${data.provider}`,
  path_node_comment: (data) => `${data.pathNodeId}:${data.commentId}`,
  project_comment: (data) => `${data.projectId}:${data.commentId}`,
  project_reaction: (data) => `${data.projectId}:${data.userId}`,
  project_stats: (data) => `${data.projectId}`,
};
