import { FC, PropsWithChildren, useState } from "react";
import {
  useRouteMatch,
  useParams,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useQuery } from "urql";
import copy from "copy-to-clipboard";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  ClassroomByIdQueryResult,
  ClassroomLearnerGroupFragment,
  Enums_Integration_Provider_Enum,
} from "@tract/common/dist/graphql";

import {
  Box,
  Flex,
  IconBack,
  Link,
  Text,
  VStack,
  useDisclosure,
  useToast,
  AspectRatio,
  FontAwesomeIcon,
  HStack,
  Avatar,
  ButtonGroup,
  Button,
  IconButton,
  Badge,
  Tooltip,
} from "Shared";

import { ClassroomContent } from "Pages/Classroom";
import { CLASSROOM_BY_ID_QUERY } from "Pages/Classroom/graphql";

import { createContext } from "Utils";

import { LayoutCentered } from "Components/LayoutCentered";
import { PendingApprovaModal } from "Components/PendingApprovalModal";
import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";

import { useSession } from "Services/session";
import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { getDisplayName } from "Types/User";
import { IconGoogleClassroom } from "Shared/Icon/custom/IconGoogleClassroom";
import { AddStudentsModal } from "Pages/Classroom/AddStudentsModal";

// import { isEducatorCodeActive } from "Types/EducatorCode";

type Props = {};

export const [ClassDataProvider, useClassContext] = createContext<{
  stale: boolean;
  classData: ClassroomLearnerGroupFragment;
  classPrimaryOwner: any;
  isClassOwner: boolean;
  isClassPrimaryOwner: boolean;
  onClickInviteStudents: () => void;
  onCopyJoinCode: () => void;
  onCopyStudentInviteLink: () => void;
}>({ strict: true });

export const LayoutClass: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const { currentUser, isApprovedOrg, isAdmin } = useSession();
  const { track } = useAnalytics();
  const toast = useToast();
  const { classId }: { classId: string } = useParams();
  const { state } = useLocation<{ back?: string }>();
  const [backUrl] = useState<string | undefined>(state?.back);

  const isAssignments = !!useRouteMatch("/class/:classId/assignments");
  const isPeople = !!useRouteMatch("/class/:classId/people");
  const isGallery = !!useRouteMatch("/class/:classId/gallery");
  const isSaved = !!useRouteMatch("/class/:classId/saved");
  const isModeration = !!useRouteMatch("/class/:classId/moderation");
  const isSettings = !!useRouteMatch("/class/:classId/settings");

  const [{ data, stale, fetching: loading }, refetchClassroom] = useQuery<
    ClassroomByIdQueryResult["data"]
  >({
    query: CLASSROOM_BY_ID_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      classId,
    },
  });

  const {
    isOpen: isInviteStudentsModalOpen,
    onOpen: openInviteStudentsModal,
    onClose: closeInviteStudentsModal,
  } = useDisclosure();

  const {
    isOpen: isPendingApprovalModalOpen,
    onOpen: openPendingApprovalModal,
    onClose: closePendingApprovalModal,
  } = useDisclosure();

  const classData: ClassroomLearnerGroupFragment | undefined =
    data?.learnerGroups[0];

  const classPrimaryOwner = classData?.members?.find((m) => !!m.isOwner)?.user;
  const isClassPrimaryOwner = classPrimaryOwner?.id === currentUser.id;
  const isClassOwner =
    isClassPrimaryOwner ||
    !!classData?.members.some(
      ({ role, userId }) => role === "teacher" && userId === currentUser.id
    );
  // TODO: Make sure class query fetches valid educator codes only OR loop to find first valid code
  const classCode = classData?.educatorCodes[0]?.code;
  // TODO: Show an error modal if teacher tries to invite students to a class with an invalid code
  // const isClassCodeActive = isEducatorCodeActive(classData?.educatorCodes[0]);
  const studentInviteLink = `${window.location.origin}/get-started/kid?code=${classCode}`;
  const inviteLinkToShow = `${window.location.host}/get-started/kid?code=${classCode}`;

  const onCopyStudentInviteLink = () => {
    if (classData) {
      copy(studentInviteLink);
      toast({
        status: "success",
        title: "Invite Link Copied",
      });
      track(ANALYTICS_EVENTS.CLASS_CODE_COPIED, {
        classId: classData.id,
        classTitle: classData.name,
      });
    }
  };

  const onCopyJoinCode = () => {
    if (classData && classCode) {
      copy(classCode);
      toast({
        status: "success",
        title: "Join Code Copied",
      });
      track(ANALYTICS_EVENTS.CLASS_CODE_COPIED, {
        classId: classData.id,
        classTitle: classData.name,
      });
    }
  };

  const onClickInviteStudents = () => {
    if (isApprovedOrg) {
      openInviteStudentsModal();
    } else {
      openPendingApprovalModal();
    }
  };

  const displayName = getDisplayName(classPrimaryOwner);

  const unfinishedAssignmentCount =
    classData?.assignments?.filter((assignment) => !assignment.isFinished)
      .length || 0;

  // TODO: Fire "Class Viewed" event on load

  if (loading) {
    return <LayoutCentered isLoading />;
  }
  if (!classData) {
    return <Redirect to="/" />;
  }

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
      overflow={{ base: "hidden", lg: "unset" }}
      w="full"
      maxW="100vw"
    >
      <SideNav pt={0} pb="5rem" w="20rem" minW="20rem">
        <Box position="relative">
          <AspectRatio ratio={5 / 2}>
            <Box bg={classData?.color || "gray.100"}>
              {backUrl && (
                <IconButton
                  as={Link}
                  to={backUrl}
                  variant="ghost"
                  color="white"
                  colorScheme="whiteAlpha"
                  pos="absolute"
                  top={3}
                  left={3}
                  icon={<IconBack />}
                  aria-label="Back"
                />
              )}
            </Box>
          </AspectRatio>
        </Box>
        <Box px={6} py={5}>
          <HStack spacing={2}>
            {classData?.source === Enums_Integration_Provider_Enum.Google && (
              <IconGoogleClassroom width={8} height={8} />
            )}
            <Text fontSize="xl" fontWeight="bold">
              {classData?.name}
            </Text>
          </HStack>
          {(isClassOwner || isAdmin) && (
            <Box fontSize="md" mt={2}>
              <Text as="span" color="gray.600">
                Join Code:
              </Text>
              <Tooltip label="Copy Join Code" placement="top">
                <Button
                  variant="link"
                  color="gray.900"
                  display="inline-flex"
                  ml={1}
                  onClick={onCopyJoinCode}
                >
                  {classCode}
                </Button>
              </Tooltip>
            </Box>
          )}
          <HStack spacing={2} mt={2}>
            <Avatar
              size="sm"
              src={classPrimaryOwner?.avatar || undefined}
              name={displayName || ""}
            />
            <Text fontSize="md" color="gray.600">
              {displayName}
            </Text>
          </HStack>
          {(isClassOwner || isAdmin) && (
            <ButtonGroup spacing={2} mt={5} w="full">
              <Button
                variant="solid"
                colorScheme="brandFull"
                size="lg"
                w="full"
                onClick={onClickInviteStudents}
              >
                Add Students
              </Button>
            </ButtonGroup>
          )}
        </Box>
        <VStack w="100%" align="stretch" spacing={0} px={3}>
          <SideNavLink
            as={Link}
            to={`/class/${classId}/assignments`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isAssignments ? solid("inbox") : regular("inbox")}
                size={8}
              />
            }
            iconRight={
              !!unfinishedAssignmentCount ? (
                <Badge variant="solid" colorScheme="brandFull">
                  {unfinishedAssignmentCount}
                </Badge>
              ) : undefined
            }
            isActive={isAssignments}
          >
            Assignments
          </SideNavLink>
          <SideNavLink
            as={Link}
            to={`/class/${classId}/people`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isPeople ? solid("users") : regular("users")}
                size={8}
              />
            }
            isActive={isPeople}
          >
            People
          </SideNavLink>
          <SideNavLink
            as={Link}
            to={`/class/${classId}/gallery`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isGallery ? solid("images") : regular("images")}
                size={8}
              />
            }
            isActive={isGallery}
          >
            Gallery
          </SideNavLink>
          <SideNavLink
            as={Link}
            to={`/class/${classId}/saved`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon
                icon={isSaved ? solid("bookmark") : regular("bookmark")}
                size={8}
              />
            }
            isActive={isSaved}
          >
            Saved
          </SideNavLink>
          {(isClassOwner || isAdmin) && (
            <SideNavLink
              as={Link}
              to={`/class/${classId}/moderation`}
              fontSize="lg"
              minH="3.5rem"
              iconLeft={
                <FontAwesomeIcon
                  icon={
                    isModeration
                      ? solid("shield-check")
                      : regular("shield-check")
                  }
                  size={8}
                />
              }
              isActive={isModeration}
            >
              Moderation
            </SideNavLink>
          )}
          {(isClassOwner || isAdmin) && (
            <SideNavLink
              as={Link}
              to={`/class/${classId}/settings`}
              fontSize="lg"
              minH="3.5rem"
              iconLeft={
                <FontAwesomeIcon
                  icon={isSettings ? solid("gear") : regular("gear")}
                  size={8}
                />
              }
              isActive={isSettings}
            >
              Settings
            </SideNavLink>
          )}
        </VStack>
      </SideNav>
      <Box w="full" overflowX={{ base: "scroll", lg: "auto" }}>
        {classData && (
          <ClassDataProvider
            value={{
              stale,
              classData,
              classPrimaryOwner,
              onClickInviteStudents,
              onCopyJoinCode,
              onCopyStudentInviteLink,
              isClassOwner,
              isClassPrimaryOwner,
            }}
          >
            <ClassroomContent />
          </ClassDataProvider>
        )}
      </Box>
      {classData && (
        <AddStudentsModal
          learnerGroup={classData}
          codeLink={inviteLinkToShow}
          onCopyLink={onCopyStudentInviteLink}
          isOpen={isInviteStudentsModalOpen}
          onClose={(refetch?: boolean) => {
            if (refetch) refetchClassroom();
            closeInviteStudentsModal();
          }}
        />
      )}
      {!isApprovedOrg && (
        <PendingApprovaModal
          isOpen={isPendingApprovalModalOpen}
          onClose={closePendingApprovalModal}
        />
      )}
    </Flex>
  );
};
