import React from "react";
import { BoxProps } from "@chakra-ui/react";
import { TopNav } from "Components/TopNav";
import { useIntercom } from "Services/intercom";
import {
  Box,
  ButtonOutlined,
  Flex,
  IconHelp,
  Link,
  Logo,
  useTheme,
} from "Shared";

export const LayoutStudentOnboarding: React.FC<BoxProps> = (props) => {
  const { colors } = useTheme();
  const { show, isReady } = useIntercom();
  return (
    <Box minH="100vh" width="100%">
      <TopNav
        renderStart={
          <Flex mx={{ base: 5 }}>
            <Logo
              as={Link}
              to="/"
              color={colors.brand}
              height="2.5rem"
              w="auto"
            />
          </Flex>
        }
        renderEnd={
          <Flex mx={{ base: 5 }}>
            <ButtonOutlined
              size="lg"
              aria-label="Help"
              leftIcon={<IconHelp />}
              onClick={isReady ? show : undefined}
            >
              Help
            </ButtonOutlined>
          </Flex>
        }
      />
      {props.children}
    </Box>
  );
};
