import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { PATH_SUBJECT_V2 } from "Constants/paths";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { ExplorePage } from "./BaseExplorePage";

export const ExploreSubject: React.FC = () => {
  const { subject: slug } = useParams<{ subject: string }>();

  const Subject = PATH_SUBJECT_V2.filter((subject) => subject.slug === slug)[0];

  const { track } = useAnalytics();
  useEffect(() => {
    track(ANALYTICS_EVENTS.SUBJECT_VIEWED, {
      subject: Subject?.standardizedLongLabel,
      subjectSlug: slug,
    });
  }, [track, Subject?.standardizedLongLabel, slug]);

  if (!Subject) {
    return <Redirect to="/" />;
  }
  const { standardizedLongLabel, value, iconDuotone } = Subject;

  return (
    <ExplorePage
      subjects={[value]}
      iconDuotone={iconDuotone}
      title={standardizedLongLabel}
      showTopTags={true}
    />
  );
};
