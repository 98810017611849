import React from "react";
import { Box } from "Shared";

export function Logo({ color, ...props }) {
  return (
    <Box display="inline-block" height="40px" {...props}>
      <Box
        as="svg"
        width="68"
        height="100%"
        viewBox="0 0 68 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.82116 12.6644C-0.987344 13.629 0.459459 7.41977 2.56757 6.54687C5.22788 5.44489 10.27 4.72329 13.6525 4.34411C17.9417 3.86305 22.1012 3.62192 24.6934 3.86305C25.6555 3.95227 26.5863 6.45825 25.4771 6.51552C21.9807 6.69637 18.4239 7.05807 12.3998 8.4241C6.70603 9.71537 1.81222 12.1364 0.82116 12.6644Z"
          fill={color}
        />
        <path
          d="M15.8631 7.43593C15.1041 9.01174 13.8201 13.3895 12.7109 18.0012C11.6016 22.6129 10.6642 28.2162 10.6058 29.3833C10.5473 30.551 6.17312 29.3833 6.17312 26.9901C6.17312 24.5968 7.45716 19.9857 8.62485 16.25C9.79254 12.5142 11.3117 8.71997 11.6035 8.01948C11.8958 7.31898 15.8631 7.43593 15.8631 7.43593Z"
          fill={color}
        />
        <path
          d="M23.2466 10.3134C23.6806 9.15535 19.667 8.31138 18.9665 9.77085C18.266 11.2303 17.2183 14.8347 16.3652 18.2811C15.5315 21.6491 15.0794 24.1768 15.0794 25.5772C15.0794 26.9776 18.527 28.554 18.8188 27.2115C19.1106 25.869 19.2275 23.8259 20.6864 20.3819C22.1458 16.938 24.0134 14.7786 24.3637 14.3699C24.7139 13.9612 25.1757 14.0504 24.9472 14.5447C24.7006 15.0788 24.2105 17.2454 24.3848 18.2612C24.6524 19.8255 26.98 20.9546 27.8046 20.8546C28.7324 20.7418 30.9629 18.0291 31.8671 16.1603C32.3343 15.3429 32.2306 12.6524 32.2891 12.3022C31.626 13.5681 29.8042 16.4617 28.9133 16.4617C28.2972 16.4617 28.4225 15.4074 28.8059 14.1734C29.2749 12.6639 29.8507 11.3925 30.2594 10.4587C30.6681 9.52489 27.7679 7.90145 26.6984 8.76592C25.7671 9.51886 23.6686 11.5185 21.7998 14.5326C22.0337 13.5995 22.8849 11.2779 23.2466 10.3134Z"
          fill={color}
        />
        <path
          d="M40.352 9.18364C40.085 11.22 39.2302 13.1985 39.0662 13.4897C39.3453 11.5335 39.4695 10.1518 38.3205 10.1518C37.5591 10.1518 35.8832 11.1742 34.7173 14.7128C33.5949 18.1195 33.6859 20.7327 34.7987 20.7321C35.9116 20.7321 36.6868 18.9821 37.7152 16.9451C38.3241 14.6761 39.6738 9.24995 40.6673 7.71574C41.5071 6.41904 44.7503 7.95145 44.4302 8.70801C44.1101 9.46456 42.8526 12.8489 42.395 14.5362C41.9375 16.2235 41.246 19.4505 42.304 19.4505C43.3626 19.4505 45.2495 16.0457 45.6726 14.7153C46.1169 13.3179 45.716 18.4673 45.7329 18.8748C44.9607 20.6779 42.682 23.3189 41.5607 23.97C40.3147 24.6934 37.9588 23.3117 37.6929 22.015C37.6031 21.5767 37.6296 21.3296 37.6694 20.8768C36.4975 23.1929 35.407 24.2599 34.8897 24.5463C33.8613 25.116 31.1088 24.3956 30.4511 21.7955C30.1394 20.5646 29.9284 17.7861 31.3415 13.7833C32.8262 9.5779 34.7758 7.44024 36.474 7.44024C38.1716 7.43843 39.9819 8.71825 40.352 9.18364Z"
          fill={color}
        />
        <path
          d="M52.0621 12.4233C51.9096 12.7892 51.862 13.1581 52.243 13.2672C52.6649 13.3878 53.3721 13.3269 53.6295 13.1467C54.2323 12.7247 54.9557 11.1573 55.2029 9.11671C55.4705 6.90792 51.4008 5.78966 50.1752 6.25626C48.9497 6.72285 46.8108 8.94128 45.4683 12.8525C44.1258 16.7631 44.2252 19.1147 44.5267 20.5615C44.9083 22.3941 47.9393 24.4414 49.963 23.2429C51.6401 22.2495 55.3747 18.3545 57.4876 13.8098C57.9548 12.9923 58.217 9.61586 58.3919 8.56512C56.0722 13.0014 51.6401 19.8984 49.2288 19.8984C47.36 19.8984 48.0707 15.6346 49.0292 13.2027C50.0728 10.5545 51.0976 9.10767 51.9415 9.10767C53.0073 9.10767 52.6649 10.9765 52.0621 12.4233Z"
          fill={color}
        />
        <path
          d="M64.2791 1.57165C64.8862 0.48233 61.2222 -0.681141 60.682 0.486549C60.1419 1.65424 59.1225 3.92331 57.7209 8.95155C56.0269 15.0299 55.0624 18.8139 55.1197 20.7454C55.1661 22.311 57.8807 24.7344 59.8742 23.0784C62.2506 21.1041 64.3467 17.5908 64.6662 16.7739C64.9857 15.9565 63.7324 14.7689 63.3954 15.4983C63.0337 16.282 61.2951 19.0545 60.0695 19.0545C58.844 19.0545 59.2949 16.9494 59.4963 16.0734C59.697 15.1975 61.7931 6.03263 64.2791 1.57165Z"
          fill={color}
        />
        <path
          d="M56.3024 7.87963C55.9052 7.97427 54.8816 5.17651 55.9558 4.90825C57.0053 4.64662 58.633 4.52605 61.3843 4.6219C64.3617 4.72619 65.8809 5.10236 66.5229 5.74438C67.1649 6.3864 67.9275 7.64753 66.585 7.53119C65.2431 7.41484 58.9947 7.23881 56.3024 7.87963Z"
          fill={color}
        />
        <path
          d="M6.20748 39.8938C4.01498 40.8789 5.52266 34.7119 7.03216 33.6491C9.03116 32.2421 19.2866 29.3394 31.0937 27.6382C44.0492 25.7718 52.0627 25.5048 58.2821 25.7815C59.0417 25.8924 58.7614 27.4742 58.1193 27.4797C57.4773 27.4845 46.9989 27.555 32.9275 30.7181C18.0013 34.0735 7.66152 39.2404 6.20748 39.8938Z"
          fill={color}
        />
      </Box>
    </Box>
  );
}
