import { FC } from "react";
import {
  Box,
  Button,
  ButtonSolid,
  Center,
  EmptyState,
  IconCancel,
  Text,
} from "Shared";
import { PageApp } from "Components/PageApp";

import { signOut } from "Services/auth";
import { useSession } from "Services/session";
import { useIntercom } from "Services/intercom";

export const Disabled: FC = () => {
  const session = useSession({ bypassStrict: true });
  const currentUser = session?.currentUser;
  const { isReady, show } = useIntercom();
  const handleSignOut = () => {
    signOut();
  };

  if (currentUser?.isEducator && !session?.isApprovedOrg) {
    return (
      <PageApp maxWidth="600px" textAlign="center" py={24}>
        <Box mb={6}>
          <IconCancel color="gray.600" width={16} height={16} margin="auto" />
        </Box>
        <Text mb={1} fontSize="2xl" fontWeight="bold">
          Your account has been disabled
        </Text>
        <Text mb={6} color="gray.600">
          We were not able to verify your organization, so we have temporarily
          disabled your account. Please contact support to help us resolve the
          issue.
        </Text>
        {isReady && (
          <ButtonSolid size="lg" onClick={show}>
            Contact Support
          </ButtonSolid>
        )}
      </PageApp>
    );
  }
  return (
    <PageApp py={24}>
      <EmptyState
        headline="Account Disabled"
        body="Your account has been disabled."
      ></EmptyState>

      <Center>
        {isReady && (
          <ButtonSolid size="lg" onClick={show}>
            Contact Support
          </ButtonSolid>
        )}
      </Center>

      <Center mt={8}>
        <Button variant="link" colorScheme="brandFull" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Center>
    </PageApp>
  );
};
