import { useRef } from "react";
import slug from "slug";
import { v4 } from "uuid";
import { useFormik } from "formik";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { useMutation } from "urql";

import {
  PathViewerPathFragment,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
  useCreatePathActivityWithReviewMutation,
  useGetLatestPathReviewForPathQuery,
} from "@tract/common/dist/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import { createNotification } from "@tract/common/dist/utils/create-notification";

import {
  BadgeOutlined,
  Button,
  ButtonSolid,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconChevronDown,
  IconChevronUp,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "Shared";

import { UsernameLink } from "Components/UsernameLink";
import { PathActivityType } from "Pages/CreateV4/PathActions";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";

import { isParentUser, isTeacherUser } from "Types/User";
import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";

enum FeedbackAction {
  Approve = "approve",
  Deny = "deny",
}
interface FeedbackFormProps {
  text: string;
  action: FeedbackAction;
}

export const PathReviewActions = ({
  path,
}: {
  path: PathViewerPathFragment;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useSession();
  const { track } = useAnalytics();
  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);
  const toast = useToast();
  const inputRef = useRef(null);
  const { data } = useGetLatestPathReviewForPathQuery({
    fetchPolicy: "network-only",
    variables: {
      pathId: path?.id,
      type: { _eq: PathActivityType.ReviewRequest },
    },
  });
  const latestReview = !!data?.pathReview?.length
    ? data.pathReview[0]
    : undefined;

  const [createPathActivityWithReview] =
    useCreatePathActivityWithReviewMutation({
      onError: (err) => {
        captureException(err);
        toast({
          status: "error",
          title: "Oops!",
          description: "Something went wrong, please try again.",
        });
      },
      onCompleted: async (data) => {
        const reviewType = data.insert_path_activity_one?.pathReview?.type;
        toast({
          status: "success",
          title:
            reviewType === FeedbackAction.Approve
              ? "Path Published"
              : "Review Submitted",
        });

        const reviewId = data.insert_path_activity_one?.pathReview?.id;

        if (reviewType === FeedbackAction.Deny) {
          createFeedActivity({
            apiKey: process.env.REACT_APP_STREAM_API_KEY,
            feedSlug: "notification",
            feedUserId: path.authorId,
            activity: createNotification({
              type: "actor",
              verb: "review_deny",
              actor: currentUser.id,
              entity: "path_review",
              entityId: reviewId,
              targetPath: `/create/path/${path.id}`,
              description: "requested changes on your path",
            }),
          });
        }

        if (reviewType === FeedbackAction.Approve) {
          track(ANALYTICS_EVENTS.PATH_PUBLISHED, {
            pathId: path?.id,
            pathSlug: path?.slug,
            pathTitle: path?.title,
            pathCoAuthorsCount: path?.authors.length,
            pathAuthorUsername: path?.user?.username,
            pathAuthorCreatorLevel: path?.user?.creatorLevel,
          });

          createFeedActivity({
            apiKey: process.env.REACT_APP_STREAM_API_KEY,
            feedSlug: "notification",
            feedUserId: path.authorId,
            activity: createNotification({
              type: "actor",
              actor: currentUser.id,
              verb: "review_approve",
              entity: "path_review",
              entityId: reviewId,
              description: "approved your path",
              targetPath: `/path/${path.id}`,
              to: path.authors.map((author) => `notification:${author.userId}`),
            }),
          });
        }
      },
    });

  const handleCreatePathReview = async (
    pathId: string,
    pathTitle: string,
    pathAuthorId: string,
    pathAuthorCreatorLevel: string,
    values: FeedbackFormProps
  ) => {
    const pathActivityId = v4();
    const pathReviewId = v4();
    const commentId = v4();
    const ltreeId = commentId.replace(/-/g, "_");
    const pathSlug = slug(pathTitle);
    const reviewType =
      values.action === FeedbackAction.Approve
        ? PathActivityType.ReviewApprove
        : PathActivityType.ReviewDeny;

    // If it's a TractOriginal, switch author to "tractoriginals"
    // and add the original author as a collaborator
    const shouldSwitch =
      path.isTractOriginal && path.authorId !== "tractoriginals";
    const authorId = shouldSwitch ? "tractoriginals" : path.authorId;
    const authors = path.authors.map(({ pathId, userId }) => ({
      pathId,
      userId,
    }));
    if (shouldSwitch) authors.push({ pathId: path.id, userId: path.authorId });

    await createPathActivityWithReview({
      variables: {
        pathId,
        authorId,
        authors,
        status:
          values.action === FeedbackAction.Approve
            ? PathStatus.Published
            : PathStatus.Draft,
        isPublished: values.action === FeedbackAction.Approve,
        publishedAt:
          values.action === FeedbackAction.Approve ? "now()" : undefined,
        slug: values.action === FeedbackAction.Approve ? pathSlug : undefined,
        object: {
          id: pathActivityId,
          pathId: pathId,
          userId: currentUser.id,
          type: reviewType,
          pathReview: {
            data: {
              id: pathReviewId,
              pathId: pathId,
              userId: currentUser.id,
              type: values.action,
              comment: {
                data: {
                  id: commentId,
                  text: values.text,
                  userId: currentUser.uid,
                  path: ltreeId,
                },
              },
            },
          },
        },
      },
    });

    track(ANALYTICS_EVENTS.PATH_REVIEW_SUBMITTED, {
      pathReviewStatus:
        values.action === FeedbackAction.Approve
          ? "approved"
          : "changesRequested",
      pathId: pathId,
      pathTitle: pathTitle,
      pathAuthorId: pathAuthorId,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: pathAuthorCreatorLevel,
      pathCoAuthorsCount: path?.authors.length,
    });
  };

  const formik = useFormik<FeedbackFormProps>({
    initialValues: {
      text: "",
      action: FeedbackAction.Approve,
    },
    onSubmit: (values, { setFieldError, resetForm }) => {
      if (values.action === FeedbackAction.Deny && values.text.trim() === "") {
        setFieldError("text", "Please provide a feedback");
        return;
      }
      handleCreatePathReview(
        path.id,
        path.title || "",
        path.authorId,
        path.user?.creatorLevel || "",
        values
      );
      resetForm();
      onClose();
    },
  });

  return (
    <Flex
      px={4}
      py={2}
      bgColor="brandFull.400"
      position={{ lg: "sticky" }}
      top={{ lg: "4rem" }}
      zIndex={11}
    >
      <HStack color="white" flex={1}>
        <BadgeOutlined
          color="white"
          fontSize="md"
          textTransform="capitalize"
          px={4}
          py={2}
        >
          {path.status === PathStatus.InReview
            ? "In Review"
            : path.status.toLowerCase()}
        </BadgeOutlined>
        {path.status === PathStatus.InReview && !!latestReview && (
          <Text>
            <UsernameLink
              username={latestReview.user.username || ""}
              userId={latestReview.user?.id || ""}
              isTeacher={isTeacherUser(latestReview.user)}
              isParent={isParentUser(latestReview.user)}
            >
              {isTeacherUser(latestReview.user)
                ? `${latestReview.user.firstName} ${latestReview.user.lastName}`
                : latestReview.user.username}
            </UsernameLink>{" "}
            requested a review{" "}
            {formatDistanceStrict(new Date(latestReview.updatedAt), new Date())}{" "}
            ago
          </Text>
        )}
      </HStack>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        initialFocusRef={inputRef}
      >
        <PopoverTrigger>
          <Button
            color="black"
            bgColor="white"
            rightIcon={isOpen ? <IconChevronUp /> : <IconChevronDown />}
          >
            Review Path
          </Button>
        </PopoverTrigger>
        <PopoverContent
          px={3}
          py={2}
          borderRadius="xl"
          boxShadow="xl"
          w="600px"
          right={4}
        >
          <PopoverCloseButton aria-label="close popup" />
          <PopoverHeader border="none" fontWeight="bold" fontSize="xl">
            Review Path
          </PopoverHeader>
          <PopoverBody>
            <FormControl isInvalid={!!formik.errors.text}>
              <Textarea
                ref={inputRef}
                pt={3}
                rows={6}
                name="text"
                placeholder="Provide feedback"
                onChange={formik.handleChange}
                value={formik.values.text}
              ></Textarea>
              <FormErrorMessage>{formik.errors.text}</FormErrorMessage>
            </FormControl>
            <RadioGroup
              mt={4}
              mb={2}
              name="action"
              defaultValue="approve"
              onChange={(value) => formik.setFieldValue("action", value)}
              value={formik.values.action}
            >
              <Stack spacing={4}>
                <Radio value="approve" alignItems="start">
                  <Text fontWeight="bold">Approve</Text>
                  <Text fontSize="sm" color="gray.600" mt={1}>
                    Path is ready to be published.
                  </Text>
                </Radio>
                <Radio value="deny" alignItems="start">
                  <Text fontWeight="bold">Request Changes</Text>
                  <Text fontSize="sm" color="gray.600" mt={1}>
                    Submit feedback that must be addressed before publishing.
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          </PopoverBody>
          <PopoverFooter border="none">
            <ButtonSolid size="lg" onClick={formik.submitForm}>
              {formik.values.action === FeedbackAction.Approve
                ? "Submit & Publish"
                : "Submit Review"}
            </ButtonSolid>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
