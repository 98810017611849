import { createIcon } from "@chakra-ui/react";

export const IconPath = createIcon({
  displayName: "IconPath",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
    fill: "none",
  },
  path: [
    <path key={1} d="M18 7.5V20" />,
    <path key={2} d="M4 8V17" />,
    <path key={3} d="M11 7.5V17" />,
    <path key={4} d="M15 17L18 20L21 17" />,
    <circle key={5} r="2" transform="matrix(-1 0 0 1 4 6)" />,
    <path
      key={6}
      d="M4 17C4 18.933 5.567 20.5 7.5 20.5C9.433 20.5 11 18.933 11 17"
    />,
    <path
      key={7}
      d="M18 7.5C18 5.567 16.433 4 14.5 4C12.567 4 11 5.567 11 7.5"
    />,
  ],
});
