import React, { forwardRef } from "react";
import uid from "uid";
import { default as Uploader } from "react-firebase-file-uploader";

export const FileUploader: React.FC<any> = forwardRef((props, ref) => {
  const normalizeFilename = (file: File) => {
    const fileParts = file.name.split(".");
    let ext = fileParts[fileParts.length - 1];
    if (ext === "jpeg") {
      ext = "jpg";
    }
    return `${uid(32)}.${ext}`;
  };

  return <Uploader ref={ref} filename={normalizeFilename} {...props} />;
});
