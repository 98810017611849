import React, { FC, PropsWithChildren } from "react";
import { useRouteMatch } from "react-router-dom";

import { Box, Flex, Link, VStack } from "Shared";

import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";

import { SideNavTitle } from "Components/SideNav/SideNavTitle";

type Props = {};

export const LayoutAdmin: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const isPaths = !!useRouteMatch("/admin/paths");
  const isCollections = !!useRouteMatch("/admin/collections");
  const isOrganizations = !!useRouteMatch("/admin/organizations");
  const isPrizeList = !!useRouteMatch("/admin/shop");
  const isPrizeBuilder = !!useRouteMatch("/admin/shop-item");
  const isPrizes = isPrizeList || isPrizeBuilder;
  const isUsers = !!useRouteMatch("/admin/users");
  const isCreateUser = !!useRouteMatch("/admin/create-user");
  const isSubmissions = !!useRouteMatch("/admin/submissions");
  const isSubmissionsQueue = !!useRouteMatch("/admin/submissions-queue");
  const isSubmissionsFeedbackQueue = !!useRouteMatch(
    "/admin/submissions-feedback"
  );
  const isComments = !!useRouteMatch("/admin/comments");
  const isAbuseReports = !!useRouteMatch("/admin/reports");
  const isLiveStreams = !!useRouteMatch("/admin/livestreams");

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
    >
      <SideNav>
        <SideNavTitle>Admin</SideNavTitle>
        <VStack w="100%" align="stretch" spacing={0} p={2}>
          <SideNavLink
            as={Link}
            to="/admin/users"
            isActive={isUsers || isCreateUser}
          >
            Users
          </SideNavLink>
          <SideNavLink as={Link} to="/admin/paths" isActive={isPaths}>
            Paths
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/collections"
            isActive={isCollections}
          >
            Collections
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/organizations"
            isActive={isOrganizations}
          >
            Organizations
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/submissions-feedback"
            isActive={isSubmissionsFeedbackQueue}
          >
            Submissions Feedback
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/submissions-queue"
            isActive={isSubmissionsQueue}
          >
            Submissions Queue
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/submissions"
            isActive={isSubmissions}
          >
            Submissions
          </SideNavLink>
          <SideNavLink as={Link} to="/admin/comments" isActive={isComments}>
            Comments
          </SideNavLink>
          <SideNavLink as={Link} to="/admin/reports" isActive={isAbuseReports}>
            Reports
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/admin/livestreams"
            isActive={isLiveStreams}
          >
            Livestreams
          </SideNavLink>
          <SideNavLink as={Link} to="/admin/shop" isActive={isPrizes}>
            Shop
          </SideNavLink>
        </VStack>
      </SideNav>
      <Box w="100%" overflowX={{ base: "scroll", lg: "auto" }}>
        {children}
      </Box>
    </Flex>
  );
};
