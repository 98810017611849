import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminRemoveCoTeacherMutationResult,
  AdminRemoveCoTeacherMutationVariables,
  AdminDashboardLearnerGroupFragment,
  AdminLearnerGroupMemberFragment,
} from "@tract/common/dist/graphql";

import { ADMIN_LEARNER_GROUP_FRAGMENT } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/graphql";

export const delete_learner_group_member: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  if (result.adminRemoveCoTeacher) {
    const variables = info.variables as AdminRemoveCoTeacherMutationVariables;

    const removedMember = (result as AdminRemoveCoTeacherMutationResult["data"])
      ?.adminRemoveCoTeacher;

    const learnerGroup = cache.readFragment(
      ADMIN_LEARNER_GROUP_FRAGMENT,
      cache.keyOfEntity({
        __typename: "learner_group",
        id: variables.learnerGroupId,
      })
    );

    cache.writeFragment<AdminDashboardLearnerGroupFragment>(
      ADMIN_LEARNER_GROUP_FRAGMENT,
      {
        ...learnerGroup,
        members: learnerGroup.members.filter(
          (member: AdminLearnerGroupMemberFragment) =>
            member.userId !== removedMember?.returning[0].userId
        ),
      }
    );
  }
};
