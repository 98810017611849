import { PROFILE_BADGE_USER_TYPE_FRAGMENT } from "Types/User";
import { gql } from "urql";

export const PATHS_BY_STATUS_FRAGMENT = gql`
  ${PROFILE_BADGE_USER_TYPE_FRAGMENT}
  fragment pathsByStatus on path {
    id
    slug
    title
    authorId
    createdAt
    updatedAt
    isPublished
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
    nodes {
      id
    }
    user {
      id: firestoreId
      ...ProfileBadgeUserType
      avatar
      username
      firstName
      lastName
      isEducator
    }
    authors_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const CREATE_PATH_MUTATION = gql`
  ${PATHS_BY_STATUS_FRAGMENT}

  mutation CreatePath($path: path_insert_input!) {
    insert_path_one(object: $path) {
      ...pathsByStatus
    }
  }
`;

export const CREATE_PATH_NODE_MUTATION = gql`
  mutation CreatePathNode($node: path_node_insert_input!) {
    insert_path_node_one(object: $node) {
      id
      pathId
      title
      caption
      createdAt
      lexorank
      responsesEnabled
      rewardAmount
    }
  }
`;

export const CREATOR_PATHS_QUERY = gql`
  ${PATHS_BY_STATUS_FRAGMENT}
  query CreatorPaths(
    $offset: Int!
    $status: String!
    $authorId: String!
    $limit: Int!
    $orderBy: path_order_by!
  ) {
    paths: path(
      offset: $offset
      limit: $limit
      where: {
        status: { _eq: $status }
        _or: [
          { authorId: { _eq: $authorId } }
          { authors: { userId: { _eq: $authorId } } }
        ]
      }
      order_by: [$orderBy]
    ) {
      ...pathsByStatus
    }
  }
`;
