import React, { FC, RefObject, useRef, useState } from "react";
import { FormikProps } from "formik";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "urql";

import {
  Box,
  Text,
  ButtonOutlined,
  ButtonGroup,
  ButtonSolid,
  useToast,
} from "Shared";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { post } from "Services/api";

import { PageHeader } from "Components/PageHeader";
import { PageContent } from "Components/PageContent";

import { PrizeForm } from "./PrizeForm";

import { PrizeFormValues } from ".";
import { ADMIN_SHOP_ITEM_OWNERS, CREATE_ADMIN_SHOP_ITEM } from "../graphql";
import {
  AdminShopItemOwnersQuery,
  CreateAdminShopItemMutation,
  CreateAdminShopItemMutationVariables,
} from "@tract/common/dist/graphql";

type Props = {};

export const CreatePrize: FC<Props> = () => {
  const history = useHistory();
  const toast = useToast();
  const { firebaseUser } = useSession();
  const formikRef = useRef() as RefObject<FormikProps<PrizeFormValues>>;
  const [isLoading, setLoading] = useState(false);

  const [{ data }] = useQuery<AdminShopItemOwnersQuery>({
    requestPolicy: "cache-and-network",
    query: ADMIN_SHOP_ITEM_OWNERS,
  });

  const [, createMutation] = useMutation<
    CreateAdminShopItemMutation,
    CreateAdminShopItemMutationVariables
  >(CREATE_ADMIN_SHOP_ITEM);

  async function handleSubmit(prize: PrizeFormValues) {
    try {
      setLoading(true);

      await createMutation({
        object: {
          name: prize.title,
          description: prize.description || "",
          cost: prize.cost || 0,
          externalURL: prize.detailsURL || "",
          disabled: prize.disabled,
          tags: [prize.category],
          quantity: typeof prize.quantity === "number" ? prize.quantity : null,
          ...(prize.newCoverFileId && {
            coverFile: {
              data: {
                id: prize.newCoverFileId,
                filename: prize.cover,
                userId: firebaseUser.uid,
              },
            },
          }),
          metadata: {
            successText: prize.successText,
            successActionURL: prize.successActionURL,
            successActionText: prize.successActionText,
            successActionRequired: prize.successActionRequired,
          },
          ownerId: prize.ownerId,
        },
      });

      if (prize.newCoverFileId) {
        await post("/v2/transloadit", firebaseUser, {
          fileId: prize.newCoverFileId,
          templateName: "shop-item-covers",
        });
      }

      toast({
        status: "success",
        title: "Shop Item Saved",
      });

      history.push(`/admin/shop`);
    } catch (e: any) {
      captureException(e);
      toast({
        status: "error",
        title: "Oops!",
        description: "There was an error saving the prize, please try again.",
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  }

  function submitForm() {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  }

  return (
    <PageContent>
      <PageHeader
        position="sticky"
        top={0}
        bg="white"
        zIndex={1}
        renderTitle={
          <Text
            fontSize={["xl", null, "2xl"]}
            fontWeight="bold"
            color="gray.300"
            mr={10}
            noOfLines={1}
          >
            New Prize
          </Text>
        }
        renderActions={
          <ButtonGroup>
            <ButtonOutlined onClick={history.goBack} disabled={isLoading}>
              Cancel
            </ButtonOutlined>
            <ButtonSolid onClick={submitForm} isLoading={isLoading}>
              Save
            </ButtonSolid>
          </ButtonGroup>
        }
      />
      <Box maxWidth="50rem" mx="auto">
        <PrizeForm
          ref={formikRef}
          owners={data?.owners}
          onSubmit={handleSubmit}
        />
      </Box>
    </PageContent>
  );
};
