import { FC, useState } from "react";
import { Field, FieldProps, Formik, useFormikContext } from "formik";
import * as yup from "yup";

import {
  Avatar,
  Button,
  ButtonOutlined,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Input,
  VStack,
  useToast,
  Textarea,
} from "Shared";

import { PageHeader } from "Components/PageHeader";

import { useSession } from "Services/session";
import { patch } from "Services/api";
import { captureException, FIREBASE_ERRORS } from "Services/errors";

import { AdminDashboardUserAccountFragment } from "@tract/common/dist/graphql";
import { USER_UPDATE_ACTION } from "@tract/common/dist/types/api/users";
import to from "@tract/common/dist/utils/async";

import { isKidUser } from "Types/User";
import { CustomAvatarFileUploader } from "Pages/Settings/ProfileEdit";

export type FormValues = {
  firstName?: string;
  lastName?: string;
  bio?: string;
  username?: string;
  avatar?: string;
};

type Props = {
  user: AdminDashboardUserAccountFragment;
  onSaveSuccess: () => void;
};

export const UserProfile: FC<Props> = ({ user, onSaveSuccess }) => {
  const { firebaseUser } = useSession();
  const toast = useToast();

  const initialValues: FormValues = {
    avatar: user.avatar || "",
    username: user.username || "",
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    bio: user.bio || "",
  };

  let usernameSchema = yup.string().trim();

  if (isKidUser(user)) {
    usernameSchema = usernameSchema.required("Please provide a username");
  }

  const schema = yup.object({
    avatar: yup.string().trim(),
    username: usernameSchema,
    firstName: yup.string().trim().required("Please provide first name"),
    lastName: yup.string().trim(),
    bio: yup.string().trim(),
  });

  const handleSubmit = async (values: FormValues) => {
    const [err, response] = await to<any, any>(
      patch("/v1/users", firebaseUser, {
        op: USER_UPDATE_ACTION.UPDATE_PROFILE,
        userId: user.id,
        ...values,
      })
    );

    if (err) {
      const errCode = err.response?.data?.code;
      if (errCode === FIREBASE_ERRORS.EMAIL_EXISTS) {
        toast({
          status: "error",
          title: "Username already exists",
        });
      } else {
        toast({
          status: "error",
          title: "Something went wrong",
        });
      }
      captureException(err);
      return;
    }

    if (!response) {
      toast({
        status: "error",
        title: "Something went wrong",
      });
      captureException(new Error("No response"));
      return;
    }

    toast({
      title: "Profile updated successfully!",
      status: "success",
    });

    onSaveSuccess();
  };

  return (
    <>
      <PageHeader title="Edit Profile" />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} noValidate autoComplete="off">
            <ProfileForm user={user} />
          </form>
        )}
      </Formik>
    </>
  );
};

const ProfileForm: React.FC<{ user: AdminDashboardUserAccountFragment }> = ({
  user,
}) => {
  const formik = useFormikContext();
  const [invalidFile, setInvalidFile] = useState(false);

  return (
    <VStack alignItems="start" spacing={4}>
      <Flex align="center">
        <Field name="avatar">
          {({ field }: FieldProps) => (
            <>
              <Avatar size="xl" src={field.value} mr={6} />
              <FormControl isInvalid={invalidFile}>
                <FormLabel fontWeight="bold">Avatar</FormLabel>
                <CustomAvatarFileUploader
                  field={field}
                  userId={user.id}
                  setInvalidFile={setInvalidFile}
                />
              </FormControl>
            </>
          )}
        </Field>
      </Flex>
      <Field name="username">
        {({ field, meta }: FieldProps) => (
          <FormControl isRequired isInvalid={!!meta.error}>
            <FormLabel fontWeight="bold">Username</FormLabel>
            <Input
              name={field.name}
              size="lg"
              placeholder="Username"
              value={field.value}
              onChange={field.onChange}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="firstName">
        {({ field, meta }: FieldProps) => (
          <FormControl isRequired isInvalid={!!meta.error}>
            <FormLabel fontWeight="bold">First Name</FormLabel>
            <Input
              name={field.name}
              size="lg"
              placeholder="First Name"
              value={field.value}
              onChange={field.onChange}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="lastName">
        {({ field }: FieldProps) => (
          <FormControl>
            <FormLabel fontWeight="bold">Last Name</FormLabel>
            <Input
              name={field.name}
              size="lg"
              placeholder="Last Name"
              value={field.value}
              onChange={field.onChange}
            />
          </FormControl>
        )}
      </Field>
      <Field name="bio">
        {({ field }: FieldProps) => (
          <FormControl>
            <FormLabel fontWeight="bold">Bio</FormLabel>
            <Textarea
              name={field.name}
              size="lg"
              placeholder="Bio"
              value={field.value}
              onChange={field.onChange}
              display="block"
              maxLength={1000}
              height="10rem"
            />
          </FormControl>
        )}
      </Field>
      <HStack>
        <Button
          type="submit"
          variant="solid"
          colorScheme="brandFull"
          isLoading={formik.isSubmitting}
          disabled={!formik.dirty || formik.isSubmitting}
        >
          Save Changes
        </Button>
        <ButtonOutlined onClick={formik.resetForm} disabled={!formik.dirty}>
          Reset
        </ButtonOutlined>
      </HStack>
    </VStack>
  );
};
