import React, { FC, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { ButtonOutlined, Grid } from "Shared";
import { useDocument } from "Utils/hooks/firestore";
import { captureException } from "Services/errors";

import { Enums } from "Types/Enums";

function AvatarGrid({ onChange }: { onChange: (url: string) => void }) {
  const { data } = useDocument<Enums>(`enums/user`, {
    onError: (err) => captureException(err),
  });

  return (
    <Grid templateColumns="1fr 1fr 1fr" gap={6}>
      {data?.avatars?.map((url, i) => (
        <button
          onClick={(e) => {
            e.preventDefault();
            onChange(url);
          }}
          key={i}
        >
          <img src={url} alt="" />
        </button>
      ))}
    </Grid>
  );
}

type Props = {
  onChange: (url: string) => void;
  initialValue: string;
  autoFocus?: boolean;
};

export const AvatarPopover: FC<Props> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  function handleChange(url: string) {
    onChange(url);
    close();
  }

  return (
    <Popover isOpen={isOpen} onOpen={open} onClose={close}>
      <PopoverTrigger>
        <ButtonOutlined size="lg" {...props}>
          {initialValue ? "Change" : "Select"}
        </ButtonOutlined>
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverBody p={6} maxHeight="320px" overflow="scroll">
          <AvatarGrid onChange={handleChange} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
