import { MediaDoc } from "@tract/common/dist/utils/media";
import { UserFragment } from "./User";

interface ProjectChallenge {
  id: string;
  question: string;
  description: string;
}

interface ProjectMission {
  id: string;
  challenge: ProjectChallenge;
}

export enum ProjectStatus {
  Errored = "errored",
  Published = "published",
  Deleted = "deleted",
}

export interface Project extends MediaDoc {
  activityId: string;
  commentCount: number;
  viewCount: number;
  pathId: string;
  createdAt: { seconds: number };
  caption: string;
  likes: number;
  userId: string;
  user: UserFragment;
  status: ProjectStatus;
  images?: {
    thumbUrl: string;
    originalUrl: string;
  };
  mission: ProjectMission;
  submissionId: string;
  awesomeScore?: number;
  bestScore?: number;
  awards?: Record<
    string,
    {
      count: number;
    }
  >;
}

export enum ViewSource {
  Unknown = "unknown",
  ChallengeAwesome = "challengeAwesome",
  ChallengeHistory = "challengeHistory",
  ClassHub = "classHub",
  ExploreAllAwesome = "exploreAllAwesome",
  ExploreAllFollow = "exploreAllFollow",
  ExploreAllNew = "exploreAllNew",
  HomeAwesome = "homeAwesome",
  Notification = "notification",
  ProjectDetails = "projectDetails",
  TeacherClass = "teacherClass",
  UserProfile = "userProfile",
  UserProfileLikes = "userProfileLikes",
  FollowingProjects = "followingProjects",
}
