import * as Yup from "yup";

export const USERNAME_MAX_LENGTH = 32;
export const StudentSchema = Yup.object().shape({
  username: Yup.string()
    .min(4, "Your username should have at least 4 characters")
    .max(
      USERNAME_MAX_LENGTH,
      `Your username should not exceed ${USERNAME_MAX_LENGTH} characters`
    )
    .matches(
      new RegExp("^[A-Za-z0-9_]+$", "g"),
      "Your username should only contain letters/numbers or underscores"
    )
    .required("Enter a username"),
  password: Yup.string()
    .required("Enter a password")
    .min(6, "Password must be at least 6 characters")
    .max(1000, "Cannot exceed 1000 characters"),
});
