import { createIcon } from "@chakra-ui/react";

export const IconPartner = createIcon({
  displayName: "IconPartner",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
    fill: "none",
  },
  path: [
    <path
      key={1}
      d="M7.33367 3.81861C8.13776 3.75441 8.90109 3.43814 9.51496 2.91484C10.2083 2.32432 11.0893 2 12 2C12.9107 2 13.7917 2.32432 14.485 2.91484C15.0989 3.43814 15.8622 3.75441 16.6663 3.81861C17.5744 3.89118 18.4269 4.28482 19.071 4.92896C19.7152 5.5731 20.1088 6.42561 20.1814 7.33367C20.2451 8.13743 20.5614 8.9012 21.0852 9.51496C21.6757 10.2083 22 11.0893 22 12C22 12.9107 21.6757 13.7917 21.0852 14.485C20.5619 15.0989 20.2456 15.8622 20.1814 16.6663C20.1088 17.5744 19.7152 18.4269 19.071 19.071C18.4269 19.7152 17.5744 20.1088 16.6663 20.1814C15.8622 20.2456 15.0989 20.5619 14.485 21.0852C13.7917 21.6757 12.9107 22 12 22C11.0893 22 10.2083 21.6757 9.51496 21.0852C8.90109 20.5619 8.13776 20.2456 7.33367 20.1814C6.42561 20.1088 5.5731 19.7152 4.92896 19.071C4.28482 18.4269 3.89118 17.5744 3.81861 16.6663C3.75441 15.8622 3.43814 15.0989 2.91484 14.485C2.32432 13.7917 2 12.9107 2 12C2 11.0893 2.32432 10.2083 2.91484 9.51496C3.43814 8.90109 3.75441 8.13776 3.81861 7.33367C3.89118 6.42561 4.28482 5.5731 4.92896 4.92896C5.5731 4.28482 6.42561 3.89118 7.33367 3.81861Z"
    />,
    <path key={2} d="M16 9L11 15L8 12" />,
  ],
});
