import { useState } from "react";
import { useDocument } from "Utils/hooks/firestore";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useClient, useQuery } from "urql";
import { useFormik } from "formik";

import {
  ButtonOutlined,
  EmptyState,
  Link,
  Modal,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VisuallyHidden,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  HStack,
  useToast,
  ModalBody,
  ModalFooter,
  ButtonSolid,
  FormControl,
  FormLabel,
  Input,
  VStack,
  SkeletonText,
  IconSettings,
  Alert,
} from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";

import { EducatorSignUp } from "@tract/common/dist/types/models/Educator";

import { captureException } from "Services/errors";
import { post } from "Services/api";
import { useSession } from "Services/session";
import to from "@tract/common/dist/utils/async";
import {
  ACTIVE_ORG_TEACHERS_QUERY,
  ADMIN_MOVE_USER,
  ADMIN_ORG_QUERY,
} from "../graphql";
import {
  ActiveOrgTeachersQuery,
  ActiveOrgTeachersQueryVariables,
  AdminOrgByIdQuery,
  AdminOrgByIdQueryVariables,
  DisableUserMutation,
  DisableUserMutationVariables,
} from "@tract/common/dist/graphql";
import { UserType } from "@tract/common/dist/types/models/User";
import { DISABLE_USER_MUTATION } from "Pages/AdminDashboard/AdminUsers/graphql";

export const TeachersTable = () => {
  const { orgId } = useParams<{ orgId?: string }>();
  const [currentTeacher, setCurrentTeacher] = useState<
    ActiveOrgTeachersQuery["user"][0] | null
  >(null);
  const {
    isOpen: isDetailsModalOpen,
    onClose: closeDetailsModal,
    onOpen: openDetailsModal,
  } = useDisclosure();
  const {
    isOpen: isMoveModalOpen,
    onClose: closeMoveModal,
    onOpen: openMoveModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmModalOpen,
    onClose: closeDeleteConfirmModal,
    onOpen: openDeleteConfirmModal,
  } = useDisclosure();

  const [{ data, fetching: loadingUsers }, refetch] = useQuery<
    ActiveOrgTeachersQuery,
    ActiveOrgTeachersQueryVariables
  >({
    query: ACTIVE_ORG_TEACHERS_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      orgId: orgId || "",
      userType: UserType.Parent,
    },
  });

  const users = data?.user || [];

  if (loadingUsers) {
    return <LayoutCentered isLoading />;
  }

  if (!users?.length) {
    return <EmptyState headline="Nothing here" />;
  }
  return (
    <>
      <Table width="100%">
        <Thead>
          <Tr>
            <Th py={2}>ID</Th>
            <Th py={2}>Name</Th>
            <Th py={2}>Created at</Th>
            <Th py={2}>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody fontSize="md">
          {users?.map((user) => (
            <Tr key={user.id}>
              <Td>{user.id}</Td>
              <Td>
                {user.firstName} {user.lastName}{" "}
                {user.email && (
                  <Text as="span" color="gray.600">
                    ({user.email})
                  </Text>
                )}
              </Td>
              <Td>{user.createdAt.toLocaleString()}</Td>
              <Td>
                <HStack>
                  <ButtonOutlined
                    onClick={() => {
                      setCurrentTeacher(user);
                      openDeleteConfirmModal();
                    }}
                  >
                    Delete
                  </ButtonOutlined>
                  <ButtonOutlined
                    onClick={() => {
                      setCurrentTeacher(user);
                      openDetailsModal();
                    }}
                  >
                    Details
                  </ButtonOutlined>
                  <ButtonOutlined
                    onClick={() => {
                      setCurrentTeacher(user);
                      openMoveModal();
                    }}
                  >
                    Move
                  </ButtonOutlined>
                  <ButtonOutlined
                    as={Link}
                    leftIcon={<IconSettings />}
                    to={`/admin/users/${user.id}/account`}
                  >
                    Account
                  </ButtonOutlined>
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {currentTeacher && (
        <>
          <MoveEducatorModal
            user={currentTeacher}
            isOpen={isMoveModalOpen}
            onClose={closeMoveModal}
          />
          <SignUpDetailsModal
            user={currentTeacher}
            isOpen={isDetailsModalOpen}
            onClose={closeDetailsModal}
          />
          <ConfirmDeleteModal
            user={currentTeacher}
            isOpen={isDeleteConfirmModalOpen}
            onClose={() => {
              refetch();
              closeDeleteConfirmModal();
            }}
          />
        </>
      )}
    </>
  );
};

const SignUpDetailsModal = ({
  user,
  isOpen,
  onClose,
}: {
  user?: ActiveOrgTeachersQuery["user"][0];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: signupDoc } = useDocument<EducatorSignUp>(
    `educator-signups/${user?.id}`
  );

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Sign Up Details</ModalHeader>
        <ModalBody>
          <Table width="100%">
            <Tbody>
              <Tr>
                <Td>First Name</Td>
                <Td>{signupDoc?.firstName}</Td>
              </Tr>
              <Tr>
                <Td>Last Name</Td>
                <Td>{signupDoc?.lastName}</Td>
              </Tr>
              <Tr>
                <Td>Email</Td>
                <Td>{signupDoc?.email}</Td>
              </Tr>
              <Tr>
                <Td>Role</Td>
                <Td>{signupDoc?.educatorRole}</Td>
              </Tr>
              <Tr>
                <Td>Usecase</Td>
                <Td>
                  {signupDoc?.useCase === "other"
                    ? signupDoc?.otherUseCase
                    : signupDoc?.useCase}
                </Td>
              </Tr>
              {signupDoc?.phoneNumber && (
                <Tr>
                  <Td>Phone</Td>
                  <Td>{signupDoc?.phoneNumber}</Td>
                </Tr>
              )}
              {signupDoc?.accessCode && (
                <Tr>
                  <Td>Access Code</Td>
                  <Td>{signupDoc?.accessCode}</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const MoveEducatorModal = ({
  user,
  isOpen,
  onClose,
}: {
  user?: ActiveOrgTeachersQuery["user"][0];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();
  const history = useHistory();
  const [fetchingOrg, setFetchingOrg] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<
    AdminOrgByIdQuery["organization_by_pk"] | null
  >(null);

  const [, moveUser] = useMutation(ADMIN_MOVE_USER);
  const client = useClient();
  const formik = useFormik({
    initialValues: { orgId: "" },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (fetchingOrg) return;

      setFetchingOrg(true);
      setSelectedOrg(null);
      try {
        const { data } = await client
          .query<AdminOrgByIdQuery, AdminOrgByIdQueryVariables>(
            ADMIN_ORG_QUERY,
            {
              orgId: values.orgId,
            }
          )
          .toPromise();

        const org = data?.organization_by_pk;
        setSelectedOrg(org);
        if (!org) {
          toast({
            title: "Organization not found",
            description:
              "Make sure the ID is correct and the organization is approved",
            status: "error",
          });
        }
      } catch (error: any) {
        captureException(error);
        toast({
          title: "Something went wrong",
          status: "error",
        });
      } finally {
        setFetchingOrg(false);
      }
    },
  });

  const confirmMove = async () => {
    if (selectedOrg) {
      try {
        setUpdating(true);

        if (user) {
          await moveUser({
            userId: user?.id,
            orgId: selectedOrg.id,
          });
        }
        toast({
          title: "Educator moved",
          status: "success",
        });
        onClose();
        history.push(`/admin/organizations/${selectedOrg.id}`);
      } catch (error: any) {
        captureException(error);
        toast({
          title: "Something went wrong",
          status: "error",
        });
      } finally {
        setUpdating(false);
      }
    }
  };
  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Move Educator</ModalHeader>
        <ModalBody>
          <>
            <Text mb={6}>
              Move {user?.firstName} {user?.lastName}{" "}
              <Text as="span" color="gray.600">
                ({user?.email})
              </Text>{" "}
              to a different organization
            </Text>
            <Alert status="error" mb={6}>
              All their classrooms and students will be moved to the new
              organization
            </Alert>
            <form onSubmit={formik.handleSubmit}>
              <FormControl>
                <FormLabel>Organizaton ID:</FormLabel>
                <Input
                  value={formik.values.orgId}
                  onChange={formik.handleChange}
                  size="lg"
                  id="orgId"
                  name="orgId"
                  variant="filled"
                  placeholder="Paste Organization ID here"
                />
              </FormControl>
            </form>
            {fetchingOrg && <SkeletonText mt={6} />}
            {selectedOrg && (
              <VStack
                alignItems="start"
                p={4}
                border="1px solid"
                borderRadius="lg"
                mt={6}
              >
                <Text fontWeight="bold">{selectedOrg.name}</Text>
                <Text color="gray.600">{selectedOrg.address}</Text>
              </VStack>
            )}
          </>
        </ModalBody>
        <ModalFooter>
          {selectedOrg && (
            <ButtonSolid
              isDisabled={updating}
              isLoading={updating}
              onClick={confirmMove}
            >
              Confirm Move
            </ButtonSolid>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ConfirmDeleteModal = ({
  user,
  isOpen,
  onClose,
}: {
  user?: ActiveOrgTeachersQuery["user"][0];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const session = useSession();
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [isDeleteConfirm, setIsConfirmDelete] = useState(false);
  const [, updateDisableUser] = useMutation<
    DisableUserMutation,
    DisableUserMutationVariables
  >(DISABLE_USER_MUTATION);
  const onDelete = async () => {
    if (!user) return;

    setDeleting(true);
    try {
      await updateDisableUser({
        userId: user.id,
        disabled: true,
      });

      const [err, response] = await to(
        post("/v1/users/delete", session.firebaseUser, { userId: user?.id })
      );

      if (err) {
        throw err;
      }

      if (!response) {
        throw new Error("No response from /users/delete");
      }

      toast({
        title: "User deleted",
        status: "success",
      });

      onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error deleting user",
        status: "error",
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Delete User?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this user? This action is{" "}
          <Text as="span" fontWeight="bold">
            permanent
          </Text>{" "}
          and{" "}
          <Text as="span" fontWeight="bold">
            irreversible
          </Text>
          !
          <Text mt={2}>
            {user?.firstName} {user?.lastName} ({user?.email})
          </Text>
          <Alert status="error" mt={3}>
            Destructive action: deletes the linked firebase account (frees up
            the email), any sign up record and classrooms{" "}
          </Alert>
          <Input
            mt={2}
            placeholder="Type email to confirm"
            type="text"
            onChange={(e) => setIsConfirmDelete(e.target.value === user?.email)}
          />
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined disabled={deleting} size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={!isDeleteConfirm || deleting}
            isLoading={deleting}
            size="lg"
            colorScheme="red"
            onClick={onDelete}
          >
            Yes, Delete
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
