import { FC, useEffect } from "react";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "Shared";
import { KidInvitesContent } from "./KidInvitesContent";

interface KidInvitesModalProps {
  entryId: string;
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
}

const viewSourceValuesMap: Record<string, string> = {
  "live-stream-details": "livestreamDetails",
  "path-card": "pathCard",
  "path-details": "pathDetails",
  "profile-page": "profile",
  "project-card": "projectCard",
  "project-details": "projectDetails",
};

export const KidInvitesModal: FC<KidInvitesModalProps> = ({
  entryId,
  title = "Share",
  description = "Tract is better with friends!",
  isOpen,
  onClose,
}) => {
  const { track } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      track(ANALYTICS_EVENTS.SHARE_MODAL_VIEWED, {
        shareModalViewSource: viewSourceValuesMap[entryId],
      });
    }
  }, [isOpen, entryId, track]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <KidInvitesContent
            my={10}
            title={title}
            description={description}
            titleSize="2xl"
            descriptionSize="lg"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
