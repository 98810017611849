import { FC, useEffect, useState, PropsWithChildren } from "react";

import {
  HStack,
  IconButton,
  IconBell,
  Link,
  Button,
  useBreakpointValue,
} from "Shared";

import { CoinsPopover } from "Components/CoinsPopover";
import { CoinValue } from "Components/CoinValue";
import { CounterBadge } from "Components/CounterBadge";
import { UserRow, IconButtonHelp } from "Components/TopNav";

import { useSession } from "Services/session";
import { useNotificationsContext } from "Services/stream";

import { isParentUser } from "Types/User";
import { captureException } from "Services/errors";
import { useCoins } from "Services/hooks/useCoins";
import { get } from "Services/api";
import { NotificationsPopover } from "Components/NotificationsPopover";

export const TopNavActionsEnd: FC<
  PropsWithChildren<{
    isCollapsed?: boolean;
    hideUserRow?: boolean;
  }>
> = ({ children, isCollapsed, hideUserRow = false }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { currentUser, firebaseUser } = useSession();
  const { unseenCount } = useNotificationsContext();
  const { balance } = useCoins();
  const isParent = isParentUser(currentUser);
  const [hasFreeGift, setHasFreeGift] = useState(false);

  useEffect(() => {
    get<{ isEligible: boolean }>("/v2/coins/daily-gift", firebaseUser)
      .then((result) => {
        setHasFreeGift(result.data.isEligible);
      })
      .catch(captureException);
  }, [firebaseUser]);

  return (
    <HStack spacing={1} mx={4}>
      {children}
      {!isCollapsed && (
        <CoinsPopover
          coinBalance={balance}
          hasFreeCoins={hasFreeGift}
          userId={currentUser.uid}
          onRedeemSuccess={() => setHasFreeGift(false)}
        >
          <Button variant="ghost" borderRadius="full" px={3}>
            <CoinValue
              size="xl"
              fontSize="md"
              value={balance}
              hasFreeCoins={hasFreeGift}
            />
          </Button>
        </CoinsPopover>
      )}
      {!hideUserRow && <UserRow user={currentUser} />}
      {!isParent && (
        <CounterBadge count={unseenCount}>
          {isMobile ? (
            <IconButton
              as={Link}
              to="/notifications"
              variant="ghost"
              icon={<IconBell />}
              aria-label="Notifications"
            />
          ) : (
            <NotificationsPopover>
              <IconButton
                variant="ghost"
                icon={<IconBell />}
                aria-label="Notifications"
              />
            </NotificationsPopover>
          )}
        </CounterBadge>
      )}
      <IconButtonHelp />
    </HStack>
  );
};
