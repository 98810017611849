import React, {
  useState,
  useEffect,
  useRef,
  DetailedHTMLProps,
  IframeHTMLAttributes,
} from "react";
import uid from "uid";
import { v1 as uuidv1 } from "uuid";
import { PlayerSdk } from "@api.video/player-sdk";

import {
  AspectRatio,
  Box,
  Center,
  IconVideo,
  Spinner,
  Text,
  VideoPlayer,
  VStack,
} from "Shared";

import { useSession } from "Services/session";
import { get } from "Services/api";
import { captureException } from "Services/errors";
import { BoxProps } from "@chakra-ui/layout";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  videoId?: string;
  src?: string;
  title?: string;
  playerId?: string;
  hideTitle?: boolean;
  fallbackSrc?: string;
  checkPlayable?: boolean;
  borderRadius?: BoxProps["borderRadius"];
  autoplay?: boolean;
};

export const ApiVideoPlayer: React.FC<Props> = ({
  videoId,
  playerId,
  src,
  title,
  hideTitle = true,
  checkPlayable = false,
  fallbackSrc,
  borderRadius,
  autoplay = false,
}) => {
  const [videoSrc, setVideoSrc] = useState(src);
  const [loading, setVideoLoading] = useState(false);
  const [shouldFallback, setFallback] = useState(false);
  const [loadedVideoId, setLoadedVideoId] = useState<string>();
  const session = useSession({ bypassStrict: true });

  // Initial fragments needed to control API player via iframe postMessage
  const fragments = [
    `sdkPlayerId:${1}`,
    `sdkOrigin:${btoa(window.location.origin)}`,
    "api",
  ];

  if (hideTitle) {
    fragments.push("hide-title");
  }

  if (autoplay) {
    fragments.push("autoplay");
  }

  useEffect(() => {
    if (src) {
      setVideoSrc(src);
      return;
    }

    if (!videoId) {
      setFallback(true);
      return;
    }

    if (loading || videoId === loadedVideoId) {
      return;
    }

    setVideoLoading(true);
    setLoadedVideoId(videoId);

    get(`/v1/videos/${videoId}`, session?.firebaseUser)
      .then((result) => {
        setVideoSrc(result.data.video.assets.player);

        if (checkPlayable && !result.data.status.encoding?.playable) {
          setFallback(true);
        } else {
          setFallback(false);
        }
      })
      .catch((err) => {
        setFallback(true);
        captureException(err);
      })
      .finally(() => {
        setVideoLoading(false);
      });
  }, [
    src,
    videoId,
    session?.firebaseUser,
    loading,
    loadedVideoId,
    fallbackSrc,
    checkPlayable,
  ]);

  const iframeId = playerId || uid();

  if (shouldFallback && !fallbackSrc) {
    return (
      <AspectRatio ratio={16 / 9}>
        <Center
          borderRadius={borderRadius ? borderRadius : "none"}
          bg="gray.100"
        >
          <VStack>
            <IconVideo color="gray.300" w={10} h={10} />
            <Text color="gray.500" fontSize="xl">
              Video Not Found :(
            </Text>
          </VStack>
        </Center>
      </AspectRatio>
    );
  }

  if (shouldFallback && fallbackSrc) {
    return (
      <VideoPlayer
        url={fallbackSrc}
        borderRadius={borderRadius ? borderRadius : "none"}
        maxH="100%"
      />
    );
  }

  const myCallback = () => console.log("Video has ended");

  return (
    <Box
      borderRadius={borderRadius ? borderRadius : "none"}
      backgroundColor="black"
      maxH="100%"
      overflow="hidden"
    >
      <AspectRatio ratio={16 / 9}>
        {loading ? (
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.800"
              color="gray.300"
              size="xl"
            />
          </Center>
        ) : (
          <VideoIframe
            id={`api-video--${iframeId}`}
            src={`${videoSrc}#${fragments.join(";")}`}
            title={title}
            width="100%"
            height="100%"
            allowFullScreen
            onEnded={() => myCallback()}
            allow="autoplay"
          />
        )}
      </AspectRatio>
    </Box>
  );
};

const VideoIframe: React.FC<
  DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>
> = (props) => {
  const analytics = useAnalytics();
  const playerSdkRef = useRef<PlayerSdk | null>(null);
  const [sessionId] = useState(() => uuidv1());

  useEffect(() => {
    if (!playerSdkRef.current) {
      const trackEvent = async (eventName: string) => {
        const totalLength = (await playerSdkRef.current?.getDuration()) || 0;
        const currentTime = await playerSdkRef.current?.getCurrentTime();
        const volume = await playerSdkRef.current?.getVolume();
        const isMuted = await playerSdkRef.current?.getMuted();
        const playbackRate = await playerSdkRef.current?.getPlaybackRate();
        const isLooped = await playerSdkRef.current?.getLoop();

        analytics.track(eventName, {
          sessionId,
          title: props.title,
          totalLength,
          currentTime,
          volume,
          isMuted,
          playbackRate,
          isLooped,
        });
      };

      playerSdkRef.current = new PlayerSdk(`#${props.id}`);
      playerSdkRef.current.addEventListener("play", async () => {
        try {
          await trackEvent(ANALYTICS_EVENTS.VIDEO_PLAYBACK_STARTED);
        } catch (error) {
          // Ignore error
        }
      });

      playerSdkRef.current.addEventListener("pause", async () => {
        try {
          await trackEvent(ANALYTICS_EVENTS.VIDEO_PLAYBACK_PAUSED);
        } catch (error) {
          // Ignore error
        }
      });

      playerSdkRef.current.addEventListener("ended", async () => {
        try {
          await trackEvent(ANALYTICS_EVENTS.VIDEO_PLAYBACK_ENDED);
        } catch (error) {
          // Ignore error
        }
      });
    }
  }, [playerSdkRef, props, sessionId, analytics]);

  return <iframe title={props.title} {...props} />;
};
