import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ManageUser } from "Pages";
import { VerifyUser } from "Pages/VerifyUser";
import { Onboarding } from "Pages/Onboarding";

import { useSession } from "Services/session";

import { authedRoutes, RouteConfig, RouteGroup } from "./routes";
import { AuthRoute } from "./AuthRoute";

import { isTeacherUser } from "Types/User";

export const AuthRouter: React.FC = () => {
  const { firebaseUser, currentUser, onboarded } = useSession();
  const needsVerify = isTeacherUser(currentUser) && !firebaseUser.emailVerified;

  const renderChildRoutes = (routeGroup: RouteGroup, key: number) => {
    const Layout = routeGroup.layout;

    // If route group contains nested layouts, wrap them in another Switch and recurse
    if (routeGroup.isNestedLayout) {
      return (
        <Layout key={key}>
          <Switch>
            {routeGroup.children.map((child: any, i: number) =>
              renderChildRoutes(child as RouteGroup, i)
            )}
          </Switch>
        </Layout>
      );
    }

    // Otherwise we know that the children are RouteConfigs
    const children = routeGroup.children as RouteConfig[];

    // Get route paths as an array of strings
    const routes: string[] = children.map((child) => child.path as string);

    return (
      <Route path={routes} key={key}>
        <Layout>
          <Switch>
            {children.map((route, i: number) => (
              <AuthRoute {...route} key={i} />
            ))}
          </Switch>
        </Layout>
      </Route>
    );
  };

  return (
    <Switch>
      <Route path="/manage-user" component={ManageUser} />

      {!onboarded && (
        <Switch>
          <Route path="/onboarding" component={Onboarding} />
          <Redirect to="/onboarding" />
        </Switch>
      )}

      {needsVerify && (
        <Switch>
          <Route path="/verify" component={VerifyUser} />
          <Redirect to="/verify" />
        </Switch>
      )}

      {authedRoutes.map(renderChildRoutes)}

      <Redirect to="/" />
    </Switch>
  );
};
