import { FirestoreTimestamp } from "../Services/dateTime";
import { Accomplishment } from "./Accomplishment";
import { InterestArea } from "@tract/common/dist/types/models/Path";
import { ClubScope } from "@tract/common/dist/types/models/Organization";
import {
  UserDisplayNameFragment,
  UserFullNameFragment,
  UserTypeFragment,
} from "@tract/common/dist/graphql/index";
import { gql } from "@apollo/client";

export enum UserType {
  Kid = "kid",
  Parent = "parent",
}

export enum PlanType {
  Single = "single",
  Family = "family",
}

export enum PlanInterval {
  Month = "month",
  Year = "year",
}

export interface User {
  username: string;
  canAuthor: boolean;
  onboarded: boolean;
  avatar: string;
  userType: UserType;
  subscribed: boolean;
  subscriptionExempt?: boolean;
  disabled?: boolean;
  orgId?: string;
  org?: {
    clubScope?: ClubScope;
  };
  createdAt?: FirestoreTimestamp;
  updatedAt?: FirestoreTimestamp;
  // in Unix seconds
  lastSeen?: FirestoreTimestamp;
  // Is currently only used for querying,
  // Should not be used for checking permissions
  isAdmin?: boolean;
  isMod?: boolean;
}

export interface UserFragment {
  id: string;
  username?: string;
  avatar?: string;
}

export interface ParentUser extends User {
  firstName: string;
  lastName: string;
  kids: string[];
  planType: PlanType;
  planInterval: PlanInterval;

  // Hack to get teacher role extended as parent user
  isEducator?: boolean;
}

export interface KidUser extends User {
  firstName: string;
  lastName: string;
  birthday: {
    day: string;
    month: string;
    year: string;
  };
  email: string;
  totalXP: number;
  weeklyXP: {
    [weeklyDate: string]: number;
  };
  monthlyXP: {
    [monthlyDate: string]: number;
  };
  dailyXP: {
    [dailyDate: string]: number;
  };
  missionsCompleted: number;
  challengesCompleted: number;
  pathsCompleted: number;
  parent: {
    // We don't use parent.email on frontend
    // email: string;
    uid: string;
  };
  followersCount?: number;
  followingCount?: number;
  bio?: string;
  interestAreas?: InterestArea[];
  country?: string;
  coinBalance?: number;
  lastCoinRedemptionTime?: Date | FirestoreTimestamp;
  creatorLevel: CreatorLevel;
}

export enum CreatorLevel {
  Disabled = "DISABLED",
  Base = "BASE",
  Creator = "CREATOR",
  Partner = "PARTNER",
  Affiliate = "AFFILIATE",
}

export interface AuthorUser extends User {
  name: string;
  school: string;
  photo?: string;
  introVideoURL?: string;
  followersCount?: number;
  followingCount?: number;
  paths: string[];
  interests?: InterestArea[];
  accomplishments?: Accomplishment[];
  country?: string;
  coinBalance?: number;
  creatorLevel: CreatorLevel;
}

export interface TeacherUser extends User {
  displayName: string;
  lowerGradeLevel: string;
  upperGradeLevel: string;
  subjects: string[];
}

export const USER_DISPLAY_NAME_FRAGMENT = gql`
  fragment UserDisplayName on user {
    firstName
    lastName
    username
    isEducator
    userType
    teacher {
      id: userId
      displayName
    }
  }
`;

export const USER_FULL_NAME_FRAGMENT = gql`
  fragment UserFullName on user {
    firstName
    lastName
    username
    isEducator
    userType
  }
`;

export const USER_TYPE_FRAGMENT = gql`
  fragment UserType on user {
    userType
    creatorLevel
    isEducator
    orgId
  }
`;

export const PROFILE_BADGE_USER_TYPE_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}
  fragment ProfileBadgeUserType on user {
    isMod
    country
    ...UserType
  }
`;

export interface AnyUser extends AuthorUser, KidUser, ParentUser, TeacherUser {}

export function getFullName(user: UserFullNameFragment) {
  return user?.userType === UserType.Parent
    ? `${user?.firstName} ${user?.lastName || ""}`
    : user.username;
}

export function getDisplayName(user?: UserDisplayNameFragment) {
  if (user?.userType === UserType.Parent || user?.isEducator) {
    return (
      user?.teacher?.displayName || `${user?.firstName} ${user?.lastName || ""}`
    );
  }

  return user?.username;
}

export function isParentUser(user: UserTypeFragment) {
  return user.userType === UserType.Parent;
}

export function isKidUser(user: UserTypeFragment) {
  return user.userType === UserType.Kid;
}

export function isStudentUser(user: UserTypeFragment) {
  return isKidUser(user) && !!user.orgId;
}

const isAuthor = (user: UserTypeFragment) => {
  return !!user.creatorLevel;
};

export function isAuthorUser(user: UserTypeFragment) {
  return isAuthor(user) && user.creatorLevel !== CreatorLevel.Disabled;
}

export function isTeacherUser(user: UserTypeFragment) {
  return user.isEducator === true;
}

export function isBaseCreator(user: UserTypeFragment) {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Base;
}

export function isCreatorUser(user: UserTypeFragment) {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Creator;
}

export function isAffiliateUser(user: UserTypeFragment) {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Affiliate;
}

export function isPartnerUser(user: UserTypeFragment) {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Partner;
}
