import React, { FC } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { useSession } from "Services/session";
import { AdminPaths } from "./AdminPaths";
import { AdminCollections } from "./AdminCollections";
import { AdminPrizes } from "./AdminShop";
import { EditPrize, CreatePrize } from "./AdminShop/PrizeBuilder";
import { AdminSubmissions } from "./AdminSubmissions";

import { AdminSubmissionQueue } from "./AdminSubmissions/Queue";
import { AdminComments } from "./AdminComments";
import { AdminAbuseReports } from "./AdminAbuseReports";
import { AdminUsers } from "./AdminUsers";
import { CreateUser } from "./AdminUsers/CreateUser";
import { AdminOrganizations } from "./AdminOrganizations";
import { CreateOrganization } from "./AdminOrganizations/CreateOrganization";
import { AdminSubmissionsFeedback } from "./AdminSubmissionsFeedback";
import { AdminLivestreams } from "./AdminLivestreams";
import { LivestreamBuilder } from "./AdminLivestreams/LivestreamBuilder";

export const AdminDashboard: FC = () => {
  const { isAdmin } = useSession();
  if (!isAdmin) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route path="/admin/paths" component={AdminPaths} />
      <Route path="/admin/collections" component={AdminCollections} />
      <Route path="/admin/users" component={AdminUsers} />
      <Route path="/admin/create-user" component={CreateUser} />
      <Redirect path="/admin/shop" to="/admin/shop/items" exact />
      <Route path="/admin/shop/items" component={AdminPrizes} />
      <Route path="/admin/shop/redemptions" component={AdminPrizes} />
      <Route path="/admin/shop/item/create" component={CreatePrize} />
      <Route path="/admin/shop/item/:prizeId/edit" component={EditPrize} />
      <Route path="/admin/submissions" component={AdminSubmissions} />
      <Route
        path="/admin/submissions-feedback"
        component={AdminSubmissionsFeedback}
      />
      <Route path="/admin/comments" component={AdminComments} />
      <Route path="/admin/submissions-queue" component={AdminSubmissionQueue} />
      <Route path="/admin/comments" component={AdminComments} />
      <Route path="/admin/reports" component={AdminAbuseReports} />
      <Route path="/admin/organizations" component={AdminOrganizations} />
      <Route path="/admin/create-organization" component={CreateOrganization} />
      <Redirect
        path="/admin/livestreams"
        to="/admin/livestreams/upcoming"
        exact
      />
      <Route path="/admin/livestreams/create" component={LivestreamBuilder} />
      <Route path="/admin/livestreams/upcoming" component={AdminLivestreams} />
      <Route path="/admin/livestreams/past" component={AdminLivestreams} />
      <Route
        path="/admin/livestreams/:livestreamId"
        component={LivestreamBuilder}
      />
    </Switch>
  );
};
