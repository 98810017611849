import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  CreatePathMutationResult,
  CreatorPathsQueryResult,
  CreatorPathsQueryVariables,
  Order_By,
} from "@tract/common/dist/graphql";

import { CREATOR_PATHS_QUERY } from "Pages/Author/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";

export const insert_path_one: UpdateResolver = (result, args, cache, info) => {
  const path = (result as CreatePathMutationResult["data"])?.insert_path_one;
  cache.updateQuery<
    CreatorPathsQueryResult["data"],
    CreatorPathsQueryVariables
  >(
    {
      query: CREATOR_PATHS_QUERY,
      variables: {
        authorId: path?.authorId || "",
        status: PathStatus.Draft,
        orderBy: { createdAt: Order_By.Desc },
        limit: 20,
        offset: 0,
      },
    },
    (data) => {
      if (path) {
        data?.paths.unshift({
          ...path,
        });
      }
      return data;
    }
  );
};
