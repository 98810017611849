export const Radio = {
  baseStyle: {
    control: {
      border: "1px solid",
      borderColor: "gray.300",
      _checked: {
        _before: {
          w: 2,
          h: 2,
        },
      },
    },
    label: {
      fontSize: "md",
      marginInlineStart: 3,
    },
  },
  sizes: {
    lg: {
      control: {
        w: 6,
        h: 6,
      },
      label: {
        fontSize: "md",
      },
    },
  },
  defaultProps: {
    colorScheme: "brandFull",
    size: "lg",
  },
};
