import { UseToastOptions } from "@chakra-ui/toast";

export const captureException = (...args: Parameters<any>) => {
  if (process.env.NODE_ENV === "development") {
    console.error(args[0]);
  }
};

export const captureMessage = (...args: Parameters<typeof console.debug>) => {
  console.debug(...args);
};

export const ERROR_TAGS = {
  EDUCATOR_ONBOARDING: "Educator Onboarding",
  KID_ONBOARDING: "Kid Onboarding",
  CLASSROOMS: "Classrooms",
};

export const NETWORK_ERROR_TOAST_OPTIONS: UseToastOptions = {
  title: "A network error occurred",
  description: "Please check your internet and try again",
  status: "error",
};

export const FIREBASE_ERRORS = {
  NETWORK_ERROR: "auth/network-request-failed",
  EMAIL_EXISTS: "auth/email-already-exists",
};

export const API_ERRORS = {
  NETWORK_ERROR: "Network Error",
};
