import React, { useCallback, useEffect, useState } from "react";
import { fabric } from "fabric";
import { useQuery } from "urql";
import { VIDEO_BACKDROP_QUERY } from "./graphql";

import { GetVideoBackdropsQuery } from "@tract/common/dist/graphql";

import {
  FormLabel,
  Grid,
  HStack,
  Icon,
  IconMonitor,
  IconSlash,
  Switch,
  VStack,
} from "Shared";

import { ButtonOption } from ".";
import { useVideoEditorContext } from "..";
import { findCanvasObjectByName } from "../shared";

export const BackdropFx: React.FC = () => {
  const {
    backdrop,
    fabricCanvas,
    setBackdrop,
    stopDesktopStream,
    startDesktopStream,
  } = useVideoEditorContext();
  const [showCamera, setShowCamera] = useState(true);
  const isDesktopStreamActive = backdrop?.type === "desktop";

  const [{ data }] = useQuery<GetVideoBackdropsQuery, null>({
    requestPolicy: "cache-and-network",
    query: VIDEO_BACKDROP_QUERY,
  });

  useEffect(() => {
    if (!fabricCanvas) return;

    const obj = findCanvasObjectByName(fabricCanvas, "backdrop");

    if (obj?.id === "desktop") {
      setBackdrop({ type: "desktop" });
    } else if (obj?.id) {
      setBackdrop({ type: "image", url: obj.id });
    }
  }, [fabricCanvas, setBackdrop]);

  const backdrops = data?.api_get_video_backdrops
    .filter((file) => file.url.includes("_thumb."))
    .map((file) => {
      return {
        url: `${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${file.url.replace(
          "_thumb.",
          "."
        )}`,
        thumb: `${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${file.url}`,
      };
    });

  const cleanup = () => {
    if (!fabricCanvas) return;
    const backdrop = findCanvasObjectByName(fabricCanvas, "backdrop");
    if (backdrop) fabricCanvas.remove(backdrop);
  };

  const handleRemoveBackdrop = () => {
    if (isDesktopStreamActive) {
      handleToggleCamera();
    }

    cleanup();
    setBackdrop(undefined);
    stopDesktopStream();
  };

  const handleImageBackdrop = async (url: string) => {
    if (!fabricCanvas) return;

    if (isDesktopStreamActive) stopDesktopStream();

    fabric.Image.fromURL(
      url,
      (img) => {
        img.set({
          id: url,
          name: "backdrop",
          selectable: false,
        });

        handleRemoveBackdrop();

        fabricCanvas.add(img);
        img.sendToBack();

        setBackdrop({ type: "image", url });
      },
      { crossOrigin: "Anonymous" }
    );
  };

  const handleToggleCamera = useCallback(() => {
    if (!fabricCanvas) return;

    const webcam = findCanvasObjectByName(fabricCanvas, "webcam");

    if (webcam) {
      setShowCamera(!showCamera);
      webcam.set({ opacity: !showCamera ? 1 : 0 });
    }
  }, [fabricCanvas, showCamera]);

  useEffect(() => {
    if (!showCamera && !isDesktopStreamActive) {
      handleToggleCamera();
    }
  }, [showCamera, isDesktopStreamActive, handleToggleCamera]);

  return (
    <VStack spacing={9}>
      <Grid
        gridTemplateColumns="repeat(3, 1fr)"
        gridAutoRows="5rem"
        gap={3}
        w="full"
      >
        <ButtonOption
          disabled={!backdrop}
          icon={<IconSlash />}
          onClick={handleRemoveBackdrop}
        />
        <ButtonOption
          icon={<IconMonitor />}
          onClick={startDesktopStream}
          selected={backdrop?.type === "desktop"}
        />
        {backdrops?.map(({ url, thumb }) => (
          <ButtonOption
            key={url}
            selected={backdrop?.url === url}
            onClick={() => handleImageBackdrop(url)}
            icon={<Icon w={6} height={6} opacity={0} />}
            imgSrc={thumb}
          />
        ))}
      </Grid>
      {isDesktopStreamActive && (
        <HStack w="full" justifyContent="space-between">
          <FormLabel>Show Camera</FormLabel>
          <Switch
            size="lg"
            isChecked={showCamera}
            onChange={handleToggleCamera}
          />
        </HStack>
      )}
    </VStack>
  );
};
