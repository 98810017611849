import { gql } from "@apollo/client";

import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

import { USER_TYPE_FRAGMENT } from "Types/User";

const LEARNER_ROW_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment LearnerRowUser on user {
    id: firestoreId
    avatar
    username
    firstName
    lastName
    email
    ...UserType
    ...UserPrivacyScopes
  }
`;

export const LEARNERS_BY_USER_IDS_QUERY = gql`
  ${LEARNER_ROW_FRAGMENT}

  query LearnersByUserIds($userIds: [String!]!) {
    users: user(
      where: { firestoreId: { _in: $userIds }, username: { _is_null: false } }
    ) {
      ...LearnerRowUser
    }
  }
`;

gql`
  query GetUserCoinBalances($userIds: [String!]!) {
    balances: coin_trx(
      distinct_on: [userId]
      where: { userId: { _in: $userIds } }
      order_by: [{ userId: asc }, { createdAt: desc }]
    ) {
      userId
      balance
    }
  }
`;

export const PROJECTS_COMPLETED_QUERY = gql`
  query UserProjectsCompleted($userIds: [String!]!) {
    user(where: { firestoreId: { _in: $userIds } }) {
      id: firestoreId
      projects_aggregate(
        where: {
          rejectedAt: { _eq: "-infinity" }
          published: { _eq: true }
          file: { transforms: {} }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
