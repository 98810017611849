import { gql } from "urql";

export const COMMENT_FRAGMENT = gql`
  fragment NodeCommentComment on comment {
    id
    text
    createdAt
    path
    deletedAt
    published
    user {
      id: firestoreId
      avatar
      username
      isEducator
      isMod
      creatorLevel
      firstName
      lastName
      canAuthor
      userType
      teacher {
        id: userId
        displayName
      }
    }
  }
`;

export const NODE_COMMENT_FRAGMENT = gql`
  ${COMMENT_FRAGMENT}

  fragment NodeComment on path_node_comment {
    pathNodeId
    commentId
    comment {
      ...NodeCommentComment
    }
  }
`;

export const GET_NODE_COMMENTS_QUERY = gql`
  ${NODE_COMMENT_FRAGMENT}

  query GetNodeComments(
    $nodeId: uuid!
    $limit: Int!
    $offset: Int!
    $path: ltree_comparison_exp!
  ) {
    nodeComments: path_node_comment(
      limit: $limit
      offset: $offset
      where: {
        pathNodeId: { _eq: $nodeId }
        comment: { path: $path, deletedAt: { _eq: "-infinity" } }
      }
      order_by: { comment: { createdAt: desc } }
    ) {
      ...NodeComment
    }
  }
`;

export const GET_NODE_COMMENT_REPLY_COUNT = gql`
  query GetNodeCommentReplyCount($nodeId: uuid!, $matches: lquery!) {
    path_node_comment_aggregate(
      where: {
        pathNodeId: { _eq: $nodeId }
        comment: {
          path: { _matches: $matches }
          deletedAt: { _eq: "-infinity" }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CREATE_NODE_COMMENT_MUTATION = gql`
  ${NODE_COMMENT_FRAGMENT}

  mutation CreateNodeComment($object: path_node_comment_insert_input!) {
    insert_path_node_comment_one(object: $object) {
      ...NodeComment
    }
  }
`;

export const EDIT_NODE_COMMENT_MUTATION = gql`
  ${COMMENT_FRAGMENT}

  mutation EditNodeComment($commentId: uuid!, $text: String!) {
    update_comment_by_pk(
      pk_columns: { id: $commentId }
      _set: { text: $text }
    ) {
      ...NodeCommentComment
    }
  }
`;

export const DELETE_NODE_COMMENT_MUTATION = gql`
  mutation DeleteNodeComment($commentId: uuid!) {
    update_comment_by_pk(
      pk_columns: { id: $commentId }
      _set: { deletedAt: "now()" }
    ) {
      id
      deletedAt
    }
  }
`;
