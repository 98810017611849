import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { LayoutMarketingVideo } from "Components/LayoutMarketingVideo";

import { EducatorOnboarding } from "./Educators";
import { KidsOnboarding } from "./Kids";

import { useSession } from "Services/session";

import { isKidUser, isTeacherUser } from "Types/User";
import { LayoutStudentOnboarding } from "Components/LayoutStudentOnboarding";

export const Onboarding: React.FC = () => {
  const { currentUser, onboarded } = useSession();

  return (
    <Switch>
      {onboarded && <Redirect to="/" />}

      <Route path="/onboarding/educator">
        <LayoutMarketingVideo>
          <EducatorOnboarding />
        </LayoutMarketingVideo>
      </Route>

      <Route path="/onboarding/kid">
        <LayoutStudentOnboarding>
          <KidsOnboarding />
        </LayoutStudentOnboarding>
      </Route>

      {isKidUser(currentUser) && <Redirect to="/onboarding/kid" />}
      {isTeacherUser(currentUser) && <Redirect to="/onboarding/educator" />}

      <Redirect to="/" />
    </Switch>
  );
};
