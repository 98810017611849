import React, { FC, useRef, RefObject, useState } from "react";
import { FormikProps } from "formik";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";

import {
  Box,
  Text,
  ButtonOutlined,
  ButtonGroup,
  ButtonSolid,
  useToast,
} from "Shared";

import { PageHeader } from "Components/PageHeader";
import { PageContent } from "Components/PageContent";
import { LayoutCentered } from "Components/LayoutCentered";

import { PrizeForm } from "./PrizeForm";

import { PrizeFormValues } from ".";

import {
  AdminShopItemQuery,
  AdminShopItemQueryVariables,
  CreateFileMutation,
  CreateFileMutationVariables,
  UpdateAdminShopItemMutation,
  UpdateAdminShopItemMutationVariables,
} from "@tract/common/dist/graphql";

import { ADMIN_SHOP_ITEM, UPDATE_ADMIN_SHOP_ITEM } from "../graphql";
import { CREATE_FILE_MUTATION } from "graphql/files";
import { useSession } from "Services/session";
import { post } from "Services/api";

type Props = {};

export const EditPrize: FC<Props> = () => {
  const { prizeId }: { prizeId: string } = useParams();
  const history = useHistory();
  const toast = useToast();
  const { firebaseUser } = useSession();
  const formikRef = useRef() as RefObject<FormikProps<PrizeFormValues>>;
  const [isLoading, setLoading] = useState(false);

  const [, updateMutation] = useMutation<
    UpdateAdminShopItemMutation,
    UpdateAdminShopItemMutationVariables
  >(UPDATE_ADMIN_SHOP_ITEM);

  const [, createFileMutation] = useMutation<
    CreateFileMutation,
    CreateFileMutationVariables
  >(CREATE_FILE_MUTATION);

  const [{ data, fetching }] = useQuery<
    AdminShopItemQuery,
    AdminShopItemQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: ADMIN_SHOP_ITEM,
    variables: {
      id: prizeId,
    },
  });

  const item = data?.item;

  async function handleSubmit(values: PrizeFormValues) {
    if (!item) return null;

    setLoading(true);

    try {
      if (values.newCoverFileId && values.cover) {
        const res = await createFileMutation({
          file: {
            filename: values.cover,
            id: values.newCoverFileId,
            userId: firebaseUser.uid,
          },
        });

        await post("/v2/transloadit", firebaseUser, {
          fileId: res?.data?.file?.id,
          templateName: "shop-item-covers",
        });
      }

      await updateMutation({
        id: item.id,
        object: {
          name: values.title,
          description: values.description || "",
          cost: values.cost,
          externalURL: values.detailsURL || "",
          coverFileId: values.newCoverFileId || item.coverFile?.id,
          tags: [values.category],
          disabled: values.disabled,
          quantity:
            typeof values.quantity === "number" ? values.quantity : null,
          metadata: {
            successText: values.successText,
            successActionURL: values.successActionURL,
            successActionText: values.successActionText,
            successActionRequired: values.successActionRequired,
          },
          ownerId: values.ownerId.length > 0 ? values.ownerId : null,
        },
      });

      toast({
        status: "success",
        title: "Shop Item Saved",
      });

      history.push(`/admin/shop`);
    } catch (e) {
      toast({
        status: "error",
        title: "Oops!",
        description: "There was an error saving the prize, please try again.",
        isClosable: true,
        duration: 9000,
      });
    } finally {
      setLoading(false);
    }
  }

  function submitForm() {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  }

  if (fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!item) {
    return <Redirect to="/" />;
  }

  return (
    <PageContent>
      <PageHeader
        position="sticky"
        top={0}
        bg="white"
        zIndex={1}
        renderTitle={
          <Text
            fontSize={["xl", null, "2xl"]}
            fontWeight="bold"
            color="gray.900"
            mr={10}
            noOfLines={1}
          >
            {item.name}
          </Text>
        }
        renderActions={
          <ButtonGroup>
            <ButtonOutlined onClick={history.goBack} disabled={isLoading}>
              Cancel
            </ButtonOutlined>
            <ButtonSolid onClick={submitForm} isLoading={isLoading}>
              Save
            </ButtonSolid>
          </ButtonGroup>
        }
      />
      <Box maxWidth="50rem" mx="auto">
        <PrizeForm
          item={item}
          owners={data?.owners}
          onSubmit={handleSubmit}
          ref={formikRef}
        />
      </Box>
    </PageContent>
  );
};
