import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  DeleteProjectCommentMutationResult,
  GetProjectDetailsQueryResult,
  GetProjectDetailsQueryVariables,
} from "@tract/common/dist/graphql";
import { GET_PROJECT_DETAILS } from "Pages/ProjectDetails/graphql";

export const update_comment_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const item = result as DeleteProjectCommentMutationResult["data"];
  //If deleting a project comment, decrement comment count in project stats
  if (
    !!item?.update_project_stats_by_pk?.projectId &&
    item?.update_comment_by_pk?.deletedAt !== "-infinity"
  ) {
    cache.updateQuery<
      GetProjectDetailsQueryResult["data"],
      GetProjectDetailsQueryVariables
    >(
      {
        query: GET_PROJECT_DETAILS,
        variables: {
          id: item?.update_project_stats_by_pk?.projectId,
        },
      },
      (data) => {
        if (!!data?.project?.stats?.commentCount) {
          data.project.stats.commentCount--;
        }
        return data;
      }
    );
  }
};
