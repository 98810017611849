import { Route, Switch } from "react-router-dom";
import { AdminContent } from "../AdminContent";
import { CreateOrganization } from "./CreateOrganization";
import { OrganizationDetails } from "./OrganizationDetails";
import { Organizations } from "./Organizations";

export const AdminOrganizations = () => {
  return (
    <AdminContent>
      <Switch>
        <Route
          path="/admin/organizations/:orgId/edit"
          component={CreateOrganization}
        />
        <Route
          path="/admin/organizations/:orgId"
          component={OrganizationDetails}
        />
        <Route path="/admin/organizations" component={Organizations} />
      </Switch>
    </AdminContent>
  );
};
