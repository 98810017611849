import { gql } from "urql";
import {
  PROJECT_CARD_FRAGMENT,
  PROJECT_STATS_FRAGMENT,
} from "Components/ProjectCard/graphql";
import { USER_TYPE_FRAGMENT } from "Types/User";

export const PROJECT_GALLERY_QUERY = gql`
  ${PROJECT_CARD_FRAGMENT}

  query ProjectGallery($where: project_bool_exp!, $limit: Int!, $offset: Int!) {
    projects: project(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { createdAt: desc }
    ) {
      ...projectCard
    }
  }
`;

export const PROJECT_DETAILS_PATH_FRAGMENT = gql`
  fragment projectPath on path {
    id
    slug
    title
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
    authorId
    user {
      id: firestoreId
      username
      avatar
      firstName
      lastName
    }
  }
`;

export const GET_PROJECT_PATH = gql`
  ${PROJECT_DETAILS_PATH_FRAGMENT}

  query GetProjectPath($id: bigint!) {
    path: path_by_pk(id: $id) {
      ...projectPath
    }
  }
`;

export const INC_PROJECT_VIEW_COUNT = gql`
  ${PROJECT_STATS_FRAGMENT}
  mutation IncProjectViewCount($projectId: uuid!) {
    update_project_stats_by_pk(
      pk_columns: { projectId: $projectId }
      _inc: { viewCount: 1 }
    ) {
      ...projectStats
    }
  }
`;

export const COMMENT_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}

  fragment comment on comment {
    id
    text
    giphyId
    createdAt
    deletedAt
    published
    userId
    user {
      id: firestoreId
      username
      avatar
      firstName
      lastName
      country
      isMod
      ...UserType
    }
  }
`;

export const PROJECT_COMMENT_FRAGMENT = gql`
  ${COMMENT_FRAGMENT}

  fragment projectComment on project_comment {
    projectId
    commentId
    awardId
    comment {
      ...comment
    }
  }
`;

export const PROJECT_COMMENTS_QUERY = gql`
  ${PROJECT_COMMENT_FRAGMENT}

  query ProjectComments($projectId: uuid!, $offset: Int!, $limit: Int!) {
    projectComments: project_comment(
      limit: $limit
      offset: $offset
      where: {
        projectId: { _eq: $projectId }
        comment: { deletedAt: { _eq: "-infinity" } }
      }
      order_by: { comment: { createdAt: desc } }
    ) {
      ...projectComment
    }
  }
`;

export const PROJECT_CREATE_COMMENT_MUTATION = gql`
  ${PROJECT_COMMENT_FRAGMENT}
  ${PROJECT_STATS_FRAGMENT}

  mutation CreateProjectComment(
    $projectId: uuid!
    $text: String
    $userId: String!
    $giphyId: String
  ) {
    projectComment: insert_project_comment_one(
      object: {
        comment: { data: { text: $text, userId: $userId, giphyId: $giphyId } }
        projectId: $projectId
      }
    ) {
      ...projectComment
    }

    update_project_stats_by_pk(
      pk_columns: { projectId: $projectId }
      _inc: { commentCount: 1 }
    ) {
      ...projectStats
    }
  }
`;

export const PROJECT_COMMENT_UPDATE = gql`
  mutation UpdateComment($commentId: uuid!, $text: String!) {
    update_comment_by_pk(
      pk_columns: { id: $commentId }
      _set: { text: $text }
    ) {
      id
      text
    }
  }
`;

export const PROJECT_COMMENT_DELETE = gql`
  ${PROJECT_STATS_FRAGMENT}
  mutation DeleteProjectComment($commentId: uuid!, $projectId: uuid!) {
    update_comment_by_pk(
      pk_columns: { id: $commentId }
      _set: { deletedAt: "now()", published: false }
    ) {
      id
      deletedAt
      published
    }

    update_project_stats_by_pk(
      pk_columns: { projectId: $projectId }
      _inc: { commentCount: -1 }
    ) {
      ...projectStats
    }
  }
`;

export const PATH_NAME_QUERY = gql`
  query PathName($pathId: bigint!) {
    path: path_by_pk(id: $pathId) {
      id
      title
    }
  }
`;

export const PATH_NODE_NAME_QUERY = gql`
  query PathNodeName($nodeId: uuid!) {
    node: path_node_by_pk(id: $nodeId) {
      id
      title
    }
  }
`;
