import React, { FC, useEffect, useState } from "react";

import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  EmptyState,
  Td,
  ButtonOutlined,
  Link,
  Flex,
  Button,
  AspectRatio,
  HStack,
  IconImage,
  VisuallyHidden,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

import { PathStatus } from "@tract/common/dist/types/models/Path";
import { gql } from "@apollo/client";
import {
  Order_By,
  PathsByStatusFragment,
  useCreatorPathsForAdminQuery,
} from "@tract/common/dist/graphql";
import { captureException } from "Services/errors";
import { UserFile } from "Utils/UserFile";
import { ProfileBadges } from "Components/ProfileBadges";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { useSession } from "Services/session";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { pluralize } from "Utils";
import { useLocation } from "react-router";
import { PATHS_BY_STATUS_FRAGMENT } from "Pages/Author/graphql";
import { PathCover } from "Components/PathCover";
import { getDisplayName } from "Types/User";

type Props = {
  pathStatus: PathStatus;
  creatorLevels: string | string[];
  learnersOnly?: boolean;
  thumbnailSize?: string;
  showHeaderAndBorders?: boolean;
};

const PATHS_PER_PAGE = 20;

gql`
  ${PATHS_BY_STATUS_FRAGMENT}
  query CreatorPathsForAdmin(
    $offset: Int!
    $status: String!
    $limit: Int!
    $orderBy: path_order_by!
    $creatorLevel: String_comparison_exp!
    $userId: String!
  ) {
    paths: path(
      offset: $offset
      limit: $limit
      where: {
        _schemaVersion: { _eq: 3 }
        status: { _eq: $status }
        user: { creatorLevel: $creatorLevel, firestoreId: { _neq: $userId } }
      }
      order_by: [$orderBy]
    ) {
      ...pathsByStatus
    }
  }
`;

export const PathsTable: FC<Props> = ({
  pathStatus,
  creatorLevels,
  thumbnailSize = "10rem",
  showHeaderAndBorders = true,
}) => {
  const { currentUser } = useSession();
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const { data, loading, fetchMore } = useCreatorPathsForAdminQuery({
    variables: {
      offset: 0,
      status: pathStatus,
      limit: PATHS_PER_PAGE,
      creatorLevel: { _in: creatorLevels as string[] },
      userId: currentUser.id,
      orderBy:
        pathStatus === PathStatus.Published
          ? { publishedAt: Order_By.Desc }
          : { createdAt: Order_By.Desc },
    },
  });

  const paths = data?.paths;

  useEffect(() => {
    if (data?.paths) {
      const moreToLoad = data.paths.length >= PATHS_PER_PAGE * currentPage;

      setHasNextPage(moreToLoad);
    }
  }, [data, currentPage, setHasNextPage]);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          offset: paths?.length,
        },
      });
      setCurrentPage(currentPage + 1);
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoadingMore(false);
    }
  };

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  if (!loading && !paths?.length) {
    return <EmptyState headline="No paths" mt={10} />;
  }

  return (
    <>
      <Table width="100%">
        {showHeaderAndBorders && (
          <Thead>
            <Tr>
              <Th>
                <VisuallyHidden>Thumbnail</VisuallyHidden>
              </Th>
              <Th>Title</Th>
              <Th>Created By</Th>
              <Th>
                <Box visibility="hidden">Actions</Box>
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {paths?.map((path) => {
            return (
              <PathRow
                key={path.id}
                path={path}
                pathStatus={pathStatus}
                thumbnailSize={thumbnailSize}
                showHeaderAndBorders={showHeaderAndBorders}
              />
            );
          })}
        </Tbody>
      </Table>
      {hasNextPage && (
        <Flex direction="column" alignItems="center" mt={8}>
          <Button
            size="lg"
            variant="outline"
            isLoading={loadingMore}
            onClick={loadMore}
          >
            Load More
          </Button>
        </Flex>
      )}
    </>
  );
};

export const PathRow: React.FC<{
  showPeerReviewLink?: boolean;
  path: PathsByStatusFragment;
  pathStatus: PathStatus;
  thumbnailSize: string;
  showHeaderAndBorders: boolean;
}> = ({
  path,
  pathStatus,
  thumbnailSize,
  showHeaderAndBorders,
  showPeerReviewLink = false,
}) => {
  const { pathname } = useLocation();
  const coverFile = new UserFile(path.coverFile);

  const viewLink =
    pathStatus === PathStatus.Published
      ? `/path/${path.slug}/`
      : `/path/${pathStatus === PathStatus.InReview ? "review" : "preview"}/${
          path?.id
        }`;
  const coAuthorsCount = path.authors_aggregate.aggregate?.count || 0;

  //State variables are for path builder back button
  const createPath = {
    pathname: `/create/path/${path?.id}`,
    state: {
      sourcePath: pathname,
      sourceLabel: "Back to Paths",
    },
  };

  return (
    <Tr>
      <Td py={4} pr={2} width={thumbnailSize}>
        <Link
          to={!showPeerReviewLink ? createPath : viewLink}
          target={!showPeerReviewLink ? undefined : "_blank"}
          tabIndex={-1}
        >
          <AspectRatio
            ratio={16 / 9}
            width={thumbnailSize}
            borderRadius="xl"
            overflow="hidden"
            bg="gray.100"
          >
            {!!coverFile.exists ? (
              <PathCover objectFit="cover" cover={path.coverFile} />
            ) : (
              <Box w={6}>
                <IconImage color="gray.400" />
              </Box>
            )}
          </AspectRatio>
        </Link>
      </Td>
      <Td py={4}>
        <Text
          as={Link}
          to={!showPeerReviewLink ? createPath : viewLink}
          fontSize="lg"
          fontWeight="bold"
          target={!showPeerReviewLink ? undefined : "_blank"}
        >
          {path.title}
        </Text>
        <Text fontSize="md" color="gray.600" mt={2}>
          Last updated{" "}
          {formatDistanceStrict(new Date(path.updatedAt), new Date())} ago
        </Text>
      </Td>
      <Td py={4}>
        <HStack>
          <UserAvatar
            userId={path.authorId}
            username={path?.user?.username || ""}
            name={path?.user && getDisplayName(path?.user)}
            isTeacher={path?.user?.isEducator}
            avatarURL={path?.user?.avatar || ""}
            size="sm"
          />
          <UsernameLink
            username={path.user?.username || ""}
            isTeacher={path?.user?.isEducator}
            userId={path.authorId}
          >
            {path?.user && getDisplayName(path?.user)}
          </UsernameLink>
          {!!path?.user && (
            <ProfileBadges
              iconSize={5}
              user={path.user}
              spacing={1}
              flagFont="md"
            />
          )}
          {coAuthorsCount > 0 && (
            <Text color="gray.600">
              and {pluralize(coAuthorsCount, "other")}
            </Text>
          )}
        </HStack>
      </Td>
      <Td py={4} pl={10} textAlign="right">
        {path?.nodes?.length && (
          <ButtonOutlined size="lg" as={Link} to={viewLink} target="_blank">
            {pathStatus === PathStatus.InReview ? "Review" : "View"}
          </ButtonOutlined>
        )}
      </Td>
    </Tr>
  );
};
