import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box } from "Shared";
import { BoxProps } from "@chakra-ui/react";

const StyledAspectRatioBox = styled(AspectRatio)`
  video:focus {
    outline: 0;
  }
`;

interface Props extends BoxProps {
  url: string;
  autoplay?: boolean;
  borderRadius?: BoxProps["borderRadius"];
}

export const VideoPlayer: React.FC<PropsWithChildren<Props>> = ({
  url,
  autoplay,
  borderRadius = 16,
  ...props
}) => {
  return (
    <StyledAspectRatioBox ratio={1280 / 720}>
      <Box
        borderRadius={borderRadius}
        bg="gray.200"
        overflow="hidden"
        {...props}
      >
        <StyledAspectRatioBox ratio={1280 / 720} w="full">
          <video
            width="100%"
            height="100%"
            style={{ objectFit: "contain" }}
            src={url}
            autoPlay={autoplay}
            controls
          />
        </StyledAspectRatioBox>
      </Box>
    </StyledAspectRatioBox>
  );
};
