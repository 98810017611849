import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useDocument } from "Utils/hooks/firestore";
import { UserStats } from "Types/UserStats";
import { Progress, IconCheck, Link } from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";

interface CheckListItem {
  title: string;
  checked: boolean;
  emoji: string;
  to: string;
}

interface CheckListCardProp extends CheckListItem {
  index: number;
}

interface LearnerCheckListProp {}

const CheckListCard: React.FC<CheckListCardProp> = ({
  title,
  checked,
  emoji,
  index,
  to,
}) => {
  return (
    <Flex
      as={Link}
      direction="column"
      to={to}
      align="center"
      justify="center"
      flex={1}
      minHeight={{ md: 40, base: 32 }}
      minWidth={{ base: 40, md: 48, lg: "auto" }}
      backgroundColor="gray.50"
      borderRadius="16px"
      position="relative"
      transition="150ms ease-in-out background-color"
      _hover={{ textDecoration: "none", bgColor: "gray.100" }}
      _active={{ bgColor: "gray.200" }}
    >
      <Text as="span" fontSize="3xl" fontWeight="bold">
        {emoji}
      </Text>
      <Text
        maxWidth="70%"
        textAlign="center"
        fontSize={{ base: "sm", md: "md" }}
        fontWeight="bold"
        color={checked ? "gray.600" : "brand"}
        as="span"
        textDecoration={checked ? "line-through" : "none"}
      >
        {title}
      </Text>
      <Flex
        as="div"
        width={6}
        height={6}
        align="center"
        justify="center"
        borderRadius="8px"
        backgroundColor={checked ? "green.500" : "inherit"}
        borderColor="gray.200"
        borderWidth={checked ? 0 : 1}
        fontSize="xs"
        color="gray.900"
        fontWeight="bold"
        position="absolute"
        left={3}
        top={3}
      >
        {checked ? <IconCheck color="white" size="16px" /> : index}
      </Flex>
    </Flex>
  );
};

export const LearnerChecklist: React.FC<LearnerCheckListProp> = () => {
  const { currentUser } = useSession();
  const { data: userStats, loading } = useDocument<UserStats>(
    `user-stats/${currentUser.uid}`,
    {
      onError: (err) => captureException(err),
      listen: true,
    }
  );
  const classroomId = !!currentUser.learnerGroups.length
    ? currentUser.learnerGroups[0].learnerGroupId
    : "";

  if (loading) {
    return (
      <LayoutCentered
        mt={{ base: 8, lg: 10 }}
        isLoading={true}
        height="16rem"
      />
    );
  }

  function hasCompletedChecklist(userStats: UserStats) {
    return (
      !!userStats?.projectsLiked &&
      !!userStats?.awardsGiven &&
      !!userStats?.prizesRedeemed &&
      !!userStats?.profilesFollowed &&
      !!userStats?.challengesSubmitted &&
      !!userStats?.profileEdited
    );
  }

  if (userStats && hasCompletedChecklist(userStats)) {
    return null;
  }

  const learnerCheckList: CheckListItem[] = [
    {
      title: "Complete a Challenge",
      checked: !!userStats?.challengesSubmitted,
      emoji: "🚀",
      to: "/explore/originals",
    },
    {
      title: "Customize Your Profile",
      checked: !!userStats?.profileEdited,
      emoji: "💅",
      to: "/settings/profile",
    },
    {
      title: "Like a Project",
      checked: !!userStats?.projectsLiked,
      emoji: "❤️",
      to: !!classroomId.length ? `/class/${classroomId}/gallery/projects` : "/",
    },
    {
      title: "Give an Award",
      checked: !!userStats?.awardsGiven,
      emoji: "🏆",
      to: !!classroomId.length ? `/class/${classroomId}/gallery/projects` : "/",
    },
    {
      title: "Go Shopping",
      checked: !!userStats?.prizesRedeemed,
      emoji: "🎁",
      to: "/shop",
    },
  ];

  if (currentUser.orgId) {
    learnerCheckList.push({
      title: "Follow a Friend",
      checked: !!userStats?.profilesFollowed,
      emoji: "👀",
      to: !!classroomId.length ? `/class/${classroomId}/people` : "/",
    });
  } else {
    learnerCheckList.push({
      title: "Follow a Creator",
      checked: !!userStats?.profilesFollowed,
      emoji: "👀",
      to: "/explore/creators",
    });
  }

  const checkedCount = learnerCheckList.filter(
    (checklist) => checklist.checked
  ).length;
  const progress = (checkedCount / learnerCheckList.length) * 100;

  return (
    <Box mt={{ base: 8, lg: 10 }}>
      <Flex
        direction={{ base: "column", lg: "row" }}
        align={{ base: "start", lg: "center" }}
        justify={{ base: "start", lg: "space-between" }}
      >
        <Box>
          <Text fontSize="xl" color="gray.900" fontWeight="bold">
            👋 Welcome to Tract, {currentUser.username}
          </Text>
          <Text mt={1} fontSize="md" color="gray.900">
            It looks like you’re new here — complete the following steps to get
            started!
          </Text>
        </Box>
        <Box width={{ base: "100%", lg: "256px" }} mt={{ base: 4, lg: 0 }}>
          <Progress
            colorScheme="green"
            height={{ base: "10px", lg: 4 }}
            value={progress}
            flex={1}
          />
          <Text fontSize="sm" fontWeight="bold" mt={1} color="gray.600">
            {checkedCount} / {learnerCheckList.length} Completed
          </Text>
        </Box>
      </Flex>
      <Box
        as="div"
        overflowX={{ base: "scroll", lg: "visible" }}
        mx={{ base: -4, lg: 0 }}
        pb={{ base: 6, lg: 0 }}
        overflowY="visible"
        mt={6}
      >
        <Flex
          overflowX={{ base: "auto", lg: "visible" }}
          pb={{ base: 6, lg: 0 }}
        >
          <Flex
            direction="row"
            gridGap={{ base: 2, md: 3 }}
            justify="space-between"
            align="center"
            px={{ base: 4, lg: 0 }}
            width={{ lg: "100%" }}
          >
            {learnerCheckList.map(({ title, emoji, checked, to }, index) => (
              <CheckListCard
                key={`checklist-${index}`}
                index={index + 1}
                title={title}
                emoji={emoji}
                checked={checked}
                to={to}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
