import { FC, useEffect, useRef, useState } from "react";
import { useCombobox } from "downshift";
import { useDebouncedCallback } from "use-debounce";

import {
  Avatar,
  Box,
  ButtonOutlined,
  ButtonSolid,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  IconSearch,
  IconX,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  Portal,
} from "Shared";

import { useSession } from "Services/session";
import { index } from "Services/algolia";

import { PathCoAuthorFragment } from "@tract/common/dist/graphql";
import { usePathV4Context } from "./LayoutCreateV4";

export type SearchMemberResult = {
  objectID: string;
  username: string;
  avatar: string;
};

type SchoolMemberSearchInputProps = {
  coAuthors: PathCoAuthorFragment[] | undefined;
  onAddMember: (member: SearchMemberResult) => void;
  disabled: boolean;
};

const searchOrgKids = async (
  query: string,
  filters: { username?: string | null; orgId?: string | null }
) => {
  const searchResults = await index.users.search<SearchMemberResult>(query, {
    filters: filters.orgId
      ? `orgId:${filters.orgId} AND userType:kid`
      : undefined,
    facetFilters: filters.username
      ? `username:-${filters.username}`
      : undefined,
    hitsPerPage: 10,
    attributesToRetrieve: ["username", "avatar"],
  });
  return searchResults;
};

export const SchoolMemberSearchInput: FC<SchoolMemberSearchInputProps> = ({
  coAuthors,
  onAddMember,
  disabled,
}) => {
  const { path } = usePathV4Context();
  const { currentUser } = useSession();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [teammatesMenuWidth, setTeammatesMenuWidth] = useState<number>(0);
  const [teammatesMenuPosY, setTeammatesMenuPosY] = useState<number>(0);
  const [teammatesMenuPosX, setTeammatesMenuPosX] = useState<number>(0);
  const [results, setResults] = useState<SearchMemberResult[] | null>(null);
  const debouncedSearch = useDebouncedCallback(async (searchText: string) => {
    if (searchText.length === 0) {
      setResults(null);
      return;
    }

    setLoading(true);

    const searchResults = await searchOrgKids(searchText, {
      orgId: currentUser.orgId,
      username: path.user?.username,
    });

    setResults(searchResults.hits);
    setLoading(false);
  }, 500);

  // TODO: this is a workaround to avoid a race condition with the isOpen prop of useCombobox where the combobox closes on select before onSelectedItemChange can fire
  const [comboBoxOpen, setComboBoxOpen] = useState(false);
  const {
    inputValue,
    highlightedIndex,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items: results || [],
    itemToString: (item) => item?.username || "",
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue: "",
          };

        default:
          return changes;
      }
    },
    onInputValueChange: ({ inputValue }) => {
      debouncedSearch(inputValue?.trim() || "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onAddMember(selectedItem);
        setComboBoxOpen(false);
        setResults(null);
      }
    },
  });

  const onClearSearch = () => {
    setInputValue("");
    setResults(null);
    inputRef.current?.focus();
  };

  const rect = inputRef.current?.getBoundingClientRect();

  useEffect(() => {
    setTeammatesMenuPosY(rect?.top || 0);
    setTeammatesMenuPosX(rect?.left || 0);
    setTeammatesMenuWidth(rect?.width || 0);
  }, [rect?.top, rect?.left, rect?.width]);

  return (
    <>
      <FormControl>
        <FormLabel>Teammates</FormLabel>
        <InputGroup size="lg" {...getComboboxProps()}>
          <Input
            size="lg"
            autoComplete="off"
            placeholder="Search by username..."
            isDisabled={disabled || (coAuthors?.length || 0) >= 4}
            onFocus={() => setComboBoxOpen(true)}
            onBlur={() => setComboBoxOpen(false)}
            {...getInputProps({ ref: inputRef })}
          />
          <InputRightElement>
            {loading ? (
              <Spinner size="sm" color="brand" emptyColor="gray.200" />
            ) : inputValue.length > 0 ? (
              <IconButton
                aria-label="clear search"
                variant="ghost"
                icon={<IconX />}
                onClick={onClearSearch}
              />
            ) : (
              <IconSearch />
            )}
          </InputRightElement>
        </InputGroup>
        <FormHelperText>
          Add up to four teammates who worked on this path with you.
        </FormHelperText>
        {(coAuthors?.length || 0) >= 4 && (
          <FormHelperText>
            You've reached the maximum number of teammates
          </FormHelperText>
        )}
      </FormControl>
      <Box position="relative" {...getMenuProps()}>
        {comboBoxOpen && results !== null && (
          <Portal>
            <Box
              mt={2}
              width={teammatesMenuWidth}
              position="absolute"
              zIndex={9999}
              top={teammatesMenuPosY + 48}
              left={teammatesMenuPosX}
              bgColor="white"
              textAlign="left"
              fontWeight="medium"
              boxShadow="lg"
              maxH="225px"
              overflowY="auto"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="xl"
              py={3}
            >
              {results && results.length > 0 ? (
                results.map((item, index) => {
                  const isCoAuthor = !!coAuthors?.some(
                    (coAuthor) => coAuthor.userId === item.objectID
                  );
                  return (
                    <HStack
                      py={3}
                      px={4}
                      cursor={isCoAuthor ? "auto" : "pointer"}
                      userSelect="none"
                      key={item.username}
                      justifyContent="space-between"
                      bgColor={
                        !isCoAuthor && highlightedIndex === index
                          ? "gray.100"
                          : "white"
                      }
                      {...getItemProps({ item, index, disabled: isCoAuthor })}
                    >
                      <HStack>
                        <Avatar src={item.avatar || ""} />
                        <Text>{item.username}</Text>
                      </HStack>
                      {isCoAuthor ? (
                        <ButtonOutlined isDisabled>Added</ButtonOutlined>
                      ) : (
                        <ButtonSolid isDisabled={(coAuthors?.length || 0) >= 4}>
                          Add
                        </ButtonSolid>
                      )}
                    </HStack>
                  );
                })
              ) : (
                <Box py={3} px={4} textAlign="center">
                  No results found, make sure to search by username.
                </Box>
              )}
            </Box>
          </Portal>
        )}
      </Box>
    </>
  );
};
