import { gql } from "urql";

export const GUIDE_FRAGMENT = gql`
  fragment guide on guide {
    id
    bigIdea
    concepts
    contentStandards
    durationLower
    durationUpper
    learningObjectives
    resources
    description
    downloadUrl
    discussionQuestions
  }
`;

export const INSERT_GUIDE_MUTATION = gql`
  ${GUIDE_FRAGMENT}

  mutation InsertGuide(
    $pathId: bigint!
    $isRelatedPath: Boolean!
    $guide: guide_insert_input!
  ) {
    guidePath: insert_guide_path_one(
      object: {
        pathId: $pathId
        isRelatedPath: $isRelatedPath
        guide: { data: $guide }
      }
    ) {
      guideId
      pathId
      isRelatedPath
      guide {
        ...guide
      }
    }
  }
`;

export const UPDATE_GUIDE_BY_PK_MUTAION = gql`
  ${GUIDE_FRAGMENT}

  mutation UpdateGuideByPk($id: uuid!, $object: guide_set_input!) {
    guide: update_guide_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...guide
    }
  }
`;
