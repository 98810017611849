import { Redirect, useParams, useLocation } from "react-router";
import { usePathV4Context } from "./LayoutCreateV4";

export const RedirectToFirstNode = () => {
  const { pathId } = useParams<{ pathId: string }>();
  const { state, pathname } = useLocation<{ sourcePath?: string }>();
  const { path } = usePathV4Context();

  const nodePath = pathname.endsWith("/activity") ? "activity" : "details";

  const firstNodePath = {
    pathname: `/create/path/${pathId}/v/${path.nodes[0]?.id}/${nodePath}`,
    state: state,
  };

  if (path.nodes.length === 0) return null;

  return <Redirect to={firstNodePath} />;
};
