import { forwardRef } from "@chakra-ui/react";
import { format } from "date-fns";
// @ts-ignore
import Clamp from "react-multiline-clamp";

import { ForumPostFragment } from "@tract/common/dist/graphql";

import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconChat,
  IconHeart,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from "Shared";

type Props = {
  post: ForumPostFragment;
};

export const ForumPostCard = forwardRef<Props, "div">(
  ({ post, ...props }, ref) => {
    return (
      <LinkBox
        as="article"
        p={6}
        borderRadius="2xl"
        border="1px solid"
        borderColor="gray.200"
        {...props}
        ref={ref}
        _hover={{ bg: "gray.50" }}
        transition="150ms ease all"
      >
        <Flex align="flex-start" justify="space-between">
          <HStack spacing={2}>
            <Avatar
              name={post.user_name}
              src={post.user_avatar_url}
              size="sm"
            />
            <Box as="address" fontStyle="normal" lineHeight="1">
              <Text fontSize="sm" mb={0}>
                {post.user_name}
              </Text>
              <Text as="time" fontSize="xs" color="gray.600">
                {format(new Date(post.created_at), "MMM d")}
              </Text>
            </Box>
          </HStack>
          <Text as="span" fontSize="md" color="gray.600">
            Share
          </Text>
        </Flex>
        <Box pl={10} pt={2}>
          <Link
            as={LinkOverlay}
            href={post.url}
            target="_blank"
            fontSize="2xl"
            fontWeight="bold"
            _hover={{ textDecoration: "none" }}
          >
            {post.name}
          </Link>
          <Box mb={2}>
            <Clamp lines={2}>
              <Box dangerouslySetInnerHTML={{ __html: post.body.body }} />
            </Clamp>
          </Box>
          <Text fontSize="sm" color="gray.600">
            {post.space_name}
          </Text>
          <HStack spacing={6} mt={3}>
            <HStack spacing={2}>
              <IconHeart size={20} />
              <Text fontWeight="bold">{post.likes_count}</Text>
            </HStack>
            <HStack spacing={2}>
              <IconChat size={20} />
              <Text fontWeight="bold">{post.comments_count}</Text>
            </HStack>
          </HStack>
        </Box>
      </LinkBox>
    );
  }
);
