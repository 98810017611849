import { PathsByStatusFragment } from "@tract/common/dist/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import { PathCover } from "Components/PathCover";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { Link, useLocation } from "react-router-dom";
import { useSession } from "Services/session";
import {
  AspectRatio,
  ButtonGroup,
  ButtonLink,
  ButtonOutlined,
  HStack,
  IconImage,
  Td,
  Text,
  Box,
  Tr,
} from "Shared";
import { pluralize } from "Utils";
import { UserFile } from "Utils/UserFile";

export const AuthorPathRow: React.FC<{
  path: PathsByStatusFragment;
  pathStatus?: PathStatus;
}> = ({ path, pathStatus }) => {
  const thumbnailSize = "10rem";
  const { currentUser, isAdmin } = useSession();
  const hideCreateLink = !isAdmin && path.authorId !== currentUser.uid;
  const { pathname } = useLocation();

  //State variables are for path builder back button
  const createLink = {
    pathname: `/create/path/${path?.id}`,
    state: {
      sourcePath: pathname,
      sourceLabel: "Back to Create",
    },
  };
  const previewLink = `/path/preview/${path.id}`;
  const publishedLink = `/path/${path.slug}`;
  const coverFile = new UserFile(path.coverFile);

  return (
    <Tr>
      <Td py={4} pr={2} width={thumbnailSize}>
        <Link
          to={hideCreateLink ? previewLink : createLink}
          tabIndex={-1}
          target={hideCreateLink ? "_blank" : undefined}
        >
          <AspectRatio
            ratio={16 / 9}
            width={thumbnailSize}
            borderRadius={8}
            overflow="hidden"
            bg="gray.100"
          >
            {coverFile.exists ? (
              <PathCover objectFit="cover" cover={path.coverFile} />
            ) : (
              <Box w={6}>
                <IconImage color="gray.400" />
              </Box>
            )}
          </AspectRatio>
        </Link>
      </Td>
      <Td py={4}>
        <ButtonLink
          colorScheme="black"
          as={Link}
          target={hideCreateLink ? "_blank" : undefined}
          to={hideCreateLink ? previewLink : createLink}
          fontWeight="bold"
          fontSize="lg"
          mb={1}
        >
          {path.title}
        </ButtonLink>
        <HStack fontSize="md" color="gray.600" spacing={1}>
          {hideCreateLink && (
            <>
              <Text>View Only</Text>
              <Text>&middot;</Text>
            </>
          )}
          <Text color="gray.900">{pluralize(path.nodes?.length, "Video")}</Text>
          <Text>&middot;</Text>
          <Text>
            Last updated{" "}
            {formatDistanceStrict(new Date(path.updatedAt), new Date())} ago
          </Text>
        </HStack>
      </Td>
      <Td py={4} pl={10} textAlign="right">
        <ButtonGroup spacing={2}>
          {path?.nodes?.length > 0 &&
            pathStatus !== PathStatus.Archived &&
            (pathStatus === PathStatus.Published ? (
              <ButtonOutlined
                size="lg"
                as={Link}
                to={publishedLink}
                target="_blank"
              >
                View
              </ButtonOutlined>
            ) : (
              <>
                <ButtonOutlined
                  as={Link}
                  to={`/path/preview/${path.id}`}
                  target="_blank"
                  size="lg"
                >
                  Preview
                </ButtonOutlined>
                {!hideCreateLink && (
                  <ButtonOutlined as={Link} to={createLink} size="lg">
                    Edit
                  </ButtonOutlined>
                )}
              </>
            ))}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};
