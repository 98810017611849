import { gql } from "urql";

import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";
import { LIVESTREAM_FRAGMENT } from "Pages/LivestreamGallery/graphql";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

export const HOME_AWESOME_PROJECTS_QUERY = gql`
  ${PROJECT_CARD_FRAGMENT}

  query HomeAwesomeProjects(
    $limit: Int!
    $offset: Int!
    $where: project_bool_exp!
  ) {
    projects: project(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      ...projectCard
    }
  }
`;

export const HOME_LIVESTREAM_SECTION_QUERY = gql`
  ${LIVESTREAM_FRAGMENT}

  query HomeLivestreamSection($limit: Int!, $startDate: timestamptz!) {
    livestreams: livestream(
      limit: $limit
      offset: 0
      order_by: { startDate: asc }
      where: {
        _or: [
          { startDate: { _gte: $startDate } }
          { endDate: { _gte: $startDate } }
        ]
        disabled: { _eq: false }
      }
    ) {
      ...Livestream
    }
  }
`;

export const TEACHER_GETTING_STARTED_PATH_SECTION_QUERY = gql`
  ${PATH_CARD_FRAGMENT}

  query PathsByTagSection($limit: Int!, $tag: jsonb!) {
    paths: path(
      limit: $limit
      offset: 0
      order_by: { publishedAt: desc_nulls_last }
      where: { status: { _eq: "PUBLISHED" }, tags: { _contains: $tag } }
    ) {
      ...PathCard
    }
  }
`;

export const PATHS_BY_TAG_QUERY = gql`
  ${PATH_CARD_FRAGMENT}

  query PathsByTagSection($limit: Int!, $tag: jsonb!) {
    paths: path(
      limit: $limit
      offset: 0
      order_by: { publishedAt: desc_nulls_last }
      where: { status: { _eq: "PUBLISHED" }, tags: { _contains: $tag } }
    ) {
      ...PathCard
    }
  }
`;

export const UPDATE_LEARNER_GROUP_COLOR = gql`
  mutation UpdateLearnerGroupColor($learnerGroupId: uuid!, $color: String!) {
    update_learner_group_by_pk(
      pk_columns: { id: $learnerGroupId }
      _set: { color: $color }
    ) {
      id
      color
    }
  }
`;

export const ARCHIVE_CLASSROOM_MUTATION = gql`
  mutation ArchiveClassroom($learnerGroupId: uuid!, $archivedAt: timestamptz!) {
    update_learner_group_by_pk(
      pk_columns: { id: $learnerGroupId }
      _set: { archivedAt: $archivedAt }
    ) {
      id
      archivedAt
    }
  }
`;
