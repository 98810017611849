import { forwardRef } from "@chakra-ui/system";
import React, { FC, useEffect, useRef, useState } from "react";
import mime from "mime-types";

import {
  AspectRatio,
  Box,
  ButtonOutlined,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Text,
  FileUploader,
  FormHelperText,
  IconUpload,
  Progress,
  IconTrash,
  VideoPlayer,
} from "Shared";
import { UserFileResult, useUserFileUpload } from "Services/useUserFileUpload";
import { FormControlProps } from "@chakra-ui/form-control";
import { Field, FieldProps } from "formik";

type FormFileUploaderProps = {
  accept?: string;
  url?: string;
  maxFileSize: number;
  inputId: string;
  inputLabel: string;
  inputHelperText: string;
  onUpdate: (result: UserFileResult | null) => void;
  onValidateFile: (isValid: boolean) => void;
  disabled: boolean;
};

export const FormFileUploader: FC<FormFileUploaderProps | FormControlProps> =
  forwardRef(
    (
      {
        accept,
        fileId,
        filename,
        url,
        maxFileSize,
        inputId,
        inputLabel,
        inputHelperText,
        onUpdate,
        onValidateFile,
        disabled,
        ...props
      },
      ref
    ) => {
      const [invalidFile, setInvalidFile] = useState(false);
      const fileUploader = useRef(null);

      const {
        result,
        handleProgress,
        handleUploadError,
        handleUploadStart,
        handleUploadSuccess,
        isUploading,
        progress,
        reset,
        storageRef,
      } = useUserFileUpload();

      useEffect(() => {
        if (result) {
          onUpdate(result);
        }
      }, [result, onUpdate]);

      const onFileSelect = (event: Event) => {
        const fileInput = event.target as HTMLInputElement;
        if (fileInput.files && fileInput.files.length === 1) {
          const file = fileInput.files[0];
          if (
            file.size <= maxFileSize &&
            (file.type.startsWith("image/") || file.type.startsWith("video/"))
          ) {
            // @ts-ignore
            fileUploader.current.startUpload(file);
            setInvalidFile(false);
            onValidateFile(true);
          } else {
            setInvalidFile(true);
            onValidateFile(false);
          }
        }
      };

      const onDelete = () => {
        onUpdate(null);
        reset();
      };

      const urlPathname = url ? new URL(url).pathname : "";

      return (
        <Field name={inputId}>
          {({ form: { submitCount, setFieldValue }, meta }: FieldProps) => {
            const isInvalid = invalidFile || (submitCount > 0 && !!meta.error);
            return (
              <FormControl {...props} ref={ref}>
                <FormLabel mb={0} mr={0}>
                  {inputLabel}
                </FormLabel>
                <Box position="relative">
                  <AspectRatio
                    ratio={16 / 9}
                    border={url ? "none" : "2px dashed"}
                    borderColor={isInvalid ? "red.500" : "gray.300"}
                    borderRadius="12px"
                    bg="gray.50"
                    overflow="hidden"
                  >
                    <>
                      {url &&
                        mime
                          .lookup(urlPathname)
                          .toString()
                          .includes("image/") && (
                          <Image src={`${url}`} alt={inputLabel} />
                        )}
                      {url &&
                        mime
                          .lookup(urlPathname)
                          .toString()
                          .includes("video/") && <VideoPlayer url={`${url}`} />}
                      {isUploading && (
                        <Box borderRadius="12px" bg="gray.50" p={4}>
                          <Flex align="center" flex={1}>
                            <Progress
                              bg="gray.200"
                              height="16px"
                              value={progress}
                              colorScheme="green"
                              flex="1"
                            />
                            <Text
                              ml={4}
                              color="gray.600"
                              fontWeight="700"
                              fontSize="16px"
                            >
                              {progress}%
                            </Text>
                          </Flex>
                        </Box>
                      )}
                      {!isUploading && !url && !result?.filename && (
                        <Box>
                          <ButtonOutlined
                            as="label"
                            size="lg"
                            cursor="pointer"
                            htmlFor={inputId}
                            alignSelf="center"
                            leftIcon={<IconUpload />}
                            disabled={disabled}
                          >
                            Upload a File
                            <FileUploader
                              accept={accept}
                              ref={fileUploader}
                              onChange={onFileSelect}
                              hidden
                              id={inputId}
                              name={inputId}
                              disabled={disabled}
                              storageRef={storageRef}
                              onUploadStart={handleUploadStart}
                              onUploadError={handleUploadError}
                              onUploadSuccess={(filename: string) => {
                                setFieldValue(inputId, filename);
                                handleUploadSuccess(filename);
                              }}
                              onProgress={handleProgress}
                            />
                          </ButtonOutlined>
                        </Box>
                      )}
                    </>
                  </AspectRatio>
                  {url && (
                    <IconButton
                      aria-label={`Delete ${inputLabel}`}
                      position="absolute"
                      boxShadow="md"
                      borderRadius="full"
                      top={3}
                      right={3}
                      icon={<IconTrash />}
                      disabled={disabled}
                      onClick={() => {
                        setFieldValue(inputId, "");
                        onDelete();
                      }}
                    />
                  )}
                </Box>
                <FormHelperText
                  fontWeight={isInvalid ? "bold" : "normal"}
                  color={isInvalid ? "red.500" : "gray.600"}
                >
                  {inputHelperText}
                </FormHelperText>
              </FormControl>
            );
          }}
        </Field>
      );
    }
  );
