import { useEffect, useState, useCallback } from "react";
import { useSession } from "Services/session";
import { useStreamContext } from "Services/stream";
import {
  useBreakpointValue,
  Center,
  Text,
  Button,
  EmptyState,
  EmptyStateFontAwesomeIcon,
} from "Shared";

import { ViewSource } from "Types/Path";
import { FlatActivity } from "getstream";
import { GridPaths } from "Components/GridContainer";
import { captureException } from "Services/errors";
import { usePathsByIdQuery } from "@tract/common/dist/graphql";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { PathCard } from "Components/PathCard";
import { LayoutCentered } from "Components/LayoutCentered";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { InviterUser } from "Pages/ProjectGallery";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

export const UsersFollowingPathFeed: React.FC<{ viewSource: ViewSource }> = ({
  viewSource,
}) => {
  const { client } = useStreamContext();
  const { currentUser } = useSession();
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [loadedFeed, setLoadedFeed] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingFeed, setLoadingFeed] = useState(false);
  const [pathIds, setPathIds] = useState<number[]>([]);

  const pageSize = useBreakpointValue({
    base: 8,
    sm: 16,
    md: 24,
    xl: 32,
    "2xl": 40,
    "3xl": 72,
  });

  const fetchFeed = useCallback(
    () =>
      client
        .feed("user_path_timeline", currentUser.uid)
        .get({ limit: pageSize, offset: 0, enrich: false })
        .then((response) => {
          const pathIds = (
            response.results as FlatActivity<{ object: number }>[]
          ).map((a) => a.object);
          setPathIds(pathIds || []);
        })
        .finally(() => {
          setLoadedFeed(true);
          setLoadingFeed(false);
        }),
    [client, currentUser.uid, pageSize]
  );

  useEffect(() => {
    if (loadedFeed || loadingFeed || !pageSize) {
      return;
    }

    setLoadingFeed(true);

    fetchFeed();

    return () => {
      setLoadedFeed(false);
    };
  }, [fetchFeed, loadingFeed, loadedFeed, pageSize]);

  const { data, loading, fetchMore, error } = usePathsByIdQuery({
    skip: !pathIds.length,
    variables: {
      limit: pageSize || 24,
      offset: 0,
      pathIds: pathIds,
      status: PathStatus.Published,
    },
    onError: (err) => captureException(err),
  });

  const handleLoadMore = async () => {
    if (!paths || loadingMore) {
      return;
    }

    setLoadingMore(true);

    const limit = pageSize;
    const offset = paths.length;
    const pathIds: number[] = [];
    let shouldFetchMore = true;

    try {
      const feedResults = await client
        .feed("user_path_timeline", currentUser.uid)
        .get({ limit, offset });

      (feedResults.results as FlatActivity<{ object: number }>[]).forEach(
        (activity) => pathIds.push(activity.object)
      );
    } catch (err: any) {
      captureException(err);
    }

    if (!pathIds.length) {
      shouldFetchMore = false;
      setShowLoadMore(false);
    }

    let hasMoreData = true;
    if (shouldFetchMore) {
      try {
        const results = await fetchMore({
          variables: {
            limit,
            offset: 0,
            pathIds: pathIds,
            status: PathStatus.Published,
          },
        });

        if (!results.data.paths.length) {
          hasMoreData = false;
        }
      } catch (err: any) {
        captureException(err);
      }

      if (!hasMoreData) {
        setShowLoadMore(false);
      }
    }

    setLoadingMore(false);
  };

  const isAnyLoading = loadingFeed || loading || loadingMore;

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: showLoadMore,
    onLoadMore: handleLoadMore,
    loading: isAnyLoading,
  });

  const paths = data?.paths;

  useEffect(() => {
    if (Array.isArray(paths) && !!pageSize) {
      if (paths.length === 0) {
        setShowLoadMore(false);
      }

      setShowLoadMore(paths.length % pageSize === 0);
    }
  }, [paths, pageSize]);

  const skeletonCount = useBreakpointValue({
    base: 2,
    sm: 4,
    md: 6,
    xl: 8,
    "2xl": 10,
    "3xl": 12,
  });

  return (
    <>
      <GridPaths>
        {!!paths?.length &&
          paths.map((path) => (
            <PathCard key={path.id} path={path} source={viewSource} />
          ))}
      </GridPaths>

      {(loadingFeed || loading) && !!skeletonCount && (
        <GridPaths width="full" height="full">
          {Array.from({ length: skeletonCount }).map((_, i) => (
            <PathCardSkeleton key={i} />
          ))}
        </GridPaths>
      )}

      {!isAnyLoading && !paths?.length && !error && (
        <EmptyState
          headline="No paths yet"
          icon={
            <EmptyStateFontAwesomeIcon icon={duotone("wand-magic-sparkles")} />
          }
        >
          {currentUser.orgId ? (
            <Text>Follow classmates to view their paths here!</Text>
          ) : (
            <>
              <Text>Follow tract members to view their paths here!</Text>
              {!isAnyLoading && <InviterUser onFollow={fetchFeed} />}
            </>
          )}
        </EmptyState>
      )}
      {!!paths?.length &&
        !!pageSize &&
        paths.length >= pageSize &&
        showLoadMore && (
          <Center ref={sentryRef} mt={6}>
            {isAnyLoading ? (
              <LayoutCentered height="auto" isLoading={true} />
            ) : (
              <Button
                variant="ghost"
                size="lg"
                colorScheme="brandFull"
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            )}
          </Center>
        )}
    </>
  );
};
