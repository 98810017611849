import { useEffect } from "react";
import { gql, useQuery } from "urql";

import { SIGNUP_ERRORS } from "@tract/common/dist/constants/signup-errors";
import { Enums_Integration_Provider_Enum } from "@tract/common/dist/graphql";

import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VisuallyHidden,
} from "Shared";
import { PageContent } from "Components/PageContent";
import { CleverButton } from "Components/SSO/CleverButton";
import { GoogleButton } from "Components/SSO/GoogleButton";

import { useQuery as useQueryParams } from "Utils";
import { useSession } from "Services/session";
import { LayoutCentered } from "Components/LayoutCentered";

const GET_USER_INTEGRATIONS = gql`
  query GetUserIntegrations($userId: String!) {
    user_integration(where: { userId: { _eq: $userId } }) {
      provider
      createdAt
    }
  }
`;

export const Integrations = () => {
  const { currentUser } = useSession();
  const toast = useToast();
  const query = useQueryParams();
  const cleverErrorCode = query.get("errorCode");
  const [{ data, fetching: loading }, refetch] = useQuery({
    query: GET_USER_INTEGRATIONS,
    variables: { userId: currentUser.uid },
    requestPolicy: "network-only",
  });

  const links = data?.user_integration;
  const googleLink = links?.find(
    (l: any) => l.provider === Enums_Integration_Provider_Enum.Google
  );
  const cleverLink = links?.find(
    (l: any) => l.provider === Enums_Integration_Provider_Enum.Clever
  );

  const onGoogleFailure = (errCode: string) => {
    if (errCode === SIGNUP_ERRORS.LINKED_WITH_DIFFERENT_ACCOUNT) {
      toast({
        status: "error",
        title: "Your google account is already connected to a Tract account",
      });
    } else if (errCode === SIGNUP_ERRORS.INVALID_EMAIL_DOMAIN) {
      toast({
        status: "error",
        title: "Please use your school email to connect",
      });
    } else {
      toast({ status: "error", title: "Something went wrong :(" });
    }
  };

  useEffect(() => {
    if (cleverErrorCode === SIGNUP_ERRORS.LINKED_WITH_DIFFERENT_ACCOUNT) {
      toast({
        status: "error",
        title: "Your clever account is already connected to a Tract account",
      });
    } else if (cleverErrorCode === SIGNUP_ERRORS.INVALID_EMAIL_DOMAIN) {
      toast({
        status: "error",
        title: "Please use your school email to connect",
      });
    } else if (cleverErrorCode) {
      toast({ status: "error", title: "Something went wrong :(" });
    }
  }, [cleverErrorCode, toast]);

  return (
    <PageContent pt={{ base: 6, lg: 10 }} pb={24}>
      <Box maxWidth="45rem" mx="auto">
        <Box mb={10}>
          <Text fontSize="4xl" fontWeight="bold">
            Connected Accounts
          </Text>
          <Text color="gray.600">
            Sign in to Tract with your existing accounts for quick access
          </Text>
        </Box>
        {loading ? (
          <LayoutCentered h="40vh" isLoading />
        ) : (
          <Table style={{ tableLayout: "fixed" }}>
            <Thead>
              <Tr>
                <Th>Provider</Th>
                <Th>Connected on</Th>
                <Th>
                  <VisuallyHidden>Action</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td fontWeight="bold">Google</Td>
                <Td>
                  {googleLink
                    ? new Date(googleLink.createdAt).toDateString()
                    : "-"}
                </Td>
                <Td textAlign="right">
                  <GoogleButton
                    linkOnly
                    isDisabled={!!googleLink}
                    onSuccess={() => refetch()}
                    onFailure={onGoogleFailure}
                    w="unset"
                  >
                    {!!googleLink ? "Connected" : "Connect"}
                  </GoogleButton>
                </Td>
              </Tr>
              <Tr>
                <Td fontWeight="bold">Clever</Td>
                <Td>
                  {cleverLink
                    ? new Date(cleverLink.createdAt).toDateString()
                    : "-"}
                </Td>
                <Td textAlign="right">
                  <CleverButton
                    linkOnly
                    w="unset"
                    isDisabled={!!cleverLink}
                    onSuccess={() => refetch()}
                  >
                    {!!cleverLink ? "Connected" : "Connect"}
                  </CleverButton>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}
      </Box>
    </PageContent>
  );
};
