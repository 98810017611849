import { createIcon } from "@chakra-ui/react";

export const IconMicrosoft = createIcon({
  displayName: "IconMicrosoft",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path
      d="M1.04346 1.04346H11.4782V11.4782H1.04346V1.04346Z"
      fill="#F35325"
    />,
    <path
      d="M12.5217 1.04346H22.9565V11.4782H12.5217V1.04346Z"
      fill="#81BC06"
    />,
    <path
      d="M1.04346 12.5217H11.4782V22.9565H1.04346V12.5217Z"
      fill="#05A6F0"
    />,
    <path
      d="M12.5217 12.5217H22.9565V22.9565H12.5217V12.5217Z"
      fill="#FFBA08"
    />,
  ],
});
