import { useState } from "react";
import { differenceInSeconds, isAfter } from "date-fns";
import { FlexProps, forwardRef, useInterval } from "@chakra-ui/react";
import { useDocument } from "Utils/hooks/firestore";
import { WatcherCount } from "../../Components/Livestream/WatcherCount";
import firebase from "firebase/compat";

import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconCheck,
  IconBell,
  Link,
  Text,
  useToast,
  ButtonSolid,
  ButtonOutlined,
  IconShare,
  IconButtonOutlined,
  useBreakpointValue,
} from "Shared";

import { LivestreamAvatar } from "../../Components/Livestream/LivestreamAvatar";

import { ProfileBadges } from "Components/ProfileBadges";
import { FollowButton } from "Pages/Profile/FollowButton";

import { isParentUser, isTeacherUser } from "Types/User";

import { useSession } from "Services/session";
import { db } from "Services/firebase";
import { captureException } from "Services/errors";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import {
  LivestreamFragment,
  LivestreamHostUserFragment,
} from "@tract/common/dist/graphql";
import { KidInvitesShareCTA } from "Pages/KidInvites/KidInvitesShareCTA";

type StreamHeaderProps = {
  host: LivestreamHostUserFragment;
  livestream: LivestreamFragment;
  isLive?: boolean;
  startTime?: firebase.firestore.Timestamp;
  onToggleLive: () => Promise<void>;
} & FlexProps;

const NO_DURATION = "00:00:00";

export const StreamHeader = forwardRef<StreamHeaderProps, "div">(
  ({ host, livestream, isLive, startTime, onToggleLive, ...props }, ref) => {
    const { currentUser } = useSession();
    const toast = useToast();
    const { track } = useAnalytics();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const isMe = currentUser.username === host?.username;
    const amParentOrTeacher =
      isTeacherUser(currentUser) || isParentUser(currentUser);
    const [duration, setDuration] = useState<string>(NO_DURATION);

    // Set livestream duration timer value when stream is live
    useInterval(
      () => {
        if (!isLive || !startTime) {
          setDuration(NO_DURATION);
        } else {
          const getDiffInSeconds = () =>
            differenceInSeconds(new Date(), startTime.toDate());
          const getDiffTimestamp = () =>
            new Date(getDiffInSeconds() * 1000).toISOString().substring(11, 19);

          setDuration(getDiffTimestamp());
        }
      },
      isLive ? 1000 : null
    );

    const { data: reminder } = useDocument<{
      remind: boolean;
    }>(
      livestream
        ? `livestreams/${livestream.id}/reminders/${currentUser.uid}`
        : null,
      {
        listen: true,
        onError: (err) => captureException(err),
      }
    );

    const toggleReminder = () => {
      if (!reminder) return;
      const newVal = !reminder.remind;

      db.doc(`livestreams/${livestream.id}/reminders/${currentUser.uid}`).set({
        remind: newVal,
      });

      if (newVal === true) {
        toast({
          status: "success",
          title: "We'll send you a reminder when the livestream is starting",
        });

        track(ANALYTICS_EVENTS.LIVESTREAM_REMINDER_SET, {
          livestreamId: livestream.id,
          livestreamTitle: livestream.title,
          livestreamHostId: livestream.hostUserId,
          livestreamHostUsername: host?.username,
        });
      }
    };

    const isAfterStartDate = isAfter(
      new Date(),
      new Date(livestream.startDate)
    );

    const [togglingLive, setTogglingLive] = useState<boolean>(false);

    const handleToggleLive = async () => {
      setTogglingLive(true);
      setDuration(NO_DURATION);
      try {
        await onToggleLive();
      } finally {
        setTogglingLive(false);
      }
    };

    return (
      <Flex direction="row" justify="space-between" {...props} ref={ref}>
        <HStack
          spacing={{ base: 3, lg: 4 }}
          overflow="hidden"
          p={{ base: 4, lg: 6 }}
        >
          <LivestreamAvatar
            as={Link}
            to={`/@${host.username}`}
            src={host.avatar || ""}
            name={host.username || ""}
            isLive={isLive}
            tabIndex={-1}
            _focus={{ boxShadow: "none" }}
            size="lg"
          />
          <HStack spacing={2} overflow="hidden">
            <Text
              as={Link}
              to={`/@${host.username}`}
              fontSize="xl"
              noOfLines={1}
            >
              {host.username}
            </Text>
            <ProfileBadges user={host} iconSize={6} flagFont="xl" />
          </HStack>
        </HStack>
        <HStack spacing={4} p={{ base: 4, lg: 6 }}>
          {isLive && <WatcherCount livestreamId={livestream.id} />}
          {isLive && duration && (
            <Text
              fontFamily="mono"
              fontSize="sm"
              fontWeight="bold"
              color="gray.600"
              display={{ base: "none", lg: "flex" }}
            >
              {duration}
            </Text>
          )}
          {currentUser.uid === livestream.hostUserId ? (
            <ButtonSolid
              colorScheme={isLive ? "gray" : "brandFull"}
              onClick={handleToggleLive}
              disabled={togglingLive}
              size="lg"
            >
              {isLive ? "End Stream" : "Go Live"}
            </ButtonSolid>
          ) : (
            <ButtonGroup>
              {isLive || isAfterStartDate ? null : reminder &&
                reminder.remind ? (
                <Button
                  variant="outline"
                  size="lg"
                  leftIcon={<IconCheck />}
                  onClick={toggleReminder}
                  display={{ base: "none", md: "flex" }}
                >
                  Reminder Set
                </Button>
              ) : (
                <Button
                  variant="outline"
                  size="lg"
                  leftIcon={<IconBell />}
                  onClick={toggleReminder}
                  display={{ base: "none", md: "flex" }}
                >
                  Remind Me
                </Button>
              )}
              {host && !isMe && !amParentOrTeacher && (
                <FollowButton profile={host} />
              )}
              <KidInvitesShareCTA entryId="live-stream-details">
                {({ onClick }) =>
                  isMobile ? (
                    <IconButtonOutlined
                      size="lg"
                      onClick={onClick}
                      aria-label="share live stream"
                      icon={<IconShare />}
                    />
                  ) : (
                    <ButtonOutlined
                      size="lg"
                      leftIcon={<IconShare width="20px" />}
                      onClick={onClick}
                    >
                      Share
                    </ButtonOutlined>
                  )
                }
              </KidInvitesShareCTA>
            </ButtonGroup>
          )}
        </HStack>
      </Flex>
    );
  }
);
