import { Badge, forwardRef } from "@chakra-ui/react";

export { Badge } from "@chakra-ui/react";

export const BadgeOutlined = forwardRef((props, ref) => {
  return <Badge variant="outline" {...props} ref={ref} />;
});

export const BadgeSuccess = forwardRef((props, ref) => {
  return <Badge variant="solid" colorScheme="green" {...props} ref={ref} />;
});
