import { BadgeProps } from "@chakra-ui/layout";
import { useSession } from "Services/session";
import { gql } from "@apollo/client";

import { Badge, Tooltip } from "Shared";

import {
  AdminProjectFragment,
  useGetRejectUserByIdQuery,
} from "@tract/common/dist/graphql";

type Props = {
  submission: AdminProjectFragment;
};

gql`
  query GetRejectUserById($userId: String!) {
    user: user_by_pk(firestoreId: $userId) {
      firstName
      lastName
    }
  }
`;

export const SubmissionStatusBadge: React.FC<Props> = ({ submission }) => {
  const { currentUser, isAdmin } = useSession();
  const isRejected = submission.rejectedAt !== "-infinity";

  const { data } = useGetRejectUserByIdQuery({
    variables: {
      userId: submission.rejectedByUserId || "",
    },
    skip: !submission && !isRejected,
  });

  if (!submission) {
    return (
      <Badge variant="solid" colorScheme="orange">
        Unknown
      </Badge>
    );
  }

  const badgeProps: BadgeProps = {
    variant: isRejected ? "solid" : "outline",
    fontSize: "sm",
    colorScheme: isRejected ? "red" : "green",
  };

  const rejectedBy =
    submission.rejectedByUserId === currentUser.uid
      ? "you"
      : isAdmin
      ? `${data?.user?.firstName} ${data?.user?.lastName}`
      : "Tract";

  const rejectedStatusTooltipLabel = `Requested by ${rejectedBy} on ${submission.updatedAt.toLocaleString()}`;

  if (isRejected) {
    return (
      <Tooltip label={rejectedStatusTooltipLabel}>
        <Badge {...badgeProps}>Changes Requested</Badge>
      </Tooltip>
    );
  }

  return <Badge {...badgeProps}>Approved</Badge>;
};
