import { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import { Prompt } from "react-router-dom";

import {
  Button,
  Divider,
  FormControl,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast,
} from "Shared";

import { captureException } from "Services/errors";

import { CreatorLevel } from "Types/User";
import { PageHeader } from "Components/PageHeader";
import { useMutation } from "urql";
import {
  AdminDashboardUserAccountFragment,
  DisableUserMutation,
  DisableUserMutationVariables,
  UpdateUserCreatorLevelMutationResult,
  UpdateUserCreatorLevelMutationVariables,
} from "@tract/common/dist/graphql";
import {
  DISABLE_USER_MUTATION,
  UPDATE_USER_CREATOR_LEVEL_MUTATION,
} from "../graphql";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

type FormProps = {
  creatorLevel: CreatorLevel;
};

export const UserAccount: FC<Props> = ({ user }) => {
  const toast = useToast();
  const { trackForUser } = useAnalytics();
  const [isEnablingDisabling, setEnablingDisabling] = useState(false);

  const [, updateDisableUser] = useMutation<
    DisableUserMutation,
    DisableUserMutationVariables
  >(DISABLE_USER_MUTATION);

  const [, update] = useMutation<
    UpdateUserCreatorLevelMutationResult["data"],
    UpdateUserCreatorLevelMutationVariables
  >(UPDATE_USER_CREATOR_LEVEL_MUTATION);

  const disableUser = async (disabled: boolean) => {
    setEnablingDisabling(true);

    try {
      const { error } = await updateDisableUser({
        userId: user.id,
        disabled: disabled,
      });

      toast({
        title: `Successfully ${disabled ? "disabled" : "enabled"} user`,
        status: "success",
      });

      if (error) throw error;

      if (disabled) {
        await trackForUser(user.id, ANALYTICS_EVENTS.ACCOUNT_DISABLED, {});
      } else {
        await trackForUser(user.id, ANALYTICS_EVENTS.ACCOUNT_ENABLED, {});
      }
    } catch (err: any) {
      toast({
        title: `Error ${disabled ? "disabling" : "enabling"} user`,
        status: "error",
      });

      captureException(err);
    } finally {
      setEnablingDisabling(false);
    }
  };

  const save = async (values: FormProps) => {
    try {
      await update({
        userId: user.id,
        creatorLevel: values.creatorLevel,
      });

      toast({
        title: "Updated User",
        status: "success",
      });
    } catch (err: any) {
      toast({
        title: "Error updating user",
        status: "error",
      });

      captureException(err);
    }
  };

  const creatorLevelOptions = [
    {
      label: "None",
      description: "Cannot create learning paths",
      value: CreatorLevel.Disabled,
    },
    {
      label: "Base",
      description:
        "Can create learning paths and publish to organization, no status flair",
      value: CreatorLevel.Base,
    },
    {
      label: "Creator",
      description: "Can create learning paths and publish to organization",
      value: CreatorLevel.Creator,
    },
    {
      label: "Affiliate",
      description: "Can publish learning paths to the entire network",
      value: CreatorLevel.Affiliate,
    },
    {
      label: "Partner",
      description: "Can earn payments for publishing learning paths",
      value: CreatorLevel.Partner,
    },
  ];

  return (
    <>
      <PageHeader title="Account Settings" />

      <Text fontSize="lg" fontWeight="bold" mb={4}>
        Authentication
      </Text>

      <FormControl>
        <HStack>
          <Switch
            isDisabled={isEnablingDisabling}
            isChecked={user.disabled}
            size="lg"
            onChange={() => {
              disableUser(!user.disabled);
            }}
            mr={1}
          />
          <Text fontSize="lg">Disable Account</Text>
          {isEnablingDisabling && <Spinner size="sm" color="blue" />}
        </HStack>
      </FormControl>

      {!user.isEducator && (
        <>
          <Divider my={8} />

          <Form
            onSubmit={save}
            initialValues={{
              creatorLevel: user.creatorLevel || CreatorLevel.Disabled,
            }}
            subscription={{ pristine: true, submitting: true }}
          >
            {({ handleSubmit, pristine, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <Prompt
                  when={!pristine && !submitting}
                  message="You have unsaved changes, are you sure you want to leave?"
                />

                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Creator Status
                </Text>
                <Field name="creatorLevel" type="input">
                  {({ input }) => (
                    <RadioGroup {...input} mb={4}>
                      <Stack spacing={4}>
                        {creatorLevelOptions.map((option) => (
                          <Radio value={option.value} key={option.value}>
                            <Text fontWeight="bold">{option.label}</Text>
                            <Text>{option.description}</Text>
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                </Field>
                <HStack spacing={2}>
                  <Button
                    type="submit"
                    colorScheme="brandFull"
                    size="lg"
                    disabled={pristine}
                    isLoading={submitting}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    size="lg"
                    onClick={() => form.restart()}
                    disabled={pristine}
                  >
                    Reset
                  </Button>
                </HStack>
              </form>
            )}
          </Form>
        </>
      )}
    </>
  );
};
