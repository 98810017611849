import React, { FC, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";
import { useDebounce } from "use-debounce";
import { motion, useAnimation } from "framer-motion";
import { useQuery } from "urql";
import { forwardRef, MenuDivider } from "@chakra-ui/react";
import * as mime from "mime-types";
import minis from "./minis.svg";
import originals from "./originals.svg";

import {
  AspectRatio,
  Box,
  Grid,
  HStack,
  IconButton,
  IconFlag,
  IconMoreVertical,
  IconShare,
  // IconBookmark,
  IconCornerUpRight,
  IconPlusCircle,
  IconMinusCircle,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  TextClamp,
  usePrefersReducedMotion,
  useDisclosure,
  FontAwesomeIcon,
  Badge,
} from "Shared";

import { useInterestArea } from "Services/paths";
import { useFeature } from "Services/features";
import { useReportPath } from "Services/hooks/useReportPath";
import { useSession } from "Services/session";

import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";

import {
  PathCardDataQueryResult,
  PathCardDataQueryVariables,
  PathCardFragment,
} from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";
import { ProfilePopover } from "Components/ProfilePopover";
import { KidInvitesShareCTA } from "Pages/KidInvites/KidInvitesShareCTA";
import { PathCover } from "Components/PathCover";
import { SharePathModal } from "Components/SharePathModal";

import { isParentUser, isKidUser, isTeacherUser } from "Types/User";
import { ViewSource } from "Types/Path";
import { getDisplayName } from "Types/User";

import {
  // SaveModal,
  // userCanSavePaths,
  RemoveModal,
} from "Components/ClassroomSavedPaths";
import { CollectPathModal } from "Components/Collections";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AssignmentBuilderModal } from "Components/AssignmentBuilder/Modal";
import { PATH_CARD_DATA_QUERY } from "./graphql";

const MotionImage = motion(Image);

type Props = {
  path: PathCardFragment & { objectID?: string };
  /**
   * The name of where the path card was rendered e.g. top-picks, author-profile, explore
   */
  source: ViewSource;
  user?: PathCardFragment["user"];
  showAuthor?: boolean;
  showAuthorName?: boolean;
  showInterestArea?: boolean;
  classCollectionId?: string; // ID of the classroom collection that is rendering this path
  collectionId?: string; // ID of the non-class collection rendering this path
};

export const PathCard: FC<Props> = ({
  path,
  user,
  source,
  showAuthor = true,
  showAuthorName = true,
  showInterestArea = true,
  classCollectionId,
  collectionId,
  ...props
}) => {
  const session = useSession({ bypassStrict: true });
  const reportPath = useReportPath();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const socialMediaShareLinksEnabled = useFeature("social-media-share-links");
  const lmsHelpArticlesEnabled = useFeature("lms-help-articles");
  const showShareCTA =
    session &&
    isTeacherUser(session.currentUser) &&
    (socialMediaShareLinksEnabled || lmsHelpArticlesEnabled);

  const [showAnimatedCoverOnHover, setShowAnimatedCoverOnHover] =
    useState(false);

  const prefersReducedMotion = usePrefersReducedMotion();

  const { ref, inView } = useInView({
    // Only start observing an element if it's more than 50% visible.
    threshold: [0.5, 1],
    // This is a nice window I found which seems to work best so far.
    // On Unauthed pages we need to take into account the top navbar height,
    // and on other pages, the height of the bottom navbar.
    // Again, it's more of a guess than a solid number. This assumes that 40% of
    // the viewport (from the middle) is where we play the gif.
    rootMargin: "-30% 0% -30% 0%",
    initialInView: false,
    // Don't observe if
    //    it's not mobile or
    //    user prefers reduced motion
    skip: !isMobile || prefersReducedMotion,
  });

  const [{ data }] = useQuery<
    PathCardDataQueryResult["data"],
    PathCardDataQueryVariables
  >({
    query: PATH_CARD_DATA_QUERY,
    variables: {
      pathId: path.id,
    },
    pause: !!user || !showAuthor,
  });

  const pathUser = data?.path?.user;

  const [debouncedShowAnimatedCover] = useDebounce(
    isMobile ? inView : showAnimatedCoverOnHover,
    150
  );

  const interestArea = useInterestArea(path);

  const showAnimatedCover =
    (!!path.coverAnimatedFile && inView && debouncedShowAnimatedCover) ||
    (!!path.coverAnimatedFile &&
      !isMobile &&
      !prefersReducedMotion &&
      showAnimatedCoverOnHover &&
      debouncedShowAnimatedCover);

  const coverAnimatedOriginalMimeType = mime.lookup(
    path?.coverAnimatedFile?.filename || ""
  );
  const isImage =
    coverAnimatedOriginalMimeType &&
    coverAnimatedOriginalMimeType.includes("image/");

  const animatedFile = new UserFile(path?.coverAnimatedFile);

  const coverAnimatedUrl = isImage
    ? animatedFile.getOriginalUrl()
    : animatedFile.getTransformedUrl("cover_animated");

  const handleReportPath = () =>
    reportPath({
      pathId: path.id,
      pathTitle: path.title,
      pathSlug: path.slug,
      pathAuthorId: path.authorId,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
    });

  const toPath = {
    pathname: `/path/${path.slug}`,
    state: {
      source,
    },
  };
  const url = `${window.location.origin}${toPath.pathname}`;

  const {
    isOpen: isAssignModalOpen,
    onOpen: onOpenAssignModal,
    onClose: onCloseAssignModal,
  } = useDisclosure();
  // const {
  //   isOpen: isSaveModalOpen,
  //   onOpen: onOpenSaveModal,
  //   onClose: onCloseSaveModal,
  // } = useDisclosure();
  const {
    isOpen: collectModalIsOpen,
    onOpen: onCollectModalOpen,
    onClose: onCollectModalClose,
  } = useDisclosure();
  const {
    isOpen: removeModalIsOpen,
    onOpen: onRemoveModalOpen,
    onClose: onRemoveModalClose,
  } = useDisclosure();
  // const {
  //   isOpen: removeSavedModalIsOpen,
  //   onOpen: onRemoveSavedModaOpen,
  //   onClose: onRemoveSavedModalClose,
  // } = useDisclosure();

  // const saveIsEnabled = session && userCanSavePaths(session.currentUser);
  const menuOptions = {
    addToCollection: session && session?.isAdmin && !collectionId,
    assign: session && isTeacherUser(session.currentUser),
    removeFromCollection: session && session?.isAdmin && collectionId,
    // removeFromSaved: saveIsEnabled && classCollectionId,
    // saveToClass: saveIsEnabled && !classCollectionId,
    share: path.isVisibleUnauthed && showShareCTA,
  };

  return (
    <>
      <Box
        ref={ref}
        onMouseEnter={() => setShowAnimatedCoverOnHover(true)}
        onMouseLeave={() => setShowAnimatedCoverOnHover(false)}
        _focusWithin={{ "& .showFocused": { visibility: "visible" } }}
        role="group"
      >
        <Link
          display="block"
          position="relative"
          mb={4}
          to={toPath}
          tabIndex={-1}
          _focus={{ boxShadow: "none" }}
          role="group"
        >
          <AspectRatio
            ratio={16 / 9}
            borderRadius={16}
            overflow="hidden"
            position="relative"
            bg="gray.50"
          >
            <>
              <PathCover objectFit="cover" cover={path.coverFile} />
              <AnimatedCover
                src={coverAnimatedUrl}
                isVisible={showAnimatedCover}
              />
              <Box
                opacity="1"
                _groupHover={{ opacity: 0 }}
                transition="150ms ease opacity"
                h="full"
                w="full"
              >
                <LogoOverlay path={path} />
              </Box>
            </>
          </AspectRatio>
        </Link>
        <Grid
          templateColumns={showAuthor ? "auto 1fr auto" : "1fr auto"}
          gap={{ base: 2, md: 3 }}
          alignItems="flex-start"
        >
          <ProfilePopover
            userId={path.authorId}
            isAuthor
            triggerElement={
              <UserAvatar
                userId={pathUser?.id}
                username={pathUser?.username || ""}
                name={pathUser && getDisplayName(pathUser)}
                isTeacher={pathUser?.isEducator}
                avatarURL={pathUser?.avatar || ""}
                display={showAuthor ? "block" : "none"}
                size="sm"
              />
            }
          />
          <Box overflow="hidden">
            <TextClamp
              lines={2}
              mb={1}
              color="gray.900"
              fontWeight="bold"
              fontSize="md"
            >
              <Link to={toPath}>{path?.title}</Link>
            </TextClamp>
            <HStack spacing={1} wrap="wrap">
              {showAuthorName && (
                <>
                  <ProfilePopover
                    userId={path.authorId}
                    isAuthor
                    triggerElement={
                      <UsernameLink
                        userId={pathUser?.id}
                        isTeacher={pathUser?.isEducator}
                        username={pathUser?.username || ""}
                        color="gray.900"
                        fontSize="sm"
                        isAuthor
                      >
                        {pathUser && getDisplayName(pathUser)}
                      </UsernameLink>
                    }
                  />
                  <span>&middot;</span>
                </>
              )}
              {showInterestArea && interestArea && (
                <>
                  <Link
                    to={`/explore/interest/${interestArea.slug}`}
                    color="gray.600"
                    fontSize="sm"
                  >
                    {interestArea.label}
                  </Link>
                </>
              )}
            </HStack>
            {!!data?.path?.guides?.length &&
              session &&
              !isKidUser(session.currentUser) && (
                <Badge
                  colorScheme="gray"
                  color="black"
                  variant="outline"
                  mt={1}
                >
                  PROJECT GUIDE
                </Badge>
              )}
          </Box>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  className="showFocused"
                  as={IconButton}
                  aria-label="Path actions"
                  icon={<IconMoreVertical size={20} />}
                  variant="ghost"
                  size="sm"
                  mr={-2}
                  transition="150ms ease background-color, 150ms ease color"
                  visibility={isOpen ? "visible" : "hidden"}
                  _groupHover={{ visibility: "visible" }}
                />
                <MenuList width={64}>
                  {menuOptions.assign && (
                    <MenuItem
                      onClick={onOpenAssignModal}
                      icon={<FontAwesomeIcon icon={regular("inbox")} />}
                    >
                      Assign
                    </MenuItem>
                  )}
                  {/* {menuOptions.saveToClass && (
                    <MenuItem onClick={onOpenSaveModal} icon={<IconBookmark />}>
                      Save to Class
                    </MenuItem>
                  )} */}
                  {menuOptions.addToCollection && (
                    <MenuItem
                      icon={<IconPlusCircle />}
                      onClick={onCollectModalOpen}
                    >
                      Add to Collection
                    </MenuItem>
                  )}
                  {menuOptions.removeFromCollection && (
                    <MenuItem
                      icon={<IconMinusCircle />}
                      onClick={onRemoveModalOpen}
                    >
                      Remove From Collection
                    </MenuItem>
                  )}
                  {/* {menuOptions.removeFromSaved && (
                    <MenuItem
                      onClick={onRemoveSavedModaOpen}
                      icon={<IconMinusCircle />}
                    >
                      Remove from Saved
                    </MenuItem>
                  )} */}
                  {menuOptions.share && (
                    <MenuItem onClick={onOpen} icon={<IconCornerUpRight />}>
                      Share
                    </MenuItem>
                  )}
                  {Object.values(menuOptions).some(Boolean) && <MenuDivider />}
                  <MenuItem onClick={handleReportPath} icon={<IconFlag />}>
                    Report
                  </MenuItem>
                  <KidInvitesShareCTA entryId="path-card">
                    {({ onClick }) => (
                      <MenuItem onClick={onClick} icon={<IconShare />}>
                        Share
                      </MenuItem>
                    )}
                  </KidInvitesShareCTA>
                </MenuList>
              </>
            )}
          </Menu>
        </Grid>
      </Box>
      {session && isParentUser(session.currentUser) && isOpen && (
        <SharePathModal isOpen={isOpen} onClose={onClose} url={url} />
      )}
      {collectModalIsOpen && (
        <CollectPathModal
          isOpen={collectModalIsOpen}
          onClose={onCollectModalClose}
          path={path}
          currentUser={session.currentUser}
        />
      )}
      {removeModalIsOpen && (
        <RemoveModal
          isOpen={removeModalIsOpen}
          onClose={onRemoveModalClose}
          pathId={path.id}
          collectionId={collectionId || ""} // will exist if !!menuOptions.removeFromCollection
          title="Remove from collection?"
          description="Are you sure you want to remove this path from the collection?"
        />
      )}
      {/* {removeSavedModalIsOpen && (
        <RemoveModal
          isOpen={removeSavedModalIsOpen}
          onClose={onRemoveSavedModalClose}
          pathId={path.id}
          collectionId={classCollectionId || ""} // will exist if !!menuOptions.removeFromSaved
          title="Remove from Saved?"
          description="Students will no longer see this path."
        />
      )} 
     {isSaveModalOpen && (
        <SaveModal
          onClose={onCloseSaveModal}
          path={path}
          currentUser={session.currentUser}
        />
      )} */}
      {isAssignModalOpen && (
        <AssignmentBuilderModal
          pathId={path.id}
          isOpen={isAssignModalOpen}
          onClose={onCloseAssignModal}
        />
      )}
    </>
  );
};

const AnimatedCover: React.FC<{ src?: string; isVisible: boolean }> = ({
  src,
  isVisible,
}) => {
  const controls = useAnimation();
  const [didLoad, setLoaded] = useState(false);

  useEffect(() => {
    if (isVisible && didLoad) {
      controls.start(() => ({
        opacity: 1,
        transition: { duration: 0.3, delay: 0.2, ease: "easeIn" },
      }));
    }
  }, [didLoad, controls, isVisible]);

  if (isVisible) {
    return (
      <MotionImage
        src={src}
        opacity={0}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        objectFit="cover"
        animate={controls}
      />
    );
  }

  return null;
};

type LogoOverlayProps = {
  path: PathCardFragment & { objectID?: string };
};

const LogoOverlay = forwardRef<LogoOverlayProps, "div">(
  ({ path, ...props }, ref) => {
    const isMini = path?.tags?.indexOf("minis") > -1;
    const isOriginal = !!path?.isTractOriginal;

    if (!isMini && !isOriginal) {
      return null;
    }

    return (
      <Box
        bg="radial-gradient(at bottom right, rgba(0,0,0,.6) 0%, rgba(0,0,0,.3) 15%, rgba(0,0,0,0) 30%)"
        h="full"
        w="full"
        position="relative"
        p={4}
        ref={ref}
        {...props}
      >
        <HStack position="absolute" bottom={3} right={4} spacing={3}>
          {isMini && (
            <Box as="img" src={minis} alt="Tract Minis" height="1.5rem" />
          )}
          {isOriginal && (
            <Box
              as="img"
              src={originals}
              alt="Tract Originals"
              height="1.5rem"
            />
          )}
        </HStack>
      </Box>
    );
  }
);
