import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { Submissions } from "Pages/AdminDashboard/AdminSubmissions/Submissions";

export const LearnerSubmissions = () => {
  return (
    <PageApp>
      <PageHeader title="Responses" />
      <Submissions />
    </PageApp>
  );
};
