import React, { forwardRef, useMemo, useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  Box,
  IconCheckCircle,
  Input,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  useTheme,
} from "Shared";
import { Flex, theme } from "@chakra-ui/react";

// Helper that prevents duplicates in a collection
// This is used as a patch with Downshift bubbling listeners when incoming items change
const uniqueCollection = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

function defaultItemFilterFunc(items, inputValue) {
  return items.filter((item) =>
    item.label.toLowerCase().includes(inputValue.toLowerCase() || "")
  );
}

export const AutoComplete = forwardRef((props, ref) => {
  // Component props
  const {
    highlightItemBg = "gray.200",
    listItemRenderer,
    listProps,
    inputRegex,
    items = [],
    itemFilterFunc = defaultItemFilterFunc,
    itemLabelFormat,
    itemValueFormat,
    initialSelectedItems = [],
    onAddItem,
    onInputChange,
    onRemoveItem,
    onSelectedItemsChange,
    disabled,
    placeholder,
    selectedItemRenderer,
  } = props;

  // Shape of items array expected: [{ label: string; value: string }]
  if (items.length) {
    const sample = items[0];
    const valid = !!(sample.label && sample.value);

    if (!valid) {
      console.error(
        "items objects must contain the following keys: label, value"
      );
    }
  }

  // State
  const [inputValue, setInputValue] = useState("");
  const [inputItems, setInputItems] = useState(items);
  const [filteredItems, setFilteredItems] = useState([]);
  const [isNewItem, setIsNewItem] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { colors } = useTheme();

  // Downshift multiselect props
  let {
    getSelectedItemProps,
    getDropdownProps,
    selectedItems,
    setSelectedItems,
  } = useMultipleSelection({
    initialSelectedItems,
    onStateChange: ({ type }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
          setInputValue("");
          break;
        default:
          break;
      }
    },
  });

  // Hold all selected values
  const selectedItemVals = selectedOptions.map((item) => item.value);

  // Hold all input item values
  const itemsVals = useMemo(() => {
    const inputItemValues = inputItems.map((item) => item.value);
    const inputItemValuesLowerCase = inputItemValues.map((item) =>
      item.toLowerCase()
    );
    return [inputItemValues, inputItemValuesLowerCase];
  }, [inputItems]);

  const [inputItemVals, inputItemValsLowerCase] = itemsVals;

  // Downshift combobox props
  const {
    getItemProps,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    openMenu,
  } = useCombobox({
    inputValue,
    items: inputItems,
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          if (inputValue && inputRegex) {
            const re = new RegExp(inputRegex);

            if (re.test(inputValue)) {
              setInputValue(inputValue);
            }
          } else {
            setInputValue(inputValue || "");
          }

          if (onInputChange) {
            onInputChange(inputValue, selectedItem);
          }
          break;
        default:
          break;
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: false,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue,
            isOpen: true,
          };
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          return {
            ...changes,
            isOpen: !!inputValue,
          };
        case useCombobox.stateChangeTypes.FunctionSelectItem:
          return {
            ...changes,
            inputValue,
          };
        default:
          return changes;
      }
    },
    onSelectedItemChange({ selectedItem }) {
      toggleSelectItem(selectedItem);
    },
  });

  // List item renderer
  const renderListItem = (item) => {
    if (listItemRenderer) {
      return listItemRenderer(item);
    }
    return item.label;
  };

  // Selected item renderer
  const renderSelectedItem = (item, index) => {
    // TODO: add these props to Tag when chakra-ui is updated to v1+
    const itemProps = getSelectedItemProps({ item, index });

    if (selectedItemRenderer) {
      return (
        <Box {...itemProps} key={`selected-item${index}`}>
          {selectedItemRenderer(item, index)}
        </Box>
      );
    }

    return (
      <Tag
        borderRadius="full"
        key={`selected-item${index}`}
        size={"md"}
        variant="solid"
        minWidth="unset"
      >
        <TagLabel>{item.label}</TagLabel>
        <TagCloseButton onClick={() => removeItemHandler(item)} />
      </Tag>
    );
  };

  useDeepCompareEffect(() => {
    setInputItems(uniqueCollection([...items, ...selectedOptions], "value"));
    setIsNewItem(items.length === 0);
  }, [items]);

  useDeepCompareEffect(() => {
    const collection = uniqueCollection(selectedItems, "value");
    setSelectedOptions(collection);
    setInputItems(uniqueCollection([...items, ...collection], "value"));

    if (onSelectedItemsChange) {
      onSelectedItemsChange(collection);
    }
  }, [selectedItems]);

  useDeepCompareEffect(() => {
    const filtered = itemFilterFunc(inputItems, inputValue || "");
    setFilteredItems(filtered);
    setIsNewItem(!inputItemVals.includes(inputValue));
  }, [inputItemVals, inputItems, inputValue, itemFilterFunc]);

  // Toggle item handler
  const toggleSelectItem = (item) => {
    if (selectedItemVals.includes(item.value)) {
      removeItemHandler(item);
    } else {
      addItemHandler(item);
    }
  };

  // Remove item
  const removeItem = (item) => {
    const filtered = selectedItems.filter(
      (option) => option.value !== item.value
    );
    setSelectedItems(filtered);
  };

  const removeItemHandler = (item) => {
    if (disabled) return;
    removeItem(item);

    if (onRemoveItem) {
      onRemoveItem(item);
    }
  };

  // Add item
  const addItem = (item) => {
    setSelectedItems(uniqueCollection([...selectedOptions, item], "value"));
  };

  const addItemHandler = (item) => {
    const label = itemLabelFormat ? itemLabelFormat(item) : item.label;
    const value = itemValueFormat ? itemValueFormat(item) : item.value;

    const formattedItem = {
      ...item,
      label,
      value,
    };

    addItem(formattedItem);

    if (onAddItem) {
      onAddItem(formattedItem);
    }
  };

  const isActive = !!(isOpen && inputValue);

  // Check against lowercase vals to prevent adding duplicates
  const itemExists = inputItemValsLowerCase.includes(inputValue.toLowerCase());

  const showAdd = isActive && isNewItem && !itemExists;

  return (
    <Box position="relative">
      <Flex width="full">
        <Stack width="full" isInline {...getComboboxProps()} align="center">
          <Box width="full">
            <Input
              {...getInputProps(
                getDropdownProps({
                  preventKeyAction: isOpen,
                  onClick: isOpen ? () => {} : openMenu,
                  onFocus: isOpen ? () => {} : openMenu,
                })
              )}
              placeholder={placeholder}
              disabled={disabled}
            />
          </Box>
        </Stack>
      </Flex>

      <Box
        bg={colors.white}
        border={isActive && "1px solid rgba(0,0,0,0.1)"}
        borderRadius="5px"
        boxShadow={isActive && theme.shadows.sm}
        position="absolute"
        width="100%"
        zIndex={11}
        {...getMenuProps()}
      >
        <List maxH="190px" overflowY="auto" {...listProps}>
          {isActive &&
            filteredItems.map((item, index) => (
              <ListItem
                bg={highlightedIndex === index ? highlightItemBg : "inherit"}
                borderBottom={`1px solid ${colors.gray400}`}
                cursor="pointer"
                fontWeight="normal"
                key={`${item.value}${index}`}
                px={3}
                py={1}
                _hover={{ bg: "gray.200" }}
                {...getItemProps({ item, index })}
              >
                <Box alignItems="center" display="flex">
                  {selectedItemVals.includes(item.value) && (
                    <ListIcon
                      as={IconCheckCircle}
                      aria-label="Selected Item"
                      color={"green.500"}
                    />
                  )}
                  {renderListItem(item)}
                </Box>
              </ListItem>
            ))}
        </List>

        {showAdd && (
          <Box
            cursor="pointer"
            onClick={() =>
              addItemHandler({ label: inputValue, value: inputValue })
            }
            px={3}
            py={1}
            _hover={{ bg: "gray.200" }}
          >
            <Box as="span">Add </Box>
            <Box as="span" fontWeight="bold">
              {inputValue}
            </Box>
          </Box>
        )}
      </Box>

      {/*
        Showing selected items is baked in so we can make use of useMultipleSelection's
        handlers and accessibility props.
      */}
      {selectedOptions.length > 0 && (
        <Stack overflowX="auto" isInline mt={3} spacing={4}>
          {selectedOptions.map((selectedItem, index) =>
            renderSelectedItem(selectedItem, index)
          )}
        </Stack>
      )}
    </Box>
  );
});
