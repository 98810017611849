import React, { useRef } from "react";

import {
  PathViewerNodeFragment,
  PathViewerPathFragment,
} from "@tract/common/dist/graphql";

import {
  Button,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  ModalCloseButton,
  IconForward,
} from "Shared";

import { Confetti } from "Components/Confetti";
import { CoinValue } from "Components/CoinValue";

import { usePathNav } from "./usePathNav";

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
  isOpen: boolean;
  onClose: () => void;
};

export const ChallengeCompleteModal: React.FC<Props> = ({
  path,
  node,
  isOpen,
  onClose,
}) => {
  const { hasNextNode, nextNodeURL } = usePathNav({ path });
  const coinAmount = (path?.xpMultiplier || 1) * node.rewardAmount;

  const initialFocusRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      isCentered
    >
      <ModalOverlay />
      <Confetti zIndex={1400} />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={10} textAlign="center">
          <Text
            fontSize={["2xl", null, "3xl"]}
            fontWeight="bold"
            color="gray.900"
            mb={2}
          >
            Challenge Completed!
          </Text>
          <Text fontSize="lg">
            You completed the challenge and earned {coinAmount.toLocaleString()}{" "}
            Coins. Nice work!
          </Text>
          <Flex align="center" justify="center" mt={8} mb={10}>
            <CoinValue
              value={node.rewardAmount}
              size="3xl"
              showPlus={true}
              multiplier={path?.xpMultiplier}
            />
          </Flex>
          <Flex direction="column" justify="center" width="100%">
            {hasNextNode && (
              <Button
                variant="solid"
                colorScheme="brandFull"
                as={Link}
                to={nextNodeURL}
                onClick={onClose}
                size="lg"
                rightIcon={<IconForward />}
                ref={initialFocusRef}
              >
                Next
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
