import { TransformOptions } from "react-markdown/lib/react-markdown";

import { Box, ExternalLink, Text } from "Shared";

import { Heading } from "./Heading";

export const defaultRenderers: TransformOptions["components"] = {
  blockquote: ({ children }) => {
    return (
      <Box
        as="blockquote"
        pl={6}
        pt={4}
        mb={4}
        borderLeft="4px solid"
        borderColor="gray.200"
        children={children}
      />
    );
  },
  hr: ({ children }) => <Box as="hr" mb={6} children={children} />,
  h1: ({ children }) => <Heading level={1} children={children} />,
  h2: ({ children }) => <Heading level={2} children={children} />,
  h3: ({ children }) => <Heading level={3} children={children} />,
  h4: ({ children }) => <Heading level={4} children={children} />,
  h5: ({ children }) => <Heading level={5} children={children} />,
  h6: ({ children }) => <Heading level={6} children={children} />,
  a: ({ href, children }) => {
    const isHeadingLink = href?.charAt(0) === "#";

    return (
      <ExternalLink
        target={isHeadingLink ? null : "_blank"}
        color="brand"
        href={href}
        children={children}
      />
    );
  },
  ol: ({ children }) => <Text as="ol" mb={4} pl={8} children={children} />,
  ul: ({ children }) => <Text as="ul" mb={4} pl={8} children={children} />,
  li: ({ children }) => <Text as="li" mb={2} children={children} />,
  p: ({ children }) => <Text pb={4} children={children} />,
};

export default defaultRenderers;
