import React, { FC } from "react";
import { ButtonProps } from "@chakra-ui/react";
import { gql } from "@apollo/client";

import { ButtonSolid, ButtonOutlined } from "Shared";

import { useFollows } from "Services/social";
import { useSession } from "Services/session";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

import { isParentUser, isTeacherUser, USER_TYPE_FRAGMENT } from "Types/User";
import { FollowButtonUserFragment } from "@tract/common/dist/graphql";

gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment FollowButtonUser on user {
    id: firestoreId
    ...UserType
    ...UserPrivacyScopes
  }
`;

type Props = {
  profile: FollowButtonUserFragment;
  onFollow?: () => void;
};

export const FollowButton: FC<Props & ButtonProps> = ({
  profile,
  onFollow = () => {},
  ...props
}) => {
  const { currentUser } = useSession();

  const { isFollowable, isFollowing, follow, unfollow, loading, isReady } =
    useFollows(profile);

  if (
    !isFollowable ||
    isTeacherUser(currentUser) ||
    isParentUser(currentUser) ||
    isTeacherUser(profile) ||
    isParentUser(profile) ||
    currentUser.id === profile.id
  ) {
    return null;
  }

  if (isFollowing) {
    return (
      <ButtonOutlined
        size="lg"
        onClick={() => unfollow()}
        isLoading={!isReady || loading}
        {...props}
      >
        Following
      </ButtonOutlined>
    );
  }

  return (
    <ButtonSolid
      size="lg"
      onClick={() => follow().then(onFollow)}
      isLoading={!isReady || loading}
      {...props}
    >
      Follow
    </ButtonSolid>
  );
};
