import { useDocument } from "Utils/hooks/firestore";
import { FC, ReactNode } from "react";
import { useFeature } from "Services/features";
import { Portal, useDisclosure } from "Shared";
import { KidInvitesModal } from "./KidInvitesModal";

interface KidInvitesShareCTAProps {
  entryId: string;
  modalContainerRef?: any;
  children: (props: any) => ReactNode;
}

export const KidInvitesShareCTA: FC<KidInvitesShareCTAProps> = ({
  entryId,
  modalContainerRef = null,
  children,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const areKidInvitesEnabled = useFeature("kid-invites");
  const { data } = useDocument<{ title: string; description: string }>(
    `kid-invites-share-modal-entries/${entryId}`
  );

  if (!areKidInvitesEnabled) return null;

  return (
    <>
      {children({ onClick: onOpen })}
      <Portal containerRef={modalContainerRef}>
        <KidInvitesModal
          entryId={entryId}
          title={data?.title}
          description={data?.description}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Portal>
    </>
  );
};
