import React, { useState } from "react";
import { useQuery } from "urql";
import {
  Box,
  Button,
  Center,
  Flex,
  Logo,
  Spinner,
  Text,
  useTheme,
  VStack,
} from "Shared";

import { useSession } from "Services/session";
import { useIntercom } from "Services/intercom";

import { StepSchool } from "./StepSchool";

import { GET_ONBOARDED_ORGS } from "../graphql";

import {
  GetOnboardedOrgsQuery,
  GetOnboardedOrgsQueryVariables,
} from "@tract/common/dist/graphql";

import { ANALYTICS_EVENTS, useTrackEventOnce } from "Services/analytics";
import { StepClass } from "./StepClass";

export enum EducatorOnboardingStep {
  School = "School",
  Class = "Class",
}

export const EducatorOnboarding: React.FC = () => {
  const { colors } = useTheme();
  const { currentUser } = useSession();
  const domain = currentUser.email?.split("@")?.[1] || "";
  const { show } = useIntercom();
  const [currentStep, setCurrentStep] = useState<EducatorOnboardingStep>(
    EducatorOnboardingStep.School
  );

  useTrackEventOnce(ANALYTICS_EVENTS.EDUCATOR_SIGN_UP_COMPLETED, {
    learnerGroupOwnerId: currentUser.id,
  });

  const [{ data, fetching, error }] = useQuery<
    GetOnboardedOrgsQuery,
    GetOnboardedOrgsQueryVariables
  >({
    pause: !domain,
    query: GET_ONBOARDED_ORGS,
  });

  const organizations = data?.organizations;

  if (error) {
    return (
      <Center h="full">
        <VStack>
          <Text fontSize="xl">
            We're having trouble loading the onboarding form,{" "}
            <Button
              as={Text}
              fontSize="xl"
              variant="link"
              colorScheme="brandFull"
              onClick={() => window.location.reload()}
              cursor="pointer"
            >
              try again?
            </Button>
          </Text>
          <Button
            onClick={show}
            variant="link"
            colorScheme="brandFull"
            as={Text}
            fontSize="lg"
            cursor="pointer"
          >
            Contact Support
          </Button>
        </VStack>
      </Center>
    );
  }

  if (fetching) {
    return (
      <Center width="full" height="full">
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex
      direction="column"
      maxW="400px"
      textAlign="center"
      alignItems="center"
      height="100%"
      mx="auto"
    >
      <Logo
        color={colors.brand}
        width={{ base: "4rem", lg: "6rem" }}
        height={{ base: "2.375rem", lg: "3.5625rem" }}
        mb={{ base: 6, lg: 10 }}
      />
      <Box w="full" mb={10}>
        {currentStep === EducatorOnboardingStep.School && (
          <StepSchool
            organizations={organizations}
            onContinue={() => setCurrentStep(EducatorOnboardingStep.Class)}
          />
        )}
        {currentStep === EducatorOnboardingStep.Class && <StepClass />}
      </Box>
    </Flex>
  );
};
