import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  InsertGuideMutationResult,
  GetPathWithNodesQuery,
  GetPathWithNodesQueryVariables,
} from "@tract/common/dist/graphql";

import { GET_PATH_WITH_NODES_QUERY } from "Pages/CreateV4/graphql";

export const insert_guide_path_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const typedResult = result as InsertGuideMutationResult["data"];

  if (!typedResult?.guidePath) return;

  const { guide } = typedResult.guidePath;
  const pathId = typedResult.guidePath.pathId;

  cache.updateQuery<GetPathWithNodesQuery, GetPathWithNodesQueryVariables>(
    {
      query: GET_PATH_WITH_NODES_QUERY,
      variables: { pathId },
    },
    (data) => {
      if (result && data) {
        data.path?.guides?.push({ guide: guide });
      }
      return data;
    }
  );
};
