import { FC, PropsWithChildren, useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "next-share";
import copy from "copy-to-clipboard";
import { useQuery } from "urql";
import {
  GetLmsOptionsQueryResult,
  GetLmsOptionsQueryVariables,
} from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Flex,
  Divider,
  ExternalLink,
  useToast,
  FontAwesomeIcon,
} from "Shared";
import { GET_LMS_OPTIONS_QUERY } from "./graphql";
import { useFeature } from "Services/features";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  url: string;
};

enum ShareType {
  Facebook = "facebook",
  Twitter = "twitter",
  LinkedIn = "linkedIn",
  CopyLink = "copyLink",
  LMS = "lms",
}

export const SharePathModal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  url,
}) => {
  const toast = useToast();
  const { track } = useAnalytics();
  const socialMediaShareLinksEnabled = useFeature("social-media-share-links");
  const lmsHelpArticlesEnabled = useFeature("lms-help-articles");

  const [didTrackEvent, setDidTrackEvent] = useState<boolean>(false);

  const [{ data, fetching: loading }] = useQuery<
    GetLmsOptionsQueryResult["data"],
    GetLmsOptionsQueryVariables
  >({
    query: GET_LMS_OPTIONS_QUERY,
  });
  const lmsOptions = data?.lms;

  const shareMessage = "Check out this learning path on Tract!";
  const hashtag = "#Tract";

  const copyLinkToClipboard = () => {
    copy(url);
    toast({
      status: "success",
      title: "Link Copied",
    });
    track(ANALYTICS_EVENTS.SHARE_MODAL_VIEWED, {
      shareType: ShareType.CopyLink,
    });
    onClose();
  };

  useEffect(() => {
    if (didTrackEvent) return;

    track(ANALYTICS_EVENTS.SHARE_MODAL_VIEWED);
    setDidTrackEvent(true);
  }, [track, didTrackEvent]);

  return (
    <Modal
      size="md"
      scrollBehavior="inside"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Share to</ModalHeader>
        <ModalBody pt={0} px={2} pb={6}>
          {loading && <LayoutCentered height="auto" isLoading />}

          {!loading && (
            <>
              <Flex direction="column">
                {socialMediaShareLinksEnabled && (
                  <>
                    <FacebookShareButton
                      url={url}
                      quote={shareMessage}
                      hashtag={hashtag}
                      onClick={() =>
                        track(ANALYTICS_EVENTS.SHARE_LINK_CLICKED, {
                          shareType: ShareType.Facebook,
                        })
                      }
                    >
                      <Button
                        as="div"
                        variant="ghost"
                        size="lg"
                        w="full"
                        h="3.5rem"
                        borderRadius="xl"
                        textAlign="start"
                      >
                        <FacebookIcon size={40} round={true} />
                        <Text ml={4} fontSize="lg" fontWeight="bold" flex={1}>
                          Facebook
                        </Text>
                      </Button>
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={url}
                      title={shareMessage}
                      onClick={() =>
                        track(ANALYTICS_EVENTS.SHARE_LINK_CLICKED, {
                          shareType: ShareType.Twitter,
                        })
                      }
                    >
                      <Button
                        as="div"
                        variant="ghost"
                        size="lg"
                        w="full"
                        h="3.5rem"
                        borderRadius="xl"
                        textAlign="start"
                      >
                        <TwitterIcon size={40} round={true} />
                        <Text ml={4} fontSize="lg" fontWeight="bold" flex={1}>
                          Twitter
                        </Text>
                      </Button>
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={url}
                      onClick={() =>
                        track(ANALYTICS_EVENTS.SHARE_LINK_CLICKED, {
                          shareType: ShareType.LinkedIn,
                        })
                      }
                    >
                      <Button
                        as="div"
                        variant="ghost"
                        size="lg"
                        w="full"
                        h="3.5rem"
                        borderRadius="xl"
                        textAlign="start"
                      >
                        <LinkedinIcon size={40} round={true} />
                        <Text ml={4} fontSize="lg" fontWeight="bold" flex={1}>
                          LinkedIn
                        </Text>
                      </Button>
                    </LinkedinShareButton>
                  </>
                )}
                <Button
                  variant="ghost"
                  size="lg"
                  w="full"
                  h="3.5rem"
                  borderRadius="xl"
                  textAlign="start"
                  onClick={copyLinkToClipboard}
                >
                  <Box color="gray.600">
                    <FontAwesomeIcon icon={regular("link")} size={10} />
                  </Box>
                  <Text ml={4} fontSize="lg" fontWeight="bold" flex={1}>
                    Copy Link
                  </Text>
                </Button>
              </Flex>
              <Box px={4}>
                <Divider mt={4} mb={6} />
              </Box>
              {lmsHelpArticlesEnabled && !!lmsOptions?.length && (
                <>
                  <Box px={4}>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color="gray.600"
                      mb={3}
                    >
                      LMS HOW-TOS
                    </Text>
                  </Box>
                  {lmsOptions?.map((lms, i) => (
                    <Button
                      variant="ghost"
                      size="lg"
                      w="full"
                      h="3.5rem"
                      borderRadius="xl"
                      as={ExternalLink}
                      href={lms.helpArticleLink}
                      key={i}
                      onClick={() => {
                        track(ANALYTICS_EVENTS.SHARE_LINK_CLICKED, {
                          shareType: ShareType.LMS,
                          shareLmsName: lms.name,
                        });
                      }}
                    >
                      {!!lms.imageFilePath ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${lms.imageFilePath}`}
                          width={40}
                          height={40}
                          alt=""
                        />
                      ) : (
                        <Box color="gray.600">
                          <FontAwesomeIcon
                            icon={regular("chalkboard-user")}
                            size={10}
                          />
                        </Box>
                      )}
                      <Flex fontSize="lg" fontWeight="bold" flex={1} ml={4}>
                        {lms.name}
                      </Flex>
                      <Box color="gray.600">
                        <FontAwesomeIcon
                          icon={regular("arrow-up-right-from-square")}
                        />
                      </Box>
                    </Button>
                  ))}
                </>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
