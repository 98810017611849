import { FC, useRef, useState } from "react";
import { useMutation, useQuery } from "urql";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  BadgeOutlined,
  BadgeSuccess,
  ButtonOutlined,
  ButtonSolid,
  EmptyState,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButtonOutlined,
  IconEdit,
  IconMoreVertical,
  IconTrash,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VisuallyHidden,
  VStack,
} from "Shared";
import { PageHeader } from "Components/PageHeader";
import { DeleteCodeConfirmModal } from "../TeacherClassrooms/ClassCodesModal";
import {
  AdminCreateKidInviteMutation,
  AdminCreateKidInviteMutationVariables,
  AdminDashboardUserAccountFragment,
  AdminEditKidInviteMutation,
  AdminEditKidInviteMutationVariables,
  GetUserInvitesQuery,
  GetUserInvitesQueryVariables,
} from "@tract/common/dist/graphql";
import { GET_KID_INVITES } from "Pages/KidInvites/graphql";
import { ADMIN_CREATE_KID_INVITE, ADMIN_EDIT_KID_INVITE } from "../graphql";
import { generateRandomCode } from "@tract/common/dist/utils/random-code";
import { CodeType } from "@tract/common/dist/types/models/EducatorCode";
import { captureException } from "Services/errors";
import { LayoutCentered } from "Components/LayoutCentered";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

type KidCodeType = GetUserInvitesQuery["educator_code"][0];

export const UserInvites: FC<Props> = ({ user }) => {
  const [selectedCode, setSelectedCode] = useState<KidCodeType | null>(null);
  const menuTriggerRef = useRef(null);

  const {
    isOpen: isDeleteConfirmModalOpen,
    onClose: closeDeleteConfirmModal,
    onOpen: openDeleteConfirmModal,
  } = useDisclosure();

  const {
    isOpen: isCreateKidCodeModal,
    onClose: closeCreateKidCodeModal,
    onOpen: openCreateKidCodeModal,
  } = useDisclosure();
  const [{ data, fetching }] = useQuery<
    GetUserInvitesQuery,
    GetUserInvitesQueryVariables
  >({
    query: GET_KID_INVITES,
    variables: { userId: user.id },
  });

  const codes = data?.educator_code;

  const onCreateCode = () => {
    setSelectedCode(null);
    openCreateKidCodeModal();
  };

  const onEditCode = (code: KidCodeType) => {
    setSelectedCode(code);
    openCreateKidCodeModal();
  };

  const onDeleteCode = (code: KidCodeType) => {
    setSelectedCode(code);
    openDeleteConfirmModal();
  };

  return (
    <>
      <PageHeader
        title="Invites"
        renderActions={
          <ButtonSolid size="lg" onClick={onCreateCode}>
            New Invite Code
          </ButtonSolid>
        }
      />
      {fetching && <LayoutCentered height="50vh" isLoading />}
      {!fetching && !codes?.length && (
        <EmptyState headline="No invite codes yet" />
      )}
      {codes && codes.length > 0 && (
        <Table width="100%">
          <Thead>
            <Tr>
              <Th>Code</Th>
              <Th>Status</Th>
              <Th>Cenerated at</Th>
              <Th>Expires at</Th>
              <Th>Seats Remaining</Th>
              <Th>
                <VisuallyHidden>Action</VisuallyHidden>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {codes?.map((kidCode) => (
              <Tr key={kidCode.code}>
                <Td>{kidCode.code}</Td>
                <Td>
                  {kidCode.available < 1 ||
                  new Date(kidCode.expiresAt).getTime() < Date.now() ? (
                    <BadgeOutlined colorScheme="red">expired</BadgeOutlined>
                  ) : (
                    <BadgeSuccess variant="outline">Active</BadgeSuccess>
                  )}
                </Td>
                <Td>{new Date(kidCode.createdAt).toLocaleString()}</Td>
                <Td>{new Date(kidCode.expiresAt).toLocaleString()}</Td>
                <Td>{kidCode.available}</Td>
                <Td>
                  <Menu>
                    <MenuButton
                      as={IconButtonOutlined}
                      ref={
                        selectedCode?.code === kidCode.code
                          ? menuTriggerRef
                          : null
                      }
                      aria-label="open menu"
                      icon={<IconMoreVertical width={6} height={6} />}
                      variant="outline"
                    />
                    <MenuList width="16rem">
                      <MenuItem
                        icon={<IconEdit />}
                        onClick={() => onEditCode(kidCode)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<IconTrash />}
                        onClick={() => onDeleteCode(kidCode)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <CreateKidCodeModal
        user={user}
        code={selectedCode}
        isOpen={isCreateKidCodeModal}
        onClose={closeCreateKidCodeModal}
      />
      {selectedCode && (
        <DeleteCodeConfirmModal
          code={selectedCode.code}
          isOpen={isDeleteConfirmModalOpen}
          menuTriggerRef={menuTriggerRef}
          onClose={closeDeleteConfirmModal}
        />
      )}
    </>
  );
};

interface CreateKidCodeModalProps {
  code: KidCodeType | null;
  user: AdminDashboardUserAccountFragment;
  isOpen: boolean;
  onClose: () => void;
}

const createKidInviteSchema = yup.object().shape({
  available: yup
    .number()
    .required("Please provide a number")
    .min(0, "Please provide a valid number"),
});

const CreateKidCodeModal: FC<CreateKidCodeModalProps> = ({
  code,
  user,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const { track } = useAnalytics();
  const [, createKidCode] = useMutation<
    AdminCreateKidInviteMutation,
    AdminCreateKidInviteMutationVariables
  >(ADMIN_CREATE_KID_INVITE);
  const [, editKidCode] = useMutation<
    AdminEditKidInviteMutation,
    AdminEditKidInviteMutationVariables
  >(ADMIN_EDIT_KID_INVITE);
  const { resetForm, ...formik } = useFormik({
    initialValues: {
      available: !!code ? code.available : 10,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: createKidInviteSchema,
    onSubmit: (values) => {
      if (code) {
        return onSave(values);
      }
      return onCreate(values);
    },
  });

  const onSave = async (values: { available: number }) => {
    if (!code) return;

    try {
      await editKidCode({
        code: code?.code,
        available: values.available,
      });
      toast({
        title: "Invite Updated",
        status: "success",
      });

      onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error updating invite",
        status: "error",
      });
    }
  };

  const onCreate = async (values: { available: number }) => {
    try {
      await createKidCode({
        code: {
          userId: user.id,
          type: CodeType.KidCode,
          available: values.available,
          code: generateRandomCode(6),
        },
      });
      toast({
        title: "Invite Created",
        status: "success",
      });

      track(ANALYTICS_EVENTS.INVITE_GENERATED, {
        inviterUserId: user.id,
        inviterUsername: user.username,
        inviteSeatsTotal: values.available,
      });

      onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error creating invite",
        status: "error",
      });
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {code ? `Edit Code (${code.code})` : "New Invite Code"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} spacing={6} alignItems="flex-start">
          <FormControl isInvalid={!!formik.errors.available} isRequired>
            <FormLabel>Number of invites</FormLabel>
            <Input
              type="number"
              name="available"
              size="lg"
              placeholder="Class Name"
              value={formik.values.available}
              onChange={(e) => {
                formik.setErrors({ ...formik.errors, available: "" });
                formik.handleChange(e);
              }}
              autoComplete="off"
            />
            <FormErrorMessage>{formik.errors.available}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            isLoading={formik.isSubmitting}
            size="lg"
            onClick={() => formik.handleSubmit()}
          >
            {code ? "Save" : "Create"}
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
