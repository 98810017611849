import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "Services/api";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { OrganizationTypes } from "@tract/common/dist/types/models/Organization";
import {
  PlaceResult,
  parseAddress,
} from "@tract/common/dist/utils/parse-google-address";
import { useDebouncedCallback } from "use-debounce";

export const useGooglePlaces = (
  searchText?: string | null,
  orgType?: OrganizationTypes
): [PlaceResult[] | null, boolean] => {
  const [results, setResults] = useState<PlaceResult[] | null>(null);
  const [loading, setLoading] = useState(false);
  const fetchPlaces = useCallback(async () => {
    if (!searchText || !orgType) return setResults(null);

    setLoading(true);
    try {
      const response = await get(
        `/v1/organizations/search?query=${searchText}&orgType=${orgType}`
      );

      setResults(response.data.results as PlaceResult[]);
    } catch (error: any) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, [searchText, orgType]);

  const fetchPlacesDebounced = useDebouncedCallback(fetchPlaces, 500);

  useEffect(() => {
    fetchPlacesDebounced();
  }, [fetchPlacesDebounced, searchText, orgType]);

  return [results, loading];
};

// Details include address_components of a place
export const useGooglePlaceDetails = (
  placeId: string | null
): [PlaceResult | null, boolean] => {
  const [result, setResult] = useState<PlaceResult | null>(null);
  const [loading, setLoading] = useState(false);
  const { firebaseUser } = useSession();

  const fetchPlaceDetails = useCallback(async () => {
    if (!placeId) return;

    setLoading(true);
    try {
      const response = await get(
        `/v1/organizations/place-details?placeId=${placeId}`,
        firebaseUser
      );

      setResult(response.data.result as PlaceResult);
    } catch (error: any) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, [placeId, firebaseUser]);

  useEffect(() => {
    fetchPlaceDetails();
  }, [placeId, fetchPlaceDetails]);

  return [result, loading];
};

export const useOrgAvailability = (placeId: string | null) => {
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState<boolean | null>(null);

  const fetchAvailability = useCallback(
    async function () {
      if (!placeId) return setIsAvailable(null);

      setLoading(true);

      try {
        const {
          data: { isAvailable },
        } = await get("/v1/organizations/is-available?placeId=" + placeId);

        setIsAvailable(isAvailable);
      } catch (error: any) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    },
    [placeId]
  );

  useEffect(() => {
    fetchAvailability();
  }, [fetchAvailability]);

  return [isAvailable, loading];
};

export const useAddress = (place: PlaceResult | null) => {
  const addressComponents = useMemo(() => parseAddress(place), [place]);
  return addressComponents;
};
