import { gql } from "urql";

export const LIVESTREAM_FRAGMENT = gql`
  fragment Livestream on livestream {
    id
    title
    description
    hostUserId
    hostUser {
      id: firestoreId
      username
      avatar
    }
    startDate
    endDate
    disabled
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
  }
`;

export const LIVESTREAM_GALLERY_QUERY = gql`
  ${LIVESTREAM_FRAGMENT}

  query LivestreamGallery(
    $limit: Int!
    $startDateStart: timestamptz!
    $startDateEnd: timestamptz!
  ) {
    livestreams: livestream(
      limit: $limit
      offset: 0
      order_by: { startDate: asc }
      where: {
        _or: [
          { startDate: { _gte: $startDateStart, _lte: $startDateEnd } }
          { endDate: { _gte: $startDateStart, _lte: $startDateEnd } }
        ]
        disabled: { _eq: false }
      }
    ) {
      ...Livestream
    }
  }
`;
