import { FC } from "react";
import styled from "@emotion/styled";
import { FlexProps, forwardRef } from "@chakra-ui/react";
import { useRouteMatch } from "react-router-dom";

import { Box, Button, Flex, HStack, Logo, Link, useTheme } from "Shared";

import { ContainerMarketing } from "./ContainerMarketing";
import { useFeature } from "Services/features";

const StyledNavbarLink = styled(Flex)`
  &:after {
    display: ${({ isActive }) => (isActive ? "block" : "none")};
    content: "";
    position: absolute;
    height: 0.25rem;
    width: 0.25rem;
    border-radius: 0.125rem;
    bottom: -0.5rem;
    left: 50%;
    margin-left: -0.125rem;
    background: ${({ theme }: { theme: TODO }) => theme.colors.brand};
  }
`;

export const NavbarLink = forwardRef(
  (
    { isActive, display, ...props }: { isActive?: boolean } & FlexProps,
    ref
  ) => {
    return (
      <StyledNavbarLink
        position="relative"
        align="center"
        isActive={isActive}
        display={display}
      >
        <Box
          ref={ref}
          display="block"
          fontFamily="body"
          fontWeight="bold"
          {...props}
          color={isActive ? "brand" : props.color || "gray.600"}
        />
      </StyledNavbarLink>
    );
  }
);

export const NavbarDrawerLink = forwardRef(({ ...props }, ref) => {
  return (
    <Flex
      ref={ref}
      direction="row"
      align="center"
      height={16}
      fontSize="xl"
      fontWeight="bold"
      borderBottom="1px solid"
      borderColor="gray.200"
      color="gray.900"
      {...props}
    />
  );
});

export const NavbarMarketing: FC<{ isFixed: boolean }> = ({ isFixed }) => {
  const isExplore = !!useRouteMatch("/explore");
  const isSignIn = !!useRouteMatch("/sign-in");
  const { colors } = useTheme();

  const isSignUpEnabled = useFeature("sign-up-enabled");
  const isSignInEnabled = useFeature("sign-in-enabled");

  const navLinkTextColor = "gray.900";

  return (
    <Box
      height={["80px", null, null, "96px"]}
      width="100%"
      position="sticky"
      top="0"
      bg="white"
      zIndex={20}
    >
      <ContainerMarketing height="100%">
        <Flex
          align="center"
          justify="center"
          height="100%"
          // maxW={isHome ? "50rem" : undefined}
          mx="auto"
        >
          <Logo as={Link} to="/" aria-label="Home" color={colors.brand} />
          <Flex flex={1} />
          <HStack spacing={6} as="nav" ml={10}>
            <NavbarLink
              as={Link}
              to="/explore"
              isActive={isExplore}
              color={navLinkTextColor}
            >
              Library
            </NavbarLink>
            {isSignInEnabled && (
              <NavbarLink
                as={Link}
                to="/sign-in"
                isActive={isSignIn}
                color={navLinkTextColor}
              >
                Sign In
              </NavbarLink>
            )}
            {isSignUpEnabled && (
              <Button
                size="lg"
                as={Link}
                to="/get-started"
                colorScheme="brandFull"
              >
                Get Started
              </Button>
            )}
          </HStack>
        </Flex>
      </ContainerMarketing>
    </Box>
  );
};
