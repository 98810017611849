import React, { FC, PropsWithChildren } from "react";
import { useRouteMatch } from "react-router-dom";

import { Box, Flex, Link, VStack } from "Shared";

import { useSession } from "Services/session";

import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";
import { SideNavTitle } from "Components/SideNav/SideNavTitle";

import { isKidUser } from "Types/User";

type Props = {};

enum Routes {
  Profile = "/settings/profile",
  Privacy = "/settings/privacy",
  Integrations = "/settings/integrations",
}

export const LayoutSettings: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const { currentUser } = useSession();
  const isProfile = !!useRouteMatch(Routes.Profile);
  const isPrivacy = !!useRouteMatch(Routes.Privacy);
  const isIntegrations = !!useRouteMatch(Routes.Integrations);

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
    >
      <SideNav>
        <SideNavTitle>Settings</SideNavTitle>
        <VStack w="100%" align="stretch" spacing={0} p={2}>
          <SideNavLink as={Link} to={`${Routes.Profile}`} isActive={isProfile}>
            Edit Profile
          </SideNavLink>
          {isKidUser(currentUser) && (
            <SideNavLink
              as={Link}
              to={`${Routes.Privacy}`}
              isActive={isPrivacy}
            >
              Privacy &amp; Safety
            </SideNavLink>
          )}
          <SideNavLink
            as={Link}
            to={`${Routes.Integrations}`}
            isActive={isIntegrations}
          >
            Connected Accounts
          </SideNavLink>
        </VStack>
      </SideNav>
      <Box w="100%" overflowX={{ base: "scroll", lg: "auto" }}>
        {children}
      </Box>
    </Flex>
  );
};
