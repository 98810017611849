import { PropsWithChildren, useState } from "react";
import { useMutation } from "urql";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  IconLock,
  Select,
  Switch,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Tooltip,
} from "Shared";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";

import { USER_PRIVACY_UPDATE_MUTATION } from "./graphql";
import { ConfirmModal } from "./ConfirmModal";
import { PageContent } from "Components/PageContent";

import {
  UserPrivacyUpdateMutation,
  UserPrivacyUpdateMutationVariables,
} from "@tract/common/dist/graphql";

enum PrivacySetting {
  Public = "public",
  School = "school",
  Network = "network",
  Private = "private",
}

export type PrivacyFormValues = {
  profilePrivacy?: string | null;
  projectPrivacy?: string | null;
  pathPrivacy?: string | null;
  creatorConsent: boolean;
};

export const PrivacyControls: React.FC = () => {
  const { track } = useAnalytics();
  const { currentUser, defaultLearnerGroup } = useSession();
  const hasRestrictedPrivacy =
    defaultLearnerGroup?.privacyScope === PrivacySetting.School;
  const [isPrivacyEnabled, setPrivacyEnabled] = useState(
    hasRestrictedPrivacy ||
      (!!currentUser.profilePrivacy && !!currentUser.projectPrivacy)
  );

  const [updating, setUpdating] = useState(false);
  const [values, setValues] = useState<PrivacyFormValues>();
  const isStudent = !!currentUser.organization;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [, updatePrivacyMutation] = useMutation<
    UserPrivacyUpdateMutation,
    UserPrivacyUpdateMutationVariables & { userId: string }
  >(USER_PRIVACY_UPDATE_MUTATION);

  const handleSubmit = (values: PrivacyFormValues) => {
    if (
      values.profilePrivacy !== currentUser.profilePrivacy &&
      // Only trigger modal when changing privacy
      // to public to something else or vice versa
      (values.profilePrivacy === PrivacySetting.Network ||
        currentUser.profilePrivacy === PrivacySetting.Network ||
        currentUser.profilePrivacy === null)
    ) {
      onOpen();
      setValues(values);
    } else {
      updatePrivacy(values);
    }
  };

  const handleConfirm = async () => {
    if (!values) {
      return;
    }

    updatePrivacy(values);
    onClose();
  };

  const updatePrivacy = async (values: PrivacyFormValues) => {
    setUpdating(true);

    try {
      const { error } = await updatePrivacyMutation({
        userId: currentUser.uid,
        input: {
          profilePrivacy:
            values.profilePrivacy === PrivacySetting.Network
              ? null
              : values.profilePrivacy,
          projectPrivacy:
            values.projectPrivacy === PrivacySetting.Network
              ? null
              : values.projectPrivacy,
          pathPrivacy:
            values.pathPrivacy === PrivacySetting.Network
              ? null
              : values.pathPrivacy,
        },
      });

      if (error) {
        throw error;
      }

      track(ANALYTICS_EVENTS.PRIVACY_SETTINGS_EDITED, {
        privacyEnabled: isPrivacyEnabled,
        profileVisibility: values.profilePrivacy,
        projectVisibility: values.projectPrivacy,
        pathVisibility: values.pathPrivacy,
        creatorResponsesEnabled: values.creatorConsent,
      });

      toast({
        title: "Privacy settings updated",
        status: "success",
      });
    } catch (err: any) {
      toast({
        title: "Error updating privacy settings",
        status: "error",
      });
      captureException(err);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <PageContent pt={{ base: 6, lg: 10 }} pb={24}>
      <Box maxWidth="45rem" mx="auto">
        <Box mb={10}>
          <Text fontSize="4xl" fontWeight="bold">
            Privacy and Safety
          </Text>
          <Text color="gray.600">
            Manage what information you see and share on Tract
          </Text>
        </Box>

        <Formik
          enableReinitialize
          initialValues={{
            profilePrivacy:
              currentUser.profilePrivacy || PrivacySetting.Network,
            projectPrivacy:
              currentUser.projectPrivacy || PrivacySetting.Network,
            pathPrivacy: currentUser.pathPrivacy || PrivacySetting.Network,
            creatorConsent: true,
          }}
          onSubmit={handleSubmit}
        >
          {(form: PropsWithChildren<FormikProps<PrivacyFormValues>>) => (
            <Form>
              <HStack alignItems="start" spacing={6} mb={10}>
                <HStack>
                  <Switch
                    id="privacy-control-switch"
                    size="lg"
                    isChecked={isPrivacyEnabled}
                    isDisabled={hasRestrictedPrivacy}
                    onChange={() => {
                      setPrivacyEnabled(!isPrivacyEnabled);

                      if (!isPrivacyEnabled) {
                        form.setFieldValue(
                          "profilePrivacy",
                          isStudent
                            ? PrivacySetting.School
                            : PrivacySetting.Private
                        );

                        form.setFieldValue(
                          "projectPrivacy",
                          isStudent
                            ? PrivacySetting.School
                            : PrivacySetting.Private
                        );

                        // form.setFieldValue(
                        //   "pathPrivacy",
                        //   isStudent ? "school" : "private"
                        // );
                      } else {
                        form.setFieldValue(
                          "profilePrivacy",
                          PrivacySetting.Network
                        );
                        form.setFieldValue(
                          "projectPrivacy",
                          PrivacySetting.Network
                        );
                        form.setFieldValue(
                          "pathPrivacy",
                          PrivacySetting.Network
                        );
                      }
                    }}
                  />
                  {hasRestrictedPrivacy && (
                    <Icon as={IconLock} w={5} h={5} color="gray.600" />
                  )}
                </HStack>
                <FormLabel htmlFor="privacy-control-switch">
                  <Text fontSize="lg" fontWeight="bold">
                    Make my account private
                  </Text>
                  {hasRestrictedPrivacy && (
                    <Text color="gray.600" fontWeight="normal">
                      Your teacher has enabled private accounts for your class.
                      {/* You can request a public profile with permission from a
                      parent or guardian. */}
                    </Text>
                  )}
                </FormLabel>
              </HStack>

              <VStack spacing={6} mb={4}>
                <Field name="profilePrivacy">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <FormLabel>Who can see my profile?</FormLabel>
                      <Select {...field} size="lg" disabled={!isPrivacyEnabled}>
                        <option
                          value={PrivacySetting.Network}
                          disabled={isPrivacyEnabled}
                        >
                          Anyone on Tract
                        </option>
                        {isStudent && (
                          <option value={PrivacySetting.School}>
                            Just my class and school
                          </option>
                        )}
                        <option value={PrivacySetting.Private}>Just me</option>
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name="projectPrivacy">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <FormLabel>Who can see my projects?</FormLabel>
                      <Select size="lg" {...field}>
                        <option
                          value={PrivacySetting.Network}
                          disabled={isPrivacyEnabled}
                        >
                          Anyone on Tract
                        </option>
                        {isStudent && (
                          <option value={PrivacySetting.School}>
                            Just my class and school
                          </option>
                        )}
                        <option value={PrivacySetting.Private}>Just me</option>
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <Field name="creatorConsent">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <HStack justifyContent="space-between">
                        <FormLabel>Let creators view my responses</FormLabel>
                        <Tooltip label="Coming Soon">
                          <HStack>
                            <Icon as={IconLock} w={5} h={5} color="gray.600" />
                            <Checkbox
                              size="lg"
                              isChecked={field.value}
                              isDisabled
                            />
                          </HStack>
                        </Tooltip>
                      </HStack>
                      <FormHelperText mt={0}>
                        When you respond to a challenge, the creator can view
                        your work and give you feedback.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Field>
                <Field name="pathPrivacy">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <FormLabel>Who can see my paths?</FormLabel>
                      <Select size="lg" {...field}>
                        <option value={PrivacySetting.Network}>
                          Anyone on Tract
                        </option>
                        {isStudent && (
                          <option value={PrivacySetting.School} disabled>
                            Just my class and school (Coming Soon)
                          </option>
                        )}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </VStack>
              <Button
                type="submit"
                colorScheme="brandFull"
                isLoading={updating}
                isDisabled={!form.dirty}
                size="lg"
              >
                Submit
              </Button>
              <ConfirmModal
                privacySettings={values}
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={async () => {
                  await handleConfirm();
                  form.resetForm({ values });
                }}
                loading={updating}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </PageContent>
  );
};
