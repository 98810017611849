import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { format } from "date-fns";
import { useLocation } from "react-router";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { PathViewerPathFragment } from "@tract/common/dist/graphql/index";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FontAwesomeIcon,
  HStack,
  IconButton,
  IconFlag,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ShowMore,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  Center,
  Accordion,
} from "Shared";

import { PathStatus } from "@tract/common/dist/types/models/Path";

import { PathActivity } from "Pages/CreateV4/PathActivity";
import { PlaylistItemNode } from "./PlaylistItemNode";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { FollowButton } from "Pages/Profile/FollowButton";

import { usePathRoute } from "./usePathRoute";
import { useSession } from "Services/session";
import { useReportPath } from "Services/hooks/useReportPath";

import {
  getDisplayName,
  isKidUser,
  isParentUser,
  isTeacherUser,
} from "Types/User";
import { PATH_INTEREST_AREAS } from "Constants/paths";
import { useFeature } from "Services/features";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { userCanSavePaths } from "Components/ClassroomSavedPaths";
import { pauseAllVideos } from "Utils/videos";

type Props = {
  path: PathViewerPathFragment;
  activeUsers?: number;
  renderMobileCloseButton?: () => ReactNode;
  onClickAssign: () => void;
  onProjectGuideOpen: () => void;
  onShareModalOpen: () => void;
  setSaveModalIsOpen: Dispatch<SetStateAction<boolean>>;
  onCollectModalOpen: () => void;
};

export const PathPlaylist: FC<Props> = ({
  path,
  activeUsers,
  renderMobileCloseButton,
  onClickAssign,
  onProjectGuideOpen,
  onShareModalOpen,
  setSaveModalIsOpen,
  onCollectModalOpen,
}) => {
  const { currentUser, isAdmin } = useSession();
  const reportPath = useReportPath();
  const { params, isPreviewRoute, isReviewRoute } = usePathRoute(path);
  const isReviewOrPreview = isReviewRoute || isPreviewRoute;
  const isTeacher = isTeacherUser(currentUser);
  const isKid = isKidUser(currentUser);
  const { pathname } = useLocation();
  const socialMediaShareLinksEnabled = useFeature("social-media-share-links");
  const lmsHelpArticlesEnabled = useFeature("lms-help-articles");
  const showShareCTA = socialMediaShareLinksEnabled || lmsHelpArticlesEnabled;

  const handleReportPath = () =>
    reportPath({
      pathId: path.id,
      pathTitle: path.title,
      pathSlug: path.slug,
      pathAuthorId: path.authorId,
      pathCoAuthorsCount: path.authors.length,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
    });
  const InterestArea = PATH_INTEREST_AREAS.filter(
    (interestArea) => interestArea.value === path?.interestArea
  )[0];

  const user = path.user;
  const isMe = currentUser.username === user?.username;
  const isParentOrTeacher =
    (user && isTeacherUser(user)) || (user && isParentUser(user));
  const amParentOrTeacher =
    isTeacherUser(currentUser) || isParentUser(currentUser);

  //State variables are for path builder back button
  const createPath = {
    pathname: `/create/path/${path?.id}`,
    state: {
      sourcePath: pathname,
      sourceLabel: "Back",
    },
  };

  if (!path) return null;

  const guide = !!path?.guides?.length ? path.guides[0]?.guide : undefined;

  const isPublished = path.status === PathStatus.Published;
  const shouldShowEdit = isAdmin || currentUser.uid === path.authorId;
  const shouldShowSave = isPublished && userCanSavePaths(currentUser);
  const shouldShowShare =
    isPublished && isTeacher && path.isVisibleUnauthed && showShareCTA;
  const shouldShowGuide = !!guide && !isKid;
  const shouldShowAssign = isTeacher;
  const shouldShowCollect = isAdmin;
  const shouldShowMenu =
    shouldShowEdit || shouldShowShare || shouldShowCollect || shouldShowSave;

  const renderPathActions = () => {
    if (!shouldShowAssign && !shouldShowGuide) {
      return null;
    }

    return (
      <ButtonGroup mt={8} w="full">
        {shouldShowAssign && (
          <PathActionButton
            label="Assign"
            icon={regular("inbox")}
            onClick={() => {
              pauseAllVideos();
              onClickAssign();
            }}
          />
        )}
        {shouldShowGuide && (
          <PathActionButton
            label="Project Guide"
            icon={regular("book-open")}
            onClick={() => {
              pauseAllVideos();
              onProjectGuideOpen();
            }}
          />
        )}
      </ButtonGroup>
    );
  };

  return (
    <>
      <Box>
        <Box px={4}>
          <Flex justifyContent="space-between" align="center">
            <HStack fontSize="md" spacing={2}>
              <UserAvatar
                username={path.user?.username || ""}
                userId={path.user?.id}
                avatarURL={path.user?.avatar || ""}
                name={path?.user && getDisplayName(path?.user)}
                isTeacher={path?.user?.isEducator}
                tabIndex={-1}
                size="lg"
                mr={1}
              />
              <UsernameLink
                username={path.user?.username || ""}
                isTeacher={path?.user?.isEducator}
                userId={path.user?.id}
                fontWeight="bold"
              >
                {path?.user && getDisplayName(path?.user)}
              </UsernameLink>
            </HStack>
            <HStack>
              {!isMe && user && !isParentOrTeacher && !amParentOrTeacher && (
                <FollowButton profile={user} />
              )}
              {shouldShowMenu && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    variant="ghost"
                    aria-label="More"
                    size="lg"
                    icon={
                      <FontAwesomeIcon icon={regular("ellipsis-vertical")} />
                    }
                  />
                  <MenuList minW="18rem">
                    {shouldShowEdit && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={regular("pencil")} />}
                        as={Link}
                        to={createPath}
                      >
                        Edit
                      </MenuItem>
                    )}
                    {shouldShowCollect && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={regular("circle-plus")} />}
                        onClick={() => {
                          pauseAllVideos();
                          onCollectModalOpen();
                        }}
                      >
                        Add to Collection
                      </MenuItem>
                    )}
                    {shouldShowShare && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={regular("share")} />}
                        onClick={() => {
                          pauseAllVideos();
                          onShareModalOpen();
                        }}
                      >
                        Share
                      </MenuItem>
                    )}
                    {shouldShowSave && (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={regular("bookmark")} />}
                        onClick={() => {
                          pauseAllVideos();
                          setSaveModalIsOpen(true);
                        }}
                      >
                        Save to Class
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}
              {renderMobileCloseButton && renderMobileCloseButton()}
            </HStack>
          </Flex>
          <Text mt={4} mb={2} fontSize="xl" fontWeight="bold">
            {path.title}
          </Text>
          <ShowMore lines={2} fontSize="md" color="gray.600" mb={2}>
            {path?.description}
          </ShowMore>
          <Box h="1.25rem">
            {!!activeUsers && activeUsers > 0 ? (
              <HStack
                spacing={2}
                fontSize="sm"
                color="green.600"
                fontWeight="bold"
              >
                <FontAwesomeIcon icon={solid("users")} size={4} />
                <Text>{activeUsers || "0"} Active Now</Text>
              </HStack>
            ) : null}
          </Box>
          {renderPathActions()}
        </Box>
        <Accordion
          allowMultiple
          variant="ghost"
          allowToggle
          mt={4}
          defaultIndex={[0, 1]}
        >
          <AccordionItem defaultChecked={true}>
            <AccordionButton>
              <Flex direction="row" justifyContent="space-between" w="full">
                <Text>Activities</Text>
                <AccordionIcon boxSize={7} />
              </Flex>
            </AccordionButton>
            <AccordionPanel pt={2}>
              <VStack align="stretch" spacing={2} direction="column">
                {path.nodes.map((node) => {
                  const isActive = node.id === params.nodeId;
                  return (
                    <Box key={node.id}>
                      <PlaylistItemNode
                        path={path}
                        node={node}
                        isActive={isActive}
                      />
                    </Box>
                  );
                })}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Flex direction="row" justifyContent="space-between" w="full">
                <Text>Details</Text>
                <AccordionIcon boxSize={7} />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              {path.authors.length > 0 && (
                <StatGroup>
                  <Stat>
                    <StatLabel>Teammates</StatLabel>
                  </Stat>
                  <VStack width="full" mt={2} mb={8}>
                    {path.authors.map((author) => (
                      <HStack width="full" justifyContent="space-between">
                        <HStack>
                          <UserAvatar
                            username={author.user.username || ""}
                            avatarURL={author.user.avatar || ""}
                            userId={author.user.id}
                            name={getDisplayName(author.user)}
                            isTeacher={author.user.isEducator}
                          />
                          <UsernameLink
                            username={author.user?.username || ""}
                            isTeacher={author?.user?.isEducator}
                            userId={author.user?.id}
                          >
                            {author.user && getDisplayName(author.user)}
                          </UsernameLink>
                          {currentUser.uid === author.user.id && (
                            <Text fontSize="sm" color="gray.600">
                              (You)
                            </Text>
                          )}
                        </HStack>
                        <FollowButton size="md" profile={author.user} />
                      </HStack>
                    ))}
                  </VStack>
                </StatGroup>
              )}
              <StatGroup>
                <Stat>
                  <StatLabel>Category</StatLabel>
                  {InterestArea ? (
                    <StatNumber
                      fontSize="xl"
                      as={Link}
                      to={`/explore/interest/${InterestArea.slug}`}
                    >
                      {InterestArea.label}
                    </StatNumber>
                  ) : (
                    <StatNumber>-</StatNumber>
                  )}
                </Stat>
              </StatGroup>
              {!!path.supplies && (
                <StatGroup mt={6}>
                  <Stat>
                    <StatLabel>Required Supplies</StatLabel>
                    <StatNumber fontSize="xl">{path.supplies}</StatNumber>
                  </Stat>
                </StatGroup>
              )}
              {path?.tags?.length > 0 && (
                <StatGroup mt={6}>
                  <Stat>
                    <StatLabel mb={2}>Tags</StatLabel>
                    <StatNumber>
                      {path.tags.map((tag: string) => (
                        <Button
                          key={tag}
                          as={Link}
                          to={`/explore/tag/${tag}`}
                          variant="outline"
                          mr={2}
                          mb={2}
                        >
                          #{tag}
                        </Button>
                      ))}
                    </StatNumber>
                  </Stat>
                </StatGroup>
              )}
              {!!path.publishedAt && (
                <Text fontSize="sm" mt={6}>
                  Published on{" "}
                  {format(new Date(path.publishedAt), "MMM d, yyyy")}
                </Text>
              )}
              {isParentUser(currentUser) && (
                <Button
                  mt={6}
                  size="lg"
                  variant="outline"
                  colorScheme="red"
                  leftIcon={<IconFlag />}
                  onClick={handleReportPath}
                >
                  Report
                </Button>
              )}
            </AccordionPanel>
          </AccordionItem>

          {isReviewOrPreview && (
            <AccordionItem defaultChecked={true}>
              <AccordionButton>
                <Flex direction="row" justifyContent="space-between" w="full">
                  <Text>Path Activity</Text>
                  <AccordionIcon boxSize={7} />
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                <PathActivity w="auto" showTitle={false} mx={6} />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Box>
    </>
  );
};

type PathActionButtonProps = {
  label: string;
  icon: IconProp;
  onClick: () => void;
};

const PathActionButton: FC<PathActionButtonProps> = ({
  label,
  icon,
  onClick,
}) => {
  return (
    <Button
      variant="unstyled"
      role="group"
      borderRadius="xl"
      alignItems="center"
      h="auto"
      w="full"
      flex={1}
      _hover={{
        "& .pathActionButton-icon": {
          bg: "brandPrimary.50",
          color: "brandPrimary.500",
        },
        "& .pathActionButton-label": {
          color: "brandPrimary.500",
        },
      }}
      _focusVisible={{
        boxShadow: "none",
        "& .pathActionButton-icon": { boxShadow: "outline" },
      }}
      onClick={onClick}
    >
      <Center
        w="full"
        bg="gray.100"
        h="4.5rem"
        borderRadius="2xl"
        mb={1}
        className="pathActionButton-icon"
        transition="150ms ease box-shadow, 150ms ease background-color, 150ms ease color"
      >
        <FontAwesomeIcon size={10} icon={icon} />
      </Center>
      <Text
        transition="150ms ease color"
        className="pathActionButton-label"
        fontWeight="bold"
      >
        {label}
      </Text>
    </Button>
  );
};
