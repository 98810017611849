export enum ViewSource {
  ClassHub = "classHub",
  ExploreAll = "exploreAll",
  ExploreAllUnauth = "exploreAllUnauth",
  ExploreInterestArea = "exploreInterestArea",
  ExploreInterestAreaUnauth = "exploreInterestAreaUnauth",
  ExploreOrganization = "exploreOrganization",
  ExplorePromoted = "explorePromoted",
  ExploreTag = "exploreTag",
  ExploreSubject = "exploreSubject",
  HomeNew = "homeNew",
  HomeTopPicks = "homeTopPicks",
  ExploreTopPicks = "exploreTopPicks",
  HomeViewingHistory = "homeViewingHistory",
  NotifyPublished = "notifyPublished",
  SearchResults = "searchResults",
  TeacherClass = "teacherClass",
  UserProfile = "userProfile",
  ViewingHistory = "viewingHistory",
  ExploreUsersFollowing = "exploreUsersFollowing",
  FollowingPaths = "followingPaths",
  TeachingResources = "teachingResources",
  Collection = "collection",
}
