import { gql } from "urql";

import { UserFileFragment } from "@tract/common/dist/graphql";

export const USER_FILE_FRAGMENT = gql`
  fragment UserFile on user_file {
    id
    filename
    transforms {
      filename
    }
  }
`;

export class UserFile {
  private _file?: UserFileFragment;
  private _transforms: Map<string, string>;
  exists: boolean;

  constructor(file?: UserFileFragment | null) {
    const transforms = new Map();

    if (file?.transforms) {
      file.transforms.forEach((ft) => {
        transforms.set(ft.filename.split(".")[0], ft.filename);
      });
    }

    this._transforms = transforms;
    this._file = file ? file : undefined;
    this.exists = !!file;
  }

  getOriginalUrl() {
    if (!this._file) {
      return "";
    }

    return `${process.env.REACT_APP_UGC_CDN_ORIGIN}/uf/${this._file.id}/${this._file.filename}`;
  }

  getTransformedUrl(filename: string) {
    if (!this._file) {
      return "";
    }
    const fileName = this._transforms.get(filename);
    if (!fileName) {
      return "";
    }

    return `${process.env.REACT_APP_UGC_CDN_ORIGIN}/uf/${this._file.id}/transforms/${fileName}`;
  }
}
