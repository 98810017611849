import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import { useDocument } from "Utils/hooks/firestore";

import { Feature } from "Types/Feature";
import { useSession } from "./session";

const FeaturesContext = createContext<{
  features: Record<string, Feature>;
}>({ features: {} });

type FeatureDocument = {
  flags: Record<string, Feature>;
};

type Props = {
  features?: Record<string, Feature> | null;
};

export const FeaturesProvider: React.FC<PropsWithChildren<Props>> = ({
  features,
  children,
}) => {
  if (!features) {
    return null;
  }

  return (
    <FeaturesContext.Provider value={{ features }}>
      {children}
    </FeaturesContext.Provider>
  );
};

/**
 * Returns a computed feature flag result.
 *
 * @param name the name/id of the feature flag (firestore document id)
 */
export const useFeature = (name: string): boolean => {
  const { features } = useContext(FeaturesContext);
  const session = useSession({ bypassStrict: true });

  const flag = features[name];

  return useMemo(() => {
    if (!flag?.enabled) {
      return false;
    }

    if (flag.live) {
      return true;
    }

    const { allow } = flag;

    if (allow) {
      if (session && allow.users?.includes(session.firebaseUser.uid)) {
        return true;
      }

      return false;
    }

    return false;
  }, [flag, session]);
};

/**
 * Fetches the feature flags, this should only be called once when the app
 * is initialized.
 */
export const useFetchFeatures = () => {
  const { data, loading, error } =
    useDocument<FeatureDocument>("feature-flags/all");

  return { features: data?.flags, isLoading: loading, error };
};
