import { forwardRef } from "@chakra-ui/system";
import { PropsWithChildren } from "react";

import { Box, Text, useBoolean, Truncate } from "Shared";
import { TextProps } from "@chakra-ui/react";

type Props = {
  lines?: number;
  more?: string;
  less?: string;
} & TextProps;

export const ShowMore = forwardRef<PropsWithChildren<Props>, "div">(
  ({ children, lines = 2, more = "more", less = "less", ...props }, ref) => {
    const [expanded, setExpanded] = useBoolean();

    const handleToggleLines = (e: TODO) => {
      e.preventDefault();
      setExpanded.toggle();
    };

    return (
      <>
        <Truncate
          lines={expanded ? Infinity : lines}
          ellipsis={
            <Box as="span">
              ...{" "}
              <Box
                as="a"
                href="#"
                onClick={handleToggleLines}
                color="brand"
                fontWeight="bold"
                _hover={{ textDecoration: "underline" }}
                _focus={{ outline: "none", boxShadow: "outline" }}
              >
                {more}
              </Box>
            </Box>
          }
        >
          <Text as="p" whiteSpace="pre-line" {...props} ref={ref}>
            {children}
          </Text>
        </Truncate>
        {expanded && (
          <Box
            as="a"
            display="inline-block"
            href="#"
            onClick={handleToggleLines}
            color="brand"
            fontWeight="bold"
            _hover={{ textDecoration: "underline" }}
            _focus={{ outline: "none", boxShadow: "outline" }}
            mb={2}
          >
            {less}
          </Box>
        )}
      </>
    );
  }
);
