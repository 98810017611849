import React, { useEffect, useState } from "react";
import { NetworkStatus, useApolloClient } from "@apollo/client";

import {
  Button,
  EmptyState,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
  useDisclosure,
  useToast,
  ButtonLink,
  Text,
} from "Shared";

import {
  AdminProjectFragment,
  useAdminProjectsQueueQuery,
  useAdminProjectsQueueCountQuery,
  useAdminPublishProjectMutation,
  AdminProjectsQueueCountQuery,
} from "@tract/common/dist/graphql";

import { captureException } from "Services/errors";

import { LayoutCentered } from "Components/LayoutCentered";
import { PageHeader } from "Components/PageHeader";
import { AdminContent } from "Pages/AdminDashboard/AdminContent";

import { SubmissionDrawer } from "../SubmissionDrawer";
import { ADMIN_PROJECTS_QUEUE_COUNT_QUERY } from "../graphql";
import { Link } from "react-router-dom";
import { getDisplayName } from "Types/User";

const QUEUE_LIMIT = 100;

export const AdminSubmissionQueue: React.FC = () => {
  const toast = useToast();
  const { cache } = useApolloClient();
  const [currentProject, setCurrentProject] = useState<AdminProjectFragment>();
  const [approving, setApproving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [publishProject] = useAdminPublishProjectMutation();

  const { data, networkStatus } = useAdminProjectsQueueQuery({
    notifyOnNetworkStatusChange: true,
    pollInterval: 5 * 60000, // poll every 5 minutes
    variables: {
      limit: QUEUE_LIMIT,
      offset: 0,
      where: {
        published: { _eq: false },
        rejectedAt: { _eq: "-infinity" },
      },
    },
  });

  const { data: queueCountData, refetch: refetchQueueCount } =
    useAdminProjectsQueueCountQuery();
  const queueCount = queueCountData?.projects.aggregate?.count;

  useEffect(() => {
    refetchQueueCount();
  }, [refetchQueueCount]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.poll) {
      refetchQueueCount();
    }
  }, [networkStatus, refetchQueueCount]);

  const handleViewProject = (project: AdminProjectFragment) => {
    setCurrentProject(project);
    onOpen();
  };

  const projects = data?.projects || [];

  const incrementAggregateCount = (increment: number) => {
    if (queueCountData?.projects.aggregate) {
      cache.writeQuery<AdminProjectsQueueCountQuery>({
        query: ADMIN_PROJECTS_QUEUE_COUNT_QUERY,
        data: {
          __typename: "query_root",
          projects: {
            __typename: "project_aggregate",
            aggregate: {
              __typename: "project_aggregate_fields",
              count: queueCountData.projects.aggregate.count + increment,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!isOpen && currentProject) {
      setTimeout(() => {
        setCurrentProject(undefined);
      }, 500);
    }
  }, [isOpen, currentProject]);

  const updateCache = (project: AdminProjectFragment) => {
    cache.evict({ id: cache.identify(project) });
    cache.gc();

    incrementAggregateCount(-1);
  };

  const handleApproveProject = async (project: AdminProjectFragment) => {
    if (approving) {
      return;
    }

    const toastId = toast({
      status: "info",
      title: "Approving Project",
      duration: 30000,
    });

    setApproving(true);

    try {
      await publishProject({
        variables: {
          projectId: project.id,
        },
        update() {
          updateCache(project);
          onClose();
          toast({
            status: "success",
            title: "Approved Project",
          });
        },
      });

      if (!projects.length) {
        refetchQueueCount();
      }
    } catch (error: any) {
      captureException(error);
    } finally {
      setApproving(false);

      if (toastId) {
        toast.close(toastId);
      }
    }
  };

  const handleRejectProject = async (project: AdminProjectFragment) => {
    updateCache(project);

    if (!projects.length) {
      refetchQueueCount();
    }
  };

  if (networkStatus === NetworkStatus.loading) {
    return <LayoutCentered height="auto" isLoading />;
  }

  if (!projects.length) {
    return <EmptyState headline="All done!" />;
  }

  return (
    <AdminContent>
      <PageHeader title={`Submission Queue (${queueCount || "-"})`} />
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Username</Th>
            <Th>Submitted at</Th>
            <Th>Type</Th>
            <Th>Text/Caption</Th>
            <Th>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {projects.map((project) => (
            <SubmissionRow
              project={project}
              onViewProject={handleViewProject}
              key={project.id}
            />
          ))}
        </Tbody>
      </Table>

      {currentProject && (
        <SubmissionDrawer
          isOpen={isOpen}
          onClose={onClose}
          onApprove={handleApproveProject}
          onReject={handleRejectProject}
          project={currentProject}
        />
      )}
    </AdminContent>
  );
};

const SubmissionRow: React.FC<{
  project: AdminProjectFragment;
  onViewProject: (project: AdminProjectFragment) => void;
}> = ({ project, onViewProject }) => {
  const isFileResponse = project.file;
  const displayName = getDisplayName(project.user);

  return (
    <Tr>
      <Td py={2} w="350px">
        {displayName}
        {project.user.isEducator && (
          <Text ml={2} as="span" fontSize="sm" color="gray.600">
            (Teacher)
          </Text>
        )}
        {project.user.invitedBy && (
          <Text ml={2} as="span" fontSize="sm" color="gray.600">
            Invited by{" "}
            <ButtonLink
              as={Link}
              fontSize="sm"
              colorScheme="brandFull"
              to={`/@${project.user.invitedBy.username}`}
            >
              {project.user.invitedBy.username}
            </ButtonLink>
          </Text>
        )}
      </Td>
      <Td py={2} w="300px">
        {new Date(project.createdAt).toLocaleString()}
      </Td>
      <Td py={2} w="100px">
        {isFileResponse ? "File" : "Text"}
      </Td>
      <Td py={2} wordBreak="break-word">
        {project.text ? project.text : "-"}
      </Td>
      <Td py={2} w="100px">
        <HStack spacing={2}>
          <Button onClick={() => onViewProject(project)}>View</Button>
        </HStack>
      </Td>
    </Tr>
  );
};
