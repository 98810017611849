import React from "react";

import {
  Box,
  Button,
  Center,
  DarkMode,
  Flex,
  Grid,
  HStack,
  Icon,
  IconCheck,
  IconFilledCamera,
  IconFilledCreator,
  IconMonitor,
  IconSliders,
  IconTrash,
} from "Shared";

import { useVideoEditorContext } from ".";

type Props = {
  enablePhotoUpload: boolean;
  onClickFx: () => void;
  onClickSettings: () => void;
  onClickCamera: () => void;
  onRecord: () => void;
  onRecordStop: () => void;
  onDiscardRecording: () => void;
  onKeepRecording: () => void;
};

export const Controls: React.FC<Props> = ({
  enablePhotoUpload,
  onClickFx,
  onClickSettings,
  onClickCamera,
  onRecord,
  onRecordStop,
  onDiscardRecording,
  onKeepRecording,
}) => {
  const { saving, preview, isRecording, isCountdownActive } =
    useVideoEditorContext();
  const isRecordingOrCounting = isRecording || isCountdownActive;

  const numberOfControls = enablePhotoUpload ? 4 : 3;

  if (!!preview) {
    return (
      <Center>
        <HStack spacing={4}>
          <Button
            colorScheme="red"
            size="xl"
            variant="solid"
            w="10rem"
            leftIcon={<IconTrash />}
            onClick={onDiscardRecording}
            disabled={saving}
          >
            Discard
          </Button>
          <Button
            colorScheme="green"
            size="xl"
            variant="solid"
            w="10rem"
            leftIcon={<IconCheck />}
            onClick={onKeepRecording}
            disabled={saving}
          >
            Keep
          </Button>
        </HStack>
      </Center>
    );
  }

  return (
    <Flex width="100%" justifyContent="center">
      <Grid
        mx="auto"
        templateColumns={`repeat(${numberOfControls}, 5rem)`}
        gap={4}
      >
        <Box>
          <DarkMode>
            <Button
              variant="ghost"
              hidden={isRecordingOrCounting}
              onClick={onClickSettings}
              w="full"
              h="full"
              borderRadius="xl"
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap={2}
              color="white"
              fontSize="xs"
            >
              <IconSliders size={24} />
              Options
            </Button>
          </DarkMode>
        </Box>
        {enablePhotoUpload && (
          <Box p={2}>
            <Button
              variant="solid"
              colorScheme="red"
              borderRadius="full"
              onClick={onClickCamera}
              hidden={isRecordingOrCounting}
              w="4rem"
              h="4rem"
              p={4}
            >
              <Icon as={IconFilledCamera} color="white" w={8} h={8} />
            </Button>
          </Box>
        )}
        <Box hidden={isRecordingOrCounting}>
          <Button
            variant="solid"
            colorScheme="red"
            borderRadius="full"
            height="5rem"
            width="5rem"
            p={4}
            onClick={onRecord}
          >
            <Box bgColor="white" w={8} h={8} borderRadius="full" />
          </Button>
        </Box>
        <Box hidden={!isRecordingOrCounting}>
          <Button
            variant="outline"
            colorScheme="red"
            borderRadius="full"
            borderColor="red.500"
            borderWidth="8px"
            height="5rem"
            width="5rem"
            transition="none"
            p={4}
            onClick={onRecordStop}
            _hover={{
              bg: "transparent",
            }}
            _active={{
              bg: "transparent",
            }}
          >
            <Box bgColor="white" w={8} h={8} borderRadius="4px" />
          </Button>
        </Box>
        <Box>
          <DarkMode>
            <Button
              variant="ghost"
              hidden={isRecordingOrCounting}
              onClick={onClickFx}
              w="full"
              h="full"
              borderRadius="xl"
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap={2}
              color="white"
              fontSize="xs"
            >
              <Icon as={IconFilledCreator} w={8} h={8} />
              Effects
            </Button>
          </DarkMode>
        </Box>
        <Box>
          <DarkMode>
            <Button
              variant="ghost"
              hidden
              onClick={onClickFx}
              w="full"
              h="full"
              borderRadius="xl"
              display="flex"
              flexDirection="column"
              alignItems="center"
              gridGap={2}
              color="white"
              fontSize="xs"
              aria-label="Share Screen"
            >
              <IconMonitor size={24} />
              Share
            </Button>
          </DarkMode>
        </Box>
      </Grid>
    </Flex>
  );
};
