import { LIVESTREAM_FRAGMENT } from "Pages/LivestreamGallery/graphql";
import { gql } from "urql";

export const NEXT_LIVESTREAM_QUERY = gql`
  ${LIVESTREAM_FRAGMENT}

  query NextLivestream($startDate: timestamptz!) {
    livestream: livestream(
      limit: 1
      offset: 0
      order_by: { startDate: asc }
      where: {
        _or: [
          { startDate: { _gte: $startDate } }
          { endDate: { _gte: $startDate } }
        ]
        disabled: { _eq: false }
      }
    ) {
      ...Livestream
    }
  }
`;

export const LIVESTREAM_BANNER_QUERY = gql`
  ${LIVESTREAM_FRAGMENT}

  query LivestreamBanner($id: uuid!) {
    livestream: livestream_by_pk(id: $id) {
      ...Livestream
    }
  }
`;

export const JOIN_LIVESTREAM_MUTATION = gql`
  mutation JoinLivestream($livestreamId: uuid!) {
    api_join_livestream(livestreamId: $livestreamId)
  }
`;

export const LIVESTREAM_USER_QUERY = gql`
  query LivestreamUserById($userId: String!) {
    users: user(where: { firestoreId: { _eq: $userId } }) {
      id: firestoreId
      uid: firestoreId
      avatar
      country
      creatorLevel
      disabled
      isEducator
      isMod
      username
    }
  }
`;
