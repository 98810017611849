import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  SkeletonText,
} from "@chakra-ui/react";

import {
  HStack,
  Flex,
  FontAwesomeIcon,
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  InputGroup,
  InputLeftElement,
  ButtonSolid,
  ModalCloseButton,
  ButtonOutlined,
  Box,
  Select,
  FormControl,
  FormLabel,
  useToast,
} from "Shared";
import { useIntercom } from "Services/intercom";
import { useSession } from "Services/session";
import {
  ClassroomLearnerGroupFragment,
  Enums_Integration_Provider_Enum,
  SyncRosterMutation,
  SyncRosterMutationVariables,
} from "@tract/common/dist/graphql";
import { get } from "Services/api";
import { GoogleButton } from "Components/SSO/GoogleButton";
import { GOOGLE_ACCESS_TOKEN_KEY } from "Components/CreateClassroomModal";
import { fetchGoogleClassrooms, useGoogleEmail } from "Services/classroom";
import { captureException } from "Services/errors";
import { useMutation } from "urql";
import { API_SYNC_ROSTER_MUTATION } from "./graphql";
import { useFeature } from "Services/features";
import { SIGNUP_ERRORS } from "@tract/common/dist/constants/signup-errors";
import { ANALYTICS_EVENTS, GroupKey, useAnalytics } from "Services/analytics";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useHistory } from "react-router-dom";

type AddStudentsModalProps = {
  learnerGroup: ClassroomLearnerGroupFragment;
  isOpen: boolean;
  codeLink: string;
  onCopyLink: () => void;
  onClose: (refetch?: boolean) => void;
};

export const AddStudentsModal: React.FC<AddStudentsModalProps> = ({
  learnerGroup,
  isOpen,
  codeLink,
  onCopyLink,
  onClose,
}) => {
  const isClassroomImportsEnabled = useFeature("classroom-imports");
  const selectCoursesRef = useRef<HTMLSelectElement>(null);
  const { currentUser, firebaseUser } = useSession();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { isReady, show } = useIntercom();
  const googleIntegration = currentUser.integrations.find(
    (ui) => ui.provider === Enums_Integration_Provider_Enum.Google
  );
  const [accessToken, setAccessToken] = useState<string | null>(() =>
    localStorage.getItem(GOOGLE_ACCESS_TOKEN_KEY)
  );
  const [loadingToken, setLoadingToken] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [courses, setCourses] = useState<any[] | null>(null);
  const [importingRoster, setImportingRoster] = useState(false);
  const history = useHistory();
  const toast = useToast();
  const analytics = useAnalytics();
  const [, syncRoster] = useMutation<
    SyncRosterMutation,
    SyncRosterMutationVariables
  >(API_SYNC_ROSTER_MUTATION);
  const googleEmail = useGoogleEmail(isOpen ? accessToken : null);

  const loadAccessToken = useCallback(() => {
    if (!!courses) return;

    setLoadingToken(true);
    get("/v2/integrations/google/refresh", firebaseUser)
      .then((response) => {
        setAccessToken(response.data?.access_token);
        localStorage.setItem(
          GOOGLE_ACCESS_TOKEN_KEY,
          response.data?.access_token
        );
      })
      .finally(() => {
        setLoadingToken(false);
      });
  }, [firebaseUser, courses]);

  const loadCourses = useCallback(
    async (accessToken?: string) => {
      if (!accessToken || !!courses) return;

      setLoadingCourses(true);
      try {
        const courses = await fetchGoogleClassrooms(accessToken);

        setCourses(courses);
      } catch (error: any) {
        captureException(error);
        if (error?.response?.data?.error?.status === "UNAUTHENTICATED") {
          loadAccessToken();
        }
      } finally {
        setLoadingCourses(false);
      }
    },
    [loadAccessToken, courses]
  );

  useEffect(() => {
    if (isClassroomImportsEnabled && isOpen && googleIntegration) {
      if (accessToken) {
        loadCourses(accessToken);
      } else {
        loadAccessToken();
      }
    }
  }, [
    isClassroomImportsEnabled,
    isOpen,
    accessToken,
    googleIntegration,
    loadCourses,
    loadAccessToken,
  ]);

  const onImportRoster = async () => {
    if (!accessToken) return;

    setImportingRoster(true);
    const courseId = selectCoursesRef.current?.value;
    if (!courseId) return;
    const learnerGroupId = learnerGroup.id;

    try {
      const result = await syncRoster({
        classId: courseId,
        learnerGroupId,
        accessToken,
      });

      analytics.track(ANALYTICS_EVENTS.CLASS_ROSTER_SYNCED, {
        learnerGroupId: learnerGroup.id,
        provider: "googleClassroom",
        studentCount: result.data?.syncRoster?.invitesCount,
      });

      analytics.group(GroupKey.LearnerGroup, learnerGroup.id, {
        name: learnerGroup.name,
        ownerUserId: currentUser.id,
        orgId: currentUser.orgId,
        provider: "googleClassroom",
      });
      onClose(true);

      history.push(`/class/${learnerGroup.id}/people/students`);
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong",
        status: "error",
      });
    } finally {
      setImportingRoster(false);
    }
  };

  const onGoogleFailure = (errCode: string) => {
    if (errCode === SIGNUP_ERRORS.INVALID_EMAIL_DOMAIN) {
      toast({
        title: "Your email is not of a valid organization",
        status: "error",
      });
    } else if (errCode === SIGNUP_ERRORS.LINKED_WITH_DIFFERENT_ACCOUNT) {
      toast({
        title: "Please use the same account you used to login",
        status: "error",
      });
    } else {
      toast({ title: "Something went wrong :(", status: "error" });
    }
  };

  return (
    <Modal size="2xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Students</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Accordion
            variant="ghost"
            index={activeIndex}
            defaultIndex={[0]}
            onChange={(index: number) => setActiveIndex(index)}
          >
            <ModalAccordionItem heading="Invite Link">
              <Text mb={4}>
                Invite students to join your class using this link:
              </Text>
              <HStack spacing={2}>
                <InputGroup>
                  <InputLeftElement color="gray.600">
                    <FontAwesomeIcon icon={regular("link")} />
                  </InputLeftElement>
                  <Input
                    borderRadius="full"
                    variant="filled"
                    value={codeLink}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                  />
                </InputGroup>
                <Box>
                  <ButtonSolid size="lg" onClick={onCopyLink}>
                    Copy Link
                  </ButtonSolid>
                </Box>
              </HStack>
            </ModalAccordionItem>
            {isClassroomImportsEnabled && (
              <ModalAccordionItem heading="Google Clasroom">
                {loadingToken || loadingCourses ? (
                  <SkeletonText />
                ) : accessToken ? (
                  <>
                    {googleEmail && (
                      <Text mb={4}>
                        You are signed in as{" "}
                        <Text as="span" fontWeight="bold">
                          {googleEmail}.
                        </Text>
                      </Text>
                    )}
                    {courses?.length ? (
                      <>
                        <FormControl mb={3}>
                          <FormLabel htmlFor="courses" hidden>
                            Select a classroom
                          </FormLabel>
                          <HStack spacing={2}>
                            <Select
                              ref={selectCoursesRef}
                              id="courses"
                              variant="filled"
                              borderRadius="full"
                              value={learnerGroup.sourceId || undefined}
                              disabled={!!learnerGroup.sourceId}
                              flex={1}
                            >
                              <option disabled>Select a class</option>
                              {courses?.map((c) => (
                                <option key={c.id} value={c.id}>
                                  {c.name}
                                </option>
                              ))}
                            </Select>
                            <ButtonSolid
                              isDisabled={
                                importingRoster ||
                                !courses ||
                                courses.length === 0
                              }
                              isLoading={importingRoster}
                              size="lg"
                              onClick={onImportRoster}
                            >
                              {!!learnerGroup.sourceId
                                ? "Sync Roster"
                                : "Import Roster"}
                            </ButtonSolid>
                          </HStack>
                        </FormControl>
                      </>
                    ) : (
                      <Text color="gray.600">
                        There are no google classrooms in this account.
                      </Text>
                    )}
                  </>
                ) : (
                  <>
                    <Text mb={4}>
                      Sign in with Google to import class roster:
                    </Text>
                    <GoogleButton
                      linkOnly
                      size="lg"
                      w="unset"
                      onSuccess={(data: any) => {
                        loadCourses(data?.access_token);
                        setAccessToken(data?.access_token);
                        localStorage.setItem(
                          GOOGLE_ACCESS_TOKEN_KEY,
                          data?.access_token
                        );
                      }}
                      onFailure={onGoogleFailure}
                    >
                      Sign in with Google
                    </GoogleButton>
                  </>
                )}
              </ModalAccordionItem>
            )}
            <ModalAccordionItem heading="CSV Import">
              <Text mb={4}>
                CSV imports are coming soon! Contact support for assistance.
              </Text>
              {isReady && (
                <ButtonOutlined size="lg" onClick={show}>
                  Contact Support
                </ButtonOutlined>
              )}
            </ModalAccordionItem>
          </Accordion>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface ModalAccordionItemProps {
  heading: string;
}

const ModalAccordionItem: React.FC<
  ModalAccordionItemProps & AccordionPanelProps
> = ({ heading, children }) => (
  <AccordionItem>
    <Flex as={AccordionButton} px={0} justify="space-between">
      <Text>{heading}</Text>
      <AccordionIcon h="2rem" w="2rem" />
    </Flex>
    <AccordionPanel pb={10}>{children}</AccordionPanel>
  </AccordionItem>
);
