import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router";
import { forwardRef } from "@chakra-ui/system";
import { ApolloError, gql, useQuery } from "@apollo/client";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { PathCardFragment } from "@tract/common/dist/graphql";
import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";

import { Box, Button, Center, EmptyState, useBreakpointValue } from "Shared";

import { PathCard } from "Components/PathCard";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

import { captureException } from "Services/errors";

import { createContext } from "Utils";
import { ViewSource } from "Types/Path";
import { GridPaths } from "Components/GridContainer";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";

const [Provider, useContext] = createContext<{
  paths: any[];
  query: {
    orgId?: string | null;
    limit: number;
    status?: PathStatus;
    visibility?: PathVisibility;
    interestAreas?: string[];
    subjects?: string[];
    skills?: string[];
    tags?: string[];
  };
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  hasNextPage: boolean;
  loading: boolean;
  loadingMore: boolean;
  error: ApolloError | undefined;
  loadMore: () => void;
}>({
  strict: true,
});

export const usePathFeed = useContext;

type PathFeedProviderProps = {
  orgId?: string | null;
  status?: PathStatus;
  visibility?: PathVisibility;
  interestAreas?: string[];
  subjects?: string[];
  skills?: string[];
  tags?: string[];
  limit?: number;
  isVisibleUnauthed?: boolean;
};

export const PathFeedProvider: React.FC<
  PropsWithChildren<PathFeedProviderProps>
> = ({
  orgId,
  limit,
  status,
  visibility,
  interestAreas,
  subjects,
  skills,
  tags,
  isVisibleUnauthed,
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const query = {
    orgId,
    limit: limit || 48,
    status: status || PathStatus.Published,
    visibility: visibility || PathVisibility.Public,
    interestAreas,
    subjects,
    skills,
    tags,
    isVisibleUnauthed,
  };

  const graphQLQuery = gql`
    ${PATH_CARD_FRAGMENT}

    query PathFeed(
      $status: String!
      $visibility: String!
      $interestArea: String_comparison_exp
      $subjects: jsonb_comparison_exp
      $skills: jsonb_comparison_exp
      $orgId: String_comparison_exp
      $limit: Int!
      $offset: Int!
      $tags: jsonb_comparison_exp
      $isVisibleUnauthed: Boolean_comparison_exp
    ) @cached(ttl: 120) {
      paths: path(
        limit: $limit
        offset: $offset
        order_by: { publishedAt: desc_nulls_last }
        where: {
          _schemaVersion: { _eq: 3 }
          status: { _eq: $status }
          visibility: { _eq: $visibility }
          interestArea: $interestArea
          subjects: $subjects
          skills: $skills
          orgId: $orgId
          tags: $tags
          isVisibleUnauthed: $isVisibleUnauthed
        }
      ) {
        ...PathCard
      }
    }
  `;

  const { data, error, loading, fetchMore } = useQuery<{
    paths: PathCardFragment[];
  }>(graphQLQuery, {
    variables: {
      status: query.status,
      visibility: query.visibility,
      limit: query.limit + 1,
      offset: 0,
      interestArea: query.interestAreas ? { _in: query.interestAreas } : {},
      subjects: query.subjects ? { _contained_in: query.subjects } : {},
      skills: query.skills ? { _contained_in: query.skills } : {},
      orgId: query.orgId ? { _eq: query.orgId } : {},
      tags: query.tags?.length ? { _contains: query.tags } : {},
      isVisibleUnauthed: !!query.isVisibleUnauthed
        ? { _eq: query.isVisibleUnauthed }
        : {},
    },
  });

  useEffect(() => {
    if (data?.paths) {
      const moreToLoad = data.paths.length > query.limit * currentPage;

      setHasNextPage(moreToLoad);
    }
  }, [data, query.limit, currentPage, setHasNextPage]);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          offset: currentPage * query.limit,
        },
      });
      setCurrentPage(currentPage + 1);
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <Provider
      value={{
        query,
        paths: data?.paths || [],
        currentPage,
        setCurrentPage,
        loading,
        loadingMore,
        error,
        loadMore,
        hasNextPage,
      }}
    >
      {children}
    </Provider>
  );
};

type Props = {
  show?: number;
  source: ViewSource;
};

export const PathFeed: FC<Props> = ({ show, source, ...props }) => {
  const { paths, query, currentPage, loading, loadingMore } = usePathFeed();

  if ((!paths || paths.length < 1) && !loading && !loadingMore) {
    return (
      <EmptyState gridColumn="1/-1" image="path" headline="No Paths Yet" />
    );
  }

  return (
    <>
      {paths.map(
        (path, i) =>
          i < query.limit * currentPage && (
            <Box
              display={show ? (i < show ? "block" : "none") : "block"}
              key={path.id}
            >
              <PathCard path={path} source={source} />
            </Box>
          )
      )}
    </>
  );
};

export const PathFeedInfinite = forwardRef<{ pageLimit?: number }, "div">(
  ({ pageLimit, ...props }, ref) => {
    const { loading, error, currentPage, hasNextPage, loadMore } =
      usePathFeed();
    const [isPaused, setIsPaused] = useState(false);
    const limit = pageLimit || 4;
    const skeletonCount = useBreakpointValue({
      base: 2,
      sm: 4,
      md: 6,
      xl: 8,
      "2xl": 10,
      "3xl": 12,
    });

    useEffect(() => {
      if (currentPage === 1) {
        setIsPaused(false);
      } else if (currentPage % limit === 0) {
        setIsPaused(true);
      }
    }, [currentPage, limit, setIsPaused]);

    const handleLoadMore = () => {
      if (!isPaused) {
        return loadMore();
      }
    };

    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage,
      onLoadMore: handleLoadMore,
      // When there is an error, we stop infinite loading.
      // It can be reactivated by setting "error" state as undefined.
      disabled: !!error,
      // `rootMargin` is passed to `IntersectionObserver`.
      // We can use it to trigger 'onLoadMore' when the sentry comes near to become
      // visible, instead of becoming fully visible on the screen.
      rootMargin: "0px 0px 80% 0px",
    });

    return (
      <Center {...props} ref={ref} mt={currentPage > 1 ? 4 : undefined}>
        {(loading || hasNextPage) && !isPaused && !!skeletonCount && (
          <GridPaths ref={sentryRef} width="full" height="full">
            {Array.from({ length: skeletonCount }).map((_, i) => (
              <PathCardSkeleton key={i} />
            ))}
          </GridPaths>
        )}
        {isPaused && (
          <Button
            variant="ghost"
            size="lg"
            colorScheme="brandFull"
            mt={10}
            onClick={() => setIsPaused(false)}
          >
            Load More
          </Button>
        )}
      </Center>
    );
  }
);

export const PathFeedLoadMore = forwardRef((props, ref) => {
  const { hasNextPage: showLoadMore, loadMore, loadingMore } = usePathFeed();

  return (
    <Center {...props} ref={ref}>
      {showLoadMore && (
        <Button
          variant="ghost"
          size="lg"
          colorScheme="brandFull"
          onClick={loadMore}
          isLoading={loadingMore}
        >
          Load More
        </Button>
      )}
    </Center>
  );
});

export const PathFeedEmptyState = forwardRef((props, ref) => {
  const { loading, loadingMore, paths } = usePathFeed();

  if (!loading && !loadingMore && !paths.length) {
    return <EmptyState headline="No results found" />;
  }

  return null;
});

export const SearchReset = () => {
  const { setCurrentPage } = usePathFeed();
  const location = useLocation();

  useEffect(() => {
    setCurrentPage(1);
  }, [location.search, setCurrentPage]);

  return null;
};
