import React, { Ref, forwardRef, useEffect, useState } from "react";
import { Tab, TabProps } from "@chakra-ui/react";
import { Link, LinkProps, useHistory, useLocation } from "react-router-dom";

export { Tabs, Tab, TabPanel, TabList, TabPanels } from "@chakra-ui/react";

type TabLinkProps = TabProps & LinkProps;

export const TabLink: React.FC<TabLinkProps> = forwardRef(
  (props: TabLinkProps, ref: Ref<any>) => {
    const history = useHistory();
    const { to, ...restProps } = props;

    if (typeof to === "string") {
      return <Tab ref={ref} onClick={() => history.push(to)} {...restProps} />;
    }

    return <Tab ref={ref} as={Link} {...props} />;
  }
);

type UseRouteTabsParams = {
  routes: { path: string }[];
  exact?: boolean;
};

export const useRouteTabs = ({
  routes,
  exact = true,
}: UseRouteTabsParams): {
  tabsProps: { index: number; onChange: () => void };
} => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const tabsProps = {
    index: tabIndex,
    onChange: () => true,
  };

  useEffect(() => {
    const tabIndex = routes.findIndex((r: { path: string }) => {
      const pathParts = new URL(r.path, "http://stub");
      return exact
        ? pathParts.pathname === location.pathname
        : location.pathname.includes(pathParts.pathname);
    });

    if (tabIndex > -1) {
      setTabIndex(tabIndex);
    }
  }, [location, routes, exact]);

  return { tabsProps };
};
