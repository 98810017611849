import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useIsMyProfile } from "Services/session";

export const useProfileViewAnalytics = ({
  isAuthorProfile,
}: {
  isAuthorProfile: boolean;
}) => {
  const { isMyProfile } = useIsMyProfile();
  const { pathname } = useLocation();
  const { track } = useAnalytics();

  useEffect(() => {
    const tabName = pathname.split("/")[2];

    if (!tabName) return;

    track(ANALYTICS_EVENTS.PROFILE_VIEWED, {
      isMyProfile,
      isAuthorProfile,
      profileTab: tabName,
    });
  }, [pathname, track, isMyProfile, isAuthorProfile]);
};
