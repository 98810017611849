import { BoxProps } from "@chakra-ui/react";
import React from "react";
import { Box } from "Shared";

type Props = {
  size?: string;
};

export const Dot: React.FC<Props & BoxProps> = ({ size = "8px", ...props }) => {
  return (
    <Box
      as="span"
      w={size}
      h={size}
      borderRadius="50%"
      bg="red.500"
      {...props}
    />
  );
};
