import { TextProps } from "@chakra-ui/layout";
import { forwardRef } from "@chakra-ui/system";

import { Link, Text } from "Shared";

type UsernameLinkProps = {
  username?: string;
  userId?: string;
  isAuthor?: boolean;
  isTeacher?: boolean;
  isParent?: boolean;
} & TextProps;

export const UsernameLink = forwardRef(
  (
    {
      username = "",
      userId = "",
      isAuthor,
      isTeacher,
      children,
      isParent,
      ...props
    }: UsernameLinkProps,
    ref
  ) => {
    const showLink = !isParent || isTeacher;
    const slug = isTeacher ? `/${userId}` : `/@${username}`;
    return (
      <Text
        as={!showLink ? "p" : Link}
        to={!showLink ? "#" : slug}
        ref={ref}
        noOfLines={1}
        {...props}
      >
        {children}
      </Text>
    );
  }
);
