import React, { useEffect } from "react";
// @ts-ignore
import ConfettiGenerator from "confetti-js";

import { Box } from "Shared";

const CONTAINER_ID = "confetti";

export function Confetti({ ...props }) {
  useEffect(() => {
    const { render, clear } = new ConfettiGenerator({
      respawn: false,
      props: ["circle", "square", "triangle"],
      start_from_edge: true,
      rotate: true,
      max: 500,
      clock: 80,
      colors: [
        [252, 188, 98],
        [34, 183, 176],
        [107, 78, 211],
        [255, 94, 105],
        [39, 140, 247],
      ],
      target: CONTAINER_ID,
    });

    render();

    return clear;
  }, []);

  return (
    <Box
      as="canvas"
      id={CONTAINER_ID}
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      height="100vh"
      width="100%"
      {...props}
    />
  );
}
