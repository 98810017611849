import { gql } from "urql";

export const PATH_SLUG_BY_NODE_ID = gql`
  query PathSlugByNodeId($id: uuid!) {
    node: path_node_by_pk(id: $id) {
      id
      path {
        id
        slug
      }
    }
  }
`;
