import React from "react";
import { Box, SkeletonCircle, Skeleton, HStack, AspectRatio } from "Shared";

type Props = {};

export const ProjectCardSkeleton: React.FC<Props> = () => {
  return (
    <Box>
      <AspectRatio ratio={16 / 9}>
        <SkeletonCircle borderRadius="xl" width="full" />
      </AspectRatio>
      <HStack mt={2}>
        <SkeletonCircle boxSize={6} />
        <Skeleton height={4} flex={1} maxW="25%" />
      </HStack>
    </Box>
  );
};
