import { gql } from "urql";
import { UpdateResolver } from "@urql/exchange-graphcache";

import { UpdateClassroomSettingsMutationVariables } from "@tract/common/dist/graphql";

export const api_update_learner_group_settings: UpdateResolver = (
  _,
  __,
  cache,
  info
) => {
  const variables = info.variables as UpdateClassroomSettingsMutationVariables;
  cache.writeFragment(
    gql`
      fragment _ on learner_group {
        id
        name
        privacyScope
        color
      }
    `,
    {
      id: variables.classId,
      name: variables.name,
      privacyScope: variables.privacyScope,
      color: variables.color,
    }
  );
};
