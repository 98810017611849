import firebase from "firebase/compat";
import { Accomplishment } from "./Accomplishment";
import { InterestArea } from "./Interests";

export enum CreatorLevel {
  Base = "BASE",
  Creator = "CREATOR",
  Partner = "PARTNER",
  Affiliate = "AFFILIATE",
}

export enum UserRole {
  Learner = "Learner",
  Parent = "Parent",
  Teacher = "Teacher",
  Admin = "Admin",
  Creator = "Creator",
}

export enum UserType {
  Kid = "kid",
  Parent = "parent",
}

export enum PlanType {
  Single = "single",
  Family = "family",
}

export enum PlanInterval {
  Month = "month",
  Year = "year",
}

export interface User {
  username: string;
  canAuthor: boolean;
  onboarded: boolean;
  avatar: string;
  userType: UserType;
  subscribed: boolean;
  subscriptionExempt?: boolean;
  orgId?: string;
  lastCoinRedemptionTime?: firebase.firestore.Timestamp;
}

export interface ParentUser extends User {
  firstName: string;
  lastName: string;
  kids: string[];
  planType: PlanType;
  planInterval: PlanInterval;

  // Hack to get teacher role extended as parent user
  isEducator?: boolean;
}

export interface KidUser extends User {
  firstName: string;
  lastName: string;
  birthday: {
    day: string;
    month: string;
    year: string;
  };
  email: string;
  totalXP: number;
  weeklyXP: {
    [weeklyDate: string]: number;
  };
  monthlyXP: {
    [monthlyDate: string]: number;
  };
  dailyXP: {
    [dailyDate: string]: number;
  };
  missionsCompleted: number;
  challengesCompleted: number;
  pathsCompleted: number;
  parent: {
    email: string;
    uid: string;
  };
  followersCount?: number;
  followingCount?: number;
  bio?: string;
  interestAreas?: InterestArea[];
  country?: string;
  coinBalance?: number;
}

export interface AuthorUser extends User {
  name: string;
  school: string;
  photo?: string;
  introVideoURL?: string;
  followersCount?: number;
  followingCount?: number;
  paths: string[];
  interests?: InterestArea[];
  accomplishments?: Accomplishment[];
  country?: string;
  coinBalance?: number;
  creatorLevel?: CreatorLevel;
}

export interface AnyUser extends AuthorUser, KidUser, ParentUser {}

// Type Guards
export function isParentUser(user: User | ParentUser): user is ParentUser {
  return (user as ParentUser).userType === UserType.Parent;
}

export function isKidUser(user: User | KidUser): user is KidUser {
  return (user as KidUser).userType === UserType.Kid;
}

const isAuthor = (user: User | AuthorUser): user is AuthorUser => {
  return !!user.canAuthor;
};

export function isAuthorUser(user: User | AuthorUser): user is AuthorUser {
  return (user as AuthorUser).canAuthor === true;
}

export function isTeacherUser(user: User | ParentUser): user is ParentUser {
  return (user as ParentUser).isEducator === true;
}

export function isAffiliateUser(user: User | AuthorUser): user is AuthorUser {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Affiliate;
}

export function isPartnerUser(user: User | AuthorUser): user is AuthorUser {
  return isAuthor(user) && user.creatorLevel === CreatorLevel.Partner;
}
