import React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { LayoutCentered } from "Components/LayoutCentered";
import { useQuery } from "urql";
import { PATH_SLUG_BY_NODE_ID } from "./graphql";
import {
  PathSlugByNodeIdQueryResult,
  PathSlugByNodeIdQueryVariables,
} from "@tract/common/dist/graphql";

export const PathNodeRedirect: React.FC = () => {
  const { nodeId } = useParams<{ nodeId: string }>();
  const { state } = useLocation();
  const [{ data, fetching: loading }] = useQuery<
    PathSlugByNodeIdQueryResult["data"],
    PathSlugByNodeIdQueryVariables
  >({
    query: PATH_SLUG_BY_NODE_ID,
    variables: {
      id: nodeId,
    },
  });

  const node = data?.node;

  if (loading) {
    return <LayoutCentered height="auto" isLoading />;
  }

  if (!node || !node.path.slug) {
    return <Redirect to="/" />;
  }

  return (
    <Redirect to={{ pathname: `/path/${node.path.slug}/v/${nodeId}`, state }} />
  );
};
