import React from "react";
import { Box, Text, useDisclosure } from "Shared";
import { UserAwardModal } from "Components/UserAwardModal";
import { UserAwardCardLayout } from "./UserAwardCardLayout";
import { GetUserAwardsQuery } from "@tract/common/dist/graphql";

type UserAwardCardProps = {
  award: GetUserAwardsQuery["user_awards"][0]["award"] & { count: number };
};

export const UserAwardCard: React.FC<UserAwardCardProps> = ({ award }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const awardImgUrl = `${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${award.filePath}`;
  return (
    <>
      <UserAwardCardLayout aria-label={award.name} onClick={onOpen}>
        <Box as="span" css={{ imageRendering: "pixelated" }}>
          <img src={awardImgUrl} width="48px" alt="" />
        </Box>
        <Text mt={3} fontSize="lg" fontWeight="bold">
          {award.name} Award
        </Text>
        <Text>
          {award.count} Time{award.count > 1 && "s"}
        </Text>
      </UserAwardCardLayout>
      <UserAwardModal
        title={`${award.name} Award`}
        subTitle={award.description}
        imageURL={awardImgUrl}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
