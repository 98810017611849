import React, { useState } from "react";
import { FormikProps, withFormik } from "formik";
import * as yup from "yup";
import Autosize from "react-textarea-autosize";

import { Box, Button, Textarea, Flex, Avatar, HStack } from "Shared";
import { useSession } from "Services/session";

export type Values = {
  text: string;
  parentPath?: string;
  parentCommentAuthorId?: string;
};

const COMMENT_CHAR_LIMIT = 500;

const Form: React.FC<FormikProps<Values>> = ({
  values,
  status,
  dirty,
  handleSubmit,
  handleChange,
  isSubmitting,
  handleReset,
}) => {
  const { currentUser } = useSession();
  const [showCtas, setShowCtas] = useState(false);
  const onCancel = () => {
    if (!!status.handleCancel) {
      status.handleCancel();
    }
    handleReset();
    setShowCtas(false);
  };
  const isReply = !!values.parentPath;

  return (
    <Flex direction="row" width="full" alignContent="center">
      <Avatar
        src={currentUser?.avatar || ""}
        name={
          !!currentUser?.firstName?.length
            ? `${currentUser?.firstName} ${currentUser?.lastName}`
            : undefined
        }
        aria-hidden="true"
        tabIndex={-1}
        size="md"
        width={12}
        mr={4}
      />
      <Box flex={1} mb={4}>
        <form onSubmit={handleSubmit}>
          <Textarea
            as={Autosize}
            autoFocus={isReply}
            name="text"
            size="lg"
            maxLength={COMMENT_CHAR_LIMIT}
            rows={1}
            maxRows={8}
            resize="none"
            placeholder={isReply ? "Add a reply..." : "Add a public comment..."}
            value={values.text}
            onChange={handleChange}
            onKeyPress={(kp) => {
              // Support CTRL + Enter
              if (
                (kp.ctrlKey || kp.metaKey) &&
                (kp.key === "Enter" || kp.key === "\n") &&
                !isSubmitting
              ) {
                handleSubmit();
              }
            }}
            onFocus={() => {
              setShowCtas(true);
            }}
            onBlur={() => {
              if (values.text.length === 0 && !status.handleCancel) {
                setShowCtas(false);
              }
            }}
          />
          {showCtas && (
            <HStack mt={2} spacing={2} justifyContent="flex-end">
              <Button
                type="reset"
                variant="outline"
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="solid"
                colorScheme="brandFull"
                isLoading={isSubmitting}
                disabled={!dirty || isSubmitting}
              >
                {isReply ? "Reply" : "Post"}
              </Button>
            </HStack>
          )}
        </form>
      </Box>
    </Flex>
  );
};

const schema = yup.object({
  text: yup.string().max(1000).trim().required(),
});

type Props = {
  submitHandler: (values: Values) => Promise<void>;
  errorHandler: (error: any) => void;
  parentPath?: string;
  parentCommentAuthorId?: string;
  onCancel?: () => void;
};

export const CommentForm = withFormik<Props, Values>({
  displayName: "NodeCommentForm",
  mapPropsToValues: (props) => ({
    text: "",
    parentPath: props.parentPath,
    parentCommentAuthorId: props.parentCommentAuthorId,
  }),
  mapPropsToStatus: (props) => ({
    handleCancel: props.onCancel,
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const castedValues = schema.cast(values) || values;

    if (typeof castedValues.text !== "string") return;

    setSubmitting(true);

    try {
      await props.submitHandler({
        text: castedValues.text,
        parentPath: props.parentPath,
      });
      resetForm();
    } catch (err) {
      props.errorHandler(err);
    } finally {
      setSubmitting(false);
    }
  },
})(Form);
