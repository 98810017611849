import React, { FC, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import { Formik, Field, Form, FieldProps, FormikProps } from "formik";

import {
  Text,
  ButtonSolid,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  ButtonOutlined,
  HStack,
  Select,
  ButtonLink,
  InputGroup,
  InputRightElement,
  IconButton,
  IconView,
  IconHide,
  FormHelperText,
} from "Shared";
import { PageHeader } from "Components/PageHeader";
import { AdminContent } from "../AdminContent";
import { FormFieldUsername } from "Pages/SignUp/Students";

import { UserRole, useSession } from "Services/session";
import { captureException } from "Services/errors";

import { generateRandomPassword } from "Utils/random";
import to from "@tract/common/dist/utils/async";
import { post } from "Services/api";
import { firebaseClient } from "Services/firebase";
import { useQuery } from "urql";
import { ALL_ORGANIZATIONS_QUERY } from "./graphql";
import {
  AllOrganizationsQueryResult,
  AllOrganizationsQueryVariables,
} from "@tract/common/dist/graphql";

const createUserSchema = yup.object().shape({
  userRole: yup
    .string()
    .oneOf(Object.values(UserRole), "Invalid role")
    .required("Role is required"),
  orgId: yup
    .string()
    .when("userRole", {
      is: UserRole.Teacher,
      then: yup.string().required("Organization is required"),
      otherwise: yup.string().optional(),
    })
    .max(1000, "Cannot exceed 1000 characters"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(150, "Cannot exceed 150 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(150, "Cannot exceed 150 characters"),
  username: yup
    .string()
    .when("userRole", {
      is: UserRole.Creator,
      then: yup
        .string()
        .required("Username is required")
        .min(4, "At least 4 characters required"),
      otherwise: yup.string().optional(),
    })
    .max(100, "Cannot exceed 100 characters"),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .max(500, "Cannot exceed 500 characters"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(1000, "Cannot exceed 1000 characters")
    .required("Password is required"),
});

type FormProps = {
  userRole: string;
  orgId?: string;
  firstName: string;
  lastName: string;
  username?: string;
  email: string;
  password: string;
};

export const CreateUser: FC = () => {
  const toast = useToast();
  const history = useHistory();
  const { firebaseUser } = useSession();
  const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormikProps<FormProps> | null>(null);

  const onSubmit = async (data: FormProps) => {
    setLoading(true);

    const user: FormProps = {
      userRole: data.userRole,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      username: data.username,
    };

    if (data.userRole === UserRole.Teacher) {
      user.orgId = data.orgId;
    }

    try {
      await createUserSchema.validate(user);

      const emailMethods = await firebaseClient
        .auth()
        .fetchSignInMethodsForEmail(data.email);

      const emailExists = !!emailMethods.length;

      if (emailExists) {
        formRef.current?.setFieldError("email", "The email already exists");
        return;
      }

      const [err, response] = await to(
        post("/v1/users/create", firebaseUser, user)
      );

      if (err) {
        throw err;
      }

      if (!response) {
        throw new Error("No response from /users/create");
      }

      toast({
        title: "User created successfully",
        status: "success",
      });
      history.push(`/admin/users/${response.data.id}`);
    } catch (err: any) {
      toast({
        title: "Error creating the user",
        status: "error",
      });
      captureException(err);
    } finally {
      setLoading(false);
    }
  };

  const [{ data, fetching: loadingOrgs }] = useQuery<
    AllOrganizationsQueryResult["data"],
    AllOrganizationsQueryVariables
  >({
    query: ALL_ORGANIZATIONS_QUERY,
    variables: { offset: 0 },
  });

  const orgs = data?.organization;

  return (
    <AdminContent>
      <PageHeader
        renderTitle={
          <Text fontSize="4xl" fontWeight="bold">
            Create User
          </Text>
        }
        renderActions={
          <HStack>
            <ButtonOutlined
              as={Link}
              size="lg"
              to="/admin/users"
              disabled={loading}
            >
              Cancel
            </ButtonOutlined>
            <ButtonSolid
              size="lg"
              disabled={loading}
              isLoading={loading}
              onClick={() => formRef.current?.submitForm()}
            >
              Create
            </ButtonSolid>
          </HStack>
        }
      />
      <Formik
        initialValues={{
          userRole: UserRole.Teacher,
          orgId: orgs && orgs.length ? orgs[0].id : "",
          firstName: "",
          lastName: "",
          username: "",
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
        validationSchema={createUserSchema}
        validateOnBlur={false}
        innerRef={formRef}
        enableReinitialize
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit} autoComplete="off" noValidate>
            <Field name="userRole">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="userRole">Role</FormLabel>
                  <Select
                    {...field}
                    id="userRole"
                    defaultValue={UserRole.Creator}
                    size="lg"
                  >
                    <option disabled value={UserRole.Admin}>
                      {UserRole.Admin}
                    </option>
                    <option value={UserRole.Creator}>{UserRole.Creator}</option>
                    <option value={UserRole.Teacher}>{UserRole.Teacher}</option>
                    <option disabled value={UserRole.Learner}>
                      {UserRole.Learner}
                    </option>
                  </Select>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {orgs && values.userRole === UserRole.Teacher && (
              <Field name="orgId">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isRequired
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                  >
                    <FormLabel htmlFor="orgId">Organization</FormLabel>
                    <Select
                      {...field}
                      disabled={loadingOrgs}
                      id="orgId"
                      size="lg"
                    >
                      {orgs.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.name} {org.zip && `(${org.zip})`}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText fontSize="sm">
                      Only approved organizations can be selected
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Field name="firstName">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input
                    {...field}
                    id="firstName"
                    size="lg"
                    placeholder="First Name"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="lastName">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input
                    {...field}
                    id="lastName"
                    size="lg"
                    placeholder="Last Name"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {values.userRole === UserRole.Creator && (
              <FormFieldUsername
                id="username"
                placeholder="Username"
                autoComplete="off"
                size="lg"
              />
            )}
            <Field name="email">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    {...field}
                    id="email"
                    size="lg"
                    type="email"
                    placeholder="Email"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="password">
              {({
                field,
                form: { submitCount, setFieldValue },
                meta,
              }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      {...field}
                      id="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label="toggle show password"
                        variant="ghost"
                        onClick={() => setShowPassword((showPwd) => !showPwd)}
                        icon={showPassword ? <IconHide /> : <IconView />}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                  <ButtonLink
                    colorScheme="brandFull"
                    mt={2}
                    onClick={() =>
                      setFieldValue("password", generateRandomPassword())
                    }
                  >
                    Generate a Random Password
                  </ButtonLink>
                </FormControl>
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </AdminContent>
  );
};
