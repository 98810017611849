import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  IconTextDefault,
  IconTextInverted,
  IconType,
  Text,
} from "Shared";

import { ButtonOption } from ".";
import { useVideoEditorContext } from "..";

const colors = {
  black: "black",
  white: "white",
  blue: "blue",
  teal: "teal",
  green: "green",
  yellow: "yellow",
  orange: "orange",
  red: "red",
  pink: "pink",
  purple: "purple",
};

const textStyles = {
  blackBg: "blackBg",
  whiteBg: "whiteBg",
  transparentBg: "transparentBg",
};

type Props = {
  onDelete: () => void;
};

export const TextFx: React.FC<Props> = ({ onDelete }) => {
  const [activeColor, setActiveColor] = useState(colors.black);
  const [activeStyle, setActiveStyle] = useState(textStyles.blackBg);
  const { fabricCanvas } = useVideoEditorContext();

  useEffect(() => {
    if (!fabricCanvas) return;

    if (fabricCanvas.getActiveObject()) {
      const obj = fabricCanvas.getActiveObject();
      setActiveColor(obj.get("fill")?.toString() || "");

      const bgColor = obj.get("backgroundColor") || "";

      switch (bgColor) {
        case "black":
          setActiveStyle(textStyles.blackBg);
          break;
        case "white":
          setActiveStyle(textStyles.whiteBg);
          break;
        case "transparent":
          setActiveStyle(textStyles.transparentBg);
          break;
      }
    }
  }, [fabricCanvas]);

  const handleChangeColor = (color: string) => {
    if (!fabricCanvas) return;
    const obj = fabricCanvas.getActiveObject();
    let fill, backgroundColor, stroke;

    switch (activeStyle) {
      case textStyles.whiteBg:
        backgroundColor = colors.white;
        fill = color;

        if (color === colors.white || color === colors.orange) {
          backgroundColor = colors.black;
        }
        break;
      case textStyles.blackBg:
        backgroundColor = color;
        fill = colors.white;

        if (
          color === colors.white ||
          color === colors.orange ||
          color === colors.yellow
        ) {
          fill = colors.black;
        }
        break;
      case textStyles.transparentBg:
        fill = color;
        stroke = colors.black;

        if (color === colors.black) {
          stroke = colors.white;
        }
        break;
    }

    obj.set({ fill, backgroundColor, stroke });
    setActiveColor(color);
  };

  const handleChangeStyle = (style: string) => {
    if (!fabricCanvas) return;
    const obj = fabricCanvas.getActiveObject();
    let fill = colors.black;
    let backgroundColor = undefined;
    let strokeWidth = 0;
    let stroke = colors.black;

    switch (style) {
      case textStyles.whiteBg:
        fill = colors.black;
        backgroundColor = colors.white;
        setActiveColor(colors.white);
        setActiveStyle(textStyles.whiteBg);
        break;
      case textStyles.blackBg:
        backgroundColor = colors.black;
        fill = colors.white;
        setActiveColor(colors.black);
        setActiveStyle(textStyles.blackBg);
        break;
      default:
        fill = colors.black;
        strokeWidth = 1.5;
        stroke = colors.white;
        setActiveColor(colors.black);
        setActiveStyle(textStyles.transparentBg);
    }

    obj.set({ backgroundColor, fill, strokeWidth, stroke });
  };

  return (
    <Flex flexDir="column" justifyContent="space-between" height="100%">
      <Box mb={4}>
        <Box mb={2}>
          <Text fontWeight="bold">Style</Text>
        </Box>
        <Grid gridTemplateColumns="repeat(3, 1fr)" gap={3}>
          <ButtonOption
            onClick={() => handleChangeStyle(textStyles.whiteBg)}
            selected={activeStyle === textStyles.whiteBg}
            icon={<IconTextDefault />}
          />
          <ButtonOption
            onClick={() => handleChangeStyle(textStyles.blackBg)}
            selected={activeStyle === textStyles.blackBg}
            icon={<IconTextInverted />}
          />
          <ButtonOption
            onClick={() => handleChangeStyle(textStyles.transparentBg)}
            selected={activeStyle === textStyles.transparentBg}
            icon={<IconType />}
          />
        </Grid>
      </Box>
      <Box mb={4}>
        <Box mb={2}>
          <Text fontWeight="bold">Color</Text>
        </Box>
        <Grid gridTemplateColumns="repeat(5, 1fr)" gap={3}>
          {Object.entries(colors).map(([key, color]) => (
            <ButtonOption
              key={key}
              selected={activeColor === color}
              onClick={() => handleChangeColor(color)}
              icon={
                <Box
                  w={6}
                  h={6}
                  borderRadius="full"
                  bgColor={color}
                  borderWidth="2px"
                  borderColor="gray.900"
                />
              }
            />
          ))}
        </Grid>
      </Box>
      <Box flex={1}></Box>
      <Button
        variant="ghost"
        size="lg"
        colorScheme="red"
        w="full"
        onClick={onDelete}
      >
        Delete
      </Button>
    </Flex>
  );
};
