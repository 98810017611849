import React, { PropsWithChildren } from "react";
import { Box, Grid, Text } from "Shared";

type SectionUserAwardsProps = {
  title: string;
};

export const SectionUserAwards: React.FC<
  PropsWithChildren<SectionUserAwardsProps>
> = ({ title, children }) => (
  <Box>
    <Text fontSize="xl" fontWeight="bold" color="gray.900">
      {title}
    </Text>
    <Grid
      templateColumns={{
        base: "repeat(2, 1fr)",
        md: "repeat(3, 1fr)",
        xl: "repeat(4, 1fr)",
      }}
      gap={{ base: 4, md: 6 }}
      mt={4}
      mb={10}
    >
      {children}
    </Grid>
  </Box>
);
