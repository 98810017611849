import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import {
  ButtonSolid,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "Shared";
import { USER_TYPES } from "Constants/userTypes";

const PasswordResetValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Enter a password")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .required("Re-type password")
    .oneOf([Yup.ref("password"), null], "Passwords don't match"),
});

function getEmailPrefix(email) {
  return "@" + email.substring(0, email.indexOf("@"));
}

export function PasswordResetForm({ userType, email, onSubmit, isLoading }) {
  return (
    <>
      <Text as="h1" fontSize="2xl" fontWeight="bold" color="gray.900" mb={4}>
        Reset Password
      </Text>
      {email && (
        <Text as="h1" fontSize="lg" fontWeight="medium" color="gray.600" mb={6}>
          Choose a new password for{" "}
          <Text fontWeight="bold" as="span">
            {userType === USER_TYPES.kid ? getEmailPrefix(email) : email}
          </Text>
        </Text>
      )}
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={PasswordResetValidationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form noValidate>
            <Field name="password">
              {({ field, form: { submitCount }, meta }) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="password">New password</FormLabel>
                  <Input
                    {...field}
                    id="password"
                    type="password"
                    size="lg"
                    placeholder="New password"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, form: { submitCount }, meta }) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="confirmPassword">
                    Confirm password
                  </FormLabel>
                  <Input
                    {...field}
                    id="confirmPassword"
                    type="password"
                    size="lg"
                    placeholder="Confirm password"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <ButtonSolid
              size="lg"
              width="100%"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Reset Password
            </ButtonSolid>
          </Form>
        )}
      </Formik>
    </>
  );
}
