import { gql } from "urql";
import { CLASSROOM_LEARNER_GROUP_FRAGMENT } from "@tract/common/dist/graphql/fragments/classroom-learner-group";

export const CLASSROOMS_BY_LEARNER_ID_QUERY = gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  query ClassroomsByLearnerId($userId: String!) {
    learnerGroups: learner_group(
      where: {
        members: { userId: { _eq: $userId } }
        archivedAt: { _eq: "-infinity" }
      }
      order_by: { createdAt: desc }
    ) {
      ...ClassroomLearnerGroup
    }
  }
`;
