import {
  ClassroomLearnerGroupFragment,
  ClassroomsByTeacherIdQuery,
  ClassroomsByTeacherIdQueryVariables,
} from "@tract/common/dist/graphql";
import { UpdateResolver } from "@urql/exchange-graphcache";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";
import { firebaseClient } from "Services/firebase";

export const api_import_classrooms: UpdateResolver<{
  importClassrooms: [{ learnerGroup: ClassroomLearnerGroupFragment }];
}> = (result, args, cache, info) => {
  const classrooms = result.importClassrooms.map((ic) => ic.learnerGroup);
  const uid = firebaseClient.auth().currentUser?.uid;
  console.log(uid);

  if (!uid) return;

  cache.updateQuery<
    ClassroomsByTeacherIdQuery,
    ClassroomsByTeacherIdQueryVariables
  >(
    {
      query: CLASSROOMS_BY_TEACHER_ID_QUERY,
      variables: {
        teacherUserId: uid,
      },
    },
    (data) => {
      data?.learnerGroups.unshift(...classrooms);
      return data;
    }
  );
};
