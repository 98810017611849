import React, { PropsWithChildren } from "react";
import { FlexProps, forwardRef } from "@chakra-ui/react";
import { Flex, Spinner } from "Shared";

export const LayoutCentered = forwardRef(
  (
    {
      isLoading,
      children,
      ...props
    }: PropsWithChildren<{ isLoading?: boolean } & FlexProps>,
    ref
  ) => {
    return (
      <Flex
        height="100vh"
        width="100%"
        align="center"
        p={6}
        justify="center"
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brandFull.500"
            size="xl"
          />
        ) : (
          children
        )}
      </Flex>
    );
  }
);
