import { useEffect, useState } from "react";
import { useCollection } from "Utils/hooks/firestore";
import { usePathsFromIdArrayQuery } from "@tract/common/dist/graphql";
import {
  PathStatus,
  UserPathHistory,
} from "@tract/common/dist/types/models/Path";

import { captureException } from "Services/errors";
import { gql } from "@apollo/client";

import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

gql`
  ${PATH_CARD_FRAGMENT}

  query PathsFromIdArray(
    $pathIds: [bigint!]
    $status: String!
    $mergeBehavior: String
  ) {
    paths: path(
      offset: 0
      where: { id: { _in: $pathIds }, status: { _eq: $status } }
    ) {
      ...PathCard
    }
  }
`;

export function usePathHistory({
  userId,
  limit = 20,
}: {
  userId: string;
  limit?: number;
}) {
  const [error, setError] = useState<any>();
  const {
    data: history,
    loading: loadingHistory,
    error: errorHistory,
  } = useCollection<UserPathHistory>("user-path-history", {
    limit: limit,
    where: ["userId", "==", userId],
    orderBy: ["lastViewed", "desc"],
    listen: false,
  });

  let pathIds = history?.map((item) => item.pathId) || [];

  const {
    data: pathData,
    loading: loadingPathData,
    error: errorPathData,
  } = usePathsFromIdArrayQuery({
    variables: {
      pathIds: pathIds || [],
      status: PathStatus.Published,
      mergeBehavior: "replace",
    },
    onError: (err) => captureException(err),
    fetchPolicy: "network-only",
  });
  const paths = pathData?.paths?.sort(
    (a, b) => pathIds?.indexOf(a.id || "") - pathIds?.indexOf(b.id || "")
  );
  const isLoading = loadingHistory || loadingPathData;

  useEffect(() => {
    if (!!errorHistory) {
      setError(errorHistory);
      captureException(errorHistory);
    } else if (!!errorPathData) {
      setError(errorPathData);
      captureException(errorPathData);
    }
  }, [errorHistory, errorPathData]);

  return { isLoading, paths, error };
}
