import React from "react";
import {
  Box,
  SkeletonCircle,
  HStack,
  AspectRatio,
  Skeleton,
  VStack,
  Tr,
  Td,
  Flex,
} from "Shared";

type Props = {};

export const LivestreamGallerySkeleton: React.FC<Props> = () => {
  return (
    <Tr width="full">
      <Td
        py={3}
        width="5.75rem"
        borderBottom="none"
        verticalAlign={{ base: "top", md: "center" }}
        textAlign="center"
      >
        <VStack align="center" spacing={3}>
          <Skeleton height={4} w="35%" />
          <Skeleton height={5} w="40%" />
          <Skeleton height={4} w="50%" />
        </VStack>
      </Td>
      <Td py={3} borderBottom="none">
        <Flex direction={{ base: "column", md: "row" }}>
          <Box
            width={{ base: "full", md: "12rem" }}
            minWidth="12rem"
            mb={{ base: 2, md: 0 }}
            mr={{ base: 0, md: 3 }}
          >
            <AspectRatio ratio={16 / 9}>
              <SkeletonCircle borderRadius="xl" width="full" />
            </AspectRatio>
          </Box>
          <Box width="full">
            <Skeleton height={4} maxW="50%" mb={3} />
            <HStack spacing={3}>
              <Box width="2rem" height="2rem">
                <SkeletonCircle size="2rem" />
              </Box>
              <Skeleton height={4} flex={1} maxW="20%" />
            </HStack>
          </Box>
        </Flex>
      </Td>
    </Tr>
  );
};
