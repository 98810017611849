import { createIcon } from "@chakra-ui/react";

export const IconFilledAdmin = createIcon({
  displayName: "IconFilledAdmin",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path d="M19.537 5.83l-6.875-2.864a1.724 1.724 0 00-1.32 0L4.465 5.83c-.64.265-1.06.892-1.06 1.587 0 7.107 4.1 12.02 7.932 13.617.422.176.898.176 1.32 0 3.07-1.278 7.936-5.693 7.936-13.617a1.72 1.72 0 00-1.057-1.587zm-7.533 12.984L12 5.172l6.299 2.624c-.119 5.421-2.94 9.35-6.295 11.018z" />
  ),
});
