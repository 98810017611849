import { gql, useMutation } from "urql";
import { useEffect, useState } from "react";

import { ANALYTICS_EVENTS, GroupKey, useAnalytics } from "./analytics";

import { CLASSROOM_LEARNER_GROUP_FRAGMENT } from "@tract/common/dist/graphql/fragments/classroom-learner-group";
import {
  CreateClassroomMutation,
  CreateClassroomMutationVariables,
} from "@tract/common/dist/graphql";
import { useSession } from "./session";
import axios from "axios";

const API_CREATE_CLASSROOM_MUTATION = gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  mutation CreateClassroom(
    $name: String!
    $orgId: String!
    $userId: String!
    $pathId: bigint
  ) {
    createClassroom: api_create_classroom(
      name: $name
      orgId: $orgId
      userId: $userId
      pathId: $pathId
    ) {
      id
      learnerGroup {
        ...ClassroomLearnerGroup
      }
    }
  }
`;

export const useCreateClassroomMutation = () => {
  const analytics = useAnalytics();
  const { currentUser } = useSession();
  const [, createClassroom] = useMutation<
    CreateClassroomMutation,
    CreateClassroomMutationVariables
  >(API_CREATE_CLASSROOM_MUTATION);

  return async (variables: CreateClassroomMutationVariables) => {
    const result = await createClassroom(variables);
    const { data, error } = result;

    const classId = data?.createClassroom?.learnerGroup?.id;

    if (!classId) {
      throw new Error("no classId found in result");
    }

    if (error) {
      throw error;
    }

    analytics.track(ANALYTICS_EVENTS.CLASS_CREATED, {
      learnerGroupId: classId,
      learnerGroupOwnerId: currentUser.id,
      classId,
      classTitle: data?.createClassroom?.learnerGroup?.name,
      numOfStudents:
        data?.createClassroom?.learnerGroup?.educatorCodes[0].available,
    });

    analytics.group(GroupKey.LearnerGroup, classId, {
      name: data?.createClassroom?.learnerGroup?.name,
      ownerUserId: currentUser.id,
      orgId: currentUser.orgId,
    });

    return result;
  };
};

export const fetchGoogleClassrooms = async (accessToken: string) => {
  const courseData = await axios.get(
    "https://classroom.googleapis.com/v1/courses",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return courseData.data.courses;
};

export const useGoogleEmail = (accessToken?: string | null) => {
  const [googleEmail, setGoogleEmail] = useState<string | null>(null);

  // Fetch email the teacher used to connect google
  useEffect(() => {
    if (!googleEmail && accessToken) {
      axios
        .get("https://www.googleapis.com/oauth2/v2/userinfo", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          setGoogleEmail(res.data.email);
        });
    }
  }, [googleEmail, accessToken]);

  return googleEmail;
};
