import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonLink,
  Center,
  EmptyState,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "Shared";
import { PageHeader } from "Components/PageHeader";
import { AdminContent } from "../AdminContent";
import { useProjectFeedbackQuery } from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { Link } from "react-router-dom";
import { UsernameLink } from "Components/UsernameLink";
import { isParentUser, isTeacherUser } from "Types/User";
import { UserAvatar } from "Components/UserAvatar";

const SUBMISSIONS_LIMIT = 20;
export const AdminSubmissionsFeedback: React.FC = () => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const { data, loading, fetchMore } = useProjectFeedbackQuery({
    variables: {
      limit: SUBMISSIONS_LIMIT,
      offset: 0,
    },
  });

  useEffect(() => {
    const submissionsCount = data?.projects.length || 0;
    if (submissionsCount % SUBMISSIONS_LIMIT > 0) {
      setIsLastPage(true);
    } else if (isLastPage) {
      setIsLastPage(false);
    }
  }, [data?.projects.length, isLastPage]);

  const paginate = async () => {
    if (!data?.projects.length) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          offset: data.projects.length,
        },
      });
    } catch (err) {
    } finally {
      setIsLoadingMore(false);
    }
  };

  return (
    <AdminContent>
      <PageHeader title="Submissions Feedback" />
      {loading ? (
        <LayoutCentered isLoading height="auto" />
      ) : !!data?.projects.length ? (
        <>
          <Box overflowX={{ base: "auto", lg: "visible" }}>
            <Table width="100%">
              <Thead>
                <Tr>
                  <Th>Reason</Th>
                  <Th>Feedback</Th>
                  <Th>Rejected by</Th>
                  <Th>Rejected at</Th>
                  <Th>Submission</Th>
                  <Th>Challenge</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {data?.projects.map((row) => (
                  <Tr key={row.id}>
                    <Td py={2} maxW="100px">
                      {row.rejectedReason}
                    </Td>
                    <Td py={2} maxW="lg">
                      {row.rejectedFeedback}
                    </Td>
                    <Td py={2}>
                      <HStack>
                        {!!row.rejectedByUser && (
                          <>
                            <UserAvatar
                              src={row.rejectedByUser?.avatar || ""}
                              tabIndex={-1}
                              size="sm"
                              isTeacher={isTeacherUser(row.rejectedByUser)}
                              isParent={isParentUser(row.rejectedByUser)}
                              userName={row.rejectedByUser?.username}
                              userId={row.rejectedByUser?.id}
                            />
                            <UsernameLink
                              username={row.rejectedByUser?.username || ""}
                              userId={row.rejectedByUser?.id}
                              isTeacher={isTeacherUser(row.rejectedByUser)}
                              isParent={isParentUser(row.rejectedByUser)}
                            >
                              {!!row.rejectedByUser?.username
                                ? row.rejectedByUser?.username
                                : `${row.rejectedByUser.firstName} ${row.rejectedByUser.lastName}`}
                            </UsernameLink>
                          </>
                        )}
                      </HStack>
                    </Td>
                    <Td py={2}>{new Date(row.rejectedAt).toLocaleString()}</Td>
                    <Td py={2}>
                      <ButtonLink
                        as={Link}
                        to={`/project/${row.id}`}
                        colorScheme="brandFull"
                      >
                        View
                      </ButtonLink>
                    </Td>
                    <Td py={2}>
                      <ButtonLink
                        as={Link}
                        to={`/path/${row.node?.path.slug}/v/${row.node?.id}`}
                        colorScheme="brandFull"
                      >
                        View
                      </ButtonLink>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Center>
            {!isLastPage && (
              <Button
                size="lg"
                variant="outline"
                onClick={paginate}
                mt={10}
                isLoading={isLoadingMore}
              >
                Load More
              </Button>
            )}
          </Center>
        </>
      ) : (
        <EmptyState headline="No Submissions Found" />
      )}
    </AdminContent>
  );
};
