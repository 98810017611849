import { forwardRef } from "@chakra-ui/react";

import {
  FontAwesomeIcon,
  IconButton,
  IconChat,
  IconHelp,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconInfo,
} from "Shared";

import { useIntercom } from "Services/intercom";
import { useSession } from "Services/session";
import { isTeacherUser } from "Types/User";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const IconButtonHelp = forwardRef((props, ref) => {
  const { show } = useIntercom();
  const { currentUser } = useSession();
  const isTeacher = isTeacherUser(currentUser);

  if (isTeacher) {
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          variant="ghost"
          aria-label="Help"
          size="md"
          icon={<IconHelp />}
          ref={ref}
          {...props}
        />
        <MenuList minW="18rem">
          <MenuItem
            icon={<FontAwesomeIcon icon={regular("book-open")} />}
            as={Link}
            to="/resources"
          >
            Teaching Resources
          </MenuItem>
          <MenuItem
            icon={<IconInfo />}
            as="a"
            href="https://help.tract.app/en/collections/2839449-educator-hub"
            target="_blank"
          >
            Help Center
          </MenuItem>
          <MenuItem icon={<IconChat />} onClick={show}>
            Live Support
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <IconButton
      variant="ghost"
      aria-label="Help"
      size="md"
      icon={<IconHelp />}
      ref={ref}
      onClick={show}
      {...props}
    />
  );
});
