import { forwardRef } from "@chakra-ui/react";
import { Avatar, IconSchool } from "Shared";

export const OrgAvatar = forwardRef(({ size = "md", ...props }, ref) => {
  let iconSize;

  switch (size) {
    case "2xl":
    case "xl":
      iconSize = 10;
      break;
    case "lg":
      iconSize = 8;
      break;
    case "md":
      iconSize = 6;
      break;
    case "sm":
      iconSize = 5;
      break;
    default:
      iconSize = 4;
  }

  return (
    <Avatar
      icon={<IconSchool color="gray.600" h={iconSize} w={iconSize} />}
      bgColor="gray.300"
      size={size}
      {...props}
      ref={ref}
    />
  );
});
