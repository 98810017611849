import { Switch, Route, Redirect } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

import { SavedPathProvider } from "Components/ClassroomSavedPaths";
import { useClassContext } from "Components/LayoutClass";

import { ClassroomAssignments } from "./ClassroomAssignments";
import { ClassroomGallery } from "./ClassroomGallery";
import { ClassroomPeople } from "./ClassroomPeople";
import { ClassroomSettings } from "./ClassroomSettings";
import { ClassroomMobileMenu } from "./ClassroomMobileMenu";
import { ClassroomModeration } from "./ClassroomModeration";
import { ClassroomSavedPaths } from "./ClassroomSavedPaths";
import { useSession } from "Services/session";
import { isTeacherUser } from "Types/User";

export const ClassroomContent: React.FC = () => {
  const { isAdmin, currentUser } = useSession();
  const { classData, isClassOwner } = useClassContext();
  const defaultRoute =
    isTeacherUser(currentUser) &&
    !classData?.members?.filter((m) => m.role !== "teacher").length
      ? "people"
      : "assignments";
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  return (
    <Switch>
      <Route path="/class/:classId" exact>
        <ClassroomMobileMenu />
      </Route>
      <Route path="/class/:classId/people">
        <ClassroomPeople classroom={classData} />
      </Route>
      <Route path="/class/:classId/assignments">
        <ClassroomAssignments classroom={classData} />
      </Route>
      <Route path="/class/:classId/gallery">
        <ClassroomGallery />
      </Route>
      <Route path="/class/:classId/saved">
        <SavedPathProvider key="saved" classroomId={classData.id}>
          <ClassroomSavedPaths />
        </SavedPathProvider>
      </Route>
      {(isClassOwner || isAdmin) && (
        <>
          <Route path="/class/:classId/settings">
            <ClassroomSettings classroom={classData} />
          </Route>
          <Route path="/class/:classId/moderation">
            <ClassroomModeration />
          </Route>
        </>
      )}
      {!isMobile && <Redirect to={`/class/${classData.id}/${defaultRoute}`} />}
    </Switch>
  );
};
