import React, { useEffect, useState, useCallback, FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { gql, useQuery } from "urql";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { GridContainer } from "Components/GridContainer";

import {
  Box,
  Button,
  Center,
  EmptyState,
  Flex,
  IconButton,
  IconX,
  Tag,
  TagLabel,
  Text,
  useRouteTabs,
  Tabs,
  TabLink,
  TabList,
  useBreakpointValue,
  HStack,
} from "Shared";

import { ProjectCard } from "../../Components/ProjectCard";
import { GridProjects } from "Components/GridContainer";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useProjectModal } from "Services/projectModal";
import { useSession } from "Services/session";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

import { useOffsetPaginationQuery, useQuery as useParamsQuery } from "Utils";

import {
  GetInviterUserQuery,
  GetInviterUserQueryVariables,
  PathNameQuery,
  PathNameQueryVariables,
  PathNodeNameQuery,
  PathNodeNameQueryVariables,
  ProjectGalleryQuery,
  ProjectGalleryQueryVariables,
} from "@tract/common/dist/graphql";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { ProfileBadges } from "Components/ProfileBadges";
import { FollowButton } from "Pages/Profile/FollowButton";

import { ViewSource } from "Types/Project";
import { isParentUser, isTeacherUser } from "Types/User";
import { PageHeader } from "Components/PageHeader";
import {
  PATH_NAME_QUERY,
  PATH_NODE_NAME_QUERY,
  PROJECT_GALLERY_QUERY,
} from "./graphql";

interface Props {}

const GALLERY_LIMIT = 24;
export const MIN_AWESOME_GALLERY_AWARD_COST = 450;

const enum ProjectGalleryRoutes {
  Awesome = `/explore/projects/awesome`,
  New = `/explore/projects/new`,
}

enum SortByType {
  Awesome = "awesome",
  New = "new",
}

enum FilterType {
  Node = "v",
  Path = "path",
}

type FilterPillProps = {
  label: string;
  title: string;
  onRemove: () => void;
};

const FilterPill: React.FC<FilterPillProps> = ({ label, title, onRemove }) => (
  <Tag
    variant="solid"
    size="xl"
    borderRadius="full"
    h={10}
    px={4}
    mr={2}
    mt={2}
    bg="brandFull.50"
    color="brandFull.500"
    textOverflow="ellipsis"
    maxWidth="340px"
  >
    <TagLabel>
      <Text as="span" textTransform="capitalize" fontWeight="bold">
        {label}:
      </Text>{" "}
      {title}
    </TagLabel>
    <IconButton
      ml={1}
      size="xs"
      bg="transparent"
      _hover={{ bg: "transparent" }}
      borderRadius="full"
      aria-label={`remove ${label} filter`}
      icon={<IconX size={20} />}
      onClick={onRemove}
    />
  </Tag>
);

export const ProjectGallery: React.FC<Props> = () => {
  const history = useHistory();
  const { currentUser } = useSession();
  const amParentOrTeacher =
    isTeacherUser(currentUser) || isParentUser(currentUser);
  const queryParams = useParamsQuery();
  const filters = {
    path: queryParams.get(FilterType.Path),
    node: queryParams.get(FilterType.Node),
  };
  const { handleOpenProjectModal } = useProjectModal();

  const tabRoutes = [
    { path: ProjectGalleryRoutes.Awesome },
    { path: ProjectGalleryRoutes.New },
  ];

  const { tabsProps } = useRouteTabs({
    routes: tabRoutes,
  });

  const defaultSortBy = SortByType.Awesome;

  const [sortBy, setSortBy] = useState<SortByType>(defaultSortBy);
  const { pathname } = useLocation();
  const [viewed, setViewed] = useState(false);

  const deleteQueryParam = useCallback(
    (paramName: FilterType) => {
      queryParams.delete(paramName);

      if (paramName === FilterType.Path) queryParams.delete(FilterType.Node);

      history.push(`?${queryParams.toString()}`);
    },
    [queryParams, history]
  );

  // Remove video filter if path filter is not given
  useEffect(() => {
    if (filters.node && !filters.path) {
      deleteQueryParam(FilterType.Node);
    }
  }, [filters.node, filters.path, deleteQueryParam]);

  const [{ data: pathData }] = useQuery<PathNameQuery, PathNameQueryVariables>({
    query: PATH_NAME_QUERY,
    variables: {
      pathId: Number(filters.path),
    },
    pause: !filters.path,
  });

  const [{ data: nodeData }] = useQuery<
    PathNodeNameQuery,
    PathNodeNameQueryVariables
  >({
    query: PATH_NODE_NAME_QUERY,
    variables: {
      nodeId: filters.node,
    },
    pause: !filters.node,
  });

  const {
    response: [{ data, fetching: loading, error }],
    pagination,
  } = useOffsetPaginationQuery<
    ProjectGalleryQuery,
    ProjectGalleryQueryVariables
  >({
    query: PROJECT_GALLERY_QUERY,
    variables: {
      limit: GALLERY_LIMIT,
      offset: 0,
      where: {
        user: {
          projectPrivacy: { _is_null: true },
        },
        file: { transforms: {} },
        published: { _eq: true },
        rejectedAt: { _eq: "-infinity" },
        isPrivate: { _eq: false },
        ...(sortBy === SortByType.Awesome && {
          awards: {
            award: { cost: { _gte: MIN_AWESOME_GALLERY_AWARD_COST } },
          },
        }),
        // filter by nodeId or pathId if nodeId isn't present
        ...(filters.node
          ? { pathNodeId: { _eq: filters.node } }
          : filters.path && {
              node: {
                path: { id: { _eq: Number(filters.path) } },
              },
            }),
      },
    },
    field: "projects",
  });

  let viewSource = ViewSource.Unknown;

  switch (sortBy) {
    case SortByType.Awesome:
      viewSource = ViewSource.ExploreAllAwesome;
      break;
    case SortByType.New:
      viewSource = ViewSource.ExploreAllNew;
      break;
  }

  const isAnyLoading = loading || pagination.loadingMore;

  const [sentryRef] = useInfiniteScroll({
    hasNextPage: pagination.hasNextPage,
    onLoadMore: pagination.loadMore,
    loading: isAnyLoading,
  });

  const projects = data?.projects;

  const { track } = useAnalytics();
  useEffect(() => {
    if (!pathname?.length) {
      return;
    }
    let newSortBy = SortByType.Awesome;

    switch (pathname) {
      case ProjectGalleryRoutes.Awesome:
        newSortBy = SortByType.Awesome;
        break;
      case ProjectGalleryRoutes.New:
        newSortBy = SortByType.New;
        break;
      default:
        if (amParentOrTeacher) {
          history.replace(ProjectGalleryRoutes.Awesome);
        } else {
          const redirectRoute = !!queryParams.toString().length
            ? `${ProjectGalleryRoutes.New}?${queryParams.toString()}`
            : `${ProjectGalleryRoutes.Awesome}`;
          history.replace(redirectRoute);
        }

        return;
    }

    setSortBy(newSortBy);
    if (!viewed) {
      setViewed(true);
      track(ANALYTICS_EVENTS.PROJECTS_VIEWED, {
        sortedBy: newSortBy,
      });
    }
  }, [pathname, track, history, queryParams, viewed, amParentOrTeacher]);

  const skeletonCount = useBreakpointValue({
    base: 2,
    sm: 4,
    lg: 6,
    "2xl": 8,
    "3xl": 10,
  });

  return (
    <>
      <PageHeader
        title="Project Gallery"
        px={{ base: 4, lg: 10 }}
        renderTabs={
          <Tabs isManual {...tabsProps}>
            <TabList>
              <TabLink
                to={`${ProjectGalleryRoutes.Awesome}?${queryParams.toString()}`}
              >
                Awesome
              </TabLink>
              <TabLink
                to={`${ProjectGalleryRoutes.New}?${queryParams.toString()}`}
              >
                New
              </TabLink>
            </TabList>
          </Tabs>
        }
      />
      <Flex direction="column">
        <GridContainer>
          {(pathData || nodeData) && (filters.path || filters.node) && (
            <Box mb={{ base: 6, xl: 10 }} mt={-2}>
              {filters.path && pathData?.path && (
                <FilterPill
                  label="Path"
                  title={pathData.path.title || ""}
                  onRemove={() => deleteQueryParam(FilterType.Path)}
                />
              )}

              {filters.node && nodeData?.node && (
                <FilterPill
                  label="Video"
                  title={nodeData.node.title || ""}
                  onRemove={() => deleteQueryParam(FilterType.Node)}
                />
              )}
            </Box>
          )}

          {loading && !!skeletonCount && (
            <GridProjects size="md">
              {Array.from({ length: skeletonCount }).map((_, i) => (
                <ProjectCardSkeleton key={i} />
              ))}
            </GridProjects>
          )}

          {(!loading && !projects?.length && !error && (
            <EmptyState
              headline="No projects published yet"
              body="Complete a challenge and you could be the first!"
            />
          )) || (
            <GridProjects size="md">
              {projects?.map((project) => {
                return (
                  <ProjectCard
                    key={project.id}
                    viewSource={viewSource}
                    project={project}
                    handleClickFromParent={(event) => {
                      handleOpenProjectModal(event, project);
                    }}
                  />
                );
              })}
            </GridProjects>
          )}

          {!!projects?.length &&
            projects.length >= GALLERY_LIMIT &&
            pagination.hasNextPage && (
              <Center ref={sentryRef} mt={6} width="full">
                {pagination.loadingMore ? (
                  !!skeletonCount && (
                    <GridProjects size="md" width="full">
                      {Array.from({ length: skeletonCount }).map((_, i) => (
                        <ProjectCardSkeleton key={i} />
                      ))}
                    </GridProjects>
                  )
                ) : (
                  <Button variant="ghost" size="lg" colorScheme="brandFull">
                    Load More
                  </Button>
                )}
              </Center>
            )}
        </GridContainer>
      </Flex>
    </>
  );
};

export const InviterUser: FC<{ onFollow?: () => void }> = ({
  onFollow = () => {},
}) => {
  const { currentUser } = useSession();

  const [{ data }] = useQuery<
    GetInviterUserQuery,
    GetInviterUserQueryVariables
  >({
    query: gql`
      ${USER_PRIVACY_SCOPES_FRAGMENT}

      query GetInviterUser($userId: String!) {
        user_by_pk(firestoreId: $userId) {
          avatar
          username
          userType
          creatorLevel
          isMod
          isEducator
          ...UserPrivacyScopes
        }
      }
    `,
    variables: { userId: currentUser.invitedById || "" },
    pause: !currentUser.invitedById,
  });

  const invitedBy = data?.user_by_pk;

  if (!currentUser.invitedById || !invitedBy) return null;

  return (
    <>
      <UserAvatar
        mt={10}
        size="xl"
        avatarURL={invitedBy.avatar || ""}
        userId={currentUser.invitedById}
        username={invitedBy.username || ""}
      />
      <HStack mt={2}>
        <UsernameLink
          fontSize="lg"
          fontWeight="bold"
          username={invitedBy.username || ""}
        >
          {invitedBy.username}
        </UsernameLink>
        <ProfileBadges user={invitedBy} />
      </HStack>
      <FollowButton
        size="lg"
        mt={3}
        profile={{ ...invitedBy, id: currentUser.invitedById }}
        onFollow={() => setTimeout(onFollow, 3000)}
      />
    </>
  );
};
