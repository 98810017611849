import { useDocument, useCollection } from "Utils/hooks/firestore";
import { db, Timestamp } from "Services/firebase";

type ChecklistItems = { items: ChecklistItem[] };

type CompletedUserChecklistItem = {
  isComplete: boolean;
  completedAt: Timestamp;
};

export type ChecklistItem = {
  id: string;
  title: string;
  description?: string;
  actionType: ChecklistItemActionType;
  actionMeta: Partial<{ url: string; path: string }>;
  timeEstimate: string;
  guideURL?: string;
};

export type UserChecklistItem = ChecklistItem & {
  isComplete: boolean;
  completedAt?: Timestamp;
};

export enum ChecklistItemActionType {
  None = "NONE",
  ExternalLink = "EXTERNAL_LINK",
  InternalLink = "INTERNAL_LINK",
}

export const useTeacherChecklist = (
  uid: string
): {
  items: UserChecklistItem[];
  complete: UserChecklistItem[];
  incomplete: UserChecklistItem[];
  progress: number;
  toggleItem: (item: UserChecklistItem) => void;
} => {
  const { data: checklistData } = useDocument<ChecklistItems>(
    `/enums/teacher-checklist`,
    {
      listen: false,
    }
  );

  const { data: userChecklistItems } =
    useCollection<CompletedUserChecklistItem>(
      `/user-teacher-checklist/${uid}/items`,
      {
        listen: true,
      }
    );

  const items: UserChecklistItem[] = checklistData
    ? checklistData?.items.map((item: ChecklistItem) => {
        const completedItem =
          userChecklistItems &&
          userChecklistItems.find((i) => i.id === item.id);

        if (!!completedItem) {
          return { ...item, ...completedItem };
        }

        return { ...item, isComplete: false };
      })
    : [];

  const complete = items.filter((i) => !!i.isComplete);
  const incomplete = items.filter((i) => !i.isComplete);

  const progress = Math.floor(
    (complete.length / (complete.length + incomplete.length)) * 100
  );

  const toggleItem = (item: UserChecklistItem) => {
    db.doc(`/user-teacher-checklist/${uid}/items/${item.id}`).set(
      {
        isComplete: !item.isComplete,
        ...(!item.isComplete && { completedAt: Timestamp.now() }),
      },
      { merge: true }
    );
  };

  return { items, complete, incomplete, progress, toggleItem };
};
