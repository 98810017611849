import { gql } from "urql";
import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  LearnerGroupSavesForPathQuery,
  LearnerGroupSavesForPathQueryVariables,
  InsertPathCollectionItemMutationResult,
  InsertPathCollectionItemMutationVariables,
} from "@tract/common/dist/graphql";

import { LEARNER_GROUP_SAVES_FOR_PATH } from "Components/ClassroomSavedPaths";

export const insert_path_collection_item_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const { collectionId, pathId } =
    args.object as InsertPathCollectionItemMutationVariables;
  const typedResult = result as InsertPathCollectionItemMutationResult["data"];

  if (!typedResult?.insert_path_collection_item_one) return;

  const fragment = gql`
    fragment PathCollection on path_collection {
      id
      pathCollectionItems {
        collectionId
        pathId
      }
    }
  `;

  const pathCollection = cache.readFragment(fragment, {
    __typename: "path_collection",
    id: collectionId,
  });

  cache.writeFragment(fragment, {
    id: collectionId,
    pathCollectionItems: [
      ...(pathCollection?.pathCollectionItems || []),
      {
        __typename: "path_collection_item",
        collectionId,
        pathId,
      },
    ],
  });

  // Update the query that populates the checkboxes in the save modal
  cache.updateQuery<
    LearnerGroupSavesForPathQuery,
    LearnerGroupSavesForPathQueryVariables
  >(
    {
      query: LEARNER_GROUP_SAVES_FOR_PATH,
      variables: { pathId },
    },
    (data) => {
      const item = typedResult.insert_path_collection_item_one;

      if (item && data) {
        data.path_collection_item.push(item);
      }

      return data;
    }
  );
};
