import { useRef } from "react";
import { Box, HStack, IconButton, IconTrash, Text, VStack } from "Shared";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { getDisplayName } from "Types/User";
import {
  SchoolMemberSearchInput,
  SearchMemberResult,
} from "./SchoolMemberSearchInput";
import { captureException } from "Services/errors";
import { usePathV4Context } from "./LayoutCreateV4";
import { useMutation } from "urql";
import {
  ADD_PATH_CO_AUTHOR_MUTATION,
  REMOVE_PATH_CO_AUTHOR_MUTATION,
} from "./graphql";

export const TeammatesSettings: React.FC<{
  onSaveStart: () => void;
  onSaveComplete: () => void;
  disabled: boolean;
}> = ({ onSaveStart, onSaveComplete, disabled }) => {
  const lastItemRef = useRef<HTMLDivElement>(null);
  const { path } = usePathV4Context();
  const [, addPathCoAuthor] = useMutation(ADD_PATH_CO_AUTHOR_MUTATION);
  const [, removePathCoAuthor] = useMutation(REMOVE_PATH_CO_AUTHOR_MUTATION);
  const displayName = path?.user ? getDisplayName(path.user) : "Unknown";
  const addNewMember = async (member: SearchMemberResult) => {
    try {
      onSaveStart();
      await addPathCoAuthor({
        pathId: path.id,
        userId: member.objectID,
      });
      lastItemRef.current?.scrollIntoView({ block: "start" });
    } catch (error: any) {
      captureException(error);
    }
    onSaveComplete();
  };

  const removeMember = async (userId: string) => {
    onSaveStart();
    await removePathCoAuthor({
      pathId: path.id,
      userId,
    });
    lastItemRef.current?.scrollIntoView({ block: "start" });
    onSaveComplete();
  };
  return (
    <>
      <Box>
        <SchoolMemberSearchInput
          onAddMember={addNewMember}
          coAuthors={path.authors}
          disabled={disabled}
        />
      </Box>
      <VStack width="full" spacing={3}>
        <HStack justifyContent="space-between" width="full">
          <HStack>
            <UserAvatar
              username={path.user?.username || ""}
              userId={path.user?.id}
              avatarURL={path.user?.avatar || ""}
              isTeacher={path?.user?.isEducator}
              name={displayName || ""}
            />
            <UsernameLink
              username={path.user?.username || ""}
              isTeacher={path?.user?.isEducator}
              userId={path.user?.id}
            >
              {displayName}
            </UsernameLink>
          </HStack>
          <HStack>
            <Text color="gray.600">Owner</Text>
            <IconButton
              aria-label="delete owner"
              icon={<IconTrash />}
              variant="ghost"
              size="md"
              isDisabled
            />
          </HStack>
        </HStack>
        {path.authors?.map((coAuthor, i) => (
          <HStack
            key={coAuthor.userId}
            justifyContent="space-between"
            width="full"
            ref={i === path.authors.length - 1 ? lastItemRef : null}
          >
            <HStack>
              <UserAvatar
                userId={coAuthor.user.id}
                username={coAuthor.user.username || ""}
                avatarURL={coAuthor.user.avatar || ""}
                isTeacher={coAuthor.user?.isEducator}
                name={getDisplayName(coAuthor.user)}
              />
              <UsernameLink
                username={coAuthor.user.username || ""}
                isTeacher={coAuthor.user?.isEducator}
                userId={coAuthor.user.id}
              >
                {coAuthor.user.username}
              </UsernameLink>
            </HStack>
            <HStack>
              <Text color="gray.600">Viewer</Text>
              <IconButton
                aria-label="delete owner"
                icon={<IconTrash />}
                variant="ghost"
                size="md"
                onClick={() => removeMember(coAuthor.userId)}
                isDisabled={disabled}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
};
