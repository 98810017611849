import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Redirect, useHistory, useLocation } from "react-router";
import algoliasearch from "algoliasearch";
import {
  InstantSearch,
  Configure,
  useHits,
  useRefinementList,
} from "react-instantsearch-hooks-web";
import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";
import {
  Box,
  Button,
  Center,
  Flex,
  IconX,
  Text,
  useBreakpointValue,
} from "Shared";
import { PATH_INTEREST_AREAS } from "Constants/paths";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { GridContainer, GridPaths, ScrollMenu } from "Components/GridContainer";
import {
  PathFeed,
  PathFeedInfinite,
  PathFeedProvider,
  SearchReset,
} from "./PathFeed";
import { pluralize } from "Utils";

import { ViewSource } from "Types/Path";

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ExploreInterestAreaUnauthed: React.FC = () => {
  const { interestArea: slug } = useParams<{ interestArea: string }>();
  const query = new URLSearchParams(useLocation().search);
  const tagFilter = query.get("tag");

  const limit = useBreakpointValue({
    base: 4,
    sm: 8,
    md: 12,
    xl: 18,
    "2xl": 18,
    "3xl": 18,
  });

  const InterestArea = PATH_INTEREST_AREAS.filter(
    (interestArea) => interestArea.slug === slug
  )[0];

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_FRONTEND_SEARCH_KEY
  );

  const { track } = useAnalytics();
  useEffect(() => {
    track(ANALYTICS_EVENTS.INTEREST_VIEWED, {
      interestArea: InterestArea?.label,
      interestAreaSlug: slug,
    });
  }, [track, InterestArea?.label, slug]);

  if (!InterestArea) {
    return <Redirect to="/" />;
  }
  const { label, value } = InterestArea;

  return (
    <>
      <Box py={{ base: 6, lg: 10 }} width="100%">
        <GridContainer pb={0}>
          <Flex direction="row" justifyContent="space-between" align="center">
            <Text
              fontSize={{ base: "4xl", lg: "5xl" }}
              fontWeight="bold"
              color="inherit"
            >
              {label}
            </Text>
          </Flex>
          <InstantSearch searchClient={searchClient} indexName="paths">
            <Configure
              filters={`visibility:${PathVisibility.Public} AND status:${
                PathStatus.Published
              } AND interestArea:"${value}" AND _schemaVersion = ${3} AND isVisibleUnauthed:true`}
            />
            <SearchResults />
          </InstantSearch>
        </GridContainer>
      </Box>
      <ScrollMenu py={6}>
        <Center mr={2} whiteSpace="nowrap" minH="2.5rem">
          <Text color="gray.600">Top Tags:</Text>
        </Center>
        <InstantSearch searchClient={searchClient} indexName="paths">
          <Configure
            filters={`visibility:${PathVisibility.Public} AND status:${
              PathStatus.Published
            } AND interestArea:"${value}" AND _schemaVersion = ${3} AND isVisibleUnauthed:true`}
          />
          <RefinementList />
        </InstantSearch>
      </ScrollMenu>
      <GridContainer>
        <PathFeedProvider
          limit={limit}
          interestAreas={[value]}
          isVisibleUnauthed={true}
          tags={tagFilter?.length ? [tagFilter] : undefined}
        >
          <GridPaths
            templateColumns={["1fr", null, "repeat(2, 1fr)", "repeat(3, 1fr)"]}
          >
            <SearchReset />
            <PathFeed source={ViewSource.ExploreInterestAreaUnauth} />
          </GridPaths>
          <PathFeedInfinite />
        </PathFeedProvider>
      </GridContainer>
    </>
  );
};

const TAG_LIMIT = 8;

const RefinementList: React.FC = () => {
  const { items } = useRefinementList({ attribute: "tags" });
  const query = useUrlQuery();
  const history = useHistory();
  const tagFilter = query.get("tag");
  const slicedItems = items.slice(0, TAG_LIMIT);

  const onClickFilter = (name: string, value: string) => {
    if (query.get(name) === value) {
      query.delete(name);
    } else {
      if (query.has(name)) {
        query.delete(name);
      }
      query.append(name, value);
    }

    history.push(`?${query.toString()}`);
  };

  return (
    <>
      {slicedItems.map((item) => (
        <Button
          key={item.label}
          variant={tagFilter === item.label ? "solid" : "outline"}
          onClick={() => onClickFilter("tag", item.label)}
          rightIcon={tagFilter === item.label ? <IconX size={20} /> : undefined}
        >
          #{item.label}
        </Button>
      ))}
    </>
  );
};

const SearchResults: React.FC = () => {
  const { results } = useHits();

  if (!results) return null;

  return (
    <Text fontSize="lg" color="inherit">
      {pluralize(results.nbHits, "Path")}
    </Text>
  );
};
