import { useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Tabs, TabList, TabLink } from "Shared";

import { PageContent } from "Components/PageContent";
import { PageHeader } from "Components/PageHeader";
import { MyReviewsTable } from "./MyReviewsTable";
import { CreatorLevel } from "Types/User";
import { CreatorLevelFilterMenu } from "Pages/AdminDashboard/AdminPaths";
import { NeedsReviewTable } from "./NeedsReviewTable";

export function PathReviews() {
  const [creatorLevels, setCreatorLevels] = useState<string | string[]>([
    CreatorLevel.Partner,
    CreatorLevel.Base,
    CreatorLevel.Creator,
    CreatorLevel.Affiliate,
  ]);
  const TAB_INDEXES: { [pathname: string]: number } = {
    "/author/peer-reviews/needs-review": 0,
    "/author/peer-reviews/my-reviews": 1,
  };
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(TAB_INDEXES[pathname]);

  return (
    <PageContent pb={32}>
      <Tabs isManual index={tabIndex} onChange={setTabIndex}>
        <PageHeader
          title="Peer Reviews"
          renderActions={
            <CreatorLevelFilterMenu
              defaultValue={creatorLevels}
              onChange={setCreatorLevels}
            />
          }
          renderTabs={
            <TabList>
              <TabLink to="/author/peer-reviews/needs-review">
                Needs Review
              </TabLink>
              <TabLink to="/author/peer-reviews/my-reviews">My Reviews</TabLink>
            </TabList>
          }
        />
        <Switch>
          <Redirect
            path="/author/peer-reviews"
            exact
            to="/author/peer-reviews/needs-review"
          />
          <Route path="/author/peer-reviews/needs-review">
            <NeedsReviewTable
              thumbnailSize="10rem"
              showHeaderAndBorders={false}
              creatorLevels={creatorLevels}
            />
          </Route>
          <Route path="/author/peer-reviews/my-reviews">
            <MyReviewsTable creatorLevels={creatorLevels} />
          </Route>
        </Switch>
      </Tabs>
    </PageContent>
  );
}
