import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

import { getFirestore } from "firebase/firestore";
export { FieldValue, FieldPath, Timestamp } from "firebase/firestore";

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
export const firebaseClient = firebase.initializeApp(firebaseConfig);
export const db = firebaseClient.firestore();
export const dbV9 = getFirestore(firebaseClient);
export const functions = firebaseClient.functions();

export const storage = (bucket: "default" | "media" | "ugc" = "default") => {
  if (bucket === "media") {
    return firebaseClient.storage(process.env.REACT_APP_MEDIA_STORAGE_BUCKET);
  }

  if (bucket === "ugc") {
    return firebaseClient.storage(process.env.REACT_APP_UGC_STORAGE_BUCKET);
  }

  return firebaseClient.storage();
};
