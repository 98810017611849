import { FC, PropsWithChildren } from "react";
import { ButtonLink, Flex, FormLabel } from "Shared";

type Props = {
  showClearAction?: boolean;
  onClickClear: () => void;
};

export const FormLabelWithClearAction: FC<PropsWithChildren<Props>> = ({
  showClearAction = true,
  onClickClear,
  children,
}) => (
  <Flex justifyContent="space-between" alignItems="baseline">
    <FormLabel>{children}</FormLabel>
    {showClearAction && (
      <ButtonLink colorScheme="brandFull" onClick={onClickClear}>
        Clear
      </ButtonLink>
    )}
  </Flex>
);
