import { FC } from "react";
import { Switch, Route, Redirect } from "react-router";

import { Tabs, TabList, TabLink, useRouteTabs } from "Shared";

import { PageHeader } from "Components/PageHeader";
import { GridContainer } from "Components/GridContainer";

import { FollowingProjects } from "./FollowingProjects";
import { UsersFollowingPathFeed } from "Pages/Explore/UsersFollowingPathFeed";

import { ViewSource } from "Types/Path";

export const Following: FC = () => {
  const { tabsProps } = useRouteTabs({
    routes: [{ path: `/following/projects` }, { path: `/following/paths` }],
  });

  return (
    <Tabs {...tabsProps} isManual>
      <GridContainer>
        <PageHeader
          title="Following"
          renderTabs={
            <TabList>
              <TabLink to={`/following/projects`}>Projects</TabLink>
              <TabLink to={`/following/paths`}>Paths</TabLink>
            </TabList>
          }
        />
        <Switch>
          <Route path={`/following/projects`}>
            <FollowingProjects />
          </Route>
          <Route path={`/following/paths`}>
            <UsersFollowingPathFeed viewSource={ViewSource.FollowingPaths} />
          </Route>
          <Redirect to={`/following/projects`} />
        </Switch>
      </GridContainer>
    </Tabs>
  );
};
