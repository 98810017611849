import React from "react";
import { IconButton, forwardRef, IconButtonProps } from "@chakra-ui/react";

export { IconButton } from "@chakra-ui/react";

export const IconButtonSolid = forwardRef(({ ariaLabel, ...props }, ref) => {
  return (
    <IconButton
      variant="solid"
      colorScheme="brandFull"
      aria-label={ariaLabel}
      borderRadius="full"
      ref={ref}
      {...props}
    />
  );
});

export const IconButtonOutlined = forwardRef(
  ({ variant, ...props }: IconButtonProps, ref) => {
    return (
      <IconButton variant="outline" borderRadius="full" ref={ref} {...props} />
    );
  }
);
