import { createIcon } from "@chakra-ui/react";

export const IconFilledHeart = createIcon({
  displayName: "IconFilledHeart",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path d="M22.15 3.857A6.336 6.336 0 0 1 24 8.337a6.352 6.352 0 0 1-1.85 4.48L12 23 1.85 12.818a6.347 6.347 0 0 1 0-8.961 6.306 6.306 0 0 1 8.933 0L12 5.077l1.217-1.22A6.314 6.314 0 0 1 17.683 2a6.297 6.297 0 0 1 4.466 1.857Z" />
  ),
});
