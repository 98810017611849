import React from "react";

import { PageContent } from "Components/PageContent";
import { PageHeader } from "Components/PageHeader";
import { LivestreamGalleryTable } from "./LivestreamGalleryTable";

interface Props {}

export const LivestreamGallery: React.FC<Props> = () => {
  return (
    <PageContent maxWidth="60rem" pb={32}>
      <PageHeader title="Events" />
      <LivestreamGalleryTable />
    </PageContent>
  );
};
