import { UpdatesConfig } from "@urql/exchange-graphcache";

import { api_disable_user } from "./mutation/api_disable_user";
import { api_delete_user } from "./mutation/api_delete_user";
import { api_update_learner_group_settings } from "./mutation/api_update_learner_group_settings";
import { api_create_classroom } from "./mutation/api_create_classroom";
import { api_import_classrooms } from "./mutation/api_import_classrooms";
import { api_update_privacy } from "./mutation/api_update_privacy";
import { delete_educator_code_by_pk } from "./mutation/delete_educator_code_by_pk";
import { delete_learner_group_member } from "./mutation/delete_learner_group_member";
import { delete_learner_group_member_by_pk } from "./mutation/delete_learner_group_member_by_pk";
import { delete_learner_group_pending_member_by_pk } from "./mutation/delete_learner_group_pending_member_by_pk";
import { delete_user_follow_by_pk } from "./mutation/delete_user_follow_by_pk";
import { insert_path_collection_one } from "./mutation/insert_path_collection_one";
import { delete_path_collection_by_pk } from "./mutation/delete_path_collection_by_pk";
import { update_path_collection_by_pk } from "./mutation/update_path_collection_by_pk";
import { update_learner_group_by_pk } from "./mutation/update_learner_group_by_pk";
import { insert_educator_code_one } from "./mutation/insert_educator_code_one";
import { insert_learner_group_member_one } from "./mutation/insert_learner_group_member_one";
import { insert_learner_group_one } from "./mutation/insert_learner_group_one";
import { insert_livestream_one } from "./mutation/insert_livestream_one";
import { insert_user_follow_one } from "./mutation/insert_user_follow_one";
import { update_livestream_by_pk } from "./mutation/update_livestream_by_pk";
import { insert_path_node_one } from "./mutation/insert_path_node_one";
import { delete_path_node_by_pk } from "./mutation/delete_path_node_by_pk";
import { insert_path_author_one } from "./mutation/insert_path_author_one";
import { delete_path_author } from "./mutation/delete_path_author";
import { insert_path_feed_one } from "./mutation/insert_path_feed_one";
import { delete_path_feed_by_pk } from "./mutation/delete_path_feed_by_pk";
import { delete_path_collection_item_by_pk } from "./mutation/delete_path_collection_item_by_pk";
import { insert_path_collection_item_one } from "./mutation/insert_path_collection_item_one";
import { insert_learner_group_path_collection_one } from "./mutation/insert_learner_group_path_collection_one";
import { insert_guide_path_one } from "./mutation/insert_guide_path_one";
import { insert_path_one } from "./mutation/insert_path_one";
import { insert_path_node_comment_one } from "./mutation/insert_path_node_comment_one";
import { insert_project_comment_one } from "./mutation/insert_project_comment_one";
import { update_comment_by_pk } from "./mutation/update_comment_by_pk";
import { insert_project_award_one } from "./mutation/insert_project_award_one";

export const updates: Partial<UpdatesConfig> = {
  Mutation: {
    api_delete_user,
    api_disable_user,
    api_update_learner_group_settings,
    api_update_privacy,
    api_create_classroom,
    api_import_classrooms,
    delete_educator_code_by_pk,
    delete_learner_group_member,
    delete_learner_group_member_by_pk,
    delete_learner_group_pending_member_by_pk,
    delete_user_follow_by_pk,
    insert_path_collection_one,
    delete_path_collection_by_pk,
    update_path_collection_by_pk,
    update_learner_group_by_pk,
    insert_educator_code_one,
    insert_learner_group_member_one,
    insert_learner_group_one,
    insert_livestream_one,
    insert_user_follow_one,
    update_livestream_by_pk,
    insert_path_node_one,
    delete_path_node_by_pk,
    insert_path_author_one,
    delete_path_author,
    insert_path_feed_one,
    delete_path_feed_by_pk,
    delete_path_collection_item_by_pk,
    insert_path_collection_item_one,
    insert_learner_group_path_collection_one,
    insert_path_one,
    insert_guide_path_one,
    insert_path_node_comment_one,
    insert_project_comment_one,
    update_comment_by_pk,
    insert_project_award_one,
  },
};
