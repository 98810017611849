import React from "react";
import { forwardRef } from "@chakra-ui/react";

import { Box } from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";

export const PageContent = forwardRef(
  ({ isLoading, children, ...props }, ref) => {
    if (isLoading) {
      return <LayoutCentered isLoading={true} />;
    }

    return (
      <Box
        width="100%"
        maxWidth="80rem"
        mx="auto"
        px={{ base: 4, lg: 10 }}
        {...props}
        ref={ref}
      >
        {children}
      </Box>
    );
  }
);
