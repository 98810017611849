import { gql } from "urql";
import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";

export const GET_PROJECT_DETAILS = gql`
  ${PROJECT_CARD_FRAGMENT}

  query GetProjectDetails($id: uuid!) {
    project: project_by_pk(id: $id) {
      ...projectCard
    }
  }
`;
