import { useRef, useState } from "react";
import { Field, FieldProps, useFormikContext } from "formik";

import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "Shared";

export const CreateClassCheckboxField: React.FC = () => {
  const [isChecked, setChecked] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useFormikContext();

  const handleOnChange = () => {
    setChecked(!isChecked);

    if (!isChecked) {
      form.setFieldValue("newClassroom", { name: "" });
    } else {
      form.setFieldValue("newClassroom", undefined);
    }
  };

  return (
    <Field name="newClassroom.name">
      {({ field, meta }: FieldProps) => (
        <>
          <Checkbox size="lg" onChange={handleOnChange} isChecked={isChecked}>
            {field.value ? field.value : "Create a New Class"}
          </Checkbox>
          {isChecked && (
            <FormControl isRequired isInvalid={!!meta.error && meta.touched}>
              <FormLabel>Name</FormLabel>
              <InputGroup>
                <Input {...field} ref={inputRef} />
              </InputGroup>
              <FormErrorMessage>{meta.error}</FormErrorMessage>
            </FormControl>
          )}
        </>
      )}
    </Field>
  );
};
