import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import { PathVisibility } from "@tract/common/dist/types/models/Path";

import { Box, Text, useBreakpointValue, HStack } from "Shared";
import { GridContainer, GridPaths } from "Components/GridContainer";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import {
  PathFeed,
  PathFeedInfinite,
  PathFeedProvider,
  SearchReset,
} from "./PathFeed";
import { OrgAvatar } from "Components/OrgAvatar";
import { ViewSource } from "Types/Path";
import { gql, useQuery } from "urql";
import { LayoutCentered } from "Components/LayoutCentered";
import {
  ExploreOrganizationQueryResult,
  ExploreOrganizationQueryVariables,
} from "@tract/common/dist/graphql";

const EXPLORE_ORGANIZATION_QUERY = gql`
  query ExploreOrganization($orgId: String!) {
    organization_by_pk(firestoreId: $orgId) {
      id: firestoreId
      avatar
      name
    }
  }
`;

export const ExploreOrganization: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { currentUser, isAdmin } = useSession();

  const [{ data, fetching }] = useQuery<
    ExploreOrganizationQueryResult["data"],
    ExploreOrganizationQueryVariables
  >({
    pause: !isAdmin,
    query: EXPLORE_ORGANIZATION_QUERY,
    variables: {
      orgId: orgId,
    },
  });

  const organization = data?.organization_by_pk || currentUser.organization;

  const limit = useBreakpointValue({
    base: 4,
    sm: 8,
    md: 12,
    xl: 16,
    "2xl": 15,
    "3xl": 18,
  });

  const { track } = useAnalytics();
  useEffect(() => {
    if (!!currentUser.organization) {
      track(ANALYTICS_EVENTS.ORGANIZATION_VIEWED, {
        orgId,
        orgName: currentUser.organization.name,
      });
    }
  }, [track, currentUser, orgId]);

  if (fetching) {
    return <LayoutCentered isLoading auto="auto" />;
  }

  if (!organization) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Box pt={8} pb={10} width="100%">
        <GridContainer pb={0}>
          <HStack spacing="6">
            <OrgAvatar src={organization.avatar} size="xl" />
            <Text fontSize="4xl" fontWeight="bold">
              {organization.name}
            </Text>
          </HStack>
        </GridContainer>
      </Box>
      <GridContainer>
        {limit && (
          <PathFeedProvider
            limit={limit}
            orgId={orgId}
            visibility={PathVisibility.OrgOnly}
          >
            <GridPaths>
              <SearchReset />
              <PathFeed source={ViewSource.ExploreOrganization} />
            </GridPaths>
            <PathFeedInfinite />
          </PathFeedProvider>
        )}
      </GridContainer>
    </>
  );
};
