import { gql } from "urql";

import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

export const COLLECTION_QUERY = gql`
  ${PATH_CARD_FRAGMENT}

  query CollectionById($id: uuid = "") {
    path_collection_by_pk(id: $id) {
      id
      title
      description
      user {
        id: firestoreId
        firstName
        username
        isEducator
        lastName
        avatar
      }
      pathCollectionItems(order_by: { lexorank: asc }) {
        collectionId
        pathId
        lexorank
        path {
          ...PathCard
        }
      }
    }
  }
`;

export const COLLECTION_UPDATE_MUTATION = gql`
  mutation UpdatePathCollection(
    $description: String = ""
    $title: String!
    $userId: String!
    $id: uuid!
  ) {
    update_path_collection_by_pk(
      _set: { description: $description, title: $title, userId: $userId }
      pk_columns: { id: $id }
    ) {
      description
      title
      userId
    }
  }
`;

export const COLLECTION_ITEM_UPDATE_MUTATION = gql`
  ${PATH_CARD_FRAGMENT}

  mutation UpdatePathCollectionItem(
    $lexorank: String!
    $collectionId: uuid!
    $pathId: bigint!
  ) {
    update_path_collection_item_by_pk(
      _set: { lexorank: $lexorank }
      pk_columns: { collectionId: $collectionId, pathId: $pathId }
    ) {
      collectionId
      pathId
      lexorank
      path {
        ...PathCard
      }
    }
  }
`;

export const COLLECTION_INSERT_MUTATION = gql`
  ${PATH_CARD_FRAGMENT}

  mutation InsertPathCollection(
    $title: String!
    $description: String
    $userId: String!
    $pathId: bigint!
  ) {
    insert_path_collection_one(
      object: {
        title: $title
        description: $description
        userId: $userId
        pathCollectionItems: { data: { pathId: $pathId } }
      }
    ) {
      id
      title
      description
      user {
        id: firestoreId
        firstName
        username
        isEducator
        lastName
        avatar
      }
      pathCollectionItems {
        collectionId
        pathId
        lexorank
        path {
          ...PathCard
        }
      }
    }
  }
`;

export const COLLECTION_DELETE_MUTATION = gql`
  mutation DeletePathCollection($collectionId: uuid!) {
    delete_path_collection_by_pk(id: $collectionId) {
      id
    }
  }
`;
