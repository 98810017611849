import firebase from "firebase/compat";

export type Livestream = {
  isLive: boolean;
  bannedUsers?: string[];
  startTime?: firebase.firestore.Timestamp;
};

export enum ViewSource {
  LivestreamGallery = "livestreamGallery",
  ExploreBanner = "exploreBanner",
  AdminPage = "adminPage",
  Home = "home",
  UserProfile = "userProfile",
}
