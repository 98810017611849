import React from "react";
import { Box, SkeletonCircle, Skeleton, HStack, AspectRatio } from "Shared";

type Props = {};

export const ClassroomCardSkeleton: React.FC<Props> = () => {
  return (
    <Box
      border="1px solid"
      borderColor="gray.300"
      borderRadius="2xl"
      overflow="hidden"
    >
      <AspectRatio ratio={5 / 2}>
        <Skeleton borderTopRadius="xl" width="full" />
      </AspectRatio>
      <Box px={6} py={5}>
        <Skeleton height={3} flex={1} mt={2} mb={4} maxW="45%" />
        <HStack>
          <SkeletonCircle boxSize={8} />
          <Skeleton height={2} my={1} flex={1} maxW="35%" />
        </HStack>
      </Box>
    </Box>
  );
};
