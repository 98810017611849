import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Box,
  ButtonSolid,
  Center,
  EmptyStateFontAwesomeIcon,
  FormControl,
  Grid,
  Input,
  Select,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "Shared";

import { CommentStatus } from "@tract/common/dist/types/models/Comment";
import { FormLabelWithClearAction } from "Components/FormLabelWithClearAction";
import { LayoutCentered } from "Components/LayoutCentered";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSession } from "Services/session";
import { EmptyState, useToast } from "Shared";
import { isParentUser, isTeacherUser } from "Types/User";
import { useOffsetPaginationQuery, useQuery as useParams } from "Utils";
import { CommentRowV2 } from "./CommentRowV2";
import {
  AdminCommentsQuery,
  AdminCommentsQueryVariables,
  AllLearnerGroupLearnersQueryResult,
  AllLearnerGroupLearnersQueryVariables,
  CommentsLearnerGroupMemberFragment,
  Comment_Bool_Exp,
  UsernameSearchQueryResult,
  UsernameSearchQueryVariables,
} from "@tract/common/dist/graphql";
import { gql, useClient, useQuery } from "urql";
import { USERNAME_SEARCH_QUERY } from "../AdminSubmissions/graphql";
import { ADMIN_COMMENTS_QUERY } from "./graphql";
import { useInterval } from "@tract/common/dist/hooks";

const COMMENTS_LIMIT = 25;

const COMMENTS_LEARNER_GROUP_MEMBER_FRAGMENT = gql`
  fragment CommentsLearnerGroupMember on learner_group_member {
    learnerGroupId
    userId
    user {
      id: firestoreId
      username
      firstName
      lastName
    }
  }
`;

const ALL_LEARNER_GROUP_LEARNERS_QUERY = gql`
  ${COMMENTS_LEARNER_GROUP_MEMBER_FRAGMENT}

  query AllLearnerGroupLearners(
    $teacherUserId: String!
    $learnerGroupId: uuid
  ) {
    learner_group(
      where: {
        members: { userId: { _eq: $teacherUserId } }
        id: { _eq: $learnerGroupId }
      }
    ) {
      members(where: { role: { _eq: "learner" } }) {
        ...CommentsLearnerGroupMember
      }
    }
  }
`;

type Props = {
  learnerGroupId?: string;
};

export const AdminCommentsV2: React.FC<Props> = ({ learnerGroupId }) => {
  const urql = useClient();
  const query = useParams();
  const { currentUser, isAdmin } = useSession();
  const toast = useToast();
  const history = useHistory();
  const { pathname } = useLocation();

  const isParentOrTeacher =
    isParentUser(currentUser) || isTeacherUser(currentUser);

  const filters = {
    learnerGroupId: learnerGroupId
      ? learnerGroupId
      : query.get("learnerGroupId"),
    userId: query.get("uid"),
  };

  const statusParam = query.get("status");

  const [learnerIdValue, setLearnerIdValue] = useState<string>(
    filters.userId || ""
  );
  const [learnerGroupIdValue] = useState<string>(filters.learnerGroupId || "");
  const [usernameSearchValue, setUsernameSearchValue] = useState<string>("");
  const [statusSearchValue, setStatusSearchValue] = useState<string>(
    query.get("status") || ""
  );

  const [{ data: groupMembersResult, fetching: loadingGroupMembers }] =
    useQuery<
      AllLearnerGroupLearnersQueryResult["data"],
      AllLearnerGroupLearnersQueryVariables
    >({
      pause: isAdmin,
      query: ALL_LEARNER_GROUP_LEARNERS_QUERY,
      variables: {
        teacherUserId: currentUser.id,
        ...(learnerGroupIdValue ? { learnerGroupId: learnerGroupIdValue } : {}),
      },
    });

  const filteredKidIds = useMemo(() => {
    if (learnerIdValue) {
      return [learnerIdValue];
    }

    let kidIds: string[] = [];

    groupMembersResult?.learner_group.forEach((group) => {
      group.members.forEach((member) => {
        kidIds.push(member.userId);
      });
    });

    return kidIds;
  }, [groupMembersResult, learnerIdValue]);

  const kids: CommentsLearnerGroupMemberFragment["user"][] = [];

  groupMembersResult?.learner_group.forEach(({ members }) =>
    members.forEach(({ user }) => kids.push(user))
  );

  let where: Comment_Bool_Exp = {};

  // NOTE: this logic is also found in in CommentsRowV2.tsx
  if (statusSearchValue === CommentStatus.NeedsReview) {
    where.published = { _eq: false }; // not published yet
    where.deletedAt = { _eq: "-infinity" }; // and not manually deleted
    where.text = { _regex: ".+" }; // and is not an award or GIF
  } else if (statusSearchValue === CommentStatus.Accepted) {
    where.deletedAt = { _eq: "-infinity" };
    where._or = [
      { published: { _eq: true } },
      { text: { _regex: "^$" } }, // award
      { text: { _is_null: true } }, // GIF
    ];
  } else if (statusSearchValue === CommentStatus.Rejected) {
    where.deletedAt = { _neq: "-infinity" };
  }

  if (isParentOrTeacher) {
    where = {
      ...where,
      user: { firestoreId: { _in: filteredKidIds } },
    };
  }

  if (isAdmin) {
    where = {
      ...where,
      ...{ user: { isAdmin: { _eq: false } } },
      ...(learnerIdValue && { user: { firestoreId: { _eq: learnerIdValue } } }),
    };
  }

  const {
    response: [{ data, fetching: loadingComments }, refetch],
    pagination,
  } = useOffsetPaginationQuery<AdminCommentsQuery, AdminCommentsQueryVariables>(
    {
      query: ADMIN_COMMENTS_QUERY,
      variables: {
        where: where,
        limit: COMMENTS_LIMIT,
        offset: 0,
      },
      field: "comments",
    }
  );

  const comments = data?.comments;
  const loading =
    loadingGroupMembers || loadingComments || pagination.loadingMore;

  const [interval, setInterval] = useState<number | null>(null);
  useInterval(() => {
    refetch({ requestPolicy: "network-only" });
  }, interval);

  useEffect(() => {
    setInterval(isAdmin ? 30000 : null);
  }, [refetch, isAdmin]);

  //set default field values in this effect
  useEffect(() => {
    async function setUsernameFilterByUid(uid: string) {
      if (isParentOrTeacher) {
        setLearnerIdValue(uid);
        return;
      }
    }
    //update username field
    if (filters.userId) setUsernameFilterByUid(filters.userId);
    else setUsernameSearchValue("");
  }, [filters.userId, isParentOrTeacher]);

  //keep learnerId field in sync with url
  useEffect(() => {
    setLearnerIdValue(filters.userId || "");
  }, [filters.userId]);

  //keep status field in sync with url
  useEffect(() => {
    setStatusSearchValue(statusParam || "");
  }, [statusParam]);

  //handles username search
  const handleUsernameSubmit = async (e: any) => {
    e.preventDefault();
    if (usernameSearchValue.length < 1) {
      query.delete("uid");
    } else {
      const { data } = await urql
        .query<UsernameSearchQueryResult["data"], UsernameSearchQueryVariables>(
          USERNAME_SEARCH_QUERY,
          {
            username: usernameSearchValue,
          }
        )
        .toPromise();

      const users = data?.user;

      if (users && users.length > 0) {
        query.set("uid", users[0].id);
      } else {
        query.delete("uid");

        toast({
          status: "error",
          title: "User not found",
        });
      }
    }
    pagination.resetOffset();
    history.push(`${pathname}?${query.toString()}`);
  };

  //build query string and sets page to 0
  const handleFilterChange = (
    { target: { value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: string
  ) => {
    value.length ? query.set(key, value) : query.delete(key);
    pagination.resetOffset();
    history.push(`${pathname}?${query.toString()}`);
  };

  const handleUsernameFilterChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setUsernameSearchValue(value);
  };

  const onUsernameClick = ({
    username,
    userId,
  }: {
    username: string;
    userId: string;
  }) => {
    setUsernameSearchValue(username);
    setLearnerIdValue(userId);
    pagination.resetOffset();
    query.set("uid", userId);
    history.push(`${pathname}?${query.toString()}`);
  };

  const handleClickClearFilter = (filterName: string) => {
    query.delete(filterName);
    history.push(`?${query.toString()}`);
  };

  return (
    <Box>
      <Grid
        templateColumns={{
          base: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
        gap={3}
        mb={10}
      >
        {isAdmin ? (
          <form onSubmit={handleUsernameSubmit}>
            <FormControl>
              <FormLabelWithClearAction
                showClearAction={!!filters.userId}
                onClickClear={() => handleClickClearFilter("uid")}
              >
                Username
              </FormLabelWithClearAction>
              <Input
                variant="filled"
                name="username"
                onChange={handleUsernameFilterChange}
                value={usernameSearchValue}
              />
            </FormControl>
          </form>
        ) : (
          <FormControl>
            <FormLabelWithClearAction
              showClearAction={!!filters.userId}
              onClickClear={() => handleClickClearFilter("uid")}
            >
              Learner
            </FormLabelWithClearAction>
            <Select
              name="learner"
              variant="filled"
              value={learnerIdValue}
              onChange={(e) => {
                setLearnerIdValue(e.target.value);
                handleFilterChange(e, "uid");
              }}
            >
              <option value="">All</option>

              {kids?.map((kid) => (
                <option key={kid.id} value={kid.id}>
                  {isParentOrTeacher
                    ? `${kid.firstName} ${kid.lastName || ""}`
                    : kid.username}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!query.get("status")}
            onClickClear={() => handleClickClearFilter("status")}
          >
            Status
          </FormLabelWithClearAction>
          <Select
            placeholder="All"
            variant="filled"
            name="status"
            value={statusSearchValue}
            onChange={(e) => {
              handleFilterChange(e, "status");
            }}
          >
            <option value={CommentStatus.NeedsReview}>Needs Review</option>
            <option value={CommentStatus.Accepted}>Accepted</option>
            <option value={CommentStatus.Rejected}>Rejected</option>
          </Select>
        </FormControl>
      </Grid>
      {!!comments?.length ? (
        <Box overflowX={{ base: "auto", lg: "visible" }} mt={10}>
          <Table width="100%">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Posted at</Th>
                <Th>Comment</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {comments.map((comment, i) => (
                <CommentRowV2
                  onUsernameClick={onUsernameClick}
                  comment={comment}
                  key={i}
                />
              ))}
            </Tbody>
          </Table>
          {pagination.hasNextPage && !!comments.length && (
            <Center w="full" mt={4}>
              <ButtonSolid
                isLoading={pagination.loadingMore}
                onClick={pagination.loadMore}
              >
                Load More
              </ButtonSolid>
            </Center>
          )}
        </Box>
      ) : loading ? (
        <LayoutCentered height="auto" isLoading />
      ) : (
        <EmptyState
          icon={<EmptyStateFontAwesomeIcon icon={duotone("comments")} />}
          headline="No comments found"
          mt={10}
        />
      )}
    </Box>
  );
};
