import { db } from "Services/firebase";
import { useState, useEffect } from "react";
import { PLAN_TYPES } from "Constants/planTypes";
import { firebaseConfig } from "Services/firebase";

import { PlanInterval } from "Types/User";

let Plans: {
  [key: string]: {
    discounted: string;
    regular: string;
  };
};

if (firebaseConfig.projectId === "tract-learning") {
  Plans = {
    [PLAN_TYPES.single]: {
      discounted: "prod_I3OX0jNX354ZzO",
      regular: "prod_I3OXyABsMSE9OY",
    },
    [PLAN_TYPES.family]: {
      discounted: "prod_I3OXdG2RhN5pce",
      regular: "prod_I3OXmmx9MDn4LR",
    },
  };
} else {
  Plans = {
    [PLAN_TYPES.single]: {
      discounted: "prod_HywCLcLLy8IwzF",
      regular: "prod_HywGxEiyvnMzG8",
    },
    [PLAN_TYPES.family]: {
      discounted: "prod_HywE0f6av5fki5",
      regular: "prod_HywIjQiZ7CjJ46",
    },
  };
}

export function usePrices() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<TODO>();
  const [error, setError] = useState();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const SingleDiscountedPlanId = Plans[PLAN_TYPES.single].discounted;
      const SingleRegularPlanId = Plans[PLAN_TYPES.single].regular;
      const FamilyDiscountedPlanId = Plans[PLAN_TYPES.family].discounted;
      const FamilyRegularPlanId = Plans[PLAN_TYPES.family].regular;

      try {
        const planObject: { [key: string]: TODO } = {};
        const productArray: { id: string; name: string }[] = [];

        const snap = await db
          .collection("products")
          .where("active", "==", true)
          .get();

        snap.forEach(doc => {
          const productId = doc.id;
          const productData = doc.data();

          productArray.push({
            id: productId,
            name: productData.name,
          });
        });

        //creating product object, which contain all the active products
        const products: { [key: string]: TODO } = {};
        for (const product of productArray) {
          const prices: Partial<{ year: TODO; month: TODO }> = {};
          const productData: Partial<{ prices: TODO }> = {};
          const snap = await db
            .collection(`products/${product.id}/prices`)
            .where("active", "==", true)
            .get();

          snap.forEach(doc => {
            const priceId = doc.id;
            const priceData = doc.data();
            if (priceData?.interval === "year") {
              priceData["priceId"] = priceId;
              prices["year"] = priceData;
            } else if (priceData?.interval === "month") {
              priceData["priceId"] = priceId;
              prices["month"] = priceData;
            }
          });
          productData["prices"] = prices;
          products[product.id] = productData;
        }

        //assigning single and family plans
        if (products[SingleDiscountedPlanId]) {
          products[SingleDiscountedPlanId].prices.month.discountedFrom =
            products[SingleRegularPlanId]?.prices?.month?.unit_amount;
          products[SingleDiscountedPlanId].prices.year.discountedFrom =
            products[SingleRegularPlanId]?.prices?.year?.unit_amount;

          planObject[PLAN_TYPES.single] =
            products[SingleDiscountedPlanId]?.prices;
          planObject["discounted"] = true;
        } else {
          planObject[PLAN_TYPES.single] = products[SingleRegularPlanId]?.prices;
        }
        if (products[FamilyDiscountedPlanId]) {
          products[FamilyDiscountedPlanId].prices.month.discountedFrom =
            products[FamilyRegularPlanId]?.prices?.month?.unit_amount;
          products[FamilyDiscountedPlanId].prices.year.discountedFrom =
            products[FamilyRegularPlanId]?.prices?.year?.unit_amount;

          planObject[PLAN_TYPES.family] =
            products[FamilyDiscountedPlanId]?.prices;
          planObject["discounted"] = true;
        } else {
          planObject[PLAN_TYPES.family] = products[FamilyRegularPlanId]?.prices;
        }
        setData(planObject);
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  return { isLoading, setIsLoading, data, error };
}

export function getTotalAmount(
  prices: TODO,
  interval: PlanInterval,
  planType: "single" | "family"
) {
  return planType === PLAN_TYPES.single
    ? interval === PlanInterval.Month
      ? (prices?.single?.month?.unit_amount * 0.01).toFixed(2)
      : (prices?.single?.year?.unit_amount * 0.01).toFixed(2)
    : interval === PlanInterval.Month
    ? (prices?.family?.month?.unit_amount * 0.01).toFixed(2)
    : (prices?.family?.year?.unit_amount * 0.01).toFixed(2);
}
