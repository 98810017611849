import React, { PropsWithChildren } from "react";

import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from "Shared";

import { useFeature } from "Services/features";

import { ProjectDetails } from "../ProjectDetails";

import { ProjectCardFragment } from "@tract/common/dist/graphql";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProjectModal: React.FC<PropsWithChildren<ModalProps>> = ({
  isOpen,
  onClose,
  children,
}) => {
  const feature = useFeature("creator-feedback");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={feature ? "full" : "3xl"}
      scrollBehavior="inside"
      motionPreset="slideInBottom"
      autoFocus={false}
    >
      <ModalCloseButton
        variant="ghost"
        size="md"
        color="white"
        colorScheme="whiteAlpha"
        isRound
        position="fixed"
        top="0"
        right={2}
        zIndex={1500}
        display={{ base: "none", lg: "flex" }}
        tabIndex={-1}
        _focus={{ boxShadow: "none" }}
      />
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent
        borderRadius={0}
        borderTopLeftRadius={{ base: 0, lg: 16 }}
        borderTopRightRadius={{ base: 0, lg: 16 }}
        height="calc(100vh - 2.5rem)"
        maxHeight="calc(100vh - 2.5rem)"
        mx={0}
        mt={{ base: 0, lg: "2.5rem" }}
        mb={0}
        overflowY="auto"
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

interface GalleryModalProps extends ModalProps {
  projectId?: string;
  onOpenNewProject?: (
    event: React.MouseEvent,
    projectID: ProjectCardFragment
  ) => void;
}

// GalleryModal gets most of it's data and state from the gallery view.
// This is to keep interaction with the gallery and modal in sync since the
// parent (Gallery) will maintain the state.

// Use ProjectDetailsModal if you want the modal to control the data and state.
export const ProjectDetailsGalleryModal: React.FC<GalleryModalProps> = ({
  projectId,
  isOpen,
  onClose,
}) => {
  return (
    <ProjectModal isOpen={isOpen} onClose={onClose}>
      <ProjectDetails projectId={projectId} />
    </ProjectModal>
  );
};
