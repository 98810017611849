import { Badge, Button, Flex, IconLeaderboard, Text } from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";
import { useIntercom } from "Services/intercom";

export const Insights = () => {
  const { show } = useIntercom();

  return (
    <LayoutCentered h="calc(100vh - 4rem)">
      <Flex align="center" textAlign="center" direction="column">
        <IconLeaderboard h={16} w={16} color="brandFull.300" mb={4} />
        <Text fontSize="3xl" fontWeight="bold" color="gray.900" mb={2}>
          Insights
          <Badge
            variant="outline"
            colorScheme="brandFull"
            position="absolute"
            ml={2}
          >
            Beta
          </Badge>
        </Text>
        <Text fontSize="lg" color="gray.900" mb={6} maxW="45rem">
          Receive a personalized report with usage insights, achievements, and
          recommendations for your students to thrive on Tract
        </Text>
        <Button
          size="lg"
          colorScheme="brandFull"
          variant="solid"
          onClick={show}
        >
          Request Report
        </Button>
      </Flex>
    </LayoutCentered>
  );
};
