import { FC, useState } from "react";
import { useMutation } from "urql";

import {
  ButtonOutlined,
  ButtonSolid,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "Shared";

import { COLLECTION_DELETE_MUTATION } from "./graphql";

import { Redirect } from "react-router-dom";

type Props = { collectionId: string; onClose: () => void; isOpen: boolean };

export const CollectionDeleteModal: FC<Props> = ({
  collectionId,
  onClose,
  isOpen,
}) => {
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [justDeleted, setJustDeleted] = useState(false);
  const [, deletePathCollection] = useMutation(COLLECTION_DELETE_MUTATION);

  async function deleteCollection() {
    setIsDeleting(true);
    try {
      const output = await deletePathCollection({ collectionId });
      if (output.error) throw new Error(output.error.message);
      setJustDeleted(true);
    } catch (error: any) {
      toast({
        title: "Error",
        description: "Unable to delete collection",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  if (justDeleted) {
    return <Redirect to="/admin/collections" />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Collection?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this collection?
          <ModalFooter as={HStack} mt={5}>
            <ButtonOutlined flexGrow={1} onClick={onClose}>
              Cancel
            </ButtonOutlined>
            <ButtonSolid
              flexGrow={1}
              onClick={deleteCollection}
              colorScheme="red"
              isLoading={isDeleting}
            >
              Yes, Delete
            </ButtonSolid>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
