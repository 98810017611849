import { gql } from "@apollo/client";

export const GET_PATH_FEEDS_BY_PATH_ID = gql`
  query GetPathFeedsByPathId($pathId: bigint!) {
    path_feed(where: { pathId: { _eq: $pathId } }) {
      slug
      pathId
    }
  }
`;

export const ADD_PATH_TO_PATH_FEED = gql`
  mutation AddPathToPathFeed(
    $slug: String!
    $pathId: bigint!
    $userId: String!
  ) {
    insert_path_feed_one(
      object: { slug: $slug, userId: $userId, pathId: $pathId }
      on_conflict: { constraint: path_feed_pkey }
    ) {
      slug
      pathId
    }
  }
`;

export const REMOVE_PATH_FROM_PATH_FEED = gql`
  mutation RemovePathFromPathFeed($slug: String!, $pathId: bigint!) {
    delete_path_feed_by_pk(slug: $slug, pathId: $pathId) {
      slug
      pathId
    }
  }
`;
