import React from "react";
import { Field, FieldProps } from "formik";
import {
  AspectRatio,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import {
  AssignBuilderPathFragment,
  UserFileFragment,
} from "@tract/common/dist/graphql";

import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";

import { UserFile } from "Utils/UserFile";

import { getDisplayName } from "Types/User";

type Props = {
  path: AssignBuilderPathFragment;
};

export const StepDetails: React.FC<Props> = ({ path }) => {
  return (
    <VStack spacing={6}>
      <Flex w="full" columnGap={6}>
        <Box flexBasis="160px">
          <PathCoverImage coverFile={path.coverFile} />
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            {path.title}
          </Text>
          <Text>{path.nodes.length} Activities</Text>
          <HStack mt={2}>
            <UserAvatar
              userId={path.user?.id}
              username={path.user?.username || ""}
              name={path.user && getDisplayName(path.user)}
              isTeacher={false}
              avatarURL={path.user?.avatar || ""}
              size="sm"
            />
            <UsernameLink
              userId={path.user?.id}
              isTeacher={path.user?.isEducator}
              username={path.user?.username || ""}
              color="gray.900"
              fontSize="sm"
              isAuthor
            >
              {path.user && getDisplayName(path.user)}
            </UsernameLink>
          </HStack>
        </Box>
      </Flex>
      <Field name="title">
        {({ field, meta }: FieldProps) => (
          <FormControl isRequired isInvalid={meta.touched && !!meta.error}>
            <FormLabel fontWeight="bold">Title</FormLabel>
            <Input
              placeholder="e.g. Multiplication"
              variant="filled"
              {...field}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="description">
        {({ field, meta }: FieldProps) => (
          <FormControl isInvalid={meta.touched && !!meta.error}>
            <FormLabel fontWeight="bold">Instructions</FormLabel>
            <Textarea
              variant="filled"
              placeholder="Optional"
              height="216px"
              {...field}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </VStack>
  );
};

const PathCoverImage: React.FC<{ coverFile?: UserFileFragment | null }> = ({
  coverFile,
}) => {
  const file = new UserFile(coverFile);
  const src = file.getTransformedUrl("cover_thumb");

  return (
    <AspectRatio ratio={16 / 9}>
      {!!src ? (
        <Image borderRadius={8} src={src} />
      ) : (
        <Box borderRadius={8} bgColor="gray.300" />
      )}
    </AspectRatio>
  );
};
