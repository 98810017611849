import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

export type TeacherGuide = {
  title: string;
  description: string;
  gradeLevel: string;
  subjectArea: string;
  color: string;
  icon: IconDefinition;
  guideURL: string;
};

export const TEACHER_GUIDES: TeacherGuide[] = [
  {
    title: "US Geography",
    description:
      "Which geographical feature is most important to the culture of the area of the US where you live?",
    gradeLevel: "3-5",
    subjectArea: "Social Studies",
    icon: duotone("earth-americas"),
    color: "green.400",
    guideURL:
      "https://docs.google.com/document/d/1xDpvpu_LpatXmK7H9bmvdmA_7Y52l7oWYhXf-1YaW0k/edit?usp=sharing",
  },
  {
    title: "Figurative language",
    description:
      "How can you use figurative language to write a short poem about your favorite dessert?",
    gradeLevel: "3-5",
    subjectArea: "English Language Arts",
    icon: duotone("book"),
    color: "orange.400",
    guideURL:
      "https://docs.google.com/document/d/1KU9OUfhK215HRNQWzAtpHKSjOrA77iJd9JnmM8bDePY/edit?usp=sharing",
  },
  {
    title: "Motion and Stability: Gravity",
    description: "What if we lost gravity on Earth?",
    gradeLevel: "3-5",
    subjectArea: "Science",
    icon: duotone("flask"),
    color: "purple.400",
    guideURL:
      "https://docs.google.com/document/d/1C9hOzQEyEW3wiPY8Jb6YETBtp3NeCXusK-IPIsDYZtw/edit?usp=sharing",
  },
  {
    title: "American Indians and Settlers",
    description:
      "How are today's generations of Native Americans impacted by the American Indian Wars?",
    gradeLevel: "3-5",
    subjectArea: "Social Studies",
    icon: duotone("earth-americas"),
    color: "green.400",
    guideURL:
      "https://docs.google.com/document/d/1nMIXaDhLjd8cBK7XZC_6iNU0cJPQyE6FMftF3lCN-qo/edit?usp=sharing",
  },
];
