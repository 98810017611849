import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  FindFollowRelationshipQuery,
  FindFollowRelationshipQueryResult,
  FindFollowRelationshipQueryVariables,
  UserSocialCountsByIdQueryHookResult,
  UserSocialCountsByIdQueryVariables,
} from "@tract/common/dist/graphql";

import { USER_SOCIAL_COUNTS_BY_ID_QUERY } from "Pages/Profile/graphql";
import { FIND_FOLLOW_RELATIONSHIP } from "Services/social";

export const insert_user_follow_one: UpdateResolver = (result, args, cache) => {
  const variables = args.object as FindFollowRelationshipQueryVariables;
  cache.updateQuery<
    FindFollowRelationshipQueryResult["data"],
    FindFollowRelationshipQueryVariables
  >(
    {
      query: FIND_FOLLOW_RELATIONSHIP,
      variables: {
        sourceUserId: variables.sourceUserId,
        targetUserId: variables.targetUserId,
      },
    },
    () => {
      const item = {
        user_follow_by_pk:
          result?.insert_user_follow_one as FindFollowRelationshipQueryResult["data"],
      };
      return item as FindFollowRelationshipQuery;
    }
  );

  const data = result as FindFollowRelationshipQueryResult["data"];

  cache.link(
    "Query",
    "user_follow_by_pk",
    { ...variables },
    {
      __typename: "user_follow",
      ...data,
    }
  );

  //Increment followers for target
  cache.updateQuery<
    UserSocialCountsByIdQueryHookResult["data"],
    UserSocialCountsByIdQueryVariables
  >(
    {
      query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
      variables: {
        id: variables.targetUserId,
      },
    },
    (data) => {
      if (
        !!data?.user[0]?.followers_aggregate?.aggregate &&
        data?.user[0]?.followers_aggregate?.aggregate?.count >= 0
      ) {
        data.user[0].followers_aggregate.aggregate.count++;
      }
      return data;
    }
  );

  //Increment following for source
  cache.updateQuery<
    UserSocialCountsByIdQueryHookResult["data"],
    UserSocialCountsByIdQueryVariables
  >(
    {
      query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
      variables: {
        id: variables.sourceUserId,
      },
    },
    (data) => {
      if (
        !!data?.user[0]?.following_aggregate?.aggregate &&
        data?.user[0]?.following_aggregate?.aggregate?.count >= 0
      ) {
        data.user[0].following_aggregate.aggregate.count++;
      }
      return data;
    }
  );
};
