import React from "react";
import { forwardRef } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { PATH_INTEREST_AREAS } from "Constants/paths";
import { Box, FontAwesomeIcon, Grid, Link, Stack, Text } from "Shared";

type CardProps = {
  label: string;
  color?: string;
  bannerColor?: string;
  iconLabel?: IconProp;
  to: string;
};

const CategoryCard = forwardRef(
  ({ label, color, bannerColor, iconLabel, to, ...props }: CardProps, ref) => {
    return (
      <Stack
        ref={ref}
        as={Link}
        to={to ? to : undefined}
        aria-label={to ? label : undefined}
        direction={{ base: "row", lg: "column" }}
        align={{ base: "center", lg: "flex-start" }}
        justify={{ base: "stretch", lg: "center" }}
        spacing={{ base: 3, lg: 1 }}
        minH="4rem"
        px={{ base: 3, lg: 6 }}
        py={{ base: 2, lg: 5 }}
        bg={bannerColor}
        borderRadius={{ base: "lg", lg: "xl" }}
        color={color}
        transition="150ms ease background-color, 150ms ease color"
        _hover={{ textDecoration: "none", color: bannerColor, bg: color }}
        {...props}
      >
        {iconLabel && <FontAwesomeIcon size={8} icon={iconLabel} />}
        <Text fontWeight="bold" aria-hidden="true" lineHeight={1.25}>
          {label}
        </Text>
      </Stack>
    );
  }
);

type Props = {};

export const CategoryGrid: React.FC<Props> = (props) => {
  return (
    <Box
      position="relative"
      overflowX={{ base: "scroll", lg: "inherit" }}
      mx={{ base: -4, lg: 0 }}
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Box
        display={{ base: "inline-block", lg: "block" }}
        px={{ base: 4, lg: 0 }}
      >
        <Grid
          gap={{ base: 2, lg: 3 }}
          templateColumns={{
            base: "repeat(5, minmax(10rem, 1fr))",
            lg: "repeat(5, minmax(0, 1fr))",
          }}
          {...props}
        >
          {PATH_INTEREST_AREAS.map(
            ({ label, color, bannerColor, iconDuotone, slug }) => (
              <CategoryCard
                key={slug}
                label={label}
                color={color}
                bannerColor={bannerColor}
                iconLabel={iconDuotone}
                to={`/explore/interest/${slug}`}
              />
            )
          )}
        </Grid>
      </Box>
    </Box>
  );
};
