import React, { PropsWithChildren } from "react";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { useMutation } from "urql";
import { FlexProps } from "@chakra-ui/react";
import copy from "copy-to-clipboard";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconLock,
  Input,
  Link,
  Switch,
  Text,
  Tooltip,
  VStack,
  useToast,
  useRadioGroup,
  IconSchool,
  IconUsers,
  ButtonLink,
  BadgeOutlined,
  FormHelperText,
  useDisclosure,
  useBreakpointValue,
  IconGlobe,
  ButtonOutlined,
} from "Shared";

import { UPDATE_CLASSROOM_SETTINGS } from "./graphql";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";

import {
  ClassroomLearnerGroupFragment,
  UpdateClassroomSettingsMutation,
  UpdateClassroomSettingsMutationVariables,
} from "@tract/common/dist/graphql";
import { RadioCard } from "Components/RadioCard";
import { useSession } from "Services/session";
import { PendingApprovaModal } from "Components/PendingApprovalModal";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { LearnerGroupColors } from "@tract/common/dist/types/models/LearnerGroup";
import { useClassContext } from "Components/LayoutClass";
import { ArchiveClassroomModal } from "Pages/HomeAuthed/ArchiveClassroomModal";

const PRIVACY_LEARN_MORE_LINK =
  "https://help.tract.app/en/articles/6173110-classroom-safety-privacy";

type FormValues = {
  name?: string | null;
  color?: string | null;
  privacySetting?: string | null;
  creatorConsent: boolean;
};

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const ClassroomSettings: React.FC<Props> = ({ classroom }) => {
  const { track } = useAnalytics();
  const { classData } = useClassContext();
  const toast = useToast();
  const privacySetting = classroom.privacyScope || "";
  const { isApprovedOrg } = useSession();
  const showBreadcrumb = useBreakpointValue({ base: true, lg: false });

  const {
    isOpen: isPendingApprovalModalOpen,
    onOpen: openPendingApprovalModal,
    onClose: closePendingApprovalModal,
  } = useDisclosure();

  const {
    isOpen: isArchiveClassModalOpen,
    onOpen: openArchiveClassModal,
    onClose: closeArchiveClassModal,
  } = useDisclosure();

  const [, updateClassroom] = useMutation<
    UpdateClassroomSettingsMutation,
    UpdateClassroomSettingsMutationVariables
  >(UPDATE_CLASSROOM_SETTINGS);

  const handleSubmit = async (values: FormValues) => {
    let privacySetting = values.privacySetting || null;

    if (!values.name) {
      return;
    }

    try {
      const mutationResult = await updateClassroom({
        classId: classroom.id,
        name: values.name,
        color: values.color,
        privacyScope: !!privacySetting ? privacySetting : null,
      });

      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      track(ANALYTICS_EVENTS.CLASS_EDITED, {
        classId: classroom.id,
        classTitle: values.name,
        classColor: values.color,
        studentProfilePrivacy: privacySetting,
        studentResponseVisibility: values.creatorConsent,
      });

      toast({
        status: "success",
        title: "Updated classroom",
      });
    } catch (err: any) {
      captureException(err);
      toast({
        status: "error",
        title: "Error updating classroom",
      });
    }
  };

  const onClickLink = () => {
    if (!isApprovedOrg) {
      openPendingApprovalModal();
      return;
    }

    copy(
      `${window.location.origin}/get-started/kid?code=${classroom.educatorCodes[0].code}`
    );
    toast({
      title: "Invite Link Copied",
      status: "success",
    });
  };

  const inviteLinkText = isApprovedOrg
    ? `${window.location.host}/get-started/kid?code=${classroom.educatorCodes[0].code}`
    : "Your school is being verified.";

  return (
    <PageApp pb="6rem">
      <PageHeader
        title="Settings"
        showBreadcrumb={showBreadcrumb}
        renderBreadcrumb={
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/class/${classData.id}/`}>
                {classData.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Settings</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          name: classroom.name,
          color: classroom.color,
          privacySetting: !!privacySetting ? privacySetting : "public",
          creatorConsent: true,
        }}
      >
        {(form: PropsWithChildren<FormikProps<FormValues>>) => (
          <Form>
            <VStack>
              <SettingRow
                label="Invite Link"
                alignItems={["left", null, "center"]}
              >
                <HStack spacing={4}>
                  <Text fontWeight="bold">{inviteLinkText}</Text>
                  <Button
                    variant="ghost"
                    size="lg"
                    colorScheme="brandFull"
                    onClick={onClickLink}
                  >
                    Copy
                  </Button>
                </HStack>
              </SettingRow>
              <SettingRow
                label="Classroom Name"
                alignItems={["left", null, "center"]}
              >
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <Input
                        {...field}
                        size="lg"
                        placeholder="My Classroom"
                        width={["full", null, 400]}
                      />
                    </FormControl>
                  )}
                </Field>
              </SettingRow>
              <SettingRow
                label="Classroom Color"
                alignItems={["left", null, "center"]}
              >
                <Field name="color" type="checkbox">
                  {({
                    form: { setFieldValue, values },
                  }: FieldProps<FormValues>) => (
                    <ColorSettingsRadioGroup
                      defaultValue={values.color}
                      onChange={(color) => setFieldValue("color", color)}
                    />
                  )}
                </Field>
              </SettingRow>

              <>
                <SettingRow label="Student Profile Privacy">
                  <Field name="privacySetting" type="checkbox">
                    {({
                      form: { setFieldValue, values },
                    }: FieldProps<FormValues>) => (
                      <PrivacySettingsRadioGroup
                        defaultValue={values.privacySetting}
                        onChange={(privacySetting) =>
                          setFieldValue("privacySetting", privacySetting)
                        }
                      />
                    )}
                  </Field>
                </SettingRow>
                <SettingRow label="Student Response Visibility">
                  <Field name="creatorConsent" type="checkbox">
                    {({ field }: FieldProps) => (
                      <FormControl>
                        <HStack spacing={6}>
                          <Tooltip label="Coming Soon">
                            <HStack>
                              <Switch
                                size="lg"
                                lineHeight={0}
                                isDisabled
                                isChecked={field.value}
                              />
                              <Icon
                                as={IconLock}
                                w={5}
                                h={5}
                                color="gray.600"
                              />
                            </HStack>
                          </Tooltip>
                          <Box>
                            <FormLabel fontSize="lg" mb={1}>
                              Allow Creators to view student responses
                            </FormLabel>
                            <FormHelperText mt={0}>
                              When a student responds to a challenge, the
                              Creator can view their work and give feedback.
                            </FormHelperText>
                          </Box>
                        </HStack>
                      </FormControl>
                    )}
                  </Field>
                </SettingRow>
                <SettingRow label="Danger Zone" alignItems="center">
                  <ButtonOutlined
                    size="lg"
                    colorScheme="red"
                    onClick={openArchiveClassModal}
                  >
                    Archive Class
                  </ButtonOutlined>
                </SettingRow>
              </>
            </VStack>
            <Flex
              align="center"
              justify="center"
              bg="white"
              px={10}
              py={4}
              h="calc(5rem + 1px)"
              position="fixed"
              left={0}
              right={0}
              bottom={0}
              zIndex={10}
              borderTop="1px solid"
              borderTopColor="gray.200"
            >
              <ButtonGroup>
                <Button
                  variant="outline"
                  size="lg"
                  isDisabled={!form.dirty}
                  onClick={form.handleReset}
                  isLoading={form.isSubmitting}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  size="lg"
                  colorScheme="brandFull"
                  isDisabled={!form.dirty}
                  isLoading={form.isSubmitting}
                >
                  Save
                </Button>
              </ButtonGroup>
            </Flex>
          </Form>
        )}
      </Formik>
      {!isApprovedOrg && (
        <PendingApprovaModal
          isOpen={isPendingApprovalModalOpen}
          onClose={closePendingApprovalModal}
        />
      )}
      <ArchiveClassroomModal
        isOpen={isArchiveClassModalOpen}
        onClose={closeArchiveClassModal}
        learnerGroupId={classroom.id}
        learnerGroupName={classroom.name || undefined}
      />
    </PageApp>
  );
};

type SettingRowProps = {
  label: string;
};

const SettingRow: React.FC<PropsWithChildren<SettingRowProps> & FlexProps> = ({
  label,
  children,
  ...props
}) => {
  return (
    <Flex
      flexDir={["column", null, "row"]}
      alignItems="left"
      justifyContent="left"
      width="full"
      py={[4, null, 0]}
      borderBottomWidth={[1, null, 0]}
      {...props}
    >
      <Box flex={1} pr={[0, null, 4]} py={[0, null, 8]}>
        <Text as="label" fontWeight="bold" fontSize="lg" color="gray.600">
          {label}
        </Text>
      </Box>
      <Box
        flex={5}
        width={["full", null, "initial"]}
        py={[2, null, 8]}
        borderBottomWidth={[0, null, 1]}
      >
        {children}
      </Box>
    </Flex>
  );
};

interface PrivacySettingsRadioGroupProps {
  defaultValue: string;
  onChange: (privacySetting: string) => void;
}

const PrivacySettingsRadioGroup: React.FC<PrivacySettingsRadioGroupProps> = ({
  defaultValue,
  onChange,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "privacySetting",
    defaultValue,
    onChange,
  });

  return (
    <VStack alignItems="unset" spacing={3} maxW="800px" {...getRootProps()}>
      <PrivacySettingsRadioCard {...getRadioProps({ value: "school" })}>
        <HStack spacing={6}>
          <Box>
            <IconSchool width="40px" height="40px" />
          </Box>
          <VStack alignItems="start">
            <Text fontWeight="bold" fontSize="lg">
              School Only
            </Text>
            <Text>
              Student profiles and projects are not visible to anyone outside
              your school.{" "}
              <ButtonLink
                as="a"
                colorScheme="brandFull"
                target="_blank"
                href={PRIVACY_LEARN_MORE_LINK}
              >
                Learn more
              </ButtonLink>
            </Text>
          </VStack>
        </HStack>
      </PrivacySettingsRadioCard>
      <PrivacySettingsRadioCard {...getRadioProps({ value: "network" })}>
        <HStack spacing={6}>
          <Box>
            <IconUsers width="40px" height="40px" />
          </Box>
          <VStack alignItems="start">
            <HStack>
              <Text fontWeight="bold" fontSize="lg">
                Member Only
              </Text>
              <BadgeOutlined colorScheme="orange">Recommended</BadgeOutlined>
            </HStack>
            <Text>
              Student profiles and projects are visible to authenticated Tract
              members.{" "}
              <ButtonLink
                as="a"
                colorScheme="brandFull"
                target="_blank"
                href={PRIVACY_LEARN_MORE_LINK}
              >
                Learn more
              </ButtonLink>
            </Text>
          </VStack>
        </HStack>
      </PrivacySettingsRadioCard>
      <PrivacySettingsRadioCard {...getRadioProps({ value: "public" })}>
        <HStack spacing={6}>
          <Box>
            <IconGlobe width="40px" height="40px" />
          </Box>
          <VStack alignItems="start">
            <Text fontWeight="bold" fontSize="lg">
              Public
            </Text>
            <Text>
              Student profiles and projects are publicly accessible on the web.{" "}
              <ButtonLink
                as="a"
                colorScheme="brandFull"
                target="_blank"
                href={PRIVACY_LEARN_MORE_LINK}
              >
                Learn more
              </ButtonLink>
            </Text>
          </VStack>
        </HStack>
      </PrivacySettingsRadioCard>
    </VStack>
  );
};

const PrivacySettingsRadioCard: React.FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  return (
    <RadioCard
      px={6}
      py={4}
      borderRadius="xl"
      fontWeight="unset"
      textTransform="unset"
      border="1px solid"
      borderColor="gray.300"
      _checked={{
        margin: "-1px",
        borderWidth: "2px",
        borderColor: "gray.900",
      }}
      _focus={{
        boxShadow: "outline",
      }}
      _hover={{
        bg: "gray.50",
      }}
      _active={{
        color: "gray.900",
      }}
      {...props}
    >
      {children}
    </RadioCard>
  );
};

interface ColorSettingsRadioGroupProps {
  defaultValue: string;
  onChange: (color: string) => void;
}

const ColorSettingsRadioGroup: React.FC<ColorSettingsRadioGroupProps> = ({
  defaultValue,
  onChange,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "colorSetting",
    defaultValue,
    onChange,
  });

  return (
    <HStack {...getRootProps()}>
      {LearnerGroupColors.map((color) => {
        return (
          <ColorSettingsRadioCard
            key={color}
            color={color}
            {...getRadioProps({ value: color })}
          />
        );
      })}
    </HStack>
  );
};

type ColorProps = {
  color: string;
};

const ColorSettingsRadioCard: React.FC<PropsWithChildren<ColorProps>> = ({
  children,
  color,
  ...props
}) => {
  return (
    <RadioCard
      h={12}
      w={12}
      borderRadius="xl"
      bg={color}
      border="1px solid"
      borderColor="gray.300"
      _checked={{
        borderWidth: "2px",
        borderColor: "gray.900",
      }}
      _active={{
        bg: { color },
      }}
      {...props}
    >
      {children}
    </RadioCard>
  );
};
