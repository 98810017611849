import { gql } from "graphql-request";

export const EDUCATOR_CODE_FRAGMENT = gql`
  fragment EducatorCode on educator_code {
    code
    available
    expiresAt
    createdAt
  }
`;

export const CLASSROOM_LEARNER_GROUP_FRAGMENT = gql`
  ${EDUCATOR_CODE_FRAGMENT}

  fragment ClassroomLearnerGroup on learner_group {
    id
    name
    type
    privacyScope
    color
    source
    sourceId
    members {
      learnerGroupId
      userId
      isOwner
      role
      user {
        id: firestoreId
        firstName
        lastName
        email
        avatar
        isEducator
        userType
        username
        teacher {
          id: userId
          displayName
        }
      }
    }
    pendingMembers {
      firstName
      lastName
      foreignId
      provider
    }
    organization {
      id
      avatar
      name
    }
    educatorCodes(order_by: { createdAt: desc }) {
      ...EducatorCode
    }
    learner_group_path_collections {
      learnerGroupId
      pathCollectionId
      pathCollection {
        id
        pathCollectionItems {
          collectionId
          pathId
        }
        pathCollectionItems_aggregate {
          aggregate {
            count
          }
        }
      }
    }
    assignments {
      assignmentId
      learnerGroupId
      isFinished
    }
  }
`;
