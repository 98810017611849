import React, { useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import qs from "querystring";

import {
  ButtonSolid,
  IconPlus,
  Flex,
  Input,
  TabList,
  Tabs,
  TabLink,
  Center,
} from "Shared";

import { useOffsetPaginationQuery, useQuery as useParamsQuery } from "Utils";

import {
  AdminOrgsByStatusQuery,
  AdminOrgsByStatusQueryVariables,
  AdminOrgsSearchQuery,
  AdminOrgsSearchQueryVariables,
} from "@tract/common/dist/graphql";
import { ADMIN_ORGS_BY_STATUS_QUERY, ADMIN_ORGS_SEARCH_ORGS } from "../graphql";

import { PageHeader } from "Components/PageHeader";
import { OrganizationsTable } from "./OrganizationsTable";
import { OrganizationStatus } from "@tract/common/dist/types/models/Organization";
import { LayoutCentered } from "Components/LayoutCentered";

const LIMIT = 10;

export const Organizations: React.FC = () => {
  const history = useHistory();
  const params = useParamsQuery();
  const status = params.get("status");
  const search = params.get("search") || "";
  const tabIndex: Record<string, number> = {
    [OrganizationStatus.Pending]: 0,
    [OrganizationStatus.Approved]: 1,
  };

  const searchOrgDebounced = useDebouncedCallback((search: string) => {
    history.push({
      search: qs.encode({
        status,
        ...(!!search && { search }),
      }),
    });
  }, 350);

  const isSearching = search.trim().length > 0;
  const {
    response: [{ data, fetching }],
    pagination: nonSearchPagination,
  } = useOffsetPaginationQuery<
    AdminOrgsByStatusQuery,
    AdminOrgsByStatusQueryVariables
  >({
    pause: isSearching,
    query: ADMIN_ORGS_BY_STATUS_QUERY,
    variables: {
      verified: status === OrganizationStatus.Approved,
      limit: LIMIT,
      offset: 0,
    },
    requestPolicy: "cache-and-network",
    field: "adminOrgs",
  });

  const {
    response: [{ data: searchData, fetching: searchFetching }],
    pagination: searchPagination,
  } = useOffsetPaginationQuery<
    AdminOrgsSearchQuery,
    AdminOrgsSearchQueryVariables
  >({
    pause: !isSearching,
    query: ADMIN_ORGS_SEARCH_ORGS,
    field: "adminOrgs",
    requestPolicy: "cache-and-network",
    variables: {
      search: search,
      verified: status === OrganizationStatus.Approved,
      limit: LIMIT,
      offset: 0,
    },
  });

  const orgs = isSearching ? searchData?.adminOrgs : data?.adminOrgs;
  const pendingCount =
    (isSearching
      ? searchData?.pendingCount.aggregate?.count
      : data?.pendingCount.aggregate?.count) || 0;
  const { resetOffset, ...pagination } = isSearching
    ? searchPagination
    : nonSearchPagination;
  const loading = fetching || searchFetching;

  useEffect(() => {
    resetOffset();
  }, [status, resetOffset]);

  if (!status) {
    return <Redirect to="/admin/organizations?status=Pending" />;
  }

  return (
    <Tabs index={tabIndex[status]} isManual>
      <PageHeader
        title="Organizations"
        renderActions={
          <ButtonSolid
            as={Link}
            to="/admin/create-organization"
            size="lg"
            colorScheme="brandFull"
            leftIcon={<IconPlus />}
          >
            Organization
          </ButtonSolid>
        }
        renderTabs={
          <Flex justifyContent="space-between">
            <TabList px={1}>
              <TabLink
                to={`/admin/organizations?${qs.encode({
                  status: "Pending",
                  ...(!!search && { search }),
                })}`}
              >
                Pending ({pendingCount})
              </TabLink>
              <TabLink
                to={`/admin/organizations?${qs.encode({
                  status: "Approved",
                  ...(!!search && { search }),
                })}`}
              >
                Active
              </TabLink>
            </TabList>
            <Input
              variant="filled"
              placeholder="Search organizations..."
              width="sm"
              onChange={(e) => searchOrgDebounced(e.target.value)}
              defaultValue={search}
              autoFocus
            />
          </Flex>
        }
      />
      {loading ? (
        <LayoutCentered height="50vh" isLoading />
      ) : (
        <OrganizationsTable status={status} orgs={orgs} />
      )}
      {pagination.hasNextPage && !!orgs?.length && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </Tabs>
  );
};
