import { useState } from "react";

import { useInterval } from "@tract/common/dist/hooks";

export const useIntercom = () => {
  const [isReady, setReady] = useState(window.Intercom?.booted);
  const [interval, setInterval] = useState<number | null>(500);
  const [count, setCount] = useState(0);

  useInterval(() => {
    if (count === 10) {
      setInterval(null);
      return;
    }

    if (isReady) {
      setInterval(null);
      return;
    }

    if (window.Intercom?.booted) {
      setReady(true);
      return;
    }

    setCount((count) => count + 1);
  }, interval);

  const show = () => {
    if (isReady) {
      window.Intercom("show");
    } else {
      window.open("mailto:help@tract.app", "_blank");
    }
  };

  const showNewMessage = (message?: string) => {
    if (isReady) {
      window.Intercom("showNewMessage", message);
    } else {
      window.open("mailto:help@tract.app", "_blank");
    }
  };

  return {
    show,
    showNewMessage,
    isReady,
    Intercom: window.Intercom,
  };
};
