import React, { useContext, useMemo } from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  ButtonSolid,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  Grid,
  Link,
  useBreakpointValue,
} from "Shared";

import { PathCard } from "Components/PathCard";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { Context } from "Components/ClassroomSavedPaths";

import { ViewSource } from "Types/Path";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { useClassContext } from "Components/LayoutClass";
import { useSession } from "Services/session";
import { isKidUser } from "Types/User";

export const ClassroomSavedPaths: React.FC = () => {
  const { currentUser } = useSession();
  const { loading, error, pathCollection } = useContext(Context);
  const { classData } = useClassContext();

  const kids = useMemo(() => {
    return (
      classData.members
        .filter(({ role }) => role === "learner")
        .map(({ userId }) => userId) || []
    );
  }, [classData]);

  const isClassStudent =
    isKidUser(currentUser) && kids.includes(currentUser.uid);

  const showBreadcrumb = useBreakpointValue({ base: true, lg: false });
  const skeletonCount =
    useBreakpointValue({
      base: 3,
      md: 4,
      xl: 6,
      "2xl": 8,
    }) || 0;

  const items = pathCollection?.pathCollectionItems || [];

  const renderGrid = () => {
    if (loading && !error) {
      return (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, minmax(0, 1fr))",
            xl: "repeat(3, minmax(0, 1fr))",
            "2xl": "repeat(4, minmax(0, 1fr))",
          }}
          gap={3}
        >
          {Array.from({ length: skeletonCount }).map((_, i) => (
            <PathCardSkeleton key={i} />
          ))}
        </Grid>
      );
    }

    if (!items || items?.length === 0) {
      return (
        <EmptyState
          icon={<EmptyStateFontAwesomeIcon icon={duotone("bookmark")} />}
          headline="No content saved yet"
          body={
            isClassStudent
              ? undefined
              : "Explore the content library to save content to this class"
          }
          cta={
            isClassStudent ? null : (
              <ButtonSolid size="lg" as={Link} to="/explore">
                Start Exploring
              </ButtonSolid>
            )
          }
        />
      );
    }

    if (!!items && items.length > 0) {
      return (
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, minmax(0, 1fr))",
            xl: "repeat(3, minmax(0, 1fr))",
            "2xl": "repeat(4, minmax(0, 1fr))",
          }}
          gap={3}
        >
          {items.map((item) => (
            <PathCard
              key={item.path.id}
              path={item.path}
              source={ViewSource.ClassHub}
              classCollectionId={item.collectionId}
            />
          ))}
        </Grid>
      );
    }
  };

  return (
    <PageApp>
      <PageHeader
        title="Saved"
        showBreadcrumb={showBreadcrumb}
        renderBreadcrumb={
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/class/${classData.id}/`}>
                {classData.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Saved</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />
      {renderGrid()}
    </PageApp>
  );
};
