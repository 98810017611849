import { PropsWithChildren } from "react";

import { BoxProps, forwardRef } from "@chakra-ui/react";

import { Flex } from "Shared";

type Props = {
  navPosition?: "left" | "right";
  hasBorder?: boolean;
} & BoxProps;

export const SideNav = forwardRef<PropsWithChildren<Props>, "aside">(
  ({ children, navPosition = "left", hasBorder = true, ...props }, ref) => {
    const positionProps: Record<any, Partial<BoxProps>> = {
      left: {
        left: 0,
        borderRight: "1px solid",
        borderRightColor: "gray.200",
      },
      right: {
        right: 0,
        borderLeft: "1px solid",
        borderLeftColor: "gray.200",
      },
    };

    return (
      <Flex
        as="aside"
        direction="column"
        position="sticky"
        top="4rem"
        bottom={0}
        zIndex={10}
        py={3}
        width="18rem"
        minWidth="18rem"
        height="calc(100vh - 4rem)"
        bg="white"
        overflow="auto"
        display={{ base: "none", lg: "flex" }}
        ref={ref}
        {...props}
        {...(hasBorder && { ...positionProps[navPosition] })}
      >
        {children}
      </Flex>
    );
  }
);
