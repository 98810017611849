import React, { MutableRefObject, useRef, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { formatDistanceStrict } from "date-fns";

import {
  Box,
  ButtonOutlined,
  ButtonSolid,
  Flex,
  HStack,
  IconAlertTriangle,
  IconButton,
  IconEdit,
  IconMoreVertical,
  IconTrash,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "Shared";

import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { GiphyComment } from "./GiphyComment";
import { AwardCommentDetails } from "./AwardCommentDetails";
import { CommentEditor } from "./CommentEditor";
import { ReportReasonTypes, ReportStatus } from "Types/AbuseReport";

import {
  isAuthorUser,
  isKidUser,
  isParentUser,
  isTeacherUser,
  getDisplayName,
} from "Types/User";
import { CommentType } from "Types/Comment";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { db } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useFeature } from "Services/features";
import { useProjectModal } from "Services/projectModal";
import { ProfileBadges } from "Components/ProfileBadges";

import {
  ProjectCommentFragment,
  ProjectCardFragment,
  DeleteProjectCommentMutation,
  DeleteProjectCommentMutationVariables,
} from "@tract/common/dist/graphql";
import { ProfilePopover } from "Components/ProfilePopover";
import { useMutation } from "urql";
import { PROJECT_COMMENT_DELETE } from "./graphql";

interface Props {
  comment: ProjectCommentFragment;
  awardId?: string;
  project: ProjectCardFragment;
  containerRef?: MutableRefObject<HTMLElement | null>;
}

export const CommentItem: React.FC<Props> = ({
  comment,
  awardId,
  project,
  containerRef,
}) => {
  const { currentUser, isAdmin } = useSession();
  const { track } = useAnalytics();
  const toast = useToast();
  const { isProjectModalOpen, closeProjectModal } = useProjectModal();
  const isReportCommentEnabled = useFeature("report-comments");
  const menuTriggerRef = useRef(null);
  const {
    isOpen: isEditing,
    onOpen: openCommentEditor,
    onClose: closeCommentEditor,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: openDeleteConfirmModal,
    onClose: closeDeleteConfirmModal,
  } = useDisclosure();

  const user = comment.comment.user;

  const commentType = awardId
    ? CommentType.Award
    : !!comment.comment.giphyId
    ? CommentType.Giphy
    : CommentType.Text;

  const isAuthor = user ? isAuthorUser(user) : false;

  const onLinkClick = () => {
    if (isProjectModalOpen) {
      closeProjectModal(false);
    }
  };

  const onDelete = () => {
    closeDeleteConfirmModal();
  };

  const reportComment = async () => {
    try {
      const reportRef = db.collection(`abuse-reports`).doc();

      await reportRef.set({
        type: "comment",
        reporterId: currentUser.uid,
        reporteeId: comment.comment.userId,
        reason: ReportReasonTypes.Offensive,
        status: ReportStatus.Pending,
        meta: {
          projectId: project.id,
          commentId: comment.commentId,
        },
      });

      track(ANALYTICS_EVENTS.PROJECT_COMMENT_REPORTED, {
        reporteeId: comment.comment.userId,
      });

      toast({
        status: "success",
        title: "Comment Reported",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error reporting this comment",
      });
    }
  };

  const displayName = getDisplayName(user);

  if (!user) {
    return null;
  }

  const userLink = `/${isTeacherUser(user) ? user.id : `@${user.username}`}`;
  const isReportable =
    isReportCommentEnabled &&
    currentUser.uid !== comment.comment.userId &&
    isKidUser(user);

  const isCommentMenuEnabled =
    isAdmin || currentUser.uid === comment.comment.userId || isReportable;

  return (
    <Box mb={4}>
      <HStack spacing={2} align="flex-start">
        <Link to={userLink} tabIndex={-1} aria-hidden="true">
          <ProfilePopover
            userId={user.id}
            containerRef={containerRef}
            onLinkClick={onLinkClick}
            triggerElement={
              <UserAvatar
                username={user.username || ""}
                userId={user.id || ""}
                avatarURL={user.avatar || ""}
                size="sm"
                gridArea="avatar"
                isTeacher={isTeacherUser(user)}
                isParent={isParentUser(user)}
                name={getDisplayName(user)}
                onClick={onLinkClick}
              />
            }
            isAuthor={isAuthor}
          />
        </Link>
        <VStack align="flex-start" flex={1} spacing={1}>
          <HStack width="100%" height={8} pr={3} justifyContent="space-between">
            <HStack spacing={1}>
              <ProfilePopover
                userId={user.id}
                containerRef={containerRef}
                onLinkClick={onLinkClick}
                triggerElement={
                  <UsernameLink
                    username={user.username || ""}
                    userId={user.id || ""}
                    fontWeight="bold"
                    flex={1}
                    isTeacher={isTeacherUser(user)}
                    isParent={isParentUser(user)}
                    onClick={onLinkClick}
                  >
                    {displayName}
                  </UsernameLink>
                }
              />
              <ProfileBadges
                iconSize={5}
                user={user}
                spacing={1}
                flagFont="lg"
              />
            </HStack>
            {isCommentMenuEnabled && !isEditing && (
              <CommentMenu
                comment={comment}
                commentType={commentType}
                menuTriggerRef={menuTriggerRef}
                isReportable={isReportable}
                onSelectDelete={openDeleteConfirmModal}
                onSelectEdit={openCommentEditor}
                onCommentReport={reportComment}
              />
            )}
          </HStack>
          {isEditing ? (
            <CommentEditor
              comment={comment}
              isAuthorComment={isAuthor}
              project={project}
              onSave={closeCommentEditor}
              onCancel={closeCommentEditor}
              type={commentType}
            />
          ) : (
            <CommentDetails comment={comment} awardId={awardId} />
          )}
        </VStack>

        <DeleteConfirmModal
          comment={comment}
          commentType={commentType}
          isAuthorComment={isAuthor}
          project={project}
          menuTriggerRef={menuTriggerRef}
          isOpen={isDeleteConfirmModalOpen}
          onClose={closeDeleteConfirmModal}
          onDelete={onDelete}
        />
      </HStack>
    </Box>
  );
};

type CommentDetailsProps = {
  comment: ProjectCommentFragment;
  awardId?: string;
};

const CommentDetails: React.FC<CommentDetailsProps> = ({
  comment,
  awardId,
}) => {
  const renderCommentBody = () => {
    if (awardId) {
      return <AwardCommentDetails comment={comment} awardId={awardId} />;
    } else if (comment.comment.giphyId) {
      return (
        <Box py={2}>
          <GiphyComment giphyId={comment.comment.giphyId} />
        </Box>
      );
    } else {
      return (
        <Text variant="breakword" as="span" pr={6}>
          {comment.comment.text}
        </Text>
      );
    }
  };

  return (
    <>
      {renderCommentBody()}
      <Text
        fontSize="sm"
        color="gray.600"
        title={new Date(comment.comment.createdAt).toLocaleString()}
      >
        {formatDistanceStrict(new Date(comment.comment.createdAt), new Date(), {
          addSuffix: true,
        })}
      </Text>
    </>
  );
};

type CommentMenuProps = {
  comment: ProjectCommentFragment;
  commentType: CommentType;
  isReportable: boolean;
  menuTriggerRef: React.RefObject<HTMLButtonElement>;
  onSelectDelete: () => void;
  onSelectEdit: () => void;
  onCommentReport: () => void;
};

const CommentMenu: React.FC<CommentMenuProps> = ({
  comment,
  commentType,
  isReportable,
  menuTriggerRef,
  onSelectDelete,
  onSelectEdit,
  onCommentReport,
}) => {
  const { currentUser, currentKidIds, isAdmin } = useSession();
  const isTeacher = isTeacherUser(currentUser);
  const isKidComment = isTeacher
    ? currentKidIds.includes(comment.comment.userId)
    : false;
  const isOwn = currentUser.uid === comment.comment.userId;
  const canModify = isAdmin || isOwn;
  const canEditAwardMessage =
    commentType === CommentType.Award && comment.comment.text;
  const isEditable = commentType === CommentType.Text || canEditAwardMessage;

  if (commentType === CommentType.Award && !comment.comment.text) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="open comment menu"
        icon={<IconMoreVertical width={4} height={4} />}
        variant="ghost"
        size="sm"
        ref={menuTriggerRef}
      />
      <MenuList width="256px">
        {canModify && (
          <>
            {isEditable && (
              <MenuItem
                cursor="pointer"
                as={Flex}
                align="center"
                px={6}
                onClick={onSelectEdit}
              >
                <IconEdit />
                <Text ml={6} color="gray.900">
                  Edit
                </Text>
              </MenuItem>
            )}
          </>
        )}
        {(canModify || isKidComment) && commentType !== CommentType.Award && (
          <MenuItem
            cursor="pointer"
            as={Flex}
            align="center"
            px={6}
            onClick={onSelectDelete}
          >
            <IconTrash />
            <Text ml={6} color="gray.900">
              Delete
            </Text>
          </MenuItem>
        )}
        {isReportable && (
          <MenuItem
            cursor="pointer"
            as={Flex}
            align="center"
            px={6}
            onClick={onCommentReport}
          >
            <IconAlertTriangle color="red.600" />
            <Text ml={6} color="red.600">
              Report
            </Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

type DeleteConfirmModalTpe = {
  comment: ProjectCommentFragment;
  commentType: CommentType;
  isAuthorComment: boolean;
  project: ProjectCardFragment;
  isOpen: boolean;
  menuTriggerRef: React.RefObject<HTMLElement>;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteConfirmModal: React.FC<DeleteConfirmModalTpe> = ({
  comment,
  commentType,
  isAuthorComment,
  project,
  isOpen,
  menuTriggerRef,
  onClose,
  onDelete,
}) => {
  const [deleting, setDeleting] = useState(false);
  const toast = useToast();
  const { track } = useAnalytics();
  const { currentUser } = useSession();

  const [, deleteProjectComment] = useMutation<
    DeleteProjectCommentMutation,
    DeleteProjectCommentMutationVariables
  >(PROJECT_COMMENT_DELETE);

  const deleteComment = async () => {
    setDeleting(true);

    try {
      await deleteProjectComment({
        projectId: project.id,
        commentId: comment.commentId,
      });

      onDelete();

      track(ANALYTICS_EVENTS.PROJECT_COMMENT_DELETED, {
        projectId: project.id,
        projectCommentCount: project.stats?.commentCount || 0,
        projectCommentType: commentType,
        isProjectOwner: comment.comment.userId === project.user.id,
        isCommentOwner: currentUser.uid === comment.comment.userId,
        isAuthorComment,
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong.",
        description: "Unable to delete comment, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      finalFocusRef={menuTriggerRef}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="left">Delete Comment?</ModalHeader>
        <ModalBody>Are you sure you want to delete this comment?</ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined onClick={onClose} disabled={deleting}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            onClick={deleteComment}
            isLoading={deleting}
            colorScheme="red"
          >
            Yes, Delete
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
