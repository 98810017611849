import { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { Buffer } from "buffer";

import { ButtonOutlined } from "Shared";
import { IconMicrosoft } from "Shared/Icon/custom/IconMicrosoft";

import { captureException } from "Services/errors";
import { useFeature } from "Services/features";

import { ProviderButtonProps } from "./_shared";

export const MicrosoftButton: React.FC<ProviderButtonProps> = ({
  signUpRole,
  educatorCode,
  isDisabled,
  onSuccess,
  onFailure,
}) => {
  const [loading, setLoading] = useState(false);
  const isMicrosoftSSOEnabled = useFeature("microsoft-sso");

  if (!isMicrosoftSSOEnabled) {
    return null;
  }

  const handleClick = async () => {
    setLoading(true);

    const state = JSON.stringify({
      signUpRole,
      educatorCode,
    });

    let accessToken: string | undefined = undefined;

    const msal = new PublicClientApplication({
      auth: {
        clientId: process.env["REACT_APP_AZURE_APP_CLIENT_ID"] || "",
        authority:
          "https://login.microsoftonline.com/ad053507-80bb-4029-bec8-5f7935940d81",
        redirectUri: `${window.location.origin}${window.location.pathname}`,
      },

      cache: {
        cacheLocation: "sessionStorage",
      },
    });

    try {
      const msResult = await msal.loginPopup({
        scopes: ["User.Read"],
        prompt: "select_account",
      });

      accessToken = msResult?.accessToken;
    } catch (err: any) {
      setLoading(false);
      return captureException(err);
    }

    return axios
      .post<any, AxiosResponse<{ token: string }>>(
        `${process.env["REACT_APP_WEB_API_ORIGIN"]}/v2/integrations/microsoft`,
        {
          code: accessToken,
          state: Buffer.from(state).toString("base64"),
        }
      )
      .then(({ data }) => {
        onSuccess(data?.token);
      })
      .catch((err: AxiosError) => {
        onFailure && onFailure(err.response?.data.errorCode);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ButtonOutlined
      leftIcon={<IconMicrosoft mr={1} />}
      onClick={handleClick}
      isLoading={loading}
      isDisabled={loading || isDisabled}
      w="full"
      h="48px"
    >
      {`${signUpRole ? "Sign Up" : "Sign In"} With Microsoft`}
    </ButtonOutlined>
  );
};
