import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminLivestreamsQueryResult,
  AdminLivestreamsQueryVariables,
  CreateLivestreamMutationResult,
} from "@tract/common/dist/graphql";

import { ADMIN_LIVESTREAMS_QUERY } from "Pages/AdminDashboard/AdminLivestreams/graphql";
import {
  ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING,
  ADMIN_LIVESTREAMS_PER_PAGE,
  ADMIN_LIVESTREAMS_START_DATE_UPCOMING,
} from "Pages/AdminDashboard/AdminLivestreams/LivestreamsTable";

export const insert_livestream_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.updateQuery<
    AdminLivestreamsQueryResult["data"],
    AdminLivestreamsQueryVariables
  >(
    {
      query: ADMIN_LIVESTREAMS_QUERY,
      variables: {
        startDate: ADMIN_LIVESTREAMS_START_DATE_UPCOMING,
        orderBy: ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING,
        limit: ADMIN_LIVESTREAMS_PER_PAGE,
        offset: 0,
      },
    },
    (data) => {
      const item = (result as CreateLivestreamMutationResult["data"])
        ?.livestream;

      if (item) {
        data?.livestreams.unshift({
          ...item,
        });
        data?.livestreams.sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      }
      return data;
    }
  );
};
