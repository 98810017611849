import { Field, FieldProps, Form, Formik } from "formik";
import { FC } from "react";
import * as Yup from "yup";

import {
  Box,
  ButtonOutlined,
  ButtonSolid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Textarea,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  ModalHeader,
  ModalFooter,
  Flex,
  ButtonGroup,
} from "Shared";

import { Accomplishment } from "Types/Accomplishment";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (accomplishment: Accomplishment) => void;
  onDelete: () => void;
  accomplishment?: Accomplishment;
};

const TITLE_CHAR_LIMIT = 500;
const DESCRIPTION_CHAR_LIMIT = 1000;

const AccomplishmentValidationSchema: Yup.SchemaOf<Accomplishment> = Yup.object(
  {
    title: Yup.string()
      .max(TITLE_CHAR_LIMIT, "Max 500 characters allowed")
      .required("Please enter a title"),
    description: Yup.string().max(
      DESCRIPTION_CHAR_LIMIT,
      "Max 1000 characters allowed"
    ),
  }
);

export const EditAccomplishmentModal: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  onDelete,
  accomplishment,
}) => {
  const title = accomplishment?.title || "";
  const description = accomplishment?.description || "";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent py={6} px={4}>
        <ModalHeader textAlign="left">
          {accomplishment ? "Edit" : "Add"} Accomplishment
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              title,
              description,
            }}
            validationSchema={AccomplishmentValidationSchema}
            enableReinitialize={true}
            onSubmit={onSave}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form id="edit-accomplishment" autoComplete="off" noValidate={true}>
              <VStack align="stretch" spacing={6}>
                <Field name="title">
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!form.errors.title} isRequired>
                      <Flex justify="space-between">
                        <FormLabel>Short description</FormLabel>
                        <Box as="span" fontWeight="normal">
                          {field.value?.length || 0}/{TITLE_CHAR_LIMIT}
                        </Box>
                      </Flex>
                      <Input
                        {...field}
                        maxLength={TITLE_CHAR_LIMIT}
                        type="text"
                        size="lg"
                      />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="description">
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!form.errors.description}>
                      <Flex justify="space-between">
                        <FormLabel>Additional details</FormLabel>
                        <Box as="span" fontWeight="normal">
                          {field.value?.length || 0}/{DESCRIPTION_CHAR_LIMIT}
                        </Box>
                      </Flex>
                      <Textarea
                        {...field}
                        maxLength={DESCRIPTION_CHAR_LIMIT}
                        height="8rem"
                      />
                      <FormErrorMessage>
                        {form.errors.description}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </VStack>
            </Form>
          </Formik>
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <ButtonGroup flexGrow={1}>
            <ButtonSolid size="lg" type="submit" form="edit-accomplishment">
              Save
            </ButtonSolid>
            <ButtonOutlined size="lg" onClick={onClose}>
              Cancel
            </ButtonOutlined>
          </ButtonGroup>
          {accomplishment && (
            <Box>
              <ButtonOutlined size="lg" onClick={onDelete}>
                Delete
              </ButtonOutlined>
            </Box>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
