import { Box, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { AssignBuilderPathFragment } from "@tract/common/dist/graphql";
import { StepAssignClass } from "./StepAssignClass";

import { StepDetails } from "./StepDetails";

type Props = {
  tabIndex: number;
  path: AssignBuilderPathFragment;
};

export const Steps: React.FC<Props> = ({ tabIndex, path }) => {
  return (
    <Tabs index={tabIndex} isManual>
      <TabPanels>
        <TabPanel>
          <Box mb={12}>
            <Text fontSize="4xl" fontWeight="bold">
              Select details
            </Text>
          </Box>
          <StepDetails path={path} />
        </TabPanel>
        <TabPanel>
          <Text fontSize="4xl" fontWeight="bold" mb={12}>
            Assign to a class
          </Text>
          <StepAssignClass />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
