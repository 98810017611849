import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, UseQueryArgs } from "urql";

type OffsetVars = {
  limit: number;
  offset: number;
};

type Props<V, D> = {
  /**
   * The target field that contains your paginated data
   */
  field: string;
} & UseQueryArgs<V, D>;

export const useOffsetPaginationQuery = <
  Data extends Record<string, any>,
  Variables extends OffsetVars
>(
  props: Props<Variables, Data>
) => {
  const { field, variables } = props;
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [checking, setChecking] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  const response = useQuery<Data, Variables>({
    ...props,
    variables: { ...variables, offset } as Variables,
  });
  const [{ data, error, stale }] = response;

  const items = useMemo(() => {
    return data?.[field] || [];
  }, [data, field]);

  const total = items.length;
  const perPage = variables?.limit || 0;

  // Facilitates checking if there is another page
  useEffect(() => {
    if (!checking || stale) {
      return;
    }

    if (offset === items.length) {
      setIsLastPage(true);
    }

    setChecking(false);
  }, [offset, items, checking, stale]);

  // Controls the loading more state
  useEffect(() => {
    if (!stale || error) {
      setLoadingMore(false);
    }
  }, [stale, error]);

  return {
    response,
    pagination: {
      currentOffset: offset,
      hasNextPage: !(total % perPage) && !isLastPage,
      loadingMore,
      loadMore: () => {
        setChecking(true);
        setOffset(total);
        setLoadingMore(true);
      },
      resetOffset: useCallback(() => {
        setOffset(0);
        setChecking(false);
        setLoadingMore(false);
        setIsLastPage(false);
      }, []),
    },
  };
};
