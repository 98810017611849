// import React, { useEffect, useState } from "react";
// import builder, { BuilderComponent } from "@builder.io/react";
// import "./Blocks";

import { Avatar, Box, HStack, Text, VStack } from "Shared";
import { ContainerMarketing } from "Components/LayoutMarketing";

// const PAGE_MODEL = process.env.REACT_APP_BUILDERIO_PAGE_MODEL;

// const BuilderHomepage: React.FC = () => {
//   const [builderContentJson, setBuilderContentJson] = useState<any | undefined>(
//     undefined
//   );
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     if (PAGE_MODEL) {
//       builder
//         .get(PAGE_MODEL, {
//           url: window.location.pathname,
//         })
//         .promise()
//         .then((data) => {
//           setBuilderContentJson(data);
//           setLoading(false);
//         });
//     }
//   }, []);

//   if (loading) {
//     return <Box h="100vh" />;
//   }

//   return (
//     <>
//       {builderContentJson && (
//         <BuilderComponent model={PAGE_MODEL} content={builderContentJson} />
//       )}
//     </>
//   );
// };

export const Homepage = () => {
  return (
    <ContainerMarketing>
      <Box maxW="40rem" mx="auto" pt={{ base: 10, md: 16 }} pb={10}>
        <VStack spacing={6} align="flex-start">
          <Text>
            Since launching Tract in 2020, I’ve had the chance to meet so many
            incredible, innovative students and educators from around the world
            as we welcomed them to the Tract community. 30K+ members, spanning
            across 20+ countries, collectively posted 100K+ projects that each
            uniquely showed the passions, creativity, and talents of every
            child. That’s certainly something to celebrate!
          </Text>
          <Text>
            However, navigating the path back to a sense of normalcy after
            school closures has been challenging, and we feel our impact can be
            amplified by creating something new and different. For that reason,{" "}
            <strong>we’ll be retiring Tract effective January 1, 2023</strong>.
          </Text>
          <Text>
            For Tract, retirement doesn't mean an ending, but rather the start
            of something new. While new signups and submissions will no longer
            be accepted, our entire video library will be available here as our
            team works toward the next chapter.
          </Text>
          <Text>
            For those who have been here with us the past 2 years, I just want
            to say thank you for trusting our team and being part of this
            community. While today’s version of Tract will no longer be
            available, our team will continue to stay focused on our mission to
            help kids live happy, productive lives and are hard at work on the
            next product iteration.
          </Text>
          <Text>
            Education is about enabling students to understand the world around
            them and the talents within them, not about standardization and
            intense competition through high stakes testing. And so our work
            continues — stay tuned!
          </Text>
          <HStack spacing={3}>
            <Avatar src="https://firebasestorage.googleapis.com/v0/b/tract-learning.appspot.com/o/public%2Fteam%2Fari_memar.jpg?alt=media" />
            <Box>
              <Text fontWeight="bold">Ari Memar</Text>
              <Text color="gray.600">Co-founder &amp; CEO</Text>
            </Box>
          </HStack>
        </VStack>
      </Box>
    </ContainerMarketing>
  );
};
