import { FC, InputHTMLAttributes, useEffect, useState } from "react";

import {
  Box,
  ButtonLink,
  Divider,
  FormLabel,
  IconCheck,
  IconX,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "Shared";

import { OrganizationTypes } from "@tract/common/dist/types/models/Organization";
import { PlaceResult } from "@tract/common/dist/utils/parse-google-address";
import {
  useGooglePlaces,
  useOrgAvailability,
} from "Services/hooks/useGooglePlaces";
import { useIntercom } from "Services/intercom";
import { useCombobox } from "downshift";
import { InputProps } from "@chakra-ui/input";
import { FormLabelProps } from "@chakra-ui/form-control";

type OrganizationSearchInputProps = {
  inputLabel: string;
  inputProps: InputHTMLAttributes<InputProps> & InputProps;
  labelProps: FormLabelProps;
  schoolType?: OrganizationTypes;
  showHelp?: boolean;
  onSchoolSelect?: (school: PlaceResult) => void;
  onSchoolUnselect?: () => void;
  onAvailability?: (isAvailable: boolean, loadingAvailability: boolean) => void;
};

export const SchoolSearchInput: FC<OrganizationSearchInputProps> = ({
  inputLabel,
  inputProps,
  labelProps,
  schoolType,
  showHelp,
  onSchoolSelect,
  onSchoolUnselect,
  onAvailability,
}) => {
  const [searchText, setSearchText] = useState("");

  const [selectedOrganization, setSelectedOrganization] =
    useState<PlaceResult | null>(null);

  const [isAvailable, loadingAvailability] = useOrgAvailability(
    selectedOrganization?.place_id || null
  );

  useEffect(() => {
    if (onAvailability) onAvailability(!!isAvailable, !!loadingAvailability);
  }, [onAvailability, isAvailable, loadingAvailability]);

  const [results, loading] = useGooglePlaces(
    selectedOrganization ? null : searchText,
    schoolType || OrganizationTypes.School
  );

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    items: results || [],
    itemToString: (item) => item?.name || "",
    onInputValueChange: ({ inputValue, selectedItem }) => {
      setSearchText(inputValue || "");
      if (inputValue !== selectedItem?.name) {
        setSelectedOrganization(null);
        if (onSchoolUnselect) onSchoolUnselect();
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        closeMenu();
        setSelectedOrganization(selectedItem);
        if (onSchoolSelect) onSchoolSelect(selectedItem);
      }
    },
  });

  const { show } = useIntercom();

  function showIntercom() {
    show();
    closeMenu();
  }

  return (
    <>
      <FormLabel {...labelProps} {...getLabelProps()}>
        {inputLabel}
      </FormLabel>
      <InputGroup size="lg" {...getComboboxProps()}>
        <Input
          size="lg"
          autoComplete="off"
          {...inputProps}
          onFocus={() => openMenu()}
          onBlur={() => closeMenu()}
          {...getInputProps()}
        />
        <InputRightElement>
          {loading || loadingAvailability ? (
            <Spinner size="sm" color="brand" emptyColor="gray.200" />
          ) : (
            !loadingAvailability &&
            !!selectedOrganization &&
            (isAvailable ? <IconCheck color="green" /> : <IconX color="red" />)
          )}
        </InputRightElement>
      </InputGroup>
      <Box {...getMenuProps()}>
        {isOpen && results && (
          <Box
            mt={2}
            width="full"
            position="absolute"
            zIndex={99}
            bgColor="white"
            textAlign="left"
            fontWeight="medium"
            boxShadow="lg"
            maxH="225px"
            overflowY="auto"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="xl"
            py={3}
          >
            {results?.map((item, index) => (
              <Box
                py={3}
                px={4}
                cursor="pointer"
                userSelect="none"
                bgColor={highlightedIndex === index ? "gray.100" : "white"}
                key={item.place_id}
                {...getItemProps({ item, index })}
              >
                <Text>{item.name}</Text>
                <Text fontSize="sm" color="gray.600">
                  {item.formatted_address}
                </Text>
              </Box>
            ))}
            {showHelp && (
              <>
                {results?.length !== 0 && <Divider my={2} />}
                <Box px={4} py={3}>
                  <Text fontWeight="normal">
                    Can't find your school? Try including a postal code or
                    address.{" "}
                    <ButtonLink
                      data-id="support-btn"
                      onClick={showIntercom}
                      color="brand"
                    >
                      Contact Support
                    </ButtonLink>
                  </Text>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
