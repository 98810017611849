import { gql } from "@apollo/client";

import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";

import { USER_TYPE_FRAGMENT } from "Types/User";

export const USER_SOCIAL_ROW_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment UserSocial on user {
    id: firestoreId
    username
    avatar
    followers(where: { sourceUserId: { _eq: $currentUserId } }) {
      sourceUserId
      targetUserId
    }
    ...UserType
    ...UserPrivacyScopes
  }
`;
