import { AdminDashboardUserAccountFragment } from "@tract/common/dist/graphql";
import { PageHeader } from "Components/PageHeader";
import { FC } from "react";
import { EmptyState } from "Shared";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

export const UserPaths: FC<Props> = ({ user }) => {
  return (
    <>
      <PageHeader title="Paths" />
      <EmptyState headline="Coming soon!" />
    </>
  );
};
