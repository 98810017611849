import { gql } from "@urql/core";

export const ADMIN_EDUCATOR_CODE_FRAGMENT = gql`
  fragment AdminDashboardEducatorCode on educator_code {
    code
    available
    expiresAt
    createdAt
  }
`;

export const ADMIN_LEARNER_GROUP_MEMBER_FRAGMENT = gql`
  fragment AdminLearnerGroupMember on learner_group_member {
    learnerGroupId
    userId
    isOwner
    role
    user {
      id: firestoreId
      firstName
      lastName
      email
      avatar
      userType
      isEducator
      username
      teacher {
        id: userId
        displayName
      }
    }
  }
`;

export const ADMIN_LEARNER_GROUP_FRAGMENT = gql`
  ${ADMIN_EDUCATOR_CODE_FRAGMENT}
  ${ADMIN_LEARNER_GROUP_MEMBER_FRAGMENT}

  fragment AdminDashboardLearnerGroup on learner_group {
    id
    name
    members {
      ...AdminLearnerGroupMember
    }
    educatorCodes(order_by: { createdAt: desc }) {
      ...AdminDashboardEducatorCode
    }
    archivedAt
  }
`;

export const ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY = gql`
  ${ADMIN_LEARNER_GROUP_FRAGMENT}

  query AdminDashboardClassroomsByTeacherId($teacherUserId: String!) {
    learnerGroups: learner_group(
      where: {
        members: { role: { _eq: "teacher" }, userId: { _eq: $teacherUserId } }
      }
      order_by: { createdAt: desc }
    ) {
      ...AdminDashboardLearnerGroup
    }
  }
`;

export const ADMIN_CREATE_CLASSROOM_MUTATION = gql`
  ${ADMIN_LEARNER_GROUP_FRAGMENT}

  mutation AdminDashboardCreateClassroom(
    $name: String!
    $numStudents: Int!
    $orgId: String!
    $userId: String!
    $code: String!
    $color: String!
  ) {
    adminCreateClassroom: insert_learner_group_one(
      object: {
        name: $name
        orgId: $orgId
        type: "Classroom"
        members: { data: { isOwner: true, userId: $userId, role: "teacher" } }
        educatorCodes: { data: { available: $numStudents, code: $code } }
        color: $color
      }
    ) {
      ...AdminDashboardLearnerGroup
    }
  }
`;

export const ADMIN_CREATE_EDUCATOR_CODE_MUTATION = gql`
  ${ADMIN_EDUCATOR_CODE_FRAGMENT}

  mutation AdminDashboardCreateEducatorCode(
    $code: String!
    $available: Int!
    $learnerGroupId: uuid!
  ) {
    adminCreateClassroomCode: insert_educator_code_one(
      object: {
        available: $available
        code: $code
        learnerGroupId: $learnerGroupId
      }
    ) {
      ...AdminDashboardEducatorCode
    }
  }
`;

export const ADMIN_DELETE_EDUCATOR_CODE_MUTATION = gql`
  mutation AdminDashboardDeleteEducatorCode($code: String!) {
    adminDeleteClassroomCode: delete_educator_code_by_pk(code: $code) {
      code
    }
  }
`;

export const ADMIN_ADD_CO_TEACHER_MUTATION = gql`
  ${ADMIN_LEARNER_GROUP_MEMBER_FRAGMENT}

  mutation AdminAddCoTeacher($learnerGroupId: uuid!, $userId: String!) {
    adminAddCoTeacher: insert_learner_group_member_one(
      object: {
        learnerGroupId: $learnerGroupId
        userId: $userId
        role: "teacher"
      }
    ) {
      ...AdminLearnerGroupMember
    }
  }
`;

export const ADMIN_REMOVE_CO_TEACHER_MUTATION = gql`
  mutation AdminRemoveCoTeacher($learnerGroupId: uuid!, $userId: String!) {
    adminRemoveCoTeacher: delete_learner_group_member(
      where: {
        learnerGroupId: { _eq: $learnerGroupId }
        userId: { _eq: $userId }
      }
    ) {
      returning {
        learnerGroupId
        userId
      }
    }
  }
`;

export const ADMIN_ARCHIVE_CLASSROOM_MUTATION = gql`
  mutation AdminArchiveClassroom(
    $learnerGroupId: uuid!
    $archivedAt: timestamptz!
  ) {
    update_learner_group_by_pk(
      pk_columns: { id: $learnerGroupId }
      _set: { archivedAt: $archivedAt }
    ) {
      id
      archivedAt
    }
  }
`;
