import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  WheelEvent,
} from "react";
import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import * as Yup from "yup";

import {
  Box,
  ButtonSolid,
  FileUploader,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Flex,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  Progress,
  Switch,
  Select,
  AspectRatio,
  ExternalLink,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  VStack,
  Markdown,
} from "Shared";

import { PrizeFormValues } from "./index";
import { ShopCategory } from "Types/Prize";
import {
  AdminShopItemFragment,
  AdminShopItemOwnerFragment,
} from "@tract/common/dist/graphql";
import { useUserFileUpload } from "Services/useUserFileUpload";
import { UserFile } from "Utils/UserFile";

type Props = {
  item?: AdminShopItemFragment;
  owners?: AdminShopItemOwnerFragment[];
  onSubmit: (prize: PrizeFormValues) => void;
};

const PrizeSchema = Yup.object().shape({
  title: Yup.string().required("Please enter a title"),
  description: Yup.string().max(
    500,
    "Description must be less than 500 characters"
  ),
  cost: Yup.number()
    .integer("Must be a whole number/integer")
    .required("Please enter a cost")
    .min(50, "Cost must be higher than 50")
    .max(500000, "Cost must not exceed 500,000"),
  cover: Yup.string(),
  category: Yup.string().required(),
  newCoverFileId: Yup.string(),
  detailsURL: Yup.string().url("Please enter a valid URL"),
  disabled: Yup.boolean(),
  quantity: Yup.number().integer("Must be a whole number/integer").optional(),
  successText: Yup.string()
    .optional()
    .max(500, "Success text must be less than 500 characters"),
  successActionURL: Yup.string().url("Please enter a valid URL").optional(),
  successActionText: Yup.string()
    .optional()
    .max(25, "Success action text must be less than 25 characters"),
  ownerId: Yup.string(),
  successActionRequired: Yup.boolean(),
});

const maxFileSize = 1024 * 1024 * 5;
const categories = Object.values(ShopCategory);

export const PrizeForm = forwardRef<FormikProps<PrizeFormValues>, Props>(
  ({ item, owners, onSubmit }, ref) => {
    const fileRef = useRef<any>(null);
    const [coverPreview, setCoverPreview] = useState<string | null>(null);

    const {
      result: uploadResult,
      handleProgress,
      handleUploadError,
      handleUploadStart,
      handleUploadSuccess,
      isUploading,
      progress,
      storageRef,
    } = useUserFileUpload();

    const handleFileSelect = (event: Event) => {
      const fileInput = event.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length === 1) {
        const file = fileInput.files[0];

        if (file.size <= maxFileSize && file.type.startsWith("image/")) {
          fileRef.current.startUpload(file);
        }
      }
    };

    // Legacy image format
    useEffect(() => {
      const coverFile = new UserFile(item?.coverFile);

      if (uploadResult) {
        setCoverPreview(uploadResult.fileUrl);
      } else if (item?.coverFile?.transforms) {
        setCoverPreview(coverFile.getTransformedUrl("thumb"));
      } else if (item?.coverFile?.id) {
        setCoverPreview(coverFile.getOriginalUrl());
      } else if (item?.media?.cover?.files[0] && !coverPreview) {
        setCoverPreview(item.media.cover.files[0]);
      }
    }, [item, coverPreview, uploadResult]);

    return (
      <Box mb={16}>
        <Formik
          validationSchema={PrizeSchema}
          initialValues={{
            title: item?.name || "",
            description: item?.description || "",
            cost: item?.cost || 0,
            cover: undefined,
            newCoverFileId: undefined,
            category: item?.tags?.[0] || "",
            detailsURL: item?.externalURL || "",
            disabled: !!item?.disabled,
            quantity:
              typeof item?.quantity === "number" ? item.quantity : undefined,
            successText: item?.metadata?.successText || "",
            successActionURL: item?.metadata?.successActionURL || "",
            successActionText: item?.metadata?.successActionText || "",
            ownerId: item?.ownerId || "",
            successActionRequired:
              item?.metadata?.successActionRequired || false,
          }}
          onSubmit={onSubmit}
          innerRef={ref}
        >
          {({ setFieldValue, values }) => (
            <VStack
              as={Form}
              spacing={6}
              justify="stretch"
              noValidate
              autoComplete="off"
            >
              <Field name="disabled">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id={field.name}>
                    <FormLabel>Publish</FormLabel>
                    <Switch
                      name={field.name}
                      defaultChecked={item ? !item.disabled : false}
                      isChecked={!field.value}
                      onChange={() => {
                        setFieldValue(field.name, !field.value);
                      }}
                      size="lg"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="title">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id={field.name}
                    isInvalid={submitCount > -1 && !!error}
                    isRequired
                  >
                    <FormLabel>Title</FormLabel>
                    <Input {...field} size="lg" />
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id="description"
                    isInvalid={submitCount > -1 && !!error}
                  >
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      {...field}
                      name="description"
                      size="lg"
                      height="200px"
                    />
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="cost">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id="cost"
                    isInvalid={submitCount > -1 && !!error}
                    isRequired
                  >
                    <FormLabel>Cost</FormLabel>
                    <Input
                      {...field}
                      name="cost"
                      size="lg"
                      onWheel={(e: WheelEvent<HTMLInputElement>) =>
                        e.currentTarget.blur()
                      }
                      inputMode="numeric"
                      pattern="[0-9]*"
                    />
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <FormControl>
                <FormLabel>Unlimited Quantity</FormLabel>
                <Switch
                  isChecked={typeof values.quantity !== "number"}
                  onChange={(e) => {
                    setFieldValue("quantity", e.target.checked ? undefined : 0);
                  }}
                  size="lg"
                />
              </FormControl>

              {typeof values.quantity === "number" && (
                <Field name="quantity">
                  {({
                    field,
                    form: { submitCount },
                    meta: { error },
                  }: FieldProps) => (
                    <FormControl
                      id={field.name}
                      isInvalid={submitCount > -1 && !!error}
                    >
                      <FormLabel>Quantity</FormLabel>
                      <Input
                        type="number"
                        {...field}
                        name={field.name}
                        size="lg"
                        onWheel={(e: WheelEvent<HTMLInputElement>) =>
                          e.currentTarget.blur()
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                      <FormErrorMessage>{error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}

              <Field name="category">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id={field.name} isRequired isInvalid={!!error}>
                    <FormLabel>Category</FormLabel>
                    <Select
                      onChange={field.onChange}
                      name={field.name}
                      value={field.value}
                      placeholder="Choose a category"
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="cover">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id="cover" isInvalid={!!error}>
                    <FormLabel>Cover Image</FormLabel>
                    <ButtonSolid as="label">
                      Choose Image
                      <FileUploader
                        accept="image"
                        ref={fileRef}
                        onChange={handleFileSelect}
                        hidden
                        id={field.name}
                        name={field.name}
                        storageRef={storageRef}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={async (filename: string) => {
                          setFieldValue(field.name, filename);
                          const result = await handleUploadSuccess(filename);
                          setFieldValue("newCoverFileId", result.fileId);
                        }}
                        onProgress={handleProgress}
                      />
                    </ButtonSolid>
                    {coverPreview && (
                      <HStack spacing={8} my={4}>
                        <Box width="100px" height="100px">
                          <AspectRatio ratio={1}>
                            <Image
                              src={coverPreview}
                              borderRadius={8}
                              fit="cover"
                            />
                          </AspectRatio>
                        </Box>
                        {isUploading && (
                          <Box
                            borderRadius="12px"
                            bg="gray.50"
                            p={4}
                            width="100%"
                          >
                            <Flex align="center" flex={1}>
                              <Progress
                                bg="gray.200"
                                height="16px"
                                value={progress}
                                colorScheme="green"
                                flex="1"
                              />
                              <Text
                                ml={4}
                                color="gray.600"
                                fontWeight="700"
                                fontSize="16px"
                              >
                                {progress}%
                              </Text>
                            </Flex>
                          </Box>
                        )}
                      </HStack>
                    )}
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="detailsURL">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id="detailsURL"
                    isInvalid={submitCount > -1 && !!error}
                  >
                    <FormLabel>Additional Information URL</FormLabel>
                    <Input {...field} name="detailsURL" size="lg" />
                    <FormHelperText>
                      External URL with additional details about the prize
                    </FormHelperText>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="successText">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <>
                    <FormLabel w="full">Success Text</FormLabel>
                    <Tabs size="md" mb={4} w="full">
                      <TabList>
                        <Tab>Write</Tab>
                        <Tab>Preview</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel pt={4}>
                          <FormControl
                            id="successText"
                            isInvalid={submitCount > -1 && !!error}
                          >
                            <Textarea
                              {...field}
                              name="successText"
                              size="lg"
                              height="200px"
                            />
                            <FormErrorMessage>{error}</FormErrorMessage>
                            <FormHelperText>
                              For the success modal when a learner buys an item.
                              <ExternalLink
                                href="https://www.markdownguide.org/cheat-sheet/"
                                target="_blank"
                                color="brand"
                                fontWeight="bold"
                                ml={2}
                              >
                                Markdown Supported
                              </ExternalLink>
                            </FormHelperText>
                          </FormControl>
                        </TabPanel>
                        <TabPanel pt={6} fontWeight="normal">
                          {field.value.length ? (
                            <Markdown
                              source={field.value || ""}
                              preset="mini"
                            />
                          ) : (
                            <Text color="gray.400">Description is empty.</Text>
                          )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </>
                )}
              </Field>
              <Field name="successActionURL">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id="successActionURL"
                    isInvalid={submitCount > -1 && !!error}
                  >
                    <FormLabel>Success Action URL</FormLabel>
                    <Input {...field} name="successActionURL" size="lg" />
                    <FormHelperText>
                      External URL with additional details about claiming the
                      order
                    </FormHelperText>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="successActionText">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id={field.name}
                    isInvalid={submitCount > -1 && !!error}
                    isRequired={!!item?.metadata?.successActionURL}
                  >
                    <FormLabel>Success Action Button Text</FormLabel>
                    <Input {...field} size="lg" />
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="successActionRequired">
                {({ field, meta: { error } }: FieldProps) => (
                  <FormControl id={field.name}>
                    <FormLabel>Success Action Required</FormLabel>
                    <Switch
                      name={field.name}
                      defaultChecked={
                        item ? item?.metadata?.successActionRequired : false
                      }
                      isChecked={field.value}
                      onChange={() => {
                        setFieldValue(field.name, !field.value);
                      }}
                      size="lg"
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="ownerId">
                {({
                  field,
                  form: { submitCount },
                  meta: { error },
                }: FieldProps) => (
                  <FormControl
                    id={field.name}
                    isInvalid={submitCount > -1 && !!error}
                    mb={6}
                  >
                    <FormLabel>Owner</FormLabel>
                    <Select {...field} size="lg">
                      <option value="">Unassigned</option>
                      {owners && owners?.length > 0
                        ? owners.map((owner) => {
                            return (
                              <option
                                key={owner.firestoreId}
                                value={owner.firestoreId}
                              >
                                {owner.firstName} {owner.lastName} (
                                {owner.email})
                              </option>
                            );
                          })
                        : null}
                    </Select>
                    <FormErrorMessage>{error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <ButtonSolid type="submit" display="none">
                Submit
              </ButtonSolid>
            </VStack>
          )}
        </Formik>
      </Box>
    );
  }
);
