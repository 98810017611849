import React, { useEffect, useRef, useState } from "react";
import { formatDistanceStrict } from "date-fns";

import {
  Box,
  Button,
  HStack,
  useDisclosure,
  Text,
  useToast,
  Flex,
  IconChat,
} from "Shared";

import {
  GetNodeCommentReplyCountQuery,
  GetNodeCommentReplyCountQueryVariables,
  NodeCommentFragment,
} from "@tract/common/dist/graphql";

import { CommentList } from "./CommentList";
import { CommentForm, Values as CommentFormValues } from "./CommentForm";
import {
  CommentEditForm,
  Values as CommentEditFormValues,
} from "./CommentEditForm";
import { captureException } from "Services/errors";
import { UsernameLink } from "Components/UsernameLink";
import { CommentMenu, DeleteConfirmModal } from "./CommentMenu";
import { getDisplayName, isParentUser, isTeacherUser } from "Types/User";
import { useSession } from "Services/session";
import { ProfileBadges } from "Components/ProfileBadges";
import { ProfilePopover } from "Components/ProfilePopover";
import { UserAvatar } from "Components/UserAvatar";
import { useQuery } from "urql";
import { GET_NODE_COMMENT_REPLY_COUNT } from "./graphql";

type Props = {
  nodeComment: NodeCommentFragment;
  createCommentHandler: (v: CommentFormValues) => Promise<void>;
  editCommentHandler: (
    v: CommentEditFormValues,
    nodeComment: NodeCommentFragment
  ) => Promise<void>;
  deleteCommentHandler: (nodeComment: NodeCommentFragment) => Promise<void>;
  createCommentErrorHandler: any;
};

const MAX_DEPTH = 2;

export const CommentItem: React.FC<Props> = ({
  nodeComment,
  createCommentHandler,
  createCommentErrorHandler,
  editCommentHandler,
  deleteCommentHandler,
}) => {
  const toast = useToast();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [editing, setEditing] = useState(false);
  const menuTriggerRef = useRef(null);
  const replyFocusRef = useRef<HTMLButtonElement>(null);
  const [focusOnReplyButton, setFocusOnReplyButton] = useState(false);
  const [replyCount, setReplyCount] = useState(0);

  const { currentUser, currentKidIds, isAdmin } = useSession();
  const isTeacher = isTeacherUser(currentUser);
  const isUsersKid = isTeacher
    ? currentKidIds.includes(nodeComment.comment.user.id)
    : false;
  const isOwn = currentUser.uid === nodeComment.comment.user.id;
  const canEdit = isAdmin || isOwn;

  const { comment } = nodeComment;
  const depth = comment.path.split(".").length;

  const [{ data }] = useQuery<
    GetNodeCommentReplyCountQuery,
    GetNodeCommentReplyCountQueryVariables
  >({
    query: GET_NODE_COMMENT_REPLY_COUNT,
    variables: {
      nodeId: nodeComment.pathNodeId,
      matches: `${comment.path}.*{1}`,
    },
    pause: depth !== 1,
  });

  const {
    isOpen: isDeleteConfirmModalOpen,
    onOpen: openDeleteConfirmModal,
    onClose: closeDeleteConfirmModal,
  } = useDisclosure();

  const handleEditComment = async (values: CommentEditFormValues) => {
    await editCommentHandler(values, nodeComment);
    setEditing(false);
  };

  const handleDeleteComment = async () => {
    await deleteCommentHandler(nodeComment);
  };

  const handleNestedDelete = async (nodeComment: NodeCommentFragment) => {
    await deleteCommentHandler(nodeComment);
    setReplyCount(replyCount - 1);
  };

  useEffect(() => {
    if (!!data?.path_node_comment_aggregate.aggregate?.count) {
      setReplyCount(data.path_node_comment_aggregate.aggregate.count);
    }
  }, [data?.path_node_comment_aggregate.aggregate?.count, setReplyCount]);

  const onToggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const createHandler = async (values: CommentFormValues) => {
    await createCommentHandler(values);
    setShowReplyForm(false);
    setReplyCount(replyCount + 1);
    setShowReplies(true);
  };

  const cancelReply = () => {
    setShowReplyForm(false);
    setFocusOnReplyButton(true);
  };

  useEffect(() => {
    if (focusOnReplyButton) {
      replyFocusRef?.current?.focus();
      setFocusOnReplyButton(false);
    }
  }, [focusOnReplyButton, setFocusOnReplyButton]);

  const displayName = getDisplayName(comment.user);

  return (
    <Box mt={6}>
      <HStack spacing={4} alignItems="top">
        <ProfilePopover
          userId={comment.user.id}
          triggerElement={
            <UserAvatar
              userId={comment.user.id || ""}
              username={comment.user.username || ""}
              avatarURL={comment.user.avatar || ""}
              size="md"
              isTeacher={isTeacherUser(comment.user)}
              isParent={isParentUser(comment.user)}
              name={displayName || ""}
            />
          }
        />
        <Flex direction="column" alignItems="flex-start" width="full">
          <Flex
            direction="row"
            justifyContent="space-between"
            width="full"
            mb={1}
          >
            <HStack spacing={3}>
              <HStack spacing={1}>
                <ProfilePopover
                  userId={comment.user.id}
                  triggerElement={
                    <UsernameLink
                      username={comment.user.username || "UNKNOWN"}
                      userId={comment.user.id || ""}
                      fontWeight="bold"
                      color="gray.900"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      isTeacher={isTeacherUser(comment.user)}
                      isParent={isParentUser(comment.user)}
                    >
                      {displayName}
                    </UsernameLink>
                  }
                />
                {!!comment.user && (
                  <ProfileBadges
                    iconSize={5}
                    user={comment.user}
                    spacing={1}
                    flagFont="md"
                  />
                )}
              </HStack>
              <Text color="gray.600">
                {formatDistanceStrict(new Date(comment.createdAt), new Date(), {
                  addSuffix: true,
                })}
              </Text>
            </HStack>
            {(canEdit || isUsersKid) && (
              <CommentMenu
                nodeComment={nodeComment}
                menuTriggerRef={menuTriggerRef}
                onSelectDelete={openDeleteConfirmModal}
                onToggleEditing={() => setEditing(!editing)}
              />
            )}
          </Flex>
          <Box
            mb={editing || showReplyForm ? 4 : 2}
            mt={editing ? 2 : undefined}
            width="full"
          >
            {!editing && (
              <Text size="xl" whiteSpace="pre-line">
                {comment.text || ""}
              </Text>
            )}
            {editing && (
              <CommentEditForm
                text={comment.text || ""}
                submitHandler={handleEditComment}
                onCancel={() => setEditing(false)}
                errorHandler={(err) => {
                  captureException(err);
                  toast({
                    status: "error",
                    title: "Error editing comment",
                  });
                }}
              />
            )}
          </Box>
          {depth < MAX_DEPTH && !showReplyForm && (
            <Button
              variant="link"
              size="sm"
              onClick={() => setShowReplyForm(true)}
              mb={2}
              ref={replyFocusRef}
            >
              <IconChat width={4} color="gray.900" />
              <Text ml={2} color="gray.900" fontSize="sm" fontWeight="bold">
                Reply
              </Text>
            </Button>
          )}
          {showReplyForm && (
            <CommentForm
              parentPath={comment.path}
              parentCommentAuthorId={comment.user.id}
              submitHandler={createHandler}
              errorHandler={createCommentErrorHandler}
              onCancel={cancelReply}
            />
          )}
          {!!replyCount && (
            <Button
              variant="link"
              fontSize="sm"
              fontWeight="bold"
              display="flex"
              justifyContent="left"
              color="brand"
              onClick={onToggleReplies}
            >
              {showReplies ? "Hide" : "View"} {replyCount} Replies
            </Button>
          )}
          {showReplies && (
            <Box width="full">
              <CommentList
                nodeId={nodeComment.pathNodeId}
                parentPath={comment.path}
                handleCreateComment={createHandler}
                handleCommentError={createCommentErrorHandler}
                handleEditComment={editCommentHandler}
                handleDeleteComment={handleNestedDelete}
                count={replyCount}
              />
            </Box>
          )}
        </Flex>
      </HStack>
      <DeleteConfirmModal
        menuTriggerRef={menuTriggerRef}
        isOpen={isDeleteConfirmModalOpen}
        onClose={closeDeleteConfirmModal}
        onDelete={handleDeleteComment}
      />
    </Box>
  );
};
