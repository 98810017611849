import { BoxProps } from "@chakra-ui/layout";
import { ReactComponent as IconCoinStacked } from "assets/icons/coin-stacked.svg";
import { SVGProps } from "react";
import { Box } from "Shared";

type CoinIconProps = {
  size?: number;
};

export const CoinStackedIcon = ({
  size = 8,
  ...props
}: CoinIconProps & BoxProps & SVGProps<SVGSVGElement>) => (
  <Box
    as={IconCoinStacked}
    width={size}
    height={size}
    display="inline-block"
    {...props}
  />
);
