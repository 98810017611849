import { gql } from "urql";

import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

export const PATH_COLLECTION_FRAGMENT = gql`
  ${PATH_CARD_FRAGMENT}

  fragment PathCollection on path_collection {
    id
    title
    description
    pathCollectionItems {
      collectionId
      pathId
      path {
        ...PathCard
      }
    }
  }
`;
