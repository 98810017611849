import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { ContainerMarketing } from "Components/LayoutMarketing";
import { LayoutCentered } from "Components/LayoutCentered";
import { Box, Text, Markdown, useDisclosure } from "Shared";
// @ts-ignore
import filePath from "./tract_community_guidelines.md";
import { ErrorModal } from "Components/ErrorModal";
import { captureException } from "Services/errors";

function Header() {
  return (
    <Box pt={32} pb={10}>
      <Text as="h1" fontSize="4xl" fontWeight="bold" color="gray.900">
        Community Guidelines
      </Text>
    </Box>
  );
}

export function CommunityGuidelines() {
  const [content, setContent] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    async function getFile() {
      try {
        setIsLoading(true);
        const response = await fetch(filePath);
        const text = await response.text();
        setContent(text);
        setIsLoading(false);
      } catch (e) {
        captureException(e);
        setIsLoading(false);
        onOpen();
      }
    }
    getFile();
  }, [onOpen]);

  if (isLoading) {
    return <LayoutCentered isLoading={isLoading} />;
  }

  return (
    <>
      <ErrorModal
        onClose={onClose}
        isOpen={isOpen}
        onRetry={onClose}
      ></ErrorModal>
      <ContainerMarketing maxWidth="960px" pb={24}>
        <Helmet>
          <title>Community Guidelines — Tract</title>
        </Helmet>
        <Header />
        {content && <Markdown source={content} />}
      </ContainerMarketing>
    </>
  );
}
