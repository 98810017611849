import { NodeCommentFragment } from "@tract/common/dist/graphql";
import React, { useState } from "react";
import { useSession } from "Services/session";
import {
  Text,
  IconButton,
  Flex,
  Menu,
  MenuButton,
  IconMoreVertical,
  MenuList,
  MenuItem,
  IconEdit,
  IconTrash,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ButtonOutlined,
  ButtonSolid,
  HStack,
} from "Shared";
import { isTeacherUser } from "Types/User";

type CommentMenuProps = {
  nodeComment: NodeCommentFragment;
  menuTriggerRef: React.RefObject<HTMLButtonElement>;
  onSelectDelete: () => void;
  onToggleEditing: () => void;
};

export const CommentMenu: React.FC<CommentMenuProps> = ({
  nodeComment,
  menuTriggerRef,
  onSelectDelete,
  onToggleEditing,
}) => {
  const { currentUser, isAdmin, currentKidIds } = useSession();
  const isTeacher = isTeacherUser(currentUser);
  const isUsersKid = isTeacher
    ? currentKidIds.includes(nodeComment.comment.user.id)
    : false;
  const isOwn = currentUser.uid === nodeComment.comment.user.id;
  const canEdit = isAdmin || isOwn;

  return (
    <Menu placement="left-start">
      <MenuButton
        as={IconButton}
        aria-label="open comment menu"
        icon={<IconMoreVertical width={4} height={4} />}
        variant="ghost"
        size="sm"
        ref={menuTriggerRef}
      />
      <MenuList width={64}>
        {canEdit && (
          <>
            <MenuItem
              cursor="pointer"
              as={Flex}
              align="center"
              px={6}
              onClick={onToggleEditing}
            >
              <IconEdit />
              <Text ml={6} color="gray.900">
                Edit
              </Text>
            </MenuItem>
          </>
        )}
        {(canEdit || isUsersKid) && (
          <MenuItem
            cursor="pointer"
            as={Flex}
            align="center"
            px={6}
            onClick={onSelectDelete}
          >
            <IconTrash />
            <Text ml={6} color="gray.900">
              Delete
            </Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  menuTriggerRef: React.RefObject<HTMLElement>;
};

export const DeleteConfirmModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  menuTriggerRef,
}) => {
  const [deleting, setDeleting] = useState(false);

  const selectDelete = async () => {
    setDeleting(true);
    await onDelete();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      finalFocusRef={menuTriggerRef}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="left">Delete Comment?</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this comment and all its replies?
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined onClick={onClose} disabled={deleting}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            onClick={selectDelete}
            isLoading={deleting}
            colorScheme="red"
          >
            Yes, Delete
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
