import { gql } from "@apollo/client";

import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";
import { CLASSROOM_LEARNER_GROUP_FRAGMENT } from "@tract/common/dist/graphql/fragments/classroom-learner-group";

import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";
import { USER_FILE_FRAGMENT } from "Utils/UserFile";

gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  query ClassroomLearnerGroup($id: uuid!) {
    learnerGroup: learner_group_by_pk(id: $id) {
      ...ClassroomLearnerGroup
    }
  }
`;

export const CLASSROOM_BY_ID_QUERY = gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  query ClassroomById($classId: uuid!) {
    learnerGroups: learner_group(
      where: { id: { _eq: $classId }, archivedAt: { _eq: "-infinity" } }
    ) {
      ...ClassroomLearnerGroup
    }
  }
`;

export const CLASSROOMS_BY_TEACHER_ID_QUERY = gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  query ClassroomsByTeacherId($teacherUserId: String!) {
    learnerGroups: learner_group(
      where: {
        members: { role: { _eq: "teacher" }, userId: { _eq: $teacherUserId } }
        archivedAt: { _eq: "-infinity" }
      }
      order_by: { createdAt: desc }
    ) {
      ...ClassroomLearnerGroup
    }
  }
`;

export const CLASSROOM_PATHS_QUERY = gql`
  ${PATH_CARD_FRAGMENT}

  query ClassroomPaths(
    $offset: Int!
    $limit: Int!
    $visibility: String!
    $authorIds: [String!]
    $isPublished: Boolean!
  ) {
    paths: path(
      limit: $limit
      offset: $offset
      where: {
        visibility: { _eq: $visibility }
        isPublished: { _eq: $isPublished }
        authorId: { _in: $authorIds }
      }
      order_by: { publishedAt: desc }
    ) {
      ...PathCard
    }
  }
`;

export const CLASSROOM_MEMBER_TEACHERS_QUERY = gql`
  query ClassroomMemberTeachers($classId: uuid!, $teacherIds: [String!]!) {
    learner_group_member(
      where: { learnerGroupId: { _eq: $classId }, userId: { _in: $teacherIds } }
    ) {
      learnerGroupId
      userId
      isOwner
      role
      user {
        id: firestoreId
        avatar
        firstName
        lastName
        email
        isEducator
        userType
        teacher {
          id: userId
          displayName
        }
      }
    }
  }
`;

// TODO: Move this to backend to resolve privacy
export const CLASSROOM_PROJECTS_QUERY = gql`
  ${PROJECT_CARD_FRAGMENT}

  query ClassroomProjects(
    $userIds: [String!]!
    $limit: Int!
    $offset: Int = 0
    $isPrivate: Boolean_comparison_exp
  ) {
    projects: project(
      limit: $limit
      offset: $offset
      where: {
        userId: { _in: $userIds }
        file: { transforms: {} }
        rejectedAt: { _eq: "-infinity" }
        isPrivate: $isPrivate
        published: { _eq: true }
        _or: [
          { user: { projectPrivacy: { _in: ["private", "school"] } } }
          { user: { projectPrivacy: { _is_null: true } } }
        ]
      }
      order_by: { createdAt: desc }
    ) {
      ...projectCard
    }
  }
`;

export const UPDATE_CLASSROOM_SETTINGS = gql`
  mutation UpdateClassroomSettings(
    $classId: uuid!
    $name: String!
    $color: String
    $privacyScope: String
  ) {
    updatePrivacy: api_update_learner_group_settings(
      input: {
        classId: $classId
        privacyScope: $privacyScope
        name: $name
        color: $color
      }
    ) {
      name
      color
      classId
      privacyScope
    }
  }
`;

export const CLASSROOM_ASSIGNMENT_FRAGMENT = gql`
  ${USER_FILE_FRAGMENT}

  fragment ClassroomAssignment on assignment_learner_group {
    assignmentId
    learnerGroupId
    isFinished
    assignment {
      id
      title
      description
      dueDate
      startDate
      paths {
        assignmentId
        pathId
        path {
          id
          coverFile {
            ...UserFile
          }
        }
      }
    }
  }
`;

export const CLASSROOM_ASSIGNMENTS_QUERY = gql`
  ${CLASSROOM_ASSIGNMENT_FRAGMENT}

  query ClassroomAssignments(
    $where: assignment_learner_group_bool_exp
    $orderBy: [assignment_learner_group_order_by!]
  ) {
    assignment_learner_group(where: $where, order_by: $orderBy) {
      ...ClassroomAssignment
    }
  }
`;

export const CLASSROOM_ASSIGNMENT_MARK_FINISHED_MUTATION = gql`
  mutation ClassroomAssignmentMarkFinished(
    $assignmentId: uuid!
    $classId: uuid!
    $isFinished: Boolean!
  ) {
    update_assignment_learner_group_by_pk(
      pk_columns: { assignmentId: $assignmentId, learnerGroupId: $classId }
      _set: { isFinished: $isFinished }
    ) {
      assignmentId
      learnerGroupId
      isFinished
    }
  }
`;

export const CLASSROOM_ASSIGNMENT_DETAILS_FRAGMENT = gql`
  ${PROJECT_CARD_FRAGMENT}
  fragment ClassroomAssignmentDetail on assignment {
    id
    title
    startDate
    dueDate
    paths {
      assignmentId
      pathId
      path {
        id
        nodes {
          id
          pathId
          title
          responsesEnabled
          projects(where: { userId: { _in: $learnerUserIds } }) {
            ...projectCard
          }
        }
      }
    }
  }
`;

export const CLASSROOM_ASSIGNMENT_DETAILS_QUERY = gql`
  ${CLASSROOM_ASSIGNMENT_DETAILS_FRAGMENT}

  query ClassroomAssignmentDetails(
    $assignmentId: uuid!
    $learnerUserIds: [String!]!
  ) {
    assignment: assignment_by_pk(id: $assignmentId) {
      ...ClassroomAssignmentDetail
    }
  }
`;

export const API_SYNC_ROSTER_MUTATION = gql`
  mutation SyncRoster(
    $classId: String!
    $accessToken: String!
    $learnerGroupId: String!
  ) {
    syncRoster: api_sync_roster(
      classId: $classId
      accessToken: $accessToken
      learnerGroupId: $learnerGroupId
    ) {
      invitesCount
    }
  }
`;
