import React from "react";
import { PathViewerNodeFragment } from "@tract/common/dist/graphql";

import {
  Divider,
  EmptyState,
  ExternalLink,
  HStack,
  IconFile,
  Stack,
} from "Shared";

import { UserFile } from "Utils/UserFile";
import { useSession } from "Services/session";

type Props = {
  node: PathViewerNodeFragment;
};

export const VideoResources: React.FC<Props> = ({ node }) => {
  const { isAdmin } = useSession();

  const videoFile = new UserFile(node.videoFile);

  if (!isAdmin || !videoFile.exists) {
    return <EmptyState headline="No Resources Provided" />;
  }

  return (
    <Stack spacing={6} divider={<Divider borderBottomWidth={0} />}>
      <HStack spacing={2}>
        <IconFile color="gray.600" />
        <ExternalLink
          color="brand"
          href={videoFile.getOriginalUrl()}
          target="_blank"
          download
        >
          Download Video
        </ExternalLink>
      </HStack>
    </Stack>
  );
};
