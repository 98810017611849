import { gql } from "urql";

export const COLLECTIONS_QUERY = gql`
  query CollectionsForAdmin {
    path_collection(
      where: { _not: { learnerGroupPathCollection: {} } }
      order_by: { title: asc }
    ) {
      id
      title
      description
      user {
        id: firestoreId
        username
        isEducator
        lastName
        firstName
        avatar
      }
      pathCollectionItems {
        pathId
        lexorank
      }
    }
  }
`;
