import { createIcon } from "@chakra-ui/react";

export const IconFilledPartner = createIcon({
  displayName: "IconFilledPartner",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path d="M7.723 4.5a3.513 3.513 0 002-.828 3.513 3.513 0 014.555 0c.563.48 1.263.77 2 .828A3.513 3.513 0 0119.5 7.723c.058.736.348 1.436.828 1.999a3.513 3.513 0 010 4.556 3.513 3.513 0 00-.828 2 3.514 3.514 0 01-3.222 3.222 3.514 3.514 0 00-2 .828 3.513 3.513 0 01-4.556 0 3.513 3.513 0 00-2-.828 3.513 3.513 0 01-3.221-3.223 3.514 3.514 0 00-.829-2 3.513 3.513 0 010-4.555c.48-.563.77-1.262.829-2A3.513 3.513 0 017.723 4.5zm8.525 6.018a1.146 1.146 0 00-1.62-1.62l-3.774 3.774-1.481-1.482a1.145 1.145 0 00-1.62 1.62l2.291 2.292a1.146 1.146 0 001.62 0l4.584-4.584z" />
  ),
});
