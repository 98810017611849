import React, { FC, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { useSession } from "Services/session";

import { PrivacyControls } from "Pages/Settings/PrivacyControls";
import { ProfileEdit } from "Pages/Settings/ProfileEdit";
import { Integrations } from "Pages/Settings/Integrations";

import { isKidUser } from "Types/User";

export const Settings: FC = () => {
  const { track } = useAnalytics();
  const { currentUser } = useSession();

  useEffect(() => {
    track(ANALYTICS_EVENTS.SETTINGS_VIEWED);
  }, [track]);

  return (
    <Switch>
      <Route path="/settings/profile" component={ProfileEdit} />
      {isKidUser(currentUser) && (
        <Route path="/settings/privacy" component={PrivacyControls} />
      )}
      <Route path="/settings/integrations" component={Integrations} />
      <Redirect to="/settings/profile" />
    </Switch>
  );
};
