import { UpdateResolver } from "@urql/exchange-graphcache";

export const delete_path_collection_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.invalidate(`path_collection:${args.id}`);
};
