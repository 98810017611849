import { gql } from "@apollo/client";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";
import { USER_DISPLAY_NAME_FRAGMENT } from "Types/User";

export const PATH_VIEWER_PROJECT_FRAGMENT = gql`
  fragment PathViewerProject on project {
    id
    text
    userId
    createdAt
    pathNodeId
    file {
      id
      userId
      filename
    }
  }
`;

export const PATH_VIEWER_NODE_FRAGMENT = gql`
  ${PATH_VIEWER_PROJECT_FRAGMENT}

  fragment PathViewerNode on path_node {
    id
    title
    caption
    lexorank
    responsesEnabled
    rewardAmount
    projects(
      where: { userId: { _eq: $userId }, rejectedAt: { _eq: "-infinity" } }
    ) {
      ...PathViewerProject
    }
    videoFile {
      id
      filename
      transforms {
        filename
      }
      integrations {
        foreignId
        source
      }
    }
    coverFile {
      id
      filename
      transforms {
        filename
      }
      integrations {
        foreignId
        source
      }
    }
  }
`;

const PATH_FRAGMENT = gql`
  ${PATH_VIEWER_NODE_FRAGMENT}
  ${USER_DISPLAY_NAME_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment PathViewerPath on path {
    id
    title
    description
    slug
    status
    authorId
    interestArea
    tags
    supplies
    publishedAt
    totalXP
    isVisibleUnauthed
    isTractOriginal
    gradeLevels
    subjects
    authors {
      pathId
      userId
      user {
        id: firestoreId
        username
        avatar
        ...UserDisplayName
        ...UserPrivacyScopes
      }
    }
    user {
      id: firestoreId
      avatar
      username
      firstName
      lastName
      ...UserType
      ...UserPrivacyScopes
    }
    trailer {
      id
      filename
      transforms {
        filename
      }
      integrations {
        foreignId
        source
      }
    }
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
    nodes(order_by: { lexorank: asc }) {
      ...PathViewerNode
    }
    xpMultiplier
    guides(limit: 1, where: { isRelatedPath: { _eq: false } }) {
      guide {
        id
        description
        bigIdea
        concepts
        contentStandards
        durationLower
        durationUpper
        learningObjectives
        resources
        downloadUrl
        discussionQuestions
      }
    }
  }
`;

export const PATH_VIEWER_QUERY = gql`
  ${PATH_FRAGMENT}

  query PathViewer($userId: String!, $where: path_bool_exp!) {
    path(where: $where, limit: 1) {
      ...PathViewerPath
    }
  }
`;

export const PATH_ACTIVE_USER_FRAGMENT = gql`
  fragment PathActiveUser on path_active_user {
    pathId
    userId
    updatedAt
  }
`;

gql`
  query PathActiveUsersAggregate($pathId: bigint!, $date: timestamptz!)
  @cached(ttl: 300) {
    path_active_user_aggregate(
      where: { pathId: { _eq: $pathId }, updatedAt: { _gt: $date } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

gql`
  ${PATH_ACTIVE_USER_FRAGMENT}

  mutation PathActiveUser($userId: String!, $pathId: bigint!) {
    insert_path_active_user_one(
      object: { pathId: $pathId, userId: $userId }
      on_conflict: {
        constraint: path_active_user_pkey
        update_columns: [pathId, userId]
      }
    ) {
      ...PathActiveUser
    }
  }
`;

gql`
  mutation CreateProject($input: api_create_project_input) {
    project: api_create_project(input: $input) {
      id
      text
      isPrivate
      file {
        id
        filename
      }
    }
  }
`;

export const PROJECT_HISTORY_PROJECT_FRAGMENT = gql`
  fragment ProjectHistoryProject on project {
    id
    userId
    pathNodeId
    createdAt
    rejectedAt
    rejectedByUserId
    rejectedReason
    rejectedFeedback
    rewardCoin {
      amount
    }
    rejectedByUser {
      firstName
      lastName
      username
      avatar
      isEducator
      isMod
      canAuthor
      creatorLevel
      userType
      onboarded
      subscribed
    }
  }
`;

gql`
  ${PROJECT_HISTORY_PROJECT_FRAGMENT}

  query ProjectHistory($userId: String!, $nodeId: uuid!, $limit: Int = 10) {
    projects: project(
      order_by: { createdAt: desc }
      limit: $limit
      where: { pathNodeId: { _eq: $nodeId }, userId: { _eq: $userId } }
    ) {
      ...ProjectHistoryProject
    }
  }
`;
