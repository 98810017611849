import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { css } from "@emotion/react";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonOutlined,
  Center,
  EmptyState,
  FontAwesomeIcon,
  HStack,
  IconButton,
  IconMoreVertical,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "Shared";

import { useProjectModal } from "Services/projectModal";
import { ANALYTICS_EVENTS, useTrackEventOnce } from "Services/analytics";

import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { LayoutCentered } from "Components/LayoutCentered";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { AssignDatesGrid } from "Components/AssignDueDate";
import { AssignmentBuilderModal } from "Components/AssignmentBuilder/Modal";

import { CLASSROOM_ASSIGNMENT_DETAILS_QUERY } from "../graphql";
import {
  ClassroomAssignmentDetailsQuery,
  ClassroomAssignmentDetailsQueryVariables,
  ClassroomLearnerGroupFragment,
  ProjectCardFragment,
} from "@tract/common/dist/graphql";
import { isParentUser, isTeacherUser } from "Types/User";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const AssignmentDetails: React.FC<Props> = ({ classroom }) => {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState<string | undefined>();
  const learnerMembers = classroom.members.filter((m) => m.role === "learner");
  const { handleOpenProjectModal } = useProjectModal();

  const [{ data, fetching }] = useQuery<
    ClassroomAssignmentDetailsQuery,
    ClassroomAssignmentDetailsQueryVariables
  >({
    query: CLASSROOM_ASSIGNMENT_DETAILS_QUERY,
    variables: {
      assignmentId,
      learnerUserIds: learnerMembers.map((m) => m.userId),
    },
  });

  const assignment = data?.assignment;
  const path = data?.assignment?.paths[0]?.path;

  useTrackEventOnce(
    ANALYTICS_EVENTS.ASSIGNMENT_DETAILS_VIEWED,
    {
      assignmentId: assignment?.id,
      learnerGroupId: classroom.id,
      startDate: assignment?.startDate,
      dueDate: assignment?.dueDate || null,
    },
    {
      skip: fetching || !assignment?.id,
    }
  );

  const projectsMap = useMemo(() => {
    const projects = new Map<string, ProjectCardFragment>();

    path?.nodes.forEach((node) => {
      node.projects.forEach((project) => {
        projects.set(`${project.userId}:${node.id}`, project);
      });
    });

    return projects;
  }, [path]);

  if (fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!path || !assignment) {
    return <EmptyState headline="Invalid assignment" />;
  }

  const renderAssignmentProject = (project?: ProjectCardFragment) => {
    if (!project) {
      return <Text>-</Text>;
    }
    if (project.rejectedAt !== "-infinity") {
      return <Text color="red.500">Project rejected</Text>;
    }

    return (
      <Center w="full">
        <HStack spacing={4}>
          <HStack color={project.published ? "green.600" : "orange.600"}>
            <FontAwesomeIcon
              icon={project.published ? regular("check") : solid("inbox-in")}
              size={4}
            />
            <Text fontWeight="bold">
              {project.published ? "Approved" : "Submitted"}
            </Text>
          </HStack>
          <ButtonOutlined
            as={Link}
            to={`/project/${project.id}`}
            onClick={(event: React.MouseEvent) =>
              handleOpenProjectModal(event, project)
            }
          >
            View
          </ButtonOutlined>
        </HStack>
      </Center>
    );
  };

  return (
    <PageApp overflow="hidden">
      <PageHeader
        showBreadcrumb
        my={4}
        renderBreadcrumb={
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Link}
                to={`/class/${classroom.id}/assignments`}
              >
                Assignments
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{assignment.title}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />
      <HStack mb={6} spacing={4}>
        <Text
          fontSize={{ base: "3xl", md: "4xl", xl: "5xl" }}
          fontWeight="bold"
        >
          {assignment.title}
        </Text>
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            aria-label="open assignment menu"
            icon={<IconMoreVertical width={6} height={6} />}
            width={12}
            height={12}
          />
          <MenuList width="16rem">
            <MenuItem
              onClick={() => {
                setEditId(assignment.id);
                onOpen();
              }}
            >
              <HStack spacing={3}>
                <FontAwesomeIcon icon={regular("pencil")} />
                <Text>Edit</Text>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <AssignDatesGrid
        startDate={assignment.startDate}
        dueDate={assignment.dueDate}
        mb={10}
        w="full"
      />
      <Box overflowX="auto" w="100%" flex={1} flexFlow="column">
        <Table
          css={css`
            td,
            th {
              min-width: 200px;
            }
          `}
        >
          <Thead>
            <Tr>
              <Th fontWeight="bold" fontSize="sm" color="gray.600" minW="10rem">
                Student
              </Th>
              {path.nodes.map((n, i) => (
                <Th
                  borderLeft="1px solid"
                  borderColor="gray.200"
                  fontWeight="bold"
                  fontSize="sm"
                  color="gray.600"
                  textAlign="center"
                  minWidth="10rem"
                >
                  {i + 1}. {n.title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {learnerMembers.map((learner) => (
              <Tr position="relative">
                <Td
                  position={{ base: undefined, lg: "sticky" }}
                  left={0}
                  bgColor="white"
                  zIndex={11}
                >
                  <HStack spacing={3}>
                    <UserAvatar
                      src={learner?.user?.avatar || ""}
                      tabIndex={-1}
                      isTeacher={isTeacherUser(learner?.user)}
                      isParent={isParentUser(learner?.user)}
                      username={learner?.user?.username || ""}
                      userId={learner?.user?.id}
                      name={`${learner?.user?.firstName} ${learner?.user.lastName}`}
                    />
                    <UsernameLink
                      username={learner?.user?.username || ""}
                      userId={learner?.user?.id}
                      isTeacher={isTeacherUser(learner?.user)}
                      isParent={isParentUser(learner?.user)}
                      fontWeight="bold"
                    >
                      {`${learner?.user?.firstName} ${learner?.user.lastName}`}
                    </UsernameLink>
                  </HStack>
                </Td>
                {path.nodes?.map((n, i) => (
                  <Td
                    borderLeft="1px solid"
                    borderColor="gray.200"
                    textAlign="center"
                    minWidth="10rem"
                  >
                    {!path.nodes[i].responsesEnabled ? (
                      <Text fontSize="sm" fontWeight="normal" color="gray.500">
                        Responses not enabled
                      </Text>
                    ) : (
                      renderAssignmentProject(
                        projectsMap?.get(`${learner.userId}:${n.id}`)
                      )
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <AssignmentBuilderModal
        assignmentId={editId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </PageApp>
  );
};
