import { formatDistanceStrict } from "date-fns";
import {
  ProjectHistoryProjectFragment,
  useProjectByIdQuery,
} from "@tract/common/dist/graphql";

import { Avatar, Box, Button, HStack, Text, VStack } from "Shared";
import { CoinValue } from "Components/CoinValue";
import { ProfileBadges } from "Components/ProfileBadges";
import { ProjectCard } from "Components/ProjectCard";
import { UserAvatar } from "Components/UserAvatar";

import { useProjectModal } from "Services/projectModal";
import { useSession } from "Services/session";

import { ReportReasons, ReportReasonTypes } from "Types/AbuseReport";
import { ViewSource } from "Types/Project";
import { getDisplayName } from "Types/User";
import { MOD_AVATAR_URL } from "Constants/userTypes";

type ProjectHistoryProps = {
  projects: ProjectHistoryProjectFragment[];
};

export const ProjectHistory: React.FC<ProjectHistoryProps> = ({ projects }) => {
  return (
    <VStack spacing={10} width="full" alignItems="flex-start" mb={10}>
      {projects.map((project, i) => (
        <ProjectHistoryItem
          key={project.id}
          project={project}
          firstSubmission={i === projects.length - 1}
        />
      ))}
    </VStack>
  );
};

type Props = {
  project: ProjectHistoryProjectFragment;
  firstSubmission: boolean;
};

const ProjectHistoryItem: React.FC<Props> = ({ project, firstSubmission }) => {
  const { currentUser } = useSession();
  const { handleOpenProjectModal } = useProjectModal();
  const isRejected = project.rejectedAt !== "-infinity";
  const rejectedByUser = project?.rejectedByUser;
  const isRejectedByMe = rejectedByUser?.username === currentUser.username;
  const rejectedByUserName = isRejectedByMe ? "you" : "A moderator";
  const rejectedByAvatar = isRejectedByMe
    ? rejectedByUser?.avatar
    : MOD_AVATAR_URL;
  const rejectReason = !!project.rejectedReason
    ? ReportReasons[project.rejectedReason as ReportReasonTypes]
    : undefined;

  const { data: projectCardData } = useProjectByIdQuery({
    skip: isRejected,
    variables: {
      projectId: project.id,
    },
  });
  const projectCard = projectCardData?.projects[0];
  const coinValue = project?.rewardCoin?.amount || 0;
  const displayName = getDisplayName(currentUser);

  return (
    <VStack spacing={10} width="full">
      {isRejected && (
        <HStack spacing={4} width="full" alignItems="top">
          <Avatar
            src={rejectedByAvatar || ""}
            name={rejectedByUserName}
            aria-hidden="true"
            width={12}
          />
          <Box>
            <Text>
              <Text as="span" fontWeight="bold">
                {rejectedByUserName}
              </Text>{" "}
              {!!rejectedByUser && (
                <Box display="inline-flex" verticalAlign="bottom">
                  <ProfileBadges
                    iconSize={5}
                    user={rejectedByUser}
                    spacing={1}
                    flagFont="md"
                  />
                </Box>
              )}{" "}
              requested revisions
              <CoinValue
                display="inline-flex"
                verticalAlign="top"
                size="md"
                value={-1 * coinValue}
                ml={1}
                color="red.500"
              />
            </Text>
            <Text fontSize="sm" color="gray.600">
              {formatDistanceStrict(new Date(project.rejectedAt), new Date(), {
                addSuffix: true,
              })}
            </Text>
            <Text mt={4} overflowWrap="anywhere">
              {project?.rejectedFeedback?.length
                ? project.rejectedFeedback
                : rejectReason}
            </Text>
          </Box>
        </HStack>
      )}
      <HStack spacing={4} width="full" alignItems="top">
        <UserAvatar
          userId={currentUser?.id || ""}
          username={currentUser?.username || "unknown"}
          name={displayName}
          src={currentUser?.avatar || ""}
          aria-hidden="true"
          width={12}
          isTeacher={currentUser.isEducator}
        />
        <Box>
          <Text>
            You {firstSubmission ? "submitted" : "re-submitted"} the challenge
            and earned{" "}
            <CoinValue
              display="inline-flex"
              verticalAlign="top"
              ml={1}
              value={coinValue}
              showPlus
            />
          </Text>
          <Text fontSize="sm" color="gray.600" mb={4}>
            {formatDistanceStrict(new Date(project.createdAt), new Date(), {
              addSuffix: true,
            })}
          </Text>
          {isRejected ? (
            <Button
              size="lg"
              width="10rem"
              variant="outline"
              onClick={(e) => handleOpenProjectModal(e, undefined, project.id)}
            >
              View Submission
            </Button>
          ) : (
            !!projectCard && (
              <ProjectCard
                viewSource={ViewSource.ChallengeHistory}
                project={projectCard}
                handleClickFromParent={(event) => {
                  handleOpenProjectModal(event, projectCard);
                }}
              />
            )
          )}
        </Box>
      </HStack>
    </VStack>
  );
};
