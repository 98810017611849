import { gql } from "urql";

export const VIDEO_BACKDROP_QUERY = gql`
  query getVideoBackdrops {
    api_get_video_backdrops {
      name
      createdAt
      url
    }
  }
`;
