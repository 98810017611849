import React, { FC, useEffect } from "react";
import { LayoutCentered } from "Components/LayoutCentered";
import { useSignOut } from "Services/auth";
import { useSession } from "Services/session";

export const SignOut: FC = () => {
  const { currentUser } = useSession();
  const signOut = useSignOut();

  useEffect(() => {
    if (currentUser) {
      signOut();
    }
  }, [currentUser, signOut]);

  return <LayoutCentered isLoading />;
};
