import { gql, useApolloClient } from "@apollo/client";

import {
  CoinBalanceByUserQueryVariables,
  Coin_Trx,
} from "@tract/common/dist/graphql";

import { useSession } from "Services/session";

const COIN_BALANCE_BY_USER = gql`
  query CoinBalanceByUser($userId: String!) {
    coins: coin_trx(
      order_by: { createdAt: desc }
      limit: 1
      where: { userId: { _eq: $userId } }
    ) {
      balance
    }
  }
`;

export const useCoins = () => {
  const { currentUser } = useSession();
  const client = useApolloClient();

  const data = client.readQuery<
    { coins: Coin_Trx[] },
    CoinBalanceByUserQueryVariables
  >({
    query: COIN_BALANCE_BY_USER,
    variables: {
      userId: currentUser.uid,
    },
  });

  const balance = data?.coins?.[0]?.balance || 0;

  const updateBalance = (amount: number) => {
    client.writeQuery<
      { coins: Partial<Coin_Trx>[] },
      CoinBalanceByUserQueryVariables
    >({
      query: COIN_BALANCE_BY_USER,
      variables: {
        userId: currentUser.uid,
      },
      data: {
        coins: [{ ...data?.coins[0], balance: balance + amount }],
      },
    });
  };

  return {
    balance,
    updateBalance,
  };
};
