import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  Box,
  Tabs,
  TabLink,
  TabList,
  useBreakpointValue,
  useRouteTabs,
  Text,
  Flex,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FontAwesomeIcon,
  Alert,
  Link,
  Center,
} from "Shared";

import { GridContainer, GridPaths } from "Components/GridContainer";
import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";

import {
  PathFeedEmptyState,
  PathFeedProviderAlgolia,
  PathFeed,
  PathFeedInfinite,
  CurrentRefinements,
  NumberOfHits,
  ExploreFilters,
} from "./PathFeedAlgolia";

import { TopPaths } from "Pages/HomeAuthed/TopPaths";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CategoryGrid } from "./CategoryGrid";
import { useSession } from "Services/session";
import { isKidUser, isTeacherUser } from "Types/User";
import { ViewSource } from "Types/Path";
import { SubjectGrid } from "./SubjectGrid";
import { useFeature } from "Services/features";

export const enum ExploreRoutes {
  awesomeProjects = "/explore/projects/awesome",
  originals = `/explore/originals`,
  community = `/explore/community`,
  communityTopPicks = `/explore/community/top-picks`,
  communityNewest = `/explore/community/new`,
}

const enum ExploreCommunityTabOption {
  topPicks = "Top Picks",
  newest = "Newest",
}

export function ExplorePaths() {
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const history = useHistory();
  const { currentUser } = useSession();
  const isKid = isKidUser(currentUser);
  const isTeacher = isTeacherUser(currentUser);
  const isCuratedExploreEnabled = useFeature("curated-explore");
  const isTopPicks = pathname === ExploreRoutes.communityTopPicks;
  const isFittedTabs = useBreakpointValue({
    base: true,
    md: false,
  });

  const showTeacherMetadataBanner = false;
  // const showTeacherMetadataBanner =
  //   isTeacher &&
  //   !currentUser.teacher?.subjects?.length &&
  //   (!currentUser.teacher?.lowerGradeLevel ||
  //     !currentUser.teacher?.upperGradeLevel);

  const limit = useBreakpointValue({
    base: 8,
    sm: 16,
    md: 24,
    xl: 32,
    "2xl": 40,
    "3xl": 72,
  });

  const { tabsProps } = useRouteTabs({
    routes: [
      { path: ExploreRoutes.originals },
      { path: ExploreRoutes.community },
    ],
    exact: false,
  });

  useEffect(() => {
    if (!pathname?.length) {
      return;
    }
    let activeTab;
    switch (pathname) {
      case ExploreRoutes.originals:
        activeTab = "originals";
        break;
      case ExploreRoutes.communityTopPicks:
        activeTab = "community-top-picks";
        break;
      case ExploreRoutes.communityNewest:
        activeTab = "community-new";
        break;
      default:
        //This will be redirected, no need for analytic
        return;
    }

    track(ANALYTICS_EVENTS.EXPLORE_VIEWED, {
      activeTab: activeTab,
    });
  }, [pathname, track]);

  return (
    <PathFeedProviderAlgolia
      limit={limit}
      isTractOriginal={pathname === ExploreRoutes.originals}
    >
      <Tabs size="lg" isManual isFitted={isFittedTabs} {...tabsProps}>
        <GridContainer pt={6}>
          {isTeacher && !isCuratedExploreEnabled && (
            <>
              {showTeacherMetadataBanner && (
                <Alert
                  status="info"
                  w="full"
                  variant="outline"
                  colorScheme="white"
                  mb={10}
                >
                  <Center w="full">
                    <FontAwesomeIcon icon={regular("info-circle")} size={6} />
                    <Text ml={3}>
                      <Link
                        to="/settings/profile"
                        fontWeight="bold"
                        color="brandFull.500"
                      >
                        Complete your profile
                      </Link>{" "}
                      to personalize your explore experience.
                    </Text>
                  </Center>
                </Alert>
              )}
              <Text
                fontSize={{ base: "3xl", md: "4xl", xl: "5xl" }}
                fontWeight="bold"
                mb={2}
              >
                Explore
              </Text>
              <Text mb={6}>
                Find a project to implement in your classroom, or see what our
                community is creating.
              </Text>
            </>
          )}
          {isKid && !isCuratedExploreEnabled && (
            <Box pb={6}>
              <CategoryGrid />
            </Box>
          )}
          {isTeacher && !isCuratedExploreEnabled && (
            <Box pb={6}>
              <SubjectGrid />
            </Box>
          )}
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            align={{ base: "stretch", md: "center" }}
            w="full"
            mb={6}
          >
            <TabList>
              <TabLink to={ExploreRoutes.originals}>Tract Originals</TabLink>
              <TabLink to={ExploreRoutes.community}>Community</TabLink>
            </TabList>
            <HStack
              justify={{ base: "space-between", md: "flex-start" }}
              mt={{ base: 4, md: 0 }}
              spacing={2}
              transition="none"
            >
              <Box>{!isTopPicks && <NumberOfHits px={4} />}</Box>
              {pathname === ExploreRoutes.originals ? (
                <ExploreFilters />
              ) : (
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="ghost"
                    leftIcon={<FontAwesomeIcon icon={regular("bars-sort")} />}
                  >
                    {pathname === ExploreRoutes.communityTopPicks
                      ? ExploreCommunityTabOption.topPicks
                      : ExploreCommunityTabOption.newest}
                  </MenuButton>
                  <MenuList w="16rem">
                    <MenuItem
                      onClick={() =>
                        history.push(ExploreRoutes.communityTopPicks)
                      }
                    >
                      {ExploreCommunityTabOption.topPicks}
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        history.push(ExploreRoutes.communityNewest)
                      }
                    >
                      {ExploreCommunityTabOption.newest}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </HStack>
          </Flex>
          <Switch>
            <Route path={ExploreRoutes.originals} exact>
              <CurrentRefinements mb={4} />
            </Route>
            <Route path={ExploreRoutes.communityTopPicks} exact>
              <TopPaths
                initialLimit={limit}
                loadMoreLimit={limit}
                homePage={false}
              />
            </Route>
            <Route path={ExploreRoutes.communityNewest} />
            <Redirect
              path={ExploreRoutes.community}
              to={ExploreRoutes.communityTopPicks}
            />
            <Redirect to={ExploreRoutes.originals} />
          </Switch>
          {!!limit && !isTopPicks && (
            <>
              <GridPaths mt={{ lg: 6 }} width="full">
                <PathFeed source={ViewSource.ExploreAll} />
              </GridPaths>
              <PathFeedEmptyState />
              <PathFeedInfinite />
            </>
          )}
        </GridContainer>
      </Tabs>
    </PathFeedProviderAlgolia>
  );
}
