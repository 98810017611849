import { gql } from "urql";

export const GET_ONBOARDED_ORGS = gql`
  query GetOnboardedOrgs {
    organizations: api_get_onboarded_orgs {
      id
      name
      isVerified
    }
  }
`;

export const ONBOARD_KID_MUTATION = gql`
  mutation OnboardKid(
    $userId: String!
    $username: String!
    $firstName: String!
    $lastName: String!
    $birthday: jsonb!
  ) {
    onboard_kid: update_user_by_pk(
      pk_columns: { firestoreId: $userId }
      _set: {
        firstName: $firstName
        lastName: $lastName
        username: $username
        birthday: $birthday
      }
    ) {
      firestoreId
      firstName
      lastName
      birthday
    }
  }
`;
