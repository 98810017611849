import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { InterestArea } from "@tract/common/dist/types/models/Path";
import { Button, useToast } from "Shared";
import { PATH_INTEREST_AREAS } from "Constants/paths";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { isKidUser } from "Types/User";

import { ExplorePage } from "./BaseExplorePage";

export const ExploreInterestArea: React.FC = () => {
  const { interestArea: slug } = useParams<{ interestArea: string }>();
  const toast = useToast();
  const { currentUser, update } = useSession();

  const InterestArea = PATH_INTEREST_AREAS.filter(
    (interestArea) => interestArea.slug === slug
  )[0];

  const { track } = useAnalytics();
  useEffect(() => {
    track(ANALYTICS_EVENTS.INTEREST_VIEWED, {
      interestArea: InterestArea?.label,
      interestAreaSlug: slug,
    });
  }, [track, InterestArea?.label, slug]);

  const isFollowing = currentUser.interestAreas?.includes(InterestArea?.value);
  const isKid = isKidUser(currentUser);
  const [savingFollowing, setSavingFollowing] = useState(false);
  const toggleInterestAreaFollow = async () => {
    setSavingFollowing(true);
    try {
      let interestAreas = !!currentUser.interestAreas
        ? [...currentUser.interestAreas]
        : [];
      if (interestAreas.includes(InterestArea?.value)) {
        interestAreas.splice(interestAreas.indexOf(InterestArea?.value), 1);
      } else {
        interestAreas.push(InterestArea?.value as InterestArea);
      }
      await update({
        interestAreas: interestAreas,
      });
    } catch (err: any) {
      toast({
        status: "error",
        title: "Oops!",
        description: "Something went wrong, please try again",
      });
      captureException(err);
    } finally {
      setSavingFollowing(false);
    }
  };

  if (!InterestArea) {
    return <Redirect to="/" />;
  }
  const { label, value, color, bannerColor, iconDuotone } = InterestArea;

  const FollowButton = isKid ? (
    <Button
      isLoading={savingFollowing}
      onClick={toggleInterestAreaFollow}
      size="lg"
      variant="outline"
      ml={{ base: 4, lg: 6 }}
    >
      {isFollowing ? "Following" : "Follow"}
    </Button>
  ) : undefined;

  return (
    <ExplorePage
      iconDuotone={iconDuotone}
      iconColor={color}
      bannerColor={bannerColor}
      FollowButton={FollowButton}
      showTopTags={true}
      title={label}
      interestAreas={[value]}
    />
  );
};
