import React, { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { captureException } from "Services/errors";

import { AutoComplete } from "Shared";

import { db } from "Services/firebase";
import { useFormikContext } from "formik";

const formatTerm = (item) => item.toLowerCase();

const normalizeTagsFromPath = (collection = []) => {
  return collection.map((item) => ({
    label: item,
    value: item,
  }));
};

const normalizeTagsFromDB = (collection = []) => {
  return collection.map((item) => ({
    label: item.displayName,
    value: item.value,
  }));
};

const mappedValues = (collection = []) => collection.map((item) => item.value);

export default function PathTags({
  onError,
  onNewTagsChange,
  onTagsChange,
  path,
  disabled,
}) {
  const [items, setItems] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(
    normalizeTagsFromPath(path.tags || [])
  );
  const [tagSearchTerm, setTagSearchTerm] = useState("");

  const { values } = useFormikContext();
  const selectedTagVals = mappedValues(selectedTags);
  const newTagVals = mappedValues(newTags);

  useEffect(() => {
    // Fetch tags when search term changes
    if (tagSearchTerm) {
      async function getData() {
        try {
          const tagArray = [];
          const snap = await db
            .collection("tags")
            .where("value", ">=", tagSearchTerm)
            .where("value", "<=", `${tagSearchTerm}\uf8ff`)
            .get();

          snap.forEach((doc) => {
            tagArray.push(doc.data());
          });

          setItems(normalizeTagsFromDB(tagArray));
        } catch (e) {
          captureException(e);
          if (onError) {
            onError(e);
          }
        }
      }
      getData();
    }
  }, [tagSearchTerm, onError]);

  useDeepCompareEffect(() => {
    if (onNewTagsChange) {
      onNewTagsChange(mappedValues(newTags));
    }
  }, [newTags, setNewTags, onNewTagsChange]);

  useDeepCompareEffect(() => {
    if (onTagsChange) {
      onTagsChange(mappedValues(selectedTags));
    }
  }, [selectedTags, setSelectedTags, onTagsChange]);

  const addNewTag = (tag) => {
    if (!newTagVals.includes(tag.value)) {
      setNewTags([...newTags, tag]);
    }
  };

  const initalSelections = normalizeTagsFromPath(
    values.tags || path.tags || []
  );

  return (
    <AutoComplete
      initialSelectedItems={initalSelections}
      inputRegex={"^([a-zA-Z0-9]+)$"} // alphanumeric chars only
      items={items}
      onAddItem={async (item) => {
        // Check if tag exists
        if (!selectedTagVals.includes(item.value)) {
          const snap = await db
            .collection("tags")
            .where("value", "==", formatTerm(item.value))
            .get();

          // Tag doesn't exist, add to list of new tags
          if (snap.empty) {
            addNewTag(item);
          }
        }
      }}
      onInputChange={(inputValue) => setTagSearchTerm(formatTerm(inputValue))}
      onRemoveItem={(item) => {
        // Check if removing a new tag
        if (newTagVals.includes(item.value)) {
          const filtered = newTags.filter((tag) => tag.value !== item.value);
          setNewTags(filtered);
        }
      }}
      onSelectedItemsChange={(items) => setSelectedTags(items)}
      placeholder="Search tags..."
      disabled={disabled}
    />
  );
}
