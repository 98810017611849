import { gql } from "urql";
import { PROJECT_COMMENT_FRAGMENT } from "Pages/ProjectGallery/graphql";
import { PROJECT_STATS_FRAGMENT } from "Components/ProjectCard/graphql";

export const AWARD_FRAGMENT = gql`
  fragment Award on award {
    id
    name
    description
    filePath
    bonus
    bonusDescription
    cost
    type
    ownerId
  }
`;

export const GET_AWARDS_QUERY = gql`
  ${AWARD_FRAGMENT}

  query GetAwards {
    awards: award(where: { disabled: { _eq: false } }) {
      ...Award
    }
  }
`;

export const GIVE_AWARD_MUTATION = gql`
  ${PROJECT_COMMENT_FRAGMENT}
  ${PROJECT_STATS_FRAGMENT}

  mutation GiveAward(
    $projectId: uuid!
    $awardId: uuid!
    $userId: String!
    $fromUserId: String!
    $cost: Int!
    $bonus: Int!
    $comment: String
  ) {
    insert_project_award_one(
      object: { projectId: $projectId, awardId: $awardId, count: 0 }
      on_conflict: { update_columns: [], constraint: project_award_pkey }
    ) {
      projectId
      awardId
      count
    }
    update_project_award_by_pk(
      pk_columns: { projectId: $projectId, awardId: $awardId }
      _inc: { count: 1 }
    ) {
      projectId
      awardId
      count
    }
    insert_user_award_one(
      object: { awardId: $awardId, userId: $userId, fromUserId: $fromUserId }
    ) {
      id
    }
    coin_trx_from: insert_coin_trx_one(
      object: {
        amount: $cost
        userId: $fromUserId
        category: project_award_purchase
      }
    ) {
      id
    }
    coin_trx_to: insert_coin_trx_one(
      object: { amount: $bonus, userId: $userId, category: project_award_bonus }
    ) {
      id
    }
    projectComment: insert_project_comment_one(
      object: {
        projectId: $projectId
        awardId: $awardId
        comment: { data: { text: $comment, userId: $fromUserId } }
      }
    ) {
      ...projectComment
    }
    update_project_stats_by_pk(
      pk_columns: { projectId: $projectId }
      _inc: { commentCount: 1 }
    ) {
      ...projectStats
    }
  }
`;
