import { forwardRef } from "@chakra-ui/react";
import { BadgeProps } from "@chakra-ui/layout";
import { PositionProps } from "@chakra-ui/styled-system";
import { Badge, Box } from "Shared";

type Props = {
  count: number;
  // Position of the badge, relative to the top right corner of the container
  badgePosition?: "inside" | "center" | "outside" | "inside-center";
  // Border color around the badge - should blend in seamlessly to the background
  borderColor?: string;
} & BadgeProps;

type BadgePositionProps = PositionProps | { transform: string };

const positionStyles: {
  inside: BadgePositionProps;
  center: BadgePositionProps;
  outside: BadgePositionProps;
  "inside-center": BadgePositionProps;
} = {
  inside: {
    top: 0,
    right: 0,
  },
  center: {
    top: "-1.25rem",
    left: "100%",
    transform: "translate(-50%, 50%)",
  },
  outside: {
    top: "-1.25rem",
    left: "100%",
  },
  "inside-center": {
    top: 0,
    left: "100%",
    transform: "translate(-50%, 0%)",
  },
};

export const CounterBadge = forwardRef<Props, "div">(
  (
    { children, count, badgePosition = "inside", borderColor, ...props },
    ref
  ) => {
    return (
      <Box ref={ref} position="relative" width="max-content" mx="auto">
        {children}
        {count > 0 && (
          <Badge
            variant="solid"
            colorScheme="red"
            position="absolute"
            zIndex={1}
            lineHeight="1.25rem"
            px={"0.375rem"}
            {...positionStyles[badgePosition]}
            boxShadow={`0 0 0 0.1875rem ${borderColor || "white"}`}
            {...props}
          >
            {count < 10 ? count : "9+"}
          </Badge>
        )}
      </Box>
    );
  }
);
