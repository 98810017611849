import { gql } from "urql";

export const ADMIN_LIVESTREAM_FRAGMENT = gql`
  fragment AdminLivestream on livestream {
    id
    title
    description
    hostUserId
    hostUser {
      id: firestoreId
      username
    }
    startDate
    endDate
    disabled
    coverFileId
    coverFile {
      id
      filename
      transforms {
        fileId
        filename
      }
    }
  }
`;

export const ADMIN_LIVESTREAMS_QUERY = gql`
  ${ADMIN_LIVESTREAM_FRAGMENT}

  query AdminLivestreams(
    $limit: Int!
    $offset: Int!
    $startDate: timestamptz_comparison_exp!
    $orderBy: livestream_order_by!
  ) {
    livestreams: livestream(
      limit: $limit
      offset: $offset
      where: { startDate: $startDate }
      order_by: [$orderBy]
    ) {
      ...AdminLivestream
    }
  }
`;

export const LIVESTREAM_BY_PK_QUERY = gql`
  ${ADMIN_LIVESTREAM_FRAGMENT}

  query LivestreamByPk($id: uuid!) {
    livestream: livestream_by_pk(id: $id) {
      ...AdminLivestream
    }
  }
`;

export const CREATE_LIVESTREAM = gql`
  ${ADMIN_LIVESTREAM_FRAGMENT}

  mutation CreateLivestream($object: livestream_insert_input!) {
    livestream: insert_livestream_one(object: $object) {
      ...AdminLivestream
    }
  }
`;

export const UPDATE_LIVESTREAM = gql`
  ${ADMIN_LIVESTREAM_FRAGMENT}

  mutation UpdateLivestream($id: uuid!, $object: livestream_set_input!) {
    livestream: update_livestream_by_pk(
      pk_columns: { id: $id }
      _set: $object
    ) {
      ...AdminLivestream
    }
  }
`;
