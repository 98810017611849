export type PlaceResult = google.maps.places.PlaceResult;

export enum GooglePlaceAddressComponent {
  PostalCode = "postal_code",
  Country = "country",
  AreaLevel1 = "administrative_area_level_1",
  AreaLevel2 = "administrative_area_level_2",
  AreaLevel3 = "administrative_area_level_3",
  AreaLevel4 = "administrative_area_level_4",
  Locality = "locality",
  SublocalityLevel1 = "sublocality_level_1",
  SublocalityLevel2 = "sublocality_level_2",
  Neighborhood = "neighborhood",
  Route = "route",
  StreetNumber = "street_number",
  Subpremise = "subpremise",
}

export const parseAddress = (place: PlaceResult | null) => {
  const getAddressLine1 = () => {
    if (!place) return null;

    const subPremise = getAddressComponent(
      place,
      GooglePlaceAddressComponent.Subpremise
    );
    const streetNumber = getAddressComponent(
      place,
      GooglePlaceAddressComponent.StreetNumber
    );
    const route = getAddressComponent(place, GooglePlaceAddressComponent.Route);
    const sublocalityLevel2 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.SublocalityLevel2
    );

    let line = "";

    if (subPremise && streetNumber) {
      line = `${subPremise}/${streetNumber}`;
    } else if (streetNumber) {
      line = streetNumber;
    }

    if (route) line += line ? ` ${route}` : route;
    if (sublocalityLevel2)
      line += line ? ` ${sublocalityLevel2}` : sublocalityLevel2;

    return line || null;
  };

  const getAddressLine2 = () => {
    if (!place) return null;

    const sublocalityLevel1 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.SublocalityLevel1
    );
    const neighborhood = getAddressComponent(
      place,
      GooglePlaceAddressComponent.Neighborhood
    );
    const areaLevel2 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.AreaLevel2
    );
    const areaLevel3 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.AreaLevel3
    );
    const areaLevel4 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.AreaLevel4
    );

    let line = "";

    if (sublocalityLevel1) line += `${sublocalityLevel1}`;
    if (neighborhood) line += line ? `, ${neighborhood}` : neighborhood;
    if (areaLevel4) line += line ? `, ${areaLevel4}` : areaLevel4;
    if (areaLevel3) line += line ? `, ${areaLevel3}` : areaLevel3;
    if (areaLevel2) line += line ? `, ${areaLevel2}` : areaLevel2;

    return line || null;
  };

  const getCity = () => {
    if (!place) return null;

    const areaLevel2 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.AreaLevel2
    );

    const locality = getAddressComponent(
      place,
      GooglePlaceAddressComponent.Locality
    );

    return locality || areaLevel2;
  };

  const getRegion = () => {
    if (!place) return null;

    const areaLevel1 = getAddressComponent(
      place,
      GooglePlaceAddressComponent.AreaLevel1
    );

    return areaLevel1;
  };

  const getCountry = () => {
    if (!place) return null;

    const country = getAddressComponent(
      place,
      GooglePlaceAddressComponent.Country
    );

    return country;
  };

  const getZip = () => {
    if (!place) return null;

    const zip = getAddressComponent(
      place,
      GooglePlaceAddressComponent.PostalCode
    );

    return zip;
  };

  return {
    // Some addresses are not very specific in which case address line 1 may be empty.
    // Use line 2 as line 1 in those cases and keep address line 2 empty
    addressLine1: getAddressLine1() || getAddressLine2() || "",
    addressLine2: getAddressLine1() ? getAddressLine2() || "" : "",
    city: getCity() || "",
    region: getRegion() || "",
    country: getCountry() || "",
    zip: getZip() || "",
  };
};

function getAddressComponent(
  place: PlaceResult,
  componentName: GooglePlaceAddressComponent
) {
  const component = place.address_components?.find((addr) =>
    addr.types.includes(componentName)
  );

  if (component) {
    return component.long_name;
  }

  return null;
}
