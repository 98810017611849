import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { StreamClient, connect } from "getstream";
import { createContext } from "Utils";
import { useLocation } from "react-router-dom";

export interface Stream {
  client: StreamClient;
  userId: string;
  user: {
    id: string;
  };
}

export const [_, useStreamContext, StreamContext] = createContext<{
  client: StreamClient;
}>({
  strict: true,
  errorMessage: "StreamContext must be used inside StreamProvider",
  name: "StreamContext",
});

export const StreamProvider: React.FC<
  PropsWithChildren<{
    token: string;
  }>
> = ({ token, children }) => {
  const apiKey = process.env.REACT_APP_STREAM_API_KEY || "";
  const appId = process.env.REACT_APP_STREAM_APP_ID || "";
  const client = connect(apiKey, token, appId);

  return (
    <StreamContext.Provider value={{ client }}>
      {children}
    </StreamContext.Provider>
  );
};

export const [__, useNotificationsContext, NotificationsContext] =
  createContext<{
    newActivityCount: number;
    unseenCount: number;
    checkUnseen: () => void;
    clearNew: () => void;
  }>({
    strict: true,
    errorMessage: "NotificationsContext must be used inside StreamProvider",
    name: "NotificationsContext",
  });

export const NotificationsProvider: React.FC<
  PropsWithChildren<{ userId: string }>
> = ({ children, userId }) => {
  const { client } = useContext(StreamContext);
  const [newActivity, setNewActivity] = useState<Set<string>>(new Set());
  const [unseenCount, setUnseenCount] = useState(0);
  const [didInit, setInit] = useState(false);
  const { pathname } = useLocation();

  const clearNew = useCallback(() => {
    setNewActivity(new Set());
  }, []);

  const checkUnseen = useCallback(async () => {
    const feed = client.feed("notification", userId);
    const { unseen } = await feed.get({ limit: 0 });
    setUnseenCount(unseen || 0);

    if (!didInit) {
      setInit(true);
    }
  }, [client, userId, didInit]);

  useEffect(() => {
    if (didInit) {
      return;
    }

    checkUnseen();
  }, [client, userId, didInit, checkUnseen]);

  useEffect(() => {
    const feed = client.feed("notification", userId);
    const sub = feed.subscribe((data) => {
      const newData = data.new;
      setNewActivity((newActivities) => {
        const set = new Set(newActivities);
        newData.forEach((data) => {
          // @ts-ignore
          set.add(data.id);
        });
        return set;
      });
    });

    return () => {
      // @ts-ignore
      sub.cancel();
    };
  }, [client, userId]);

  useEffect(() => {
    const totalUnread = newActivity.size + unseenCount;

    // Clean up unread count to avoid recursively setting document title
    // This matches the first occurence of "(1) - "
    document.title = document.title.replace(new RegExp(/\(.*\) - /), "");

    if (totalUnread) {
      document.title = `(${totalUnread}) - ${document.title}`;
    }
  }, [newActivity, unseenCount, pathname]);

  return (
    <NotificationsContext.Provider
      value={{
        newActivityCount: newActivity.size,
        unseenCount: newActivity.size + unseenCount,
        checkUnseen,
        clearNew,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
