import {
  AdminLivestreamsQuery,
  AdminLivestreamsQueryVariables,
  Order_By,
} from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import React, { FC } from "react";

import {
  VisuallyHidden,
  Tbody,
  Table,
  Th,
  Tr,
  Thead,
  ButtonSolid,
  Center,
} from "Shared";
import { useOffsetPaginationQuery } from "Utils";

import { ADMIN_LIVESTREAMS_QUERY } from "./graphql";
import { LivestreamsTableRow } from "./LivestreamsTableRow";

type Props = {
  upcoming: boolean;
};
export const ADMIN_LIVESTREAMS_PER_PAGE = 25;
export const ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING = { startDate: Order_By.Asc };
export const ADMIN_LIVESTREAMS_ORDER_BY_PAST = { startDate: Order_By.Desc };
export const ADMIN_LIVESTREAMS_START_DATE_UPCOMING = { _gte: "now()" };
export const ADMIN_LIVESTREAMS_START_DATE_PAST = { _lte: "now()" };

export const LivestreamsTable: FC<Props> = ({ upcoming }) => {
  const {
    response: [{ data, fetching }],
    pagination,
  } = useOffsetPaginationQuery<
    AdminLivestreamsQuery,
    AdminLivestreamsQueryVariables
  >({
    query: ADMIN_LIVESTREAMS_QUERY,
    variables: {
      limit: ADMIN_LIVESTREAMS_PER_PAGE,
      offset: 0,
      startDate: upcoming
        ? ADMIN_LIVESTREAMS_START_DATE_UPCOMING
        : ADMIN_LIVESTREAMS_START_DATE_PAST,
      orderBy: upcoming
        ? ADMIN_LIVESTREAMS_ORDER_BY_UPCOMING
        : ADMIN_LIVESTREAMS_ORDER_BY_PAST,
    },
    field: "livestreams",
  });

  const livestreams = data?.livestreams || [];

  if (!livestreams && fetching) {
    return <LayoutCentered isLoading height="auto" />;
  }
  return (
    <>
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Host</Th>
            <Th>Start Date</Th>
            <Th>Duration</Th>
            <Th>Visible</Th>
            <Th>
              <VisuallyHidden>Actions</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {livestreams?.map((livestream) => {
            return (
              <LivestreamsTableRow
                key={livestream.id}
                livestream={livestream}
                upcoming={upcoming}
              />
            );
          })}
        </Tbody>
      </Table>
      {pagination.hasNextPage && !!livestreams.length && (
        <Center w="full">
          <ButtonSolid
            my={6}
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonSolid>
        </Center>
      )}
    </>
  );
};
