import { gql } from "urql";

export const SIGN_UP_EDUCATOR_MUTATION = gql`
  mutation SignUpEducator(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    api_sign_up(
      role: EDUCATOR
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      userId
      authToken
    }
  }
`;

export const SIGN_UP_STUDENT_MUTATION = gql`
  mutation SignUpStudent(
    $username: String!
    $password: String!
    $code: String!
  ) {
    api_sign_up(
      role: STUDENT
      username: $username
      password: $password
      code: $code
    ) {
      userId
      authToken
    }
  }
`;

export const JOIN_CLASSROOM_MUTATION = gql`
  mutation JoinClassroom($educatorCode: String!, $source: String) {
    api_join_classroom(educatorCode: $educatorCode, source: $source) {
      learnerGroupId
    }
  }
`;
