import firebase from "firebase/compat";

enum ResponseType {
  Text = "text",
  Video = "video",
}

export enum FileCaptureMethod {
  Unknown = "unknown",
  Upload = "upload",
  VideoEditor = "video-editor",
}

export enum Status {
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
}

export interface SubmissionResponse {
  value?: string;
  file?: string;
}

export interface Submission {
  challengeId: string;
  missionId: string;
  pathId: string;
  pathAuthorId: string;
  projectId?: string;
  response: SubmissionResponse;
  responseType: ResponseType;
  fileCaptureMethod: FileCaptureMethod;
  status: Status;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  userId: string;
  meta?: {
    rejectedBy: {
      userId: string;
      reason: string;
    };
  };
}
