import { FC, useEffect, useState } from "react";
import cuid from "cuid";
import slug from "slug";
import { useMutation, useQuery } from "urql";
import { v4 } from "uuid";

import {
  GetPathStatusQuery,
  GetPathWithNodesQuery,
  SavePathStatusMutationVariables,
} from "@tract/common/dist/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  useDisclosure,
  IconSettings,
  IconButton,
  Tooltip,
} from "Shared";
import { SettingsModal } from "./SettingsModal";

import { useSession } from "Services/session";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { UNTITLTED_PATH_NAME } from "Constants/paths";

import {
  GET_PATH_STATUS,
  SAVE_PATH_STATUS_MUTATION,
} from "Pages/CreateV4/graphql";
import { usePathV4Context } from "./LayoutCreateV4";
import { captureException } from "Services/errors";
import { GuideModal } from "./GuideModal";

interface PathActionsProps {
  path: GetPathWithNodesQuery["path"];
}

export type PathValidationError = {
  message: string;
  link?: string;
  childErrors?: PathValidationError[];
};

export enum PathActivityType {
  SetArchived = "set:archived",
  SetDraft = "set:draft",
  SetInReview = "set:inreview",
  SetPublished = "set:published",
  ReviewApprove = "review:approve",
  ReviewDeny = "review:deny",
  ReviewRequest = "review:request",
}

export const validatePath = (
  path: GetPathWithNodesQuery["path"]
): {
  isValid: boolean;
  settingErrors: PathValidationError[];
  nodeErrors: PathValidationError[];
} => {
  const settingErrors: PathValidationError[] = [];
  if (!path?.title || path.title.trim() === UNTITLTED_PATH_NAME) {
    settingErrors.push({
      message: "Path is missing a title",
    });
  }

  if (!path?.coverFile) {
    settingErrors.push({
      message: "Path is missing a thumbnail",
    });
  }

  if (
    !!path?.coverFile &&
    !path?.coverFile?.transforms?.filter(
      (t) =>
        t.filename === "cover_thumb.jpg" || t.filename === "video_cover_sm.jpg"
    )?.length
  ) {
    settingErrors.push({
      message: "Path thumbnail needs to finish processing.  Retry in a minute!",
    });
  }

  const nodeErrors: PathValidationError[] = [];
  path?.nodes.forEach((node, nodeIndex) => {
    const errors: PathValidationError[] = [];
    const nodeTitle = node.title || "Untitled Video";
    const nodeLink = `/create/path/${path.id}/v/${node.id}/details`;
    if (!node.title) {
      errors.push({
        message: `Missing title`,
      });
    }

    if (!node.videoFile) {
      errors.push({
        message: `Missing video`,
      });
    }

    const isValidNode = errors.length === 0;

    if (errors.length > 0) {
      nodeErrors.push({
        message: `${nodeIndex + 1}. ${nodeTitle}`,
        childErrors: errors,
        ...(isValidNode ? null : { link: nodeLink }),
      });
    }
  });

  const isValid = settingErrors.length === 0 && nodeErrors.length === 0;

  return { isValid, settingErrors, nodeErrors };
};

type TriggerType = "settings-cta" | "publish-cta";

export const PathActions: FC<PathActionsProps> = ({ path }) => {
  const { track } = useAnalytics();
  const { currentUser, isAdmin } = useSession();
  const [triggerType, setTriggerType] = useState<TriggerType>("publish-cta");
  const {
    isOpen: isSettingsModalOpen,
    onOpen: onOpenSettingsModal,
    onClose: closeSettingsModal,
  } = useDisclosure();
  const { refreshPath } = usePathV4Context();
  const {
    isOpen: isGuideModalOpen,
    onOpen: onOpenGuideModal,
    onClose: closeGuideModal,
  } = useDisclosure();

  const openSettingsModal = (who: TriggerType) => {
    setTriggerType(who);
    onOpenSettingsModal();
  };

  const [, refetchPathStatus] = useQuery<GetPathStatusQuery>({
    query: GET_PATH_STATUS,
    variables: { pathId: path?.id },
  });

  useEffect(() => {
    refetchPathStatus();
  }, [refetchPathStatus]);

  const [, savePathStatus] = useMutation<{}, SavePathStatusMutationVariables>(
    SAVE_PATH_STATUS_MUTATION
  );

  const toast = useToast();

  const saveStatus = async (newStatus: string) => {
    if (!path) return;

    if (!isAdmin) {
      if (
        path?.status === PathStatus.Published ||
        path?.status === PathStatus.Archived
      ) {
        toast({
          status: "error",
          title: `Path is already ${path?.status.toLowerCase()}`,
        });
        return;
      }
    }

    const pathSlugObj = {
      slug:
        path.slug === null && path.title && newStatus === PathStatus.Published
          ? `${slug(path.title)}-${cuid.slug()}`
          : path.slug,
    };

    const publishedAt =
      !path.publishedAt && newStatus === PathStatus.Published
        ? "now()"
        : path.publishedAt;

    const updatedPath = {
      isPublished: newStatus === PathStatus.Published,
      status: newStatus,
      publishedAt,
      ...pathSlugObj,
      authorId: path.authorId,
      authors: path.authors.map(({ userId }) => ({ userId, pathId: path.id })),
    };

    // If it's a TractOriginal, switch author to "tractoriginals"
    // and add the original author as a collaborator
    if (path && path.isTractOriginal && path.authorId !== "tractoriginals") {
      updatedPath.authorId = "tractoriginals";
      updatedPath.authors = [
        ...updatedPath.authors,
        { userId: path.authorId, pathId: path.id },
      ];
    }

    const pathActivityId = v4();
    const pathReviewId = v4();

    let pathActivityType = PathActivityType.SetArchived;
    switch (newStatus) {
      case PathStatus.Archived:
        pathActivityType = PathActivityType.SetArchived;
        break;
      case PathStatus.Draft:
        pathActivityType = PathActivityType.SetDraft;
        break;
      case PathStatus.InReview:
        pathActivityType = PathActivityType.SetInReview;
        break;
      case PathStatus.Published:
        pathActivityType = PathActivityType.SetPublished;
        break;
      default:
        break;
    }

    const mutationResult = await savePathStatus({
      pathId: path.id,
      ...updatedPath,
      pathActivity: {
        id: pathActivityId,
        pathId: path.id,
        userId: currentUser.id,
        type: pathActivityType,
        pathReview:
          newStatus === PathStatus.InReview
            ? {
                data: {
                  id: pathReviewId,
                  pathId: path.id,
                  userId: currentUser.id,
                  type: PathActivityType.ReviewRequest,
                },
              }
            : undefined,
      },
    });
    if (!!mutationResult.error) {
      captureException(mutationResult.error);
      toast({
        status: "error",
        title: `Setting path status failed, please try again`,
      });
    } else {
      toast({
        title: "Path status saved",
        status: "success",
        duration: 2000,
      });
    }
  };

  const onPublishAttempt = () => {
    openSettingsModal("publish-cta");
  };

  const onAdminPublish = () => {
    const validation = validatePath(path);

    if (validation.isValid) {
      saveStatus(PathStatus.Published);

      track(ANALYTICS_EVENTS.PATH_PUBLISHED, {
        pathId: path?.id,
        pathSlug: path?.slug,
        pathTitle: path?.title,
        videosCount: path?.nodes.length,
        pathAuthorUsername: path?.user?.username,
        pathAuthorCreatorLevel: path?.user?.creatorLevel,
        pathCoAuthorsCount: path?.authors.length,
      });
    } else {
      refreshPath();
      toast({
        status: "error",
        title: `Path validation failed, please try again`,
      });
    }
  };

  const onCancelReview = () => {
    saveStatus(PathStatus.Draft);
    track(ANALYTICS_EVENTS.PATH_REVIEW_CANCELED, {
      pathId: path?.id,
      pathSlug: path?.slug,
      pathTitle: path?.title,
      pathCoAuthorsCount: path?.authors.length,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
    });
  };
  const onUnpublish = () => {
    saveStatus(PathStatus.Draft);

    track(ANALYTICS_EVENTS.PATH_UNPUBLISHED, {
      pathId: path?.id,
      pathSlug: path?.slug,
      pathTitle: path?.title,
      pathCoAuthorsCount: path?.authors.length,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
    });
  };
  const onArchive = () => saveStatus(PathStatus.Archived);
  const onUnarchive = () => saveStatus(PathStatus.Draft);

  if (!path) return null;
  return (
    <>
      {(isAdmin || path.status === PathStatus.Published) && (
        <Tooltip label="Settings" hasArrow placement="bottom">
          <IconButton
            as="a"
            onClick={() => openSettingsModal("settings-cta")}
            target="_blank"
            variant="ghost"
            aria-label="Open Settings"
            icon={<IconSettings />}
          />
        </Tooltip>
      )}
      {!isAdmin &&
        (path.status === PathStatus.Draft ||
          path.status === PathStatus.InReview) && (
          <Button
            size="lg"
            variant="solid"
            colorScheme="brandFull"
            onClick={onPublishAttempt}
          >
            Publish
          </Button>
        )}
      {isAdmin && (
        <Menu>
          <MenuButton
            as={Button}
            size="lg"
            variant="solid"
            colorScheme="brandFull"
          >
            Manage
          </MenuButton>
          <MenuList>
            {path.status !== PathStatus.Published && (
              <MenuItem onClick={onAdminPublish}>Publish</MenuItem>
            )}
            {path.status === PathStatus.Published && (
              <MenuItem onClick={onUnpublish}>Unpublish</MenuItem>
            )}
            {path.status === PathStatus.InReview && (
              <MenuItem onClick={onCancelReview}>Cancel Review</MenuItem>
            )}
            {path.status !== PathStatus.Archived && (
              <MenuItem onClick={onArchive}>Archive</MenuItem>
            )}
            {path.status === PathStatus.Archived && (
              <MenuItem onClick={onUnarchive}>Unarchive</MenuItem>
            )}
            {!!path.guides?.length ? (
              <MenuItem onClick={onOpenGuideModal}>Update Guide</MenuItem>
            ) : (
              <MenuItem onClick={onOpenGuideModal}>Add Guide</MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
      <SettingsModal
        path={path}
        triggerType={triggerType}
        isOpen={isSettingsModalOpen}
        onClose={closeSettingsModal}
        openToStep={path.status === PathStatus.InReview ? 3 : 1}
      />
      <GuideModal
        path={path}
        isOpen={isGuideModalOpen}
        onClose={closeGuideModal}
      />
    </>
  );
};
