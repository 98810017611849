import { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { yupToFormErrors } from "formik";

import { DEFAULT_CODE_EXPIRY_IN_DAYS } from "@tract/common/dist/constants/educator-codes";

import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
  useDisclosure,
  ButtonOutlined,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonSolid,
  useToast,
  FormLabel,
  ModalCloseButton,
  FormErrorMessage,
  FormControl,
  FormHelperText,
  Input,
  VStack,
} from "Shared";

import { ClassCodeRow } from "./ClassCodeRow";

import { captureException } from "Services/errors";
import {
  AdminDashboardCreateEducatorCodeMutationResult,
  AdminDashboardCreateEducatorCodeMutationVariables,
  AdminDashboardDeleteEducatorCodeMutationResult,
  AdminDashboardDeleteEducatorCodeMutationVariables,
  AdminDashboardLearnerGroupFragment,
  AdminDashboardUserAccountFragment,
  EducatorCodeFragment,
} from "@tract/common/dist/graphql";
import { useMutation } from "urql";
import { generateRandomCode } from "@tract/common/dist/utils/random-code";
import {
  ADMIN_CREATE_EDUCATOR_CODE_MUTATION,
  ADMIN_DELETE_EDUCATOR_CODE_MUTATION,
} from "./graphql";

type ClassCodesModalProps = {
  isOpen: boolean;
  learnerGroup: AdminDashboardLearnerGroupFragment;
  user: AdminDashboardUserAccountFragment;
  onClose: () => void;
};

export const ClassCodesModal: React.FC<ClassCodesModalProps> = ({
  isOpen,
  learnerGroup,
  user,
  onClose,
}) => {
  const [codeToDelete, setCodeToDelete] = useState<EducatorCodeFragment | null>(
    null
  );
  const menuTriggerRef = useRef(null);

  const {
    isOpen: isDeleteConfirmModalOpen,
    onClose: closeDeleteConfirmModal,
    onOpen: openDeleteConfirmModal,
  } = useDisclosure();

  const {
    isOpen: isCreateCodeModalOpen,
    onClose: closeCreateCodeModal,
    onOpen: openCreateCodeModal,
  } = useDisclosure();

  const onDeleteClick = (classCode: EducatorCodeFragment) => {
    setCodeToDelete(classCode);
    openDeleteConfirmModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{learnerGroup.name}</ModalHeader>
        <ModalBody>
          {learnerGroup.educatorCodes.length && (
            <Table width="100%" mt={3}>
              <Thead>
                <Tr>
                  <Th>Code</Th>
                  <Th>Status</Th>
                  <Th>Created at</Th>
                  <Th>Expires at</Th>
                  <Th>Seats Remaining</Th>
                  <Th>
                    <VisuallyHidden>Actions</VisuallyHidden>
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {learnerGroup.educatorCodes.map((edCode) => (
                  <ClassCodeRow
                    key={edCode.code}
                    code={edCode}
                    onSelectDelete={() => onDeleteClick(edCode)}
                    menuTriggerRef={
                      codeToDelete?.code === edCode.code ? menuTriggerRef : null
                    }
                  />
                ))}
              </Tbody>
            </Table>
          )}
          {codeToDelete && (
            <DeleteCodeConfirmModal
              code={codeToDelete.code}
              menuTriggerRef={menuTriggerRef}
              isOpen={isDeleteConfirmModalOpen}
              onClose={closeDeleteConfirmModal}
            />
          )}
          {learnerGroup && (
            <CreateCodeModal
              user={user}
              learnerGroup={learnerGroup}
              isOpen={isCreateCodeModalOpen}
              onClose={closeCreateCodeModal}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            size="lg"
            colorScheme="brandFull"
            onClick={openCreateCodeModal}
          >
            New Class Code
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type DeleteConfirmModalProps = {
  isOpen: boolean;
  code: string;
  menuTriggerRef: React.RefObject<HTMLElement>;
  onClose: () => void;
};

export const DeleteCodeConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isOpen,
  code,
  menuTriggerRef,
  onClose,
}) => {
  const [deleting, setDeleting] = useState(false);
  const toast = useToast();
  const [, deleteClassCode] = useMutation<
    AdminDashboardDeleteEducatorCodeMutationResult["data"],
    AdminDashboardDeleteEducatorCodeMutationVariables
  >(ADMIN_DELETE_EDUCATOR_CODE_MUTATION);

  const deleteCode = async () => {
    setDeleting(true);
    try {
      const { error } = await deleteClassCode({
        code,
      });
      if (error) throw error;

      onClose();

      toast({
        status: "success",
        title: "Code Deleted",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error deleting code",
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      finalFocusRef={menuTriggerRef}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="left">Delete Code?</ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to delete this code?</Text>
          <Text fontWeight="bold">{code}</Text>
          <Text color="gray.600" mt={2}>
            You cannot delete it if it has been redeemed
          </Text>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined onClick={onClose} disabled={deleting}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            onClick={deleteCode}
            isLoading={deleting}
            colorScheme="red"
          >
            Yes, Delete
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type CreateCodeModalProps = {
  user: AdminDashboardUserAccountFragment;
  learnerGroup: AdminDashboardLearnerGroupFragment;
  isOpen: boolean;
  onClose: () => void;
};

const createCodeSchema = yup.object().shape({
  total: yup
    .number()
    .integer("Please provide a valid number")
    .min(1, "At least one seat needed")
    .max(500, "Max seats allowed is 500")
    .required("Please provide a valid number")
    .typeError("Please provide a valid number"),
});

const CreateCodeModal: React.FC<CreateCodeModalProps> = ({
  learnerGroup,
  isOpen,
  onClose,
}) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState<{
    learnerGroupId?: string;
    total?: string;
  } | null>(null);
  const [totalSeats, setTotalSeats] = useState("");
  const toast = useToast();

  useEffect(() => {
    setCreating(false);
    setErrors(null);
    setTotalSeats("");
  }, [isOpen]);

  const [, createClassroomCode] = useMutation<
    AdminDashboardCreateEducatorCodeMutationResult["data"],
    AdminDashboardCreateEducatorCodeMutationVariables
  >(ADMIN_CREATE_EDUCATOR_CODE_MUTATION);

  const createCode = async () => {
    setCreating(true);
    setErrors(null);

    const codeDoc = {
      total: totalSeats,
    };

    try {
      await createCodeSchema.validate(codeDoc, { abortEarly: false });
      await createClassroomCode({
        available: Number(codeDoc.total),
        code: generateRandomCode(6),
        learnerGroupId: learnerGroup.id,
      });

      toast({
        status: "success",
        title: "Class Code Created",
      });
      onClose();
    } catch (error: any) {
      captureException(error);
      if (error instanceof yup.ValidationError) {
        setErrors(yupToFormErrors(error) as any);
      } else {
        toast({
          status: "error",
          title: "Error creating code",
        });
      }
    } finally {
      setCreating(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Class Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack}>
          <FormControl isInvalid={!!errors?.total} isRequired>
            <FormLabel htmlFor="total">Total Seats</FormLabel>
            <Input
              id="total"
              name="total"
              placeholder="Total Seats"
              type="number"
              size="lg"
              value={totalSeats}
              onChange={(e) => {
                setTotalSeats(e.target.value);
                setErrors((errors) => ({ ...errors, total: "" }));
              }}
            />
            <FormErrorMessage>{errors?.total}</FormErrorMessage>
            <FormHelperText>
              Expires{" "}
              {new Date(
                Date.now() + 1000 * 60 * 60 * 24 * DEFAULT_CODE_EXPIRY_IN_DAYS
              ).toLocaleDateString()}
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={onClose} disabled={creating}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            size="lg"
            onClick={createCode}
            isLoading={creating}
            colorScheme="brandFull"
          >
            Create Code
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
