import { BoxProps } from "@chakra-ui/react";
import { Box } from "Shared";

export const StepBox: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box px={10} py={6}>
      <Box mx="auto" maxW="30rem" minH="100%" {...props}>
        {children}
      </Box>
    </Box>
  );
};
