import React from "react";
import { Redirect } from "react-router-dom";

import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";

import { LearnersTable } from "./LearnersTable";

import { useSession } from "Services/session";

import { isParentUser } from "Types/User";

export const Learners: React.FC = () => {
  const { currentUser, currentKidIds, isAdmin } = useSession();

  const showAdminView = isAdmin || isParentUser(currentUser);

  if (currentUser.isEducator) {
    return <Redirect to="/classroom" />;
  }

  // TODO: URA
  return (
    <PageApp>
      <PageHeader title="Learners" />
      <LearnersTable
        kidIds={currentKidIds}
        showAdminView={showAdminView}
        classMemberOrTeacher={true}
      />
    </PageApp>
  );
};
