import { UpdateResolver } from "@urql/exchange-graphcache";

import { Mutation_RootUpdate_Learner_Group_By_PkArgs } from "@tract/common/dist/graphql";

export const update_learner_group_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const typedArgs = args as Mutation_RootUpdate_Learner_Group_By_PkArgs;
  const cacheKey = cache.keyOfEntity({
    __typename: "learner_group",
    id: typedArgs.pk_columns.id,
  });
  if (typedArgs._set?.archivedAt !== "-infinity") {
    cache.invalidate(cacheKey);
  }
};
