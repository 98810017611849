import { FlexProps } from "@chakra-ui/layout";
import React, { FC } from "react";
import { Flex, Logo, useTheme } from "Shared";

type Props = {
  withLogo?: boolean;
};

export const LayoutOnboarding: FC<Props & FlexProps> = ({
  withLogo,
  children,
  ...props
}) => {
  const { colors } = useTheme();
  return (
    <Flex
      direction="column"
      width={"37.5rem"}
      maxWidth="100%"
      p={[6, null, 10]}
      pt={[8, null, 12]}
      border={["none", "1px solid"]}
      borderColor={[null, "gray.300"]}
      borderRadius={16}
      mt={16}
      mb={32}
      mx="auto"
      {...props}
    >
      {withLogo && <Logo color={colors.brand} />}
      {children}
    </Flex>
  );
};
