import firebase from "firebase/compat";

import { MediaDoc } from "../../utils/media";

export enum ResponseType {
  Text = "text",
  Video = "video",
}

export enum InterestArea {
  Arts = "Arts",
  Nature = "Nature",
  Food = "Food",
  Sports = "Sports",
  Gaming = "Gaming",
  Music = "Music",
  Politics = "Politics",
  Technology = "Technology",
  Business = "Business",
  Health = "Health & Wellness",
}

export interface Challenge {
  id: string;
  challengeContentUrl?: string;
  question: string;
  responseType: ResponseType;
  description: string;
  isShareable?: boolean;
  XP: number;
  minutesToComplete: number;
}

export enum StepContentType {
  None = "",
  Video = "video",
  Image = "image",
}

export interface Step {
  choices?: string[];
  contentUrl: string;
  contentUrlType: StepContentType;
  apiVideoUrl?: string;
  description: string;
  quiz: string;
  title: string;
}

type BaseBlock = {
  id: string;
  type: BlockType;
  value: string;
};

export enum BlockType {
  Text = "text",
  Heading = "heading",
  Image = "image",
  Video = "video",
  Vimeo = "vimeo",
  Challenge = "challenge",
}

export type HeadingBlock = {
  type: BlockType.Heading;
  value: string;
} & BaseBlock;

export type TextBlock = {
  type: BlockType.Text;
  value: string;
} & BaseBlock;

export type ImageBlock = {
  type: BlockType.Image;
  value: string;
} & BaseBlock;

export type VideoBlock = {
  type: BlockType.Video;
  value: string;
  reactionId?: string;
  commentSectionId?: string;
  commentCount?: number;
} & BaseBlock;

export type VimeoBlock = {
  type: BlockType.Vimeo;
  value: string;
} & BaseBlock;

export type ChallengeBlock = {
  type: BlockType.Challenge;
  value: Challenge;
  reactionId?: string;
  commentSectionId?: string;
} & BaseBlock;

export type ContentBlock =
  | TextBlock
  | HeadingBlock
  | ImageBlock
  | VideoBlock
  | VimeoBlock
  | ChallengeBlock;

export interface Mission {
  challenges: Challenge[];
  challengeIds: string[];
  createdAt: {
    _nanoseconds: number;
    _seconds: number;
  };
  description: string;
  title: string;
  steps: Step[];
  contentBlocks?: ContentBlock[];
  updatedAt:
    | {
        _nanoseconds: number;
        _seconds: number;
      }
    | firebase.firestore.Timestamp;
  media?: {
    blocks?: Record<string, { video?: { id: string; source: string } }>;
  };
}

export enum PathStatus {
  Unknown = "UNKNOWN",
  PublishEnqueued = "PUBLISH_ENQUEUED",
  Published = "PUBLISHED",
  Publishing = "PUBLISHING",
  PublishError = "PUBLISH_ERROR",
  InReview = "IN_REVIEW",
  Draft = "DRAFT",
  Archived = "ARCHIVED",
}

export enum PathVisibility {
  Public = "PUBLIC",
  OrgOnly = "ORG_ONLY",
}

export declare type UserPathHistory = {
  userId: string;
  pathId: string;
  lastViewed: firebase.firestore.Timestamp;
};

export interface Path extends MediaDoc {
  _schemaVersion: number;
  archivedAt: Date;
  author: string;
  cover: string;
  coverAnimated?: string;
  description: string;
  gradeLevels: string[];
  interestArea: InterestArea;
  isFeatured: boolean;
  isPublished: boolean;
  missionsOrder: string[];
  publishedAt: Date;
  publishedWeek: string;
  skills: string[];
  subjects: string[];
  tags: string[];
  slug: string;
  status?: PathStatus;
  title: string;
  trailerUrl: string;
  trailer?: {
    foreignVideoId: string;
    videoUrl: string;
  };
  newTags: string[];
  supplies: string;
  createdAt: Date;
  updatedAt: Date;
  xpMultiplier?: number;
  totalXP: number;
  leadersCount: number;
  questionsCount: number;
  reviewRequestedAt: Date;
  orgId: string;
  visibility: PathVisibility;
}
export interface PathTooltipDoc {
  pathId: number;
}

export const TEACHER_PATH_TAG = "TractForTeachers";
