import { PropsWithChildren, ReactNode } from "react";
import { forwardRef } from "@chakra-ui/system";
import { LinkProps } from "react-router-dom";

import { Box, Link } from "Shared";

type Props = {
  isActive?: boolean;
  icon?: ReactNode;
} & LinkProps;

export const TopNavLink = forwardRef<PropsWithChildren<Props>, "a">(
  ({ isActive = false, icon, children, ...props }, ref) => {
    return (
      <Link
        fontSize="lg"
        fontWeight="bold"
        color={isActive ? "brandFull.500" : "gray.900"}
        _hover={{ textDecoration: "none", color: "brandFull.500" }}
        transition="150ms ease color"
        display="flex"
        alignItems="center"
        {...props}
        ref={ref}
      >
        {icon && <Box mr={2}>{icon}</Box>}
        {children}
      </Link>
    );
  }
);
