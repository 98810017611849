import React, { FC } from "react";
import { Formik, Field, Form, FieldProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  Box,
  Text,
  ButtonSolid,
  Input,
  FormControl,
  FormLabel,
  Link,
  Divider,
  FormErrorMessage,
  Switch,
  Flex,
  BadgeOutlined,
  ButtonOutlined,
  Stack,
} from "Shared";
import { PlanOption } from "Components/PlanOption";
import { OfferBanner } from "Components/OfferBanner";
import { getTotalAmount } from "Services/prices";
import { useSession } from "Services/session";

import { PlanInterval, PlanType } from "Types/User";

const ParentSignUpFormSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter first name"),
  lastName: Yup.string().required("Enter last name"),
  email: Yup.string().email("Invalid email").required("Enter an email"),
  password: Yup.string()
    .required("Enter a password")
    .min(6, "Password must be at least 6 characters"),
});

const CancelFormSchema = Yup.object().shape({
  firstName: Yup.string().required("Enter first name"),
  lastName: Yup.string().required("Enter last name"),
});

export type OnboardingForm = {
  firstName: string;
  lastName: string;
  planType: PlanType;
  planInterval: PlanInterval;
  email: string;
  password: string;
};

type Props = {
  prices: TODO;
  isLoading: boolean;
  openIntercom: () => void;
  onSubmit: ({
    profile,
    actions,
  }: {
    profile: TODO;
    actions: FormikHelpers<OnboardingForm>;
  }) => void;
  prefilledEmail?: string;
  signOut?: (e: React.MouseEvent) => void;
};

export const ParentOnboardingForm: FC<Props> = ({
  prices,
  onSubmit,
  isLoading,
  openIntercom,
  signOut,
  prefilledEmail,
}) => {
  const { currentUser } = useSession();
  function handleSubmit(
    data: OnboardingForm,
    actions: FormikHelpers<OnboardingForm>
  ) {
    onSubmit({ profile: data, actions });
  }

  return (
    <>
      <Text
        as="h1"
        fontSize={["3xl", null, null, "4xl"]}
        fontWeight="bold"
        color="gray.900"
        mb={6}
        mt={currentUser ? 10 : 0}
      >
        Start your free 14 days
      </Text>

      <Formik
        initialValues={
          currentUser
            ? {
                firstName: "",
                lastName: "",
                planType: PlanType.Single,
                planInterval: PlanInterval.Year,
                email: "",
                password: "",
              }
            : {
                firstName: "",
                lastName: "",
                planType: PlanType.Single,
                planInterval: PlanInterval.Year,
                email: prefilledEmail ? prefilledEmail : "",
                password: "",
              }
        }
        validationSchema={
          currentUser ? CancelFormSchema : ParentSignUpFormSchema
        }
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form noValidate>
            <Stack direction="column" spacing={6} align="stretch" mb={10}>
              <Stack
                direction={{ base: "column", lg: "row" }}
                spacing={6}
                align="stretch"
              >
                <Field name="firstName">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={submitCount > 0 && !!meta.error}
                    >
                      <FormLabel htmlFor="firstName">First name</FormLabel>
                      <Input
                        size="lg"
                        id="firstName"
                        placeholder="First name"
                        type="text"
                        autoFocus
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="lastName">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={submitCount > 0 && !!meta.error}
                    >
                      <FormLabel htmlFor="lastName">Last name</FormLabel>
                      <Input
                        size="lg"
                        id="lastName"
                        placeholder="Last name"
                        type="text"
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>

              {currentUser ? (
                <Field name="email">
                  {() => (
                    <FormControl>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input
                        size="lg"
                        id="email"
                        value={currentUser.email || ""}
                        placeholder="Email"
                        type="email"
                        disabled
                      />
                    </FormControl>
                  )}
                </Field>
              ) : (
                <Field name="email">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={submitCount > 0 && !!meta.error}
                    >
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input
                        size="lg"
                        id="email"
                        placeholder="Email"
                        type="email"
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}

              {!currentUser && (
                <Field name="password">
                  {({ field, form: { submitCount }, meta }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={submitCount > 0 && !!meta.error}
                    >
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <Input
                        size="lg"
                        id="password"
                        placeholder="Password"
                        type="password"
                        {...field}
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
            </Stack>

            <Text fontSize="2xl" fontWeight="bold" textAlign="center" mb={3}>
              Pick a plan
            </Text>
            <Field name="planInterval">
              {({ form: { submitCount }, meta }: FieldProps) => (
                <FormControl mb={4} isInvalid={submitCount > 0 && !!meta.error}>
                  <Flex justify="center" align="center">
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={
                        values?.planInterval === PlanInterval.Month
                          ? "gray.900"
                          : "gray.600"
                      }
                      textAlign="center"
                    >
                      Pay Monthly
                    </Text>
                    <Switch
                      name="planInterval"
                      isChecked={
                        values?.planInterval === PlanInterval.Month
                          ? false
                          : true
                      }
                      onChange={() => {
                        setFieldValue(
                          "planInterval",
                          values?.planInterval === PlanInterval.Month
                            ? PlanInterval.Year
                            : PlanInterval.Month
                        );
                      }}
                      ml={4}
                      size="lg"
                      mt={2}
                    />
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      color={
                        values?.planInterval === PlanInterval.Year
                          ? "gray.900"
                          : "gray.600"
                      }
                      ml={4}
                    >
                      Pay Yearly
                    </Text>
                    <BadgeOutlined textTransform="capitalize" ml={2}>
                      Save 20%
                    </BadgeOutlined>
                  </Flex>
                </FormControl>
              )}
            </Field>
            <Field name="planType" width="100%">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  mb={6}
                  isInvalid={submitCount > 0 && !!meta.error}
                  width="100%"
                >
                  <Stack
                    direction={{ base: "column", lg: "row" }}
                    spacing={6}
                    align="stretch"
                  >
                    <PlanOption
                      planType={PlanType.Single}
                      price={
                        values?.planInterval === PlanInterval.Year
                          ? prices?.single?.year
                          : prices?.single?.month
                      }
                      changePlan={() =>
                        setFieldValue("planType", PlanType.Single)
                      }
                      isSelected={values?.planType === PlanType.Single}
                    ></PlanOption>
                    <PlanOption
                      planType={PlanType.Family}
                      price={
                        values?.planInterval === PlanInterval.Year
                          ? prices?.family?.year
                          : prices?.family?.month
                      }
                      changePlan={() =>
                        setFieldValue("planType", PlanType.Family)
                      }
                      isSelected={values?.planType === PlanType.Family}
                    ></PlanOption>
                  </Stack>
                </FormControl>
              )}
            </Field>

            {prices?.discounted && <OfferBanner mb={6} />}
            <Divider my={6} />
            <ButtonSolid
              size="lg"
              width="100%"
              type="submit"
              isLoading={isLoading}
            >
              Start Your 14 Day Free Trial
            </ButtonSolid>
            {currentUser && (
              <ButtonOutlined size="lg" width="100%" onClick={signOut} mt={3}>
                I changed my mind
              </ButtonOutlined>
            )}
            <Text fontSize="sm" color="gray.900" mb={6} mt={6}>
              You’ll have 14 days of trial access. After that, you’ll be charged
              ${getTotalAmount(prices, values?.planInterval, values?.planType)}{" "}
              for your first {values?.planInterval} and a recurring{" "}
              {values?.planInterval === PlanInterval.Month
                ? "monthly"
                : "yearly"}{" "}
              charge of $
              {getTotalAmount(prices, values?.planInterval, values?.planType)}{" "}
              will automatically apply. You may cancel at any time in your
              payment settings. By clicking 'Start Your 14 Day Free Trial', you
              agree to our{" "}
              <Text
                as={Link}
                to="/legal/tos"
                color="brand"
                fontWeight="bold"
                target="_blank"
              >
                Terms of Service
              </Text>{" "}
              and{" "}
              <Text
                as={Link}
                to="/legal/privacy"
                color="brand"
                fontWeight="bold"
                target="_blank"
              >
                Privacy Policy
              </Text>
              , and authorize this recurring charge.
            </Text>
            <Text fontSize="sm" color="gray.900">
              We're on a mission to improve equity in education. If you are a
              family in need,{" "}
              <Box
                as="span"
                color="brand"
                fontWeight="bold"
                cursor="pointer"
                onClick={openIntercom}
                _hover={{ textDecoration: "underline" }}
              >
                contact us
              </Box>{" "}
              to request a sponsorship.
            </Text>
          </Form>
        )}
      </Formik>
    </>
  );
};
