import { gql } from "urql";

export const GET_LMS_OPTIONS_QUERY = gql`
  query GetLMSOptions {
    lms: lms(order_by: { imageFilePath: asc }, limit: 50) {
      id
      name
      helpArticleLink
      imageFilePath
    }
  }
`;
