import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useCollection } from "Utils/hooks/firestore";
import { ExplorePage } from "./BaseExplorePage";

type Tag = {
  value: string;
  description?: string;
};

type Props = {
  authed: boolean;
};

export const ExploreTag: React.FC<Props> = ({ authed }) => {
  const { tag: tagParam } = useParams<{ tag: string }>();
  const tagValue: string = tagParam;

  const { data: tagData } = useCollection<Tag>("tags", {
    where: ["value", "==", tagValue.toLowerCase()],
    listen: false,
  });
  const tag = tagData && tagData[0];

  const { track } = useAnalytics();
  useEffect(() => {
    track(ANALYTICS_EVENTS.TAG_VIEWED, {
      tagValue,
    });
  }, [track, tagValue]);

  return (
    <ExplorePage
      description={tag?.description}
      tags={tagValue ? [tagValue] : undefined}
      iconDuotone={duotone("hashtag")}
      title={`#${tagValue}`}
    />
  );
};
