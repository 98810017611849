import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { PageHeader } from "Components/PageHeader";
import { AdminCommentsV2 } from "./AdminCommentsV2";
import { AdminContent } from "../AdminContent";

export const AdminComments: React.FC = () => {
  return (
    <AdminContent>
      <PageHeader title="Comments" />
      <Switch>
        <Route path="/admin/comments" exact>
          <AdminCommentsV2 />
        </Route>
      </Switch>
    </AdminContent>
  );
};
