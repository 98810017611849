export interface Comment {
  userId: string;
  text: string;
  upvoteCount: number;
  downvoteCount: number;
  replyCount: number;
  createdAt?: Date;
  updatedAt?: Date;
  upvoteUserIds?: Set<string>;
  status: CommentStatus;
  parentCommentId?: string;
  isRoot: boolean;
}

export interface CommentSection {
  entityId: string;
  entityType: string;
  missionId: number;
  pathId: number;
  count: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum CommentStatus {
  NeedsReview = "NEEDS_REVIEW",
  Accepted = "ACCEPTED",
  Rejected = "REJECTED",
  Deleted = "DELETED",
}
