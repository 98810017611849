import {
  PopoverCloseButton as ChakraPopoverCloseButton,
  forwardRef,
  IconButtonProps,
} from "@chakra-ui/react";

import { IconButton, IconX } from "Shared";

export {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverBody,
  PopoverFooter,
} from "@chakra-ui/react";

export const PopoverCloseButton = forwardRef<IconButtonProps, "button">(
  (props, ref) => {
    return (
      <IconButton
        as={ChakraPopoverCloseButton}
        variant="ghost"
        size="md"
        icon={<IconX />}
        position="absolute"
        top={{ base: 2, md: 3 }}
        right={{ base: 2, md: 3 }}
        ref={ref}
        {...props}
      />
    );
  }
);
