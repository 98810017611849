import React, { FC, PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

import { Box, Flex, Logo } from "Shared";

import { colors } from "Shared/themes/shared";

export { ContainerMarketing } from "../LayoutMarketing/ContainerMarketing";

type Props = {
  title?: string;
};

export const LayoutError: FC<PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  return (
    <Box position="relative" bg="gray.50" height="100%">
      <Helmet>
        <title>{title ? `${title} — Tract` : "Tract"}</title>
      </Helmet>
      <Box maxWidth="100rem" bg="white" mx="auto" pt={6} height="100%">
        <Flex align="center" justify="center" height="100%">
          <Logo
            as="a"
            href="/"
            aria-label="Home"
            color={colors.brand}
            transition="150ms ease-in-out transform"
            _hover={{ transform: "scale3d(1.1, 1.1, 1.1)" }}
          />
        </Flex>
        <Box maxWidth="400px" mx="auto" mt={10}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
