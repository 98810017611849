import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { Center } from "@chakra-ui/layout";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { lookup } from "mime-types";

import {
  Box,
  Button,
  EmptyState,
  Grid,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Thead,
  Th,
  Tr,
  useToast,
  VisuallyHidden,
  FormControl,
  useDisclosure,
  ButtonGroup,
  FormLabel,
  EmptyStateFontAwesomeIcon,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

import { Status } from "@tract/common/dist/types/models/Submission";
import { isParentUser, isTeacherUser, isAuthorUser } from "Types/User";

import { SubmissionDrawer } from "../SubmissionDrawer";
import { ArtifactPreviewPopover } from "./ArtifactPreviewPopover";
import { useSession } from "Services/session";
import { SubmissionStatusBadge } from "../SubmissionStatusBadge";
import { useQuery as useParams } from "Utils";
import { useFeature } from "Services/features";
import { FormLabelWithClearAction } from "Components/FormLabelWithClearAction";
import {
  AdminProjectFragment,
  AdminProjectsQueryVariables,
  ClassroomsByTeacherIdQueryResult,
  ClassroomsByTeacherIdQueryVariables,
  SubmissionLearnerFilterUsersQueryResult,
  SubmissionLearnerFilterUsersQueryVariables,
  useAdminProjectsQuery,
  UsernameSearchQueryResult,
  UsernameSearchQueryVariables,
} from "@tract/common/dist/graphql";
import { gql, useClient, useQuery } from "urql";
import { CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/Classroom/graphql";
import { USERNAME_SEARCH_QUERY } from "../graphql";

export type SubmissionRow = {
  submissionId: string;
  username: string;
  firstName: string;
  lastName: string;
  userId: string;
  previewURL?: string;
  response: string;
  responseType: string;
  pathId: string;
  missionId: string;
  challengeId: string;
  status: Status;
  projectId?: string;
  isRejected: boolean;
  rejecterId?: string;
  rejecterFullName?: string;
  mime: string;
  updatedAt: Date;
  createdAt: Date;
};

const SUBMISSION_LEARNER_FILTER_USERS_QUERY = gql`
  query SubmissionLearnerFilterUsers($userIds: [String!]!) {
    user(where: { firestoreId: { _in: $userIds } }) {
      id: firestoreId
      username
      firstName
      lastName
    }
  }
`;

const SUBMISSIONS_LIMIT = 25;

type Props = {
  learnerGroupId?: string;
};

export const Submissions: React.FC<Props> = ({ learnerGroupId }) => {
  const urql = useClient();
  const toast = useToast();
  const history = useHistory();
  const query = useParams();
  const { currentUser, isAdmin } = useSession();

  const pathParam = query.get("path");
  const statusParam = query.get("status");
  const uidParam = query.get("uid");
  const learnerGroupIdParam = learnerGroupId
    ? learnerGroupId
    : query.get("learnerGroupId");

  const [filterPathId, setFilterPathId] = useState(pathParam || "");
  const [filterStatus, setFilterStatus] = useState(statusParam || "");
  const [filterUserId, setFilterUserId] = useState(uidParam || "");
  const [filterLearnerGroupId, setFilterLearnerGroupId] = useState(
    learnerGroupIdParam || ""
  );

  const [pathIdValue, setPathIdValue] = useState(pathParam || "");

  useEffect(() => {
    if (filterPathId !== pathParam) {
      setFilterPathId(pathParam || "");
    }
  }, [pathParam, filterPathId]);

  useEffect(() => {
    if (filterStatus !== statusParam) {
      setFilterStatus(statusParam || "");
    }
  }, [statusParam, filterStatus]);

  useEffect(() => {
    if (filterUserId !== uidParam) {
      setFilterUserId(uidParam || "");
    }
  }, [uidParam, filterUserId]);

  const [usernameSearchValue, setUsernameSearchValue] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentSubmission, setCurrentSubmission] =
    useState<AdminProjectFragment>();
  const isAuthor = isAuthorUser(currentUser);
  const isAuthorRoute = !!useRouteMatch("/author/submissions");
  const isCreatorSubmissionsEnabled = useFeature("creator-submissions-view");
  const isParentOrTeacher =
    isTeacherUser(currentUser) || isParentUser(currentUser);

  const [{ data: learnerGroupsResult, fetching: loadingLearnerGroups }] =
    useQuery<
      ClassroomsByTeacherIdQueryResult["data"],
      ClassroomsByTeacherIdQueryVariables
    >({
      pause: isAdmin,
      query: CLASSROOMS_BY_TEACHER_ID_QUERY,
      variables: {
        teacherUserId: currentUser.id,
      },
    });

  const learnerGroups = learnerGroupsResult?.learnerGroups;

  const learnerGroupMembers = useMemo(() => {
    if (learnerGroups && filterLearnerGroupId) {
      const group = learnerGroups.find(
        (group) => group.id === filterLearnerGroupId
      );
      return group?.members.filter(({ role }) => role === "learner") || [];
    }

    return;
  }, [learnerGroups, filterLearnerGroupId]);

  const [{ data: usersFilterResult }] = useQuery<
    SubmissionLearnerFilterUsersQueryResult["data"],
    SubmissionLearnerFilterUsersQueryVariables
  >({
    query: SUBMISSION_LEARNER_FILTER_USERS_QUERY,
    pause: isAdmin,
    variables: {
      userIds: learnerGroupMembers?.map(({ userId }) => userId) || [],
    },
  });

  const kids = usersFilterResult?.user;

  const authorIdFilter =
    isAuthorRoute && isAuthorUser(currentUser) ? currentUser.uid : null;

  const queryVars: AdminProjectsQueryVariables = {
    limit: SUBMISSIONS_LIMIT,
    offset: 0,
    rejectedAtInput: {
      ...(filterStatus
        ? filterStatus === "ACCEPTED"
          ? { _eq: "-infinity" }
          : { _neq: "-infinity" }
        : {}),
    },
    userIdInput: {
      ...(filterUserId && { _eq: filterUserId }),
      ...(learnerGroupMembers && {
        _in: learnerGroupMembers.map(({ userId }) => userId),
      }),
    },
    pathIdInput: {
      ...(filterPathId && { _eq: parseInt(filterPathId, 10) }),
    },
    pathInput: {
      ...(authorIdFilter && {
        _or: [
          { authorId: { _eq: authorIdFilter } },
          { authors: { userId: { _eq: authorIdFilter } } },
        ],
      }),
    },
    published: {
      ...(!isAdmin &&
        !isParentOrTeacher && {
          _eq: true,
        }),
    },
  };

  const { data, loading, fetchMore } = useAdminProjectsQuery({
    variables: queryVars,
  });

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    const submissionsCount = data?.projects.length || 0;
    if (submissionsCount % SUBMISSIONS_LIMIT > 0) {
      setIsLastPage(true);
    } else if (isLastPage) {
      setIsLastPage(false);
    }
  }, [data?.projects.length, isLastPage]);

  const paginate = async () => {
    if (!data?.projects.length) {
      return;
    }

    setIsLoadingMore(true);

    try {
      await fetchMore({
        variables: {
          ...queryVars,
          offset: data.projects.length,
        },
      });
    } catch (err) {
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleUsernameFilterSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { data } = await urql
      .query<UsernameSearchQueryResult["data"], UsernameSearchQueryVariables>(
        USERNAME_SEARCH_QUERY,
        {
          username: usernameSearchValue,
        }
      )
      .toPromise();

    const users = data?.user;

    if (!usernameSearchValue) {
      query.delete("uid");
      setFilterUserId("");
    } else {
      if (users && users.length > 0) {
        const userId = users[0].id;
        query.set("uid", userId);
        setFilterUserId(userId);
      } else {
        query.delete("uid");
        setFilterUserId("");

        toast({
          status: "error",
          title: "User not found",
        });
      }
    }

    history.push(`?${query.toString()}`);
  };

  const handleUsernameFilterChange = (e: any) => {
    setUsernameSearchValue(e.target.value);
  };

  const handleLearnerGroupFilterChange = (e: any) => {
    const { value } = e.target;

    if (value.length) {
      query.set("learnerGroupId", value);
      query.delete("uid");
    } else {
      query.delete("learnerGroupId");
    }

    setFilterLearnerGroupId(value);
    setFilterUserId("");
    history.push(`?${query.toString()}`);
  };

  const filterByUID = (uid: string) => {
    if (uid.length) {
      query.set("uid", uid);
      setFilterUserId(uid);
    } else {
      query.delete("uid");
      setFilterUserId("");
    }

    history.push(`?${query.toString()}`);
  };

  const handleLearnerFilterChange = (e: any) => {
    const { value } = e.target;
    filterByUID(value);
  };

  const handleStatusFilterChange = (e: any) => {
    const { value } = e.target;

    if (value) {
      query.set("status", value);
    } else {
      query.delete("status");
    }

    setFilterStatus(value);
    history.push(`?${query.toString()}`);
  };

  const handlePathIdFilterSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pathIdValue) {
      query.set("path", pathIdValue);
    } else {
      query.delete("path");
    }

    setFilterPathId(pathIdValue);
    history.push(`?${query.toString()}`);
  };

  const handleDrawerClose = () => {
    onClose();
  };

  const handleClickClearFilter = (filterName: string) => {
    query.delete(filterName);

    switch (filterName) {
      case "path":
        setFilterPathId("");
        setPathIdValue("");
        break;
      case "status":
        setFilterStatus("");
        break;
      case "uid":
        setUsernameSearchValue("");
        setFilterUserId("");
        break;
      case "learnerGroupId":
        setFilterLearnerGroupId("");
        break;
    }

    history.push(`?${query.toString()}`);
  };

  if (isAuthor && !isCreatorSubmissionsEnabled) {
    return <Redirect to="/author" />;
  }

  if (isParentOrTeacher && loadingLearnerGroups) {
    return <LayoutCentered isLoading />;
  }

  return (
    <Box>
      <Grid
        templateColumns={
          isAdmin
            ? "repeat(4, 1fr)"
            : {
                base: "repeat(2, 1fr)",
                lg: "repeat(3, 1fr)",
                xl: "repeat(4, 1fr)",
              }
        }
        gap={3}
        mb={10}
      >
        {isTeacherUser(currentUser) && !learnerGroupId && (
          <FormControl>
            <FormLabel>Class</FormLabel>
            <Select
              name="learnerGroupId"
              variant="filled"
              value={filterLearnerGroupId}
              onChange={handleLearnerGroupFilterChange}
            >
              {learnerGroups?.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        {isAdmin || isAuthor ? (
          <form onSubmit={handleUsernameFilterSubmit}>
            <FormControl>
              <FormLabelWithClearAction
                showClearAction={!!filterUserId}
                onClickClear={() => handleClickClearFilter("uid")}
              >
                Username
              </FormLabelWithClearAction>
              <Input
                name="username"
                variant="filled"
                value={usernameSearchValue}
                onChange={handleUsernameFilterChange}
              />
            </FormControl>
          </form>
        ) : (
          <FormControl>
            <FormLabelWithClearAction
              showClearAction={!!filterUserId}
              onClickClear={() => handleClickClearFilter("uid")}
            >
              Learner
            </FormLabelWithClearAction>
            <Select
              name="learner"
              variant="filled"
              value={filterUserId}
              onChange={handleLearnerFilterChange}
            >
              <option value="">All</option>

              {kids?.map((kid) => (
                <option key={kid.id} value={kid.id}>
                  {isParentOrTeacher
                    ? `${kid.firstName} ${kid.lastName || ""}`
                    : kid.username}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!filterStatus}
            onClickClear={() => handleClickClearFilter("status")}
          >
            Status
          </FormLabelWithClearAction>
          <Select
            name="status"
            variant="filled"
            value={filterStatus}
            onChange={handleStatusFilterChange}
          >
            <option value="">All</option>
            <option value={Status.Accepted}>Approved</option>
            <option value={Status.Rejected}>Changes Requested</option>
          </Select>
        </FormControl>
        {isAdmin && (
          <form onSubmit={handlePathIdFilterSubmit}>
            <FormControl>
              <FormLabelWithClearAction
                showClearAction={!!filterPathId}
                onClickClear={() => handleClickClearFilter("path")}
              >
                Path ID
              </FormLabelWithClearAction>
              <Input
                name="pathId"
                variant="filled"
                value={pathIdValue}
                onChange={(e) => setPathIdValue(e.target.value)}
              />
            </FormControl>
          </form>
        )}
      </Grid>
      {loading ? (
        <LayoutCentered isLoading height="auto" />
      ) : !!data?.projects.length ? (
        <>
          <Box overflowX={{ base: "auto", lg: "visible" }}>
            <Table width="100%">
              <Thead>
                <Tr>
                  <Th>{isParentOrTeacher ? "Learner" : "Username"}</Th>
                  <Th>Submitted at</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                  <Th>
                    <VisuallyHidden>Actions</VisuallyHidden>
                  </Th>
                </Tr>
              </Thead>
              <Tbody fontSize="md">
                {data?.projects.map((row) => (
                  <Tr key={row.id}>
                    <Td py={2}>
                      <Button
                        variant="link"
                        color="gray.900"
                        onClick={() => {
                          setUsernameSearchValue(row.user.username || "");
                          filterByUID(row.user.id);
                        }}
                      >
                        {isParentOrTeacher
                          ? `${row.user.firstName} ${row.user.lastName || ""}`
                          : row.user.username}
                      </Button>
                    </Td>
                    <Td py={2}>{new Date(row.createdAt).toLocaleString()}</Td>
                    <Td py={2}>
                      {`File Upload (${lookup(row.file?.filename || "")})`}
                    </Td>
                    <Td py={2}>
                      <SubmissionStatusBadge submission={row} />
                    </Td>
                    <Td py={2} textAlign="end">
                      <ButtonGroup>
                        <ArtifactPreviewPopover project={row}>
                          <Button variant="outline">Preview</Button>
                        </ArtifactPreviewPopover>
                        <Button
                          variant="outline"
                          onClick={() => {
                            setCurrentSubmission(row);
                            onOpen();
                          }}
                        >
                          View
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {currentSubmission && (
            <SubmissionDrawer
              isOpen={isOpen}
              onClose={handleDrawerClose}
              project={currentSubmission}
            />
          )}
          <Center>
            {!isLastPage && (
              <Button
                size="lg"
                variant="outline"
                onClick={paginate}
                mt={10}
                isLoading={isLoadingMore}
              >
                Load More
              </Button>
            )}
          </Center>
        </>
      ) : (
        <EmptyState
          icon={<EmptyStateFontAwesomeIcon icon={duotone("images")} />}
          headline="No projects found"
        />
      )}
    </Box>
  );
};
