import { countries } from "country-emoji";
import { useMemo } from "react";

export const useCountryNames = () =>
  useMemo(
    () =>
      Object.values(countries)
        // Remove EU
        .filter(country => country !== countries["EU"])
        // Only use the first name
        .map(country => (Array.isArray(country) ? country[0] : country))
        .sort(),
    []
  );
