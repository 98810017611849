import { createIcon } from "@chakra-ui/react";

export const IconTextInverted = createIcon({
  displayName: "IconTextInverted",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5ZM7 6C6.44772 6 6 6.44772 6 7V9C6 9.55228 6.44772 10 7 10C7.55228 10 8 9.55228 8 9V8H11V16H9C8.44772 16 8 16.4477 8 17C8 17.5523 8.44772 18 9 18H12H15C15.5523 18 16 17.5523 16 17C16 16.4477 15.5523 16 15 16H13V8H16V9C16 9.55228 16.4477 10 17 10C17.5523 10 18 9.55228 18 9V7C18 6.44772 17.5523 6 17 6H12H7Z"
      fill="currentColor"
    />,
  ],
});
