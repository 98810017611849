import React from "react";
import { Link as ChakraLink, forwardRef } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export const Link = forwardRef((props, ref) => {
  return <ChakraLink as={RouterLink} ref={ref} {...props} />;
});

export const ExternalLink = forwardRef((props, ref) => {
  return <ChakraLink ref={ref} {...props} target="_blank" />;
});
