import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDisclosure } from "Shared";

export const useRouteUpdateDisclosure = (): {
  isOpen: boolean;
  onOpen: (e: any) => void;
  onClose: (goBack?: boolean) => void;
} => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  function handleOpen(e: any) {
    e.preventDefault();
    onOpen();
  }

  // Call onClose if user navigates back while open
  useEffect(() => {
    if (isOpen) {
      const listener = () => {
        onClose();
      };

      window.addEventListener("popstate", listener);

      return () => window.removeEventListener("popstate", listener);
    }
  }, [isOpen, history, onClose]);

  function handleClose(goBack?: boolean) {
    // On close, reset the URL back to where it was
    if (goBack) {
      window.history.back();
    }
    onClose();
  }

  return {
    onOpen: handleOpen,
    onClose: handleClose,
    isOpen,
  };
};
