/**
 * Enum describing a video type stored in api.video as metadata
 */
// TODO: Deprecated
export enum VideoType {
  NodeVideo = "node_video",
  PathTrailer = "path_trailer",
  MissionVideo = "mission_video",
  MissionVideoBlock = "mission_video_block",
}

export interface CreateVideoResponse {
  videoId: string;
  videoUrl: string;
}

export interface GetVideoResponse {
  status: object;
  video: object;
}
