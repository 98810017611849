import { useRef } from "react";
import { forwardRef, InputGroupProps } from "@chakra-ui/react";

import {
  IconButton,
  IconX,
  Input,
  InputGroup,
  InputLeftElement,
  IconSearch,
  InputRightElement,
  ScaleFade,
} from "Shared";

export type Props = {
  query: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onClear: () => void;
  placeholder?: string;
} & InputGroupProps;

export const SearchInput = forwardRef(
  ({ query, onChange, onClear, placeholder, ...props }: Props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClear = () => {
      onClear();
      inputRef.current && inputRef.current.focus();
    };

    return (
      <InputGroup variant="filled" size="lg" ref={ref} {...props}>
        <InputLeftElement>
          <IconSearch color="gray.600" />
        </InputLeftElement>
        <Input
          name="query"
          value={query}
          id="search-input"
          onChange={onChange}
          borderRadius="full"
          autoComplete="off"
          placeholder={placeholder}
          flex={1}
          ref={inputRef}
        />
        <InputRightElement>
          <ScaleFade in={!!query.length} initialScale={0.8}>
            <IconButton
              aria-label="Clear search"
              icon={<IconX />}
              variant="ghost"
              onClick={handleClear}
              tabIndex={!!query.length ? 0 : -1}
            />
          </ScaleFade>
        </InputRightElement>
      </InputGroup>
    );
  }
);
