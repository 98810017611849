import { useFormik } from "formik";
import { useMutation } from "urql";
import { v4 } from "uuid";

import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  FormHelperText,
  ModalFooter,
  ButtonOutlined,
  ButtonSolid,
  HStack,
  Textarea,
} from "Shared";

import { ReportReasons, ReportReasonTypes } from "Types/AbuseReport";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { useFeature } from "Services/features";

import {
  AdminProjectFragment,
  AdminProjectPathFragment,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
  useRejectProjectMutation,
} from "@tract/common/dist/graphql";
import { createNotification } from "@tract/common/dist/utils/create-notification";

import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";

type RejectSubmissionModalProps = {
  submission: AdminProjectFragment;
  node: AdminProjectPathFragment["node"];
  isOpen: boolean;
  onClose: (rejected?: boolean) => void;
};

export const RejectSubmissionModal: React.FC<RejectSubmissionModalProps> = ({
  submission,
  node,
  isOpen,
  onClose,
}) => {
  const toast = useToast();
  const isSubmissionFeedbackEnabled = useFeature("submission-feedback");
  const { currentUser } = useSession();
  const { track, trackForUser } = useAnalytics();
  const [rejectProject] = useRejectProjectMutation();
  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);
  const path = node?.path;

  const formik = useFormik({
    initialValues: {
      reason: ReportReasonTypes.Invalid,
      feedback: "",
    },

    onSubmit: async (values) => {
      const coinTrxId = v4();

      try {
        await rejectProject({
          variables: {
            coinAmount: -(submission.rewardCoin?.amount || 0),
            coinTrxId,
            projectId: submission.id,
            rejectReason: values.reason,
            rejectFeedback: values.feedback.trim() || null,
            rejectedAt: new Date().toISOString(),
            rejectedByUserId: currentUser.uid,
            userId: submission.user.id,
          },
        });

        toast({
          title: "Changes requested successfully",
          status: "success",
        });

        onClose(true);

        if (path && node) {
          const eventProps = {
            pathId: path.id,
            pathTitle: path.title,
            pathAuthorId: path.authorId,
            pathNodeId: node.id,
            pathNodeTitle: node.title,
            nodeId: submission.pathNodeId,
            submissionId: submission.id,
            isFeedbackProvided: !!values?.feedback?.length,
            reason: values?.reason,
          };

          track(ANALYTICS_EVENTS.SUBMISSION_REJECTED, eventProps);
          trackForUser(
            submission.user.id,
            ANALYTICS_EVENTS.SUBMISSION_REJECTION_RECEIVED,
            eventProps
          );
        }

        createFeedActivity({
          apiKey: process.env.REACT_APP_STREAM_API_KEY,
          feedSlug: "notification",
          feedUserId: submission.user.id,
          activity: createNotification({
            type: "actor",
            actor: currentUser.id,
            verb: "reject",
            entity: "project",
            entityId: submission.id,
            targetPath: `/project/${submission.id}`,
            description: "has requested revisions on your project submission",
          }),
        });
      } catch (error: any) {
        captureException(error);

        toast({
          status: "error",
          title: "There was a problem performing this action",
        });
      }
    },
  });

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request Changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Reason</FormLabel>
            <Select
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
            >
              <option value={ReportReasonTypes.Invalid}>
                {ReportReasons.Invalid}
              </option>
              <option value={ReportReasonTypes.Offensive}>
                {ReportReasons.Offensive}
              </option>
              <option value={ReportReasonTypes.Plagiarised}>
                {ReportReasons.Plagiarised}
              </option>
              <option value={ReportReasonTypes.Other}>
                {ReportReasons.Other}
              </option>
            </Select>
            <FormHelperText>
              Let us know why you’re requesting changes on this submission
            </FormHelperText>
          </FormControl>
          {isSubmissionFeedbackEnabled && (
            <FormControl mt={2}>
              <Textarea
                onChange={formik.handleChange}
                name="feedback"
                value={formik.values.feedback}
                placeholder="Provide feedback..."
                width="100%"
                height="6rem"
                py={3}
                size="md"
                autoComplete="off"
                borderRadius="lg"
              />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={
              formik.values.reason === ReportReasonTypes.Other &&
              !formik.values.feedback.length
            }
            isLoading={formik.isSubmitting}
            size="lg"
            onClick={() => formik.handleSubmit()}
          >
            Request Changes
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
