import firebase from "firebase/compat";

export enum LearnerGroupStatus {
  Active = "Active",
  Archived = "Archived",
}

export enum LearnerGroupType {
  Family = "Family",
  Classroom = "Classroom",
}

export type LearnerGroup = {
  type: LearnerGroupType;
  status: LearnerGroupStatus;
  name: string;
  primaryOwner: string;
  allOwners: string[];
  kids: string[];
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
};

export const LearnerGroupColors = [
  "purple.500",
  "pink.500",
  "red.500",
  "orange.500",
  "yellow.400",
  "green.500",
  "teal.500",
  "blue.500",
];
