import { FC } from "react";
import { useRouteMatch } from "react-router";

import {
  Flex,
  HStack,
  IconBell,
  IconExplore,
  IconPath,
  IconUser,
} from "Shared";

import { BottomNavItem } from "./BottomNavItem";

import { useIsMyProfile, useSession } from "Services/session";
import { isKidUser } from "Types/User";
import { CounterBadge } from "Components/CounterBadge";
import { useNotificationsContext } from "Services/stream";

type Props = {};

export const BottomNav: FC<Props> = () => {
  const isExplore = !!useRouteMatch("/explore");
  const isNotifications = !!useRouteMatch("/notifications");
  const isMyPaths = !!useRouteMatch("/my-paths");
  const { isMyProfile, profileRoute } = useIsMyProfile();
  const { unseenCount } = useNotificationsContext();
  const { currentUser } = useSession();

  const exploreLink = "/explore/originals";

  return (
    <Flex
      align="center"
      justify="center"
      position="fixed"
      bottom={0}
      width="100%"
      height="3.5rem"
      zIndex={20}
      bg="white"
    >
      <HStack as="nav" spacing={0} height="100%" width="100%" maxWidth="30rem">
        <BottomNavItem
          aria-label="Browse"
          label="Browse"
          icon={<IconExplore />}
          to={exploreLink}
          isActive={isExplore}
        />
        {isKidUser(currentUser) && (
          <BottomNavItem
            aria-label="My Paths"
            label="My Paths"
            icon={<IconPath />}
            to="/my-paths"
            isActive={isMyPaths}
          />
        )}
        <BottomNavItem
          aria-label="Profile"
          label="Profile"
          icon={<IconUser />}
          to={profileRoute}
          isActive={isMyProfile}
        />
        <BottomNavItem
          aria-label="Notifications"
          label="Notifications"
          icon={
            <CounterBadge count={unseenCount} badgePosition="center">
              <IconBell />
            </CounterBadge>
          }
          to="/notifications"
          isActive={isNotifications}
        />
      </HStack>
    </Flex>
  );
};
