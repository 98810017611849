import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import { mode } from "@chakra-ui/theme-tools";
import type { PartsStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  return {
    th: {
      textTransform: "none",
      fontWeight: "normal",
      letterSpacing: "normal",
      color: mode("gray.600", "gray.400")(props),
      _first: {
        pl: 0,
      },
      _last: {
        pr: 0,
      },
    },
    td: {
      _first: {
        pl: 0,
      },
      _last: {
        pr: 0,
      },
    },
  };
};

export const Table = {
  baseStyle,
  sizes: {
    md: {
      th: {
        fontSize: "sm",
        px: 3,
      },
      td: {
        px: 3,
      },
    },
  },
  defaultProps: {
    variant: "unstyled",
  },
};
