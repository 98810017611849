import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Field,
  FieldInputProps,
  FieldProps,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from "formik";
import * as yup from "yup";

import {
  Avatar,
  Box,
  ButtonGroup,
  ButtonOutlined,
  ButtonSolid,
  Checkbox,
  Emoji,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  VStack,
  Input,
  Text,
  Textarea,
  useToast,
  Select,
  FormErrorMessage,
  useDisclosure,
  IconButton,
  IconEdit,
  IconPlus,
  Markdown,
  HStack,
  FileUploader,
  VisuallyHidden,
} from "Shared";

import { PATH_GRADE_LEVEL_V2, PATH_INTEREST_AREAS } from "Constants/paths";

import { useSession } from "Services/session";
import { useCountryNames } from "Services/hooks/useCountryNames";
import { captureException } from "Services/errors";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { AvatarPopover } from "Components/AvatarPopover";
import { PageContent } from "Components/PageContent";

import { InterestArea } from "@tract/common/dist/types/models/Path";
import { Accomplishment } from "@tract/common/dist/types/models/Accomplishment";
import { EditAccomplishmentModal } from "Components/EditAccomplishmentModal";
import {
  CreatorLevel,
  getDisplayName,
  isKidUser,
  isTeacherUser,
} from "Types/User";
import { useUserFileUpload } from "Services/useUserFileUpload";
import { PathSubjectsOptionGroup } from "Components/PathSubjectsOptionGroup";

const BIO_CHAR_LIMIT = 180;
const DISPLAY_NAME_CHAR_LIMIT = 30;

const schema = yup.object().shape({
  avatar: yup.string().url(),
  bio: yup
    .string()
    .test(
      "is-valid-length",
      `Bio can only be ${BIO_CHAR_LIMIT} characters`,
      (value) => (value ? value.length <= BIO_CHAR_LIMIT : true)
    ),
  interestAreas: yup.array().of(yup.string()),
  country: yup.string(),
  displayName: yup.string(),
  lowerGradeLevel: yup.string(),
  upperGradeLevel: yup.string(),
  subjects: yup.array().of(yup.string()),
});

type Props = {};

type FormProps = {
  avatar: string;
  username: string;
  bio: string;
  interestAreas: InterestArea[];
  country?: string;
  displayName?: string;
  lowerGradeLevel?: string;
  upperGradeLevel?: string;
  subjects: string[];
};

export const ProfileEdit: FC<Props> = () => {
  const { currentUser, update, profileSlug } = useSession();
  const [saving, setSaving] = useState(false);
  const [usernameTaken, setUsernameTaken] = useState(false);
  const [accomplishments, setAccomplishments] = useState<Accomplishment[]>(
    currentUser?.accomplishments || []
  );
  const isTeacher = isTeacherUser(currentUser);
  const isKid = isKidUser(currentUser);
  const [invalidFile, setInvalidFile] = useState(false);
  // Current accomplishment in edit mode
  const [currentAccompIndex, setCurrentAccompIndex] = useState(-1);
  const [accomplishmentsDirty, setaccomplishmentsDirty] = useState(false);
  const {
    isOpen: accomplishmentEditorIsOpen,
    onOpen: accomplishmentEditorOnOpen,
    onClose: accomplishmentEditorOnClose,
  } = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const countryNames = useCountryNames();
  const { track } = useAnalytics();

  function handleInterestAreaChange(
    form: FormikProps<FormProps>,
    field: FieldInputProps<InterestArea>
  ) {
    if (field.checked) {
      form.setFieldValue(
        "interestAreas",
        form.values.interestAreas.filter((value) => value !== field.value)
      );
    } else {
      form.setFieldValue(
        "interestAreas",
        form.values.interestAreas.concat([field.value])
      );
    }
  }

  function handleSubjectsChange(
    subject: string,
    subjects: string[],
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    if (subjects?.includes(subject)) {
      setFieldValue(
        "subjects",
        subjects?.filter((value) => value !== subject)
      );
    } else {
      setFieldValue(
        "subjects",
        !!subjects?.length ? subjects?.concat([subject]) : [subject]
      );
    }
  }

  async function handleSubmit(values: FormProps) {
    try {
      setSaving(true);
      setUsernameTaken(false);

      await update({
        avatar: values.avatar,
        bio: values.bio,
        interestAreas: values.interestAreas,
        country: values.country,
        accomplishments: accomplishments,
        displayName: values.displayName,
        lowerGradeLevel: values.lowerGradeLevel,
        upperGradeLevel: values.upperGradeLevel,
        subjects: values.subjects,
      });

      toast({
        status: "success",
        title: "Profile Saved",
      });

      history.push(`/${profileSlug}/about`);

      track(ANALYTICS_EVENTS.PROFILE_EDITED, {
        isAuthorProfile: false,
        bio: values.bio,
      });
    } catch (err: any) {
      captureException(err);
      toast({
        title: "Oops!",
        description: "There was an error saving your profile",
        status: "error",
      });
    } finally {
      setSaving(false);
    }
  }

  const onModalClose = () => {
    setCurrentAccompIndex(-1);
    accomplishmentEditorOnClose();
  };

  const handleSaveAccomplishment = (accomplishment: Accomplishment) => {
    const newAccomplishments = [...accomplishments];
    accomplishment.title = accomplishment.title.trim();
    accomplishment.description = (accomplishment?.description || "").trim();
    if (currentAccompIndex > -1) {
      // Modify existing
      newAccomplishments[currentAccompIndex] = accomplishment;
    } else {
      // Add new
      newAccomplishments.push(accomplishment);
    }
    setAccomplishments(newAccomplishments);
    setaccomplishmentsDirty(true);
    onModalClose();
  };

  const handleDeleteAccomplishment = () => {
    if (currentAccompIndex > -1) {
      const newAccomplishments = [...accomplishments];
      newAccomplishments.splice(currentAccompIndex, 1);
      setAccomplishments(newAccomplishments);
    }
    setaccomplishmentsDirty(true);
    onModalClose();
  };

  const setAccomplishmentInEditMode = (index: number) => {
    setCurrentAccompIndex(index);
    accomplishmentEditorOnOpen();
  };

  return (
    <PageContent pt={{ base: 6, lg: 10 }} pb={32}>
      <Box maxWidth="45rem" mx="auto">
        <Box mb={10}>
          <Text fontSize="4xl" fontWeight="bold">
            Edit Profile
          </Text>
          <Text fontSize="md" fontWeight="regular" color="gray.600">
            Tract members will get to know you with the info below
          </Text>
        </Box>

        <Formik
          validationSchema={schema}
          validateOnChange={false}
          initialValues={{
            avatar: currentUser.avatar || "",
            username: currentUser.username || "",
            bio: currentUser.bio || "",
            interestAreas: currentUser.interestAreas || [],
            country: currentUser.country || "",
            displayName: currentUser.teacher?.displayName || "",
            lowerGradeLevel: currentUser.teacher?.lowerGradeLevel || "",
            upperGradeLevel: currentUser.teacher?.upperGradeLevel || "",
            subjects: currentUser.teacher?.subjects || [],
          }}
          onSubmit={handleSubmit}
        >
          {({ dirty, values, setFieldValue }) => (
            <>
              <Form>
                <VStack align="stretch" spacing={6}>
                  <Field name="avatar">
                    {({ field, form }: FieldProps) => (
                      <Flex align="center">
                        <Avatar
                          size="xl"
                          src={field.value}
                          name={getDisplayName(currentUser) || ""}
                          mr={4}
                        />
                        <FormControl isInvalid={invalidFile}>
                          <FormLabel hidden>Avatar</FormLabel>
                          {isTeacher ? (
                            <CustomAvatarFileUploader
                              field={field}
                              userId={currentUser.uid}
                              setInvalidFile={setInvalidFile}
                            />
                          ) : (
                            <AvatarPopover
                              initialValue={field.value}
                              onChange={(url: string) => {
                                form.setFieldValue("avatar", url);
                              }}
                            />
                          )}
                          <FormErrorMessage>
                            {form.errors.avatar}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    )}
                  </Field>
                  <Field name="username">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        id="username"
                        isRequired
                        display="none"
                        isInvalid={
                          !!(form.errors.username && form.touched.username) ||
                          usernameTaken
                        }
                      >
                        <FormLabel>Username</FormLabel>
                        <Input
                          {...field}
                          variant="filled"
                          type="text"
                          size="lg"
                          isDisabled
                        />
                        <FormErrorMessage>
                          {usernameTaken
                            ? "username unavailable"
                            : form.errors.username}
                        </FormErrorMessage>
                        <FormHelperText>
                          https://{window.location.host}/@
                          {form.values.username}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  {isTeacher && (
                    <Field name="displayName">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          id="displayName"
                          isInvalid={
                            !!(
                              form.errors.displayName &&
                              form.touched.displayName
                            )
                          }
                        >
                          <FormLabel>What do your students call you?</FormLabel>
                          <Input
                            {...field}
                            variant="filled"
                            display="block"
                            maxLength={DISPLAY_NAME_CHAR_LIMIT}
                            disabled={saving}
                            placeholder="Preferred name"
                          />
                          <FormErrorMessage>
                            {form.errors.displayName}
                          </FormErrorMessage>
                          <FormHelperText>
                            Enter a name that will be shown to your students
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  <Field name="bio">
                    {({ field, form }: FieldProps) => (
                      <FormControl
                        id="bio"
                        isInvalid={!!(form.errors.bio && form.touched.bio)}
                      >
                        <FormLabel>Bio</FormLabel>
                        <Textarea
                          {...field}
                          variant="filled"
                          display="block"
                          maxLength={BIO_CHAR_LIMIT}
                          height="8rem"
                          disabled={saving}
                        />
                        <FormErrorMessage>{form.errors.bio}</FormErrorMessage>
                        <FormHelperText>
                          <Flex justify="space-between">
                            <Box as="span" hidden={!isKid} mr={4}>
                              Don't reveal personal information like your real
                              name or where you live
                            </Box>
                            <Box as="span">
                              {field.value.length}/{BIO_CHAR_LIMIT}
                            </Box>
                          </Flex>
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="country">
                    {({ field, form }: FieldProps) => (
                      <FormControl isInvalid={!!form.errors.country}>
                        <FormLabel>Country</FormLabel>
                        <Select
                          {...field}
                          variant="filled"
                          size="lg"
                          placeholder="Select a country..."
                          disabled={saving}
                        >
                          {countryNames.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {form.errors.country}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {currentUser.creatorLevel === CreatorLevel.Partner && (
                    <VStack align="stretch" spacing={6}>
                      <Text
                        flexGrow={1}
                        fontSize="2xl"
                        fontWeight="bold"
                        mt={4}
                      >
                        Accomplishments
                      </Text>
                      {accomplishments?.length ? (
                        accomplishments.map(({ title, description }, i) => (
                          <Flex flexDirection="row" key={i}>
                            <Box flexGrow={1} wordBreak="break-word" pr={4}>
                              <Markdown source={title} fontWeight="bold" />
                              {description ? (
                                <Markdown
                                  color="gray.900"
                                  source={description}
                                />
                              ) : (
                                <Text color="gray.300" mb={4}>
                                  No details yet.
                                </Text>
                              )}
                            </Box>
                            <Box>
                              <IconButton
                                aria-label="Edit Accomplishment"
                                variant="outline"
                                icon={<IconEdit />}
                                isRound={true}
                                disabled={saving}
                                onClick={() => setAccomplishmentInEditMode(i)}
                              />
                            </Box>
                          </Flex>
                        ))
                      ) : (
                        <Text mt={4} color="gray.300">
                          No accomplishments
                        </Text>
                      )}

                      <Box>
                        <ButtonOutlined
                          onClick={accomplishmentEditorOnOpen}
                          disabled={saving}
                          leftIcon={<IconPlus />}
                        >
                          Accomplishment
                        </ButtonOutlined>
                      </Box>
                    </VStack>
                  )}
                  {isKid && (
                    <Box>
                      <FormLabel mb={3}>What are you interested in?</FormLabel>
                      <Grid templateColumns="repeat(2, minmax(0, 1fr))" gap={3}>
                        {PATH_INTEREST_AREAS.map((interestArea) => (
                          <Field
                            key={interestArea.value}
                            name="interestAreas"
                            type="checkbox"
                            value={interestArea.value}
                          >
                            {({ field, form }: FieldProps) => (
                              <Checkbox
                                size="lg"
                                value={field.value}
                                isChecked={field.checked}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleInterestAreaChange(form, field);
                                }}
                              >
                                <Emoji emoji={interestArea.emoji} mr={1} />
                                {interestArea.label}
                              </Checkbox>
                            )}
                          </Field>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {isTeacher && (
                    <Box>
                      <FormLabel>What grade levels do you teach?</FormLabel>
                      <HStack spacing={3}>
                        <Field name="lowerGradeLevel">
                          {({ field, meta: { error } }: FieldProps) => (
                            <FormControl id={field.name} isInvalid={!!error}>
                              <VisuallyHidden>
                                <FormLabel>Lowest Grade Level</FormLabel>
                              </VisuallyHidden>
                              <Select
                                variant="filled"
                                onChange={field.onChange}
                                name={field.name}
                                value={field.value}
                                placeholder="Lowest..."
                              >
                                {PATH_GRADE_LEVEL_V2.map((gradeLevel, i) => (
                                  <option
                                    key={gradeLevel.value}
                                    value={gradeLevel.value}
                                    disabled={
                                      !!values.upperGradeLevel &&
                                      i >
                                        PATH_GRADE_LEVEL_V2.findIndex(
                                          (grade) =>
                                            grade.value ===
                                            values.upperGradeLevel
                                        )
                                    }
                                  >
                                    {gradeLevel.label}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>{error}</FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="upperGradeLevel">
                          {({ field, meta: { error } }: FieldProps) => (
                            <FormControl id={field.name} isInvalid={!!error}>
                              <VisuallyHidden>
                                <FormLabel>Highest Grade Level</FormLabel>
                              </VisuallyHidden>
                              <Select
                                variant="filled"
                                onChange={field.onChange}
                                name={field.name}
                                value={field.value}
                                placeholder="Highest..."
                              >
                                {PATH_GRADE_LEVEL_V2.map((gradeLevel, i) => (
                                  <option
                                    key={gradeLevel.value}
                                    value={gradeLevel.value}
                                    disabled={
                                      !!values.lowerGradeLevel &&
                                      i <
                                        PATH_GRADE_LEVEL_V2.findIndex(
                                          (grade) =>
                                            grade.value ===
                                            values.lowerGradeLevel
                                        )
                                    }
                                  >
                                    {gradeLevel.label}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>{error}</FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Box>
                  )}
                  {isTeacher && (
                    <Box>
                      <FormLabel mb={3}>What subjects do you teach?</FormLabel>
                      <PathSubjectsOptionGroup
                        onChange={handleSubjectsChange}
                        subjects={values.subjects}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  )}
                </VStack>
                <Flex
                  align="center"
                  justify="center"
                  bg="white"
                  px={10}
                  py={4}
                  h="calc(5rem + 1px)"
                  position="fixed"
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex={10}
                  borderTop="1px solid"
                  borderTopColor="gray.200"
                >
                  <ButtonGroup>
                    <ButtonSolid
                      size="lg"
                      type="submit"
                      isLoading={saving}
                      disabled={!dirty && !accomplishmentsDirty}
                    >
                      Save Profile
                    </ButtonSolid>
                    <ButtonOutlined size="lg" as={Link} to={`/${profileSlug}`}>
                      Cancel
                    </ButtonOutlined>
                  </ButtonGroup>
                </Flex>
              </Form>
            </>
          )}
        </Formik>
      </Box>
      <EditAccomplishmentModal
        // If null, consider new accomplishment
        accomplishment={accomplishments[currentAccompIndex] || null}
        isOpen={accomplishmentEditorIsOpen}
        onClose={onModalClose}
        onSave={handleSaveAccomplishment}
        onDelete={handleDeleteAccomplishment}
      />
    </PageContent>
  );
};

type CustomAvatarFileUploaderProps = {
  field: FieldInputProps<string>;
  userId: string;
  setInvalidFile: Dispatch<SetStateAction<boolean>>;
};

export const CustomAvatarFileUploader: React.FC<
  CustomAvatarFileUploaderProps
> = ({ field, userId, setInvalidFile }) => {
  const formik = useFormikContext();
  const avatarFileUploader = useRef(null);
  const [avatarFileUrl, setAvatarFileUrl] = useState("");

  const {
    result,
    handleProgress,
    handleUploadError,
    handleUploadStart,
    handleUploadSuccess,
    isUploading,
    storageRef,
  } = useUserFileUpload({ path: `ugc/user-avatars/${userId}` });

  useEffect(() => {
    if (result && result.fileUrl !== avatarFileUrl) {
      formik.setFieldValue("avatar", result.fileUrl);
      setAvatarFileUrl(result.fileUrl);
    }
  }, [result, formik, avatarFileUrl]);

  const onAvatarFileSelect = (event: Event) => {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length === 1) {
      const file = fileInput.files[0];
      if (file.size <= 1024 * 50 && file.type.startsWith("image/")) {
        // @ts-ignore
        avatarFileUploader.current.startUpload(file);
        setInvalidFile(false);
      } else {
        setInvalidFile(true);
      }
    }
  };

  return (
    <>
      <ButtonOutlined
        as="label"
        size="lg"
        cursor="pointer"
        htmlFor={field.name}
        alignSelf="center"
        disabled={isUploading}
        isLoading={isUploading}
      >
        Change
        <FileUploader
          accept="image/jpeg,image/jpg,image/png"
          ref={avatarFileUploader}
          onChange={onAvatarFileSelect}
          hidden
          id={field.name}
          name={field.name}
          storageRef={storageRef}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={(filename: string) => {
            handleUploadSuccess(filename);
          }}
          onProgress={handleProgress}
        />
      </ButtonOutlined>
      <FormErrorMessage>Please select a JPG file under 50KB</FormErrorMessage>
    </>
  );
};
