import { createIcon } from "@chakra-ui/react";

export const IconTextDefault = createIcon({
  displayName: "IconTextDefault",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 7C6 6.44772 6.44772 6 7 6H12H17C17.5523 6 18 6.44772 18 7V9C18 9.55228 17.5523 10 17 10C16.4477 10 16 9.55228 16 9V8H13V16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H12H9C8.44772 18 8 17.5523 8 17C8 16.4477 8.44772 16 9 16H11V8H8V9C8 9.55228 7.55228 10 7 10C6.44772 10 6 9.55228 6 9V7Z"
      fill="currentColor"
    />,
    <rect
      x="2"
      y="2"
      width="20"
      height="20"
      rx="3"
      stroke="currentColor"
      stroke-width="2"
    />,
  ],
});
