import { CombinedError, gql, useQuery } from "urql";
import { PropsWithChildren, useEffect } from "react";

import { useToast } from "Shared";

import { createContext } from "Utils";

import { PATH_COLLECTION_FRAGMENT } from "graphql/path-collection";

import {
  ClassSavedPathsQuery,
  ClassSavedPathsQueryVariables,
  PathCollectionFragment,
} from "@tract/common/dist/graphql";

interface IContext {
  error?: CombinedError;
  loading: boolean;
  pathCollection?: PathCollectionFragment;
}

export const [Provider, , Context] = createContext<IContext>({ strict: true });

type Props = {
  classroomId: string;
};

export const SavedPathProvider: React.FC<PropsWithChildren<Props>> = ({
  classroomId,
  children,
}) => {
  const toast = useToast();

  const [{ fetching, error, data }] = useQuery<
    ClassSavedPathsQuery,
    ClassSavedPathsQueryVariables
  >({
    query: CLASS_SAVED_PATHS,
    variables: { learnerGroupId: classroomId },
  });

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load paths.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  });

  return (
    <Provider
      value={{
        error,
        loading: fetching,
        pathCollection:
          data?.learner_group_path_collection?.[0]?.pathCollection,
      }}
    >
      {children}
    </Provider>
  );
};

export const CLASS_SAVED_PATHS = gql`
  ${PATH_COLLECTION_FRAGMENT}

  query ClassSavedPaths($learnerGroupId: uuid!) {
    learner_group_path_collection(
      where: { learnerGroupId: { _eq: $learnerGroupId } }
    ) {
      learnerGroupId
      pathCollectionId
      pathCollection {
        ...PathCollection
      }
    }
  }
`;
