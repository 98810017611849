import React, { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import useResizeObserver from "@react-hook/resize-observer";
import ysFixWebmDuration from "fix-webm-duration";
import DetectRTC from "detectrtc";

import {
  Box,
  Center,
  Circle,
  DarkMode,
  Flex,
  Grid,
  Image,
  Spinner,
  Text,
  VisuallyHidden,
  useBoolean,
  IconButton,
  IconX,
  Slide,
} from "Shared";

import { captureException } from "Services/errors";

import { useInterval } from "@tract/common/dist/hooks";

import { createContext } from "Utils";
import { useKeyPress } from "Utils/hooks/useKeyPress";

import { DrawerPanel, findCanvasObjectByName } from "./shared";
import { Drawer } from "./Drawer";
import { Controls } from "./Controls";
import { Timer } from "./Timer";
import { Permissions } from "./Permissions";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

const CANVAS_FPS = 30;
const MAX_VIDEO_LENGTH_MIN = 4;
const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;
const COUNTDOWN_END = -1;
const COUNTDOWN_STOP = -2;
const DESKTOP_STREAM_FRAMERATE = 5;

enum RecordType {
  Video = "video",
  Photo = "photo",
}

type Preview = {
  type: RecordType;
  mediaURL: string;
};

type Backdrop = {
  type: "desktop" | "image";
  url?: string;
};

export type Result = {
  type: RecordType;
  blob: Blob;
  metadata?: {
    fx?: {
      hasText?: boolean;
      backdrop?: Backdrop;
    };
  };
};

interface ContextProps {
  VIDEO_WIDTH: number;
  VIDEO_HEIGHT: number;
  activeDrawerPanel: DrawerPanel;
  audioDevice?: MediaDeviceInfo;
  audioDevices: MediaDeviceInfo[];
  backdrop?: Backdrop;
  desktopStream: MediaStream | null;
  fabricCanvas?: fabric.Canvas;
  isCountdownActive: boolean;
  isCountdownEnabled: boolean;
  isRecording: boolean;
  saving: boolean;
  preview?: Preview;
  videoDevice?: MediaDeviceInfo;
  videoDevices: MediaDeviceInfo[];
  startDesktopStream: () => void;
  stopDesktopStream: () => void;
  setVideoDevice: (device: MediaDeviceInfo) => void;
  setAudioDevice: (device: MediaDeviceInfo) => void;
  setActiveEffectsPanel: (panel: DrawerPanel) => void;
  setBackdrop: (backdrop?: Backdrop) => void;
  toggleCountdown: () => void;
}

export const [Provider, useVideoEditorContext] = createContext<ContextProps>({
  name: "VideoEditor",
  strict: true,
});

type Props = {
  isOpen: boolean;
  enablePhotoUpload?: boolean;
  onClose: () => void;
  onSave: (result: Result) => void;
};

export const VideoEditor: React.FC<Props> = React.memo(
  ({
    isOpen = false,
    enablePhotoUpload = true,
    onClose = () => {},
    onSave,
  }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const selfieCanvasRef = useRef<HTMLCanvasElement>(null);
    const videoContainerRef = useRef<HTMLDivElement>(null);
    const chunksRef = useRef<Blob[]>([]);
    const cameraRenderRef = useRef<any>(null);
    const webcamStreamRef = useRef<MediaStream | null>(null);
    const desktopStreamRef = useRef<MediaStream | null>(null);
    const stateRef = useRef<{ pauseDrawing: boolean; hasBackdrop: boolean }>({
      pauseDrawing: false,
      hasBackdrop: false,
    });

    const [isReady, setIsReady] = useState(false);
    const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>();
    const [selfie, setSelfie] = useState<SelfieSegmentation>();
    const [isDrawerActive, setDrawer] = useBoolean(false);
    const [backdrop, setBackdrop] = useState<Backdrop>();
    const [countdown, setRecordCountdown] = useState(COUNTDOWN_STOP);
    const [isCountdownEnabled, setCountdownEnabled] = useBoolean(true);
    const [checkedPermissions, setCheckedPermissions] = useState(false);
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [videoDevice, setVideoDevice] = useState<MediaDeviceInfo>();
    const [audioDevice, setAudioDevice] = useState<MediaDeviceInfo>();
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
    const [recordType, setRecordType] = useState<RecordType>();
    const [preview, setPreview] = useState<Preview>();
    const [result, setResult] = useState<Result>();
    const [saving, setSaving] = useState(false);
    const [recordingStartTime, setRecordingStartTime] = useState(new Date(0));
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [activeDrawerPanel, setActiveDrawerPanel] = useState(
      DrawerPanel.Effects
    );

    const { track } = useAnalytics();
    const isPermitted =
      DetectRTC.isWebsiteHasMicrophonePermissions &&
      DetectRTC.isWebsiteHasMicrophonePermissions;
    const isRecording = recordingStartTime.getTime() !== new Date(0).getTime();
    const isCountdownActive = countdown !== -2;

    useEffect(() => {
      document.body.style.overflow = isOpen ? "hidden" : "unset";

      return () => {
        document.body.style.overflow = "unset";
      };
    }, [isOpen]);

    // Check permissions
    useEffect(() => {
      if (checkedPermissions) return;
      let _stream: MediaStream;

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: { facingMode: "user" } })
        .then((stream) => {
          _stream = stream;
          stream.getAudioTracks().forEach((t) => t.stop());
          stream.getVideoTracks().forEach((t) => t.stop());

          return new Promise<void>((resolve) => {
            DetectRTC.load(resolve);
          });
        })
        .catch(captureException)
        .finally(() => {
          setCheckedPermissions(true);
        });

      return () => {
        if (_stream) {
          _stream.getAudioTracks().forEach((t) => t.stop());
          _stream.getVideoTracks().forEach((t) => t.stop());
        }
      };
    }, [checkedPermissions]);

    // Setup devices
    useEffect(() => {
      if (
        loadingDevices ||
        videoDevices.length ||
        audioDevices.length ||
        !isPermitted
      )
        return;

      async function setup() {
        setLoadingDevices(true);
        const devices = await navigator.mediaDevices.enumerateDevices();

        const _audioDevices: MediaDeviceInfo[] = [];
        const _videoDevices: MediaDeviceInfo[] = [];

        devices.forEach((device) => {
          switch (device.kind) {
            case "audioinput":
              _audioDevices.push(device);
              break;
            case "videoinput":
              _videoDevices.push(device);
              break;
          }
        });

        if (_audioDevices.length) {
          setAudioDevices(_audioDevices);
          setAudioDevice(_audioDevices[0]);
        }

        if (_videoDevices.length) {
          setVideoDevices(_videoDevices);
          setVideoDevice(_videoDevices[0]);
        }

        setLoadingDevices(false);
      }

      setup();
    }, [isPermitted, loadingDevices, audioDevices.length, videoDevices.length]);

    useEffect(() => {
      const canvas = new fabric.Canvas(canvasRef.current, {
        selection: false,
        defaultCursor: "default",
        hoverCursor: "default",
      });

      setFabricCanvas(canvas);
    }, []);

    // Setup model to cut out background from camera
    useEffect(() => {
      document.querySelectorAll("script").forEach((script) => {
        if (script.src.includes("selfie_segmentation")) {
          script.remove();
        }
      });

      const _selfie = new SelfieSegmentation({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
        },
      });

      _selfie.setOptions({
        selfieMode: true,
        modelSelection: 1,
      });

      setSelfie(_selfie);

      return () => {
        _selfie.close();
      };
    }, []);

    useEffect(() => {
      if (!selfie || !isPermitted || !checkedPermissions) return;
      if (!audioDevice || !videoDevice) return;
      let _stream: MediaStream;

      const cleanup = () => {
        if (webcamStreamRef.current) {
          webcamStreamRef.current.getVideoTracks().forEach((t) => t.stop());
          webcamStreamRef.current.getAudioTracks().forEach((t) => t.stop());
          webcamStreamRef.current = null;
        }

        if (cameraRenderRef.current) {
          cancelAnimationFrame(cameraRenderRef.current);
        }
      };

      const renderCamera = () => {
        if (stateRef.current.pauseDrawing) {
          cameraRenderRef.current = requestAnimationFrame(renderCamera);
          return;
        }

        selfie.send({ image: videoEl }).then(() => {
          cameraRenderRef.current = requestAnimationFrame(renderCamera);
        });
      };

      cleanup();

      const videoEl = document.createElement("video");

      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: audioDevice.deviceId,
          },
          video: {
            deviceId: videoDevice.deviceId,
            frameRate: { min: 15, ideal: 30, max: 30 },
            aspectRatio: { ideal: 16 / 9 },
            height: VIDEO_HEIGHT,
            width: VIDEO_WIDTH,
          },
        })
        .then((stream) => {
          _stream = stream;
          videoEl.muted = true;
          videoEl.srcObject = stream;
          webcamStreamRef.current = stream;
          return videoEl.play();
        })
        .then(() => {
          requestAnimationFrame(renderCamera);
        });

      return () => {
        if (_stream) {
          _stream.getVideoTracks().forEach((t) => t.stop());
          _stream.getAudioTracks().forEach((t) => t.stop());
        }

        cleanup();
      };
    }, [selfie, audioDevice, videoDevice, isPermitted, checkedPermissions]);

    useEffect(() => {
      if (!fabricCanvas) return;

      let stopRender = false;
      let now;
      let then = Date.now();
      let interval = 1000 / CANVAS_FPS;
      let delta;

      fabric.util.requestAnimFrame(async function render() {
        if (stopRender) return;

        now = Date.now();
        delta = now - then;

        if (delta > interval) {
          then = now - (delta % interval);
          fabricCanvas.requestRenderAll();
        }

        fabric.util.requestAnimFrame(render);
      });

      return () => {
        stopRender = true;
      };
    }, [fabricCanvas]);

    useEffect(() => {
      if (!fabricCanvas || !selfieCanvasRef.current || !selfie) return;

      const selfieCanvas = selfieCanvasRef.current;
      const selfieFabricImg = new fabric.Image(selfieCanvas, {
        name: "webcam",
        top: 0,
        left: 0,
        objectCaching: false,
        selectable: false,
      });

      fabricCanvas.add(selfieFabricImg);

      selfie.onResults(async (results) => {
        const ctx = selfieCanvas.getContext("2d");

        if (stateRef.current.pauseDrawing) {
          return;
        }

        if (ctx) {
          ctx.save();
          ctx.clearRect(0, 0, VIDEO_WIDTH, VIDEO_HEIGHT);
          if (stateRef.current.hasBackdrop) {
            ctx.drawImage(
              results.segmentationMask,
              0,
              0,
              VIDEO_WIDTH,
              VIDEO_HEIGHT
            );
            ctx.globalCompositeOperation = "source-atop";
          }
          ctx.drawImage(results.image, 0, 0, VIDEO_WIDTH, VIDEO_HEIGHT);
          ctx.restore();

          setIsReady(true);
        }
      });
    }, [fabricCanvas, selfie]);

    useEffect(() => {
      if (!fabricCanvas) return;

      fabricCanvas.on("mouse:down", (e) => {
        switch (e.target?.constructor) {
          case fabric.IText:
            setActiveDrawerPanel(DrawerPanel.TextOptions);
            setDrawer.on();
            break;
          case fabric.Image:
            if (e.target.name === "webcam") {
              setActiveDrawerPanel(DrawerPanel.Effects);
            }
            break;
          default:
            setActiveDrawerPanel(DrawerPanel.Effects);
        }
      });

      return () => {
        fabricCanvas.off("mouse:down");
      };
    }, [fabricCanvas, setDrawer]);

    useEffect(() => {
      stateRef.current = { ...stateRef.current, hasBackdrop: !!backdrop };
    }, [backdrop]);

    useEffect(() => {
      stateRef.current = { ...stateRef.current, pauseDrawing: !!preview };
    }, [preview]);

    const backspacePress = useKeyPress("Backspace");
    const escapePress = useKeyPress("Escape");

    useEffect(() => {
      if (!backspacePress || !fabricCanvas) return;
      const activeObj = fabricCanvas.getActiveObject();
      if (!activeObj) return;
      if (activeObj instanceof fabric.IText && activeObj.isEditing) {
        return;
      }
      fabricCanvas.remove(activeObj);
      setActiveDrawerPanel(DrawerPanel.Effects);
    }, [backspacePress, fabricCanvas]);

    useEffect(() => {
      if (!escapePress) return;

      onClose();
    }, [escapePress, onClose]);

    const recalulateCanvas = () => {
      const canvasContainer = document.getElementsByClassName(
        "canvas-container"
      )[0] as HTMLDivElement;

      if (!canvasRef.current || !videoContainerRef.current) return;

      const width = videoContainerRef.current.clientWidth - 80;
      const height = videoContainerRef.current.clientHeight - 80;

      const widthScale = width / VIDEO_WIDTH;
      const heightScale = height / VIDEO_HEIGHT;
      const scale = Math.min(widthScale, heightScale);

      if (canvasContainer) {
        const cc = canvasContainer;
        cc.style.width = `${VIDEO_WIDTH}px`;
        cc.style.height = `${VIDEO_HEIGHT}px`;
        // @ts-ignore
        cc.style.aspectRatio = "16 / 9";
        cc.style.transform = `scale(${scale < 1 ? scale : 1})`;
      }
    };

    useEffect(() => {
      setTimeout(recalulateCanvas, 100);
    }, [isDrawerActive]);
    useResizeObserver(videoContainerRef, recalulateCanvas);

    const stopDesktopStream = useCallback(() => {
      if (desktopStreamRef.current) {
        desktopStreamRef.current.getVideoTracks().forEach((t) => t.stop());
        desktopStreamRef.current.removeEventListener("inactive", () => {});
      }
    }, []);

    useEffect(() => {
      return () => {
        stopDesktopStream();
      };
    }, [stopDesktopStream]);

    const startDesktopStream = async () => {
      if (!fabricCanvas) return;

      try {
        // @ts-ignore Need TypeScript update for getDisplayMedia
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: {
            frameRate: DESKTOP_STREAM_FRAMERATE,
            width: { max: VIDEO_WIDTH },
            height: { max: VIDEO_HEIGHT },
          },
        });

        stopDesktopStream();
        desktopStreamRef.current = stream;

        const backdrop = findCanvasObjectByName(fabricCanvas, "backdrop");
        if (backdrop) fabricCanvas.remove(backdrop);

        stream.addEventListener("inactive", () => {
          setBackdrop(undefined);
          desktopStreamRef.current = null;
          const backdrop = findCanvasObjectByName(fabricCanvas, "backdrop");
          if (backdrop) fabricCanvas.remove(backdrop);
        });

        const track = stream.getVideoTracks()[0];
        const trackSettings = track.getSettings();

        const videoEl = document.createElement("video");
        videoEl.width = trackSettings.width || VIDEO_WIDTH;
        videoEl.height = trackSettings.height || VIDEO_HEIGHT;
        videoEl.srcObject = stream;
        videoEl.play();

        const video = new fabric.Image(videoEl, {
          id: "desktop",
          name: "backdrop",
          objectCaching: false,
          selectable: false,
        });

        fabricCanvas.add(video);
        video.center();
        video.sendToBack();
        setBackdrop({ type: "desktop" });
      } catch (err) {
        // noop
      }
    };

    useInterval(
      () => {
        setRecordCountdown(countdown - 1);
      },
      isCountdownActive ? 1000 : null
    );

    const handleRecord = async (type: RecordType) => {
      setRecordType(type);
      setRecordCountdown(isCountdownEnabled ? 3 : COUNTDOWN_END);
      setDrawer.off();
    };

    useEffect(() => {
      if (recordType !== RecordType.Video || countdown !== COUNTDOWN_END)
        return;

      // @ts-ignore TODO: need to upgrade TypeScript
      const stream = canvasRef.current?.captureStream(CANVAS_FPS);
      if (!stream || !webcamStreamRef.current) return;
      stream.addTrack(webcamStreamRef.current.getAudioTracks()[0]);
      const recorder = new MediaRecorder(stream);

      chunksRef.current = [];
      let startTime = new Date();

      recorder.ondataavailable = ({ data }) => {
        if (data && data.size > 0) {
          chunksRef.current.push(data);
        }
      };

      recorder.onstart = () => {
        startTime = new Date();
        setMediaRecorder(recorder);
        setRecordingStartTime(startTime);
      };

      recorder.onstop = () => {
        setRecordingStartTime(new Date(0));

        const chunks = chunksRef.current;
        const duration = Date.now() - startTime.getTime();
        const [chunk] = chunks;
        const blob = new Blob(chunks, { type: chunk.type });

        ysFixWebmDuration(blob, duration).then((blob) => {
          recorder.removeEventListener("dataavailable", () => {});
          setMediaRecorder(undefined);
          setPreview({
            type: recordType,
            mediaURL: URL.createObjectURL(blob),
          });
        });
      };

      recorder.start();
    }, [countdown, recordType]);

    useEffect(() => {
      if (
        recordType !== RecordType.Photo ||
        countdown !== COUNTDOWN_END ||
        !canvasRef.current
      )
        return;

      setPreview({
        type: recordType,
        mediaURL: canvasRef.current.toDataURL("image/png"),
      });
    }, [recordType, countdown]);

    useEffect(() => {
      if (!preview) return;

      const save = async () => {
        setSaving(true);
        try {
          const result = await fetch(preview.mediaURL);
          const blob = await result.blob();
          setResult({
            type: preview.type,
            blob,
          });
        } catch (err: any) {
          captureException(err);
        } finally {
          setSaving(false);
        }
      };

      save();
    }, [preview]);

    const handleRecordStop = async () => {
      if (isCountdownActive) {
        setRecordCountdown(COUNTDOWN_STOP);
      }

      mediaRecorder?.requestData();
      mediaRecorder?.stop();
    };

    const handleKeepRecording = () => {
      if (!result) return;

      let hasText = false;
      const objects = fabricCanvas?.getObjects();

      objects?.forEach((o) => {
        if (o instanceof fabric.IText) {
          hasText = true;
        }
      });

      track(ANALYTICS_EVENTS.VIDEO_EDITOR_RESULT_SAVED, {
        type: result.type,
        hasBackdrop: !!result.metadata?.fx?.backdrop,
        hasText: !!result.metadata?.fx?.hasText,
      });

      onSave({
        ...result,
        metadata: {
          fx: {
            backdrop,
            hasText,
          },
        },
      });
    };

    const handleOpenDrawer = (panel: DrawerPanel) => {
      if (isDrawerActive && activeDrawerPanel === panel) {
        setDrawer.off();
        return;
      }

      setDrawer.on();
      setActiveDrawerPanel(panel);
    };

    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        w="full"
        h="full"
        zIndex={2147483638}
      >
        <Provider
          value={{
            VIDEO_WIDTH,
            VIDEO_HEIGHT,
            activeDrawerPanel,
            audioDevice,
            audioDevices,
            backdrop,
            desktopStream: desktopStreamRef.current,
            fabricCanvas,
            isCountdownActive,
            isCountdownEnabled,
            isRecording,
            saving,
            preview,
            videoDevice,
            videoDevices,
            startDesktopStream,
            stopDesktopStream,
            setActiveEffectsPanel: setActiveDrawerPanel,
            setAudioDevice,
            setBackdrop,
            setVideoDevice,
            toggleCountdown: setCountdownEnabled.toggle,
          }}
        >
          <Flex
            bgColor="brandFull.900"
            height="100vh"
            width="100%"
            flexDir="column"
          >
            <Flex width="100%" height="100%" flex={1} overflow="hidden">
              <Flex flex={1} height="100%" flexDir="column" overflow="hidden">
                {/* Header */}
                <Center
                  h="4.5rem"
                  minH="4.5rem"
                  p={4}
                  flex={0}
                  position="relative"
                >
                  <DarkMode>
                    <IconButton
                      onClick={onClose}
                      icon={<IconX />}
                      size="md"
                      aria-label="Close"
                      borderRadius="full"
                      position="absolute"
                      top={4}
                      left={4}
                    />
                  </DarkMode>
                  <Text fontWeight="bold" fontSize="xl" color="white">
                    Create
                  </Text>
                </Center>

                <Grid
                  ref={videoContainerRef}
                  gridTemplateColumns="100%"
                  gridTemplateRows="100%"
                  w="full"
                  h={0}
                  flex={1}
                  position="relative"
                >
                  {isPermitted && !isReady && (
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >
                      <Spinner
                        size="xl"
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="whiteAlpha.500"
                      />
                    </Box>
                  )}

                  {isCountdownEnabled && countdown >= 0 && (
                    <Box
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      zIndex={2}
                    >
                      <Circle size="128px" bg="rgba(0,0,0,0.8)">
                        <Text fontSize="5xl" fontWeight="bold" color="white">
                          {countdown === 0 ? "Go!" : countdown}
                        </Text>
                      </Circle>
                    </Box>
                  )}

                  <Box
                    position="absolute"
                    bottom={20}
                    left="50%"
                    transform="translate(-50%)"
                    zIndex={2}
                  >
                    <Timer
                      isActive={isRecording}
                      minutes={MAX_VIDEO_LENGTH_MIN}
                      startTime={recordingStartTime}
                      onExpired={handleRecordStop}
                    />
                  </Box>

                  <Box
                    gridRowStart={1}
                    gridColumnStart={1}
                    w="full"
                    h="full"
                    position="relative"
                    hidden={!isReady || !!preview}
                  >
                    <Center h="full" w="full">
                      <canvas
                        width={VIDEO_WIDTH}
                        height={VIDEO_HEIGHT}
                        ref={canvasRef}
                      />
                    </Center>
                  </Box>

                  <Box
                    gridRowStart={1}
                    gridColumnStart={1}
                    w="full"
                    h="full"
                    hidden={!preview}
                  >
                    <Center
                      h="full"
                      w="full"
                      px={10}
                      maxH="calc(100vh - 11.5rem)"
                    >
                      <Box
                        css={{ aspectRatio: "16 / 9" }}
                        maxH="calc(100vh - 11.5rem)"
                      >
                        {preview?.type === RecordType.Video && (
                          <video
                            loop
                            autoPlay
                            controls
                            src={preview.mediaURL}
                          />
                        )}
                        {preview?.type === RecordType.Photo && (
                          <Image src={preview.mediaURL} objectFit="cover" />
                        )}
                      </Box>
                    </Center>
                  </Box>

                  {checkedPermissions && !isPermitted && (
                    <Box gridRowStart={1} gridColumnStart={1} w="full" h="full">
                      <Center w="full" h="full">
                        <Permissions
                          onClose={onClose}
                          onRetryCamera={() => {
                            setCheckedPermissions(false);
                          }}
                        />
                      </Center>
                    </Box>
                  )}
                </Grid>
              </Flex>

              <Box
                position="relative"
                flex={!!isDrawerActive && !preview ? "0 0 24rem" : undefined}
                maxW="24rem"
              >
                <Slide
                  direction="right"
                  in={!!isDrawerActive && !preview}
                  style={{ width: "24rem", position: "absolute" }}
                >
                  <Box w="24rem" height="100%" p={4} pl={0}>
                    <Drawer onMinimize={setDrawer.off} />
                  </Box>
                </Slide>
              </Box>
            </Flex>

            {isReady && (
              <Center p={4} width="100%" h="7rem">
                <Controls
                  enablePhotoUpload={enablePhotoUpload}
                  onClickFx={() => handleOpenDrawer(DrawerPanel.Effects)}
                  onClickSettings={() => handleOpenDrawer(DrawerPanel.Settings)}
                  onClickCamera={() => handleRecord(RecordType.Photo)}
                  onRecord={() => handleRecord(RecordType.Video)}
                  onRecordStop={handleRecordStop}
                  onKeepRecording={handleKeepRecording}
                  onDiscardRecording={() => setPreview(undefined)}
                />
              </Center>
            )}
          </Flex>

          <VisuallyHidden>
            <canvas
              ref={selfieCanvasRef}
              width={VIDEO_WIDTH}
              height={VIDEO_HEIGHT}
            />
          </VisuallyHidden>
        </Provider>
      </Box>
    );
  }
);
