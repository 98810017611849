import { FC } from "react";

import {
  Box,
  Checkbox,
  Progress,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  VisuallyHidden,
  HStack,
} from "Shared";

import { PageHeader } from "Components/PageHeader";

import { useTeacherChecklist } from "Components/TeacherChecklist";
import { AdminDashboardUserAccountFragment } from "@tract/common/dist/graphql";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

export const TeacherActivation: FC<Props> = ({ user }) => {
  const { items, progress, toggleItem } = useTeacherChecklist(user.id);

  return (
    <Box>
      <PageHeader
        title="Activation"
        renderActions={
          <HStack spacing={3}>
            <Progress
              colorScheme="green"
              size="md"
              value={progress}
              w="10rem"
            />
            <Text fontSize="sm" color="gray.600" mt={2} mb={4}>
              {progress}% complete
            </Text>
          </HStack>
        }
      />
      <Table width="100%">
        <Thead>
          <Tr>
            <Th>
              <VisuallyHidden>Completed</VisuallyHidden>
            </Th>
            <Th>Item</Th>
            <Th>Completed at</Th>
          </Tr>
        </Thead>
        <Tbody fontSize="md">
          {items.map((item) => (
            <Tr key={item.id}>
              <Td py={4}>
                <Checkbox
                  colorScheme="green"
                  isChecked={item.isComplete}
                  onChange={() => toggleItem(item)}
                  size="lg"
                />
              </Td>
              <Td py={4}>
                <Text fontWeight="bold">{item.title}</Text>
                <Text color="gray.600">{item.description}</Text>
              </Td>
              <Td py={4}>
                {item.isComplete && item?.completedAt?.toDate
                  ? item?.completedAt.toDate().toLocaleString()
                  : "-"}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
