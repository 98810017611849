import React, { FC, PropsWithChildren } from "react";

import {
  Flex,
  Text,
  ButtonGroup,
  ButtonSolid,
  ButtonOutlined,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "Shared";

import { useIntercom } from "Services/intercom";

const defaultMessage =
  "There was an unexpected error. Please try again or choose Help to contact support.";

type Props = {
  onRetry: () => void;
  onClose: () => void;
  isOpen: boolean;
};

export const ErrorModal: FC<PropsWithChildren<Props>> = ({
  onRetry,
  onClose,
  isOpen,
  children,
}) => {
  const { show } = useIntercom();

  function handleHelp() {
    onRetry();
    show();
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalBody p={6}>
          <Text fontSize="xl" color="gray.900" fontWeight="bold" mb={4}>
            Oops!
          </Text>
          {!!children ? (
            children
          ) : (
            <Text fontSize="md" color="gray.900">
              {defaultMessage}
            </Text>
          )}
          <Flex justify="flex-end" mt={6}>
            <ButtonGroup>
              <ButtonOutlined onClick={handleHelp}>Help!</ButtonOutlined>
              <ButtonSolid onClick={onRetry}>Try Again</ButtonSolid>
            </ButtonGroup>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
