import { FC } from "react";

import {
  ButtonOutlined,
  Center,
  ExternalLink,
  FontAwesomeIcon,
  Markdown,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "Shared";

import { GuideFragment } from "@tract/common/dist/graphql";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  guide: GuideFragment;
  title: string;
};

export const ProjectGuideModal: FC<Props> = ({
  isOpen,
  onClose,
  guide,
  title,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent
        maxH="85%"
        css={{
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        overflowY="scroll"
        px={4}
      >
        <ModalHeader>Project Guide</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text align="center" fontSize="3xl" fontWeight="bold" mb={1}>
            {title}
          </Text>
          <Text align="center" color="gray.600" mb={10}>
            {guide.description}
          </Text>
          {!!guide.bigIdea?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Big Idea
              </Text>
              <Markdown source={guide.bigIdea || ""} mb={6} pb={0} />
            </>
          )}
          {!!guide.resources?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Resources
              </Text>
              <Markdown source={guide.resources || ""} mb={6} />
            </>
          )}
          {!!guide.discussionQuestions?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Discussion Questions
              </Text>
              <Markdown
                source={guide.discussionQuestions || ""}
                mb={6}
                pb={0}
              />
            </>
          )}
          {!!guide.concepts?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Concepts
              </Text>
              <Markdown source={guide.concepts || ""} mb={6} />
            </>
          )}
          {!!guide.learningObjectives?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Learning Objectives
              </Text>
              <Markdown source={guide.learningObjectives || ""} mb={6} />
            </>
          )}
          {!!guide.contentStandards?.length && (
            <>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Content Standards
              </Text>
              <Markdown source={guide.contentStandards || ""} mb={6} />
            </>
          )}
        </ModalBody>
        {!!guide.downloadUrl && (
          <ModalFooter
            position="sticky"
            left={0}
            right={0}
            bottom={0}
            zIndex={10}
            bg="white"
          >
            <Center w="full">
              <ButtonOutlined
                leftIcon={<FontAwesomeIcon icon={regular("download")} />}
                size="lg"
                as={ExternalLink}
                href={guide.downloadUrl}
                target="_blank"
              >
                Download Guide
              </ButtonOutlined>
            </Center>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
