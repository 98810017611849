import React, { useContext, useEffect, useState } from "react";

import { useSession } from "Services/session";

import {
  Box,
  ButtonOutlined,
  EmptyState,
  Flex,
  IconArrowUp,
  SlideFade,
  VStack,
  IconButton,
  IconMarkRead,
  Tooltip,
  Button,
  FontAwesomeIcon,
} from "Shared";

import { BottomNav } from "Components/BottomNav";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { LayoutCentered } from "Components/LayoutCentered";

import { NotificationGroup } from "./NotificationGroup";
import { NotificationsContext, useStreamContext } from "Services/stream";
import { NotificationActivity } from "getstream";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type NotificationsState = {
  activities: NotificationActivity[];
  hasNextPage: boolean;
};

type Props = {
  insidePopover?: boolean;
  onClose?: () => void;
};

export const Notifications: React.FC<Props> = ({
  insidePopover = false,
  onClose,
}) => {
  const NOTIFICATION_LIMIT = 20;
  const { currentUser } = useSession();
  const { client } = useStreamContext();
  const { checkUnseen, clearNew, newActivityCount } =
    useContext(NotificationsContext);
  const [notifications, setNotifications] = useState<NotificationsState>({
    activities: [],
    hasNextPage: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [offset, setOffset] = useState(0);

  const feed = client.feed("notification", currentUser.id);

  const { activities, hasNextPage } = notifications;

  useEffect(() => {
    // Ensure we only load initial activities once
    if (fetched || !currentUser) {
      return;
    }

    const getNotifications = async () => {
      const { results, next } = await feed.get({
        limit: NOTIFICATION_LIMIT,
        offset: 0,
        mark_seen: true,
        mark_read: false,
      });

      const newActivities = results as NotificationActivity[];

      checkUnseen();
      clearNew();
      setNotifications({
        activities: newActivities,
        hasNextPage: !!next,
      });
      setLoading(false);
      setFetched(true);
    };

    setLoading(true);
    getNotifications();
  }, [client, currentUser, checkUnseen, clearNew, fetched, feed]);

  const loadMore = async () => {
    setLoadingMore(true);

    const newOffset = offset + NOTIFICATION_LIMIT;
    const feed = client.feed("notification", currentUser?.id);
    const { results, next } = await feed.get({
      limit: NOTIFICATION_LIMIT,
      offset: newOffset,
      mark_seen: true,
      mark_read: false,
    });

    setNotifications({
      activities: [...activities, ...(results as NotificationActivity[])],
      hasNextPage: !!next,
    });
    setLoadingMore(false);
    setOffset(newOffset);
    checkUnseen();
  };

  const handleGetNew = async () => {
    setLoading(true);

    const feed = client.feed("notification", currentUser?.id);
    const { results, next } = await feed.get({
      limit: NOTIFICATION_LIMIT,
      offset: 0,
      mark_seen: true,
      mark_read: false,
    });

    setNotifications({
      activities: [...(results as NotificationActivity[])],
      hasNextPage: !!next,
    });

    clearNew();
    setLoading(false);
  };

  async function handleMarkAllAsRead() {
    await feed.get({ mark_read: true });

    const activitiesRead = notifications.activities.map((activity) => ({
      ...activity,
      is_read: true,
    }));
    setNotifications({
      ...notifications,
      activities: [...activitiesRead],
    });
  }

  const areAnyNotificationsUnread = !!activities.filter(
    (activity) => !activity.is_read
  ).length;

  const markAllAsReadDisabled =
    !activities?.length || !areAnyNotificationsUnread;

  const renderNotificationItems = () => {
    return (
      <Box position="relative">
        {!!newActivityCount && (
          <Flex
            justify="center"
            position="sticky"
            top={3}
            zIndex={1}
            width="100%"
          >
            <SlideFade in={!!newActivityCount}>
              <Box
                boxShadow="md"
                borderRadius="full"
                position="absolute"
                top={3}
                left="50%"
                transform="translate(-50%, 0)"
              >
                <ButtonOutlined
                  onClick={handleGetNew}
                  leftIcon={<IconArrowUp />}
                  bg="white"
                >
                  {newActivityCount} New
                </ButtonOutlined>
              </Box>
            </SlideFade>
          </Flex>
        )}
        {activities.length > 0 ? (
          <VStack spacing={3} align="stretch">
            {activities.map((group, i) => (
              <NotificationGroup
                key={i}
                group={group}
                currentUser={currentUser}
                onClose={onClose}
              />
            ))}
          </VStack>
        ) : (
          <EmptyState
            headline="Nothing to see here"
            body="This is where you'll see notifications about things happening on Tract"
          />
        )}

        {hasNextPage && (
          <Flex justify="center" my={6}>
            <ButtonOutlined isLoading={loadingMore} onClick={loadMore}>
              Load More
            </ButtonOutlined>
          </Flex>
        )}
      </Box>
    );
  };

  if (!insidePopover) {
    return (
      <PageApp maxWidth="50rem" renderBottomNav={<BottomNav />}>
        <PageHeader
          title="Notifications"
          renderActions={
            <Tooltip label="Mark all as read" hasArrow placement="top">
              <IconButton
                aria-label="mark all as read"
                icon={<IconMarkRead />}
                size="md"
                disabled={markAllAsReadDisabled}
                onClick={handleMarkAllAsRead}
              />
            </Tooltip>
          }
        />

        {loading ? (
          <LayoutCentered isLoading />
        ) : (
          <Box>{renderNotificationItems()}</Box>
        )}
      </PageApp>
    );
  } else {
    return (
      <Box>
        {renderNotificationItems()}
        <Box
          w="full"
          position="sticky"
          left={0}
          right={0}
          bottom={0}
          bg="white"
          pb={2}
        >
          <Button
            size="lg"
            w="full"
            variant="outline"
            disabled={markAllAsReadDisabled}
            onClick={handleMarkAllAsRead}
            leftIcon={
              <FontAwesomeIcon icon={regular("trash-check")} size={6} />
            }
          >
            Mark all as read
          </Button>
        </Box>
      </Box>
    );
  }
};
