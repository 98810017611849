import React, { useEffect, useState, PropsWithChildren } from "react";
import { Form, Formik } from "formik";
import { useQuery } from "urql";
import { format } from "date-fns";
import { ModalProps } from "@chakra-ui/react";

import {
  Box,
  Circle,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "Shared";

import { ANALYTICS_EVENTS, useTrackEventOnce } from "Services/analytics";

import { INPUT_DATE_FORMAT, Step, steps } from "./_shared";

import { Steps } from "./Steps";
import { FormControls } from "./Controls";

import {
  AssignBuilderQuery,
  AssignBuilderQueryVariables,
  AssignBuilderPathByIdQuery,
  AssignBuilderPathByIdQueryVariables,
} from "@tract/common/dist/graphql";
import {
  ASSIGN_BUILDER_PATH_BY_ID_QUERY,
  ASSIGN_BUILDER_QUERY,
} from "./graphql";

const DEFAULT_STEP = steps[0];

type Props = {
  assignmentId?: string;
  pathId?: number;
  isOpen: boolean;
  onClose: () => void;
} & Omit<ModalProps, "children">;

export const AssignmentBuilderModal: React.FC<PropsWithChildren<Props>> = ({
  assignmentId,
  pathId,
  isOpen,
  onClose,
  ...props
}) => {
  const [activeStep, setStep] = useState<Step>(DEFAULT_STEP);

  const [{ data: pathData, fetching: pathFetching }] = useQuery<
    AssignBuilderPathByIdQuery,
    AssignBuilderPathByIdQueryVariables
  >({
    query: ASSIGN_BUILDER_PATH_BY_ID_QUERY,
    variables: {
      id: pathId,
    },
    pause: !pathId,
  });

  const [{ data: assignmentData, fetching: assignmentFetching }] = useQuery<
    AssignBuilderQuery,
    AssignBuilderQueryVariables
  >({
    query: ASSIGN_BUILDER_QUERY,
    variables: {
      id: assignmentId,
    },
    pause: !assignmentId,
  });

  let path = pathData?.path_by_pk;

  const fetching = assignmentFetching || pathFetching;
  const assignment = assignmentData?.assignment_by_pk;

  if (assignment?.paths[0]) {
    path = assignment?.paths[0]?.path;
  }

  useEffect(() => {
    setStep(DEFAULT_STEP);
  }, []);

  useTrackEventOnce(
    ANALYTICS_EVENTS.ASSIGNMENT_CREATE_VIEWED,
    {
      isNew: !assignmentId,
      pathIds: [pathId],
    },
    {
      skip: fetching || !pathId,
    }
  );

  if (!path || fetching) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        assignmentId: assignmentId,
        title: assignment?.title || path?.title || "",
        description: assignment?.description || "",
        pathId: path?.id,
        startDate: format(
          assignment?.startDate ? new Date(assignment.startDate) : new Date(),
          INPUT_DATE_FORMAT
        ),
        dueDate: !!assignment?.dueDate
          ? format(new Date(assignment.dueDate), INPUT_DATE_FORMAT)
          : undefined,
        classIds:
          assignment?.learnerGroups.map((lg) => lg.learnerGroupId) || [],
        newClassroom: undefined,
      }}
      onSubmit={() => {}}
      validationSchema={activeStep.schema}
      validateOnChange={true}
    >
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
        scrollBehavior="inside"
        {...props}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader boxShadow="base">Assign</ModalHeader>
          <ModalBody py={0}>
            <Box w="full" height="full">
              <Form>
                <Flex
                  alignItems="start"
                  height="100%"
                  direction={{ base: "column", lg: "row" }}
                  flex="1 1 auto"
                >
                  <VStack
                    w="16rem"
                    position="sticky"
                    top={0}
                    pt={3}
                    alignItems="start"
                    spacing={6}
                    display={{ base: "none", lg: "block" }}
                  >
                    {steps.map((step, i) => (
                      <StepNavItem
                        key={step.id}
                        isActive={activeStep.id === step.id}
                        step={step}
                        index={i}
                      />
                    ))}
                  </VStack>
                  <Box
                    w="100%"
                    overflowX={{ base: "scroll", lg: "auto" }}
                    borderLeft={{ base: "none", lg: "1px solid" }}
                    borderLeftColor={{ base: "none", lg: "gray.200" }}
                    py={10}
                  >
                    <Box w="100%" maxW="720px" mx="auto">
                      <Steps tabIndex={steps.indexOf(activeStep)} path={path} />
                    </Box>
                  </Box>
                </Flex>
              </Form>
            </Box>
          </ModalBody>
          <ModalFooter borderTop="1px solid" borderTopColor="gray.200">
            <FormControls
              activeStep={activeStep}
              steps={steps}
              setStep={setStep}
              onSaved={onClose}
              onCancel={onClose}
              path={path}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Formik>
  );
};

export const StepNavItem: React.FC<{
  isActive: boolean;
  step: Step;
  index: number;
}> = ({ isActive, step, index }) => {
  return (
    <HStack spacing={3}>
      <Circle bgColor={isActive ? "brand" : "gray.300"} size={8}>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={isActive ? "white" : "gray.600"}
        >
          {index + 1}
        </Text>
      </Circle>
      <Text
        fontWeight="bold"
        color={isActive ? "brand" : "gray.900"}
        fontSize="lg"
      >
        {step.navName}
      </Text>
    </HStack>
  );
};
