import React, { useState } from "react";
import { Redirect, useParams } from "react-router";

import { useUnauthPathViewerQuery } from "@tract/common/dist/graphql/index";

import { Box, Center, EmptyState, Grid, Spinner, Text, VStack } from "Shared";

import { ContentUnauthed } from "./ContentUnauthed";
import { usePathRoute } from "../usePathRoute";
import { LayoutCentered } from "Components/LayoutCentered";
import { ContainerMarketing } from "Components/LayoutMarketing";
import {
  PathStatus,
  PathVisibility,
} from "@tract/common/dist/types/models/Path";
import { PlaylistItemNode } from "./PlaylistItemNode";
import { Helmet } from "react-helmet";
import { UserFile } from "Utils/UserFile";

export const PathViewerUnauthed: React.FC = () => {
  const { slug, nodeId } = useParams<{
    nodeId: string;
    slug: string;
  }>();

  const [usingFirestoreId, setUsingFirestoreId] = useState(false);

  /**
   * In cases with legacy paths, the slug will be a firestore ID, so we try that
   * if the query on the slug doesn't return a path
   */
  const { data, error, loading } = useUnauthPathViewerQuery({
    variables: {
      where: {
        ...(usingFirestoreId
          ? { firestoreId: { _eq: slug } }
          : { slug: { _eq: slug } }),
        status: { _eq: PathStatus.Published },
        visibility: { _eq: PathVisibility.Public },
      },
    },
  });

  const path = data?.path[0];
  const { params, baseUrl, isPreviewRoute } = usePathRoute(path);

  const coverFile = new UserFile(path?.coverFile);
  const coverUrl = coverFile.getTransformedUrl("cover_thumb");

  if (!loading && (isPreviewRoute || !path?.isVisibleUnauthed)) {
    return <Redirect to="/" />;
  }

  if (error) {
    return <EmptyState headline="Error loading content" />;
  }

  if (loading) {
    return (
      <Center height="200px">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand"
          size="xl"
        />
      </Center>
    );
  }

  if (!path) {
    if (!usingFirestoreId) {
      setUsingFirestoreId(true);
    }
    return (
      <LayoutCentered color="gray.600" as={VStack}>
        <Text fontWeight="bold" fontSize="6xl">
          Ooops...
        </Text>
        <Text fontSize="lg">
          Sorry, we couldn't get the path you're looking for :(
        </Text>
      </LayoutCentered>
    );
  }
  const node = path.nodes.find((n) => n.id === params.nodeId);

  if (nodeId && !node) {
    return <Redirect to={baseUrl} />;
  }

  return (
    <>
      <Helmet>
        {!!path?.title?.length && <title>{`${path.title} — Tract`}</title>}
        {!!path?.description?.length && (
          <meta name="description" content={path.description} />
        )}
        {!!coverUrl && <meta name="image" content={coverUrl} />}
      </Helmet>
      <ContainerMarketing>
        <Grid
          templateColumns={{ lg: "1fr 24rem" }}
          gap={6}
          pt={{ base: 0, lg: 6 }}
          pb={20}
          mx={{ base: -6, lg: "auto" }}
        >
          <ContentUnauthed path={path} />

          <Box px={{ base: 6, lg: 0 }}>
            <Text mt={2} mb={4} fontSize="xl" fontWeight="bold">
              Lessons in This Path
            </Text>
            <VStack align="stretch" spacing={4}>
              {path.nodes.map((node, i) => (
                <Box key={node.id}>
                  <PlaylistItemNode path={path} node={node} isLocked={false} />
                </Box>
              ))}
            </VStack>
          </Box>
        </Grid>
      </ContainerMarketing>
    </>
  );
};
