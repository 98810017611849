import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useIntercom } from "Services/intercom";

export const useReportPath = () => {
  const { show: showIntercom } = useIntercom();
  const { track } = useAnalytics();
  const report = (eventProps: any) => {
    showIntercom();
    track(ANALYTICS_EVENTS.PATH_REPORTED, eventProps);
  };
  return report;
};
