import { OptimisticMutationConfig } from "@urql/exchange-graphcache";

export const optimistic: OptimisticMutationConfig = {
  api_update_privacy: (data, cache, info) => {
    return {
      ...info.variables,
    };
  },

  api_disable_user: (data, cache, info) => {
    return {
      ...info.variables,
    };
  },
};
