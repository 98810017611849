import { PropsWithChildren } from "react";
import NukaCarousel, {
  Alignment,
  CarouselProps,
  ControlProps,
} from "nuka-carousel";

export const prevButtonDisabled = ({
  currentSlide,
  wrapAround,
  cellAlign,
  slidesToShow,
}: ControlProps) => {
  // inifite carousel
  if (wrapAround) {
    return false;
  }
  // disable if displaying the leftmost slide
  if (currentSlide === 0) {
    return true;
  }
  // remainder scroll mode
  if (cellAlign === Alignment.Right && currentSlide <= slidesToShow - 1) {
    return true;
  }
  return false;
};

export const nextButtonDisabled = ({
  currentSlide,
  slideCount,
  slidesToShow,
  wrapAround,
  cellAlign,
}: ControlProps) => {
  // inifite carousel
  if (wrapAround) {
    return false;
  }
  // If we are at the last possible slide without wrap, disable
  if (currentSlide >= slideCount - 1) {
    return true;
  }
  // remainder scroll mode
  if (
    cellAlign === Alignment.Left &&
    currentSlide >= slideCount - slidesToShow
  ) {
    return true;
  }
  return false;
};

export const Carousel: React.FC<PropsWithChildren<CarouselProps>> = (props) => {
  return <NukaCarousel {...props} />;
};
