import React, { FC } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import {
  Box,
  Flex,
  TabLink,
  TabList,
  Tabs,
  useRouteTabs,
  // useBreakpointValue,
} from "Shared";

import { ProfileAbout } from "./About";
import { ProfileProjects } from "./Projects";
// import { ProfileLikes } from "../ProfileLikes";
import { LearnerStatHeader } from "./StatHeader";
import { UserAwards } from "./UserAwards";

import { CreatorLevel, isTeacherUser } from "Types/User";

import { useSession } from "Services/session";
import { useProfileViewAnalytics } from "Services/hooks/useProfileViewAnalytics";
import { UserProfileUserFragment } from "@tract/common/dist/graphql";
import { ProfilePaths } from "./Paths";
import { ProfileLivestreams } from "./Livestreams";

type Props = {
  user: UserProfileUserFragment;
  isMobile: boolean | undefined;
};

// Used to wrap child views that don't make sense at full width
export const ProfileContent: FC<Props> = ({ user, isMobile }) => {
  const { usernameOrId: usernameOrIdRoute } = useParams<{
    usernameOrId?: string;
  }>();
  const { currentUser } = useSession();
  const isLearnerCreator =
    user.creatorLevel && user.creatorLevel !== CreatorLevel.Disabled;
  const isTeacher = isTeacherUser(user);
  const isPartnerOrMod =
    user.isMod ||
    (user.creatorLevel && user.creatorLevel === CreatorLevel.Partner);

  useProfileViewAnalytics({ isAuthorProfile: false });

  // const showLikes = useBreakpointValue<boolean | undefined>({
  //   base: false,
  //   md: true,
  // });

  const showProjects = !isTeacher || (isTeacher && user.id === currentUser.uid);

  const { tabsProps } = useRouteTabs({
    routes: [
      ...(showProjects ? [{ path: `/${usernameOrIdRoute}/projects` }] : []),
      ...(isLearnerCreator ? [{ path: `/${usernameOrIdRoute}/paths` }] : []),
      { path: `/${usernameOrIdRoute}/about` },
      ...(isPartnerOrMod
        ? [{ path: `/${usernameOrIdRoute}/livestreams` }]
        : []),
      ...(!isTeacher ? [{ path: `/${usernameOrIdRoute}/awards` }] : []),
      // ...(showLikes ? [{ path: `/${usernameOrIdRoute}/likes` }] : []),
    ],
  });

  return (
    <>
      <Box my={8}>
        <LearnerStatHeader user={user} isMobile={isMobile} />
      </Box>
      <Flex justify="center">
        <Tabs
          {...tabsProps}
          isManual
          mb={{ base: 10, md: 16 }}
          overflowX={{ base: "scroll", md: "initial" }}
        >
          <TabList>
            {showProjects && (
              <TabLink to={`/${usernameOrIdRoute}/projects`} flex={[1, 0]}>
                Projects
              </TabLink>
            )}
            {isLearnerCreator && (
              <TabLink to={`/${usernameOrIdRoute}/paths`} flex={[1, 0]}>
                Paths
              </TabLink>
            )}
            <TabLink to={`/${usernameOrIdRoute}/about`} flex={[1, 0]}>
              About
            </TabLink>
            {isPartnerOrMod && (
              <TabLink to={`/${usernameOrIdRoute}/livestreams`} flex={[1, 0]}>
                Livestreams
              </TabLink>
            )}
            {!isTeacher && (
              <TabLink to={`/${usernameOrIdRoute}/awards`} flex={[1, 0]}>
                Awards
              </TabLink>
            )}
            {/* {showLikes && (
              <TabLink to={`/${usernameOrIdRoute}/likes`} flex={[1, 0]}>
                Likes
              </TabLink>
            )} */}
          </TabList>
        </Tabs>
      </Flex>
      <Switch>
        <Redirect
          exact
          from="/:usernameOrId"
          to={`/${usernameOrIdRoute}/projects`}
        />
        {showProjects && (
          <Route path="/:usernameOrId/projects">
            <ProfileProjects user={user} />
          </Route>
        )}
        {isLearnerCreator && (
          <Route path="/:usernameOrId/paths">
            <ProfilePaths author={user} />
          </Route>
        )}
        <Route path="/:usernameOrId/about">
          <ProfileAbout currentUser={currentUser} user={user} />
        </Route>
        {isPartnerOrMod && (
          <Route path="/:usernameOrId/livestreams">
            <ProfileLivestreams user={user} />
          </Route>
        )}
        {!isTeacher && (
          <Route path="/:usernameOrId/awards">
            <UserAwards user={user} />
          </Route>
        )}
        {/* {showLikes && (
          <Route path="/:usernameOrId/likes">
            <ProfileLikes profile={user} />
          </Route>
        )} */}
      </Switch>
    </>
  );
};
