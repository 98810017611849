import React, { FC, PropsWithChildren } from "react";
import { useQuery } from "urql";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Flex,
} from "Shared";

import { useSession } from "Services/session";

import { UserListRow } from "./UserListRow";
import {
  UserFollowersByIdQueryResult,
  UserFollowersByIdQueryVariables,
  UserFollowingByIdQueryResult,
  UserFollowingByIdQueryVariables,
} from "@tract/common/dist/graphql";
import {
  USER_FOLLOWERS_BY_ID_QUERY,
  USER_FOLLOWING_BY_ID_QUERY,
} from "Pages/Profile/graphql";

type Props = {
  header: string;
  profileId: string;
  listType: "following" | "followers";
  loadingStateCount?: number;
};

const USER_LIST_MAX = 50;

export const UserListModal: FC<PropsWithChildren<Props>> = ({
  children,
  header,
  profileId,
  listType,
  loadingStateCount,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useSession();

  const [{ data: followersData, fetching: loadingFollowersData }] = useQuery<
    UserFollowersByIdQueryResult["data"],
    UserFollowersByIdQueryVariables
  >({
    pause: listType === "following" || !isOpen,
    query: USER_FOLLOWERS_BY_ID_QUERY,
    variables: {
      id: profileId,
      currentUserId: currentUser.id,
      limit: USER_LIST_MAX,
    },
  });
  const userFollowers = followersData?.user[0];

  const [{ data: followingData, fetching: loadingFollowingData }] = useQuery<
    UserFollowingByIdQueryResult["data"],
    UserFollowingByIdQueryVariables
  >({
    pause: listType === "followers" || !isOpen,
    query: USER_FOLLOWING_BY_ID_QUERY,
    variables: {
      id: profileId,
      currentUserId: currentUser.id,
      limit: USER_LIST_MAX,
    },
  });

  const userFollowing = followingData?.user[0];

  return (
    <>
      {/* @ts-ignore */}
      {children({ open: onOpen })}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
        scrollBehavior="inside"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0} pb={4}>
            {(loadingFollowingData || loadingFollowersData) && (
              <>
                {[...Array(Math.min(loadingStateCount || 0, 10))].map((i) => (
                  <UserSkeleton key={i} />
                ))}
              </>
            )}
            {listType === "followers"
              ? userFollowers?.followers?.map((user) => (
                  <UserListRow
                    key={user.sourceUserId}
                    user={user.sourceUser}
                    my={2}
                  />
                ))
              : userFollowing?.following?.map((user) => (
                  <UserListRow
                    key={user.targetUserId}
                    user={user.targetUser}
                    my={2}
                  />
                ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export const UserSkeleton: FC = () => {
  return (
    <Box height="3rem" width="full" mb={2}>
      <Flex direction="row" alignItems="center">
        <Box bg="gray.100" width={12} height={12} borderRadius="full" mr={3} />
        <Box bg="gray.100" height={6} width="full" flex={1} borderRadius="md" />
      </Flex>
    </Box>
  );
};
