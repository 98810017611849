import { Box, VStack, Flex } from "Shared";

export const CommentSkeleton: React.FC = () => {
  return (
    <Box height="4.25rem" width="full" mt={6}>
      <Flex direction="row" alignItems="center">
        <Box bg="gray.100" width={12} height={12} borderRadius="full" mr={4} />
        <VStack spacing={1} width="full" flex={1}>
          <Box bg="gray.100" height={3} width="full" borderRadius="md" />
          <Box bg="gray.100" height={3} width="full" borderRadius="md" />
        </VStack>
      </Flex>
    </Box>
  );
};
