import { getColor, mode, transparentize } from "@chakra-ui/theme-tools";

function variantOutline(props: Record<string, any>) {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(`${c}.200`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.600`);
  const lightBorderColor = getColor(theme, `${c}.300`);
  const color = mode(lightColor, darkColor)(props);
  const borderColor = mode(lightBorderColor, darkColor)(props);

  return {
    color,
    boxShadow: `inset 0 0 0px 1px ${borderColor}`,
  };
}

export const Badge = {
  baseStyle: {
    fontSize: "xs",
    fontWeight: "bold",
    px: 2,
    borderRadius: "full",
    lineHeight: "1.5rem",
  },
  variants: {
    outline: variantOutline,
  },
  defaultProps: {
    as: "span",
  },
};
