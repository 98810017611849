import {
  messageHasReactions,
  useMessageContext,
  useReactionHandler,
  useEmojiContext,
  useChannelStateContext,
} from "stream-chat-react";
import { format } from "date-fns";

import {
  Avatar,
  Box,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  IconMoreHorizontal,
  Tooltip,
  Portal,
  Button,
  IconFilledAdmin,
} from "Shared";

import { useLivestreamContext } from "Pages/Livestream";
import { ReactionEmoji } from "stream-chat-react/dist/components/Channel/emojiData";
import { Suspense, useMemo, useRef } from "react";
import { customReactions, ReactionPopover } from "./ReactionPopover";
import { useSession } from "Services/session";
import { CreatorLevel } from "@tract/common/dist/types/models/User";

export const LivestreamMessage: React.FC = (props) => {
  const { message, handleDelete, /*handleFlag,*/ isMyMessage } =
    useMessageContext();
  const channelState = useChannelStateContext();
  const {
    isMod: canMod,
    isBannedUser,
    handleBan,
    handleUnban,
  } = useLivestreamContext();
  const { currentUser, auth } = useSession();
  const currentUserIsMod =
    auth.isAdmin || currentUser.creatorLevel === CreatorLevel.Partner;
  const userId = message.user?.id || "";
  const isBanned = isBannedUser(userId);
  const isMine = isMyMessage();
  const isMod = !!channelState.members?.[userId]?.is_moderator;
  const { Emoji, emojiConfig } = useEmojiContext("ReactionsList");
  const reactionHandler = useReactionHandler(message);
  const hasReactions = messageHasReactions(message);
  // const [flagged, setFlagged] = useState(false);

  const menuTriggerRef = useRef(null);
  const { emojiData } = emojiConfig || {};

  const getEmojiByReactionType = (type: string): ReactionEmoji | undefined => {
    const reactionEmoji = customReactions.find(
      (option: ReactionEmoji) => option.id === type
    );
    return reactionEmoji;
  };

  // const flagMessage = (e: React.BaseSyntheticEvent) => {
  //   setFlagged(true);
  //   handleFlag(e);
  // };

  const getUsersPerReactionType = (type: string) =>
    message?.latest_reactions
      ?.map((reaction) => {
        if (reaction.type === type) {
          return reaction.user?.name || reaction.user?.id;
        }
        return null;
      })
      .filter(Boolean);

  // const canFlag =
  //   !message.deleted_at && !isBanned && !isMine && !isMod && !flagged;
  const canReact = !message.deleted_at && !isBanned;

  const capabilities = useMemo(() => {
    const items = [];

    if (!message.deleted_at && (isMine || canMod)) {
      items.push("delete");
    }
    if (canMod && !isMine && !isMod) {
      items.push("ban");
    }

    return items;
  }, [isMod, isMine, canMod, message.deleted_at]);

  return (
    <Box
      role="group"
      _hover={{ bgColor: "gray.50" }}
      py={2}
      pl={4}
      pr={2}
      width="full"
      // bg={flagged ? "red.50" : "white"}
      // borderLeft={flagged ? "4px solid" : "none"}
      // borderLeftColor="red.500"
    >
      <HStack width="full">
        <Box width="full">
          <HStack align="top" spacing={3} width="full">
            <Avatar
              src={message.user?.image}
              name={message.user?.name}
              size="sm"
            />
            <Box width="full">
              <Flex direction="row" justifyContent="space-between" width="full">
                <Flex direction="row" align="center">
                  <Box>
                    <Text fontSize="md" fontWeight="bold">
                      {message.user?.name}{" "}
                      {isBanned && currentUserIsMod && "(Banned)"}
                    </Text>
                  </Box>
                  {isMod && (
                    <Tooltip label="Moderator" placement="top" hasArrow>
                      <IconFilledAdmin
                        boxSize={5}
                        bg="yellow.500"
                        borderRadius="md"
                        color="white"
                        ml={1}
                      />
                    </Tooltip>
                  )}
                  <Box _groupHover={{ display: "block" }} display="none">
                    {message.created_at && (
                      <Text ml={2} fontSize="sm" as="time" color="gray.500">
                        {format(new Date(message.created_at), "h:mma")}
                      </Text>
                    )}
                  </Box>
                </Flex>
                <Box
                  _groupHover={{ display: "flex" }}
                  display="none"
                  bg="white"
                  borderRadius={99}
                  boxShadow="md"
                  position="absolute"
                  right={2}
                  border="1px solid"
                  borderColor="gray.300"
                >
                  {canReact && <ReactionPopover message={message} />}
                  {/* {canFlag && (
                    <IconButton
                      aria-label="Flag chat message"
                      icon={<IconFlag width={4} height={4} />}
                      variant="ghost"
                      size="sm"
                      onClick={flagMessage}
                      disabled={!canFlag}
                    />
                  )} */}
                  {capabilities.length > 0 && (
                    <Menu>
                      <MenuButton
                        className="showFocused"
                        as={IconButton}
                        aria-label="Chat message actions"
                        icon={<IconMoreHorizontal size={16} />}
                        variant="ghost"
                        size="sm"
                        ref={menuTriggerRef}
                      />
                      <Portal containerRef={menuTriggerRef}>
                        <MenuList>
                          {capabilities.includes("delete") && (
                            <MenuItem fontSize="md" onClick={handleDelete}>
                              Delete
                            </MenuItem>
                          )}
                          {capabilities.includes("ban") && (
                            <MenuItem
                              fontSize="md"
                              onClick={() =>
                                isBanned
                                  ? handleUnban(userId)
                                  : handleBan(userId)
                              }
                            >
                              {isBanned ? "Unban" : "Ban"}
                            </MenuItem>
                          )}
                        </MenuList>
                      </Portal>
                    </Menu>
                  )}
                </Box>
              </Flex>
              <Box>
                {message.deleted_at || isBanned ? (
                  <Text fontSize="md" color="gray.500">
                    Message Deleted
                  </Text>
                ) : (
                  <Text fontSize="md" wordBreak="break-word">
                    {message.text}
                  </Text>
                )}
                <HStack spacing={1} mt={1}>
                  {hasReactions && (
                    <>
                      {Object.entries(message.reaction_counts || {})
                        .sort()
                        .map(([key, value]) => (
                          <Tooltip
                            label={getUsersPerReactionType(key)?.join(", ")}
                            hasArrow
                            fontSize="sm"
                            placement="top"
                            key={key}
                            openDelay={50}
                          >
                            <Button
                              size="sm"
                              variant="outline"
                              onClick={(e) => reactionHandler(key, e)}
                              alignContent="center"
                              borderColor={
                                getUsersPerReactionType(key)?.includes(
                                  currentUser.username
                                )
                                  ? "gray.600"
                                  : "gray.300"
                              }
                              border={
                                getUsersPerReactionType(key)?.includes(
                                  currentUser.username
                                )
                                  ? "2px solid"
                                  : "1px solid"
                              }
                            >
                              <Box h={4} mr={1}>
                                <Suspense fallback={null}>
                                  <Emoji
                                    data={emojiData}
                                    emoji={
                                      getEmojiByReactionType(key) || "undefined"
                                    }
                                    size={16}
                                  />
                                </Suspense>
                              </Box>
                              <Text>{value}</Text>
                            </Button>
                          </Tooltip>
                        ))}
                    </>
                  )}
                </HStack>
              </Box>
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};
