import {
  Button as ChakraButton,
  ButtonProps,
  forwardRef,
} from "@chakra-ui/react";

export const ButtonSolid = forwardRef(
  ({ _hover, _active, ...props }: ButtonProps, ref) => {
    return (
      <ChakraButton
        variant="solid"
        colorScheme="brandFull"
        ref={ref}
        {...props}
      />
    );
  }
);

export const ButtonOutlined = forwardRef(({ inverted, ...props }, ref) => {
  return <ChakraButton variant="outline" ref={ref} {...props} />;
});

export const ButtonLink = forwardRef((props, ref) => {
  return <ChakraButton variant="link" ref={ref} {...props} />;
});
