import React from "react";

import { useSession } from "Services/session";

import { PageApp } from "Components/PageApp";

import { LearnerDash } from "./LearnerDash";

import { isParentUser, isTeacherUser } from "Types/User";
import { TeacherHome } from "./TeacherHome";

export const HomeAuthed: React.FC = () => {
  const { currentUser } = useSession();
  const showTeacherHome =
    isTeacherUser(currentUser) || isParentUser(currentUser);
  return (
    <PageApp>{showTeacherHome ? <TeacherHome /> : <LearnerDash />}</PageApp>
  );
};
