import React from "react";

import { Markdown, Text } from "Shared";

import { UsernameLink } from "Components/UsernameLink";

type Props = {
  username: string;
  isEducator: boolean;
  displayName: string;
  description: string;
  numActors?: number;
};

export const NotificationBody: React.FC<Props> = ({
  username = "Someone",
  isEducator,
  displayName,
  description,
  numActors = 2,
}) => {
  return (
    <>
      <UsernameLink
        username={username}
        fontWeight="bold"
        onClick={(e: any) => e.stopPropagation()}
        display="inline"
        isTeacher={isEducator}
      >
        {displayName}
      </UsernameLink>{" "}
      {numActors > 1 && (
        <span>
          and {numActors - 1} {numActors > 2 ? "others" : "other"}
        </span>
      )}{" "}
      <Markdown
        as="span"
        source={`${description}`}
        components={{
          p: ({ children }) => <Text as="span" children={children} />,
        }}
      />
    </>
  );
};
