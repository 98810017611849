import { gql } from "urql";

export const STREAM_IO_CREATE_ACTIVITY = gql`
  mutation StreamIOCreateActivity(
    $apiKey: String!
    $feedSlug: String!
    $feedUserId: String!
    $activity: StreamIOCreateActivityInput!
  ) {
    streamio_create_activity(
      api_key: $apiKey
      feed_slug: $feedSlug
      feed_user_id: $feedUserId
      activity: $activity
    ) {
      id
    }
  }
`;
