import { Switch, Route, Redirect } from "react-router";

import { ClassroomLearnerGroupFragment } from "@tract/common/dist/graphql";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  Tabs,
  TabList,
  TabLink,
  useBreakpointValue,
  useRouteTabs,
} from "Shared";

import { useSession } from "Services/session";

import { LearnersTable } from "Pages/Learners/LearnersTable";

import { PageApp } from "Components/PageApp";
import { useClassContext } from "Components/LayoutClass";
import { PageHeader } from "Components/PageHeader";
import { ClassroomTeachers } from "./ClassroomTeachers";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const ClassroomPeople: React.FC<Props> = ({ classroom }) => {
  const { currentUser, isAdmin } = useSession();
  const { classData } = useClassContext();
  const showBreadcrumb = useBreakpointValue({ base: true, lg: false });

  const selectedClassCode = classroom.educatorCodes[0];
  const isClassTeacher = classroom.members.some(
    ({ userId }) => userId === currentUser.id && currentUser.isEducator
  );
  const isClassMember =
    isClassTeacher ||
    classroom.members.some(({ userId }) => currentUser.id === userId);
  const showAdminView = isAdmin || isClassTeacher;

  const { tabsProps } = useRouteTabs({
    routes: [
      { path: `/class/${classData.id}/people/students` },
      { path: `/class/${classData.id}/people/teachers` },
    ],
  });

  return (
    <Tabs {...tabsProps} isManual>
      <PageApp maxW="50rem">
        <PageHeader
          title="People"
          showBreadcrumb={showBreadcrumb}
          renderBreadcrumb={
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to={`/class/${classData.id}/`}>
                  {classData.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>People</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          }
          renderTabs={
            <TabList>
              <TabLink to={`/class/${classData.id}/people/students`}>
                Students
              </TabLink>
              <TabLink to={`/class/${classData.id}/people/teachers`}>
                Teachers
              </TabLink>
            </TabList>
          }
        />
        <Switch>
          <Route path={`/class/:classId/people/students`}>
            <LearnersTable
              classroomId={classroom.id}
              kidIds={classroom.members
                .filter(({ role }) => role === "learner")
                .map(({ userId }) => userId)}
              activeCode={selectedClassCode}
              showAdminView={showAdminView}
              classMemberOrTeacher={isClassMember}
            />
          </Route>
          <Route path={`/class/:classId/people/teachers`}>
            <ClassroomTeachers classroom={classData} />
          </Route>
          <Redirect to={`/class/${classData.id}/people/students`} />
        </Switch>
      </PageApp>
    </Tabs>
  );
};
