import { PropsWithChildren } from "react";

import { format } from "date-fns";
import { FlexProps, forwardRef } from "@chakra-ui/react";
import { Flex, HStack, Text } from "Shared";

import {
  LivestreamFragment,
  LivestreamHostUserFragment,
} from "@tract/common/dist/graphql";

type StreamBodyProps = {
  host: LivestreamHostUserFragment;
  livestream: LivestreamFragment;
} & FlexProps;

export const StreamBody = forwardRef<PropsWithChildren<StreamBodyProps>, "div">(
  ({ host, livestream, ...props }, ref) => {
    return (
      <Flex
        direction="column"
        p={10}
        borderRadius="2xl"
        bg="gray.50"
        {...props}
        ref={ref}
      >
        <HStack spacing={2}>
          <Text
            fontSize="sm"
            fontWeight="bold"
            color="orange.600"
            textTransform="uppercase"
            mb={3}
          >
            {format(new Date(livestream?.startDate), "eee, LLL do @ h:mm b")}
          </Text>
        </HStack>
        <Text fontSize="2xl" fontWeight="bold" color="gray.900">
          {livestream.title}
        </Text>
        {livestream?.description && (
          <Text fontSize="md" color="gray.900" mt={2}>
            {livestream?.description}
          </Text>
        )}
      </Flex>
    );
  }
);
