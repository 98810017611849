/**
 * Generated using https://colorbox.io/
 */
export const colors = {
  brand: "#141CDE",
  // Deprecated, use "brandPrimary"
  brandFull: {
    50: "#e7f0ff",
    100: "#ccddff",
    200: "#92a9ff",
    300: "#5d72ff",
    400: "#3342f4",
    500: "#141CDE",
    600: "#060aae",
    700: "#01027b",
    800: "#000047",
    900: "#000019",
  },
  // Identical to "brandFull" with updated the naming convention
  brandPrimary: {
    50: "#e7f0ff",
    100: "#ccddff",
    200: "#92a9ff",
    300: "#5d72ff",
    400: "#3342f4",
    500: "#141CDE",
    600: "#060aae",
    700: "#01027b",
    800: "#000047",
    900: "#000019",
  },
  brandSecondary: {
    50: "#fff7e6",
    100: "#fff1cd",
    200: "#ffe29a",
    300: "#ffd568",
    400: "#ffca36",
    500: "#ffc004",
    600: "#e6a700",
    700: "#cc9100",
    800: "#b37b00",
    900: "#996600",
  },
};
