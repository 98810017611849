import { FC, PropsWithChildren, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "urql";

import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonLink,
  Divider,
  Flex,
  HStack,
  IconExternalLink,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "Shared";

import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";
import { CoinValue } from "Components/CoinValue";
import { AdminContent } from "../AdminContent";

import {
  AdminDashboardUserAccountFragment,
  AdminUsersCoinBalanceByUserQuery,
  AdminUsersCoinBalanceByUserQueryVariables,
} from "@tract/common/dist/graphql";
import { DELETE_USER_MUTATION } from "./graphql";
import { useSession } from "Services/session";

import { getDisplayName } from "Types/User";

type Props = {
  user: AdminDashboardUserAccountFragment;
};

const ADMIN_USERS_COIN_BALANCE_BY_USER = gql`
  query AdminUsersCoinBalanceByUser($userId: String!) {
    coin_trx(
      offset: 0
      where: { userId: { _eq: $userId } }
      order_by: { createdAt: desc }
      limit: 1
    ) {
      id
      userId
      balance
    }
  }
`;

export const LayoutUser: FC<PropsWithChildren<Props>> = ({
  user,
  children,
}) => {
  const toast = useToast();
  const history = useHistory();
  const { currentUser } = useSession();
  const isEditRoute = !!useRouteMatch("/admin/users/:userId/edit");
  const isAccountRoute = !!useRouteMatch("/admin/users/:userId/account");
  const isInvitesRoute = !!useRouteMatch("/admin/users/:userId/invites");
  const isPathsRoute = !!useRouteMatch("/admin/users/:userId/paths");
  const isCoinsRoute = !!useRouteMatch("/admin/users/:userId/coins");
  const isClassroomsRoute = !!useRouteMatch("/admin/users/:userId/classrooms");
  const isActivationRoute = !!useRouteMatch("/admin/users/:userId/activation");
  const displayName = user.username || `${user.firstName} ${user.lastName}`;
  const hasPrivacyEnabled =
    !!user.projectPrivacy || !!user.profilePrivacy || !!user.pathPrivacy;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleting, setDeleting] = useState(false);

  const [{ data, fetching, error }] = useQuery<
    AdminUsersCoinBalanceByUserQuery,
    AdminUsersCoinBalanceByUserQueryVariables
  >({
    requestPolicy: "network-only",
    query: ADMIN_USERS_COIN_BALANCE_BY_USER,
    variables: {
      userId: user.id,
    },
  });

  const [, deleteUser] = useMutation(DELETE_USER_MUTATION);

  const handleDeleteUser = async () => {
    if (deleting) return;

    if (user.id === currentUser.id) {
      toast({ status: "warning", title: "Cannot delete own user" });
      onClose();
      return;
    }

    setDeleting(true);

    try {
      await deleteUser({ userId: user.id });
      history.replace("/admin/users");
    } catch {
      toast({ title: "Error deleting user", status: "error" });
    } finally {
      setDeleting(false);
      onClose();
    }
  };

  const classes = user.learnerGroups.map((lg) => {
    return {
      id: lg.learnerGroup.id,
      name: lg.learnerGroup.name,
      teacher: lg.learnerGroup.members[0]?.user, // There is only 1 class owner
    };
  });

  return (
    <Flex direction={{ base: "column", lg: "row" }} flex="1 1 auto">
      <SideNav borderRight="none">
        <Box pt={4} px={6} pb={6}>
          <Avatar src={user.avatar || ""} size="xl" />
          <Text pt={2} fontSize="2xl" fontWeight="bold">
            {user.username || `${user.firstName} ${user.lastName}`}
          </Text>
          {user.organization && (
            <Text
              as={Link}
              to={`/explore/organization/${user.organization.id}`}
              color="gray.600"
            >
              {user.organization.name}
            </Text>
          )}
          {user.invitedBy && (
            <Text color="gray.600">
              Invited by{" "}
              <ButtonLink
                as={Link}
                colorScheme="brandFull"
                to={`/admin/users/${user.invitedBy.id}`}
              >
                {user.invitedBy.username}
              </ButtonLink>
            </Text>
          )}
          <Box mt={2}>
            {error ? (
              <Text fontSize="xs">Error loading coin balance</Text>
            ) : (
              <CoinValue
                value={data?.coin_trx?.[0]?.balance || 0}
                loading={fetching}
              />
            )}
          </Box>
          {!user.isEducator && classes?.length && (
            <Box my={2}>
              <>
                {classes.map((currentClass, index) => (
                  <Box key={index} mt={4}>
                    <Link to={`/class/${currentClass.id}`} fontWeight="bold">
                      {currentClass.name}
                    </Link>
                    {currentClass.teacher && (
                      <>
                        <br />
                        <Link
                          to={`/admin/users/${currentClass.teacher.firestoreId}`}
                        >
                          <Avatar
                            src={currentClass.teacher.avatar || ""}
                            size="xs"
                          />
                        </Link>{" "}
                        <Link
                          to={`/admin/users/${currentClass.teacher.firestoreId}`}
                        >
                          {getDisplayName(currentClass.teacher)}
                        </Link>
                      </>
                    )}
                  </Box>
                ))}
              </>
            </Box>
          )}
          {hasPrivacyEnabled && (
            <Box mt={2}>
              <Popover trigger="hover" isLazy>
                <PopoverTrigger>
                  <Badge colorScheme="purple">Privacy On</Badge>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                      <Text>
                        Profile:{" "}
                        {!user.profilePrivacy ? "public" : user.profilePrivacy}
                      </Text>
                      <Text>
                        Projects:{" "}
                        {!user.projectPrivacy ? "public" : user.projectPrivacy}
                      </Text>
                      <Text>
                        Paths: {!user.pathPrivacy ? "public" : user.pathPrivacy}
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Box>
          )}
        </Box>

        <VStack w="100%" align="stretch" spacing={2} p={2}>
          <SideNavLink
            as={Link}
            to={`/admin/users/${user.id}/edit`}
            isActive={isEditRoute}
          >
            Edit Profile
          </SideNavLink>
          <SideNavLink
            as={Link}
            to={`/admin/users/${user.id}/account`}
            isActive={isAccountRoute}
          >
            Account
          </SideNavLink>
          {user.isEducator ? (
            <>
              <SideNavLink
                as={Link}
                to={`/admin/users/${user.id}/classrooms`}
                isActive={isClassroomsRoute}
              >
                Classes
              </SideNavLink>
              <SideNavLink
                as={Link}
                to={`/admin/users/${user.id}/activation`}
                isActive={isActivationRoute}
              >
                Activation
              </SideNavLink>
            </>
          ) : (
            <>
              <SideNavLink
                as={Link}
                to={`/admin/users/${user.id}/invites`}
                isActive={isInvitesRoute}
              >
                Invites
              </SideNavLink>
              <SideNavLink
                as={Link}
                to={`/admin/users/${user.id}/paths`}
                isActive={isPathsRoute}
              >
                Paths
              </SideNavLink>
              <SideNavLink
                as={Link}
                to={`/admin/users/${user.id}/coins`}
                isActive={isCoinsRoute}
              >
                Coins
              </SideNavLink>
              <SideNavLink
                as={Link}
                to={`/admin/submissions?uid=${user.id}`}
                iconRight={<IconExternalLink size={20} />}
              >
                Submissions
              </SideNavLink>
              <SideNavLink
                as={Link}
                to={`/admin/comments?uid=${user.id}`}
                iconRight={<IconExternalLink size={20} />}
              >
                Comments
              </SideNavLink>
            </>
          )}
        </VStack>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && (
          <>
            <Divider mb={4} />
            <Box px={4}>
              <Button
                variant="link"
                colorScheme="red"
                w="full"
                onClick={onOpen}
              >
                Delete User
              </Button>
            </Box>
          </>
        )}
      </SideNav>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Delete {displayName}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>Are you sure you want to delete {displayName}?</ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button variant="outline" onClick={onClose} isDisabled={deleting}>
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="red"
                onClick={handleDeleteUser}
                isLoading={deleting}
              >
                Delete
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AdminContent>{children}</AdminContent>
    </Flex>
  );
};
