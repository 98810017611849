import React from "react";
import { ModalProps } from "@chakra-ui/modal";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Text,
  List,
  ListItem,
  Stack,
} from "Shared";

import { CoinValue } from "Components/CoinValue";

import { AwardFragment, useGetAwardsQuery } from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";

type Props = {
  amount: number;
  multiplier?: number;
};

export const EarnMoreModal: React.FC<Props & Omit<ModalProps, "children">> = ({
  amount,
  multiplier = 1,
  ...props
}) => {
  const { data, loading } = useGetAwardsQuery();
  const awards = data?.awards;

  if (loading) {
    return <LayoutCentered height="auto" isLoading />;
  }

  if (!awards) {
    return null;
  }

  return (
    <Modal size="lg" scrollBehavior="inside" isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Challenge Rewards</ModalHeader>
        <ModalBody pb={6}>
          <Text mb={6}>
            Earn Coins for submitting a valid challenge response. Earn bonus
            Coins by receiving an award. You'll have to take your time and put
            in extra effort, but there is no limit to how many Awards you can
            receive!
          </Text>
          <List>
            <ListItem
              borderBottom="1px solid"
              borderBottomColor="gray.200"
              py={3}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                align="center"
              >
                <Text>Submit a Valid Response</Text>
                <Text>
                  <CoinValue value={amount} multiplier={multiplier} showPlus />
                </Text>
              </Stack>
            </ListItem>
            {Object.values(awards)
              .sort((a, b) => a.cost - b.cost)
              .map((award, i) => (
                <AwardItem
                  key={award.id}
                  award={award}
                  isLastItem={Object.keys(awards).length === i + 1}
                />
              ))}
          </List>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const AwardItem: React.FC<{ award: AwardFragment; isLastItem: boolean }> = ({
  award,
  isLastItem,
}) => {
  return (
    <ListItem
      borderBottom={isLastItem ? "none" : "1px solid"}
      borderBottomColor="gray.200"
      py={3}
    >
      <Stack direction="row" justifyContent="space-between" align="center">
        <Stack direction="row" spacing={4} align="center">
          <img
            alt={`${award.name} Award`}
            src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${award.filePath}`}
            width="32px"
          />
          <Text>{award.name} Award</Text>
        </Stack>
        <CoinValue value={award.bonus} showPlus />
      </Stack>
    </ListItem>
  );
};
