import { gql } from "@apollo/client";

export const CURRENT_USER_LEARNER_GROUP_FRAGMENT = gql`
  fragment CurrentUserLearnerGroup on learner_group {
    id
    name
    type
    privacyScope
    members {
      learnerGroupId
      userId
      role
      isOwner
    }
    assignments {
      assignmentId
      learnerGroupId
      isFinished
    }
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUser on user {
    id: firestoreId
    uid: firestoreId
    avatar
    bio
    birthday
    country
    creatorLevel
    disabled
    email
    firstName
    interestAreas
    isEducator
    isMod
    lastName
    orgId
    subscriptionExempt
    userType
    username
    accomplishments
    profilePrivacy
    projectPrivacy
    pathPrivacy
    integrations {
      userId
      foreignId
      provider
    }
    organization {
      id: firestoreId
      avatar
      name
      verified
      shopEnabled
    }
    learnerGroups(
      where: { learnerGroup: { archivedAt: { _eq: "-infinity" } } }
      order_by: { createdAt: desc }
    ) {
      learnerGroupId
      userId
      role
      isOwner
      learnerGroup {
        ...CurrentUserLearnerGroup
      }
    }
    followers_aggregate {
      aggregate {
        count
      }
    }
    following_aggregate {
      aggregate {
        count
      }
    }
    invitedById
    teacher {
      id: userId
      lowerGradeLevel
      upperGradeLevel
      displayName
      subjects
    }
  }

  ${CURRENT_USER_LEARNER_GROUP_FRAGMENT}
`;

export const SESSION_USER_BY_ID_QUERY = gql`
  ${CURRENT_USER_FRAGMENT}

  query SessionUserById($userId: String!) {
    users: user(where: { firestoreId: { _eq: $userId } }) {
      ...CurrentUser
    }
  }
`;

export const SESSION_USER_UPDATE_MUTATION = gql`
  ${CURRENT_USER_FRAGMENT}
  mutation UpdateSessionUser(
    $id: String!
    $avatar: String!
    $bio: String!
    $interestAreas: jsonb!
    $country: String!
    $accomplishments: jsonb!
  ) {
    user: update_user_by_pk(
      pk_columns: { firestoreId: $id }
      _set: {
        avatar: $avatar
        bio: $bio
        interestAreas: $interestAreas
        country: $country
        accomplishments: $accomplishments
      }
    ) {
      ...CurrentUser
    }
  }
`;

export const SESSION_USER_TEACHER_INSERT_MUTATION = gql`
  mutation InsertUserTeacher($object: user_teacher_insert_input!) {
    insert_user_teacher_one(object: $object) {
      userId
      displayName
      lowerGradeLevel
      upperGradeLevel
      subjects
    }
  }
`;

export const SESSION_UPDATE_USER_TEACHER_MUTATION = gql`
  mutation UpdateUserTeacher(
    $userId: String!
    $displayName: String
    $lowerGradeLevel: String
    $upperGradeLevel: String
    $subjects: jsonb
  ) {
    update_user_teacher_by_pk(
      pk_columns: { userId: $userId }
      _set: {
        displayName: $displayName
        lowerGradeLevel: $lowerGradeLevel
        upperGradeLevel: $upperGradeLevel
        subjects: $subjects
      }
    ) {
      userId
      displayName
      lowerGradeLevel
      upperGradeLevel
      subjects
    }
  }
`;
