import React from "react";

import { Box, Button, Text, VStack } from "Shared";

import { GetAwardsQuery } from "@tract/common/dist/graphql";

type AwardQueryResult = GetAwardsQuery["awards"][0];

export const AwardButtonItem: React.FC<{
  award: AwardQueryResult;
  onClick: (award: AwardQueryResult) => void;
  isSelected?: boolean;
}> = ({ award, onClick, isSelected = false }) => {
  return (
    <Button
      p={4}
      display="block"
      variant="ghost"
      height="100%"
      borderRadius="lg"
      bg={isSelected ? "gray.100" : "transparent"}
      onClick={() => onClick(award)}
    >
      <VStack>
        <Box mb={2}>
          <img
            src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${award.filePath}`}
            alt={award.name}
            width="48px"
          />
        </Box>
        <Text fontSize="md" color="gray.600" fontWeight="normal">
          {award.cost.toLocaleString()}
        </Text>
      </VStack>
    </Button>
  );
};
