import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  CreateNodeCommentMutationResult,
  CreateNodeCommentMutationVariables,
  GetNodeCommentsQueryResult,
  GetNodeCommentsQueryVariables,
} from "@tract/common/dist/graphql";

import { COMMENTS_PER_PAGE } from "Pages/PathViewer/NodeComments/CommentList";
import { GET_NODE_COMMENTS_QUERY } from "Pages/PathViewer/NodeComments/graphql";

export const insert_path_node_comment_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  const variables = args as CreateNodeCommentMutationVariables;
  const path = variables.object.comment?.data.path as string;
  const pathSplit = path.split(".");
  const isReply = pathSplit.length > 1;
  const matchPath = { _matches: isReply ? `${pathSplit[0]}.*{1}` : "*{1}" };
  cache.updateQuery<
    GetNodeCommentsQueryResult["data"],
    GetNodeCommentsQueryVariables
  >(
    {
      query: GET_NODE_COMMENTS_QUERY,
      variables: {
        nodeId: variables.object.pathNodeId,
        limit: COMMENTS_PER_PAGE,
        offset: 0,
        path: matchPath,
      },
    },
    (data) => {
      const item = (result as CreateNodeCommentMutationResult["data"])
        ?.insert_path_node_comment_one;

      if (item) {
        data?.nodeComments.unshift({
          ...item,
        });
      }
      return data;
    }
  );
};
