import React from "react";

import { Text, Link, useBreakpointValue } from "Shared";

import { HScrollContainer } from "./HScrollContainer";

import { captureException } from "Services/errors";

import {
  HomeLivestreamSectionQuery,
  HomeLivestreamSectionQueryVariables,
} from "@tract/common/dist/graphql";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";
import { HOME_LIVESTREAM_SECTION_QUERY } from "./graphql";
import { useQuery } from "urql";
import { LivestreamCard } from "Components/LivestreamCard";
import { ViewSource } from "Types/Livestream";

export const LivestreamSection: React.FC = () => {
  const [{ data, fetching, error }] = useQuery<
    HomeLivestreamSectionQuery,
    HomeLivestreamSectionQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: HOME_LIVESTREAM_SECTION_QUERY,
    variables: {
      limit: 6,
      startDate: "now()",
    },
  });

  if (error) {
    captureException(error);
  }

  const livestreams = data?.livestreams;

  const numCards = useBreakpointValue({ base: 6, lg: 3, xl: 4 });

  const renderTitle = () => {
    return (
      <>
        <Text as={Link} to="/events" color="gray.900">
          Upcoming Events
        </Text>
        <Link fontSize="lg" to="/events" color="brand" tabIndex={-1}>
          See more
        </Link>
      </>
    );
  };

  if (fetching) {
    return (
      <HScrollContainer renderTitle={renderTitle}>
        {Array.from({ length: numCards || 4 }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </HScrollContainer>
    );
  }

  if (!livestreams || (livestreams && livestreams.length < 1)) {
    return null;
  }

  const templateColumns = {
    base: `repeat(${Math.min(livestreams.length, 6)}, 256px)`,
    lg: "repeat(3, minmax(0,1fr))",
    xl: "repeat(4, minmax(0,1fr))",
  };

  return (
    <HScrollContainer
      renderTitle={renderTitle}
      templateColumns={templateColumns}
    >
      {livestreams
        .filter((_, i) => i < (numCards || 4))
        .map((livestream) => (
          <LivestreamCard
            key={livestream.id}
            livestream={livestream}
            viewSource={ViewSource.Home}
          />
        ))}
    </HScrollContainer>
  );
};
