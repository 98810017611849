import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  PathViewerNodeFragment,
  PathViewerPathFragment,
  useCreateProjectMutation,
} from "@tract/common/dist/graphql";
import {
  Box,
  Button,
  ButtonSolid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "Shared";
import { CoinValue } from "Components/CoinValue";
import { EarnMoreModal } from "Components/EarnMoreModal";
import { ChallengeFileField } from "./ChallengeFileField";

import { useSession } from "Services/session";
import { useCoins } from "Services/hooks/useCoins";
import { captureException, captureMessage } from "Services/errors";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { FileCaptureMethod } from "@tract/common/dist/types/models/Submission";

import { usePathRoute } from "./usePathRoute";
import { isTeacherUser } from "Types/User";
import { PATH_VIEWER_NODE_FRAGMENT } from "./graphql";

interface FormValues {
  file?: {
    fileId: string;
    filename: string;
    fileCaptureMethod: FileCaptureMethod;
    type: "image" | "video";
  };
  text?: string;
  isPrivate: boolean;
}

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
  onChallengeCompleted: () => void;
};

export const ChallengeCard: React.FC<Props> = ({
  path,
  node,
  onChallengeCompleted,
}) => {
  const toast = useToast();
  const { track } = useAnalytics();
  const { isViewOnlyMode } = usePathRoute(path);
  const { updateBalance } = useCoins();
  const { currentUser } = useSession();
  const [createProject] = useCreateProjectMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isRejectionModalVisible,
    onClose: closeRejectionModal,
    onOpen: showRejectModal,
  } = useDisclosure();
  const [spamCooldown, setSpamCooldown] = useState("3");
  const [hasFileError, setFileError] = useState(false);
  const isTeacher = isTeacherUser(currentUser);

  const handleUploadDone = (error?: Error) => {
    setFileError(!!error);
  };

  const onSubmit = async (values: FormValues) => {
    if (isViewOnlyMode) {
      toast({
        status: "error",
        title: "Only Learners Can Submit Challenges",
      });
      return;
    }

    let projectId;
    const isFileResponse = node.responsesEnabled;
    const fileId = values.file?.fileId;
    const filename = values.file?.filename;

    // Check for valid file if file response
    if (isFileResponse && (!fileId || !filename)) {
      captureMessage("Challenge file response is missing file", {
        extra: {
          nodeId: node.id,
        },
      });

      toast({
        status: "error",
        title: "Error submitting project, invalid file",
      });

      return;
    }

    try {
      const { data } = await createProject({
        variables: {
          input: {
            isPrivate: values.isPrivate,
            nodeId: node.id,
            ...(values.file &&
              filename && {
                file: {
                  id: values.file.fileId,
                  filename: values.file.filename,
                  fileCaptureMethod: values.file.fileCaptureMethod,
                },
              }),
          },
        },
        update(cache, { data }) {
          if (data?.project) {
            const { project } = data;
            cache.writeFragment<PathViewerNodeFragment, { userId: string }>({
              id: cache.identify(node),
              fragment: PATH_VIEWER_NODE_FRAGMENT,
              fragmentName: "PathViewerNode",
              variables: {
                userId: currentUser.uid,
              },
              data: {
                ...node,
                projects: [
                  {
                    __typename: "project",
                    id: project.id,
                    pathNodeId: node.id,
                    userId: currentUser.uid,
                    createdAt: new Date().toISOString(),
                    text: project.text,
                    ...(project.file && {
                      file: {
                        __typename: "user_file",
                        id: project.file?.id,
                        userId: currentUser.uid,
                        filename: project.file?.filename,
                      },
                    }),
                  },
                ],
              },
            });
          }
        },
      });
      projectId = data?.project?.id;
      if (!projectId) {
        throw new Error("Invalid response from createProject");
      }

      track(ANALYTICS_EVENTS.CHALLENGE_SUBMITTED, {
        pathId: path?.id,
        pathSlug: path?.slug,
        pathTitle: path?.title,
        pathAuthorId: path?.authorId,
        pathNodeTitle: node.title,
        pathNodeId: node.id,
        rewardAmount: node.rewardAmount,
        responseType: "file",
        isPrivate: values.isPrivate,
        ...(isFileResponse && {
          fileCaptureMethod:
            values.file?.fileCaptureMethod || FileCaptureMethod.Unknown,
        }),
        projectId: projectId,
        pathAuthorUsername: path?.user?.username,
        pathAuthorCreatorLevel: path?.user?.creatorLevel,
      });
    } catch (err: any) {
      if (
        typeof err.message === "string" &&
        err.message.startsWith("spam rate limit")
      ) {
        setSpamCooldown(err.message.split(";")[1].split(":")[1]);
        track(ANALYTICS_EVENTS.SUBMISSION_THROTTLED, {
          pathId: path.id,
          pathNodeId: node.id,
        });

        showRejectModal();

        return;
      }

      captureException(err);
      toast({
        status: "error",
        title: "Error submitting project",
      });
      return;
    }

    updateBalance(node.rewardAmount * path.xpMultiplier);
    onChallengeCompleted();
  };

  const project = node?.projects?.[0];

  const initialValues: FormValues = {
    isPrivate: isTeacher,
    text: project?.text || "",
  };

  const validate = async (values: FormValues) => {
    const errors: any = {};

    if (!values.file) {
      errors.file = "A file is required";
    }

    return errors;
  };

  if (!path) return null;

  return (
    <Box>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(props) => (
          <Form>
            <ChallengeFileField
              path={path}
              node={node}
              project={project}
              onUploadDone={handleUploadDone}
            />
            {!project && (
              <HStack mt={10} spacing={4}>
                <Button
                  type="submit"
                  size="lg"
                  colorScheme="brandFull"
                  isLoading={props.isSubmitting}
                  disabled={
                    !props.isValid ||
                    props.isSubmitting ||
                    isViewOnlyMode ||
                    hasFileError
                  }
                >
                  Submit to Earn{" "}
                  <CoinValue
                    size="md"
                    value={node.rewardAmount}
                    multiplier={path.xpMultiplier}
                    ml={2}
                  />
                </Button>
                <Button
                  variant="link"
                  fontWeight="bold"
                  colorScheme="brandFull"
                  onClick={onOpen}
                >
                  Earn More
                </Button>
              </HStack>
            )}
          </Form>
        )}
      </Formik>

      <EarnMoreModal
        amount={node.rewardAmount}
        multiplier={path.xpMultiplier}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
        size="2xl"
      />

      <SubmissionRejectedModal
        isOpen={isRejectionModalVisible}
        onClose={closeRejectionModal}
        cooldownMin={spamCooldown}
      />
    </Box>
  );
};

const SubmissionRejectedModal = ({
  isOpen,
  onClose,
  cooldownMin,
}: {
  isOpen: boolean;
  onClose: () => void;
  cooldownMin: string;
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={16}>
        <ModalHeader
          fontSize="2xl"
          color="gray.900"
          textAlign="center"
          fontWeight="bold"
        >
          Submission Rejected
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="md" color="gray.900">
            It looks like you may be rushing through videos and challenges. You
            can earn more Coins by taking your time and submitting quality work.
            You'll be able to try again in {cooldownMin} minutes.
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonSolid onClick={onClose}>Got It</ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
