import { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { forwardRef } from "@chakra-ui/system";
import {
  PathViewerNodeFragment,
  PathViewerPathFragment,
} from "@tract/common/dist/graphql";

import {
  AspectRatio,
  Box,
  Center,
  Flex,
  FontAwesomeIcon,
  IconImage,
  Link,
  Text,
} from "Shared";
import { UserFile } from "Utils/UserFile";
import { usePathRoute } from "./usePathRoute";
import { CoinValue } from "Components/CoinValue";
import { PathViewerPlaylistContext } from ".";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
  isActive?: boolean;
};

export const PlaylistItemNode = forwardRef<Props, "div">(
  ({ path, node, isActive, ...props }, ref) => {
    const { params, baseUrl } = usePathRoute(path);
    const { pathname, state } = useLocation<{ disableScroll?: boolean }>();
    const videoFile = new UserFile(node.videoFile);
    const thumbnailURL = videoFile.getTransformedUrl("video_cover_sm");
    const { showMobileNav, setShowMobileNav } = useContext(
      PathViewerPlaylistContext
    );

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        params.nodeId === node.id &&
        state?.disableScroll !== true &&
        scrollRef.current
      ) {
        const elemOffset: number = scrollRef.current.offsetTop;
        const containerElem = document.getElementById(
          "path-viewer-sidenav"
        ) as HTMLDivElement;

        if (containerElem) {
          containerElem.scrollTop = elemOffset - 16;
        }
      }
    }, [pathname, state, params.nodeId, node.id, scrollRef]);

    return (
      <Box
        as={Link}
        display="block"
        to={`${baseUrl}/v/${node.id}`}
        _hover={{
          textDecoration: "none",
          color: "brandFull.500",
          bg: "brandFull.50",
        }}
        transition="150ms ease background-color, 150ms ease box-shadow"
        ref={scrollRef}
        onClick={() => showMobileNav && setShowMobileNav.off()}
        p={3}
        borderRadius="2xl"
        boxShadow={isActive ? "outlineInset" : undefined}
      >
        <Flex
          h="full"
          alignItems="center"
          {...props}
          ref={ref}
          borderRadius="2xl"
        >
          {thumbnailURL && (
            <AspectRatio
              ratio={16 / 9}
              w={{ base: "calc(4rem * (16 / 9))", lg: "8rem" }}
              minW={{ base: "calc(4rem * (16 / 9))", lg: "8rem" }}
              mr={3}
            >
              <Center
                borderRadius="lg"
                bgImage={thumbnailURL}
                bgSize="cover"
                bgColor="gray.200"
                color="white"
              >
                {isActive && <FontAwesomeIcon icon={solid("play")} size={8} />}
              </Center>
            </AspectRatio>
          )}
          {!thumbnailURL && !node.responsesEnabled ? (
            <AspectRatio
              ratio={16 / 9}
              w={{ base: "calc(4rem * (16 / 9))", lg: "8rem" }}
              minW={{ base: "calc(4rem * (16 / 9))", lg: "8rem" }}
              mr={3}
            >
              <Center borderRadius="lg" bg="gray.100">
                <IconImage color="gray.300" />
              </Center>
            </AspectRatio>
          ) : null}
          <Box overflow="hidden">
            <Text fontWeight="bold" fontSize="md" noOfLines={2}>
              {node.title || "Untitled Content"}
            </Text>
            {node.responsesEnabled && node.rewardAmount > 0 && (
              <CoinValue
                value={node.rewardAmount}
                multiplier={path.xpMultiplier}
              />
            )}
          </Box>
        </Flex>
      </Box>
    );
  }
);
