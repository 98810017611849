import {
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams, useLocation } from "react-router";

import {
  useToast,
  Box,
  Flex,
  HStack,
  IconButton,
  IconPlus,
  Divider,
  IconView,
  Button,
  Link,
  Text,
  Tooltip,
  Editable,
  EditableInput,
  EditablePreview,
  Grid,
  AspectRatio,
  Spinner,
  Image,
  IconBack,
  IconImage,
  IconCheck,
  IconAlertTriangle,
  IconMonitor,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  VStack,
  useRadioGroup,
  IconBookOpen,
  useDisclosure,
  ModalCloseButton,
  useMediaQuery,
  useTheme,
} from "Shared";
import { TopNavActionsEnd, TopNav } from "Components/TopNav";
import { LayoutCentered } from "Components/LayoutCentered";

import { PathStatus } from "@tract/common/dist/types/models/Path";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";

import { createContext } from "Utils";
import { PathStatusBadgeV2 } from "Components/PathStatusBadge";
import { UNTITLTED_PATH_NAME } from "Constants/paths";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { PathActions } from "./PathActions";
import { OperationResult, useMutation, useQuery } from "urql";
import {
  ADD_NODE_VIDEO_FILE,
  DELETE_NODE_VIDEO_FILE,
  DELETE_PATH_NODE_MUTATION,
  GET_PATH_WITH_NODES_QUERY,
  UPDATE_PATH_NODE_MUTATION,
} from "./graphql";
import {
  AddNodeVideoFileMutation,
  AddNodeVideoFileMutationVariables,
  CreatePathNodeMutation,
  CreatePathNodeMutationVariables,
  DeleteNodeVideoFileMutation,
  DeleteNodeVideoFileMutationVariables,
  DeletePathNodeMutation,
  DeletePathNodeMutationVariables,
  GetPathWithNodesQueryResult,
  GetPathWithNodesQueryVariables,
  PathBuilderNodeFragment,
  PathBuilderPathV4Fragment,
  SavePathTitleMutation,
  SavePathTitleMutationVariables,
  UpdatePathNodeMutation,
  UpdatePathNodeMutationVariables,
} from "@tract/common/dist/graphql";
import { SAVE_PATH_TITLE_MUTATION } from "Pages/CreateV4/graphql";
import { UserFile } from "Utils/UserFile";
import { CREATE_PATH_NODE_MUTATION } from "Pages/Author/graphql";
import { LexoRank } from "lexorank";
import { calculateNewLexorank } from "Utils/lexorank";
import { useInterval } from "@tract/common/dist/hooks";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { RadioCard } from "Components/RadioCard";
import { ReviewRequestCelebrateModal } from "./ReviewRequestCelebrateModal";
import { CoinIcon } from "Components/CoinIcon";

export enum PathResponseRewards {
  Standard = 15,
  Double = 30,
  Capstone = 60,
}

export type linkState = { sourcePath?: string; sourceLabel?: string };

export const [_, usePathV4Context, PathV4Context] = createContext<{
  path: PathBuilderPathV4Fragment;
  activeNode?: PathBuilderNodeFragment;
  refreshPath: () => void;
  updatePathNodeMutation: (
    variables: UpdatePathNodeMutationVariables
  ) => Promise<
    OperationResult<UpdatePathNodeMutation, UpdatePathNodeMutationVariables>
  >;
  deletePathNodeMutation: (
    variables: DeletePathNodeMutationVariables
  ) => Promise<
    OperationResult<DeletePathNodeMutation, DeletePathNodeMutationVariables>
  >;
  addNodeVideoFileMutation: (
    variables: AddNodeVideoFileMutationVariables
  ) => Promise<
    OperationResult<AddNodeVideoFileMutation, AddNodeVideoFileMutationVariables>
  >;
  deleteNodeVideoFileMutation: (
    variables: DeleteNodeVideoFileMutationVariables
  ) => Promise<
    OperationResult<
      DeleteNodeVideoFileMutation,
      DeleteNodeVideoFileMutationVariables
    >
  >;
  setDetailsSaved: React.Dispatch<SetStateAction<boolean>>;
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<SetStateAction<boolean>>;
  openRequestReviewCelebrateModal: () => void;
  videoFileUploadingOrDeleting: boolean;
  setVideoFileUploadingOrDeleting: React.Dispatch<SetStateAction<boolean>>;
  autosaveFailed: boolean;
  setAutosaveFailed: React.Dispatch<SetStateAction<boolean>>;
}>({
  strict: true,
  errorMessage: "PathV4Context must be used inside PathV4Provider",
  name: "PathProviderV4",
});

export const LayoutCreateV4: FC<PropsWithChildren> = ({ children }) => {
  const toast = useToast();
  const history = useHistory();
  const { track } = useAnalytics();
  const { currentUser, isAdmin } = useSession();
  const { state } = useLocation<linkState>();
  const [nodes, setNodes] = useState<PathBuilderNodeFragment[]>([]);
  const {
    isOpen: isPreAddNodeModalOpen,
    onClose: closePreAddNodeModal,
    onOpen: openPreAddNodeModal,
  } = useDisclosure();
  const {
    isOpen: isRequestReviewCelebrateModalOpen,
    onOpen: openRequestReviewCelebrateModal,
    onClose: closeRequestReviewCelebrateModal,
  } = useDisclosure();
  const { pathId, nodeId } = useParams<{
    pathId: string;
    nodeId?: string;
  }>();

  const [{ data, fetching: loading }, refetch] = useQuery<
    GetPathWithNodesQueryResult["data"],
    GetPathWithNodesQueryVariables
  >({
    query: GET_PATH_WITH_NODES_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      pathId: pathId,
    },
  });
  const path = data?.path;

  useEffect(() => {
    if (path && path.nodes.length === 0) {
      openPreAddNodeModal();
    }
  }, [path, openPreAddNodeModal]);

  const refreshPath = () => {
    refetch();
  };

  const [pathTitle, setPathTitle] = useState<string | null | undefined>();
  const [titleEditorDirty, setTitleEditorDirty] = useState(false);
  const onChangeEditable = (nextValue: string) => {
    setTitleEditorDirty(true);
    setPathTitle(nextValue);
  };

  useEffect(() => {
    if (!path) return;

    if (!titleEditorDirty) {
      setPathTitle(path.title);
    }
  }, [path, titleEditorDirty]);

  useEffect(() => {
    if (!path?.nodes?.length) return;
    setNodes(path.nodes);
  }, [path?.nodes]);

  const [activeNode, setActiveNode] = useState<
    PathBuilderNodeFragment | undefined
  >();
  const [detailsSaved, setDetailsSaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [autosaveFailed, setAutosaveFailed] = useState(false);
  const [videoFileUploadingOrDeleting, setVideoFileUploadingOrDeleting] =
    useState(false);
  useEffect(() => {
    const filteredNodes = path?.nodes?.filter(
      (innerNode) => innerNode.id === nodeId
    );
    const node = filteredNodes?.length ? filteredNodes[0] : undefined;
    if (!!node) {
      setActiveNode(node);
    }
  }, [nodeId, path?.nodes]);

  const [{ fetching: savingPathTitle }, savePathTitleMutation] = useMutation<
    SavePathTitleMutation,
    SavePathTitleMutationVariables
  >(SAVE_PATH_TITLE_MUTATION);

  const onPathTitleSave = async (pathTitle: string) => {
    if (!pathTitle || pathTitle === path?.title) {
      setPathTitle(path?.title);
      return;
    }

    const mutationResult = await savePathTitleMutation({
      pathId: pathId,
      pathTitle: pathTitle,
    });

    if (!mutationResult.error) {
      track(ANALYTICS_EVENTS.PATH_TITLE_EDITED, {
        pathId,
        pathTitle,
        pathAuthorId: path?.authorId,
        pathAuthorUsername: path?.user?.username,
        pathAuthorCreatorLevel: path?.user?.creatorLevel,
      });
      setTitleEditorDirty(false);
      setDetailsSaved(true);
    } else {
      captureException(mutationResult.error);
      toast({
        title: "Error while saving title",
        status: "error",
      });
    }
  };

  const [{ fetching: addingPathNode }, createPathNodeMutation] = useMutation<
    CreatePathNodeMutation,
    CreatePathNodeMutationVariables
  >(CREATE_PATH_NODE_MUTATION);

  const [{ fetching: savingPathNode }, updatePathNodeMutation] = useMutation<
    UpdatePathNodeMutation,
    UpdatePathNodeMutationVariables
  >(UPDATE_PATH_NODE_MUTATION);

  const [{ fetching: deletingPathNode }, deletePathNodeMutation] = useMutation<
    DeletePathNodeMutation,
    DeletePathNodeMutationVariables
  >(DELETE_PATH_NODE_MUTATION);

  const [{ fetching: addingNodeVideoFile }, addNodeVideoFileMutation] =
    useMutation<AddNodeVideoFileMutation, AddNodeVideoFileMutationVariables>(
      ADD_NODE_VIDEO_FILE
    );

  const [{ fetching: deletingNodeVideoFile }, deleteNodeVideoFileMutation] =
    useMutation<
      DeleteNodeVideoFileMutation,
      DeleteNodeVideoFileMutationVariables
    >(DELETE_NODE_VIDEO_FILE);

  const onAddPathNode = async (responsesEnabled?: boolean) => {
    try {
      const previousLexorank = path?.nodes[path?.nodes.length - 1]?.lexorank;
      const lexorank = !!previousLexorank
        ? LexoRank.parse(previousLexorank).genNext()
        : LexoRank.middle();
      const pathNodeMutationResult = await createPathNodeMutation({
        node: {
          pathId: pathId,
          lexorank: lexorank.format(),
          responsesEnabled: responsesEnabled || false,
          rewardAmount: PathResponseRewards.Standard,
        },
      });
      const newNodeId = pathNodeMutationResult.data?.insert_path_node_one?.id;
      if (!pathNodeMutationResult.data?.insert_path_node_one?.id) {
        throw new Error("Invalid response from create node mutation");
      }
      history.push({
        pathname: `/create/path/${pathId}/v/${newNodeId}/details`,
        state: state,
      });

      setDetailsSaved(true);
      track(ANALYTICS_EVENTS.NODE_CREATED, {
        pathId: pathId,
        pathAuthorId: path?.authorId,
        pathAuthorUsername: path?.user?.username,
        pathAuthorCreatorLevel: path?.user?.creatorLevel,
        pathTitle: path?.title,
        pathSlug: path?.slug,
        nodeId: newNodeId,
        pathNodesCount: (path?.nodes?.length || 0) + 1,
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Something went wrong :(",
        status: "error",
      });
    }
  };

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list or empty list
    if (!result.destination || !nodes?.length) {
      return;
    }

    const nodeMoving = nodes[result.source.index];
    const lexorankBeforeShift = nodeMoving.lexorank;

    if (!nodeMoving) return;

    const newLexoRank = calculateNewLexorank(
      nodes,
      result.source.index,
      result.destination.index
    );

    //Reorder nodes in state for instant feedback
    setNodes((oldNodes) =>
      oldNodes.map((n) => {
        if (n.id === nodes[result.source.index].id) {
          return { ...n, lexorank: newLexoRank };
        }
        return n;
      })
    );

    //Write new lexorank of node to db
    const pathNodeMutationResult = await updatePathNodeMutation({
      nodeId: nodeMoving?.id,
      node: {
        lexorank: newLexoRank,
      },
    });
    if (!!pathNodeMutationResult.error) {
      //Restore prior node order
      setNodes((oldNodes) =>
        oldNodes.map((n) => {
          if (n.id === nodes[result.source.index].id) {
            return { ...n, lexorank: lexorankBeforeShift };
          }
          return n;
        })
      );
      captureException(pathNodeMutationResult.error);
      toast({
        title: "Error adjusting video order",
        status: "error",
      });
    } else {
      setDetailsSaved(true);
    }
  };
  const { breakpoints } = useTheme();

  const [unsuportedScreenSize] = useMediaQuery(
    `(max-width: ${breakpoints.lg})`
  );

  const saving =
    savingPathNode ||
    savingPathTitle ||
    addingPathNode ||
    deletingPathNode ||
    addingNodeVideoFile ||
    deletingNodeVideoFile ||
    videoFileUploadingOrDeleting;
  const dirty = unsavedChanges || titleEditorDirty;

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  if (!path || (path.authorId !== currentUser.uid && !isAdmin)) {
    return (
      <LayoutCentered direction="column">
        <Text fontWeight="bold" fontSize="8xl" color="gray.300">
          404
        </Text>
        <Text fontWeight="bold" fontSize="4xl" color="gray.600">
          Sorry, the path does not exist
        </Text>
      </LayoutCentered>
    );
  }

  const onCloseAddNodeModal = (contentType: string) => {
    onAddPathNode(contentType === "challenge");
    closePreAddNodeModal();
  };

  return (
    <PathV4Context.Provider
      value={{
        path,
        activeNode,
        refreshPath,
        updatePathNodeMutation,
        deletePathNodeMutation,
        addNodeVideoFileMutation,
        deleteNodeVideoFileMutation,
        setDetailsSaved,
        unsavedChanges,
        setUnsavedChanges,
        openRequestReviewCelebrateModal,
        videoFileUploadingOrDeleting,
        setVideoFileUploadingOrDeleting,
        autosaveFailed,
        setAutosaveFailed,
      }}
    >
      <Flex direction="column" w="100%">
        <TopNav
          renderStart={
            <Flex align="center" ml={4} mr={6} overflow="hidden">
              {!!state?.sourcePath && (
                <Tooltip label={state?.sourceLabel} hasArrow>
                  <IconButton
                    as={Link}
                    to={state?.sourcePath}
                    variant="ghost"
                    icon={<IconBack />}
                    aria-label={state?.sourceLabel || "Back"}
                    mr={2}
                  />
                </Tooltip>
              )}
              <Link
                to="/author/drafts"
                fontSize="lg"
                _hover={{ textDecoration: "none", color: "brand" }}
              >
                Create
              </Link>
              <Text fontSize="lg" color="gray.600" ml={4} mr={2}>
                /
              </Text>
              {unsuportedScreenSize ? (
                <Text
                  noOfLines={1}
                  fontSize="lg"
                  color="gray.600"
                  ml={4}
                  mr={2}
                >
                  {pathTitle}
                </Text>
              ) : (
                <>
                  <Editable
                    display="flex"
                    flexDirection="row"
                    placeholder={UNTITLTED_PATH_NAME}
                    mr={6}
                    value={
                      !pathTitle || pathTitle === UNTITLTED_PATH_NAME
                        ? ""
                        : pathTitle
                    }
                    onChange={onChangeEditable}
                    onSubmit={onPathTitleSave}
                    submitOnBlur={true}
                    selectAllOnFocus={false}
                    fontSize="lg"
                  >
                    <EditablePreview _hover={{ bg: "gray.100" }} px={2} />
                    <EditableInput w="30rem" textAlign="start" px={2} />
                  </Editable>
                  {autosaveFailed && !saving && (
                    <HStack>
                      <IconAlertTriangle color="gray.600" size={20} />
                      <Text color="gray.600" fontSize="md">
                        Autosave failed
                      </Text>
                    </HStack>
                  )}
                  {saving && (
                    <HStack>
                      <Spinner
                        emptyColor="gray.200"
                        color="gray.400"
                        size="sm"
                        speed="1s"
                      />
                      <Text fontSize="md" fontWeight="bold" color="gray.600">
                        Saving...
                      </Text>
                    </HStack>
                  )}
                  {!dirty && detailsSaved && !saving && !autosaveFailed && (
                    <HStack>
                      <IconCheck color="green.600" size={20} />
                      <Text color="green.600" fontWeight="bold" fontSize="md">
                        Saved
                      </Text>
                    </HStack>
                  )}
                </>
              )}
            </Flex>
          }
          renderEnd={
            <HStack spacing={4} mr={4}>
              {!unsuportedScreenSize && (
                <HStack spacing={3}>
                  <PathStatusBadgeV2 path={path} />
                  {path.nodes.length > 0 &&
                    path.status !== PathStatus.Published && (
                      <Tooltip label="Preview" hasArrow placement="bottom">
                        <IconButton
                          as={Link}
                          to={`/path/preview/${pathId}`}
                          target="_blank"
                          variant="ghost"
                          aria-label="Preview Path"
                          icon={<IconView />}
                        />
                      </Tooltip>
                    )}
                  {path.nodes.length > 0 &&
                    path.status === PathStatus.Published && (
                      <Tooltip label="View" hasArrow placement="bottom">
                        <IconButton
                          as={Link}
                          to={`/path/${path.slug}`}
                          target="_blank"
                          variant="ghost"
                          aria-label="View Path"
                          icon={<IconView />}
                        />
                      </Tooltip>
                    )}
                  <PathActions path={path} />
                </HStack>
              )}
              <Divider orientation="vertical" h="2.5rem" />
              <TopNavActionsEnd
                isCollapsed
                hideUserRow={unsuportedScreenSize}
              />
            </HStack>
          }
        />
        <Flex
          id="main"
          as="main"
          direction={{ base: "column", lg: "row" }}
          flex="1 1 auto"
          position="relative"
          bg="gray.50"
        >
          <Flex
            as="aside"
            direction="column"
            position="sticky"
            top="4rem"
            bottom={0}
            left={0}
            zIndex={10}
            py={3}
            height="calc(100vh - 4rem)"
            display={{ base: "none", lg: "flex" }}
            overflowY="auto"
            overflowX="hidden"
            w="9rem"
            minW="9rem"
          >
            <Flex direction="column" flex={1}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {nodes
                        .sort((a, b) => a.lexorank.localeCompare(b.lexorank))
                        .map((node, index) => {
                          return (
                            <Draggable
                              key={node.id}
                              draggableId={node.id}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <NodeThumbnail
                                    key={node.id}
                                    node={node}
                                    state={state}
                                  />
                                </Box>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
              {path.status !== PathStatus.Published && (
                <>
                  <Box mt={2} mb={4} pl={4} pr={6}>
                    <Divider width="full" />
                  </Box>
                  <Box pl={4} pr={6}>
                    <AspectRatio ratio={16 / 9}>
                      <Button
                        width="full"
                        height="full"
                        borderRadius="xl"
                        overflow="hidden"
                        bg="white"
                        _hover={{ bg: "white", boxShadow: "outline" }}
                        isLoading={addingPathNode}
                        disabled={addingPathNode}
                        border="1px solid"
                        borderColor="gray.300"
                        onClick={openPreAddNodeModal}
                      >
                        <Box w={6}>
                          <IconPlus color="gray.500" />
                        </Box>
                      </Button>
                    </AspectRatio>
                  </Box>
                </>
              )}
            </Flex>
          </Flex>
          {path && children}
        </Flex>
        <PreAddNodeModal
          enableClose={path.nodes.length > 0}
          isOpen={isPreAddNodeModalOpen}
          onClose={closePreAddNodeModal}
          onSelectType={onCloseAddNodeModal}
        />
        <Modal size="full" isOpen={unsuportedScreenSize} onClose={() => null}>
          <ModalContent>
            <ModalBody as={Center} bg="gray.50">
              <Flex direction="column" align="center" maxW="32.5rem" mx={6}>
                <IconMonitor color="gray.500" w={16} h={16} mb={6} />
                <Text color="gray.600" fontWeight="bold" fontSize="2xl" mb={1}>
                  Screen size not supported
                </Text>
                <Text fontSize="md" color="gray.600" textAlign="center">
                  A minimum screen width of 992px is required to edit Learning
                  Paths. Try resizing the window or use a different device.
                </Text>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <ReviewRequestCelebrateModal
        isOpen={isRequestReviewCelebrateModalOpen}
        onClose={closeRequestReviewCelebrateModal}
      />
    </PathV4Context.Provider>
  );
};

type Props = {
  node: PathBuilderNodeFragment;
  state: linkState;
};

const NodeThumbnail: FC<Props> = ({ node, state }) => {
  const { nodeId } = useParams<{
    nodeId?: string;
  }>();
  const { refreshPath, setUnsavedChanges, setDetailsSaved } =
    usePathV4Context();
  const [isOpen, setIsOpen] = useState(false);

  const [interval, setInterval] = useState<number | null>(null);
  const [intervalStartDate, setIntervalStartDate] = useState<Date | null>(null);
  useInterval(() => {
    if (!intervalStartDate) return;

    //End poll after 5 minutes
    if (
      new Date().getTime() - new Date(intervalStartDate).getTime() >
      5 * 60 * 1000
    ) {
      setInterval(null);
    }
    refreshPath();
    if (!!node?.videoFile?.transforms?.length) {
      setIntervalStartDate(null);
      setInterval(null);
    }
  }, interval);

  useEffect(() => {
    setIsOpen(nodeId === node.id);
  }, [nodeId, setIsOpen, node?.id]);

  useEffect(() => {
    if (!!node?.videoFile?.filename && !node?.videoFile?.transforms?.length) {
      setIntervalStartDate(new Date());
      setInterval(5 * 1000);
    }
  }, [node?.videoFile?.filename, node?.videoFile?.transforms?.length]);

  const videoFile = new UserFile(node.videoFile);
  const thumbUrl = videoFile?.getTransformedUrl("video_cover_sm");

  const clearSavingStates = () => {
    setUnsavedChanges(false);
    setDetailsSaved(false);
  };

  return (
    <Box width="9rem" role="group" position="relative">
      {isOpen && (
        <Box
          position="absolute"
          bg="brand"
          w={1}
          left={0}
          top={3}
          bottom={3}
          borderRightRadius={4}
        />
      )}
      <Link
        display="block"
        py={2}
        pl={4}
        pr={6}
        boxShadow="none"
        cursor="grab"
        outline="none"
        _focusVisible={{ boxShadow: "outlineInset" }}
        onClick={clearSavingStates}
        to={{
          pathname: `/create/path/${node.pathId}/v/${node.id}/details`,
          state: state,
        }}
      >
        <AspectRatio
          ratio={16 / 9}
          borderRadius="xl"
          overflow="hidden"
          bg="gray.200"
          transition="150ms ease all"
          cursor="pointer"
          _groupHover={{ boxShadow: "outline" }}
        >
          {!!thumbUrl.length ? (
            <Image objectFit="cover" src={thumbUrl} />
          ) : (
            <Box w={6}>
              {!!interval ? (
                <Spinner
                  thickness="3px"
                  speed="2s"
                  emptyColor="gray.300"
                  color="gray.500"
                />
              ) : (
                <IconImage color="gray.400" />
              )}
            </Box>
          )}
        </AspectRatio>
      </Link>
    </Box>
  );
};

const PreAddNodeModal: React.FC<{
  isOpen: boolean;
  enableClose?: boolean;
  onClose: () => void;
  onSelectType: (contentType: string) => void;
}> = ({ isOpen, enableClose = false, onClose, onSelectType }) => (
  <Modal
    isCentered
    size="2xl"
    onClose={onClose}
    isOpen={isOpen}
    closeOnOverlayClick={enableClose}
    closeOnEsc={enableClose}
  >
    <ModalOverlay />
    <ModalContent>
      {enableClose && <ModalCloseButton />}
      <ModalHeader>
        <Text fontSize="2xl" fontWeight="bold" lineHeight={1.4}>
          Add Content
        </Text>
      </ModalHeader>
      <ModalBody>
        <Box mt={4} mb={10} mx={6}>
          <ContentTypeRadioGroup defaultValue="" onChange={onSelectType} />
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

interface ContentTypeRadioGroupProps {
  defaultValue: string;
  onChange: (contentType: string) => void;
}

const ContentTypeRadioGroup: React.FC<ContentTypeRadioGroupProps> = ({
  defaultValue,
  onChange,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "contentType",
    defaultValue,
    onChange,
  });

  return (
    <Grid
      templateColumns="repeat(2, minmax(0, 1fr))"
      w="full"
      gap={6}
      {...getRootProps()}
    >
      {/* <ContentTypeRadioCard {...getRadioProps({ value: "show" })}>
        <HStack spacing={4}>
          <Box>
            <IconView width="32px" height="32px" />
          </Box>
          <Text>
            <Text as="span" fontWeight="bold">
              Show
            </Text>{" "}
            something you're proud of
          </Text>
        </HStack>
      </ContentTypeRadioCard> */}
      <ContentTypeRadioCard {...getRadioProps({ value: "teach" })}>
        <VStack spacing={4}>
          <Box>
            <IconBookOpen width="32px" height="32px" />
          </Box>
          <Text fontSize="lg" textAlign="center">
            <Text as="span" fontWeight="bold">
              Teach
            </Text>{" "}
            about something you love
          </Text>
        </VStack>
      </ContentTypeRadioCard>
      <ContentTypeRadioCard {...getRadioProps({ value: "challenge" })}>
        <VStack spacing={4}>
          <Box>
            <CoinIcon />
          </Box>
          <Text fontSize="lg" textAlign="center">
            <Text as="span" fontWeight="bold">
              Challenge
            </Text>{" "}
            viewers to submit a response
          </Text>
        </VStack>
      </ContentTypeRadioCard>
    </Grid>
  );
};

const ContentTypeRadioCard: React.FC<PropsWithChildren> = ({
  children,
  ...props
}) => {
  return (
    <RadioCard
      px={10}
      py={8}
      borderRadius="2xl"
      fontWeight="unset"
      textTransform="unset"
      border="1px solid"
      borderColor="gray.300"
      _checked={{
        margin: "-1px",
        borderWidth: "2px",
        borderColor: "gray.900",
      }}
      _focus={{
        boxShadow: "outline",
      }}
      _hover={{
        bg: "gray.50",
      }}
      _active={{
        color: "gray.900",
      }}
      {...props}
    >
      {children}
    </RadioCard>
  );
};
