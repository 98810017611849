import React, { ReactNode } from "react";
import { forwardRef } from "@chakra-ui/react";

import { Flex, Text } from "Shared";

type SideNavLinkProps = {
  isActive?: boolean;
  iconRight?: ReactNode;
  isNested?: boolean;
};

export const SideNavGroupLabel = forwardRef<SideNavLinkProps, "div">(
  ({ isActive, children, iconRight, isNested, ...props }, ref) => {
    return (
      <Flex
        align="center"
        justify="space-between"
        px={3}
        pl={isNested ? 8 : undefined}
        py={2}
        borderRadius="lg"
        minH="2rem"
        color="gray.600"
        fontSize="sm"
        fontWeight="bold"
        whiteSpace="nowrap"
        ref={ref}
        {...props}
      >
        <Text>{children}</Text>
        {iconRight}
      </Flex>
    );
  }
);
