export const Checkbox = {
  defaultProps: {
    colorScheme: "brandFull",
  },
  baseStyle: {
    control: {
      border: "1px solid",
      borderColor: "gray.300",
    },
  },
  sizes: {
    lg: {
      control: { w: 6, h: 6, borderRadius: "md" },
      icon: { fontSize: ".75rem" },
      label: { fontSize: "md", ml: 3 },
    },
  },
};
