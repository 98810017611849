import React, { useEffect, useMemo, useRef, useState } from "react";
import mime from "mime-types";
import { Field, FieldProps, useFormikContext } from "formik";
import { isMobile } from "react-device-detect";
import { detect } from "detect-browser";
import { v4 as uuid } from "uuid";

import { FileCaptureMethod } from "@tract/common/dist/types/models/Submission";

import {
  PathViewerNodeFragment,
  PathViewerPathFragment,
  PathViewerProjectFragment,
} from "@tract/common/dist/graphql";

import {
  Box,
  Button,
  Checkbox,
  FileUploader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  IconButtonOutlined,
  IconFile,
  IconHelp,
  IconTrash,
  IconUploadCloud,
  IconVideo,
  Image,
  Input,
  Progress,
  Text,
  TooltipIcon,
  VideoPlayer,
  useDisclosure,
  ButtonOutlined,
  IconUpload,
  Alert,
  AlertIcon,
  Portal,
} from "Shared";

import { pauseAllVideos } from "Utils/videos";

import { Result, VideoEditor } from "Components/VideoEditor";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useUserFileUpload } from "Services/useUserFileUpload";
import { useFeature } from "Services/features";
import { useSession } from "Services/session";

import { usePathRoute } from "./usePathRoute";
import { isTeacherUser } from "Types/User";
import { FILE_TYPES } from "Constants/fileTypes";

const browser = detect();

type Props = {
  path: PathViewerPathFragment;
  node: PathViewerNodeFragment;
  project?: PathViewerProjectFragment;
  onUploadDone: (error?: Error) => void;
};

export const ChallengeFileField: React.FC<Props> = ({
  path,
  node,
  project,
  onUploadDone,
}) => {
  const isSubmissionFeedbackEnabled = useFeature("submission-feedback");
  const { isViewOnlyMode } = usePathRoute(path);
  const { currentUser } = useSession();
  const isCompleted = !!project;
  const { track } = useAnalytics();
  const boxRef = useRef<HTMLDivElement>(null);
  const { setFieldValue } = useFormikContext();
  const {
    onClose: onCloseVideoEditor,
    isOpen: isVideoEditorOpen,
    onOpen: onOpenVideoEditor,
  } = useDisclosure();
  const [preview, setPreview] = useState<{
    fileType: string | null;
    url: string;
  } | null>(null);
  const [fileCaptureMethod, setFileCaptureMethod] = useState<FileCaptureMethod>(
    FileCaptureMethod.Unknown
  );
  const [file, setFile] = useState<File>();
  const [invalidFile, setInvalidFile] = useState("");
  const uploaderRef = useRef<{
    startUpload: (f: File) => void;
  }>(null);
  const maxFileSize = 500e6;
  const maxFileSizeDisplay = "500 mb";
  const isSafariBrowser = browser?.name === "safari";
  const isNewUploadModuleEnabled = !isSafariBrowser;

  let {
    result,
    isUploading,
    progress,
    storageRef,
    error: uploadError,
    handleUploadStart,
    handleUploadError,
    handleProgress,
    handleUploadSuccess,
    reset,
  } = useUserFileUpload();

  useEffect(() => {
    let isMounted = true;

    if (result && isMounted) {
      setPreview({
        fileType: result.metadata.contentType,
        url: result.fileUrl,
      });
    }

    return () => {
      isMounted = false;
    };
  }, [result]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && project) {
      setPreview({
        fileType: mime.lookup(project.file?.filename || "") || null,
        url: `${process.env.REACT_APP_UGC_CDN_ORIGIN}/uf/${project.file?.id}/${project.file?.filename}`,
      });
    }

    return () => {
      setPreview(null);
      isMounted = false;
    };
  }, [project]);

  let currentFileType =
    typeof preview?.fileType === "string" ? preview.fileType : "";

  const isFileTypeImage = useMemo(() => {
    return currentFileType.startsWith("image");
  }, [currentFileType]);

  const isFileTypeVideo = useMemo(() => {
    return currentFileType.startsWith("video");
  }, [currentFileType]);

  const isFileTypeOther = !isFileTypeImage && !isFileTypeVideo;
  const isPublishable = !isCompleted && !isFileTypeOther;

  // Update Formik state when user uploads a new file
  useEffect(() => {
    if (result) {
      setFieldValue("file.filename", result.filename);
      setFieldValue("file.fileId", result.fileId);
    }
  }, [setFieldValue, result, currentFileType]);

  useEffect(() => {
    if (currentFileType) {
      setFieldValue("file.type", currentFileType.split("/")[0]);
    }
  }, [setFieldValue, currentFileType]);

  useEffect(() => {
    setFieldValue("file.fileCaptureMethod", fileCaptureMethod);
  }, [setFieldValue, fileCaptureMethod]);

  if (!path) return null;

  function handleClearFile() {
    // Clear the Formik state
    setFieldValue("file", undefined);
    // Clear the file preview
    setPreview(null);
    // Reset the file uploader state
    reset();
  }

  const ClearFileButton = () => (
    <IconButtonOutlined
      aria-label="clear file"
      onClick={handleClearFile}
      icon={<IconTrash />}
      size="md"
    />
  );

  const onFileSelect = (event: Event) => {
    const fileInput = event.target as HTMLInputElement;

    setFileCaptureMethod(FileCaptureMethod.Upload);

    if (fileInput.files && fileInput.files.length === 1) {
      const file = fileInput.files[0];
      const mimeType = mime.lookup(file.name).toString();

      if (file.size >= maxFileSize) {
        setInvalidFile("File must not exceed 500mb");
        return;
      }

      if (!mimeType.includes("image/") && !mimeType.includes("video/")) {
        setInvalidFile("File type not supported");
        return;
      }

      uploaderRef.current?.startUpload(file);
      setInvalidFile("");
    }
  };

  const onUploadError = (error: Error) => {
    onUploadDone(error);
    handleUploadError(error);
  };

  const onUploadSuccess = (filename: string) => {
    onUploadDone();
    handleUploadSuccess(filename);
  };

  const trackChallengeStarted = (fileCaptureMethod: FileCaptureMethod) => {
    track(ANALYTICS_EVENTS.CHALLENGE_STARTED, {
      pathId: path.id,
      pathTitle: path.title,
      pathAuthorId: path.authorId,
      pathNodeTitle: node.title,
      pathNodeId: node.id,
      fileCaptureMethod,
    });
  };

  const handleFileUploadClick = () => {
    trackChallengeStarted(FileCaptureMethod.Upload);
  };

  const handleCreate = () => {
    pauseAllVideos();
    onOpenVideoEditor();
  };

  const handleVideoEditorSave = (result: Result) => {
    let file: File | null = null;

    if (result.type === "video") {
      file = new File([result.blob], "video.webm", { type: "video/webm" });
    }

    if (result.type === "photo") {
      file = new File([result.blob], "photo.png", { type: "image/png" });
    }

    if (!file) {
      throw new Error("Invalid file");
    }

    setFileCaptureMethod(FileCaptureMethod.VideoEditor);
    setFile(file);
    uploaderRef.current?.startUpload(file);
    onCloseVideoEditor();
  };

  const handleRetryUpload = () => {
    if (file) {
      uploaderRef.current?.startUpload(file);
    }
  };

  const isTeacher = isTeacherUser(currentUser);

  if (!!project && isSubmissionFeedbackEnabled) {
    return null;
  }

  return (
    <Field name="file.filename">
      {({ field: { value }, form: { submitCount }, meta }: FieldProps) => {
        if (isUploading) {
          return (
            <Box borderRadius="12px" bg="gray.50" p={4}>
              <Flex align="center">
                <Progress
                  bg="gray.200"
                  height="16px"
                  value={progress}
                  colorScheme="green"
                  flex="1"
                />
                <Text ml={4} color="gray.600" fontWeight="700" fontSize="16px">
                  {progress}%
                </Text>
              </Flex>
            </Box>
          );
        }

        if (preview) {
          return (
            <Box>
              <Flex direction="column">
                {isFileTypeImage && (
                  <Flex mb={4}>
                    <Image
                      src={preview.url}
                      borderRadius="8px"
                      minHeight="1px"
                      maxHeight="400px"
                    />
                  </Flex>
                )}
                {isFileTypeVideo && (
                  <Box width="100%" mb={4}>
                    <VideoPlayer url={preview.url} borderRadius="8px" />
                  </Box>
                )}

                {value && (
                  <>
                    <Flex
                      justify={isFileTypeOther ? "space-between" : "center"}
                    >
                      {isFileTypeOther && (
                        <Flex align="center">
                          <IconFile mr={2} ml={2} size="24px" />
                          <Text as="span" fontSize="md" fontWeight="700">
                            {value}
                          </Text>
                        </Flex>
                      )}
                      {isPublishable && (
                        <>
                          <Field name="text">
                            {({ field }: FieldProps) => (
                              <Input
                                {...field}
                                flex={1}
                                placeholder="Add a caption..."
                                mr={4}
                              />
                            )}
                          </Field>
                          {ClearFileButton()}
                        </>
                      )}
                    </Flex>
                    {isPublishable && !isTeacher && (
                      <Flex align="center">
                        <Field type="checkbox" name="isPrivate">
                          {({ field }: FieldProps) => (
                            <Checkbox
                              {...field}
                              isChecked={field.value && isPublishable}
                              mt={3}
                              mr={2}
                            >
                              <Text>Make Project Private</Text>
                            </Checkbox>
                          )}
                        </Field>
                        <TooltipIcon
                          label="Your project won't be visible to other learners in the Project Gallery"
                          placement="top"
                          textAlign="left"
                        >
                          <IconHelp size="18px" />
                        </TooltipIcon>
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
            </Box>
          );
        }

        return (
          <>
            {!!uploadError && (
              <Box w="full">
                <Alert status="error">
                  <AlertIcon />
                  <Text mr={4}>There was an error uploading your file.</Text>
                  <Button
                    variant="outline"
                    colorScheme="red"
                    onClick={handleRetryUpload}
                  >
                    Retry Upload
                  </Button>
                </Alert>
              </Box>
            )}

            <FormControl
              hidden={!!uploadError}
              isRequired
              isInvalid={(submitCount > 0 && !!meta.error) || !!invalidFile}
            >
              {isNewUploadModuleEnabled && (
                <HStack spacing={6}>
                  {!isMobile && (
                    <Button
                      variant="outline"
                      onClick={handleCreate}
                      width="full"
                      borderRadius="2xl"
                      py={10}
                      px={6}
                      height="8.875rem"
                      disabled={isViewOnlyMode}
                      whiteSpace="break-spaces"
                      overflow="hidden"
                    >
                      <Flex direction="column" alignItems="center">
                        <IconVideo size={40} mb={1} />
                        <Text fontSize="lg" fontWeight="bold">
                          Create
                        </Text>
                      </Flex>
                    </Button>
                  )}
                  <Button
                    as="label"
                    cursor="pointer"
                    htmlFor={`file.${node.id}`}
                    variant="outline"
                    width="full"
                    borderRadius="2xl"
                    py={10}
                    px={6}
                    height="8.875rem"
                    disabled={isViewOnlyMode}
                    whiteSpace="break-spaces"
                    overflow="hidden"
                  >
                    <Flex direction="column" alignItems="center">
                      <IconUploadCloud size={40} mb={1} />
                      <Text fontSize="lg" fontWeight="bold">
                        Upload a File
                      </Text>
                    </Flex>
                    <FileUploader
                      disabled={isViewOnlyMode}
                      hidden
                      ref={uploaderRef}
                      id={`file.${node.id}`}
                      accept={`${FILE_TYPES.image},${FILE_TYPES.video}`}
                      name="file"
                      filename={uuid()}
                      storageRef={storageRef}
                      onUploadStart={handleUploadStart}
                      onUploadError={onUploadError}
                      onUploadSuccess={onUploadSuccess}
                      onProgress={handleProgress}
                      onChange={onFileSelect}
                      onClick={handleFileUploadClick}
                    />
                  </Button>
                </HStack>
              )}

              {!isNewUploadModuleEnabled && (
                <>
                  <Flex
                    justifyContent="center"
                    border="2px dashed"
                    borderColor="gray.300"
                    borderRadius="12px"
                    bg="gray.50"
                    py="40px"
                  >
                    <ButtonOutlined
                      disabled={isViewOnlyMode}
                      as="label"
                      size="lg"
                      cursor="pointer"
                      htmlFor={`file.${node.id}`}
                      leftIcon={<IconUpload />}
                    >
                      Upload a File
                      <FileUploader
                        hidden
                        disabled={isViewOnlyMode}
                        ref={uploaderRef}
                        id={`file.${node.id}`}
                        accept={`${FILE_TYPES.image},${FILE_TYPES.video}`}
                        name="file"
                        randomizeFilename
                        storageRef={storageRef}
                        onUploadStart={handleUploadStart}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUploadSuccess}
                        onProgress={handleProgress}
                        onChange={onFileSelect}
                      />
                    </ButtonOutlined>
                  </Flex>
                  <FormHelperText>
                    All images and videos supported except animated gifs. Max
                    size: {maxFileSizeDisplay}
                  </FormHelperText>
                </>
              )}
              <FormErrorMessage>{meta.error || invalidFile}</FormErrorMessage>
            </FormControl>

            <Portal>
              {isVideoEditorOpen && (
                <VideoEditor
                  isOpen={isVideoEditorOpen}
                  onClose={onCloseVideoEditor}
                  onSave={handleVideoEditorSave}
                />
              )}
            </Portal>

            {/* Container Box For Menus/Popovers in Modal */}
            <Box ref={boxRef} />
          </>
        );
      }}
    </Field>
  );
};
