import React, { FC } from "react";
import {
  LivestreamGalleryQuery,
  LivestreamGalleryQueryVariables,
} from "@tract/common/dist/graphql";
import {
  Box,
  Tbody,
  Table,
  HStack,
  Text,
  IconCalender,
  EmptyState,
  Skeleton,
  VStack,
  EmptyStateFontAwesomeIcon,
} from "Shared";
import { useQuery } from "urql";

import { LIVESTREAM_GALLERY_QUERY } from "./graphql";
import { LivestreamGalleryRow } from "./LivestreamGalleryRow";
import { LivestreamGallerySkeleton } from "Components/Skeletons/LivestreamGallerySkeleton";
import { getISODay, set } from "date-fns";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {};

export const LivestreamGalleryTable: FC<Props> = () => {
  const dayInMs = 24 * 60 * 60 * 1000;
  const weekInMs = 7 * dayInMs;
  const sundayISOIndex = 7;

  const currentISODay = getISODay(new Date());
  const isSunday = currentISODay === sundayISOIndex;
  const todayAtMidnight = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const beginningOfWeek = isSunday
    ? new Date(todayAtMidnight)
    : new Date(new Date(todayAtMidnight).getTime() - currentISODay * dayInMs);
  const endOfWeek = isSunday
    ? new Date(new Date(todayAtMidnight).getTime() + weekInMs)
    : new Date(
        new Date(todayAtMidnight).getTime() +
          (sundayISOIndex - currentISODay) * dayInMs
      );
  const oneYearFromEndOfWeek = new Date(
    new Date(endOfWeek).getTime() + weekInMs * 52
  );

  const [{ data, fetching }] = useQuery<
    LivestreamGalleryQuery,
    LivestreamGalleryQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: LIVESTREAM_GALLERY_QUERY,
    variables: {
      limit: 100,
      startDateStart: beginningOfWeek.toISOString(),
      startDateEnd: oneYearFromEndOfWeek.toISOString(),
    },
  });
  const livestreams = data?.livestreams;

  if (fetching) {
    return (
      <>
        <Skeleton width="15%" height={6} mt={8} mb={2} />
        <Table width="100%">
          <Tbody>
            {Array.from({ length: 5 }).map((_, i) => (
              <LivestreamGallerySkeleton key={i} />
            ))}
          </Tbody>
        </Table>
      </>
    );
  }
  if (!livestreams?.length) {
    return (
      <EmptyState
        icon={<EmptyStateFontAwesomeIcon icon={duotone("calendar")} />}
        headline="No upcoming events scheduled"
      />
    );
  }

  const thisWeekLivestreams = livestreams?.filter(
    (livestream) =>
      new Date(livestream.startDate).getTime() < new Date(endOfWeek).getTime()
  );

  const nextWeekLivestreams = livestreams?.filter(
    (livestream) =>
      new Date(livestream.startDate).getTime() > new Date(endOfWeek).getTime()
  );

  return (
    <VStack align="stretch" spacing={10}>
      {!!thisWeekLivestreams.length && (
        <Box>
          <HStack mb={4}>
            <IconCalender />
            <Text fontSize="xl" fontWeight="bold">
              This Week
            </Text>
          </HStack>
          <Table width="100%">
            <Tbody>
              {thisWeekLivestreams.map((livestream) => {
                return (
                  <LivestreamGalleryRow
                    key={livestream.id}
                    livestream={livestream}
                  />
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
      {!!nextWeekLivestreams.length && (
        <Box>
          <HStack mb={4}>
            <IconCalender />
            <Text fontSize="xl" fontWeight="bold">
              Next Week
            </Text>
          </HStack>
          <Table width="100%">
            <Tbody>
              {nextWeekLivestreams.map((livestream) => {
                return (
                  <LivestreamGalleryRow
                    key={livestream.id}
                    livestream={livestream}
                  />
                );
              })}
            </Tbody>
          </Table>
        </Box>
      )}
    </VStack>
  );
};
