import { FC } from "react";
import { useHistory } from "react-router";
import { useNavHistory } from "Services/history";

import {
  IconButton,
  ButtonGroup,
  IconChevronLeft,
  IconChevronRight,
} from "Shared";

type NavigationHistoryProps = {};

export const NavigationHistory: FC<NavigationHistoryProps> = () => {
  const history = useHistory();
  const { locations, index } = useNavHistory();
  return (
    <ButtonGroup>
      <IconButton
        icon={<IconChevronLeft />}
        aria-label="go back"
        onClick={() => {
          history.goBack();
        }}
        variant="ghost"
        disabled={index < 0}
      />
      <IconButton
        variant="ghost"
        icon={<IconChevronRight />}
        aria-label="go forward"
        onClick={() => {
          history.goForward();
        }}
        disabled={locations.length - 1 === index}
      />
    </ButtonGroup>
  );
};
