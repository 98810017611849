import { UpdateResolver } from "@urql/exchange-graphcache";

export const delete_path_collection_item_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.invalidate({
    __typename: "path_collection_item",
    collectionId: args.collectionId,
    pathId: args.pathId,
  });
};
