import { forwardRef } from "@chakra-ui/system";
import {
  UnauthPathViewerNodeFragment,
  UnauthPathViewerPathFragment,
} from "@tract/common/dist/graphql";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

import {
  AspectRatio,
  Center,
  Grid,
  GridItem,
  IconImage,
  IconLock,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from "Shared";
import { UserFile } from "Utils/UserFile";
import { usePathRoute } from "../usePathRoute";

type Props = {
  path: UnauthPathViewerPathFragment;
  node: UnauthPathViewerNodeFragment;
  isActive?: boolean;
  isLocked?: boolean;
};

export const PlaylistItemNode = forwardRef<Props, "div">(
  ({ path, node, isActive, isLocked = true, ...props }, ref) => {
    const { params, baseUrl } = usePathRoute(path);
    const { pathname, state } = useLocation<{ disableScroll?: boolean }>();
    const videoFile = new UserFile(node.videoFile);
    const thumbnailURL = videoFile.getTransformedUrl("video_cover_sm");

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (
        params.nodeId === node.id &&
        state?.disableScroll !== true &&
        scrollRef.current
      ) {
        const elemOffset: number = scrollRef.current.offsetTop;
        const containerElem = document.getElementById(
          "path-viewer-sidenav"
        ) as HTMLDivElement;

        if (containerElem) {
          containerElem.scrollTop = elemOffset - 16;
        }
      }
    }, [pathname, state, params.nodeId, node.id, scrollRef]);

    return (
      <LinkBox ref={scrollRef}>
        <Grid
          templateColumns="1fr 2fr"
          gap={4}
          py={2}
          px={4}
          borderRadius="lg"
          alignItems="center"
          color={isActive ? "brandFull.500" : undefined}
          bg={isActive ? "brandFull.50" : undefined}
          _focusWithin={{ boxShadow: "outline" }}
          {...props}
          ref={ref}
        >
          <GridItem>
            <AspectRatio ratio={16 / 9}>
              {thumbnailURL ? (
                <Center borderRadius="md" bgImage={thumbnailURL} bgSize="cover">
                  {isLocked && <IconLock color="white" />}
                </Center>
              ) : (
                <Center borderRadius="md" bg="gray.100">
                  {isLocked ? (
                    <IconLock color="gray.500" />
                  ) : (
                    <IconImage color="gray.300" />
                  )}
                </Center>
              )}
            </AspectRatio>
          </GridItem>
          <GridItem>
            <LinkOverlay
              as={Link}
              display="block"
              to={`${baseUrl}/v/${node.id}`}
              _hover={{ textDecoration: "none", color: "brand" }}
              _focus={{ boxShadow: "none" }}
            >
              <Text fontWeight={isActive ? "bold" : "normal"} fontSize="md">
                {node.title || "Untitled Content"}
              </Text>
            </LinkOverlay>
          </GridItem>
        </Grid>
      </LinkBox>
    );
  }
);
