import { gql } from "urql";

import { USER_TYPE_FRAGMENT } from "Types/User";

export const ADMIN_DASHBOARD_USER_ACCOUNT_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}

  fragment AdminDashboardUserAccount on user {
    id: firestoreId
    avatar
    bio
    disabled
    username
    firstName
    lastName
    creatorLevel
    canAuthor
    orgId
    createdAt
    profilePrivacy
    projectPrivacy
    pathPrivacy
    invitedBy {
      id: firestoreId
      username
    }
    organization {
      id: firestoreId
      name
    }
    learnerGroups {
      learnerGroup {
        id
        name
        members(where: { isOwner: { _eq: true } }) {
          user {
            id
            avatar
            firestoreId
            userType
            firstName
            email
            isEducator
            lastName
            teacher {
              id: userId
              displayName
            }
          }
        }
      }
    }
    ...UserType
  }
`;

export const GET_ADMIN_DASHBOARD_USER = gql`
  ${ADMIN_DASHBOARD_USER_ACCOUNT_FRAGMENT}

  query GetAdminDashboardUser($userId: String!) {
    user_by_pk(firestoreId: $userId) {
      ...AdminDashboardUserAccount
    }
  }
`;

export const GET_ADMIN_DASHBOARD_USERS = gql`
  ${ADMIN_DASHBOARD_USER_ACCOUNT_FRAGMENT}

  query GetAdminDashboardUsers(
    $limit: Int = 100
    $offset: Int = 0
    $where: user_bool_exp!
  ) {
    adminDashboardUsers: user(
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
      where: $where
    ) {
      ...AdminDashboardUserAccount
    }
  }
`;

export const DISABLE_USER_MUTATION = gql`
  mutation DisableUser($userId: String!, $disabled: Boolean!) {
    api_disable_user(input: { userId: $userId, disabled: $disabled }) {
      userId
      disabled
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userId: String!) {
    api_delete_user(userId: $userId)
  }
`;

export const UPDATE_USER_CREATOR_LEVEL_MUTATION = gql`
  ${ADMIN_DASHBOARD_USER_ACCOUNT_FRAGMENT}

  mutation UpdateUserCreatorLevel($userId: String!, $creatorLevel: String!) {
    update_user_by_pk(
      pk_columns: { firestoreId: $userId }
      _set: { creatorLevel: $creatorLevel }
    ) {
      ...AdminDashboardUserAccount
    }
  }
`;

export const ADMIN_DASHBOARD_UPDATE_USER_PROFILE_MUTATION = gql`
  ${ADMIN_DASHBOARD_USER_ACCOUNT_FRAGMENT}

  mutation AdminDashboardUpdateUserProfile(
    $userId: String!
    $username: String!
    $firstName: String!
    $avatar: String!
    $lastName: String
    $bio: String
  ) {
    update_user_by_pk(
      pk_columns: { firestoreId: $userId }
      _set: {
        avatar: $avatar
        username: $username
        firstName: $firstName
        lastName: $lastName
        bio: $bio
      }
    ) {
      ...AdminDashboardUserAccount
    }
  }
`;

export const ALL_ORGANIZATIONS_QUERY = gql`
  query AllOrganizations($offset: Int!) {
    organization(
      offset: $offset
      where: { status: { _eq: "Approved" } }
      order_by: { name: asc }
    ) {
      id: firestoreId
      name
      zip
    }
  }
`;

export const ADMIN_USER_COINS_LEDGER_QUERY = gql`
  query AdminUserCoinsLedger($offset: Int!, $limit: Int!, $userId: String!) {
    coins: coin_trx(
      offset: $offset
      limit: $limit
      order_by: { createdAt: desc }
      where: { userId: { _eq: $userId } }
    ) {
      id
      userId
      amount
      balance
      category
      createdAt
    }
  }
`;

export const ADMIN_USER_ADJUST_COINS_MUTATION = gql`
  mutation AdminUserAdjustCoins($userId: String!, $amount: Int!) {
    insert_coin_trx_one(
      object: { userId: $userId, amount: $amount, category: admin_adjustment }
    ) {
      id
    }
  }
`;

export const ADMIN_CREATE_KID_INVITE = gql`
  mutation AdminCreateKidInvite($code: educator_code_insert_input!) {
    adminCreateKidInvite: insert_educator_code_one(object: $code) {
      available
      code
      createdAt
      expiresAt
    }
  }
`;

export const ADMIN_EDIT_KID_INVITE = gql`
  mutation AdminEditKidInvite($code: String!, $available: Int!) {
    adminEditKidInvite: update_educator_code_by_pk(
      pk_columns: { code: $code }
      _set: { available: $available }
    ) {
      code
      available
    }
  }
`;
