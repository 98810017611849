import { FC, useEffect, useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useMutation } from "urql";
import { db } from "Services/firebase";

import {
  ButtonOutlined,
  ButtonSolid,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useToast,
} from "Shared";

import {
  UpdatePathCollectionMutation,
  UpdatePathCollectionMutationVariables,
  InsertPathCollectionMutation,
  InsertPathCollectionMutationVariables,
} from "@tract/common/dist/graphql";
import {
  COLLECTION_UPDATE_MUTATION,
  COLLECTION_INSERT_MUTATION,
} from "./graphql";

export type CollectionMetadata = {
  create?: {
    userId: string;
    pathId: number;
  };
  edit?: {
    id: string;
    title: string;
    description?: string;
    userId: string;
  };
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  collectionMetadata: CollectionMetadata;
};

interface FormValues {
  title: string;
  description: string | null;
  userId: string;
}

export const CollectionAddOrEditModal: FC<Props> = ({
  isOpen,
  onClose,
  collectionMetadata,
}) => {
  const toast = useToast();
  const [collectionOwners, setCollectionOwners] = useState(
    [] as { id: string; name: string }[]
  );

  if (!collectionMetadata.create && !collectionMetadata.edit) {
    throw new Error("Create or Edit metadata must be provided");
  }

  const initialValues = {
    title: collectionMetadata.edit?.title || "",
    description: collectionMetadata.edit?.description || "",
    userId:
      collectionMetadata.create?.userId ||
      collectionMetadata.edit?.userId ||
      "",
  };

  const [, updatePathCollection] = useMutation<
    UpdatePathCollectionMutation,
    UpdatePathCollectionMutationVariables
  >(COLLECTION_UPDATE_MUTATION);

  const [, createPathCollection] = useMutation<
    InsertPathCollectionMutation,
    InsertPathCollectionMutationVariables
  >(COLLECTION_INSERT_MUTATION);

  async function submitHandler(values: FormValues) {
    if (collectionMetadata.edit) {
      try {
        const vars = {
          ...values,
          id: collectionMetadata.edit.id,
        };

        const output = await updatePathCollection(vars);
        if (output.error) throw new Error(output.error.message);
        onClose();
      } catch (error: any) {
        toast({
          title: "Error",
          description: "Unable to edit collection.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      try {
        const vars = {
          ...values,
          pathId: collectionMetadata?.create?.pathId,
        };

        const output = await createPathCollection(vars);
        if (output.error) throw new Error(output.error.message);
        onClose();

        toast({
          title: "Success",
          description: "Collection created.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } catch (error: any) {
        toast({
          title: "Error",
          description: "Unable to create collection.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    (async () => {
      const results = await db.collection("/collection-owners").get();
      const owners = results.docs.map((d) => ({
        id: d.data().id,
        name: d.data().name,
      }));
      setCollectionOwners(owners);
    })();
  }, [collectionOwners]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <ModalHeader>
              {collectionMetadata.edit ? "Edit" : "Create"} Collection
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Field name="title">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl isRequired pt={5} key={field.name}>
                    <FormLabel>Title</FormLabel>
                    <Input
                      {...field}
                      type="text"
                      size="lg"
                      autoComplete="off"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="description">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl pt={5}>
                    <FormLabel>Description</FormLabel>
                    <Textarea {...field} size="lg" autoComplete="off" />
                  </FormControl>
                )}
              </Field>

              <Field name="userId">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl isRequired pt={5}>
                    <FormLabel>Owner</FormLabel>
                    <Select {...field} size="lg" placeholder="Select an owner">
                      {collectionOwners.map((owner) => (
                        <option value={owner.id} id={owner.id} key={owner.id}>
                          {owner.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </ModalBody>

            <ModalFooter as={HStack} mt={5}>
              <ButtonOutlined onClick={onClose} size="lg">
                Cancel
              </ButtonOutlined>
              <ButtonSolid type="submit" size="lg">
                Save
              </ButtonSolid>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
};
