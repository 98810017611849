import { forwardRef, IconButtonProps } from "@chakra-ui/react";

import { IconButton, Link } from "Shared";

type Props = {
  label: string;
  to: string;
  isActive?: boolean;
} & IconButtonProps;

export const BottomNavItem = forwardRef<Props, "button">(
  ({ label, to, isActive = false, ...props }, ref) => {
    return (
      <IconButton
        as={Link}
        to={to}
        size="lg"
        height="100%"
        width="100%"
        variant="ghost"
        _focus={{ boxShadow: "none" }}
        borderRadius={0}
        color={isActive ? "brand" : "gray.900"}
        {...props}
        aria-label={label}
        ref={ref}
      />
    );
  }
);
