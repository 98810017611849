import React, { FC } from "react";
import { BadgeProps } from "@chakra-ui/react";

import { Badge } from "Shared";

import { PathStatus } from "@tract/common/dist/types/models/Path";
import { PathBuilderPathV4Fragment } from "@tract/common/dist/graphql";

export const PathStatusBadgeV2: FC<
  {
    path: PathBuilderPathV4Fragment;
  } & BadgeProps
> = ({ path, ...props }) => {
  if (path?.status === PathStatus.Published) {
    return (
      <Badge variant="solid" colorScheme="green" {...props}>
        Published
      </Badge>
    );
  }

  if (path?.status === PathStatus.Archived) {
    return (
      <Badge variant="subtle" colorScheme="red" {...props}>
        Archived
      </Badge>
    );
  }

  if (path?.status === PathStatus.InReview) {
    return (
      <Badge variant="subtle" colorScheme="orange" {...props}>
        In Review
      </Badge>
    );
  }

  return (
    <Badge variant="subtle" {...props}>
      Draft
    </Badge>
  );
};
