import React, { useEffect, useState } from "react";
import { ModalProps } from "@chakra-ui/modal";
import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery, gql } from "urql";

import {
  Button,
  ButtonGroup,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  ButtonOutlined,
  ButtonSolid,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  VisuallyHidden,
  FormErrorMessage,
  useToast,
  Box,
  EmptyState,
  TableContainer,
  EmptyStateFontAwesomeIcon,
  SearchInput,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { LearnerRow } from "./LearnerRow";

import { captureException } from "Services/errors";
import { post } from "Services/api";
import { useSession } from "Services/session";

import { isEducatorCodeActive } from "Types/EducatorCode";
import {
  LearnerRowUserFragment,
  LearnersByUserIdsQueryResult,
  LearnersByUserIdsQueryVariables,
  LearnersTableActiveCodeFragment,
  useGetUserCoinBalancesQuery,
  UserProjectsCompletedQueryResult,
  UserProjectsCompletedQueryVariables,
} from "@tract/common/dist/graphql";
import {
  LEARNERS_BY_USER_IDS_QUERY,
  PROJECTS_COMPLETED_QUERY,
} from "./graphql";
import { useClassContext } from "Components/LayoutClass";
import {
  LearnerGroupPendingMember,
  LearnerPendingRow,
} from "./LearnerPendingRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

gql`
  fragment LearnersTableActiveCode on educator_code {
    code
    available
    expiresAt
  }
`;

type LearnersTableProps = {
  kidIds: string[];
  classroomId?: string;
  activeCode?: LearnersTableActiveCodeFragment;
  showAdminView: boolean;
  classMemberOrTeacher: boolean;
  onDeleteUser?: (learner: LearnerRowUserFragment | null) => void;
};

const PasswordChangeFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Please provide a new password")
    .min(6, "Password must be at least 6 characters")
    .max(256, "Password cannot be longer than 256 characters"),

  confirmPassword: yup
    .string()
    .required("Please re-type your new password")
    .oneOf([yup.ref("newPassword"), ""], "Passwords don't match"),
});

export const LearnersTable: React.FC<LearnersTableProps> = ({
  kidIds,
  activeCode,
  classroomId,
  showAdminView,
  classMemberOrTeacher,
  onDeleteUser,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const {
    classData,
    stale: isClassDataStale,
    onClickInviteStudents,
    onCopyStudentInviteLink,
  } = useClassContext();

  const {
    isOpen: isPasswordModalOpen,
    onClose: closePasswordModal,
    onOpen: openPasswordModal,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onClose: closeDeleteModal,
    onOpen: openDeleteModal,
  } = useDisclosure();
  const {
    isOpen: isDeletePendingMemberModalOpen,
    onClose: closeDeletePendingMemberModal,
    onOpen: openDeletePendingMemberModal,
  } = useDisclosure();

  const [currentLearner, setCurrentLearner] =
    useState<LearnerRowUserFragment | null>(null);
  const [currentPendingMember, setCurrentPendingMember] =
    useState<LearnerGroupPendingMember | null>(null);

  const [balancesByUserId, setBalancesByUserId] = useState<Map<string, number>>(
    new Map()
  );

  const [{ data, fetching: loading }] = useQuery<
    LearnersByUserIdsQueryResult["data"],
    LearnersByUserIdsQueryVariables
  >({
    query: LEARNERS_BY_USER_IDS_QUERY,
    variables: {
      userIds: kidIds,
    },
  });

  const kids = data?.users;

  useGetUserCoinBalancesQuery({
    variables: {
      userIds: kidIds,
    },
    onCompleted: (data) => {
      const balanceMap = new Map();
      data.balances.forEach((balance) => {
        balanceMap.set(balance.userId, balance.balance);
      });
      setBalancesByUserId(balanceMap);
    },
  });

  const [{ data: projectsCompletedResult }] = useQuery<
    UserProjectsCompletedQueryResult["data"],
    UserProjectsCompletedQueryVariables
  >({
    query: PROJECTS_COMPLETED_QUERY,
    variables: {
      userIds: kidIds,
    },
  });

  const challengesCompletedUserMap = new Map<string, number>();

  projectsCompletedResult?.user.forEach((user) => {
    const count = user.projects_aggregate.aggregate?.count || 0;
    challengesCompletedUserMap.set(user.id, count);
  });

  const onPasswordModalOpen = (learner: LearnerRowUserFragment) => {
    openPasswordModal();
    setCurrentLearner(learner);
  };

  const onPasswordModalClose = () => {
    closePasswordModal();
    setCurrentLearner(null);
  };

  const onDeleteModalOpen = (learner: LearnerRowUserFragment) => {
    openDeleteModal();
    setCurrentLearner(learner);
  };

  const onDeleteModalClose = () => {
    closeDeleteModal();
    setCurrentLearner(null);
  };

  const onDeletePendingMemberModalOpen = (
    learner: LearnerGroupPendingMember
  ) => {
    openDeletePendingMemberModal();
    setCurrentPendingMember(learner);
  };

  const onDeletePendingMemberModalClose = () => {
    closeDeletePendingMemberModal();
    setCurrentPendingMember(null);
  };

  if (loading || isClassDataStale) {
    return <LayoutCentered h="50vh" isLoading />;
  }

  const isCodeActive = isEducatorCodeActive(activeCode);

  if (!kids?.length && !classData.pendingMembers.length) {
    return (
      <EmptyState
        icon={<EmptyStateFontAwesomeIcon icon={duotone("users")} />}
        mx="auto"
        headline="No students yet"
      >
        <Text color="gray.600" fontSize="md">
          When you’re ready, invite your students to join the class
        </Text>
        {isCodeActive && showAdminView && (
          <ButtonSolid
            mt={6}
            size="lg"
            colorScheme="brandFull"
            onClick={onClickInviteStudents}
          >
            Add Students
          </ButtonSolid>
        )}
      </EmptyState>
    );
  }

  const filteredSortedKids = (kids || [])
    .concat(
      showAdminView
        ? (classData.pendingMembers as unknown as LearnerRowUserFragment[])
        : []
    )
    ?.filter(
      (kid) =>
        searchTerm.length === 0 ||
        kid.firstName
          ?.toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        kid.username
          ?.toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        kid.lastName
          ?.toLocaleLowerCase()
          .includes(searchTerm.toLocaleLowerCase()) ||
        `${kid.firstName?.toLocaleLowerCase()} ${kid.lastName?.toLocaleLowerCase()}`.includes(
          searchTerm.toLocaleLowerCase()
        )
    )
    .sort(function (a, b) {
      return a.lastName?.localeCompare(b.lastName || "") || 0;
    });

  return (
    <Box>
      <ButtonGroup w="full" mb={8}>
        <SearchInput
          query={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm("")}
          placeholder="Search students..."
        />
        {showAdminView && (
          <Button
            variant="solid"
            colorScheme="brandFull"
            size="lg"
            px={6}
            onClick={onClickInviteStudents}
          >
            Add Students
          </Button>
        )}
      </ButtonGroup>
      {classData.pendingMembers.length > 0 && (
        <EmptyState
          headline="Class roster synced!"
          body="Now invite students to join using your invite link"
          cta={
            <Button
              variant="outline"
              size="lg"
              leftIcon={<FontAwesomeIcon icon={regular("link")} />}
              onClick={onCopyStudentInviteLink}
            >
              Copy Invite Link
            </Button>
          }
          border="1px solid"
          borderColor="gray.300"
          mb={10}
        />
      )}
      <TableContainer>
        <Table>
          <Thead>
            {showAdminView && filteredSortedKids?.length > 0 && (
              <>
                <Tr>
                  <Th></Th>
                  <Th textAlign="center">Responses</Th>
                  <Th textAlign="end" isNumeric>
                    Coins
                  </Th>
                  <Th>
                    <VisuallyHidden>Actions</VisuallyHidden>
                  </Th>
                </Tr>
              </>
            )}
          </Thead>
          <Tbody>
            {filteredSortedKids?.length > 0 ? (
              filteredSortedKids?.map((kid) => {
                const pendingMember =
                  kid as unknown as LearnerGroupPendingMember;

                if (!!pendingMember.foreignId) {
                  return (
                    <LearnerPendingRow
                      key={pendingMember.foreignId}
                      learner={pendingMember}
                      onDeleteModalOpen={onDeletePendingMemberModalOpen}
                    />
                  );
                }

                return (
                  <LearnerRow
                    key={kid.id}
                    learner={kid}
                    classroom={classData}
                    challengesCompleted={challengesCompletedUserMap.get(kid.id)}
                    coinBalance={balancesByUserId.get(kid.id)}
                    classroomId={classroomId}
                    onPasswordModalOpen={onPasswordModalOpen}
                    onDeleteModalOpen={onDeleteModalOpen}
                    showAdminView={showAdminView}
                    classMemberOrTeacher={classMemberOrTeacher}
                  />
                );
              })
            ) : (
              <EmptyState width="full" mx="auto" headline="No students found" />
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {classroomId && (
        <PasswordChangeModal
          learner={currentLearner}
          classroomId={classroomId}
          isOpen={!!currentLearner && isPasswordModalOpen}
          onClose={onPasswordModalClose}
        />
      )}
      {currentLearner && (
        <ConfirmDeleteModal
          learner={currentLearner}
          classroomId={classroomId}
          isOpen={!!currentLearner && isDeleteModalOpen}
          onClose={onDeleteModalClose}
          onDelete={onDeleteUser}
        />
      )}
      {currentPendingMember && (
        <DeletePendingMemberModal
          learner={currentPendingMember}
          classroomId={classroomId}
          isOpen={isDeletePendingMemberModalOpen}
          onClose={onDeletePendingMemberModalClose}
        />
      )}
    </Box>
  );
};

type PasswordChangeModalProps = {
  learner: LearnerRowUserFragment | null;
  classroomId: string;
};

const PasswordChangeModal: React.FC<
  PasswordChangeModalProps & Omit<ModalProps, "children">
> = ({ learner, classroomId, ...props }) => {
  const toast = useToast();
  const { firebaseUser } = useSession();
  const { resetForm, ...formik } = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: PasswordChangeFormSchema,

    onSubmit: async (values) => {
      try {
        await post("/v2/users/learner-password", firebaseUser, {
          learnerGroupId: classroomId,
          learnerUserId: learner?.id,
          password: values.newPassword,
        });

        toast({
          title: "Password changed",
          status: "success",
        });

        props.onClose();
      } catch (error) {
        toast({
          title: "Error changing password",
          status: "error",
        });
      }
    },
  });

  useEffect(() => {
    if (props.isOpen) {
      resetForm();
    }
  }, [props.isOpen, resetForm]);

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent minWidth="600px">
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} spacing={6} alignItems="flex-start">
          <Text>
            Enter a new password for{" "}
            <Text as="span" fontWeight="bold">
              {learner?.firstName} {learner?.lastName || ""} (
              {learner?.username})
            </Text>
          </Text>
          <FormControl isInvalid={!!formik.errors.newPassword} isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              name="newPassword"
              size="lg"
              placeholder="Enter password..."
              value={formik.values.newPassword}
              onChange={(e) => {
                formik.setErrors({ ...formik.errors, newPassword: "" });
                formik.handleChange(e);
              }}
            />

            <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!formik.errors.confirmPassword} isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              size="lg"
              placeholder="Enter password..."
              value={formik.values.confirmPassword}
              onChange={(e) => {
                formik.setErrors({ ...formik.errors, confirmPassword: "" });
                formik.handleChange(e);
              }}
            />
            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={props.onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            isLoading={formik.isSubmitting}
            size="lg"
            onClick={() => formik.handleSubmit()}
          >
            Change Password
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type DeleteConfirmModalProps = {
  learner: LearnerRowUserFragment;
  classroomId?: string;
  onDelete?: (learner: LearnerRowUserFragment | null) => void;
};

const ConfirmDeleteModal: React.FC<
  DeleteConfirmModalProps & Omit<ModalProps, "children">
> = ({ learner, classroomId, onDelete, ...props }) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [, removeMember] = useMutation(gql`
    mutation DeleteLearnerGroupMember($classId: uuid!, $learnerId: String!) {
      delete_learner_group_member_by_pk(
        learnerGroupId: $classId
        userId: $learnerId
      ) {
        learnerGroupId
        userId
      }
    }
  `);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await removeMember({
        classId: classroomId,
        learnerId: learner.id,
      });

      toast({
        title: "Student removed",
        status: "success",
      });

      onDelete && onDelete(learner);
      props.onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error removing student",
        status: "error",
      });
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Remove Student?</ModalHeader>
        <ModalBody>
          <Text mb={2}>
            Are you sure you want to remove{" "}
            <Text fontWeight="bold" as="span">
              {learner?.firstName} {learner?.lastName}
            </Text>{" "}
            {learner?.username && (
              <>
                (
                <Text fontWeight="bold" as="span">
                  @{learner?.username}
                </Text>
                )
              </>
            )}
            ? They will no longer have access to this class, and if they are not
            a member of another class their account will be disabled.
          </Text>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined disabled={deleting} size="lg" onClick={props.onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={deleting}
            isLoading={deleting}
            size="lg"
            colorScheme="red"
            onClick={handleDelete}
          >
            Yes, Remove
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type DeletePendingMemberModalProps = {
  learner: LearnerGroupPendingMember;
  classroomId?: string;
  onDelete?: (learner: LearnerGroupPendingMember | null) => void;
};

const DeletePendingMemberModal: React.FC<
  DeletePendingMemberModalProps & Omit<ModalProps, "children">
> = ({ learner, classroomId, onDelete, ...props }) => {
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [, removeMember] = useMutation(gql`
    mutation DeletePendingLearnerGroupMember(
      $classId: uuid!
      $foreignId: String!
      $provider: enums_integration_provider_enum!
    ) {
      delete_learner_group_pending_member_by_pk(
        learnerGroupId: $classId
        foreignId: $foreignId
        provider: $provider
      ) {
        learnerGroupId
        foreignId
        provider
      }
    }
  `);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await removeMember({
        classId: classroomId,
        foreignId: learner.foreignId,
        provider: learner.provider,
      });

      toast({
        title: "Student removed",
        status: "success",
      });

      onDelete && onDelete(learner);
      props.onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error removing student",
        status: "error",
      });
    } finally {
      setDeleting(false);
    }
  };
  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Remove Student?</ModalHeader>
        <ModalBody>
          <Text mb={2}>
            Are you sure you want to remove{" "}
            <Text fontWeight="bold" as="span">
              {learner?.firstName} {learner?.lastName}
            </Text>{" "}
            ? You can still invite them through class code or class link
          </Text>
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined disabled={deleting} size="lg" onClick={props.onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            disabled={deleting}
            isLoading={deleting}
            size="lg"
            colorScheme="red"
            onClick={handleDelete}
          >
            Yes, Remove
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
