import React, { PropsWithChildren, useState } from "react";
import { Prompt } from "react-router-dom";
import { v4 } from "uuid";

import {
  AspectRatio,
  Box,
  Button,
  Center,
  IconUploadCloud,
  Progress,
  Spinner,
  Text,
  useToast,
  Flex,
  HStack,
  IconVideo,
} from "Shared";

import { storage } from "Services/firebase";
import { post } from "Services/api";
import { useSession } from "Services/session";

import { MediaUploader } from "Components/MediaUploader";
import { ApiVideoPlayer } from "Components/ApiVideoPlayer";

import {
  CreateVideoResponse,
  VideoType,
} from "@tract/common/dist/types/api/videos";
import { to } from "@tract/common/dist/utils/async";
import { usePathV4Context } from "Pages/CreateV4/LayoutCreateV4";

type Props = {
  title: string;
  videoMeta: { key: string; value: string }[];
  isLoading: boolean;
  videoType: VideoType;
  originalFileUrl?: string;
  foreignVideoId?: string;
  disabled?: boolean;
  uploaderRef: React.RefObject<{
    startUpload: (file: File) => void;
  }>;
  onUploadSuccess: (
    fileId: string,
    filename: string,
    foreignId: string
  ) => void;
  onCreate: () => void;
};

export const VideoUploader: React.FC<Props> = ({
  title,
  foreignVideoId,
  videoMeta,
  originalFileUrl,
  videoType,
  uploaderRef,
  isLoading = false,
  disabled = false,
  onUploadSuccess,
  onCreate,
}) => {
  const toast = useToast();
  let [loading, setLoading] = useState(isLoading);
  const { firebaseUser } = useSession();
  const newFileId = v4();

  loading = loading || isLoading;

  const { activeNode } = usePathV4Context();
  const processedByTransloadit = !!activeNode?.videoFile?.transforms?.length;

  const handleUploadSuccess = async (fullPath: string, filename: string) => {
    setLoading(true);

    const fileId = filename.slice(0, 36);

    const [err, res] = await to(
      post<CreateVideoResponse>("/v1/videos", firebaseUser, {
        title: `${title}`,
        videoType: videoType,
        source: fullPath,
        bucket: process.env.REACT_APP_UGC_STORAGE_BUCKET,
        isPrivate: true,
        metadata: videoMeta.concat([
          {
            key: "fileId",
            value: fileId,
          },
        ]),
      })
    );

    if (!res || err) {
      toast({
        title: "Error uploading video",
        status: "error",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    onUploadSuccess(fileId, filename, res.data.videoId);
  };

  return (
    <>
      <Prompt
        when={loading}
        message="We are still processing your video, are you sure you want to leave?"
      />
      <Box w="100%">
        <Box maxW="75rem" mx="auto">
          {loading ? (
            <VideoLoadingState />
          ) : foreignVideoId || originalFileUrl ? (
            !processedByTransloadit ? (
              <VideoLoadingState />
            ) : (
              <ApiVideoPlayer
                borderRadius="2xl"
                videoId={foreignVideoId}
                fallbackSrc={originalFileUrl}
              />
            )
          ) : (
            <MediaUploader
              uploaderRef={uploaderRef}
              name="video"
              mediaType="video"
              filePath={`uf/${newFileId}`}
              inputId="video"
              storage={storage("ugc")}
              maxFileSize={2000}
              onFileUploadSuccess={handleUploadSuccess}
              filename={newFileId}
              disabled={disabled}
              renderAction={(buttonProps: any) => (
                <InnerBorderLayout>
                  <HStack spacing={4}>
                    <Button
                      variant="outline"
                      onClick={onCreate}
                      borderRadius="2xl"
                      py={{ base: 4, xl: 6 }}
                      px={{ base: 10, xl: 14 }}
                      height="8.875rem"
                      whiteSpace="break-spaces"
                      disabled={disabled}
                    >
                      <Flex direction="column" alignItems="center">
                        <IconVideo size={40} mb={1} />
                        <Text fontSize="lg" fontWeight="bold">
                          Create
                        </Text>
                      </Flex>
                    </Button>
                    <Button
                      as="label"
                      cursor="pointer"
                      htmlFor={`file`}
                      variant="outline"
                      borderRadius="2xl"
                      py={{ base: 4, xl: 6 }}
                      px={{ base: 10, xl: 14 }}
                      height="8.875rem"
                      whiteSpace="break-spaces"
                      {...buttonProps}
                    >
                      <Flex direction="column" alignItems="center">
                        <IconUploadCloud size={40} mb={1} />
                        <Text fontSize="lg" fontWeight="bold">
                          Upload
                        </Text>
                      </Flex>
                    </Button>
                  </HStack>
                </InnerBorderLayout>
              )}
              renderUploading={({ progress }) => (
                <InnerBorderLayout>
                  <Prompt
                    when={progress <= 100}
                    message="We are still processing your changes, are you sure you want to leave?"
                  />
                  <Progress
                    colorScheme="brandFull"
                    size="lg"
                    value={progress}
                    w="50%"
                  />
                </InnerBorderLayout>
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const VideoLoadingState: React.FC = () => {
  return (
    <Box w="full" h="full" bg="black" borderRadius="2xl">
      <AspectRatio ratio={16 / 9}>
        <Center>
          <Spinner
            emptyColor="gray.200"
            color="gray.600"
            thickness="4px"
            speed="0.65s"
            size="xl"
          />
        </Center>
      </AspectRatio>
    </Box>
  );
};

const InnerBorderLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <Box bg="white" p={2} borderRadius="2xl">
    <AspectRatio
      ratio={16 / 9}
      border="2px dashed"
      borderColor="gray.300"
      borderRadius="xl"
      w="full"
    >
      <Center>{children}</Center>
    </AspectRatio>
  </Box>
);
