import { PathFilterItem, PATH_SUBJECT_V2 } from "Constants/paths";
import { Box, Button } from "Shared";

type PathSubjectsOptionGroupProps = {
  subjects?: string[];
  onChange: (
    subject: string,
    subjects: string[],
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const PathSubjectsOptionGroup: React.FC<
  PathSubjectsOptionGroupProps
> = ({ subjects = [], onChange, setFieldValue }) => {
  return (
    <Box align="left">
      {PATH_SUBJECT_V2.filter(
        (subject) => subject.standardizedLongLabel !== "Physical Education"
      ).map((subject) => {
        return (
          !!subject.standardizedValue && (
            <PathSubjectOption
              key={subject.standardizedValue}
              subject={subject}
              onChange={() =>
                onChange(
                  subject?.standardizedValue || "",
                  subjects,
                  setFieldValue
                )
              }
              isChecked={subjects?.includes(subject.standardizedValue)}
            />
          )
        );
      })}
    </Box>
  );
};

type SubjectProps = {
  subject: PathFilterItem;
  isChecked?: boolean;
  onChange: (subject: string) => void;
};

const PathSubjectOption: React.FC<SubjectProps> = ({
  subject,
  isChecked,
  onChange,
}) => {
  return (
    <Button
      colorScheme={isChecked ? "brandFull" : "gray"}
      variant={isChecked ? "solid" : "outline"}
      mb={2}
      mr={2}
      border="1px solid"
      onClick={() => onChange(subject.standardizedValue || "")}
    >
      {subject.standardizedShortLabel}
    </Button>
  );
};
