import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  duotone,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { InterestArea } from "@tract/common/dist/types/models/Path";
import { CreatorLevel } from "Types/User";

export type PathFilterItem = {
  label: string;
  value: string;
  slug?: string;
  emoji?: string;
  iconDuotone?: IconProp;
  standardizedValue?: string;
  standardizedLongLabel?: string;
  standardizedShortLabel?: string;
};

export const PATH_STATUSES = {
  archived: "ARCHIVED",
  inReview: "IN_REVIEW",
  draft: "DRAFT",
  published: "PUBLISHED",
};

export enum PathFilter {
  categories = "INTEREST_AREA",
  tags = "TAG",
  subjects = "SUBJECT",
  skills = "SKILL",
  gradeLevels = "GRADE_LEVEL",
  creatorLevels = "CREATOR_LEVEL",
}

export type InterestAreaItem = {
  label: string;
  value: InterestArea;
  slug: string;
  emoji: string;
  color?: string;
  bannerColor?: string;
  iconRegular?: IconProp;
  iconDuotone?: IconProp;
};

export interface TrendingTag {
  value: string;
  tags: string[];
}

export const PATH_CREATOR_LEVELS = [
  {
    label: "Creator",
    slug: "creator",
    value: CreatorLevel.Creator,
  },
  {
    label: "Affiliate",
    slug: "affiliate",
    value: CreatorLevel.Affiliate,
  },
  {
    label: "Partner",
    slug: "partner",
    value: CreatorLevel.Partner,
  },
];

export const PATH_INTEREST_AREAS: InterestAreaItem[] = [
  {
    label: "Arts",
    value: InterestArea.Arts,
    slug: "arts",
    emoji: "🎨",
    color: "#0F074B",
    bannerColor: "#F52384",
    iconRegular: regular("paintbrush-pencil"),
    iconDuotone: duotone("paintbrush-pencil"),
  },
  {
    label: "Nature",
    value: InterestArea.Nature,
    slug: "nature",
    emoji: "🌱",
    color: "#0C213A",
    bannerColor: "#E7AD78",
    iconRegular: regular("narwhal"),
    iconDuotone: duotone("narwhal"),
  },
  {
    label: "Food",
    value: InterestArea.Food,
    slug: "food",
    emoji: "🍽",
    color: "#C3FC30",
    bannerColor: "#C62E2B",
    iconRegular: regular("avocado"),
    iconDuotone: duotone("avocado"),
  },
  {
    label: "Sports",
    value: InterestArea.Sports,
    slug: "sports",
    emoji: "🏀",
    color: "#92066E",
    bannerColor: "#1DFB78",
    iconRegular: regular("football"),
    iconDuotone: duotone("football"),
  },
  {
    label: "Gaming",
    value: InterestArea.Gaming,
    slug: "gaming",
    emoji: "🎮",
    color: "#4AC0C9",
    bannerColor: "#390B66",
    iconRegular: regular("alien-8bit"),
    iconDuotone: duotone("alien-8bit"),
  },
  {
    label: "Music",
    value: InterestArea.Music,
    slug: "music",
    emoji: "🎵",
    color: "#6A18F8",
    bannerColor: "#A5E4D2",
    iconRegular: regular("music"),
    iconDuotone: duotone("music"),
  },
  {
    label: "World Culture",
    value: InterestArea.Politics,
    slug: "world-culture",
    emoji: "🌎",
    color: "#F2E233",
    bannerColor: "#2005FC",
    iconRegular: regular("earth-americas"),
    iconDuotone: duotone("earth-americas"),
  },
  {
    label: "Technology",
    value: InterestArea.Technology,
    slug: "technology",
    emoji: "💻",
    color: "#39232C",
    bannerColor: "#B091B9",
    iconRegular: regular("microchip"),
    iconDuotone: duotone("microchip"),
  },
  {
    label: "Business",
    value: InterestArea.Business,
    slug: "business",
    emoji: "💼",
    color: "#9DBA58",
    bannerColor: "#264938",
    iconRegular: regular("briefcase"),
    iconDuotone: duotone("briefcase"),
  },
  {
    label: "Health & Wellness",
    value: InterestArea.Health,
    slug: "health-and-wellness",
    emoji: "❤️‍🩹",
    color: "#835A46",
    bannerColor: "#FDFB5F",
    iconRegular: regular("staff-aesculapius"),
    iconDuotone: duotone("staff-aesculapius"),
  },
];

export const PATH_INTEREST_AREA_ICON_LABELS = {};

export const PATH_INTEREST_AREAS_FILTER_OPTIONS = [
  {
    label: "All Categories",
    value: "",
  },
  ...PATH_INTEREST_AREAS,
];

export const PATH_SKILLS: PathFilterItem[] = [
  {
    label: "Career Management",
    value: "Career Management",
    slug: "career-management",
    emoji: "💼",
  },
  {
    label: "Communication",
    value: "Communication",
    slug: "communication",
    emoji: "💬",
  },
  {
    label: "Collaboration",
    value: "Collaboration",
    slug: "collaboration",
    emoji: "🤝",
  },
  {
    label: "Creativity",
    value: "Creativity",
    slug: "creativity",
    emoji: "🎨",
  },
  {
    label: "Critical Thinking",
    value: "Critical Thinking",
    slug: "critical-thinking",
    emoji: "🤔",
  },
  {
    label: "Global Fluency",
    value: "Global Fluency",
    slug: "global-fluency",
    emoji: "🗣️",
  },
  {
    label: "Leadership",
    value: "Leadership",
    slug: "leadership",
    emoji: "🎖️",
  },
  {
    label: "Wellness",
    value: "Wellness",
    slug: "wellness",
    emoji: "⚕️",
  },
];

export const PATH_SKILLS_FILTER_OPTIONS = [
  {
    label: "All Skills",
    value: "",
  },
  ...PATH_SKILLS,
];

export const PATH_SUBJECTS: PathFilterItem[] = [
  {
    label: "Health",
    value: "Health",
    slug: "health",
    emoji: "🧑‍⚕️",
    iconDuotone: duotone("staff-snake"),
  },
  {
    label: "History (Social Science)",
    value: "History (Social Science)",
    slug: "history-social-science",
    emoji: "📜",
    iconDuotone: duotone("earth-americas"),
  },
  {
    label: "Mathematics",
    value: "Mathematics",
    slug: "mathematics",
    emoji: "📘",
    iconDuotone: duotone("calculator-simple"),
  },
  {
    label: "Physical Education",
    value: "Physical Education",
    slug: "physical-education",
    emoji: "🏋️",
    iconDuotone: duotone("person-running"),
  },
  {
    label: "Reading/Language Arts",
    value: "Reading/Language Arts",
    slug: "reading-language-arts",
    emoji: "📚",
    iconDuotone: duotone("book-open-cover"),
  },
  {
    label: "Science",
    value: "Science",
    slug: "science",
    emoji: "🔬",
    iconDuotone: duotone("atom-simple"),
  },
  {
    label: "Visual & Performing Arts",
    value: "Visual & Performing Arts",
    slug: "visual-performing-arts",
    emoji: "🎭",
    iconDuotone: duotone("pen-paintbrush"),
  },
  {
    label: "World Language",
    value: "World Language",
    slug: "world-language",
    emoji: "📙",
    iconDuotone: duotone("language"),
  },
];

export const PATH_GRADE_LEVEL: PathFilterItem[] = [
  {
    label: "K-2",
    value: "K-2",
    slug: "K-2",
  },
  {
    label: "3-5",
    value: "3-5",
    slug: "3-5",
  },
  {
    label: "6-8",
    value: "6-8",
    slug: "6-8",
  },
  {
    label: "9-12",
    value: "9-12",
    slug: "9-12",
  },
];

export type PathGradeLevelV2Item = {
  value: string;
  numeric: number;
  label: string;
};

export const PATH_GRADE_LEVEL_V2: PathGradeLevelV2Item[] = [
  {
    value: "KG",
    numeric: 0,
    label: "Kindergarten",
  },
  {
    value: "01",
    numeric: 1,
    label: "First grade",
  },
  {
    value: "02",
    numeric: 2,
    label: "Second grade",
  },
  {
    value: "03",
    numeric: 3,
    label: "Third grade",
  },
  {
    value: "04",
    numeric: 4,
    label: "Fourth grade",
  },
  {
    value: "05",
    numeric: 5,
    label: "Fifth grade",
  },
  {
    value: "06",
    numeric: 6,
    label: "Sixth grade",
  },
  {
    value: "07",
    numeric: 7,
    label: "Seventh grade",
  },
  {
    value: "08",
    numeric: 8,
    label: "Eighth grade",
  },
  {
    value: "09",
    numeric: 9,
    label: "Ninth grade",
  },
  {
    value: "10",
    numeric: 10,
    label: "Tenth grade",
  },
  {
    value: "11",
    numeric: 11,
    label: "Eleventh grade",
  },
  {
    value: "12",
    numeric: 12,
    label: "Twelfth grade",
  },
];

export const PATH_SUBJECT_V2: PathFilterItem[] = [
  {
    standardizedValue: "CEDS.01",
    standardizedLongLabel: "English Language Arts",
    standardizedShortLabel: "ELA",
    label: "Reading/Language Arts",
    value: "Reading/Language Arts",
    slug: "english-language-arts",
    iconDuotone: duotone("book-open-cover"),
    emoji: "📚",
  },
  {
    standardizedValue: "CEDS.03",
    standardizedLongLabel: "Life and Physical Sciences",
    standardizedShortLabel: "Science",
    label: "Science",
    value: "Science",
    slug: "science",
    iconDuotone: duotone("atom-simple"),
    emoji: "🔬",
  },
  {
    standardizedValue: "CEDS.02",
    standardizedLongLabel: "Mathematics",
    standardizedShortLabel: "Math",
    label: "Mathematics",
    value: "Mathematics",
    slug: "math",
    iconDuotone: duotone("calculator-simple"),
    emoji: "📘",
  },
  {
    standardizedValue: "CEDS.04",
    standardizedLongLabel: "Social Sciences and History",
    standardizedShortLabel: "Social Science",
    label: "History (Social Science)",
    value: "History (Social Science)",
    slug: "history-social-science",
    iconDuotone: duotone("earth-americas"),
    emoji: "📜",
  },
  {
    standardizedValue: "CEDS.08",
    standardizedLongLabel: "Health and Safety Education",
    standardizedShortLabel: "Health",
    label: "Health",
    value: "Health",
    slug: "health",
    iconDuotone: duotone("staff-snake"),
    emoji: "🧑‍⚕️",
  },
  {
    standardizedValue: "CEDS.24",
    standardizedLongLabel: "World Language",
    standardizedShortLabel: "World Language",
    label: "World Language",
    value: "World Language",
    slug: "world-language",
    iconDuotone: duotone("language"),
    emoji: "📙",
  },
  {
    standardizedValue: "CEDS.05",
    standardizedLongLabel: "Visual and Performing Arts",
    standardizedShortLabel: "Arts",
    label: "Visual & Performing Arts",
    value: "Visual & Performing Arts",
    slug: "visual-performing-arts",
    iconDuotone: duotone("pen-paintbrush"),
    emoji: "🎭",
  },
  {
    standardizedValue: "CEDS.08",
    standardizedLongLabel: "Physical Education",
    standardizedShortLabel: "Physical Education",
    label: "Physical Education",
    value: "Physical Education",
    slug: "physical-education",
    iconDuotone: duotone("person-running"),
    emoji: "🏋️",
  },
];

export const TIME_TO_COMPLETE_HOURS = [0, 1, 2];
export const TIME_TO_COMPLETE_MINUTES = [0, 1, 2, 3, 5, 10, 15, 20, 30, 45];

export const UNTITLTED_PATH_NAME = "Untitled Path";
export const UNTITLTED_MISSION_NAME = "Untitled Mission";
export const UNTITLTED_CHALLENGE_NAME = "Untitled Challenge";
