import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

import { SIGNUP_ERRORS } from "@tract/common/dist/constants/signup-errors";

import { useQuery } from "Utils";

export const SSOErrors = () => {
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();
  const qs = useQuery();

  useEffect(() => {
    const errorCode = qs.get("errorCode");
    if (!errorCode || error) return;

    let msg = "";

    switch (errorCode) {
      case SIGNUP_ERRORS.MISSING_EMAIL:
        msg = "An email is required to sign up";
        break;
      case SIGNUP_ERRORS.INVALID_EMAIL_DOMAIN:
        msg = "Please use your school email account";
        break;
      case SIGNUP_ERRORS.NEED_TO_SIGNUP:
        msg =
          "You don't have a Tract account! Please sign up before logging in";
        break;
      case SIGNUP_ERRORS.MISSING_CLASSROOM_INVITE:
        msg =
          "You don't have an invite to this classroom. Ask your teacher for assistance or contact support";
        break;
      case SIGNUP_ERRORS.EDUCATOR_CODE_REQUIRED:
      case SIGNUP_ERRORS.INTERNAL:
      default:
        msg = "Something went wrong :( Please try again or contact support";
    }

    setError(msg);
    qs.delete("errorCode");
    history.replace(`${location.pathname}?${qs.toString()}`);
  }, [qs, error, history, location.pathname]);

  if (error) {
    return (
      <Text fontWeight="bold" color="red.600">
        {error}
      </Text>
    );
  }

  return null;
};
