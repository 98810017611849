import { flag } from "country-emoji";
import { ProfileBadgeUserTypeFragment } from "@tract/common/dist/graphql";

import {
  Emoji,
  Center,
  HStack,
  IconFilledAdmin,
  IconFilledAffiliate,
  IconFilledBook,
  IconFilledCreator,
  IconFilledPartner,
  Tooltip,
} from "Shared";

import {
  isAffiliateUser,
  isCreatorUser,
  isPartnerUser,
  isTeacherUser,
} from "Types/User";

interface Props {
  user?: ProfileBadgeUserTypeFragment;
  iconSize?: number;
  spacing?: number;
  flagFont?: string;
}

enum BadgeTypes {
  "Creator",
  "Affiliate",
  "Partner",
  "Teacher",
  "Moderator",
  "Admin",
}

export const ProfileBadges: React.FC<Props> = ({
  user,
  iconSize = 5,
  flagFont = "lg",
}) => {
  const isCreator = !!user && isCreatorUser(user);
  const isAffiliate = !!user && isAffiliateUser(user);
  const isPartner = !!user && isPartnerUser(user);
  const isTeacher = !!user && isTeacherUser(user);
  const isModerator = user?.isMod;
  const countryToShow = user?.country;
  const badgesToShow: BadgeTypes[] = [];

  if (!user) {
    return null;
  }

  if (isCreator && !isPartner && !isTeacher && !isModerator) {
    badgesToShow.push(BadgeTypes.Creator);
  }

  if (isAffiliate && !isPartner && !isTeacher && !isModerator) {
    badgesToShow.push(BadgeTypes.Affiliate);
  }

  if (isPartner && !isTeacher && !isModerator) {
    badgesToShow.push(BadgeTypes.Partner);
  }

  if (isTeacher) {
    badgesToShow.push(BadgeTypes.Teacher);
  }

  if (isModerator) {
    badgesToShow.push(BadgeTypes.Moderator);
  }

  if (!badgesToShow.length && !countryToShow) {
    return null;
  }

  return (
    <HStack spacing={1} align="center" display="inline-flex">
      {badgesToShow.indexOf(BadgeTypes.Creator) > -1 && (
        <Tooltip label="Creator" placement="top" hasArrow>
          <Center
            h={iconSize}
            w={iconSize}
            p="1px"
            bg="orange.500"
            borderRadius="md"
            color="white"
            as="span"
            display="inline-block"
          >
            <IconFilledCreator display="block" w="full" h="full" />
          </Center>
        </Tooltip>
      )}
      {badgesToShow.indexOf(BadgeTypes.Affiliate) > -1 && (
        <Tooltip label="Affiliate" placement="top" hasArrow>
          <Center
            h={iconSize}
            w={iconSize}
            p="1px"
            bg="purple.500"
            borderRadius="md"
            color="white"
            as="span"
            display="inline-block"
          >
            <IconFilledAffiliate display="block" w="full" h="full" />
          </Center>
        </Tooltip>
      )}
      {badgesToShow.indexOf(BadgeTypes.Partner) > -1 && (
        <Tooltip label="Partner" placement="top" hasArrow>
          <Center
            h={iconSize}
            w={iconSize}
            p="1px"
            bg="brandFull.400"
            borderRadius="md"
            color="white"
            as="span"
            display="inline-block"
          >
            <IconFilledPartner display="block" w="full" h="full" />
          </Center>
        </Tooltip>
      )}
      {badgesToShow.indexOf(BadgeTypes.Teacher) > -1 && (
        <Tooltip label="Teacher" placement="top" hasArrow>
          <Center
            h={iconSize}
            w={iconSize}
            p="1px"
            bg="pink.500"
            borderRadius="md"
            color="white"
            as="span"
            display="inline-block"
          >
            <IconFilledBook display="block" w="full" h="full" />
          </Center>
        </Tooltip>
      )}
      {badgesToShow.indexOf(BadgeTypes.Moderator) > -1 && (
        <Tooltip label="Moderator" placement="top" hasArrow>
          <IconFilledAdmin
            boxSize={iconSize}
            bg="yellow.500"
            borderRadius="md"
            color="white"
            as="span"
          />
        </Tooltip>
      )}
      {!!countryToShow && (
        <Tooltip label={countryToShow} placement="top" hasArrow>
          <Center
            h={iconSize}
            w={iconSize}
            p="1px"
            borderRadius="md"
            color="gray.600"
            as="span"
            display="inline-block"
            lineHeight={iconSize}
            fontSize={flagFont}
            textAlign="center"
          >
            <Emoji
              style={{ display: "inline-block" }}
              emoji={flag(countryToShow)!}
            />
          </Center>
        </Tooltip>
      )}
    </HStack>
  );
};
