import React from "react";

import {
  Link,
  Text,
  Flex,
  Avatar,
  Box,
  Td,
  Tr,
  HStack,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  ButtonOutlined,
  IconButton,
  IconMoreVertical,
  Portal,
  ButtonSolid,
  FontAwesomeIcon,
  MenuDivider,
} from "Shared";

import { isTeacherUser } from "Types/User";
import { useFeature } from "Services/features";
import { useSession } from "Services/session";
import { useFollows } from "Services/social";

import {
  ClassroomLearnerGroupFragment,
  LearnerRowUserFragment,
} from "@tract/common/dist/graphql";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type LearnerRowProps = {
  classroom?: ClassroomLearnerGroupFragment;
  learner: LearnerRowUserFragment;
  coinBalance?: number;
  challengesCompleted?: number;
  classroomId?: string;
  onPasswordModalOpen: (learner: LearnerRowUserFragment) => void;
  onDeleteModalOpen: (learner: LearnerRowUserFragment) => void;
  showAdminView: boolean;
  classMemberOrTeacher: boolean;
};

export const LearnerRow: React.FC<LearnerRowProps> = ({
  learner,
  classroom,
  coinBalance = 0,
  challengesCompleted = 0,
  classroomId,
  onPasswordModalOpen,
  onDeleteModalOpen,
  showAdminView,
  classMemberOrTeacher,
  ...props
}) => {
  const isPasswordControlEnabled = useFeature("teacher-password-control");
  const { currentUser } = useSession();
  const isTeacher = isTeacherUser(currentUser);
  const isMyProfile = learner.id === currentUser.uid;
  const { isFollowing, follow, unfollow, loading } = useFollows(learner);
  const learnerSignedUpWithPassword = !!learner.email?.includes(
    "@tempaccounts.tract.app"
  );

  return (
    <Tr>
      <Td py={3} pr={4}>
        <Flex {...props}>
          {learner.username ? (
            <>
              <Avatar
                as={Link}
                to={`/@${learner.username}`}
                tabIndex={-1}
                src={learner.avatar || ""}
                size="lg"
                mr={3}
              />
              <Flex flex="1" direction="column" justify="center" align="start">
                {classMemberOrTeacher && (
                  <Text fontSize="lg" color="gray.900" fontWeight="bold" mb={1}>
                    {learner.firstName} {learner.lastName || ""}
                  </Text>
                )}
                <Text
                  as={Link}
                  fontSize="md"
                  color="gray.600"
                  fontWeight="bold"
                  to={`/@${learner.username}`}
                >{`@${learner.username}`}</Text>
              </Flex>
            </>
          ) : (
            <>
              <Avatar display={["none", null, "block"]} size="lg" mx={4} />
              <Box flex="1">
                <Text fontSize="lg" color="gray.600" fontWeight="bold">
                  No username yet
                </Text>
              </Box>
            </>
          )}
        </Flex>
      </Td>
      {showAdminView ? (
        <>
          <Td textAlign="center">{challengesCompleted}</Td>
          <Td textAlign="end" isNumeric>
            {coinBalance.toLocaleString()}
          </Td>
          <Td>
            <HStack width="100%" justify="flex-end">
              {isPasswordControlEnabled && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    size="lg"
                    aria-label="More"
                    variant="ghost"
                    icon={<IconMoreVertical />}
                  />

                  <Portal>
                    <MenuList minWidth="16rem">
                      <MenuItem
                        as={Link}
                        to={`/@${learner?.username}`}
                        icon={<FontAwesomeIcon icon={regular("user")} />}
                      >
                        View Profile
                      </MenuItem>
                      {/* TODO: Link to moderation view, pre-filtered to this student's activity */}
                      {/* <MenuItem
                        icon={
                          <FontAwesomeIcon
                            icon={regular("shield-check")}
                            fixedWidth
                            fontSize="22px"
                          />
                        }
                      >
                        View Activity
                      </MenuItem> */}
                      {learnerSignedUpWithPassword && (
                        <MenuItem
                          onClick={() => onPasswordModalOpen(learner)}
                          icon={<FontAwesomeIcon icon={regular("key")} />}
                        >
                          Change Password
                        </MenuItem>
                      )}
                      {(!classroom?.source || learnerSignedUpWithPassword) && (
                        <>
                          <MenuDivider />
                          <MenuItem
                            onClick={() => onDeleteModalOpen(learner)}
                            icon={
                              <FontAwesomeIcon icon={regular("circle-minus")} />
                            }
                          >
                            Remove from Class
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </Portal>
                </Menu>
              )}
            </HStack>
          </Td>
        </>
      ) : (
        <Td>
          {!isTeacher && (
            <Flex justify="flex-end">
              {isFollowing ? (
                <ButtonOutlined
                  display={isMyProfile ? "none" : "block"}
                  size="lg"
                  onClick={() => unfollow()}
                  isLoading={loading}
                >
                  Unfollow
                </ButtonOutlined>
              ) : (
                <ButtonSolid
                  display={isMyProfile ? "none" : "block"}
                  size="lg"
                  onClick={() => follow()}
                  isLoading={loading}
                >
                  Follow
                </ButtonSolid>
              )}
            </Flex>
          )}
        </Td>
      )}
    </Tr>
  );
};
