import React, { PropsWithChildren } from "react";
import { BoxProps, forwardRef } from "@chakra-ui/react";

import { Box } from "Shared";

type Props = {
  emoji: string;
  label?: string;
} & BoxProps;

export const Emoji = forwardRef<PropsWithChildren<Props>, "span">(
  ({ emoji, label, ...props }, ref) => {
    return (
      <Box
        aria-label={label ? label : undefined}
        aria-hidden={label ? "false" : "true"}
        as="span"
        role="img"
        {...props}
      >
        {emoji}
      </Box>
    );
  }
);

export function makeEmojiIcon(name: string) {
  const EmojiIcon = (props: any) => <Emoji emoji={name} {...props} />;

  EmojiIcon.displayName = name;

  return EmojiIcon;
}
