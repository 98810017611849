import React, { FC } from "react";

import { EmptyState, Table, Tbody, Text } from "Shared";

import { PathsByStatusFragment } from "@tract/common/dist/graphql";
import { AuthorPathRow } from "./AuthorPathRow";

type Props = {
  paths?: PathsByStatusFragment[];
};

export const DraftPathsTable: FC<Props> = ({ paths }) => {
  if (!paths?.length) {
    return (
      <EmptyState
        image="draft"
        headline="Nothing in the works"
        maxWidth={[null, null, "50%"]}
        mx="auto"
        mt={10}
      >
        <Text fontSize="md" color="gray.600">
          This is where your draft paths live
        </Text>
      </EmptyState>
    );
  }

  return (
    <Table width="100%" mt={10}>
      <Tbody>
        {paths?.map((path, i) => (
          <AuthorPathRow key={path.id} path={path} />
        ))}
      </Tbody>
    </Table>
  );
};
