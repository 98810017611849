import { FC, useEffect, useState } from "react";
import { LexoRank } from "lexorank";

import { useQuery, useMutation } from "urql";
import { COLLECTIONS_QUERY } from "../../Pages/AdminDashboard/AdminCollections/graphql";
import { INSERT_PATH_COLLECTION_ITEM } from "Components/ClassroomSavedPaths";
import { CollectionAddOrEditModal } from "./AddOrEditModal";

import {
  CurrentUserFragment,
  InsertPathCollectionItemMutation,
  InsertPathCollectionItemMutationVariables,
  PathViewerPathFragment,
  PathCardFragment,
  CollectionsForAdminQuery,
} from "@tract/common/dist/graphql";

import {
  Button,
  ButtonOutlined,
  HStack,
  IconPlus,
  Link,
  Modal,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  useDisclosure,
  VStack,
  Spinner,
  EmptyState,
  SearchInput,
} from "Shared";
import { getDisplayName } from "Types/User";

type Props = {
  onClose: () => void;
  isOpen: boolean;
  currentUser: CurrentUserFragment;
  path: PathViewerPathFragment | PathCardFragment;
};

export const CollectPathModal: FC<Props> = ({
  onClose,
  path,
  currentUser,
  isOpen,
}) => {
  const toast = useToast();
  const [adding, setAdding] = useState([] as string[]);
  const {
    isOpen: createModalIsOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const [searchFilter, setSearchFilter] = useState("");
  const [{ fetching, error, data }] = useQuery<CollectionsForAdminQuery>({
    query: COLLECTIONS_QUERY,
    pause: !isOpen,
  });

  const [, insertPathCollectionItem] = useMutation<
    InsertPathCollectionItemMutation,
    InsertPathCollectionItemMutationVariables
  >(INSERT_PATH_COLLECTION_ITEM);

  async function handleAdd({
    pathId,
    collection,
  }: {
    pathId: number;
    collection: CollectionsForAdminQuery["path_collection"][number];
  }) {
    let nextLexorank = LexoRank.middle();
    if (collection.pathCollectionItems.length > 0) {
      const lastItem =
        collection.pathCollectionItems[
          collection.pathCollectionItems.length - 1
        ];
      nextLexorank = LexoRank.parse(lastItem.lexorank).genNext();
    }
    const collectionId = collection.id;
    setAdding([...adding, collectionId]);
    try {
      const output = await insertPathCollectionItem({
        pathId,
        collectionId,
        lexorank: nextLexorank.format(),
      });
      if (output.error) throw new Error(output.error.message);
      // TODO: There may be some kind of caching problem that allows adding a path to a
      // collection multiple times in the UI, but resulting in an error. Can't reproduce.
      // Watch for it in QA.
    } catch (err) {
      toast({
        title: "An error occurred.",
        description: "Unable to save to collection.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setAdding(adding.filter((x) => x !== collectionId));
  }

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load paths.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const filteredCollections = data?.path_collection?.filter((collection) => {
    if (!searchFilter) return true;

    return collection?.title
      ?.toLowerCase()
      .includes(searchFilter.toLowerCase());
  });

  function closeHandler() {
    setSearchFilter("");
    onClose();
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeHandler}
        size="lg"
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add to Collection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SearchInput
              query={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
              onClear={() => setSearchFilter("")}
              placeholder="Search collections..."
              marginBottom={6}
            />

            <VStack gap={3} py={0}>
              {fetching && (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand"
                  size="xl"
                  my={10}
                />
              )}

              {!fetching && filteredCollections?.length === 0 && (
                <EmptyState headline="No collections found" />
              )}

              {!fetching &&
                filteredCollections?.map((collection) => {
                  const added = !!collection.pathCollectionItems.find(
                    (pc) => pc.pathId === path.id
                  );

                  return (
                    <HStack
                      width="full"
                      justifyContent="space-between"
                      key={collection.id}
                    >
                      <VStack align="left">
                        <Link
                          fontWeight="bold"
                          fontSize="lg"
                          to={`/collection/${collection.id}`}
                        >
                          {collection.title}
                        </Link>
                        <Text style={{ marginTop: 0 }}>
                          {getDisplayName(collection.user)} &middot;{" "}
                          {collection.pathCollectionItems?.length} paths
                        </Text>
                      </VStack>
                      <Button
                        colorScheme="brandFull"
                        size="lg"
                        isLoading={adding.includes(collection.id)}
                        disabled={added}
                        onClick={() =>
                          handleAdd({
                            pathId: path.id,
                            collection,
                          })
                        }
                      >
                        {added ? "Added" : "Add"}
                      </Button>
                    </HStack>
                  );
                })}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <ButtonOutlined
              width="full"
              onClick={onCreateModalOpen}
              leftIcon={<IconPlus />}
              size="lg"
            >
              Create a new collection
            </ButtonOutlined>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <CollectionAddOrEditModal
        isOpen={createModalIsOpen}
        collectionMetadata={{
          create: { userId: currentUser.id, pathId: path.id },
        }}
        onClose={onCreateModalClose}
      />
    </>
  );
};
