import React, { FC } from "react";

import { Tr, Td, Link } from "Shared";

import { AdminShopItemPurchaseFragment } from "@tract/common/dist/graphql";

type Props = {
  redemption: AdminShopItemPurchaseFragment;
};

export const PrizeRedemptionsRow: FC<Props> = ({ redemption }) => {
  const { user, shopItem, createdAt, status, coinTrx } = redemption;

  return (
    <Tr>
      <Td py={4} pr={10}>
        <Link to={`/@${user.username}`} fontWeight="bold">
          {user.username}
        </Link>
      </Td>
      <Td py={4} pr={10}>
        <Link to={`/shop/item/${shopItem.id}`}>{shopItem.name}</Link>
      </Td>
      <Td py={4} pr={10}>
        {coinTrx.amount}
      </Td>
      <Td py={4} pr={10}>
        {new Date(createdAt).toLocaleString()}
      </Td>
      <Td py={4} pr={10}>
        {status}
      </Td>
    </Tr>
  );
};
