import React, { FC } from "react";
import { format } from "date-fns";
import { useDocument } from "Utils/hooks/firestore";

import { AspectRatio, Image, Text, useDisclosure } from "Shared";
import { UserAwardModal } from "Components/UserAwardModal";
import { UserAwardCardLayout } from "./UserAwardCardLayout";

import { Achievement } from "Types/Achievement";
import { UserAchievement } from "Types/UserAchievement";

type Props = {
  userAchievements: UserAchievement[];
};

export const UserAchievementCard: FC<Props> = ({ userAchievements }) => {
  const achievementId = userAchievements[0].achievementId;
  const { data: achievement } = useDocument<Achievement>(
    `achievements/${achievementId}`
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!achievement) return null;

  return (
    <>
      <UserAwardCardLayout aria-label={achievement.title} onClick={onOpen}>
        <AspectRatio ratio={1} height="3rem" width="3rem" mb={3}>
          <Image src={achievement.imageURL} alt={achievement.title} />
        </AspectRatio>
        <Text fontSize="lg" fontWeight="bold">
          {achievement.title}
        </Text>
        {userAchievements.length > 1 && (
          <Text>
            {userAchievements.length} Time{userAchievements.length > 1 && "s"}
          </Text>
        )}
      </UserAwardCardLayout>
      <UserAwardModal
        title={achievement.title}
        subTitle={
          userAchievements[0]
            ? achievement.descriptionAwarded
            : achievement.descriptionNotAwarded
        }
        subHead={format(userAchievements[0].createdAt?.toDate(), "MMM d, yyyy")}
        imageURL={achievement.imageURL}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
