import { FC, useEffect } from "react";

import {
  Box,
  EmptyState,
  ExternalLink,
  Flex,
  Link,
  Select,
  VStack,
  Text,
  Divider,
  Avatar,
  HStack,
  LinkBox,
  LinkOverlay,
} from "Shared";

// @ts-ignore
import Clamp from "react-multiline-clamp";

import { ForumPostCard } from "./ForumPostCard";
import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";
import { SideNavGroupLabel } from "Components/SideNav/SideNavGroupLabel";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { LayoutCentered } from "Components/LayoutCentered";
import { ForumBanner } from "./ForumBanner";

import {
  useForumSpaces,
  useForumSpaceGroups,
  useForumPosts,
  ForumSpaceGroupFragment,
  ForumSpaceFragment,
  ForumPostFragment,
  FORUM_URL,
} from "Services/forum";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { BoxProps } from "@chakra-ui/react";

const filterPosts = (posts: ForumPostFragment[]): ForumPostFragment[] =>
  posts.filter((post) => !post.hide_meta_info);

export const Community: FC = () => {
  const matchSpace = useRouteMatch<{ spaceId: string }>({
    path: `/community/:spaceId`,
  });

  const { data: spacesData } = useForumSpaces();

  const { data: spaceGroupData } = useForumSpaceGroups();

  const spaceGroups = spaceGroupData?.circle_space_groups || [];
  const spaces = spacesData?.circle_spaces || [];

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      mx="auto"
      maxW="64rem"
      px={{ base: 4, lg: 10 }}
    >
      <SideNav zIndex={9} borderRight="none" py={6}>
        <VStack spacing={4} align="stretch" px={2}>
          <SideNavLink as={Link} to={`/community`} isActive={!matchSpace}>
            🏠 Home
          </SideNavLink>
          {spaceGroups.map((spaceGroup) => (
            <Box as="nav" key={spaceGroup.id}>
              <SideNavGroupLabel>{spaceGroup.name}</SideNavGroupLabel>
              <VStack align="stretch" spacing={1}>
                {spaceGroup.space_order_array.map((spaceId) => {
                  const space = spaces.find((s) => s.id === spaceId);

                  return (
                    <SideNavLink
                      key={spaceId}
                      as={Link}
                      to={`/community/${spaceId}`}
                      isActive={
                        matchSpace
                          ? parseInt(matchSpace.params.spaceId, 10) === spaceId
                          : false
                      }
                    >
                      {space?.emoji} {space?.name}
                    </SideNavLink>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </VStack>
      </SideNav>
      <Box ml={{ base: 0, lg: 6 }} flex={1}>
        <Switch>
          <Route path="/community" exact>
            <CommunityFeed spaces={spaces} spaceGroups={spaceGroups} />
          </Route>
          <Route path="/community/:spaceId">
            <CommunitySpace spaces={spaces} />
          </Route>
        </Switch>
      </Box>
    </Flex>
  );
};

type MobileMenuProps = {
  spaceGroups: ForumSpaceGroupFragment[];
  spaces: ForumSpaceFragment[];
};

const MobileMenu: FC<MobileMenuProps> = ({ spaceGroups, spaces }) => {
  const history = useHistory();

  const handleChange = (e: any) => {
    const spaceId = e.target.value;

    if (spaceId) {
      history.push(`/community/${spaceId}`);
    }
  };

  return (
    <Select size="lg" variant="filled" onChange={handleChange}>
      <option>Select a category...</option>
      {spaceGroups.map((spaceGroup) => (
        <optgroup key={spaceGroup.id} label={spaceGroup.name}>
          {spaceGroup.space_order_array.map((spaceId) => {
            const space = spaces.find((s) => s.id === spaceId);
            return (
              <option key={spaceId} value={spaceId}>
                {space?.name}
              </option>
            );
          })}
        </optgroup>
      ))}
    </Select>
  );
};

type CommunityFeedProps = {
  spaceGroups: ForumSpaceGroupFragment[];
  spaces: ForumSpaceFragment[];
};

export const CommunityFeed: FC<CommunityFeedProps> = ({
  spaceGroups,
  spaces,
}) => {
  const { track } = useAnalytics();
  const { data, loading } = useForumPosts();

  useEffect(() => {
    track(ANALYTICS_EVENTS.FORUM_VIEWED);
  }, [track]);

  const posts = data?.circle_posts || [];

  return (
    <VStack
      direction="column"
      align="stretch"
      spacing={3}
      flex={1}
      pt={{ base: 4, lg: 6 }}
      pb={20}
    >
      <ForumBanner />
      <Box display={{ lg: "none" }}>
        <MobileMenu spaceGroups={spaceGroups} spaces={spaces} />
      </Box>
      {loading && <LayoutCentered isLoading height="auto" />}
      {filterPosts(posts).map(
        (post) => post && <ForumPostCard key={post.id} post={post} />
      )}
    </VStack>
  );
};

export const MiniCommunityHomeFeed: FC<BoxProps> = (props) => {
  const { track } = useAnalytics();
  const { data, loading } = useForumPosts();
  const posts = data?.circle_posts || [];

  const onPostClick = (post: ForumPostFragment) => {
    track(ANALYTICS_EVENTS.FORUM_POST_CLICKED, {
      forumSpaceName: post.space_name,
      forumPostId: post.id,
      forumPostUrl: post.url,
      forumPostName: post.name,
      forumPostUserName: post.user_name,
    });
  };

  return (
    <Box
      overflow="hidden"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="xl"
      mt={{ base: undefined, xl: 4 }}
      minW={{ base: "full", md: "unset", xl: "23rem" }}
      {...props}
    >
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={6}
        py={4}
      >
        <Text
          as={ExternalLink}
          href={FORUM_URL}
          target="_blank"
          fontSize="xl"
          fontWeight="bold"
          color="gray.900"
        >
          Teacher's Lounge
        </Text>
        <ExternalLink
          color="brand"
          fontWeight="bold"
          target="_blank"
          href={FORUM_URL}
        >
          See More
        </ExternalLink>
      </Flex>
      <Divider mx={-6} w="auto" />
      {loading ? (
        <LayoutCentered isLoading height="auto" />
      ) : (
        <VStack padding="1.25rem 1.5rem 1.5rem 1.25rem" spacing={4}>
          {filterPosts(posts)
            .slice(0, 3)
            .map((post) => (
              <LinkBox
                as="article"
                key={post.id}
                onClick={() => onPostClick(post)}
              >
                <HStack spacing={3} align="start">
                  <Avatar name={post.user_name} src={post.user_avatar_url} />
                  <VStack align="start" spacing={1}>
                    <Link
                      as={LinkOverlay}
                      href={post.url}
                      target="_blank"
                      fontWeight="bold"
                      _hover={{ textDecoration: "none" }}
                    >
                      {post.name}
                    </Link>
                    <Clamp lines={2}>
                      <Box
                        dangerouslySetInnerHTML={{ __html: post.body.body }}
                      />
                    </Clamp>
                  </VStack>
                </HStack>
              </LinkBox>
            ))}
        </VStack>
      )}
    </Box>
  );
};

type CommunitySpaceProps = {
  spaces: ForumSpaceFragment[];
};

export const CommunitySpace: FC<CommunitySpaceProps> = ({ spaces }) => {
  const { track } = useAnalytics();
  const { spaceId } = useParams<{ spaceId: string }>();
  const space = spaces.find((s) => s.id === parseInt(spaceId, 10));

  useEffect(() => {
    if (!space) return;
    track(ANALYTICS_EVENTS.FORUM_SPACE_VIEWED, {
      forumSpaceId: space?.id,
      forumSpaceName: space?.name,
    });
  }, [track, space]);

  const handlePostClick = (post: ForumPostFragment) => {
    track(ANALYTICS_EVENTS.FORUM_POST_CLICKED, {
      forumSpaceId: space?.id,
      forumSpaceName: space?.name,
      forumPostId: post.id,
      forumPostName: post.name,
      forumPostUrl: post.url,
      forumPostUserName: post.user_name,
    });
  };

  const { data, loading } = useForumPosts({
    spaceId,
    page: "1",
  });

  const posts = data?.circle_posts || [];

  return (
    <>
      <Box pt={{ base: 4, lg: 6 }} pb={4}>
        <Box mb={2} display={{ lg: "none" }}>
          <Link to="/community" fontSize="md" color="gray.600">
            Teacher's Lounge
          </Link>
        </Box>
        <Box as="h1" fontSize="3xl" fontWeight="bold">
          {space?.emoji} {space?.name}
        </Box>
      </Box>
      <VStack direction="column" align="stretch" spacing={3} flex={1} pb={20}>
        {!loading && !posts.length && (
          <EmptyState headline="No posts yet">
            <ExternalLink
              href={FORUM_URL}
              target="_blank"
              color="brand"
              fontWeight="bold"
            >
              Create a post
            </ExternalLink>{" "}
            to get the discussion started!
          </EmptyState>
        )}
        {loading && <LayoutCentered isLoading height="auto" />}
        {posts.map(
          (post) =>
            post && (
              <ForumPostCard
                key={post.id}
                post={post}
                onClick={() => handlePostClick(post)}
              />
            )
        )}
      </VStack>
    </>
  );
};
