import { FC, useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Tag,
} from "@chakra-ui/react";

import { usePathViewerQuery } from "@tract/common/dist/graphql";

import {
  AspectRatio,
  Box,
  Button,
  Center,
  ExternalLink,
  Flex,
  FontAwesomeIcon,
  Grid,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  Markdown,
  Text,
  VStack,
} from "Shared";

import { PageApp } from "Components/PageApp";
import { useSession } from "Services/session";
import { PathCover } from "Components/PathCover";
import { TeacherGuide, TEACHER_GUIDES } from "./teacherGuides";
import { useDocument } from "Utils/hooks/firestore";
import { ViewSource } from "Types/Path";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

export const TeachingResources = () => {
  const { data } = useDocument<{ paths: string[] }>(`enums/video-tutorials`);
  const { track } = useAnalytics();
  const [didTrackEvent, setDidTrackEvent] = useState<boolean>(false);

  useEffect(() => {
    if (didTrackEvent) return;

    track(ANALYTICS_EVENTS.TEACHING_RESOURCES_VIEWED);
    setDidTrackEvent(true);
  }, [track, didTrackEvent]);

  const handleViewMoreGuidesClick = () => {
    track(ANALYTICS_EVENTS.PROJECT_GUIDE_VIEW_MORE_CLICKED);
  };

  return (
    <PageApp pt={{ base: 6, lg: 16 }} pb={{ base: 10, lg: 24 }}>
      <Box mb={6}>
        <Text fontSize="3xl" fontWeight="bold">
          Project Guides
        </Text>
        <Text color="gray.600" mt={1}>
          Helpful examples for integrating Tract projects into your classroom
        </Text>
      </Box>
      <Grid
        templateColumns={{
          base: "100%",
          md: "repeat(2, minmax(0, 1fr))",
          lg: "repeat(3, minmax(0, 1fr))",
          xl: "repeat(4, minmax(0, 1fr))",
        }}
        gap={3}
      >
        {TEACHER_GUIDES.map((guide, i) => (
          <GuideCard key={i} guide={guide} />
        ))}
      </Grid>
      <Center mt={10}>
        <Button
          variant="ghost"
          colorScheme="brandFull"
          size="xl"
          rightIcon={
            <FontAwesomeIcon icon={regular("arrow-up-right-from-square")} />
          }
          as={ExternalLink}
          href="https://help.tract.app/en/articles/6439333-tract-project-guides"
          target="_blank"
          onClick={handleViewMoreGuidesClick}
        >
          See more project guides
        </Button>
      </Center>
      <Box mt={16} mb={6}>
        <Text fontSize="3xl" fontWeight="bold">
          Video Tutorials
        </Text>
      </Box>
      <VStack spacing={6} align="stretch">
        {data?.paths.map((slug: string, i: number) => (
          <PathTutorialAccordion key={slug} slug={slug} isExpanded={i === 0} />
        ))}
      </VStack>
    </PageApp>
  );
};

const GuideCard: FC<{ guide: TeacherGuide }> = ({ guide }) => {
  const { track } = useAnalytics();

  const handleGuideClick = () => {
    track(ANALYTICS_EVENTS.PROJECT_GUIDE_CLICKED, { guideTitle: guide.title });
  };

  return (
    <LinkBox
      border="1px solid"
      borderColor="gray.300"
      borderRadius="2xl"
      overflow="hidden"
      position="relative"
      transition="150ms ease box-shadow"
      _hover={{ boxShadow: "md" }}
    >
      <Flex direction="column" align="flex-start" h="full" p={6}>
        <HStack align="center" justify="stretch" spacing={1}>
          <Box color={guide.color}>
            <FontAwesomeIcon icon={guide.icon} size={5} />
          </Box>
          <Text
            fontSize="xs"
            textTransform="uppercase"
            color="gray.600"
            fontWeight="bold"
          >
            {guide.subjectArea}
          </Text>
        </HStack>
        <LinkOverlay
          fontSize="xl"
          fontWeight="bold"
          as={ExternalLink}
          target="_blank"
          href={guide.guideURL}
          mt={3}
          _hover={{ textDecoration: "none" }}
          onClick={handleGuideClick}
        >
          {guide.title}
        </LinkOverlay>
        <Text color="gray.600" mt={1} flex={1}>
          {guide.description}
        </Text>
        <Box mt={4}>
          <Tag>Grades {guide.gradeLevel}</Tag>
        </Box>
      </Flex>
    </LinkBox>
  );
};

const PathTutorialAccordion: FC<{ slug: string; isExpanded?: boolean }> = ({
  slug,
  isExpanded = false,
}) => {
  const { currentUser } = useSession();

  const { data } = usePathViewerQuery({
    skip: !slug,
    fetchPolicy: "network-only",
    variables: {
      userId: currentUser.uid,
      where: {
        slug: { _eq: slug },
      },
    },
  });

  const path = data?.path[0];

  if (!path) return null;

  return (
    <Accordion
      variant="enclosed"
      allowToggle
      defaultIndex={isExpanded ? 0 : undefined}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton>
              <Flex
                direction="row"
                alignItems="flex-start"
                textAlign="start"
                w="full"
              >
                <AspectRatio ratio={16 / 9} w={{ base: "6rem", md: "10rem" }}>
                  <PathCover
                    cover={path.coverFile}
                    objectFit="cover"
                    bg="gray.100"
                    borderRadius={{ base: "lg", md: "xl" }}
                  />
                </AspectRatio>
                <Box pt={{ base: 0, lg: 3 }} ml={{ base: 4, md: 6 }} flex={1}>
                  <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">
                    {path.title}
                  </Text>
                  <Box color="gray.600" mt={2} maxW="50rem">
                    <Markdown source={path.description || ""} />
                  </Box>
                </Box>
                <Box color="brand" ml={4}>
                  {isExpanded ? (
                    <FontAwesomeIcon icon={regular("angle-up")} size={8} />
                  ) : (
                    <FontAwesomeIcon icon={regular("angle-down")} size={8} />
                  )}
                </Box>
              </Flex>
            </AccordionButton>
            <AccordionPanel borderTopWidth="1px" py={0}>
              {path.nodes
                .filter((node) => !node.responsesEnabled)
                .map((node, i) => (
                  <LinkBox
                    key={node.id}
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    textAlign="start"
                    w="full"
                    py={{ base: 4, md: 6 }}
                    borderBottomWidth="1px"
                    borderBottomColor="inherit"
                    _last={{ borderBottomWidth: 0 }}
                  >
                    <AspectRatio
                      ratio={16 / 9}
                      w={{ base: "6rem", md: "10rem" }}
                    >
                      <PathCover
                        cover={node.videoFile}
                        objectFit="cover"
                        bg="gray.100"
                        borderRadius={{ base: "lg", md: "xl" }}
                      />
                    </AspectRatio>
                    <Box
                      pt={{ base: 0, lg: 3 }}
                      ml={{ base: 4, md: 6 }}
                      flex={1}
                    >
                      <LinkOverlay
                        as={Link}
                        to={{
                          pathname: `/node/${node.id}`,
                          state: { source: ViewSource.TeachingResources },
                        }}
                        fontSize="lg"
                        fontWeight="bold"
                        color="brand"
                      >
                        {i + 1}. {node.title}
                      </LinkOverlay>
                      <Box color="gray.600" mt={2} maxW="50rem">
                        <Markdown source={node.caption || ""} />
                      </Box>
                    </Box>
                    <Box color="brand" display={{ base: "none", md: "block" }}>
                      <FontAwesomeIcon
                        icon={regular("arrow-up-right-from-square")}
                        size={8}
                      />
                    </Box>
                  </LinkBox>
                ))}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
