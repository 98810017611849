import { useState } from "react";
import { useMutation } from "urql";
import { useFormikContext } from "formik";
import { Button, useToast } from "@chakra-ui/react";
import { parse } from "date-fns";
import { Flex } from "Shared";

import {
  CREATE_ASSIGNMENT_MUTATION,
  UPDATE_ASSIGNMENT_MUTATION,
} from "./graphql";

import { FormValues, INPUT_DATE_FORMAT, Step } from "./_shared";

import {
  AssignBuilderPathFragment,
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables,
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables,
} from "@tract/common/dist/graphql";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { useCreateClassroomMutation } from "Services/classroom";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  activeStep: Step;
  steps: Step[];
  setStep: (step: Step) => void;
  onSaved: () => void;
  onCancel: () => void;
  path: AssignBuilderPathFragment;
};

const formatInputDateForDB = (date?: string) => {
  return date ? parse(date, INPUT_DATE_FORMAT, new Date()).toISOString() : null;
};

export const FormControls: React.FC<Props> = ({
  steps,
  activeStep,
  setStep,
  onSaved,
  onCancel,
  path,
}) => {
  const { track } = useAnalytics();
  const toast = useToast();
  const { currentUser } = useSession();
  const [isSaving, setIsSaving] = useState(false);
  const form = useFormikContext<FormValues>();
  const isFinalStep = steps.indexOf(activeStep) === steps.length - 1;
  const isFirstStep = steps.indexOf(activeStep) === 0;

  const [, createAssignment] = useMutation<
    CreateAssignmentMutation,
    CreateAssignmentMutationVariables
  >(CREATE_ASSIGNMENT_MUTATION);
  const [, updateAssignment] = useMutation<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >(UPDATE_ASSIGNMENT_MUTATION);
  const createClassroom = useCreateClassroomMutation();

  const handleNext = async () => {
    const result = await form.validateForm();

    if (Object.entries(result).length === 0) {
      setStep(steps[steps.indexOf(activeStep) + 1]);
    }
  };

  const handleBack = () => {
    setStep(steps[steps.indexOf(activeStep) - 1]);
  };

  const handleSave = async () => {
    const result = await form.validateForm();

    if (Object.entries(result).length > 0) return;

    setIsSaving(true);

    try {
      if (form.values.newClassroom) {
        try {
          const { error, data } = await createClassroom({
            name: form.values.newClassroom.name,
            userId: currentUser.id,
            orgId: currentUser.orgId || "",
          });

          if (data?.createClassroom?.id) {
            form.values.classIds.push(data.createClassroom.id);
          }

          if (error) {
            toast({
              status: "error",
              title: "Error creating classroom",
            });
            return;
          }
        } catch (err: any) {
          toast({
            status: "error",
            title: "Error creating classroom",
          });

          return;
        }
      }

      if (form.values.assignmentId) {
        await updateAssignment({
          id: form.values.assignmentId,
          object: {
            title: form.values.title,
            description: form.values.description,
            startDate: formatInputDateForDB(form.values.startDate),
            dueDate: formatInputDateForDB(form.values.dueDate),
            userId: currentUser.id,
          },
          learnerGroups: form.values.classIds.map((id) => ({
            assignmentId: form.values.assignmentId,
            learnerGroupId: id,
          })),
        });

        track(ANALYTICS_EVENTS.ASSIGNMENT_EDITED, {
          assignmentId: form.values.assignmentId,
          pathIds: [form.values.pathId],
          learnerGroupIds: form.values.classIds,
          startDate: form.values.startDate,
          dueDate: form.values.dueDate || null,
        });
      } else {
        await createAssignment({
          object: {
            title: form.values.title,
            description: form.values.description,
            startDate: formatInputDateForDB(form.values.startDate),
            dueDate: formatInputDateForDB(form.values.dueDate),
            userId: currentUser.id,
            learnerGroups: {
              data: form.values.classIds.map((id) => ({
                learnerGroupId: id,
              })),
            },
            paths: {
              data: [{ pathId: form.values.pathId }],
            },
          },
        });

        track(ANALYTICS_EVENTS.ASSIGNMENT_CREATED, {
          assignmentId: form.values.assignmentId,
          pathIds: [form.values.pathId],
          learnerGroupIds: form.values.classIds,
          startDate: form.values.startDate,
          dueDate: form.values.dueDate || null,
          pathId: path.id,
          pathSlug: path.slug,
          pathTitle: path.title,
          pathAuthorId: path.user?.id,
          pathAuthorUsername: path.user?.username,
          isTractOriginal: path.isTractOriginal,
          hasProjectGuide: !!path.guides.length,
        });
      }

      toast({
        status: "success",
        title: "Saved Assignment",
      });

      onSaved();
    } catch (err: any) {
      toast({
        status: "error",
        title: "Error saving assignment",
      });
      captureException(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Flex w="full" justifyContent="space-between">
      {isFirstStep && (
        <Button
          size="xl"
          variant="outline"
          borderRadius="full"
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
      {!isFirstStep && (
        <Button
          size="xl"
          variant="outline"
          borderRadius="full"
          disabled={isSaving}
          onClick={handleBack}
        >
          Back
        </Button>
      )}
      {isFinalStep ? (
        <Button
          size="xl"
          variant="solid"
          borderRadius="full"
          colorScheme="brandFull"
          isLoading={isSaving}
          onClick={handleSave}
        >
          Save
        </Button>
      ) : (
        <Button
          size="xl"
          variant="solid"
          borderRadius="full"
          colorScheme="brandFull"
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </Flex>
  );
};
