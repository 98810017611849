import { Dispatch, SetStateAction, FC, PropsWithChildren } from "react";
import { createContext } from "Utils";
import { useNavigationHistory } from "./hooks/useNavigationHistory";

const [Provider, useContext] = createContext<{
  locations: string[];
  index: number;
  setLocations: Dispatch<SetStateAction<string[]>>;
}>({
  strict: true,
});

export const useNavHistory = useContext;

type NavigationProps = {};

export const NavigationProvider: FC<PropsWithChildren<NavigationProps>> = ({
  children,
}) => {
  const history = useNavigationHistory();
  return <Provider value={history}>{children}</Provider>;
};
