import { createIcon } from "@chakra-ui/react";

export const IconBackdrop = createIcon({
  displayName: "IconBackdrop",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path
      d="M18 22.4062V21.4062C18 20.3454 17.5786 19.328 16.8284 18.5778C16.0783 17.8277 15.0609 17.4063 14 17.4062H10C8.93913 17.4062 7.92172 17.8277 7.17157 18.5778C6.42143 19.328 6 20.3454 6 21.4062V22.4062"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <path
      d="M12 14.4062C14.2091 14.4062 16 12.6154 16 10.4062C16 8.19711 14.2091 6.40625 12 6.40625C9.79086 6.40625 8 8.19711 8 10.4062C8 12.6154 9.79086 14.4062 12 14.4062Z"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />,
    <path
      d="M0.792904 9.7929C0.40238 10.1834 0.40238 10.8166 0.792904 11.2071C1.18343 11.5976 1.81659 11.5976 2.20712 11.2071L0.792904 9.7929ZM10.7071 2.70712C11.0976 2.3166 11.0976 1.68343 10.7071 1.29291C10.3166 0.902384 9.68342 0.902384 9.2929 1.29291L10.7071 2.70712ZM2.20712 11.2071L10.7071 2.70712L9.2929 1.29291L0.792904 9.7929L2.20712 11.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M12.7469 2.79288C12.3564 3.18341 12.3564 3.81657 12.7469 4.2071C13.1375 4.59762 13.7706 4.59762 14.1612 4.2071L12.7469 2.79288ZM15.6612 2.7071C16.0517 2.31657 16.0517 1.68341 15.6612 1.29289C15.2706 0.902362 14.6375 0.902362 14.2469 1.29289L15.6612 2.7071ZM14.1612 4.2071L15.6612 2.7071L14.2469 1.29289L12.7469 2.79288L14.1612 4.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M16.4875 3.79289C16.097 4.18341 16.097 4.81658 16.4875 5.2071C16.878 5.59762 17.5112 5.59762 17.9017 5.2071L16.4875 3.79289ZM20.4017 2.7071C20.7922 2.31658 20.7922 1.68341 20.4017 1.29289C20.0112 0.902365 19.378 0.902365 18.9875 1.29289L20.4017 2.7071ZM17.9017 5.2071L20.4017 2.7071L18.9875 1.29289L16.4875 3.79289L17.9017 5.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M0.617217 4.79289C0.226693 5.18341 0.226693 5.81658 0.617217 6.2071C1.00774 6.59763 1.64091 6.59763 2.03143 6.2071L0.617217 4.79289ZM5.53143 2.70711C5.92195 2.31658 5.92195 1.68342 5.53143 1.29289C5.1409 0.902368 4.50774 0.902368 4.11721 1.29289L5.53143 2.70711ZM2.03143 6.2071L5.53143 2.70711L4.11721 1.29289L0.617217 4.79289L2.03143 6.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M18.6172 6.79289C18.2267 7.18341 18.2267 7.81658 18.6172 8.2071C19.0077 8.59763 19.6409 8.59763 20.0314 8.2071L18.6172 6.79289ZM23.5314 4.70711C23.9219 4.31658 23.9219 3.68342 23.5314 3.29289C23.1409 2.90237 22.5077 2.90237 22.1172 3.29289L23.5314 4.70711ZM20.0314 8.2071L23.5314 4.70711L22.1172 3.29289L18.6172 6.79289L20.0314 8.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M17.6172 12.7929C17.2267 13.1834 17.2267 13.8166 17.6172 14.2071C18.0077 14.5976 18.6409 14.5976 19.0314 14.2071L17.6172 12.7929ZM23.5314 9.70711C23.9219 9.31658 23.9219 8.68342 23.5314 8.2929C23.1409 7.90237 22.5077 7.90237 22.1172 8.2929L23.5314 9.70711ZM19.0314 14.2071L23.5314 9.70711L22.1172 8.2929L17.6172 12.7929L19.0314 14.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M0.876677 14.7929C0.486153 15.1834 0.486153 15.8166 0.876678 16.2071C1.2672 16.5976 1.90037 16.5976 2.29089 16.2071L0.876677 14.7929ZM5.79089 12.7071C6.18141 12.3166 6.18141 11.6834 5.79089 11.2929C5.40036 10.9024 4.7672 10.9024 4.37667 11.2929L5.79089 12.7071ZM2.29089 16.2071L5.79089 12.7071L4.37667 11.2929L0.876677 14.7929L2.29089 16.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M5.74695 14.7929C5.35642 15.1834 5.35642 15.8166 5.74695 16.2071C6.13747 16.5976 6.77064 16.5976 7.16116 16.2071L5.74695 14.7929ZM7.66116 15.7071C8.05168 15.3166 8.05168 14.6834 7.66116 14.2929C7.27063 13.9024 6.63747 13.9024 6.24694 14.2929L7.66116 15.7071ZM7.16116 16.2071L7.66116 15.7071L6.24694 14.2929L5.74695 14.7929L7.16116 16.2071Z"
      fill="currentcolor"
    />,
    <path
      d="M20.6172 14.7929C20.2267 15.1834 20.2267 15.8166 20.6172 16.2071C21.0077 16.5976 21.6409 16.5976 22.0314 16.2071L20.6172 14.7929ZM23.5314 14.7071C23.922 14.3166 23.922 13.6834 23.5314 13.2929C23.1409 12.9024 22.5077 12.9024 22.1172 13.2929L23.5314 14.7071ZM22.0314 16.2071L23.5314 14.7071L22.1172 13.2929L20.6172 14.7929L22.0314 16.2071Z"
      fill="currentcolor"
    />,
  ],
});
