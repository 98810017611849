import { UpdateResolver } from "@urql/exchange-graphcache";

export const delete_educator_code_by_pk: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  cache.invalidate({
    __typename: "educator_code",
    code: info.variables.code,
  });
};
