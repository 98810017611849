import React, { useState } from "react";

import {
  ButtonOutlined,
  ButtonSolid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "Shared";

import {
  Maybe,
  AdminRejectedProjectFragment,
  useGetRejectedProjectByIdLazyQuery,
  useUnrejectProjectMutation,
} from "@tract/common/dist/graphql";
import { captureMessage } from "Services/errors";

type Props = {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const UnrejectModal: React.FC<Props> = ({
  projectId,
  isOpen,
  onClose,
}) => {
  const toast = useToast();

  const [unrejectProject] = useUnrejectProjectMutation();
  const [getRejectedProject] = useGetRejectedProjectByIdLazyQuery();
  const [loading, setLoading] = useState(false);

  const handleUnrejectProject = async () => {
    let project: Maybe<AdminRejectedProjectFragment> | undefined;

    try {
      setLoading(true);

      const { data } = await getRejectedProject({
        variables: {
          projectId: projectId,
        },
      });

      project = data?.project;

      if (project?.rejectedAt === "-infinity") {
        toast({
          status: "error",
          title: "There was an error",
          description:
            "Request was either already cancelled or user has already resubmitted",
        });

        return;
      }
    } catch (err) {
      toast({
        status: "error",
        title: "Error finding the submission",
      });
    } finally {
      setLoading(false);
    }

    if (!project) {
      setLoading(false);
      toast({
        status: "error",
        title: "Project not found",
      });
      return;
    }

    if (!project.rewardCoin) {
      setLoading(false);

      captureMessage("No reward coin found on project when trying to reject", {
        extra: {
          projectId: project.id,
        },
      });

      return;
    }

    try {
      await unrejectProject({
        variables: {
          projectId: project.id,
          refundAmount: project.rewardCoin?.amount,
          refundUserId: project.userId,
        },
      });
      onClose();
    } catch (err) {
      toast({
        status: "error",
        title: "There was an error",
        description:
          "Request was either already cancelled or user has already resubmitted",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancel Change Request</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to cancel the request for changes on this
          submission?
        </ModalBody>
        <ModalFooter as={HStack}>
          <ButtonOutlined size="lg" onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonSolid
            isLoading={loading}
            size="lg"
            onClick={handleUnrejectProject}
          >
            Cancel Request
          </ButtonSolid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
