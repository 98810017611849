import React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";

import { ExplorePaths } from "./ExplorePaths";
import { ExploreTag } from "Pages/Explore/ExploreTag";
import { ExploreInterestAreaUnauthed } from "Pages/Explore/ExploreInterestAreaUnauthed";
import { ContainerMarketing } from "Components/LayoutMarketing";

type ExploreProps = {};

export const ExploreUnauthed: React.FC<ExploreProps> = () => {
  return (
    <>
      <Helmet>
        <title>Explore — Tract</title>
      </Helmet>
      <ContainerMarketing pb={24}>
        <Switch>
          <Route path="/explore/tag/:tag">
            <ExploreTag authed={false} />
          </Route>
          <Route path="/explore/interest/:interestArea">
            <ExploreInterestAreaUnauthed />
          </Route>
          <Route path="/explore">
            <ExplorePaths />
          </Route>
          <Redirect path="/explore/paths" to="/explore" />
        </Switch>
      </ContainerMarketing>
    </>
  );
};
