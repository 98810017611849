import { gql } from "urql";
import { PROJECT_CARD_FRAGMENT } from "Components/ProjectCard/graphql";
import { USER_SOCIAL_ROW_FRAGMENT } from "Components/UserListModal/graphql";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";
import { LIVESTREAM_FRAGMENT } from "Pages/LivestreamGallery/graphql";
import { USER_TYPE_FRAGMENT } from "Types/User";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

export const USER_PROFILE_USER_FRAGMENT = gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment UserProfileUser on user {
    id: firestoreId
    avatar
    username
    firstName
    lastName
    isMod
    country
    bio
    interestAreas
    accomplishments
    ...UserType
    ...UserPrivacyScopes
    teacher {
      id: userId
      displayName
    }
  }
`;

export const USER_PROFILE_QUERY = gql`
  ${USER_PROFILE_USER_FRAGMENT}

  query UserProfile($where: user_bool_exp!) {
    user(where: $where) {
      ...UserProfileUser
    }
  }
`;

export const USER_SOCIAL_COUNTS_FRAGMENT = gql`
  fragment UserSocialCounts on user {
    id: firestoreId
    followers_aggregate(where: { disabled: { _eq: false } }) {
      aggregate {
        count
      }
    }
    following_aggregate(where: { disabled: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
`;

export const USER_SOCIAL_COUNTS_BY_ID_QUERY = gql`
  ${USER_SOCIAL_COUNTS_FRAGMENT}

  query UserSocialCountsById($id: String!) {
    user(where: { firestoreId: { _eq: $id } }) {
      ...UserSocialCounts
    }
  }
`;

export const USER_FOLLOWERS_FRAGMENT = gql`
  ${USER_SOCIAL_ROW_FRAGMENT}

  fragment UserFollowers on user {
    id: firestoreId
    followers(limit: $limit, where: { disabled: { _eq: false } }) {
      sourceUserId
      sourceUser {
        ...UserSocial
      }
    }
  }
`;

export const USER_FOLLOWERS_BY_ID_QUERY = gql`
  ${USER_FOLLOWERS_FRAGMENT}

  query UserFollowersById(
    $id: String!
    $currentUserId: String!
    $limit: Int = 25
  ) {
    user(where: { firestoreId: { _eq: $id } }) {
      ...UserFollowers
    }
  }
`;

export const USER_FOLLOWING_FRAGMENT = gql`
  ${USER_SOCIAL_ROW_FRAGMENT}

  fragment UserFollowing on user {
    id: firestoreId
    following(limit: $limit, where: { disabled: { _eq: false } }) {
      targetUserId
      targetUser {
        ...UserSocial
      }
    }
  }
`;

export const USER_FOLLOWING_BY_ID_QUERY = gql`
  ${USER_FOLLOWING_FRAGMENT}

  query UserFollowingById(
    $id: String!
    $currentUserId: String!
    $limit: Int = 25
  ) {
    user(where: { firestoreId: { _eq: $id } }) {
      ...UserFollowing
    }
  }
`;

export const USER_PROFILE_PROJECTS_QUERY = gql`
  ${PROJECT_CARD_FRAGMENT}

  query UserProfileProjects(
    $userId: String!
    $isPrivate: Boolean_comparison_exp
    $published: Boolean_comparison_exp
    $limit: Int!
    $offset: Int!
  ) {
    projects: project(
      order_by: { createdAt: desc }
      where: {
        userId: { _eq: $userId }
        file: { transforms: {} }
        rejectedAt: { _eq: "-infinity" }
        isPrivate: $isPrivate
        published: $published
      }
      limit: $limit
      offset: $offset
    ) {
      ...projectCard
    }
  }
`;

export const PROFILE_LIVESTREAMS_QUERY = gql`
  ${LIVESTREAM_FRAGMENT}

  query ProfileLivestreams(
    $limit: Int!
    $offset: Int!
    $hostUserId: String!
    $startDate: timestamptz!
  ) {
    livestreams: livestream(
      limit: $limit
      offset: $offset
      order_by: { startDate: asc }
      where: {
        hostUserId: { _eq: $hostUserId }
        _or: [
          { startDate: { _gte: $startDate } }
          { endDate: { _gte: $startDate } }
        ]
        disabled: { _eq: false }
      }
    ) {
      ...Livestream
    }
  }
`;

export const PROFILE_PATHS_QUERY = gql`
  ${PATH_CARD_FRAGMENT}

  query ProfilePaths($limit: Int!, $offset: Int!, $userId: String!) {
    paths: path(
      limit: $limit
      offset: $offset
      order_by: { publishedAt: desc }
      where: {
        _or: [
          { authorId: { _eq: $userId } }
          { authors: { userId: { _eq: $userId } } }
        ]
        isPublished: { _eq: true }
      }
    ) {
      ...PathCard
    }
  }
`;
