import { tabsAnatomy as parts } from "@chakra-ui/anatomy";
import type {
  PartsStyleFunction,
  SystemStyleFunction,
} from "@chakra-ui/theme-tools";
import { getColor, mode } from "@chakra-ui/theme-tools";

const variantLine: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c, orientation, isFitted } = props;
  const isVertical = orientation === "vertical";
  const borderProp =
    orientation === "vertical" ? "borderStart" : "borderBottom";
  const marginProp = isVertical ? "marginStart" : "marginBottom";

  return {
    tablist: {
      [borderProp]: "none",
      borderColor: "transparent",
      mx: 0,
      pb: "0.25rem",
    },
    tab: {
      [borderProp]: "none",
      borderColor: "transparent",
      [marginProp]: "0",
      position: "relative",
      py: 2,
      px: 3,
      ml: isFitted ? 0 : 2,
      borderRadius: "xl",
      _first: {
        ml: 0,
      },
      _after: {
        content: "''",
        display: "none",
        height: "0.25rem",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-0.25rem",
        borderRadius: "full",
      },
      _selected: {
        color: mode(`${c}.500`, `${c}.300`)(props),
        borderColor: "currentColor",
        _after: {
          bg: "currentColor",
          display: "block",
        },
        _hover: {
          bg: "none",
        },
      },
      _hover: {
        color: mode(`${c}.500`, `${c}.300`)(props),
        bg: mode(`${c}.50`, "whiteAlpha.300")(props),
      },
      _active: {
        bg: mode(`${c}.50`, "whiteAlpha.300")(props),
      },
      _disabled: {
        opacity: 0.4,
        cursor: "not-allowed",
      },
    },
  };
};

const variantSoftRounded: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c, theme } = props;
  return {
    tab: {
      ml: 2,
      borderRadius: "xl",
      fontWeight: "semibold",
      color: "gray.900",
      _selected: {
        color: getColor(theme, `${c}.500`),
        bg: getColor(theme, `${c}.50`),
      },
      _first: {
        ml: 0,
      },
    },
  };
};

const variantEnclosed: PartsStyleFunction<typeof parts> = (props) => {
  const { colorScheme: c, theme } = props;
  return {
    tab: {
      _selected: {
        borderTop: "4px solid",
        borderTopColor: getColor(theme, `${c}.500`),
        color: getColor(theme, `${c}.500`),
      },
      _first: {
        borderLeft: "none",
      },
      _last: {
        borderRight: "none",
      },
    },
  };
};

const baseStyleTab: SystemStyleFunction = (props) => {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transitionProperty: "common",
    transitionDuration: "normal",
    transition: "150ms box-shadow ease, 150ms background-color ease",
    fontWeight: "bold",
    whiteSpace: "nowrap",
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  tab: baseStyleTab(props),
  tabpanel: {
    p: 0,
  },
});

export const Tabs = {
  baseStyle,
  variants: {
    line: variantLine,
    "soft-rounded": variantSoftRounded,
    enclosed: variantEnclosed,
  },
  sizes: {
    md: {
      tab: {
        fontSize: "md",
        py: 3,
        maxHeight: "3rem",
      },
    },
    lg: {
      tab: {
        fontSize: "lg",
        py: 4,
        maxHeight: "3.5rem",
      },
    },
  },
  defaultProps: {
    variant: "line",
    size: "lg",
    colorScheme: "brandFull",
  },
};
