import { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { differenceInMinutes, getUnixTime } from "date-fns";

import { useStrictSessionContext, useSession } from "./session";

import { throttle } from "Utils/throttle";

import { isKidUser, isParentUser, isTeacherUser } from "Types/User";

import { useCoins } from "./hooks/useCoins";
import { PATH_GRADE_LEVEL_V2 } from "Constants/paths";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export enum GroupKey {
  Org = "group_orgId",
  LearnerGroup = "group_learnerGroupId",
  LearnerGroupOwner = "group_learnerGroupOwnerId",
}

export const ANALYTICS_EVENTS = {
  ASSIGNMENT_CREATED: "Assignment Created",
  ASSIGNMENT_CREATE_VIEWED: "Assignment Create Viewed",
  ASSIGNMENT_EDITED: "Assignment Edited",
  ASSIGNMENT_MARKED: "Assignment Marked",
  ASSIGNMENT_DETAILS_VIEWED: "Assignment Details Viewed",
  BOUNTIES_VIEWED: "Bounties Viewed",
  CHALLENGE_CREATED: "Challenge Created",
  CHALLENGE_DELETED: "Challenge Deleted",
  CHALLENGE_EDITED: "Challenge Edited",
  CHALLENGE_REACTION_ADDED: "Challenge Reaction Added",
  CHALLENGE_REACTION_REMOVED: "Challenge Reaction Removed",
  CHALLENGE_STARTED: "Challenge Started",
  CHALLENGE_SUBMITTED: "Challenge Submitted",
  CHALLENGE_VIEWED: "Challenge Viewed",
  CLASS_CODE_COPIED: "Class Code Copied",
  CLASS_CREATED: "Class Created",
  CLASS_EDITED: "Class Edited",
  CLASS_VIEWED: "Class Viewed",
  CLASS_ASSIGNMENTS_VIEWED: "Class Assignments Viewed",
  COMMUNITY_CALENDAR_VIEWED: "Community Calendar Viewed",
  COMMUNITY_JOINED: "Community Joined",
  COMMUNITY_LEFT: "Community Left",
  CREATOR_INTEREST_FLAGGED: "Creator Interest Flagged",
  DAILY_GIFT_CLAIMED: "Daily Gift Claimed",
  EDUCATOR_EMAIL_VERIFIED: "Educator Email Verified",
  EDUCATOR_ONBOARDING_SESSION_BOOKED: "Educator Onboarding Session Booked",
  EDUCATOR_ONBOARDING_SESSION_CLICKED: "Educator Onboarding Session Clicked",
  EDUCATOR_ONBOARDING_SESSION_SKIPPED: "Educator Onboarding Session Skipped",
  EDUCATOR_ORG_SELECTED: "Educator Org Selected",
  EDUCATOR_SIGN_UP_APPROVED: "Educator Sign Up Approved",
  EDUCATOR_SIGN_UP_COMPLETED: "Educator Sign Up Completed",
  EDUCATOR_SIGN_UP_STARTED: "Educator Sign Up Started",
  EVENTS_VIEWED: "Events Viewed",
  EVENT_ARCHIVED: "Event Archived",
  EVENT_ATTENDED: "Event Attended",
  EVENT_CREATED: "Event Created",
  EVENT_EDITED: "Event Edited",
  EVENT_PUBLISHED: "Event Published",
  EVENT_REGISTERED: "Event Registered",
  EVENT_UNPUBLISHED: "Event Unpublished",
  EVENT_WATCHED: "Event Watched",
  EXPLORE_FILTER_APPLIED: "Explore Filter Applied",
  EXPLORE_FILTER_CLICKED: "Explore Filter Clicked",
  EXPLORE_ROW_ITEM_CLICKED: "Explore Row Item Clicked",
  EXPLORE_TAG_CLICKED: "Explore Tag Clicked",
  EXPLORE_VIEWED: "Explore Viewed",
  FORUM_POST_CLICKED: "Forum Post Clicked",
  FORUM_SPACE_VIEWED: "Forum Space Viewed",
  FORUM_VIEWED: "Forum Viewed",
  HOME_DASHBOARD_VIEWED: "Home Dashboard Viewed",
  INTEGRATION_BUTTON_CLICKED: "Integration Button Clicked",
  INTEREST_VIEWED: "Interest Viewed",
  INVITES_VIEWED: "Invites Viewed",
  INVITE_CODE_COPIED: "Invite Code Copied",
  INVITE_COLLEAGUE_CLICKED: "Invite Colleague Clicked",
  INVITE_COLLEAGUE_SENT: "Invite Colleague Sent",
  INVITE_COLLEAGUE_SOCIAL_CLICKED: "Invite Colleague Social Clicked",
  INVITE_GENERATED: "Invite Generated",
  KID_ADDED: "Kid Added",
  LEADERBOARD_VIEWED: "Leaderboard Viewed",
  SUBJECT_VIEWED: "Subject Viewed",
  LEARNER_SIGN_UP_STARTED: "Learner Sign Up Started",
  LEARNER_SIGN_UP_COMPLETED: "Learner Sign Up Completed",
  LEARNER_ONBOARDING_COMPLETED: "Learner Onboarding Completed",
  LIVESTREAM_CHAT_MESSAGE_SENT: "Livestream Chat Message Sent",
  LIVESTREAM_REMINDER_SET: "Livestream Reminder Set",
  LIVESTREAM_VIEWED: "Livestream Viewed",
  MISSION_COMPLETED: "Mission Completed",
  MISSION_CREATED: "Mission Created",
  MISSION_DELETED: "Mission Deleted",
  MISSION_EDITED: "Mission Edited",
  MISSION_VIDEO_DELETED: "Mission Video Deleted",
  MISSION_VIDEO_UPLOADED: "Mission Video Uploaded",
  MISSION_VIEWED: "Mission Viewed",
  MY_KIDS_VIEWED: "My Kids Viewed",
  MY_PATHS_VIEWED: "My Paths Viewed",
  ONBOARDING_COMPLETED: "Onboarding Completed",
  ONBOARDING_STARTED: "Onboarding Started",
  ORGANIZATION_VIEWED: "Organization Viewed",
  PASSWORD_RESET_COMPLETED: "Password Reset Completed",
  PASSWORD_RESET_SENT: "Password Reset Sent",
  PATH_ADDED: "Path Added",
  PATH_ANSWER_LIKED: "Path Question Answer Liked",
  PATH_ARCHIVED: "Path Archived",
  PATH_COMPLETED: "Path Completed",
  PATH_CREATED: "Path Created",
  PATH_FILTER_VIEWED: "Path Filter Viewed",
  PATH_PUBLISHED: "Path Published",
  PATH_QUESTION_ANSWERED: "Path Question Answered",
  PATH_QUESTION_ANSWER_RECEIVED: "Path Question Answer Received",
  PATH_QUESTION_ASKED: "Path Question Asked",
  PATH_QUESTION_LIKED: "Path Question Liked",
  PATH_REJECTED: "Path Rejected",
  PATH_REJECTION_RECEIVED: "Path Rejection Received",
  PATH_REPORTED: "Path Reported",
  PATH_REVIEW_CANCELED: "Path Review Canceled",
  PATH_REVIEW_REQUESTED: "Path Review Requested",
  PATH_REVIEW_SUBMITTED: "Path Review Submitted",
  PATH_SAVED: "Path Saved",
  PATH_SETTINGS_EDITED: "Path Settings Edited",
  PATH_TITLE_EDITED: "Path Title Edited",
  PATH_UNARCHIVED: "Path Unarchived",
  PATH_UNPUBLISHED: "Path Unpublished",
  PATH_UNSAVED: "Path Unsaved",
  PATH_VIEWED: "Path Viewed",
  PAYMENT_CANCELED: "Payment Canceled",
  PAYMENT_PROCESSED: "Payment Processed",
  PRIVACY_SETTINGS_EDITED: "Privacy Settings Edited",
  PRIZE_BOARD_VIEWED: "Prize Board Viewed",
  PRIZE_REDEEMED: "Prize Redeemed",
  PRIZE_VIEWED: "Prize Viewed",
  PROFILE_EDITED: "Profile Edited",
  PROFILE_FOLLOWED: "Profile Followed",
  PROFILE_REPORTED: "Profile Reported",
  PROFILE_UNFOLLOWED: "Profile Unfollowed",
  PROFILE_VIEWED: "Profile Viewed",
  PROJECTS_VIEWED: "Projects Viewed",
  PROJECT_AWARD_GIVEN: "Project Award Given",
  PROJECT_AWARD_RECEIVED: "Project Award Received",
  PROJECT_COMMENT_ADDED: "Project Comment Added",
  PROJECT_COMMENT_DELETED: "Project Comment Deleted",
  PROJECT_COMMENT_EDITED: "Project Comment Edited",
  PROJECT_COMMENT_RECEIVED: "Project Comment Received",
  PROJECT_COMMENT_REPORTED: "Project Comment Reported",
  PROJECT_GUIDE_CLICKED: "Project Guide Clicked",
  PROJECT_GUIDE_VIEW_MORE_CLICKED: "Project Guide View More Clicked",
  PROJECT_LIKED: "Project Liked",
  PROJECT_PUBLISHED: "Project Published",
  PROJECT_REPORTED: "Project Reported",
  PROJECT_VIEWED: "Project Viewed",
  RECORDING_DELETED: "Recording Deleted",
  RECORDING_KEPT: "Recording Kept",
  RECORDING_STARTED: "Recording Started",
  RECORDING_STOPPED: "Recording Stopped",
  REPORT_CONFIRMED: "Report Confirmed",
  REPORT_DISMISSED: "Report Dismissed",
  SEARCH_SUBMITTED: "Search Submitted",
  SETTINGS_VIEWED: "Settings Viewed",
  SHARE_MODAL_VIEWED: "Share Modal Viewed",
  SHARE_LINK_CLICKED: "Share Link Clicked",
  SIGN_IN_COMPLETED: "Sign In Completed",
  SIGN_IN_FAILED: "Sign In Failed",
  SIGN_UP_COMPLETED: "Sign Up Completed",
  SIGN_UP_STARTED: "Sign Up Started",
  SUBMISSION_REJECTED: "Submission Rejected",
  SUBMISSION_REJECTION_RECEIVED: "Submission Rejection Received",
  SUBMISSION_THROTTLED: "Challenge Submission Rejected",
  TAG_VIEWED: "Tag Viewed",
  TEACHER_CHECKLIST_GUIDE_CLICKED: "Teacher Checklist Guide Clicked",
  TEACHER_CHECKLIST_ITEM_CLICKED: "Teacher Checklist Item Clicked",
  TEACHER_CHECKLIST_OPENED: "Teacher Checklist Opened",
  TEACHING_RESOURCES_VIEWED: "Teaching Resources Viewed",
  VIDEO_COMMENT_ADDED: "Video Comment Added",
  VIDEO_COMMENT_DELETED: "Video Comment Deleted",
  VIDEO_COMMENT_EDITED: "Video Comment Edited",
  VIDEO_COMMENT_LIKED: "Video Comment Liked",
  VIDEO_COMMENT_UNLIKED: "Video Comment Unliked",
  VIDEO_EDITOR_RESULT_SAVED: "Video Editor Result Saved",
  VIDEO_REACTION_ADDED: "Video Reaction Added",
  VIDEO_REACTION_REMOVED: "Video Reaction Removed",
  ACCOUNT_DISABLED: "Account Disabled",
  ACCOUNT_ENABLED: "Account Enabled",
  NODE_EDITED: "Path Node Edited",
  NODE_DELETED: "Path Node Deleted",
  NODE_VIDEO_UPLOADED: "Path Node Video Uploaded",
  NODE_VIDEO_DELETED: "Path Node Video Deleted",
  NODE_CREATED: "Path Node Created",
  CLASS_ARCHIVED: "Class Archived",
  CLASS_UNARCHIVED: "Class Un-Archived",
  CLASS_ROSTER_SYNCED: "Class Roster Synced",
  CLASS_IMPORT_STARTED: "Class Import Started",
  CLASS_IMPORT_CLASSES_SELECTED: "Class Import Classes Selected",
  PROJECT_GUIDE_VIEWED: "Project Guide Viewed",
  VIDEO_PLAYBACK_STARTED: "Video Playback Started",
  VIDEO_PLAYBACK_PAUSED: "Video Playback Paused",
  VIDEO_PLAYBACK_ENDED: "Video Playback Ended",
};

/**
 * Structure a request to Segment's [HTTP API](https://segment.com/docs/connections/sources/catalog/libraries/server/http-api/)
 */
function segmentHTTPRequest(url: string, data: any) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": `application/json`,
      Authorization: `Basic ${process.env.REACT_APP_SEGMENT_HTTP_WRITE_KEY}`,
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

/**
 * Analytics client for making tracking calls directly to Segment's HTTP API.
 * This is useful in cases where you don't want to include any of the
 * session/browser context, such as tracking an event on behalf of another user.
 **/
const analyticsHTTPClient = {
  track(args: any) {
    return segmentHTTPRequest(`https://api.segment.io/v1/track`, args);
  },
};

const SESSION_EXPIRATION_MINUTES = 30;
export const SESSION_START_TIME_LS_KEY = "tract-sessionStartTime";
export const LAST_EVENT_TIME_LS_KEY = "tract-lastEventTime";

/**
 * Retrieves and maintains session start time with localStorage.
 */
const getSessionStartTime = (userId: string) => {
  // Test localstorage availability
  try {
    localStorage.setItem("testStorage", "pass");
    localStorage.removeItem("testStorage");
  } catch (err) {
    // return -1 to disable sessions
    return -1;
  }

  const now = new Date();

  if (!localStorage.getItem(SESSION_START_TIME_LS_KEY)) {
    localStorage.setItem(SESSION_START_TIME_LS_KEY, now.getTime().toString());
  }

  let sessionStartTime = new Date(
    parseInt(
      localStorage.getItem(SESSION_START_TIME_LS_KEY) ||
        now.getTime().toString(),
      10
    )
  );

  const item = localStorage.getItem(LAST_EVENT_TIME_LS_KEY);
  const lastEventTime = (item && parseInt(item, 10)) || 0;

  // Return a new sessionStartTime if last event exceeds SESSION_EXPIRATION_MINUTES
  if (
    differenceInMinutes(now, new Date(lastEventTime)) >
    SESSION_EXPIRATION_MINUTES
  ) {
    localStorage.setItem(SESSION_START_TIME_LS_KEY, now.getTime().toString());
    sessionStartTime = now;
  }

  localStorage.setItem(LAST_EVENT_TIME_LS_KEY, now.getTime().toString());

  // Periodically update last seen for online status
  throttledUpdatelastSeen(userId);

  return getUnixTime(sessionStartTime);
};

/**
 * Must be used in components that are authenticated or have a session.
 */
export function useAnalytics() {
  const context = useStrictSessionContext({ bypassStrict: true });

  return useMemo(() => {
    return {
      track(eventName: string, eventProperties?: Record<string, any>) {
        const groupProperties: any = {};
        // Attribute all learner activity to learner group and teacher
        // Overriden by eventProperties.learnerGroupId
        // and eventProperties.learnerGroupOwnerId
        if (
          context?.currentUser &&
          isKidUser(context.currentUser) &&
          context.currentUser.learnerGroups.length
        ) {
          groupProperties.group_learnerGroupId =
            context.currentUser.learnerGroups.map(
              (lgm) => `${GroupKey.LearnerGroup}:${lgm.learnerGroupId}`
            );
          groupProperties.group_learnerGroupOwnerId =
            context.currentUser.learnerGroups.map(
              (lgm) =>
                `${GroupKey.LearnerGroupOwner}:${
                  lgm.learnerGroup.members.find((m) => m.isOwner)?.userId
                }`
            );
        }

        // Add approved group fields as group keys
        if (eventProperties) {
          eventProperties = {
            ...eventProperties,
            ...groupProperties,
          } as Record<string, any>;

          // Prioritize passed in orgId instead of currentUser orgId
          if (eventProperties.orgId) {
            eventProperties.group_orgId = `${GroupKey.Org}:${eventProperties.orgId}`;
          } else if (context && context.currentUser.orgId) {
            eventProperties.orgId = context.currentUser.orgId;
            eventProperties.group_orgId = `${GroupKey.Org}:${context.currentUser.orgId}`;
          }

          if (eventProperties.learnerGroupId) {
            eventProperties.group_learnerGroupId = `${GroupKey.LearnerGroup}:${eventProperties.learnerGroupId}`;
          }

          if (eventProperties.learnerGroupOwnerId) {
            eventProperties.group_learnerGroupOwnerId = `${GroupKey.LearnerGroupOwner}:${eventProperties.learnerGroupOwnerId}`;
          }
        } else {
          eventProperties = groupProperties;
        }

        window.analytics.track(eventName, eventProperties || {}, {
          integrations: {
            ...(context && {
              Amplitude: {
                session_id: getSessionStartTime(context.currentUser.id),
              },
            }),
          },
        });
      },

      trackForUser(uid: string, eventName: string, eventProperties: object) {
        return analyticsHTTPClient.track({
          userId: uid,
          event: eventName,
          properties: eventProperties,
        });
      },

      identify(
        userId: string,
        traits?: Record<string, any>,
        options?: SegmentAnalytics.SegmentOpts
      ) {
        return window.analytics.identify(userId, traits || {}, options);
      },

      group(
        groupKey: GroupKey,
        groupId: string,
        traits?: Record<string, any>,
        options?: SegmentAnalytics.SegmentOpts
      ) {
        const groupKeyValue = `${groupKey}:${groupId}`;

        return window.analytics.group(
          groupKeyValue,
          {
            groupKey,
            [groupKey]: groupKeyValue,
            ...traits,
          },
          options
        );
      },

      reset() {
        return window.analytics.reset();
      },

      ready(callback: () => void) {
        return window.analytics.ready(callback);
      },
    };
  }, [context]);
}

export function PageTracker() {
  const { pathname } = useLocation();
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    // Ignore the initial pageview, since it's tracked by the SDK on init
    if (pageCount > 1) {
      window.analytics.page();
    }
  }, [pageCount]);

  useEffect(() => {
    // When the URL changes, increment the page count to trigger a page call
    setPageCount((c) => c + 1);
  }, [pathname]);

  return null;
}

export function IdentifyUser() {
  const { currentUser, currentKidIds, firebaseUser } = useSession();
  const { balance } = useCoins();
  const { identify, group } = useAnalytics();

  useEffect(() => {
    async function runIdentify() {
      let userTraits: TODO = {
        username: currentUser.username,
        email: currentUser.email,
        userType: currentUser.userType,
        creatorLevel: currentUser.creatorLevel,
        coinBalance: balance,
        isEmailVerified: firebaseUser.emailVerified,
        ...(currentUser.orgId && {
          organization: currentUser.orgId,
          orgId: currentUser.orgId,
          orgName: currentUser.organization?.name,
        }),
        privacyEnabled: !!currentUser.profilePrivacy,
        integrations: currentUser.integrations.map(
          (integration) => integration.provider
        ),
        ...(currentUser.teacher && {
          displayName: currentUser.teacher?.displayName,
          lowerGradeLevel: PATH_GRADE_LEVEL_V2.filter(
            (grade) => grade.value === currentUser.teacher?.lowerGradeLevel
          )[0]?.numeric,
          upperGradeLevel: PATH_GRADE_LEVEL_V2.filter(
            (grade) => grade.value === currentUser.teacher?.upperGradeLevel
          )[0]?.numeric,
          subjects: currentUser.teacher?.subjects,
        }),
      };

      if (isParentUser(currentUser)) {
        userTraits.firstName = currentUser.firstName;
        userTraits.lastName = currentUser.lastName;
        userTraits.kidsCount = currentKidIds && currentKidIds.length;
      }

      const sessionStartTime = getSessionStartTime(currentUser.id);

      if (currentUser) {
        identify(currentUser.uid, userTraits, {
          integrations: {
            Intercom: { hideDefaultLauncher: true },
            Amplitude: {
              session_id: sessionStartTime,
            },
          },
        });
      }

      if (isTeacherUser(currentUser)) {
        group(GroupKey.LearnerGroupOwner, currentUser.id, {
          userId: currentUser.id,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          orgId: currentUser.orgId,
        });
      }

      if (currentUser.orgId) {
        group(
          GroupKey.Org,
          currentUser.orgId,
          {
            name: currentUser.organization?.name,
          },
          {
            integrations: {
              Amplitude: {
                session_id: sessionStartTime,
              },
            },
          }
        );
      }
    }

    runIdentify();
  }, [balance, currentUser, currentKidIds, identify, group, firebaseUser]);

  return null;
}

const THROTTLE_TIMEOUT = 300000; // 5 minutes
// TODO: URA
const throttledUpdatelastSeen = throttle((userId: string) => {
  // db.doc(`users/${userId}`).update({
  //   lastSeen: FieldValue.serverTimestamp(),
  // });
}, THROTTLE_TIMEOUT);

/**
 * Hook to track an event exactly once per mounted component
 */
export const useTrackEventOnce = (
  event: string,
  props?: Record<string, any>,
  options?: {
    skip?: boolean;
  }
) => {
  const [didTrack, setTracked] = useState(false);
  const { track } = useAnalytics();

  useEffect(() => {
    if (!!options?.skip || didTrack) return;
    track(event, props);
    setTracked(true);
  }, [track, didTrack, event, props, options]);

  return;
};
