import React, { FC } from "react";
import { useQuery } from "urql";
import {
  Avatar,
  Box,
  ButtonSolid,
  Flex,
  HStack,
  IconButton,
  IconFlag,
  IconMoreVertical,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useToast,
  Skeleton,
  ButtonOutlined,
} from "Shared";

import { useIsMyProfile, useSession } from "Services/session";

import { ProfileBadges } from "Components/ProfileBadges";
import { FollowButton } from "../FollowButton";

import { getDisplayName, isParentUser, isTeacherUser } from "Types/User";
import { ReportReasonTypes, ReportStatus } from "Types/AbuseReport";
import { db } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { captureException } from "Services/errors";
import { useFeature } from "Services/features";
import {
  UserProfileUserFragment,
  UserSocialCountsByIdQueryResult,
  UserSocialCountsByIdQueryVariables,
} from "@tract/common/dist/graphql";
import { SocialCounts } from "../SocialCounts";

import { USER_SOCIAL_COUNTS_BY_ID_QUERY } from "../graphql";
import { KidInvitesShareCTA } from "Pages/KidInvites/KidInvitesShareCTA";

type Props = {
  user: UserProfileUserFragment;
  isMobile: boolean | undefined;
};

export const LearnerStatHeader: FC<Props> = ({ user, isMobile }) => {
  const { isMyProfile } = useIsMyProfile();
  const { currentUser } = useSession();
  const { track } = useAnalytics();
  const toast = useToast();
  const isReportProfileEnabled = useFeature("report-profile");

  const [{ data: socialCountsData, fetching: loadingSocialCounts }] = useQuery<
    UserSocialCountsByIdQueryResult["data"],
    UserSocialCountsByIdQueryVariables
  >({
    pause: !user.id,
    query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
    variables: {
      id: user.id || "",
    },
  });
  const socialCounts = !!socialCountsData?.user?.length
    ? socialCountsData.user[0]
    : undefined;

  const onProfileReport = async () => {
    try {
      await db.collection(`abuse-reports`).add({
        type: "profile",
        reporterId: currentUser.uid,
        reporteeId: user.id,
        reason: ReportReasonTypes.Offensive,
        status: ReportStatus.Pending,
      });

      track(ANALYTICS_EVENTS.PROFILE_REPORTED, {
        reporteeId: user.id,
      });

      toast({
        status: "success",
        title: "Profile Reported",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error reporting this profile",
      });
    }
  };

  const displayName = getDisplayName(user);

  return (
    <Flex direction="column" justify="center" align="center" overflow="hidden">
      <Stack
        direction="column"
        align="center"
        spacing={{ base: 2, lg: 4 }}
        width="full"
        overflow="hidden"
      >
        <Avatar src={user.avatar || ""} name={displayName || ""} size="2xl" />
        <Flex direction="column" align="center" width="full" overflow="hidden">
          <HStack spacing={2} textAlign="center" align="center" maxW="full">
            <Text
              fontSize={{ base: "2xl", md: "4xl" }}
              fontWeight="bold"
              flex="1"
              noOfLines={1}
            >
              {displayName}
            </Text>
            <ProfileBadges iconSize={6} user={user} spacing={2} flagFont="xl" />
          </HStack>
          {!isTeacherUser(user) && (
            <Box mt={2}>
              {loadingSocialCounts ? (
                <Skeleton height={3} my={1} width="4rem" />
              ) : (
                !!socialCounts && <SocialCounts socialCounts={socialCounts} />
              )}
            </Box>
          )}
        </Flex>
      </Stack>
      <HStack mt={6} transition="none">
        <KidInvitesShareCTA entryId="profile-page">
          {({ onClick }) => (
            <ButtonOutlined size="lg" onClick={onClick}>
              Share
            </ButtonOutlined>
          )}
        </KidInvitesShareCTA>
        {isMyProfile ? (
          <ButtonSolid size="lg" as={Link} to={"/settings/profile"}>
            Edit Profile
          </ButtonSolid>
        ) : (
          !isParentUser(currentUser) && (
            <FollowButton size="lg" profile={user} />
          )
        )}
        {isReportProfileEnabled && !isMyProfile && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Profile actions"
              variant="ghost"
              size="lg"
              borderRadius="full"
              icon={<IconMoreVertical />}
            />
            <MenuList>
              <MenuItem onClick={onProfileReport} icon={<IconFlag />}>
                Report
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Flex>
  );
};
