import React, { PropsWithChildren } from "react";

import { Text } from "Shared";

const headings: Record<any, "h1" | "h2" | "h3" | "h4" | "h5" | "h6"> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
};

type Props = PropsWithChildren<{
  level: number;
}>;

export const Heading: React.FC<Props> = (props) => {
  let fontSize;
  let my;

  switch (props.level) {
    case 1:
      fontSize = "3xl";
      my = 10;
      break;
    case 2:
      fontSize = "2xl";
      my = 6;
      break;
    case 3:
      fontSize = "xl";
      my = 4;
      break;
    case 4:
      fontSize = "lg";
      my = 2;
      break;
    default:
      fontSize = "md";
      my = 2;
      break;
  }

  return (
    <Text
      as={headings[props.level]}
      fontSize={fontSize}
      fontWeight="bold"
      {...props}
      my={my}
    />
  );
};
