import { AlertProps, forwardRef } from "@chakra-ui/react";

import React from "react";
import { Alert as ChakraAlert } from "@chakra-ui/react";

export { AlertIcon, AlertTitle } from "@chakra-ui/react";

export const Alert = forwardRef((props: AlertProps, ref) => {
  return <ChakraAlert borderRadius={6} ref={ref} {...props} />;
});
