import { linkState } from "Pages/CreateV4/LayoutCreateV4";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  ModalFooter,
  ButtonGroup,
  ButtonSolid,
  ButtonOutlined,
} from "Shared";

interface Props {
  when: boolean;
  onOK: () => Promise<boolean>;
  onCancel: () => Promise<boolean>;
  title: string;
  bodyText?: string;
  okText: string;
  cancelText: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export const RouterPrompt: React.FC<Props> = ({
  when,
  onOK,
  onCancel,
  title,
  bodyText,
  okText,
  cancelText,
  isOpen,
  onOpen,
  onClose,
}) => {
  const history = useHistory();
  const { state } = useLocation<linkState>();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        onOpen();
        return "useCustom";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when, onOpen]);

  const handleOK = useCallback(async () => {
    const canRoute = await onOK();
    if (canRoute) {
      history.block(() => {});
      history.push({ pathname: currentPath, state: state });
    }
    onClose();
  }, [currentPath, history, onOK, onClose, state]);

  const handleCancel = useCallback(async () => {
    const canRoute = await onCancel();
    if (canRoute) {
      history.block(() => {});
      history.push({ pathname: currentPath, state: state });
    }
    onClose();
  }, [currentPath, history, onCancel, onClose, state]);

  return (
    <Modal size="lg" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="left">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{!!bodyText?.length && <Text>{bodyText}</Text>}</ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <ButtonOutlined size="lg" onClick={handleCancel}>
              {cancelText}
            </ButtonOutlined>
            <ButtonSolid size="lg" onClick={handleOK}>
              {okText}
            </ButtonSolid>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
