import {
  Tr,
  Td,
  BadgeOutlined,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  IconTrash,
  IconMoreVertical,
  Text,
  IconButtonOutlined,
} from "Shared";

import { EducatorCodeFragment } from "@tract/common/dist/graphql";

type ClassCodeRowProps = {
  code: EducatorCodeFragment;
  menuTriggerRef: React.LegacyRef<HTMLButtonElement> | undefined;
  onSelectDelete: () => void;
};

export const ClassCodeRow: React.FC<ClassCodeRowProps> = ({
  code,
  menuTriggerRef,
  onSelectDelete,
}) => {
  const isExpired = new Date(code.expiresAt).getTime() < new Date().getTime();

  return (
    <Tr>
      <Td py={2}>{code.code}</Td>
      <Td py={2}>
        <BadgeOutlined colorScheme={isExpired ? "orange" : "green"}>
          {isExpired ? "expired" : "active"}
        </BadgeOutlined>
      </Td>
      <Td py={2}>{new Date(code.createdAt).toLocaleString()}</Td>
      <Td py={2}>{new Date(code.expiresAt).toLocaleString()}</Td>
      <Td py={2}>{code.available}</Td>
      <Td py={2} textAlign="right">
        <Menu>
          <MenuButton
            ref={menuTriggerRef}
            as={IconButtonOutlined}
            aria-label="see more actions"
            borderRadius="full"
            icon={<IconMoreVertical />}
            isLoading={false}
          />
          <MenuList>
            <MenuItem onClick={onSelectDelete}>
              <IconTrash />
              <Text ml={6} color="gray.900">
                Delete Code
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
};
