import { UpdateResolver } from "@urql/exchange-graphcache";

export const delete_learner_group_member_by_pk: UpdateResolver = (
  _,
  args,
  cache
) => {
  cache.invalidate({
    __typename: "learner_group_member",
    learnerGroupId: args.learnerGroupId,
    userId: args.userId,
  });
};
