import { createIcon } from "@chakra-ui/react";

export const IconGoogleClassroom = createIcon({
  displayName: "IconGoogleClassroom",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path d="M2.18066 4.5919H21.8193V20.9553H2.18066V4.5919Z" fill="#0F9D58" />,
    <path
      d="M16.3635 13.3229C17.0434 13.3229 17.5907 12.7715 17.5907 12.0958C17.5907 11.42 17.0393 10.8686 16.3635 10.8686C15.6878 10.8686 15.1364 11.42 15.1364 12.0958C15.1364 12.7715 15.6878 13.3229 16.3635 13.3229ZM16.3635 14.1397C15.0493 14.1397 13.6356 14.8362 13.6356 15.6985V16.594H19.0915V15.6985C19.0915 14.8362 17.6777 14.1397 16.3635 14.1397ZM7.63662 13.3229C8.31653 13.3229 8.86378 12.7715 8.86378 12.0958C8.86378 11.42 8.31239 10.8686 7.63662 10.8686C6.96086 10.8686 6.40947 11.42 6.40947 12.0958C6.40947 12.7715 6.96086 13.3229 7.63662 13.3229ZM7.63662 14.1397C6.32241 14.1397 4.90869 14.8362 4.90869 15.6985V16.594H10.3646V15.6985C10.3646 14.8362 8.95084 14.1397 7.63662 14.1397Z"
      fill="#57BB8A"
    />,
    <path
      d="M12.0021 12.2284C12.9059 12.2284 13.6397 11.4946 13.6356 10.5909C13.6356 9.68708 12.9018 8.95327 11.998 8.95741C11.0942 8.95741 10.3645 9.69122 10.3645 10.595C10.3645 11.4988 11.0983 12.2284 12.0021 12.2284ZM12.0021 13.3229C10.1614 13.3229 8.18384 14.3013 8.18384 15.5036V16.594H15.8204V15.5036C15.8204 14.2972 13.8428 13.3229 12.0021 13.3229Z"
      fill="#F7F7F7"
    />,
    <path
      d="M14.1829 19.8691H19.0915V20.9595H14.1829V19.8691Z"
      fill="#F1F1F1"
    />,
    <path
      d="M22.3666 2.41121H1.63759C0.733805 2.41121 0 3.14501 0 4.0488V21.5067C0 22.4064 0.733805 23.1402 1.63759 23.1402H22.3666C23.2703 23.1402 24.0041 22.4064 24.0041 21.5026V4.0488C24 3.14501 23.2703 2.41121 22.3666 2.41121ZM21.8193 20.9595H2.18069V4.5919H21.8193V20.9595Z"
      fill="#F4B400"
    />,
    <path
      opacity="0.2"
      d="M22.3666 2.41121H1.63759C0.733805 2.41121 0 3.14501 0 4.0488V4.18561C0 3.28183 0.733805 2.54802 1.63759 2.54802H22.3666C23.2703 2.54802 24.0041 3.28183 24.0041 4.18561V4.0488C24 3.14501 23.2703 2.41121 22.3666 2.41121Z"
      fill="white"
    />,
    <path
      opacity="0.2"
      d="M22.3666 23.0034H1.63759C0.733805 23.0034 0 22.2696 0 21.3658V21.5026C0 22.4064 0.733805 23.1402 1.63759 23.1402H22.3666C23.2703 23.1402 24.0041 22.4064 24.0041 21.5026V21.3658C24 22.2696 23.2703 23.0034 22.3666 23.0034Z"
      fill="#BF360C"
    />,
    <path
      d="M19.0832 20.9595H14.1746L16.3552 23.1402H21.2597L19.0832 20.9595Z"
      fill="url(#paint0_linear_338_3653)"
    />,
    <path
      opacity="0.2"
      d="M2.18066 4.45509H21.8193V4.5919H2.18066V4.45509Z"
      fill="#263238"
    />,
    <path
      opacity="0.2"
      d="M2.18066 20.9595H21.8193V21.0963H2.18066V20.9595Z"
      fill="white"
    />,
    <path
      d="M22.3666 2.41121H1.63759C0.733805 2.41121 0 3.14501 0 4.0488V21.5067C0 22.4064 0.733805 23.1402 1.63759 23.1402H22.3666C23.2703 23.1402 24.0041 22.4064 24.0041 21.5026V4.0488C24 3.14501 23.2703 2.41121 22.3666 2.41121Z"
      fill="url(#paint1_radial_338_3653)"
    />,
  ],
});
