import { CoinValue } from "Components/CoinValue";
import { Box, HStack, Text } from "Shared";
import {
  GetAwardsQuery,
  GetAwardsQueryVariables,
  ProjectCommentFragment,
} from "@tract/common/dist/graphql";
import { useMemo } from "react";
import { useQuery } from "urql";
import { GET_AWARDS_QUERY } from "graphql/awards";

type AwardCommentDetailsProps = {
  comment: ProjectCommentFragment;
  awardId: string;
};

export const AwardCommentDetails: React.FC<AwardCommentDetailsProps> = ({
  comment,
  awardId,
}) => {
  const [{ data }] = useQuery<GetAwardsQuery, GetAwardsQueryVariables>({
    query: GET_AWARDS_QUERY,
  });
  const award = useMemo(
    () => data?.awards.find((award) => award.id === awardId),
    [data, awardId]
  );
  return (
    <Box pr={6}>
      {comment.comment.text && (
        <Text variant="breakword" as="span">
          {comment.comment.text}
        </Text>
      )}
      {award && (
        <HStack
          fontSize="sm"
          borderRadius="lg"
          bgColor="gray.100"
          width="fit-content"
          px={3}
          py={2}
          my={2}
        >
          <Text as="span">Gave</Text>
          <img
            src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${award.filePath}`}
            alt={award.name}
            width="24px"
          />
          <Text as="span" fontWeight="bold">
            {award.name} Award
          </Text>
          <CoinValue value={award.bonus} color="brand" showPlus />
        </HStack>
      )}
    </Box>
  );
};
