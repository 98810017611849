import { MediaDoc } from "@tract/common/dist/utils/media";

export enum AwardType {
  Medal = "medal",
  Reaction = "reaction",
  Creator = "creator",
}

export interface Award extends MediaDoc {
  id: string;
  name: string;
  description: string;
  cost: number;
  bonus: number;
  type: AwardType;
  owner: string;
}
