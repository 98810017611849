import React, { useEffect, useRef, useState } from "react";

import { Progress } from "Shared";

type Props = {
  audioDevice: MediaDeviceInfo;
};

export const VuMeter: React.FC<Props> = ({ audioDevice }) => {
  const audioWorkletNodeRef = useRef<AudioWorkletNode | null>(null);
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    let _stream: MediaStream;

    navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: { deviceId: audioDevice.deviceId },
      })
      .then(async (stream) => {
        _stream = stream;
        const context = new AudioContext();
        await context.audioWorklet.addModule("/vu-processor.js");
        let microphone = context.createMediaStreamSource(stream);
        audioWorkletNodeRef.current = new AudioWorkletNode(context, "vumeter");
        const node = audioWorkletNodeRef.current;
        node.port.onmessage = (event) => {
          let _volume = 0;
          if (event.data.volume) _volume = event.data.volume;
          setVolume(Math.round(_volume * 200));
        };
        microphone.connect(node).connect(context.destination);
      });

    return () => {
      if (_stream) {
        _stream.getAudioTracks().forEach((t) => t.stop());
      }

      audioWorkletNodeRef.current?.disconnect();
      audioWorkletNodeRef.current?.port.close();
    };
  }, [audioDevice.deviceId]);

  return (
    <Progress value={volume} width="full" colorScheme="green" isAnimated />
  );
};
