import React from "react";
import {
  forwardRef,
  Progress as ChakraProgress,
  ProgressProps,
} from "@chakra-ui/react";

export const Progress = forwardRef<ProgressProps, "div">((props, ref) => (
  <ChakraProgress borderRadius="full" {...props} />
));
