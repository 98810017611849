import { createIcon } from "@chakra-ui/react";

export const IconForum = createIcon({
  displayName: "IconForum",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
    fill: "none",
  },
  path: [
    <path
      key={1}
      d="M18 12.6667C18 13.1382 17.8127 13.5903 17.4793 13.9237C17.1459 14.2571 16.6937 14.4444 16.2222 14.4444H5.55556L2 18V3.77778C2 3.30628 2.1873 2.8541 2.5207 2.5207C2.8541 2.1873 3.30628 2 3.77778 2H16.2222C16.6937 2 17.1459 2.1873 17.4793 2.5207C17.8127 2.8541 18 3.30628 18 3.77778V12.6667Z"
    />,
    <path
      key={2}
      d="M7.5207 17.9237C7.8541 18.2571 8.30628 18.4444 8.77778 18.4444H18.4444L22 22V7.77777C22 7.30627 21.8127 6.85409 21.4793 6.52069"
    />,
  ],
});
