import React, { FC, useMemo } from "react";

import {
  Box,
  Emoji,
  Grid,
  Link,
  Stack,
  Text,
  HStack,
  Flex,
  VStack,
  Markdown,
  Center,
  LinkBox,
  LinkOverlay,
  IconCheckCircle,
} from "Shared";

import { PATH_INTEREST_AREAS } from "Constants/paths";

import { CurrentUser } from "Services/session";
import { ContainerNarrow } from "Components/ContainerNarrow";
import { UserProfileUserFragment } from "@tract/common/dist/graphql";
import { Accomplishment } from "Types/Accomplishment";
import { CreatorLevel, isTeacherUser } from "Types/User";

type Props = {
  user: UserProfileUserFragment;
  currentUser: CurrentUser;
};

export const ProfileAbout: FC<Props> = ({ user, currentUser }) => {
  const isOwner = user.id === currentUser.id;
  const isTeacher = isTeacherUser(currentUser);

  // Create a map of interest areas
  const interestAreas = useMemo(() => {
    return PATH_INTEREST_AREAS.reduce((accumulator, interestArea) => {
      accumulator.set(interestArea.value, interestArea);
      return accumulator;
    }, new Map());
  }, []);

  const accomplishments: Accomplishment[] = user.accomplishments;

  const displayName = isTeacherUser(user)
    ? `${user.firstName} ${user.lastName}`
    : user.username;

  return (
    <>
      <ContainerNarrow mb={10}>
        <Stack direction="column" spacing={6}>
          <Box
            bg="gray.50"
            borderRadius="2xl"
            pt={{ base: 4, md: 8 }}
            pb={{ base: 6, md: 10 }}
            px={{ base: 4, md: 10 }}
          >
            <HStack mb={4}>
              <Text fontSize="2xl" fontWeight="bold" noOfLines={1}>
                About {displayName}
              </Text>
            </HStack>
            {user.bio?.length ? (
              <Text fontSize="md" variant="breakword">
                {user.bio}
              </Text>
            ) : isOwner ? (
              <Link to={`/settings/profile`} fontWeight="bold" color="brand">
                Add a bio
              </Link>
            ) : (
              <Text fontSize="md" color="gray.600">
                No bio yet
              </Text>
            )}
          </Box>
          {user.creatorLevel === CreatorLevel.Partner && (
            <Box
              bg="gray.50"
              borderRadius="2xl"
              pt={{ base: 4, md: 8 }}
              pb={{ base: 4, md: 8 }}
              px={{ base: 4, md: 10 }}
            >
              <Text fontSize="2xl" fontWeight="bold" mb={6}>
                Accomplishments
              </Text>
              {!!accomplishments?.length ? (
                accomplishments.map(({ title, description }, i) => (
                  <Flex flex={1} key={i}>
                    <Center
                      bg="green.100"
                      w={8}
                      h={8}
                      borderRadius="full"
                      mr={3}
                    >
                      <IconCheckCircle color="green.500" />
                    </Center>
                    <Box pt={1}>
                      <Markdown source={title} fontWeight="bold" />
                      {description && (
                        <Markdown color="gray.900" source={description} />
                      )}
                    </Box>
                  </Flex>
                ))
              ) : isOwner ? (
                <Link
                  to={`/settings/profile`}
                  fontWeight="bold"
                  color="brand"
                  pb={2}
                >
                  Add your accomplishments
                </Link>
              ) : (
                <Text fontSize="md" color="gray.600" pb={2}>
                  No accomplishments yet
                </Text>
              )}
            </Box>
          )}
          {!isTeacher && (
            <Box
              bg="gray.50"
              borderRadius="2xl"
              pt={{ base: 4, md: 8 }}
              pb={{ base: 6, md: 10 }}
              px={{ base: 4, md: 10 }}
            >
              <Text fontSize="2xl" fontWeight="bold" mb={6}>
                Interests
              </Text>
              <Grid
                templateColumns={{
                  base: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(4, minmax(0, 1fr))",
                  lg: "repeat(6, minmax(0, 1fr))",
                }}
                gap={8}
              >
                {user.interestAreas?.length ? (
                  user.interestAreas?.map((interest: string) => (
                    <LinkBox key={interest}>
                      <VStack
                        direction="column"
                        align="center"
                        textAlign="center"
                        borderRadius="xl"
                        _focusWithin={{ boxShadow: "outline" }}
                      >
                        <Box bg="gray.200" w={16} h={16} borderRadius={32}>
                          <Center height="full" width="full">
                            <Emoji
                              fontSize="3xl"
                              emoji={interestAreas.get(interest).emoji}
                              label={interestAreas.get(interest).label}
                            />
                          </Center>
                        </Box>
                        <LinkOverlay
                          as={Link}
                          to={`/explore/interest/${
                            interestAreas.get(interest).slug
                          }`}
                          fontSize="lg"
                          fontWeight="bold"
                          _hover={{ textDecoration: "none", color: "brand" }}
                          _focus={{ boxShadow: "none" }}
                        >
                          {interestAreas.get(interest).label}
                        </LinkOverlay>
                      </VStack>
                    </LinkBox>
                  ))
                ) : isOwner ? (
                  <Link
                    to={`/settings/profile`}
                    fontWeight="bold"
                    color="brand"
                  >
                    Add intersts
                  </Link>
                ) : (
                  <Text fontSize="md" color="gray.600">
                    No interests yet
                  </Text>
                )}
              </Grid>
            </Box>
          )}
        </Stack>
      </ContainerNarrow>
    </>
  );
};
