import React, { FC, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { ButtonSolid, IconPlus, Link, Tabs, TabList, TabLink } from "Shared";

import { PageHeader } from "Components/PageHeader";

import { PrizeTable } from "./PrizeTable";
import { PrizeRedemptionsTable } from "./PrizeRedemptionsTable";
import { AdminContent } from "../AdminContent";

export const AdminPrizes: FC = () => {
  const TAB_INDEXES: { [pathname: string]: number } = {
    "/admin/shop/items": 0,
    "/admin/shop/redemptions": 1,
  };
  const { pathname } = useLocation();

  const [tabIndex, setTabIndex] = useState(TAB_INDEXES[pathname]);

  function handleTabChange(i: number) {
    setTabIndex(i);
  }

  return (
    <AdminContent>
      <Tabs isManual index={tabIndex} onChange={handleTabChange}>
        <PageHeader
          title="Shop"
          renderActions={
            <ButtonSolid
              as={Link}
              to="/admin/shop/item/create"
              leftIcon={<IconPlus />}
            >
              Add Item
            </ButtonSolid>
          }
          renderTabs={
            <TabList>
              <TabLink to="/admin/shop/items">Items</TabLink>
              <TabLink to="/admin/shop/redemptions">Redemptions</TabLink>
            </TabList>
          }
        />
        <Switch>
          <Route path="/admin/shop/redemptions">
            <PrizeRedemptionsTable />
          </Route>
          <Route path="/admin/shop/items">
            <PrizeTable />
          </Route>
        </Switch>
      </Tabs>
    </AdminContent>
  );
};
