import React from "react";
import { Buffer } from "buffer";
import { ButtonProps } from "@chakra-ui/react";

import { ButtonOutlined } from "Shared";
import { IconClever } from "Shared/Icon/custom/IconClever";

import { ProviderButtonProps } from "./_shared";

import { useFeature } from "Services/features";
import { useSession } from "Services/session";

export const CleverButton: React.FC<ProviderButtonProps & ButtonProps> = ({
  linkOnly = false,
  signUpRole,
  educatorCode,
  isDisabled,
  children,
  ...props
}) => {
  const session = useSession({ bypassStrict: true });
  const isCleverSSOEnabled = useFeature("clever-sso");

  if (!isCleverSSOEnabled) {
    return null;
  }

  return (
    <ButtonOutlined
      onClick={() => {
        const qs = new URLSearchParams();
        const redirectURI = `${process.env.REACT_APP_WEB_API_ORIGIN}/v2/integrations/clever`;
        const state = JSON.stringify(
          linkOnly && session
            ? {
                linkOnly: true,
                uid: session.currentUser.uid,
              }
            : {
                signUpRole,
                educatorCode,
              }
        );

        qs.set("redirect_uri", redirectURI);
        qs.set("response_type", "code");
        qs.set("client_id", process.env.REACT_APP_CLEVER_CLIENT_ID || "");
        qs.set("state", Buffer.from(state).toString("base64"));

        window.location.href = `https://clever.com/oauth/authorize?${qs.toString()}`;
      }}
      leftIcon={<IconClever mr={1} />}
      isDisabled={isDisabled}
      color="white"
      bgColor="#4274F6"
      w="full"
      h="48px"
      _hover={{ backgroundColor: "#395cce" }}
      _active={{ backgroundColor: "#4274F6" }}
      {...props}
    >
      {children}
    </ButtonOutlined>
  );
};
