import React, { FC } from "react";

import {
  AspectRatio,
  Box,
  Grid,
  Image,
  Link,
  TextClamp,
  Text,
  Badge,
} from "Shared";

import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";

import { LivestreamFragment } from "@tract/common/dist/graphql";
import { UserFile } from "Utils/UserFile";
import { ProfilePopover } from "Components/ProfilePopover";
import { format } from "date-fns";
import { useDocument } from "Utils/hooks/firestore";
import { Livestream, ViewSource } from "Types/Livestream";
import { captureException } from "Services/errors";

type Props = {
  livestream: LivestreamFragment;
  viewSource: ViewSource;
};

export const LivestreamCard: FC<Props> = ({ livestream, viewSource }) => {
  const coverFile = new UserFile(livestream.coverFile);
  const coverUrl = coverFile.getTransformedUrl("cover_thumb");
  const date = new Date(livestream.startDate);

  const toLivestream = {
    pathname: `/livestream/${livestream.id}`,
    state: {
      viewSource: viewSource,
    },
  };

  const { data: livestreamDoc } = useDocument<Livestream>(
    livestream.id ? `/livestreams/${livestream.id}` : null,
    {
      onError: (err) => captureException(err),
    }
  );
  const isLive = livestreamDoc?.isLive;
  const alreadyStreamed =
    !isLive && new Date().getTime() > new Date(livestream.endDate).getTime();

  return (
    <Box
      _focusWithin={{ "& .showFocused": { visibility: "visible" } }}
      role="group"
    >
      <Box
        as={alreadyStreamed ? undefined : Link}
        display="block"
        position="relative"
        mb={4}
        to={toLivestream}
        tabIndex={-1}
        _focus={{ boxShadow: "none" }}
      >
        {isLive && (
          <Badge
            colorScheme="red"
            variant="solid"
            fontSize="xs"
            fontWeight="bold"
            position="absolute"
            zIndex={1}
            left={4}
            top={4}
          >
            LIVE
          </Badge>
        )}
        {alreadyStreamed && (
          <Badge
            colorScheme="gray"
            variant="solid"
            fontSize="xs"
            fontWeight="bold"
            position="absolute"
            zIndex={1}
            left={4}
            top={4}
          >
            ENDED
          </Badge>
        )}
        <AspectRatio
          ratio={16 / 9}
          borderRadius={16}
          overflow="hidden"
          bg="gray.50"
        >
          <Image
            objectFit="cover"
            opacity={alreadyStreamed ? 0.3 : undefined}
            src={coverUrl}
          />
        </AspectRatio>
      </Box>
      <Grid
        templateColumns={"auto 1fr auto"}
        gap={{ base: 2, md: 3 }}
        alignItems="flex-start"
      >
        <ProfilePopover
          userId={livestream?.hostUserId}
          isAuthor
          triggerElement={
            <UserAvatar
              username={livestream?.hostUser?.username || ""}
              avatarURL={livestream?.hostUser?.avatar || ""}
              size="sm"
            />
          }
        />
        <Box overflow="hidden">
          <TextClamp
            lines={2}
            mb={1}
            color="gray.900"
            fontWeight="bold"
            fontSize="md"
          >
            <Text
              as={alreadyStreamed ? undefined : Link}
              color={alreadyStreamed ? "gray.500" : undefined}
              to={toLivestream}
            >
              {livestream?.title}
            </Text>
          </TextClamp>
          <Text fontSize="sm" mb={1}>
            {format(date, "eee, LLL do · h:mmbbbbb")}
          </Text>
          <ProfilePopover
            userId={livestream.hostUserId}
            isAuthor
            triggerElement={
              <UsernameLink
                username={livestream?.hostUser?.username || ""}
                color="gray.900"
                fontSize="sm"
                isAuthor
              >
                {livestream?.hostUser?.username || ""}
              </UsernameLink>
            }
          />
        </Box>
      </Grid>
    </Box>
  );
};
