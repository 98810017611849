import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import {
  Box,
  Button,
  ButtonLink,
  Flex,
  IconCheck,
  Logo,
  Text,
  useToast,
  useTheme,
  Divider,
} from "Shared";

import { useSession } from "Services/session";
import { captureException, captureMessage, ERROR_TAGS } from "Services/errors";
import { firebaseClient } from "Services/firebase";
import { useSignOut } from "Services/auth";

import { LayoutMarketingVideo } from "Components/LayoutMarketingVideo";

export const VerifyUser: React.FC = () => {
  const { colors } = useTheme();
  const emailVerified = !!firebaseClient.auth().currentUser?.emailVerified;

  if (emailVerified) {
    return <Redirect to="/" />;
  }

  return (
    <LayoutMarketingVideo>
      <Flex
        direction="column"
        maxW={{ base: "100%", lg: "30rem" }}
        textAlign="center"
        alignItems="center"
        height="100%"
        width="100%"
        mx="auto"
      >
        <Logo
          color={colors.brand}
          width={{ base: "4rem", lg: "6rem" }}
          height={{ base: "2.375rem", lg: "3.5625rem" }}
          mb={{ base: 6, lg: 10 }}
        />
        <VerifyEmailStep />
      </Flex>
    </LayoutMarketingVideo>
  );
};

const VerifyEmailStep = () => {
  const toast = useToast();
  const signOut = useSignOut();
  const session = useSession({ bypassStrict: true });
  const [resending, setResending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const resendEmail = async () => {
    setResending(true);

    try {
      if (!session?.firebaseUser.emailVerified) {
        await firebaseClient.auth().currentUser?.sendEmailVerification();

        captureMessage("Email Verification Resent");

        setEmailSent(true);
      } else {
        toast({
          status: "success",
          title: "Email already verified!",
        });
      }
    } catch (error: any) {
      captureException(error, {
        tags: { feature: ERROR_TAGS.EDUCATOR_ONBOARDING },
      });

      toast({
        status: "error",
        title: "Something went wrong :(",
      });
    } finally {
      setResending(false);
    }
  };

  return (
    <Box pb={4} maxW="26rem" textAlign="center" mx="auto">
      <Text fontSize={{ base: "2xl", lg: "3xl" }} fontWeight="bold">
        Verify your email
      </Text>
      {session?.auth && (
        <>
          <Box mt={2}>
            <Text color="gray.600" fontSize="lg">
              We sent an email to{" "}
              <Text as="span" fontWeight="bold">
                {session.currentUser.email}
              </Text>
              . Check your inbox to activate your account.
            </Text>
            <Box mt={10}>
              <Button
                height={12}
                variant="outline"
                disabled={resending || emailSent}
                onClick={resendEmail}
                leftIcon={
                  emailSent ? <IconCheck color="green.500" /> : undefined
                }
                width="full"
              >
                {emailSent ? "Sent" : resending ? "Sending..." : "Resend Email"}
              </Button>
            </Box>
            <Text mt={6} color="gray.600">
              If you verified your email, please{" "}
              <ButtonLink
                fontWeight="bold"
                colorScheme="brandFull"
                onClick={() => window.location.reload()}
              >
                refresh
              </ButtonLink>
            </Text>
          </Box>
          <Divider my={10} />
          <Box textAlign="center">
            <Button variant="link" colorScheme="brandFull" onClick={signOut}>
              Sign Out
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
