import React, { FC, useEffect } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useQuery } from "urql";

import { useBreakpointValue } from "Shared";

import { useSession } from "Services/session";

import { BottomNav } from "Components/BottomNav";
import { PageApp } from "Components/PageApp";

import { ProfileContent } from "./ProfileContent";
import {
  UserProfileQuery,
  UserProfileQueryVariables,
} from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { USER_PROFILE_QUERY } from "./graphql/index";
import { usePrivacyScopesCheck } from "Services/privacy";
import { PrivateAccount } from "./PrivateAccount";

type Props = {};

// Used to wrap child views that don't make sense at full width
export const Profile: FC<Props> = () => {
  const { usernameOrId: usernameOrIdFromRoute } =
    useParams<{ usernameOrId?: string }>();
  const { profileSlug } = useSession();
  const isMe = !!useRouteMatch("/me");
  const history = useHistory();

  const isUsername = usernameOrIdFromRoute?.startsWith("@");
  const usernameOrId = usernameOrIdFromRoute?.replace("@", "");

  useEffect(() => {
    if (isMe) {
      history.replace(`/${profileSlug}`);
    }
  }, [history, profileSlug, isMe]);

  const isMobile = useBreakpointValue<boolean | undefined>({
    base: true,
    sm: false,
  });

  const [{ data, fetching }] = useQuery<
    UserProfileQuery,
    UserProfileQueryVariables
  >({
    pause: !usernameOrId,
    query: USER_PROFILE_QUERY,
    variables: {
      where: {
        ...(isUsername
          ? { username: { _eq: usernameOrId } }
          : { firestoreId: { _eq: usernameOrId } }),
      },
    },
  });

  const userLoading = fetching;
  const user = data?.user[0];

  const { isAllowed } = usePrivacyScopesCheck("profile", user);

  if (userLoading) {
    return <LayoutCentered isLoading height="auto" />;
  }

  if (!user) {
    return <Redirect to="/" />;
  }

  if (!isAllowed) {
    return <PrivateAccount />;
  }

  return (
    <PageApp isLoading={userLoading} renderBottomNav={<BottomNav />}>
      <ProfileContent user={user} isMobile={isMobile} />
    </PageApp>
  );
};
