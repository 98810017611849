import React, { FC, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import {
  Tabs,
  TabList,
  TabLink,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from "Shared";

import { PageHeader } from "Components/PageHeader";

import { PathStatus } from "@tract/common/dist/types/models/Path";
import { PathsTable } from "./PathsTable";
import { AdminContent } from "../AdminContent";
import { CreatorLevel } from "Types/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export const AdminPaths: FC = () => {
  const TAB_INDEXES: { [pathname: string]: number } = {
    "/admin/paths/published": 0,
    "/admin/paths/in-review": 1,
    "/admin/paths/drafts": 2,
    "/admin/paths/archived": 3,
  };
  const { pathname } = useLocation();
  const [creatorLevels, setCreatorLevels] = useState<string | string[]>([
    CreatorLevel.Partner,
    CreatorLevel.Affiliate,
    CreatorLevel.Creator,
    CreatorLevel.Base,
  ]);
  const [tabIndex, setTabIndex] = useState(TAB_INDEXES[pathname] || 0);
  const changeCreatorLevels = (value: string | string[]) => {
    setCreatorLevels(value);
  };
  return (
    <AdminContent>
      <Tabs isManual index={tabIndex} onChange={setTabIndex}>
        <PageHeader
          title="Paths"
          renderActions={
            <CreatorLevelFilterMenu
              onChange={changeCreatorLevels}
              defaultValue={creatorLevels}
            />
          }
          renderTabs={
            <TabList>
              <TabLink to="/admin/paths/published">Published</TabLink>
              <TabLink to="/admin/paths/in-review">In Review</TabLink>
              <TabLink to="/admin/paths/drafts">Drafts</TabLink>
              <TabLink to="/admin/paths/archived">Archived</TabLink>
            </TabList>
          }
        />

        <Switch>
          <Redirect path="/admin/paths" exact to="/admin/paths/published" />
          {/* Keys are necessary to allow component to unmount when switching routes */}
          <Route path="/admin/paths/published">
            <PathsTable
              key={1}
              pathStatus={PathStatus.Published}
              creatorLevels={creatorLevels}
            />
          </Route>
          <Route path="/admin/paths/in-review">
            <PathsTable
              key={2}
              pathStatus={PathStatus.InReview}
              creatorLevels={creatorLevels}
            />
          </Route>
          <Route path="/admin/paths/drafts">
            <PathsTable
              key={3}
              pathStatus={PathStatus.Draft}
              creatorLevels={creatorLevels}
            />
          </Route>
          <Route path="/admin/paths/archived">
            <PathsTable
              key={4}
              pathStatus={PathStatus.Archived}
              creatorLevels={creatorLevels}
            />
          </Route>
        </Switch>
      </Tabs>
    </AdminContent>
  );
};

export const CreatorLevelFilterMenu: React.FC<{
  onChange: (value: string | string[]) => void;
  defaultValue?: string | string[];
}> = ({ defaultValue, onChange }) => (
  <Menu closeOnSelect={false}>
    <MenuButton
      as={Button}
      size="lg"
      variant="outline"
      rightIcon={<FontAwesomeIcon icon={regular("angle-down")} />}
    >
      Creator Level
    </MenuButton>
    <MenuList>
      <MenuOptionGroup
        type="checkbox"
        onChange={onChange}
        defaultValue={defaultValue || [CreatorLevel.Partner]}
      >
        <MenuItemOption value={CreatorLevel.Partner}>
          {CreatorLevel.Partner}
        </MenuItemOption>
        <MenuItemOption value={CreatorLevel.Affiliate}>
          {CreatorLevel.Affiliate}
        </MenuItemOption>
        <MenuItemOption value={CreatorLevel.Creator}>
          {CreatorLevel.Creator}
        </MenuItemOption>
        <MenuItemOption value={CreatorLevel.Base}>
          {CreatorLevel.Base}
        </MenuItemOption>
      </MenuOptionGroup>
    </MenuList>
  </Menu>
);
