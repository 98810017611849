import React, { MutableRefObject, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "urql";

import {
  Box,
  Button,
  ButtonSolid,
  ButtonOutlined,
  Flex,
  FormControl,
  Textarea,
  Text,
  useToast,
  HStack,
  useBoolean,
  useDisclosure,
} from "Shared";

import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { useFeature } from "Services/features";
import { CommentItem } from "./CommentItem";

import { LayoutCentered } from "Components/LayoutCentered";
import { GiphyPopover } from "Components/GiphyPopover";
import { AwardWithText } from "Components/AwardListModal";

import {
  CreateProjectCommentMutation,
  CreateProjectCommentMutationVariables,
  ProjectCommentsQuery,
  ProjectCommentsQueryVariables,
  ProjectCardFragment,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
} from "@tract/common/dist/graphql";
import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import { CommentType } from "Types/Comment";
import { isAuthorUser } from "Types/User";
import { PendingApprovaModal } from "Components/PendingApprovalModal";
import { createNotification } from "@tract/common/dist/utils/create-notification";
import { useOffsetPaginationQuery } from "Utils";
import {
  PROJECT_COMMENTS_QUERY,
  PROJECT_CREATE_COMMENT_MUTATION,
} from "./graphql";

const MAX_COMMENT_LENGTH = 250;

const CommentSchema = Yup.object().shape({
  comment: Yup.string()
    .required("Required")
    .max(MAX_COMMENT_LENGTH, "Comment limited to 255 characters."),
});

export const PROJECT_COMMENT_LIMIT = 10;

interface Props {
  project: ProjectCardFragment;
  newAward: AwardWithText | null;
  onNewAwardAdded: () => void;
  containerRef: MutableRefObject<HTMLElement | null>;
}

export const Comments: React.FC<Props> = ({
  project,
  newAward,
  onNewAwardAdded,
  containerRef,
}) => {
  const toast = useToast();
  const analytics = useAnalytics();
  const { currentUser, isApprovedOrg } = useSession();
  const [, createComment] = useMutation<
    CreateProjectCommentMutation,
    CreateProjectCommentMutationVariables
  >(PROJECT_CREATE_COMMENT_MUTATION);
  const [isAddCommentExpanded, setIsAddCommentExpanded] = useBoolean();
  const isAwardTextCommentEnabled = useFeature("award-text-comment");
  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);

  const {
    isOpen: isPendingApprovalModalOpen,
    onOpen: openPendingApprovalModal,
    onClose: closePendingApprovalModal,
  } = useDisclosure();

  const {
    response: [{ data, fetching: loading }],
    pagination,
  } = useOffsetPaginationQuery<
    ProjectCommentsQuery,
    ProjectCommentsQueryVariables
  >({
    query: PROJECT_COMMENTS_QUERY,
    variables: {
      projectId: project.id,
      limit: PROJECT_COMMENT_LIMIT,
      offset: 0,
    },
    field: "projectComments",
  });

  const projectComments = data?.projectComments;

  useEffect(() => {
    if (!isAwardTextCommentEnabled || !newAward) return;

    handleComment({ text: newAward.text, awardId: newAward.id });

    // clear newAward to prevent this effect from running unnecessarily
    onNewAwardAdded();

    // this effect shouldn't run if reference to handleComment changes
    // eslint-disable-next-line
  }, [newAward, onNewAwardAdded]);

  async function handleSelectGif({ giphyId }: { giphyId: string }) {
    await handleComment({ giphyId });
  }

  async function handleComment(data: {
    text?: string;
    giphyId?: string;
    awardId?: string;
  }) {
    let commentId = "";

    const commentType = !!data.awardId
      ? CommentType.Award
      : !!data.giphyId
      ? CommentType.Giphy
      : CommentType.Text;

    try {
      const mutationResult = await createComment({
        projectId: project.id,
        text: data.text,
        userId: currentUser.uid,
        giphyId: data.giphyId,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
      const isProjectOwner = currentUser.uid === project.user.id;

      if (!isProjectOwner) {
        createFeedActivity({
          apiKey: process.env.REACT_APP_STREAM_API_KEY,
          feedSlug: "notification",
          feedUserId: project.user.id,
          activity: createNotification({
            type: "actor",
            actor: currentUser.id,
            verb: "comment",
            entity: "project_comment",
            entityId: commentId,
            description: "commented on your project",
            targetPath: `/project/${project.id}`,
          }),
        });
      }

      analytics.track(ANALYTICS_EVENTS.PROJECT_COMMENT_ADDED, {
        projectId: project.id,
        projectCommentCount: project.stats?.commentCount || 0,
        projectCommentType: commentType,
        isProjectOwner: isProjectOwner,
        isAuthorComment: isAuthorUser(currentUser),
      });

      if (!isProjectOwner && project.user) {
        // Fire a "Project Comment Received" event on behalf of the user who owns the project
        analytics.trackForUser(
          project.user.id,
          ANALYTICS_EVENTS.PROJECT_COMMENT_RECEIVED,
          {
            projectId: project.id,
            projectCommentType: commentType,
            isAuthorComment: isAuthorUser(currentUser),
          }
        );
      }
    } catch (err: any) {
      captureException(err);

      toast({
        title: "Something went wrong.",
        description: "Unable to post comment, try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  return (
    <Box py={6}>
      <Text as="h2" fontSize="2xl" fontWeight="bold" pr={{ base: 0, lg: 6 }}>
        {project.stats?.commentCount}{" "}
        {project.stats?.commentCount === 1 ? "Comment" : "Comments"}
      </Text>
      <Formik
        validationSchema={CommentSchema}
        initialValues={{ comment: "" }}
        onSubmit={(values, actions) => {
          if (currentUser.isEducator && !isApprovedOrg) {
            openPendingApprovalModal();
            return;
          }
          handleComment({ text: values.comment });
          actions.setSubmitting(false);
          actions.resetForm();
          setIsAddCommentExpanded.off();
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Box
            as="form"
            // @ts-ignore
            onSubmit={handleSubmit}
            py={4}
            pr={{ base: 0, lg: 6 }}
            zIndex={1}
          >
            <FormControl>
              <Textarea
                name="comment"
                onChange={handleChange}
                value={values.comment}
                maxLength={MAX_COMMENT_LENGTH}
                placeholder="Share your thoughts..."
                width="100%"
                height={isAddCommentExpanded ? "10rem" : "3rem"}
                py={3}
                transition=".3s ease height"
                size="md"
                autoComplete="off"
                borderRadius="lg"
                onFocus={() => setIsAddCommentExpanded.on()}
                onBlur={(e) => {
                  if (!e.target.value.length) {
                    setIsAddCommentExpanded.off();
                  }
                }}
                resize="none"
              />
            </FormControl>
            <HStack spacing={2} mt={3} transition=".3s ease opacity">
              <ButtonSolid
                size="md"
                type="submit"
                isDisabled={!values.comment || !!errors.comment}
              >
                Post
              </ButtonSolid>
              {currentUser.isEducator && !isApprovedOrg ? (
                <Button
                  onClick={openPendingApprovalModal}
                  variant="outline"
                  ml={1}
                  size="md"
                  color="gray.600"
                >
                  GIF
                </Button>
              ) : (
                <GiphyPopover
                  onSelectGif={handleSelectGif}
                  containerRef={containerRef}
                >
                  <Button variant="outline" ml={1} size="md" color="gray.600">
                    GIF
                  </Button>
                </GiphyPopover>
              )}
            </HStack>
          </Box>
        )}
      </Formik>
      <Box mt={6}>
        {projectComments
          ?.filter((c) => c.comment?.deletedAt === "-infinity")
          ?.filter(
            (c) =>
              c.comment?.published ||
              c.comment?.user.id === currentUser.id ||
              c.comment?.giphyId
          )
          ?.map((comment) => (
            <CommentItem
              key={comment.commentId}
              project={project}
              comment={comment}
              awardId={comment.awardId}
              containerRef={containerRef}
            />
          ))}
      </Box>
      {pagination.hasNextPage && !!projectComments?.length && (
        <Flex justify="center" mb={4}>
          <ButtonOutlined
            isLoading={pagination.loadingMore}
            onClick={pagination.loadMore}
          >
            Load More
          </ButtonOutlined>
        </Flex>
      )}

      {currentUser.isEducator && !isApprovedOrg && (
        <PendingApprovaModal
          hideCTA
          isOpen={isPendingApprovalModalOpen}
          onClose={closePendingApprovalModal}
        />
      )}
    </Box>
  );
};
