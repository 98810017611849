import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";

import {
  Alert,
  Box,
  Button,
  ButtonLink,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Link,
  Text,
  useToast,
  VStack,
} from "Shared";

import { firebaseClient } from "Services/firebase";
import { useIntercom } from "Services/intercom";
import { captureException } from "Services/errors";

import { SSOButton } from "Components/SSO/SSOButton";
import { SSOErrors } from "Components/SSO/SSOErrors";
import { PageContent } from "Components/PageContent";

import { useQuery } from "Utils";
import { Enums_Integration_Provider_Enum } from "@tract/common/dist/graphql";
import { useFeature } from "Services/features";
import { useHistory } from "react-router-dom";

type FormValues = {
  username: string;
  password: string;
};

export const SignIn: FC = () => {
  const qs = useQuery();
  const toast = useToast();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tokenSignInAttempted, setTokenSignInAttempted] = useState(false);
  const [accountDisabled, setAccountDisabled] = useState(false);
  const { isReady, show } = useIntercom();
  const isSignUpEnabled = useFeature("sign-up-enabled");

  useEffect(() => {
    if (loading || tokenSignInAttempted) return;

    const token = qs.get("token");
    const provider = qs.get("provider");

    if (qs.get("debug") !== "true") {
      return history.replace("/");
    }

    if (token) {
      setLoading(true);

      firebaseClient
        .auth()
        .signInWithCustomToken(token)
        .catch((err) => {
          captureException(err);
          toast({
            status: "error",
            title: `Error signing in with ${provider ? provider : "SSO"}`,
          });
        })
        .finally(() => {
          setTokenSignInAttempted(true);
          setLoading(false);
        });
    }
  }, [qs, loading, toast, tokenSignInAttempted, history]);

  const handleSignIn = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setLoading(true);

    const email = values.username.includes("@")
      ? values.username
      : values.username + "@tempaccounts.tract.app";

    return firebaseClient
      .auth()
      .signInWithEmailAndPassword(email, values.password)
      .catch((err) => {
        setLoading(false);

        switch (err.code) {
          case "auth/too-many-requests":
            actions.setFieldError(
              "password",
              "Too many sign-in attempts. Access has been temporarily disabled, you can try again later or click 'Forgot your password?'"
            );
            break;
          case "auth/user-disabled":
            setAccountDisabled(true);
            break;
          case "auth/user-not-found":
          case "auth/wrong-password":
          case "auth/invalid-email":
            actions.setFieldError(
              "password",
              "Sign in failed, please enter correct email/username and password"
            );
            break;
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      });
  };

  return (
    <>
      <Helmet>
        <title>Sign In — Tract</title>
      </Helmet>
      <PageContent
        pt={{ base: 10, lg: 16 }}
        pb={32}
        px={6}
        maxW="30rem"
        textAlign="center"
      >
        <Box mb={5}>
          <Text fontSize="2xl" fontWeight="bold">
            Sign In
          </Text>
        </Box>
        <VStack mb={10}>
          {[
            Enums_Integration_Provider_Enum.Google,
            Enums_Integration_Provider_Enum.Clever,
            Enums_Integration_Provider_Enum.Microsoft,
          ].map((provider) => (
            <SSOButton
              key={provider}
              provider={provider}
              isDisabled={loading}
            />
          ))}
          <SSOErrors />
        </VStack>
        <Box mb={8}>
          <Text fontSize="2xl" fontWeight="bold">
            Or use your Tract account:
          </Text>
        </Box>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={handleSignIn}
        >
          {(form) => (
            <Form>
              <VStack spacing={8}>
                <FormControl isRequired isInvalid={!!form.errors.username}>
                  <FormLabel>Email or Username </FormLabel>
                  <Field name="username">
                    {({ field }: FieldProps) => (
                      <Input
                        size="lg"
                        type="text"
                        placeholder="Email or Username"
                        variant="filled"
                        disabled={loading}
                        {...field}
                      />
                    )}
                  </Field>
                  {form.errors.username && (
                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isRequired isInvalid={!!form.errors.password}>
                  <FormLabel>Password</FormLabel>
                  <Field name="password">
                    {({ field }: FieldProps) => (
                      <Input
                        size="lg"
                        type="password"
                        placeholder="Password"
                        variant="filled"
                        disabled={loading}
                        {...field}
                      />
                    )}
                  </Field>
                  <FormHelperText textAlign="left" fontWeight="bold">
                    <Link to="/forgot-password" color="brand">
                      Forgot your password?
                    </Link>
                  </FormHelperText>
                  {form.errors.password && (
                    <FormErrorMessage textAlign="left">
                      {form.errors.password}
                    </FormErrorMessage>
                  )}
                </FormControl>
                {accountDisabled && (
                  <Alert status="error" textAlign="left">
                    <Text>
                      Account has been disabled please{" "}
                      <ButtonLink
                        color="brand"
                        onClick={() => isReady && show()}
                        display="inline"
                      >
                        contact support
                      </ButtonLink>
                    </Text>
                  </Alert>
                )}
                <Button
                  size="lg"
                  colorScheme="brandFull"
                  variant="solid"
                  w="full"
                  type="submit"
                  isLoading={loading}
                >
                  Sign In
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
        {isSignUpEnabled && (
          <HStack fontSize="md" mt={10} spacing={2} justifyContent="center">
            <Text>Don't have an account?</Text>
            <Link to="/get-started" fontWeight="bold" color="brand">
              Sign Up
            </Link>
          </HStack>
        )}
      </PageContent>
    </>
  );
};
