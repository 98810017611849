import { Text, TextProps, forwardRef } from "@chakra-ui/react";

type TextClampProps = TextProps & {
  lines: number;
};

/**
 * CSS-only solution for truncating text to multiple lines. Supported in all major browsers.
 * https://caniuse.com/?search=line-clamp
 * https://css-tricks.com/almanac/properties/l/line-clamp/
 */
export const TextClamp = forwardRef<TextClampProps, "p">(
  ({ lines, ...props }, ref) => {
    return (
      <Text
        display="-webkit-box"
        overflow="hidden"
        textOverflow="ellipsis"
        css={{
          "-webkit-line-clamp": lines.toString(),
          "-webkit-box-orient": "vertical",
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
