import { useRef, useState, PropsWithChildren } from "react";

import {
  Box,
  Button,
  ExternalLink,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "Shared";

import { CoinStackedIcon } from "Components/CoinStackedIcon";
import { CoinValue } from "Components/CoinValue";
import { pluralize } from "Utils/pluralize";
import { captureException } from "Services/errors";
import { isKidUser } from "Types/User";
import { useSession } from "Services/session";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { post } from "Services/api";
import { useCoins } from "Services/hooks/useCoins";

type Props = {
  coinBalance: number | undefined;
  hasFreeCoins?: boolean;
  onRedeemSuccess: () => void;
  userId: string;
};

export const CoinsPopover: React.FC<PropsWithChildren<Props>> = ({
  coinBalance,
  hasFreeCoins,
  onRedeemSuccess,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRedeemedGift, setShowRedeemedGift] = useState<boolean>(false);
  const [isRedeeming, setIsRedeeming] = useState<boolean>(false);
  const [coinGift, setCoinGift] = useState<number>();
  const { updateBalance } = useCoins();
  const { currentUser, firebaseUser } = useSession();
  const isKid = isKidUser(currentUser);
  const ref = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();

  const redeemGift = async () => {
    try {
      setIsRedeeming(true);

      const { data } = await post("/v2/coins/daily-gift", firebaseUser);

      setShowRedeemedGift(true);
      setCoinGift(data.amount);
      updateBalance(data.amount);
      onRedeemSuccess();

      track(ANALYTICS_EVENTS.DAILY_GIFT_CLAIMED, {
        userCoinBalance: coinBalance,
        giftAmount: data.amount,
      });
    } catch (e: any) {
      captureException(e);
    } finally {
      setIsRedeeming(false);
    }
  };

  return (
    <Popover
      initialFocusRef={ref}
      isOpen={isOpen}
      onOpen={() => {
        setShowRedeemedGift(false);
        onOpen();
      }}
      onClose={onClose}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        zIndex={4}
        boxShadow="lg"
        width="400px"
        borderRadius="2xl"
      >
        <PopoverBody tabIndex={-1} pt={6} pb={8} px={6} ref={ref}>
          {showRedeemedGift && !isRedeeming ? (
            <Flex align="center" justifyContent="center" direction="column">
              <CoinValue size="3xl" value={coinGift || 0} showPlus={true} />
              <Text mt={2} fontSize="xl" fontWeight="bold">
                You Got {pluralize(coinGift || 0, "Free Coin")}!
              </Text>
              <Text>Check back tomorrow for another gift.</Text>
              {isKid && (
                <ExternalLink
                  color="brand"
                  href="https://help.tract.app/en/articles/5250595-what-are-coins-and-how-do-i-earn-them"
                  fontWeight="bold"
                  target="_blank"
                >
                  Learn How to Earn Coins
                </ExternalLink>
              )}
            </Flex>
          ) : (
            <HStack align="flex-start" spacing={4}>
              <CoinStackedIcon size={16} />
              <Box>
                <Text fontWeight="bold" fontSize="xl">
                  Coins
                </Text>
                <Text>
                  You have {coinBalance?.toLocaleString() || 0} Coins.
                </Text>
                {isKid && (
                  <ExternalLink
                    color="brand"
                    href="https://help.tract.app/en/articles/5250595-what-are-coins-and-how-do-i-earn-them"
                    fontWeight="bold"
                    target="_blank"
                  >
                    Learn How to Earn Coins
                  </ExternalLink>
                )}
                {(hasFreeCoins || isRedeeming) && (
                  <Button
                    size="lg"
                    fontWeight="bold"
                    fontSize="md"
                    colorScheme="brandFull"
                    mt={6}
                    isLoading={isRedeeming}
                    onClick={redeemGift}
                  >
                    Get Your Free Gift
                  </Button>
                )}
              </Box>
            </HStack>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
