import { FC, useEffect, useState } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { formatDistance, isAfter, isBefore } from "date-fns";
import { useDocument } from "Utils/hooks/firestore";
import { Chat as StreamChat } from "stream-chat-react";
import { useQuery, gql, useMutation } from "urql";
import { serverTimestamp, arrayRemove, arrayUnion } from "firebase/firestore";

import {
  Box,
  Center,
  Flex,
  IconButton,
  IconChat,
  IconCancel,
  IconLivestream,
  IconSidebarLeft,
  IconSidebarRight,
  Text,
  VStack,
  useToast,
  useBoolean,
} from "Shared";

import { SideNav } from "Components/SideNav";

import { StreamHeader } from "./StreamHeader";
import { StreamBody } from "../../Components/Livestream/StreamBody";
import { LivestreamChat } from "./LivestreamChat";

import { captureException } from "Services/errors";
import { useChat } from "Services/chat";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { USER_PRIVACY_SCOPES_FRAGMENT } from "Services/privacy";
import { useSession } from "Services/session";
import { db } from "Services/firebase";

import { USER_TYPE_FRAGMENT } from "Types/User";
import { Livestream as ILivestream } from "Types/Livestream";

import {
  LivestreamByPkQuery,
  LivestreamByPkQueryVariables,
  SessionUserByIdQueryResult,
  SessionUserByIdQueryVariables,
} from "@tract/common/dist/graphql";
import { useInterval } from "@tract/common/dist/hooks";
import { LIVESTREAM_BY_PK_QUERY } from "Pages/AdminDashboard/AdminLivestreams/graphql";
import { createContext } from "Utils";

import { JOIN_LIVESTREAM_MUTATION } from "../../Components/Livestream/graphql";
import { LIVESTREAM_USER_QUERY } from "../../Components/Livestream/graphql";
import { LayoutCentered } from "Components/LayoutCentered";

gql`
  ${USER_TYPE_FRAGMENT}
  ${USER_PRIVACY_SCOPES_FRAGMENT}

  fragment LivestreamHostUser on user {
    id: firestoreId
    username
    avatar
    isMod
    country
    ...UserType
    ...UserPrivacyScopes
  }
`;

const [LivestreamProvider, useContext] = createContext<{
  isMod: boolean;
  isBannedUser: (userId: string) => boolean;
  handleBan: (userId: string) => void;
  handleUnban: (userId: string) => void;
}>({
  name: "livestream-chat",
  strict: true,
});

export const useLivestreamContext = useContext;

export const Livestream: FC = () => {
  const toast = useToast();
  const { track } = useAnalytics();
  const { currentUser, isAdmin } = useSession();
  const [showChat, setShowChat] = useBoolean(true);
  const [joining, setJoining] = useState(false);
  const [livestreamJoined, setLivestreamJoined] = useState(false);
  const [livestreamJoinErrored, setLivestreamJoinErrored] = useState(false);
  const [watching, setWatching] = useState(false);
  const [isMod, setIsMod] = useState(isAdmin);
  const location = useLocation<{ viewSource?: string }>();
  const { livestreamId } = useParams<{ livestreamId: string }>();
  const [trackedLivestreamId, setTrackedLivestreamId] = useState<string>();
  const { chatClient, isLoading } = useChat();
  const channel = !isLoading && chatClient.channel("livestream", livestreamId);

  const [{ data, fetching: loadingLivestream }] = useQuery<
    LivestreamByPkQuery,
    LivestreamByPkQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: LIVESTREAM_BY_PK_QUERY,
    variables: {
      id: livestreamId,
    },
  });
  const livestream = data?.livestream;
  const livestreamRef = db.doc(`/livestreams/${livestreamId}`);

  const { data: livestreamDoc, loading: loadingFirestore } =
    useDocument<ILivestream>(
      livestreamId ? `/livestreams/${livestreamId}` : null,
      {
        listen: true,
        onError: (err) => captureException(err),
      }
    );

  const [{ data: hostData, fetching: loadingHost }] = useQuery<
    SessionUserByIdQueryResult["data"],
    SessionUserByIdQueryVariables
  >({
    pause: !livestream?.hostUserId,
    query: LIVESTREAM_USER_QUERY,
    variables: { userId: livestream?.hostUserId || "" },
  });
  const host = hostData?.users[0];

  const [, joinLivestream] = useMutation(JOIN_LIVESTREAM_MUTATION);

  useEffect(() => {
    if (!livestream || !host || !livestreamJoined) return;
    if (trackedLivestreamId === livestream.id) return;

    track(ANALYTICS_EVENTS.LIVESTREAM_VIEWED, {
      livestreamId: livestream.id,
      livestreamTitle: livestream.title,
      livestreamHostId: livestream.hostUserId,
      livestreamIsLive: livestreamDoc?.isLive,
      livestreamHostUsername: host.username,
      livestreamViewSource: location?.state?.viewSource,
    });

    setTrackedLivestreamId(livestream.id);
  }, [
    track,
    livestreamJoined,
    livestream,
    trackedLivestreamId,
    host,
    livestreamDoc?.isLive,
    location?.state?.viewSource,
  ]);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const distanceToStart = livestream?.startDate
    ? formatDistance(currentDate, new Date(livestream.startDate))
    : undefined;
  const isBeforeStartDate =
    livestream?.startDate &&
    isBefore(currentDate, new Date(livestream.startDate));

  const durationExpired =
    livestream?.startDate &&
    livestream?.endDate &&
    isAfter(currentDate, new Date(livestream.endDate));

  useInterval(() => {
    setCurrentDate(new Date());
  }, 1000);

  const channelId = process.env.REACT_APP_LIVESTREAM_CHANNEL;
  const isBanned = !!livestreamDoc?.bannedUsers?.includes(currentUser.id);

  const loading = loadingFirestore || loadingLivestream || loadingHost;

  useEffect(() => {
    if (
      !channel ||
      !livestream ||
      !watching ||
      joining ||
      livestreamJoined ||
      livestreamJoinErrored
    )
      return;

    const start = async () => {
      setJoining(true);
      try {
        const { error } = await joinLivestream({
          livestreamId: livestream.id,
        });

        if (livestream.hostUserId === currentUser.id) {
          setIsMod(true);
        } else {
          const { members } = await channel.queryMembers({
            id: currentUser.id,
          });

          setIsMod(members.some((member) => member.is_moderator));
        }

        if (!error) {
          setLivestreamJoined(true);
        } else {
          setLivestreamJoinErrored(true);
          toast({ status: "error", title: "Error joining livestream" });
        }
      } catch (err) {
        toast({ status: "error", title: "Error joining livestream" });
      } finally {
        setJoining(false);
      }
    };

    start();
  }, [
    channel,
    joining,
    watching,
    livestreamJoined,
    livestreamJoinErrored,
    joinLivestream,
    livestream,
    toast,
    currentUser.id,
  ]);

  useEffect(() => {
    if (!channel || !livestreamDoc) return;

    if (livestreamDoc.isLive) {
      channel
        .watch()
        .then(() => {
          setWatching(true);
        })
        .catch(captureException);
    } else if (channel.initialized) {
      channel
        .stopWatching()
        .then(() => {
          setWatching(false);
        })
        .catch(captureException);
    }
  }, [channel, livestreamDoc]);

  const handleBan = async (userId: string) => {
    try {
      await livestreamRef.update({
        banndUsers: arrayUnion(userId),
      });

      toast({ title: `Successfully banned user`, status: "success" });
    } catch (err) {
      toast({ title: "Error banning user", status: "error" });
    }
  };

  const handleUnban = async (userId: string) => {
    try {
      await livestreamRef.update({
        bannedUsers: arrayRemove(userId),
      });

      toast({ title: `Successfully unbanned user`, status: "success" });
    } catch (err) {
      toast({ title: "Error unbanning user", status: "error" });
    }
  };

  const handleToggleLive = async () => {
    setJoining(true);

    try {
      await livestreamRef.set(
        {
          isLive: !livestreamDoc?.isLive,
          startTime: serverTimestamp(),
        },
        { merge: true }
      );
    } catch (e: any) {
      captureException(e);

      toast({
        status: "error",
        title: "Oops!",
        description: "There was an error going live.",
        isClosable: true,
        duration: 6000,
      });
    } finally {
      setJoining(false);
    }
  };

  if (loading) {
    return <LayoutCentered isLoading />;
  }
  if (!loadingLivestream && !livestream) {
    return <Redirect to="/" />;
  }

  return (
    <LivestreamProvider
      value={{
        isMod,
        isBannedUser: (userId: string) => {
          return !!livestreamDoc?.bannedUsers?.includes(userId);
        },
        handleBan,
        handleUnban,
      }}
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        w="full"
        h={{ base: "calc(100vh - 4rem)", lg: "auto" }}
      >
        <StreamChat client={chatClient} theme={"team light"}>
          <Box
            w="full"
            pb={{ base: 0, lg: "4rem" }}
            overflow={{ lg: "hidden" }}
          >
            <Box
              w="full"
              bg="black"
              maxH="calc(100vh - 8rem)"
              overflow="hidden"
              position="relative"
            >
              {!showChat && (
                <Box
                  display={{ base: "none", lg: "block" }}
                  position="absolute"
                  right={4}
                  top="50%"
                  mt="-1.25rem"
                  zIndex={1}
                >
                  <IconButton
                    variant="ghost"
                    bg="blackAlpha.500"
                    _hover={{ bg: "blackAlpha.600" }}
                    _active={{ bg: "blackAlpha.600" }}
                    aria-label="Show Chat"
                    size="md"
                    icon={<IconSidebarLeft color="white" />}
                    onClick={setShowChat.on}
                  />
                </Box>
              )}
              {livestreamDoc?.isLive ? (
                <Box
                  as="iframe"
                  css={{ aspectRatio: "16 / 9" }}
                  h="100%"
                  w="100%"
                  maxH="calc(100vh - 8rem)"
                  src={`https://embed.api.video/live/${channelId}`}
                ></Box>
              ) : (
                <Center
                  css={{ aspectRatio: "16 / 9" }}
                  h="100%"
                  w="100%"
                  maxH="calc(100vh - 8rem)"
                  mx="auto"
                  color="white"
                >
                  <VStack spacing={{ base: 1, md: 2 }}>
                    <IconLivestream
                      boxSize={{ base: 10, md: 16 }}
                      color="gray.50"
                    />
                    {durationExpired ? (
                      <VStack spacing={{ base: 0, md: 1 }}>
                        <Text
                          fontSize={{ base: "2xl", md: "4xl" }}
                          fontWeight="bold"
                        >
                          Thanks for watching!
                        </Text>
                        <Text
                          fontSize={{ base: "md", md: "xl" }}
                          fontWeight="bold"
                          color="gray.50"
                        >
                          Livestream has ended
                        </Text>
                      </VStack>
                    ) : (
                      <VStack spacing={{ base: 0, md: 1 }}>
                        <Text
                          fontSize={{ base: "2xl", md: "4xl" }}
                          fontWeight="bold"
                        >
                          Coming Soon
                        </Text>
                        <Text
                          fontSize={{ base: "md", md: "xl" }}
                          fontWeight="bold"
                          color="gray.50"
                        >
                          {isBeforeStartDate
                            ? `Stream begins in ${distanceToStart}`
                            : `Stream will begin momentarily`}
                        </Text>
                      </VStack>
                    )}
                  </VStack>
                </Center>
              )}
            </Box>
            <Box maxW="70rem" mx="auto">
              {livestream && host && (
                <>
                  <StreamHeader
                    host={host}
                    livestream={livestream}
                    isLive={livestreamDoc?.isLive}
                    startTime={livestreamDoc?.startTime}
                    borderBottom={{ base: "1px solid", lg: "none" }}
                    borderBottomColor="gray.200"
                    onToggleLive={handleToggleLive}
                  />
                  <StreamBody
                    host={host}
                    livestream={livestream}
                    mt={4}
                    mx="auto"
                    maxW="40rem"
                    display={{ base: "none", lg: "block" }}
                  />
                </>
              )}
            </Box>
          </Box>
          <SideNav
            py={0}
            navPosition="right"
            w={{ base: "100%", lg: "24rem" }}
            minW={{ base: "100%", lg: "24rem" }}
            top={{ base: 0, lg: "4rem" }}
            display={{ base: "flex", lg: showChat ? "flex" : "none" }}
            h={{ base: "auto", lg: "calc(100vh - 3.5rem - 8px)" }}
            overflow="hidden"
          >
            <Flex
              align="center"
              justify="center"
              px={6}
              borderBottom="1px solid"
              borderBottomColor="gray.200"
              h="calc(3.5rem + 1px)"
              display={{ base: "none", lg: "flex" }}
            >
              <IconButton
                variant="ghost"
                aria-label="Hide Chat"
                size="md"
                icon={<IconSidebarRight />}
                position="absolute"
                left={2}
                top={2}
                onClick={setShowChat.off}
              />
              <Text fontSize="md" fontWeight="bold">
                Stream Chat
              </Text>
            </Flex>
            {livestreamJoined &&
            livestream &&
            livestreamDoc?.isLive &&
            !isBanned &&
            channel &&
            host ? (
              <LivestreamChat
                livestream={livestream}
                channel={channel}
                host={host}
              />
            ) : (
              <Center flex={1}>
                <Box mx={10}>
                  <Flex direction="column" align="center">
                    {isBanned ? (
                      <>
                        <IconCancel boxSize={10} color="gray.600" />
                        <Text fontSize="xl" fontWeight="bold" mt={2}>
                          You are banned from chat
                        </Text>
                        <Text color="gray.600" align="center" mt={1}>
                          You are unable to participate in this chat until a
                          moderator unbans you.
                        </Text>
                      </>
                    ) : livestreamDoc?.isLive ? (
                      <>
                        <IconChat boxSize={10} color="gray.600" />
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color="gray.600"
                          mt={2}
                        >
                          Joining...
                        </Text>
                      </>
                    ) : (
                      <>
                        <IconChat boxSize={10} color="gray.600" />
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color="gray.600"
                          mt={2}
                        >
                          Chat Unavailable
                        </Text>
                      </>
                    )}
                  </Flex>
                </Box>
              </Center>
            )}
          </SideNav>
        </StreamChat>
      </Flex>
    </LivestreamProvider>
  );
};
