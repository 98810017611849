import { forwardRef } from "@chakra-ui/react";
import { useCollection, useDocument } from "Utils/hooks/firestore";
import { format } from "date-fns";

import {
  Box,
  Button,
  Flex,
  IconBell,
  IconCheck,
  Link,
  LinkBox,
  LinkOverlay,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Text,
  useToast,
} from "Shared";

import { Livestream, ViewSource } from "Types/Livestream";

import { db } from "Services/firebase";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import { LivestreamAvatar } from "../../Components/Livestream/LivestreamAvatar";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useQuery } from "urql";
import {
  SessionUserByIdQueryResult,
  SessionUserByIdQueryVariables,
  NextLivestreamQuery,
  NextLivestreamQueryVariables,
  LivestreamBannerQuery,
  LivestreamBannerQueryVariables,
} from "@tract/common/dist/graphql";
import { SESSION_USER_BY_ID_QUERY } from "graphql/users";
import {
  LIVESTREAM_BANNER_QUERY,
  NEXT_LIVESTREAM_QUERY,
} from "../../Components/Livestream/graphql";

export const LivestreamPreviewBanner = forwardRef((props, ref) => {
  const { currentUser } = useSession();
  const toast = useToast();
  const { track } = useAnalytics();

  const [{ data: nextLivestream, fetching: loadingNextLivestream }] = useQuery<
    NextLivestreamQuery,
    NextLivestreamQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: NEXT_LIVESTREAM_QUERY,
    variables: {
      startDate: "now()",
    },
  });

  const { data: liveLivestreams, loading: loadingLiveLivestreams } =
    useCollection<Livestream>("livestreams", {
      listen: true,
      where: ["isLive", "==", true],
      onError: (err) => captureException(err),
    });

  const liveLivestream = liveLivestreams?.[0];
  const isLive = !!liveLivestream;

  const [{ data: streamingLivestream, fetching: loadingStreamingLivestream }] =
    useQuery<LivestreamBannerQuery, LivestreamBannerQueryVariables>({
      pause: !liveLivestreams?.[0]?.id,
      requestPolicy: "cache-and-network",
      query: LIVESTREAM_BANNER_QUERY,
      variables: {
        id: !!liveLivestreams?.length ? liveLivestreams[0]?.id : undefined,
      },
    });

  const livestream =
    !!streamingLivestream?.livestream &&
    !streamingLivestream.livestream.disabled
      ? streamingLivestream.livestream
      : nextLivestream?.livestream.length
      ? nextLivestream.livestream[0]
      : undefined;

  const { data: reminder } = useDocument<{
    remind: boolean;
  }>(
    !!livestream
      ? `livestreams/${livestream.id}/reminders/${currentUser.uid}`
      : null,
    {
      listen: true,
      onError: (err) => captureException(err),
    }
  );

  const [{ data: hostData }] = useQuery<
    SessionUserByIdQueryResult["data"],
    SessionUserByIdQueryVariables
  >({
    pause: !livestream?.hostUserId,
    query: SESSION_USER_BY_ID_QUERY,
    variables: { userId: livestream?.hostUserId || "" },
  });
  const host = hostData?.users[0];

  const toggleReminder = () => {
    if (!reminder) return;
    const newVal = !reminder.remind;

    if (livestream) {
      db.doc(`livestreams/${livestream.id}/reminders/${currentUser.uid}`).set({
        remind: newVal,
      });
    }

    if (newVal === true) {
      toast({
        status: "success",
        title: "We'll send you a reminder when the livestream is starting",
      });

      track(ANALYTICS_EVENTS.LIVESTREAM_REMINDER_SET, {
        livestreamId: livestream?.id,
        livestreamTitle: livestream?.title,
        livestreamHostId: livestream?.hostUserId,
        livestreamHostUsername: host?.username,
      });
    }
  };

  const toLivestream = {
    pathname: `/livestream/${livestream?.id}`,
    state: {
      viewSource: ViewSource.ExploreBanner,
    },
  };

  const loading =
    loadingNextLivestream ||
    loadingLiveLivestreams ||
    loadingStreamingLivestream;
  if (!livestream || loading) {
    return null;
  }

  return (
    <Flex
      border="1px solid"
      borderColor="gray.300"
      boxShadow="base"
      borderRadius="xl"
      _hover={{ boxShadow: "lg" }}
      transition="150ms ease box-shadow"
      w="full"
      maxW="60rem"
      {...props}
      ref={ref}
    >
      <LinkBox overflow={{ md: "hidden" }} w="full" p={2}>
        {livestream && host ? (
          <Stack direction="row" spacing={0} w="full" align="flex-start">
            <Box p={2}>
              <LivestreamAvatar
                src={host.avatar || ""}
                size="md"
                name={host.username || ""}
                isLive={isLive}
              />
            </Box>
            <Stack
              direction={{ base: "column", md: "row" }}
              flex={1}
              spacing={0}
              align="flex-start"
              justify="space-between"
              overflow={{ md: "hidden" }}
            >
              <Flex
                flex={1}
                direction="column"
                maxW="50rem"
                overflow="hidden"
                p={2}
              >
                {isLive ? (
                  <Text
                    fontSize="sm"
                    textTransform="uppercase"
                    fontWeight="bold"
                    color="orange.600"
                  >
                    Streaming Live Now
                  </Text>
                ) : (
                  <Text
                    fontSize="sm"
                    textTransform="uppercase"
                    fontWeight="bold"
                    color="orange.600"
                  >
                    Streaming Live:{" "}
                    {format(
                      new Date(livestream.startDate),
                      "eee, LLL do @ h:mm a"
                    )}
                  </Text>
                )}
                <LinkOverlay
                  as={Link}
                  to={toLivestream}
                  fontSize="lg"
                  fontWeight="bold"
                  _hover={{ textDecoration: "none" }}
                  _active={{ boxShadow: "none" }}
                >
                  {livestream.title}
                </LinkOverlay>
                {livestream.description && (
                  <Text noOfLines={1} display={{ base: "none", lg: "block" }}>
                    {livestream.description}
                  </Text>
                )}
              </Flex>
              {isLive ? null : reminder && reminder.remind ? (
                <Box p={2}>
                  <Button
                    variant="ghost"
                    leftIcon={<IconCheck />}
                    onClick={toggleReminder}
                    ml={{ base: -4, md: 0 }}
                  >
                    Reminder Set
                  </Button>
                </Box>
              ) : (
                <Box p={2}>
                  <Button
                    variant="ghost"
                    leftIcon={<IconBell />}
                    onClick={toggleReminder}
                    ml={{ base: -4, md: 0 }}
                  >
                    Remind Me
                  </Button>
                </Box>
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" spacing={4} w="full" align="flex-start">
            <SkeletonCircle size="3rem" />
            <SkeletonText
              noOfLines={3}
              spacing={4}
              flex={1}
              pt={1}
              maxW="80%"
            />
          </Stack>
        )}
      </LinkBox>
    </Flex>
  );
});
