import { createIcon } from "@chakra-ui/react";

export const IconSchool = createIcon({
  displayName: "IconSchool",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
    fill: "none",
  },
  path: [
    <path key={1} d="M22 9L12 4L2 9L12 14L22 9Z" />,
    <path key={2} d="M18 11V17.5L12 20.5L6 17.5V11" />,
    <line key={3} x1="22" y1="9" x2="22" y2="15" />,
  ],
});
