import { createIcon } from "@chakra-ui/react";

export const IconClever = createIcon({
  displayName: "IconClever",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "none",
  },
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.799988 12.1683C0.799988 5.50638 6.374 0.0999756 14.3644 0.0999756C19.2645 0.0999756 22.2007 1.56047 24.6171 3.67862L20.9781 7.42809C18.9757 5.80531 16.9251 4.80603 14.3259 4.80603C9.9456 4.80603 6.77833 8.05158 6.77833 12.0316C6.77833 16.08 9.85895 19.3939 14.3259 19.3939C17.3006 19.3939 19.1201 18.3349 21.161 16.6779L24.8 19.9576C22.1237 22.5114 19.149 24.1 14.1333 24.1C6.47989 24.1 0.799988 18.8302 0.799988 12.1683Z"
      fill="white"
    />,
  ],
});
