import { ResolverConfig } from "@urql/exchange-graphcache";
import { simplePagination } from "@urql/exchange-graphcache/extras";

export const resolvers: ResolverConfig = {
  query_root: {
    shop_item_by_pk: (_, args, cache) => {
      return cache.keyOfEntity({
        __typename: "shop_item",
        id: `${args.id}`,
      });
    },

    coin_trx: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    comment: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    path_node_comment: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    project_comment: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    shop_item: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    shop_item_purchase: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    livestream: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    path: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    project: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    view_top_creators_by_followers_count: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    organization: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),

    search_organizations: simplePagination({
      offsetArgument: "offset",
      limitArgument: "limit",
    }),
  },
};
