import { gql } from "urql";

export const PATH_CARD_DATA_FRAGMENT = gql`
  fragment PathCardData on path {
    id
    user {
      id: firestoreId
      avatar
      firstName
      lastName
      username
      isEducator
    }
    guides {
      guide {
        id
      }
    }
  }
`;

export const PATH_CARD_DATA_QUERY = gql`
  ${PATH_CARD_DATA_FRAGMENT}
  query PathCardData($pathId: bigint!) {
    path: path_by_pk(id: $pathId) {
      ...PathCardData
    }
  }
`;
