import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";
import * as yup from "yup";

import {
  Avatar,
  Button,
  Flex,
  HStack,
  Link,
  Table,
  Text,
  Thead,
  Tr,
  ButtonOutlined,
  EmptyState,
  FormControl,
  Grid,
  Input,
  Select,
  Tbody,
  Td,
  Th,
  VisuallyHidden,
  ButtonLink,
  FormErrorMessage,
} from "Shared";

import { PageHeader } from "Components/PageHeader";
import { FormLabelWithClearAction } from "Components/FormLabelWithClearAction";
import { LayoutCentered } from "Components/LayoutCentered";

import { useQuery as useParams } from "Utils";

import { UserRole } from "Services/session";
// import { functions } from "Services/firebase";
import { AdminContent } from "Pages/AdminDashboard/AdminContent";
import { GET_ADMIN_DASHBOARD_USERS } from "../graphql";
import {
  AdminDashboardUserAccountFragment,
  GetAdminDashboardUsersQueryResult,
  GetAdminDashboardUsersQueryVariables,
  User_Bool_Exp,
} from "@tract/common/dist/graphql";
import { CreatorLevel, isTeacherUser, UserType } from "Types/User";

const USERS_LIMIT = 50;

enum FilterName {
  username = "username",
  userId = "userId",
  firstName = "firstName",
  orgId = "orgId",
  userType = "userType",
}

const generateUserFilterByType = (userType: string) => {
  let userTypeFilter: User_Bool_Exp | null = null;

  switch (userType) {
    case UserRole.Admin:
      userTypeFilter = {
        isAdmin: { _eq: true },
      };
      break;

    case UserRole.Creator:
      userTypeFilter = {
        creatorLevel: { _eq: CreatorLevel.Creator },
      };
      break;

    case UserRole.Learner:
      userTypeFilter = {
        userType: { _eq: UserType.Kid },
      };
      break;

    case UserRole.Parent:
      userTypeFilter = {
        userType: { _eq: UserType.Parent },
        isEducator: { _eq: false },
      };
      break;

    case UserRole.Teacher:
      userTypeFilter = {
        userType: { _eq: UserType.Parent },
        isEducator: { _eq: true },
      };
      break;
    default:
      break;
  }

  return userTypeFilter;
};

// const getUserByEmail = functions.httpsCallable("admin-getUserByEmail");

export const Users: React.FC = () => {
  const query = useParams();
  const history = useHistory();
  const emailQueryParam = query.get("email");
  const filters = {
    email: query.get("email"),
    username: query.get("username"),
    userId: query.get("userId"),
    orgId: query.get("orgId"),
    userType: query.get("userType"),
  };

  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [orgId, setOrgId] = useState("");
  const [userType, setUserType] = useState("");
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setUsername(filters.username || "");
  }, [filters.username]);

  useEffect(() => {
    setUserId(filters.userId || "");
  }, [filters.userId]);

  useEffect(() => {
    setEmail(filters.email || "");
  }, [filters.email]);

  useEffect(() => {
    setOrgId(filters.orgId || "");
  }, [filters.orgId]);

  useEffect(() => {
    setUserType(filters.userType || "");
  }, [filters.userType]);

  const [{ data, fetching: loading, error }] = useQuery<
    GetAdminDashboardUsersQueryResult["data"],
    GetAdminDashboardUsersQueryVariables
  >({
    requestPolicy: "network-only",
    query: GET_ADMIN_DASHBOARD_USERS,
    variables: {
      limit: USERS_LIMIT,
      offset,
      where: {
        ...(filters.userType && generateUserFilterByType(filters.userType)),
        ...(filters.username && {
          username: { _eq: filters.username },
        }),
        ...(filters.email && {
          email: { _eq: filters.email },
        }),
        ...(filters.userId && {
          firestoreId: { _eq: filters.userId },
        }),
        ...(filters.orgId && {
          organization: {
            firestoreId: { _eq: filters.orgId },
          },
        }),
      },
    },
  });

  const users = data?.adminDashboardUsers;

  const [loadingMore, setLoadingMore] = useState(false);

  const showMore = !((users?.length || 0) % USERS_LIMIT);

  const handleLoadMore = async () => {
    if (users?.length) {
      setLoadingMore(true);
      setOffset(users.length);
    }
  };

  useEffect(() => {
    if (!loading || (error && loadingMore)) {
      setLoadingMore(false);
    }
  }, [loading, error, loadingMore]);

  const handleFilterSubmit = (
    filterName: FilterName | "email",
    filterValue: string
  ) => {
    if (filterValue.length < 1) {
      query.delete(filterName);
    } else {
      if (filterName !== "email") query.delete("email");
      query.set(filterName, filterValue);
    }

    history.push(`?${query.toString()}`);
  };

  const getUserRoles = (user: AdminDashboardUserAccountFragment) => {
    let roles = [];

    if (user.canAuthor) roles.push(UserRole.Creator);

    if (user.isEducator) roles.push(UserRole.Teacher);

    if (user.userType === "parent") roles.push(UserRole.Parent);

    if (user.userType === "kid") roles.push(UserRole.Learner);

    // if (user.isAdmin) roles.push(UserRole.Admin);

    return roles.join(", ");
  };

  const handleClickClearFilter = (filterName: FilterName | "email") => {
    query.delete(filterName);
    history.push(`?${query.toString()}`);
  };

  return (
    <AdminContent>
      <PageHeader
        title="Users"
        renderActions={
          <ButtonLink
            as={Link}
            variant="solid"
            to="/admin/create-user"
            size="lg"
            colorScheme="brandFull"
          >
            Create User
          </ButtonLink>
        }
      />
      <Grid
        templateColumns={["repeat(3, 1fr)", "repeat(4, 1fr)"]}
        gap={6}
        mb={6}
      >
        <Formik
          initialValues={{ username }}
          enableReinitialize={true}
          validateOnChange={false}
          onSubmit={({ username }) =>
            handleFilterSubmit(FilterName.username, username)
          }
          validationSchema={yup.object({
            username: yup
              .string()
              .test("length", "Must be at least 3 characters", (val) => {
                return !!(val && val.length >= 3);
              }),
          })}
        >
          {({ values, handleChange, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={!!errors.username}>
                <FormLabelWithClearAction
                  showClearAction={!!filters.username}
                  onClickClear={() =>
                    handleClickClearFilter(FilterName.username)
                  }
                >
                  Username
                </FormLabelWithClearAction>
                <Input
                  name="username"
                  variant="filled"
                  value={values.username}
                  onChange={handleChange}
                />
                {!!errors.username && (
                  <FormErrorMessage>{errors.username}</FormErrorMessage>
                )}
              </FormControl>
            </form>
          )}
        </Formik>

        <Formik
          initialValues={{ userId }}
          enableReinitialize={true}
          onSubmit={({ userId }) =>
            handleFilterSubmit(FilterName.userId, userId)
          }
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabelWithClearAction
                  showClearAction={!!filters.userId}
                  onClickClear={() => handleClickClearFilter(FilterName.userId)}
                >
                  UID
                </FormLabelWithClearAction>
                <Input
                  name="userId"
                  variant="filled"
                  value={values.userId}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
          )}
        </Formik>

        <Formik
          initialValues={{ email }}
          enableReinitialize={true}
          onSubmit={({ email }) => {
            Object.values(FilterName).map((filter) => query.delete(filter));
            handleFilterSubmit("email", email.trim());
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabelWithClearAction
                  showClearAction={!!emailQueryParam}
                  onClickClear={() => {
                    handleClickClearFilter("email");
                  }}
                >
                  Email
                </FormLabelWithClearAction>
                <Input
                  name="email"
                  variant="filled"
                  value={values.email}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
          )}
        </Formik>

        <Formik
          initialValues={{ orgId }}
          enableReinitialize={true}
          onSubmit={({ orgId }) => handleFilterSubmit(FilterName.orgId, orgId)}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabelWithClearAction
                  showClearAction={!!filters.orgId}
                  onClickClear={() => handleClickClearFilter(FilterName.orgId)}
                >
                  Org ID
                </FormLabelWithClearAction>
                <Input
                  name="orgId"
                  variant="filled"
                  value={values.orgId}
                  onChange={handleChange}
                />
              </FormControl>
            </form>
          )}
        </Formik>

        <FormControl>
          <FormLabelWithClearAction
            showClearAction={!!filters.userType}
            onClickClear={() => handleClickClearFilter(FilterName.userType)}
          >
            Role
          </FormLabelWithClearAction>
          <Select
            name="userType"
            variant="filled"
            value={userType}
            onChange={({ target: { value } }) =>
              handleFilterSubmit(FilterName.userType, value)
            }
          >
            <option value="">All</option>
            {Object.values(UserRole).map((role: UserRole) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {loading && !loadingMore ? (
        <LayoutCentered isLoading={loading && !loadingMore} height="auto" />
      ) : users && users.length === 0 ? (
        <EmptyState headline="No users found" />
      ) : (
        users && (
          <Table width="100%">
            <Thead>
              <Tr>
                <Th>User</Th>
                <Th>Created at</Th>
                {/* <Th>Last Seen</Th> */}
                <Th>Roles</Th>
                <Th>
                  <VisuallyHidden>Actions</VisuallyHidden>
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="md">
              {users?.map((user) => (
                <Tr key={user.id}>
                  <Td py={4} maxW="12rem" overflowX="hidden">
                    <HStack>
                      <Avatar size="sm" src={user.avatar || ""} />
                      {user.username ? (
                        <Text
                          as={Link}
                          to={`/admin/users/${user.id}`}
                          fontWeight="bold"
                        >
                          {user.username}
                        </Text>
                      ) : (
                        <Text
                          fontWeight="bold"
                          {...(user.isEducator && {
                            as: Link,
                            to: `/admin/users/${user.id}`,
                          })}
                        >
                          {`${user.firstName} ${user.lastName}`}
                        </Text>
                      )}
                    </HStack>
                  </Td>
                  <Td py={4}>{new Date(user.createdAt).toLocaleString()}</Td>
                  {/* <Td py={4}>
                    {user.lastSeen?.toDate &&
                      user.lastSeen.toDate().toLocaleString()}
                  </Td> */}
                  <Td py={4}>{getUserRoles(user)}</Td>
                  <Td py={4} textAlign="right">
                    {(!!user.username || isTeacherUser(user)) && (
                      <ButtonOutlined
                        as={Link}
                        to={
                          isTeacherUser(user)
                            ? `/${user.id}`
                            : `/@${user.username}`
                        }
                      >
                        View Profile
                      </ButtonOutlined>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )
      )}

      {users?.length &&
      users.length >= USERS_LIMIT &&
      showMore &&
      !emailQueryParam ? (
        <Flex alignItems="center" justifyContent="center" mt={4}>
          <Button
            size="lg"
            variant="outline"
            onClick={handleLoadMore}
            mt={10}
            isLoading={loadingMore}
          >
            Next Page
          </Button>
        </Flex>
      ) : null}
    </AdminContent>
  );
};
