export interface CommentData {
  text?: string;
  giphyId?: string;
  awardId?: string;
}

export enum CommentType {
  Text = "TEXT",
  Giphy = "GIPHY",
  Award = "AWARD",
}

/**
 * An interface describing a comment, a comment can contain different
 * types of data (text, links, gif).
 */
export interface Comment {
  createdAt: Date;
  data: CommentData;
  type: CommentType;
  userId: string;
  isVisible?: boolean;
}
