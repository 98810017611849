import { FC } from "react";
import { Field, Form, Formik } from "formik";
import { useHistory, useLocation } from "react-router";
import { useRefinementList } from "react-instantsearch-hooks-web";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import {
  PathFilter,
  PATH_CREATOR_LEVELS,
  PATH_GRADE_LEVEL,
  PATH_INTEREST_AREAS,
  PATH_SKILLS,
  PATH_SUBJECTS,
  PATH_SUBJECT_V2,
} from "Constants/paths";

import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "Shared";
import { useQuery } from "Utils";

export enum FilterKey {
  GradelLevels = "gradeLevels",
  Skills = "skills",
  InterestAreas = "categories",
  Subjects = "subjects",
  CreatorLevels = "creatorLevels",
}

type FilterValues = Record<string, string[]>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  filterKey: FilterKey;
};

const HeaderTitle = {
  [FilterKey.GradelLevels]: "Grade Level",
  [FilterKey.Skills]: "Skills",
  [FilterKey.InterestAreas]: "Categories",
  [FilterKey.Subjects]: "Subject Area",
  [FilterKey.CreatorLevels]: "Creator Level",
};

export const FilterModal: FC<Props> = ({ isOpen, onClose, filterKey }) => {
  const refinementList = useRefinementList({ attribute: filterKey });
  const refinements = refinementList?.items?.sort((a, b) =>
    a.value.localeCompare(b.value)
  );
  const currentValues = {
    [filterKey]: refinements
      .filter((refinement) => refinement.isRefined)
      .map((refinement) => refinement.value),
  };
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { track } = useAnalytics();

  const getCheckboxList = (filterKey: FilterKey) => {
    switch (filterKey) {
      case FilterKey.GradelLevels:
        return PATH_GRADE_LEVEL;
      case FilterKey.Skills:
        return PATH_SKILLS;
      case FilterKey.InterestAreas:
        return PATH_INTEREST_AREAS;
      case FilterKey.Subjects:
        return PATH_SUBJECTS;
      case FilterKey.CreatorLevels:
        return PATH_CREATOR_LEVELS;
      default:
        return [];
    }
  };

  const handleSubmit = (filters: FilterValues) => {
    if (query.has(filterKey)) {
      query.delete(filterKey);
    }

    const filter = filters[filterKey];

    if (filter.length) {
      query.append(filterKey, filter.join(","));

      track(ANALYTICS_EVENTS.EXPLORE_FILTER_APPLIED, {
        exploreFilterName: PathFilter[filterKey],
        exploreFilterValues: filter,
      });
    }

    onClose();
    history.push(`${location.pathname}?${query}`);
  };

  const handleClear = () => {
    onClose();
    if (query.has(filterKey)) {
      query.delete(filterKey);
    }
    history.push(`${location.pathname}?${query}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <Formik
        initialValues={currentValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <ModalContent>
              <ModalHeader>{HeaderTitle[filterKey]}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <CheckboxGroup defaultValue={currentValues[filterKey]}>
                  <VStack align="stretch" spacing={1}>
                    {getCheckboxList(filterKey).map(({ label, value }) => {
                      let displayName = label;
                      if (filterKey === "gradeLevels") {
                        displayName = `Grade ${label}`;
                      }
                      if (filterKey === "subjects") {
                        displayName =
                          PATH_SUBJECT_V2.filter(
                            (subject) => subject.label === label
                          )[0]?.standardizedShortLabel || label;
                      }
                      return (
                        <Field
                          as={Checkbox}
                          py={2}
                          size="lg"
                          color="gray.600"
                          key={value}
                          name={filterKey}
                          value={value}
                        >
                          {displayName}
                        </Field>
                      );
                    })}
                  </VStack>
                </CheckboxGroup>
              </ModalBody>
              <ModalFooter>
                <ButtonGroup spacing={2} flex={1}>
                  <Button
                    size="lg"
                    variant="outline"
                    flex={1}
                    onClick={handleClear}
                    disabled={!currentValues[filterKey]?.length}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    size="lg"
                    variant="solid"
                    colorScheme="brandFull"
                    flex={1}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
