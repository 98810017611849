import { useMemo } from "react";
import { useLocation } from "react-router";
import { PathViewerPathFragment } from "@tract/common/dist/graphql";
import { usePathRoute } from "./usePathRoute";

type UsePathNavParams = {
  path?: PathViewerPathFragment | null;
};

export const usePathNav = ({ path }: UsePathNavParams) => {
  const { params, baseUrl } = usePathRoute(path);
  const pathNode = path?.nodes.find((m) => m.id === params.nodeId);
  const { pathname } = useLocation();

  // Build an array of URLs to traverse for prev/next CTAs
  const navItems = useMemo(() => {
    if (!path?.nodes) {
      return [];
    }

    return path.nodes.map((node) => `${baseUrl}/v/${node.id}`);
  }, [path, baseUrl]);

  // Determine prev and next URLs based on current path
  const { prevURL, nextURL } = useMemo(() => {
    const currIndex = navItems.findIndex((url) => url === pathname);

    return {
      prevURL: navItems[currIndex - 1],
      nextURL: navItems[currIndex + 1],
    };
  }, [navItems, pathname]);

  if (!path) return { nextURL, prevURL };

  const nodeIndex = pathNode
    ? path.nodes.findIndex((n) => n.id === pathNode.id)
    : 0;
  const prevNode = path.nodes[nodeIndex - 1];
  const prevNodeURL = prevNode && `${baseUrl}/v/${prevNode.id}`;
  const nextNode = path.nodes[nodeIndex + 1];
  const nextNodeURL = nextNode && `${baseUrl}/v/${nextNode.id}`;

  return {
    hasPrevNode: !!prevNode,
    prevNodeURL,
    hasNextNode: !!nextNode,
    nextNodeURL,
    hasNext: !!nextNode,
    nextURL,
    prevURL,
    currentNodeNumber: nodeIndex + 1,
    totalNodes: path.nodes.length,
    // TODO: Add 'nextIncompleteChallengeURL'
  };
};
