import React, { useState } from "react";
import { useMutation } from "urql";
import { Field, FieldProps, Form, Formik } from "formik";

import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Input,
  Text,
  useToast,
  FormErrorMessage,
  ButtonLink,
} from "Shared";

import { CurrentUser, useSession } from "Services/session";
import { useSignOut } from "Services/auth";
import {
  ANALYTICS_EVENTS,
  useAnalytics,
  useTrackEventOnce,
} from "Services/analytics";

import { PageContent } from "Components/PageContent";

import {
  OnboardKidMutation,
  OnboardKidMutationVariables,
} from "@tract/common/dist/graphql";

import { ONBOARD_KID_MUTATION } from "../graphql";
import { FormFieldUsername } from "Pages/SignUp/Students";
import { USERNAME_MAX_LENGTH } from "Pages/SignUp/Students/schema";

type FormValues = {
  firstName: string;
  lastName: string;
  username: string;
  birthday: {
    day: string;
    month: string;
    year: string;
  };
};

const validate = (currentUser: CurrentUser) => (values: FormValues) => {
  const errors: any = {};
  // if (!currentUser.birthday) {
  //   if (!values.birthday.month) {
  //     errors.birthday = { month: "Please provide a month" };
  //   }
  //   if (!values.birthday.day) {
  //     errors.birthday = { ...errors.birthday, day: "Please provide a day" };
  //   }
  //   if (!values.birthday.year) {
  //     errors.birthday = { ...errors.birthday, year: "Please provide a year" };
  //   }
  // }
  if (!currentUser.firstName && !values.firstName) {
    errors.firstName = "Please provide your first name";
  }
  if (!currentUser.lastName && !values.lastName) {
    errors.lastName = "Please provide your last name";
  }
  if (!currentUser.username) {
    if (!values.username) {
      errors.username = "Please provide your username";
    } else if (values.username.length < 6) {
      errors.username = "Your username should be at least 6 characters";
    }
  }
  return errors;
};

export const KidsOnboarding: React.FC = () => {
  const toast = useToast();
  const { track } = useAnalytics();
  const signout = useSignOut();
  const { currentUser, refreshSession, defaultLearnerGroup } = useSession();
  const [, onboardKid] = useMutation<
    OnboardKidMutation,
    OnboardKidMutationVariables
  >(ONBOARD_KID_MUTATION);
  const [loading, setLoading] = useState(false);

  useTrackEventOnce(ANALYTICS_EVENTS.LEARNER_SIGN_UP_COMPLETED, {
    orgId: currentUser.orgId,
    learnerGroupId: defaultLearnerGroup?.id,
    learnerGroupOwnerId: defaultLearnerGroup?.members.find(
      (member) => member.isOwner
    )?.userId,
    username: currentUser.username,
  });

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await onboardKid({
        userId: currentUser.id,
        firstName: values.firstName,
        lastName: values.lastName,
        birthday: values.birthday,
        username: values.username,
      });

      track(ANALYTICS_EVENTS.LEARNER_ONBOARDING_COMPLETED, {
        username: values.username,
      });

      refreshSession();
    } catch (err) {
      setLoading(false);

      toast({
        status: "error",
        title: "error saving details",
      });
    }
  };

  return (
    <PageContent
      my="112px"
      maxW={{ lg: "30rem" }}
      textAlign="center"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="2xl"
      overflow="hidden"
      p={10}
    >
      <Box flex={1}>
        <Box textAlign="center">
          <Text fontSize="3xl" fontWeight="bold" mb={6}>
            Almost done!
          </Text>
        </Box>
        <Formik
          initialValues={{
            username: currentUser.username || "",
            firstName: currentUser.firstName || "",
            lastName: currentUser.lastName || "",
            birthday: currentUser.birthday || {
              day: "",
              month: "",
              year: "",
            },
          }}
          validate={validate(currentUser)}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => (
            <Form noValidate onSubmit={form.handleSubmit}>
              <Box>
                {/* {!currentUser.birthday && <DatePicker />} */}
                {!currentUser.firstName && (
                  <Field name="firstName">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isRequired
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                      >
                        <FormLabel>First Name</FormLabel>
                        <Input
                          size="lg"
                          variant="filled"
                          type="text"
                          placeholder="First Name"
                          {...field}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
                {!currentUser.lastName && (
                  <Field name="lastName">
                    {({ field, form: { submitCount }, meta }: FieldProps) => (
                      <FormControl
                        isRequired
                        isInvalid={submitCount > 0 && !!meta.error}
                        mb={6}
                      >
                        <FormLabel>Last Name</FormLabel>
                        <Input
                          size="lg"
                          variant="filled"
                          type="text"
                          placeholder="Last Name"
                          {...field}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                )}
                {!currentUser.username && (
                  <FormFieldUsername
                    id="username"
                    variant="filled"
                    placeholder="Username"
                    helpText="Don't use your real name!"
                    autoComplete="off"
                    size="lg"
                    maxLength={USERNAME_MAX_LENGTH}
                  />
                )}
                <Button
                  isLoading={loading}
                  size="lg"
                  type="submit"
                  colorScheme="brandFull"
                  variant="solid"
                  w="full"
                >
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <Box textAlign="center" mt={6}>
          <ButtonLink colorScheme="brandFull" onClick={signout}>
            Sign Out
          </ButtonLink>
        </Box>
      </Box>
    </PageContent>
  );
};
