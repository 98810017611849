import firebase from "firebase/compat";

export enum ClubScope {
  FullAccess = "FULL",
  Limited = "LIMITED",
  Restricted = "RESTRICTED",
}

export enum OrganizationTypes {
  School = "School",
  Other = "Other",
  Unknown = "Unknown",
}

export enum OrganizationStatus {
  Approved = "Approved",
  Pending = "Pending",
  Archived = "Archived",
}

export enum GradeLevel {
  Primary = "primary",
  Intermediate = "intermediate",
  Secondary = "secondary",
  Vocational = "vocational",
  College = "college",
}

export interface Organization {
  avatar?: string;
  type: OrganizationTypes;
  status: OrganizationStatus;
  name: string;
  gradeLevel: GradeLevel;
  placeId: string;
  emailDomains: string[];
  address: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  zip?: string;
  region?: string;
  country?: string;
  clubScope?: ClubScope;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  shopEnabled?: boolean;
}
