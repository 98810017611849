import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  FindFollowRelationshipQueryVariables,
  UserSocialCountsByIdQueryHookResult,
  UserSocialCountsByIdQueryVariables,
} from "@tract/common/dist/graphql";

import { USER_SOCIAL_COUNTS_BY_ID_QUERY } from "Pages/Profile/graphql";

export const delete_user_follow_by_pk: UpdateResolver = (_, args, cache) => {
  cache.invalidate({
    __typename: "user_follow",
    sourceUserId: args.sourceUserId,
    targetUserId: args.targetUserId,
  });

  const variables = args as FindFollowRelationshipQueryVariables;
  //Decrement followers for target
  cache.updateQuery<
    UserSocialCountsByIdQueryHookResult["data"],
    UserSocialCountsByIdQueryVariables
  >(
    {
      query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
      variables: {
        id: variables.targetUserId,
      },
    },
    (data) => {
      if (!!data?.user[0]?.followers_aggregate?.aggregate?.count) {
        data.user[0].followers_aggregate.aggregate.count--;
      }
      return data;
    }
  );

  //Decrement following for source
  cache.updateQuery<
    UserSocialCountsByIdQueryHookResult["data"],
    UserSocialCountsByIdQueryVariables
  >(
    {
      query: USER_SOCIAL_COUNTS_BY_ID_QUERY,
      variables: {
        id: variables.sourceUserId,
      },
    },
    (data) => {
      if (!!data?.user[0]?.following_aggregate?.aggregate?.count) {
        data.user[0].following_aggregate.aggregate.count--;
      }
      return data;
    }
  );
};
