import React, { useMemo, useState } from "react";
import { endOfDay } from "date-fns";
import { useMutation, useQuery } from "urql";
import { useDebounce } from "use-debounce";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  duotone,
  regular,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Alert,
  AspectRatio,
  Box,
  Button,
  Center,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  Flex,
  FontAwesomeIcon,
  Grid,
  HStack,
  IconButton,
  Image,
  Link,
  SearchInput,
  Stack,
  TabLink,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "Shared";

import {
  ClassroomAssignmentFragment,
  ClassroomAssignmentsQuery,
  ClassroomAssignmentsQueryVariables,
  ClassroomAssignmentMarkFinishedMutation,
  ClassroomAssignmentMarkFinishedMutationVariables,
  ClassroomLearnerGroupFragment,
  Order_By,
} from "@tract/common/dist/graphql";

import {
  CLASSROOM_ASSIGNMENTS_QUERY,
  CLASSROOM_ASSIGNMENT_MARK_FINISHED_MUTATION,
} from "./graphql";

import { LayoutCentered } from "Components/LayoutCentered";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { AssignmentBuilderModal } from "Components/AssignmentBuilder/Modal";
import { AssignDatesGrid } from "Components/AssignDueDate";

import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import {
  ANALYTICS_EVENTS,
  useAnalytics,
  useTrackEventOnce,
} from "Services/analytics";

import { UserFile } from "Utils/UserFile";

import { isTeacherUser } from "Types/User";
import { AssignmentDetails } from "./Assignments/AssignmentDetails";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

export const ClassroomAssignments: React.FC<Props> = ({ classroom }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState<string | undefined>();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 250);

  const [{ data, fetching, stale }, refetch] = useQuery<
    ClassroomAssignmentsQuery,
    ClassroomAssignmentsQueryVariables
  >({
    requestPolicy: "cache-and-network",
    query: CLASSROOM_ASSIGNMENTS_QUERY,
    variables: {
      where: { learnerGroupId: { _eq: classroom.id } },
      orderBy: [
        { assignment: { dueDate: Order_By.Desc } },
        { assignment: { startDate: Order_By.Asc } },
      ],
    },
  });

  useTrackEventOnce(
    ANALYTICS_EVENTS.CLASS_ASSIGNMENTS_VIEWED,
    {
      learnerGroupId: classroom.id,
      totalAssignments: data?.assignment_learner_group.length,
    },
    {
      skip: fetching || stale,
    }
  );

  const assignmentsByTab = useMemo(() => {
    const assignment: Record<string, ClassroomAssignmentFragment[]> = {
      started: [],
      upcoming: [],
      finished: [],
    };

    for (let alg of data?.assignment_learner_group || []) {
      if (debouncedSearchTerm) {
        const normalizedSearchTerm = debouncedSearchTerm.toLowerCase();

        if (
          !alg.assignment.title.toLowerCase().includes(normalizedSearchTerm) &&
          !alg.assignment.description
            ?.toLowerCase()
            .includes(normalizedSearchTerm)
        ) {
          continue;
        }
      }

      if (alg.isFinished) {
        assignment.finished.push(alg);
        continue;
      }

      if (new Date(alg.assignment.startDate) < endOfDay(new Date())) {
        assignment.started.push(alg);
      } else {
        assignment.upcoming.push(alg);
      }
    }

    return assignment;
  }, [data?.assignment_learner_group, debouncedSearchTerm]);

  const tabs = [
    {
      id: "started",
      path: `/class/${classroom.id}/assignments`,
      name: "In Progress",
    },
    {
      id: "upcoming",
      path: `/class/${classroom.id}/assignments`,
      name: "Upcoming",
    },
    {
      id: "finished",
      path: `/class/${classroom.id}/assignments`,
      name: "Finished",
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  if (fetching || stale) {
    return <LayoutCentered isLoading height="auto" />;
  }

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <Center height="full" p={8}>
          <Alert status="error">
            There was an error with assignments, retry or contact support.
          </Alert>
        </Center>
      )}
    >
      <Switch>
        <Route path="/class/:classId/assignments/:assignmentId">
          <AssignmentDetails classroom={classroom} />
        </Route>

        <Route>
          <Tabs isManual isLazy tabIndex={activeTab} onChange={setActiveTab}>
            <PageApp>
              <PageHeader
                title="Assignments"
                renderTabs={
                  <Grid
                    templateColumns={{ base: "1fr", lg: "2fr 1fr" }}
                    gap={{ base: 4, lg: 0 }}
                    w="100%"
                  >
                    <TabList>
                      {tabs.map((route) => (
                        <TabLink
                          key={route.id}
                          to={route.path}
                          fontSize={{ base: "md", md: "lg" }}
                        >
                          {route.name} {assignmentsByTab[route.id].length}
                        </TabLink>
                      ))}
                    </TabList>
                    <SearchInput
                      query={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onClear={() => setSearchTerm("")}
                      placeholder="Search assignments..."
                    />
                  </Grid>
                }
              />
              <TabPanels>
                {tabs.map((route) => (
                  <TabPanel key={route.id}>
                    <AssignmentList
                      searchTerm={searchTerm}
                      assignments={assignmentsByTab[route.id]}
                      queryBy={route.id}
                      onEdit={(assignmentId) => {
                        setEditId(assignmentId);
                        onOpen();
                      }}
                    />
                  </TabPanel>
                ))}
              </TabPanels>

              <AssignmentBuilderModal
                assignmentId={editId}
                isOpen={isOpen}
                onClose={() => {
                  onClose();
                  refetch();
                }}
              />
            </PageApp>
          </Tabs>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
};

const AssignmentList: React.FC<{
  assignments?: ClassroomAssignmentFragment[];
  searchTerm?: string;
  queryBy: string;
  onEdit: (assignmentId: string) => void;
}> = ({ assignments = [], searchTerm, queryBy, onEdit }) => {
  const sortedAssignments = useMemo(() => {
    return assignments
      .sort((a, b) => {
        if (a.assignment.dueDate === b.assignment.dueDate) {
          return 0;
        }

        // Sort nulls last
        if (a.assignment.dueDate === null) {
          return 1;
        }
        if (b.assignment.dueDate === null) {
          return -1;
        }

        // Sort dueDate descending
        return (
          (new Date(a.assignment.dueDate).getTime() || 0) -
          (new Date(b.assignment.dueDate).getTime() || 0)
        );
      })
      .sort((a, b) => {
        // Sort startDate descending
        if (a.assignment.dueDate === null && b.assignment.dueDate === null) {
          return (
            (new Date(b.assignment.startDate).getTime() || 0) -
            (new Date(a.assignment.startDate).getTime() || 0)
          );
        }

        return 0;
      });
  }, [assignments]);

  if (!sortedAssignments.length) {
    return (
      <EmptyState
        icon={<EmptyStateFontAwesomeIcon icon={duotone("inbox")} />}
        headline={searchTerm ? "No assignments found" : `No assignments yet`}
        body={
          searchTerm
            ? "Try clearing your search"
            : "Explore the content library to assign content to this class"
        }
        cta={
          searchTerm ? null : (
            <Button
              size="lg"
              as={Link}
              to="/explore"
              variant="solid"
              colorScheme="brandFull"
            >
              Start Exploring
            </Button>
          )
        }
      />
    );
  }

  return (
    <Stack spacing={6}>
      {sortedAssignments.map((alg) => (
        <AssignmentItem
          key={alg.assignmentId}
          classroomAssignment={alg}
          onEdit={onEdit}
        />
      ))}
    </Stack>
  );
};

const AssignmentItem: React.FC<{
  classroomAssignment: ClassroomAssignmentFragment;
  onEdit: (assignmentId: string) => void;
}> = ({ classroomAssignment, onEdit }) => {
  const { track } = useAnalytics();
  const location = useLocation();
  const toast = useToast();
  const { currentUser } = useSession();
  const { assignmentId, learnerGroupId, assignment, isFinished } =
    classroomAssignment;
  const coverFile = new UserFile(assignment.paths[0].path.coverFile);
  const path = assignment.paths?.[0];

  const assignmentUrl = isTeacherUser(currentUser)
    ? `${location.pathname}/${assignmentId}`
    : `/path/${path.pathId}`;

  const [, markFinished] = useMutation<
    ClassroomAssignmentMarkFinishedMutation,
    ClassroomAssignmentMarkFinishedMutationVariables
  >(CLASSROOM_ASSIGNMENT_MARK_FINISHED_MUTATION);

  const handleMarkFinished = async () => {
    try {
      await markFinished({
        classId: learnerGroupId,
        assignmentId: assignmentId,
        isFinished: !isFinished,
      });

      track(ANALYTICS_EVENTS.ASSIGNMENT_MARKED, {
        assignmentId: assignmentId,
        learnerGroupId: learnerGroupId,
        isFinished: isFinished,
      });

      toast({
        status: "success",
        title: `Marked ${isFinished ? "Unfinished" : "Finished"}`,
      });
    } catch (err: any) {
      captureException(err);
    }
  };

  return (
    <Box
      overflowX="scroll"
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Flex minW="40rem">
        <Link to={assignmentUrl}>
          <AspectRatio
            ratio={16 / 9}
            flexBasis="160px"
            mr={6}
            minW="10rem"
            borderRadius={8}
            overflow="hidden"
          >
            <Image src={coverFile.getTransformedUrl("cover_thumb")} />
          </AspectRatio>
        </Link>
        <Box flex={1}>
          <Flex justifyContent="space-between">
            <VStack align="flex-start" spacing={0}>
              <Link to={assignmentUrl} fontSize="xl" fontWeight="bold">
                {assignment.title}
              </Link>
              <Text>{assignment.description}</Text>
            </VStack>
            {isTeacherUser(currentUser) && (
              <Box justifySelf="flex-end">
                <HStack>
                  <Tooltip
                    label={`Mark ${isFinished ? "Unfinished" : "Finished"}`}
                  >
                    <IconButton
                      variant="ghost"
                      aria-label="mark assignment finished"
                      icon={
                        isFinished ? (
                          <FontAwesomeIcon icon={solid("circle-check")} />
                        ) : (
                          <FontAwesomeIcon icon={regular("circle-check")} />
                        )
                      }
                      size="lg"
                      onClick={handleMarkFinished}
                    />
                  </Tooltip>
                  <Tooltip label="Edit">
                    <IconButton
                      onClick={() => onEdit(assignmentId)}
                      variant="ghost"
                      aria-label="edit assignment"
                      icon={<FontAwesomeIcon icon={regular("pencil")} />}
                      size="lg"
                    />
                  </Tooltip>
                </HStack>
              </Box>
            )}
          </Flex>
          <AssignDatesGrid
            mt={6}
            startDate={assignment.startDate}
            dueDate={assignment.dueDate}
          />
        </Box>
      </Flex>
    </Box>
  );
};
