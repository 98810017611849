import React, { useCallback, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { captureException, ERROR_TAGS } from "Services/errors";
import { Field, FieldProps } from "formik";
import { InputProps } from "@chakra-ui/react";

import {
  AspectRatio,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Box,
  Link,
  PinInput,
  PinInputField,
  HStack,
  useDisclosure,
  FormHelperText,
  ButtonLink,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { PageContent } from "Components/PageContent";

import { useQuery as useQueryParams } from "Utils";

import { index } from "Services/algolia";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { get } from "Services/api";

import to from "@tract/common/dist/utils/async";
import { CodeType } from "@tract/common/dist/types/models/EducatorCode";
import { ErrorModal } from "Components/ErrorModal";
import { SignUpWithKidCode } from "./SignUpWithKidCode";
import { SignUpWithClassCode } from "./SignUpWithClassCode";
import {
  Api_Join_Classroom_Output,
  Educator_Code,
  Mutation_RootApi_Join_ClassroomArgs,
} from "@tract/common/dist/graphql";
import { useSession } from "Services/session";
import { useMutation } from "urql";
import { JOIN_CLASSROOM_MUTATION } from "../graphql";
import { useHistory } from "react-router-dom";

export const StudentOnboard: React.FC = () => {
  const query = useQueryParams();
  const { track } = useAnalytics();
  const history = useHistory();
  const codeFromQuery = query.get("code");
  const [code, setCode] = useState(codeFromQuery || "");
  const [codeLoading, setCodeLoading] = useState(false);
  const [invalidCode, setInvalidCode] = useState<string | null>(null);
  const [codeData, setCodeData] = useState<Educator_Code | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const session = useSession({ bypassStrict: true });
  const [, joinClassroom] = useMutation<
    { api_join_classroom?: Api_Join_Classroom_Output },
    Mutation_RootApi_Join_ClassroomArgs
  >(JOIN_CLASSROOM_MUTATION);

  const handleCodeSubmit = useCallback(
    async (code: string) => {
      code = code.toUpperCase();
      setCodeLoading(true);

      try {
        const [err, response] = await to(
          get<Educator_Code>(`/v2/educator-codes/${code}`)
        );
        const codeData = response?.data;

        if (err) {
          captureException(err);

          if ((err as any)?.response.status === 429) {
            setInvalidCode(
              "You hit it too many times. Please wait for a minute and try again"
            );
          } else {
            setInvalidCode(
              "Something went wrong. Check your internet connection and try again."
            );
          }
          return;
        }

        if (!response) {
          captureException(
            new Error(`no response from /v2/educator-codes/${code}`)
          );
          setInvalidCode("Something went wrong");
          return;
        }

        if (!codeData) {
          setInvalidCode("Invalid code, please double check your letters");
          return;
        }

        if (new Date(codeData.expiresAt).getTime() < new Date().getTime()) {
          setInvalidCode("Code expired");
          return;
        }

        if (codeData.available <= 0) {
          setInvalidCode("Code has no available seats");
          return;
        }

        if (session) {
          const result = await joinClassroom({
            educatorCode: code,
            source: codeData.learnerGroup?.source,
          });

          const learnerGroupId =
            result.data?.api_join_classroom?.learnerGroupId;
          if (learnerGroupId) {
            history.replace(`/class/${learnerGroupId}`);
          } else {
            history.replace("/");
          }
          return;
        }

        setCodeData(codeData);

        if (codeData.type === CodeType.ClassCode) {
          track(ANALYTICS_EVENTS.LEARNER_SIGN_UP_STARTED, {
            orgId: codeData?.learnerGroup?.orgId,
            educatorCode: code,
            classId: codeData.learnerGroup?.id,
            learnerGroupId: codeData.learnerGroup?.id,
            learnerGroupOwnerId: codeData.learnerGroup?.members.find(
              (member) => {
                return member.isOwner;
              }
            )?.userId,
          });
        } else if (codeData.type === CodeType.KidCode) {
          track(ANALYTICS_EVENTS.LEARNER_SIGN_UP_STARTED, {
            inviteCode: code,
            invitedByUserId: codeData.user?.id,
            invitedByUsername: codeData.user?.username,
          });
        }
      } catch (err: any) {
        captureException(err, { tags: { feature: ERROR_TAGS.KID_ONBOARDING } });
        return;
      } finally {
        setCodeLoading(false);
      }
    },
    [track, history, session, joinClassroom]
  );

  useEffect(() => {
    if (codeFromQuery && codeFromQuery === code) {
      handleCodeSubmit(code);
    }
  }, [code, codeFromQuery, handleCodeSubmit]);

  function handleCodeChange(value: string) {
    setInvalidCode(null);
    setCode(value.trim().toUpperCase());
  }

  const onSuccess = () => {
    setLoggedIn(true);
  };

  if (codeFromQuery && codeFromQuery === code && codeLoading) {
    return <LayoutCentered isLoading />;
  }

  return (
    <>
      <Helmet>
        <title>Get Started — Tract</title>
      </Helmet>
      <PageContent
        pt={{ base: 10, md: 20 }}
        pb={{ base: 16, md: 24 }}
        px={0}
        // mx={{ base: codeData ? 4 : 0, lg: "auto" }}
      >
        <Box
          w="100%"
          maxW={{ md: "30rem" }}
          mx="auto"
          border={{ md: "1px solid" }}
          borderColor={{ md: "gray.300" }}
          borderRadius={{ md: "2xl" }}
          overflow="hidden"
          textAlign="center"
        >
          {codeData?.learnerGroup && (
            <AspectRatio ratio={3 / 1} display={{ base: "none", lg: "block" }}>
              <Box bg={codeData.learnerGroup.color || "gray.100"} />
            </AspectRatio>
          )}
          <Box p={{ base: 6, md: 10 }}>
            {!codeData && (
              <>
                <FormControl mb={6} isInvalid={!!invalidCode}>
                  <FormLabel
                    fontSize="3xl"
                    fontWeight="bold"
                    textAlign="center"
                    mb={10}
                    mr={0}
                  >
                    Enter your invite code:
                  </FormLabel>
                  <HStack spacing={3} alignItems="stretch">
                    <PinInput
                      value={code}
                      onComplete={handleCodeSubmit}
                      onChange={handleCodeChange}
                      variant="filled"
                      type="alphanumeric"
                    >
                      {Array.from({ length: 6 }).map((_, i) => (
                        <PinInputField key={i} height={14} flex={1} />
                      ))}
                    </PinInput>
                  </HStack>
                  <FormErrorMessage textAlign="left">
                    {invalidCode}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  width="full"
                  isLoading={codeLoading}
                  colorScheme="brandFull"
                  size="lg"
                  onClick={() => handleCodeSubmit(code)}
                  disabled={code.length < 6 || codeLoading}
                >
                  Continue
                </Button>
              </>
            )}

            {codeData && (
              <Box>
                {codeData.type === CodeType.KidCode ? (
                  <SignUpWithKidCode
                    code={code}
                    loggedIn={loggedIn}
                    codeData={codeData}
                    onSuccess={onSuccess}
                  />
                ) : (
                  <SignUpWithClassCode
                    code={code}
                    loggedIn={loggedIn}
                    codeData={codeData}
                    onSuccess={onSuccess}
                  />
                )}
              </Box>
            )}
            {codeData ? (
              <Text mt={10} mb={4}>
                By signing up, I agree to the{" "}
                <ButtonLink as={Link} colorScheme="brandFull" to="/legal/tos">
                  Terms of Service
                </ButtonLink>{" "}
                and{" "}
                <ButtonLink
                  as={Link}
                  colorScheme="brandFull"
                  to="/legal/privacy"
                >
                  Privacy Policy
                </ButtonLink>
              </Text>
            ) : (
              <Text mt={10}>
                Already signed up?{" "}
                <Link to="/sign-in/kid" fontWeight="bold" color="brand">
                  Sign In
                </Link>
              </Text>
            )}
          </Box>
        </Box>
      </PageContent>
    </>
  );
};

function validateEmailPrefix(username: string) {
  var reg = /^[A-Za-z0-9_]*$/;
  if (reg.test(username)) {
    return true;
  } else {
    return false;
  }
}

export function FormFieldUsername({
  helpText,
  ...props
}: { helpText?: string } & InputProps) {
  const { isOpen, onClose } = useDisclosure();

  async function validateUsername(value: string) {
    if (!value.length) {
      return "Please enter a username";
    }

    if (!validateEmailPrefix(value)) {
      return "Username should contain only alphabets, numbers or '_'";
    }

    try {
      const searchResults = await index.users.search("", {
        filters: `username:${value}`,
      });

      if (searchResults.hits.length) {
        return "Username already taken";
      }
    } catch (err: any) {
      captureException(err, { tags: { feature: ERROR_TAGS.KID_ONBOARDING } });
      return "Error validating username, try again.";
    }
  }

  return (
    <Field name="username" validate={validateUsername}>
      {({ field, form: { submitCount }, meta }: FieldProps) => (
        <FormControl
          isRequired
          isInvalid={submitCount > 0 && !!meta.error}
          mb={6}
        >
          <ErrorModal
            onClose={onClose}
            isOpen={isOpen}
            onRetry={() => onClose()}
          ></ErrorModal>
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input {...field} {...props} type="text" />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
          {helpText && (
            <FormHelperText textAlign="left">{helpText}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
}
