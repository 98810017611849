import { matchPath, useLocation, useRouteMatch } from "react-router";
import {
  PathViewerPathFragment,
  UnauthPathViewerPathFragment,
} from "@tract/common/dist/graphql";
import { TEACHER_PATH_TAG } from "@tract/common/dist/types/models/Path";
import { useSession } from "Services/session";
import { isParentUser, isTeacherUser } from "Types/User";

export const usePathRoute = (
  path?: PathViewerPathFragment | UnauthPathViewerPathFragment | null
) => {
  const { pathname } = useLocation();
  const isPreviewRoute = !!useRouteMatch("/path/preview");
  const isReviewRoute = !!useRouteMatch("/path/review");
  const baseUrl = isPreviewRoute
    ? `/path/preview/${path?.id}`
    : isReviewRoute
    ? `/path/review/${path?.id}`
    : `/path/${path?.slug}`;
  const baseRoute = isPreviewRoute
    ? `/path/preview/:pathId`
    : isReviewRoute
    ? `/path/review/:pathId`
    : `/path/:slug`;
  const nodeRoute = `${baseRoute}/v/:nodeId`;

  // Parse current path for route matches across all possible child routes
  const matchNode = matchPath(pathname, {
    path: `/path/:slug/v/:nodeId`,
  });
  const matchNodePreview = matchPath(pathname, {
    path: `/path/preview/:pathId/v/:nodeId`,
  });
  const matchNodeReview = matchPath(pathname, {
    path: `/path/review/:pathId/v/:nodeId`,
  });

  // Combine the route matches into a single params object
  const rawParams: { slug?: string; missionId?: string; challengeId?: string } =
    {
      ...((matchNode && matchNode.params) || {}),
      ...((matchNodePreview && matchNodePreview.params) || {}),
      ...((matchNodeReview && matchNodeReview.params) || {}),
    };

  // Mission and Challenge IDs are numbers, so we need to map the route params
  const mappedParams: {
    slug?: string;
    nodeId?: string;
  } = Object.entries(rawParams).reduce((prev, [key, val]) => {
    return { ...prev, [key]: val };
  }, {});

  const session = useSession({ bypassStrict: true });
  const isTeacher = session && isTeacherUser(session.currentUser);
  const isTeacherPath =
    Array.isArray(path?.tags) && path?.tags.includes(TEACHER_PATH_TAG);
  const isParent = session && isParentUser(session.currentUser);
  const isPathOwner = path?.authorId === session?.currentUser.id;
  const isSubmissionAllowed =
    (!isParent && !isTeacher) || (isTeacher && isTeacherPath);
  const isViewOnlyMode = isPreviewRoute || !isSubmissionAllowed || isPathOwner;

  return {
    params: mappedParams,
    baseUrl,
    baseRoute,
    isViewOnlyMode,
    isPreviewRoute,
    isTeacherPath,
    isReviewRoute,
    nodeRoute,
  };
};
