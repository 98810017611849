import React from "react";
import { CurrentUser } from "Services/session";
import { PathCard } from "Components/PathCard";
import { useBreakpointValue } from "@chakra-ui/react";
import { Link, Text } from "Shared";
import { HScrollContainer } from "./HScrollContainer";
import { usePathHistory } from "Services/pathHistory";
import { ViewSource } from "Types/Path";
import { PathCardSkeleton } from "Components/Skeletons/PathCardSkeleton";

type ProgressSectionProp = {
  user: CurrentUser;
};

export const ProgressSection: React.FC<ProgressSectionProp> = ({ user }) => {
  const userId = user.uid;

  const numCards = useBreakpointValue({ base: 6, lg: 3, xl: 4 }) || 4;

  const { paths, isLoading, error } = usePathHistory({
    userId,
    limit: 7,
  });

  const renderTitle = () => {
    return (
      <>
        <Text as={Link} to="/my-paths" color="gray.900">
          Viewing History
        </Text>
        <Link fontSize="lg" to="/my-paths" color="brand" tabIndex={-1}>
          See More
        </Link>
      </>
    );
  };

  //Reduced Grid column if path progress paths not up to min card per row
  const numCols =
    !!paths?.length && paths?.length < numCards ? paths.length : numCards;

  const templateColumns = {
    base: `repeat(${numCols}, 256px)`,
    lg: `repeat(3, 1fr)`,
    xl: `repeat(4, 1fr)`,
  };

  if (isLoading) {
    return (
      <HScrollContainer
        renderTitle={renderTitle}
        templateColumns={templateColumns}
      >
        {Array.from({ length: numCards }).map((_, i) => (
          <PathCardSkeleton key={i} />
        ))}
      </HScrollContainer>
    );
  }

  if (!!error) {
    return null;
  }

  if (!paths || (paths && paths.length < 1)) {
    return null;
  }

  return (
    <HScrollContainer
      renderTitle={renderTitle}
      templateColumns={templateColumns}
    >
      {paths
        .filter((_, i) => i < (numCards || 4))
        .map((path) => (
          <PathCard
            key={path.id}
            path={path}
            source={ViewSource.HomeViewingHistory}
          />
        ))}
    </HScrollContainer>
  );
};
