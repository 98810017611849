import React, { FC } from "react";

import { Tr, Td, Link, ButtonSolid, Badge, Text } from "Shared";

import { AdminLivestreamFragment } from "@tract/common/dist/graphql";
import { Livestream } from "Types/Livestream";
import { useDocument } from "Utils/hooks/firestore";
import { captureException } from "Services/errors";

type Props = {
  livestream: AdminLivestreamFragment;
  upcoming: boolean;
};

export const LivestreamsTableRow: FC<Props> = ({ livestream, upcoming }) => {
  const { data: livestreamDoc } = useDocument<Livestream>(
    livestream.id ? `/livestreams/${livestream.id}` : null,
    {
      onError: (err) => captureException(err),
    }
  );
  const isLive = livestreamDoc?.isLive;
  const durationMinutes =
    (new Date(livestream?.endDate).getTime() -
      new Date(livestream?.startDate).getTime()) /
    60 /
    1000;
  return (
    <Tr>
      <Td py={4}>
        <Text as={Link} to={`/livestream/${livestream.id}`} fontWeight="bold">
          {livestream.title}
        </Text>
        {isLive && (
          <Badge variant="outline" ml={3} colorScheme="green">
            Live
          </Badge>
        )}
      </Td>
      <Td py={4}>{livestream.hostUser.username}</Td>
      <Td py={4}>
        {new Date(livestream.startDate).toLocaleDateString()}{" "}
        {new Date(livestream.startDate).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Td>
      <Td py={4}>{durationMinutes || 0} minutes</Td>
      <Td py={4}>{livestream.disabled ? "No" : "Yes"}</Td>
      <Td py={4}>
        {upcoming && (
          <ButtonSolid as={Link} to={`/admin/livestreams/${livestream.id}`}>
            Edit
          </ButtonSolid>
        )}
      </Td>
    </Tr>
  );
};
