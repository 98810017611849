import { gql } from "@apollo/client";

export const GET_KID_INVITES = gql`
  query GetUserInvites($userId: String!) {
    educator_code(
      where: {
        userId: { _eq: $userId }
        type: { _eq: "kid_invite" }
        learnerGroupId: { _is_null: true }
      }
      order_by: { createdAt: desc }
    ) {
      available
      code
      expiresAt
      createdAt
    }
  }
`;
