import { Switch, Route, Redirect } from "react-router";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Link,
  Tabs,
  TabList,
  TabLink,
  useBreakpointValue,
  useRouteTabs,
} from "Shared";

import { PageApp } from "Components/PageApp";
import { useClassContext } from "Components/LayoutClass";
import { PageHeader } from "Components/PageHeader";
import { ClassroomPaths } from "./ClassroomPaths";
import { ClassroomProjects } from "./ClassroomProjects";

export const ClassroomGallery: React.FC = () => {
  const { classData } = useClassContext();
  const showBreadcrumb = useBreakpointValue({ base: true, lg: false });

  const { tabsProps } = useRouteTabs({
    routes: [
      { path: `/class/${classData.id}/gallery/paths` },
      { path: `/class/${classData.id}/gallery/projects` },
    ],
  });

  return (
    <Tabs {...tabsProps} isManual>
      <PageApp>
        <PageHeader
          title="Gallery"
          showBreadcrumb={showBreadcrumb}
          renderBreadcrumb={
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to={`/class/${classData.id}/`}>
                  {classData.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>Gallery</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          }
          renderTabs={
            <TabList>
              <TabLink to={`/class/${classData.id}/gallery/paths`}>
                Paths
              </TabLink>
              <TabLink to={`/class/${classData.id}/gallery/projects`}>
                Projects
              </TabLink>
            </TabList>
          }
        />
        <Switch>
          <Route path={`/class/:classId/gallery/paths`}>
            <ClassroomPaths classroom={classData} />
          </Route>
          <Route path={`/class/:classId/gallery/projects`}>
            <ClassroomProjects classroom={classData} />
          </Route>
          <Redirect to={`/class/${classData.id}/gallery/paths`} />
        </Switch>
      </PageApp>
    </Tabs>
  );
};
