import { gql } from "urql";

export const USER_PRIVACY_UPDATE_MUTATION = gql`
  mutation UserPrivacyUpdate($input: api_update_privacy_input!) {
    api_update_privacy(input: $input) {
      userId
      profilePrivacy
      projectPrivacy
      pathPrivacy
    }
  }
`;
