import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useQuery } from "urql";

import {
  Button,
  ButtonSolid,
  Center,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  Grid,
  useBreakpointValue,
} from "Shared";

import { ProjectCard } from "Components/ProjectCard";

import { useSession } from "Services/session";
import { useProjectModal } from "Services/projectModal";

import {
  ClassroomLearnerGroupFragment,
  ClassroomProjectsQuery,
  ClassroomProjectsQueryVariables,
} from "@tract/common/dist/graphql";

import { ViewSource } from "Types/Project";
import { isKidUser } from "Types/User";

import { CLASSROOM_PROJECTS_QUERY } from "./graphql";
import { useEffect, useState } from "react";
import { ProjectCardSkeleton } from "Components/Skeletons/ProjectCardSkeleton";
import { useClassContext } from "Components/LayoutClass";

type Props = {
  classroom: ClassroomLearnerGroupFragment;
};

const PER_PAGE = 54;

export const ClassroomProjects: React.FC<Props> = ({ classroom }) => {
  const { currentUser } = useSession();
  const { onClickInviteStudents } = useClassContext();
  const { handleOpenProjectModal } = useProjectModal();
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const kidIds =
    classroom.members
      .filter(({ role }) => role === "learner")
      .map(({ userId }) => userId) || [];

  const primaryOwnerUserId = classroom?.members.find(
    ({ isOwner }) => isOwner
  )?.userId;
  const isClassPrimaryOwner = primaryOwnerUserId === currentUser.id;
  const isClassOwner =
    isClassPrimaryOwner ||
    classroom?.members.some(
      ({ role, userId }) => role === "teacher" && userId === currentUser.id
    );

  const [{ data, fetching: loading, error }] = useQuery<
    ClassroomProjectsQuery,
    ClassroomProjectsQueryVariables
  >({
    pause: !kidIds.length,
    query: CLASSROOM_PROJECTS_QUERY,
    requestPolicy: "cache-and-network",
    variables: {
      offset: offset,
      userIds: kidIds,
      limit: PER_PAGE,
      isPrivate: isClassOwner ? { _in: [true, false] } : { _eq: false },
    },
  });

  useEffect(() => {
    if (!loading || (error && loadingMore)) {
      setLoadingMore(false);
    }
  }, [loading, error, loadingMore]);

  const projects = data?.projects;
  const isClassStudent =
    isKidUser(currentUser) && kidIds.includes(currentUser.uid);

  const handleLoadMore = () => {
    if (projects?.length) {
      setLoadingMore(true);
      setOffset(projects.length);
    }
  };

  const showMore = !((projects?.length || 0) % PER_PAGE);

  const skeletonCount =
    useBreakpointValue({
      base: 3,
      md: 4,
      xl: 6,
      "2xl": 8,
    }) || 0;

  if (!loadingMore && loading && !!skeletonCount) {
    return (
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, minmax(0, 1fr))",
          xl: "repeat(3, minmax(0, 1fr))",
          "2xl": "repeat(4, minmax(0, 1fr))",
        }}
        gap={3}
      >
        {Array.from({ length: skeletonCount }).map((_, i) => (
          <ProjectCardSkeleton key={i} />
        ))}
      </Grid>
    );
  }

  return (
    <>
      {((!projects || projects.length <= 0) && (
        <EmptyState
          icon={<EmptyStateFontAwesomeIcon icon={duotone("images")} />}
          headline="No projects yet"
          body={
            isClassStudent
              ? "Complete a challenge and you could be the first!"
              : "Projects posted by students in this class will appear here"
          }
          cta={
            isClassStudent ? null : (
              <ButtonSolid size="lg" onClick={onClickInviteStudents}>
                Add Students
              </ButtonSolid>
            )
          }
        />
      )) || (
        <>
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, minmax(0, 1fr))",
              xl: "repeat(3, minmax(0, 1fr))",
              "2xl": "repeat(4, minmax(0, 1fr))",
            }}
            gap={3}
          >
            {projects?.map((project) => {
              return (
                <ProjectCard
                  key={project.id}
                  viewSource={ViewSource.TeacherClass}
                  project={project}
                  handleClickFromParent={(event) => {
                    handleOpenProjectModal(event, project);
                  }}
                />
              );
            })}
          </Grid>
          <Center mt={10}>
            {showMore && (
              <Button
                onClick={() => handleLoadMore()}
                colorScheme="brandFull"
                isLoading={loadingMore}
                size="lg"
              >
                Load More
              </Button>
            )}
          </Center>
        </>
      )}
    </>
  );
};
