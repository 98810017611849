import { useEffect, FC, PropsWithChildren } from "react";
import slug from "slug";
import { useMutation, useQuery } from "urql";
import { v4 } from "uuid";
import cuid from "cuid";
import { Link, useLocation } from "react-router-dom";
import { sample } from "lodash";

import {
  GetLatestPathReviewForPathQuery,
  GetLatestPathReviewForPathQueryVariables,
  GetPathWithNodesQuery,
  RequestReviewFromReviewerMutationVariables,
  SavePathStatusMutationVariables,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
} from "@tract/common/dist/graphql";
import { PathStatus } from "@tract/common/dist/types/models/Path";
import {
  GET_LATEST_PATH_REVIEW,
  GET_PATH_STATUS,
  SAVE_PATH_STATUS_MUTATION,
} from "Pages/CreateV4/graphql";
import { REQUEST_REVIEW_FROM_REVIEWER } from "Pages/AuthorPathReviews/graphql";

import {
  Box,
  Button,
  ButtonSolid,
  ExternalLink,
  Flex,
  HStack,
  IconAlertTriangle,
  IconVideo,
  ModalFooter,
  ModalBody,
  Text,
  useToast,
  VStack,
  IconList,
} from "Shared";
import { StepBox } from "./StepBox";
import { GiphyGif } from "Components/GiphyGif";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { getDisplayName } from "Types/User";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import {
  PathActivityType,
  PathValidationError,
  validatePath,
} from "../PathActions";
import { linkState, usePathV4Context } from "../LayoutCreateV4";
import { formatDistance } from "date-fns";
import { LayoutCentered } from "Components/LayoutCentered";
import { createNotification } from "@tract/common/dist/utils/create-notification";
import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";

const ValidationCard: React.FC<PropsWithChildren> = ({ children }) => (
  <Box
    p={6}
    pt={4}
    w="full"
    borderRadius="xl"
    borderStyle="solid"
    borderWidth="1px"
    borderColor="gray.200"
  >
    {children}
  </Box>
);

export const StepFeedback: React.FC<{
  path: GetPathWithNodesQuery["path"];
  onCloseModal: () => void;
  onChangeStep: (step: number) => void;
}> = ({ path, onCloseModal, onChangeStep }) => {
  const toast = useToast();
  const { state } = useLocation<linkState>();
  const { openRequestReviewCelebrateModal } = usePathV4Context();
  const { track } = useAnalytics();
  const { isAdmin, currentUser } = useSession();
  const { isValid, nodeErrors, settingErrors } = validatePath(path);
  const [, refetchPathStatus] = useQuery({
    query: GET_PATH_STATUS,
    variables: { pathId: path?.id },
  });
  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);
  const [, savePathStatus] = useMutation<{}, SavePathStatusMutationVariables>(
    SAVE_PATH_STATUS_MUTATION
  );
  const [, requestAnotherReview] = useMutation<
    {},
    RequestReviewFromReviewerMutationVariables
  >(REQUEST_REVIEW_FROM_REVIEWER);

  useEffect(() => {
    refetchPathStatus();
  }, [refetchPathStatus]);

  const saveStatus = async (newStatus: string) => {
    if (!path) return;

    if (!isAdmin) {
      if (
        path?.status === PathStatus.Published ||
        path?.status === PathStatus.Archived
      ) {
        toast({
          status: "error",
          title: `Path is already ${path?.status.toLowerCase()}`,
        });
        return;
      }
    }

    const pathSlugObj = {
      slug:
        path.slug === null && path.title && newStatus === PathStatus.Published
          ? `${slug(path.title)}-${cuid.slug()}`
          : path.slug,
    };

    const publishedAt =
      !path.publishedAt && newStatus === PathStatus.Published
        ? "now()"
        : path.publishedAt;

    const updatedPath = {
      isPublished: newStatus === PathStatus.Published,
      status: newStatus,
      publishedAt,
      authorId: path.authorId,
      authors: path.authors.map((a) => ({
        pathId: a.pathId,
        userId: a.userId,
      })),
      ...pathSlugObj,
    };

    const pathActivityId = v4();
    const pathReviewId = v4();

    let pathActivityType = PathActivityType.SetArchived;
    switch (newStatus) {
      case PathStatus.Archived:
        pathActivityType = PathActivityType.SetArchived;
        break;
      case PathStatus.Draft:
        pathActivityType = PathActivityType.SetDraft;
        break;
      case PathStatus.InReview:
        pathActivityType = PathActivityType.SetInReview;
        break;
      case PathStatus.Published:
        pathActivityType = PathActivityType.SetPublished;
        break;
      default:
        break;
    }

    savePathStatus({
      pathId: path.id,
      ...updatedPath,
      pathActivity: {
        id: pathActivityId,
        pathId: path.id,
        userId: currentUser.id,
        type: pathActivityType,
        pathReview:
          newStatus === PathStatus.InReview
            ? {
                data: {
                  id: pathReviewId,
                  pathId: path.id,
                  userId: currentUser.id,
                  type: PathActivityType.ReviewRequest,
                },
              }
            : undefined,
      },
    });
  };

  const [{ data, fetching: loadingLatestReview }] = useQuery<
    GetLatestPathReviewForPathQuery,
    GetLatestPathReviewForPathQueryVariables
  >({
    query: GET_LATEST_PATH_REVIEW,
    variables: {
      pathId: path?.id,
      type: { _neq: PathActivityType.ReviewRequest },
    },
    requestPolicy: "network-only",
  });
  const latestReview = !!data?.pathReview?.length
    ? data.pathReview[0]
    : undefined;

  const onRequestReview = (pathReviewId?: string) => {
    const { isValid } = validatePath(path);

    if (isValid || isAdmin) {
      saveStatus(PathStatus.InReview);
      if (!!pathReviewId) {
        requestAnotherReview({ pathReviewId: pathReviewId });

        if (latestReview && latestReview.userId !== currentUser.uid) {
          createFeedActivity({
            apiKey: process.env.REACT_APP_STREAM_API_KEY,
            feedSlug: "notification",
            feedUserId: latestReview.userId,
            activity: createNotification({
              type: "actor",
              actor: currentUser.id,
              verb: "review_request",
              entity: "path_review",
              entityId: latestReview.id,
              description: "requested a review on their path",
              targetPath: `/create/path/${path?.id}/activity`,
            }),
          });
        }
      } else {
        openRequestReviewCelebrateModal();
        onCloseModal();
      }

      track(ANALYTICS_EVENTS.PATH_REVIEW_REQUESTED, {
        pathId: path?.id,
        pathSlug: path?.slug,
        pathTitle: path?.title,
        pathCoAuthorsCount: path?.authors.length,
        pathAuthorUsername: path?.user?.username,
        pathAuthorCreatorLevel: path?.user?.creatorLevel,
      });
    } else {
      toast({
        title: "Please fix the issues before retrying",
        status: "error",
      });
    }
  };

  const onCancelRequest = () => {
    saveStatus(PathStatus.Draft);
  };

  if (isValid && loadingLatestReview)
    return <LayoutCentered h="full" isLoading />;

  return (
    <Flex direction="column" w="100%" h="100%">
      {(settingErrors.length > 0 || nodeErrors.length > 0) && (
        <ContentValidationErrors
          onBack={() => onChangeStep(2)}
          settingsErrors={settingErrors}
          nodeErrors={nodeErrors}
          onChangeStep={onChangeStep}
          onCloseModal={onCloseModal}
          state={state}
        />
      )}
      {isValid && !!latestReview ? (
        latestReview.type === "approve" ? (
          path?.status === PathStatus.Published ? (
            <ContentPublished
              onBack={() => onChangeStep(2)}
              onChangeStep={onChangeStep}
              latestReview={latestReview}
            />
          ) : (
            <ContentReadyForReview
              onBack={() => onChangeStep(2)}
              onForward={() => onRequestReview()}
              onChangeStep={onChangeStep}
            />
          )
        ) : (
          <>
            {path?.status === PathStatus.InReview ? (
              <ContentInReview
                onBack={() => onChangeStep(2)}
                onForward={() => onCancelRequest()}
                onChangeStep={onChangeStep}
              />
            ) : (
              <ContentRevisionsRequested
                onBack={() => onChangeStep(2)}
                onForward={() => onRequestReview(latestReview?.id)}
                onChangeStep={onChangeStep}
                latestReview={latestReview}
              />
            )}
          </>
        )
      ) : null}
      {isValid && !latestReview && !loadingLatestReview ? (
        path?.status === PathStatus.InReview ? (
          <ContentInReview
            onBack={() => onChangeStep(2)}
            onForward={() => onCancelRequest()}
            onChangeStep={onChangeStep}
          />
        ) : (
          <ContentReadyForReview
            onBack={() => onChangeStep(2)}
            onForward={() => onRequestReview()}
            onChangeStep={onChangeStep}
          />
        )
      ) : null}
    </Flex>
  );
};

type ContentProps = {
  onBack?: () => void;
  onForward?: () => void;
  onCloseModal?: () => void;
  onChangeStep: (step: number) => void;
};

const ContentValidationErrors: FC<
  ContentProps & {
    settingsErrors: PathValidationError[];
    nodeErrors: PathValidationError[];
    state: linkState;
  }
> = ({
  onBack,
  onCloseModal,
  onChangeStep,
  settingsErrors,
  nodeErrors,
  state,
}) => {
  return (
    <>
      <ModalBody>
        <StepBox>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Your path is missing important details
          </Text>
          <Text fontSize="md" color="gray.600">
            Before publishing, add the missing information below by clicking
            “Fix”.
          </Text>
          <VStack spacing={3} w="full" mt={6}>
            {settingsErrors.length > 0 && (
              <ValidationCard>
                <HStack spacing={3}>
                  <IconList size={20} />
                  <Text flex={1} fontSize="md" fontWeight="bold">
                    Details
                  </Text>
                  <ButtonSolid onClick={() => onChangeStep(1)}>Fix</ButtonSolid>
                </HStack>
                <VStack mt={2}>
                  {settingsErrors.map((error, i) => (
                    <HStack spacing={3} w="full" key={i}>
                      <IconAlertTriangle size={20} color="orange.500" />
                      <Text fontSize="md" flex={1}>
                        {error.message}
                      </Text>
                    </HStack>
                  ))}
                </VStack>
              </ValidationCard>
            )}
            {nodeErrors.length > 0 &&
              nodeErrors.map((nodeError, j) => (
                <ValidationCard key={j}>
                  <HStack spacing={3}>
                    <IconVideo size={20} />
                    <Text flex={1} fontSize="md" fontWeight="bold">
                      {nodeError.message}
                    </Text>
                    {nodeError.link && (
                      <ButtonSolid
                        onClick={onCloseModal}
                        as={Link}
                        to={{
                          pathname: nodeError.link,
                          state: state,
                        }}
                      >
                        Fix
                      </ButtonSolid>
                    )}
                  </HStack>
                  <VStack mt={2}>
                    {nodeError.childErrors?.map((error, k) => (
                      <HStack spacing={3} w="full" key={k}>
                        <IconAlertTriangle size={20} color="orange.500" />
                        <Text flex={1}>{error.message}</Text>
                      </HStack>
                    ))}
                  </VStack>
                </ValidationCard>
              ))}
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="outline" size="lg" minWidth="10rem" onClick={onBack}>
          Back
        </Button>
      </ModalFooter>
    </>
  );
};

const ContentReadyForReview: FC<ContentProps> = ({ onBack, onForward }) => {
  const gifIds = [
    "l1Aswx03WbLDf9kYw",
    "CjmvTCZf2U3p09Cn0h",
    "UhYmjuupNqAPauP0qF",
    "YFz6ixWv530divvhxb",
    "m67AyQex4TsI4XL0tY",
    "si4P9VBMEIhq40i6tT",
  ];
  const gifId = sample(gifIds) || gifIds[0];

  return (
    <>
      <ModalBody>
        <StepBox>
          <VStack spacing={10}>
            <Flex direction="column" textAlign="center">
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Get expert feedback and get published
              </Text>
              <Text fontSize="md" color="gray.600">
                Receive feedback from a Tract Creator to help you level up your
                skills.{" "}
                <ExternalLink
                  href="https://help.tract.app/en/articles/5935360-peer-reviews"
                  color="brand"
                  fontWeight="bold"
                  target="_blank"
                >
                  Learn more
                </ExternalLink>
              </Text>
            </Flex>
            <GiphyGif width={400} giphyId={gifId} />
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="outline" size="lg" minWidth="10rem" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="solid"
          minW="10rem"
          colorScheme="brandFull"
          size="lg"
          onClick={onForward}
        >
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};

const ContentInReview: FC<ContentProps> = ({ onBack, onForward }) => {
  const gifIds = [
    "mCRJDo24UvJMA",
    "lJNoBCvQYp7nq",
    "bAplZhiLAsNnG",
    "y1mWdggc3hEC4",
    "T8Dhl1KPyzRqU",
  ];
  const gifId = sample(gifIds) || gifIds[0];

  return (
    <>
      <ModalBody>
        <StepBox>
          <VStack spacing={10}>
            <Flex direction="column" textAlign="center">
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Your path is being reviewed
              </Text>
              <Text fontSize="md" color="gray.600">
                A Tract Creator will provide feedback and publish your path in
                the next 48 hours.{" "}
                <ExternalLink
                  href="https://help.tract.app/en/articles/5935360-peer-reviews"
                  color="brand"
                  fontWeight="bold"
                  target="_blank"
                >
                  Learn more
                </ExternalLink>
              </Text>
            </Flex>
            <GiphyGif width={400} giphyId={gifId} />
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="outline" size="lg" minWidth="10rem" onClick={onBack}>
          Back
        </Button>
        <Button variant="outline" minW="10rem" size="lg" onClick={onForward}>
          Cancel Review
        </Button>
      </ModalFooter>
    </>
  );
};

const ContentRevisionsRequested: FC<
  ContentProps & {
    latestReview: GetLatestPathReviewForPathQuery["pathReview"][0];
  }
> = ({ onBack, onForward, latestReview }) => {
  return (
    <>
      <ModalBody>
        <StepBox>
          <VStack spacing={10}>
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Your path requires changes to publish
              </Text>
              <Text fontSize="md" color="gray.600">
                Use the feedback below to improve your path, then submit your
                changes.{" "}
                <ExternalLink
                  href="https://help.tract.app/en/articles/5935360-peer-reviews"
                  color="brand"
                  fontWeight="bold"
                  target="_blank"
                >
                  Learn more
                </ExternalLink>
              </Text>
            </Flex>
            <ReviewFeedback
              latestReview={latestReview}
              explanationText="requested changes on your path"
            />
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="outline" size="lg" minWidth="10rem" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="solid"
          minW="10rem"
          colorScheme="brandFull"
          size="lg"
          onClick={onForward}
        >
          Submit
        </Button>
      </ModalFooter>
    </>
  );
};

const ContentPublished: FC<
  ContentProps & {
    latestReview: GetLatestPathReviewForPathQuery["pathReview"][0];
  }
> = ({ onBack, latestReview }) => {
  return (
    <>
      <ModalBody>
        <StepBox>
          <VStack align="stretch" spacing={10}>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb={2}>
                Your path has been published!
              </Text>
              <Text fontSize="md" color="gray.600">
                Great work! We can’t wait to see what you create next.
              </Text>
            </Box>
            <ReviewFeedback
              latestReview={latestReview}
              explanationText="approved and published your path"
            />
          </VStack>
        </StepBox>
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <Button variant="outline" size="lg" minWidth="10rem" onClick={onBack}>
          Back
        </Button>
      </ModalFooter>
    </>
  );
};

const ReviewFeedback: FC<{
  latestReview: GetLatestPathReviewForPathQuery["pathReview"][0];
  explanationText: string;
}> = ({ latestReview, explanationText }) => {
  return (
    <Box w="full">
      <HStack align="flex-start" spacing={3} w="full">
        <UserAvatar
          userId={latestReview.userId}
          avatarURL={latestReview.user.avatar || ""}
          username={latestReview.user.username || ""}
          isTeacher={latestReview.user?.isEducator}
        />
        <Box pt={1} w="full">
          <Text>
            <UsernameLink
              fontWeight="bold"
              userId={latestReview.userId}
              username={latestReview.user.username || ""}
              isTeacher={latestReview.user?.isEducator}
            >
              {getDisplayName(latestReview.user)}
            </UsernameLink>{" "}
            {explanationText}
          </Text>
          <Text color="gray.600" fontSize="sm">
            {formatDistance(new Date(latestReview.createdAt), new Date(), {
              addSuffix: true,
            })}
          </Text>
          <Text mt={4} w="full" bg="gray.50" p={4} borderRadius="xl">
            {latestReview.comment?.text}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};
