import { FC, PropsWithChildren, useState } from "react";
import { lookup } from "mime-types";

import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
  VideoPlayer,
  PopoverArrow,
} from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";

import { captureException } from "Services/errors";
import { storage } from "Services/firebase";

import { AdminProjectFragment } from "@tract/common/dist/graphql";

type Props = {
  project: AdminProjectFragment;
};

export const ArtifactPreviewPopover: FC<PropsWithChildren<Props>> = ({
  children,
  project,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const projectMime = lookup(project.file?.filename || "") || "";

  async function getPreviewURL() {
    let previewURL = "";
    try {
      const ref = storage("ugc").ref(
        `uf/${project.file?.id}/${project.file?.filename}`
      );

      previewURL = await ref.getDownloadURL();
    } catch (err: any) {
      captureException(err);
    }

    return previewURL;
  }

  const handlePopoverOpen = async () => {
    setIsLoading(true);

    try {
      const previewUrl = await getPreviewURL();
      setUrl(previewUrl);
    } catch (err: any) {
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Popover
      isLazy
      trigger="hover"
      arrowShadowColor="gray.300"
      onOpen={handlePopoverOpen}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent boxShadow="md" borderRadius="xl" borderColor="gray.300">
        <PopoverArrow />
        <PopoverBody p={2} minHeight="8rem">
          {isLoading ? (
            <LayoutCentered isLoading height="100%" />
          ) : (
            <Box borderRadius={2} overflow="hidden">
              {projectMime.startsWith("image") && <Image src={url} w="30rem" />}
              {projectMime.startsWith("video") && (
                <VideoPlayer url={url} w="30rem" borderRadius={0} />
              )}
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
