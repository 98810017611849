import { PropsWithChildren } from "react";
import { BoxProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/system";

import { Box, Text, Badge, Truncate } from "Shared";

import { GetAwardsQuery } from "@tract/common/dist/graphql";

type Props = {
  award: GetAwardsQuery["awards"][0];
  count: number;
} & BoxProps;

export const ProjectAwardItem = forwardRef<PropsWithChildren<Props>, "div">(
  ({ award, count, ...props }, ref) => {
    return (
      <Box ref={ref} {...props} position="relative">
        <Badge
          variant="outline"
          zIndex={1}
          position="absolute"
          top={-3}
          left={8}
          bg="white"
        >
          {count}X
        </Badge>

        <Box
          width={{ base: 8, lg: 10 }}
          height={{ base: 8, lg: 10 }}
          overflow="hidden"
          css={{ imageRendering: "pixelated" }}
        >
          <img
            src={`${process.env.REACT_APP_MEDIA_CDN_ORIGIN}/${award.filePath}`}
            width="100%"
            alt=""
          />
        </Box>

        <Text fontSize="md" lineHeight="1.4" mt={2}>
          <Truncate lines={2}>
            <span>{award.name}</span>
          </Truncate>
        </Text>
      </Box>
    );
  }
);
