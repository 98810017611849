import { MenuDivider } from "@chakra-ui/menu";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FC, useState } from "react";
import * as yup from "yup";
import copy from "copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "next-share";
import { Field, Formik, Form, FieldProps } from "formik";

import {
  Avatar,
  Button,
  Flex,
  FontAwesomeIcon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Input,
  IconTwitter,
  IconFacebook,
  IconLinkedin,
  IconLink,
  useToast,
  FormControl,
  FormErrorMessage,
} from "Shared";

import { OrgAvatar } from "Components/OrgAvatar";

import {
  isKidUser,
  isParentUser,
  isTeacherUser,
  getDisplayName,
} from "Types/User";
import { useFeature } from "Services/features";
import { CurrentUser, useSession } from "Services/session";
import { useSignOut } from "Services/auth";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

type Props = {
  user: CurrentUser;
};

type InviteForm = {
  inviteEmail: string;
};

const formSchema: yup.SchemaOf<any> = yup.object({
  inviteEmail: yup.string().email("Please enter a valid email"),
});

export const UserRow: FC<Props> = ({ user }) => {
  const toast = useToast();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const signOut = useSignOut();
  const { isAdmin } = useSession();
  const { track } = useAnalytics();
  const isKid = isKidUser(user);
  const isParent = isParentUser(user);
  const isTeacher = isTeacherUser(user);
  const displayName = getDisplayName(user);

  const isAchievementPageEnabled = useFeature("achievement-page");
  const areKidInvitesEnabled = useFeature("kid-invites");
  const isTypeformDeprecated = useFeature("deprecate-typeform");

  function getShareLink(source: string) {
    return `https://teach.tract.app/?utm_source=${source}&utm_medium=organic_social&utm_campaign=invite_a_teacher`;
  }

  const SHARE_MESSAGE =
    "Teachers — join me on Tract, an online community where kids teach kids through " +
    "multimedia. Sign up for FREE and try it out in your classroom to support PBL, " +
    "Genius Hour, social emotional learning, and more!";

  enum ShareType {
    Facebook = "facebook",
    Twitter = "twitter",
    LinkedIn = "linkedIn",
    CopyLink = "copyLink",
  }

  function sendInvite(values: InviteForm) {
    track(ANALYTICS_EVENTS.INVITE_COLLEAGUE_SENT, {
      inviteEmail: values.inviteEmail,
      inviterFirstName: user.firstName,
      inviterLastName: user.lastName,
    });
    setIsInviteModalOpen(false);
    toast({
      status: "success",
      title: "Invite Sent",
    });
  }

  return (
    <>
      <Menu>
        <MenuButton>
          <Avatar
            h="2.5rem"
            mx={1}
            w="2.5rem"
            src={user.avatar || ""}
            name={displayName || ""}
          />
        </MenuButton>
        <MenuList minWidth="20rem" maxW="20rem">
          <Flex direction="row" align="center" px={4} py={3} overflow="hidden">
            <Avatar
              name={displayName || ""}
              size="sm"
              src={user.avatar || ""}
            />
            <Text ml={3} fontWeight="bold" noOfLines={1}>
              {displayName}
            </Text>
          </Flex>
          {isAdmin && (
            <MenuItem
              as={Link}
              to={`/admin/users`}
              icon={<FontAwesomeIcon icon={regular("user-shield")} />}
            >
              Admin
            </MenuItem>
          )}
          {(!isParent || isTeacher) && (
            <MenuItem
              as={Link}
              to={`/me`}
              icon={<FontAwesomeIcon icon={regular("user")} />}
            >
              Profile
            </MenuItem>
          )}
          <MenuItem
            as={Link}
            to={`/settings`}
            icon={<FontAwesomeIcon icon={regular("gear")} />}
          >
            Settings
          </MenuItem>
          {isTeacher && isTypeformDeprecated && (
            <MenuItem
              icon={<FontAwesomeIcon icon={regular("paper-plane")} />}
              onClick={() => {
                track(ANALYTICS_EVENTS.INVITE_COLLEAGUE_CLICKED, {
                  inviteColleagueSource: "userDropdown",
                });
                setIsInviteModalOpen(true);
              }}
            >
              Invite a Colleague
            </MenuItem>
          )}
          {isTeacher && !isTypeformDeprecated && (
            <MenuItem
              as="a"
              href="https://5u0wpzphcth.typeform.com/to/Oxf8Oe9O"
              target="_blank"
              icon={<FontAwesomeIcon icon={regular("paper-plane")} />}
              onClick={() =>
                track(ANALYTICS_EVENTS.INVITE_COLLEAGUE_CLICKED, {
                  inviteColleagueSource: "userDropdown",
                })
              }
            >
              Invite a Colleague
            </MenuItem>
          )}
          {isKid && isAchievementPageEnabled && (
            <MenuItem
              as={Link}
              to={`/achievements`}
              icon={<FontAwesomeIcon icon={regular("trophy")} />}
            >
              Achievements
            </MenuItem>
          )}
          {areKidInvitesEnabled && (
            <MenuItem
              as={Link}
              to="/invites"
              icon={<FontAwesomeIcon icon={regular("paper-plane")} />}
            >
              Invites{" "}
              <Badge ml={2} color="brandFull.500" bgColor="brandFull.50">
                New
              </Badge>
            </MenuItem>
          )}
          {isKid && (
            <MenuItem
              as={Link}
              to={`/my-paths`}
              icon={<FontAwesomeIcon icon={regular("clock-rotate-left")} />}
            >
              Viewing History
            </MenuItem>
          )}
          {!!user.organization && (
            <>
              <MenuDivider />
              <MenuItem
                as={Link}
                to={`/explore/organization/${user.orgId}`}
                icon={<OrgAvatar src={user.organization.avatar} size="sm" />}
                fontWeight="bold"
              >
                {user.organization.name}
              </MenuItem>
              {isKid && (
                <MenuItem
                  as={Link}
                  to={`/classes`}
                  icon={<FontAwesomeIcon icon={regular("backpack")} />}
                >
                  Classes
                </MenuItem>
              )}
            </>
          )}
          <MenuDivider />
          <MenuItem
            onClick={signOut}
            icon={
              <FontAwesomeIcon icon={regular("arrow-right-from-bracket")} />
            }
          >
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>

      {isInviteModalOpen && (
        <Modal
          isOpen={true}
          onClose={() => setIsInviteModalOpen(false)}
          isCentered
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invite a Colleague</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={3}>
                Invite your fellow educators to join Tract for free.
              </Text>
              <Formik
                initialValues={{ inviteEmail: "" }}
                validationSchema={formSchema}
                onSubmit={(values) => sendInvite(values)}
              >
                {({ handleSubmit, isSubmitting, values }) => (
                  <Form onSubmit={handleSubmit} noValidate>
                    <Field name="inviteEmail">
                      {({ field, form: { submitCount }, meta }: FieldProps) => (
                        <FormControl
                          isRequired
                          isInvalid={submitCount > 0 && !!meta.error}
                        >
                          <Input
                            {...field}
                            id="inviteEmail"
                            name="inviteEmail"
                            type="email"
                            placeholder="Enter email..."
                          />
                          <FormErrorMessage>
                            Invalid email address
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      colorScheme="brandFull"
                      my={3}
                      mr={1}
                      size="lg"
                      type="submit"
                      disabled={isSubmitting || values.inviteEmail.length === 0}
                    >
                      Send Invite
                    </Button>
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="lg"
                        variant="outline"
                        leftIcon={<FontAwesomeIcon icon={regular("share")} />}
                      >
                        Share
                      </MenuButton>
                      <MenuList>
                        <TwitterShareButton
                          style={{ display: "block", width: "100%" }}
                          url={getShareLink("twitter")}
                          title={SHARE_MESSAGE}
                          onClick={() =>
                            track(
                              ANALYTICS_EVENTS.INVITE_COLLEAGUE_SOCIAL_CLICKED,
                              {
                                shareType: ShareType.Twitter,
                              }
                            )
                          }
                        >
                          <MenuItem as="div">
                            <IconTwitter color="#1DA1F2" mr={5} />
                            Share to Twitter
                          </MenuItem>
                        </TwitterShareButton>
                        <FacebookShareButton
                          style={{ display: "block", width: "100%" }}
                          url={getShareLink("facebook")}
                          onClick={() => {
                            track(
                              ANALYTICS_EVENTS.INVITE_COLLEAGUE_SOCIAL_CLICKED,
                              {
                                shareType: ShareType.Facebook,
                              }
                            );
                            setIsInviteModalOpen(false);
                          }}
                        >
                          <MenuItem as="div">
                            <IconFacebook color="#1DA1F2" mr={5} />
                            <Text>Share to Facebook</Text>
                          </MenuItem>
                        </FacebookShareButton>
                        <LinkedinShareButton
                          style={{ display: "block", width: "100%" }}
                          url={getShareLink("linkedin")}
                          onClick={() => {
                            track(
                              ANALYTICS_EVENTS.INVITE_COLLEAGUE_SOCIAL_CLICKED,
                              {
                                shareType: ShareType.LinkedIn,
                              }
                            );
                            setIsInviteModalOpen(false);
                          }}
                        >
                          <MenuItem as="div">
                            <IconLinkedin color="#1DA1F2" mr={5} />
                            Share to Linkedin
                          </MenuItem>
                        </LinkedinShareButton>
                        <MenuDivider />
                        <MenuItem
                          onClick={() => {
                            copy(getShareLink("link"));
                            toast({
                              status: "success",
                              title: "Link Copied",
                            });
                            track(
                              ANALYTICS_EVENTS.INVITE_COLLEAGUE_SOCIAL_CLICKED,
                              {
                                shareType: ShareType.CopyLink,
                              }
                            );
                            setIsInviteModalOpen(false);
                          }}
                        >
                          <IconLink mr={5} /> Copy Link
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};
