import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "urql";
import React, { useEffect, useState } from "react";
import { LexoRank } from "lexorank";
import { calculateNewLexorank, sortByLexorank } from "Utils/lexorank";
import {
  regular,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { captureException } from "Services/errors";

import { getDisplayName } from "Types/User";
import { ViewSource } from "Types/Path";

import {
  Box,
  FontAwesomeIcon,
  Grid,
  HStack,
  IconButton,
  IconBack,
  IconMoreVertical,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
  EmptyState,
  EmptyStateFontAwesomeIcon,
  ButtonSolid,
  AspectRatio,
  IconImage,
  TextClamp,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
} from "Shared";
import {
  CollectionByIdQuery,
  CollectionByIdQueryVariables,
  UpdatePathCollectionItemMutation,
  UpdatePathCollectionItemMutationVariables,
} from "@tract/common/dist/graphql";

import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import { PathCard } from "Components/PathCard";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { useSession } from "Services/session";

import { CollectionAddOrEditModal } from "Components/Collections/AddOrEditModal";
import { CollectionDeleteModal } from "Components/Collections/DeleteModal";

import {
  COLLECTION_QUERY,
  COLLECTION_ITEM_UPDATE_MUTATION,
} from "Components/Collections/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { PathCover } from "Components/PathCover";

export const Collection: React.FC = () => {
  const { state } = useLocation<{
    sourcePath?: string;
    sourceLabel?: string;
  }>();
  const session = useSession();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const {
    isOpen: editModalIsOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const {
    isOpen: deleteModalIsOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const {
    isOpen: organizeModalIsOpen,
    onOpen: onOrganizeModalOpen,
    onClose: onOrganizeModalClose,
  } = useDisclosure();

  const [collectionItems, setCollectionItems] = useState(
    [] as NonNullable<
      CollectionByIdQuery["path_collection_by_pk"]
    >["pathCollectionItems"]
  );
  const [{ fetching, error, data }] = useQuery<
    CollectionByIdQuery,
    CollectionByIdQueryVariables
  >({
    query: COLLECTION_QUERY,
    variables: { id },
  });

  const [, updateCollectionItemMutation] = useMutation<
    UpdatePathCollectionItemMutation,
    UpdatePathCollectionItemMutationVariables
  >(COLLECTION_ITEM_UPDATE_MUTATION);

  const collection = data?.path_collection_by_pk;

  useEffect(() => {
    if (collection?.pathCollectionItems)
      setCollectionItems(collection.pathCollectionItems.sort(sortByLexorank));
  }, [collection?.pathCollectionItems]);

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load collection.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  const onDragEnd = async (result: DropResult) => {
    // dropped outside the list or empty list
    if (!result.destination || !collectionItems?.length) {
      return;
    }

    const collectionItemsBeforeShift = [...collectionItems];

    const noPriorOrder = collectionItems.every(
      (item) => item.lexorank === LexoRank.middle().format()
    );
    if (noPriorOrder) {
      let previous = LexoRank.middle();
      for (const item of collectionItems) {
        const current = previous.genNext();
        item.lexorank = current.format();
        previous = current;
      }
    }

    const nodeMoving = collectionItems[result.source.index];

    if (!nodeMoving) return;

    const newLexoRank = calculateNewLexorank(
      collectionItems,
      result.source.index,
      result.destination.index
    );

    //Reorder nodes in state for instant feedback
    setCollectionItems(() => {
      return collectionItems
        .map((n) => {
          if (n.pathId === nodeMoving.pathId) {
            return { ...n, lexorank: newLexoRank };
          }
          return n;
        })
        .sort(sortByLexorank);
    });

    // Write new lexorank of node to db.
    // There will only be one item to update under normal circumstances.
    // If there is an old collection created before the items were ordered,
    // this will update all of them.
    const itemsToSave = noPriorOrder ? collectionItems : [nodeMoving];
    for (const item of itemsToSave) {
      const isMovingItem = item.pathId === nodeMoving.pathId;
      const lexorank = isMovingItem ? newLexoRank : item.lexorank;

      const result = await updateCollectionItemMutation({
        collectionId: item.collectionId,
        pathId: item.pathId,
        lexorank,
      });

      if (result.error) {
        captureException(result.error);

        //Restore prior node order
        setCollectionItems(collectionItemsBeforeShift);
        toast({
          title: "Error adjusting collection order",
          status: "error",
        });

        break;
      } else {
        toast({
          status: "success",
          title: "Order saved",
        });
      }
    }
  };

  if (fetching && !collection) {
    return <LayoutCentered isLoading />;
  } else {
    if (!collection) {
      return null;
    }

    return (
      <PageApp>
        <VStack mb={10} position="relative" marginTop={4}>
          {state?.sourcePath && (
            <Tooltip label={state?.sourceLabel} hasArrow>
              <IconButton
                as={Link}
                to={state?.sourcePath}
                variant="ghost"
                icon={<IconBack />}
                aria-label={state?.sourceLabel || "Back"}
                size="lg"
                position="absolute"
                left={0}
                top={0}
                marginTop={{ base: 2, xl: 6 }}
              />
            </Tooltip>
          )}
          <HStack>
            <PageHeader
              my={0}
              pl={session?.isAdmin ? "48px" : 0}
              textAlign="center"
              title={collection.title || undefined}
            />
            {session?.isAdmin && (
              <>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Collection actions"
                    icon={<IconMoreVertical />}
                    variant="ghost"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={onEditModalOpen}
                      icon={<FontAwesomeIcon icon={regular("pencil")} />}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      icon={<FontAwesomeIcon icon={regular("bring-forward")} />}
                      onClick={onOrganizeModalOpen}
                    >
                      Organize
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem
                      icon={<FontAwesomeIcon icon={regular("trash-can")} />}
                      onClick={onDeleteModalOpen}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
                <CollectionAddOrEditModal
                  isOpen={editModalIsOpen}
                  collectionMetadata={{
                    edit: {
                      id: collection.id,
                      title: collection.title || "",
                      description: collection.description || "",
                      userId: collection.user.id,
                    },
                  }}
                  onClose={onEditModalClose}
                />
                <CollectionDeleteModal
                  isOpen={deleteModalIsOpen}
                  collectionId={collection.id}
                  onClose={onDeleteModalClose}
                />
              </>
            )}
          </HStack>
          <Box display="block" maxWidth="50rem" pb={2} textAlign="center">
            {collection.description}
          </Box>
          <HStack>
            <UserAvatar
              userId={collection.user.id}
              username={collection.user.username || ""}
              name={collection.user && getDisplayName(collection?.user)}
              isTeacher={collection.user.isEducator}
              avatarURL={collection.user.avatar || ""}
            />
            <UsernameLink
              username={collection.user.username || ""}
              isTeacher={collection.user.isEducator}
              userId={collection.user.id}
            >
              {collection?.user && getDisplayName(collection?.user)}
            </UsernameLink>
          </HStack>
        </VStack>
        {!!collectionItems.length && (
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, minmax(0, 1fr))",
              xl: "repeat(3, minmax(0, 1fr))",
              "2xl": "repeat(4, minmax(0, 1fr))",
            }}
            gap={3}
          >
            {collectionItems?.map((item) => (
              <PathCard
                key={item.path.id}
                path={item.path}
                source={ViewSource.Collection}
                collectionId={item.collectionId}
              />
            ))}
          </Grid>
        )}
        {!collectionItems?.length && (
          <EmptyState
            icon={<EmptyStateFontAwesomeIcon icon={duotone("bookmark")} />}
            headline="No content saved yet"
            body={
              session?.isAdmin
                ? "Explore the content library to save content to this collection"
                : undefined
            }
            cta={
              !session?.isAdmin ? null : (
                <ButtonSolid size="lg" as={Link} to="/explore">
                  Start Exploring
                </ButtonSolid>
              )
            }
          />
        )}

        {!!collectionItems?.length && organizeModalIsOpen && (
          <Modal
            isOpen={organizeModalIsOpen}
            onClose={onOrganizeModalClose}
            size="full"
            isCentered
            scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Organize
                <ModalCloseButton>Done</ModalCloseButton>
              </ModalHeader>
              <ModalBody>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided1) => (
                      <Box
                        {...provided1.droppableProps}
                        ref={provided1.innerRef}
                        width="80%"
                        margin="auto"
                      >
                        {collectionItems.map((item, index) => {
                          return (
                            <Draggable
                              key={item.path.id}
                              draggableId={item.path.id.toString()}
                              index={index}
                            >
                              {(provided2) => (
                                <Box
                                  ref={provided2.innerRef}
                                  {...provided2.draggableProps}
                                  {...provided2.dragHandleProps}
                                  margin="5px auto"
                                >
                                  <HStack>
                                    <FontAwesomeIcon
                                      icon={regular("grip-lines")}
                                      width="10%"
                                    />
                                    <AspectRatio
                                      ratio={16 / 9}
                                      width="20%"
                                      borderRadius={8}
                                      overflow="hidden"
                                      bg="gray.100"
                                    >
                                      {!!item.path?.coverFile ? (
                                        <PathCover
                                          w={6}
                                          objectFit="cover"
                                          cover={item.path.coverFile}
                                        />
                                      ) : (
                                        <Box w={6}>
                                          <IconImage color="gray.400" />
                                        </Box>
                                      )}
                                    </AspectRatio>
                                    <TextClamp
                                      lines={1}
                                      fontWeight="bold"
                                      width="70%"
                                    >
                                      {item.path.title}
                                    </TextClamp>
                                  </HStack>
                                </Box>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided1.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </PageApp>
    );
  }
};
