import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Flex, Text, Link, ButtonSolid, useDisclosure } from "Shared";
import { LayoutCentered } from "Components/LayoutCentered";
import { LayoutOnboarding } from "Components/LayoutOnboarding";
import { ErrorModal } from "Components/ErrorModal";
import { PasswordResetForm } from "./PasswordResetForm";

import { updatePassword, verifyEmailActionCode } from "Services/auth";
import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { captureException } from "Services/errors";

import { USER_TYPES } from "Constants/userTypes";

export function ResetPassword({ oobCode, mode, userType }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [invalidLink, setInvalidLink] = useState(false);
  const { track } = useAnalytics();
  useEffect(() => {
    async function verifyActionCode() {
      try {
        setIsPageLoading(true);
        const email = await verifyEmailActionCode(oobCode, mode);
        setEmail(email);
        setIsPageLoading(false);
      } catch (e) {
        captureException(e);
        setInvalidLink(true);
        setIsPageLoading(false);
      }
    }
    verifyActionCode();
  }, [mode, oobCode]);

  async function handlePasswordUpdate({ password, confirmPassword }) {
    try {
      setIsLoading(true);
      await updatePassword(oobCode, password);
      track(ANALYTICS_EVENTS.PASSWORD_RESET_COMPLETED);
      if (userType === USER_TYPES.kid) {
        history.push("/sign-in/kid");
      } else {
        history.push("/sign-in/adult");
      }
      setIsLoading(false);
    } catch (e) {
      captureException(e);
      setIsLoading(false);
      onOpen();
    }
  }

  return (
    <>
      <ErrorModal
        onClose={onClose}
        isOpen={isOpen}
        onRetry={e => onClose()}
      ></ErrorModal>
      {isPageLoading ? (
        <LayoutCentered isLoading />
      ) : invalidLink ? (
        <LayoutOnboarding>
          <Text fontSize="xl" color="gray.900" fontWeight="bold" mb={4}>
            Oops!
          </Text>
          <Text fontSize="md" color="gray.900">
            Invalid or expired link.
          </Text>
          <Flex justify="center" mt={6}>
            <ButtonSolid as={Link} to={`/forgot-password/${userType}`}>
              Send New Reset Link
            </ButtonSolid>
          </Flex>
        </LayoutOnboarding>
      ) : (
        <LayoutOnboarding>
          <PasswordResetForm
            userType={userType}
            email={email}
            isLoading={isLoading}
            onSubmit={handlePasswordUpdate}
          />
        </LayoutOnboarding>
      )}
    </>
  );
}
