import { FC, useEffect, useState } from "react";
import { useQuery, useMutation, useClient, gql } from "urql";
import { LexoRank } from "lexorank";

import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
  useToast,
} from "Shared";

import { getTaughtClassesforUser } from "./util";

import { useAnalytics, ANALYTICS_EVENTS } from "Services/analytics";
import { captureException } from "Services/errors";
import { useCreateClassroomMutation } from "Services/classroom";

import {
  CurrentUserFragment,
  LearnerGroupSavesForPathQuery,
  LearnerGroupSavesForPathQueryVariables,
  GetLearnerGroupsForTeacherQuery,
  GetLearnerGroupsForTeacherQueryVariables,
  CreateLearnerGroupPathCollectionMutation,
  CreateLearnerGroupPathCollectionMutationVariables,
  InsertPathCollectionItemMutation,
  InsertPathCollectionItemMutationVariables,
  PathViewerPathFragment,
  PathCardFragment,
} from "@tract/common/dist/graphql";

type Props = {
  onClose: () => void;
  currentUser: CurrentUserFragment;
  path: PathViewerPathFragment | PathCardFragment;
};

export const LEARNER_GROUP_SAVES_FOR_PATH = gql`
  query LearnerGroupSavesForPath($pathId: bigint!) {
    path_collection_item(where: { pathId: { _eq: $pathId } }) {
      collectionId
      pathId
      pathCollection {
        id
        learnerGroupPathCollection {
          learnerGroupId
          pathCollectionId
        }
      }
    }
  }
`;

const CREATE_LEARNER_GROUP_PATH_COLLECTION = gql`
  mutation CreateLearnerGroupPathCollection(
    $learnerGroupId: uuid!
    $pathId: bigint!
    $userId: String!
    $lexorank: String!
  ) {
    insert_learner_group_path_collection_one(
      object: {
        learnerGroupId: $learnerGroupId
        pathCollection: {
          data: {
            pathCollectionItems: {
              data: { pathId: $pathId, lexorank: $lexorank }
            }
            userId: $userId
          }
        }
      }
    ) {
      pathCollectionId
      learnerGroupId
      pathCollection {
        id
        pathCollectionItems {
          collectionId
          pathId
          lexorank
          pathCollection {
            id
            learnerGroupPathCollection {
              learnerGroupId
              pathCollectionId
            }
          }
        }
      }
    }
  }
`;

const LEARNER_GROUP_COLLECTIONS = gql`
  query GetLearnerGroupsForTeacher($userId: String!) {
    learner_group_path_collection(
      where: {
        learnerGroup: {
          members: { role: { _eq: "teacher" }, userId: { _eq: $userId } }
        }
      }
    ) {
      _id: learnerGroupId
      pathCollectionId
      pathCollection {
        id
        pathCollectionItems {
          _id: pathId
          lexorank
        }
      }
      learnerGroupId
      learnerGroup {
        id
        name
      }
    }
  }
`;

export const INSERT_PATH_COLLECTION_ITEM = gql`
  mutation InsertPathCollectionItem(
    $collectionId: uuid!
    $pathId: bigint!
    $lexorank: String!
  ) {
    insert_path_collection_item_one(
      object: {
        collectionId: $collectionId
        pathId: $pathId
        lexorank: $lexorank
      }
    ) {
      collectionId
      pathId
      pathCollection {
        id
        learnerGroupPathCollection {
          learnerGroupId
          pathCollectionId
        }
      }
    }
  }
`;

export const SaveModal: FC<Props> = ({ onClose, path, currentUser }) => {
  const toast = useToast();
  const [pendingSaves, setPendingSaves] = useState<string[]>([]);
  const [addNewClass, setAddNewClass] = useState<boolean>(false);
  const [newClassName, setnewClassName] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const client = useClient();
  const taughtClasses = getTaughtClassesforUser(currentUser);
  const { track } = useAnalytics();

  const [{ fetching, error, data }] = useQuery<
    LearnerGroupSavesForPathQuery,
    LearnerGroupSavesForPathQueryVariables
  >({
    query: LEARNER_GROUP_SAVES_FOR_PATH,
    variables: { pathId: path.id },
  });

  const [, createCollection] = useMutation<
    CreateLearnerGroupPathCollectionMutation,
    CreateLearnerGroupPathCollectionMutationVariables
  >(CREATE_LEARNER_GROUP_PATH_COLLECTION);

  const [, insertPathCollectionItem] = useMutation<
    InsertPathCollectionItemMutation,
    InsertPathCollectionItemMutationVariables
  >(INSERT_PATH_COLLECTION_ITEM);

  const createClassroom = useCreateClassroomMutation();

  const savedLearnerGroupIds = data?.path_collection_item
    .map((pci) => pci.pathCollection.learnerGroupPathCollection)
    .flat()
    .map((x) => x.learnerGroupId);

  useEffect(() => {
    if (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to load paths.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  function checkboxOnChange(classId: string) {
    if (pendingSaves.includes(classId))
      setPendingSaves(pendingSaves.filter((id) => id !== classId));
    else setPendingSaves([...pendingSaves, classId]);
  }

  async function handleSave() {
    setSaving(true);

    try {
      // Create a new class if necessary
      if (addNewClass) {
        if (!currentUser.orgId) throw new Error("User has no organization");

        try {
          const output = await createClassroom({
            name: newClassName,
            orgId: currentUser.orgId,
            userId: currentUser.id,
            pathId: path.id,
          });
          if (output.error) throw new Error(output.error.message);
        } catch (err: any) {
          toast({ status: "error", title: "error creating classroom" });
          captureException(err);
        }
      }

      if (pendingSaves.length > 0) {
        // Handle saving to existing classes
        const result = await client
          .query<
            GetLearnerGroupsForTeacherQuery,
            GetLearnerGroupsForTeacherQueryVariables
          >(
            LEARNER_GROUP_COLLECTIONS,
            { userId: currentUser.id },
            { requestPolicy: "network-only" } // TODO: update/invalidate the cache instead
          )
          .toPromise();

        for (const learnerGroupId of pendingSaves) {
          // is there an existing learner_group_path_collection for this learner_group?
          // search for each of the user's learner groups

          // if so, find it by learner_group_id and get the path_collection_id
          const existingCollection =
            result.data?.learner_group_path_collection?.find(
              (pc) => pc.learnerGroupId === learnerGroupId
            );

          if (existingCollection) {
            let newLexoRank = LexoRank.middle();
            if (
              existingCollection.pathCollection.pathCollectionItems.length > 0
            ) {
              const items =
                existingCollection.pathCollection.pathCollectionItems;
              const lastItem = items[items.length - 1];
              newLexoRank = LexoRank.parse(lastItem.lexorank).genNext();
            }

            const vars = {
              collectionId: existingCollection.pathCollectionId,
              pathId: path.id,
              lexorank: newLexoRank.format(),
            };
            const output = await insertPathCollectionItem(vars);
            if (output.error) throw new Error(output.error.message);
          } else {
            // if not, create the whole tree:
            // learner_group_collection -> path_collection -> path_collection_item
            const output = await createCollection({
              learnerGroupId,
              pathId: path.id,
              userId: currentUser.id,
              lexorank: LexoRank.middle().format(),
            });
            if (output.error) throw new Error(output.error.message);
          }
        }
      }

      onClose();
      setPendingSaves([]);
      setnewClassName("");
      setSaving(false);

      toast({
        title: "Path Saved!",
        status: "success",
      });

      track(ANALYTICS_EVENTS.PATH_SAVED, {
        pathId: path.id,
        pathSlug: path.slug,
        pathTitle: path.title,
        pathAuthorId: path.authorId,
        pathAuthorUsername: path.user?.username,
        pathSubjectAreas: path.subjects || [],
        pathGradeLevels: path.gradeLevels || [],
        isTractOriginal: path.isTractOriginal,
      });
    } catch (e: any) {
      captureException(e);
      toast({
        title: "An error occurred.",
        description: "Unable to save path.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="lg"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save to Class</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!!fetching && (
            <Center py={20}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand"
                size="xl"
              />
            </Center>
          )}
          {!fetching && (
            <>
              <FormControl isRequired>
                <FormLabel>Select classes</FormLabel>
                <VStack align="stretch" spacing={2}>
                  {taughtClasses.map(({ id, name }) => (
                    <Checkbox
                      size="lg"
                      fontWeight="normal"
                      py={2}
                      color="gray.600"
                      key={id}
                      value={id}
                      isChecked={
                        savedLearnerGroupIds?.includes(id) ||
                        pendingSaves.includes(id)
                      }
                      disabled={savedLearnerGroupIds?.includes(id)}
                      onChange={() => checkboxOnChange(id)}
                    >
                      {name}
                    </Checkbox>
                  ))}
                  <Checkbox
                    size="lg"
                    fontWeight="normal"
                    py={2}
                    color="gray.600"
                    onChange={(e) => setAddNewClass(e.target.checked)}
                  >
                    Create a New Class
                  </Checkbox>
                </VStack>
              </FormControl>
              {addNewClass && (
                <FormControl isRequired pl="5" pt="5" pr="1">
                  <FormLabel>Class Name</FormLabel>
                  <Input
                    type="text"
                    name="classroomName"
                    size="lg"
                    placeholder="Class Name"
                    onChange={(e) => {
                      setnewClassName(e.target.value);
                    }}
                    autoComplete="off"
                    ref={(input) => input && input.focus()}
                  />
                </FormControl>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={2} flex={1}>
            <Button size="lg" variant="outline" flex={1} onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              size="lg"
              variant="solid"
              colorScheme="brandFull"
              flex={1}
              onClick={handleSave}
              isLoading={saving}
              disabled={
                saving ||
                (pendingSaves.length === 0 && newClassName.length === 0)
              }
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
