import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import axios, { AxiosError, AxiosResponse } from "axios";
import { ButtonProps } from "@chakra-ui/react";

import { ButtonOutlined, Text, useToast } from "Shared";
import { IconGoogle } from "Shared/Icon/custom/IconGoogle";
import { ProviderButtonProps } from "./_shared";

import { useInterval } from "@tract/common/dist/hooks";
import { captureException } from "Services/errors";
import { post } from "Services/api";
import { useSession } from "Services/session";
import { GOOGLE_ACCESS_SCOPES } from "@tract/common/dist/constants/google-access-scopes";

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const GoogleButton: React.FC<ProviderButtonProps & ButtonProps> = ({
  signUpRole,
  educatorCode,
  isDisabled,
  linkOnly = false,
  onLoading,
  onSuccess,
  onFailure,
  children,
  ...props
}) => {
  const googleAuthRef = useRef<gapi.auth2.GoogleAuth | null>(null);
  const googleButtonRef = useRef<HTMLButtonElement>(null);

  const [error, setError] = useState("");
  const [loadErrored, setLoadErrored] = useState(false);
  const [googleReady, setGoogleReady] = useState(!!window.gapi);
  const [loading, setLoading] = useState(false);
  const session = useSession({ bypassStrict: true });
  const toast = useToast();

  useInterval(
    () => {
      if (window.gapi) {
        setGoogleReady(true);
      }
    },
    googleReady ? null : 10
  );

  const handleGoogleFailure = useCallback(
    (reason: { error: string; details: string }) => {
      setLoadErrored(true);
      if (reason.details.toLowerCase().includes("cookies")) {
        setError(
          "Enable cookies or use a different browser before signing in with Google"
        );
      } else {
        setError(
          "Something went wrong with Google :( Use email/username or contact support"
        );
      }
    },
    []
  );

  useEffect(() => {
    if (!googleReady) return;

    window.gapi.load("auth2", function () {
      if (googleAuthRef.current) return;

      googleAuthRef.current = window.gapi.auth2.init({
        client_id: GOOGLE_AUTH_CLIENT_ID,
        cookie_policy: "single_host_origin",
      });

      googleAuthRef.current.then(() => {}, handleGoogleFailure);

      googleButtonRef.current?.addEventListener("click", async () => {
        try {
          const res = await googleAuthRef.current?.grantOfflineAccess({
            // Asking for more permissions can deter
            // new sign ups so do it only when linking
            ...(linkOnly && {
              scope: GOOGLE_ACCESS_SCOPES.join(" "),
            }),
            prompt: "select_account",
          });

          if (!res?.code) {
            setError("Something went wrong :(");
            return;
          }

          setLoading(true);
          const params = new URLSearchParams();
          params.append("code", res.code);
          if (linkOnly && session) {
            params.append("linkOnly", "1");

            return post("/v2/integrations/google", session.firebaseUser, params)
              .then(({ data, status }) => {
                if (status >= 400) throw new Error("Something went wrong");
                onSuccess(data);
              })
              .catch((err: AxiosError) => {
                onFailure && onFailure(err.response?.data.errorCode);
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            if (signUpRole) {
              params.append("signUpRole", signUpRole);
            }
            if (educatorCode) {
              params.append("educatorCode", educatorCode);
            }

            return axios
              .post<any, AxiosResponse<{ token: string }>>(
                `${process.env.REACT_APP_WEB_API_ORIGIN}/v2/integrations/google`,
                params
              )
              .then(({ status, data }) => {
                if (status >= 400) throw new Error("test");
                onSuccess(data.token);
              })
              .catch((err: AxiosError) => {
                onFailure && onFailure(err.response?.data.errorCode);
              })
              .finally(() => {
                setLoading(false);
                onLoading?.(false);
              });
          }
        } catch (error: any) {
          captureException(error);
          setError("Something went wrong :(");
        }
      });
    });
  }, [
    googleReady,
    onLoading,
    signUpRole,
    educatorCode,
    linkOnly,
    session,
    toast,
    onSuccess,
    onFailure,
    handleGoogleFailure,
  ]);

  return (
    <>
      <Helmet>
        <script src="https://apis.google.com/js/api:client.js" async />
      </Helmet>
      <ButtonOutlined
        leftIcon={<IconGoogle mr={1} />}
        ref={googleButtonRef}
        isLoading={loading}
        isDisabled={!googleReady || loadErrored || loading || isDisabled}
        w="full"
        h="48px"
        {...props}
      >
        {children}
      </ButtonOutlined>
      {error && (
        <Text fontWeight="bold" color="red.600">
          {error}
        </Text>
      )}
    </>
  );
};
