import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  forwardRef,
  Text,
} from "@chakra-ui/react";
import {
  Button,
  Center,
  IconFilledAffiliate,
  IconFilledCreator,
  IconFilledPartner,
  Link,
  Stack,
} from "Shared";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useSession } from "Services/session";
import {
  CreatorLevel,
  isAffiliateUser,
  isCreatorUser,
  isAuthorUser,
  isBaseCreator,
  isPartnerUser,
} from "Types/User";
import { useFeature } from "Services/features";
import { Redirect } from "react-router-dom";
import { BottomNav } from "Components/BottomNav";
import { PageApp } from "Components/PageApp";
import { PageHeader } from "Components/PageHeader";
import { useIntercom } from "Services/intercom";

const ActiveIndicator = forwardRef((props, ref) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      width="0.25rem"
      py={1}
      ref={ref}
      {...props}
    >
      <Box
        height="100%"
        width="0.25rem"
        borderTopRightRadius="0.25rem"
        borderBottomRightRadius="0.25rem"
        bg="brand"
      />
    </Box>
  );
});

export const Achievements: React.FC = () => {
  const { track } = useAnalytics();
  const { show: openIntercom } = useIntercom();
  const { currentUser } = useSession();
  const isAchievementPageEnabled = useFeature("achievement-page");

  const [creatorSectionOpen, setCreatorSectionOpen] = useState<CreatorLevel>(
    CreatorLevel.Creator
  );

  const onCreatorInterestFlagged = () => {
    track(ANALYTICS_EVENTS.CREATOR_INTEREST_FLAGGED, {
      creatorStatus: creatorSectionOpen.toLowerCase(),
    });

    openIntercom();
  };

  if (!isAchievementPageEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <PageApp renderBottomNav={<BottomNav />}>
      <Box mx="auto" maxWidth={{ md: "50rem" }}>
        <PageHeader title="Achievements" />
        <Accordion mx="auto" allowToggle>
          <AccordionItem
            bg="white"
            borderColor="gray.300"
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="2xl"
            overflow="hidden"
            boxShadow="sm"
            onClick={() => setCreatorSectionOpen(CreatorLevel.Creator)}
          >
            <Flex direction="column">
              <AccordionButton
                p={4}
                pr={6}
                _hover={{ bg: "none" }}
                _focus={{ outline: 0 }}
                position="relative"
                role="group"
              >
                <ActiveIndicator
                  transform="scale(0, 1)"
                  transition="transform .1s ease"
                  _groupFocus={{ display: "block", transform: "scale(1, 1)" }}
                  py={4}
                />
                <Center
                  width={12}
                  height={12}
                  bg="orange.500"
                  mr={4}
                  borderRadius="md"
                >
                  <IconFilledCreator color="white" h={8} w={8} />
                </Center>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "flex-start", md: "center" }}
                  justify={{ md: "space-between" }}
                  spacing={0}
                  flex={1}
                >
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="brand"
                    _hover={{ textDecoration: "underline" }}
                  >
                    Path to Creator
                  </Text>
                  <Badge
                    colorScheme={
                      isCreatorUser(currentUser) ||
                      isAffiliateUser(currentUser) ||
                      isPartnerUser(currentUser)
                        ? "green"
                        : "gray"
                    }
                    variant={
                      isCreatorUser(currentUser) ||
                      isAffiliateUser(currentUser) ||
                      isPartnerUser(currentUser)
                        ? "solid"
                        : "outline"
                    }
                  >
                    {isAuthorUser(currentUser) && !isBaseCreator(currentUser)
                      ? "Complete"
                      : "In Progress"}
                  </Badge>
                </Stack>
              </AccordionButton>
              <AccordionPanel p={0}>
                <Flex
                  direction="column"
                  align="flex-start"
                  mx={{ base: 6, lg: 20 }}
                  pb={10}
                >
                  <Text fontSize="2xl" fontWeight="bold" mt={4}>
                    Start creating on Tract
                  </Text>
                  <Text mt={4}>
                    Publish your first learning path on any topic you are
                    excited to teach about by following best practices in our{" "}
                    <Text
                      as={Link}
                      to="/explore/tag/creatoracademy"
                      color="brand"
                    >
                      #CreatorAcademy
                    </Text>
                    , or complete{" "}
                    <Text
                      as={Link}
                      to="/path/how-to-create-a-learning-path-on-tract/trailer"
                      color="brand"
                    >
                      How to Create a Learning Path
                    </Text>
                    . Your path must be reviewed and approved by the Tract team
                    and other Creators at your school.
                  </Text>
                  {isBaseCreator(currentUser) && (
                    <Button
                      size="lg"
                      variant="solid"
                      colorScheme="brandFull"
                      mt={6}
                      as={Link}
                      to="/author/published"
                    >
                      Get Started
                    </Button>
                  )}
                </Flex>
              </AccordionPanel>
            </Flex>
          </AccordionItem>

          <AccordionItem
            mt={4}
            bg="white"
            borderColor="gray.300"
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="2xl"
            overflow="hidden"
            boxShadow="sm"
            onClick={() => setCreatorSectionOpen(CreatorLevel.Affiliate)}
          >
            <Flex direction="column">
              <AccordionButton
                p={4}
                pr={6}
                _hover={{ bg: "none" }}
                _focus={{ outline: 0 }}
                position="relative"
                role="group"
              >
                <ActiveIndicator
                  transform="scale(0, 1)"
                  transition="transform .1s ease"
                  _groupFocus={{ display: "block", transform: "scale(1, 1)" }}
                  py={4}
                />
                <Center
                  width={12}
                  height={12}
                  bg="purple.500"
                  mr={4}
                  borderRadius="md"
                >
                  <IconFilledAffiliate color="white" h={8} w={8} />
                </Center>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "flex-start", md: "center" }}
                  justify={{ md: "space-between" }}
                  spacing={0}
                  flex={1}
                >
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="brand"
                    _hover={{ textDecoration: "underline" }}
                  >
                    Path to Affiliate
                  </Text>
                  {(isCreatorUser(currentUser) ||
                    isAffiliateUser(currentUser) ||
                    isPartnerUser(currentUser)) && (
                    <Badge
                      colorScheme={
                        isAffiliateUser(currentUser) ||
                        isPartnerUser(currentUser)
                          ? "green"
                          : "gray"
                      }
                      variant={
                        isAffiliateUser(currentUser) ||
                        isPartnerUser(currentUser)
                          ? "solid"
                          : "outline"
                      }
                    >
                      {isAffiliateUser(currentUser) ||
                      isPartnerUser(currentUser)
                        ? "Complete"
                        : "In Progress"}
                    </Badge>
                  )}
                </Stack>
              </AccordionButton>
              <AccordionPanel p={0}>
                <Flex
                  direction="column"
                  align="flex-start"
                  mx={{ base: 6, lg: 20 }}
                  pb={10}
                >
                  <Text fontSize="2xl" fontWeight="bold" mt={4}>
                    Expand your reach and become a star
                  </Text>
                  <Text mt={4}>
                    To be eligible, publish 5 learning paths and inspire 50
                    projects. The{" "}
                    <Text
                      as="a"
                      href="https://help.tract.app/en/articles/5872779-how-do-i-earn-affiliate-status"
                      color="brand"
                    >
                      Tract Affiliate Program
                    </Text>{" "}
                    puts qualified Creators one step closer toward the dream of
                    earning money from their passion for sharing knowledge.
                    Tract Affiliates can request feedback from Tract’s in-house
                    production team, and publish content to the entire member
                    base, while building an audience and working toward the
                    coveted status of Tract Partner. Perks include a Tract
                    T-Shirt, customized avatar, and visibility on the Creators
                    page.
                  </Text>
                  {isCreatorUser(currentUser) && (
                    <Button
                      size="lg"
                      variant="solid"
                      colorScheme="brandFull"
                      mt={6}
                      onClick={onCreatorInterestFlagged}
                    >
                      Apply Now
                    </Button>
                  )}
                </Flex>
              </AccordionPanel>
            </Flex>
          </AccordionItem>

          <AccordionItem
            mt={4}
            bg="white"
            borderColor="gray.300"
            borderWidth="1px"
            borderStyle="solid"
            borderRadius="2xl"
            overflow="hidden"
            boxShadow="sm"
            onClick={() => setCreatorSectionOpen(CreatorLevel.Partner)}
          >
            <Flex direction="column">
              <AccordionButton
                p={4}
                pr={6}
                _hover={{ bg: "none" }}
                _focus={{ outline: 0 }}
                position="relative"
                role="group"
              >
                <ActiveIndicator
                  transform="scale(0, 1)"
                  transition="transform .1s ease"
                  _groupFocus={{ display: "block", transform: "scale(1, 1)" }}
                  py={4}
                />
                <Center
                  width={12}
                  height={12}
                  bg="brandFull.400"
                  mr={4}
                  borderRadius="md"
                >
                  <IconFilledPartner color="white" h={8} w={8} />
                </Center>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  align={{ base: "flex-start", md: "center" }}
                  justify={{ md: "space-between" }}
                  spacing={0}
                  flex={1}
                >
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="brand"
                    _hover={{ textDecoration: "underline" }}
                  >
                    Path to Partner
                  </Text>
                  {(isPartnerUser(currentUser) ||
                    isAffiliateUser(currentUser)) && (
                    <Badge
                      colorScheme={
                        isPartnerUser(currentUser) ? "green" : "gray"
                      }
                      variant={isPartnerUser(currentUser) ? "solid" : "outline"}
                    >
                      {isPartnerUser(currentUser) ? "Complete" : "In Progress"}
                    </Badge>
                  )}
                </Stack>
              </AccordionButton>
              <AccordionPanel p={0}>
                <Flex
                  direction="column"
                  align="flex-start"
                  mx={{ base: 6, lg: 20 }}
                  pb={10}
                >
                  <Text fontSize="2xl" fontWeight="bold" mt={4}>
                    Earn money making videos in school
                  </Text>
                  <Text mt={4}>
                    To be eligible publish 10 paths, inspire 200 projects, and
                    attract 100 followers. The{" "}
                    <Text
                      as="a"
                      href="https://help.tract.app/en/articles/5952344-how-do-you-earn-partner-status"
                      color="brand"
                    >
                      Tract Partner Program
                    </Text>{" "}
                    is for those who are committed to creating and are ready to
                    level up from Affiliate. As a Tract Partner, you’ll receive
                    a 1-time allowance for an upgraded mic, lighting, and green
                    screen, the ability to monetize, featured discoverability
                    across Tract, and access to special projects and events.
                  </Text>
                  {isAffiliateUser(currentUser) && (
                    <Button
                      size="lg"
                      variant="solid"
                      colorScheme="brandFull"
                      mt={6}
                      onClick={onCreatorInterestFlagged}
                    >
                      Apply Now
                    </Button>
                  )}
                </Flex>
              </AccordionPanel>
            </Flex>
          </AccordionItem>
        </Accordion>
      </Box>
    </PageApp>
  );
};
