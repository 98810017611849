import { useChat } from "Services/chat";
import { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat";

import { captureException } from "Services/errors";
import { EMAIL_ACTION_MODES } from "Constants/emailActionModes";

import { get } from "./api";
import { useIntercom } from "./intercom";
import { firebaseClient } from "./firebase";
import {
  useAnalytics,
  ANALYTICS_EVENTS,
  SESSION_START_TIME_LS_KEY,
  LAST_EVENT_TIME_LS_KEY,
} from "./analytics";

import { Auth } from "Types/Auth";

export function useAuth() {
  const authListenerRef = useRef<firebase.Unsubscribe | null>(null);
  const { track } = useAnalytics();
  const [auth, setAuth] = useState<Auth | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | firebase.auth.AuthError | null>(
    null
  );

  const [didTrackSignIn, setDidTrackSignIn] = useState(false);
  useEffect(() => {
    if (!didTrackSignIn) {
      track(ANALYTICS_EVENTS.SIGN_IN_COMPLETED);
      setDidTrackSignIn(true);
    }
  }, [track, didTrackSignIn]);

  useEffect(() => {
    authListenerRef.current = firebaseClient.auth().onAuthStateChanged(
      async (user) => {
        if (user) {
          let isAdmin = false;
          let streamToken;
          let chatToken;
          let idTokenResult;

          if (!isAdmin) {
            try {
              await signOut();
            } catch (err) {
              captureException(err);
            } finally {
              window.location.replace("/");
            }
          }

          try {
            const { data } = await get("/v1/auth", user);
            idTokenResult = await user.getIdTokenResult(true);
            isAdmin = idTokenResult ? !!idTokenResult.claims.admin : false;
            streamToken = data.streamToken;
            chatToken = data.chatToken;
          } catch (err: any) {
            captureException(err);
            setError(err);
            setIsLoading(false);
            return;
          }

          setAuth({
            isAdmin: isAdmin,
            streamToken,
            chatToken,
          });

          // Need the auth user object intact without destructuring it
          setFirebaseUser(user);
        } else {
          setFirebaseUser(null);
          setAuth(null);
          localStorage.removeItem(SESSION_START_TIME_LS_KEY);
          localStorage.removeItem(LAST_EVENT_TIME_LS_KEY);
        }

        setIsLoading(false);
      },
      (error: any) => {
        captureException(error);
        setError(error);
        setIsLoading(false);
      }
    );

    return () => {
      authListenerRef.current?.();
    };
  }, []);

  return { auth, isLoading, error, firebaseUser };
}

export async function checkIfEmailExists(email: string) {
  return new Promise((resolve, reject) => {
    firebaseClient
      .auth()
      .fetchSignInMethodsForEmail(email)
      .then((signInMethods) => {
        // If there is any account with this email, we get that in signInMethods
        resolve(signInMethods.length > 0);
      })
      .catch(reject);
  });
}

export function signInWithEmail({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  return firebaseClient.auth().signInWithEmailAndPassword(email, password);
}

export function signInWithUsername({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  return firebaseClient
    .auth()
    .signInWithEmailAndPassword(username + "@tempaccounts.tract.app", password);
}

export async function signOut() {
  return firebaseClient
    .auth()
    .signOut()
    .then(() =>
      // Reload site to refresh integration scripts
      window.history.go(0)
    );
}

export function useSignOut() {
  const { reset: resetAnalytics } = useAnalytics();
  const { reset: resetChat } = useChat();
  const { isReady } = useIntercom();

  return () => {
    try {
      resetAnalytics();
      resetChat();

      if (isReady) {
        // Clear the Intercom session — Segment's reset() method does not clear sessions for integrations
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-logout
        window.Intercom("shutdown");
      }
    } catch (err: any) {
      captureException(err);
    }

    return signOut();
  };
}

export async function sendForgotPasswordLink(emailAddress: string) {
  return await firebaseClient.auth().sendPasswordResetEmail(emailAddress);
}

export async function verifyEmailActionCode(actionCode: string, mode: string) {
  if (mode === EMAIL_ACTION_MODES.resetPassword) {
    return firebaseClient.auth().verifyPasswordResetCode(actionCode);
  }
}

export async function updatePassword(actionCode: string, newPassword: string) {
  return firebaseClient.auth().confirmPasswordReset(actionCode, newPassword);
}
