import React, { PropsWithChildren, Ref } from "react";
import {
  AccordionItem as ChakraAccordionItem,
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps as ChakraAccordionButtonProps,
  AccordionPanel as ChakraAccordionPanel,
  AccordionItemProps as ChakraAccordionItemProps,
  BoxProps,
  forwardRef,
  useTheme,
} from "@chakra-ui/react";

import { Box, IconPlus, IconMinus } from "Shared";

export { Accordion } from "@chakra-ui/react";

type AccordionItemProps = PropsWithChildren<{
  heading: string;
  headingAs?: BoxProps["as"];
}> &
  ChakraAccordionItemProps;

export const AccordionItem = forwardRef(
  (
    { heading, headingAs, children, ...props }: AccordionItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <ChakraAccordionItem
        p={3}
        border={0}
        bg="gray.50"
        borderRadius={16}
        fontFamily="body"
        mb={4}
        ref={ref}
        {...props}
      >
        {({ isExpanded }) => (
          <>
            <AccordionHeader
              as={headingAs ? headingAs : "h2"}
              isExpanded={isExpanded}
              // rightIcon
            >
              {heading}
            </AccordionHeader>
            <ChakraAccordionPanel p={3}>{children}</ChakraAccordionPanel>
          </>
        )}
      </ChakraAccordionItem>
    );
  }
);

type AccordionHeaderProps = PropsWithChildren<{
  isExpanded: boolean;
}> &
  ChakraAccordionButtonProps;

const AccordionHeader = forwardRef(
  (
    { as, children, isExpanded, ...props }: AccordionHeaderProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { focusOutline } = useTheme();

    return (
      <Box as={as ? as : undefined}>
        <ChakraAccordionButton
          fontSize="xl"
          fontWeight="bold"
          color="gray.900"
          p={3}
          transition="none"
          _focus={{ ...focusOutline.normal }}
          _hover={{ bg: "transparent", textDecoration: "underline" }}
          ref={ref}
          {...props}
        >
          <Box flex="1" textAlign="left" mr={2}>
            {children}
          </Box>
          {isExpanded ? (
            <IconMinus color="gray.900" />
          ) : (
            <IconPlus color="gray.900" />
          )}
        </ChakraAccordionButton>
      </Box>
    );
  }
);
