import { gql } from "urql";

export const CREATE_FILE_MUTATION = gql`
  mutation CreateFile($file: user_file_insert_input!) {
    file: insert_user_file_one(
      object: $file
      on_conflict: {
        constraint: user_file_pkey
        update_columns: [id, filename, userId]
      }
    ) {
      id
      filename
      userId
    }
  }
`;
