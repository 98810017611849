import { FC, useContext } from "react";
import { useRouteMatch } from "react-router";

import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Flex,
  FontAwesomeIcon,
  HStack,
  IconButton,
  Link,
  Logo,
  useTheme,
  IconMenu,
  IconX,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from "Shared";

import { useFeature } from "Services/features";
import { useSession } from "Services/session";

import {
  TopNav,
  TopNavLink,
  TopNavActionsEnd,
  TopNavSearch,
} from "Components/TopNav";
import { NavigationHistory } from "Components/NavigationHistory";
import { MobileMenuContext } from "Components/MobileNav";

import { isAuthorUser, isParentUser, isTeacherUser } from "Types/User";

type Props = {};

export const TopNavApp: FC<Props> = () => {
  const { currentUser } = useSession();
  const isParent = isParentUser(currentUser);
  const isTeacher = isTeacherUser(currentUser);
  const isAuthor = isAuthorUser(currentUser);

  const isHome = !!useRouteMatch({ path: "/", exact: true });
  const isExplore = !!useRouteMatch("/explore");
  const isShop = !!useRouteMatch("/shop");
  const isAuthorRoute = !!useRouteMatch("/author");

  const { colors } = useTheme();

  const isGlobalNavEnabled = useFeature("global-navigation");
  //  We want shop enabled for users with no org also
  const isShopVisible =
    !currentUser.organization || currentUser.organization.shopEnabled;

  const exploreLink = "/explore";

  const {
    isOpen: showMobileNav,
    onToggle: onToggleMobileNav,
    onClose: onCloseMobileNav,
  } = useContext(MobileMenuContext);

  const renderLinks = () => {
    return (
      <>
        <TopNavLink
          to="/"
          isActive={isHome}
          icon={
            <FontAwesomeIcon
              icon={isHome ? solid("house") : regular("house")}
              size={8}
            />
          }
        >
          Home
        </TopNavLink>
        <TopNavLink
          to={exploreLink}
          isActive={isExplore}
          icon={
            <FontAwesomeIcon
              icon={isExplore ? solid("compass") : regular("compass")}
              size={8}
            />
          }
        >
          Explore
        </TopNavLink>
        {(!isParent || isTeacher) && isAuthor && (
          <TopNavLink
            to="/author/drafts"
            isActive={isAuthorRoute}
            icon={
              <FontAwesomeIcon
                icon={
                  isAuthorRoute
                    ? solid("wand-magic-sparkles")
                    : regular("wand-magic-sparkles")
                }
                size={8}
              />
            }
          >
            Create
          </TopNavLink>
        )}
        {!(isTeacher || isParent) && isShopVisible && (
          <TopNavLink
            to="/shop"
            isActive={isShop}
            icon={
              <FontAwesomeIcon
                icon={isShop ? solid("store") : regular("store")}
                size={8}
              />
            }
          >
            Shop
          </TopNavLink>
        )}
        {isTeacher && (
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              aria-label="More"
              size="md"
              icon={<FontAwesomeIcon icon={regular("ellipsis-vertical")} />}
            />
            <MenuList minW="18rem">
              <MenuItem
                icon={<FontAwesomeIcon icon={regular("calendar")} />}
                as={Link}
                to="/events"
              >
                Events
              </MenuItem>
              {isShopVisible && (
                <MenuItem
                  to="/shop"
                  as={Link}
                  icon={<FontAwesomeIcon icon={regular("store")} />}
                >
                  Shop
                </MenuItem>
              )}
              <MenuItem
                icon={<FontAwesomeIcon icon={regular("book-open")} />}
                as={Link}
                to="/resources"
              >
                Teaching Resources
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </>
    );
  };

  const baseToMd = useBreakpointValue({ base: true, lg: false }) || false;

  return (
    <TopNav
      renderStart={
        <Flex align="center" h="100%" flex={{ lg: 1 }}>
          <IconButton
            variant="ghost"
            aria-label="Menu"
            icon={showMobileNav ? <IconX /> : <IconMenu />}
            onClick={onToggleMobileNav}
            display={{ base: "inline-flex", lg: "none" }}
            ml={2}
          />
          <Flex mx={{ base: 4 }}>
            <Logo
              as={Link}
              to="/"
              color={colors.brand}
              height="2.5rem"
              w="auto"
              onClick={onCloseMobileNav}
            />
          </Flex>
          <HStack
            as="nav"
            spacing={{ base: 4, xl: 6 }}
            ml={2}
            mr={6}
            display={{ base: "none", lg: "flex" }}
          >
            {isGlobalNavEnabled && <NavigationHistory />}
            {renderLinks()}
          </HStack>
          <TopNavSearch display={{ base: "none", lg: "block" }} />
        </Flex>
      }
      renderEnd={
        <TopNavActionsEnd isCollapsed={baseToMd} hideUserRow={baseToMd} />
      }
    />
  );
};
