import { FC, useEffect } from "react";
import {
  Channel,
  MessageInput,
  VirtualizedMessageList,
} from "stream-chat-react";
import { Channel as IChannel } from "stream-chat";

import { Box, Flex } from "Shared";

import { LayoutCentered } from "Components/LayoutCentered";
import { LivestreamMessage } from "Components/Chat/LivestreamMessage";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import {
  LivestreamFragment,
  LivestreamHostUserFragment,
} from "@tract/common/dist/graphql";
import { useSession } from "Services/session";

type LivestreamChatProps = {
  livestream: LivestreamFragment;
  channel: IChannel;
  host: LivestreamHostUserFragment;
};

export const LivestreamChat: FC<LivestreamChatProps> = ({
  livestream,
  channel,
  host,
}) => {
  const { track } = useAnalytics();
  const { currentUser, isApprovedOrg } = useSession();

  const overrideSubmitHandler = (message: any) => {
    track(ANALYTICS_EVENTS.LIVESTREAM_CHAT_MESSAGE_SENT, {
      livestreamId: livestream.id,
      livestreamTitle: livestream.title,
      livestreamHostId: livestream.hostUserId,
      livestreamHostUsername: host.username,
    });
    if (!channel.disconnected) {
      channel.sendMessage(message);
    }
  };

  useEffect(() => {
    return () => {
      if (!channel.disconnected) {
        channel.stopWatching();
      }
    };
  }, [channel]);

  return (
    <Box
      css={{
        ".str-chat": {
          fontFamily: "unset",
          height: "100%",
          "*, *::before, *::after": {
            fontFamily: "unset",
          },
          ".str-chat__virtual-list": {
            padding: "0",
          },
          ".str-chat__virtual-list-message-wrapper": {
            padding: "1px 0",
          },
          ".str-chat__textarea__textarea": {
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            background: "white",
            border: "1px solid lightgrey",
            minHeight: "60px",
          },
          ".str-chat__textarea__textarea::-webkit-scrollbar": {
            display: "none",
          },
          ".str-chat__textarea": {
            height: "60px",
          },
          "div[data-test-id=virtuoso-scroller]::-webkit-scrollbar": {
            display: "none",
          },
          ".str-chat__message-livestream-author": {
            textTransform: "none",
          },
          ".str-chat__li--top, .str-chat__li--single": {
            margin: "0",
          },
          ".str-chat__li--bottom": {
            marginBottom: "0",
          },
          ".str-chat__input-flat": {
            padding: "8px 16px",
          },
        },
      }}
      flex={1}
      h={{ base: "100%", lg: "calc(100vh - 7.5rem - 1px)" }}
    >
      <Channel
        channel={channel}
        LoadingIndicator={() => <LayoutCentered isLoading h="100%" />}
      >
        <Flex direction="column" width="100%">
          <VirtualizedMessageList
            Message={LivestreamMessage}
            defaultItemHeight={70}
            suppressAutoscroll={false}
          />
          {currentUser.isEducator && !isApprovedOrg ? null : (
            <Box display={{ base: "none", lg: "block" }}>
              <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
            </Box>
          )}
        </Flex>
      </Channel>
    </Box>
  );
};
