import { Redirect } from "react-router-dom";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  FontAwesomeIcon,
  HStack,
  IconBack,
  IconButton,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from "Shared";

import { useClassContext } from "Components/LayoutClass";
import { useSession } from "Services/session";
import { SideNavLink } from "Components/SideNav/SideNavLink";
import { isTeacherUser } from "Types/User";

export const ClassroomMobileMenu = () => {
  const { classData, classPrimaryOwner, isClassOwner, onClickInviteStudents } =
    useClassContext();
  const { isAdmin, currentUser } = useSession();

  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const defaultRoute =
    isTeacherUser(currentUser) &&
    !classData?.members?.filter((m) => m.role !== "teacher").length
      ? "people"
      : "assignments";

  if (!isMobile)
    return <Redirect to={`/class/${classData.id}/${defaultRoute}`} />;

  return (
    <Box pb={6}>
      <AspectRatio ratio={5 / 2} maxH="8rem">
        <Box bg={classData?.color || "gray.100"}>
          <IconButton
            as={Link}
            size="lg"
            to={`/`}
            variant="ghost"
            color="white"
            colorScheme="whiteAlpha"
            pos="absolute"
            top={1}
            left={1}
            icon={<IconBack />}
            aria-label="Back"
          />
        </Box>
      </AspectRatio>
      <Box px={4} py={5}>
        <Text fontSize="xl" fontWeight="bold">
          {classData?.name}
        </Text>
        <HStack spacing={3} mt={2}>
          <Avatar
            size="sm"
            src={classPrimaryOwner?.avatar || undefined}
            name={`${classPrimaryOwner?.firstName} ${classPrimaryOwner?.lastName}`}
          />
          <Text fontSize="md" fontWeight="bold" color="gray.600">
            {classPrimaryOwner?.firstName} {classPrimaryOwner?.lastName}
          </Text>
        </HStack>
        {(isClassOwner || isAdmin) && (
          <ButtonGroup spacing={2} mt={5} w="full">
            <Button
              variant="solid"
              colorScheme="brandFull"
              size="lg"
              w="full"
              onClick={onClickInviteStudents}
            >
              Add Students
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <VStack w="100%" align="stretch" spacing={0} px={1}>
        <SideNavLink
          as={Link}
          to={`/class/${classData.id}/assignments`}
          fontSize="lg"
          minH="3.5rem"
          iconLeft={<FontAwesomeIcon icon={solid("inbox")} size={8} />}
          iconRight={<FontAwesomeIcon icon={regular("angle-right")} size={8} />}
        >
          Assignments
        </SideNavLink>
        <SideNavLink
          as={Link}
          to={`/class/${classData.id}/people`}
          fontSize="lg"
          minH="3.5rem"
          iconLeft={<FontAwesomeIcon icon={regular("users")} size={8} />}
          iconRight={<FontAwesomeIcon icon={regular("angle-right")} size={8} />}
        >
          People
        </SideNavLink>
        <SideNavLink
          as={Link}
          to={`/class/${classData.id}/gallery`}
          fontSize="lg"
          minH="3.5rem"
          iconLeft={<FontAwesomeIcon icon={regular("images")} size={8} />}
          iconRight={<FontAwesomeIcon icon={regular("angle-right")} size={8} />}
        >
          Gallery
        </SideNavLink>
        <SideNavLink
          as={Link}
          to={`/class/${classData.id}/saved`}
          fontSize="lg"
          minH="3.5rem"
          iconLeft={<FontAwesomeIcon icon={regular("bookmark")} size={8} />}
          iconRight={<FontAwesomeIcon icon={regular("angle-right")} size={8} />}
        >
          Saved
        </SideNavLink>
        {(isClassOwner || isAdmin) && (
          <SideNavLink
            as={Link}
            to={`/class/${classData.id}/moderation`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={
              <FontAwesomeIcon icon={regular("shield-check")} size={8} />
            }
            iconRight={
              <FontAwesomeIcon icon={regular("angle-right")} size={8} />
            }
          >
            Moderation
          </SideNavLink>
        )}
        {(isClassOwner || isAdmin) && (
          <SideNavLink
            as={Link}
            to={`/class/${classData.id}/settings`}
            fontSize="lg"
            minH="3.5rem"
            iconLeft={<FontAwesomeIcon icon={regular("gear")} size={8} />}
            iconRight={
              <FontAwesomeIcon icon={regular("angle-right")} size={8} />
            }
          >
            Settings
          </SideNavLink>
        )}
      </VStack>
    </Box>
  );
};
