import { PageApp } from "Components/PageApp";
import { useEffect, useState } from "react";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { useFeature } from "Services/features";
import { Redirect } from "react-router";
import { KidInvitesContent } from "./KidInvitesContent";

export const KidInvites = () => {
  const areKidInvitesEnabled = useFeature("kid-invites");
  const { track } = useAnalytics();
  const [pageTracked, setPageTracked] = useState(false);

  useEffect(() => {
    if (pageTracked) return;

    track(ANALYTICS_EVENTS.INVITES_VIEWED);
    setPageTracked(true);
  }, [pageTracked, track]);

  if (!areKidInvitesEnabled) return <Redirect to="/" />;

  return (
    <PageApp textAlign="center">
      <KidInvitesContent
        mt={20}
        title="Tract is better with friends"
        description="Learn, create, share, and teach together on Tract"
      />
    </PageApp>
  );
};
