export const pluralize = (
  value: number,
  word: string,
  plural: string | null = null,
  suffix = "s",
  format = false
) => {
  const valueToPrint = format ? value.toLocaleString() : value;
  if (value === 1) {
    return `${valueToPrint} ${word}`;
  }
  return plural
    ? `${valueToPrint} ${plural}`
    : `${valueToPrint} ${word}${suffix}`;
};
