import React, { FC, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  IconButton,
} from "@chakra-ui/react";
import { IconReaction } from "Shared";

import {
  ReactionSelector,
  StreamMessage,
  useReactionHandler,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

type Props = {
  message: StreamMessage<DefaultStreamChatGenerics>;
};

//To get the data to add more of these, follow instructions from the tip section here
//https://getstream.io/chat/docs/sdk/react/custom-code-examples/reactions/
export const customReactions = [
  {
    colons: ":tada:",
    emoticons: [],
    id: "tada",
    name: "Party Popper",
    native: "🎉",
    skin: null,
    unified: "1f389",
  },
  {
    colons: ":blush:",
    emoticons: [],
    id: "blush",
    name: "Smiling Face with Smiling Eyes",
    native: "😊",
    skin: null,
    unified: "1f60a",
  },
  {
    colons: ":clap:",
    emoticons: [],
    id: "clap",
    name: "Clapping Hands Sign",
    native: "👏",
    skin: null,
    unified: "1f44f",
  },
  {
    colons: ":laughing:",
    emoticons: [],
    id: "laughing",
    name: "Smiling Face with Open Mouth and Tightly-Closed Eyes",
    native: "😆",
    skin: null,
    unified: "1f606",
  },
  {
    colons: ":blue_heart:",
    emoticons: [],
    id: "blue_heart",
    name: "Blue Heart",
    native: "💙",
    skin: null,
    unified: "1f499",
  },
  {
    colons: ":fire:",
    emoticons: [],
    id: "fire",
    name: "Fire",
    native: "🔥",
    skin: null,
    unified: "1f525",
  },
];

export const ReactionPopover: FC<Props> = ({ message, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const reactionHandler = useReactionHandler(message);

  function handleReaction(reactionType: string, e: React.BaseSyntheticEvent) {
    reactionHandler(reactionType, e);
    close();
    return Promise.resolve();
  }

  return (
    <Popover isOpen={isOpen} onOpen={open} onClose={close}>
      <PopoverTrigger>
        <IconButton
          className="showFocused"
          aria-label="Chat message reaction"
          icon={<IconReaction width={4} height={4} />}
          variant="ghost"
          size="sm"
        />
      </PopoverTrigger>
      <PopoverContent
        zIndex={4}
        border="none"
        _focus={{ outline: 0 }}
        position="absolute"
        left="-225px"
        bottom="40px"
      >
        <ReactionSelector
          reactionOptions={customReactions}
          handleReaction={handleReaction}
          detailedView={false}
        />
      </PopoverContent>
    </Popover>
  );
};
