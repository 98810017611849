import { ShopCategory } from "Types/Prize";

export const getShopCategoryName = (category: ShopCategory) => {
  switch (category) {
    case ShopCategory.Donations:
      return "Donations";
    case ShopCategory.Experiences:
      return "Experiences";
    case ShopCategory.CreatorGoodies:
      return "Creator Goodies";
    case ShopCategory.Merch:
      return "Merch";
  }
};
