import React from "react";
import { useHistory } from "react-router-dom";

import { Box } from "Shared";

import { SSOButtonProps } from "./_shared";

import { GoogleButton } from "./GoogleButton";
import { CleverButton } from "./CleverButton";
import { MicrosoftButton } from "./MicrosoftButton";

import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { Enums_Integration_Provider_Enum } from "@tract/common/dist/graphql";

type Props = {
  provider: Enums_Integration_Provider_Enum;
} & SSOButtonProps;

export const SSOButton: React.FC<Props> = ({ provider, ...props }) => {
  const history = useHistory();
  const { track } = useAnalytics();

  const handleSuccess = (token?: string) => {
    const qs = new URLSearchParams();

    if (provider) {
      qs.set("provider", provider);
    }

    if (props.signUpRole) {
      qs.set("signUpRole", props.signUpRole);
    }

    if (token) {
      qs.set("token", token);
    }

    history.replace(`/sign-in?${qs.toString()}`);
  };

  const handleError = (errCode: string) => {
    if (props.signUpRole === "Educator") {
      history.replace(`/get-started/educator?errorCode=${errCode}`);
    } else if (props.signUpRole === "Student") {
      history.replace(
        `/get-started/kid?errorCode=${errCode}&code=${props.educatorCode}`
      );
    } else {
      history.replace(`/sign-in?errorCode=${errCode}`);
    }
  };

  const handleClick = () => {
    track(ANALYTICS_EVENTS.INTEGRATION_BUTTON_CLICKED, {
      provider: provider,
      isLinkOnly: props.linkOnly,
      signUpRole: props.signUpRole,
    });
  };

  const renderButton = () => {
    switch (provider) {
      case Enums_Integration_Provider_Enum.Google:
        return (
          <GoogleButton
            {...props}
            onFailure={handleError}
            onSuccess={handleSuccess}
          >{`${
            props.signUpRole ? "Sign Up" : "Sign In"
          } With Google`}</GoogleButton>
        );
      case Enums_Integration_Provider_Enum.Clever:
        return (
          <CleverButton
            {...props}
            onFailure={handleError}
            onSuccess={handleSuccess}
          >{`${
            props.signUpRole ? "Sign Up" : "Sign In"
          } With Clever`}</CleverButton>
        );
      case Enums_Integration_Provider_Enum.Microsoft:
        return (
          <MicrosoftButton
            {...props}
            onSuccess={handleSuccess}
            onFailure={handleError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box onClick={handleClick} w="full">
      {renderButton()}
    </Box>
  );
};
