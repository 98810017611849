import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import { FC, useCallback, useRef, useState } from "react";
import * as yup from "yup";
import { useMutation } from "urql";

import {
  ButtonLink,
  ButtonSolid,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "Shared";

import { gradeLevelList } from "Services/organization";
import { useSignOut } from "Services/auth";
import { useSession } from "Services/session";

import { EducatorRole } from "@tract/common/dist/types/models/Educator";
import { GradeLevel } from "@tract/common/dist/types/models/Organization";
import { PlaceResult } from "@tract/common/dist/utils/parse-google-address";
import { SchoolSearchInput } from "./SchoolSearchInput";
import {
  OnboardEducatorStepSchoolMutation,
  OnboardEducatorStepSchoolMutationVariables,
} from "@tract/common/dist/graphql";
import { ANALYTICS_EVENTS, GroupKey, useAnalytics } from "Services/analytics";
import { ONBOARD_EDUCATOR_STEP_SCHOOOL } from "./graphql";

export type StepSchoolForm = {
  isVerified: boolean;
  isOrgAvailable: boolean;
  placeId: string;
  placeName: string;
  orgId: string;
  gradeLevel: GradeLevel;
  educatorRole: EducatorRole;
  phoneNumber?: string;
  accessCode?: string;
};

type StepProps = {
  organizations?: {
    isVerified: boolean;
    name: string;
    id: string;
  }[];
  onContinue: () => void;
};

const schoolInfoFormSchema = yup.object().shape({
  isVerified: yup.boolean(),
  placeId: yup.mixed().when("isVerified", {
    is: false,
    then: yup.string().required("Please select a valid school"),
    otherwise: yup.mixed().optional(),
  }),
  placeName: yup.string(),
  orgId: yup.mixed().when("isVerified", {
    is: true,
    then: yup.string().required("Please select a valid school"),
    otherwise: yup.mixed().optional(),
  }),
  gradeLevel: yup.string().oneOf(
    gradeLevelList.map(({ value }) => value),
    "Please select a valid grade level"
  ),
  phoneNumber: yup.string().optional(),
  accessCode: yup.string().optional(),
  educatorRole: yup
    .string()
    .oneOf(Object.values(EducatorRole), "Please select a valid educator role"),
});

export const ORG_NOT_AVAILABLE_ERROR_MSG =
  "This school is already linked to another email domain. Contact support for assistance.";

export const StepSchool: FC<StepProps & {}> = ({
  organizations = [],
  onContinue,
}) => {
  const isVerified = organizations.length >= 1;
  const toast = useToast();
  const analytics = useAnalytics();
  const { currentUser } = useSession();
  const formRef = useRef<FormikProps<StepSchoolForm>>(null);
  const [showSearchInput, setShowSearchInput] = useState(!isVerified);
  const signOut = useSignOut();

  const [, onboardEducator] = useMutation<
    OnboardEducatorStepSchoolMutation,
    OnboardEducatorStepSchoolMutationVariables
  >(ONBOARD_EDUCATOR_STEP_SCHOOOL);

  const onSubmitStep = async (
    values: StepSchoolForm,
    formikHelpers: FormikHelpers<StepSchoolForm>
  ) => {
    if (!values.orgId && !values.isOrgAvailable) {
      formikHelpers.setFieldError("placeId", ORG_NOT_AVAILABLE_ERROR_MSG);
      return;
    }

    const { error, data } = await onboardEducator({
      orgId: values.orgId || undefined,
      placeId: values.placeId || undefined,
      gradeLevel: values.gradeLevel || undefined,
    });

    const orgId = data?.api_onboard_educator?.orgId;

    if (error || !orgId) {
      toast({
        status: "error",
        title: "error saving school details",
      });
      return;
    }

    const selectedOrg = organizations.find((org) => org.id === orgId);
    const orgName = selectedOrg?.name || values.placeName;

    analytics.identify(currentUser.id, {
      orgId,
      orgName,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      gradeLevel: values.gradeLevel,
      email: currentUser.email,
      educatorRole: values.educatorRole,
      phoneNumber: values.phoneNumber,
    });

    analytics.group(GroupKey.Org, orgId, {
      name: orgName,
    });

    analytics.track(ANALYTICS_EVENTS.EDUCATOR_ORG_SELECTED, {
      orgId,
      orgName,
      orgEduStage: values.gradeLevel,
      learnerGroupOwnerId: currentUser.id,
      isVerifiedOrg: !!selectedOrg?.isVerified,
      accessCode: values.accessCode,
      educatorRole: values.educatorRole,
    });

    onContinue();
  };

  const onSchoolAvailability = useCallback(
    (isAvailable: boolean, loadingAvailability: boolean) => {
      formRef.current?.setFieldValue(
        "isOrgAvailable",
        !loadingAvailability && isAvailable
      );
      if (!loadingAvailability && isAvailable === false) {
        formRef.current?.setFieldError("placeId", ORG_NOT_AVAILABLE_ERROR_MSG);
      }
    },
    [formRef]
  );

  const onSchoolSelect = (selectedSchool: PlaceResult) => {
    formRef.current?.setFieldValue("placeId", selectedSchool.place_id);
    formRef.current?.setFieldValue("placeName", selectedSchool.name);
  };

  const onSchoolUnselect = () => {
    formRef.current?.setFieldValue("placeId", "");
    formRef.current?.setFieldValue("placeName", "");
  };

  return (
    <>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        Tell us about your school
      </Text>
      <Text fontSize="md" color="gray.600">
        If your colleagues are already on Tract, we’ll add you to their team
      </Text>
      <Formik
        initialValues={{
          isVerified: isVerified,
          isOrgAvailable: false,
          placeId: "",
          placeName: "",
          orgId: "",
          gradeLevel: GradeLevel.Primary,
          educatorRole: EducatorRole.Teacher,
          phoneNumber: "",
          accessCode: "",
        }}
        onSubmit={onSubmitStep}
        validationSchema={schoolInfoFormSchema}
        innerRef={formRef}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Field name={showSearchInput ? "placeId" : "orgId"}>
              {({ field, form, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={form.submitCount > 0 && !!meta.error}
                  mb={6}
                  mt={10}
                >
                  {showSearchInput ? (
                    <SchoolSearchInput
                      inputLabel="School Name and Address"
                      inputProps={{
                        variant: "filled",
                        id: "placeId",
                        name: "placeId",
                        placeholder: "Search by school name or address",
                      }}
                      labelProps={{
                        htmlFor: "placeId",
                      }}
                      onAvailability={onSchoolAvailability}
                      onSchoolSelect={onSchoolSelect}
                      onSchoolUnselect={onSchoolUnselect}
                      showHelp
                    />
                  ) : (
                    <>
                      <FormLabel htmlFor="orgId">School Name</FormLabel>
                      <Select
                        {...field}
                        variant="filled"
                        id="orgId"
                        size="lg"
                        borderRadius="lg"
                        placeholder="School Name"
                      >
                        {organizations.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.name}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                  <FormErrorMessage textAlign="left">
                    {meta.error}
                  </FormErrorMessage>
                  {isVerified && showSearchInput && (
                    <FormHelperText textAlign="left">
                      <ButtonLink
                        color="brand"
                        onClick={() => {
                          form.setFieldValue("isVerified", true);
                          form.setFieldValue("placeId", "");
                          form.setFieldValue("isOrgAvailable", false);
                          setShowSearchInput(false);
                        }}
                      >
                        Choose an Existing School
                      </ButtonLink>
                    </FormHelperText>
                  )}
                  {isVerified && !showSearchInput && (
                    <FormHelperText textAlign="left">
                      Don't see your school?{" "}
                      <ButtonLink
                        color="brand"
                        onClick={() => {
                          form.setFieldValue("isVerified", false);
                          form.setFieldValue("placeId", "");
                          form.setFieldValue("isOrgAvailable", false);
                          form.setFieldValue("orgId", "");
                          setShowSearchInput(true);
                        }}
                      >
                        Add New School
                      </ButtonLink>
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            </Field>
            {values.placeId && !values.orgId && (
              <Field name="gradeLevel">
                {({ field, form: { submitCount }, meta }: FieldProps) => (
                  <FormControl
                    isRequired
                    isInvalid={submitCount > 0 && !!meta.error}
                    mb={6}
                  >
                    <FormLabel htmlFor="gradeLevel">Grade Level</FormLabel>
                    <Select
                      {...field}
                      variant="filled"
                      id="gradeLevel"
                      size="lg"
                      borderRadius="lg"
                    >
                      {gradeLevelList.map((gl) => (
                        <option key={gl.value} value={gl.value}>
                          {gl.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}
            <Field name="educatorRole">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={submitCount > 0 && !!meta.error}
                  mb={6}
                >
                  <FormLabel htmlFor="educatorRole">Educator Role</FormLabel>
                  <Select
                    {...field}
                    variant="filled"
                    id="educatorRole"
                    size="lg"
                    borderRadius="lg"
                  >
                    <option value={EducatorRole.Teacher}>
                      {EducatorRole.Teacher}
                    </option>
                    <option value={EducatorRole.Principal}>
                      {EducatorRole.Principal}
                    </option>
                    <option value={EducatorRole.TechnologySpecialist}>
                      {EducatorRole.TechnologySpecialist}
                    </option>
                    <option value={EducatorRole.Administrator}>
                      {EducatorRole.Administrator}
                    </option>
                  </Select>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="phoneNumber">
              {({ field, form: { submitCount }, meta }: FieldProps) => (
                <FormControl isInvalid={submitCount > 0 && !!meta.error} mb={6}>
                  <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
                  <Input
                    {...field}
                    variant="filled"
                    id="phoneNumber"
                    size="lg"
                    type="phoneNumber"
                    borderRadius="lg"
                    placeholder="Optional"
                  />

                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="accessCode">
              {({ field, meta }: FieldProps) => (
                <FormControl mb={6}>
                  <FormLabel htmlFor="accessCode">Access Code</FormLabel>
                  <Input
                    {...field}
                    variant="filled"
                    id="accessCode"
                    size="lg"
                    borderRadius="lg"
                    placeholder="Optional"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <ButtonSolid
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              width="full"
              size="lg"
              mb={10}
            >
              Continue
            </ButtonSolid>
            <ButtonLink onClick={signOut} colorScheme="brandFull">
              Sign Out
            </ButtonLink>
          </Form>
        )}
      </Formik>
    </>
  );
};
