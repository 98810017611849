import React, { useState } from "react";

import {
  Avatar,
  Badge,
  Button,
  ButtonGroup,
  HStack,
  Link,
  Tr,
  Td,
  useToast,
  Text,
  ButtonLink,
} from "Shared";

import {
  ADMIN_UPDATE_COMMENT_STATUS_MUTATION,
  ADMIN_PARENT_COMMENT_QUERY,
} from "./graphql";

import { STREAM_IO_CREATE_ACTIVITY } from "graphql/streamio";
import { createNotification } from "@tract/common/dist/utils/create-notification";

import {
  AdminCommentFragment,
  AdminUpdateCommentStatusMutation,
  AdminUpdateCommentStatusMutationVariables,
  ParentCommentQuery,
  ParentCommentQueryVariables,
  StreamIoCreateActivityMutation,
  StreamIoCreateActivityMutationVariables,
} from "@tract/common/dist/graphql";

import { captureException, captureMessage } from "Services/errors";
import { GiphyGif } from "Components/GiphyGif";
import { useMutation, useClient } from "urql";

type CommentProps = {
  comment: AdminCommentFragment;
  onUsernameClick: (userdata: { username: string; userId: string }) => void;
};

export const CommentRowV2: React.FC<CommentProps> = ({
  comment,
  onUsernameClick,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [, createFeedActivity] = useMutation<
    StreamIoCreateActivityMutation,
    StreamIoCreateActivityMutationVariables
  >(STREAM_IO_CREATE_ACTIVITY);

  // NOTE: this logic is also found in in AdminCommentsV2.tsx
  // it's not been accepted or rejected
  const needsReview =
    !comment.published && comment.text && comment.deletedAt === "-infinity";
  // comments that have no text (awards and gifs) are approved by default
  // but can also be manually deleted
  const isAccepted =
    (comment.published || !comment.text) && comment.deletedAt === "-infinity";
  // it's been manually rejected if it has been deleted
  const isRejected = comment.deletedAt !== "-infinity";

  const isReply = comment.path?.includes(".");

  const [, updateCommentStatus] = useMutation<
    AdminUpdateCommentStatusMutation,
    AdminUpdateCommentStatusMutationVariables
  >(ADMIN_UPDATE_COMMENT_STATUS_MUTATION);

  const client = useClient();

  const handleUpdateComment = async (reject: boolean) => {
    setLoading(true);
    try {
      const mutationResult = await updateCommentStatus({
        commentId: comment.id,
        deletedAt: reject ? "now()" : "-infinity",
        published: !reject,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      const parentCommentId = comment.path?.split(".")[0]?.replaceAll("_", "-");
      let parentCommentAuthorId = null;
      if (parentCommentId) {
        const data = await client
          .query<ParentCommentQuery, ParentCommentQueryVariables>(
            ADMIN_PARENT_COMMENT_QUERY,
            { id: parentCommentId }
          )
          .toPromise();
        parentCommentAuthorId = data.data?.comment_by_pk?.userId;
      }

      const pathAuthorId = comment.pathNodeComment?.node.path.authorId;
      const commenterId = comment.user.id;

      if (!reject) {
        if (
          (!isReply && pathAuthorId !== commenterId) ||
          (isReply && parentCommentAuthorId !== commenterId)
        ) {
          const recipient = isReply ? parentCommentAuthorId : pathAuthorId;

          if (!recipient) {
            captureMessage("No comment recipient found");
            return;
          }

          createFeedActivity({
            apiKey: process.env.REACT_APP_STREAM_API_KEY,
            feedSlug: "notification",
            feedUserId: recipient,
            activity: createNotification({
              type: "actor",
              verb: "comment",
              actor: comment.user.id,
              entity: "path_node_comment",
              entityId: comment.id,
              targetPath: `/path/${comment.pathNodeComment?.node.path.id}/v/${comment.pathNodeComment?.node.id}`,
              description: isReply
                ? "replied to your comment"
                : "commented on your video",
              meta: {
                schemaVersion: 1,
                isReply,
              },
            }),
          });
        }
      }

      toast({
        title: "Updated comment status",
        status: "success",
      });
    } catch (error: any) {
      captureException(error);
      toast({
        title: "Error updating comment",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tr>
      <Td py={2}>
        <HStack>
          <Avatar src={comment.user.avatar || ""} size="sm" mr={2} />
          <Button
            variant="link"
            color="gray.900"
            fontSize="md"
            fontWeight="bold"
            onClick={() =>
              onUsernameClick({
                username: comment.user.username || "",
                userId: comment.user.id,
              })
            }
          >
            {!!comment.user.username
              ? comment.user.username
              : `${comment.user.firstName} ${comment.user.lastName || ""}`}
          </Button>
          {comment.user.invitedBy && (
            <Text as="span" fontSize="sm" color="gray.600">
              Invited by{" "}
              <ButtonLink
                as={Link}
                fontSize="sm"
                colorScheme="brandFull"
                to={`/@${comment.user.invitedBy.username}`}
              >
                {comment.user.invitedBy.username}
              </ButtonLink>
            </Text>
          )}
        </HStack>
      </Td>
      <Td py={2} fontWeight="normal" fontSize="md">
        {new Date(comment.createdAt).toLocaleString()}
      </Td>
      <Td py={2} fontWeight="normal" fontSize="md" wordBreak="break-word">
        {comment.projectComment?.awardId && (
          <>
            <Text fontStyle="italic" as="span">
              [Award]
            </Text>{" "}
          </>
        )}
        {comment.text}
        {comment.giphyId && <GiphyGif giphyId={comment.giphyId} width={150} />}
      </Td>
      <Td py={2} fontWeight="normal" fontSize="md">
        {comment.pathNodeComment && (
          <Link
            to={`/node/${comment.pathNodeComment.pathNodeId}`}
            target="_blank"
          >
            Path Video
          </Link>
        )}
        {comment.projectComment && (
          <Link
            to={`/project/${comment.projectComment.projectId}`}
            target="_blank"
          >
            Project
          </Link>
        )}
        {comment.pathReviewComment && (
          <Link
            to={`/path/review/${comment.pathReviewComment.pathId}`}
            target="_blank"
          >
            Peer Review
          </Link>
        )}
      </Td>
      <Td py={2}>
        {(isAccepted || comment.giphyId || comment.projectComment?.awardId) &&
          !isRejected && (
            <Badge colorScheme={"green"} fontSize="xs" variant="solid">
              Accepted
            </Badge>
          )}
        {isRejected && (
          <Badge colorScheme={"red"} fontSize="xs" variant="solid">
            Rejected
          </Badge>
        )}
      </Td>
      <Td py={2} textAlign="end">
        <ButtonGroup>
          {(needsReview || isRejected) && (
            <Button
              onClick={() => handleUpdateComment(false)}
              variant="outline"
              isLoading={loading}
            >
              Accept
            </Button>
          )}
          {(needsReview || isAccepted) && (
            <Button
              onClick={() => handleUpdateComment(true)}
              variant="outline"
              isLoading={loading}
              // disabled={!comment.text?.length}
            >
              Reject
            </Button>
          )}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};
