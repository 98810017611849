import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useApolloClient } from "@apollo/client";
import { formatRelative, subDays } from "date-fns";
import { Helmet } from "react-helmet";
import { serverTimestamp } from "firebase/firestore";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router";

import {
  PathActiveUserFragment,
  usePathActiveUserMutation,
  usePathActiveUsersAggregateQuery,
  usePathViewerQuery,
} from "@tract/common/dist/graphql/index";
import { PathStatus } from "@tract/common/dist/types/models/Path";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Center,
  EmptyState,
  Flex,
  HStack,
  IconAlertTriangle,
  IconBack,
  IconButton,
  IconChevronUp,
  IconForward,
  IconList,
  IconX,
  Link,
  LinkBox,
  LinkOverlay,
  Spinner,
  Text,
  VStack,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
} from "Shared";

import { SideNav } from "Components/SideNav";
import { LayoutCentered } from "Components/LayoutCentered";
import { PathPlaylist } from "./PathPlaylist";
import { PathReviewActions } from "./PathReviewActions";
import { ContentNode } from "./ContentNode";

import { useSession } from "Services/session";
import { db } from "Services/firebase";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";
import { isKidUser } from "Types/User";

import { usePathNav } from "./usePathNav";
import { usePathRoute } from "./usePathRoute";
import { PATH_ACTIVE_USER_FRAGMENT } from "./graphql";
import { UserFile } from "Utils/UserFile";
import { SharePathModal } from "Components/SharePathModal";
import { ProjectGuideModal } from "./ProjectGuideModal";
import { SaveModal } from "Components/ClassroomSavedPaths";
import { CollectPathModal } from "Components/Collections";
import { AssignmentBuilderModal } from "Components/AssignmentBuilder/Modal";
import { pauseAllVideos } from "Utils/videos";

export const PathViewerPlaylistContext = createContext({
  showMobileNav: false,
  setShowMobileNav: { on: () => {}, off: () => {}, toggle: () => {} },
});

export const PathViewer: React.FC = () => {
  const { slug: slugOrPathId, pathId: pathIdParam } = useParams<{
    nodeId: string;
    pathId: string;
    slug: string;
  }>();
  const { cache } = useApolloClient();
  const previewPathId = Number(pathIdParam);
  const isReview = !!useRouteMatch("/path/review");
  const isPreview = !!useRouteMatch("/path/preview");
  const { path: routePath } = useRouteMatch();
  const { state, pathname } = useLocation<{ source?: string }>();
  const [updatePathActiveUser] = usePathActiveUserMutation();

  const {
    isOpen: collectModalIsOpen,
    onOpen: onCollectModalOpen,
    onClose: onCollectModalClose,
  } = useDisclosure();

  const [usingFirestoreId, setUsingFirestoreId] = useState(false);
  const isReviewOrPreview = isReview || isPreview;
  const isSlugPathId =
    typeof Number(slugOrPathId) === "number" && !isNaN(Number(slugOrPathId));

  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();
  const {
    isOpen: isShareModalOpen,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();
  const {
    isOpen: isProjectGuideOpen,
    onOpen: onProjectGuideOpen,
    onClose: onProjectGuideClose,
  } = useDisclosure();
  const [saveModelIsOpen, setSaveModalIsOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { currentUser } = useSession();

  const { track } = useAnalytics();

  /**
   * The query loads by id if the url is a preview. Otherwise it uses the slug.
   * In cases with legacy paths, the slug will be a firestore ID, so we try that
   * if the query on the slug doesn't return a path
   */
  const { data, error, loading } = usePathViewerQuery({
    skip: !slugOrPathId && !previewPathId,
    fetchPolicy: "network-only",
    variables: {
      userId: currentUser.uid,
      where: {
        _or: [
          { id: { _eq: Number(slugOrPathId) || previewPathId || -1 } },
          ...(isReviewOrPreview ? [] : [{ slug: { _eq: slugOrPathId } }]),
        ],
      },
    },
  });

  const path = data?.path?.[0];
  const url = window.location.href;
  const guide = !!path?.guides?.length ? path.guides[0]?.guide : undefined;

  const openProjectGuide = () => {
    track(ANALYTICS_EVENTS.PROJECT_GUIDE_VIEWED, {
      pathId: path?.id,
    });
    onProjectGuideOpen();
  };

  const [viewedPathId, setViewedPathId] = useState("");
  useEffect(() => {
    if (!path || viewedPathId === path.id) {
      return;
    }

    track(ANALYTICS_EVENTS.PATH_VIEWED, {
      pathId: path.id,
      pathSlug: path.slug,
      pathTitle: path.title,
      pathAuthorId: path.authorId,
      pathViewSource: state?.source,
      pathCoAuthorsCount: path.authors.length,
      pathAuthorUsername: path?.user?.username,
      pathAuthorCreatorLevel: path?.user?.creatorLevel,
      hasProjectGuide: !!path?.guides?.length,
    });

    setViewedPathId(path.id);
  }, [track, path, viewedPathId, state?.source]);

  const activeUserDate = useMemo(() => {
    return subDays(new Date(), 30).toISOString(); // Last 30 days
  }, []);

  const { data: activeUsersData } = usePathActiveUsersAggregateQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      pathId: path?.id,
      date: activeUserDate,
    },
    skip: !path,
  });

  const activeUsers =
    activeUsersData?.path_active_user_aggregate.aggregate?.count;

  useEffect(() => {
    if (!path) {
      return;
    }

    if (!isKidUser(currentUser)) {
      return;
    }

    const cacheId = cache.identify({
      __typename: "path_active_user",
      pathId: path.id,
      userId: currentUser.uid,
    });

    const cacheResult = cache.readFragment<PathActiveUserFragment>({
      id: cacheId,
      fragment: PATH_ACTIVE_USER_FRAGMENT,
    });

    let needsUpdate = !cacheResult;

    if (cacheResult?.updatedAt) {
      const isToday = formatRelative(
        new Date(cacheResult.updatedAt),
        new Date()
      ).startsWith("today");
      needsUpdate = !isToday;
    }

    if (needsUpdate) {
      updatePathActiveUser({
        variables: {
          pathId: path.id,
          userId: currentUser.uid,
        },
        update(cache, { data }) {
          if (data?.insert_path_active_user_one) {
            cache.writeFragment({
              id: cacheId,
              fragment: PATH_ACTIVE_USER_FRAGMENT,
              data: {
                ...data.insert_path_active_user_one,
              },
            });
          }
        },
      });
    }
  }, [path, cache, currentUser, updatePathActiveUser]);

  const updateHistory = useCallback(async () => {
    let historyRef = db.doc(`user-path-history/${currentUser.uid}:${path?.id}`);
    historyRef.set({
      pathId: path?.id,
      userId: currentUser.uid,
      lastViewed: serverTimestamp(),
    });
  }, [path?.id, currentUser.uid]);

  useEffect(() => {
    if (!!path?.id) {
      updateHistory();
    }
  }, [path?.id, updateHistory]);

  const { params, baseUrl } = usePathRoute(path);
  const [showMobileNav, setShowMobileNav] = useBoolean(false);
  const { nextURL, prevURL } = usePathNav({
    path,
  });

  const getNextButtonText = (): string => {
    return "Next";
  };

  // Convert path id to slug path
  if (path && isSlugPathId) {
    return (
      <Redirect
        to={`${pathname.replace(`path/${slugOrPathId}`, `path/${path.slug}`)}`}
      />
    );
  }

  const coverFile = new UserFile(path?.coverFile);
  const coverUrl = coverFile.getTransformedUrl("cover_thumb");

  if (isReviewOrPreview && !pathIdParam) {
    return <Redirect to="/" />;
  }

  if (error) {
    return (
      <Flex direction="column" w="full">
        <EmptyState headline="Error loading content" />;
      </Flex>
    );
  }

  if (loading) {
    return (
      <Flex direction="column" w="full">
        <Center py={20}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand"
            size="xl"
          />
        </Center>
      </Flex>
    );
  }

  if (!path) {
    if (!usingFirestoreId && !isReviewOrPreview) {
      setUsingFirestoreId(true);
    }

    return (
      <LayoutCentered color="gray.600" as={VStack}>
        <Text fontWeight="bold" fontSize="6xl">
          Ooops...
        </Text>
        <Text fontSize="lg">
          Sorry, we couldn't get the path you're looking for :(
        </Text>
      </LayoutCentered>
    );
  }

  const node = path.nodes.find((n) => n.id === params.nodeId);

  if (params.nodeId && !node) {
    return <Redirect to="/" />;
  }

  if (isReview) {
    if (path.status === PathStatus.Draft) {
      return <Redirect to={`/path/preview/${path.id}`} />;
    }

    if (path.status === PathStatus.Published) {
      return <Redirect to={`/path/${path.slug}`} />;
    }
  }

  return (
    <>
      <Helmet>
        {!!path?.title?.length && <title>{`${path.title} — Tract`}</title>}
        {!!path?.description?.length && (
          <meta name="description" content={path.description} />
        )}
        {!!coverUrl && <meta name="image" content={coverUrl} />}
      </Helmet>
      <Flex direction="column" w="full">
        {isPreview && (
          <Alert
            borderRadius="none"
            status="warning"
            position={{ lg: "sticky" }}
            top={{ lg: "4rem" }}
            zIndex={11}
            textAlign="center"
            justifyContent="center"
          >
            <IconAlertTriangle />
            <AlertTitle>You are in view-only mode</AlertTitle>
          </Alert>
        )}
        {isReview && <PathReviewActions path={path} />}
        <Flex direction="row">
          <Box
            w="full"
            display={showMobileNav ? "none" : "block"}
            pos="relative"
            pb={{ base: "4rem", lg: 0 }}
          >
            <Switch>
              <Redirect
                exact
                from={`${routePath}`}
                to={`${baseUrl}/v/${path.nodes[0]?.id}`}
              />
              <Redirect
                exact
                from={`${routePath}/trailer`}
                to={`${baseUrl}/v/${path.nodes[0]?.id}`}
              />
              <Redirect
                exact
                from={`${routePath}/v/:nodeId/challenge`}
                to={`${routePath}/v/:nodeId`}
              />
              <Route path={`${routePath}/v/:nodeId`}>
                {node && <ContentNode path={path} node={node} />}
              </Route>
            </Switch>
            <Flex
              align="center"
              justify="space-between"
              px={2}
              h={{ base: "4rem", lg: "5rem" }}
              bg="white"
              borderTop="1px solid"
              borderTopColor="gray.200"
              position={{ base: "inherit", lg: "fixed" }}
              bottom={0}
              left={0}
              right="30rem"
            >
              <Box as="span">
                {!!prevURL && (
                  <Button
                    variant="ghost"
                    size="lg"
                    leftIcon={<IconBack size={20} />}
                    as={Link}
                    to={prevURL}
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Box as="span">
                {!!nextURL && (
                  <Button
                    variant="solid"
                    colorScheme="brandFull"
                    size="lg"
                    rightIcon={<IconForward size={20} />}
                    as={Link}
                    to={nextURL}
                  >
                    {getNextButtonText()}
                  </Button>
                )}
              </Box>
            </Flex>
          </Box>
          <PathViewerPlaylistContext.Provider
            value={{ showMobileNav, setShowMobileNav }}
          >
            {isMobile ? (
              <Box
                display={showMobileNav ? "block" : "none"}
                minH="calc(100vh - 4rem)"
                w="full"
                py={4}
              >
                <PathPlaylist
                  path={path}
                  activeUsers={activeUsers}
                  onClickAssign={onAssignOpen}
                  onProjectGuideOpen={openProjectGuide}
                  onShareModalOpen={onShareModalOpen}
                  setSaveModalIsOpen={setSaveModalIsOpen}
                  onCollectModalOpen={onCollectModalOpen}
                  renderMobileCloseButton={() => (
                    <IconButton
                      aria-label="Close menu"
                      size="md"
                      variant="ghost"
                      icon={<IconX />}
                      onClick={() => setShowMobileNav.off()}
                      mr={-2}
                      ml={1}
                    />
                  )}
                />
              </Box>
            ) : (
              <SideNav
                navPosition="right"
                id="path-viewer-sidenav"
                py={6}
                w="30rem"
                minW="30rem"
                overflow="scroll"
                css={{ scrollBehavior: "smooth" }}
              >
                <PathPlaylist
                  path={path}
                  activeUsers={activeUsers}
                  onClickAssign={onAssignOpen}
                  onProjectGuideOpen={openProjectGuide}
                  onShareModalOpen={onShareModalOpen}
                  setSaveModalIsOpen={setSaveModalIsOpen}
                  onCollectModalOpen={onCollectModalOpen}
                />
              </SideNav>
            )}
          </PathViewerPlaylistContext.Provider>
        </Flex>
        {isMobile && !showMobileNav && (
          <LinkBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDir="row"
            position="fixed"
            bottom={0}
            w="100%"
            px={4}
            py={3}
            bg="gray.100"
            overflow="hidden"
            id="path-viewer-sidenav"
          >
            <HStack spacing={4} overflow="hidden">
              <IconList minW={6} />
              <LinkOverlay
                as={Button}
                onClick={() => {
                  pauseAllVideos();
                  setShowMobileNav.on();
                }}
                variant="unstyled"
                _focus={{ boxShadow: "none" }}
              >
                <Text fontSize="md" fontWeight="bold" noOfLines={1}>
                  {path.title}
                </Text>
              </LinkOverlay>
            </HStack>
            <IconChevronUp minW={6} ml={4} />
          </LinkBox>
        )}
      </Flex>
      {isAssignOpen && (
        <AssignmentBuilderModal
          pathId={path.id}
          isOpen={isAssignOpen}
          onClose={onAssignClose}
        />
      )}
      {isShareModalOpen && (
        <SharePathModal
          isOpen={isShareModalOpen}
          onClose={onShareModalClose}
          url={url}
        />
      )}
      {!!guide && (
        <ProjectGuideModal
          isOpen={isProjectGuideOpen}
          onClose={onProjectGuideClose}
          guide={guide}
          title={path.title || ""}
        />
      )}
      {saveModelIsOpen && (
        <SaveModal
          onClose={() => setSaveModalIsOpen(false)}
          path={path}
          currentUser={currentUser}
        />
      )}
      {collectModalIsOpen && (
        <CollectPathModal
          isOpen={collectModalIsOpen}
          path={path}
          currentUser={currentUser}
          onClose={onCollectModalClose}
        />
      )}
    </>
  );
};
