import { useEffect, useRef, useState } from "react";
import to from "@tract/common/dist/utils/async";
import {
  AddPathToPathFeedMutationVariables,
  GetPathFeedsByPathIdQuery,
  GetPathFeedsByPathIdQueryVariables,
  GetPathWithNodesQuery,
  RemovePathFromPathFeedMutationVariables,
  SavePathV4MutationVariables,
  useCreateFileMutation,
} from "@tract/common/dist/graphql";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  Flex,
  VStack,
  Box,
  ModalOverlay,
  ModalCloseButton,
  useToast,
  HStack,
  Spinner,
  IconCheck,
} from "Shared";
import { StepDetails } from "./StepDetails";
import { StepFeedback } from "./StepFeedback";
import { StepMetaData } from "./StepMetaData";
import { StepperItem } from "./StepperItem";
import { Formik, FormikProps } from "formik";
import { useMutation, useQuery } from "urql";
import { SAVE_PATH_V4_MUTATION } from "../graphql";
import {
  ADD_PATH_TO_PATH_FEED,
  GET_PATH_FEEDS_BY_PATH_ID,
  REMOVE_PATH_FROM_PATH_FEED,
} from "graphql/path-feeds";
import { captureException, captureMessage } from "Services/errors";
import { post } from "Services/api";
import { useSession } from "Services/session";
import { UserFileResult } from "Services/useUserFileUpload";
import { PathStatus } from "@tract/common/dist/types/models/Path";

export type TriggerType = "settings-cta" | "publish-cta";

export interface NodePublishFormProps {
  title: string;
  coverFileId: string;
  description?: string;
  interestArea: string;
  subjects: string[];
  skills: string[];
  gradeLevels: string[];
  tags: string[];
  newTags: string[];
  inTopPicks: boolean;
  isVisibleUnauthed: boolean;
  xpMultiplier: number;
  isTractOriginal: boolean;
}

export const SettingsModal: React.FC<{
  path: GetPathWithNodesQuery["path"];
  triggerType: TriggerType;
  isOpen: boolean;
  onClose: () => void;
  openToStep?: number;
}> = ({ path, triggerType, isOpen, onClose, openToStep = 1 }) => {
  const toast = useToast();
  const { firebaseUser, isAdmin } = useSession();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [coverResult, setCoverResult] = useState<UserFileResult | null>();
  const [currentStep, setCurrentStep] = useState(openToStep);
  const formRef = useRef<FormikProps<NodePublishFormProps>>(null);
  const canEdit = isAdmin || path?.status !== PathStatus.Published;

  useEffect(() => {
    setSaved(false);
  }, [isOpen]);
  const [, savePath] = useMutation<{}, SavePathV4MutationVariables>(
    SAVE_PATH_V4_MUTATION
  );
  const [, addPathToFeed] = useMutation<{}, AddPathToPathFeedMutationVariables>(
    ADD_PATH_TO_PATH_FEED
  );
  const [, removePathFromFeed] = useMutation<
    {},
    RemovePathFromPathFeedMutationVariables
  >(REMOVE_PATH_FROM_PATH_FEED);
  const [createFile] = useCreateFileMutation();
  const [{ data }] = useQuery<
    GetPathFeedsByPathIdQuery,
    GetPathFeedsByPathIdQueryVariables
  >({ query: GET_PATH_FEEDS_BY_PATH_ID, variables: { pathId: `${path?.id}` } });
  const inTopPicks = !!data?.path_feed.find((f) => f.slug === "top-picks");

  const onSubmitForm = async (values: NodePublishFormProps) => {
    setSaving(true);
    try {
      if (coverResult) {
        const [createCoverErr, res] = await to(
          createFile({
            variables: {
              file: {
                id: coverResult?.fileId,
                filename: coverResult?.filename,
                userId: path?.authorId,
              },
            },
          })
        );

        if (createCoverErr) {
          captureException(createCoverErr);
          toast({
            status: "error",
            title: "Error saving thumbnail. Please try again",
          });
          return;
        }

        if (!res?.data?.file?.id) {
          captureMessage("fileId not found while saving path thumbnail", {
            extra: {
              pathId: path?.id,
            },
          });
          toast({
            status: "error",
            title: "Error saving thumbnail. Please try again",
          });
          return;
        }

        const [coverTransloaditErr] = await to(
          post("/v2/transloadit", firebaseUser, {
            fileId: res.data.file.id,
            templateName: "path-covers",
          })
        );

        if (coverTransloaditErr) {
          captureException(coverTransloaditErr);
          toast({
            status: "error",
            title: "Error saving thumbnail. Please try again",
          });
          return;
        }
      }

      const mutationResult = await savePath({
        path: {
          title: values.title,
          description: values.description,
          coverFileId: values.coverFileId || null,
          interestArea: values.interestArea,
          tags: values.tags,
          subjects: values.subjects,
          gradeLevels: values.gradeLevels,
          skills: values.skills,
          xpMultiplier: values.xpMultiplier,
          isVisibleUnauthed: values.isVisibleUnauthed,
          isTractOriginal: isAdmin ? values.isTractOriginal : undefined,
        },
        pathId: path?.id,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      if (values.inTopPicks !== inTopPicks && path) {
        if (values.inTopPicks) {
          await addPathToFeed({
            pathId: `${path.id}`,
            slug: "top-picks",
            userId: path.authorId,
          });
        } else {
          await removePathFromFeed({ pathId: `${path.id}`, slug: "top-picks" });
        }
      }
      setSaved(true);
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error saving path. Please try again",
      });
      setSaved(false);
    } finally {
      setSaving(false);
    }
  };

  const closeModal = async () => {
    if (!!formRef.current?.values && formRef.current.dirty) {
      await onSubmitForm(formRef.current.values);
    }
    onClose();
  };

  const changeStep = async (step: number) => {
    setCurrentStep(step);
    if (!!formRef.current?.values && formRef.current.dirty) {
      await onSubmitForm(formRef.current.values);
    }
  };
  return (
    <Modal
      scrollBehavior="inside"
      size="4xl"
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={!saving}
      isCentered
    >
      <ModalOverlay />
      <Formik
        initialValues={{
          title: path?.title || "",
          description: path?.description || "",
          coverFileId: path?.coverFile?.id || "",
          interestArea: path?.interestArea || "",
          tags: path?.tags || [],
          subjects: path?.subjects || [],
          gradeLevels: path?.gradeLevels || [],
          skills: path?.skills || [],
          newTags: [],
          xpMultiplier: path?.xpMultiplier || 1,
          isVisibleUnauthed: path?.isVisibleUnauthed || false,
          inTopPicks,
          isTractOriginal: path?.isTractOriginal || false,
        }}
        onSubmit={onSubmitForm}
        innerRef={formRef}
        enableReinitialize
      >
        {({ dirty }) => (
          <ModalContent height="45rem" maxH="80%">
            <ModalCloseButton />
            <ModalHeader boxShadow="base" maxH="4rem">
              <Text fontSize="2xl" fontWeight="bold" lineHeight={1.4}>
                {triggerType === "publish-cta" ? "Publish" : "Settings"}
              </Text>
              {saving && (
                <HStack position="absolute" top={5} right="4rem">
                  <Spinner
                    emptyColor="gray.200"
                    color="gray.400"
                    size="sm"
                    speed="1s"
                  />
                  <Text fontSize="md" color="gray.600">
                    Saving...
                  </Text>
                </HStack>
              )}
              {!dirty && saved && !saving && (
                <HStack position="absolute" top={5} right="4rem">
                  <IconCheck color="green.600" size={20} />
                  <Text color="green.600" fontSize="md">
                    Saved
                  </Text>
                </HStack>
              )}
            </ModalHeader>
            <ModalBody p={0}>
              <Flex alignItems="start" height="100%">
                <VStack
                  w="16rem"
                  position="sticky"
                  top={0}
                  pt={3}
                  alignItems="start"
                  spacing={0}
                >
                  <StepperItem
                    step={1}
                    label="Details"
                    isActive={currentStep === 1}
                    isCompleted={
                      triggerType === "publish-cta" &&
                      currentStep > 1 &&
                      !!path?.title &&
                      !!path.coverFile
                    }
                    onClick={() => changeStep(1)}
                  />
                  <StepperItem
                    step={2}
                    label="Discovery"
                    isActive={currentStep === 2}
                    isCompleted={
                      triggerType === "publish-cta" && currentStep > 2
                    }
                    onClick={() => changeStep(2)}
                  />
                  <StepperItem
                    step={3}
                    label="Feedback &amp; Review"
                    isActive={currentStep === 3}
                    onClick={() => changeStep(3)}
                  />
                </VStack>
                <Box
                  borderLeft="1px solid"
                  borderColor="gray.200"
                  textAlign="start"
                  flex={2}
                  overflow="hidden"
                  height="100%"
                  position="relative"
                >
                  {currentStep === 1 ? (
                    <StepDetails
                      path={path}
                      triggerType={triggerType}
                      onSaveStart={() => setSaving(true)}
                      onSaveComplete={() => setSaving(false)}
                      onChangeStep={(step: number) => changeStep(step)}
                      coverResult={coverResult}
                      setCoverResult={setCoverResult}
                      disabled={!canEdit}
                    />
                  ) : currentStep === 2 ? (
                    <StepMetaData
                      path={path}
                      triggerType={triggerType}
                      onSaveStart={() => setSaving(true)}
                      onSaveComplete={() => setSaving(false)}
                      onChangeStep={(step: number) => changeStep(step)}
                      disabled={!canEdit}
                    />
                  ) : (
                    <StepFeedback
                      path={path}
                      onCloseModal={closeModal}
                      onChangeStep={(step: number) => changeStep(step)}
                    />
                  )}
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  );
};
