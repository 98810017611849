import * as yup from "yup";

export type DetailsFormValues = {
  assignmentId?: string;
  title: string;
  description?: string;
  pathId: number;
};

export type AssignClassFormValues = {
  startDate?: string;
  dueDate?: string;
  classIds: string[];
  newClassroom?: {
    name: string;
  };
};

export type FormValues = DetailsFormValues & AssignClassFormValues;

export type Step = {
  id: string;
  navName: string;
  schema: typeof detailsSchema | typeof assignClassSchema;
};

export const detailsSchema = yup.object({
  assignmentId: yup.string().uuid().optional(),
  title: yup.string().required("Title is required"),
  description: yup.string().optional(),
  pathId: yup.string().required(),
});

export const assignClassSchema = yup.object({
  startDate: yup.string().required("Start date is required"),
  dueDate: yup
    .string()
    .optional()
    .test(
      "Invalid due date",
      "Due date must be after start date",
      (dueDate, context) => {
        return dueDate ? context.parent.startDate <= dueDate : true;
      }
    ),
  classIds: yup
    .array()
    .of(yup.string())
    .when("newClassroom.name", {
      is: (val: string) => !val || !val.trim(),
      then: (schema) =>
        schema.min(1, "Must assign at least 1 class").required(),
    }),
  newClassroom: yup
    .object({
      name: yup.string().required("Classroom name is required"),
    })
    .default(undefined)
    .optional(),
});

export const steps: Step[] = [
  {
    id: "details",
    navName: "Select Details",
    schema: detailsSchema,
  },
  {
    id: "assign",
    navName: "Assign",
    schema: assignClassSchema,
  },
];

export const INPUT_DATE_FORMAT = "yyyy-MM-dd";
