import React, { useEffect, useState } from "react";
import { Gif } from "@giphy/react-components";
import { IGif } from "@giphy/js-types";

import { gf } from "Services/giphy";
import { captureException } from "Services/errors";

type Props = {
  giphyId: string;
  width?: number;
};

export const GiphyGif: React.FC<Props> = ({ giphyId, width = 100 }) => {
  const [gif, setGif] = useState<IGif>();

  useEffect(() => {
    async function getGiphy() {
      if (giphyId) {
        try {
          const { data } = await gf.gif(giphyId);
          setGif(data);
        } catch (error: any) {
          captureException(error);
        }
      }
    }

    getGiphy();
  }, [giphyId]);

  if (!gif) {
    return null;
  }

  return (
    <Gif gif={gif} width={width} hideAttribution noLink borderRadius={8} />
  );
};
