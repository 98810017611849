import { useCallback, useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { ModalProps } from "@chakra-ui/modal";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import {
  ButtonOutlined,
  ButtonSolid,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  ModalCloseButton,
  VStack,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Text,
  Checkbox,
  Center,
  useDisclosure,
  useToast,
} from "Shared";
import { IconGoogleClassroom } from "Shared/Icon/custom/IconGoogleClassroom";
import { useInterval } from "@tract/common/dist/hooks";
import { GOOGLE_ACCESS_SCOPES } from "@tract/common/dist/constants/google-access-scopes";
import { get, post, patch } from "Services/api";
import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { fetchGoogleClassrooms, useGoogleEmail } from "Services/classroom";
import { gql, useMutation } from "urql";
import {
  ClassroomLearnerGroupFragment,
  ImportClassroomsMutation,
  ImportClassroomsMutationVariables,
} from "@tract/common/dist/graphql";
import { CLASSROOM_LEARNER_GROUP_FRAGMENT } from "@tract/common/dist/graphql/fragments/classroom-learner-group";
import { useFeature } from "Services/features";
import { ANALYTICS_EVENTS, GroupKey, useAnalytics } from "Services/analytics";

export interface CreateClassModalForm {
  classroomName: string;
  numStudents: string;
}

interface CreateClassModalProps {
  learnerGroups?: ClassroomLearnerGroupFragment[];
  onSubmit: (values: CreateClassModalForm) => void;
  includeNumStudents: boolean;
}

const createClassFormSchema = yup.object().shape({
  classroomName: yup.string().required("Please enter a class name"),
  numStudents: yup
    .number()
    .integer("Please provide a valid number")
    .min(1, "Please enter a number between 1-100")
    .max(100, "Please enter a number between 1-100")
    .required("Please provide a valid number")
    .typeError("Please provide a valid number"),
});

const API_IMPORT_CLASSROOMS_MUTATION = gql`
  ${CLASSROOM_LEARNER_GROUP_FRAGMENT}

  mutation ImportClassrooms(
    $orgId: String!
    $accessToken: String!
    $classes: [classroom_input!]!
  ) {
    importClassrooms: api_import_classrooms(
      orgId: $orgId
      accessToken: $accessToken
      classes: $classes
    ) {
      learnerGroup {
        ...ClassroomLearnerGroup
      }
    }
  }
`;

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_ACCESS_TOKEN_KEY = "@tract/google/access_token";

export const CreateClassModal: React.FC<
  Omit<ModalProps, "children"> & CreateClassModalProps
> = (props) => {
  const toast = useToast();
  const analytics = useAnalytics();
  const isClassroomImportsEnabled = useFeature("classroom-imports");
  const googleAuthRef = useRef<gapi.auth2.GoogleAuth | null>(null);
  const [googleReady, setGoogleReady] = useState(!!window.gapi);
  const [error, setError] = useState<string | null>(null);
  const [courses, setCourses] = useState<any[] | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(() =>
    localStorage.getItem(GOOGLE_ACCESS_TOKEN_KEY)
  );
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [, importClassrooms] = useMutation<
    ImportClassroomsMutation,
    ImportClassroomsMutationVariables
  >(API_IMPORT_CLASSROOMS_MUTATION);
  const { currentUser, firebaseUser } = useSession();
  const {
    isOpen: isImportClassroomsModalOpen,
    onOpen: openImportClassroomsModal,
    onClose: closeImportClassroomsModal,
  } = useDisclosure();
  const googleEmail = useGoogleEmail(props.isOpen ? accessToken : null);

  useInterval(
    () => {
      if (window.gapi) {
        setGoogleReady(true);
      }
    },
    googleReady ? null : 10
  );

  const handleGoogleFailure = useCallback(
    (reason: { error: string; details: string }) => {
      if (reason.details.toLowerCase().includes("cookies")) {
        setError(
          "Enable cookies or use a different browser before signing in with Google"
        );
      } else {
        setError(
          "Something went wrong with Google :( Use email/username or contact support"
        );
      }
    },
    []
  );

  useEffect(() => {
    if (!googleReady) return;

    window.gapi.load("auth2", function () {
      if (googleAuthRef.current) return;

      googleAuthRef.current = window.gapi.auth2.init({
        client_id: GOOGLE_AUTH_CLIENT_ID,
        cookie_policy: "single_host_origin",
      });

      googleAuthRef.current.then(() => {}, handleGoogleFailure);
    });
  }, [googleReady, handleGoogleFailure]);

  const onClickImportGoogle = async () => {
    if (!googleAuthRef.current) return;

    analytics.track(ANALYTICS_EVENTS.CLASS_IMPORT_STARTED, {
      provider: "googleClassroom",
    });

    if (courses) {
      openImportClassroomsModal();
      props.onClose();
      return;
    }
    setCourses(null);
    const userIntegration = currentUser.integrations.find(
      (ui) => ui.provider === "google"
    );

    setLoadingCourses(true);
    if (accessToken) {
      try {
        const courses = await fetchGoogleClassrooms(accessToken);
        setCourses(courses);
        setLoadingCourses(false);
        openImportClassroomsModal();
        props.onClose();
        return;
      } catch (error: any) {
        // API errored due to expired token, skip and get a new one
      }
    }

    let _accessToken: string | undefined;

    try {
      const refreshTokenResponse = await get(
        "/v2/integrations/google/refresh",
        firebaseUser
      );
      _accessToken = refreshTokenResponse.data?.access_token;
    } catch (error: any) {
      captureException(error);
    } finally {
      setLoadingCourses(false);
    }

    if (!_accessToken) {
      googleAuthRef.current
        .grantOfflineAccess({
          scope: GOOGLE_ACCESS_SCOPES.join(" "),
        })
        .then(async (response) => {
          setLoadingCourses(true);
          try {
            // If there's no integration, link the user first
            if (!userIntegration) {
              const params = new URLSearchParams();
              params.append("code", response.code);
              params.append("linkOnly", "1");
              const result = await post(
                "/v2/integrations/google",
                firebaseUser,
                params
              );

              if (result.status !== 200) {
                throw new Error("Something went wrong");
              }
              _accessToken = result.data.access_token;
            } else {
              // Update refresh token otherwise
              const updateResponse = await patch(
                "/v2/integrations/google/refresh",
                firebaseUser,
                {
                  code: response.code,
                }
              );

              _accessToken = updateResponse.data.access_token;
            }

            if (!_accessToken) {
              throw new Error("No access token");
            }

            setAccessToken(_accessToken);
            localStorage.setItem(GOOGLE_ACCESS_TOKEN_KEY, _accessToken);
            const courses = await fetchGoogleClassrooms(_accessToken);

            setCourses(courses);
            openImportClassroomsModal();
            props.onClose();
          } catch (error: any) {
            captureException(error);
            if (error.response?.data?.message) {
              toast({ status: "error", title: error.response?.data?.message });
            } else {
              toast({ status: "error", title: "Something went wrong" });
            }
          } finally {
            setLoadingCourses(false);
          }
        });
    } else {
      setLoadingCourses(true);
      setAccessToken(_accessToken);
      localStorage.setItem(GOOGLE_ACCESS_TOKEN_KEY, _accessToken);
      const courses = await fetchGoogleClassrooms(_accessToken);
      setCourses(courses);
      setLoadingCourses(false);
      openImportClassroomsModal();
      props.onClose();
    }
  };

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      classroomName: "",
      numStudents: "30",
    },
    validateOnBlur: false,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: createClassFormSchema,
    onSubmit: (values) => props.onSubmit(values),
  });

  if (isImportClassroomsModalOpen) {
    return (
      <ImportClassroomsModal
        learnerGroups={props.learnerGroups}
        classrooms={courses}
        isOpen={isImportClassroomsModalOpen}
        onClose={closeImportClassroomsModal}
        handleImport={async (classes) => {
          if (!currentUser.orgId || !accessToken) return;

          analytics.track(ANALYTICS_EVENTS.CLASS_IMPORT_CLASSES_SELECTED, {
            provider: "googleClassroom",
            classCount: classes.length,
          });

          const result = await importClassrooms({
            orgId: currentUser.orgId,
            accessToken,
            classes,
          });

          result.data?.importClassrooms?.forEach((lg) => {
            const classId = lg?.learnerGroup?.id;
            analytics.track(ANALYTICS_EVENTS.CLASS_CREATED, {
              learnerGroupId: classId,
              learnerGroupOwnerId: currentUser.id,
              classId,
              classTitle: lg?.learnerGroup?.name,
              numOfStudents: lg?.learnerGroup?.educatorCodes[0].available,
              provider: `${lg?.learnerGroup?.source}Classroom`,
            });

            analytics.group(GroupKey.LearnerGroup, classId, {
              name: lg?.learnerGroup?.name,
              ownerUserId: currentUser.id,
              orgId: currentUser.orgId,
              provider: `${lg?.learnerGroup?.source}Classroom`,
            });
          });
          closeImportClassroomsModal();
        }}
      />
    );
  }

  return (
    <>
      <Helmet>
        <script src="https://apis.google.com/js/api:client.js" async />
      </Helmet>
      <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Class</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={VStack} spacing={6} mb={8} alignItems="flex-start">
            <FormControl isInvalid={!!formik.errors.classroomName} isRequired>
              <FormLabel>Class title</FormLabel>
              <Input
                type="text"
                name="classroomName"
                size="lg"
                placeholder="Class title"
                value={formik.values.classroomName}
                onChange={(e) => {
                  formik.setErrors({ ...formik.errors, classroomName: "" });
                  formik.handleChange(e);
                }}
                autoFocus
                autoComplete="off"
              />
              <FormErrorMessage>{formik.errors.classroomName}</FormErrorMessage>
            </FormControl>
            {props.includeNumStudents && (
              <FormControl isInvalid={!!formik.errors.numStudents} isRequired>
                <FormLabel>Number of students</FormLabel>
                <Input
                  type="text"
                  name="numStudents"
                  size="lg"
                  placeholder="Number of students"
                  value={formik.values.numStudents}
                  onChange={(e) => {
                    formik.setErrors({ ...formik.errors, numStudents: "" });
                    formik.handleChange(e);
                  }}
                  autoComplete="off"
                />
                <FormErrorMessage>{formik.errors.numStudents}</FormErrorMessage>
              </FormControl>
            )}
            <ButtonSolid
              w="full"
              size="lg"
              isLoading={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Create Class
            </ButtonSolid>
            {isClassroomImportsEnabled && (
              <>
                <Text
                  w="full"
                  textAlign="center"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="sm"
                  color="gray.600"
                >
                  Or import from
                </Text>
                <ButtonOutlined
                  w="full"
                  size="lg"
                  isDisabled={!googleReady || !!error}
                  isLoading={loadingCourses}
                  onClick={onClickImportGoogle}
                  leftIcon={<IconGoogleClassroom />}
                >
                  Google Classroom
                </ButtonOutlined>
                {googleEmail && (
                  <Text w="full" textAlign="center" color="gray.600">
                    You are logged in as{" "}
                    <Text as="span" fontWeight="bold">
                      {googleEmail}
                    </Text>
                  </Text>
                )}
              </>
            )}
            {error && (
              <Text fontWeight="bold" color="red.600">
                {error}
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface SelectedClassroom {
  name: string;
  id: string;
}

interface ImportClassroomsModalProps {
  learnerGroups?: ClassroomLearnerGroupFragment[];
  classrooms?: any[] | null;
  handleImport: (selectedClassrooms: SelectedClassroom[]) => void;
}

const ImportClassroomsModal: React.FC<
  Omit<ModalProps, "children"> & ImportClassroomsModalProps
> = ({ learnerGroups, classrooms, handleImport, ...props }) => {
  const { values, isSubmitting, setFieldValue, handleSubmit } = useFormik<{
    classes: { [id: string]: { selected: boolean; name: string; id: string } };
  }>({
    initialValues: { classes: {} },
    async onSubmit(values) {
      const classes = Object.values(values.classes);
      await handleImport(
        classes
          .filter((c) => c.selected)
          .map((c) => ({ id: c.id, name: c.name }))
      );
    },
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Classes</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} spacing={6} mb={8} alignItems="flex-start">
          {classrooms?.length ? (
            <FormControl isRequired>
              <FormLabel>Select classes</FormLabel>
              {classrooms.map((course) => (
                <Checkbox
                  key={course.id}
                  w="full"
                  mb={1}
                  disabled={
                    !!learnerGroups?.find((lg) => lg.sourceId === course.id)
                  }
                  name={`classes.${course.id}`}
                  onChange={(e) =>
                    setFieldValue(`classes.${course.id}`, {
                      id: course.id,
                      selected: e.target.checked,
                      name: course.name,
                    })
                  }
                >
                  <Text color="gray.600" fontWeight="normal">
                    {course.name}
                  </Text>
                </Checkbox>
              ))}
            </FormControl>
          ) : (
            <Center>No classes found</Center>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <ButtonOutlined size="lg" onClick={props.onClose}>
              Cancel
            </ButtonOutlined>
            <ButtonSolid
              size="lg"
              isLoading={isSubmitting}
              isDisabled={
                isSubmitting ||
                !Object.values(values.classes).filter((c) => c.selected).length
              }
              onClick={() => handleSubmit()}
            >
              Import Classes
            </ButtonSolid>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
