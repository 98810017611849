import { mode } from "@chakra-ui/theme-tools";

function baseStyleList(props: Record<string, any>) {
  return {
    bg: mode("white", "gray.900")(props),
    boxShadow: mode("lg", "dark-lg")(props),
    borderColor: mode("gray.300", "gray.700")(props),
    borderRadius: "xl",
    color: mode("gray.900", "white")(props),
  };
}

function baseStyleItem(props: Record<string, any>) {
  return {
    px: 4,
    py: 3,
    _hover: { textDecoration: "none" },
    _focus: { boxShadow: "none" },
  };
}

function baseStyle(props: Record<string, any>) {
  return {
    list: baseStyleList(props),
    item: baseStyleItem(props),
  };
}

export const Menu = {
  baseStyle,
};
