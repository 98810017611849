import { gql } from "urql";
import { UpdateResolver } from "@urql/exchange-graphcache";

import { DisableUserMutationVariables } from "@tract/common/dist/graphql";

export const api_disable_user: UpdateResolver = (_, __, cache, info) => {
  const variables = info.variables as DisableUserMutationVariables;

  cache.writeFragment(
    gql`
      fragment _ on user {
        id: firestoreId
        disabled
      }
    `,
    {
      __typename: "user",
      id: variables.userId,
      disabled: variables.disabled,
    }
  );
};
