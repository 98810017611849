import { gql } from "urql";

import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  AdminCreateKidInviteMutationResult,
  AdminCreateKidInviteMutationVariables,
  AdminDashboardClassroomsByTeacherIdQueryResult,
  AdminDashboardClassroomsByTeacherIdQueryVariables,
  AdminDashboardCreateEducatorCodeMutationResult,
  AdminDashboardCreateEducatorCodeMutationVariables,
  GetUserInvitesQueryResult,
  GetUserInvitesQueryVariables,
} from "@tract/common/dist/graphql";

import { ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY } from "Pages/AdminDashboard/AdminUsers/TeacherClassrooms/graphql";
import { GET_KID_INVITES } from "Pages/KidInvites/graphql";

export const insert_educator_code_one: UpdateResolver = (
  result,
  args,
  cache,
  info
) => {
  if (result.adminCreateClassroomCode) {
    const variables =
      info.variables as AdminDashboardCreateEducatorCodeMutationVariables;

    const item = (
      result as AdminDashboardCreateEducatorCodeMutationResult["data"]
    )?.adminCreateClassroomCode;

    const learnerGroup = cache.readFragment(
      gql`
        fragment _ on learner_group {
          id
          members {
            userId
            isOwner
          }
        }
      `,
      {
        id: variables.learnerGroupId,
      }
    );

    const learnerGroupOwner = learnerGroup.members.find((m: any) => m.isOwner);

    cache.updateQuery<
      AdminDashboardClassroomsByTeacherIdQueryResult["data"],
      AdminDashboardClassroomsByTeacherIdQueryVariables
    >(
      {
        query: ADMIN_CLASSROOMS_BY_TEACHER_ID_QUERY,
        variables: {
          teacherUserId: learnerGroupOwner.userId,
        },
      },
      (data) => {
        if (data?.learnerGroups && item) {
          const i = data.learnerGroups.findIndex(
            (lg) => lg.id === variables.learnerGroupId
          );

          data.learnerGroups[i].educatorCodes.unshift({
            ...item,
          });
        }
        return data;
      }
    );
  }

  if (result.adminCreateKidInvite) {
    const variables = info.variables as AdminCreateKidInviteMutationVariables;

    const item = (result as AdminCreateKidInviteMutationResult["data"])
      ?.adminCreateKidInvite;

    if (variables.code.userId) {
      cache.updateQuery<
        GetUserInvitesQueryResult["data"],
        GetUserInvitesQueryVariables
      >(
        {
          query: GET_KID_INVITES,
          variables: {
            userId: variables.code.userId,
          },
        },
        (data) => {
          if (item) {
            return {
              educator_code: [item, ...(data?.educator_code || [])],
            };
          }
          return data;
        }
      );
    }
  }
};
