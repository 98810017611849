import { gql } from "urql";
import {
  PATH_ACTIVITY_FRAGMENT,
  PATH_REVIEW_FRAGMENT,
} from "Pages/AuthorPathReviews/graphql";
import { PATH_CARD_FRAGMENT } from "@tract/common/dist/graphql/fragments/path-card";

export const pathCoAuthorFragment = gql`
  fragment PathCoAuthor on path_author {
    pathId
    userId
    user {
      id: firestoreId
      username
      firstName
      lastName
      isEducator
      avatar
    }
  }
`;

export const PATH_BUILDER_NODE_FRAGMENT = gql`
  fragment PathBuilderNode on path_node {
    id
    pathId
    title
    caption
    createdAt
    lexorank
    responsesEnabled
    rewardAmount
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
    videoFile {
      id
      filename
      transforms {
        filename
      }
      integrations {
        foreignId
        source
      }
    }
  }
`;

export const PATH_BUILDER_PATH_FRAGMENT = gql`
  ${pathCoAuthorFragment}
  ${PATH_BUILDER_NODE_FRAGMENT}

  fragment PathBuilderPathV4 on path {
    id
    title
    description
    interestArea
    status
    isPublished
    media
    orgId
    authorId
    slug
    publishedAt
    totalXP
    isFeatured
    xpMultiplier
    subjects
    supplies
    tags
    skills
    gradeLevels
    isVisibleUnauthed
    isTractOriginal
    coverFile {
      id
      filename
      transforms {
        filename
      }
    }
    coverAnimatedFile {
      id
      filename
      transforms {
        filename
      }
    }
    user {
      id: firestoreId
      creatorLevel
      username
      firstName
      lastName
      isEducator
      avatar
      userType
      pathPrivacy
      teacher {
        id: userId
        displayName
      }
    }
    authors {
      ...PathCoAuthor
    }
    nodes(order_by: { lexorank: asc }) {
      ...PathBuilderNode
    }
    guides(limit: 1, where: { isRelatedPath: { _eq: false } }) {
      guide {
        id
        description
        bigIdea
        concepts
        contentStandards
        durationLower
        durationUpper
        learningObjectives
        resources
        downloadUrl
        discussionQuestions
      }
    }
  }
`;

export const GET_PATH_WITH_NODES_QUERY = gql`
  ${PATH_BUILDER_PATH_FRAGMENT}
  query GetPathWithNodes($pathId: bigint!) {
    path: path_by_pk(id: $pathId) {
      ...PathBuilderPathV4
    }
  }
`;

export const SAVE_PATH_V4_MUTATION = gql`
  ${PATH_BUILDER_PATH_FRAGMENT}

  mutation SavePathV4($pathId: bigint!, $path: path_set_input) {
    path: update_path_by_pk(pk_columns: { id: $pathId }, _set: $path) {
      ...PathBuilderPathV4
    }
  }
`;

export const SAVE_PATH_TITLE_MUTATION = gql`
  mutation SavePathTitle($pathId: bigint!, $pathTitle: String!) {
    path: update_path_by_pk(
      pk_columns: { id: $pathId }
      _set: { title: $pathTitle }
    ) {
      id
      title
    }
  }
`;

export const GET_LATEST_PATH_REVIEW = gql`
  ${PATH_REVIEW_FRAGMENT}

  query GetLatestPathReviewForPath(
    $pathId: bigint!
    $type: String_comparison_exp
  ) {
    pathReview: path_review(
      limit: 1
      order_by: { createdAt: desc_nulls_last }
      where: { pathId: { _eq: $pathId }, type: $type }
    ) {
      ...PathReview
    }
  }
`;

export const GET_PATH_STATUS = gql`
  query GetPathStatus($pathId: bigint!) {
    path: path_by_pk(id: $pathId) {
      id
      status
    }
  }
`;

export const SAVE_PATH_STATUS_MUTATION = gql`
  ${PATH_ACTIVITY_FRAGMENT}

  mutation SavePathStatus(
    $pathId: bigint!
    $status: String!
    $isPublished: Boolean
    $slug: String
    $publishedAt: timestamptz
    $pathActivity: path_activity_insert_input!
    $authorId: String!
    $authors: [path_author_insert_input!]!
  ) {
    path: update_path_by_pk(
      pk_columns: { id: $pathId }
      _set: {
        status: $status
        isPublished: $isPublished
        slug: $slug
        publishedAt: $publishedAt
        authorId: $authorId
      }
    ) {
      id
      status
      isPublished
      slug
      publishedAt
    }

    insert_path_author(
      objects: $authors
      on_conflict: {
        update_columns: []
        constraint: path_author_path_id_user_id_key
      }
    ) {
      returning {
        pathId
        userId
      }
    }

    insert_path_activity_one(object: $pathActivity) {
      ...PathActivity
    }
  }
`;

export const GET_PATH_BUILDER_NODE_QUERY = gql`
  ${PATH_BUILDER_NODE_FRAGMENT}
  query GetPathBuilderNode($nodeId: uuid!) {
    node: path_node_by_pk(id: $nodeId) {
      ...PathBuilderNode
    }
  }
`;

export const UPDATE_PATH_NODE_MUTATION = gql`
  ${PATH_BUILDER_NODE_FRAGMENT}
  mutation UpdatePathNode($nodeId: uuid!, $node: path_node_set_input!) {
    update_path_node_by_pk(pk_columns: { id: $nodeId }, _set: $node) {
      ...PathBuilderNode
    }
  }
`;

export const DELETE_PATH_NODE_MUTATION = gql`
  mutation DeletePathNode($nodeId: uuid!) {
    delete_path_node_by_pk(id: $nodeId) {
      id
      pathId
    }
  }
`;

export const ADD_PATH_CO_AUTHOR_MUTATION = gql`
  ${pathCoAuthorFragment}
  mutation AddPathV4CoAuthor($pathId: bigint!, $userId: String!) {
    insert_path_author_one(object: { pathId: $pathId, userId: $userId }) {
      ...PathCoAuthor
    }
    update_path_by_pk(
      pk_columns: { id: $pathId }
      _set: { isVisibleUnauthed: false }
    ) {
      id
      isVisibleUnauthed
    }
  }
`;

export const REMOVE_PATH_CO_AUTHOR_MUTATION = gql`
  mutation RemovePathV4CoAuthor($pathId: bigint!, $userId: String!) {
    delete_path_author(
      where: { pathId: { _eq: $pathId }, userId: { _eq: $userId } }
    ) {
      returning {
        pathId
        userId
      }
    }
  }
`;

export const ADD_NODE_VIDEO_FILE = gql`
  mutation AddNodeVideoFile(
    $fileId: uuid!
    $nodeId: uuid!
    $file: user_file_insert_input!
  ) {
    file: insert_user_file_one(object: $file) {
      id
    }

    update_path_node_by_pk(
      pk_columns: { id: $nodeId }
      _set: { videoFileId: $fileId }
    ) {
      id
      videoFile {
        integrations(where: { source: { _eq: "api.video" } }) {
          foreignId
        }
      }
    }
  }
`;

export const DELETE_NODE_VIDEO_FILE = gql`
  mutation DeleteNodeVideoFile($nodeId: uuid!, $nodeVideoId: String!) {
    delete_user_file_integration(where: { foreignId: { _eq: $nodeVideoId } }) {
      returning {
        fileId
      }
    }

    update_path_node_by_pk(
      pk_columns: { id: $nodeId }
      _set: { videoFileId: null }
    ) {
      id
      videoFile {
        integrations(where: { source: { _eq: "api.video" } }) {
          foreignId
        }
      }
    }
  }
`;

export const GET_PATH_ACTIVITY_FOR_PATH = gql`
  ${PATH_ACTIVITY_FRAGMENT}

  query GetPathActivityForPath($pathId: bigint!) {
    pathActivity: path_activity(
      order_by: { createdAt: desc_nulls_last }
      where: { pathId: { _eq: $pathId } }
    ) {
      ...PathActivity
      createdAt
    }
  }
`;

gql`
  ${PATH_CARD_FRAGMENT}

  query GetPathCardById($pathId: bigint!) {
    path: path_by_pk(id: $pathId) {
      ...PathCard
    }
  }
`;
