import { useRef, useMemo } from "react";

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  VStack,
} from "Shared";
import { Confetti } from "Components/Confetti";
import { GiphyGif } from "Components/GiphyGif";
import { sample } from "lodash";

const gifIds = [
  "3ohc0VvHtTYzYkxoha",
  "5PSPV1ucLX31u",
  "8hSPD2Yn4PuUM",
  "3ohc1begniznf7LLwI",
];

export const ReviewRequestCelebrateModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const randomGif = useMemo(() => sample(gifIds) || gifIds[0], []);

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      isCentered
    >
      <ModalOverlay />
      <Confetti zIndex={1400} />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={16} textAlign="center">
          <VStack spacing={6}>
            <GiphyGif width={400} giphyId={randomGif} />
            <Box>
              <Text fontSize="3xl" fontWeight="bold" color="gray.900" mb={2}>
                Nice work!
              </Text>
              <Text fontSize="lg">
                A Tract Creator will provide feedback and publish your path in
                the next 48 hours.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
