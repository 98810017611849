import { createIcon } from "@chakra-ui/react";

export const IconMarkRead = createIcon({
  displayName: "IconMarkRead",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    stroke: "currentcolor",
    strokeWidth: "2",
    fill: "none",
  },
  path: [
    <path key={0} d="M21 9L14 19L10 16" />,
    <path key={1} d="M2.75 6.1875H17" />,
    <path key={2} d="M2.75 11H13.5" />,
    <path key={3} d="M2.75 15.8125H5.5" />,
  ],
});
