import algoliasearch from "algoliasearch";
import { range } from "lodash";

export const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_FRONTEND_SEARCH_KEY
);

export const index = {
  paths: algoliaClient.initIndex("paths"),
  submissions: algoliaClient.initIndex("submissions"),
  comments: algoliaClient.initIndex("comments"),
  reports: algoliaClient.initIndex("abuse-reports"),
  commentSections: algoliaClient.initIndex("comment-sections"),
  users: algoliaClient.initIndex("users"),
};

const mapSubjects = (subjects?: string[]) => {
  let mappedSubjects: string[] = [];
  subjects?.forEach((subject) => {
    switch (subject) {
      case "CEDS.01": //Language arts
        mappedSubjects.push("Reading/Language Arts");
        break;
      case "CEDS.02": //Mathematics
        mappedSubjects.push("Mathematics");
        break;
      case "CEDS.03": //Life and physical sciences
        mappedSubjects.push("Science");
        break;
      case "CEDS.04": //Social sciences and history
        mappedSubjects.push("History (Social Science)");
        break;
      case "CEDS.05": //Visual and performing arts
        mappedSubjects.push("Visual & Performing Arts");
        break;
      case "CEDS.08": //Physical, health, and safety education
        mappedSubjects.push("Health");
        mappedSubjects.push("Physical Education");
        break;
      case "CEDS.11": //Communication and audio/visual technology
      case "CEDS.21": //Engineering and technology
      case "CEDS.22": //Miscellaneous
        break;
      case "CEDS.24": //World languages
        mappedSubjects.push("World Language");
        break;
      default:
        break;
    }
  });
  return mappedSubjects;
};

const mapGradeLevels = (lowerGradeLevel?: string, upperGradeLevel?: string) => {
  let mappedGradeLevels: string[] = [];
  const nonFilteringValues = ["UG", "Other"];
  if (
    !lowerGradeLevel?.length ||
    !upperGradeLevel?.length ||
    nonFilteringValues.includes(lowerGradeLevel) ||
    nonFilteringValues.includes(upperGradeLevel)
  ) {
    return mappedGradeLevels;
  }
  let lowerGradeLevelNumber =
    lowerGradeLevel === "PR" || lowerGradeLevel === "KG" ? 1 : +lowerGradeLevel;
  let upperGradeLevelNumber =
    upperGradeLevel === "PR" || upperGradeLevel === "KG" ? 1 : +upperGradeLevel;
  const gradeRange = range(lowerGradeLevelNumber, upperGradeLevelNumber + 1, 1);

  if (gradeRange.some((grade) => [1, 2].includes(grade))) {
    mappedGradeLevels.push("K-2");
  }
  if (gradeRange.some((grade) => [3, 4, 5].includes(grade))) {
    mappedGradeLevels.push("3-5");
  }
  if (gradeRange.some((grade) => [6, 7, 8].includes(grade))) {
    mappedGradeLevels.push("6-8");
  }
  if (gradeRange.some((grade) => [9, 10, 11, 12].includes(grade))) {
    mappedGradeLevels.push("9-12");
  }

  return mappedGradeLevels;
};

export const generateMappedUrlQueryParams = (
  subjects?: string[],
  lowerGradeLevel?: string,
  upperGradeLevel?: string
) => {
  const mappedSubjects = mapSubjects(subjects);
  const mappedGradeLevels = mapGradeLevels(lowerGradeLevel, upperGradeLevel);
  return filtersToUrl(mappedSubjects, mappedGradeLevels);
};

export const filtersToUrl = (subjects: string[], gradeLevels: string[]) => {
  if (!subjects?.length && !gradeLevels?.length) return "";
  const params = new URLSearchParams();
  if (!!subjects?.length) {
    params.append(`subjects`, subjects.join(","));
  }
  if (!!gradeLevels?.length) {
    params.append(`gradeLevels`, gradeLevels.join(","));
  }
  return params.toString();
};
