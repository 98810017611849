import React from "react";
import { v4 } from "uuid";
import { useToast } from "Shared";
import { useMutation } from "urql";

import {
  NodeCommentFragment,
  CreateNodeCommentMutation,
  CreateNodeCommentMutationVariables,
  EditNodeCommentMutation,
  EditNodeCommentMutationVariables,
  DeleteNodeCommentMutation,
  DeleteNodeCommentMutationVariables,
} from "@tract/common/dist/graphql";

import { CommentForm, Values } from "./CommentForm";
import { CommentList } from "./CommentList";
import { Values as CommentEditFormValues } from "./CommentEditForm";

import { useSession } from "Services/session";
import { captureException } from "Services/errors";
import { ANALYTICS_EVENTS, useAnalytics } from "Services/analytics";

import {
  CREATE_NODE_COMMENT_MUTATION,
  DELETE_NODE_COMMENT_MUTATION,
  EDIT_NODE_COMMENT_MUTATION,
} from "./graphql";

type Props = {
  nodeId: string;
  pathId: string;
  pathAuthorId: string;
  pathTitle: string;
  nodeTitle: string;
  commentCount: number;
};

export const NodeComments: React.FC<Props> = ({
  nodeId,
  pathId,
  pathAuthorId,
  pathTitle,
  nodeTitle,
  commentCount,
}) => {
  const toast = useToast();
  const { currentUser } = useSession();
  const { track } = useAnalytics();

  const [, createComment] = useMutation<
    CreateNodeCommentMutation,
    CreateNodeCommentMutationVariables
  >(CREATE_NODE_COMMENT_MUTATION);

  const [, editComment] = useMutation<
    EditNodeCommentMutation,
    EditNodeCommentMutationVariables
  >(EDIT_NODE_COMMENT_MUTATION);

  const [, deleteComment] = useMutation<
    DeleteNodeCommentMutation,
    DeleteNodeCommentMutationVariables
  >(DELETE_NODE_COMMENT_MUTATION);

  const handleCreateComment = async (values: Values) => {
    const commentId = v4();
    const ltreeId = commentId.replace(/-/g, "_");
    const isReply = !!values.parentPath;
    let totalCount = 1;
    try {
      const mutationResult = await createComment({
        object: {
          pathNodeId: nodeId,
          comment: {
            data: {
              id: commentId,
              text: values.text,
              userId: currentUser.uid,
              path: values.parentPath
                ? values.parentPath + "." + ltreeId
                : ltreeId,
            },
          },
        },
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      track(ANALYTICS_EVENTS.VIDEO_COMMENT_ADDED, {
        nodeId,
        nodeTitle: nodeTitle,
        videoCommentCount: totalCount,
        pathId: pathId,
        pathAuthorId: pathAuthorId,
        pathTitle: pathTitle,
        isCommentReply: isReply,
      });
    } catch (err: any) {
      captureException(err);
      toast({
        status: "error",
        title: "Error adding comment",
      });
    }
  };

  const handleCommentError = (err: any) => {
    captureException(err);
    toast({
      status: "error",
      title: "Error submitting comment",
    });
  };

  const handleEditComment = async (
    values: CommentEditFormValues,
    nodeComment: NodeCommentFragment
  ) => {
    const { comment } = nodeComment;
    const depth = comment?.path?.split(".")?.length;
    const isReply = depth > 1;

    try {
      const mutationResult = await editComment({
        commentId: nodeComment.commentId,
        text: values.text,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }

      track(ANALYTICS_EVENTS.VIDEO_COMMENT_EDITED, {
        nodeId: nodeComment.pathNodeId,
        nodeTitle: nodeTitle,
        isCommentOwner: nodeComment?.comment?.user?.id === currentUser.uid,
        videoCommentCount: commentCount,
        pathId: pathId,
        pathAuthorId: pathAuthorId,
        pathTitle: pathTitle,
        isCommentReply: isReply,
      });
    } catch (err: any) {
      captureException(err);
      toast({
        status: "error",
        title: "Error editing comment",
      });
    }
  };

  const handleDeleteComment = async (nodeComment: NodeCommentFragment) => {
    let totalCount = 1;
    const { comment } = nodeComment;
    const depth = comment?.path?.split(".")?.length;
    const isReply = depth > 1;
    try {
      const mutationResult = await deleteComment({
        commentId: nodeComment.commentId,
      });
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
    } catch (err: any) {
      captureException(err);
      toast({
        status: "error",
        title: "Error deleting comment",
      });
    }

    track(ANALYTICS_EVENTS.VIDEO_COMMENT_DELETED, {
      nodeId: nodeComment.pathNodeId,
      nodeTitle: nodeTitle,
      videoCommentCount: totalCount,
      isCommentOwner: nodeComment?.comment?.user?.id === currentUser.uid,
      pathId: pathId,
      pathAuthorId: pathAuthorId,
      pathTitle: pathTitle,
      isCommentReply: isReply,
    });
  };

  return (
    <>
      <CommentForm
        submitHandler={handleCreateComment}
        errorHandler={handleCommentError}
      />
      <CommentList
        nodeId={nodeId}
        handleCreateComment={handleCreateComment}
        handleCommentError={handleCommentError}
        handleEditComment={handleEditComment}
        handleDeleteComment={handleDeleteComment}
      />
    </>
  );
};
