import React, { useMemo } from "react";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { gql } from "@apollo/client";
import groupBy from "lodash.groupby";

import { LayoutCentered } from "Components/LayoutCentered";
import { SectionUserAwards } from "./SectionUserAwards";
import { UserAwardCard } from "./UserAwardCard";

import { Award } from "Types/Awards";
import { useAchievements } from "Services/hooks/useAchievements";
import { UserAchievementCard } from "./UserAchievementCard";
import { EmptyState, EmptyStateFontAwesomeIcon } from "Shared";
import {
  useGetUserAwardsQuery,
  UserProfileUserFragment,
} from "@tract/common/dist/graphql";
import { useSession } from "Services/session";

gql`
  query GetUserAwards($userId: String!) {
    user_awards: user_award(where: { userId: { _eq: $userId } }) {
      award {
        id
        name
        description
        filePath
        type
      }
    }
  }
`;

type AwardsProps = {
  user: UserProfileUserFragment;
};

export type UserAward = Award & {
  count: number;
};

export const UserAwards: React.FC<AwardsProps> = ({ user }) => {
  const { currentUser } = useSession();
  const isOwnProfile = user.id === currentUser.uid;

  const [trophies, trophiesLoading] = useAchievements(user);
  const { data, loading: awardsLoading } = useGetUserAwardsQuery({
    variables: { userId: user.id },
  });

  const { creator, medal, reaction } = useMemo(
    () => groupBy(data?.user_awards, (userAward) => userAward.award.type),
    [data]
  );

  const medalAwards = useMemo(
    () => Object.entries(groupBy(medal, (userAward) => userAward.award.id)),
    [medal]
  );
  const reactionAwards = useMemo(
    () => Object.entries(groupBy(reaction, (userAward) => userAward.award.id)),
    [reaction]
  );
  const creatorAwards = useMemo(
    () => Object.entries(groupBy(creator, (userAward) => userAward.award.id)),
    [creator]
  );

  if (awardsLoading || trophiesLoading) {
    return <LayoutCentered isLoading height="auto" py={10} />;
  }

  if (
    !trophies &&
    !medalAwards.length &&
    !reactionAwards.length &&
    !creatorAwards.length
  ) {
    return (
      <EmptyState
        icon={<EmptyStateFontAwesomeIcon icon={duotone("medal")} />}
        headline="No awards yet"
        body={
          isOwnProfile
            ? "Earn awards by sharing your work with the community"
            : undefined
        }
      />
    );
  }

  return (
    <>
      {medalAwards.length > 0 && (
        <SectionUserAwards title="Medals">
          {medalAwards.map(([awardId, userAwards]) => (
            <UserAwardCard
              key={awardId}
              award={{ ...userAwards[0].award, count: userAwards.length }}
            />
          ))}
        </SectionUserAwards>
      )}
      {trophies && (
        <SectionUserAwards title="Trophies">
          {Object.entries(trophies).map(([achievementId, userAchievements]) => (
            <UserAchievementCard
              key={achievementId}
              userAchievements={userAchievements}
            />
          ))}
        </SectionUserAwards>
      )}
      {creatorAwards.length > 0 && (
        <SectionUserAwards title="Creator Awards">
          {creatorAwards.map(([awardId, userAwards]) => (
            <UserAwardCard
              key={awardId}
              award={{ ...userAwards[0].award, count: userAwards.length }}
            />
          ))}
        </SectionUserAwards>
      )}
      {reactionAwards.length > 0 && (
        <SectionUserAwards title="Reactions">
          {reactionAwards.map(([awardId, userAwards]) => (
            <UserAwardCard
              key={awardId}
              award={{ ...userAwards[0].award, count: userAwards.length }}
            />
          ))}
        </SectionUserAwards>
      )}
    </>
  );
};
