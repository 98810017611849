import { UpdateResolver } from "@urql/exchange-graphcache";
import {
  ClassroomLearnerGroupFragment,
  ClassroomLearnerGroupDocument,
} from "@tract/common/dist/graphql";

export const delete_learner_group_pending_member_by_pk: UpdateResolver = (
  _,
  args,
  cache
) => {
  const learnerGroupId = args.learnerGroupId as string;
  cache.invalidate({
    __typename: "learner_group_pending_member",
    learnerGroupId,
    foreignId: args.foreignId,
    provider: args.provider,
  });

  const learnerGroup = cache.readFragment(
    ClassroomLearnerGroupDocument,
    cache.keyOfEntity({
      __typename: "learner_group",
      id: learnerGroupId,
    })
  ) as unknown as ClassroomLearnerGroupFragment;

  if (learnerGroup) {
    cache.writeFragment(ClassroomLearnerGroupDocument, {
      ...learnerGroup,
      pendingMembers: learnerGroup.pendingMembers.filter(
        (member) => member.foreignId !== args.foreignId
      ),
    });
  }
};
