import React, { FC, PropsWithChildren } from "react";
import { useRouteMatch } from "react-router-dom";

import { Box, Flex, Link, VStack } from "Shared";

import { SideNav } from "Components/SideNav";
import { SideNavLink } from "Components/SideNav/SideNavLink";
import { SideNavTitle } from "Components/SideNav/SideNavTitle";
import { useSession } from "Services/session";

type Props = {};

export const LayoutCreate: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const { isAdmin } = useSession();
  const isPaths = !!useRouteMatch("/author/:status");
  const isSubmissions = !!useRouteMatch("/author/submissions");
  const isPeerReviews = !!useRouteMatch("/author/peer-reviews");

  return (
    <Flex
      direction={{ base: "column", lg: "row" }}
      flex="1 1 auto"
      position="relative"
    >
      <SideNav>
        <SideNavTitle>Create</SideNavTitle>
        <VStack w="100%" align="stretch" spacing={0} p={2}>
          <SideNavLink
            as={Link}
            to="/author/drafts"
            isActive={!isSubmissions && !isPeerReviews && isPaths}
          >
            My Paths
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/author/submissions"
            isActive={isSubmissions}
          >
            Responses
          </SideNavLink>
          {isAdmin && (
            <SideNavLink
              as={Link}
              to="/author/peer-reviews"
              isActive={isPeerReviews}
            >
              Peer Reviews
            </SideNavLink>
          )}
        </VStack>
      </SideNav>
      <Box w="100%" overflowX={{ base: "scroll", lg: "auto" }}>
        {children}
      </Box>
    </Flex>
  );
};
