import React, { useEffect, useState } from "react";

import { useDocument } from "Utils/hooks/firestore";

import { useToast } from "Shared";

import { captureException } from "Services/errors";

export const AppVersion = React.memo(() => {
  const toast = useToast();
  const [notified, setNotified] = useState(false);
  const isDevEnv = process.env.REACT_APP_ENVIRONMENT === "development";
  const isStagingEnv = process.env.REACT_APP_ENVIRONMENT === "staging";

  const { data, loading, error } = useDocument<{
    currentVersion: string;
  }>("app/bootstrap", {
    listen: true,
    onError: (err) => captureException(err),
  });

  useEffect(() => {
    if (isDevEnv) {
      return;
    }

    if (
      !error &&
      !loading &&
      data?.currentVersion !== process.env.REACT_APP_GIT_SHA
    ) {
      if (!notified && !toast.isActive("app-version")) {
        toast({
          id: "app-version",
          title: "New Version Available",
          description: "Please save your work and refresh your browser.",
          status: "info",
          duration: null,
          isClosable: isStagingEnv,
          position: "bottom-right",
        });

        setNotified(true);
      }
    }
  }, [notified, data, loading, toast, error, isDevEnv, isStagingEnv]);

  if (!loading && data?.currentVersion === process.env.REACT_APP_GIT_SHA) {
    if (toast.isActive("app-version")) {
      toast.close("app-version");
    }
  }

  return null;
});
