import { AvatarProps, forwardRef } from "@chakra-ui/react";

import { Avatar, Badge, Box, Flex } from "Shared";

type LivestreamAvatarProps = {
  isLive?: boolean;
} & AvatarProps;

export const LivestreamAvatar = forwardRef<LivestreamAvatarProps, "div">(
  ({ isLive = false, ...props }, ref) => {
    return (
      <Box
        position="relative"
        _after={
          isLive
            ? {
                content: "''",
                position: "absolute",
                top: "-5px",
                right: "-5px",
                bottom: "-5px",
                left: "-5px",
                border: "3px solid",
                borderColor: "green.500",
                borderRadius: "full",
                zIndex: -1,
              }
            : undefined
        }
      >
        <Avatar {...props} ref={ref} />
        {isLive && (
          <Flex
            position="absolute"
            justify="center"
            bottom={"-12px"}
            left={0}
            right={0}
            zIndex={1}
          >
            <Badge
              variant="solid"
              colorScheme="green"
              textAlign="center"
              lineHeight="1.25rem"
              letterSpacing="1.5"
              border="2px solid"
              borderColor="white"
              px={1.5}
            >
              Live
            </Badge>
          </Flex>
        )}
      </Box>
    );
  }
);
