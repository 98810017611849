import {
  Order_By,
  PathsWithReviewsQuery,
  usePathsWithReviewsQuery,
} from "@tract/common/dist/graphql";
import { LayoutCentered } from "Components/LayoutCentered";
import { PathCover } from "Components/PathCover";
import { ProfileBadges } from "Components/ProfileBadges";
import { UserAvatar } from "Components/UserAvatar";
import { UsernameLink } from "Components/UsernameLink";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import { useEffect, useState } from "react";
import { captureException } from "Services/errors";
import { useSession } from "Services/session";
import {
  Box,
  EmptyState,
  Text,
  AspectRatio,
  Table,
  Tr,
  Td,
  Tbody,
  Link,
  ButtonOutlined,
  Flex,
  ButtonSolid,
  IconRepeat,
  HStack,
  Button,
  IconCheck,
  IconImage,
} from "Shared";
import { pluralize } from "Utils";
import { UserFile } from "Utils/UserFile";
import { getDisplayName } from "Types/User";

const PATHS_PER_PAGE = 20;

export const MyReviewsTable: React.FC<{ creatorLevels: string | string[] }> = ({
  creatorLevels,
}) => {
  const { currentUser } = useSession();
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const { data, loading, fetchMore } = usePathsWithReviewsQuery({
    variables: {
      status: {},
      offset: 0,
      limit: PATHS_PER_PAGE,
      reviewUserId: { _eq: currentUser.id },
      creatorLevel: { _in: creatorLevels as string[] },
      userId: currentUser.id,
      type: {},
      orderBy: { updatedAt: Order_By.Desc },
    },
  });
  const pathReviews = data?.pathReviews;

  useEffect(() => {
    if (!!pathReviews?.length) {
      const moreToLoad = pathReviews.length >= PATHS_PER_PAGE * currentPage;

      setHasNextPage(moreToLoad);
    }
  }, [data, currentPage, setHasNextPage, pathReviews]);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await fetchMore({
        variables: {
          offset: pathReviews?.length,
        },
      });
      setCurrentPage(currentPage + 1);
    } catch (err: any) {
      captureException(err);
    } finally {
      setLoadingMore(false);
    }
  };

  if (loading) {
    return <LayoutCentered isLoading />;
  }

  if (!pathReviews?.length) {
    return <EmptyState headline="No feedback given yet" />;
  }

  return (
    <Box width="100%" mt={10}>
      <Table width="100%">
        <Tbody>
          {pathReviews?.map((pathReview) => (
            <AuthorReviewRow key={pathReview.id} review={pathReview} />
          ))}
        </Tbody>
      </Table>
      {hasNextPage && (
        <Flex direction="column" alignItems="center" mt={8}>
          <Button
            size="lg"
            variant="outline"
            isLoading={loadingMore}
            onClick={loadMore}
          >
            Load More
          </Button>
        </Flex>
      )}
    </Box>
  );
};

const AuthorReviewRow: React.FC<{
  review: PathsWithReviewsQuery["pathReviews"][0];
}> = ({ review }) => {
  const reviewCreatedAt = formatDistanceStrict(
    new Date(review.createdAt),
    new Date()
  );
  const viewLink =
    review.path?.isPublished && !!review.path.slug?.length
      ? `/path/${review.path?.slug}`
      : `/path/review/${review.path?.id}`;

  const reviewUpdatedAt = formatDistanceStrict(
    new Date(review.updatedAt),
    new Date()
  );
  const coAuthorsCount = review.path.authors_aggregate.aggregate?.count || 0;
  const coverFile = new UserFile(review.path.coverFile);

  return (
    <Tr>
      <Td py={4} pr={2} borderBottom="none" width="10rem">
        <Link to={viewLink} target="_blank" tabIndex={-1}>
          <AspectRatio
            ratio={16 / 9}
            width="10rem"
            borderRadius="xl"
            overflow="hidden"
            bg="gray.100"
          >
            {coverFile.exists ? (
              <PathCover objectFit="cover" cover={review.path.coverFile} />
            ) : (
              <Box w={6}>
                <IconImage color="gray.400" />
              </Box>
            )}
          </AspectRatio>
        </Link>
      </Td>
      <Td py={4} pr={10} borderBottom="none">
        <Text
          as={Link}
          to={viewLink}
          target="_blank"
          fontSize="lg"
          fontWeight="bold"
        >
          {review.path.title}
        </Text>
        <HStack fontSize="md" color="gray.600" mt={2}>
          {review?.type === "approve" || review?.needsReview ? (
            <IconCheck color="green.600" />
          ) : (
            <IconRepeat />
          )}
          <Text as="span">
            {review?.type === "approve"
              ? `Approved ${
                  review.user.isMod ? "and published" : ""
                } ${reviewCreatedAt} ago`
              : review?.needsReview
              ? `Requested ${reviewUpdatedAt} ago`
              : `You requested changes ${reviewCreatedAt} ago`}
          </Text>
        </HStack>
      </Td>
      <Td py={4} borderBottom="none">
        <HStack>
          <UserAvatar
            userId={review.path.authorId}
            username={review.path?.user?.username || ""}
            name={review.path?.user && getDisplayName(review.path?.user)}
            isTeacher={review.path?.user?.isEducator}
            avatarURL={review.path?.user?.avatar || ""}
            size="sm"
          />
          <UsernameLink
            username={review.path?.user?.username || undefined}
            userId={review.path?.user?.id}
            isTeacher={review.path?.user?.isEducator}
          >
            {review.path?.user && getDisplayName(review.path?.user)}
          </UsernameLink>
          {!!review?.path?.user && (
            <ProfileBadges
              iconSize={5}
              user={review.path.user}
              spacing={1}
              flagFont="md"
            />
          )}
          {coAuthorsCount > 0 && (
            <Text color="gray.600">
              and {pluralize(coAuthorsCount, "other")}
            </Text>
          )}
        </HStack>
      </Td>
      <Td py={4} pl={10} textAlign="right" borderBottom="none">
        {review?.type === "deny" &&
          (review.needsReview ? (
            <ButtonSolid size="lg" as={Link} to={viewLink} target="_blank">
              View Changes
            </ButtonSolid>
          ) : (
            <ButtonOutlined size="lg" as={Link} to={viewLink} target="_blank">
              Changes Requested
            </ButtonOutlined>
          ))}
      </Td>
    </Tr>
  );
};
