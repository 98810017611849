import { createIcon } from "@chakra-ui/react";

export const IconFilledBook = createIcon({
  displayName: "IconFilledBook",
  viewBox: "0 0 24 24",
  defaultProps: {
    h: 6,
    w: 6,
    fill: "currentColor",
  },
  path: (
    <path d="M19.083 15.385V4.448a.78.78 0 00-.78-.781H7.624A3.126 3.126 0 004.5 6.792v10.416c0 1.726 1.4 3.125 3.125 3.125h10.677a.78.78 0 00.781-.78v-.522a.787.787 0 00-.29-.608c-.136-.502-.136-1.93 0-2.432a.776.776 0 00.29-.606zM8.667 8.03c0-.108.088-.196.195-.196h6.901c.107 0 .195.088.195.196v.65a.196.196 0 01-.195.196H8.862a.196.196 0 01-.195-.195v-.651zm0 2.083c0-.107.088-.195.195-.195h6.901c.107 0 .195.088.195.195v.651a.196.196 0 01-.195.195H8.862a.196.196 0 01-.195-.195v-.651zm8.248 8.138h-9.29a1.04 1.04 0 110-2.083h9.29a11.71 11.71 0 000 2.083z" />
  ),
});
