import { UpdateResolver } from "@urql/exchange-graphcache";

import {
  DeletePathNodeMutationResult,
  PathBuilderPathV4Fragment,
  PathBuilderNodeFragment,
} from "@tract/common/dist/graphql";

import { PATH_BUILDER_PATH_FRAGMENT } from "Pages/CreateV4/graphql";

export const delete_path_node_by_pk: UpdateResolver = (
  result,
  _,
  cache,
  __
) => {
  const removedNodeId = (result as DeletePathNodeMutationResult["data"])
    ?.delete_path_node_by_pk?.id;
  const pathId = (result as DeletePathNodeMutationResult["data"])
    ?.delete_path_node_by_pk?.pathId;

  const path = cache.readFragment(
    PATH_BUILDER_PATH_FRAGMENT,
    cache.keyOfEntity({
      __typename: "path",
      id: pathId,
    })
  );

  cache.writeFragment<PathBuilderPathV4Fragment>(PATH_BUILDER_PATH_FRAGMENT, {
    ...path,
    nodes: path.nodes.filter(
      (node: PathBuilderNodeFragment) => node.id !== removedNodeId
    ),
  });
};
