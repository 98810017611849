import { FC, useEffect } from "react";
import { serverTimestamp } from "firebase/firestore";
import { v4 } from "uuid";

import { HStack, IconUsers, Text } from "Shared";

import { useDocument } from "Utils/hooks/firestore";
import { useInterval } from "@tract/common/dist/hooks";
import { LivestreamViewers as ILivestreamViewers } from "Types/LivestreamViewers";
import { db } from "Services/firebase";

type WatcherCountProps = {
  livestreamId: number;
};

const VIEWER_TIMESTAMP_WRITE_INTERVAL = 60 * 1000;
const VIEWER_TIMEOUT = 90 * 1000;

const livestreamViewerIdSessionKey = "livestreamViewerId";
if (!sessionStorage.getItem(livestreamViewerIdSessionKey)) {
  sessionStorage.setItem(livestreamViewerIdSessionKey, v4());
}
const viewerId = sessionStorage.getItem(livestreamViewerIdSessionKey);

export const WatcherCount: FC<WatcherCountProps> = ({ livestreamId }) => {
  // Updating one property of this document through the normal useDocument
  // way confuses the cache (makes it think there is only one viewer) so
  // this function updates firestore directly, bypassing the caching.
  function updateViewerTimestamp() {
    if (viewerId) {
      const data = { viewers: { [viewerId]: serverTimestamp() } };
      const options = { merge: true };
      db.doc(`/livestream-viewers/${livestreamId}`).set(data, options);
    }
  }

  const { data: livestreamViewersDoc } = useDocument<ILivestreamViewers>(
    livestreamId ? `/livestream-viewers/${livestreamId}` : null,
    { listen: true }
  );

  const watcherCount = calculateViewers(livestreamViewersDoc);

  // Update the livestream viewer firestore doc to include this viewer once when the page is loaded
  useEffect(updateViewerTimestamp, [livestreamId]);
  // Then update it periodically to indicate this user is still watching
  useInterval(updateViewerTimestamp, VIEWER_TIMESTAMP_WRITE_INTERVAL);

  if (!watcherCount) return null;

  return (
    <HStack
      spacing={2}
      color="green.600"
      display={{ base: "none", lg: "flex" }}
    >
      <IconUsers />
      <Text fontWeight="bold">{watcherCount}</Text>
    </HStack>
  );
};

function calculateViewers(
  livestreamSessionDoc: ILivestreamViewers | null | undefined
): number {
  if (!livestreamSessionDoc?.viewers) return 0;

  const timestamps = Object.values(livestreamSessionDoc.viewers);
  const currentTimestamps = timestamps.filter((timestamp: any) => {
    if (!timestamp) return false;

    const timestampDate = timestamp.toDate();
    return Date.now() - timestampDate.valueOf() < VIEWER_TIMEOUT;
  });

  return currentTimestamps.length;
}
