import { useRef } from "react";
import * as Yup from "yup";
import {
  GetPathWithNodesQuery,
  InsertGuideMutation,
  InsertGuideMutationVariables,
  UpdateGuideByPkMutation,
  UpdateGuideByPkMutationVariables,
} from "@tract/common/dist/graphql";
import {
  Modal,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  useToast,
  FormControl,
  FormLabel,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  FormErrorMessage,
  ExternalLink,
  Tabs,
  Markdown,
  ModalFooter,
  ButtonGroup,
  Button,
  HStack,
  VisuallyHidden,
  Input,
  VStack,
  Box,
} from "Shared";

import { Formik, Form, Field, FieldProps, FormikProps } from "formik";
import { useMutation } from "urql";

import { captureException } from "Services/errors";
import { INSERT_GUIDE_MUTATION, UPDATE_GUIDE_BY_PK_MUTAION } from "./graphql";

export type TriggerType = "settings-cta" | "publish-cta";

export interface GuideFormProps {
  description?: string;
  bigIdea?: string;
  durationLower?: number;
  durationUpper?: number;
  resources?: string;
  concepts?: string;
  learningObjectives?: string;
  contentStandards?: string;
  discussionQuestions?: string;
  downloadUrl?: string;
}

const GuideFormSchema = Yup.object().shape({
  downloadUrl: Yup.string().url("Must be a URL"),
  description: Yup.string(),
  bigIdea: Yup.string(),
  durationLower: Yup.number()
    .integer("Must be a whole number/integer")
    .optional(),
  durationUpper: Yup.number()
    .integer("Must be a whole number/integer")
    .optional(),
  resources: Yup.string(),
  concepts: Yup.string(),
  learningObjectives: Yup.string(),
  contentStandards: Yup.string(),
  discussionQuestions: Yup.string(),
});

export const GuideModal: React.FC<{
  path: GetPathWithNodesQuery["path"];
  isOpen: boolean;
  onClose: () => void;
}> = ({ path, isOpen, onClose }) => {
  const toast = useToast();

  const formRef = useRef<FormikProps<GuideFormProps>>(null);
  const [, insertGuideMutation] = useMutation<
    InsertGuideMutation,
    InsertGuideMutationVariables
  >(INSERT_GUIDE_MUTATION);
  const [, updateGuideMutation] = useMutation<
    UpdateGuideByPkMutation,
    UpdateGuideByPkMutationVariables
  >(UPDATE_GUIDE_BY_PK_MUTAION);

  const currentGuide = !!path?.guides?.length
    ? path.guides[0]?.guide
    : undefined;
  const creating = !currentGuide;

  const onSubmitForm = async (values: GuideFormProps) => {
    try {
      let mutationResult;
      if (!!currentGuide) {
        mutationResult = await updateGuideMutation({
          id: currentGuide.id,
          object: {
            description: values.description,
            bigIdea: values.bigIdea,
            durationLower: values.durationLower,
            durationUpper: values.durationUpper,
            resources: values.resources,
            concepts: values.concepts,
            learningObjectives: values.learningObjectives,
            contentStandards: values.contentStandards,
            downloadUrl: values.downloadUrl,
            discussionQuestions: values.discussionQuestions,
          },
        });
      } else {
        mutationResult = await insertGuideMutation({
          pathId: path?.id,
          isRelatedPath: false,
          guide: {
            description: values.description,
            bigIdea: values.bigIdea,
            durationLower: values.durationLower,
            durationUpper: values.durationUpper,
            resources: values.resources,
            concepts: values.concepts,
            learningObjectives: values.learningObjectives,
            contentStandards: values.contentStandards,
            downloadUrl: values.downloadUrl,
            discussionQuestions: values.discussionQuestions,
          },
        });
      }
      if (!!mutationResult.error) {
        throw new Error(mutationResult.error.toString());
      }
      toast({
        title: `Guide ${creating ? "created" : "saved"}`,
        status: "success",
      });
      onClose();
    } catch (error: any) {
      captureException(error);
      toast({
        status: "error",
        title: "Error saving guide. Please try again",
      });
    }
  };

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <Formik
        initialValues={{
          downloadUrl: currentGuide?.downloadUrl || "",
          description: currentGuide?.description || "",
          bigIdea: currentGuide?.bigIdea || "",
          durationLower: currentGuide?.durationLower || undefined,
          durationUpper: currentGuide?.durationUpper || undefined,
          resources: currentGuide?.resources || "",
          concepts: currentGuide?.concepts || "",
          learningObjectives: currentGuide?.learningObjectives || "",
          contentStandards: currentGuide?.contentStandards || "",
          discussionQuestions: currentGuide?.discussionQuestions || "",
        }}
        onSubmit={onSubmitForm}
        innerRef={formRef}
        enableReinitialize
        validationSchema={GuideFormSchema}
      >
        {({ dirty, isSubmitting }) => (
          <ModalContent maxH="85%" overflowY="scroll">
            <Form>
              <ModalCloseButton />
              <ModalHeader>
                <Text fontSize="2xl" fontWeight="bold">
                  {creating ? "Create" : "Update"} Path Guide
                </Text>
              </ModalHeader>
              <ModalBody px={8}>
                <VStack align="stretch" spacing={5}>
                  <Field name="downloadUrl">
                    {({ field, meta: { error } }: FieldProps) => (
                      <FormControl id={field.name} isInvalid={!!error}>
                        <FormLabel>Download Url</FormLabel>
                        <Input
                          {...field}
                          variant="filled"
                          display="block"
                          placeholder="Paste google drive share url here..."
                        />
                        <FormErrorMessage>{error}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Box>
                    <FormLabel>
                      Lower and Upper Duration Estimate (Days)
                    </FormLabel>
                    <HStack spacing={3}>
                      <Field name="durationLower">
                        {({ field, meta: { error } }: FieldProps) => (
                          <FormControl id={field.name} isInvalid={!!error}>
                            <VisuallyHidden>
                              <FormLabel>Lower Duration</FormLabel>
                            </VisuallyHidden>
                            <Input
                              {...field}
                              variant="filled"
                              display="block"
                              placeholder="Lower..."
                            />
                            <FormErrorMessage>{error}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="durationUpper">
                        {({ field, meta: { error } }: FieldProps) => (
                          <FormControl id={field.name} isInvalid={!!error}>
                            <VisuallyHidden>
                              <FormLabel>Upper Duration</FormLabel>
                            </VisuallyHidden>
                            <Input
                              {...field}
                              variant="filled"
                              display="block"
                              placeholder="Upper..."
                            />
                            <FormErrorMessage>{error}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </HStack>
                  </Box>
                  <Field name="description">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full">Description</FormLabel>
                        <FormControl
                          id="description"
                          isInvalid={submitCount > -1 && !!error}
                        >
                          <Textarea
                            {...field}
                            name="description"
                            size="lg"
                            height="150px"
                            variant="filled"
                            isDisabled={isSubmitting}
                          />
                          <FormErrorMessage>{error}</FormErrorMessage>
                        </FormControl>
                      </Box>
                    )}
                  </Field>
                  <Text color="gray.600" fontStyle="italic">
                    Fields below are{" "}
                    <ExternalLink
                      href="https://www.markdownguide.org/cheat-sheet/"
                      target="_blank"
                      color="brand"
                      fontWeight="bold"
                    >
                      Markdown Supported
                    </ExternalLink>
                  </Text>
                  <Field name="bigIdea">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Big Idea
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="bigIdea"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="bigIdea"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">Big Idea is empty.</Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                  <Field name="resources">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Resources
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="resources"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="resources"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">
                                  Resources is empty.
                                </Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                  <Field name="discussionQuestions">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Discussion Questions
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="discussionQuestions"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="discussionQuestions"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">
                                  Discussion Questions field is empty.
                                </Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                  <Field name="concepts">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Concepts
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="concepts"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="concepts"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">Concepts is empty.</Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                  <Field name="learningObjectives">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Learning Objectives
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="learningObjectives"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="learningObjectives"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">
                                  Learning Objectives is empty.
                                </Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                  <Field name="contentStandards">
                    {({
                      field,
                      form: { submitCount },
                      meta: { error },
                    }: FieldProps) => (
                      <Box>
                        <FormLabel w="full" mb={0}>
                          Content Standards
                        </FormLabel>
                        <Tabs size="md" w="full">
                          <TabList>
                            <Tab>Write</Tab>
                            <Tab>Preview</Tab>
                          </TabList>
                          <TabPanels>
                            <TabPanel pt={4}>
                              <FormControl
                                id="contentStandards"
                                isInvalid={submitCount > -1 && !!error}
                              >
                                <Textarea
                                  {...field}
                                  name="contentStandards"
                                  size="lg"
                                  height="150px"
                                  variant="filled"
                                  isDisabled={isSubmitting}
                                />
                                <FormErrorMessage>{error}</FormErrorMessage>
                              </FormControl>
                            </TabPanel>
                            <TabPanel pt={6} fontWeight="normal">
                              {field.value.length ? (
                                <Markdown
                                  source={field.value || ""}
                                  preset="mini"
                                />
                              ) : (
                                <Text color="gray.400">
                                  Content Standards is empty.
                                </Text>
                              )}
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </Box>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <ModalFooter
                position="sticky"
                left={0}
                right={0}
                bottom={0}
                zIndex={10}
                bg="white"
                borderTop="1px solid"
                borderTopColor="gray.200"
              >
                <ButtonGroup spacing={2}>
                  <Button size="lg" variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="lg"
                    variant="solid"
                    disabled={!dirty}
                    colorScheme="brandFull"
                    isLoading={isSubmitting}
                  >
                    {creating ? "Create" : "Save"}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </Form>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  );
};
